import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SelectSearchable from '~/components/SelectSearchable'
import { setFilter } from '~/store/modules/filtersSellOut/actions'
import { FILTERS_SEELOUT } from '~/store/modules/filtersSellOut/constants'

/**
 * Componente para selecionar a granulometria dos dados.
 * Ele possibilita a seleção de um valores e mantém no estado global: filtersSellOut.aggregate.
 *
 * Não recebe nenhum parâmetro e retorna um select procurável.
 *
 * @example <FilterAggregate />
 */

const options = [
  { label: 'Dia', value: 'DAY' },
  { label: 'Semana', value: 'WEEK' },
  { label: 'Mês', value: 'MONTH' },
  { label: 'Ano', value: 'YEAR' }
]

export const aggregateOptions = {
  day: options[0],
  week: options[1],
  month: options[2],
  year: options[3]
}

const defaultValue = options[0]

const FilterAggregate = () => {
  const dispatch = useDispatch()

  const { aggregate: filteredAggregate } = useSelector(
    state => state.filtersSellOut
  )

  const handleChange = useCallback(
    data => {
      const newState = data || defaultValue

      dispatch(setFilter({ type: FILTERS_SEELOUT.aggregate, data: newState }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!filteredAggregate) {
      handleChange()
    }
  }, [dispatch, filteredAggregate, handleChange])

  return (
    <SelectSearchable
      placeholder="Alterar período"
      options={options}
      onChange={handleChange}
      defaultValue={[filteredAggregate || defaultValue]}
      isClearable={false}
    />
  )
}

export default FilterAggregate
