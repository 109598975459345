export const ORDERS_CARDS = 'ORDERS_CARDS'
export const ORDERS_PER_DAY = 'ORDERS_PER_DAY'
export const ORDERS_TOP_PRODUCTS = 'ORDERS_TOP_PRODUCTS'
export const ORDERS_TOP_CHANNELS = 'ORDERS_TOP_CHANNELS'
export const ORDERS_TOP_PAYMENTS = 'ORDERS_TOP_PAYMENTS'
export const ORDERS_LIST = 'ORDERS_LIST'
export const ORDERS_LIST_SET_FILTERS = 'ORDERS_LIST_SET_FILTERS'
export const ORDER_GET_INFO = 'ORDER_GET_INFO'
export const CANCEL_ORDER = 'CANCEL_ORDER'
export const ORDER_GET_INFO_REFRESH = 'ORDER_GET_INFO_REFRESH'
export const ORDER_CLEAR_INFO = 'ORDER_CLEAR_INFO'
export const ORDER_UPDATE_INFO = 'ORDER_UPDATE_INFO'
export const ORDER_UPDATE_STATUS = 'ORDER_UPDATE_STATUS'
export const ORDER_UPDATE_DISCOUNT = 'ORDER_UPDATE_DISCOUNT'
export const ORDER_UPDATE_RECIPIENT = 'ORDER_UPDATE_RECIPIENT'
export const ORDER_DELETE = 'ORDER_DELETE'

export const REQUEST_ERROR_EXTERNAL_DELIVERY = 'ERROR_EXTERNAL_DELIVERY'

export const PAYMENT_VERIFY_REFUSAL = 'PAYMENT_VERIFY_REFUSAL'

export const ORDER_ITEMS_UPDATE = 'ORDER_ITEMS_UPDATE'
export const ORDER_ITEM_DELETE = 'ORDER_ITEM_DELETE'

export const ORDER_PAYMENT_CANCEL = 'ORDER_PAYMENT_CANCEL'

export const ORDER_REDUX_QUERY = 'queryOrder'

export const LOCALSTORAGE_ORDER_TABLE_STATUS =
  '@NewtailAdmin:orderTableActiveStatusKeys'

export const RESTART_PICKING = 'RESTART_PICKING'
export const APPROVE_FRAUD_ANALYSIS = 'APPROVE FRAUD ANALYSIS'

export const REQUEST_EXTERNAL_DELIVERY = 'REQUEST_EXTERNAL_DELIVERY'
export const CANCEL_EXTERNAL_DELIVERY = 'CANCEL_EXTERNAL_DELIVERY'
