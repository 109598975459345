import { useParams } from 'react-router-dom'
import { t } from 'i18next'

import EmptyPage from '~/components/empty-page'
import Loading from '~/components/Loading'
import LoadingPopUp from '~/components/LoadingPopUp'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { useAlert } from '~/hooks/useAlert'
import useFetchSWR from '~/hooks/useFetchSWR'
import useQueryParams from '~/hooks/useQuery'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'

import { schema } from './schema'

export const IndustryPaymentsTable = () => {
  const publisherId = useQueryParams().get('rmid')

  const { data, error, isValidating } = useFetchSWR<WalltetPaymentsResponse>({
    url: `${reatailmediaApi}/payment?publisher_id=${publisherId}`,
    refreshInterval: 60000,
    revalidateOnFocus: false
  })

  const [AlertOnError] = useAlert(error)

  const { id } = useParams()

  return (
    <>
      <Loading status={!data && isValidating}>{t('common:actions.loadingData')}...</Loading>

      <LoadingPopUp isActive={isValidating} />

      <AlertOnError />

      {data?.total === 0 && (
        <EmptyPage
          title={t('common:table.empty')}
          subtitle={t('common:table.emptyFiltered')}
          titleButton={t('common:words.clearFilters')}
          svgPath="photos/orders-empty"
        />
      )}

      {data?.total > 0 && (
        <Panel isLoading={!data} noPaddingBody>
          <Table
            schema={schema({ walletId: id, publisherId })}
            data={data?.data}
            total={data?.total}
            sideBorder
            smallerRowPadding
          />
        </Panel>
      )}
    </>
  )
}
