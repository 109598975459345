import { IconType } from 'react-icons'
import { FiUsers as icon } from 'react-icons/fi'

import * as Customer from '~/pages/customers'

export const customers: RootMenuItem<IconType> = {
  id: '06a542f0-5da3-4ab5-ad0b-cf3e50aff441',
  path: '/customers',
  name: 'Clientes',
  pageTitle: 'Clientes',
  icon,
  children: [
    {
      id: '416e664d-49ff-4fa2-909d-0844d86f4bb2',
      path: '/customers/list',
      name: 'Listagem de clientes',
      pageTitle: 'Listagem de clientes',
      component: Customer.CustomersList
    },
    {
      id: 'f3d4f3e2-475e-4be0-a969-afb9aa5710ee',
      path: '/customers/create',
      name: 'Cadastrar cliente',
      pageTitle: 'Cadastrar cliente',
      component: Customer.CustomerCreate
    },
    {
      id: null, // rota-sem-id
      path: '/customers/edit/:id',
      name: 'Editar cliente',
      pageTitle: 'Editar cliente',
      component: Customer.CustomersEdit,
      hideMenu: true
    },
    {
      id: 'f3321883-40e4-4cc4-bff3-38502ed960b8',
      path: '/customers/dashboard',
      name: 'Métricas de clientes',
      pageTitle: 'Métricas de clientes',
      component: Customer.CustomerDashboard
    }
  ]
}
