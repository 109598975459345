import { create } from './create'
import { dashboard } from './dashboard'
import { get } from './get'
import { handleProducts } from './handleProducts'
import { patch } from './patch'
import { patchProduct } from './patchProduct'
import reports from './report'
import { update } from './update'

export default {
  ...get,

  ...create,
  ...update,
  ...patch,
  ...dashboard,
  ...handleProducts,
  ...reports,
  ...patchProduct
}
