import React from 'react';

import { AuthorizationTypes } from './types';

const PaymentAuthorization = ({ payment }) => {
  return (
    <>
      {' • '}
      <strong>Autorização: </strong> {payment?.autorization_id}{' '}
    </>
  );
};

export default PaymentAuthorization;

PaymentAuthorization.propTypes = AuthorizationTypes;
