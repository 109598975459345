import React from 'react'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'

import { Container } from './styles'

/**
 *
 *
 * @param {node} children Text for box
 * @param {boolean} active State on parent component
 * @param {function} onDelete Function to call on delete
 * @param {function} onCancel Function to call on cancel
 * @param {boolean} loading State on reducer ou parent component
 * @param {string} elementPosition Element position: absolute or relative
 *
 */

const DeleteConfirmation = ({
  children,
  active,
  onDelete,
  onCancel,
  yesMsg,
  cancelMsg,
  loading,
  elementPosition = 'relative'
}) =>
  active ? (
    <Container
      active={active}
      className="deleteItem"
      elementPosition={elementPosition}
    >
      <div className="alertBox">
        <div className="childrenWrapper">{children}</div>

        <footer className="actionButtons">
          <Button
            size="xsmall"
            onClick={onDelete}
            type="button"
            text={yesMsg}
            template="alert"
            loading={loading}
          />
          <Button
            size="xsmall"
            onClick={onCancel}
            type="button"
            template="transparentDanger"
            text={cancelMsg}
            loading={loading}
          />
        </footer>
      </div>
    </Container>
  ) : (
    <></>
  )

export default DeleteConfirmation

DeleteConfirmation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  active: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  yesMsg: PropTypes.string,
  cancelMsg: PropTypes.string,
  elementPosition: PropTypes.oneOf(['relative', 'absolute']),
  loading: PropTypes.bool
}

DeleteConfirmation.defaultProps = {
  yesMsg: 'Sim, quero apagar.',
  cancelMsg: 'Cancelar',
  loading: false,
  elementPosition: 'relative'
}
