// Customers
export const CUSTOMER_LIST = 'CUSTOMER_LIST'
export const CUSTOMER_LIST_SET_PAGE = 'CUSTOMER_LIST_SET_PAGE'
export const CUSTOMER_LIST_PER_PAGE = 'CUSTOMER_LIST_PER_PAGE'

export const CUSTOMER_REDUX_QUERY = 'queryCustomer'

export const GET_CUSTOMER = 'GET_CUSTOMER'
export const CLEAR_CUSTOMER_STATE = 'CLEAR_CUSTOMER_STATE'

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'

export const CUSTOMER_SET_EDITABLE_FORM = 'CUSTOMER_SET_EDITABLE_FORM'
export const CUSTOMER_SUBMIT_FORM = 'CUSTOMER_SUBMIT_FORM'
export const CUSTOMER_BY_TYPE = 'CUSTOMER_BY_TYPE'
export const CUSTOMER_CHART_PER_DAY = 'CUSTOMER_CHART_PER_DAY'
export const CUSTOMER_CHART_PER_TIME = 'CUSTOMER_CHART_PER_TIME'
export const CUSTOMER_TOP_CHANNELS = 'CUSTOMER_TOP_CHANNELS'
export const CUSTOMER_PER_GMV_RANGE = 'CUSTOMER_PER_GMV_RANGE'
