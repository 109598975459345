import { types } from '../constants'

const index = {
  pending: `${types.list}_PENDING`,
  fulfilled: `${types.list}_FULFILLED`,
  rejected: `${types.list}_REJECTED`
}

const parentKey = 'placements'
const key = 'get'

export const get = {
  [index.pending]: ({ draft }: ActionData) => {
    draft[parentKey][key].loading = true
    draft[parentKey][key].error = null
  },
  [index.fulfilled]: ({ draft, action }: ActionData) => {
    draft[parentKey][key].data = action.payload
    draft[parentKey][key].loading = false
    draft[parentKey][key].error = null
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft[parentKey][key].loading = false
    draft[parentKey][key].error = action.payload
  }
}
