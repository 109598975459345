// Products
export const PRODUCTS_LIST = 'PRODUCTS_LIST';
export const PRODUCT_SUBMIT_FORM = 'PRODUCT_SUBMIT_FORM';
export const PRODUCT_GET_INFO = 'PRODUCT_GET_INFO';
export const PRODUCT_UPDATE_INFO = 'PRODUCT_UPDATE_INFO';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';

export const PRODUCT_REDUX_QUERY = 'queryProduct';

export const PRODUCTS_PER_PAGE_CHANGED = 'PRODUCTS_PER_PAGE_CHANGED';

export const SEARCH_OFFERS = 'SEARCH_OFFERS';
