import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Alert from '~/components/Alert';
import HeaderPages from '~/components/HeaderPages';
import Loading from '~/components/Loading';
import PageTitle from '~/components/PageTitle';
import FormFlyer from '~/modules/flyers/components/Form';
import HeaderButtons from '~/modules/flyers/components/HeaderButtons';
import {
  clearFlyerStore,
  getFlyer,
} from '~/modules/flyers/store/flyers/actions';

const FlyerEdit = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    ({ promotionFlyer }) => promotionFlyer.flyers.view,
  );

  useLayoutEffect(() => {
    if (data?.id !== id) {
      dispatch(clearFlyerStore());
    }
  }, [dispatch, id, data]);

  useEffect(() => {
    if (!data) {
      dispatch(getFlyer({ id }));
    }
  }, [dispatch, id, data]);

  return (
    <>
      <PageTitle title="Encartes > Editar" />

      <HeaderPages
        title="Editar encarte"
        sideRightComponent={<HeaderButtons isEditPage />}
      />

      <Loading status={loading}>Carregando encarte...</Loading>

      <Alert active={error}>Erro: {error}</Alert>

      {!loading && data && !error && <FormFlyer />}
    </>
  );
};

export default FlyerEdit;
