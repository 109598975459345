import React from 'react';
import PropTypes from 'prop-types';

import Panel from '~/components/Panel';
import Table from '~/components/Table';

import { formatMoney, formatDate } from '~/helpers';

const schema = {
  id: {
    render: obj => obj.id,
    title: '#',
  },
  name: {
    render: obj => obj.productName,
    title: '#Pedido',
  },
  value: {
    render: obj => formatDate(obj.date),
    title: 'Data',
  },
  quantity: {
    render: obj => formatMoney(obj.incomeValue),
    title: 'Valor',
  },
};

const Orders = ({ data = {}, loading }) => (
  <Panel title="Pedidos" noPaddingBody isLoading={loading}>
    <Table schema={schema} data={data.info} />
  </Panel>
);
export default Orders;

Orders.propTypes = {
  data: PropTypes.shape(),
  loading: PropTypes.bool,
};

Orders.defaultProps = {
  data: {},
  loading: false,
};
