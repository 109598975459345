import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'
import Tooltip from '~/components/Tooltip'
import { Badge } from '~/components/UI/Badge'
import { getRole } from '~/helpers'
import { useDeleteConfirmation } from '~/hooks/useDeleteConfirmation'
import { cancelPaymentOrder } from '~/pages/orders/actions'

import { AuthorizationTypes } from './types'

const PaymentCancelButton = ({ orderId, payment, hasWaitingRefund }) => {
  const [DeleteConfirmation, activeConfirmation, setActiveConfirmation] =
    useDeleteConfirmation()

  const dispatch = useDispatch()

  const { loadingPaymentCancel } = useSelector(state => state.order)

  const isAdmin = useMemo(() => getRole() === 'admin', [])

  const cancelPaymentAvaiable = useMemo(
    () => ['payment_approved', 'payment_authorized'].includes(payment?.status),
    [payment]
  )

  const handleDelete = useCallback(() => {
    const requestData = {
      orderId,
      body: {
        autorization_id: payment?.autorization_id
      }
    }

    dispatch(cancelPaymentOrder(requestData))
    setActiveConfirmation()
  }, [payment, dispatch, orderId, setActiveConfirmation])

  return (
    !!isAdmin &&
    !!cancelPaymentAvaiable && (
      <>
        {' '}
        {!hasWaitingRefund && (
          <Button
            template="transparentDanger"
            text="Cancelar pagamento"
            customWidth="auto"
            size="xsmall"
            onClick={setActiveConfirmation}
            loading={loadingPaymentCancel}
            disabled={loadingPaymentCancel || hasWaitingRefund}
          />
        )}
        {hasWaitingRefund && (
          <Badge>
            <Spinner /> Aguardando estorno...
          </Badge>
        )}
        {!!isAdmin && (
          <div className="wrapperConfirmPayment">
            <DeleteConfirmation
              active={activeConfirmation}
              onDelete={handleDelete}
              onCancel={setActiveConfirmation}
              yesMsg="Sim, desejo cancelar"
              cancelMsg="Não"
              loading={loadingPaymentCancel}
              disabled={loadingPaymentCancel || hasWaitingRefund}
            >
              Tem certeza que deseja cancelar esse pagamento?
            </DeleteConfirmation>
          </div>
        )}
      </>
    )
  )
}

PaymentCancelButton.propTypes = AuthorizationTypes

export default PaymentCancelButton
