import React, { useRef } from 'react'

import { Button } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'

import Approvers from '../_elements/Form/Approvers'
import GeneralInfo from '../_elements/Form/GeneralInfo'
import TradeDetails from '../_elements/Form/TradeDetails'
import SelectedProducts from '../_elements/SelectedProducts'
import * as S from './styles'

const TradeCreate = () => {
  return (
    <>
      <PageTitle title="Campanhas > Novo acordo" />

      {/* Informações gerais */}
      <GeneralInfo />

      {/* Detalhes do acordo */}
      {/* <TradeDetails /> */}

      {/* Fluxo de aprovação */}
      <Approvers />

      {/* Tabela com os produtos selecionados */}
      <SelectedProducts />

      <S.ButtonWrapper>
        <Button
          template="transparentDark"
          text="Pular esta etapa por enquanto"
        />

        <Button text="Próximo" />
      </S.ButtonWrapper>
    </>
  )
}

export default TradeCreate
