import axios from 'axios';

export const createUser = data => {
  return axios.post('/user-signup', data);
};

export const createAccount = data => {
  return axios.post('/api/account/account-create', data);
};

export const createIntegration = data => {
  return axios.post('/create-integration', data);
};

export const getOnboardingStep = accountId => {
  return axios.get(`/api/onboarding/step/${accountId}`);
};

export const skipIntegration = data => {
  return axios.put('/api/onboarding/integration-skip', data);
};

export const checkTokenInvite = token => {
  return axios.get(`/api/user/validate-invite/${token}`);
};

export const activateInvite = data => {
  return axios.post(`/api/user/active-invite`, data);
};
