import React from 'react'
import { FiPlus } from 'react-icons/fi'

import { ButtonNavLink } from '~/components/Buttons'
import { FilterSearch } from '~/components/FilterSearch'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { CUSTOMER_REDUX_QUERY } from '~/store/modules/customers/constants'

import CustomersTable from './elements/CustomersTable'

const CustomersList = () => (
  <>
    {/* Meta <title> */}
    <PageTitle title="Clientes > Listagem" />

    <HeaderPages
      title="Listagem de clientes"
      sideLeftComponent={
        <FilterSearch
          placeholder="Buscar cliente por nome ou CPF/CNPJ"
          keyState={`${CUSTOMER_REDUX_QUERY}`}
          live
        />
      }
      sideRightComponent={
        <>
          <ButtonNavLink
            to="/customers/create"
            text="Cadastrar cliente"
            iconLeft={<FiPlus />}
            size="small"
          />
        </>
      }
    />

    <CustomersTable />
  </>
)

export default CustomersList
