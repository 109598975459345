import { useDispatch } from 'react-redux'

import { formatStringDateToTimezone } from '~/helpers'
import { deleteFlyer, updateFlyer } from '~/modules/flyers/store/flyers/actions'

const useFlyers = () => {
  const dispatch = useDispatch()

  const handleCancelFlyers = data => {
    const requestData = {
      formData: { ...data, active: false },
      id: data.id,
      successFeedbackMsg: 'Encarte cancelado com sucesso.',
      useModel: false
    }

    dispatch(updateFlyer(requestData))
  }

  const handleFinishFlyer = ({ data, onSuccess }) => {
    const requestData = {
      formData: {
        ...data,
        end_at: formatStringDateToTimezone(new Date())
      },
      id: data.id,
      successFeedbackMsg: 'Encarte finalizado com sucesso.',
      useModel: false,
      onSuccess
    }

    dispatch(updateFlyer(requestData))
  }

  const handlePauseFlyer = ({ data, onSuccess }) => {
    const requestData = {
      formData: { ...data, active: false },
      id: data.id,
      successFeedbackMsg: 'Encarte pausado com sucesso.',
      onSuccess,
      useModel: false
    }

    dispatch(updateFlyer(requestData))
  }

  const handleReactiveFlyer = ({ data }) => {
    const requestData = {
      formData: { ...data, active: true },
      id: data.id,
      successFeedbackMsg: 'Encarte reativado com sucesso.',
      useModel: false,
      onSuccess: () => null
    }

    dispatch(updateFlyer(requestData))
  }

  const handleDeleteFlyer = ({ data, onSuccess }) => {
    dispatch(deleteFlyer({ id: data.id, onSuccess }))
  }

  return {
    handleCancelFlyers,
    handleFinishFlyer,
    handlePauseFlyer,
    handleReactiveFlyer,
    handleDeleteFlyer
  }
}

export default useFlyers
