import { t } from 'i18next'
export const joinWithCommasAndAnd = ({ values }: { values: string[] }) => {
  const length = values.length

  if (length === 0) {
    return ''
  }

  if (length === 1) {
    return values[0]
  }

  if (length === 2) {
    return `${values[0]} ${t('rm:and')} ${values[1]}`
  }

  const last = values[length - 1]
  const rest = values.slice(0, -1).join(', ')

  return `${rest}, e ${last}`
}
