import React from 'react'
import { injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { setFilteredCampaignStatus } from '~/store/modules/filters/actions'

import SelectSearchable from '../SelectSearchable'

const FilterStatusCampaign = ({ intl }) => {
  const dispatch = useDispatch()

  const { campaignStatus: filteredTemplateType } = useSelector(
    state => state.filters
  )

  const types = ['scheduled', 'enqueued', 'sent', 'canceled']

  const type_option = types.map((item, index) => ({
    label: intl.formatMessage({ id: item }),
    value: item,
    step: index,
    type: item
  }))

  const handleChangeStatus = selectedStatusType => {
    dispatch(
      setFilteredCampaignStatus({ filteredCampaignStatus: selectedStatusType })
    )
  }

  return (
    <SelectSearchable
      placeholder="Filtrar por status"
      options={type_option}
      onChange={handleChangeStatus}
      defaultValue={[filteredTemplateType]}
    />
  )
}

export default injectIntl(FilterStatusCampaign)

FilterStatusCampaign.propTypes = {
  intl: PropTypes.shape()
}

FilterStatusCampaign.defaultProps = {
  intl: null
}
