import i18next from 'i18next'
import { produce } from 'immer'
import { AnyAction } from 'redux'

import * as types from './constants'

const INITIAL_STATE: ForgotPasswordState = {
  messageSuccess: '',
  messageError: '',
  loading: false
}

export default (state = INITIAL_STATE, action: AnyAction) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.FORGOT_PASSWORD_REQUEST}_PENDING`: {
        draft.loading = true
        draft.messageError = ''
        draft.messageSuccess = ''

        break
      }

      case `${types.FORGOT_PASSWORD_REQUEST}_FULFILLED`:
        draft.loading = false
        draft.messageError = ''
        draft.messageSuccess = i18next.t(
          'auth:forgotPassword.form.alert.success'
        )
        break

      case `${types.FORGOT_PASSWORD_REQUEST}_REJECTED`:
        draft.loading = false
        draft.messageSuccess = ''
        draft.messageError = action.payload
        break

      default:
        return state
    }
  })
