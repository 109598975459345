import i18n from '~/i18n'

import { advertiser as service } from '~/modules/retailMedia/services/grocery'

import { types } from './constants'

const { t } = i18n

/**
 * Get all */
export const listGroceryAdvertisers = (queries = {}) => ({
  type: types.list,
  payload: service.list(queries),
  errorFeedbackMsg: t('rm:actions.advertiser.list.error')
})

/**
 * Sort list
 */
export const setSortListAdvertisers = (data: OnSortProps) => ({
  type: types.listSort,
  payload: data
})

/**
 * Handle page */
export const setPageGroceryAdvertisers = (value: number) => ({
  type: types.page,
  payload: value
})

/**
 * Handle items por page */
export const setPerPageGroceryAdvertisers = (value: number) => ({
  type: types.perPage,
  payload: value
})

/**
 * Get advertiser info
 */
export const getAdvertiser = ({
  id,
  params = {}
}: {
  id: string
  params: object
}) => ({
  type: types.getAdvertiser,
  payload: service.get({ id, params })
})
