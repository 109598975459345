import React from 'react'

import PropTypes from 'prop-types'

import { Wrapper } from './styles'

export const Icon = ({ icon: Icon }) => (
  <Wrapper className="wrapperIcon">
    {typeof Icon === 'string' ? Icon : <Icon />}
  </Wrapper>
)

Icon.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
}
