import { ChartOptions } from 'chart.js'

import clicksPreset from '../metrics/clicks'
import conversionsPreset from '../metrics/conversions'
import impressionsPreset from '../metrics/impressions'
import { ChartPreset } from '../types'

const datasets = ({ data }: Pick<MetricsChartConfigProps, 'data'>) =>
  [
    impressionsPreset.dataset({ data }),
    clicksPreset.dataset({ data }),
    conversionsPreset.dataset({ data })
  ] as ChartConfig[]

const chartOptions: ChartOptions<'line'> = {
  scales: {
    ...impressionsPreset.optionsScale({ drawOnChartArea: true }),
    ...clicksPreset.optionsScale({ drawOnChartArea: true }),
    ...conversionsPreset.optionsScale({ drawOnChartArea: true })
  }
}

export const simpleChartPreset: ChartPreset = ({ data }) => ({
  datasets: datasets({ data }),
  chartOptions
})
