import RechargeTransaction from './rechargeTransaction'
import Wallet from './wallet'
// import WalletRecharge from './walletRecharge'
import WalletTransaction from './WalletTransaction'

const WalletPages = {
  Wallet,
  WalletTransaction,
  // WalletRecharge,
  RechargeTransaction
}

export { WalletPages }
