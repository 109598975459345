import { types } from '../constants'

const index = {
  pending: `${types.countStatus}_PENDING`,
  fulfilled: `${types.countStatus}_FULFILLED`,
  rejected: `${types.countStatus}_REJECTED`
}

export const listCountStatus = {
  [index.pending]: ({ draft }) => {
    draft.grocery.campaign.listCountStatus.loading = true
    draft.grocery.campaign.listCountStatus.error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.grocery.campaign.listCountStatus.count = action.payload.count

    draft.grocery.campaign.listCountStatus.loading = false
    draft.grocery.campaign.listCountStatus.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.grocery.campaign.listCountStatus.loading = false
    draft.grocery.campaign.listCountStatus.error = action.payload
  }
}
