import React from 'react'
import { FaBarcode } from 'react-icons/fa'

import PropTypes from 'prop-types'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import ThumbProduct from '~/components/ThumbProduct'
import { formatDateToString } from '~/helpers'

import Indicators from '../Indicators'
import Categories from './Categories'
import modelProduct from './model'

import * as S from './styles'

const Product = ({ data, indicatorAtLast, onBarCodeClick }) => {
  const product = modelProduct(data)

  const handleOnBarCodeClick = () => {
    onBarCodeClick(product.gtin)
  }

  return (
    <S.Container>
      <S.Image>
        <ThumbProduct asset={product.asset} title={product.name} />
      </S.Image>

      <S.Infos>
        <div className="mainInfo">
          <S.Name>
            {product.name}{' '}
            <S.Tag title="unit_measurement e unit_weight">
              {product.unit_measurement}
              {product.unit_weight ? ` / ${product.unit_weight}` : ''}
            </S.Tag>
          </S.Name>
          <S.TagWrapper>
            {!product.active && (
              <S.Tag
                template="warning"
                title="Indica que o produto foi desativado pela integração."
                as="abbr"
              >
                Desativado
              </S.Tag>
            )}
            {!product.available && (
              <S.Tag
                template="warning"
                title="Indica que o produto não será exibido quando não estiver ativo, estiver há mais de 2 dias sem nenhuma atualização ou com o estoque inferior ao estoque mínimo."
                as="abbr"
              >
                Indisponível
              </S.Tag>
            )}
          </S.TagWrapper>
        </div>

        <div className="secondary">
          <S.InfoItem>{formatDateToString(product.indexedAt)}</S.InfoItem>

          {product.external_id ? (
            <S.InfoItem>ID: {product.external_id} </S.InfoItem>
          ) : (
            <AlertInfo text="ID externo não cadastrado" />
          )}

          <S.InfoItem>
            {product.gtin ? (
              <Button
                template="transparent"
                size="xsmall"
                text={`EAN: ${product.gtin}`}
                iconRight={<FaBarcode />}
                onClick={handleOnBarCodeClick}
              />
            ) : (
              <AlertInfo text="Sem GTIN cadastrado" />
            )}
          </S.InfoItem>
        </div>

        <div className="details">
          <S.InfoItem>
            {product.brand ? (
              `Marca: ${product.brand}`
            ) : (
              <AlertInfo text="Sem marca cadastrada" />
            )}
          </S.InfoItem>

          <S.InfoItem>
            {product.categories ? (
              <S.TagWrapper>
                Categorias: <Categories categories={product.categories} />
              </S.TagWrapper>
            ) : (
              <AlertInfo text="Sem categoria cadastrada" />
            )}
          </S.InfoItem>
        </div>
      </S.Infos>

      <Indicators product={product} indicatorAtLast={indicatorAtLast} />
    </S.Container>
  )
}

export default Product

Product.propTypes = {
  onBarCodeClick: PropTypes.func.isRequired,
  indicatorAtLast: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    account_id: PropTypes.string,
    location_id: PropTypes.string,
    store_id: PropTypes.string,
    external_id: PropTypes.string,
    product_id: PropTypes.string,
    name: PropTypes.string,
    brand: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string
    }),
    current_stock: PropTypes.number,
    minimum_stock: PropTypes.number,
    price: PropTypes.number,
    promotional_price: PropTypes.number,
    gtin: PropTypes.string,
    additional_gtins: [],
    ncm: PropTypes.shape({
      _id: PropTypes.string,
      code: PropTypes.string
    }),
    cest: PropTypes.shape({
      _id: PropTypes.string,
      code: PropTypes.string
    }),
    categories: [],
    tags: [],
    assets: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      ),
      large: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      ),
      medium: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      ),
      small: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      )
    }),
    attributes: PropTypes.arrayOf([]),
    package: PropTypes.string,
    unit_weight: PropTypes.number,
    unit_measurement: PropTypes.string,
    weight_per_unit: PropTypes.number,
    indexed_at: PropTypes.string,
    additional_gtin: PropTypes.arrayOf([]),
    asset: [
      PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string
      })
    ],
    category: PropTypes.arrayOf([]),
    stock: PropTypes.number
  }).isRequired
}
