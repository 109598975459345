import { useCallback, useMemo, useState } from 'react'
import { FiPlayCircle, FiUserX, FiXCircle } from 'react-icons/fi'
import { HiOutlineUserCircle } from 'react-icons/hi2'
import { useDispatch } from 'react-redux'

import { Button } from '~/components/Buttons'
import Tooltip from '~/components/Tooltip'
import { formatDate, formatDateToString, getRole, getUserId } from '~/helpers'
import useConfirmationModal from '~/hooks/useConfirmationModal'
import {
  agentTransfer,
  finishServiceChat,
  startService
} from '~/modules/chat/store/actions'
import { useAppSelector } from '~/store/hooks'

import { TransferChat } from '../TransferChat'
import * as S from './styled'

interface ModalInfo {
  title: string
  confirmationButton: string
  content: string
  onSubmit: () => void
}

export const AttendantActions = () => {
  const [modalInfo, setModalInfo] = useState<ModalInfo>(null)
  const dispatch = useDispatch()

  const [
    ConfirmationhModal,
    showConfirmationModal,
    toggleConfirmationModal,
    setIsShowing
  ] = useConfirmationModal()

  const userRole = getRole()
  const userId = getUserId()

  const { selectedChat, finishServiceLoading, selectedStatus } = useAppSelector(
    ({ chats }) => chats
  )

  const { session } = selectedChat

  const { isMobile } = useAppSelector(state => state.layout)

  const userIdLogged = getUserId()

  const isStartChat = useMemo(
    () => !!selectedChat?.session?.userId,
    // && !!selectedChat?.session?.agentStartedAt,
    [selectedChat]
  )

  const isUserOnStartChat = useMemo(
    () => selectedChat?.session?.userId === userIdLogged,
    [selectedChat, userIdLogged]
  )

  const handleStartService = useCallback(() => {
    dispatch(
      startService({
        sessionId: session?.sessionId
      })
    )
  }, [dispatch, session?.sessionId])

  const handleServiceChatStop = useCallback(() => {
    dispatch(
      finishServiceChat({
        sessionId: session?.sessionId,
        onSuccess: () => setIsShowing(false)
      })
    )
  }, [dispatch, session?.sessionId, setIsShowing])

  const handleUnbindChat = useCallback(() => {
    dispatch(
      agentTransfer({
        sessionId: session?.sessionId,
        data: { userId: null },
        values: null,
        successFeedbackMsg: 'Atendimento desvinculado',
        onSuccess: () => setIsShowing(false)
      })
    )
  }, [dispatch, session?.sessionId, setIsShowing])

  const isFinished = useMemo(() => !!session?.finishedAt, [session?.finishedAt])
  const hasUserId = useMemo(() => !!session?.userId, [session?.userId])

  const isSameUserId = useMemo(
    () => session?.userId === userId,
    [session?.userId, userId]
  )

  const isAdminUser = useMemo(() => userRole === 'admin', [userRole])

  const showBackToWaiting = useMemo(
    () =>
      !isFinished && isStartChat && hasUserId && (isSameUserId || isAdminUser),
    [hasUserId, isAdminUser, isFinished, isSameUserId, isStartChat]
  )

  /*
  isFinished false
  isUserOnStartChat false
  isAdminUser true
  isStartChat false
  */

  const showFinishChat = useMemo(
    () =>
      !isFinished &&
      ((isUserOnStartChat && isStartChat) ||
        (isAdminUser && isStartChat) ||
        (isUserOnStartChat && !isFinished) ||
        (isAdminUser && !isFinished && hasUserId)),
    [isFinished, isUserOnStartChat, isStartChat, isAdminUser, hasUserId]
  )

  const handleToggleConfirmationModal = useCallback(
    (type?: 'finish' | 'unbind') => {
      if (type === 'unbind') {
        setModalInfo({
          title: 'Voltar atendimento para fila',
          confirmationButton: 'Entendo e desejo voltar o atendimento',
          content:
            'Ao voltar um atendimento para a fila, o usuário atual será desvinculado e o atendimento voltará para a aba "aguardando atendimento".',
          onSubmit: handleUnbindChat
        })
      }

      if (type === 'finish') {
        setModalInfo({
          title: 'Finalizar atendimento',
          confirmationButton: 'Entendo e desejo finalizar o atendimento',
          content:
            'Ao finalizar o atendimento, essa conversa será arquivada. O atendimento poderá ser retomado enquanto a sessão do chat estiver ativa.',
          onSubmit: handleServiceChatStop
        })
      }

      toggleConfirmationModal()
    },
    [handleServiceChatStop, handleUnbindChat, toggleConfirmationModal]
  )

  const statusSelected = useMemo(
    () => selectedStatus === 'finished' || selectedStatus === 'available',
    [selectedStatus]
  )

  return (
    <S.Container className="AttendantActions">
      <S.SectionAttendant>
        <S.WrapperAttendant>
          {!!isFinished && (
            <S.Attendant>
              <small>
                Finalizado{' '}
                {formatDateToString(session.finishedAt, 'DD/MM/YY [às] HH:mm')}
                <br />
                por{' '}
                <span className="name">{session?.userName || 'usuário'}</span>
              </small>
            </S.Attendant>
          )}

          {(isAdminUser || !isSameUserId) && hasUserId && !isFinished && (
            <Tooltip
              icon={
                <S.Attendant>
                  <HiOutlineUserCircle />
                  {!session?.userName ? (
                    <>Sem atendente</>
                  ) : (
                    <>{session?.userName}</>
                  )}
                </S.Attendant>
              }
              text="Atendente responsável pela conversa"
              positionProp="right"
            />
          )}
        </S.WrapperAttendant>

        {!isFinished && isAdminUser && isStartChat && (
          <TransferChat
            textButton="Transferir"
            titlePopover="Transferir atendimento"
            textButtonNextStep="Transferir atendimento"
          />
        )}

        {((!hasUserId && !isStartChat) || isFinished) && (
          <Button
            iconLeft={<FiPlayCircle />}
            text={
              statusSelected && !!session?.userId
                ? 'Reabrir atendimento'
                : 'Iniciar atendimento'
            }
            template="success"
            size="small"
            onClick={handleStartService}
          />
        )}

        {isAdminUser && !isStartChat && (
          <TransferChat
            textButton="Vincular atendimento"
            titlePopover="Vincular atendimento"
            textButtonNextStep="Vincular atendimento"
          />
        )}
      </S.SectionAttendant>

      {showBackToWaiting && (
        <div>
          <Button
            iconLeft={<FiUserX />}
            text="Voltar atendimento para a fila"
            template="deleteOutline"
            size="xsmall"
            customWidth="auto"
            onClick={() => handleToggleConfirmationModal('unbind')}
          />
        </div>
      )}

      {showFinishChat && (
        <div className="finishButton">
          <Button
            iconLeft={<FiXCircle />}
            text="Finalizar atendimento"
            template="danger"
            size={isMobile ? 'xsmall' : 'small'}
            onClick={() => handleToggleConfirmationModal('finish')}
            textLoading="Carregando..."
            loading={finishServiceLoading}
            disabled={finishServiceLoading}
          />
        </div>
      )}

      <ConfirmationhModal
        title={modalInfo?.title}
        isOpen={showConfirmationModal}
        onClose={handleToggleConfirmationModal}
        onSubmit={modalInfo?.onSubmit}
        confirmationButton={modalInfo?.confirmationButton}
      >
        <p>{modalInfo?.content}</p>
      </ConfirmationhModal>
    </S.Container>
  )
}
