import React from 'react';
import { useSelector } from 'react-redux';

import useCheckout from '~/pages/orders/_hooks/useCheckout';

import PanelOpenValue from '../PanelOpenValue';
import PanelLinkCheckout from '../PanelLinkCheckout';

import Loading from '~/components/Loading';
import PanelStatusPending from '../PanelStatusPending';

const PanelCheckout = () => {
  const { orderData, getOrderLoading, updateLoading } = useSelector(
    state => state.order,
  );

  const { isOpenValue, hasPendingItems, showLinkCheckout } = useCheckout({
    order: orderData,
  });

  return (
    <>
      <Loading status={getOrderLoading}>
        Carregando informações do pedido
      </Loading>

      <Loading status={updateLoading}>
        Atualizando informações do pedido
      </Loading>

      {!getOrderLoading && !updateLoading && (
        <>
          {/* Painel para avisar que existe itens com status pendentes */}
          {hasPendingItems && <PanelStatusPending />}

          {/* Painel para avisar que o pedido(1º item) ainda com algum valor em abreto [+ detalhes no componente] */}
          {isOpenValue && <PanelOpenValue />}

          {/* Painel para exibir o link para o checkout */}
          {showLinkCheckout && <PanelLinkCheckout />}
        </>
      )}
    </>
  );
};

export default PanelCheckout;
