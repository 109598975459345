// import { Container } from './styles';

import { useMemo } from 'react'
import { FiGlobe } from 'react-icons/fi'

import { Badge } from '~/components/UI/Badge'
import useFetchSWR from '~/hooks/useFetchSWR'

const LastOrderByCustomer = ({ customerId }: { customerId: string }) => {
  const { data: dataSWR, error: errorSWR } = useFetchSWR({
    url: `${process.env.REACT_APP_HOST}/search/orders?customer_id=${customerId}&quantity=1`,
    shouldFetch: !!customerId
  })

  const lastOrder = useMemo(() => dataSWR?.data?.[0], [dataSWR])

  return lastOrder ? (
    <Badge
      as="a"
      href={`/order/${lastOrder.id}`}
      target="_blank"
      rel="noreferrer"
    >
      <div className="icon__left">
        <FiGlobe />
      </div>
      Último pedido: {lastOrder.order_id}
    </Badge>
  ) : (
    <></>
  )
}

export default LastOrderByCustomer
