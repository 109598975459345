import { useCallback, useMemo, useRef, useState } from 'react'
import { FiAlertCircle, FiEdit, FiInfo, FiX } from 'react-icons/fi'
import { NumberFormatValues } from 'react-number-format'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Form } from '@unform/web'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import Tooltip from '~/components/Tooltip'
import { InputMoney } from '~/components/unform'
import { formatMoney, getRetailMediaAccountType, isReadOnly } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import { useAppSelector } from '~/store/hooks'

import { useAdsTable } from '../_tables/AdsTable/hooks/useAdsTable'
import { cpcPatch } from '../../store/industry/ad/actions'

import * as S from './styles'

interface Props {
  data: AdsResultFormatted
  showMin?: boolean
  isExternatData?: boolean
}

export const EditCpc = ({ data: dataSchemaTable }: Props) => {
  const { handleMutate } = useAdsTable()

  const isPublisher = getRetailMediaAccountType() === 'publisher'

  const [isEdit, setIsEdit] = useState(false)
  // Local Loading para não colidor com outros elementos fazendo requisição
  // Ideal seria o loading do Redux aceitar ID do item editado
  const [localLoading, setLocalLoading] = useState(false)
  const [cpc, setCpc] = useState(0)

  const formRef = useRef(null)

  const dispatch = useDispatch()

  const {
    campaign: {
      item: { data: campaignData }
    },
    industry: {
      budget: {
        minCosts: { data: minCostsData }
      },
      ad: {
        cpcPatch: { loading }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  const { id } = useParams()

  /**
   * Checagem se o ID da URL é igual ao ID do Redux
   * Isso é bug. Ao entrarmos numa campanha e voltarmos para a listagem, os dados da campanha
   * seguem armazenado no redux e o componente entende que estamos dentro da campanha.
   */
  const idIsValid = useMemo(
    () => !!campaignData?.id && campaignData.id === id,
    [campaignData?.id, id]
  )

  const canEdit = useMemo(
    () =>
      !isPublisher &&
      campaignData &&
      !!minCostsData &&
      idIsValid &&
      !isReadOnly(),
    [idIsValid, campaignData, isPublisher, minCostsData]
  )

  const showMin = useMemo(
    () => campaignData && !!minCostsData && idIsValid,
    [campaignData, idIsValid, minCostsData]
  )

  const item = useMemo(() => {
    const response = minCostsData?.ads?.filter(
      item => item.id === dataSchemaTable.settings.product_id
    )

    return response?.length ? response : [minCostsData.global]
  }, [dataSchemaTable.settings.product_id, minCostsData])

  const isValidCpc = useMemo(
    () =>
      Number(dataSchemaTable?.settings?.cpc) ===
      Number(campaignData?.settings?.cpc),
    [campaignData?.settings?.cpc, dataSchemaTable?.settings?.cpc]
  )

  const showMinBadge = useMemo(() => {
    // checar se usa CPC global
    const adUsesGlobalCPC =
      !dataSchemaTable?.settings?.cpc || dataSchemaTable?.settings?.cpc === null

    const globalCPC = campaignData?.settings?.cpc || campaignData?.settings?.cpc
    const adCPC = dataSchemaTable?.settings?.cpc

    // checar se tem cpc mínimo para o anúncio
    const hasSelfCPC = !isNaN(item?.[0]?.min_cpc) && item?.[0]?.min_cpc > -1

    // setar quais valores devemos olhar para comparar
    const currentCPCValue = adUsesGlobalCPC ? globalCPC : adCPC
    const currentMinCPCValue = hasSelfCPC
      ? item[0].min_cpc
      : minCostsData?.global?.min_cpc

    return currentMinCPCValue > currentCPCValue
  }, [
    campaignData?.settings?.cpc,
    dataSchemaTable?.settings?.cpc,
    item,
    minCostsData?.global?.min_cpc
  ])

  const handleChangeValueInput = useCallback(
    (inputData: NumberFormatValues) => {
      const formatNumber = inputData.floatValue / 100
      setCpc(formatNumber)
    },
    []
  )

  const handleSuccess = useCallback(
    (data: AdsHandleMutateProps) => {
      handleMutate(data)
      setIsEdit(false)
      setLocalLoading(false)
    },
    [handleMutate]
  )

  const handleEditRequest = useCallback(
    (cpc: number | null) => {
      setLocalLoading(true)

      dispatch(
        cpcPatch({
          body: { cpc },
          id: dataSchemaTable.id,
          onSuccess: () =>
            handleSuccess({
              updatedKey: 'cpc',
              value: cpc,
              id: dataSchemaTable.id
            })
        })
      )
    },
    [dataSchemaTable.id, dispatch, handleSuccess]
  )

  const { lengthCentsCurrency } = useAppInfo()

  const handleSubmit = useCallback(
    (data: { budget: { numAsString: string } }) => {
      if (data.budget) {
        const amount = data.budget.numAsString
        const value = lengthCentsCurrency
          ? Number(amount) / 100
          : Number(amount)

        handleEditRequest(value)
      }
    },
    [handleEditRequest, lengthCentsCurrency]
  )

  const handleUseValueDefault = useCallback(() => {
    handleEditRequest(null)
  }, [handleEditRequest])

  const cpcValue = useMemo(() => {
    const adUsesGlobalCPC =
      !dataSchemaTable?.settings?.cpc || dataSchemaTable?.settings?.cpc === null

    const globalCPC =
      dataSchemaTable?.campaign_settings?.cpc ||
      campaignData?.settings?.cpc ||
      campaignData?.settings?.cpc

    const adCPC = dataSchemaTable?.settings?.cpc

    const currentCPCValue = adUsesGlobalCPC ? globalCPC : adCPC

    return currentCPCValue
  }, [campaignData?.settings?.cpc, dataSchemaTable])

  const initialData = useMemo(
    () => ({
      budget: Number(cpcValue)
    }),
    [cpcValue]
  )

  return (
    <Form initialData={initialData} onSubmit={handleSubmit} ref={formRef}>
      <>
        {!isEdit && (
          <>
            <S.Value>
              <p>{formatMoney(Number(cpcValue))}</p>

              {showMinBadge && !isPublisher && (
                <Tooltip
                  icon={<FiAlertCircle className="alert" />}
                  text={t('rm:components.editBudget.editCPC.belowMinimumValue')}
                  positionProp="center-top"
                />
              )}

              {!isPublisher && canEdit && (
                <Button
                  template="transparentPrimary"
                  onClick={() => setIsEdit(e => !e)}
                  text={<FiEdit />}
                  size="xsmall"
                />
              )}
            </S.Value>

            {showMin && (
              <S.SubtitleSmall isDanger={showMinBadge}>
                {t('rm:minimumValue')} {formatMoney(item?.[0]?.min_cpc)}
              </S.SubtitleSmall>
            )}

            {!!dataSchemaTable?.settings?.cpc &&
              !isValidCpc &&
              !isPublisher &&
              canEdit && (
                <S.ButtonCPCDefault>
                  <Button
                    template="transparentPrimary"
                    onClick={handleUseValueDefault}
                    text={t('rm:components.editBudget.editCPC.useDefaultCPC')}
                    size="xsmall"
                    textLoading={t('rm:saving')}
                    loading={localLoading}
                    disabled={localLoading}
                    customWidth="auto"
                  />
                </S.ButtonCPCDefault>
              )}
          </>
        )}
      </>

      {isEdit && canEdit && (
        <>
          <S.WrapperInput>
            <InputMoney
              id="budget"
              name="budget"
              onChange={handleChangeValueInput}
              htmlRightIcon={
                cpc < Number(item?.[0]?.min_cpc) && (
                  <Tooltip
                    icon={<FiInfo className="tooltip-icon" />}
                    text={t('rm:components.editBudget.aboveMinimumValue')}
                  />
                )
              }
              showBadgeIsvalid={cpc >= Number(item?.[0]?.min_cpc)}
              showBorderInvalid={cpc < Number(item?.[0]?.min_cpc)}
              loading={loading}
            />
            {showMin && (
              <S.SubtitleSmall isDanger={cpc < Number(item?.[0]?.min_cpc)}>
                {t('rm:minimumValue')} {formatMoney(item?.[0]?.min_cpc)}
              </S.SubtitleSmall>
            )}
          </S.WrapperInput>

          <S.WrapperButtons>
            {!localLoading && (
              <Button
                template="transparentDanger"
                onClick={() => setIsEdit(e => !e)}
                className="button-cancel"
                text={<FiX />}
                size="small"
                customWidth="32px"
              />
            )}

            <Button
              type="submit"
              template="success"
              disabled={localLoading}
              loading={localLoading}
              text={t('rm:button.Save')}
              textLoading={t('rm:saving')}
              size="small"
              customWidth="80px"
            />
          </S.WrapperButtons>
        </>
      )}
    </Form>
  )
}
