import React, { useCallback, useMemo } from 'react'
import { FiGlobe, FiTrash } from 'react-icons/fi'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'

import { useLogisticSlots } from '../../useLogisticSlots'

import * as S from './styles'

/**
 * Componente responsável por renderizar um dia sem slots na tela. Ele exibirá a data,
 * descrição da data, indicador de hierarquia (global/local) e um botão para excluir data.
 * @param {object} data
 * @returns React.Element
 */

const WithoutSlot = ({ data }) => {
  const {
    mode,

    deleteConfig
  } = useLogisticSlots()

  const day = useMemo(() => data.day, [data])
  const isGlobal = useMemo(() => data.isGlobal, [data])

  const handleDeleteWithouWorkDay = useCallback(() => {
    deleteConfig({ config: data, dayOnly: true })
  }, [data, deleteConfig])

  return (
    <S.Container hasitem template={mode} isGlobal={isGlobal}>
      <S.Header template={mode}>
        <div className="title">
          <div className="day">{day.label}</div>

          {data.description && (
            <span className="description">{data.description}</span>
          )}

          {isGlobal && (
            <div className="globalTag">
              <FiGlobe /> Configuração global
            </div>
          )}
        </div>

        <Button
          type="button"
          onClick={handleDeleteWithouWorkDay}
          size="small"
          template="transparentDanger"
          iconLeft={<FiTrash />}
          text="Apagar data"
          disabled={false}
          customWidth="auto"
        />
      </S.Header>
    </S.Container>
  )
}

export default WithoutSlot

WithoutSlot.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    slots: PropTypes.arrayOf(PropTypes.shape({})),
    isGlobal: PropTypes.bool,
    description: PropTypes.string,
    date: PropTypes.string,
    day: PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    })
  }).isRequired
}
