import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/advertiser-publisher`

export const listPublishers = (
  params: ListAdvertiserPublisherServiceRequestParams = null
) => axios.get(`${baseURL}/publishers`, { params })

export const listAdvertisersServiceKey = 'LIST_ADVERTISERS'

export const listAdvertisersService = (
  params: ListAdvertiserPublisherServiceRequestParams = null
) =>
  axios.get<ListAdvertisersServiceResponse>(`${baseURL}/advertisers`, {
    params
  })

// Editar vínculo
export const patchLinkPublisherAdvertiserService = ({
  advertiserId,
  body
}: AdvertiserPublisherLinkPatchServiceParams) =>
  axios.patch<AdvertiserPublisherLinkPatchLinkResponse>(
    `${baseURL}/${advertiserId}`,
    body
  )

export default {
  listPublishers,
  listAdvertisers: listAdvertisersService,
  listAdvertisersService
}
