import React from 'react';

import PropTypes from 'prop-types';

const ColumnStatus = ({ status }) => {
  return (
    <div className={`status__${status?.template}`}>
      <strong>Status: </strong>
      {status?.label || 'Sem status definido'}
    </div>
  );
};

export default ColumnStatus;

ColumnStatus.propTypes = {
  status: PropTypes.shape({
    template: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};
