import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import YupPassword from 'yup-password'

import { Button } from '~/components/Buttons'
import RulesList from '~/components/RulesList'
import { FormGroup, Label, InputPassword } from '~/components/unform'
import { getValidationErrors, history } from '~/helpers'
import { auth } from '~/routes/paths/auth'

import * as S from './styles'

const FormUpdatePassword = ({
  isLoading,
  onSubmit,
  token,
  onSuccessMessage
}) => {
  YupPassword(Yup)

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [ruleErrors, setRuleErrors] = useState([])

  const formRef = useRef(null)

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const rules = useMemo(
    () => ({
      length: t('auth:updatePassword.passwordRules.length'),
      minLowercase: t('auth:updatePassword.passwordRules.minLowercase'),
      minUppercase: t('auth:updatePassword.passwordRules.minUppercase'),
      minNumbers: t('auth:updatePassword.passwordRules.minNumbers'),
      minSymbols: t('auth:updatePassword.passwordRules.minSymbols'),
      isEqual: t('auth:updatePassword.passwordRules.isEqual')
    }),
    [t]
  )

  const passwordsIsEqual = useMemo(
    () => newPassword && newPassword === confirmPassword,
    [newPassword, confirmPassword]
  )

  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .password()
      .min(8, 'length')
      .minLowercase(1, 'minLowercase')
      .minUppercase(1, 'minUppercase')
      .minNumbers(1, 'minNumbers')
      .minSymbols(1, 'minSymbols')
      .required('required'),
    confirmPassword: Yup.string()
      .test('isEqual', 'isEqual', () => !!passwordsIsEqual)
      .required('confirmRequired')
  })

  const onSuccessSubmit = () => {
    toastr.success(
      t('common:capitalize', {
        value: t('common:words.success')
      }),
      onSuccessMessage
    )
    localStorage.removeItem('token')
    history.push('/')
  }

  const requestBody = useMemo(
    () => ({
      password: newPassword,
      token,
      onSuccess: onSuccessSubmit
    }),
    [newPassword, token]
  )

  const handleSubmitForm = useCallback(
    async data => {
      try {
        await schema.validate(data, { abortEarly: false })

        dispatch(onSubmit(requestBody))
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.confirm?.setErrors(errors)
          return toastr.error(
            'Erro',
            rules?.[err.inner?.[0]?.message] ||
              t('auth:updatePassword.form.passwordUpdateError')
          )
        }
      }
    },
    [dispatch, onSubmit, requestBody, rules, schema, t]
  )

  const checkRules = useCallback(async () => {
    const data = { newPassword, confirmPassword }

    const errors = passwordsIsEqual ? [] : ['isEqual']

    try {
      await schema.validate(data, { abortEarly: false })

      setRuleErrors([...errors])
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = err.inner.map(error => error.message)

        setRuleErrors([...errors, ...validationErrors])
      }
    }
  }, [confirmPassword, newPassword, passwordsIsEqual, schema])

  const handleChange = useCallback(({ value, name }) => {
    name === 'newPassword' && setNewPassword(value)

    name === 'confirmPassword' && setConfirmPassword(value)
  }, [])

  useEffect(() => {
    checkRules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPassword, newPassword])

  const readyToSubmit = useMemo(() => ruleErrors.length <= 0, [ruleErrors])

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={handleSubmitForm}>
        <FormGroup>
          <Label htmlFor="newPassword">
            {t('auth:updatePassword.form.newPassword.label')}
          </Label>

          <InputPassword name="newPassword" onChange={handleChange} />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="confirmPassword">
            {t('auth:updatePassword.form.confirmPassword.label')}
          </Label>

          <InputPassword name="confirmPassword" onChange={handleChange} />
        </FormGroup>

        <Button
          type="submit"
          text={t('auth:updatePassword.form.submitButton.text')}
          loading={isLoading}
          disabled={!readyToSubmit || isLoading}
          customWidth="100%"
        />
      </Form>

      <RulesList rules={rules} ruleErrors={ruleErrors} />
    </S.Container>
  )
}

export default FormUpdatePassword

FormUpdatePassword.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  onSuccessMessage: PropTypes.string.isRequired
}
