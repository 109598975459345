import moment from 'moment-timezone'

export const model = data => {
  const { start_date, start_hour, scheduleMode = 'sendLater' } = data

  const selectedDate =
    start_date && start_hour && scheduleMode === 'sendLater'
      ? `${start_date} ${start_hour}`
      : undefined

  const schedule_date = moment(selectedDate).utc().format()

  const destinations = data.destinations
    .toString()
    .replace(/([A-z]+)/g, '')
    .replace(/\+55/g, '')
    .split(/(,|;|:|\n)+/)
    .filter(item => !!item.trim() && ![',', ';', ':'].includes(item))
    .map(item => ({ customer_number: item.trim() }))

  const campaign = {
    name: data.name,
    description: data.description,
    template_id: data.template_id,
    destinations,
    media: data?.messages?.[0]?.media,
    schedule_date,
    expire_at: new Date(`${data.end_date} ${data.end_hour}`)
  }

  const repalceBRtoN = string => string.replace(/<br ?\/?>/gi, '\n')

  const formatCallbackButtons = buttons => {
    const formattedBoby = Object.entries(buttons)
      .filter(item => !!item[1].checked)
      .map(item => ({ text: item[1].value }))
    return formattedBoby
  }

  if (data.callbacks) {
    const formattedCallbacks = data.callbacks.map(item => ({
      ...item,
      message: item?.message ? repalceBRtoN(item.message) : null,
      buttons: item.buttons ? formatCallbackButtons(item.buttons) : null
    }))

    campaign.callbacks = formattedCallbacks
  }

  if (!campaign.media?.url) {
    delete campaign.media
  }

  if (campaign?.callbacks?.[0]) {
    delete campaign.callbacks[0].mediaFile
  }

  if (campaign.callbacks?.[0].media && !campaign.callbacks[0].media?.url) {
    delete campaign.callbacks[0].media
  }

  return campaign
}
