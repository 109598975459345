import { templateService } from '~/services/notification/templates'
import store from '~/store'

import * as types from './constants'

/**
 *
 * @exports listTemplates Get templates available to the logged in user
 * @param {object} queryString Object with options to filter
 *
 * @exports createTemplate
 *
 * @exports updateTemplate
 * @param {object} data Data object with new data
 * @param {string} successFeedbackMsg Message for the success toast
 *
 * @exports transparentDanger
 *
 */

/**
 * Get active chats
 * @param {object} queryString Object with options to filter
 */
export const listTemplates = () => ({
  type: types.LIST_TEMPLATES,
  payload: templateService.list(),
  getErrorFeedbackMsg: error =>
    error.response?.data?.error?.message ||
    'Houve um erro ao carregar as mensagens.'
})

/**
 * Create chat template
 */

const createTemplateOptimistic = data => ({
  type: types.CREATE_TEMPLATES_OPTIMISTIC,
  payload: data
})

export const createTemplate = data => {
  const { dispatch } = store

  dispatch(createTemplateOptimistic(data))

  return {
    type: types.CREATE_TEMPLATES,
    payload: templateService.create(data),
    successFeedbackMsg: 'Mensagem criada com sucesso.',
    getErrorFeedbackMsg: error =>
      error.response?.data?.error?.message ||
      'Houve um erro ao criar a mensagem.'
  }
}

/**
 * Update chat template
 */

const updatedTemplateOptimistic = data => ({
  type: types.UPDATE_TEMPLATE_OPTIMISTIC,
  payload: data
})

export const updateTemplate = data => {
  const { dispatch } = store

  dispatch(updatedTemplateOptimistic(data))

  return {
    type: types.UPDATE_TEMPLATE,
    payload: templateService.update(data),
    successFeedbackMsg: 'Mensagem editada com sucesso.',
    getErrorFeedbackMsg: error =>
      error.response?.data?.error?.message ||
      'Houve um erro ao editar a mensagem.'
  }
}

/**
 * Delete chat template
 */

const transparentDangerOptimistic = templateId => ({
  type: types.DELETE_TEMPLATE_OPTIMISTIC,
  payload: templateId
})

export const transparentDanger = templateId => {
  const { dispatch } = store

  dispatch(transparentDangerOptimistic(templateId))

  return {
    type: types.DELETE_TEMPLATE,
    payload: templateService.delete(templateId),
    successFeedbackMsg: 'Mensagem deletada com sucesso.',
    getErrorFeedbackMsg: error =>
      error.response?.data?.error?.message ||
      'Houve um erro ao deletar a mensagem.'
  }
}
