import React from 'react';

import Panel from '~/components/Panel';
import { FormGroup, InputMask, Label } from '~/components/unform';

const HoursFields = () => (
  <fieldset>
    <Panel
      title="Horários de funcionamento"
      className="form-grid"
      isCollapsible
    >
      <FormGroup className="span2">
        <Label htmlFor="hour_start" isRequired>
          Horário de abertura
        </Label>
        <InputMask
          name="hour_start"
          format="##:##"
          mask="_"
          placeholder="Insira um horário de abertura"
          required
        />
      </FormGroup>

      <FormGroup className="span2" isRequired>
        <Label htmlFor="hour_end">Horário de fechamento</Label>
        <InputMask
          name="hour_end"
          format="##:##"
          mask="_"
          placeholder="Insira um horário de fechamento"
          required
        />
      </FormGroup>
    </Panel>
  </fieldset>
);

export default HoursFields;
