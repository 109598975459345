import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

const TEMPLATE_STATUS = {
  in_review: 'Em análise',
  approved: 'Aprovado',
  denied: 'Negado',
};

const Status = ({ data }) => {
  const statusType = useMemo(() => {
    if (data === 'denied') {
      return 'canceled ';
    }

    if (data === 'in_review') {
      return 'pending';
    }

    if (data === 'approved') {
      return 'success';
    }
  }, [data]);

  return (
    <div className={`status__${statusType}`}>
      {TEMPLATE_STATUS[data] || 'Sem status definido'}
    </div>
  );
};

Status.defaultProps = {
  data: '',
};

Status.propTypes = {
  data: PropTypes.string,
};

export default Status;
