import axios from 'axios';

/**
 *
 * @endpoint /search/products
 * @exports getProductsList  Busca todos os produtos da loja
 * @param {string} queryString ?query=<campo de busca>&page=<opcional>&quantity=<tam da página, opcional>
 *
 * @endpoint /api/catalog
 * @exports postFormProduct Criação de produto
 * @param {object} data  Dados para criação do produto
 *
 * @exports getProductData Busca as informações de um produto
 * @param {string} object.id ID do produto
 *
 * @exports updateProductData Atualiza o produto
 * @param {object} data Dados para atualizar o produtos
 *
 * @exports deleteProduct Delete produto
 * @param {string} id Gerencia a paginação
 *
 */

export const getProductsList = queryString =>
  axios.get(`${process.env.REACT_APP_HOST}/search/products?${queryString}`);

export const postFormProduct = data =>
  axios.post(`${process.env.REACT_APP_HOST}/api/catalog/products/`, data);

export const getProductData = ({ id }) =>
  axios.get(`${process.env.REACT_APP_HOST}/api/catalog/v1/offers/${id}`);

export const updateProductData = data =>
  axios.put(`${process.env.REACT_APP_HOST}/api/catalog/products/`, data);

export const deleteProductService = id =>
  axios.delete(`${process.env.REACT_APP_HOST}/api/catalog/products/${id}`);
