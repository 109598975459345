import * as types from '../constants';

const index = {
  pending: `${types.GET_CUSTOMER}_PENDING`,
  fulfilled: `${types.GET_CUSTOMER}_FULFILLED`,
  rejected: `${types.GET_CUSTOMER}_REJECTED`,
};

export const getCustomer = {
  [index.pending]: ({ draft }) => {
    draft.getCustomersLoading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.customer = action.payload;
    draft.getCustomersLoading = false;
  },
  [index.rejected]: ({ draft }) => {
    draft.getCustomersLoading = false;
  },
};
