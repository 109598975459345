import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { ButtonNavLink } from '~/components/Buttons'

import useCheckout from '../../_hooks/useCheckout'

const ColumnStatus = ({ data, hideStatus = false }) => {
  const { id, status, orderStatus } = data

  const statusType = useMemo(
    () => status?.[0]?.type || orderStatus?.[0]?.type,
    [status, orderStatus]
  )

  const statusLabel = useMemo(
    () => status?.[0]?.label || orderStatus?.[0]?.label,
    [status, orderStatus]
  )

  const { isOpenValue, showLinkCheckout, urlCheckout } = useCheckout({
    order: data
  })

  return (
    <div className={`status__${statusType}`}>
      {!hideStatus && (
        <>
          {statusLabel || 'Sem status definido'}
          <br />
        </>
      )}

      {isOpenValue && (
        <ButtonNavLink
          to={`/order/${id}`}
          template="warningOutlined"
          text="Atualizar valor"
          customWidth="auto"
          size="small"
        />
      )}

      {showLinkCheckout && urlCheckout && (
        <a
          href={urlCheckout}
          target="_blank"
          title="Link para o checkout deste pedido"
          rel="noreferrer"
        >
          Link checkout
        </a>
      )}
    </div>
  )
}

export default ColumnStatus

ColumnStatus.propTypes = {
  hideStatus: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    total_price: PropTypes.number,
    status: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string
      })
    ),
    orderStatus: PropTypes.shape({}),
    payments: PropTypes.arrayOf(PropTypes.shape({})),
    items: PropTypes.arrayOf(PropTypes.shape({}))
  })
}

ColumnStatus.defaultProps = {
  hideStatus: false,
  data: [
    {
      status: [],
      orderStatus: [],
      payments: [],
      items: [],
      id: null,
      total_price: null
    }
  ]
}
