import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FiCheck, FiPlusSquare } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import debounce from 'lodash/debounce'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { setQuery } from '~/store/modules/filters/actions'

import { Input } from '../'

import * as S from './styles'

export const AutocompleteInput = ({
  name,
  placeholder = null,
  selectedTags,
  tags,
  onCreate,
  onFocus,
  keyState = 'query.generic',
  loading = false,
  showQuantityAlert = false,
  total,
  perPage
}: AutocompleteInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const [showAutocomplete, setShowAutocomplete] = useState(false)

  const { t, tCapitalize } = useAPPTranslation()

  const dispatch = useDispatch()

  const inputRef = useRef(null)

  const executeDebounceFunc = func => func()
  const handleDebounce = useRef(debounce(executeDebounceFunc, 500)).current

  const handleInputFocus = useCallback(() => {
    if (onFocus) {
      onFocus()
    }

    setShowAutocomplete(true)
  }, [onFocus])

  const handleInputChange = useCallback(
    ({ value }) => {
      handleDebounce(() => {
        setInputValue(value)
        dispatch(setQuery({ keyState, query: { [`${keyState}`]: value } }))
      })
    },
    [dispatch, keyState]
  )

  useEffect(() => {
    dispatch(setQuery({ keyState: '', query: { [`${keyState}`]: null } }))
  }, [dispatch, keyState])

  const handleSelectInput = useCallback(
    (filteredTag: any) => {
      onCreate(filteredTag)
    },
    [onCreate]
  )

  const filteredTags = useMemo(() => {
    const filterTags = tags.map(tag => ({
      ...tag,
      isSelected: selectedTags.some(selectedTag => selectedTag.id === tag.id)
    }))

    return filterTags
  }, [selectedTags, tags])

  const highlightText = useCallback(
    (searchText: string, suggestion: string) => {
      const regex = new RegExp(searchText, 'gi')
      const highlightedText = suggestion.replace(regex, match => {
        return `<strong class="highlight" >${match}</strong>`
      })

      return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
    },
    []
  )

  const handleClick = useCallback(
    ({ e, tag }) => {
      e.preventDefault()

      handleSelectInput(tag)

      inputRef?.current.focus()
    },
    [handleSelectInput, inputRef]
  )

  return (
    <S.Wrapper>
      <S.InputContainer>
        <S.WrapperInput showAutocomplete={inputValue && showAutocomplete}>
          <p>{t('c:components.AutocompleteInput.helperText')}</p>

          <Input
            name={name}
            onChange={handleInputChange}
            placeholder={placeholder}
            onFocus={handleInputFocus}
            autocomplete="off"
            type="search"
            autoFocus
          />

          {showAutocomplete && (
            <S.FilteredTagsWrapper
              className="filtered"
              tabIndex={0}
              ref={inputRef}
            >
              <>
                {!loading && showQuantityAlert && total > perPage && (
                  <AlertInfo
                    template="info"
                    text={t(
                      'c:components.AutocompleteInput.showQuantityAlert',
                      { perPage, total }
                    )}
                  />
                )}

                <Loading status={loading} />

                {!loading && tags.length === 0 && (
                  <p>{t('c:messages.noResultsFound')}</p>
                )}

                {!loading && tags.length > 0 && filteredTags.length === 0 && (
                  <p>
                    {t('c:components.AutocompleteInput.selectedAllCategories', {
                      inputValue
                    })}
                  </p>
                )}

                {!loading &&
                  filteredTags.map(filteredTag => (
                    <S.TagButton
                      type="button"
                      tabIndex={-1}
                      key={filteredTag.id}
                      onClick={e => handleClick({ e, tag: filteredTag })}
                      disabled={filteredTag.isSelected}
                      isSelected={filteredTag.isSelected}
                    >
                      <span className="text">
                        {inputValue
                          ? highlightText(inputValue, filteredTag.label)
                          : filteredTag.label}
                      </span>

                      <Button
                        as="span"
                        template={
                          filteredTag.isSelected
                            ? 'transparentPrimary'
                            : 'transparent'
                        }
                        text={
                          filteredTag.isSelected
                            ? tCapitalize('c:words.added')
                            : t('c:actions.add')
                        }
                        iconLeft={
                          filteredTag.isSelected ? (
                            <FiCheck />
                          ) : (
                            <FiPlusSquare />
                          )
                        }
                        size="xsmall"
                      />
                    </S.TagButton>
                  ))}

                {!loading && showQuantityAlert && total > perPage && (
                  <AlertInfo
                    template="info"
                    text={t(
                      'c:components.AutocompleteInput.showQuantityAlert',
                      { perPage, total }
                    )}
                  />
                )}
              </>
            </S.FilteredTagsWrapper>
          )}
        </S.WrapperInput>
      </S.InputContainer>
    </S.Wrapper>
  )
}
