import React from 'react'
import { Helmet } from 'react-helmet-async'

/**
 *
 * SEO Component
 * @example
 * <PageTitle title="Adicionar local" />
 *
 * @param {String} title Recebe o texto que será anexado ao título<title> da página
 */

interface PageTitleProps {
  title: string
}

const PageTitle = ({ title }: PageTitleProps) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
)

export default PageTitle
