import { useCallback, useMemo, useRef, useState } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { FiHelpCircle } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { Form } from '@unform/web'
import { t } from 'i18next'
import moment from 'moment'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { LogoStore } from '~/components/LogoStore'
import Panel from '~/components/Panel'
import Status from '~/components/Status'
import Tooltip from '~/components/Tooltip'
import { FormGroup } from '~/components/unform'
import NameAndTimeInterval from '~/components/unform/FormGroup/NameAndTimeInterval'
import {
  formatDateToStringIgnoreUTC,
  getAccountType,
  getDurationInDays,
  joinWithCommasAndAnd
} from '~/helpers'
import { useAppSelector } from '~/store/hooks'

import { getLabelAdType } from '../../dtos/common/campaign/format'
import { patch } from '../../store/industry/campaign/actions'
import AlertBrands from '../AlertBrands'
import EditButton from '../EditButton'
import ReactivateButton from '../ReactivateButton'
import { statusType } from '../StatusBar/statusType'

import * as S from './styles'

export const InfoView = ({ data }: { data: IndustryCampaignView }) => {
  const {
    industry: {
      publisherConfiguration: {
        list: { data: configurationData }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  const [isEditMode, setIsEditMode] = useState(false)

  const formRef = useRef(null)

  const dispatch = useDispatch()

  const loadingSave = false

  const isGrocery = getAccountType() === 'grocery'

  const canEdit = useMemo(() => data?.canEditCampaign, [data?.canEditCampaign])

  const startDateIsPast = useMemo(
    () => moment().utc().isSameOrAfter(data?.raw?.start_at, 'day'),
    [data]
  )

  const initialData: FormNameAndTimeIntervalData = useMemo(
    () => ({
      name: data?.title,
      description: data?.description,
      start_date: formatDateToStringIgnoreUTC(
        data?.raw?.start_at,
        'YYYY-MM-DD'
      ),
      end_date: data?.raw?.end_at
        ? formatDateToStringIgnoreUTC(data?.raw?.end_at, 'YYYY-MM-DD')
        : null,
      hasFinalDate: !data?.raw?.end_at,
      duration: data?.raw?.end_at
        ? getDurationInDays({
            startDate: data?.raw?.start_at,
            endDate: data?.raw?.end_at
          })
        : null
    }),
    [data]
  )

  /**
   * Handle submit
   */

  const handleSubmit = useCallback(
    (formData: FormNameAndTimeIntervalData) => {
      const body = {
        start_at: formData.start_date,
        end_at: formData?.end_date || null,
        name: formData.name,
        description: formData?.description
      }

      dispatch(
        patch({
          body,
          id: data?.id,
          successFeedbackMsg: t('rm:campaign.successfully.updated'),
          onSuccess: () => setIsEditMode(false)
        })
      )
    },
    [data, dispatch]
  )

  /** Handle brands */
  const publisherBrands = useMemo(
    () => configurationData?.configuration?.brands,
    [configurationData]
  )

  return (
    <Panel
      title={t('rm:generalInformation')}
      template={isEditMode ? 'borderWarning' : 'default'}
      headerSideContent={
        !isGrocery && (
          <>
            {canEdit && (
              <EditButton
                onClick={() => setIsEditMode((e: boolean) => !e)}
                isActive={isEditMode}
              />
            )}

            {data?.status === 'finished' && (
              <ReactivateButton
                onClick={() => setIsEditMode((e: boolean) => !e)}
                isActive={isEditMode}
              />
            )}
          </>
        )
      }
    >
      <S.RowWrapper className="hasBorder">
        <S.GroupWrapper>
          <S.Wrapper>
            {isGrocery ? (
              <div className="wrapper-publisher">
                <div className="logo-store">
                  <LogoStore
                    backgroundLogo={
                      data?.advertiser?.account_theme?.header_color
                    }
                    alt={data?.advertiser?.name}
                    linkLogo={data?.advertiser?.logo}
                  />
                </div>
                <div className="wrapper-publisher_name">
                  <strong>{t('rm:Advertiser')}</strong>
                  <p>{data?.advertiser?.name}</p>
                </div>
              </div>
            ) : (
              <div className="wrapper-publisher">
                <div className="logo-store">
                  <LogoStore
                    backgroundLogo={
                      data?.publisher?.account_theme?.header_color
                    }
                    alt={data?.publisher?.name}
                    linkLogo={data?.publisher?.logo}
                  />
                </div>
                <div className="wrapper-publisher_name">
                  <strong>Publisher</strong>
                  <p>{data?.publisher?.name}</p>
                </div>
              </div>
            )}
          </S.Wrapper>
          {/* Precisa ter um loading melhor pra essa seção para não usar validação direta no dado */}
          {!!publisherBrands?.length && data?.publisher?.name && (
            <S.Wrapper>
              <S.Text>
                {t('rm:adTags')}{' '}
                <Tooltip
                  text={t('rm:adTags.tooltip.description', {
                    name: data.publisher.name
                  })}
                  icon={<FiHelpCircle />}
                />
              </S.Text>
              <p>
                {joinWithCommasAndAnd({
                  values: publisherBrands.map(({ name }) => name)
                })}
              </p>
            </S.Wrapper>
          )}

          <S.Wrapper>
            <strong>{t('rm:campaignType')}</strong>
            <p>{getLabelAdType(data?.adType)}</p>
          </S.Wrapper>

          <S.Wrapper>
            <strong>{t('rm:campaignTitle')}</strong>
            <p>{data?.title}</p>
          </S.Wrapper>
        </S.GroupWrapper>
      </S.RowWrapper>

      {!isEditMode && (
        <>
          <S.RowWrapper className="hasBorder">
            <S.GroupWrapper>
              <S.Wrapper>
                <strong>Status</strong>
                <Status
                  status={{
                    label: statusType[data?.status]?.label || data?.status,
                    type: statusType[data?.status]?.color || 'default'
                  }}
                />
              </S.Wrapper>

              <S.Wrapper>
                <strong>{t('rm:startDate')}</strong>

                <p className="dateLabel">
                  {data?.startDateWithHours || t('rm:startDate')}
                </p>
              </S.Wrapper>

              <S.Wrapper>
                <strong>{t('rm:endDate')}</strong>
                <p className="dateLabel">
                  {data?.endDateWithHours || t('rm:noEndDateDefined')}
                </p>
              </S.Wrapper>

              {!!data?.duration && (
                <S.Wrapper>
                  <strong>{t('rm:duration')}</strong>
                  <p>
                    {data?.duration}
                    {data?.remaining && ` - ${data?.remaining}`}
                  </p>
                </S.Wrapper>
              )}
            </S.GroupWrapper>
          </S.RowWrapper>

          {data?.description && (
            <S.RowWrapper className="hasBorder">
              <S.GroupWrapper>
                <S.Wrapper>
                  <strong>{t('rm:description')}</strong>
                  <p>{data.description}</p>
                </S.Wrapper>
              </S.GroupWrapper>
            </S.RowWrapper>
          )}
        </>
      )}

      {isEditMode && (
        <S.RowWrapper className="hasBorder">
          <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <NameAndTimeInterval
              formRef={formRef}
              labelTitle={t(
                'rm:campaign.create.NameAndTimeInterval.labelTitle'
              )}
              placeholderTitle={t(
                'rm:campaign.create.NameAndTimeInterval.placeholderTitle'
              )}
              hasDescription
              labelDescription={t(
                'rm:campaign.create.NameAndTimeInterval.labelDescription'
              )}
              placeholderDescription={t(
                'rm:campaign.create.NameAndTimeInterval.placeholderDescription'
              )}
              labelInterval={t(
                'rm:campaign.create.NameAndTimeInterval.labelInterval'
              )}
              onlyDates
              endDateIsRequired={false}
              showValidBadgeOnSection
              intervalAlertMessage={[
                t('rm:campaign.create.NameAndTimeInterval.intervalAlertMessage')
              ]}
              showEndDateOptional
              containerElement="section"
              isEditMode
              startDateReadOnly={startDateIsPast}
              initialData={initialData}
              autoFocus={data.status === 'finished' ? 'end_date' : 'name'}
            />

            <FormGroup className="actionButtons form-default__buttons-pb0">
              <Loading status={loadingSave}>{t('rm:savingChanges')}</Loading>

              {!loadingSave && (
                <>
                  <Button
                    template="transparentDanger"
                    onClick={() => setIsEditMode(false)}
                    text={t('rm:button.Cancel')}
                    loading={loadingSave}
                  />

                  <Button
                    type="submit"
                    template="success"
                    loading={loadingSave}
                    disabled={loadingSave}
                    text={t('rm:button.Save')}
                    textLoading={t('rm:saving')}
                  />
                </>
              )}
            </FormGroup>
          </Form>
        </S.RowWrapper>
      )}
    </Panel>
  )
}
