const prefix = '@retailMedia/publisher'

export const PUBLISHERS_LIST = `${prefix}/LIST`
export const ADS_LIST_SORT = `${prefix}/LIST_SORT`
export const PUBLISHERS_LIST_MINIFIED = `${prefix}/LIST_MINIFIED`
export const PUBLISHERS_LIST_QUERY = `${prefix}/LIST_QUERY`
export const PUBLISHERS_LIST_SET_PAGE = `${prefix}/LIST_SET_PAGE`
export const PUBLISHERS_LIST_PER_PAGE = `${prefix}/LIST_PER_PAGE`

export const PUBLISHERS_INFO = `${prefix}/INFO`

export const types = {
  list: PUBLISHERS_LIST,
  listSort: ADS_LIST_SORT,
  page: PUBLISHERS_LIST_SET_PAGE,
  perPage: PUBLISHERS_LIST_PER_PAGE,
  getPublisher: PUBLISHERS_INFO
}
