import React from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { FaPlay, FaPause } from 'react-icons/fa';

import PropTypes from 'prop-types';

const Audio = ({ data }) => {
  const customIcons = {
    play: <FaPlay />,
    pause: <FaPause />,
  };

  return (
    <div className="audio">
      <AudioPlayer
        src={data}
        showJumpControls={false}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        layout="horizontal-reverse"
        customIcons={customIcons}
        customProgressBarSection={[
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.CURRENT_LEFT_TIME,
        ]}
      />
    </div>
  );
};

export default Audio;

Audio.propTypes = {
  data: PropTypes.string.isRequired,
};
