import axios from 'axios';

/**
 *
 * @endpoint /api/operator
 *
 * @exports listOperatorService Busca todos os operadores da empresa ou loja
 *
 * @exports showOperatorService Busca as informações de um operador
 * @param {string} id ID do operador
 *
 * @exports createOperatorService Criação de operador
 * @param {object} data  Dados para criação do operador
 *
 * @exports updateOperatorService Atualiza um operador
 * @param {object} data Dados para atualizar um operador
 *
 * @exports deleteOperatorService Deletar um operador
 * @param {string} id Id do operador que será deletado
 *
 */

export const operatorApiBaseUrl = `${process.env.REACT_APP_HOST}/api/operator`;

export const listOperatorsUrl = queryString =>
  `${operatorApiBaseUrl}?${queryString}`;

export const listOperatorsService = queryString =>
  axios.get(listOperatorsUrl(queryString));

export const showOperatorService = id =>
  axios.get(`${operatorApiBaseUrl}/${id}`);

export const createOperatorService = data =>
  axios.post(`${operatorApiBaseUrl}/`, data);

export const updateOperatorService = data =>
  axios.put(`${operatorApiBaseUrl}/`, data);

export const deleteOperatorService = id =>
  axios.delete(`${operatorApiBaseUrl}/${id}`);
