/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import Panel from '~/components/Panel'
import { FormGroup, Label } from '~/components/unform'
import MessageWithMediaAndPreview from '~/components/unform/FormGroup/MessageWithMediaAndPreview'
import Select from '~/components/unform/ReactSelect'
import WhatsAppMessagePreview from '~/components/WhatsAppMessagePreview'
import {
  formattedMessageToHtml,
  getFileType,
  handleUploadProgressAxios
} from '~/helpers'
import TemplateOption from '~/modules/messagingCampaigns/components/TemplateOption'
import useMsgCampaigns from '~/modules/messagingCampaigns/hooks/useMsgCampaigns'
import campaignActions from '~/modules/messagingCampaigns/store/campaign/actions'
import templateActions from '~/modules/messagingCampaigns/store/template/actions'

import * as S from './styles'

const scopePathMessage = 'messages[0]'

const Template = ({ initialData, onChange, initialTemplate, formRef }) => {
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [previewMedia, setPreviewMedia] = useState(null)
  const [template, setTemplate] = useState(null)

  const dispatch = useDispatch()

  const { onlyApprovedTemplates, allowMediaOnTemplate } = useMsgCampaigns()

  const isOriginalTemplateImage = useMemo(
    () => template?.media?.url === previewMedia?.url,
    [template, previewMedia]
  )

  const mediaTypeAllowed = useMemo(
    () =>
      template?.media?.type === 'document' ? 'application/pdf' : 'image/*',
    [template]
  )

  const labelMedia = useMemo(
    () =>
      template?.media?.type === 'document'
        ? 'Enviar arquivo PDF'
        : 'Enviar imagem',
    [template]
  )

  const {
    template: {
      list: { data, loading }
    }
  } = useSelector(state => state.messagingCampaigns)

  const options = useMemo(() => {
    if (!data || !data.length) return []

    const formattedData = data?.map(item => ({
      status: item.status,
      label: item.title,
      value: item._id,
      hasImage: item?.media?.type === 'image',
      hasDocument: item?.media?.type === 'document',
      media: item.media,
      buttons: item.messages?.[0]?.buttons,
      message: item.messages?.[0]?.text,
      mediaType: item?.media?.type,
      mediaUrl: item?.media?.url,
      mediaName: item?.media?.name,
      isDisabled: onlyApprovedTemplates ? !(item.status === 'approved') : false
    }))

    return formattedData
  }, [data, onlyApprovedTemplates])

  const handleChange = useCallback(
    selectedTemplate => {
      setTemplate(selectedTemplate)
      onChange(selectedTemplate)

      if (!selectedTemplate?.media) {
        setPreviewMedia(null)
      }

      if (selectedTemplate.media) {
        const media = data.find(
          item => item._id === selectedTemplate.value
        )?.media

        setPreviewMedia(media)

        formRef?.current?.setFieldValue(
          `${scopePathMessage}.media.type`,
          selectedTemplate.mediaType
        )
        formRef?.current?.setFieldValue(
          `${scopePathMessage}.media.url`,
          selectedTemplate.mediaUrl
        )
        formRef?.current?.setFieldValue(
          `${scopePathMessage}.media.name`,
          selectedTemplate.mediaName
        )
      }
    },
    [data, formRef, onChange]
  )

  useEffect(() => {
    const initialTemplate = options.find(
      option => option.value === initialData?.template_id
    )

    if (!template && initialTemplate) {
      handleChange(initialTemplate)
    }
  }, [handleChange, initialData, options, template])

  useEffect(() => {
    if (initialData?.media) {
      setPreviewMedia(initialData?.media)
    }
  }, [initialData])

  useEffect(() => {
    dispatch(
      templateActions.list({
        count: false
      })
    )
  }, [dispatch])

  const defaultValue = useMemo(
    () =>
      initialTemplate
        ? options.filter(item => item.value === initialTemplate)
        : null,
    [initialTemplate, options]
  )

  useEffect(() => {
    if (defaultValue && !initialTemplate) {
      onChange(defaultValue)
    }
  }, [defaultValue, onChange])

  /**
   * Template image
   */

  const templateHasMedia = useMemo(() => template?.media, [template])

  const handlePreviewMedia = event => {
    setPreviewMedia(null)

    const file = event.target.files?.[0]

    if (!file) return

    const fileType = getFileType(file)

    const isFile = fileType === 'document'
    const isImage = fileType === 'image'

    if (!isFile && !isImage) {
      toastr.error('Erro', `Ainda não suportamos esse formato de arquivo.`)
    }

    const maxSizeInMBFile = 100
    const maxSizeInMBPicture = 5
    const maxSizeInMB = isFile ? maxSizeInMBFile : maxSizeInMBPicture
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024

    if (file.size > maxSizeInBytes) {
      const errorSizeMessages = {
        application: `O tamanho máximo permitido do arquivo é de ${maxSizeInMBFile}mb`,
        image: `O tamanho máximo permitido da imagem é de ${maxSizeInMBPicture}mb`
      }

      const errorMsg = errorSizeMessages[fileType]
      toastr.error('Erro', errorMsg)
      return
    }

    const previewURL = URL.createObjectURL(file)

    setPreviewMedia({ type: fileType, url: previewURL, name: file.name })
  }

  const handleUpload = useCallback(
    async event => {
      setLoadingUpload(true)

      if (templateHasMedia) {
        handlePreviewMedia(event)
      }

      const body = new FormData()

      try {
        const [file] = event.target.files
        body.append('file', file)

        const fileType = getFileType(file)

        const axiosOptions = handleUploadProgressAxios({
          onChange: setUploadProgress
        })

        /**
         * Função que será chamada ao final da promise disparada pelo Redux
         */
        const handleUploadSuccess = ({ data }) => {
          setPreviewMedia(oldState => ({ ...oldState, url: data.url }))
          setLoadingUpload(false)
          setUploadProgress(0)

          formRef?.current?.setFieldValue(`${scopePathMessage}.type`, fileType)
          formRef?.current?.setFieldValue(`${scopePathMessage}.url`, data.url)
          formRef?.current?.setFieldValue(`${scopePathMessage}.name`, file.name)
        }

        dispatch(
          campaignActions.upload({
            body,
            onSuccess: handleUploadSuccess,
            successFeedbackMsg: 'Mídia carregada com sucesso!',
            errorFeedbackMsg:
              'Houve um erro ao carregar a mídia. Tente novamente.',
            axiosOptions
          })
        )
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Erro', 'Houve um erro ao enviar a mídia da campanha.')
        setUploadProgress(0)
        setLoadingUpload(false)
      }
    },
    [dispatch, formRef, templateHasMedia]
  )

  const handleClearCampaignImage = useCallback(() => {
    const { mediaType, mediaUrl, mediaName } = template

    if (formRef?.current) {
      formRef.current.setFieldValue(`${scopePathMessage}.media.type`, mediaType)
      formRef.current.setFieldValue(`${scopePathMessage}.media.url`, mediaUrl)
      formRef.current.setFieldValue(`${scopePathMessage}.media.name`, mediaName)
      formRef.current.clearField(`${scopePathMessage}.mediaFile`)
    }

    setPreviewMedia({ type: mediaType, url: mediaUrl })
  }, [template, formRef])

  /**
   * Memos
   */

  const messagePreviewData = useMemo(() => {
    const templateHasButtons = !!template?.buttons?.length
    const steps = [
      {
        text: formattedMessageToHtml(template?.message || ''),
        media: previewMedia || template?.media,
        buttons: template?.buttons
      }
    ]

    if (templateHasButtons) {
      steps.push({
        isUser: true,
        text: template?.buttons[0].text
      })
    }

    return steps
  }, [template])

  const showMediaFormGroup = useMemo(
    () => allowMediaOnTemplate && templateHasMedia,
    [allowMediaOnTemplate, templateHasMedia]
  )

  return (
    <fieldset>
      <Panel title="Template" className="form-grid" isCollapsible>
        <FormGroup className="span-full">
          <Label htmlFor="template_id" isRequired>
            Escolha um template
          </Label>

          <Select
            placeholder="Definir template"
            options={options}
            name="template_id"
            components={{ Option: TemplateOption }}
            loading={loading}
            onChange={handleChange}
            isClearable={false}
            required
            defaultValue={defaultValue}
          />

          {!!template && (
            <S.MessagePreviewWrapper>
              <WhatsAppMessagePreview
                messages={messagePreviewData}
                isEditing={false}
                allowMediaOnMessage={allowMediaOnTemplate}
              />
            </S.MessagePreviewWrapper>
          )}
        </FormGroup>
      </Panel>

      {showMediaFormGroup && (
        <MessageWithMediaAndPreview
          panelTitle="Mídia do template"
          mediaLabel={labelMedia}
          mediaDescription="Você pode alterar ou enviar com o arquivo padrão do template."
          formRef={formRef}
          showTextEditor={false}
          handleUpload={handleUpload}
          isLoading={loadingUpload}
          uploadProgress={uploadProgress}
          mediaFile={previewMedia}
          onClearMedia={handleClearCampaignImage}
          initialText={messagePreviewData?.[0]?.text}
          alwaysShowFileInput
          canRemoveMedia={!isOriginalTemplateImage}
          mediaTypeAllowed={mediaTypeAllowed}
          path={scopePathMessage}
        />
      )}
    </fieldset>
  )
}

export default Template

Template.defaultProps = {
  initialTemplate: null,
  initialData: null
}

Template.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialTemplate: PropTypes.string,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  initialData: PropTypes.shape({
    active: PropTypes.bool,
    description: PropTypes.string,
    destinations: PropTypes.arrayOf(PropTypes.string),
    media: PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.string
    }),
    name: PropTypes.string,
    template_id: PropTypes.string,
    schedule_date: PropTypes.string
  })
}
