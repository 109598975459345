import { advertiserCards, advertiserListAllCards } from './advertiser'
import { publisherCards, publisherListAllCards } from './publisher'

const cardsByType = {
  publisher: publisherCards,
  advertiser: advertiserCards,
  listAll: {
    publisher: publisherListAllCards,
    advertiser: advertiserListAllCards
  }
}

export default cardsByType
