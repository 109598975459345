import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-top: 1rem;
`
interface IconColorProps {
  iconColor?: 'success' | 'danger' | 'warning' | 'info'
}

const containerModifier = {
  success: ({ theme }) => css`
    color: ${theme.colors.success};
  `,
  success_alert: () => css`
    color: rgba(155, 191, 115, 1);
  `,
  warning: ({ theme }) => css`
    color: ${theme.colors.warning};
  `,
  info: ({ theme }) => css`
    color: ${theme.colors.info};
  `,
  danger: ({ theme }) => css`
    color: ${theme.colors.danger};
  `
}

export const Content = styled.div<IconColorProps>`
  ${({ theme, iconColor = 'warning' }) => css`
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
      ${containerModifier[iconColor]({ theme })}
    }
  `}
`
