import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SelectSearchable from '~/components/SelectSearchable'
import { listAttendant } from '~/modules/chat/store/actions'
import { setChatAgent } from '~/store/modules/filters/actions'

export const statusBotOptions = [
  { label: 'Qualquer status do bot', value: 'null' },
  {
    label: 'Bot ativado',
    value: 'true'
  },
  {
    label: 'Bot desativado',
    value: 'false'
  }
]

export const FilterChatAgent = () => {
  const dispatch = useDispatch()

  const {
    filters: { filterChatAgent },
    chats: { listAttendantData }
  } = useSelector(state => state)

  const agents = listAttendantData.map(agent => ({
    label: agent.name,
    value: agent.user_id
  }))

  const handleChangeAgent = useCallback(
    (filteredAgent: { filteredAgent: { value: string; label: string } }) => {
      dispatch(setChatAgent({ filteredAgent }))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(listAttendant({}))
  }, [dispatch])

  return (
    <SelectSearchable
      placeholder="Filtrar por atendente"
      options={agents}
      onChange={handleChangeAgent}
      defaultValue={[filterChatAgent]}
    />
  )
}
