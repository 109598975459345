import React from 'react'
import {
  FiCreditCard,
  FiDollarSign,
  FiEye,
  FiPercent,
  FiRefreshCw,
  FiShoppingBag
} from 'react-icons/fi'
import { HiOutlineCursorClick } from 'react-icons/hi'
import { HiOutlineMegaphone } from 'react-icons/hi2'
import { IoRocketOutline } from 'react-icons/io5'

export const dashboardIcons: CardIcons = {
  publisher: {
    prints: <FiEye className="text-warning" />,
    clicks: <HiOutlineCursorClick className="text-info" />,
    ctr: <FiPercent className="text-primary" />,
    income: <FiDollarSign className="text-success" />,
    conversion: <FiShoppingBag className="text-success" />,
    actives_campaigns: <IoRocketOutline className="text-info" />,
    ads_income: <FiDollarSign className="text-success" />,
    sales_income: <FiShoppingBag className="text-warning" />,
    actives_advertisers: <HiOutlineMegaphone className=" text-primary" />,
    roas: <FiRefreshCw className=" text-primary" />
  },
  advertiser: {
    prints: <FiEye className="text-warning" />,
    clicks: <HiOutlineCursorClick className="text-info" />,
    ctr: <FiPercent className="text-primary" />,
    income: <FiDollarSign className="text-success" />,
    conversion: <FiShoppingBag className="text-success" />,
    actives_campaigns: <IoRocketOutline className="text-info" />,
    ads_income: <FiDollarSign className="text-success" />,
    sales_income: <FiShoppingBag className="text-warning" />,
    actives_advertisers: <HiOutlineMegaphone className=" text-primary" />,
    roas: <FiRefreshCw className=" text-primary" />
  }
}
