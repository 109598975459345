/* eslint-disable no-underscore-dangle */
import React, { useMemo, useEffect, useState, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { hideSidebar } from '~/components/sidebar/sidebar-actions'
import {
  indexAllLocationRegions,
  indexLocationMinified,
  indexLocationRegions,
  showAllOnToEdit,
  showLocation
} from '~/pages/locations/actions'

import AddMapPoint from '../components/AddMapPin'
import MapDraw from './components/MapDraw'
import SidebarRegions from './components/SidebarRegions'
import { MapDrawProvider } from './hooks/useMapDraw'

import * as S from './styles'

const LocationsRegions = () => {
  const [isShowingEdit, setIsShowingEdit] = useState(true)
  const { id: locationId = null, mode = null } = useParams()

  const isPreview = mode !== 'edit'

  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(hideSidebar())
  }, [dispatch])

  const { listLocationRegionsLoading, loading, listLocationsMinifiedLoading } =
    useSelector(state => state.locations)

  useEffect(() => {
    if (locationId && locationId !== 'all') {
      dispatch(showLocation(locationId))
      dispatch(indexLocationRegions({ locationId }))
    } else {
      dispatch(indexLocationMinified())
      dispatch(indexAllLocationRegions({ data: [] }))
    }
  }, [locationId, dispatch])

  //
  const [centerMap, setCenterMap] = useState(null)

  const handleCenterMap = address => {
    if (!address) return setCenterMap(null)

    setCenterMap(address)
  }

  const readyToRender = useMemo(
    () =>
      !listLocationRegionsLoading && !listLocationsMinifiedLoading && !loading,
    [listLocationRegionsLoading, listLocationsMinifiedLoading, loading]
  )
  useEffect(() => {
    dispatch(showAllOnToEdit({ data: [] }))
  }, [dispatch])

  const handleShowAllRegions = () => {
    if (!isShowingEdit) {
      dispatch(showAllOnToEdit({ data: [] }))
    }
    setIsShowingEdit(state => !state)
  }

  return (
    <>
      <PageTitle title="Regiões de entrega" />

      <Loading status={!readyToRender}>Carregando regiões...</Loading>

      {readyToRender && (
        <S.Container>
          <AddMapPoint
            onChange={handleCenterMap}
            handleShowAllRegions={handleShowAllRegions}
            isShowingEdit={isShowingEdit}
            isPreview={isPreview}
          />

          <MapDrawProvider>
            <MapDraw
              extenalCenterMap={centerMap}
              isPreview={isPreview}
              isShowingEdit={isShowingEdit}
            />

            <SidebarRegions isPreview={isPreview} />
          </MapDrawProvider>
        </S.Container>
      )}
    </>
  )
}
export default LocationsRegions
