import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    width: var(--aside__width);

    transition: ease-in-out all 0.3s;

    border-radius: ${theme.border.radius.medium};
    background-color: ${theme.colors.white};

    > header {
      padding: 1rem 1rem 0.5rem;
      position: relative;
      min-width: var(--aside__width);

      .title {
      }

      .description {
        color: ${({ theme }) => theme.colors.gray};
        font-size: ${({ theme }) => theme.font.sizes.small};
        font-weight: 400;
        line-height: 1.125;
        margin-bottom: 0;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }

      .addressSection__wrapper {
        padding: 4px 8px;
        margin: 0 -8px 8px -8px;
        border-radius: 4px;
        background-color: ${theme.colors.secondaryLighten30};

        header {
          display: flex;
          justify-content: space-between;
        }

        .addressSection {
          font-size: 12px;
          line-height: 1.125;
        }
      }

      .locationsNear {
        margin-bottom: 0.5rem;
      }

      > .icon__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        outline: none;
        background-color: transparent;
        padding: 0;
        border: 0;
      }
    }
  `}
`

export const Form = styled.section`
  ${({ theme }) => css`
    flex: 1;

    width: 100%;
    min-width: var(--aside__width);
    padding: 0.5rem 1rem 1rem;

    overflow-x: hidden;
    overflow-y: auto;

    /* Custom scrollbar - width */
    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.colors.inative};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.tertiary};
      transition: 0.3s ease-in-out all;
      /* Handle on hover */
      &:hover {
        background: ${theme.colors.gray};
      }
    }

    form {
      display: flex;
      flex-direction: column;
      height: 100%;

      .wrapTextField {
        flex: 1;

        display: flex;
        flex-direction: column;

        > div {
          height: 100%;

          textarea {
            height: 100%;
          }
        }
      }

      footer {
        margin-top: 1rem;
      }
    }
  `}
`

export const FormAddress = styled(Form)`
  padding: 0.5rem 0 1rem;

  header {
    padding: 0 1rem;
  }

  .alertWrapper {
    margin: 0 1rem;
    width: calc(100%-2rem);
  }

  .form-default__buttons {
    padding: 1rem;
  }
`

export const CartCard = styled.section`
  ${({ theme }) => css`
    position: relative;
    margin-bottom: ${theme.spacings.medium};
    width: 100%;

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: ${theme.spacings.small};

      line-height: 1.125;

      background-color: ${theme.colors.successShade20};
      border-radius: ${theme.border.radius.medium};

      color: ${theme.colors.light};
      text-align: center;

      a {
        color: ${theme.colors.white};
        font-size: ${theme.font.sizes.large};
        font-weight: ${theme.font.bold};
      }
    }

    .icon__close {
      position: absolute;
      top: 0.1rem;
      right: 0.35rem;
      outline: none;
      background-color: transparent;
      padding: 0;
      border: 0;
      color: ${theme.colors.white};
    }
  `}
`
