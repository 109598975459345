export const ruptureCodes = {
  stock_out_product: {
    label: 'Produto em falta'
  },
  expired_product: {
    label: 'Produto vencido'
  },
  damaged_product: {
    label: 'Produto danificado'
  },
  bad_quality_product: {
    label: 'Produto em má qualidade'
  },
  season_out_product: {
    label: 'Produto fora da safra'
  },
  discontinued_product: {
    label: 'Produto descontinuado'
  },
  bot_error: {
    label: 'Erro do bot'
  },
  wrong_stock: {
    label: 'Estoque errado'
  },
  waiting_unloading: {
    label: 'Aguardando descarregamento'
  },
  store_does_not_carry_product: {
    label: 'Loja não trabalha com o produto'
  },
  other: {
    label: 'Outro'
  }
}
