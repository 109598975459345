type TermsOfServiceDTO = (
  data: TermsOfService | TermsOfService[]
) => TermsOfServiceContext['data']

const termsOfServiceDataModalDTO: TermsOfServiceDTO = data => {
  if (!data) return null

  const {
    url,
    publisher_name: name,
    version,
    publisher_id
  } = (Array.isArray(data) ? data[0] : data) || {}

  return {
    url,
    name,
    version,
    publisher_id
  }
}

export default termsOfServiceDataModalDTO
