import React from 'react'

import PropTypes from 'prop-types'

import { Badge } from '~/components/UI/Badge'

import LocationName from '../LocationName'

const LocationBadge = ({ locationId, locationName }) => (
  <Badge>{<LocationName locationId={locationId} />}</Badge>
)

export default LocationBadge

LocationBadge.propTypes = {
  locationId: PropTypes.string
}
LocationBadge.defaultProps = {
  locationId: null
}
