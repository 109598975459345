import moment from 'moment-timezone';

import { PROMOTION_STATUS } from '~/modules/promotional/constants/promotionStatus';

moment.locale('pt-br');

export const getStatus = promotion => {
  const isActive = promotion.active;
  const isDisabled = promotion.disabled_at;
  const isBefore = moment().isBefore(promotion.start_promotion);
  const isAfter = moment().isAfter(promotion.end_promotion);
  const isBetweeen = moment().isBetween(
    promotion.start_promotion,
    promotion.end_promotion,
  );

  let status;

  // scheduled
  if (isActive && isBefore) {
    status = 'scheduled';
  }
  // active
  if (isActive && isBetweeen) {
    status = 'active';
  }
  // finished
  if (isActive && isAfter) {
    status = 'finished';
  }
  // pause
  if (!isActive && isDisabled && isBetweeen) {
    status = 'pause';
  }
  // canceled
  if (!isActive && isDisabled && isAfter) {
    status = 'canceled';
  }

  return { type: status, ...PROMOTION_STATUS[status] };
};
