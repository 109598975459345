import { unformatMoney } from '~/helpers';

// Helper
const formatFixedPercent = data => {
  let value = 0; // { type: Number, required: true }

  if (data.type === 'percent') {
    // { type: String, required: true, enum: ['percent', 'fixed'] }
    value = Number(data.value.numAsString);
  }

  if (data.type === 'fixed') {
    // { type: String, required: true, enum: ['percent', 'fixed'] }
    value = Number(unformatMoney(data.value.value));
  }

  return { ...data, value };
};

// Formats

const formatTriggerCoupon = ({
  trigger,
  promotionRules,
  promotion_target,
  offers_ids,
}) => {
  const data = { trigger: {} };

  const offerIds = promotion_target === 'selected' ? offers_ids : [];

  if (promotionRules.totalValue) {
    const total = {
      offers_ids: offerIds, // { type: [String], required: false }
      min_value: Number(unformatMoney(trigger.total.min_value.value)), // { type: Number, required: true, default: 0 }
    };

    data.trigger.total = total;
  }

  if (promotionRules.orderItems) {
    const items = {
      offers_ids: offerIds, // { type: [String], required: false }
      min_quantity: Number(trigger.items.min_quantity.value), // { type: Number, required: true, default: 0 }
    };

    data.trigger.items = items;
  }

  return { ...data };
};

const fomratBenefitsCoupon = benefits => {
  const data = { benefits: {} };

  if (benefits?.items_discount) {
    const items_discount = formatFixedPercent(benefits.items_discount);

    data.benefits.items_discount = items_discount;
  }

  if (benefits?.shipping_discount) {
    const shipping_discount = formatFixedPercent(benefits.shipping_discount);

    data.benefits.shipping_discount = shipping_discount;
  }

  return { ...data };
};

const formatLimitsCoupon = ({ limits, promotionRules }) => {
  const data = { limits: {} };

  if (promotionRules?.totalUsage) {
    const total = Number(limits.total.value);

    data.limits.total = total; // { type: Number, required: false }
  }

  if (promotionRules?.perCustomer) {
    const perCustomer = Number(limits.per_customer.value);

    data.limits.per_customer = perCustomer; // { type: Number, required: false }
  }

  return { ...data };
};

export { formatTriggerCoupon, fomratBenefitsCoupon, formatLimitsCoupon };
