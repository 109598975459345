import React, { useRef, useEffect } from 'react'

import { useField } from '@unform/core'

const ImageInput = ({ name, onChange, ...rest }) => {
  const inputRef = useRef(null)

  const { fieldName, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = ''
      }
    })
  }, [fieldName, registerField])

  return (
    <>
      <input
        id={name}
        type="file"
        ref={inputRef}
        accept="image/*"
        onChange={onChange}
        {...rest}
      />
    </>
  )
}

export default ImageInput
