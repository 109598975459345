import React, { useMemo, useRef, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { Button } from '~/components/Buttons/'
import { getValidationErrors } from '~/helpers'
import {
  createOperator,
  updateOperator
} from '~/store/modules/operators/actions'

import GeneralFields from './General'

/**
 *
 * @example
 * // New
 * <FormOperator />
 *
 * // Edit
 * <FormOperator edit initialData={data} />
 *
 * @param {object} initialData Objeto recebido para edição
 * @param {object} edit Recebe se está em modo de edição
 *
 */

const FormOperator = ({ initialData, edit = false }) => {
  const formRef = useRef(null)

  const { loading } = useSelector(state => state.operators)

  useEffect(() => {
    formRef.current.setData(initialData)
  }, [initialData])

  const dispatch = useDispatch()

  const textButton = useMemo(() => (edit ? 'Salvar' : 'Adicionar'), [edit])

  const schema = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    location_id: Yup.string().required('O local é obrigatório'),
    pin: Yup.string()
      .typeError('O pin deve ter 4 números - Type')
      .required('O pin é obrigatório')
      .test('length', 'O pin deve ter 4 números', val => val.length === 4)
  })

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        const formattedData = {
          ...data,
          pin: String(data.pin)
        }

        await schema.validate(formattedData, {
          abortEarly: false
        })

        dispatch(
          edit
            ? updateOperator({ id: initialData.id, ...formattedData })
            : createOperator(formattedData)
        )
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRef.current?.setErrors(errors)
        }
      }
    },
    [dispatch, edit, initialData, schema]
  )

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={initialData}
        className="form-default"
      >
        <GeneralFields />

        <div className="form-default__buttons">
          <Button
            type="submit"
            text={textButton}
            loading={loading}
            template="primary"
            size="large"
          />
        </div>
      </Form>
    </>
  )
}

export default FormOperator

FormOperator.propTypes = {
  initialData: PropTypes.shape(),
  edit: PropTypes.bool
}

FormOperator.defaultProps = {
  initialData: {
    id: null
  },
  edit: false
}
