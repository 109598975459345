/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

const Refusal = ({ refusal, payment }) => {
  const showRefusal = useMemo(
    () => payment?.status === 'payment_refused' && refusal,
    [payment, refusal],
  );

  const isAntiFraud = type =>
    type === 'fraudAnalysis' || type === 'antifraud' > 0;

  return (
    !!showRefusal && (
      <div>
        {refusal.map((item, index) => (
          <div key={index} className="refusal_reason">
            {item.message && (
              <div className="key">
                <strong>#{index + 1}</strong>
              </div>
            )}
            {/*  */}
            <div className="text">
              {item.code && (
                <div className="item">
                  <strong>Código:</strong> {item.code}
                </div>
              )}
              {/*  */}
              {item.message && (
                <div className="item">
                  <strong>Mensagem:</strong> {item.message}
                </div>
              )}
              {/*  */}
              {item.type && (
                <div className="item">
                  <strong>Onde parou:</strong>{' '}
                  {isAntiFraud(item.type)
                    ? 'Antifraude'
                    : 'Processamento do cartão'}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    )
  );
};

Refusal.defaultProps = {
  item: {
    code: null,
    message: null,
    type: null,
  },
  refusal: [],
};

Refusal.propTypes = {
  item: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string,
  }),
  payment: PropTypes.shape({
    autorization_id: PropTypes.string,
    transaction_id: PropTypes.string,
    status: PropTypes.string,
    createdAt: PropTypes.string,
    method: PropTypes.string,
    method_info: PropTypes.string,
    transactioned_at: PropTypes.string,
    reserved_at: PropTypes.string,
    reserved_value: PropTypes.number,
    value: PropTypes.number,
    fraud_analysis_status: PropTypes.string,
  }).isRequired,
  refusal: PropTypes.arrayOf(PropTypes.string),
};

export default Refusal;
