import axios from 'axios';

/**
 * Rotas para fazer uma busca livre de produtos
 * @param {string} body.query
 * @param {array[]} body.external_ids
 * @returns Lista de produtos
 */

export const searchOffersService = body =>
  axios.post(`/search/offers/unique`, body);
