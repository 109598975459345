import React from 'react';
import { Button as ButtonReactStrap, Spinner } from 'reactstrap';

const defaultProps = {
  loading: false,
  width: '220px',
  spinner: { color: 'secondary', type: 'grow', size: 'sm' },
  disabled: false,
};

const Button = ({
  loading,
  text,
  width,
  spinner,
  disabled,
  iconLeft,
  iconRight,
  ...rest
}) => (
  <ButtonReactStrap style={{ width }} disabled={loading || disabled} {...rest}>
    {iconLeft}
    {loading ? (
      <Spinner type={spinner.type} size={spinner.size} color={spinner.color} />
    ) : (
      text
    )}
    {iconRight}
  </ButtonReactStrap>
);

Button.defaultProps = defaultProps;

export default Button;
