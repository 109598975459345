import styled, { DefaultTheme, css } from 'styled-components'

export const CardForm = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacings.medium};

    fieldset {
      > div:not(:last-child) {
        margin-bottom: ${theme.spacings.medium};
      }
    }

    aside {
      display: flex;
      align-items: center;
      margin-left: 2rem;
      background-color: rgb(238, 238, 238);
      border-radius: 1rem;
      padding: 1rem;

      > div {
        margin: 0;
      }
    }
  `}
`
export const CardDateAndCVVWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    div:first-child {
      margin-right: ${theme.spacings.medium};
    }
  `}
`
