import React from 'react';

import PropTypes from 'prop-types';

import Tooltip from '~/components/Tooltip';

import * as S from './styles';

export const IconBot = ({
  $active,
  isLoading = false,
  useBot = true,
  onClick = null,
  template = 'default',
}) => (
  <>
    <S.Container
      className="bot"
      onClick={onClick}
      type="button"
      disabled={!useBot || !onClick}
      template={template}
      useBot={useBot}
    >
      {useBot && (
        <>
          <S.Bot
            useBot={useBot}
            active={useBot && $active}
            icon-role="bot"
            isLoading={isLoading}
          />
          <span className="text">
            {$active ? 'Robô ativo' : 'Robô desativado'}
          </span>
        </>
      )}

      {!useBot && (
        <>
          <span className="forbidden" />
          <Tooltip
            icon={
              <S.Bot
                useBot={useBot}
                active={useBot && $active}
                icon-role="bot"
                isLoading={isLoading}
              />
            }
            text="Este cliente optou por não ser atendido por robô. O bot não pode ser ativado para ele."
          />
        </>
      )}
    </S.Container>
  </>
);

IconBot.propTypes = {
  $active: PropTypes.bool,
  isLoading: PropTypes.bool,
  useBot: PropTypes.bool,
  onClick: PropTypes.func,
  template: PropTypes.string,
};

IconBot.defaultProps = {
  $active: false,
  isLoading: false,
  useBot: true,
  onClick: null,
  template: 'default',
};
