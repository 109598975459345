import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    width: 100%;
    height: 100%;
  `}
`;
