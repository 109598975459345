import * as Yup from 'yup'

export const schemaYup = Yup.object().shape({
  name: Yup.string().required('O título da campanha é obrigatório'),
  description: Yup.string(),
  destinations: Yup.string()
    .required('O campo destinatários é obrigatório')
    .required('A mensagem de resposta é obrigatória')
  // scheduleMode: Yup.string().required(
  //   'A data e horário de envio são informações obrigatórias',
  // ),
})
