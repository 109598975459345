import axios from 'axios';

import { baseAPI } from './_prefix';

const methodsConfigAPI = `${baseAPI}/method-config`;

/**
 *
 * @endpoint /payment/method-config
 * @exports getPaymentMethodsConfig  Retorna todos métodos de pagamento que já foram selecionados.
 *
 */

export const list = () => {
  return axios.get(`${methodsConfigAPI}`);
};

/**
 *
 * @endpoint /payment/method-config
 * @exports createPaymentMethodConfig Cria um novo método de pagamento.
 *
 */

export const create = ({ body }) => {
  return axios.post(`${methodsConfigAPI}`, body);
};

/**
 *
 * @endpoint /payment/method-config/:id
 * @exports updatePaymentMethodConfig Atualiza as configurações de pagamento.
 *
 */

export const update = ({ id, body }) => {
  return axios.put(`${methodsConfigAPI}/${id}`, body);
};

/**
 *
 * @endpoint /payment/method-config/:id
 * @exports updatePaymentMethodConfig Atualiza as configurações de pagamento.
 *
 */

export const deleteItem = ({ id }) => {
  return axios.delete(`${methodsConfigAPI}/${id}`);
};
/**
 * Métodos referentes às configurações de métodos de pagamento global ou de uma location
 */
export default {
  list,
  create,
  update,
  delete: deleteItem,
};
