import React, { useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'

import { t } from 'i18next'
import { debounce } from 'lodash'

import FilterUserRoles from '~/components/FilterUserRoles'
import Input from '~/components/Form/Input'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'

const UsersListFilters: React.FC = () => {
  const [innerLoading, setInnerLoading] = useState(false)

  const params = useQueryParams()
  const name = params.get('name')
  const email = params.get('email')
  const role = params.get('role') as UserRoles

  const executeDebounceFunc = (func: () => void) => func()

  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  const handleFilter = (data: OnChangeInputData) => {
    const { name, value } = data

    if (!value) {
      const newParams = new URLSearchParams(params.toString())
      newParams.delete(name)
      history.push({ search: newParams.toString() })
      setInnerLoading(false)

      return
    }

    params.set(name, String(value))
    params.set('page', '1')
    history.push({ search: params.toString() })

    setInnerLoading(false)
  }

  const handleChange = (data: OnChangeInputData) => {
    setInnerLoading(true)
    handleDebounce(() => handleFilter(data))
  }

  return (
    <nav>
      <form className="d-flex gap-2 mb-2">
        <Input
          name="name"
          label={t('users:name')}
          onChange={handleChange}
          defaultValue={name}
          icon={FiSearch}
          placeholder={t('users:filterByName')}
          type="search"
          loading={innerLoading}
        />

        <Input
          name="email"
          label={t('users:email')}
          onChange={handleChange}
          defaultValue={email}
          icon={FiSearch}
          placeholder={t('users:filterByEmail')}
          type="search"
          loading={innerLoading}
        />

        <div className="selectWrapper w-25">
          <FilterUserRoles
            onChange={handleChange}
            initialValue={role}
            size="small"
            ignoreRedux
          />
        </div>
      </form>
    </nav>
  )
}

export default UsersListFilters
