import styled, { css } from 'styled-components'

export const Container = styled.section`
  ${({ theme }) => css`
    .file-input-wrapper {
      display: flex;
      margin-bottom: ${theme.spacings.xsmall};

      > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  `}
`
