import { t } from 'i18next'

import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import AprovationsTable from '~/modules/retailMedia/components/_tables/AprovationsTable'
import MainTabs from '~/modules/retailMedia/components/MainTabs'

const pageTitle = `Retail media - ${t('rm:CampaignsUnderReviewDashBoard')}`

const RetailMediaCampaignsInReview = () => (
  <>
    <PageTitle title={pageTitle} />

    <HeaderPages title={pageTitle} injectDatepicker />

    <MainTabs />

    <AprovationsTable />
  </>
)

export default RetailMediaCampaignsInReview
