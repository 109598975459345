import { FiUsers as icon } from 'react-icons/fi'

import * as Pages from '~/modules/industry/customers/pages'

export const customers = {
  id: 'd4ed80de-a3e9-4997-bc89-e152864eb84c',
  path: '/customers',
  name: 'Consumidores',
  pageTitle: 'Relatório de consumidores',
  icon,
  contentFullWidth: true,
  component: Pages.Dashboard,
  children: null,
  containsHome: true
}
