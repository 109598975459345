import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 1rem;

    overflow: hidden;

    > dl {
      overflow: auto;
      height: calc(100% - 50px);

      /* width */
      ::-webkit-scrollbar {
        width: 0.5rem;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: ${theme.colors.inative};
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${theme.colors.tertiary};
        transition: 0.3s ease-in-out all;
        /* Handle on hover */
        &:hover {
          background: ${theme.colors.gray};
        }
      }
    }

    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: -1rem;
      height: 66px;

      span {
        font-size: ${theme.font.sizes.small};
        font-style: normal;
        font-weight: ${theme.font.bold};
        color: ${theme.colors.neutralColorDark};
      }
    }

    > dt {
      border-bottom: 1px solid ${theme.colors.tertiary};
      margin-top: 1rem;

      &:last-child {
        border-bottom: none;
      }
    }
  `}
`

export const ContentHistory = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.tertiary};
    padding: 8px 4px;
    line-height: 1.125;
    border-left: 3px solid transparent;

    transition: all 0.2s ease-in-out 0s;
    border-left: 0.25rem solid transparent;

    > dd {
      margin-bottom: 0;
    }

    &:nth-child(odd) {
      background-color: ${theme.colors.neutral.high.lightest};
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      border-left-color: ${theme.colors.hover};
      background-color: ${theme.colors.primaryLighten61} !important;
    }
  `}
`

export const HeaderList = styled.dt`
  ${({ theme }) => css`
    position: sticky;
    top: 0;

    display: flex;
    align-items: center;

    margin-top: 1rem;

    font-size: ${theme.font.sizes.small};
    font-style: normal;
    font-weight: ${theme.font.bold};

    background: white;

    > svg {
      font-size: 1rem;
      color: ${theme.colors.primary};
      margin-right: 8px;
    }
  `}
`

export const ActionTitle = styled.dt`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    font-style: normal;
    font-weight: ${theme.font.bold};
    color: ${theme.colors.neutralColorDark};
  `}
`

export const ContentName = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Text = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    font-style: normal;
    font-weight: ${theme.font.normal};
    color: ${theme.colors.stormGray};
  `}
`
