import React, {
  useCallback,
  useMemo,
  useState,
  useLayoutEffect,
  useRef
} from 'react'
import { FiExternalLink } from 'react-icons/fi'

import { Form } from '@unform/web'

import { Button, ButtonNavLink } from '~/components/Buttons'

import { useMapDraw } from '../../../hooks/useMapDraw'
import SubmitButtons from '../SubmitButtons'
import MainFields from './MainFields'
import TimesFieds from './TimesFieds'

import * as S from './styles'

const EditPanel = () => {
  const formRef = useRef(null)

  const [activePanel, setActivePanel] = useState('main')
  const [isGlobalConfig, setIsGlobalConfig] = useState(false)

  const { editingRegion, handleUpdateRegion, currentRegionSlotsLink } =
    useMapDraw()

  useLayoutEffect(() => {
    formRef?.current?.setFieldValue('global_config', isGlobalConfig)
  }, [formRef, isGlobalConfig])

  /**
   * Com essa função vamos controlar o uso de valores globais
   */
  const handleGlobalConfig = useCallback(e => {
    setIsGlobalConfig(e.currentTarget.checked)
  }, [])

  /**
   *
   */

  const getTemplateButton = useCallback(
    key => (activePanel === key ? 'transparentPrimary' : 'transparent'),
    [activePanel]
  )

  const navigation = useMemo(
    () => [
      {
        key: 'main',
        template: getTemplateButton('main'),
        label: 'Geral',
        onClick: () => setActivePanel('main'),
        Component: MainFields
      },
      {
        key: 'times',
        template: getTemplateButton('times'),
        label: 'Horários',
        onClick: () => setActivePanel('times'),
        Component: TimesFieds,
        hide: editingRegion?.type === 2
      }
      // {
      //   key: 'values',
      //   template: getTemplateButton('values'),
      //   label: 'Valores',
      //   onClick: () => setActivePanel('values'),
      //   Component: ValuesFields,
      // },
    ],
    [getTemplateButton, editingRegion]
  )

  useLayoutEffect(() => {
    const panelElement = document.querySelector('.panel__wrapper')
    panelElement.scroll(0, 0)
  }, [])

  /**
   * Handle initial data
   */

  const initialData = useMemo(
    () => ({
      global_config:
        !editingRegion.delivery_time && !editingRegion.min_interval_schedule,
      ...editingRegion
    }),
    [editingRegion]
  )

  useLayoutEffect(() => {
    if (initialData.global_config) {
      setIsGlobalConfig(true)
    }
  }, [initialData])

  return (
    <S.Container>
      <header>
        <strong className="title">{editingRegion.name}</strong>
      </header>

      <S.Nav activePanel={activePanel}>
        {navigation.map(
          item =>
            !item?.hide && (
              <Button
                key={item.key}
                onClick={item.onClick}
                template={item.template}
                className={`button__${item.key}`}
              >
                {item.label}
              </Button>
            )
        )}
      </S.Nav>

      <Form
        onSubmit={handleUpdateRegion}
        initialData={initialData}
        ref={formRef}
      >
        {/* Pode reativar essa seção quando for usar painel de valores também */}
        {/* <S.GlobalConfigWrapper activePanel={activePanel}>
          <GlobalConfig
            isGlobalConfig={isGlobalConfig}
            onChangeGlobalConfig={handleGlobalConfig}
          />
        </S.GlobalConfigWrapper> */}

        <S.FieldsWrapper activePanel={activePanel} className="panel__wrapper">
          <div className="panel">
            {navigation.map(
              ({ Component, key, hide }) =>
                !hide && (
                  <Component
                    key={key}
                    isGlobalConfig={isGlobalConfig}
                    onChangeGlobalConfig={handleGlobalConfig}
                  />
                )
            )}
          </div>
        </S.FieldsWrapper>

        <footer>
          <div className="navLink">
            <ButtonNavLink
              template="transparentPrimary"
              iconRight={<FiExternalLink />}
              text="Configurar slots de entrega"
              target="_blank"
              to={currentRegionSlotsLink}
            />
          </div>

          <SubmitButtons />
        </footer>
      </Form>
    </S.Container>
  )
}

export default EditPanel
