import React, { useMemo } from 'react';
import { FiRepeat } from 'react-icons/fi';

import PropTypes from 'prop-types';

import { formatMoney } from '~/helpers';
import { itemStatusList } from '~/pages/orders/_status/items';

import { Item } from './styles';

const Product = ({ item }) => {
  const originalPriceFormatted = useMemo(
    () => formatMoney(item.original_price),
    [item],
  );

  const totalValue = useMemo(
    () => formatMoney(item.quantity * item.original_price),
    [item],
  );

  const isDeleted = useMemo(() => item.status === 'deleted', [item.status]);

  const statusInfos = useMemo(
    () =>
      Object.values(itemStatusList).find(
        statusItem => statusItem.type === item.status,
      ) || {},
    [item],
  );

  return (
    <Item isDeleted={isDeleted} className={isDeleted ? 'deleted' : 'confirmed'}>
      {/* Nome e código do produto */}
      <div className="name">
        {item.status === 'substitution' && (
          <span className="substitution">{item.original_name}</span>
        )}
        <strong className="nameProduct">
          {item.status === 'substitution' && (
            <span className="icon">
              <FiRepeat />
            </span>
          )}
          {item.name}
        </strong>

        <span className="productId color__gray">
          {item.status === 'substitution'
            ? item.product_id_substitute
            : item.product_id}
        </span>

        {item.detail && (
          <div className="note">
            <div className="text">
              <span className="helpText">Observações do cliente: </span>
              <span className="noteText">{item.detail}</span>
            </div>
          </div>
        )}
      </div>

      {(item.status !== 'stock_out' || item.status !== 'deleted') && (
        <>
          {/* Preço unitário */}
          <div className="original_price">
            <span className="text_price">{originalPriceFormatted}</span>
          </div>

          {/* Quantidade */}
          <div className="quantity grayColumn">
            <span className="text">
              {!!item?.original_quantity && `${item?.original_quantity} / `}
              {item.quantity}
            </span>
          </div>

          {/* Desconto */}
          <div className="discount">{formatMoney(item?.discount || 0)}</div>

          {/* Valor total */}
          <div className="total_value grayColumn">{totalValue}</div>
        </>
      )}

      {/* Editar status */}
      <div className="status">
        <span className="text_status">
          {statusInfos.label || 'Sem status definido'}
        </span>
      </div>
    </Item>
  );
};

export default Product;

Product.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    product_id: PropTypes.string,
    product_id_substitute: PropTypes.string,
    detail: PropTypes.string,
    original_name: PropTypes.string,
    original_price: PropTypes.number,
    quantity: PropTypes.number,
    original_quantity: PropTypes.number,
    discount: PropTypes.number,
    id: PropTypes.string,
    order_id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};
