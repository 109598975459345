import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  ReactNode
} from 'react'
import { Icon } from 'react-feather'
import { FiAlertCircle, FiCheckCircle, FiXCircle } from 'react-icons/fi'
import NumberFormat from 'react-number-format'
import { Spinner } from 'reactstrap'

import { useField } from '@unform/core'

import { formatMoneyInputs } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'

import { Container, Error, IconWrapper, MessageError } from './styles'

/**
 *
 * @example
 * <InputMoney
 *   name="main_phone"
 *   placeholder="Insira o telefone"
 *   loading={loading}
 * />
 *
 * @param {string} name Recebe o name para o input
 * @param {ReactElement | string} icon Ícone que será exbido a esquerda do input.
 * @param {boolean} loading Exibe um loader ao lado do input
 * @param {function} onBlur Extendendo onBlur
 *
 * @returns O valor retornado é um objeto com value(formatado) e numAsString(sem formatação)
 *
 */

interface IputMoneyProps {
  name: string
  icon?: Icon
  htmlIcon?: ReactNode
  htmlRightIcon?: ReactNode
  onBlur?: (value?: any) => void

  iconRight?: ReactNode
  disabled?: boolean
  showWarningOnRequired?: boolean
  showBadgeIsvalid?: boolean
  showBadgeIsInvalid?: boolean
  showBorderInvalid?: boolean
  onChange?: (value?: any) => void
  helpText?: string
  placeholder?: string
  required?: boolean
}

const InputMoney = ({
  name,
  icon: Icon,
  htmlIcon,
  htmlRightIcon,
  onBlur,
  loading,
  iconRight,
  disabled = false,
  showWarningOnRequired = true,
  showBadgeIsvalid = false,
  showBadgeIsInvalid = false,
  showBorderInvalid = false,
  onChange,
  helpText,
  ...rest
}: IputMoneyProps) => {
  const { lengthCentsCurrency } = useAppInfo()

  const inputRef = useRef(null)

  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name)

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [valueInput, setValueInput] = useState('')

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    onBlur?.({
      value: inputRef.current.state.value,
      name: inputRef.current.state.name,
      clearError,
      setIsFilled
    })

    setIsFilled(!!inputRef.current?.state.value)
  }, [clearError, onBlur])

  const defaultValueIsNull = defaultValue === null

  const defaultValueIsUndefined = typeof defaultValue === 'undefined'

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'state'
    })
  }, [inputRef, fieldName, registerField])

  useEffect(() => {
    if (!defaultValueIsNull && !defaultValueIsUndefined) {
      if (typeof defaultValue === 'number') {
        const data = lengthCentsCurrency
          ? defaultValue.toFixed(lengthCentsCurrency)
          : defaultValue
        setValueInput(String(data))
      } else {
        setValueInput(defaultValue)
      }
    }
  }, [
    defaultValue,
    defaultValueIsNull,
    defaultValueIsUndefined,
    lengthCentsCurrency
  ])

  const inputChangedHandler = values => {
    setValueInput(values.value)
    onChange?.(values)
  }

  useEffect(() => {
    if (defaultValue) {
      setIsFilled(true)
    }
  }, [defaultValue])

  return (
    <>
      <Container
        isFocused={isFocused}
        isFilled={isFilled}
        isErrored={!!error || showBadgeIsInvalid || showBorderInvalid}
        isDisabled={disabled}
        showWarning={!isFilled && showWarningOnRequired && rest?.required}
      >
        {Icon && <Icon />}

        {htmlIcon && <div className="htmlIcon">{htmlIcon}</div>}

        <NumberFormat
          type="text"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          name={fieldName}
          aria-label={fieldName}
          format={formatMoneyInputs}
          onValueChange={inputChangedHandler}
          value={valueInput}
          defaultValue={defaultValue === null ? '' : defaultValue}
          disabled={disabled}
          {...rest}
          ref={inputRef}
        />

        {showBadgeIsvalid && (
          <IconWrapper className="success">
            <FiCheckCircle />
          </IconWrapper>
        )}
        {showBadgeIsInvalid && (
          <IconWrapper className="error">
            <FiXCircle />
          </IconWrapper>
        )}

        {loading && <Spinner type="grow" size="sm" color="secondary" />}

        {error && (
          <Error title={error}>
            <FiAlertCircle />
          </Error>
        )}

        {iconRight && <div className="right-icon">{iconRight}</div>}

        {htmlRightIcon && (
          <div className="htmlIcon htmlRightIcon">{htmlRightIcon}</div>
        )}
      </Container>
      {error && <MessageError>{error}</MessageError>}
      {helpText && <div className="help-text">{helpText}</div>}
    </>
  )
}

export default InputMoney
