import { produce } from 'immer';

import reducerCases from './reducerCases';

const INITIAL_STATE = {
  // Indicador global de error
  error: null,

  // View cart
  loadingViewShoppingCart: false,
  errorViewShoppingCart: null,
  shoppingCart: null,

  // Search offers
  searchOffersResults: false,
  loadingSearchOffers: null,
  errorSearchOffers: null,

  // Send link
  loadingSendLink: false,
  errorSendLink: null,

  // Update
  loadingUpdate: false,
  errorUpdate: null,

  // Delete
  loadingDelete: false,
  errorDelete: null,

  // Item Updated
  // Salvando dados do item atualizado para mudar na listagem carrega pelo SWR antes do reload automático
  itemUpdated: null,

  shouldRevalidateList: false,
};

/**
 * Shopping Cart
 */

const shoppingCart = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const reducer = Object.keys(reducerCases).filter(
      item => item === action.type,
    );

    if (reducer?.length) {
      reducerCases[reducer]({ state, draft, action });
    }
  });

export default shoppingCart;
