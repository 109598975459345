import React from 'react';

import PropTypes from 'prop-types';

import Table from '~/components/Table';

import { schema } from './schema';

const DestinationsTable = ({ data }) => {
  const { destinations } = data;

  return <Table schema={schema} data={destinations} />;
};

DestinationsTable.propTypes = {
  data: PropTypes.shape({
    destinations: PropTypes.arrayOf(
      PropTypes.shape({
        customer_number: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default DestinationsTable;
