import styled, { css } from 'styled-components';

import { Button } from '~/components/Buttons';

export const AddButton = styled(Button)`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.medium};
  `}
`;

export const ApproverFormWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xxlarge};
    border: 1px solid ${theme.colors.outlineGray};
    border-radius: ${theme.border.radius.large};

    .title {
      font-size: ${theme.font.sizes.xlarge};
      font-weight: ${theme.font.bolder};
      margin-bottom: ${theme.spacings.xxlarge};
    }
  `}
`;
