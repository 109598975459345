import React, { useCallback, useRef, useState } from 'react';

import { Form } from '@unform/web';

import { Button } from '~/components/Buttons';
import HeaderPages from '~/components/HeaderPages';
import LocationsNear from '~/components/LocationsNear';
import { FormGroup } from '~/components/unform';

import AddressFields from '../_elements/FormLocation/Address';

import { ContainerSearch } from './styles';

const SearchLocations = () => {
  const formRef = useRef(null);
  const [address, setAddress] = useState(null);

  const handleSubmit = useCallback(formData => {
    setAddress(formData);
  }, []);

  return (
    <>
      <HeaderPages title="Buscar Locais" />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <AddressFields
          formRef={formRef}
          labelItem="do cliente"
          hideCoordinates
          hideComplement
        />

        <FormGroup>
          <Button text="Buscar" textLoading="Buscando..." type="submit" />
        </FormGroup>
      </Form>
      {address != null && (
        <ContainerSearch>
          <LocationsNear address={address} typeLocation="list" />
        </ContainerSearch>
      )}
    </>
  );
};
export default SearchLocations;
