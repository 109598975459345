import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { useAlert } from '~/hooks/useAlert'
import {
  getPlacementQueryKey,
  getPlacementService,
  listPlacementQueryKey,
  patchPlacementService
} from '~/modules/retailMedia/services/placements'

import { bodyPatchPlacementDTO } from './dtos/bodyPatchPlacement'
import ListFormats from './ListFormats'
import MainInfo from './MainInfo'

const EditPlacement = ({ id }: { id: string }) => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const { status, data, error } = useQuery({
    queryKey: [getPlacementQueryKey, id],
    queryFn: async () => {
      const res = await getPlacementService(id)

      return res.data
    }
  })

  const [AlertOnError] = useAlert(
    error && {
      response: {
        data: {
          message: [t('rm:placements.get.error')]
        }
      }
    }
  )

  /**
   * Handle PATCH request and collateral effects
   */

  const queryClient = useQueryClient()

  const patchPlacementMutation = useMutation({
    mutationFn: patchPlacementService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [listPlacementQueryKey]
      })

      queryClient.invalidateQueries({
        queryKey: [getPlacementQueryKey, id]
      })

      toastr.success(
        t('rm:placements.patch.toast.success.title'),
        t('rm:placements.patch.toast.success.messageName', { name: data.name })
      )
    },
    onError: () => {
      toastr.error(
        t('common:messages.errorOccurred.title'),
        t('rm:placements.patch.toast.error.message')
      )
    }
  })

  const handleSubmit: SubmitPatch = useCallback(
    async (formData, callback) => {
      setLoading(true)

      try {
        await patchPlacementMutation.mutateAsync({
          id,
          body: bodyPatchPlacementDTO(formData)
        })

        callback?.()
      } finally {
        setLoading(false)
      }
    },
    [id, patchPlacementMutation]
  )

  return (
    <>
      <PageTitle
        title={`${t('rm:placements.edit.pageTitle')} - ${data ? `[${data?.name}] - ${data?.full_name}` : t('common:actions.loading')}`}
      />

      <section className="px-3 pb-3">
        <Loading status={status === 'pending'}>
          {t('common:actions.loadingData')}...
        </Loading>

        <AlertOnError />

        {!!data && (
          <>
            <MainInfo data={data} onSubmit={handleSubmit} isLoading={loading} />

            <hr />

            <ListFormats
              data={data}
              placementId={id}
              onSubmit={handleSubmit}
              isLoading={loading}
            />
          </>
        )}

        <Loading status={loading}>
          {t('common:actions.savingUpdates')}...
        </Loading>
      </section>
    </>
  )
}

export default EditPlacement
