import { shade } from 'polished'
import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const iconButtonTemplate = ({ theme }: ButtonTemplatesProps) => css`
  background: var(--info);
  color: var(--white);

  border: 1px solid var(--info);

  &:not(:disabled) {
    &:hover {
      background: ${shade(0.1, theme.info)};
    }
  }
`

export default iconButtonTemplate
