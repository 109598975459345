import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SelectSearchable from '~/components/SelectSearchable'
import { setFilteredLocationStatus } from '~/store/modules/filters/actions'

/**
 * Componente para filtrar a listagem por status da loja.
 * Ele possibilita a seleção das lojas por status e mantém no estado global: filters.locationStatus.
 *
 * Não recebe nenhum parâmetro e retorna um select procurável.
 *
 * @example <FilterLocationStatus />
 */

const options = [
  {
    label: 'Todos',
    value: null
  },
  {
    label: 'Ativos',
    value: '1'
  },
  {
    label: 'Inativos',
    value: '0'
  }
]

const FilterLocationStatus = () => {
  const dispatch = useDispatch()

  const { locationStatus: filteredLocationStatus } = useSelector(
    state => state.filters
  )

  const handleChangeLocationStatus = selectedLocationStatus => {
    dispatch(
      setFilteredLocationStatus({
        filteredLocationStatus: selectedLocationStatus
      })
    )
  }

  useEffect(() => {
    if (!filteredLocationStatus) {
      dispatch(
        setFilteredLocationStatus({
          filteredLocationStatus: options[1]
        })
      )
    }
  }, [dispatch, filteredLocationStatus])

  return (
    <SelectSearchable
      placeholder="Filtrar por status do local"
      options={options}
      onChange={handleChangeLocationStatus}
      defaultValue={[filteredLocationStatus || options[1]]}
    />
  )
}

export default FilterLocationStatus
