import { formatDateExibition } from '~/helpers';
import { PROMOTION_STATUS } from '~/modules/promotional/constants/promotionStatus';
import { PROMOTION_TYPES } from '~/modules/promotional/constants/promotionTypes';

import { getStatus } from '../helpers/getStatus';

export const modelPromotionsList = data => {
  const formattedDate = data.map(item => {
    const { start_promotion, end_promotion, promotion_type } = item;

    return {
      ...item,
      start_date: formatDateExibition(start_promotion),
      end_date: formatDateExibition(end_promotion),
      type: {
        type: promotion_type,
        label: PROMOTION_TYPES[promotion_type],
      },
      status: { type: item.status, ...PROMOTION_STATUS[item.status] },
    };
  });

  return formattedDate;
};
