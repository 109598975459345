import Routes from './paths'
import * as RoutesIndustry from './paths/industry'

export const publicRoutes = [Routes.auth, Routes.integration]

export const common = [Routes.users, Routes.config, Routes.logout]

export const grocery: RootMenuItem<unknown>[] = [
  Routes.dashboard, //ts
  Routes.orders, //ts
  Routes.shoppingCarts, // ts
  Routes.chat, // ts
  // Routes.chatV1, // ts
  Routes.analytics, // ts
  Routes.messagingCampaigns, // ts
  Routes.products, // ts
  Routes.retailMedia, // ts
  Routes.promotional, // ts
  // Routes.trade, WIP
  Routes.locations, // ts
  Routes.logistic, // ts
  Routes.customers, // ts
  Routes.financial, // ts
  Routes.operators, // ts
  Routes.users, // ts
  Routes.config // ts,
]

export const industry: RootMenuItem<unknown>[] = [
  RoutesIndustry.retailMedia,
  RoutesIndustry.kpi,
  RoutesIndustry.sellout,
  RoutesIndustry.stock,
  RoutesIndustry.customers,
  Routes.users,
  Routes.config
]

export const commonWhiteLabel = [Routes.users, Routes.config, Routes.logout]

export const groceryWhiteLabel: RootMenuItem<unknown>[] = [
  Routes.retailMedia,
  Routes.users,
  Routes.config
]

export const industryWhiteLabel: RootMenuItem<unknown>[] = [
  RoutesIndustry.retailMedia,
  Routes.users,
  Routes.config
]
