import React from 'react'
import { createRoot } from 'react-dom/client'
import './assets/scss/newtail.scss'

import App from './app'

import '~/i18n/index'

window.React = React

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
