import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacings.xxxsmall};

    small {
      font-size: ${theme.font.sizes.xsmall};
      margin: ${theme.spacings.xxxsmall} 0 0;
    }

    .warning {
      color: ${theme.colors.warning};
    }

    .error {
      color: ${theme.colors.danger};
    }

    .alert {
      display: flex;
      align-items: center;

      svg {
        font-size: ${theme.font.sizes.xsmall};
        margin-right: ${theme.spacings.tiny};
      }
    }
  `}
`

export const InputContainer = styled.div`
  ${({ theme }) => css`
    /* display: flex; */

    > button {
      margin-top: ${theme.spacings.xxxsmall};
    }
  `}
`
