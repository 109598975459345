import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CiDeliveryTruck } from 'react-icons/ci'
import { FiExternalLink, FiEye, FiX } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import { cpf, cnpj } from 'cpf-cnpj-validator'

import { Address, AddressInline } from '~/components/Address'
import Button from '~/components/Buttons/Button'
import Loading from '~/components/Loading'
import LocationsNear from '~/components/LocationsNear'
import { formatDateExibition } from '~/helpers'
import { getAccountInfo } from '~/helpers/account-info'
import { gtagEvent } from '~/lib/gtag'
import { togglePanelCustomer } from '~/modules/chat/store/actions'

import { Container } from './styles'

const SenderInfos = () => {
  const [customerInfos, setCustomerInfos] = useState()
  const [showLocationNear, setShowLocationNear] = useState(false)

  const { selectedChat, customerId } = useSelector(({ chats }) => chats)

  const dispatch = useDispatch()

  const { customer, getCustomersLoading } = useSelector(
    ({ customers }) => customers
  )

  const handlePanelCustomer = () => dispatch(togglePanelCustomer())

  useEffect(
    () => setCustomerInfos(customerId && customer ? customer : null),
    [customer, dispatch, customerId]
  )

  const senderName = useMemo(
    () => customerInfos?.name || selectedChat?.customerName,
    [customerInfos?.name, selectedChat]
  )

  const senderSince = useMemo(
    () =>
      customerInfos
        ? `desde ${formatDateExibition(customerInfos.createdAt)}`
        : 'sem cadastro',
    [customerInfos]
  )
  const socialId = useMemo(() => {
    const id = customer?.social_id

    if (cpf.isValid(id)) {
      return cpf.format(id)
    }

    if (cnpj.isValid(id)) {
      return cnpj.format(id)
    }

    return null
  }, [customer?.social_id])

  const addressCustomer = useMemo(
    () =>
      customerInfos && {
        address_street: customerInfos.address_street,
        address_number: customerInfos.address_number,
        address_complement: customerInfos.address_complement,
        address_neighborhood: customerInfos.address_neighborhood,
        address_city: customerInfos.address_city,
        address_state: customerInfos.address_state,
        address_country: customerInfos.address_country,
        address_postal_code: customerInfos.address_postal_code
      },
    [customerInfos]
  )

  // Handle show near locations
  const accountInfo = getAccountInfo()

  const accountName = useMemo(() => accountInfo?.name, [accountInfo])

  const handleShowNearLocations = useCallback(() => {
    gtagEvent({
      category: 'Chat',
      action: 'Exibir locais próximos ao cliente',
      label: `Locais mais próximos do cliente - ${accountName}`
    })

    setShowLocationNear(true)
  }, [accountName])

  return (
    <Container>
      <header>
        <div className="name">{senderName}</div>

        {/* <div className="since__date">Cliente {senderSince}</div> */}

        {customerInfos && (
          <div>
            <a
              href={`/customers/edit/${customer.id}`}
              target="_blank"
              rel="noreferrer"
            >
              Ver dados do cliente <FiExternalLink />
            </a>
          </div>
        )}

        <button
          type="button"
          onClick={handlePanelCustomer}
          className="icon__close"
        >
          <FiX />
        </button>
      </header>

      <Loading status={getCustomersLoading}>
        Carregando informações do usuário
      </Loading>

      {customerInfos && (
        <section>
          {/* {addressCustomer && (
            <div>
              <small>
                <AddressInline location={addressCustomer} />
              </small>
            </div>
          )} */}
          <div className="buttonLocations">
            {!showLocationNear && (
              <Button
                iconLeft={<FiEye />}
                template="light"
                text="Locais mais próximos do cliente"
                onClick={handleShowNearLocations}
                size="xsmall"
              />
            )}
          </div>

          {addressCustomer?.address_postal_code && showLocationNear && (
            <div className="store">
              <LocationsNear address={addressCustomer} showAddress />
            </div>
          )}
        </section>
      )}
    </Container>
  )
}

export default SenderInfos
