import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

import { isEqual } from 'lodash'

import {
  requestRetailMediaAccountID,
  formatSearchString,
  queryDatesDatepickerIsolated,
  getWhiteLabelPublisherId,
  getRetailMediaAccountType
} from '~/helpers'
import handleQueryParams from '~/helpers/handleQueryParams'
import { handleSortDirection } from '~/helpers/sortData'
import { useAppInfo } from '~/hooks/useAppInfo'
import useFetchSWR from '~/hooks/useFetchSWR'
import useQueryParams from '~/hooks/useQuery'
import { formatAds } from '~/modules/retailMedia/dtos/common/ads'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'
import { useAppSelector } from '~/store/hooks'

import { schema } from '../schemas'

const AdsTableContext = createContext<AdsTableContextData>(
  {} as AdsTableContextData
)

/**
 * Chaves usadas nas query params que alteram a
 * quantidade resultados e impactam na paginação
 */
const dynamicKeys: (keyof QueryParamsAds)[] = [
  'campaign_id',
  'publisher_id',
  'advertiser_id',
  'campaign_name',
  'product_sku',
  'account_info',
  'ad_status',
  'ad_type',
  'end_date',
  'quantity',
  'show_inactive',
  'start_date'
]

const AdsTableProvider = ({
  children,
  campaignAdType = null
}: AdsTableProviderProps) => {
  const insideCampaign = !!campaignAdType
  const { id: campaignId } = useParams()

  const searchParams = useQueryParams()

  const paramId = insideCampaign ? campaignId : searchParams.get('rmid')

  const isAdvertiser = getRetailMediaAccountType() === 'advertiser'

  const { isWhiteLabel } = useAppInfo()

  const shouldUseTokenId = useMemo(
    () => !insideCampaign && isWhiteLabel && isAdvertiser,
    [insideCampaign, isAdvertiser, isWhiteLabel]
  )

  const id = useMemo(
    () => (shouldUseTokenId ? getWhiteLabelPublisherId() : paramId),
    [paramId, shouldUseTokenId]
  )
  // Checa se tem id do publisher ou advertiser para saber se é um listagem geral.
  // Isso causa alterações no schema da tabela
  const isListAll = !id && !campaignAdType && !isWhiteLabel

  const [innerLoading, setInnerLoading] = useState(false)
  const [response, setResponse] = useState<AdResultsResponse>()
  const [showInactive, setShowInactive] = useState(true)
  const [queryParams, setQueryParams] = useState({} as QueryParamsAds)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [sortDirection, setSortDirection] = useState(null)
  const [sortKey, setSortKey] = useState(null)

  /**
   * Redux
   */

  const { retailMedia: retailMediaFilters } = useAppSelector(
    state => state.filters
  )

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  /**
   * Handle request
   */

  const params = useMemo(() => {
    const filterKey = insideCampaign
      ? 'campaign_id'
      : requestRetailMediaAccountID

    const data = {
      [filterKey]: id,
      ...queryDatesDatepickerIsolated(startDate, endDate),
      show_inactive: `${showInactive}`,
      page,
      quantity: perPage,
      ad_type: insideCampaign ? null : retailMediaFilters?.adType?.value,
      ad_status: retailMediaFilters?.adStatus?.value,
      campaign_name: insideCampaign ? null : retailMediaFilters?.campaignName,
      // Ainda não temos suporte para 2 queries no <Filters />
      product_sku:
        insideCampaign && campaignAdType !== 'product'
          ? null
          : retailMediaFilters?.productSKU || null,
      account_info: isListAll,
      order_direction: sortDirection,
      order_by: sortKey
    } as QueryParamsAds

    return data
  }, [
    campaignAdType,
    endDate,
    id,
    insideCampaign,
    isListAll,
    page,
    perPage,
    retailMediaFilters?.adStatus?.value,
    retailMediaFilters?.adType?.value,
    retailMediaFilters?.campaignName,
    retailMediaFilters?.productSKU,
    showInactive,
    sortDirection,
    sortKey,
    startDate
  ])

  useLayoutEffect(() => {
    if (isEqual(params, queryParams)) {
      return
    }

    const { shouldResetFixedKeys } = handleQueryParams({
      params,
      currentParams: queryParams,
      dynamicKeys
    })

    if (shouldResetFixedKeys) {
      params.page = 1
      setPage(1)
    }
    setInnerLoading(true)
    setQueryParams(params)
  }, [params, queryParams])

  const urlGetAds = useMemo(
    () => `${reatailmediaApi}/ad/results/v2?${formatSearchString(queryParams)}`,
    [queryParams]
  )

  const {
    data: dataSWR,
    error,
    isValidating,
    mutate
  } = useFetchSWR<AdResultsResponse>({
    url: urlGetAds,
    refreshInterval: 60000,
    revalidateOnFocus: false
  })

  useEffect(() => {
    if (dataSWR) {
      setResponse(dataSWR)
    }
  }, [dataSWR])

  const { data, currentPage, pages, total }: AdsDataMemo = useMemo(() => {
    const formattedData = response?.data
      ? formatAds({ ads: response.data })
      : null

    setInnerLoading(false)
    return {
      currentPage: response?.currentPage,
      pages: response?.pages,
      total: response?.total,
      data: formattedData
    }
  }, [response])

  /**
   * HANDLE UPDATE ADS AFTER
   */

  const handleMutate = useCallback(
    async (data: AdsHandleMutateProps) => {
      const { updatedKey, value, id } = data

      let mutateData = dataSWR

      if (['cpc', 'cpm'].includes(updatedKey)) {
        mutateData = {
          ...dataSWR,
          data: dataSWR.data.map(item =>
            item.id === id
              ? { ...item, settings: { ...item.settings, [updatedKey]: value } }
              : item
          )
        }
      }

      if (updatedKey === 'active') {
        mutateData = {
          ...dataSWR,
          data: dataSWR.data.map(item =>
            item.id === id ? { ...item, active: value } : item
          )
        }
      }

      await mutate(mutateData)
    },
    [dataSWR, mutate]
  )

  /**
   * Handle list
   */

  const handleActive = useCallback(
    ({ e }: HandleActiveProps) => {
      const value = e.target.checked
      setShowInactive(value)
    },
    [setShowInactive]
  )

  const handlePagination = useCallback((page: number) => {
    setPage(page)
  }, [])

  const handleItemsPerPageChange = useCallback(
    ({ value }: { value: number }) => {
      setPerPage(value)
    },
    []
  )

  const handleSortList = useCallback(
    (data: OnSortProps) => {
      const selectedKey = data.key
      const sortInitialDirection = data?.sortInitialDirection

      const response = handleSortDirection({
        selectedKey,
        currentSortDirection: sortDirection,
        currentSortKey: sortKey,
        sortInitialDirection
      })

      setSortDirection(response.sortDirection)
      setSortKey(response.sortKey)
    },
    [sortDirection, sortKey]
  )

  return (
    <AdsTableContext.Provider
      value={{
        data,
        error,
        innerLoading,
        isValidating,
        currentPage,
        insideCampaign,
        pages,
        page,
        perPage,
        total,
        schema: schema({ isListAll, insideCampaign, campaignAdType }),
        handleActive,
        handlePagination,
        handleItemsPerPageChange,
        sortDirection,
        sortKey,
        handleSortList,
        handleMutate,
        queryParams: formatSearchString(queryParams),
        showInactive
      }}
    >
      {children}
    </AdsTableContext.Provider>
  )
}

function useAdsTable(): AdsTableContextData {
  const context = useContext(AdsTableContext)

  if (!context) {
    throw new Error('useAdsTable must be used within an AdsTableProvider')
  }

  return context
}

export { AdsTableContext, AdsTableProvider, useAdsTable }
