import React from 'react'

import PropTypes from 'prop-types'

import { Button } from '../Buttons'
import Svg from '../svg'

import * as S from './styles'

const EmptyPage = ({
  title = 'Desculpe, não encontramos o que você procura.',
  subtitle,
  svgPath,
  titleButton,
  onClick,
  children,
  buttonProps = {}
}) => (
  <S.Container className="empty-page">
    <header>
      <h1>{title}</h1>
      {subtitle && <p className="subtitle">{subtitle}</p>}
    </header>

    {children && <div className="children">{children}</div>}

    {svgPath && (
      <S.Image>
        <Svg className="login-card__img-centered" src={svgPath} />
      </S.Image>
    )}

    {onClick && (
      <Button onClick={onClick} {...buttonProps}>
        {titleButton}
      </Button>
    )}
  </S.Container>
)

export default EmptyPage

EmptyPage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  svgPath: PropTypes.string,
  titleButton: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.shape({})
  ])
}

EmptyPage.defaultProps = {
  subtitle: '',
  onClick: null,
  svgPath: null,
  titleButton: null,
  children: null
}
