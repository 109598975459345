import { alternativeProducts } from './alternativesProducts';

export const stockOutCartItemModel = ({ item }) => ({
  id: item.item_id,
  status: item.status,
  match: item.name,
  title: item?.main_product?.name,
  quantity: item.quantity,
  sizePack: item.main_product?.size_pack,
  unitMeasurement: item.main_product?.unit_measurement,
  assets: { thumbnail: item.main_product?.asset?.[0] },
  tags: null,
  alternativeProducts: alternativeProducts(item.alternative_products),
});
