import { create } from './create'
import { dashboard } from './dashboard'
import { get } from './get'
import { list } from './list' // Ok
import { listCountStatus } from './listCountStatus'
import { patch } from './patch'
import reports from './report'
import { update } from './update'

export default {
  ...get,
  ...list,
  ...create,
  ...update,
  ...dashboard,
  ...reports,
  ...patch,
  ...listCountStatus
}
