import {
  getCampaignHistoryReportService,
  getCampaignsReportService
} from '~/modules/retailMedia/services/report'

import { types } from './constants'

/**
 * Get spot reports */

export const reportCampaigns: ReportCampaignsAction = queries => ({
  type: types.get,
  payload: getCampaignsReportService(queries)
})

/**
 * Get spot reports */

export const reportCampaignsRealtime: ReportCampaignsAction = queries => ({
  type: types.getRealtime,
  payload: getCampaignsReportService(queries)
})

/**
 * Get history reports */

export const reportCampaignsHistory: ReportCampaignsHistoryAction =
  queries => ({
    type: types.getHistory,
    payload: getCampaignHistoryReportService(queries)
  })
