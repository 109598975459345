import React from 'react';
import { FiMapPin, FiTag } from 'react-icons/fi';

import PropTypes from 'prop-types';

const ColumnExtra = ({ locationIds = [], code = null, isLoyalty = false }) => {
  const labelLocations = () => {
    const length = locationIds?.length || 0;

    switch (true) {
      case length === 1:
        return `${length} local selecionado`;

      case length > 1:
        return `${length} locais selecionados`;

      default:
        return 'Todos os locais';
    }
  };

  return (
    <div style={{ lineHeight: 1 }}>
      <div>
        <small>
          <FiMapPin /> {labelLocations()}
        </small>
      </div>

      {!!code && (
        <div>
          <small>
            <FiTag /> Código do cupom:
            <br />
            <strong style={{ color: 'var(--info)' }}>{code}</strong>
          </small>
        </div>
      )}

      {!!isLoyalty && (
        <div>
          <small style={{ color: 'var(--success)' }}>
            <strong>Fidelidade</strong>
          </small>
        </div>
      )}
    </div>
  );
};

export default ColumnExtra;

ColumnExtra.propTypes = {
  code: PropTypes.string,
  isLoyalty: PropTypes.bool.isRequired,
  locationIds: PropTypes.arrayOf([PropTypes.string]).isRequired,
};

ColumnExtra.defaultProps = {
  code: null,
};
