import styled, { css } from 'styled-components'

export const Container = styled.dl`
  --minHeight: 4rem;

  &.mh-3 {
    --minHeight: 3rem;
  }

  dd + dt {
    margin-top: 1rem;
  }
`

/**
 * Item
 */

export const Item = styled.dd`
  --templateColor: var(--primary);

  &.template {
    &__default {
      --templateColor: var(--primary);
    }
    &__primary {
      --templateColor: var(--primary);
    }
    &__info {
      --templateColor: var(--info);
    }
    &__success {
      --templateColor: var(--success);
    }
    &__success_alert {
      --templateColor: var(--successAlert);
    }
    &__warning {
      --templateColor: var(--warning);
    }
    &__danger {
      --templateColor: var(--danger);
    }
  }

  position: relative;

  line-height: 1.25rem;

  margin: 0 0 0 1.5rem;
  border-bottom: 1px solid transparent;
  border-top: 1px solid var(--tertiary);
  transition: 0.3s ease all;

  .vertical_timeline {
    &-wrapper_main {
      position: relative;

      display: flex;
      align-items: center;
      gap: 0.5rem;

      width: 100%;
      min-height: var(--minHeight);

      &:before {
        content: '';
        position: absolute;
        top: calc(var(--minHeight) / 2);
        left: -1.5rem;
        transform: translateY(-50%);

        height: 1rem;
        width: 1rem;

        border: 1px solid var(--templateColor);
        border-radius: 50%;

        background: transparent;

        transition: 0.3s ease all;
      }
    }
  }

  ${Container} > dt + & {
    border-top: 1px solid transparent;
  }

  ${Container} > dt:first-child + & {
    border-top: 1px solid transparent;
    > .vertical_timeline {
      &-wrapper_main {
        &:before {
          background: var(--templateColor);
          border: none;
        }
      }
    }
  }

  &:hover {
    background: var(--light);

    > .vertical_timeline {
      &-wrapper_main {
        &:before {
          filter: brightness(1.25);
          background: var(--templateColor);
        }
      }
    }
  }

  & + dd {
    .vertical_timeline {
      &-wrapper_main {
        &:after {
          content: '';
          position: absolute;
          left: -1rem;
          top: 0;
          transform: translateY(-50%);

          height: calc(var(--minHeight) * 0.375);
          width: 1px;
          background: var(--templateColor);
        }
      }
    }
  }
`

/**
 * Time
 */

export const Time = styled.div`
  ${({ theme }) => css`
    padding: 0 0 0 0.5rem;
    font-size: ${theme.font.sizes.small};
  `}
`

/**
 * Icon
 */
export const Icon = styled.div`
  ${({ theme }) => css`
    padding: 0 1.25rem;

    font-size: ${theme.font.sizes.xlarge};
    color: var(--templateColor);
  `}
`

/**
 * Title
 */
export const Title = styled.div`
  ${({ theme }) =>
    css`
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.bold};
    `}
`

/**
 * Content
 */
export const Content = styled.section`
  --translateY: calc(-0.25 * var(--minHeight));
  --height: calc(100% + (var(--minHeight) / 2));

  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -1rem;
    top: 0;
    transform: translateY(var(--translateY));

    height: var(--height);
    width: 1px;
    background: var(--templateColor);
  }
`
