import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const transparentDarkButtonTemplate = ({ theme }: ButtonTemplatesProps) => css`
  background: transparent;
  color: var(--dark);

  min-width: unset;
  height: auto;
  padding: 0.125rem 0.25rem;

  &:not(:disabled) {
    &:hover {
      color: var(--primary);
    }
  }
`

export default transparentDarkButtonTemplate
