import * as S from './styles'

/**
 *
 * @param {node} children Text for box
 * @param {boolean} active State on parent component
 *
 */

const Alert: React.FC<AlertProps> = ({
  children,
  template = 'warning',
  active = true
}) => (
  <S.Container
    className="alertWrapper"
    data-active={active}
    data-template={template}
  >
    {children}
  </S.Container>
)

export default Alert
