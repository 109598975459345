import { toastr } from 'react-redux-toastr'

import i18next from 'i18next'
import { Middleware } from 'redux'

/**
 * DOC: https://gitlab.com/newtail/newtail-react-app/-/wikis/Middleware-do-Redux-para-tratar-request
 */

const middleware: Middleware = store => {
  const { dispatch } = store

  /**
   *
   * 20/09/2023 - Comentando pois parece que seu uso não está afetando nada
   */
  const getReducerValidKeys = (action: DispatchActionProps) => {
    const copyAction = { ...action }

    const listNotValidKeys: DispatchExtraKeys[] = [
      'titleSuccessFeedBack',
      'successFeedbackMsg',
      'onSuccess',
      'onError',
      'errorFeedbackMsg',
      'getErrorFeedbackMsg',
      'hideError',
      'hideSuccess'
    ]

    listNotValidKeys.forEach((el: DispatchKeys) => delete copyAction[el])

    return copyAction
  }

  const showLoading = (action: DispatchActionProps, isPromise: boolean) => {
    const suffixes = ['_PENDING', '_FULFILLED', '_REJECTED']

    const shouldShowLoading = suffixes.reduce((bool, el) => {
      if (action.type && action.type.endsWith(el)) {
        bool = false
      }

      return bool
    }, true)

    if (shouldShowLoading && isPromise) {
      dispatch({
        type: `${action.type}_PENDING`,
        values: action.values
      })
    }
  }

  const handleSuccess = (resp: any, action: DispatchActionProps) => {
    // const reducerValidKeys = getReducerValidKeys(action)

    const {
      onSuccess,
      titleSuccessFeedBack = `${i18next.t('common:capitalize', {
        value: i18next.t('common:words.success')
      })}!`,
      successFeedbackMsg,
      hideSuccess = false,
      showSuccessCallback
    } = action

    dispatch({
      // ...reducerValidKeys,
      type: `${action.type}_FULFILLED`,
      payload: resp.data,
      values: action.values
    })

    if (typeof onSuccess === 'function') {
      onSuccess(resp)
    }

    const showToast =
      typeof showSuccessCallback === 'function'
        ? !!showSuccessCallback(resp)
        : true

    if (successFeedbackMsg && !hideSuccess && showToast) {
      toastr.success(titleSuccessFeedBack, successFeedbackMsg)
    }
  }

  const handleError = (error: any, action: DispatchActionProps) => {
    const { onError, getErrorFeedbackMsg, errorFeedbackMsg, hideError } = action

    const defaultMessage = i18next.t('common:messages.errorOccurred.message')

    if (typeof onError === 'function') {
      onError(error)
    }

    let msg =
      typeof getErrorFeedbackMsg === 'function'
        ? getErrorFeedbackMsg(error)
        : errorFeedbackMsg || defaultMessage

    dispatch({
      type: `${action.type}_REJECTED`,
      payload: msg,
      values: action.values
    })
    const notAuthenticated = error.response && error.response.status === 401

    if (notAuthenticated) {
      msg = i18next.t('auth:messages.sessionExpired')
    }

    if (!hideError) {
      toastr.error('Erro', msg)
    }
  }

  return next => (action: DispatchActionProps) => {
    const promise = action.payload
    const isPromise = promise && promise.then

    if (isPromise) {
      promise
        .then((resp: any) => handleSuccess(resp, action))
        .catch((error: any) => handleError(error, action))
    }

    showLoading(action, isPromise)

    next(action)
  }
}

export default middleware
