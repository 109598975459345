import { handleSortDirection } from '~/helpers/sortData'

import { types } from '../constants'

const index = {
  pending: `${types.list}_PENDING`,
  fulfilled: `${types.list}_FULFILLED`,
  rejected: `${types.list}_REJECTED`,
  setPage: types.listSetPage,
  setPerPage: types.listPerPage,
  sort: types.listSort
}

const parentKey = 'placements'
const key = 'list'

export const list = {
  [index.pending]: ({ draft }: ActionData) => {
    draft[parentKey][key].loading = true
    draft[parentKey][key].error = null
  },
  [index.fulfilled]: ({ draft, action }: ActionData) => {
    draft[parentKey][key].data = action.payload.data
    draft[parentKey][key].pages = action.payload.pages
    draft[parentKey][key].total = action.payload.total
    draft[parentKey][key].page = action.payload.page

    draft[parentKey][key].loading = false
    draft[parentKey][key].error = null
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft[parentKey][key].loading = false
    draft[parentKey][key].error = action.payload
  },
  // Pagination
  [index.setPage]: ({ draft, action }: ActionData) => {
    draft[parentKey][key].page = action.payload
  },
  [index.setPerPage]: ({ draft, action }: ActionData) => {
    draft[parentKey][key].perPage = action.payload
  },
  [index.sort]: ({ draft, action }: ActionData<OnSortProps>) => {
    const selectedKey = action.payload.key
    const sortInitialDirection = action.payload?.sortInitialDirection

    const currentSortDirection =
      draft[parentKey][key].sortDirection ||
      draft[parentKey][key].sortDirectionDefault

    const currentSortKey =
      draft[parentKey][key].sortKey || draft[parentKey][key].sortKeyDefault

    const { sortDirection, sortKey } = handleSortDirection({
      selectedKey,
      currentSortDirection,
      currentSortKey,
      sortInitialDirection
    })

    draft[parentKey][key].sortDirection = sortDirection
    draft[parentKey][key].sortKey = sortKey
  }
}
