import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Container } from '~/components/Container'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import FormLocation from '../_elements/FormLocation'
import { resetMapCoordinates } from '../actions'

const LocationsNew = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetMapCoordinates())
  }, [dispatch])

  return (
    <Container>
      <PageTitle title="Locais > Adicionar" />

      <HeaderPages
        title="Adicionar novo local"
        labelBreadcrumb="Locais"
        backTo="/locations/list"
      />

      <FormLocation initialData={{ merge_products: true }} />
    </Container>
  )
}
export default LocationsNew
