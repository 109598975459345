import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { FiEdit, FiMinus, FiSave, FiX } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import InputMoney from '~/components/unform/Input/InputMoney'
import { formatMoney, unformatMoney } from '~/helpers'
import { updateOrderDiscount } from '~/pages/orders/actions'

import * as S from './styles'

const TotalDiscount = ({
  isEditItemMode,
  maxDiscountValue,
  maxDiscountPercent,
  onChange,
  subTotalValue,
  canEditOrder,
  totalDiscount = 0
}) => {
  const formRef = useRef()
  const dispatch = useDispatch()

  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (isEditItemMode) {
      setEdit(false)
    }
  }, [isEditItemMode])

  const {
    updateLoading,
    orderData: { id }
  } = useSelector(state => state.order)

  const handleChange = useCallback(
    e => {
      const value = e.formattedValue

      onChange(Number(unformatMoney(value)))
    },
    [onChange]
  )

  const handleShortAction = useCallback(
    percent => {
      const subTotalValueFormatted = Number(subTotalValue)

      const calc = (percent / 100) * subTotalValueFormatted

      formRef.current.setFieldValue('totalDiscount', calc)

      onChange(calc)
    },
    [onChange, subTotalValue]
  )

  const formattedValue = useMemo(
    () => `${formatMoney(totalDiscount)}`,
    [totalDiscount]
  )

  const formattedMaxValue = useMemo(
    () =>
      `Máximo ${formatMoney(maxDiscountValue)}(${maxDiscountPercent * 100}%)`,
    [maxDiscountPercent, maxDiscountValue]
  )

  const calcAllowedValues = values => {
    const formattedCurrentValue = Number(unformatMoney(values.formattedValue))

    return formattedCurrentValue <= maxDiscountValue
  }

  // Handles

  const handleCancelEdit = useCallback(() => {
    setEdit(false)
  }, [])

  const handleSaveDiscount = useCallback(
    data => {
      const { totalDiscount } = data
      const total_discount = Number(unformatMoney(totalDiscount.value))

      dispatch(updateOrderDiscount({ id, total_discount }))

      setEdit(false)
    },
    [dispatch, id]
  )

  const discounts = [5, 10, 15, 20]

  return canEditOrder || totalDiscount > 0 ? (
    <S.Container isEditMode={edit}>
      <header>
        <strong>Desconto total:</strong>
        {/* {Boolean(maxDiscountValue) && (
          <>
            <br />
            <small>{formattedMaxValue}</small>
          </>
        )} */}
      </header>

      {!isEditItemMode && canEditOrder && !edit && (
        <aside>
          <Button
            text="Editar"
            iconLeft={<FiEdit />}
            className="button__edit"
            size="small"
            customWidth="auto"
            onClick={() => setEdit(true)}
            loading={updateLoading}
          />
        </aside>
      )}

      {!edit && <div className="value">{formattedValue}</div>}

      {canEditOrder && edit && (
        <Form
          ref={formRef}
          initialData={{ totalDiscount: formattedValue }}
          onSubmit={handleSaveDiscount}
        >
          <div className="fields">
            <div className="buttons">
              {discounts.map(value => (
                <Button
                  key={value}
                  onClick={() => handleShortAction(value)}
                  template="light"
                  size="xsmall"
                  customWidth="auto"
                  type="button"
                  text={`${value}%`}
                />
              ))}
            </div>

            <div className="input">
              <InputMoney
                placeholder="Insira um valor"
                name="totalDiscount"
                required
                type="money"
                onChange={handleChange}
                isAllowed={calcAllowedValues}
              />
            </div>
          </div>

          {edit && (
            <footer>
              <Button
                text="Cancelar"
                iconLeft={<FiX />}
                className="button__cancel"
                size="small"
                customWidth="auto"
                onClick={handleCancelEdit}
                template="transparentDanger"
                loading={updateLoading}
              />

              <Button
                text="Salvar"
                iconLeft={<FiSave />}
                size="small"
                customWidth="auto"
                type="submit"
                template="success"
                loading={updateLoading}
              />
            </footer>
          )}
        </Form>
      )}
    </S.Container>
  ) : (
    <></>
  )
}

export default TotalDiscount

TotalDiscount.propTypes = {
  isEditItemMode: PropTypes.bool.isRequired,
  canEditOrder: PropTypes.bool.isRequired,
  totalDiscount: PropTypes.number.isRequired,
  maxDiscountValue: PropTypes.number,
  subTotalValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxDiscountPercent: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

TotalDiscount.defaultProps = {
  maxDiscountPercent: null,
  maxDiscountValue: null,
  subTotalValue: null
}
