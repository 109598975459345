import React from 'react';
import { FiTrash } from 'react-icons/fi';

import { DeleteButton } from './styles';

const DeleteAction = () => {
  return (
    <DeleteButton type="button">
      <FiTrash />
    </DeleteButton>
  );
};

export default DeleteAction;
