import { listReport } from '~/modules/retailMedia/services/industry/publisher'
import { t } from 'i18next'

import { getPublisherService } from '../../services/publisher'
import { types } from './constants'

/**
 * Get all */
export const listPublisher = (queries = {}) => ({
  type: types.list,
  payload: listReport(queries),
  errorFeedbackMsg: t('rm:actions.publisher.list.error')
})

/**
 * Sort list
 */
export const setSortListPublisher = (data: OnSortProps) => ({
  type: types.listSort,
  payload: data
})

/**
 * Handle page */
export const setPageIndustryPublisher = (value: number) => ({
  type: types.page,
  payload: value
})

/**
 * Handle items por page */
export const setPerPageIndustryPublisher = (value: number) => ({
  type: types.perPage,
  payload: value
})

/**
 * Get advertiser info
 */
export const getPublisher = ({
  id,
  params = {}
}: {
  id: string
  params: object
}) => ({
  type: types.getPublisher,
  payload: getPublisherService({ url: { id }, params })
})
