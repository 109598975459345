import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { FiRefreshCw } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { hideSidebar } from '~/components/sidebar/sidebar-actions'
import ThumbProduct from '~/components/ThumbProduct'
import { Image } from '~/pages/products/list/elements/Product/styles'
import {
  getShoppingCartData,
  searchShoppingCart
} from '~/store/modules/shoppingCart/actions'

import * as S from './styles'

const ShoppingCartView = () => {
  const [changeItem, setChangeItem] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const { id } = useParams()

  const {
    shoppingCart,
    searchOffersResults,
    loadingSearchOffers,
    errorSearchOffers
  } = useSelector(({ shoppingCart }) => shoppingCart)

  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(hideSidebar())
  }, [dispatch, id])

  useEffect(() => {
    dispatch(getShoppingCartData({ id }))
  }, [dispatch, id])

  useLayoutEffect(() => {
    if (changeItem && searchQuery !== changeItem.title) {
      setSearchQuery(changeItem.title)
    }
  }, [changeItem, searchQuery])

  const query = useMemo(
    () => ({
      query: searchQuery,
      location_id: shoppingCart?.location_id,
      customer_id: shoppingCart?.customer.id,
      quantity: 50,
      page: 1,
      host: 'zonasul.newtail.com.br'
    }),
    [searchQuery]
  )

  useEffect(() => {
    if (searchQuery) {
      dispatch(searchShoppingCart({ query }))
    }
  }, [changeItem, searchQuery, query])

  return (
    <>
      {/* Meta <title> */}
      <PageTitle title="Carrinhos > Aprovação" />

      {/* <Filters locations shoppingCartStatus /> */}

      <S.Container>
        <div className="items">
          {shoppingCart?.rawItems?.map(item => (
            <div key={item.id} className={`item ${item.status}`}>
              <Image className="item__image">
                <ThumbProduct
                  url={item.assets?.thumbnail?.url}
                  title={item.title}
                />
              </Image>
              <div className="item__info">
                <strong>{item.title}</strong>
                <br />
                <small>Cliente buscou: {item.match}</small> -
                <small>Sistema buscou: {item.query}</small>
              </div>
              <div className="item__sidebar">
                <Button
                  text="Trocar"
                  iconLeft={<FiRefreshCw />}
                  size="xsmall"
                  customWidth="auto"
                  onClick={() => setChangeItem(item)}
                />
              </div>
            </div>
          ))}
        </div>
        {/*  */}
        <aside className="actions">
          <div className="list">
            {searchQuery && <header>Buscando por: {searchQuery}</header>}

            {changeItem && (
              <div>
                <div className={`item ${changeItem.status}`}>
                  <Image className="item__image">
                    <ThumbProduct
                      url={changeItem.assets?.thumbnail?.url}
                      title={changeItem.title}
                    />
                  </Image>
                  <div className="item__info">
                    <strong>{changeItem.title}</strong>
                  </div>
                </div>
              </div>
            )}

            <Loading status={loadingSearchOffers}>
              Buscando ofertas relecionadas ao produto: {searchQuery}
            </Loading>

            <div className="results">
              {searchOffersResults?.data?.map(item => (
                <div className="results__cards">
                  <Image className="card__image">
                    <ThumbProduct
                      url={item.asset?.[0]?.url}
                      title={item.name}
                    />
                  </Image>
                  <div className="card__name">{item.name}</div>
                </div>
              ))}
            </div>
          </div>
        </aside>
      </S.Container>
    </>
  )
}

export default ShoppingCartView
