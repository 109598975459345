import { lighten } from 'polished';
import styled, { css } from 'styled-components';

import { device } from '~/styles/device';

const containerModifiers = {
  active: () => css`
    max-height: 100%;
    padding: 0.75rem 0;
    opacity: 1;

    @media ${device.laptop} {
      padding: 1rem 0;
      max-height: 100%;
    }

    border-width: 1px;
    margin-bottom: 1.25rem;
  `,
  absolute: () => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    padding: 0.5rem 0;

    @media ${device.laptop} {
      padding: 0.5rem 0;
    }
  `,
};

export const Container = styled.div`
  ${({ theme, active, elementPosition }) => css`
    z-index: 20;
    overflow: hidden;

    max-height: 0;
    padding: 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: ${theme.border_radius};
    background: ${lighten(0.4, theme.danger)};
    opacity: 0;

    transition: all 0.3s ease-in-out;

    ${active && containerModifiers.active()}
    ${elementPosition === 'absolute' && containerModifiers.absolute()}


  .alertBox {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;
      max-width: 100%;
      max-height: 100%;

      padding: 0 0.5rem;
      font-weight: 500;
      line-height: 1.25;

      > .childrenWrapper {
        margin-bottom: 0.25rem;
        text-align: center;
      }

      > .actionButtons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        > * {
          margin: 0 0.5rem;
        }
      }
    }

    @media ${device.laptop_max} {
      .alertBox {
        > span {
          flex: 1 1 100%;
          text-align: center;
          margin-bottom: 0.5rem;
        }

        button {
          min-width: 40%;
        }
      }
    }
  `}
`;
