import * as Yup from 'yup'

export const requestMinCostValidator = Yup.object().shape({
  type: Yup.string().oneOf(['in_store']).required(),
  ad_type: Yup.string().oneOf(['digital_signage']).required(),
  start_at: Yup.string().required(),
  end_at: Yup.string().required(),
  settings: Yup.object().shape({
    places: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required()
        })
      )
      .required()
  }),
  video: Yup.object()
    .shape({
      assets: Yup.array()
        .of(
          Yup.object().shape({
            url: Yup.string().url().required(),
            type: Yup.string().oneOf(['video']).required()
          })
        )
        .required()
    })
    .required()
})
