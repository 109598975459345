import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import SelectSearchable from '~/components/SelectSearchable'
import { useAppSelector } from '~/store/hooks'
import { setchatBotStatus } from '~/store/modules/filters/actions'

/**
 * Componente para filtrar a listagem por canais.
 * Ele possibilita a seleção de diversos locais e mantém no estado global: filters.channel.
 *
 * Não recebe nenhum parâmetro e retorna um select procurável.
 *
 * @example <FilterChatBotStatus />
 */

export const statusBotOptions = [
  { label: 'Qualquer status do bot', value: 'null' },
  {
    label: 'Bot ativado',
    value: 'true'
  },
  {
    label: 'Bot desativado',
    value: 'false'
  }
]

const FilterChatBotStatus = () => {
  const dispatch = useDispatch()

  const { chatBotStatus: filteredchatBotStatus } = useAppSelector(
    state => state.filters
  )

  const handleChangeStatusBot = useCallback(
    selectedchatBotStatus => {
      dispatch(
        setchatBotStatus({ filteredchatBotStatus: selectedchatBotStatus })
      )
    },
    [dispatch]
  )

  // const defaultValue = useMemo(
  //   () => ({ label: 'Bot desativado', value: 'false' }),
  //   []
  // )

  // useEffect(() => {
  //   handleChangeStatusBot({ ...defaultValue })
  // }, [defaultValue, handleChangeStatusBot])

  return (
    <SelectSearchable
      placeholder="Filtrar por status do bot"
      options={statusBotOptions}
      onChange={handleChangeStatusBot}
      defaultValue={[filteredchatBotStatus]}
    />
  )
}

export default FilterChatBotStatus
