import styled, { css } from 'styled-components';

const containerModifiers = {
  hasSession: theme => css`
    &:after {
      content: '';
      position: absolute;
      top: -0.25rem;
      left: -0.25rem;

      height: 0.75rem;
      width: 0.75rem;
      border-radius: 50%;

      background: var(--colorStatus);
      transition: 0.1s ease-in-out all;
    }
  `,
  selectChatsMode: theme => css`
    background: transparent;

    &:after {
      height: 0rem;
      width: 0rem;
    }
  `,
};

export const Container = styled.div`
  ${({
    isSelected,
    selectChatsMode,
    theme,
    hasSession,
    botActive,
    useBot,
  }) => css`
    --defaultColor: ${botActive ? theme.colors.success : theme.colors.danger};
    --colorStatus: ${useBot ? 'var(--defaultColor)' : theme.colors.warning};

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    height: 32px;
    width: 32px;
    border-radius: 50%;

    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.inative};

    font-size: 1.5rem;

    transition: 0.2s ease-in-out all;

    color: ${theme.gray};

    img {
      border-radius: 50%;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .initials {
      font-size: 0.875rem;
      font-weight: 600;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      outline: none;
      border: none;
      border-radius: 0.25rem;

      height: 1.5rem;
      width: 1.5rem;
      border: 2px solid ${theme.colors.dark};

      background-color: ${theme.colors.white};
      font-size: 2rem;
      color: ${isSelected ? theme.colors.success : 'currentColor'};
    }

    ${hasSession && containerModifiers.hasSession(theme)}
    ${selectChatsMode && containerModifiers.selectChatsMode(theme)}
  `}
`;
