import { formatMoneyV2, formatNumberWithDots, formatPercent } from '~/helpers'

type Formatters = {
  [key in MetricsFormats]: (data: string | number | null) => string
}

const formatters: Formatters = {
  unit: value => formatNumberWithDots(value),
  money: value => formatMoneyV2({ value }),
  percent: value => formatPercent(value)
}

type FormatNumberByTypeProps = {
  type: MetricsFormats
  value: string | number | null
}

export const formatNumberByType = ({
  type,
  value
}: FormatNumberByTypeProps) => {
  return formatters[type](value)
}
