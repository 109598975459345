import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.spacings.xxsmall};
  `}
`;

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 3rem;
    max-width: 3rem;
    max-height: 3rem;
    margin-right: ${theme.spacings.medium};
    border: 0.25rem solid ${theme.colors.light};

    border-radius: ${theme.border.radius.large};

    background-color: ${theme.colors.light};

    img {
      max-width: 3rem;
      max-height: 3rem;
      object-fit: cover;
    }
  `}
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }

  ${({ theme }) => css`
    .product-name {
      font-weight: ${theme.font.bolder};
      text-transform: capitalize;
      -webkit-line-clamp: 2;
      display: -webkit-inline-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1rem;
    }

    .unit-measurement {
      font-size: ${theme.font.sizes.xsmall};
    }

    .product-external-id {
      > p {
        display: inline-flex;
        align-items: center;

        color: ${theme.colors.gray};
        font-size: ${theme.font.sizes.xsmall};
      }

      > svg {
        margin-right: ${theme.spacings.tiny};
      }
    }
  `}
`;
