import React, { useCallback, useEffect, useState } from 'react';

import { Scope } from '@unform/core';

import Loading from '~/components/Loading';
import { FormGroup, Label, Checkbox } from '~/components/unform';
import { brazilianStates } from '~/helpers';
import useFetchSWR from '~/hooks/useFetchSWR';

import * as S from './styles';

const LocationsRadioList = ({
  label = 'Escolha os locais',
  pathUnform = 'location_ids',
}) => {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState();

  const { data, error } = useFetchSWR({
    url: `/api/location/minified`,
  });

  useEffect(() => {
    if (data) {
      const { data: locationsAPI } = data;

      const locationsWithState = locationsAPI.reduce((acc, location) => {
        if (!acc[`${location.address_state}`]) {
          acc[`${location.address_state}`] = [];
        }

        acc[`${location.address_state}`].push(location);

        return acc;
      }, {});

      const formattedLocations = locationsFiltered =>
        locationsFiltered
          .filter(({ active }) => active)
          .map(item => ({
            value: item.id,
            label: item.name,
          }));

      const handleLocations = Object.keys(locationsWithState).map(item => ({
        label: brazilianStates[`${item}`],
        options: formattedLocations(locationsWithState[`${item}`]),
      }));

      const customLocations = [];

      customLocations.push({
        label: 'Todos os locais',
        value: 'all',
      });

      setLocations([...customLocations, ...handleLocations]);

      setLoading(false);
    }

    if (error) {
      const formattedLocations = [
        {
          value: null,
          label: 'Erro ao carregar locais...',
        },
      ];

      setLocations(formattedLocations);
      setLoading(false);
    }
  }, [data, error]);

  //
  const [selectAll, setSelectAll] = useState(true);

  const handleToggleAll = useCallback(() => {
    setSelectAll(oldState => !oldState);
  }, []);

  return (
    <FormGroup style={{ flex: 1 }}>
      <Label htmlFor="location_ids" isRequired text={label} />

      <Loading status={loading}>Carregando locais...</Loading>

      <Scope path={pathUnform}>
        {locations?.map(
          region =>
            region?.options && (
              <S.Group key={region?.label}>
                <Label text={region.label || 'Região sem nome definido'} />

                <S.LocationGroup>
                  {region.options?.map(location => (
                    <S.LocationItem key={location.value}>
                      <Checkbox
                        name={`${location.value}`}
                        id={`${location.value}`}
                        value={location.value}
                        label={location.label}
                        // disabled={selectAll}
                      />
                    </S.LocationItem>
                  ))}
                </S.LocationGroup>
              </S.Group>
            ),
        )}
      </Scope>
    </FormGroup>
  );
};

export default LocationsRadioList;
