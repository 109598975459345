import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { formatMoney, unformatMoney } from '~/helpers'

import TotalDiscount from '../TotalDiscount'

import * as S from './styles'

const TotalValues = ({ isEditMode, canEditOrder = false, subTotalItems }) => {
  const [totalDiscount, setTotalDiscount] = useState(0)

  const { orderData } = useSelector(state => state.order)

  const {
    total_discount,
    sub_total,
    shipping_cost,
    service_fee,
    total_price,
    payments,
    promotion_discount,
    shipping_discount,
    coupon_discount
  } = orderData

  useEffect(() => {
    if (total_discount) {
      setTotalDiscount(total_discount || 0)
    }
  }, [total_discount])

  const subTotalValue = useMemo(() => {
    const finalValue = subTotalItems
    return formatMoney(finalValue)
  }, [subTotalItems])

  const totalValue = useMemo(
    () =>
      formatMoney(
        Number(unformatMoney(subTotalValue)) +
          (service_fee || 0) +
          (shipping_cost || 0)
      ),
    [service_fee, shipping_cost, subTotalValue]
  )

  const maxDiscountPercent = 0.2

  const maxDiscountValue = useMemo(() => {
    const subTotal = Number(unformatMoney(subTotalValue)).toFixed(2)

    const maxValue = subTotal * maxDiscountPercent

    return maxValue
  }, [subTotalValue])

  const handleTotalDiscount = useCallback(value => setTotalDiscount(value), [])

  return (
    <S.Container>
      {/* Subotal */}

      <S.TotalRow>
        <strong className="label">Subtotal sem deconto:</strong>
        <span className="value">
          {isEditMode ? subTotalValue : formatMoney(sub_total)}
        </span>
      </S.TotalRow>

      {Boolean(shipping_cost) && (
        <S.TotalRow>
          <strong className="label">Custo de frete:</strong>
          <span className="value">{formatMoney(shipping_cost || 0)}</span>
        </S.TotalRow>
      )}

      {/* Total Discount */}
      <TotalDiscount
        isEditItemMode={isEditMode}
        canEditOrder={canEditOrder}
        maxDiscountValue={maxDiscountValue}
        maxDiscountPercent={maxDiscountPercent}
        totalDiscount={totalDiscount}
        subTotalValue={subTotalItems}
        onChange={handleTotalDiscount}
      />

      {/* Service fee */}
      {Boolean(service_fee) && (
        <S.TotalRow>
          <strong className="label">Taxa de serviço:</strong>
          <span className="value">{formatMoney(service_fee || 0)} </span>
        </S.TotalRow>
      )}

      {promotion_discount > 0 && (
        <S.TotalRow className="discountDetails">
          <span className="label">Descontos promocionais:</span>
          <span className="value">{formatMoney(promotion_discount)}</span>
        </S.TotalRow>
      )}

      {coupon_discount > 0 && (
        <S.TotalRow className="discountDetails">
          <span className="label">Descontos cupom:</span>
          <span className="value">{formatMoney(coupon_discount)}</span>
        </S.TotalRow>
      )}

      {shipping_discount > 0 && (
        <S.TotalRow className="discountDetails">
          <span className="label">Desconto entrega:</span>
          <span className="value">{formatMoney(shipping_discount)}</span>
        </S.TotalRow>
      )}

      {!!payments?.[0]?.cash_value && (
        <S.TotalRow>
          <strong className="label">Troco para:</strong>
          <span className="value">
            {formatMoney(payments?.[0]?.cash_value)}
          </span>
        </S.TotalRow>
      )}

      <S.TotalRow className="totalValue">
        <span className="label">Total:</span>
        <span className="value">
          {isEditMode ? totalValue : formatMoney(total_price)}
        </span>
        <small className="details">
          Valor total com descontos, custos e taxas aplicadas
        </small>
      </S.TotalRow>
    </S.Container>
  )
}

export default TotalValues

TotalValues.propTypes = {
  subTotalItems: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  canEditOrder: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired
}
