import React from 'react';
import { FiPlus } from 'react-icons/fi';

import { ButtonNavLink } from '~/components/Buttons';
import HeaderPages from '~/components/HeaderPages';
import PageTitle from '~/components/PageTitle';

import fakeData from '../fakeListData.json';
import TradeCard from './_elements/TradeCard';

const TradeList = () => {
  return (
    <>
      <PageTitle title="Campanhas > Listagem" />

      <HeaderPages
        title="Listagem de campanhas"
        className="has-datepicker"
        sideRightComponent={
          <ButtonNavLink
            to="/trade/create"
            text="Criar campanha"
            iconLeft={<FiPlus />}
          />
        }
      />

      {fakeData.map(item => (
        <TradeCard data={item} key={item.id} />
      ))}
    </>
  );
};

export default TradeList;
