import { NavLink } from 'react-router-dom'

import { t } from 'i18next'

import { LogoStore } from '~/components/LogoStore'

export const schema: TableSchema<AdvertiserWithMetrics> = {
  publisher: {
    title: 'Publisher',
    sortKey: 'name',
    render: rowData => (
      <NavLink
        to={`/retail-media/campaigns?rmid=${rowData.id}`}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <LogoStore
          alt={rowData.title}
          linkLogo={rowData.logo_url}
          backgroundLogo={rowData.account_theme.header_color}
          size="medium"
        />
        <div style={{ lineHeight: 1, marginLeft: '.5rem' }}>
          <strong className="title">{rowData.title}</strong>
        </div>
      </NavLink>
    ),
    className: 'order__details col__infos col__title',
    showTotal: true
  },
  balance: {
    title: t('rm:balanceInWallet'),
    sortKey: 'balance',
    sortInitialToggleDirection: 'DESC',
    render: rowData => (
      <div style={{ lineHeight: 1.125 }}>
        {rowData?.balance}
        {rowData?.checkingAccountId && (
          <>
            <br />
            <NavLink
              to={`/retail-media/wallet/${rowData.checkingAccountId}/movimentations?rmid=${rowData.id}`}
            >
              <small>{t('rm:accessWallet')}</small>
            </NavLink>
          </>
        )}
      </div>
    )
  },
  total_campaigns: {
    sortKey: 'total_campaigns',
    sortInitialToggleDirection: 'DESC',
    title: t('rm:Campaigns'),
    render: ({ metrics }) => metrics.totalCampaigns
  },
  impressions: {
    sortKey: 'impressions',
    sortInitialToggleDirection: 'DESC',
    title: t('rm:metrics.impressions'),
    render: ({ metrics }) => metrics.impressions
  },
  clicks: {
    sortKey: 'clicks',
    sortInitialToggleDirection: 'DESC',
    title: t('rm:metrics.clicks'),
    render: ({ metrics }) => metrics.clicks
  },
  ctr: {
    title: t('rm:metrics.ctr'),
    helpText: t('rm:metrics.ctr.tooltip'),
    sortKey: 'ctr',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics.ctr
  },
  totalSpent: {
    sortKey: 'total_spent',
    sortInitialToggleDirection: 'DESC',
    title: t('rm:metrics.cost'),
    render: ({ metrics }) => metrics.totalSpent
  },
  conversion: {
    sortKey: 'conversions',
    sortInitialToggleDirection: 'DESC',
    title: t('rm:metrics.conversions'),
    render: ({ metrics }) => metrics.conversions
  },
  conversion_rate: {
    sortKey: 'conversion_rate',
    sortInitialToggleDirection: 'DESC',
    title: t('rm:metrics.conversion_rate'),
    render: ({ metrics }) => metrics.conversionRate
  },
  income: {
    sortKey: 'income',
    sortInitialToggleDirection: 'DESC',
    title: t('rm:metrics.sales'),
    render: ({ metrics }) => metrics.income
  },
  roas: {
    title: t('rm:metrics.roas'),
    helpText: t('rm:metrics.roas.tooltip'),
    sortKey: 'roas',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics.roas
  },
  adCost: {
    title: t('rm:metrics.adCost'),
    sortKey: 'adcost',
    sortInitialToggleDirection: 'DESC',
    render: ({ metrics }) => metrics.adcost
  }
}
