import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/place`

/**
 * Get all places
 */
export const listPlacesKey = 'listPlaces'
export const listPlacesService = (params?: RequestListPlacesParams) =>
  axios.get<ResponseListPlaces>(baseURL, { params })
