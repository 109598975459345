import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/campaign`

export const listKeywordsService = (
  params: RequestGetKeywordsServiceParams
) => {
  const { campaignId, count, page, quantity } = params
  return axios.get<GetKeywordsServiceResponse>(
    `${baseURL}/${campaignId}/keyword`,
    { params: { count, quantity, page } }
  )
}

export const changeStatusKeywordService = (params: {
  id: string
  active: boolean
}) =>
  axios.patch<CampaignKeyword>(`${baseURL}/keyword/${params.id}`, {
    active: params.active
  })

export const addKeywordsBulkService = (params: RequestAddKeywordBulkParams) =>
  axios.post<CampaignKeyword[]>(
    `${baseURL}/${params.campaignId}/keyword/bulk`,
    { keywords: params.keywords }
  )
