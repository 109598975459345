import axios, { AxiosResponse } from 'axios'

/**
 *
 * @endpoint /api/access-module
 * @exports getAccessModuleService Retorna os módulos liberados para a conta logada acessar
 *
 */

export type GetAccessModules = () => Promise<
  AxiosResponse<[AccessModulesResponse]>
>

export const getAccessModulesService: GetAccessModules = () =>
  axios.get(`/api/access-module`)
