import * as types from './constants'

/**
 *
 * Função para armazenar no estado global o status dos carrinhos que serão exibidos
 *
 * @param {object} filteredShoppingCartStatus Objeto com a option{value, label} do status selecionado
 *
 */
export const setFilter = ({ type, data }) => ({
  type: types.SET_FILTER,
  payload: { type, data }
})

/**
 *
 * Função para armazenar no estado global o status dos carrinhos que serão exibidos
 *
 * @param {object} filteredShoppingCartStatus Objeto com a option{value, label} do status selecionado
 *
 */
export function setShoppingCartStatus({ filteredShoppingCartStatus }) {
  return {
    type: types.SET_FILTERED_SHOPPING_CART_STATUS,
    payload: { shoppingCartStatus: filteredShoppingCartStatus }
  }
}
/**
 *
 * Função para armazenar no estado global o status do bot das conversas que serão exibidas
 *
 * @param {object} filteredLocations Objeto com a option{value, label} do status selecionado
 *
 */
export function setchatBotStatus({ filteredchatBotStatus }) {
  return {
    type: types.SET_FILTERED_chatBotStatus,
    payload: { chatBotStatus: filteredchatBotStatus }
  }
}

/**
 * Função para armazenar no estado o agente selecionado no filtro do chat
 *
 * @param {object} filteredAgent Objeto com a option{value, label} do status selecionado
 */
export function setChatAgent({ filteredAgent }) {
  return {
    type: types.SET_FILTERED_filterChatAgent,
    payload: { filteredAgent }
  }
}

/**
 *
 * Função para armazenar no estado global as tags das conversas que serão exibidas
 *
 * @param {object} filteredChatTags Objeto com a option{value, label} do status selecionado
 *
 */
export function setFilteredChatTags({ filteredChatTags }) {
  return {
    type: types.SET_FILTERED_CHATTAGS,
    payload: { chatTags: filteredChatTags }
  }
}

/**
 *
 * Função para armazenar no estado global os locais selecionado para o filtro.
 *
 * @param {object} filteredLocations Objeto com a option{value, label} do local selecionado
 *
 */
export function setFilteredLocations({ filteredLocations }) {
  return {
    type: types.SET_FILTERED_LOCATIONS,
    payload: { locations: filteredLocations }
  }
}

/**
 *
 * Função para armazenar no estado global as regiões do local selecionado para o filtro.
 *
 * @param {object} filteredValues Objeto com a option{value, label} da região selecionada
 *
 */
export function setFilteredLocationRegions({ filteredValues }) {
  return {
    type: types.SET_LOCATION_REGION,
    payload: { locationRegions: filteredValues }
  }
}

/**
 *
 * Função para armazenar no estado global os estados selecionado para o filtro.
 *
 * @param {object} filteredStates Objeto com a option{value, label} do estado selecionado
 *
 */
export function setFilteredStates({ filteredStates }) {
  return {
    type: types.SET_FILTERED_STATES,
    payload: { states: filteredStates }
  }
}

/**
 *
 * Função para armazenar no estado global o canal selecionado para o filtro.
 *
 * @param {object} setFilteredChannel Object com a option{value, label} do local selecionado
 *
 */

export function setFilteredChannel({ filteredChannel }) {
  return {
    type: types.SET_FILTERED_CHANNEL,
    payload: { channel: filteredChannel }
  }
}

/**
 *
 * Função para armazenar no estado global a permissão de usuário selecionada para o filtro.
 *
 * @param {object} setFilteredChannel Objeto com a option{value, label} da permissão selecionada
 *
 */

export function setFilteredUserRoles({ filteredUserRoles }) {
  return {
    type: types.SET_FILTERED_USER_ROLE,
    payload: { userRoles: filteredUserRoles }
  }
}

/**
 *
 * Função para armazenar no estado global o status selecionado para o filtro.
 *
 * @param {object} setFilteredChannel Objeto com a option{value, label} do local selecionado
 *
 */

export function setFilteredStatus({ filteredStatus }) {
  return {
    type: types.SET_FILTERED_STATUS,
    payload: { status: filteredStatus }
  }
}

/**
 * Função para armazenar no estado global o tipo de loja selecionada para o filtro
 *
 * @param {object} filteredLocationType Objeto com a option{value, label} do local selecionado
 */

export function setFilteredLocationType({ filteredLocationType }) {
  return {
    type: types.SET_FILTERED_STORE_TYPE,
    payload: { locationType: filteredLocationType }
  }
}

/**
 * Função para armazenar no estado global o status da loja selecionada para o filtro
 *
 * @param {object} filteredLocationStatus Objeto com a option{value, label} do local selecionado
 */

export function setFilteredLocationStatus({ filteredLocationStatus }) {
  return {
    type: types.SET_FILTERED_LOCATION_STATUS,
    payload: { locationStatus: filteredLocationStatus }
  }
}

/**
 * Função para armazenar no estado global o status do template selecionada para o filtro
 *
 * @param {object} filteredTemplateStatus Objeto com a option do status selecionado
 */
export function setFilteredTemplateStatus({ filteredTemplateStatus }) {
  return {
    type: types.SET_FILTERED_TEMPLATE_STATUS,
    payload: { status: filteredTemplateStatus }
  }
}

/**
 * Função para armazenar no estado global o status da campanha selecionada para o filtro
 *
 * @param {object} filteredCampaignStatus Objeto com a option do status selecionado
 */
export function setFilteredCampaignStatus({ filteredCampaignStatus }) {
  return {
    type: types.SET_FILTERED_CAMPAIGN_STATUS,
    payload: { status: filteredCampaignStatus }
  }
}

/**
 * Função para armazenar no estado global o status promocional selecionada para o filtro
 *
 * @param {object} filteredPromotionalStatus Objeto com a option do status selecionado
 */
export function setFilteredPromotionalStatus({ filteredPromotionalStatus }) {
  return {
    type: types.SET_FILTERED_PROMOTIONAL_STATUS,
    payload: { status: filteredPromotionalStatus }
  }
}

export function setFilteredPromotionalType({ promotionalType }) {
  return {
    type: types.SET_FILTERED_PROMOTIONAL_TYPE,
    payload: { promotionalType }
  }
}

/**
 *
 * Função para armazenar no estado global o status de pagamento selecionado para o filtro.
 *
 * @param {object} setFilteredChannel Objeto com a option{value, label} do local selecionado
 *
 */

export function setFilteredPaymentStatus({ filteredPaymentStatus }) {
  return {
    type: types.SET_FILTERED_PAYMENT_STATUS,
    payload: { paymentStatus: filteredPaymentStatus }
  }
}

/**
 *
 * Função para armazenar no estado global o tipo de pagamento selecionado para o filtro.
 *
 * @param {object} setFilteredPaymentMethod Objeto com a option{value, label} do local selecionado
 *
 */

export function setFilteredPaymentMethod({ filteredPaymentMethod }) {
  return {
    type: types.SET_FILTERED_PAYMENT_METHOD,
    payload: { paymentMethod: filteredPaymentMethod }
  }
}

/**
 *
 * Função para armazenar no estado global o termo de pesquisa livre para o filtro.
 *
 * @param {string} query Objeto com a option{value, label} do local selecionado
 *
 */

export function setQuery({ query, keyState }) {
  return {
    type: types.SET_QUERY,
    payload: { query, keyState }
  }
}

/**
 *
 * Função para resetar os filtros
 *
 */

export function resetFilterLocation() {
  return {
    type: types.RESET_FILTER_LOCATION
  }
}

export function resetFilterLocationRegion() {
  return {
    type: types.RESET_FILTER_LOCATION_REGION
  }
}

export function resetFilters() {
  return {
    type: types.RESET_FILTERS
  }
}
