import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    right: 0;
    top: 0;
    width: 100dvw;
    z-index: ${theme.layers.space};
    overflow: hidden;
  `}
`
