import { CampaignTableProvider } from './hooks/useCampaignTable'
import Table from './Table'

const CampaignTable: React.FC = () => {
  return (
    <CampaignTableProvider>
      <Table />
    </CampaignTableProvider>
  )
}

export default CampaignTable
