import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import Panel from '~/components/Panel'
import useConfirmationModal from '~/hooks/useConfirmationModal'

import { deleteLocation } from '../actions'

const DeleteLocation = ({ locationId, validateTerm, scrollToActiveFields }) => {
  const dispatch = useDispatch()

  const { loadingDelete } = useSelector(state => state.locations)

  const [ConfirmationModal, showConfirmationModal, toggleConfirmationModal] =
    useConfirmationModal()

  const handleDelete = () => {
    dispatch(deleteLocation(locationId))
  }

  const handleHighlightedButtonClick = () => {
    toggleConfirmationModal()
    scrollToActiveFields()
  }

  return (
    <fieldset style={{ position: 'relative' }}>
      <ConfirmationModal
        title="Deseja apagar o local?"
        isOpen={showConfirmationModal}
        onClose={toggleConfirmationModal}
        onSubmit={handleDelete}
        confirmationValue={validateTerm}
        confirmationButton="Entendo e desejo apagar este local"
      >
        <p>
          Esta ação não poderá ser desfeita e os dados do local não poderão mais
          ser acessados.
        </p>
        <p>
          Se preferir manter o acesso aos dados, você pode{' '}
          <span
            className="highlight"
            role="button"
            tabIndex={0}
            aria-hidden="true"
            onClick={handleHighlightedButtonClick}
          >
            desativar o local
          </span>
          .
        </p>
      </ConfirmationModal>

      <Panel>
        <Button
          template="transparentDanger"
          text="Excluir local"
          customWidth="auto"
          onClick={toggleConfirmationModal}
          loading={loadingDelete}
        />
      </Panel>
    </fieldset>
  )
}

export default DeleteLocation

DeleteLocation.propTypes = {
  locationId: PropTypes.string.isRequired,
  validateTerm: PropTypes.string.isRequired,
  scrollToActiveFields: PropTypes.func.isRequired
}
