import i18n from 'i18next'

import AlertInfo from '~/components/AlertInfo'
import { Badge } from '~/components/UI/Badge'

import FormAddFormat from '../components/FormAddFormat'
import RemoveFormatButton from './RemoveFormatButton'

const ListFormats = ({
  data,
  onSubmit,
  placementId,
  isLoading
}: {
  placementId?: string
} & EditChildrenProps) => (
  <section>
    <header>
      <h5>
        {i18n.t('rm:placements.edit.supportedFormat.title')}
        <br />
        <small>
          {i18n.t('rm:placements.edit.supportedFormat.description')}
        </small>
      </h5>
    </header>

    {!data.formats?.length && (
      <AlertInfo template="warning">
        {i18n.t('rm:placements.edit.supportedFormat.noFormat')}
      </AlertInfo>
    )}

    {!!data.formats?.length && (
      <ul className="list-group list-group-flush placements-formats">
        {data.formats.map(item => (
          <li
            className="list-group-item d-inline-flex align-items-center gap-1"
            key={item.id}
          >
            <strong className="placements-formats-name">
              {i18n.t('common:capitalize', {
                value: i18n.t(`rm:${item.type}`)
              })}
            </strong>

            {item.type === 'banner' && (
              <>
                <Badge
                  template="primary"
                  iconLeft={<Badge template="primaryDark">{item.size}</Badge>}
                >
                  {item.width} x {item.height}
                </Badge>
              </>
            )}

            {!!item?.quantity && (
              <Badge>
                {i18n.t('common:capitalize', {
                  value: i18n.t('common:words.maximum')
                })}
                : {item.quantity} {i18n.t('rm:ads')}
              </Badge>
            )}

            <RemoveFormatButton placementId={placementId} formatId={item.id} />
          </li>
        ))}
      </ul>
    )}

    <FormAddFormat data={data} onSubmit={onSubmit} isLoading={isLoading} />
  </section>
)

export default ListFormats
