import React from 'react'
import { useSelector } from 'react-redux'

import { DatepickerHeader } from '~/components/datepicker-header'
import EmptyPage from '~/components/empty-page'
import { FiltersMetabase } from '~/components/FiltersMetabase'
import HeaderPages from '~/components/HeaderPages'
import MetabaseDashboard from '~/components/MetabaseDashboard'
import PageTitle from '~/components/PageTitle'

import { Container } from './styles'

const SellouttDashboard = () => {
  const { supermarkets: filteredSupermarketsIds } = useSelector(
    state => state.filtersSellOut
  )

  return (
    <Container>
      <PageTitle title="Relatório de vendas" />

      <HeaderPages
        title="Relatório de vendas"
        sideRightComponent={<DatepickerHeader maxEndDate={'D1'} />}
      />

      <FiltersMetabase />

      {filteredSupermarketsIds && <MetabaseDashboard type="analytics" />}

      {!filteredSupermarketsIds && (
        <EmptyPage
          title="Selecione um supermercado"
          subtitle="Para começar precisamos definir um supermercado."
          svgPath="photos/orders-empty"
        />
      )}
    </Container>
  )
}

export default SellouttDashboard
