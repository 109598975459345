import { Fragment } from 'react'

import * as S from './styles'

const VerticalTimeline = ({ data, className }: VerticalTimelineProps) =>
  !!data?.length && (
    <S.Container className={`VerticalTimeline ${className}`}>
      {data.map(({ title, data }, index) => (
        <Fragment key={`id_${index}_${Math.random()}`}>
          {title && <dt className="vertical_timeline-title">{title}</dt>}

          {data?.map((item, index) => (
            <S.Item
              key={item.id || `id_${index}_${Math.random()}`}
              className={`vertical_timeline-item template__${
                item?.template || 'default'
              }`}
            >
              <section className="vertical_timeline-wrapper_main">
                {item?.time && (
                  <S.Time className="vertical_timeline-item-time">
                    {item.time}
                  </S.Time>
                )}

                {item?.Icon && (
                  <S.Icon className="vertical_timeline-item-icon">
                    {typeof item.Icon === 'string' ? item.Icon : <item.Icon />}
                  </S.Icon>
                )}

                <S.Title className="vertical_timeline-item-title">
                  {item.title}
                </S.Title>
              </section>

              {item?.content && (
                <S.Content className="vertical_timeline-item-wrapper-extra-content">
                  {item.content}
                </S.Content>
              )}
            </S.Item>
          ))}
        </Fragment>
      ))}
    </S.Container>
  )

export default VerticalTimeline
