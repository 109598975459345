import { IconType } from 'react-icons'
import { FiSend as icon } from 'react-icons/fi'

import * as CampaignPage from './pages/campaigns'
import CampaignDashboard from './pages/dashboard'
import * as TemplatePage from './pages/templates'

const messagingCampaignsRoutes: RootMenuItem<IconType> = {
  id: '76a5afeb-cb0b-4b0d-8b19-2a76ca1a55d3',
  path: '/messaging-campaigns',
  name: 'Campanhas',
  pageTitle: 'Campanhas',
  icon,

  children: [
    /**
     * Templates
     */
    {
      id: 'f228fb7e-7ca2-4e4f-b5de-dae816fa7179',
      path: '/messaging-campaigns/templates/list',
      name: 'Listagem de templates',
      pageTitle: 'Listagem de templates',
      component: TemplatePage.List
    },
    {
      id: '419542e7-2a53-4131-a431-dcf1fe9686fd',
      path: '/messaging-campaigns/templates/create',
      name: 'Criar template',
      pageTitle: 'Criar template',
      component: TemplatePage.New
    },
    {
      id: null, // rota-sem-id
      path: '/messaging-campaigns/templates/view/:id',
      name: 'Editar template',
      pageTitle: 'Editar template',
      component: TemplatePage.View,
      hideMenu: true
    },
    /**
     * Campanhas
     */
    {
      id: '2082f5b7-7086-460e-a09a-43c1dd2e5bcd',
      path: '/messaging-campaigns/list',
      name: 'Listagem de campanhas',
      pageTitle: 'Listagem de campanhas',
      component: CampaignPage.List
    },
    {
      id: '19d2cec3-2bb0-482b-a802-e10e9afd4d30',
      path: '/messaging-campaigns/create',
      name: 'Criar campanha',
      pageTitle: 'Criar campanha',
      component: CampaignPage.New
    },
    {
      id: null, // rota-sem-id
      path: '/messaging-campaigns/duplicate/:id',
      name: 'Duplicar campanha',
      pageTitle: 'Duplicar campanha',
      component: CampaignPage.Duplicate,
      hideMenu: true
    },
    {
      id: null, // rota-sem-id
      path: '/messaging-campaigns/edit/:id',
      name: 'Editar campanha',
      pageTitle: 'Editar campanha',
      component: CampaignPage.Edit,
      hideMenu: true
    },
    {
      id: null, // rota-sem-id
      path: '/messaging-campaigns/view/:id',
      name: 'Ver campanha',
      pageTitle: 'Ver campanha',
      component: CampaignPage.View,
      hideMenu: true
    },
    {
      id: null, // rota-sem-id
      path: '/messaging-campaigns/view/dashboard/:id',
      name: 'Relatório da campanha',
      pageTitle: 'Relatório da campanha',
      component: CampaignPage.Dashboard,
      hideMenu: true
    },
    /**
     * Campanhas Dashboard
     */
    {
      id: 'fa6e6436-20c8-4870-a5df-485737d1f171',
      path: '/messaging-campaigns/dashboard',
      name: 'Relatório de campanhas',
      pageTitle: 'Relatório de campanhas',
      component: CampaignDashboard
    }
  ]
}

export default messagingCampaignsRoutes
