import { t } from 'i18next'

export const publisherCards: CardsSchema<
  ReportCampaigns,
  TypesPublisherCard
> = ({ data, loading }) => [
  {
    id: 'card-01',
    title: t('rm:metrics.publishedCampaigns'),
    value: data?.formatted?.actives_campaigns || '-',
    type: 'actives_campaigns',
    loading: loading,
    isRelativeInterval: false
  },
  {
    id: 'card-02',
    title: t('rm:metrics.impressions'),
    value: data?.formatted?.impressions || '-',
    type: 'prints',
    loading: loading
  },
  {
    id: 'card-03',
    title: t('rm:metrics.clicks'),
    value: data?.formatted?.clicks || '-',
    type: 'clicks',
    loading: loading
  },
  {
    id: 'card-04',
    title: t('rm:metrics.ctr'),
    value: data?.formatted?.ctr || '-',
    type: 'ctr',
    loading: loading
  },
  {
    id: 'card-05',
    title: t('rm:metrics.totalSpent'),
    value: data?.formatted?.total_spent || '-',
    type: 'ads_income',
    loading: loading
  },
  {
    id: 'card-06',
    title: t('rm:metrics.income'),
    value: data?.formatted?.income || '-',
    type: 'income',
    loading: loading
  }
]

export const publisherListAllCards: CardsSchema<
  ReportCampaigns,
  TypesPublisherListAllCard
> = ({ data, loading }) => [
  {
    id: 'card-01',
    title: t('rm:metrics.activeAdvertisers'),
    value: data?.formatted?.actives_advertisers || 0,
    type: 'actives_advertisers',
    loading: loading,
    isRelativeInterval: false
  },
  {
    id: 'card-02',
    title: t('rm:metrics.publishedCampaigns'),
    value: data?.formatted?.actives_campaigns || 0,
    type: 'actives_campaigns',
    loading: loading,
    isRelativeInterval: false
  },
  {
    id: 'card-03',
    title: t('rm:metrics.impressions'),
    value: data?.formatted?.impressions || '-',
    type: 'prints',
    loading: loading,
    isRelativeInterval: true
  },
  {
    id: 'card-04',
    title: t('rm:metrics.clicks'),
    value: data?.formatted?.clicks || '-',
    type: 'clicks',
    loading: loading,
    isRelativeInterval: true
  },
  {
    id: 'card-031',
    title: t('rm:metrics.conversions'),
    value: data?.formatted?.conversions || '-',
    type: 'conversions',
    loading: loading
  },
  {
    id: 'card-041',
    title: t('rm:metrics.roas'),
    value: data?.formatted?.roas || '-',
    type: 'ctr',
    loading: loading
  },
  {
    id: 'card-05',
    title: t('rm:metrics.totalSpent'),
    value: data?.formatted?.total_spent || 0,
    type: 'ads_income',
    loading: loading,
    isRelativeInterval: true
  },
  {
    id: 'card-06',
    title: t('rm:metrics.income'),
    value: data?.formatted?.income || 0,
    type: 'sales_income',
    loading: loading,
    isRelativeInterval: true
  }
  // {
  //   id: 'card-061',
  //   title: 'Saldo carteiras',
  //   value: data?.formatted?.balance || 0,
  //   type: 'balance',
  //   loading: loading
  // }
]
