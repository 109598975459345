const prefix = '@retailMedia/ADS'

export const ADS_LIST = `${prefix}/LIST`
export const ADS_LIST_SORT = `${prefix}/LIST_SORT`
export const ADS_LIST_SET_PAGE = `${prefix}/LIST_SET_PAGE`
export const ADS_LIST_PER_PAGE = `${prefix}/LIST_PER_PAGE`

export const types = {
  list: ADS_LIST,
  listSort: ADS_LIST_SORT,
  listSetPage: ADS_LIST_SET_PAGE,
  listPerPage: ADS_LIST_PER_PAGE
}
