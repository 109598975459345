import { t } from 'i18next'

import { Badge } from '~/components/UI/Badge'
import { getRetailMediaAccountType } from '~/helpers'

import ToogleActiveCampaignKeyword from '../../../ToogleActiveCampaignKeyword'

const accountType = getRetailMediaAccountType()

const renderSwitch = {
  active: {
    title: '',
    render: (rowData: CampaignKeyword) => (
      <ToogleActiveCampaignKeyword data={rowData} key={rowData.id} />
    ),
    width: 50
  }
}

export const schemaKeywords: TableSchema<CampaignKeyword> = {
  ...(accountType === 'advertiser' ? { ...renderSwitch } : {}),
  title: {
    title: t('rm:keywords'),
    render: rowData => (
      <>
        {accountType === 'publisher' && rowData.disabled_at && (
          <Badge template="warning">Pausada</Badge>
        )}{' '}
        {rowData.keyword}
      </>
    ),
    className: 'order__details col__infos col__title',
    showTotal: true
  }
}
