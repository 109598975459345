import { FILTERS_SEELOUT } from '~/store/modules/filtersSellOut/constants'

import { metabaseKeys } from './keysMetabase'

export default {
  metabaseType: metabaseKeys[FILTERS_SEELOUT.channels],
  filteredBy: [FILTERS_SEELOUT.supermarkets],
  placeholder: 'Filtrar por canais',
  placeholderError: '🔴 Erro ao carregar canais',
  labelMulti: 'canais selecionados',
  labelAll: 'Todos os canais',
  searching: 'Buscando canais...'
}
