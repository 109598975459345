import { MdOutlineDeleteOutline } from 'react-icons/md'

import { Button } from '~/components/Buttons'
import ThumbProduct from '~/components/ThumbProduct'
import i18n from '~/i18n'
import Image from '~/modules/retailMedia/components/BannersTable/TumbProduct'

// const handleOpenModal = (data: DataModalHandle) => {
//   data.handleEditModal(data)
// }

const handleDeleteBanner = (data: DataModalHandle) => {
  data.handleDeleteBanner(data)
}
export const schema: TableSchema<DataModalHandle> = {
  media: {
    title: i18n.t('rm:campaign.create.Banner.table.media.title'),
    render: rowData => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Image url={rowData?.media_url}>
          <ThumbProduct url={rowData?.media_url} />
        </Image>
      </div>
    )
  },
  size: {
    title: i18n.t('rm:campaign.create.Banner.table.size.title'),
    render: rowData => (
      <div style={{ lineHeight: 1 }}>
        <strong className="title">
          <code>
            {rowData?.dimensions?.width} x {rowData?.dimensions?.height}
          </code>
        </strong>
      </div>
    ),
    className: 'order__details col__infos col__title'
  },
  // alt_text: {
  //   title: 'Texto alternativo',
  //   render: rowData => (
  //     <div style={{ maxWidth: '18rem' }}>{rowData?.alt_text}</div>
  //   )
  // },
  actions: {
    render: rowData => (
      <div style={{ textAlign: 'right' }}>
        {/* <Button
          onClick={() => handleOpenModal(rowData)}
          size="small"
          customWidth="200"
          style={{ marginRight: '2rem' }}
        >
          <FiEdit />
        </Button> */}

        <Button
          onClick={() => handleDeleteBanner(rowData)}
          template="transparentDanger"
          size="xsmall"
          iconLeft={<MdOutlineDeleteOutline />}
          text={i18n.t('rm:campaign.create.Banner.table.actions.removeBanner')}
        />
      </div>
    )
  }
}
