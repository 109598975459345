import { useCallback, useMemo, useState } from 'react'
import { HiOutlineArrowsUpDown } from 'react-icons/hi2'
import { useDispatch } from 'react-redux'

import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import { agentTransfer } from '~/modules/chat/store/actions'
import { useAppSelector } from '~/store/hooks'

import { NewPopover } from '../NewPopover'
import { ConfirmationStep } from './components/ConfirmationStep'
import { InitialStep } from './components/InitialStep'

import * as S from './styles'

interface Props {
  textButton: string
  titlePopover: string
  textButtonNextStep: string
}

export const TransferChat = ({
  textButton,
  titlePopover,
  textButtonNextStep
}: Props) => {
  const [step, setStep] = useState<StepState>('initial')
  const [selectedAttendent, setSelectedAttendent] = useState(null)
  const [showPopover, setShowPopover] = useState(false)

  const dispatch = useDispatch()

  const { isMobile } = useAppSelector(state => state.layout)

  const {
    chats: { selectedChat, listAttendantData }
  } = useAppSelector(state => state)

  const configureNextStep = useCallback((step: StepState) => {
    setStep(step)
  }, [])

  const handleSelectAttendent = useCallback(
    (value: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedAttendent(value.target.value)
    },
    []
  )

  const handleCancel = () => {
    setShowPopover(false)
    setStep('initial')
    setSelectedAttendent(null)
  }

  const handleSubmit = useCallback(() => {
    const user = listAttendantData.filter(
      attendant => attendant.user_id === selectedAttendent
    )

    const params = {
      sessionId: selectedChat?.session?.sessionId,
      values: { user, chatId: selectedChat.chatId },
      data: {
        userId: selectedAttendent
      }
    }

    dispatch(agentTransfer({ ...params, onSuccess: handleCancel }))
  }, [
    dispatch,
    listAttendantData,
    selectedAttendent,
    selectedChat.chatId,
    selectedChat?.session?.sessionId
  ])

  const initialData = useMemo(() => {
    const initialDataList = listAttendantData.filter(
      attendant => attendant.user_id === selectedChat?.session?.userId
    )[0]

    return {
      attendant: initialDataList?.user_id
    }
  }, [listAttendantData, selectedChat?.session?.userId])

  return (
    <S.Container>
      <NewPopover
        content={
          <Button
            iconLeft={<HiOutlineArrowsUpDown />}
            text={textButton}
            template="primaryOutlined"
            size={isMobile ? 'xsmall' : 'small'}
            customWidth="auto"
            style={{ marginLeft: '0.5rem' }}
            isSelected={showPopover}
          />
        }
        title={titlePopover}
        handleShowPopover={setShowPopover}
        showPopover={showPopover}
        handleCancel={handleCancel}
      >
        <Form onSubmit={handleSubmit} initialData={initialData}>
          {step === 'initial' && (
            <InitialStep
              handleChangeStep={configureNextStep}
              handleSelectAttendent={handleSelectAttendent}
              selectedAttendent={selectedAttendent}
              textButtonNextStep={textButtonNextStep}
            />
          )}

          {step === 'confirmation' && (
            <ConfirmationStep
              selectedAttendent={selectedAttendent}
              handleActionCancel={handleCancel}
            />
          )}
        </Form>
      </NewPopover>
    </S.Container>
  )
}
