import React, { useMemo } from 'react'
import { FiPrinter } from 'react-icons/fi'
import { useReactToPrint } from 'react-to-print'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { getAccountInfo } from '~/helpers/account-info'
import { gtagEvent } from '~/lib/gtag'

const ButtonPrint = ({
  contentToPrint,
  label,
  loading,
  hideMobile,
  ...rest
}) => {
  const printItem = useReactToPrint({
    content: () => contentToPrint
  })

  const accountInfo = getAccountInfo()

  const accountName = useMemo(() => accountInfo?.name, [accountInfo])

  const handlePrint = () => {
    printItem()

    gtagEvent({
      category: 'Pedido',
      action: 'Imprimir pedido',
      label: `[${accountName}] - Imprimiu pedido`
    })
  }

  return (
    <Button
      text={label}
      type="button"
      onClick={handlePrint}
      iconLeft={<FiPrinter />}
      loading={loading}
      hideMobile={hideMobile}
      {...rest}
    />
  )
}

export default ButtonPrint

ButtonPrint.propTypes = {
  contentToPrint: PropTypes.shape({}),
  label: PropTypes.string,
  loading: PropTypes.bool,
  hideMobile: PropTypes.bool
}

ButtonPrint.defaultProps = {
  contentToPrint: null,
  label: 'Imprimir',
  loading: false,
  hideMobile: true
}
