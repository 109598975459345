import styled, { css } from 'styled-components';

import bgChat from '~/assets/img/elements/bg_chat.jpeg';

const containerModifiers = {
  list: () => css`
    --border: none;
    --padding: 0;
  `,
  disabled: () => css`
    opacity: 0.75;
  `,
};

export const Container = styled.div`
  ${({ theme, isList, isDisabled }) => css`
    --border: 1px solid ${theme.colors.tertiary};
    --bgHover: ${theme.colors.primaryLighten60};
    --padding: 0.75rem;

    padding: var(--padding);
    cursor: pointer;

    transition: ${theme.transition.default} all;

    &:not(:last-child) {
      border-bottom: var(--border);
    }

    header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.25rem;

      > div {
        display: flex;
        align-items: center;
      }

      button {
        opacity: 0;
        transition: ${theme.transition.default} opacity;
      }
    }

    &:hover {
      background: var(--bgHover);
      header {
        button {
          opacity: 1;
        }
      }
    }

    ${isList && containerModifiers.list(theme)}
    ${isDisabled && containerModifiers.disabled(theme)}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;

    .column {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 50%;

      > div {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;

        .titleColumn {
          text-transform: uppercase;
          color: ${theme.colors.gray};
          font-size: ${theme.font.sizes.xxsmall};
        }
      }
    }

    .message {
      padding-right: 0.5rem;

      p {
        position: relative;
        padding: 0.5rem;
        border-radius: 0.25rem;
        background: rgb(236, 234, 231);
        border: 1px solid white;
        margin-bottom: 0;

        span {
          position: relative;
          z-index: 2;
          font-size: ${theme.font.sizes.xsmall};

          code {
            color: ${theme.colors.dark};
          }

          /* Temp hack pra compensar o \n\n que vira br br  */
          br + br {
            display: block;
            height: 2px;
            content: '';
          }
        }

        &:before {
          background: url(${bgChat});
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          mix-blend-mode: multiply;
          background-size: 21rem;
          opacity: 0.05;
        }
      }
    }

    .buttons {
      padding-left: 0.5rem;

      div {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;

        .titleColumn {
          flex: 1 1 100%;
        }

        .empty {
          flex: 1 1 100%;
          font-size: ${theme.font.sizes.xsmall};
        }

        .button {
          background: ${theme.colors.light};
          color: ${theme.colors.info};
          border: 1px solid ${theme.colors.tertiary};
          border-radius: 0.25rem;
          padding: 0.25rem 0.5rem;
          margin: 0.125rem 0.25rem 0.125rem 0;
          font-size: ${theme.font.sizes.xsmall};
          line-height: 1.25;
          min-width: 200px;
          text-align: center;
        }
      }
    }
  `}
`;

const tagModifiers = {
  warning: theme => css`
    background-color: ${theme.colors.warningLighten50};
    color: ${theme.colors.warning};
  `,
};

export const Tag = styled.span`
  ${({ theme, template }) => css`
    display: inline-flex;
    align-items: center;
    width: auto;

    border-radius: ${theme.border.radius.medium};
    padding: 0.125rem 0.25rem;
    margin: 0.125rem;

    font-size: ${theme.font.sizes.xxsmall};
    color: ${theme.colors.dark};
    background-color: ${theme.colors.primaryLighten60};

    svg {
      margin-right: 0.25rem;
      color: ${theme.colors.primary};
    }

    ${template === 'warning' && tagModifiers.warning(theme)}
  `}
`;
