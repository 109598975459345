import React, { useCallback } from 'react'
import { FiPlayCircle } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'

import useFlyers from '../../hooks/useFlyers'

const ReactiveButton = ({ data, menuItem = false }) => {
  const { handleReactiveFlyer } = useFlyers()

  const { loading } = useSelector(
    ({ promotionFlyer }) => promotionFlyer.flyers.update
  )

  const handleSubmit = useCallback(() => {
    const body = {
      data
    }

    handleReactiveFlyer(body)
  }, [handleReactiveFlyer, data])

  return (
    <>
      {menuItem ? (
        <button type="button" onClick={handleSubmit} className="success">
          {loading ? <Spinner /> : <FiPlayCircle />} <span>Reativar</span>
        </button>
      ) : (
        <Button
          text="Reativar"
          type="button"
          template="primaryOutlined"
          size="small"
          iconLeft={loading ? <Spinner /> : <FiPlayCircle />}
          customWidth="auto"
          onClick={handleSubmit}
        />
      )}
    </>
  )
}

export default ReactiveButton

ReactiveButton.propTypes = {
  data: PropTypes.shape({}).isRequired,
  menuItem: PropTypes.bool
}

ReactiveButton.defaultProps = {
  menuItem: false
}
