import { FiAlertTriangle } from 'react-icons/fi'

import * as S from './styles'

export const AlertConfirmation = ({
  description,
  template = 'danger',
  title,
  icon = null
}: AlertConfirmationProps) => (
  <S.ConfirmationContainer data-template={template}>
    <S.WarningIcon>{icon || <FiAlertTriangle />}</S.WarningIcon>

    <S.Content>
      <S.ConfirmationTitle>{title ? title : 'Alerta'}</S.ConfirmationTitle>
      <S.Description>{description}</S.Description>
    </S.Content>
  </S.ConfirmationContainer>
)
