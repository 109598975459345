import axios from 'axios';

/**
 *
 * @endpoint /search/customers
 * @exports getCustomerList  Busca todos os clientes da loja
 * @param {string} queryString ?query=<campo de busca>&page=<opcional>&quantity=<tam da página, opcional>
 *
 * @endpoint /api/customer/:id
 * @exports getCustomer  Pega um cliente pelo ID
 *
 * @endpoint /api/customer
 * @exports postFormCustomer
 *
 * @endpoint /api/data-service/customer
 * @exports getCustomersByType
 * @exports getCustomerPerDay
 * @exports getCustomerPerTime
 * @exports getCustomerTopChannels
 * @exports getCustomerPerGmvRange
 *
 */

// export const getCustomerList = queryString =>
//   axios.get(
//     `/api/customer/list-customers?${queryString}`,
// );

export const getCustomerList = queryString =>
  axios.get(`/search/customers?${queryString}`);

export const createCustomerService = data => axios.post(`/api/customer/`, data);

export const postFormCustomer = data => axios.post(`/api/customer`, data); // old

export const updateCustomerService = data => axios.put(`/api/customer/`, data);

export const getCustomer = id => axios.get(`/api/customer/${id}`);

export const getCustomersByType = queryString =>
  axios.get(`/api/data-service/customer/customers-by-type?${queryString}`);

export const getCustomerPerDay = queryString =>
  axios.get(`/api/data-service/customer/per-day?${queryString}`);

export const getCustomerPerTime = queryString =>
  axios.get(`/api/data-service/customer/per-time-range?${queryString}`);

export const getCustomerTopChannels = queryString =>
  axios.get(`/api/data-service/customer/top-channels?${queryString}`);

export const getCustomerPerGmvRange = queryString =>
  axios.get(`/api/data-service/customer/per-gmv-range?${queryString}`);
