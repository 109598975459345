const prefix = '@retailMedia/campaign'

export const CAMPAIGN_GET = `${prefix}/GET`
export const CAMPAIGN_LIST = `${prefix}/LIST`
export const CAMPAIGN_LIST_PAGE = `${prefix}/LIST_PAGE`
export const CAMPAIGN_LIST_PER_PAGE = `${prefix}/LIST_PER_PAGE`
export const CAMPAIGN_LIST_SORT = `${prefix}/LIST_SORT`

export const ADD_PRODUCT = `${prefix}/ADD_PRODUCT`
export const ADD_PRODUCTS_BULK = `${prefix}/ADD_PRODUCTS_BULK`
export const DELETE_PRODUCT = `${prefix}/DELETE_PRODUCT`
export const DELETE_ALL_PRODUCTS = `${prefix}/DELETE_ALL_PRODUCTS`

export const product = {
  add: ADD_PRODUCT,
  addBulk: ADD_PRODUCTS_BULK,
  remove: DELETE_PRODUCT,
  remove_all: DELETE_ALL_PRODUCTS
}

export const campaignView = {
  get: CAMPAIGN_GET
}

export const campaignList = {
  list: CAMPAIGN_LIST,
  page: CAMPAIGN_LIST_PAGE,
  perPage: CAMPAIGN_LIST_PER_PAGE,
  sort: CAMPAIGN_LIST_SORT
}
