import { MdOutlineDeleteOutline } from 'react-icons/md'

import { Button } from '~/components/Buttons'
import ThumbProduct from '~/components/ThumbProduct'
import i18n from '~/i18n'
import Image from '~/modules/retailMedia/components/BannersTable/TumbProduct'

const handleDeleteAsset = (data: DataAssetHandle) => {
  data.handleDeleteAsset(data)
}
export const schema = {
  image: {
    title: i18n.t('rm:media'),
    render: rowData => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Image url={rowData?.media_url}>
          <ThumbProduct url={rowData?.media_url} />
        </Image>
      </div>
    )
  },
  format_banner: {
    title: i18n.t('rm:mediaSize'),
    render: rowData => (
      <div style={{ lineHeight: 1 }}>
        <strong className="title">
          <code>
            {rowData?.dimensions?.width} x {rowData?.dimensions?.height}
          </code>
        </strong>
      </div>
    ),
    className: 'order__details col__infos col__title'
  },
  alt_text: {
    title: i18n.t('rm:mediaType'),
    render: rowData => rowData.type
  },
  actions: {
    render: rowData => (
      <div className="text-align-right">
        <Button
          onClick={() => handleDeleteAsset(rowData)}
          template="transparentDanger"
          size="xsmall"
          iconLeft={<MdOutlineDeleteOutline />}
          text={i18n.t('c:actions.removeMedia')}
        />
      </div>
    )
  }
}
