import React from 'react';

import PropTypes from 'prop-types';

const Video = ({ data }) => {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video className="video" controls>
      <source src={data} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;

Video.propTypes = {
  data: PropTypes.string.isRequired,
};
