import { formatSearchString, queryDatesDatepicker, history } from '~/helpers'
import {
  postFormCustomer,
  getCustomerList,
  getCustomerPerDay,
  getCustomerPerTime,
  getCustomerTopChannels,
  getCustomerPerGmvRange,
  getCustomersByType,
  getCustomer as getCustomerService,
  updateCustomerService,
  createCustomerService
} from '~/services/customer-service'

import * as types from './constants'

/**
 * Busca os clientes associados à conta
 */

export const customersList = queryString => ({
  type: types.CUSTOMER_LIST,
  payload: getCustomerList(formatSearchString(queryString))
})

/**
 * Handle page
 */

export const handleCustomerPage = value => {
  return {
    type: types.CUSTOMER_LIST_SET_PAGE,
    payload: value
  }
}

/**
 * Handle items por page
 */

export const handleCustomerPerPage = value => {
  return {
    type: types.CUSTOMER_LIST_PER_PAGE,
    payload: value
  }
}

/**
 * Get single customer
 */

export const getCustomer = id => ({
  type: types.GET_CUSTOMER,
  payload: getCustomerService(id)
})

/**
 * Clear the customer state
 */

export const clearCustomerState = () => ({
  type: types.CLEAR_CUSTOMER_STATE
})

/**
 * Create customer
 */
export const createCustomer = ({ body, onSuccess = null }) => ({
  type: types.CREATE_CUSTOMER,
  payload: createCustomerService(body),
  successFeedbackMsg: 'Cliente criado com sucesso',
  getErrorFeedbackMsg: error => error.response.data.error,
  onSuccess
})

/**
 * Old create customer
 */
export const submitFormCustomer = data => dispatch => {
  dispatch({
    type: types.CUSTOMER_SUBMIT_FORM,
    payload: postFormCustomer(data),
    successFeedbackMsg: 'Cliente adicionado com sucesso!',
    onSuccess: () => {
      history.push(`/customers/list`)
    }
  })
}

/**
 * Save customer
 */
export const updateCustomer = ({ body, onSuccess = null }) => ({
  type: types.UPDATE_CUSTOMER,
  payload: updateCustomerService(body),
  successFeedbackMsg: 'Cliente atualizado com sucesso',
  getErrorFeedbackMsg: error => error.response.data.error,
  onSuccess
})

export const setEditableForm = isEditable => dispatch => {
  dispatch({
    type: types.CUSTOMER_SET_EDITABLE_FORM,
    payload: isEditable
  })
}

export const customersByType = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate)
  })
  dispatch({
    type: types.CUSTOMER_BY_TYPE,
    payload: getCustomersByType(queryString)
  })
}

export const getCustomerUpgradeChart = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate)
  })
  dispatch({
    type: types.CUSTOMER_CHART_PER_DAY,
    payload: getCustomerPerDay(queryString)
  })
}

export const getCustomerPerTimeChart = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate)
  })
  dispatch({
    type: types.CUSTOMER_CHART_PER_TIME,
    payload: getCustomerPerTime(queryString)
  })
}

export const customerListTopChannels = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate)
  })
  dispatch({
    type: types.CUSTOMER_TOP_CHANNELS,
    payload: getCustomerTopChannels(queryString)
  })
}

export const getCustomerListGmvRange = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate)
  })
  dispatch({
    type: types.CUSTOMER_PER_GMV_RANGE,
    payload: getCustomerPerGmvRange(queryString)
  })
}
