import React from 'react';

import PropTypes from 'prop-types';

const TEMPLATE_STATUS_LIST = {
  in_review: 'Em análise',
  approved: 'Aprovado',
  denied: 'Negado',
};

const StatusTag = ({ status }) => {
  return (
    <>
      <strong>Status: </strong>
      {TEMPLATE_STATUS_LIST[status] || 'Sem status definido'}
    </>
  );
};

StatusTag.defaultProps = {
  status: '',
};

StatusTag.propTypes = {
  status: PropTypes.string,
};

export default StatusTag;
