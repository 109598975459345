import { types } from '../constants'

const index = {
  pending: `${types.patch_product}_PENDING`,
  fulfilled: `${types.patch_product}_FULFILLED`,
  rejected: `${types.patch_product}_REJECTED`
}

export const patchProduct = {
  [index.pending]: ({ draft, ...rest }) => {
    draft.industry.campaign.patch_product.loading = true
    draft.industry.campaign.patch_product.error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.campaign.patch_product.loading = false
    draft.industry.campaign.patch_product.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.campaign.patch_product.loading = false
    draft.industry.campaign.patch_product.error = action.payload
  }
}
