import axios from 'axios'

import { formatSearchString } from '~/helpers'

export const getChatUrl = ({ queryString }: GetChatUrl) =>
  `/search/chats?${queryString}`

export const getChatsService = ({ queryString }: GetChatsService) =>
  axios.get<GetChatsResponse>(
    `/search/v2/chats?${formatSearchString(queryString)}`
  )

export const updatedChatService = (data: UpdateData) =>
  axios.put(`/api/notification/chat`, data)

export const updatedSessionService = (data: UpdateData) =>
  axios.put(`/api/notification/chat/sessions`, data)

export const getChatMessages = ({ id, page = 1 }: GetChatMessages) =>
  axios.get(`/api/notification/chat/messages/${id}?page=${page}`)

export const setMessageStatusToReadService = (data: SetMessageStatusData) =>
  axios.post(`/api/notification/chat/messages/read`, data)

/**
 * Tags
 */
export const getTagsService = () => axios.get(`/api/notification/tags/`)

export const createTagService = (data: TagData) =>
  axios.post(`/api/notification/tags`, data)

export const updateTagService = (data: TagData) =>
  axios.put(`/api/notification/tags/`, data)

export const deleteTagService = (tagId: DeleteTag) =>
  axios.delete(`/api/notification/tags/${tagId}`)

export const setChatsTags = (data: SetChatsTags) =>
  axios.put(`/api/notification/chat/sessions/tags`, data)

export const getQrCodeWppWeb = () =>
  axios.get(`/api/notification/whatsapp-web/qrcode`)

export const getStatusAuth = () =>
  axios.get(`/api/notification/whatsapp-web/status`)

export const getRestartAuth = () =>
  axios.get(`/api/notification/whatsapp-web/restart`)

export const getChatProviderService = () =>
  axios.get(`/api/configuration/settings/message_provider`)

export const toggleBotStatusService = ({ sessionId, data }: ToggleBotStatus) =>
  axios.put(`/api/notification/chat/sessions/bot-status/${sessionId}`, data)

export const getDashboarUrl = ({ queries }: GetDashboardUrl) =>
  axios.get(`/metabase/?${queries}`)

export const putStartService = async ({ sessionId }: StartService) =>
  axios.put(`/api/notification/chat/sessions/agent-start/${sessionId}`)

export const putFinishService = ({ sessionId }: FinishService) =>
  axios.put(`/api/notification/chat/sessions/agent-finish/${sessionId}`)

export const setNewMessageStatusToReadService = (data: SetStatusToRead) =>
  axios.post(`/api/notification/v2/chat/messages/read`, data)

export const listAttendantService: ListAttendantService = ({ params }) =>
  axios.get(`/search/chats/agents`, { params })

export const agentTransferService = ({ sessionId, data }: AgentTransfer) =>
  axios.put(`/api/notification/chat/sessions/agent-transfer/${sessionId}`, data)

export const historyChatSession = ({ sessionId, chatId }: HistoryChatSession) =>
  axios.get(`api/notification/chat/session-histories`, {
    params: { sessionId, chatId }
  })
