import axios from 'axios';

export function getCardsData(queryString) {
  return axios.get(
    `${
      process.env.REACT_APP_HOST
    }/api/data-service/dashboard/cards?${queryString || ''}`
  );
}

export function getGmvPerDayData(queryString) {
  return axios.get(
    `${
      process.env.REACT_APP_HOST
    }/api/data-service/dashboard/gmv-per-day?${queryString || ''}`
  );
}

export function getMarginPerDay(queryString) {
  return axios.get(
    `${
      process.env.REACT_APP_HOST
    }/api/data-service/dashboard/margin-per-day?${queryString || ''}`
  );
}

export function getTopProducts(queryString) {
  return axios.get(
    `${
      process.env.REACT_APP_HOST
    }/api/data-service/dashboard/top-products?${queryString || ''}`
  );
}

export function getTopCustomers(queryString) {
  return axios.get(
    `${
      process.env.REACT_APP_HOST
    }/api/data-service/dashboard/top-customers?${queryString || ''}`
  );
}
