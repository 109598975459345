export default {
  list: {
    loading: false,
    error: null,
    data: [],
    page: 1,
    pages: null,
    itemsPerPage: 10,
  },
  view: {
    loading: false,
    error: null,
    data: null,
  },
  create: {
    loading: false,
    error: null,
  },
  update: {
    loading: false,
    error: null,
  },
  remove: {
    loading: false,
    error: null,
  },
};
