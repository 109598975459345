import React, { useMemo, useCallback, useState } from 'react'
import { FiX } from 'react-icons/fi'

import moment from 'moment'
import PropTypes from 'prop-types'

import { useLogisticSlots } from '../../useLogisticSlots'
import Slot from '../Slot'
import ButtonMore from '../Slot/ButtonMore'

import * as S from './styles'

const SlotConfig = ({ data, dayId = null, template }) => {
  const { deleteConfig, handleSelectedDays, locationID } = useLogisticSlots()
  /**
   * Show only 4 results.
   */
  const [limitResults, setLimitResults] = useState(true)
  const maxLegthOnCollapseMode = 6

  const {
    start_hour,
    start_minute,
    repeat_minutes,
    repeat,
    location_id,
    isNew = false,
    max_orders = null
  } = data

  const isRepeatable = useMemo(() => repeat > 1, [repeat])

  const isGlobal = useMemo(
    () => !!(!location_id && locationID),
    [location_id, locationID]
  )

  const canDelete = useMemo(() => !!(!isGlobal && dayId), [isGlobal, dayId])

  const startHour = useMemo(
    () => moment(`${start_hour}:${start_minute}`, 'HH:mm').format('HH:mm'),
    [start_hour, start_minute]
  )

  const endHourFormatter = useCallback(
    startSlotHour =>
      moment(startSlotHour, 'HH:mm').add(repeat_minutes, 'm').format('HH:mm'),
    [repeat_minutes]
  )

  const slots = useMemo(
    () =>
      Array.from({ length: repeat }).reduce((acc, current, currentIndex) => {
        const isLastSlot = currentIndex + 1 === repeat
        const hideThisitem = currentIndex > maxLegthOnCollapseMode - 1
        const shouldBeHidden = limitResults && hideThisitem && !isLastSlot

        const currentStartHour = acc[currentIndex - 1]?.endHour || startHour
        const currentEndHour = endHourFormatter(currentStartHour)

        const item = {
          id: `${currentIndex}${startHour}${currentEndHour}`,
          isNew,
          startHour: currentStartHour,
          endHour: currentEndHour,
          isRepeatable,
          isGlobal,
          template,
          hide: shouldBeHidden,
          maxOrders: max_orders
        }

        return [...acc, item]
      }, []),
    [
      repeat,
      limitResults,
      startHour,
      endHourFormatter,
      isNew,
      isRepeatable,
      isGlobal,
      template,
      max_orders
    ]
  )

  const handleDeleteSlot = useCallback(() => {
    if (isNew) {
      return handleSelectedDays(dayId)
    }

    deleteConfig({ config: data })
  }, [data, dayId, deleteConfig, handleSelectedDays, isNew])

  return (
    <S.ContainerGroup isRepeatable={isRepeatable} className="containerGroup">
      {canDelete && (
        <div className="wrapperDelete">
          <button type="button" onClick={handleDeleteSlot}>
            <FiX />
          </button>
        </div>
      )}

      {slots.map((slot, index) => {
        if (
          limitResults &&
          index === maxLegthOnCollapseMode - 1 &&
          maxLegthOnCollapseMode < repeat
        ) {
          return (
            <ButtonMore
              slot={slot}
              lengthHide={repeat - maxLegthOnCollapseMode}
              action={() => setLimitResults(state => !state)}
            />
          )
        }

        return !slot.hide && <Slot key={slot.id} slot={slot} />
      })}
    </S.ContainerGroup>
  )
}

export default SlotConfig

SlotConfig.propTypes = {
  template: PropTypes.string.isRequired,
  dayId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.shape({
    _id: PropTypes.string,
    location_id: PropTypes.string,
    start_hour: PropTypes.number,
    start_minute: PropTypes.number,
    repeat_minutes: PropTypes.number,
    repeat: PropTypes.number,
    repeat_minutes_lead: PropTypes.number,
    isNew: PropTypes.bool
  }).isRequired
}

SlotConfig.defaultProps = {
  dayId: null
}
