import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import PropTypes from 'prop-types'

import BadgeIsValid from '~/components/BadgeIsValid'
import Panel from '~/components/Panel'
import { FormGroup, Label, Input, InputMask } from '~/components/unform'
import { getAccountInfo } from '~/helpers/account-info'
import {
  getInfoAddressByPostalCode,
  getInfoAddressByPostalCodeForCoordinates
} from '~/services/location-service'

import { setMapCoordinates } from '../../actions'

const AddressFields = ({
  formRef,
  labelItem = 'local',
  descriptionPanel = '',
  hideCoordinates = false,
  hideComplement = false,
  noPaddingBody = false,
  allRequired = false,
  isCollapsible = true,
  showBadgeValidate = false,
  badgeIsValid = false
}) => {
  const [loadingCep, setLoadingCep] = useState(false)
  const [loadingCoords, setLoadingCoords] = useState(false)

  const dispatch = useDispatch()
  const info = getAccountInfo()
  const host = info?.host

  const handleBlur = useCallback(async () => {
    const postalCode = formRef.current.getFieldValue('address_postal_code')
    const street = formRef.current.getFieldValue('address_street')
    const number = formRef.current.getFieldValue('address_number')

    const address = `${street ? `${street}, ` : ''}${
      number ? `${number} - ` : ''
    }${postalCode ? `${postalCode}` : ''}`

    try {
      setLoadingCoords(true)

      const response = await getInfoAddressByPostalCodeForCoordinates({
        postalCode: `${address}`,
        host
      })

      const { address_neighborhood, latitude, longitude } = response.data

      formRef.current.setData({
        address_neighborhood,
        latitude,
        longitude
      })

      if (!hideCoordinates) {
        dispatch(setMapCoordinates({ latitude, longitude }))
      }
    } catch (err) {
      toastr.error('Erro', 'CEP não encontrado')
    } finally {
      setLoadingCoords(false)
    }
  }, [dispatch, formRef, hideCoordinates, host])

  const handleBlurCep = useCallback(
    async ({ value, clearError, setIsFilled }) => {
      const normalizedValue = value.replace(/-/g, '').replace(/_/g, '')

      setLoadingCep(true)

      if (normalizedValue.length === 8) {
        try {
          const response = await getInfoAddressByPostalCode(normalizedValue)

          const {
            address_postal_code,
            address_neighborhood,
            address_street,
            address_city,
            address_state,
            address_country,
            latitude,
            longitude
          } = response.data

          formRef.current.setData({
            address_postal_code,
            address_neighborhood,
            address_street,
            address_city,
            address_state,
            address_country,
            latitude,
            longitude
          })

          formRef.current.setErrors({
            address_postal_code: false,
            address_neighborhood: false,
            address_street: false,
            address_city: false,
            address_state: false,
            address_country: false,
            address_number: false,
            latitude: false,
            longitude: false
          })

          if (!hideCoordinates) {
            dispatch(setMapCoordinates({ latitude, longitude }))
          }

          setIsFilled(true)
          clearError()
        } catch (err) {
          toastr.error('Erro', 'CEP não encontrado')
        } finally {
          setLoadingCep(false)
        }
      }

      if (normalizedValue.length !== 8) {
        toastr.warning('Alert', 'O CEP precisa ter 8 números.')
      }

      setLoadingCep(false)
    },
    [dispatch, formRef, hideCoordinates, host]
  )

  return (
    <fieldset>
      <Panel
        title={`Endereço ${labelItem}`}
        className="form-grid"
        noPaddingBody={noPaddingBody}
        description={descriptionPanel}
        isCollapsible={isCollapsible}
        iconLeft={
          showBadgeValidate ? <BadgeIsValid isValid={badgeIsValid} /> : null
        }
      >
        {/* CEP */}
        <FormGroup>
          <Label isRequired={allRequired} htmlFor="address_postal_code">
            CEP
          </Label>
          <InputMask
            name="address_postal_code"
            onBlur={handleBlurCep}
            loading={loadingCep}
            format="#####-###"
            placeholder="Insira o cep (12345-567)"
            required={allRequired}
          />
        </FormGroup>
        {/* Street */}
        <FormGroup className="span2 span-start">
          <Label isRequired={allRequired} htmlFor="address_street">
            Rua
          </Label>
          <Input
            name="address_street"
            placeholder="Insira a rua"
            loading={loadingCep}
            disabled={loadingCep}
            onBlur={!hideCoordinates && handleBlur}
            required={allRequired}
          />
        </FormGroup>
        {/* Number */}
        <FormGroup>
          <Label isRequired={allRequired} htmlFor="address_number">
            Número
          </Label>
          <Input
            name="address_number"
            placeholder="Número"
            onBlur={!hideCoordinates && handleBlur}
            required={allRequired}
          />
        </FormGroup>
        {/* Complement */}
        {!hideComplement && (
          <FormGroup className="span2">
            <Label htmlFor="address_complement">Complemento</Label>
            <Input name="address_complement" placeholder="Complemento" />
          </FormGroup>
        )}
        {/* Neighborhood */}
        <FormGroup className="span2 span-start">
          <Label htmlFor="address_neighborhood">Bairro</Label>
          <Input
            name="address_neighborhood"
            placeholder="Insira o bairro"
            loading={loadingCep}
            disabled={loadingCep}
          />
        </FormGroup>
        {/* City */}
        <FormGroup className="span2">
          <Label isRequired={allRequired} htmlFor="address_city">
            Cidade
          </Label>
          <Input
            name="address_city"
            placeholder="Insira a cidade"
            loading={loadingCep}
            disabled={loadingCep}
            required={allRequired}
          />
        </FormGroup>
        {/* State */}
        <FormGroup>
          <Label isRequired={allRequired} htmlFor="address_state">
            UF
          </Label>
          <Input
            name="address_state"
            placeholder="UF"
            loading={loadingCep}
            disabled={loadingCep}
            required={allRequired}
            maxLength={2}
          />
        </FormGroup>
        {!hideCoordinates && (
          <>
            {/* Latitude */}
            <FormGroup className="span2 span-start">
              <Label isRequired={allRequired} htmlFor="latitude">
                Latitude
              </Label>
              <Input
                name="latitude"
                placeholder="Insira a latitude"
                loading={loadingCep || loadingCoords}
                disabled={loadingCep || loadingCoords}
                required={allRequired}
              />
            </FormGroup>
            {/* Longitude */}
            <FormGroup className="span2">
              <Label isRequired={allRequired} htmlFor="longitude">
                Longitude
              </Label>
              <Input
                name="longitude"
                placeholder="Insira a longitude"
                loading={loadingCep || loadingCoords}
                disabled={loadingCep || loadingCoords}
                required={allRequired}
              />
            </FormGroup>
          </>
        )}
      </Panel>
    </fieldset>
  )
}

export default AddressFields

AddressFields.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
  labelItem: PropTypes.string,
  hideCoordinates: PropTypes.bool,
  hideComplement: PropTypes.bool,
  noPaddingBody: PropTypes.bool,
  allRequired: PropTypes.bool
}

AddressFields.defaultProps = {
  formRef: null,
  labelItem: 'local',
  hideCoordinates: false,
  hideComplement: false,
  noPaddingBody: false,
  allRequired: false
}
