import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiPlus } from 'react-icons/fi'

import { Button } from '~/components/Buttons'

import PlacementForm from '../PlacementForm'

const FormAddFormat = ({ data, onSubmit, isLoading }: EditChildrenProps) => {
  const { t } = useTranslation()
  const [isEditMode, setIsEditMode] = useState(false)

  const handleCancel = () => setIsEditMode(false)

  return (
    <section>
      {!isEditMode && (
        <footer className="mt-2">
          <Button
            text={t('rm:placements.cta.addFormat')}
            iconLeft={<FiPlus />}
            onClick={() => setIsEditMode(true)}
            size="small"
          />
        </footer>
      )}

      {isEditMode && (
        <>
          <hr />
          <PlacementForm
            initialData={data}
            fieldsets={['formats']}
            onSubmit={formData => onSubmit(formData, handleCancel)}
            onCancel={handleCancel}
            isLoading={isLoading}
          />
        </>
      )}
    </section>
  )
}

export default FormAddFormat
