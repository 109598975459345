import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button } from '~/components/Buttons/'
import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { useAlert } from '~/hooks/useAlert'
import { useDeleteConfirmation } from '~/hooks/useDeleteConfirmation'
import { showOperator, deleteOperator } from '~/store/modules/operators/actions'

import FormOperator from '../_elements/FormOperator'

const OperatorsEdit = () => {
  const [initialValue, setInitialValue] = useState()

  const dispatch = useDispatch()

  const { operator, loading, error, loadingDelete } = useSelector(
    state => state.operators
  )

  const [DeleteConfirmation, activeConfirmation, setActiveConfirmation] =
    useDeleteConfirmation()

  const { id } = useParams()

  const [AlertOnError] = useAlert(error)

  const loadOperator = useCallback(() => {
    dispatch(showOperator(id))
  }, [dispatch, id])

  useEffect(() => {
    loadOperator()
  }, [loadOperator])

  useEffect(() => {
    if (operator) {
      setInitialValue(operator)
    }
  }, [operator])

  const handleDelete = () => {
    dispatch(deleteOperator(id))
  }

  const titlePage = useMemo(
    () =>
      initialValue
        ? `Operador: ${initialValue.name} > Editar`
        : 'Operadores > Editar',
    [initialValue]
  )

  return (
    <>
      <PageTitle title={titlePage} />

      <HeaderPages
        title={titlePage}
        backTo="/operators/list"
        labelBreadcrumb="Voltar ao operador"
        sideRightComponent={
          <Button
            template="transparentDanger"
            text="Excluir operador"
            customWidth="auto"
            onClick={setActiveConfirmation}
            loading={loadingDelete}
          />
        }
      />

      <AlertOnError />

      <DeleteConfirmation
        active={activeConfirmation}
        onDelete={handleDelete}
        onCancel={setActiveConfirmation}
        yesMsg="Sim, desejo apagar"
        loading={loadingDelete}
      >
        Tem certeza que deseja apagar este operador?
      </DeleteConfirmation>

      {loading && (
        <Loading status={loading}>Carregando informações do operador</Loading>
      )}

      {initialValue && (
        <FormOperator edit initialData={initialValue} loading={loading} />
      )}
    </>
  )
}
export default OperatorsEdit
