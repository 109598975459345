import { createContext, useContext, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import { history } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import {
  getCampaignPermissionsService,
  getCampaignPermissionsServiceKey
} from '~/modules/retailMedia/services/configuration/campaign-permissions'

const CampaignFormContext = createContext<CampaignFormProviderData>(
  {} as CampaignFormProviderData
)

const CampaignFormProvider = ({ children }: React.PropsWithChildren) => {
  const [costType, setCostType] = useState<CampaignCostType>('cpmcpc')
  // Handle network
  const { isWhiteLabel } = useAppInfo()

  // Handle query params
  const params = useQueryParams()
  const rmid = params.get('rmid')
  const type = params.get('type') as CampaignAdType

  // Should show publisher selection
  const showPublisherFieldset = useMemo(() => !isWhiteLabel, [isWhiteLabel])
  const shouldSelectPublisher = useMemo(
    () => !!((!isWhiteLabel && rmid) || isWhiteLabel),
    [isWhiteLabel, rmid]
  )

  const adTypeIsValid = [
    'product',
    'banner',
    'sponsored_brand',
    'digital_signage'
  ].includes(type)

  // Handle change type
  const handleType = (type: CampaignAdType) => {
    params.set('type', type)
    history.push({ search: params.toString() })
  }

  /**
   * Handle campaign permissions
   */
  const queryKeyCampaignPermissions = [
    getCampaignPermissionsServiceKey,
    { publisher_id: rmid }
  ]

  const { data: campaignPermisions, status: statusCampaignPermisions } =
    useQuery({
      queryKey: queryKeyCampaignPermissions,
      queryFn: async () => {
        const response = await getCampaignPermissionsService({
          publisher_id: rmid
        })

        return response?.data
      }
    })

  /**
   * Form is ready
   */

  const formIsReady = useMemo(
    () => !!campaignPermisions && statusCampaignPermisions !== 'pending',
    [campaignPermisions, statusCampaignPermisions]
  )

  return (
    <CampaignFormContext.Provider
      value={{
        campaignPermisions,
        formIsReady,
        selectedType: type,
        adTypeIsValid,
        shouldSelectPublisher,
        showPublisherFieldset,
        //
        handleType,
        //
        costType,
        setCostType
      }}
    >
      {children}
    </CampaignFormContext.Provider>
  )
}
function useCampaignForm(): CampaignFormProviderData {
  const context = useContext(CampaignFormContext)

  if (!context) {
    throw new Error(
      'useCampaignForm must be used within an CampaignFormProvider'
    )
  }

  return context
}

export { CampaignFormContext, CampaignFormProvider, useCampaignForm }
