import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FiEdit, FiExternalLink, FiShoppingCart, FiX } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useParams } from 'react-router-dom'

import { SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'

import { Address } from '~/components/Address'
import Alert from '~/components/Alert'
import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import FilterLocations from '~/components/FilterLocations'
import LocationsNear from '~/components/LocationsNear'
import Panel from '~/components/Panel'
import { Label, Textarea } from '~/components/unform'
import { formatSearchString } from '~/helpers'
import { getAccountInfo } from '~/helpers/account-info'
import { bodyShoppingCart } from '~/modules/chat/pages/messenger/elements/ShoppingCartPanel/model'
import AddressFields from '~/pages/locations/_elements/FormLocation/Address'
import { indexLocationMinified } from '~/pages/locations/actions'
import { shoppingCartService } from '~/services/shopping-cart'
import { useAppSelector } from '~/store/hooks'
import { updateCustomer } from '~/store/modules/customers/actions'

import * as S from './styles'

const FormGenerateShoppingCart = () => {
  const [loading, setLoading] = useState(false)
  const [editAddress, setEditAddress] = useState(false)
  const [cartId, setCartId] = useState<string | null>(null)
  const [location, setLocation] = useState<string | null | { value: string }>(
    null
  )

  const formRef = useRef(null)
  const formRefCart = useRef(null)

  const dispatch = useDispatch()

  const { customerId } = useParams()

  /**
   * Redux State
   */

  // Get locations
  const { listLocationsMinifiedLoading } = useAppSelector(
    ({ filters }) => filters
  )

  const { listLocationsMinified } = useAppSelector(({ locations }) => locations)

  const queryStringLocation = useMemo(
    () =>
      formatSearchString({
        page: 0,
        quantity: 500
      }),
    []
  )

  useEffect(() => {
    if (!listLocationsMinified.data && !listLocationsMinifiedLoading) {
      dispatch(indexLocationMinified(queryStringLocation))
    }
  }, [
    dispatch,
    listLocationsMinified,
    listLocationsMinifiedLoading,
    queryStringLocation
  ])

  // Get customer info

  const { host } = getAccountInfo()
  const simpleHost = useMemo(() => host?.split('.')[0], [host])

  const { customer, saveCustomersLoading } = useAppSelector(
    state => state.customers
  )

  // TEM O CÓDIGO REPETIDO EM: src/pages/chat/messenger/elements/ShoppingCartPanel/index.js
  const shippingAddress = useMemo(
    () => ({
      type: 'shipping',
      full_name: customer?.name,
      street: customer.address_street,
      number: customer.address_number,
      city: customer.address_city,
      region: customer.address_state,
      neighborhood: customer.address_neighborhood,
      postcode: customer.address_postal_code,
      complement: customer.address_complement,
      country: customer.address_country
    }),
    [customer]
  )

  const shippingAddressIsValid = useMemo(() => {
    const { address_street, address_number, address_city, address_state } =
      customer

    return address_street && address_number && address_city && address_state
  }, [customer])

  const handleChangeLocation = useCallback((location: string) => {
    setLocation(location)
  }, [])

  const handleEditAddress = useCallback(
    () => setEditAddress(oldState => !oldState),
    []
  )

  /**
   * Handle update customer
   */

  const handleSubmitCustomer = useCallback(
    (formData: any) => {
      const body = { ...customer, ...formData, address_country: 'Brazil' }

      dispatch(updateCustomer({ body, onSuccess: () => setEditAddress(false) }))
    },
    [customer, dispatch]
  )

  /**
   * Handle generate shopping cart
   */
  const handleSubmit: SubmitHandler<any> = useCallback(
    async data => {
      setLoading(true)
      const { description } = data
      const locationId = typeof location !== 'string' && location?.value

      const body = bodyShoppingCart({
        description,
        customerId,
        locationId,
        shippingAddress,
        originSource: 'generator'
      })

      try {
        const response = await shoppingCartService.generate({ body })

        const { cart_id } = response.data

        const urlCart = `https://cart.newtail.com.br/${simpleHost}/cart/${cart_id}`
        window.open(urlCart, '_blank', 'noreferrer')

        formRefCart.current.reset()

        setCartId(cart_id)
        setLocation(null)
        setLoading(false)
      } catch (err) {
        toastr.error('Erro', 'Houve um erro ao criar o carrinho.')

        console.error(err)

        setLoading(false)
      }
    },
    [location, customerId, shippingAddress, simpleHost, formRefCart]
  )

  return (
    <>
      {(!shippingAddressIsValid || editAddress) && (
        <Panel>
          {!shippingAddressIsValid && (
            <AlertInfo text="O endereço do cliente está incompleto. Você pode completar o endereço abaixo." />
          )}

          <Form
            onSubmit={handleSubmitCustomer}
            initialData={customer}
            ref={formRef}
          >
            <AddressFields
              formRef={formRef}
              labelItem="do cliente"
              hideCoordinates
              allRequired
            />

            <div className="form-default__buttons">
              <Button
                text="Cancelar"
                onClick={() => setEditAddress(false)}
                template="transparentDanger"
                size="small"
                loading={saveCustomersLoading}
                disabled={saveCustomersLoading}
              />

              <Button
                type="submit"
                text="Salvar endereço"
                textLoading="Salvando..."
                loading={saveCustomersLoading}
                disabled={saveCustomersLoading}
                size="small"
                template="success"
              />
            </div>
          </Form>
        </Panel>
      )}

      {/* Depois que gerou o carrinho */}
      {cartId && (
        <>
          <S.CartCard>
            <div className="text">
              <button
                type="button"
                onClick={() => setCartId(null)}
                className="icon__close"
              >
                <FiX />
              </button>
              <span>Carrinho gerado com sucesso:</span>
              <a
                href={`https://cart.newtail.com.br/${simpleHost}/cart/${cartId}`}
                target="_blank"
                rel="noreferrer"
              >
                Acesse o carrinho <FiExternalLink />
              </a>
            </div>
          </S.CartCard>

          <Button
            text="Gerar outro carrinho"
            onClick={() => setCartId(null)}
            customWidth="100%"
            iconLeft={<FiShoppingCart />}
          />
        </>
      )}

      {/* Se por algum motivo o host não foi carregado */}
      {!host && (
        <Alert>
          Não foi um encontrado um host para esta conta. O host é necessário
          para gerar o carrinho. Tenta atualizar a página, fazer o login
          novamente ou entrar em contato com o suporte técnico.
        </Alert>
      )}

      {!cartId && shippingAddressIsValid && !editAddress && (
        <>
          <Panel>
            <div className="addressSection__wrapper">
              <header>
                <strong>Endereço de entrega:</strong>

                <Button
                  template="transparentPrimary"
                  text="Alterar"
                  iconLeft={<FiEdit />}
                  size="xsmall"
                  onClick={handleEditAddress}
                />
              </header>

              {shippingAddress && <Address shippingAddress={shippingAddress} />}
            </div>
          </Panel>

          {!location && (
            <Panel>
              <LocationsNear address={customer} fullLabel />
            </Panel>
          )}

          <Panel>
            <Label
              isRequired
              text={
                location
                  ? 'Local selecionado:'
                  : 'Escolha um local para entrega:'
              }
            />

            <FilterLocations
              placeholder="Escolha uma loja"
              onChange={handleChangeLocation}
              hideAll
              ignoreRedux
              filterActiveLocations
            />
          </Panel>

          {!location && (
            <Alert>
              Para prosseguir com a criação do carrinho é necessário escolher
              uma loja.
            </Alert>
          )}

          {location && shippingAddressIsValid && !editAddress && (
            <>
              <Panel>
                <Form ref={formRefCart} onSubmit={handleSubmit}>
                  {location && (
                    <div className="wrapTextField">
                      <strong>Lista de compras:</strong>
                      <Textarea
                        name="description"
                        // eslint-disable-next-line max-len
                        placeholder={`- 200g de batata inglesa\n- 1kg arroz branco tio joão\n- 2 pasta de dente colgate tripla ação`}
                        required
                      />
                    </div>
                  )}

                  <footer style={{ marginTop: '1rem' }}>
                    <Button
                      type="submit"
                      text="Gerar carrinho"
                      loading={loading}
                      disabled={!location}
                      template={!location ? 'warning' : 'primary'}
                    />
                  </footer>
                </Form>
              </Panel>
            </>
          )}
        </>
      )}
    </>
  )
}

export default FormGenerateShoppingCart
