import React, { useCallback, useMemo } from 'react'
import { FiMessageSquare, FiPhone } from 'react-icons/fi'
import { HiOutlineUserCircle } from 'react-icons/hi2'
import { IoChatbubblesOutline } from 'react-icons/io5'
import { RiTimerLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import LocationBadge from '~/components/LocationBadge'
import { Spinner } from '~/components/Spinner'
import Tooltip from '~/components/Tooltip'
import { Badge } from '~/components/UI/Badge'
import {
  formatRelativeTime,
  getUserId,
  timeDistance,
  timeDistanceInMinutes,
  updateElapsedTime
} from '~/helpers'
import { setSelectedChat } from '~/modules/chat/store/actions'
import { useAppSelector } from '~/store/hooks'
import { clearCustomerState } from '~/store/modules/customers/actions'

import Avatar from '../Avatar'
import ChatItemMenu from '../ChatItemMenu'
import TagLabel from '../TagLabel'

import * as S from './styles'

const ChatItem = ({ data }: ChatItemProps) => {
  const {
    unreadMessage,
    unreadMessageCount,
    chatId,
    customerName,
    customerNumber,
    lastMessageAt,
    lastMessage,
    session,
    useBot,
    storeNumber,
    locationId = null,
    firstMessageWithoutResponseAt,
    locationName
  } = data

  const {
    selectedStatus,
    selectedChat,
    selectChatsMode,
    chatsSelectedToEdit,
    editLocationMode,
    chatMenuOpen,
    updateChatLoading
  } = useAppSelector(({ chats }) => chats)

  const dispatch = useDispatch()
  /**
   * Sinaliza se a conversa tem sessão ativa
   * */
  const hasSession = useMemo(() => !!session?.sessionId, [session])

  /**
   * Sinaliza se o botão de menu deve ser exibido
   * */
  const showMenuButton = useMemo(
    () => hasSession && !editLocationMode && !selectChatsMode,
    [editLocationMode, hasSession, selectChatsMode]
  )
  /**
   * Sinaliza se o link deverá estar desativado
   *  */
  const disabledLink = useMemo(() => editLocationMode, [editLocationMode])
  /**
   * Manipula qual deverá ser o texto exibido no título do chat
   *  */
  const userLabel = useMemo(
    () => customerName || customerNumber,
    [customerName, customerNumber]
  )
  /**
   * Sinaliza se o chat está aberta no painel principal
   *  */
  const isActive = useMemo(
    () => chatId === selectedChat?.chatId,
    [chatId, selectedChat]
  )
  /**
   * Sinaliza qual deverá ser o estava do fake checkbox no select mode
   *  */
  const isSelected = useMemo(
    () => chatsSelectedToEdit.findIndex(item => item.chatId === chatId) > -1,
    [chatsSelectedToEdit, chatId]
  )
  /**
   * Sinaliza se deve exibir o indcador de carregamento
   *  */
  const shoudlShowLoadingIndicator = useMemo(
    () => chatMenuOpen?.chatId === chatId && updateChatLoading,
    [chatMenuOpen?.chatId, chatId, updateChatLoading]
  )

  /**
   * Tentando contornar casos de chat abertos que não estão mais persistidos no Redux
   */

  const handleSelectChat = useCallback(() => {
    dispatch(clearCustomerState())

    dispatch(setSelectedChat({ selectedChat: data }))
  }, [data, dispatch])

  const sessionTimer = useMemo(
    () =>
      session?.customerRequestedAgentAt &&
      timeDistance(session?.customerRequestedAgentAt),
    [session?.customerRequestedAgentAt]
  )

  // const isStartChat = useMemo(
  //   () => !!session?.userId && !session?.finishedAt,
  //   [session?.finishedAt, session?.userId]
  // )

  /**
   * Sinaliza se deve exibir o nome do atendente
   */

  const isSameUser = useMemo(
    () => session?.userId === getUserId(),
    [session?.userId]
  )

  const showAgentBadge = useMemo(
    () =>
      (!isSameUser || selectedStatus === 'available') &&
      !!session?.userId &&
      !!session?.userName,
    [isSameUser, selectedStatus, session?.userId, session?.userName]
  )

  const timeToAttendMinutes = timeDistanceInMinutes(
    firstMessageWithoutResponseAt
  )

  const formatTime = Number(
    updateElapsedTime(firstMessageWithoutResponseAt).replace(':', '.')
  )

  const badgeTemplate = useMemo(() => {
    if (formatTime <= 0.05) {
      return 'success'
    } else if (formatTime > 0.05 && formatTime <= 0.15) {
      return 'warning'
    } else {
      return 'danger'
    }
  }, [formatTime])

  return (
    <S.Container active={isActive} isSelected={isSelected}>
      {disabledLink && <S.Blanket />}

      <NavLink
        to={`/chats/${chatId}`}
        title={lastMessage || `Ler mensages de ${userLabel}`}
        onClick={handleSelectChat}
      >
        <S.Content unread={unreadMessage}>
          <div className="userMessage wrapper">
            <S.Avatar>
              <Avatar
                customerName={customerName}
                selectChatsMode={selectChatsMode}
                chatId={chatId}
                sessionId={session?.sessionId}
                isSelected={isSelected}
                botActive={session?.botActive}
                useBot={useBot}
              />
            </S.Avatar>

            <div className="column">
              <div className="firstRow">
                <div className="customerName">{userLabel}</div>

                {hasSession ? (
                  <div className="time">
                    {formatRelativeTime(lastMessageAt)}
                  </div>
                ) : (
                  <div className="time">Arquivo</div>
                )}
              </div>

              <div className="secondRow">
                <div className="lastMessage">
                  <FiMessageSquare /> {lastMessage}
                </div>

                <aside>
                  {unreadMessage && (
                    <div className="unreadBadge">
                      {unreadMessageCount || ''}
                    </div>
                  )}

                  {shoudlShowLoadingIndicator && <Spinner />}

                  {showMenuButton && (
                    <ChatItemMenu
                      chatId={chatId}
                      sessionId={session?.sessionId}
                    />
                  )}
                </aside>
              </div>
            </div>
          </div>

          <div className="tagsRow wrapper">
            <Badge title={storeNumber}>
              <span className="icon__left">
                <FiPhone />
              </span>
              {storeNumber?.slice(-4)}
            </Badge>

            {(session?.locationId || locationId) && (
              <LocationBadge
                locationName={locationName}
                locationId={session?.locationId || locationId}
              />
            )}

            {sessionTimer && (
              <Tooltip
                icon={
                  <Badge template={'warning'}>
                    <span className="icon__left">
                      <RiTimerLine />
                    </span>
                    {sessionTimer}
                  </Badge>
                }
                text="Tempo desde a primeira mensagem"
                positionProp="right-top"
              />
            )}
            {/* Removendo enquanto ainda não temos a funcionalidade de transferir sem começar */}
            {/* {isStartChat && (
              <Badge template="primary">
                <span className="icon__left">
                  <IoChatbubblesOutline />
                </span>
                Em atendimento
              </Badge>
            )} */}

            {showAgentBadge && (
              <Tooltip
                icon={
                  <Badge template="primary">
                    <span>
                      <HiOutlineUserCircle />
                      {session?.userName}
                    </span>
                  </Badge>
                }
                text="Atendente responsável pela conversa"
                positionProp="center-top"
              />
            )}

            {firstMessageWithoutResponseAt && badgeTemplate && (
              <S.WrapperBadge>
                <Tooltip
                  icon={
                    <Badge template={badgeTemplate}>
                      {timeToAttendMinutes || '0min'}
                    </Badge>
                  }
                  text="Tempo desde a última mensagem enviada"
                  positionProp="left"
                />
              </S.WrapperBadge>
            )}
          </div>

          <div className="tagsRow wrapper">
            {session?.tags?.length > 0 &&
              session?.tags?.map(tag => <TagLabel tagId={tag} key={tag} />)}
          </div>
        </S.Content>
      </NavLink>
    </S.Container>
  )
}

export default ChatItem
