import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.div`
  ${({ theme }) => css`
    &.fullWidth {
      width: 100%;
    }

    > small {
      margin-top: ${theme.spacings.xxxsmall};
      color: ${theme.colors.stormGray};
      display: block;
    }

    &.actionButtons {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;

      @media ${device.laptop_max} {
        justify-content: space-between;
        flex-wrap: wrap;
      }

      > * + * {
        /* @media ${device.laptop_max} {
          margin-top: 0.25rem;
        } */

        @media ${device.laptop} {
          margin-left: 1rem;
        }
      }
    }

    &:focus-within,
    &.opened {
      > label {
        color: ${theme.primary};
      }
    }
  `}
`
