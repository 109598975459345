import { FiAlertTriangle, FiX } from 'react-icons/fi'

import ConfirmationModalForm from './elements/ConfirmationModalForm'

import * as S from './styles'

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  isOpen,
  onClose,
  children,
  onSubmit,
  cancelButton,
  confirmationValue,
  confirmationButton,
  submitLoading
}) => (
  <S.Modal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false}>
    <S.Header>
      <S.Title>{title}</S.Title>
      <S.CloseButton type="button" onClick={onClose}>
        <FiX />
      </S.CloseButton>
    </S.Header>

    <S.ConfirmationContainer>
      <S.WarningIcon>
        <FiAlertTriangle />
      </S.WarningIcon>
      <S.Content>
        <S.ConfirmationTitle>Alerta</S.ConfirmationTitle>
        <S.Description>{children}</S.Description>
      </S.Content>
    </S.ConfirmationContainer>

    <div>
      <ConfirmationModalForm
        onSubmit={onSubmit}
        confirmationValue={confirmationValue}
        cancelButton={cancelButton}
        onClose={onClose}
        confirmationButton={confirmationButton}
        submitLoading={submitLoading}
      />
    </div>
  </S.Modal>
)

ConfirmationModal.defaultProps = {
  title: 'Tem certeza que deseja excluir?',
  isOpen: false,
  children:
    'Você tem certeza que deseja fazer isso? Essa ação não poderá ser desfeita.',
  cancelButton: 'Cancelar',
  confirmationButton: 'Excluir',
  confirmationValue: '',
  onSubmit: () => null
}

export default ConfirmationModal
