import { history } from '~/helpers';

import { template as service } from '../../services';
import { types } from './constants';
/**
 * Create */
const create = body => ({
  type: types.create,
  payload: service.create(body),
  successFeedbackMsg: 'Template criado com sucesso.',
  getErrorFeedbackMsg: error =>
    error.response?.data?.error?.message ||
    'Houve um erro ao criar o template.',
  onSuccess: ({ data }) => {
    // eslint-disable-next-line no-underscore-dangle
    history.push(`/messaging-campaigns/templates/view/${data._id}`);
  },
});

/**
 * Get one */
const get = id => ({
  type: types.get,
  payload: service.get(id),
});

/**
 * Update
 *
 * @param {string} data.id Id do item atualizado
 * @param {object} data.body Corpo da requisição de atualização
 */
const update = data => ({
  type: types.update,
  payload: service.update(data),
});

/**
 * Get all */
const list = queries => ({
  type: types.list,
  payload: service.list(queries),
});

/**
 * Handle page */
const setPage = value => ({
  type: types.page,
  payload: value,
});

/**
 * Handle items por page */
const setPerPage = value => ({
  type: types.perPage,
  payload: value,
});

/**
 * Delete */

const remove = id => ({
  type: types.remove,
  payload: service.remove(id),
  successFeedbackMsg: 'Template removido com sucesso.',
  getErrorFeedbackMsg: error =>
    error.response?.data?.error?.message ||
    'Houve um erro ao deletar o template.',
  onSuccess: () => {
    history.push('/messaging-campaigns/templates/list');
  },
});

const templateActions = {
  list,
  setPage,
  setPerPage,
  get,
  create,
  update,
  remove,
};

export default templateActions;
