import { publisherConfiguration as service } from '~/modules/retailMedia/services/industry'

import { types } from './constants'

/**
 * GET Publisher Configuration
 * Traz todas as configurações de um publisher necessários para rodar uma campanha
 *
 * @param {strin} id Publisher ID
 * @returns PublisherConfiguration
 */
export const getPublisherConfiguration = (id: string) => ({
  type: types.list,
  payload: service.getPublisherConfigurationService(id)
})

export default {
  getPublisherConfiguration
}
