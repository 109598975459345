import { SET_SELECTED_TEMPLATE, RESET_SELECTED_TEMPLATE } from '../constants';

/**
 * Define the selected template that will be used in the chat send field
 * @param {string} message Template text
 */
export const setSelectedTemplate = ({ message }) => ({
  type: SET_SELECTED_TEMPLATE,
  payload: message,
  getErrorFeedbackMsg: error =>
    error.response?.data?.error || 'Houve um erro ao carregar o template.',
});

export const resetSelectedTemplate = () => ({
  type: RESET_SELECTED_TEMPLATE,
});
