import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    .rule {
      display: flex;
      align-items: center;
      line-height: 1.125;
      padding: 0.25rem 0;

      .icon {
        margin-right: 0.5rem;
        font-size: 1rem;

        transition: all 0.3s ease-in-out;

        &.success {
          color: ${theme.colors.success};
        }
        &.error {
          color: ${theme.colors.warning};
        }
      }
    }
  `}
`;
