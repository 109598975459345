import React, { useMemo } from 'react'
import { IconType } from 'react-icons'
import { FiChevronsRight } from 'react-icons/fi'

import { t } from 'i18next'

import Panel from '~/components/Panel'
import VerticalTimeline from '~/components/VerticalTimeline'
import { formatDateToString } from '~/helpers'

import { statusType } from '../StatusBar/statusType'

import * as S from './styles'

interface Props {
  data: [
    {
      campaign_id: string
      created_at: string
      deleted_at: null
      id: string
      note: string
      status: string
      updated_at: string
      user_id: string
      user_name: string
    }
  ]
}

const statusFormatted = Object.values(statusType).map(status => ({
  type: status.type,
  Icon: status?.iconHistory || FiChevronsRight,
  template: status.color,
  label: status.label
}))

const StatusHistory = ({ data }: Props) => {
  const groupedStatusByDate: GroupVerticalTimeline = useMemo(() => {
    const newData = data?.reduce((prev, current) => {
      const currentSatus = statusFormatted.find(
        item => item.type === current.status
      )

      const date = formatDateToString(current.updated_at, 'dddd DD/MM/YYYY')

      const formattedCurrent: ItemVerticalTimeline = {
        ...(current || []),
        ...currentSatus,
        id: current.id || `id_${Math.random()}`,
        time: formatDateToString(current.created_at, 'HH:mm'),
        title: (
          <>
            {currentSatus?.label}

            {current.status === 'approved' && !!current.user_name && (
              <S.Approved>
                {t('rm:campaignApprovedBy', { user_name: current.user_name })}
              </S.Approved>
            )}

            {current.status === 'rejected' && !!current.user_name && (
              <S.Approved>
                {t('rm:campaignRejectedBy', { user_name: current.user_name })}
              </S.Approved>
            )}

            {current.status === 'rejected' && !!current.note && (
              <S.Approved>
                {t('rm:rejectionReason', { note: current.note })}
              </S.Approved>
            )}
          </>
        )
      }

      return {
        ...prev,
        [date]: [...(prev[date] || []), formattedCurrent]
      }
    }, {})

    const newDataToArray: GroupVerticalTimeline = Object.entries(newData).map(
      ([title, data]: [title: string, data: ItemVerticalTimeline[]]) => ({
        title,
        data
      })
    )

    return newDataToArray
  }, [data])

  return (
    <Panel
      isCollapsible
      initialCollapsibleState={false}
      title={t('rm:statusHistory')}
    >
      <VerticalTimeline
        data={groupedStatusByDate}
        className="history__status"
      />
    </Panel>
  )
}

export default StatusHistory
