import React from 'react';
import { FiHelpCircle } from 'react-icons/fi';

import Panel from '~/components/Panel';
import Tooltip from '~/components/Tooltip';
import { Label, FormGroup, Input, Checkbox } from '~/components/unform';

const CatalogFields = () => {
  return (
    <fieldset>
      <Panel title="Dados do catálogo" className="form-grid" isCollapsible>
        <FormGroup className="span-full">
          <Label htmlFor="email">Formato do código de barras da balança</Label>
          <Input
            id="gtin_format"
            name="gtin_format"
            placeholder="Insira o formato aqui"
            type="text"
          />
        </FormGroup>

        <FormGroup className="span-full">
          <Label
            htmlFor="ignore_product_update"
            text="Tempo mínimo de atualização de um produto"
            helperText="Por padrão, os produtos com mais de 48 horas sem atualização no catálogo ficarão indisponíveis e não serão retornados nas consultas. Esse comportamento pode ser ignorando através da funcionalidade abaixo."
          />

          <Checkbox
            name="ignore_product_update"
            label={
              <>
                Ignorar o tempo mínimo de atualização dos produtos no carrinho
                <Tooltip
                  icon={<FiHelpCircle />}
                  text="Essa funcionalidade desabilita a função de indisponibilizar no catálogo os produtos com mais de 48h sem atualização."
                />
              </>
            }
            id="ignore_product_update"
          />
        </FormGroup>

        <FormGroup className="span-full">
          <Label
            htmlFor="merge_products"
            text="Enriquecer o catálogo"
            helperText="Disponibilizamos um catálogo com imagens, nomes otimizados, descrição, etc. Você pode mesclar as informações do catálogo deste local com o catálogo Newtail através da funcionalidade abaixo."
          />

          <Checkbox
            name="merge_products"
            label={
              <>
                Enriquecer dados do catálogo local com o catálogo Newtail
                <Tooltip
                  icon={<FiHelpCircle />}
                  text="Essa funcionalidade enriquece o catálogo do local com os dados do catálogo Newtail."
                />
              </>
            }
            id="merge_products"
          />
        </FormGroup>

        <FormGroup className="span-full">
          <Label
            text="Controle de estoque"
            helperText="Por padrão, os produtos com estoque negativo ou estoque zero ficarão indisponíveis e não serão retornados nas consultas. Esses comportamentos podem ser desativados através das funcionalidades abaixo."
          />

          <Checkbox
            name="use_negative_stock"
            label={
              <>
                Não desativar produtos com estoque negativo
                <Tooltip
                  icon={<FiHelpCircle />}
                  text="Os produtos com estoque negativo serão exibidos como produtos disponíveis nas consultas."
                />
              </>
            }
            id="use_negative_stock"
          />

          <Checkbox
            name="use_zero_stock"
            label={
              <>
                Não desativar produtos com estoque zero
                <Tooltip
                  icon={<FiHelpCircle />}
                  text="Os produtos com estoque zero serão exibidos como produtos disponíveis nas consultas."
                />
              </>
            }
            id="use_zero_stock"
          />
        </FormGroup>
      </Panel>
    </fieldset>
  );
};

export default CatalogFields;
