import React, { useCallback, useMemo } from 'react'
import { FiCheck, FiUser } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { setSelectedChatToEdit } from '~/modules/chat/store/actions'

import { Container } from './styles'

const Avatar = ({
  customerName,
  selectChatsMode,
  chatId,
  sessionId,
  isSelected,
  botActive,
  useBot
}) => {
  const dispatch = useDispatch()

  const hasSession = useMemo(() => !!sessionId, [sessionId])
  const initialsCustomerName = useMemo(() => {
    if (customerName) {
      const secondName = customerName.split(' ')[1]
      const hasSecondName = secondName && secondName.match(/([a-zA-Z])/g)

      const regex = hasSecondName ? /\b([a-zA-Z0-9])/g : /([a-zA-Z0-9])/g

      const initials = customerName
        .match(regex)
        ?.slice(0, 2)
        .join('')
        .toUpperCase()

      if (initials) {
        return <span className="initials">{initials}</span>
      }
    }

    return <FiUser />
  }, [customerName])

  const handleSelect = useCallback(
    e => {
      e.preventDefault()

      dispatch(setSelectedChatToEdit({ chatId, sessionId }))
    },
    [chatId, dispatch, sessionId]
  )

  return (
    <Container
      className="avatar__wrapper"
      selectChatsMode={selectChatsMode}
      isSelected={isSelected}
      hasSession={hasSession}
      useBot={useBot}
      botActive={botActive}
    >
      {selectChatsMode && (
        <button type="button" onClick={handleSelect}>
          {isSelected && <FiCheck />}
        </button>
      )}

      {!selectChatsMode && initialsCustomerName}
    </Container>
  )
}

export default Avatar

Avatar.propTypes = {
  chatId: PropTypes.string,
  sessionId: PropTypes.string,
  avatarURL: PropTypes.string,
  customerName: PropTypes.string,
  selectChatsMode: PropTypes.bool,
  useBot: PropTypes.bool,
  isSelected: PropTypes.bool,
  botActive: PropTypes.bool
}

Avatar.defaultProps = {
  chatId: null,
  sessionId: null,
  avatarURL: null,
  customerName: null,
  selectChatsMode: false,
  useBot: true,
  isSelected: false,
  botActive: true
}
