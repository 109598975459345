import React, { useMemo } from 'react'
import { useTransition } from 'react-spring'

import { SidePanelMessage, useSidePanel } from '~/hooks/useSidePanel'

import SidePanel from './SidePanel'
import { Overlay } from './SidePanel/styles'

import { Container } from './styles'

interface SidePanelContainerProps {
  messages: SidePanelMessage[]
}

const SidePanelContainer: React.FC<SidePanelContainerProps> = ({
  messages
}) => {
  const { removeSidePanel } = useSidePanel()

  const messagesWithTransitions = useTransition(messages, {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(100%)' }
  })

  const overlayWithTransitions = useTransition(messages, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  const callbackRemove = useMemo(
    () => messages[messages.length - 1]?.callbackRemove || null,
    [messages]
  )

  return (
    <Container>
      {overlayWithTransitions(styles => (
        <Overlay
          onClick={() => removeSidePanel({ callback: callbackRemove })}
          role="button"
          style={styles}
        />
      ))}

      {messagesWithTransitions((styles, item) => (
        <SidePanel message={item} style={styles} />
      ))}
    </Container>
  )
}

export default SidePanelContainer
