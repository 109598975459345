import { MdOutlineDeleteOutline } from 'react-icons/md'

import { Button } from '~/components/Buttons'

export const schema: TableSchema<UploadedMediaTableItem> = {
  media: {
    title: 'Mídia',
    render: rowData => rowData?.fileMedia.name
  },
  mediaDimension: {
    title: 'Dimensões da mídia',
    render: rowData => (
      <div style={{ lineHeight: 1 }}>
        <strong className="title">
          <code>
            {rowData?.dimensions?.width} x {rowData?.dimensions?.height}
          </code>
        </strong>
      </div>
    ),
    className: 'order__details col__infos col__title'
  },
  mediaConfig: {
    title: 'Duração da mídia',
    render: rowData => (
      <div style={{ lineHeight: 1 }}>
        {rowData?.dimensions?.duration && (
          <strong className="title">
            {Math.floor(rowData.dimensions.duration)} segundos
          </strong>
        )}
      </div>
    ),
    className: 'order__details col__infos col__title'
  },
  actions: {
    className: 'text-end',
    render: rowData => (
      <Button
        text="Remover mídia"
        onClick={() => rowData.handleDeleteMedia(rowData)}
        template="danger"
        size="small"
        iconLeft={<MdOutlineDeleteOutline />}
      />
    )
  }
}
