export default {
  list: {
    loading: false,

    data: [],
    error: null,

    total: 0,
    pages: 0,
    page: 1,
    perPage: 10,

    sortKey: null,
    sortDirection: null,
    sortKeyDefault: null,
    sortDirectionDefault: null
  },
  item: {
    data: null,
    loading: false,
    error: null
  },
  products: {
    selectecProducts: [],
    loadingAddBulk: false,
    rejectedProducts: null
  }
} as RetailMediaCampaignState
