import axios from 'axios'

/**
 * Retorna a URL para o dashboard de vendas da indústria
 */
export const getDashboardAnalyticsUrl = ({ queries }) =>
  axios.post(`/metabase/analytics`, queries)

/**
 * Retorna a URL para o dashboard de consumidados da indústria
 */
export const getDashboardCustomersUrl = ({ queries }) =>
  axios.post(`/metabase/customers`, queries)

/**
 * Retorna a URL para o dashboard de árvore de indicadores
 */
export const getDashboardKpi = ({ queries }) =>
  axios.post(`/metabase/kpi`, queries)

/**
 * Retorna a URL para o dashboard de estoque
 */
export const getDashboardStock = ({ queries }) =>
  axios.post(`/metabase/stocks`, queries)

/**
 * Retorna a URL para o dashboard de vendas do supermercado
 */
export const getDashboardGroceryAnalyticsUrl = ({ queries }) =>
  axios.post(`/metabase/analytics-grocery`, queries)

/**
 * Retorna a URL para o dashboard de consumidados do supermercado
 */
export const getDashboardGroceryCustomersUrl = ({ queries }) =>
  axios.post(`/metabase/customers-grocery`, queries)

/**
 * Retorna a URL para o dashboard de attendance
 */
export const getDashboardAttendance = ({ queries }) =>
  axios.post(`/metabase/attendance`, {
    start: queries.start,
    finish: queries.finish
  })
