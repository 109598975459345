import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SelectSearchable from '~/components/SelectSearchable'
import { getAssociatedChannels } from '~/store/modules/channels/actions'
import { setFilteredChannel } from '~/store/modules/filters/actions'

/**
 * Componente para filtrar a listagem por canais.
 * Ele possibilita a seleção de diversos locais e mantém no estado global: filters.channel.
 *
 * Não recebe nenhum parâmetro e retorna um select procurável.
 *
 * @example <FilterChannel />
 */
const FilterChannel = () => {
  const dispatch = useDispatch()

  // const { associatedChannels } = useSelector(state => state.channels);

  // useEffect(() => {
  //   if (!associatedChannels) {
  //     dispatch(getAssociatedChannels());
  //   }
  // }, [dispatch, associatedChannels]);

  const { channel: filteredChannel } = useSelector(state => state.filters)

  const handleChangeChannel = selectedChannel => {
    dispatch(setFilteredChannel({ filteredChannel: selectedChannel }))
  }

  const staticChannelOptions = [
    {
      label: 'Americanas Delivery',
      value: 'americanas_delivery'
    },
    {
      label: 'B2B',
      value: 'b2b'
    },
    {
      label: 'E-Commerce',
      value: 'magento_ecommerce'
    },
    {
      label: 'Televendas',
      value: 'televendas'
    },
    {
      label: 'WhatsApp',
      value: 'whatsapp'
    },
    {
      label: 'WhatsApp Bot',
      value: 'whatsapp_bot'
    }
  ]

  const channelOptions = [
    { label: 'Todos os canais', value: null },
    ...staticChannelOptions
    // ...associatedChannels,
  ]

  return (
    <SelectSearchable
      placeholder="Filtrar por canais"
      options={channelOptions}
      onChange={handleChangeChannel}
      defaultValue={[filteredChannel]}
    />
  )
}

export default FilterChannel
