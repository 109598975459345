import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { Spinner } from '../Spinner'

import * as S from './styles'

const Loading: React.FC<LoadingProps> = ({
  status,
  children = null,
  alignCenter = false,
  onlyLine = false
}) => {
  const { t } = useAPPTranslation()

  return status ? (
    <S.Container
      alignCenter={alignCenter}
      onlyLine={onlyLine}
      className="LoadingContainer"
    >
      {!onlyLine && (
        <>
          <Spinner /> {children || `${t('c:words.loading')}...`}
        </>
      )}
    </S.Container>
  ) : (
    <></>
  )
}

export default Loading
