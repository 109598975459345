import React from 'react'

import { EditBudget } from '~/modules/retailMedia/components/EditBudget'

/**
 * Componente isolado usado na listagem de anúncios para edição de custo
 */
const EditBudgetColumn: RowComponent = data => {
  return (
    <EditBudget
      editType={data.ad_type === 'product' ? 'cpc' : 'cpm'}
      data={data}
      campaignData={{
        settings: {
          cpc: data.campaign_settings.cpc,
          cpm: data.campaign_settings.cpm
        },
        product_sku: data.product_sku
      }}
    />
  )
}

export default EditBudgetColumn
