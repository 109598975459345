import React from 'react'
import { FiCopy, FiEdit } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { ButtonNavLink } from '~/components/Buttons'

import CancelButton from '../ListMenu/CancelButton'
import DeleteButton from '../ListMenu/DeleteButton'
import FinishButton from '../ListMenu/FinishButton'
import PauseButton from '../ListMenu/PauseButton'
import ReactiveButton from '../ListMenu/ReactiveButton'

import * as S from './styles'

const HeaderButtons = ({ isEditPage = false }) => {
  const { data, loading } = useSelector(
    ({ promotionFlyer }) => promotionFlyer.flyers.view
  )
  const status = data?.status?.type

  return !loading && data ? (
    <>
      <S.Container>
        {!isEditPage && status !== 'finished' && status !== 'canceled' && (
          <ButtonNavLink
            text="Editar"
            to={`/promotional-flyers/edit/${data?.id}`}
            size="small"
            iconLeft={<FiEdit />}
            customWidth="auto"
          />
        )}

        <ButtonNavLink
          to={`/promotional-flyers/create/?duplicate=${data?.id}`}
          text="Duplicar"
          template="primaryOutlined"
          size="small"
          iconLeft={<FiCopy />}
          customWidth="auto"
        />

        {status === 'active' && <PauseButton data={data.raw} />}

        {status === 'pause' && <ReactiveButton data={data.raw} />}

        {status === 'pause' && <FinishButton data={data.rae} />}

        {status === 'scheduled' && <CancelButton data={data.raw} />}

        {status !== 'active' && status !== 'pause' && (
          <DeleteButton data={data.raw} />
        )}
      </S.Container>
    </>
  ) : (
    <></>
  )
}

export default HeaderButtons

HeaderButtons.propTypes = {
  isEditPage: PropTypes.bool
}

HeaderButtons.defaultProps = {
  isEditPage: false
}
