import React, { useCallback } from 'react'
import {
  TbLayoutSidebarLeftCollapseFilled,
  TbLayoutSidebarLeftExpandFilled
} from 'react-icons/tb'
import { useDispatch } from 'react-redux'

import { handleFullScreenMode } from '~/modules/chat/store/actions'
import { useAppSelector } from '~/store/hooks'

import * as S from './styles'

const ToggleFullScreen: React.FC = () => {
  const dispatch = useDispatch()

  const { fullScreen } = useAppSelector(state => state.chats)

  const handleToggleFullScreen = useCallback(() => {
    dispatch(handleFullScreenMode({ value: !fullScreen }))
  }, [dispatch, fullScreen])

  return (
    <S.Container onClick={handleToggleFullScreen}>
      {fullScreen ? (
        <TbLayoutSidebarLeftExpandFilled />
      ) : (
        <TbLayoutSidebarLeftCollapseFilled />
      )}

      <span>{fullScreen ? 'Sair do' : 'Entrar no'} modo tela cheia</span>
    </S.Container>
  )
}

export default ToggleFullScreen
