import { formatDateExibition } from '~/helpers';

import { getStatus } from '../../helpers/getStatus';

export const modelFlyersList = data => {
  const formattedDate = data.map(item => {
    const { start_at, end_at } = item;

    return {
      ...item,
      start_date: formatDateExibition(start_at),
      end_date: formatDateExibition(end_at),
      status: getStatus(item),
      // Esse objeto raw serve para termos o objeto cru que recebemos
      // para mandar nas atualizações simples que não passam pelo model
      raw: item,
    };
  });

  return formattedDate;
};
