import i from 'i18next'

import Panel from '~/components/Panel'
import { FormGroup, Label, Radio } from '~/components/unform'
import { contexts } from '~/modules/retailMedia/constants'

const FormContext: React.FC = () => {
  const contextOptions: RadioOptionProps[] = contexts.map(context => {
    const value = i.t(`rm:context.${context}`) as string

    return {
      label: i.t('common:capitalize', { value }) as string,
      value: context,
      description: <code>{context}</code>
    }
  })

  return (
    <Panel title="Contexto">
      <FormGroup>
        <Label
          text={i.t('rm:placements.form.context.description')}
          htmlFor="context"
        />
        <Radio
          options={contextOptions}
          name="context"
          inline
          required
          itemSize="small"
        />
      </FormGroup>
    </Panel>
  )
}

export default FormContext
