/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import EmptyPage from '~/components/empty-page'
import Loading from '~/components/Loading'
import Pagination from '~/components/pagination'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { formatSearchString, queryDatesDatepickerIsolated } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import useFetchSWR from '~/hooks/useFetchSWR'
import { resetFilters } from '~/store/modules/filters/actions'

import * as S from '../styles'
import { schema } from './table-schema'

const CartsTable = () => {
  const [page, setPage] = useState(1)
  const [data, setData] = useState()

  const dispatch = useDispatch()

  const { startDate, endDate } = useSelector(state => state.datepickerHeader)

  const { itemUpdated } = useSelector(({ shoppingCart }) => shoppingCart)

  const { locations: filteredLocations, shoppingCartStatus } = useSelector(
    state => state.filters
  )

  const filteredLocationsIds = useMemo(
    () => filteredLocations?.value || null,
    [filteredLocations]
  )

  const shoppingCartStatusType = useMemo(
    () => shoppingCartStatus?.value || null,
    [shoppingCartStatus]
  )

  /**
   * Gerenciando a Listagem de pedidos
   */
  const quantityPerPage = 100
  const queryString = useMemo(
    () =>
      formatSearchString({
        quantity: quantityPerPage,
        location_id: filteredLocationsIds,
        status: shoppingCartStatusType,
        ...queryDatesDatepickerIsolated(startDate, endDate)
      }),
    [endDate, filteredLocationsIds, shoppingCartStatusType, startDate]
  )

  const { data: dataSWR, error: errorSWR } = useFetchSWR({
    url: `${process.env.REACT_APP_HOST}/api/shopping-cart/minified?${queryString}&page=${page}`,
    refreshInterval: 6000,
    revalidateOnFocus: true
  })

  const [loading, setLoading] = useState(!dataSWR)
  const [total, setTotal] = useState()
  const [errorOnPage, setErrorOnPage] = useState(null)
  const [AlertOnError] = useAlert(errorOnPage)

  useEffect(() => {
    setLoading(true)
    setPage(1)
  }, [queryString])

  useEffect(() => {
    if (dataSWR) {
      setData(dataSWR.data)
      setTotal(dataSWR.total)
      setLoading(false)
    }
  }, [dataSWR])

  useEffect(() => {
    if (itemUpdated) {
      const updatedData = dataSWR?.data.map(item =>
        item.cart_id === itemUpdated.cart_id
          ? { ...item, status: itemUpdated.status }
          : item
      )

      setData(updatedData)
    }
  }, [itemUpdated, dataSWR])

  useEffect(() => {
    if (errorSWR) {
      setErrorOnPage('Houve um erro com a conexão.')
    }
    if (!errorSWR) {
      setErrorOnPage(null)
    }
  }, [errorSWR])

  /**
   * Gerenciando a paginação
   */

  const handlePagination = page => {
    setLoading(true)
    setPage(page)
  }

  /**
   * Handle reset filters
   */

  const handleResetFilter = useCallback(
    () => dispatch(resetFilters()),
    [dispatch]
  )

  return (
    <>
      <Loading status={loading}>Carregando lista de carrinhos...</Loading>

      <AlertOnError />

      {total === 0 && (
        <EmptyPage
          title="Nenhum carrinho encontrado"
          subtitle="Não encontramos carrinhos com os filtros aplicados."
          titleButton="Limpar filtros"
          svgPath="photos/orders-empty"
          onClick={handleResetFilter}
        />
      )}

      <Panel
        noPaddingBody
        isLoading={loading}
        title="Todos os carrinhos"
        headerSideContent={`${dataSWR?.total || 0} itens`}
        description="Aqui você encontrará todos os carrinhos criados. Você pode filtrar por local, status ou intervalo de datas."
      >
        {data && total > 0 && (
          <Table schema={schema} data={data} total={total} />
        )}
      </Panel>

      {total > 0 && total > quantityPerPage && (
        <S.Footer>
          <Pagination
            count={total}
            page={page}
            limit={quantityPerPage}
            onClickPagination={handlePagination}
            hasFirstLast
            hasPagination
            alignCenter
          />
          <p>
            {total} {total > 1 ? 'itens' : 'item'}
          </p>
        </S.Footer>
      )}
    </>
  )
}

export default CartsTable
