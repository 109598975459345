import React, { useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { FiExternalLink, FiMapPin, FiUser } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'

import moment from 'moment'
import PropTypes from 'prop-types'

import { ButtonNavLink } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'
import {
  addLogos,
  formatDateExhibitionShort,
  formatDateExibition,
  formatMoney,
  paymentMethodsLabels,
  paymentStatusLabels
} from '~/helpers'

import useCheckout from '../../_hooks/useCheckout'
import { statusInProgress } from '../../_status/order'
import { SlotAlert } from '../../list/styles'

import * as S from './styles'

const Item = ({ data, index }) => {
  const { isOpenValue, showLinkCheckout, urlCheckout } = useCheckout({
    order: data
  })

  const isNow = useMemo(() => data.shipments[0]?.use_delivery_time, [data])
  const shipment = useMemo(() => data.shipments?.[0], [data])
  const shipmentType = useMemo(() => data.shipments?.[0]?.type, [data])
  const currentSlot = useMemo(
    () => shipment?.slot || 'Sem slot cadastrado',
    [shipment]
  )

  const labelShipmentType = {
    shipping_from_store: 'Entrega em casa',
    pick_up_in_store: 'Retirada na loja'
  }

  const styles = {
    shipping_from_store: { color: '#55AA87' },
    pick_up_in_store: { color: '#AAAA22' }
  }

  const isPending = useMemo(() => data.total_price === 0, [data.total_price])

  // Handle delay
  const isToday = useMemo(
    () => moment().isSame(data?.schedule_datetime || data.schedule_date, 'day'),
    [data]
  )

  const limitHour = useMemo(() => {
    const endHour = data.schedule_datetime

    const startTime = moment()
    const endTime = moment(endHour)

    return endTime.diff(startTime, 'minutes')
  }, [data.schedule_datetime])

  const templateAlertSlot = useMemo(
    () => (limitHour < 30 ? 'danger' : 'warning'),
    [limitHour]
  )

  const showBadgeDelayOrder = useMemo(
    () => limitHour < 60 && statusInProgress.includes(data.status[0].type),
    [data.status, limitHour]
  )

  const labelForBadgeDelay = useMemo(() => {
    const normalizeTime = limitHour < 0 ? limitHour * -1 : limitHour

    function getTimeFromMins(mins) {
      var h = (mins / 60) | 0,
        m = mins % 60 | 0
      return moment.utc().hours(h).minutes(m).format('H:mm')
    }

    if (normalizeTime < 0) {
      return `Normal`
    }

    if (normalizeTime >= 1440 || normalizeTime < 0) {
      return `Atrasado`
    }

    const formatTime =
      normalizeTime > 60
        ? `${getTimeFromMins(normalizeTime)}h`
        : `${normalizeTime} minutos`

    return limitHour > 0 ? `Faltam ${formatTime}` : `Atrasado ${formatTime}`
  }, [limitHour])

  return (
    <Draggable draggableId={data.id} index={index}>
      {(dragProvided, dragSnapshot) => (
        <S.Container
          wasMoved={data.wasMoved}
          isNew={data.isNew}
          isDragging={dragSnapshot.isDragging}
          isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
          ref={dragProvided.innerRef}
          {...dragProvided.dragHandleProps}
          {...dragProvided.draggableProps}
        >
          <NavLink to={`/order/${data.id}`}>
            <div className="orderInfos">
              <div className="orderId">#{data.order_id}</div>

              {showBadgeDelayOrder && (
                <SlotAlert template={templateAlertSlot} isPast={limitHour < 0}>
                  {labelForBadgeDelay}
                </SlotAlert>
              )}
              <div className="channelId">
                <div className={` channel__${data.channel_id}`}>
                  {addLogos(data.channel_id)} {data.isNew && <Spinner />}
                  <div className="label">{data.channel_id}</div>
                </div>
              </div>
            </div>

            <div className="shippingSection">
              <div className="location">
                <FiMapPin />
                <div className="name">
                  {data.location && data.location[0]?.name
                    ? data.location[0].name
                    : 'Sem loja'}
                </div>
              </div>

              <div className="time">
                {!!data.shipments?.[0]?.type && (
                  <>
                    <strong>
                      {formatDateExibition(
                        data?.schedule_datetime || data.schedule_date
                      )}
                    </strong>
                    <br />
                    {isToday && isNow ? 'Hoje até ' : ''}
                    {isToday && isNow ? shipment.schedule_time : currentSlot}
                    <br />
                    <span style={{ ...styles[shipmentType] }}>
                      {labelShipmentType?.[shipmentType]}
                    </span>
                  </>
                )}
                {/* <strong>
                  {formatDateExhibitionShort(
                    data.schedule_datetime || data.schedule_date
                  )}
                </strong>{' '} */}
                {/* {data.shipments && `(${data.shipments[0]?.slot})`} */}
              </div>
            </div>

            <div className="customerName">
              <FiUser />
              <div className="name">{data.customer.name}</div>
            </div>

            <div className="paymentTags">
              <div className="title">Pagamento:</div>
              {data?.payments?.[0] ? (
                <>
                  <span
                    className={`paymentTag paymentMethod ${data.payments?.[0]?.method}`}
                  >
                    <div className="text">
                      {paymentMethodsLabels[data.payments?.[0]?.method]}
                    </div>
                  </span>

                  <span
                    className={`paymentTag paymentStatus  ${data.payments?.[0]?.status}`}
                  >
                    <div className="text">
                      {paymentStatusLabels[data.payments?.[0]?.status]?.short}
                    </div>
                  </span>
                </>
              ) : (
                <span className="paymentTag error">
                  <div className="text">Sem informações de pagamento.</div>
                </span>
              )}
            </div>
          </NavLink>

          <S.Footer
            isPending={isPending && isOpenValue}
            hasCheckoutLink={showLinkCheckout && urlCheckout}
          >
            <div className="value">
              {isPending ? (
                <>
                  {isOpenValue && (
                    <ButtonNavLink
                      to={`/order/${data.id}`}
                      template="warning"
                      text="Valor em aberto. Defina um valor"
                      size="small"
                      customWidth="100%"
                    />
                  )}
                </>
              ) : (
                <div className="text">{formatMoney(data.total_price)}</div>
              )}
            </div>

            {showLinkCheckout && urlCheckout && (
              <>
                <a
                  href={urlCheckout}
                  target="_blank"
                  title="Link para o checkout deste pedido"
                  rel="noreferrer"
                  className="checkout"
                >
                  <div className="text">Link checkout</div> <FiExternalLink />
                </a>
              </>
            )}
          </S.Footer>
        </S.Container>
      )}
    </Draggable>
  )
}

export default Item

Item.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    wasMoved: PropTypes.bool,
    isNew: PropTypes.bool,
    order_id: PropTypes.string,
    channel_id: PropTypes.string,
    schedule_date: PropTypes.string,
    total_price: PropTypes.number,
    payments: PropTypes.arrayOf(PropTypes.shape({})),
    location: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    shipments: PropTypes.arrayOf(PropTypes.shape({})),
    customer: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired
}
