import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  color: ${props => props.theme.primary};
`;
