import { FiCheck, FiHelpCircle, FiX } from 'react-icons/fi'
import { RiWallet3Line } from 'react-icons/ri'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import CardInfo from '~/components/CardInfo'

import * as S from './styles'

export const SuccessCreateTransaction = ({
  currentBalance,
  onClose
}: Pick<FormCreateTransactionProps, 'currentBalance' | 'onClose'>) => {
  return (
    <S.SuccessContainer>
      <header>
        <span className="icon">
          <FiCheck />
        </span>

        <h2 className="title">{t('rm:recharge.success.title')}</h2>

        <p className="description">
          {t('rm:recharge.success.description')}
        </p>
      </header>

      <CardInfo
        title={t('rm:Balance')}
        textTooltip={t('rm:recharge.card.tooltip')}
        icon={<FiHelpCircle />}
        minHeight="none"
        template="light"
        featuredText={
          <S.AmountCard className="amount">
            <span className="icon">
              <RiWallet3Line />
            </span>
            <span>{currentBalance}</span>
          </S.AmountCard>
        }
      />

      <footer>
        <Button
          text={t('common:actions.close')}
          template="transparent"
          onClick={onClose}
          iconLeft={<FiX />}
        />
      </footer>
    </S.SuccessContainer>
  )
}
