import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'

import { useQuery } from '@tanstack/react-query'
import { Form } from '@unform/web'
import { t } from 'i18next'
import { debounce } from 'lodash'

import AlertInfo from '~/components/AlertInfo'
import Input from '~/components/Form/Input'
import HeaderPages from '~/components/HeaderPages'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import LoadingPopUp from '~/components/LoadingPopUp'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { formatDateToString, formatSearchString, history } from '~/helpers'
import { handleSortDirection } from '~/helpers/sortData'
import { useAlert } from '~/hooks/useAlert'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import useQueryParams from '~/hooks/useQuery'
import ExportButton from '~/modules/retailMedia/components/ExportButton'
import { formatGroceryListAdvertisers } from '~/modules/retailMedia/dtos/grocery/advertiser/list'
import {
  getAdvertiserReportKey,
  getAdvertiserReportService
} from '~/modules/retailMedia/services/report'
import { Footer } from '~/pages/products/list/styles'
import { useAppSelector } from '~/store/hooks'

import AdvertisersFilters from '../AdvertiserFilters'
import { schema } from './schema'

const AdvertisersTable = () => {
  const [innerLoading, setInnerLoading] = useState(false)

  const searchParams = useQueryParams()
  const page = Number(searchParams.get('page')) || 1
  const quantity = Number(searchParams.get('quantity')) || 50
  const advertiserId = searchParams.get('rmid')
  const advertiser_name = searchParams.get('advertiser_name')
  const seller_id = searchParams.get('seller_id')
  const sort_direction = searchParams.get('sort_direction') as SortDirection
  const sort_key = searchParams.get('sort_key')

  const {
    datepickerHeader: { startDate, endDate }
  } = useAppSelector(state => state)

  const params = useMemo(
    () => ({
      page,
      start_date: formatDateToString(startDate, 'YYYY-MM-DD'),
      end_date: formatDateToString(endDate, 'YYYY-MM-DD'),
      advertiser_name,
      seller_id,
      account_info: true,
      quantity,
      order_direction: sort_direction,
      order_by: sort_key,
      count: true
    }),
    [
      page,
      startDate,
      endDate,
      advertiser_name,
      seller_id,
      quantity,
      sort_direction,
      sort_key
    ]
  )

  const {
    isPending,
    isFetching,
    data: { data, total } = {},
    error
  } = useQuery({
    refetchInterval: 60000,
    queryKey: [getAdvertiserReportKey, params],
    queryFn: async () => {
      const res = await getAdvertiserReportService(params)
      const { data } = res
      const formatted = formatGroceryListAdvertisers(data.data)
      return { ...data, data: formatted }
    }
  })

  const [AlertOnError, setError] = useErrorApiAlert({
    title: t('c:messages.errorOccurred.title')
  })

  useEffect(() => {
    if (error) setError(error)
  }, [error, setError])

  /**
   * Handle Pagination
   */
  const handlePagination = useCallback(
    (value: number) => {
      searchParams.set('page', String(value))
      history.push({ search: searchParams.toString() })
    },
    [searchParams]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      searchParams.set('quantity', String(value.value))
      searchParams.set('page', '1')
      history.push({ search: searchParams.toString() })
    },
    [searchParams]
  )
  /**
   * Sort list
   */
  const handleSortList = useCallback(
    (data: OnSortProps) => {
      const selectedKey = data.key
      const sortInitialDirection = data?.sortInitialDirection

      const response = handleSortDirection({
        selectedKey,
        currentSortDirection: sort_direction,
        currentSortKey: sort_key,
        sortInitialDirection
      })

      searchParams.set('sort_direction', response.sortDirection)
      searchParams.set('sort_key', response.sortKey)

      history.push({ search: searchParams.toString() })
    },
    [searchParams, sort_direction, sort_key]
  )

  return (
    <>
      <HeaderPages
        title={t('rm:AdvertisersList')}
        sideRightComponent={
          <ExportButton
            queryParams={formatSearchString(params)}
            exportType={'advertisers'}
          />
        }
      />

      <section>
        <AdvertisersFilters />
      </section>

      <Loading status={isPending}>{t('c:actions.loadingData')}...</Loading>

      <LoadingPopUp isActive={isFetching} />

      <AlertOnError />

      <Loading status={!isPending && isFetching} onlyLine />

      {!!data && (
        <Table
          schema={schema}
          freeze="first-row-and-column"
          data={data}
          total={total}
          sideBorder
          smallerRowPadding
          // sort settings
          activeSortDirection={sort_direction}
          activeSortKey={sort_key}
          onSort={handleSortList}
        />
      )}

      {total === 0 && data && (
        <AlertInfo template="warning" text={t('c:table.emptyFiltered')} />
      )}

      <ListPagination
        total={total}
        label={t(
          `rm:advertisers.pagination.label.${total > 1 ? 'plural' : 'singular'}`
        )}
        currentPage={page}
        itemsPerPage={quantity}
        menuPlacement="top"
        onClickPagination={handlePagination}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  )
}

export default AdvertisersTable
