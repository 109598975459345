// Users
export const USERS_SUBMIT_USER = 'USERS_SUBMIT_USER'
export const USERS_LIST_USERS = 'USERS_LIST_USERS'
export const USERS_RESEND_INVITE = 'USERS_RESEND_INVITE'
export const USERS_EDIT = 'USERS_EDIT'
export const USERS_REMOVE = 'USERS_REMOVE'

export const USER_ROLE_REDUX_QUERY = 'USER_ROLE_REDUX_QUERY'

export const USER_ACCOUNTS_GET = 'USER_ACCOUNTS_GET'
export const USER_DEFAULT_ACCOUNT_SET = 'USER_DEFAULT_ACCOUNT_SET'
