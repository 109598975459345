import { reducer as toastr } from 'react-redux-toastr'

import { Action, combineReducers } from 'redux'

import localstorageConstants from '~/helpers/localstorage-manipulation'
// Nova estrutura de módulos dentro dos módulos
import { LOGIN_LOGGED_OUT } from '~/modules/auth/store/login/constants'
import updatePassword from '~/modules/auth/store/updatePassword/reducer'
import promotionFlyer from '~/modules/flyers/store/reducer'
import messagingCampaigns from '~/modules/messagingCampaigns/store/reducer'
import promotional from '~/modules/promotional/store/reducer'
import retailMedia from '~/modules/retailMedia/store/reducer'
import user from '~/modules/users/store/reducer'

import datepickerHeader from '../components/datepicker-header/datepicker-header-reducer'
import filter from '../components/filter/filter-reducer'
import sidebar from '../components/sidebar/sidebar-reducers'
import layout from '../layouts/layout-reducers'
import theme from '../layouts/theme-reducers'
import signup from '../modules/auth/pages/sign-up/sign-up-reducer'
import forgotPassword from '../modules/auth/store/forgotPassword/reducer'
import signin from '../modules/auth/store/login/reducer'
import chatConnect from '../modules/chat/store/chatConnect/reducer'
import chats from '../modules/chat/store/reducer'
import chatsV1 from '../modules/chatV1/store/reducer'
import profile from '../pages/configurations/profile/profile-reducer'
import dashboard from '../pages/dashboard/dashboard-reducer'
import integration from '../pages/integration/integration-reducer'
import locations from '../pages/locations/reducer'
import order from '../pages/orders/reducer'
// Nova estrutura de estado globais
import accessModules from './modules/accessModules/reducer'
import account from './modules/account/reducer'
import channels from './modules/channels/reducer'
import chatTemplates from './modules/chatTemplates/reducer'
import customers from './modules/customers/reducer'
import filters from './modules/filters/reducer'
import filtersSellOut from './modules/filtersSellOut/reducer'
import operators from './modules/operators/reducer'
import payment from './modules/payment/reducer'
import products from './modules/products/reducer'
import shoppingCart from './modules/shoppingCart/reducer'

export const appReducer = combineReducers({
  accessModules,
  promotionFlyer,
  channels,
  chats,
  chatsV1,
  chatConnect,
  chatTemplates,
  filters,
  filtersSellOut,
  sidebar,
  layout,
  theme,
  datepickerHeader,
  filter,
  signin,
  signup,
  updatePassword,
  forgotPassword,
  dashboard,
  integration,
  profile,
  user,
  toastr,
  customers,
  order,
  shoppingCart,
  messagingCampaigns,
  products,
  promotional,
  locations,
  operators,
  account,
  payment,
  retailMedia
})

export type RootState = ReturnType<typeof appReducer>

export const rootReducer = (state: RootState, action: Action) => {
  const { type } = action
  if (type === LOGIN_LOGGED_OUT || type === 'RESET_REDUX_STATE') {
    localStorage.removeItem(localstorageConstants.REDUX_PERSIST)

    state = undefined
  }
  // return appReducer
  return appReducer(state, action)
}
