import styled, { css } from 'styled-components';

const getStatusStyle = (theme, status) => {
  const styles = {
    active: css`
      color: ${theme.colors.success};
      background-color: ${theme.colors.successLighten40};
    `,
    scheduled: css`
      color: ${theme.colors.warning};
      background-color: ${theme.colors.warningLighten40};
    `,
    finished: css`
      background-color: ${theme.colors.tertiary};
    `,
  };

  return styles[status] || styles.active;
};

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    line-height: 1;

    .title {
      margin-bottom: ${theme.spacings.tiny};
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.bold};
      color: ${theme.colors.primary};
    }

    .description {
      font-size: ${theme.font.sizes.small};
    }
  `}
`;

export const TagWrapper = styled.div`
  ${({ theme, status }) => getStatusStyle(theme, status)}

  font-size: .75rem;
  max-width: 100px;
  padding: 0.2rem 2.8rem;
  border-radius: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// export const ButtonWrapper = styled.div`
//   ${({ theme }) => css`
//     display: flex;
//     flex-direction: column;
//     position: absolute;
//     top: 100%;
//     right: 0;
//     background-color: ${theme.colors.white};
//     width: max-content;
//     z-index: 1;
//     box-shadow: 0px 4px 8px rgba(24, 21, 60, 0.08);
//     border-radius: ${theme.border.radius.medium};
//     padding: 0;

//     button,
//     a {
//       display: flex;
//       align-items: center;
//       font-size: ${theme.font.sizes.medium};
//       padding: 1rem;
//       line-height: 1;
//       transition: 0.3s ease-in-out all;

//       &:hover {
//         background: ${theme.colors.light};
//       }

//       svg {
//         margin-right: ${theme.spacings.xxsmall};
//         color: ${theme.colors.primary};
//         height: 24px;
//         width: 24px;
//       }

//       & + * {
//         border-top: 1px solid #f5f5f6;
//       }

//       &.danger {
//         svg {
//           color: ${theme.colors.danger};
//         }
//       }
//       &.warning {
//         svg {
//           color: ${theme.colors.warning};
//         }
//       }
//     }
//   `}
// `;

// export const Container = styled.div`
//   position: relative;
// `;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
