import styled, { css } from 'styled-components'

export const Title = styled.div`
  ${({ theme }) => css`
    > span {
      font-size: ${theme.font.sizes.xxsmall};
      font-style: normal;
      font-weight: ${theme.font.bold};
      line-height: ${theme.spacings.small};
      color: ${theme.colors.neutral.low.light};
    }
  `}
`

export const Subtitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacings.xxxsmall};

    > svg {
      margin-right: ${theme.spacings.tiny};
      color: ${theme.primary};
      font-size: ${theme.font.sizes.medium};
    }

    > span {
      color: ${theme.colors.neutralLow};
      font-size: ${theme.font.sizes.medium};
      font-style: normal;
      font-weight: ${theme.font.bolder};
      line-height: ${theme.spacings.medium};
    }
  `}
`
