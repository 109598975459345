import React, { useEffect, useState } from 'react';

import { Scope } from '@unform/core';
import PropTypes from 'prop-types';

import { InputHidden } from '~/components/unform';
import { Checkbox } from '~/components/unform/Checkbox';

import * as S from './styles';

// Componente responsável por exibir os dois níveis de checkbox (métodos e sub métodos)

const Method = ({
  path,
  label,
  baseIdentifier,
  method,
  subMethods,
  onSubMethodClick,
  formRef,
}) => {
  const [showSubMethods, setShowSubMethods] = useState(false);

  const handleChangeMethod = e => setShowSubMethods(e.target.checked);

  useEffect(() => {
    const initialValueActive = formRef?.current?.getFieldValue(
      `${path}.active`,
    );

    setShowSubMethods(initialValueActive);
  }, [formRef, method, path]);

  return (
    <Scope path={path}>
      <Checkbox
        name="active"
        label={label}
        id={baseIdentifier}
        onChange={handleChangeMethod}
      />
      <InputHidden readOnly name="label" />
      <InputHidden readOnly name="method" />
      <InputHidden readOnly name="type" />

      {showSubMethods && subMethods?.length > 0 && (
        <S.SubOptions>
          <S.Label>Bandeiras aceitas</S.Label>

          {subMethods.map(({ _id, label }, indexInner) => (
            <Scope
              path={`sub_methods[${indexInner}]`}
              key={`${baseIdentifier}${_id}`}
            >
              <Checkbox
                id={`sub_methods[${indexInner}].sub_method_${baseIdentifier}`}
                name="active"
                label={label}
                onClick={() =>
                  onSubMethodClick({
                    method: path,
                  })
                }
              />
              <InputHidden readOnly name="label" />
              <InputHidden readOnly name="method" />
            </Scope>
          ))}
        </S.SubOptions>
      )}
    </Scope>
  );
};

export const MethodPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    method: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

Method.defaultProps = {
  onSubMethodClick: null,
  subMethods: [],
};

Method.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  onSubMethodClick: PropTypes.func,
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  baseIdentifier: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  subMethods: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      method: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

export default Method;
