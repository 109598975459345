export const channelName = channel_id => {
  switch (channel_id) {
    case 'purchase':
      return 'Cestas';

    case 'whatsapp':
      return 'WhatsApp';

    case 'whatsapp_bot':
      return 'WhatsApp';

    case 'whatsapp_bot_zs':
      return 'WhatsApp';

    case 'magento_ecommerce':
      return 'E-commerce';

    case 'vtex':
      return 'E-commerce';

    default:
      return channel_id;
  }
};
