const prefix = '@retailMedia/advertiser'

export const ADVERTISERS_LIST = `${prefix}/LIST`
export const ADS_LIST_SORT = `${prefix}/LIST_SORT`
export const ADVERTISERS_LIST_MINIFIED = `${prefix}/LIST_MINIFIED`
export const ADVERTISERS_LIST_QUERY = `${prefix}/LIST_QUERY`
export const ADVERTISERS_LIST_SET_PAGE = `${prefix}/LIST_SET_PAGE`
export const ADVERTISERS_LIST_PER_PAGE = `${prefix}/LIST_PER_PAGE`

export const ADVERTISERS_INFO = `${prefix}/INFO`

export const types = {
  list: ADVERTISERS_LIST,
  listSort: ADS_LIST_SORT,
  page: ADVERTISERS_LIST_SET_PAGE,
  perPage: ADVERTISERS_LIST_PER_PAGE,
  getAdvertiser: ADVERTISERS_INFO
}
