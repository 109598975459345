import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import EmptyPage from '~/components/empty-page';
import ListPagination from '~/components/ListPagination';
import Loading from '~/components/Loading';
import Panel from '~/components/Panel';
import Table from '~/components/Table';
import { queryDatesDatepickerIsolated } from '~/helpers';
import {
  handlePromotionsPerPage,
  listPromotions,
} from '~/modules/promotional/store/promotion/actions';
import { PROMOTIONAL_REDUX_QUERY as reduxQuery } from '~/modules/promotional/store/promotion/constants';
import { Footer } from '~/pages/products/list/styles';

import { schema } from './schema';

const PromotionalTable = () => {
  const {
    promotionalStatus,
    promotionalType: promotionType,
    query,
  } = useSelector(state => state.filters);

  const { startDate, endDate } = useSelector(state => state.datepickerHeader);

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const { loading, promotions, total, pages, itemsPerPage } = useSelector(
    state => state.promotional,
  );

  const filteredQuery = useMemo(
    () => query?.[`${reduxQuery}`] || null,
    [query],
  );

  const queryString = useMemo(
    () => ({
      page,
      quantity: itemsPerPage,
      status: promotionalStatus?.type,
      promotion_type: promotionType?.type,
      query: filteredQuery,
      ...queryDatesDatepickerIsolated(startDate, endDate),
    }),
    [
      itemsPerPage,
      page,
      promotionalStatus,
      promotionType,
      startDate,
      endDate,
      filteredQuery,
    ],
  );

  useEffect(() => {
    setPage(1);
  }, [
    itemsPerPage,
    promotionalStatus,
    promotionType,
    startDate,
    endDate,
    filteredQuery,
  ]);

  useEffect(() => {
    dispatch(listPromotions(queryString));
  }, [dispatch, queryString]);

  const handlePagination = useCallback(
    page => {
      setPage(page);
    },
    [dispatch, itemsPerPage],
  );

  const handleItemsPerPageChange = ({ value }) => {
    dispatch(handlePromotionsPerPage(value));
  };

  return (
    <>
      <Loading status={loading}>Carregando promoções...</Loading>

      {total === 0 && (
        <EmptyPage
          title="Nenhuma promoção encontrada"
          subtitle="Não encontramos promoções com os filtros aplicados."
          titleButton="Limpar filtros"
          svgPath="photos/orders-empty"
        />
      )}

      {total > 0 && (
        <>
          <ListPagination
            total={total}
            label={total > 1 ? 'promoções' : 'promoção'}
            currentPage={page}
            itemsPerPage={itemsPerPage}
            menuPlacement="top"
            onClickPagination={handlePagination}
            onItemsPerPageChange={handleItemsPerPageChange}
          />

          <Panel noPaddingBody isLoading={loading}>
            {!!promotions && (
              <Table schema={schema} data={promotions} total={total} />
            )}
          </Panel>

          <Footer>
            <ListPagination
              total={total}
              label={total > 1 ? 'promoções' : 'promoção'}
              currentPage={page}
              itemsPerPage={itemsPerPage}
              menuPlacement="top"
              onClickPagination={handlePagination}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </Footer>
        </>
      )}
    </>
  );
};

export default PromotionalTable;
