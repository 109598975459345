import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { Search } from 'react-feather';

import WrapperFields from './wrapper';
import Input from '~/components/forms/inputs';

const GeneralForm = ({
  onNext,
  onPrevious,
  onSubmitStep,
  disabledStep,
  edit,
  initialValue,
  isLastStep,
}) => {
  const [category, setCategory] = useState({});

  useEffect(() => {
    if (initialValue.category) {
      if (initialValue.category[0]) {
        const { name, id } = initialValue.category[0];

        setCategory({ name, id });
      }
    }
  }, [initialValue]);

  const beforeSubmitOnCreateProduct = data => {
    const { category, description, brand } = data;

    const formattedData = {
      main: {
        brand,
        description,
      },
    };

    onSubmitStep(formattedData);

    onNext();
  };

  return (
    <WrapperFields
      onSubmit={beforeSubmitOnCreateProduct}
      disabledStep={disabledStep}
      onPreviousStep={onPrevious}
      edit={edit}
      isLastStep={isLastStep}
    >
      <FormGroup className="form-group__flex form-group__wrap form-group__full-width">
        <Input
          type="textarea"
          label="Descrição do produto"
          name="description"
          placeholder="Fale sobre este produto aqui."
          defaultValue={initialValue.description}
        />
      </FormGroup>

      <FormGroup className="form-group__flex form-group__wrap">
        <Input
          iconLeft={<Search />}
          label="Categoria"
          name="category_name"
          placeholder="Pesquisar categoria"
          defaultValue={category.name}
        />
      </FormGroup>

      <FormGroup className="form-group__flex form-group__wrap">
        <Input
          label="Marca"
          name="brand"
          placeholder="Insira a marca do produto"
          defaultValue={initialValue.brand}
        />
      </FormGroup>
    </WrapperFields>
  );
};

export default GeneralForm;

GeneralForm.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmitStep: PropTypes.func,
  disabledStep: PropTypes.bool,
  isLastStep: PropTypes.bool,
  initialValue: PropTypes.shape(),
};

GeneralForm.defaultProps = {
  onNext: null,
  onNext: null,
  onPrevious: null,
  onSubmitStep: null,
  disabledStep: false,
  isLastStep: false,
  initialValue: {},
};
