import { produce } from 'immer';
import * as types from './profile-constants';

const INITIAL_STATE = {
  user: {},
  loadingSaveUser: false,
  loadingGetUser: false,
  company: {},
  loadingSaveCompany: false,
  loadingGetCompany: false,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.PROFILE_SUBMIT_USER}_PENDING`: {
        draft.loadingSaveUser = true;
        break;
      }
      case `${types.PROFILE_SUBMIT_USER}_FULFILLED`: {
        draft.loadingSaveUser = false;
        break;
      }
      case `${types.PROFILE_SUBMIT_USER}_REJECTED`: {
        draft.loadingSaveUser = false;
        break;
      }

      case `${types.PROFILE_GET_DATA_USER}_PENDING`: {
        draft.loadingGetUser = true;
        break;
      }
      case `${types.PROFILE_GET_DATA_USER}_FULFILLED`: {
        draft.user = action.payload;
        draft.loadingGetUser = false;
        break;
      }
      case `${types.PROFILE_GET_DATA_USER}_REJECTED`: {
        draft.loadingGetUser = false;
        break;
      }

      case `${types.PROFILE_SUBMIT_COMPANY}_PENDING`: {
        draft.loadingSaveCompany = true;
        break;
      }
      case `${types.PROFILE_SUBMIT_COMPANY}_FULFILLED`: {
        draft.loadingSaveCompany = false;
        break;
      }
      case `${types.PROFILE_SUBMIT_COMPANY}_REJECTED`: {
        draft.loadingSaveCompany = false;
        break;
      }

      case `${types.PROFILE_GET_DATA_COMPANY}_PENDING`: {
        draft.loadingGetCompany = true;
        break;
      }
      case `${types.PROFILE_GET_DATA_COMPANY}_FULFILLED`: {
        draft.company = action.payload;
        draft.loadingGetCompany = false;
        break;
      }
      case `${types.PROFILE_GET_DATA_COMPANY}_REJECTED`: {
        draft.loadingGetCompany = false;
        break;
      }
      default:
        return state;
    }
  });
