import React, { useMemo, useState } from 'react'
import { FiEdit } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'

import FormEdit from '../FormEdit'

const RecipientFullName = () => {
  const [showNameForm, setShowNameForm] = useState(false)

  const { orderData } = useSelector(state => state.order)

  const handleToggleEditFullName = () => {
    setShowNameForm(state => !state)
  }

  /**
   * Memos
   */

  const fullname = useMemo(
    () => orderData.shipping_address?.[0]?.full_name || 'Sem nome cadastrado',
    [orderData]
  )

  return (
    <>
      <strong>Destinatário:</strong>{' '}
      {!showNameForm && (
        <>
          {fullname}

          <Button
            type="button"
            onClick={handleToggleEditFullName}
            text="Editar nome"
            size="xsmall"
            iconLeft={<FiEdit />}
            style={{ marginLeft: '1rem' }}
            customWidth="auto"
          />
        </>
      )}
      {showNameForm && (
        <FormEdit
          initialData={{
            full_name: fullname
          }}
          handleToggleShowEditForm={handleToggleEditFullName}
        />
      )}
    </>
  )
}

export default RecipientFullName
