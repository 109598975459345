import React from 'react';

import PropTypes from 'prop-types';

const Text = ({ data }) => {
  const HTMLtags = {
    '*': 'strong',
    '~': 'strike',
    _: 'em',
    '```': 'code',
  };

  const formatterMessage = (_, p1, p2) =>
    `<${HTMLtags[p1]}>${p2}</${HTMLtags[p1]}>`;

  const formatterLink = match =>
    `<a href="${match}" target="_blank">${match}</a>`;

  const formatterPhone = match =>
    `<a href="tel:${match}" target="_blank">${match}</a>`;

  const regexWhatsAppStyles = /([*~_]|```)([a-zA-Z0-9].+?)\1(?!\w)/g;
  const regexURL = /(?:(?:https|http):\/\/|\b(?:[a-z\d]+\.))[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?/gim;
  const regexPhone = /(?:(?:\(?([0-9]{2,11})\))|(?:([0-9]{2,11})))?[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/gm;

  const formattedMessage = messageBody =>
    messageBody
      .replace(regexURL, formatterLink)
      .replace(regexPhone, formatterPhone)
      .replace(regexWhatsAppStyles, formatterMessage)
      .replace(/\n/g, '<br />');

  return (
    <div
      className="text"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: formattedMessage(data),
      }}
    />
  );
};

export default Text;

Text.propTypes = {
  data: PropTypes.string.isRequired,
};
