export const externalShipmentTexts = {
  enqueued: {
    featured: 'Buscando entregador...',
    description:
      'Estamos buscando um entregador para coletar este pedido. Atualizaremos essa informação assim que recebermos uma resposta. Você não precisa permanecer na página.',
    showLoading: true
  },
  pending: {
    featured: 'Buscando entregador...',
    description:
      'Estamos buscando um entregador para coletar este pedido. Atualizaremos essa informação assim que recebermos uma resposta. Você não precisa permanecer na página.',
    showLoading: true
  },
  pickup: {
    featured: 'A caminho da coleta...',
    description: 'Entregador a caminho do local de retirada.',
    imminent: {
      description: 'Entregador chegando no local de retirada.'
    }
  },
  pickup_complete: {
    featured: 'Pedido coletado',
    description: 'Aguardando entrega...'
  },
  dropoff: {
    featured: 'A caminho da entrega...',
    description: 'Entregador a caminho do local de entrega.',
    imminent: {
      description: 'Entregador chegando no local de entrega.'
    }
  },
  delivered: {
    featured: 'Pedido entregue',
    description: 'O pedido foi entregue.'
  },
  canceled: {
    featured: 'Solicitação cancelada',
    description: 'Esta solicitação foi cancelada.'
  },
  return: {
    title: 'Entregador retornando com o pedido',
    featured: 'Devolução a caminho',
    description: 'Entregador a caminho do local de devolução.',
    imminent: {
      description: 'Entregador chegando ao local de devolução.'
    }
  },
  returned: {
    featured: 'Pedido retornado',
    description: 'O pedido foi retornado para o local de origem'
  },
  failed: {
    featured: 'A solicitação falhou',
    description:
      'Não conseguimos solicitar. Você pode cancelar e solicitar outra entrega.'
  },
  // Errors Uber
  invalid_params: {
    featured: 'A solicitação falhou',
    description: 'Os parâmetros do seu pedido eram inválidos.'
  },
  unknown_location: {
    featured: 'A solicitação falhou',
    description: 'O local especificado não foi compreendido.'
  },
  address_undeliverable: {
    featured: 'A solicitação falhou',
    description: 'O local especificado não está em uma área de entrega.'
  },
  pickup_window_too_small: {
    featured: 'A solicitação falhou',
    description:
      'A janela de coleta precisa ter pelo menos 10 minutos de duração.'
  },
  dropoff_deadline_too_early: {
    featured: 'A solicitação falhou',
    description:
      'O prazo de desembarque precisa ser de pelo menos 20 minutos após o horário de pronto para desembarque.'
  },
  dropoff_deadline_before_pickup_deadline: {
    featured: 'A solicitação falhou',
    description: 'O prazo de devolução deve ser após o prazo de retirada.'
  },
  dropoff_ready_after_pickup_deadline: {
    featured: 'A solicitação falhou',
    description:
      'O horário de entrega precisa ser igual ou anterior ao prazo de coleta.'
  },
  pickup_ready_too_early: {
    featured: 'A solicitação falhou',
    description: 'O tempo de coleta pronto não pode estar no passado.'
  },
  pickup_deadline_too_early: {
    featured: 'A solicitação falhou',
    description:
      'O tempo limite de coleta precisa ser de pelo menos 20 minutos a partir de agora.'
  },
  pickup_ready_too_late: {
    featured: 'A solicitação falhou',
    description:
      'O tempo de coleta pronto precisa estar dentro dos próximos 30 dias.'
  },
  customer_not_found: {
    featured: 'A solicitação falhou',
    description: 'O cliente não existe.'
  },
  request_timeout: {
    featured: 'A solicitação falhou',
    description: 'O pedido excedeu o tempo limite…'
  },
  customer_suspended: {
    featured: 'A solicitação falhou',
    description: 'Sua conta está vencida. O pagamento é necessário.'
  },
  address_undeliverable_limited_couriers: {
    featured: 'A solicitação falhou',
    description:
      'O local especificado não está em uma área de entrega neste momento porque todos os entregadores estão ocupados no momento.'
  },
  customer_blocked: {
    featured: 'A solicitação falhou',
    description: 'Sua conta não tem permissão para criar entregas.'
  },
  customer_limited: {
    featured: 'A solicitação falhou',
    description: 'Os limites da sua conta foram excedidos.'
  },
  unknown_error: {
    featured: 'A solicitação falhou',
    description: 'Ocorreu um erro desconhecido.'
  }
}
