import { t } from 'i18next'

import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import CheckingAccountTable from '~/modules/retailMedia/components/CheckingAccountTable'

const Wallets = () => {
  return (
    <>
      <PageTitle title={`Retail Media - ${t('rm:Wallets')}`} />

      <HeaderPages
        title={t('rm:Wallets')}
        subtitle={t('rm:wallet.monvimentations.header.subtitle')}
      />

      <CheckingAccountTable />
    </>
  )
}

export default Wallets
