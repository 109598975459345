import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';

import { requiredValidation } from '~/helpers';

import WrapperFields from './wrapper';
import Input from '~/components/forms/inputs';

const NewForm = ({
  onNext,
  onSubmitStep,
  disabledStep,
  edit,
  initialValue,
  isLastStep,
}) => {
  const beforeSubmitOnCreateProduct = data => {
    const formattedData = {
      main: {
        ...data,
      },
    };

    onSubmitStep(formattedData);

    onNext();
  };

  return (
    <WrapperFields
      onSubmit={beforeSubmitOnCreateProduct}
      disabledStep={disabledStep}
      edit={edit}
      isLastStep={isLastStep}
    >
      <FormGroup className="form-group__flex form-group__wrap form-group__full-width">
        <Input
          label="Nome"
          name="name"
          validate={[requiredValidation]}
          placeholder="Ex.: Celular Samsung Galaxy S9 64GB Preto"
          maxLength="60"
          showLength
          autoFocus
          defaultValue={initialValue.name}
        />
      </FormGroup>
    </WrapperFields>
  );
};

export default NewForm;

NewForm.propTypes = {
  onNext: PropTypes.func,
  onSubmitStep: PropTypes.func,
  disabledStep: PropTypes.bool,
  isLastStep: PropTypes.bool,
  edit: PropTypes.bool,
  initialValue: PropTypes.shape(),
};

NewForm.defaultProps = {
  onNext: null,
  onSubmitStep: null,
  disabledStep: false,
  edit: false,
  isLastStep: false,
  initialValue: {},
};
