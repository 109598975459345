import { createContext, useContext, useCallback, useState } from 'react'

import MasterLoading from '~/components/MasterLoading'

// Types
export interface MasterLoadingKeys {
  id: string
  message?: string
}

interface UIProviderProps {
  children: React.ReactNode
}

// Core

export const UIContext = createContext<UIContextData>({} as UIContextData)

const UIProvider: React.FC<UIProviderProps> = ({ children }) => {
  // MasterLoading
  const [messagesMasterLoading, setMessagesMasterLoading] = useState<
    MasterLoadingKeys[]
  >([])

  const addMasterLoading = useCallback((data: MasterLoadingKeys) => {
    setMessagesMasterLoading(state => [...state, data])
  }, [])

  const removeMasterLoading = useCallback((id: string) => {
    setMessagesMasterLoading(state =>
      state.filter(message => message.id !== id)
    )
  }, [])

  return (
    <UIContext.Provider
      value={{
        addMasterLoading,
        removeMasterLoading,
        messagesMasterLoading
      }}
    >
      <MasterLoading messages={messagesMasterLoading} />
      {children}
    </UIContext.Provider>
  )
}

function useUI(): UIContextData {
  const context = useContext(UIContext)

  if (!context) {
    throw new Error('useUI must be used within an UIProvider')
  }

  return context
}

export { UIProvider, useUI }
