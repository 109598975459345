import React, { useState } from 'react';

import PropTypes from 'prop-types';

import AlertInfo from '~/components/AlertInfo';
import Panel from '~/components/Panel';
import { Badge } from '~/components/UI/Badge';
import { Label, Radio } from '~/components/unform';

import BenefitsDiscount from '../FormGroups/BenefitsDiscount';
import BenefitsItemsDiscount from '../FormGroups/BenefitsItemsDiscount';
import BenefitsPay from '../FormGroups/BenefitsPay';
import BenefitsShippingDiscount from '../FormGroups/BenefitsShippingDiscount';

import * as S from './styles';

const PromotionTypes = ({
  quantitySelected = null,
  selectedTrigger = 'auto',
  typeSelected = 'buy_pay',
  isEdit,
}) => {
  const [selectedOption, setSelectedOption] = useState(typeSelected);

  const handleChangeSelectedOption = ({ target }) => {
    setSelectedOption(target.value);
  };

  const selectHelptext = {
    buy_pay: 'Na compra de 3 itens, pague apenas 2.',
    discount: 'Na compra de 3 itens, ganhe 25% de desconto.',
    fixed_price: 'Compre 3 itens e pague R$ 3,00.',
  };

  const optionsTypes = {
    auto: [
      {
        id: `promotion_type_buy_pay`,
        value: 'buy_pay',
        label: 'Leve e pague',
        description: 'Leve 3 itens e pague 2',
        disabled: isEdit,
        activeChildren: <BenefitsPay quantitySelected={quantitySelected} />,
      },
      {
        id: `promotion_type_discount`,
        value: 'discount',
        label: 'Desconto',
        description: 'Leve 3 itens e ganha 30%',
        disabled: isEdit,
        activeChildren: <BenefitsDiscount />,
      },
    ],
    coupon: [
      {
        id: `promotion_type_shipping_discount`,
        value: 'shipping_discount',
        label: 'Desconto no frete',
        // disabled: isEdit,
        activeChildren: <BenefitsShippingDiscount />,
      },
      // {
      //   id: `promotion_type_discount_total`,
      //   value: 'discount_total',
      //   label: 'Desconto no total',
      //   description: 'Inclui taxas e valor do frete.',
      //  disabled: isEdit,
      //   activeChildren: <BenefitsPay quantitySelected={quantitySelected} />,
      // },
      {
        id: `promotion_type_items_discount`,
        value: 'items_discount',
        label: 'Desconto no subtotal',
        description: 'Sem incluir taxas e valor do frete.',
        // disabled: isEdit,
        activeChildren: <BenefitsItemsDiscount />,
      },
    ],
  };

  return (
    <Panel
      title="Mecânica da promoção"
      isCollapsible
      initialCollapsibleState={!isEdit}
      headerSideContent={<Badge>{typeSelected}</Badge>}
    >
      <S.Group>
        <Label
          htmlFor="promotion_type"
          isRequired
          text="Escolha o tipo e os valores que a promoção terá."
          helperText={`Exemplo: ${selectHelptext[selectedOption]}`}
        />

        <Radio
          options={optionsTypes[selectedTrigger]}
          onChange={handleChangeSelectedOption}
          name="promotion_type"
          required
        />

        {isEdit && (
          <AlertInfo text="O tipo de promoção não pode ser alterado. Para alterar, duplique a promoção e cancele a atual." />
        )}
      </S.Group>
    </Panel>
  );
};

export default PromotionTypes;

PromotionTypes.propTypes = {
  quantitySelected: PropTypes.number,
  selectedTrigger: PropTypes.string,
  typeSelected: PropTypes.string,
  isEdit: PropTypes.bool,
};

PromotionTypes.defaultProps = {
  quantitySelected: null,
  selectedTrigger: 'auto',
  typeSelected: 'buy_pay',
  isEdit: false,
};
