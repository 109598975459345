import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Toogle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  label {
    font-size: 0.75rem;
    margin: 0 0 0 0.5rem;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
  }

  .toggle {
    height: 1.5rem;
    width: 3rem;
    border-radius: 1rem;
    display: inline-block;
    position: relative;
    margin: 0;
    border: 0;
    background: ${props => props.theme.primary};
    transition: all 0.2s ease;
    box-shadow: 0 6px 16px -3px rgba(44, 44, 44, 0.1);
    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      background: white;
      box-shadow: 0 1px 2px rgba(44, 44, 44, 0.5);
      transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
    }
    &:checked {
      background: ${props => props.theme.success};
      &:after {
        transform: translatex(calc(3rem - 1.25rem - 4px));
      }
    }
  }
`;
