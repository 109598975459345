import { AlertNew } from '~/components/AlertNew'

import { Icon } from '~/components/Icon'
import { statusType } from '../../statusType'

import * as S from './styles'

interface Props {
  status: string
}

export const Alerts = ({ status }: Props) => {
  return (
    <S.Container>
      <AlertNew color={statusType[status]?.color}>
        <S.Content iconColor={statusType[status]?.color}>
          {!!statusType[status]?.icon && (
            <Icon icon={statusType[status]?.icon} />
          )}
          {statusType[status]?.icon}
          <span>{statusType[status]?.text}</span>
        </S.Content>
      </AlertNew>
    </S.Container>
  )
}
