/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Route } from 'react-router-dom'

import PrivateRoute from './private-route'

const childRoutes = (Layout, routes, isPrivate) =>
  routes?.map(
    (
      {
        children,
        path,
        component: Component,
        maxHeightViewport = false,
        contentFullWidth = false,
        contentFullScreen = false,
        pageTitle = null,
        hideFullMenu = false,
        sidebarStartsClosed = false
      },
      index
    ) =>
      children ? (
        // Route item with children
        children.map(
          (
            {
              path,
              component: Component,
              maxHeightViewport = false,
              contentFullWidth = false,
              contentFullScreen = false,
              pageTitle = null,
              hideFullMenu = false,
              sidebarStartsClosed = false
            },
            index
          ) => (
            <Route
              key={`item-${index}`}
              path={path}
              exact
              element={
                <PrivateRoute isPrivate={isPrivate}>
                  <Layout
                    maxHeightViewport={maxHeightViewport}
                    contentFullWidth={contentFullWidth}
                    contentFullScreen={contentFullScreen}
                    pageTitle={pageTitle}
                    hideFullMenu={hideFullMenu}
                    sidebarStartsClosed={sidebarStartsClosed}
                  >
                    <Component />
                  </Layout>
                </PrivateRoute>
              }
            />
          )
        )
      ) : (
        // Route item without children
        <Route
          key={index}
          path={path}
          exact
          element={
            <PrivateRoute isPrivate={isPrivate}>
              <Layout
                maxHeightViewport={maxHeightViewport}
                contentFullWidth={contentFullWidth}
                pageTitle={pageTitle}
                hideFullMenu={hideFullMenu}
                sidebarStartsClosed={sidebarStartsClosed}
              >
                <Component />
              </Layout>
            </PrivateRoute>
          }
        />
      )
  )

export default childRoutes
