import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { RightArrow } from './commons'

const GenericContent = ({ prevValue, newValue }: DefaultChangeProps) => {
  const { t } = useAPPTranslation()

  const values = {
    prevValue: t(`rm:campaign.logs.values.${prevValue}`, {
      defaultValue: prevValue
    }),
    newValue: t(`rm:campaign.logs.values.${newValue}`, {
      defaultValue: newValue
    })
  }

  return (
    <>
      <span className="prevValue">
        {['', null].includes(prevValue) ? (
          <i>{t('rm:campaign.logs.empty_value')}</i>
        ) : (
          JSON.stringify(values.prevValue)
        )}
      </span>
      <RightArrow />
      <span className="newValue">
        {['', null].includes(newValue) ? (
          <i>{t('rm:campaign.logs.empty_value')}</i>
        ) : (
          JSON.stringify(values.newValue)
        )}
      </span>
    </>
  )
}

export default GenericContent
