import i18next from 'i18next'

import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import GeneralForm from '~/modules/users/components/GeneralForm'

const UserNew = () => (
  <>
    {/* Meta <title> */}
    <PageTitle title={i18next.t('users:addUser.pageTitle')} />

    <HeaderPages
      title={i18next.t('users:createUser')}
      labelBreadcrumb={i18next.t('users:users')}
      backTo="/users/list"
    />

    <GeneralForm />
  </>
)

export default UserNew
