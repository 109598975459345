import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.div`
  margin: 1rem 0;
`

export const TotalRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;

    border-top: ${theme.colors.tertiary} 1px solid;
    border-bottom: ${theme.colors.inative} 1px solid;

    background-color: ${theme.colors.primaryLighten60};

    transition: 0.3s ease-in-out all;

    &:hover {
      background-color: ${theme.colors.secondaryLighten30};
    }

    &.totalValue {
      background-color: ${theme.colors.light};

      border-radius: 0 0 0.5rem 0.5rem;
      font-weight: ${theme.font.bold};

      flex-wrap: wrap;
      align-items: baseline;
      line-height: 1.125;

      .value {
        font-size: ${theme.font.sizes.large};
      }

      .details {
        flex: 1 1 100%;
        text-align: right;
      }
    }

    @media ${device.tablet_max} {
      margin: 1rem 0.5rem 0;
    }

    .value {
      min-width: 4rem;
      text-align: right;
      margin-left: 0.5rem;
    }

    &.discountDetails {
      padding: 0.125rem 0.5rem;
      font-size: ${theme.font.sizes.xsmall};
      background-color: ${theme.colors.primaryLighten61};
      border-top: ${theme.colors.neutral.high.medium} 1px solid;
      border-bottom: none;
      position: relative;

      .value {
        margin-right: 0.25rem;
      }

      &:after {
        content: '';
        position: absolute;
        top: -0.5rem;
        right: -1px;

        display: flex;
        width: 2px;
        height: calc(100% + 0.5rem);
        transform: translate(-50%, -50%);

        background-color: ${theme.colors.secondary};
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 1px;

        display: flex;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        transform: translate(50%, -50%);

        background-color: ${theme.colors.secondary};
      }
    }
  `}
`
