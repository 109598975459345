import styled, { DefaultTheme, css } from 'styled-components'

const containerModifiers = {
  active: ({ theme }: { theme: DefaultTheme }) => css`
    background-color: ${theme.colors.primaryLighten60};
  `,
  isSelected: ({ theme }: { theme: DefaultTheme }) => css`
    background-color: ${theme.colors.successLighten45};

    &:hover {
      background-color: ${theme.colors.successLighten40};
    }
  `
}

export const Container = styled.li`
  ${({
    theme,
    active,
    isSelected
  }: {
    theme: DefaultTheme
    active?: boolean
    isSelected?: boolean
  }) => css`
    * {
      text-decoration: none;
    }
    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);

        height: 1px;
        width: calc(100% - 1rem);

        background-color: ${theme.colors.inative};

        transition: 0.2s ease-in-out all;
      }
    }

    &:hover {
      a {
        text-decoration: none;
      }

      &:after {
        width: 100%;
      }
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;

      position: relative;

      padding: 0.5rem;
      width: 100%;

      border: none;
      outline: none;

      background: none;
      color: ${theme.dark};

      transition: 0.2s ease-in-out all;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);

        height: 1px;
        width: calc(100%);

        opacity: 1;
        background-color: ${theme.inative};

        transition: 0.2s ease-in-out all;
      }

      &:hover {
        background-color: ${theme.colors.primaryLighten60};
        text-decoration: none;

        &:after {
          opacity: 0;
        }
      }

      ${active && containerModifiers.active({ theme })}
      ${isSelected && containerModifiers.isSelected({ theme })}
    }
  `}
`

export const Avatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 32px;
  margin-right: 8px;
  position: relative;

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
`

export const Blanket = styled.div`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
`

export const Content = styled.div`
  ${({ theme, unread }: { theme: DefaultTheme; unread?: boolean }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;

    > .wrapper {
      display: flex;

      > .column {
        flex: 1;

        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 1;
        }

        .secondRow {
        }
      }
    }

    .customerName {
      font-size: 0.875rem;
      font-weight: 700;
      -webkit-line-clamp: 1;
      display: -webkit-inline-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1rem;
    }

    .time {
      font-size: 0.75rem;
      color: ${theme.gray};
      text-align: right;
    }

    .lastMessage {
      overflow: hidden;

      max-width: 336px;

      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      font-size: 0.75rem;
      color: ${unread ? theme.dark : theme.gray};
      font-weight: ${unread ? 'bold' : 'normal'};
    }

    .tagsRow {
      display: flex;
      justify-content: flex-start;
      flex-flow: wrap;
      width: 100%;

      > .TagLabel {
        margin: 0.125rem 0.125rem 0.125rem 0;
      }
    }

    aside {
      display: flex;
      align-items: center;
      min-height: 16px;
      max-height: 16px;

      .unreadBadge {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        margin-left: 0.5rem;

        height: 16px;
        width: 16px;
        border-radius: 50%;

        background-color: ${theme.primary};

        font-size: 0.675rem;
        color: white;
        letter-spacing: -0.5px;
        text-indent: -0.5px;
        line-height: 1;
      }
    }
  `}
`

export const WrapperBadge = styled.div`
  display: inline-flex;
  margin-left: auto;

  .Badge_UI_Component {
    margin: 0;
  }
`
