/* eslint-disable no-underscore-dangle */
import * as types from '../../constants';

const index = {
  pending: `${types.UPDATE_PAYMENT_METHODS_CONFIG}_PENDING`,
  fulfilled: `${types.UPDATE_PAYMENT_METHODS_CONFIG}_FULFILLED`,
  rejected: `${types.UPDATE_PAYMENT_METHODS_CONFIG}_REJECTED`,
};

export const cases = {
  [index.pending]: ({ draft }) => {
    draft.paymentMethodsConfigUpdateLoading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    const updatedConfigList = action.payload;
    const oldConfigList = draft.paymentMethodsConfigList;
    const updatedList = oldConfigList.map(item =>
      item._id === updatedConfigList._id ? updatedConfigList : item,
    );

    draft.paymentMethodsConfigList = updatedList;

    draft.paymentMethodsConfigUpdateLoading = false;
  },
  [index.rejected]: ({ draft }) => {
    draft.paymentMethodsConfigUpdateLoading = false;
  },
};
