import { types } from '../constants';

const index = {
  pending: `${types.update}_PENDING`,
  fulfilled: `${types.update}_FULFILLED`,
  rejected: `${types.update}_REJECTED`,
};

export const update = {
  [index.pending]: ({ draft }) => {
    draft.template.update.loading = true;
    draft.template.update.error = null;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.template.item = action.payload.updated;
    draft.template.update.loading = false;
    draft.template.update.error = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.template.update.loading = false;
    draft.template.update.error = action.payload;
  },
};
