import { t } from 'i18next'

import { Badge } from '~/components/UI/Badge'
import { adTypesBadges } from '~/modules/retailMedia/pages/config/lib/adTypesBadges'

const BadgesAdTypes: React.FC<AdTypesProps> = ({ data }) => {
  const adTypes = data?.ad_types?.sort()

  return (
    <section className="advertiser-ad_types d-flex gap-1 align-items-center">
      {adTypes?.map(type => (
        <Badge template={adTypesBadges?.[type]?.color} key={type}>
          {adTypesBadges?.[type]?.label || type}
        </Badge>
      ))}

      {!adTypes?.length && <Badge>{t('rm:none')}</Badge>}
    </section>
  )
}

export default BadgesAdTypes
