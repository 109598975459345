import React from 'react';

import { FormGroup, InputMask, Label } from '~/components/unform';

import GlobalConfig from './GlobalConfig';
import { globalConfigPropTypes } from './globalConfigPropTypes';

const TimesFieds = ({ isGlobalConfig, onChangeGlobalConfig }) => {
  return (
    <div className="fields fields__times">
      <GlobalConfig onChangeGlobalConfig={onChangeGlobalConfig} />

      <strong className="title">Tempos médios</strong>

      <FormGroup>
        <Label htmlFor="hour_end" text="Entrega" />
        <InputMask
          name="delivery_time"
          placeholder="Insira um tempo médio de entrega"
          iconRight="minutos"
          disabled={isGlobalConfig}
        />
      </FormGroup>

      <FormGroup>
        <Label
          htmlFor="min_interval_schedule"
          text="Intervalo até o próximo slot disponível"
          helperText="Este valor define o tempo mínimo necessário entre a hora atual e o próximo slot"
        />

        <InputMask
          name="min_interval_schedule"
          placeholder="Insira um valor em minutos"
          iconRight="minutos"
          disabled={isGlobalConfig}
        />
      </FormGroup>
    </div>
  );
};

export default TimesFieds;

TimesFieds.propTypes = {
  ...globalConfigPropTypes,
};
