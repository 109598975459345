import * as _ from 'lodash';

import * as types from '../constants';
import {
  confirmedCartItemModel,
  pendingCartItemModel,
  stockOutCartItemModel,
} from '../models/items';

const index = {
  pending: `${types.VIEW}_PENDING`,
  fulfilled: `${types.VIEW}_FULFILLED`,
  rejected: `${types.VIEW}_REJECTED`,
};

export const cases = {
  [index.pending]: ({ draft }) => {
    draft.loadingViewShoppingCart = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    const { items } = action.payload;

    const rawItems = () => {
      let itemsRaw = [
        ...(items?.confirmed || []),
        ...(items?.pending || []),
        ...(items?.stock_out || []),
      ];

      itemsRaw = _.orderBy(itemsRaw, [item => item.createdAt], ['asc']);

      const formattedItems = itemsRaw.map(item => {
        if (item.status === 'confirmed') {
          return confirmedCartItemModel({
            item,
          });
        }

        if (item.status === 'stock_out') {
          return stockOutCartItemModel({
            item,
          });
        }

        if (item.status === 'pending') {
          return pendingCartItemModel({
            item,
          });
        }

        return {};
      });

      return formattedItems;
    };

    draft.shoppingCart = { ...action.payload, rawItems: rawItems() };
    draft.loadingViewShoppingCart = false;
    draft.errorViewShoppingCart = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.loadingViewShoppingCart = false;
    draft.errorViewShoppingCart = action.payload;
  },
};
