/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';

import Alert from '~/components/Alert';

import { useLogisticSlots } from '../../useLogisticSlots';
import { Default, WithoutSlot } from '../Day';

import * as S from './styles';

/**
 * Esse componente é responsável por renderizar as colunas ou linhas de datas com seus slots.
 * De acordo com o modo escolhido, mudaremos a direção da renderização: column ou row.
 * Apenas o modo dia de semana é renderizado no formato colunas.
 */

const Days = () => {
  const { loading, days, mode, isWithoutWorkDates } = useLogisticSlots();

  const Day = useMemo(
    () => (isWithoutWorkDates ? WithoutSlot : Default),
    [isWithoutWorkDates],
  );

  return !loading ? (
    <S.ContainerDays template={mode}>
      {days?.map((item, index) => (
        <Day data={item} key={index} />
      ))}

      {!days && <Alert active>Nenhuma configuração foi encontrada.</Alert>}
    </S.ContainerDays>
  ) : (
    <></>
  );
};

export default Days;
