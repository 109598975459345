import { formatMoney, formatNumberWithDots, formatPercent } from '~/helpers'

import { formatMetrics } from '../../common/metrics'

const format: Format = (value, type) => {
  if (isNaN(Number(value))) return '-'

  const formatByType = {
    money: formatMoney,
    percent: formatPercent,
    numberWithDots: formatNumberWithDots
  }

  return formatByType?.[type](Number(value)) || value || '-'
}

export const dashboardPublisherListModel: DashboardPublisherListModel = data =>
  data.map(item => ({
    id: item.id,
    checkingAccountId: item.checking_account_id,
    balance: formatMoney(item?.metrics?.balance),
    name: item.name || item.id,
    account: {
      id: item.account_id,
      headerColor: item?.account_theme?.header_color || 'white',
      logo: item?.account_logo || null
    },
    metrics: {
      totalCampaigns: format(item?.metrics?.total_campaigns, 'numberWithDots'),
      impressions: format(item?.metrics?.impressions, 'numberWithDots'),
      views: format(item?.metrics?.views, 'numberWithDots'),
      clicks: format(item?.metrics?.clicks, 'numberWithDots'),
      conversions: format(item?.metrics?.conversions, 'numberWithDots'),
      conversion_rate: format(item?.metrics?.conversion_rate, 'percent'),
      ctr: format(item?.metrics?.ctr, 'percent'),
      adCost: format(item?.metrics?.adcost, 'percent'),
      income: format(item?.metrics?.income, 'money'),
      ecpm: format(item?.metrics?.ecpm, 'money'),
      totalSpent: format(item?.metrics?.total_spent, 'money'),
      cpa: format(item?.metrics?.cpa, 'money'),
      avgCpc: format(item?.metrics?.avg_cpc, 'money'),
      avgCpm: format(item?.metrics?.avg_cpm, 'money'),
      roas: format(item?.metrics?.roas, 'numberWithDots')
    }
  }))

export const formatIndustryListPublisher: FormatAdvertisersList = data =>
  data.map(item => ({
    _raw: {
      originPath: 'src/modules/retailMedia/dtos/industry/publisher/list.ts'
    },
    id: item.id,
    checkingAccountId: item.checking_account_id,
    balance: formatMoney(item.metrics.balance),
    title: item.name,
    metrics: formatMetrics({ data: item.metrics }),
    logo_url: item.account_logo,
    account_theme: { ...item.account_theme }
  }))
