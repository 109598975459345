import React, { useEffect, useRef } from 'react'

import { useField } from '@unform/core'

import InputCheckbox from './InputCheckbox'

interface PropsCheckbox {
  name: string
  id?: string
  value?: string
  label?: string | JSX.Element
  disabled?: boolean
  returnValue?: boolean
  isCheckedInitialValue?: boolean
}

function Checkbox({
  name,
  id,
  value,
  label,
  disabled = false,
  returnValue = false,
  isCheckedInitialValue,
  ...rest
}: PropsCheckbox) {
  const inputRef = useRef()
  const { fieldName, defaultValue, registerField, error } = useField(name)

  const defaultChecked = !!defaultValue || isCheckedInitialValue

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        if (returnValue) {
          const { checked, value } = ref.current

          return { checked, value }
        }

        return ref.current.checked
      },
      clearValue: ref => {
        ref.current.checked = defaultChecked
      },
      setValue: (ref, value) => {
        ref.current.checked = value
      }
    })
  }, [defaultValue, fieldName, registerField, defaultChecked, returnValue])

  return (
    <InputCheckbox
      defaultChecked={defaultChecked}
      inputRef={inputRef}
      value={value}
      name={name}
      fieldName={fieldName}
      label={label}
      error={error}
      id={id || name}
      disabled={disabled}
      {...rest}
    />
  )
}

export default Checkbox
