import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/publisher`

/**
 * VIEW
 * Retorna dados do publisher com opção de retornar dados da conta
 */
export const getPublisherService = ({ url: { id }, params }) =>
  axios.get(`${baseURL}/${id}`, { params })
