import React from 'react';

import PropTypes from 'prop-types';

import { ButtonNavLink } from '~/components/Buttons';

import * as S from './styles';

const CampaignNav = ({ campaignId }) => {
  return (
    <S.Container>
      <ButtonNavLink
        text="Dados gerais"
        to={`/messaging-campaigns/view/${campaignId}`}
        activeClassName="active"
        template="light"
      />
      <ButtonNavLink
        text="Relatório da campanha"
        to={`/messaging-campaigns/view/dashboard/${campaignId}`}
        activeClassName="active"
        template="light"
      />
    </S.Container>
  );
};

export default CampaignNav;

CampaignNav.propTypes = {
  campaignId: PropTypes.string.isRequired,
};
