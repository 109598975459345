import i18n from '~/i18n'
import { history } from '~/helpers'
import { campaign as service } from '~/modules/retailMedia/services/grocery'
import {
  getCampaignHistoryReportService,
  getCampaignsReportService
} from '~/modules/retailMedia/services/report'

import { types } from './constants'

/**
 * Get all */
export const listGroceryCampaigns = (queries = {}) => ({
  type: types.list,
  payload: service.list(queries),
  errorFeedbackMsg: i18n.t('rm:actions.campaign.list.error')
})

/**
 * Get reports */
export const reportIndustryCampaigns = (
  queries: GetCampaignReportRequestParams
) => ({
  type: types.report.get,
  payload: getCampaignsReportService(queries)
})

/**
 * Get all with history*/
export const reportIndustryCampaignsHistory = (
  queries: GetCampaignHistoryReportRequestParams
) => ({
  type: types.report.history,
  payload: getCampaignHistoryReportService(queries)
})

/**
 * Create */
const create = ({
  body,
  successFeedbackMsg = i18n.t('rm:actions.campaign.create.success'),
  errorFeedbackMsg = i18n.t('rm:actions.campaign.create.error')
}) => ({
  type: types.create,
  payload: service.create(body),
  successFeedbackMsg,
  errorFeedbackMsg,
  onSuccess: ({ data }) => {
    // eslint-disable-next-line no-underscore-dangle
    history.push(`/retail-media/campaign/view/${data._id}`)
  }
})

/**
 * Upload file */
const upload = ({
  body,
  axiosOptions = {},
  successFeedbackMsg = i18n.t('rm:actions.campaign.upload.media.success'),
  errorFeedbackMsg = i18n.t('rm:actions.campaign.upload.media.error'),
  onSuccess
}) => ({
  type: types.upload,
  payload: service.upload({ body, axiosOptions }),
  successFeedbackMsg,
  errorFeedbackMsg,
  onSuccess
})

/**
 * Get one */
const get = data => ({
  type: types.get,
  payload: service.get(data)
})

const cancelCampaign = id => ({
  type: types.cancelCamapign,
  payload: service.cancelCampaign(id)
})

/**
 * Update
 *
 * @param {string} data.id Id do item atualizado
 * @param {object} data.body Corpo da requisição de atualização
 */
const update = data => ({
  type: types.update,
  payload: service.update(data),
  successFeedbackMsg: i18n.t('rm:actions.campaign.edit.success'),
  errorFeedbackMsg: i18n.t('rm:actions.campaign.edit.error'),
  onSuccess: response => {
    // eslint-disable-next-line no-underscore-dangle
    history.push(`/retail-media/campaign/view/${data.id}`)
  }
})

/**
 * Toggle active
 *
 * @param {string} data.id Id do item atualizado
 * @param {object} data.body Corpo da requisição de atualização
 */
export const handleStatusCampaign = ({ onSuccess, ...data }) => ({
  type: types.patch,
  payload: service.patch(data),
  successFeedbackMsg: i18n.t('rm:actions.campaign.edit.success'),
  errorFeedbackMsg: `${i18n.t('rm:actions.campaign.edit.success')} [status]`,
  onSuccess
})

export const handleStatusLocal = data => ({
  type: types.updateItemLocal,
  payload: data
})

/**
 * Delete
 *
 * @param {string} data.id Id do item atualizado
 */
const remove = ({ id, onSuccess }) => ({
  type: types.remove,
  payload: service.remove(id),
  onSuccess
})

/**
 * Handle page */
export const setPageGroceryCampaigns = (value: number) => ({
  type: types.page,
  payload: value
})

/**
 * Handle items por page */
export const setPerPageGroceryCampaigns = (value: number) => ({
  type: types.perPage,
  payload: value
})

/**
 * Get dashboard data */
const dashboard = () => ({
  type: types.dashboard,
  payload: service.dashboard.get(),
  errorFeedbackMsg: i18n.t('rm:actions.campaign.dash.list.error')
})

export const countStatus = (queries = {}) => ({
  type: types.countStatus,
  payload: service.listCountStatus(queries)
})

export default {
  // listGroceryCampaigns,
  // setPage,
  // setPerPage,
  get,
  create,
  update,
  upload,
  remove,
  dashboard,
  cancelCampaign,
  countStatus
}
