import React from 'react'
import { FiFolder } from 'react-icons/fi'

import ThumbProduct from '~/components/ThumbProduct'
import { WrapperThumbProduct } from '~/components/ThumbProduct/styles'
import { Badge } from '~/components/UI/Badge'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

const ProductInfo: React.FC<{ data: PublisherProduct }> = ({ data }) => {
  const { t } = useAPPTranslation()

  const hasValidCategories = !!data?.categories.filter(Boolean)?.length
  const isAvailable = data?.is_available || data?.available_stock

  return (
    <section className="d-flex align-items-center">
      <WrapperThumbProduct>
        <ThumbProduct url={data.image_url} title={data.name} />
      </WrapperThumbProduct>
      {/* Categories */}
      <article className="d-flex flex-column justify-content-center align-items-start">
        {hasValidCategories ? (
          <small
            className="d-flex align-items-center gap-1 lh-1 color-dark"
            style={{ maxWidth: '50vw', width: '480px', minWidth: '300px' }}
          >
            <FiFolder /> {data?.categories?.at(-1)}
          </small>
        ) : (
          <Badge template="warning" size="small" className="m-0">
            {t('rm:config.catalog.alert.wihoutCategory')}
          </Badge>
        )}
        {/* Product name */}
        {data.name && (
          <div className="mainInfo">
            <a href={data.url} target="_blank" rel="noreferrer">
              <strong style={{ fontSize: '.75rem' }}>{data.name}</strong>
            </a>
          </div>
        )}
        {/* Available tag */}
        <Badge size="small" template={isAvailable ? 'success' : 'danger'}>
          {isAvailable ? t('c:words.available') : t('c:words.unavailable')}
        </Badge>
      </article>
    </section>
  )
}

export default ProductInfo
