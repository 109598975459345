import styled, { css } from 'styled-components';

import { device } from '~/styles/device';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    gap: 0.5rem;
    padding: 0;
  }
`;

export const List = styled.div`
  ${({ theme }) => css`
    > * + * {
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`;

export const Sidebar = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall};
    border: 1px solid ${theme.colors.inative};
    border-radius: 0.25rem;
    background-color: ${theme.colors.white};

    margin-bottom: 0.5rem;

    @media ${device.laptop} {
      order: 2;
      margin-bottom: 0;
    }
  `}
`;
