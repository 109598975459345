import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { MdOutlineStorefront } from 'react-icons/md'
import { toastr } from 'react-redux-toastr'

import PropTypes from 'prop-types'

import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { formattedDistance } from '~/helpers'
import { getAccountInfo } from '~/helpers/account-info'
import { searchLocationByDeliveryType } from '~/services/location-service'

import { Address, AddressInline } from '../Address'
import Alert from '../Alert'
import Loading from '../Loading'
import Tag from '../Tag'
import { schema } from './elements/table-schema'

import * as S from './styles'

const LocationsNear = ({
  address,
  typeLocation = 'tags',
  showAddress = false,
  fullLabel = false
}) => {
  const [locations, setLocation] = useState()
  const [loading, setLoading] = useState(true)

  const info = getAccountInfo()
  const host = info?.host

  const handleFindLocation = useCallback(async () => {
    try {
      const {
        address_postal_code,
        address_street,
        address_number,
        address_neighborhood,
        address_city,
        address_state
      } = address

      const formatAddress = `${address_street}, ${address_number}, ${address_neighborhood} ${address_city} - ${address_state}`

      const query = {
        address: formatAddress,
        postal_code: address_postal_code,
        delivery_type: 'shipping_from_store'
      }

      const response = await searchLocationByDeliveryType({
        query,
        host
      })

      setLocation(response.data)
    } catch (error) {
      toastr.error(
        'Erro',
        'Houve um erro ao buscar os locais próximos ao endereço.'
      )
    } finally {
      setLoading(false)
    }
  }, [address, host])

  useEffect(() => {
    handleFindLocation()
  }, [handleFindLocation])

  const label = useMemo(
    () =>
      fullLabel ? 'Locais que antendem com entrega:' : 'Atende com entrega',
    [fullLabel]
  )

  return (
    <S.Container className="locationsNear">
      <Loading status={loading}>Buscando locais que atendam o cliente</Loading>

      {!loading && typeLocation === 'tags' && (
        <>
          <header className="headerSection">
            {showAddress && (
              <div className="original_address">
                <small>
                  <AddressInline location={address} />
                </small>
              </div>
            )}

            <span className="icon">
              <MdOutlineStorefront />
            </span>
            <strong>{label}</strong>
          </header>

          <div>
            {!address?.address_postal_code && (
              <Tag>Cadastre um CEP no endereço do cliente</Tag>
            )}

            {address?.address_postal_code && (
              <>
                {locations?.length ? (
                  locations.map(item => (
                    <Tag
                      key={item.id}
                      label={
                        item?.distance
                          ? formattedDistance({
                              value: item.distance,
                              decimals: 1
                            })
                          : ''
                      }
                    >
                      {item.name}
                    </Tag>
                  ))
                ) : (
                  <Tag>Nenhum local atende o endereço cadastrado</Tag>
                )}
              </>
            )}
          </div>
        </>
      )}

      {!loading &&
        typeLocation === 'list' &&
        (locations?.length ? (
          <Panel
            title="Locais próximos"
            description={<AddressInline location={address} />}
            noPaddingBody
            isLoading={loading}
          >
            <Table schema={schema} data={locations} />
          </Panel>
        ) : (
          <Alert active>Nenhum local atende o endereço cadastrado</Alert>
        ))}
    </S.Container>
  )
}

export default LocationsNear

LocationsNear.propTypes = {
  address: PropTypes.shape({
    address_postal_code: PropTypes.string,
    address_street: PropTypes.string,
    address_number: PropTypes.string,
    address_complement: PropTypes.string,
    address_neighborhood: PropTypes.string,
    address_city: PropTypes.string,
    address_state: PropTypes.string
  }).isRequired,
  typeLocation: PropTypes.oneOf(['tags', 'list'])
}

LocationsNear.defaultProps = {
  typeLocation: 'tags'
}
