import { IconType } from 'react-icons'
import { FiSliders as icon } from 'react-icons/fi'

import Dashboard from '~/pages/dashboard'

export const dashboard: RootMenuItem<IconType> = {
  id: 'e4288743-bc9a-4228-90c1-e79f63dabba1',
  path: '/',
  name: 'Dashboard',
  icon,
  containsHome: true,
  component: Dashboard,
  children: null
}
