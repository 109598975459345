import { shade } from 'polished'
import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const deleteButtonTemplate = ({ theme }: ButtonTemplatesProps) => css`
  background: var(--danger);
  color: var(--white);

  border: 1px solid var(--danger);

  &:not(:disabled) {
    &:hover {
      background: ${shade(0.1, theme.danger)};
    }
  }
`

export default deleteButtonTemplate
