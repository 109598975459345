import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  margin-bottom: 1rem;

  > * + * {
    margin-left: 0.5rem;
  }
`;
