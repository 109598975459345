import styled, { css } from 'styled-components';

const containerModifiers = {
  isDisabled: () => css`
    opacity: 0.5;
    cursor: not-allowed;

    * {
      cursor: not-allowed;
    }

    label {
      &:hover {
        color: inherit;
      }
    }
  `,
};

export const Container = styled.div`
  ${({ theme, isDisabled }) => css`
    ${isDisabled && containerModifiers.isDisabled()}

    &.disabled {
      ${containerModifiers.isDisabled()}
    }

    &.hide,
    input[type='checkbox'] {
      display: none;
    }

    /* Label */
    label {
      position: relative;
      margin-bottom: 0;

      display: flex;
      align-items: center;
      flex: auto;

      border-radius: ${theme.border.radius.medium};
      padding: 0 ${theme.spacings.small} 0 1.5rem;
      height: 100%;

      font-size: ${theme.font.sizes.small};

      transition: all ${theme.transition.default};
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
        content: '';
        transition: all ${theme.transition.fast};
      }

      &:before {
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        width: ${theme.spacings.large};
        height: ${theme.spacings.large};
        border-radius: ${theme.border.radius.medium};
        border: 2px solid ${theme.colors.primary};

        background-color: ${theme.colors.light};
      }

      &:after {
        top: 50%;
        left: 9px;
        transform: translate(-50%, -50%);
        border-radius: ${theme.border.radius.small};

        width: 0;
        height: 0;

        background: ${theme.colors.success};
      }

      &:hover {
        color: ${theme.colors.primary};

        &:after {
          width: 8px;
          height: 8px;
          background: ${theme.colors.tertiary};
        }
      }
    }

    /* Input */
    input[type='checkbox']:checked,
    &.selected input[type='checkbox'] {
      /* The .selected class is used in the radio list */
      + label:before {
        border-color: ${theme.colors.primary};
      }

      + label:after {
        width: 10px;
        height: 10px;
        background: ${theme.colors.success};
      }

      + label {
        color: ${theme.colors.primary};

        strong {
          color: ${theme.colors.primary};
        }
        span {
          color: ${theme.colors.dark};
        }
      }
    }
  `}
`;
