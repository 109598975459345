import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { FiFilter } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button } from '~/components/Buttons'
import { Filters } from '~/components/Filters'
import { hideSidebar } from '~/components/sidebar/sidebar-actions'
import { getRole } from '~/helpers'
import {
  getChat,
  setChatIdByUrl,
  setSelectedChat,
  toggleAutoRefresh
} from '~/modules/chat/store/actions'
import { CHAT_REDUX_QUERY } from '~/modules/chat/store/constants'
import { useAppSelector } from '~/store/hooks'

import ChatContextWrapper from '../ChatContextWrapper'
import ChatFilters from '../ChatFilters'
import ChatWrapper from '../ChatWrapper'
import Sidebar from '../Sidebar'
import SlidingPanels from '../SlidingPanels'

const Chat = () => {
  const { id: chatId } = useParams()

  const dispatch = useDispatch()

  const {
    chats,
    loadingChats,
    loading,
    selectedStatus,
    selectedChat,
    fullScreen
  } = useAppSelector(({ chats }) => chats)

  const { isConnected, provider, isWhatsAppWeb } = useAppSelector(
    ({ chatConnect }) => chatConnect
  )

  const { isMobile } = useAppSelector(state => state.layout)

  const statusSelecteds = useMemo(
    () =>
      selectedStatus === 'waiting_contact' ||
      selectedStatus === 'in_contact' ||
      selectedStatus === 'available',
    [selectedStatus]
  )

  useEffect(() => {
    if (statusSelecteds) {
      dispatch(toggleAutoRefresh(true))
    } else if (!statusSelecteds) {
      dispatch(toggleAutoRefresh(false))
    }

    dispatch(setChatIdByUrl(chatId || null))

    return () => {
      dispatch(toggleAutoRefresh(false))
    }
  }, [chatId, dispatch, selectedStatus, statusSelecteds])

  const chatIsReady = useMemo(
    () => provider && (!isWhatsAppWeb || isConnected),
    [isConnected, provider, isWhatsAppWeb]
  )

  /**
   * Escondando menu ao carregar
   */

  useLayoutEffect(() => {
    if (!isMobile) {
      dispatch(hideSidebar())
    }
  }, [dispatch, isMobile])

  const role = getRole()

  const isAdmin = useMemo(() => role === 'admin', [role])

  /**
   * Fazendo requisição do chat no caso de single chat page
   */
  const queryString = useMemo(() => ({ chatId: chatId }), [chatId])

  useEffect(() => {
    if (fullScreen) {
      // Pega chat por id
      if (!selectedChat) {
        dispatch(getChat(queryString))
      }

      // Define no selected chat
      // if (selectedChat?.chatId === chatId) {
      //   return
      // }

      if (chatId && chats.length > 0) {
        const hasChatByURL = chats.find(chat => chat.chatId === chatId)

        if (hasChatByURL) {
          if (selectedChat?.chatId !== hasChatByURL?.chatId) {
            dispatch(setSelectedChat({ selectedChat: hasChatByURL }))
          }
        }
      }
    }
  }, [chatId, chats, dispatch, fullScreen, queryString, selectedChat])

  /**
   * Auto refresh no chat para sincronizar mudanças por fatores externos
   */
  const getListTimer = useRef(null)

  useEffect(() => {
    if (fullScreen) {
      getListTimer.current = setInterval(async () => {
        dispatch(getChat(queryString))
      }, 10000)
    }
    return () => {
      clearInterval(getListTimer.current)
    }
  }, [dispatch, fullScreen, queryString, selectedChat])

  return chatIsReady ? (
    <>
      <ChatFilters />

      <ChatContextWrapper>
        {!fullScreen && <Sidebar />}

        <ChatWrapper />

        <SlidingPanels />
      </ChatContextWrapper>
    </>
  ) : (
    <></>
  )
}

export default Chat
