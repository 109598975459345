import styled, { css, keyframes } from 'styled-components'

const spinnerGrow = props => keyframes`
   0% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      50% {
        opacity: 1;
      }
  `

const spin = props => keyframes`
    100% {
          transform: rotate(360deg);
        }
  `

export const Container = styled.div`
  ${({ theme, color }) => css`
    display: inline-block;
    vertical-align: text-bottom;

    width: ${theme.spacings.medium};
    height: ${theme.spacings.medium};
    border-radius: ${theme.border.radius.rounded};

    background-color: ${color || 'var(--loading)'};
    opacity: 0;

    animation: ${spinnerGrow} 0.75s linear infinite;
    -webkit-animation: ${spinnerGrow} 0.75s linear infinite;
  `}
`

export const ContainerCircle = styled.div`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  ${({ theme }) => css`
    width: calc(${theme.spacings.medium} * 2);
    height: calc(${theme.spacings.medium} * 2);

    border: ${theme.spacings.xxxsmall} solid ${theme.colors.primary};
    border-top: ${theme.spacings.xxxsmall} solid ${theme.colors.secondary};

    border-radius: ${theme.border.radius.rounded};

    animation: ${spin} 1s linear infinite;
  `}
`
