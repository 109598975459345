import React from 'react';
import { FiTag } from 'react-icons/fi';

import PropTypes from 'prop-types';

import { formatMoney } from '~/helpers';

const ColumnValues = ({ data }) => {
  return (
    <div>
      {formatMoney(data.total_price)}
      {!!data?.coupon_id && (
        <>
          <br />
          <small style={{ fontSize: '8px', color: '#999' }}>
            <FiTag /> {data?.metadata?.coupon_code || 'Cupom aplicado'}
          </small>
        </>
      )}
    </div>
  );
};

ColumnValues.propTypes = {
  data: {
    total_price: PropTypes.number.isRequired,
    coupon_id: PropTypes.string,
    metadata: PropTypes.shape({
      coupon_code: PropTypes.string,
    }),
  }.isRequired,
};

export default ColumnValues;
