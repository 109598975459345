import { types } from '../constants'

const index = {
  pending: `${types.patch}_PENDING`,
  fulfilled: `${types.patch}_FULFILLED`,
  rejected: `${types.patch}_REJECTED`
}

export const patch = {
  [index.pending]: ({ draft }) => {
    draft.industry.ad.patch.loading = true
    draft.industry.ad.patch.error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.ad.patch.loading = false
    draft.industry.ad.patch.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.ad.patch.loading = false
    draft.industry.ad.patch.error = action.payload
  }
}
