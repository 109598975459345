import { getRetailMediaAccountType } from '~/helpers'

import AdvertiserDashboardTabs from './Advertiser'
import PublisherDashboardTabs from './Publisher'

const MainTabs = () => {
  const isPublisher = getRetailMediaAccountType() === 'publisher'

  return isPublisher ? <PublisherDashboardTabs /> : <AdvertiserDashboardTabs />
}

export default MainTabs
