import { FiSave, FiX } from 'react-icons/fi'

import { Form } from '@unform/web'
import i from 'i18next'

import { Button } from '~/components/Buttons'
import { FormGroup } from '~/components/unform'

import FormContext from '../FormContext'
import FormFormats from '../FormFormats'
import FormMainInfo from '../FormMainInfo'

const PlacementForm: React.FC<PlacementFormProps> = ({
  onSubmit,
  isLoading,
  initialData,
  fieldsets = null,
  onCancel = null
}) => (
  <Form className="form-grid" onSubmit={onSubmit} initialData={initialData}>
    {/* Informações gerais */}
    {(!fieldsets || fieldsets.includes('main')) && <FormMainInfo />}
    {/* Contexto da campanha */}
    {(!fieldsets || fieldsets.includes('context')) && <FormContext />}
    {/* Formato do anúncio */}
    {(!fieldsets || fieldsets.includes('formats')) && (
      <FormFormats initialData={initialData} />
    )}

    <FormGroup className="actionButtons">
      {!!onCancel && (
        <Button
          text={i.t('common:actions.cancel')}
          iconLeft={<FiX />}
          onClick={onCancel}
          loading={isLoading}
          template="transparentDanger"
          className="me-auto px-0"
        />
      )}

      <Button
        text={i.t('common:actions.save')}
        textLoading={`${i.t('common:actions.saving')}...`}
        iconLeft={<FiSave />}
        loading={isLoading}
        template="success"
        type="submit"
      />
    </FormGroup>
  </Form>
)

export default PlacementForm
