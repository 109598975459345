import { useState } from 'react'

import ThumbProduct from '~/components/ThumbProduct'
import useModal from '~/hooks/useModal'

import * as S from './styles'

interface Props {
  url: string
  title: string
}

/**
 * Componente que renderiza um thumb via url e permite dar um zoom na imagem
 * abriado num de um modal
 */

const ThumbZoomable = ({ url, title }: Props) => {
  const [show, setShow] = useState(false)

  const [Modal, showModal, toggle, setIsShowing] = useModal()

  const handleCloseModal = () => {
    setIsShowing(false)
    setShow(false)
  }

  return (
    <>
      <S.Image onClick={() => setShow(true)} className="imageWrapper">
        <ThumbProduct url={url} title={title} />
      </S.Image>

      {show && (
        <Modal isOpen={!!show} onClose={handleCloseModal} maxWidth="auto">
          <S.ImageModal src={url} />
        </Modal>
      )}
    </>
  )
}

export default ThumbZoomable
