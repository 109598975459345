import React, { useState, useMemo, useLayoutEffect } from 'react'

import { cnpj, cpf } from 'cpf-cnpj-validator'

import AlertInfo from '~/components/AlertInfo'
import { FormGroup, Textarea, Label } from '~/components/unform'

import { cleanSocialId } from '../../helpers/cleanSocialId'
import * as FormStyles from '../Form/styles'

const CustomersList = ({ initialData }) => {
  const [customers, setCustomers] = useState({})

  const handleChange = ({ target: { value } }) => {
    if (!value) {
      setCustomers(null)
      return
    }

    const result = cleanSocialId(value)

    const invalids = result.filter(
      item => !(cpf.isValid(item) || cnpj.isValid(item))
    )
    const valids = result.filter(
      item => cpf.isValid(item) || cnpj.isValid(item)
    )

    setCustomers({ all: result, valids, invalids })
  }

  useLayoutEffect(() => {
    if (initialData?.customers) {
      const value = initialData.customers.toString()

      handleChange({ target: { value } })
    }
  }, [])

  return (
    <FormGroup style={{ flex: 1 }}>
      <Label htmlFor="customers" isRequired text="Lista de CPF elegíveis" />

      <Textarea
        name="customers"
        placeholder="Insira os CPFs aqui separados por vírgula."
        required
        onChange={handleChange}
      />

      <AlertInfo text="Não vamos alterar sua listagem. A checagem abaixo é apenas uma funcionalidade para auxiliar no filtro manual." />

      {customers && (
        <>
          <FormStyles.Group>
            <small>
              <strong>{customers?.all?.length || 0} clientes:</strong>{' '}
              {customers?.all?.map((item, index) => (
                <span
                  key={item}
                  className={`customerSocialId ${
                    cpf.isValid(item) || cnpj.isValid(item)
                      ? 'valid'
                      : 'invalid'
                  }`}
                >
                  {index > 0 && ', '}
                  <span className="value">{item}</span>
                </span>
              ))}
            </small>
          </FormStyles.Group>
          <FormStyles.Group>
            <small>
              <strong>
                {customers?.valids?.length || 0} documentos válidos:
              </strong>{' '}
              {customers?.valids?.map((item, index) => (
                <span key={item} className={`customerSocialId `}>
                  {index > 0 && ', '}
                  <span className="value">{item}</span>
                </span>
              ))}
            </small>
          </FormStyles.Group>
          <FormStyles.Group>
            <small>
              <strong>
                {customers?.invalids?.length || 0} documentos inválidos:
              </strong>{' '}
              {customers?.invalids?.map((item, index) => (
                <span
                  key={item}
                  className={`customerSocialId invalid
              `}
                >
                  {index > 0 && ', '}
                  <span className="value">{item}</span>
                </span>
              ))}
            </small>
          </FormStyles.Group>
        </>
      )}
    </FormGroup>
  )
}

export default CustomersList
