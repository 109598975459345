import React, { useState } from 'react'
import { FiBookOpen, FiMessageSquare } from 'react-icons/fi'
import { IoHelpOutline } from 'react-icons/io5'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

import { t } from 'i18next'
import { Button } from '~/components/Buttons'
import Tooltip from '~/components/Tooltip'
import { useAppInfo } from '~/hooks/useAppInfo'

import * as S from './styles'

const PopoverSupport: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  const { isWhiteLabel, faqUrl, newTicketUrl } = useAppInfo()

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" end>
      <S.Container data-wl={isWhiteLabel}>
        <DropdownToggle nav className="help-widget">
          <Button
            template="transparentPrimary"
            customWidth="auto"
            text={
              <Tooltip
                icon={<IoHelpOutline />}
                positionProp="left-bottom"
                text={t('config:support.menu.helpAndResources')}
              />
            }
          />
        </DropdownToggle>

        <DropdownMenu right>
          <DropdownItem>
            <Button
              text={t('config:support.menu.helpAndDocumentation')}
              iconLeft={<FiBookOpen />}
              template="transparentDark"
              size="xsmall"
              as="a"
              href={faqUrl}
              target="_blank"
              rel="noreferrer"
            />
          </DropdownItem>

          <DropdownItem>
            <Button
              text={t('config:support.menu.reportProblem')}
              iconLeft={<FiMessageSquare />}
              template="transparentDark"
              size="xsmall"
              as="a"
              href={newTicketUrl}
              target="_blank"
              rel="noreferrer"
            />
          </DropdownItem>
        </DropdownMenu>
      </S.Container>
    </Dropdown>
  )
}

export default PopoverSupport
