import { Action, Dispatch, Middleware, Unsubscribe } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import io from 'socket.io-client'

import { getAuthToken } from '~/helpers'
import {
  LOGIN_LOGGED_IN,
  LOGIN_LOGGED_OUT
} from '~/modules/auth/store/login/constants'
import { ADD_NEW_MESSAGE } from '~/modules/chat/store/constants'

interface StoreAPI<S = RootState, A extends Action = AnyAction> {
  dispatch: Dispatch<A>
  getState(): S
  subscribe(listener: () => void): Unsubscribe
}

const SocketMiddleware: Middleware = (store: StoreAPI) => {
  let socket = null

  const setSocket = (store: StoreAPI) => {
    const token = getAuthToken()

    if (token) {
      socket = io(`${process.env.NOTIFICATION_HOST}?token=${token}`)

      socket.on('whatsapp', message => {
        const filteredLocation =
          store.getState()?.filters?.locations?.value || null

        const shouldRefresh = store.getState()?.chats?.autoRefresh || false

        if (shouldRefresh) {
          store.dispatch({
            type: ADD_NEW_MESSAGE,
            payload: { message, filteredLocation }
          })
        }
      })

      socket.on('app_notification', message => {
        store.dispatch({ type: message.key, data: message })
      })
    }
  }

  setSocket(store)

  return next => (action: AnyAction) => {
    if (!socket && action.type === LOGIN_LOGGED_IN) {
      setSocket(store)
    }

    if (socket && action.type === LOGIN_LOGGED_OUT) {
      socket.close()
      socket = null
    }

    return next(action)
  }
}

export default SocketMiddleware
