import styled from 'styled-components'

export const Container = styled.div`
  --textColor: var(--dark);

  &[data-template='info'] {
    --baseColor: var(--info);
  }
  &[data-template='success'] {
    --baseColor: var(--success);
  }
  &[data-template='success_alert'] {
    --baseColor: var(--successAlert);
  }
  &[data-template='warning'] {
    --baseColor: var(--warning);
  }
  &[data-template='error'],
  &[data-template='danger'] {
    --baseColor: var(--danger);
  }

  position: relative;

  width: 100%;
  border-radius: 5px;
  padding: 10px;

  color: var(--textColor);
  background-color: var(--baseColor);

  /* Lighten BG */

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';

    position: absolute;
    z-index: 0;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    border-radius: 0.25rem;
    background-color: rgba(255, 255, 255, 0.75);
  }
`
