import { t } from 'i18next'

import {
  listAdvertiserPublishers,
  listPublisherAdvertisers
} from '../../advertiserPublisher/actions'
import { CAPMPAIGNS_FILTERS_KEYS } from '../constants'

const common = {
  isMulti: false,
  formatOptions: data =>
    data?.map(item => ({
      value: item.id,
      label: item.name
    }))
}

type FiltersAdvertiserPublisher = {
  [key in AccountTypes]: GenericFilterProps
}

export const advertiserPublisherFilters: FiltersAdvertiserPublisher = {
  grocery: {
    ...common,
    keyState: CAPMPAIGNS_FILTERS_KEYS.advertiser,
    placeholder: t('rm:filterByAdvertiser'),
    dynamicAction: () =>
      listPublisherAdvertisers({ count: false, quantity: 5000 })
  },
  industry: {
    ...common,
    keyState: CAPMPAIGNS_FILTERS_KEYS.publisher,
    placeholder: t('rm:filterByPublisher'),
    dynamicAction: () =>
      listAdvertiserPublishers({ count: false, quantity: 5000 })
  }
}
