import { t } from 'i18next'

import { wallet as service } from '~/modules/retailMedia/services/industry'

import { types } from './constants'

export const setPageIndustryListAllWallet = (value: number) => ({
  type: types.listAllSetPage,
  payload: value
})

export const setPerPageIndustryListAllWallet = (value: number) => ({
  type: types.listAllPerPage,
  payload: value
})

export const setPageIndustryListTransaction = (value: number) => ({
  type: types.listTransactionSetPage,
  payload: value
})

export const setPerPageIndustryListTransaction = (value: number) => ({
  type: types.listTransactionSetPerPage,
  payload: value
})

type RechargeIndustryWalletProps<T> = {
  body: IndustryRecharge
} & ReduxPromiseMiddlewareHelpers<T>

export const rechargeIndustryWallet = ({
  body,
  successFeedbackMsg,
  showSuccessCallback,
  errorFeedbackMsg = t('rm:actions.wallet.transaction.recharge.error'),
  onSuccess = null,
  onError
}: RechargeIndustryWalletProps<{ data: ResponseRecharge }>) => {
  const successFeedbackMsgByType = {
    pix: t('rm:actions.wallet.transaction.pix.recharge.success'),
    credit_card: t('rm:actions.wallet.transaction.recharge.success')
  }

  // Fake para testar response
  // const status: WalletPaymentStatus = 'payment_refused'
  // const payloadData: { data: ResponseRecharge } = {
  //   data: { status, id: '1234', payment_method: 'credit_card' }
  // }
  // const payload = payloadData => Promise.resolve(payloadData)

  return {
    type: types.recharge,
    // payload: payload(payloadData),
    payload: service.recharge(body),
    successFeedbackMsg:
      successFeedbackMsg || successFeedbackMsgByType?.[body.payment_method],
    errorFeedbackMsg,
    onSuccess,
    onError,
    showSuccessCallback
  }
}

export const getTransactionRecharge = ({
  id,
  errorFeedbackMsg = t('rm:actions.wallet.transaction.find.error'),
  onSuccess = null
}) => ({
  type: types.getTransaction,
  payload: service.getTransaction(id),
  errorFeedbackMsg,
  onSuccess
})

export default {
  setPageIndustryListAllWallet,
  setPerPageIndustryListAllWallet,

  setPageIndustryListTransaction,
  setPerPageIndustryListTransaction
}
