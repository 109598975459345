import * as S from './styles'

const Badge = ({
  iconLeft,
  children,
  template,
  size = 'medium',
  className = '',
  text = null,
  ...rest
}: BadgeProps) => {
  return (
    <S.Badge
      className={`Badge_UI_Component Badge_UI_Component_${size} ${className}`}
      template={template}
      {...rest}
    >
      {iconLeft && <div className="icon__left">{iconLeft}</div>}
      {text}
      {children}
    </S.Badge>
  )
}

export { Badge }
