import React from 'react'
import { FiChevronLeft, FiExternalLink, FiX } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import { setOrderOpen, togglePanelCustomer } from '~/modules/chat/store/actions'

import OrderInfos from '../OrderInfos'

import { Container } from './styles'

const OrderPanel = () => {
  const { orderOpen } = useSelector(({ chats }) => chats)

  const { open, id, orderId } = orderOpen

  const dispatch = useDispatch()

  const handlePanelCustomer = () => {
    dispatch(togglePanelCustomer())
    dispatch(setOrderOpen({ open: false }))
  }

  const handlePanelOrder = () => dispatch(setOrderOpen({ open: false }))

  return (
    <Container isOpen={open}>
      {orderOpen.open && (
        <header>
          <Button
            template="icon"
            onClick={handlePanelOrder}
            className="icon__back"
          >
            <FiChevronLeft />
          </Button>

          <div className="infos">
            <div className="name">Pedido {orderId}</div>

            <div>
              <a href={`/order/${id}`} target="_blank" rel="noreferrer">
                Ver pedido completo <FiExternalLink />
              </a>
            </div>
          </div>

          <button
            type="button"
            onClick={handlePanelCustomer}
            className="icon__close"
          >
            <FiX />
          </button>
        </header>
      )}

      {id && <OrderInfos />}
    </Container>
  )
}

export default OrderPanel
