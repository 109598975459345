import React from 'react'
import { IntlProvider } from 'react-intl'

import moment from 'moment'

// import pt from 'react-intl/locale-data';
// import es from 'react-intl/locale-data/es';
// import en from 'react-intl/locale-data/es';
import localstorageConstants from '~/helpers/localstorage-manipulation.js'
import i18n from '~/i18n'

import { LANGUAGES } from './languages.js'
import translations from './translations.json'

// import 'moment/locale/pt-br'

// Setup dados de localização por idioma
// addLocaleData([...pt, ...es, ...en]);

export default class IntlProviderConfigured extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      locale: 'en-US',
      currency: 'USD'
    }
  }

  componentDidMount() {
    const currentUrlLang = window.location.pathname.split('/')[1]
    const currentLanguage = LANGUAGES[currentUrlLang]

    // const userLang = navigator.language || navigator.userLanguage;
    // console.log(userLang);
    // if(!currentLanguage) return window.location.href = `/${LANGUAGES.default}`

    let currency = 'BRL'

    const storageValue = localStorage.getItem(localstorageConstants.APP_INFO)

    if (storageValue) {
      const appInfo = JSON.parse(storageValue)

      currency = appInfo?.currencyCode
    }

    const getValidLanguage = () => {
      const language = i18n.language || 'pt-BR'

      try {
        // Testa se o código de idioma é válido
        new Intl.NumberFormat(language)
        return language // Se for válido, retorna o mesmo código
      } catch (error) {
        // Se houver erro, retorna um código de idioma padrão
        console.warn(
          `Idioma inválido: ${language}. Usando 'pt-BR' como padrão.`
        )
        return 'pt-BR'
      }
    }

    const language = getValidLanguage()
    this.setState({ locale: 'pt-BR', loading: false, currency: currency })
    moment.locale(language)
    // this.setState({ locale: userLang, loading: false });
  }

  render() {
    const { locale, loading } = this.state
    const { children } = this.props

    if (loading) return <div>...</div>

    return (
      <IntlProvider locale={locale} messages={translations[locale]}>
        {children}
      </IntlProvider>
    )
  }
}
