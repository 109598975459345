import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectSearchable from '~/components/SelectSearchable';
import { setFilteredPaymentStatus } from '~/store/modules/filters/actions';

/**
 * Componente para filtrar a listagem por canais.
 * Ele possibilita a seleção de diversos locais e mantém no estado global: filters.channel.
 *
 * Não recebe nenhum parâmetro e retorna um select procurável.
 *
 * @example <FilterPayment />
 */

export const paymentStatusOptions = [
  { label: 'Qualquer status de pagamento', value: null },
  { value: 'waiting_payment', label: 'Pagamento pendente' },
  { value: 'payment_authorized', label: 'Pagamento autorizado' },
  { value: 'payment_approved', label: 'Pagamento aprovado' },
  { value: 'payment_refused', label: 'Pagamento recusado' },
  { value: 'payment_refunded', label: 'Pagamento cancelado' },
  { value: 'waiting_refund', label: 'Aguardando estorno' },
  { value: 'payment_partial_refunded', label: 'Parcielmente estornado' },
];

const FilterPayment = () => {
  const dispatch = useDispatch();

  const { paymentStatus: filteredPaymentStatus } = useSelector(
    state => state.filters,
  );

  const handleChangePaymentStatus = selectedPaymentStatus => {
    dispatch(
      setFilteredPaymentStatus({
        filteredPaymentStatus: selectedPaymentStatus,
      }),
    );
  };

  return (
    <SelectSearchable
      placeholder="Filtrar por status de pagamento"
      options={paymentStatusOptions}
      onChange={handleChangePaymentStatus}
      defaultValue={[filteredPaymentStatus]}
    />
  );
};

export default FilterPayment;
