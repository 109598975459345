export default {
  view: {
    data: null,
    loading: false,
    error: null
  },
  list: {
    data: null,
    loading: false,
    page: 1,
    perPage: 25,
    total: 0,
    pages: 0,
    error: null,
    sortKey: null,
    sortDirection: null,
    sortKeyDefault: null,
    sortDirectionDefault: null
  }
}
