import React, { useCallback } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import LocationName from '~/components/LocationName';

import { useMapDraw } from '../../hooks/useMapDraw';
import Icons from '../Icons';

import * as S from './styles';

const ListRegions = ({ isPreview }) => {
  const { id: locationId = null } = useParams();

  const { polygonRefs, handleToggleRegionEditMode, editingRegion } =
    useMapDraw();
  const { locationRegions } = useSelector(state => state.locations);

  const iconType = {
    1: 'draw',
    2: 'forbidden',
  };

  const handleClick = useCallback(
    id => {
      const hasPolygon = Object.keys(polygonRefs).find(
        polygonId => polygonId === id,
      );

      if (hasPolygon) {
        const refPolygon = polygonRefs[id];

        handleToggleRegionEditMode({
          e: { target: refPolygon.ref },
          polygon: refPolygon.polygon,
          isPreview,
        });
      }
    },
    [handleToggleRegionEditMode, polygonRefs, isPreview],
  );

  return (
    <S.Container className="listRegions">
      {locationRegions?.map(region => (
        <S.Region
          key={region.id}
          type="button"
          onClick={() => handleClick(region.id)}
          isActive={editingRegion?.id === region?.id}
        >
          <Icons type={iconType[region.region_type]} size="small" />{' '}
          <div className="name">
            {region.name}
            {isPreview && !locationId && (
              <>
                <br />
                <small>
                  <LocationName locationId={region.location_id} />
                </small>
              </>
            )}
          </div>
          {!isPreview && (
            <div className="arrow">
              <FiChevronRight />
            </div>
          )}
        </S.Region>
      ))}
    </S.Container>
  );
};

export default ListRegions;

ListRegions.propTypes = {
  isPreview: PropTypes.bool,
};

ListRegions.defaultProps = {
  isPreview: false,
};
