import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const transparentButtonTemplate = ({ theme }: ButtonTemplatesProps) => css`
  background: transparent;
  color: var(--primary);

  min-width: unset;
  height: auto;
  padding: 0.125rem 0.25rem;

  &:not(:disabled) {
    &:hover {
      color: var(--hover);
    }
  }

  &.active {
    background: var(--primary);
    color: var(--light);
    border: 1px solid var(--primary);

    &:not(:disabled) {
      &:hover {
        color: var(--light);
        filter: brightness(1.5);
      }
    }
  }
`

export default transparentButtonTemplate
