import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { injectIntl, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import AlertDismissible from '~/components/AlertDismissible'
import { Button } from '~/components/Buttons'
import Form from '~/components/forms/form'
import Input from '~/components/forms/inputs'
import { FormGroup } from '~/components/unform'
import { requiredValidation, emailValidation } from '~/helpers'
import { useAppSelector } from '~/store/hooks'

import { forgotPassword } from '../store/forgotPassword/actions'

const ForgotPasswordForm = () => {
  const { t } = useTranslation()

  const [alert, setAlert] = useState(false)
  const [formIsValid, setFormIsValid] = useState(false)

  const dispatch = useDispatch()

  const { loading, messageError, messageSuccess } = useAppSelector(
    state => state.forgotPassword
  )

  const showAlert = () => {
    if (messageError || messageSuccess) {
      if (!alert) {
        setAlert(true)
      }
    }
  }

  const closedAlert = () => {
    setAlert(false)
  }

  useEffect(() => {
    showAlert()
  }, [messageError, messageSuccess])

  const handleSubmitForgotPassword = useCallback(
    e => {
      dispatch(forgotPassword(e))
    },
    [dispatch]
  )

  return (
    <Form
      onSubmit={handleSubmitForgotPassword}
      checkIsValid={isValid => setFormIsValid(isValid)}
    >
      <FormGroup>
        <Input
          name="email"
          type="email"
          placeholder={t('auth:forgotPassword.form.email.placeholder')}
          label={t('auth:forgotPassword.form.email.label')}
          validate={[emailValidation, requiredValidation]}
        />
      </FormGroup>

      {alert && (
        <AlertDismissible
          type={messageError ? 'danger' : 'success'}
          isOpen={alert}
          onClose={closedAlert}
          className="login-form__alert"
          messageText={messageError || messageSuccess}
        />
      )}

      <FormGroup className="actionButtons">
        <Button
          type="submit"
          size="large"
          text={t('auth:forgotPassword.form.submit.text')}
          textLoading={t('auth:forgotPassword.form.submit.textLoading')}
          loading={loading}
          disabled={!formIsValid}
          className="mx-auto"
        />
      </FormGroup>

      <footer className="mt-4 pt-3 mx-auto text-center border-top border-tertiary">
        <Link to="/">{t('auth:forgotPassword.footer.backToLogin')}</Link>
      </footer>
    </Form>
  )
}

export default ForgotPasswordForm

ForgotPasswordForm.propTypes = {
  intl: PropTypes.shape()
}

ForgotPasswordForm.defaultProps = {
  intl: null
}
