import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CardNew from '~/components/CardNew';
import { HoldCards } from '~/components/CardNew/styles';
import Panel from '~/components/Panel';
import { formatMoney } from '~/helpers';
import { getPromotionMetrics } from '~/modules/promotional/store/promotion/actions';

const Metrics = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { promotionMetrics, promotion, loadingMetrics, errorMetrics } =
    useSelector(state => state.promotional);

  useEffect(() => {
    dispatch(getPromotionMetrics({ id }));
  }, [dispatch, id]);

  return (
    <Panel
      title="Desempenho"
      description="Visão geral sobre as vendas da promoção"
      isCollapsible
    >
      <HoldCards>
        <CardNew
          title="Cupons reservados"
          value={promotionMetrics?.reservedCoupons}
          compare={promotion?.limits?.total}
          isLoading={loadingMetrics}
        />

        <CardNew
          title="Cupons usados"
          value={promotionMetrics?.efetivedCoupons}
          compare={promotion?.limits?.total}
          isLoading={loadingMetrics}
        />

        <CardNew
          title="Desconto reservado"
          value={formatMoney(promotionMetrics?.reservedDiscount)}
          isLoading={loadingMetrics}
        />

        <CardNew
          title="Desconto efetivado"
          value={formatMoney(promotionMetrics?.efetivedDiscount)}
          isLoading={loadingMetrics}
        />
      </HoldCards>
    </Panel>
  );
};

export default Metrics;
