import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmptyPage from '~/components/empty-page';
import ListPagination from '~/components/ListPagination';
import Loading from '~/components/Loading';
import Panel from '~/components/Panel';
import Table from '~/components/Table';
import { useAlert } from '~/hooks/useAlert';
import {
  customersList,
  handleCustomerPage,
  handleCustomerPerPage,
} from '~/store/modules/customers/actions';
import { CUSTOMER_REDUX_QUERY } from '~/store/modules/customers/constants';

import { schema } from './table-schema';

const CustomersTable = () => {
  const dispatch = useDispatch();

  const {
    listCustomersLoading: loading,
    listCustomers: { data, total },
    listCustomersPage,
    listCustomersPerpage,
    error,
  } = useSelector(state => state.customers);

  const { query } = useSelector(state => state.filters);

  const filteredQuery = useMemo(
    () => query?.[`${CUSTOMER_REDUX_QUERY}`] || null,
    [query],
  );

  useEffect(() => {
    dispatch(
      customersList({
        page: listCustomersPage - 1,
        quantity: listCustomersPerpage,
        query: filteredQuery,
      }),
    );
  }, [dispatch, listCustomersPerpage, listCustomersPage, filteredQuery]);

  const [AlertOnError] = useAlert(error);

  /**
   * Handle pagination
   */

  const handlePagination = useCallback(
    page => {
      dispatch(handleCustomerPage(page));
    },
    [dispatch],
  );

  const handleItemsPerPageChange = useCallback(
    ({ value }) => {
      dispatch(handleCustomerPerPage(value));
    },
    [dispatch],
  );

  return (
    <>
      <Loading status={loading}>Carregando lista de clientes</Loading>

      <AlertOnError />

      {!loading && !!total && (
        <>
          <ListPagination
            total={total}
            label={total > 1 ? 'clientes' : 'cliente'}
            currentPage={listCustomersPage}
            itemsPerPage={listCustomersPerpage}
            onClickPagination={handlePagination}
            onItemsPerPageChange={handleItemsPerPageChange}
          />

          <Panel noPaddingBody isLoading={loading}>
            <Table schema={schema} data={data} total={total} />
          </Panel>

          <ListPagination
            total={total}
            label={total > 1 ? 'clientes' : 'cliente'}
            currentPage={listCustomersPage}
            itemsPerPage={listCustomersPerpage}
            onClickPagination={handlePagination}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </>
      )}

      {total === 0 && (
        <EmptyPage
          title="Clientes"
          subtitle="Você ainda não tem clientes cadastrados, comece importando sua base de clientes"
          svgPath="photos/clients-risk-empty"
        />
      )}
    </>
  );
};

export default CustomersTable;
