import React, { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import {
  Checkbox,
  FormGroup,
  Input,
  InputPhone,
  Label
} from '~/components/unform'
import InputCpfCnpj from '~/components/unform/Input/InputCpfCnpj'
import {
  updateSession,
  togglePanelCustomer,
  updateChat
} from '~/modules/chat/store/actions'
import FormCustomer from '~/pages/customers/_elements/FormCustomer'
import AddressFields from '~/pages/locations/_elements/FormLocation/Address'
import { createCustomer } from '~/store/modules/customers/actions'

import { Container } from './styles'

const CreateCustomer = () => {
  const formRef = useRef(null)
  const dispatch = useDispatch()

  const { selectedChat } = useSelector(({ chats }) => chats)
  const { saveCustomersLoading } = useSelector(({ customers }) => customers)

  const main_phone = selectedChat?.customerNumber.substring(2)
  const name = selectedChat.customerName
  const initialData = { main_phone, name, use_bot: 'true' }

  const closePanel = useCallback(
    () => dispatch(togglePanelCustomer()),
    [dispatch]
  )

  const updateCustomerSession = useCallback(
    newCustomerInfo => {
      const { data } = newCustomerInfo
      const actionData = {
        data: {
          ...selectedChat,
          customerId: data.id,
          customerName: data.name,
          session: {
            ...selectedChat.session,
            customerId: data.id,
            customerName: data.name
          }
        },
        successFeedbackMsg: 'Conversa atualizada com sucesso',
        onSuccess: closePanel
      }

      dispatch(updateChat(actionData))
    },
    [dispatch, selectedChat, closePanel]
  )

  const handleSubmit = useCallback(
    async formData => {
      const {
        main_phone: { numAsString: main_phone },
        name,
        social_id
      } = formData

      const newCustomer = {
        ...formData,
        external_id: `phone:${main_phone}`,
        name,
        main_email: `${main_phone}@newtail.com.br`,
        main_phone,
        date_of_birth: '2000-01-01',
        application_source: 'whatsapp',
        channel_source: 'online'
      }

      if (social_id) {
        newCustomer.social_id = social_id
        newCustomer.external_id = `social:${social_id}`
      }

      dispatch(
        createCustomer({ body: newCustomer, onSuccess: updateCustomerSession })
      )
    },
    [dispatch, updateCustomerSession]
  )

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
        <FormGroup>
          <Label isRequired>Telefone</Label>
          <InputPhone
            id="main_phone"
            name="main_phone"
            placeholder="Insira o telefone do cliente"
            type="tel"
            disabled
            required
          />
        </FormGroup>

        <FormGroup>
          <Label isRequired>Nome</Label>
          <Input name="name" required />
        </FormGroup>

        <FormGroup>
          <Label isRequired>CPF / CNPJ</Label>
          <InputCpfCnpj
            id="social_id"
            name="social_id"
            placeholder="Insira o CPF/CNPJ do cliente"
            required
          />
        </FormGroup>

        <AddressFields
          formRef={formRef}
          labelItem="do cliente"
          hideCoordinates
          noPaddingBody
        />

        <FormGroup className="span2">
          <Checkbox
            name="loyalty_program"
            label="Cliente fidelidade"
            id="loyalty_program"
          />
        </FormGroup>

        <FormGroup className="span2">
          <Checkbox
            name="use_bot"
            label="Usar atendimento virtual (BOT) com esse cliente no chat."
            id="use_bot"
          />
        </FormGroup>

        <FormGroup>
          <Button
            text="Cadastrar"
            textLoading="Cadastrando..."
            type="submit"
            loading={saveCustomersLoading}
          />
        </FormGroup>
      </Form>
    </Container>
  )
}

export default CreateCustomer
