/* eslint-disable no-underscore-dangle */
import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo
} from 'react'
import { toastr } from 'react-redux-toastr'

import moment from 'moment'
import PropTypes from 'prop-types'

import { formatSearchString, history } from '~/helpers'
import { slotsService } from '~/services/logistic/slots'

import { WEEKDAYS } from './constants/weekdays'

const LogisticSlotsContext = createContext({})

const LogisticSlotsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  /**
   * States
   */
  // Mode define se estamos tratando slots semanais, exeções ou dias sem funcionamento
  // 'weekdays' | 'exceptions' | 'without-work'
  const [mode, setMode] = useState()
  const [locationID, setLocationID] = useState(null)
  const [regionID, setRegionID] = useState(null)
  const [days, setDays] = useState()
  const [selectedDays, setSelectedDays] = useState([])
  const [startHour, setStartHour] = useState({})
  const [endHour, setEndHour] = useState({})
  const [repeat, setRepeat] = useState(1)
  const [maxOrders, setMaxOrders] = useState(0)
  const [formSlotOpen, setFormSlotOpen] = useState(false)
  const [formDateOpen, setFormDateOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(null)
  const [error, setError] = useState(null)

  const formOpen = useMemo(
    () => formSlotOpen || !!selectedDays.length,
    [formSlotOpen, selectedDays.length]
  )

  const isWeekday = useMemo(() => mode === 'weekdays', [mode])
  const isWithoutWorkDates = useMemo(() => mode === 'without-work', [mode])
  const isExceptionsDates = useMemo(() => mode === 'exceptions', [mode])
  const templateBoard = useMemo(
    () => (isWeekday ? 'columns' : 'rows'),
    [isWeekday]
  )

  const isDateMode = useMemo(
    () => isWithoutWorkDates || isExceptionsDates,
    [isWithoutWorkDates, isExceptionsDates]
  )

  /**
   * Handles
   */

  /**
   * 00 Initial data
   */
  const queryString = useMemo(
    () =>
      formatSearchString({
        location_id: locationID !== 'all' ? locationID : null,
        region_id: regionID !== 'all' ? regionID : null
      }),
    [locationID, regionID]
  )

  /**
   *
   * Como os arrays não estão vindo com chave de identificação, essa função
   * serve para buscar os slots referentes ao dia.
   */
  const findWeeklySlots = ({ data, index }) => {
    const indexWeekDay = data.findIndex(item => item?.weekday === index + 1)

    return data[indexWeekDay]?.slots || []
  }

  const checkDaySlots = ({ data, index }) => {
    const indexWeekDay = data.findIndex(item => item?.weekday === index + 1)

    const {
      end_minute_in_day,
      repeat,
      repeat_minutes,
      repeat_minutes_lead,
      start_hour,
      start_minute,
      start_minute_in_day
    } = data[indexWeekDay]?.slots?.[0] || {}

    const thisDayHasnSlot =
      end_minute_in_day === -1 &&
      repeat === 0 &&
      repeat_minutes === 0 &&
      repeat_minutes_lead === 0 &&
      start_hour === 0 &&
      start_minute === 0 &&
      start_minute_in_day === 0

    return thisDayHasnSlot
  }

  const getInitialDataWeekly = useCallback(async () => {
    try {
      setLoading(true)
      const response = await slotsService.listWeekly(queryString)

      const weekdaysColumns = Array.from({
        length: 7
      }).map((item, index) => ({
        day: WEEKDAYS[index],
        slots: findWeeklySlots({ data: response.data.data, index }),
        dayWithoutWork: checkDaySlots({ data: response.data.data, index })
      }))

      setDays(weekdaysColumns)

      setLoading(false)
      setLoadingUpdate(false)
    } catch (err) {
      console.error(err)
    }
  }, [queryString])

  const getInitialDataExceptions = useCallback(async () => {
    try {
      setLoading(true)
      const response = await slotsService.listDates(queryString)

      const configs = response.data.data

      const modelException = item => ({
        day: {
          id: moment.utc(item.date || item.slots[0].date).format('YYYY-MM-DD'),
          label: moment
            .utc(item.date || item.slots[0].date)
            .format('DD/MM/YYYY')
        },
        slots: item.slots
      })

      const days =
        configs.length > 0 ? configs.map(item => modelException(item)) : null

      setDays(days)

      setLoading(false)
      setLoadingUpdate(false)
    } catch (err) {
      console.error(err)
    }
  }, [queryString])

  const getInitialDataDaysWithoutWork = useCallback(async () => {
    try {
      setLoading(true)
      const response = await slotsService.listDayWithoutWork(queryString)

      const configs = response.data.data

      const modelException = item => ({
        day: {
          id: moment.utc(item.date).format('YYYY-MM-DD'),
          label: moment.utc(item.date).format('DD/MM/YYYY')
        },
        description: item.description,
        isGlobal: !item.location_id,
        id: item._id
      })

      const days =
        configs.length > 0 ? configs.map(item => modelException(item)) : null

      setDays(days)

      setLoading(false)
      setLoadingUpdate(false)
    } catch (err) {
      console.error(err)
    }
  }, [queryString])

  const getInitialData = useCallback(() => {
    const methods = {
      weekdays: () => getInitialDataWeekly(),
      'without-work': () => getInitialDataDaysWithoutWork(),
      exceptions: () => getInitialDataExceptions(),
      error: () => {
        history.push(
          `/logistic/slots/weekdays${locationID ? `/${locationID}` : ''}${
            regionID ? `/${regionID}` : ''
          }`
        )
        toastr.error(
          'Erro ao carregar o painel',
          'O modo escolhido não existe. Redirecionamos para o inicial.'
        )
      }
    }

    const method = methods?.[mode] || methods.error

    method()
  }, [
    getInitialDataDaysWithoutWork,
    getInitialDataExceptions,
    getInitialDataWeekly,
    locationID,
    mode,
    regionID
  ])

  const clearNewSlot = () => {
    setSelectedDays([])
    setStartHour({})
    setEndHour({})
    setRepeat(1)
    setMaxOrders(0)
  }

  useEffect(() => {
    if (mode) {
      setLoading(true)
      setDays()
      setFormDateOpen(false)
      clearNewSlot()

      getInitialData()
    }
  }, [mode, getInitialData, locationID])

  const setBoardConfig = useCallback(
    ({ locationId = null, regionId = null, mode }) => {
      setLoading(true)
      setFormSlotOpen(false)
      setFormDateOpen(false)
      setMode(mode)
      setLocationID(locationId)
      setRegionID(regionId)
    },
    []
  )

  /**
   * 01
   */
  const handleSelectedDays = useCallback(
    selectedDay => {
      const alreadyDaySelected = selectedDays.some(item => item === selectedDay)

      if (alreadyDaySelected) {
        setSelectedDays(selectedDays.filter(item => item !== selectedDay))

        return
      }

      if (selectedDay === null) {
        setSelectedDays([])

        return
      }

      setSelectedDays([...selectedDays, selectedDay])
    },
    [selectedDays]
  )

  /**
   * 02 Manipulando horários de edição
   */

  const repeatMinutes = useMemo(() => {
    if (startHour.start_hour && endHour.start_hour) {
      const startHourDate = moment(
        `${startHour.start_hour}:${startHour.start_minute}`,
        'HH:mm'
      )

      const endHourDate = moment(
        `${endHour.start_hour}:${endHour.start_minute}`,
        'HH:mm'
      )

      const diff = endHourDate.diff(startHourDate, 'minutes')

      return diff
    }

    return 60
  }, [endHour, startHour])

  const handleChangeTimes = useCallback(e => {
    const { value, name } = e.target

    if (name === 'hour_start') {
      const splitValue = value.split(':')

      const formattedHour = {
        start_hour: splitValue[0],
        start_minute: splitValue[1],
        raw: value
      }

      setStartHour(formattedHour)
    }

    if (name === 'hour_end') {
      const splitValue = value.split(':')

      const formattedHour = {
        start_hour: splitValue[0],
        start_minute: splitValue[1],
        raw: value
      }

      setEndHour(formattedHour)
    }

    if (name === 'repeat') {
      setRepeat(Number(value))
    }

    if (name === 'max_orders') {
      setMaxOrders(Number(value))
    }
  }, [])

  const newSlot = useMemo(
    () => ({
      id: `${startHour.start_hour}${startHour.start_minute}`,
      start_hour: Number(startHour?.start_hour || 0),
      start_minute: Number(startHour?.start_minute || 0),
      repeat_minutes: repeatMinutes,
      isNew: true,
      repeat_minutes_lead: 0,
      repeat,
      max_orders: maxOrders,
      location_id: locationID,
      region_id: regionID
    }),
    [
      startHour?.start_hour,
      startHour?.start_minute,
      repeatMinutes,
      repeat,
      maxOrders,
      locationID,
      regionID
    ]
  )

  /**
   * 04 Handle dates
   */
  const handleFormDate = () => {
    setError(null)
    setFormDateOpen(oldState => !oldState)
  }

  const createDayWithoutWork = useCallback(
    async ({ date, description }) => {
      try {
        setLoadingUpdate(true)

        const requestData = {
          location_id: locationID,
          region_id: regionID,
          date: moment(date).format('YYYY-MM-DD'),
          description
        }

        await slotsService.createDayWithoutWork({ data: requestData })

        setFormDateOpen(false)

        getInitialData()
      } catch (err) {
        console.error(err)
        setLoadingUpdate(false)
        setError({
          key: 'createDate',
          message: 'Houve um erro ao cadastrar a data.'
        })
      }
    },
    [locationID, regionID, getInitialData]
  )

  const handleAddDates = useCallback(
    ({ new_date, description }) => {
      if (isWithoutWorkDates) {
        createDayWithoutWork({ date: new_date, description })

        return
      }

      const day = {
        id: moment(new_date).format('YYYY-MM-DD'),
        label: moment(new_date).format('DD/MM/YYYY')
      }

      const formattedDate = {
        day,
        slots: [],
        description
      }

      setDays(currentDays =>
        currentDays ? [...currentDays, formattedDate] : [formattedDate]
      )

      setFormDateOpen(false)

      !isWithoutWorkDates && handleSelectedDays(day.id)
    },
    [createDayWithoutWork, handleSelectedDays, isWithoutWorkDates]
  )

  /**
   * 05 - Submit slot
   */
  const createSlot = useCallback(async () => {
    try {
      setLoadingUpdate(true)

      const keyDate = isWeekday ? 'weekdays' : 'dates'

      const requestData = {
        ...newSlot,
        location_id: locationID,
        region_id: regionID,
        [keyDate]: selectedDays
      }

      await slotsService.createSlot({ data: requestData })

      clearNewSlot()

      getInitialData()
    } catch (err) {
      setLoadingUpdate(false)
      setError({
        key: 'createSlot',
        message:
          err.response?.data?.errors?.[0]?.message ||
          'Houve um erro ao criar o slot.'
      })
    }
  }, [isWeekday, newSlot, locationID, regionID, selectedDays, getInitialData])

  /**
   * 06 - Submit day without slot (local or global)
   */
  const handleWeekdayWithoutSlot = useCallback(
    async ({
      config = null,
      confirmed = false,
      callbackAction = null
    } = {}) => {
      if (!config) {
        callbackAction?.()
        return setDeleteModal(null)
      }

      const { day } = config

      if (!confirmed) {
        const modelModalDayWithoutWork = {
          key: 'weekdayWithoutWork',
          title: 'Desativando entregas',
          descriptionModal:
            'Tem certeza que deseja desativar as entregas nesse dia?',
          weekday: WEEKDAYS[day - 1].label,
          day,
          callbackAction
        }

        setDeleteModal(modelModalDayWithoutWork)
      }

      if (confirmed) {
        try {
          setLoadingUpdate(true)

          const requestData = {
            id: `${day}0000`,
            start_hour: 0,
            start_minute: 0,
            repeat_minutes: 0,
            isNew: true,
            repeat_minutes_lead: 0,
            repeat: 0,
            location_id: locationID,
            region_id: regionID,
            weekdays: [day]
          }

          await slotsService.createSlot({ data: requestData })

          getInitialData()
        } catch (err) {
          setError({
            key: 'createSlot',
            message:
              err.response?.data?.errors?.[0]?.message ||
              'Houve um erro ao criar o slot.'
          })
        } finally {
          setLoadingUpdate(false)
          setDeleteModal(null)
          callbackAction?.()
        }
      }
    },
    [getInitialData, locationID, regionID]
  )

  /**
   * 06 - Delete slot
   * @param { object } config Configuração do slot ou do dia
   * @param { boolean } data.confirmed Define se usuário confirmou a exclusão
   */
  const deleteSlot = useCallback(
    async ({
      config = null,
      confirmed = false,
      callbackAction = null
    } = {}) => {
      if (!config) {
        callbackAction?.()
        return setDeleteModal(null)
      }

      if (!confirmed && config?.dayWithoutWork) {
        const modelModalDetete = {
          key: 'delete',
          title: 'Reativando entregas',
          descriptionModal:
            'Tem certeza que deseja reativar as entregas neste dia?',
          ...config,
          weekday: WEEKDAYS[config.weekday - 1].label,
          callbackAction
        }

        setDeleteModal(modelModalDetete)

        return
      }

      if (!confirmed) {
        const modelModalDetete = {
          key: 'delete',
          title: 'Apagando configuração',
          descriptionModal:
            'Tem certeza que deseja apagar essa configuração de slot?',
          ...config,
          weekday: WEEKDAYS[config.weekday - 1].label,
          date: config.date
            ? moment.utc(config.date).format('DD/MM/YYYY')
            : null,
          repeat: config.repeat,
          startSlot: moment(
            `${config.start_hour}:${config.start_minute}`,
            'HH:mm'
          ).format('HH:mm'),
          callbackAction
        }

        setDeleteModal(modelModalDetete)

        return
      }

      if (confirmed) {
        try {
          setLoadingUpdate(true)

          await slotsService.deleteSlot(config._id)

          setDeleteModal(null)

          setLoadingUpdate(false)

          getInitialData()

          return
        } catch (err) {
          console.error(err)
        } finally {
          setLoadingUpdate(false)
          callbackAction?.()
        }
      }
    },
    [getInitialData]
  )

  /**
   * 06 - Delete day without work
   * @param { object } config Configuração do slot ou do dia
   * @param { boolean } confirmed Define se usuário confirmou a exclusão
   */
  const deleteDayWithouWork = useCallback(
    async ({ config = null, confirmed = false }) => {
      if (!config) {
        return setDeleteModal(null)
      }

      if (confirmed) {
        try {
          setLoadingUpdate(true)

          await slotsService.deleteDayWithoutWork(config.id)

          setDeleteModal(null)

          setLoadingUpdate(false)

          return getInitialData()
        } catch (err) {
          console.error(err)

          setLoadingUpdate(false)
        }
      }

      const modelModalDetete = {
        descriptionModal:
          'Tem certeza que deseja apagar essa data sem funcionamento?',
        ...config,
        date: moment.utc(config.date).format('DD/MM/YYYY')
      }

      setDeleteModal(modelModalDetete)
    },
    [getInitialData]
  )

  const deleteConfig = useCallback(
    async ({
      config = null,
      confirmed = false,
      dayOnly = false,
      callbackAction = null
    } = {}) => {
      if (!config) {
        callbackAction?.()
        return setDeleteModal(null)
      }

      const action = dayOnly ? deleteDayWithouWork : deleteSlot

      await action({ config, confirmed, callbackAction })
    },
    [deleteDayWithouWork, deleteSlot]
  )

  /**
   * Exports
   */
  return (
    <LogisticSlotsContext.Provider
      value={{
        loading,
        loadingUpdate,
        error,
        //
        templateBoard,
        //
        setBoardConfig,
        locationID,
        //
        mode,
        isWeekday,
        isWithoutWorkDates,
        isExceptionsDates,
        isDateMode,
        days,
        formOpen,
        setFormSlotOpen,
        formDateOpen,
        setFormDateOpen,
        handleFormDate,
        handleAddDates,
        handleWeekdayWithoutSlot,
        /**
         * Manipulando dias
         */
        selectedDays,
        handleSelectedDays,
        /**
         * Manipulando horários da edição
         */
        startHour,
        endHour,
        repeatMinutes,
        handleChangeTimes,
        /**
         * Manipulando criação de slot
         */
        newSlot,
        createSlot,
        deleteModal,
        deleteConfig,
        /**
         * Manipulando dias sem funcionamento
         */
        deleteDayWithouWork
      }}
    >
      {children}
    </LogisticSlotsContext.Provider>
  )
}

function useLogisticSlots() {
  const context = useContext(LogisticSlotsContext)

  if (!context) {
    throw new Error(
      'useLogisticSlots must be used within an LogisticSlotsProvider'
    )
  }

  return context
}

export { LogisticSlotsContext, LogisticSlotsProvider, useLogisticSlots }

LogisticSlotsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
