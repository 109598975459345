import React from 'react'

import { t } from 'i18next'

import Status from '~/components/Status'
import { Badge } from '~/components/UI/Badge'

const statusType = {
  pending_review: {
    label: t('rm:pending_review'),
    color: 'warning'
  },
  rejected: {
    label: t('rm:rejected'),
    color: 'danger'
  },
  running: {
    label: t('rm:running'),
    color: 'success'
  },
  enabled: {
    label: t('rm:ready'),
    color: 'success'
  },
  paused: {
    label: t('rm:paused'),
    color: 'warning'
  },
  invalid_cost: {
    label: t('rm:adsStatus.invalidCost'),
    color: 'warning'
  },
  stock_out: {
    label: t('rm:stockOut'),
    color: 'warning'
  }
}

type StatusAdsBadgeProps = {
  data: {
    isRunning: boolean
    status: RetailMediaAdsStatuses
    campaignStatus: RetailMediaCampaignStatuses
  }
}

const StatusAdsBadge: React.FC<StatusAdsBadgeProps> = ({ data }) => (
  <>
    <Status
      status={{
        label: statusType[data.status]?.label || data.status,
        type: statusType[data.status]?.color || 'default'
      }}
    />

    {data.isRunning && (
      <Badge
        template="success"
        style={{ marginTop: '.25rem' }}
        title={t('rm:CampaignStatus')}
      >
        {t('rm:running')}
      </Badge>
    )}

    {data.campaignStatus === 'paused' && (
      <Badge
        template="warning"
        style={{ marginTop: '.25rem' }}
        title={t('rm:CampaignStatus')}
      >
        {t('rm:paused', { context: 'female' })}
      </Badge>
    )}

    {data.campaignStatus === 'finished' && (
      <Badge
        template="info"
        style={{ marginTop: '.25rem' }}
        title={t('rm:CampaignStatus')}
      >
        {t('rm:finished')}
      </Badge>
    )}
  </>
)

export default StatusAdsBadge
