import React, { useCallback, useState } from 'react'

import PropTypes from 'prop-types'

import * as S from './styles'

const ToogleInput = ({
  label,
  name,
  onChange,
  size = 'medium',
  isChecked = false,
  isAsync = false,
  className = '',
  ...rest
}: ToogleInputProps) => {
  const [checked, setChecked] = useState(!!isChecked)
  const [loading, setLoading] = useState(false)

  const handleLoading = useCallback((value: boolean) => {
    setLoading(false)
    setChecked(value)
  }, [])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isAsync) {
        setChecked(e.target.checked)
      }

      if (isAsync) {
        setLoading(true)
      }

      onChange?.({ e, handleLoading })
    },
    [handleLoading, isAsync, onChange]
  )

  return (
    <S.Container className={className} isLoading={loading} size={size}>
      {label && <label htmlFor={name}>{label}</label>}

      <input
        id={name}
        name={name}
        type="checkbox"
        className="toggle"
        checked={checked}
        onChange={handleChange}
        {...rest}
      />
    </S.Container>
  )
}

export default ToogleInput

ToogleInput.defaultProps = {
  label: null,
  onChange: null,
  isChecked: false,
  isAsync: false,
  className: null
}

ToogleInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  isAsync: PropTypes.bool,
  className: PropTypes.string
}
