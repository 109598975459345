import { formatNumberWithK } from '~/helpers'
import i18n from '~/i18n'
import { primary as theme } from '~/styles/themes'

import { formatStylesChart, maxValue } from '../../helpers'
import { CreateDataset } from '../types'
import { OptionsScale } from './types'

const axisID = 'y-impressionsDiff'

const settings = {
  label: i18n.t('rm:metrics.impressionsDiff'),
  formatData: (value: number) => formatNumberWithK({ number: value }),
  color: theme.colors.info,
  dataKey: 'impressionsNumberDiff' as keyof ReportHistoryCampaigns,

  order: 10
}

const dataset: CreateDataset = ({ data }) => ({
  yAxisID: axisID,
  type: 'line',
  label: settings.label,
  data: data?.[settings.dataKey] || null,
  order: settings.order,

  ...formatStylesChart({ color: settings.color })
})

const optionsScale: OptionsScale = ({
  selectedMetrics,
  showScaleRuler = true,
  drawOnChartArea = true,
  position = 'left',
  data
}) => {
  const shouldApplyMax = selectedMetrics?.includes('views')

  return {
    [axisID]: {
      display: showScaleRuler,
      position,
      grid: {
        // If true, draw lines on the chart area inside the axis lines. This is useful
        // when there are multiple axes and you need to control which grid lines are drawn.
        drawOnChartArea
      },
      beginAtZero: true,
      suggestedMin: 0,
      suggestedMax: shouldApplyMax ? maxValue({ data })?.metrics : null,
      ticks: {
        z: 30,
        color: settings.color,
        callback: settings.formatData
      }
    }
  }
}

const impressionsDiffPreset = { axisID, dataset, optionsScale, settings }

export default impressionsDiffPreset
