import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'

import AlertInfo from '~/components/AlertInfo'
import Loading from '~/components/Loading'
import { getPublisherConfiguration } from '~/modules/retailMedia/store/industry/publisherConfiguration/actions'
import { useAppSelector } from '~/store/hooks'

import AlertBrandsPureComponent from './PureComponent'

// Poderíamos ter pego esse valor pelo Redux. Achei melhor manter por props
// para facilitar o uso no create que não temos dados ainda e em caso de optarmos
// usar em listas de vários publishers.

const AlertBrands: React.FC<AlertBrandsProps> = ({ publisherId }) => {
  const {
    industry: {
      publisherConfiguration: {
        list: { data, loading, error }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!data && !!publisherId) {
      dispatch(getPublisherConfiguration(publisherId))
    }
  }, [data, dispatch, publisherId])

  const publisherBrands = useMemo(() => data?.configuration?.brands, [data])
  const showAlert = useMemo(
    () => !!publisherBrands?.length && publisherBrands.length > 1,
    [publisherBrands]
  )

  return (
    <div className="alertBrands">
      {loading && (
        <Loading status={loading}>{t('rm:publisher.brands.loading')}</Loading>
      )}

      {error && (
        <AlertInfo
          template="danger"
          text={t('rm:publisher.brands.loading.error')}
        />
      )}

      {showAlert && (
        <AlertBrandsPureComponent publisherBrands={publisherBrands} />
      )}
    </div>
  )
}

export default AlertBrands
