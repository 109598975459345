import { FC, useState } from 'react'

import Modal from '~/components/Modal'

/**
 * Gerencia o componente de modal
 *
 * @exports useModal;
 * @exports useModal.Modal - Element - Componente do painel <Modal />
 * @exports useModal.isShowing - boolean - Informa se o painel está ativo ou não
 * @exports useModal.toggle - function - Seta se o painel está ativo ou não
 * @exports useModal.setIsShowing - function - Seta se o painel está ativo ou não
 *
 * @example
 *
 *  const [Modal, isShowing, toggle, setIsShowing] = useModal();
 *
 *  <Button onClick={setIsShowing} />
 *
 *   <Modal
 *      title="Título da modal"
 *      isOpen={showModal}
 *      onClose={toggleModal}
 *    >
 *      Conteúdo da modal
 *    </Modal>
 *
 */

type UseModal = (
  initialState?: boolean
) => [
  FC<ModalProps>,
  boolean,
  () => void,
  React.Dispatch<React.SetStateAction<boolean>>
]

const useModal: UseModal = (initialValue = false) => {
  const [isShowing, setIsShowing] = useState(initialValue)

  const toggle = () => {
    setIsShowing(oldState => !oldState)
  }

  return [Modal, isShowing, toggle, setIsShowing]
}

export default useModal
