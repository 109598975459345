const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '576px',
  tablet: '768px',
  laptop: '960px',
  laptopL: '1200px',
  desktop: '1600px',
  mobileM_max: '374.9px',
  mobileL_max: '575.9px',
  tablet_max: '767.9px',
  laptop_max: '991.9px',
  laptopL_max: '1199.9px',
  desktop_max: '1599.9px'
} as const

const device = {
  mobileS: `screen and (min-width: ${size.mobileS})`,
  mobileM: `screen and (min-width: ${size.mobileM})`,
  mobileL: `screen and (min-width: ${size.mobileL})`,
  tablet: `screen and (min-width: ${size.tablet})`,
  laptop: `screen and (min-width: ${size.laptop})`,
  laptopL: `screen and (min-width: ${size.laptopL})`,
  desktop: `screen and (min-width: ${size.desktop})`,
  desktopL: `screen and (min-width: ${size.desktop})`,

  mobileS_max: `screen and (max-width: ${size.mobileS})`,
  mobileM_max: `screen and (max-width: ${size.mobileM_max})`,
  mobileL_max: `screen and (max-width: ${size.mobileL_max})`,
  tablet_max: `screen and (max-width: ${size.tablet_max})`,
  laptop_max: `screen and (max-width: ${size.laptop_max})`,
  laptopL_max: `screen and (max-width: ${size.laptopL_max})`,
  desktop_max: `screen and (max-width: ${size.desktop_max})`
} as const

export { device }
/**
 *
 * Device Category	    BP Width	  Device Name
 * Mobile, portrait     320px	      iPhone SE
 *                      375px	      iPhone 6 to X
 *                      414px	      iPhone 8 Plus
 *
 * Mobile, landscape	  568px	      iPhone SE
 *                      667px	      iPhone 6 to 8
 *                      736px	      iPhone 8 Plus
 *                      812px	      iPhone X
 *
 * Tablet, portrait	    768px	      iPad Air, iPad Mini, iPad Pro 9″
 *                      834px	      iPad Pro 10″
 *
 * Tablet, landscape	  1024px      iPad Air, iPad Mini, iPad Pro 9″
 *                      1024px      iPad Pro 12″ (portrait)
 *                      1112px      iPad Pro 10″
 *
 * Laptop displays	    1366px      HD laptops (768p)
 *                      1366px      iPad Pro 12″ (landscape)
 *                      1440px      13″ MacBook Pro (2x scaling)
 *
 * Desktop displays	    1680px      13″ MacBook Pro (1.5x scaling)
 *                      1920px      1080p displays
 *
 *
 */
