import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ExternalDashboard from '~/components/ExternalDashboard';
import HeaderPages from '~/components/HeaderPages';
import Loading from '~/components/Loading';
import PageTitle from '~/components/PageTitle';
import { useAlert } from '~/hooks/useAlert';
import CampaignNav from '~/modules/messagingCampaigns/components/CampaignNav';
import campaignActions from '~/modules/messagingCampaigns/store/campaign/actions';
import templateActions from '~/modules/messagingCampaigns/store/template/actions';

import HeaderButtons from '../_elements/HeaderButtons';
import CampaignView from './components/CampaignView';

const CampaignsDashboard = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const {
    campaign: {
      item: { data, loading, error },
    },
    template: {
      item: { data: templateData },
    },
  } = useSelector(state => state.messagingCampaigns);

  const [AlertOnError] = useAlert(error);

  const loadCampaign = useCallback(() => {
    dispatch(campaignActions.get(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (data?.template_id) {
      dispatch(templateActions.get(data?.template_id));
    }
  }, [data?.template_id, dispatch]);

  useEffect(() => {
    loadCampaign();
  }, [loadCampaign]);

  return (
    <>
      <PageTitle title={`Campanha - ${data?.name || 'Carregando...'}`} />

      <HeaderPages
        title={data?.name || 'Carregando...'}
        subtitle={
          <p>
            <strong>Status: </strong>
            {data?.active ? 'Ativa' : 'Desativada'}
          </p>
        }
        sideRightComponent={
          <HeaderButtons isEditPage={data?.enqueued_at !== null} />
        }
        backTo="/messaging-campaigns/list"
        labelBreadcrumb="Voltar às campanhas"
      />

      <AlertOnError />

      <CampaignNav campaignId={id} />

      <ExternalDashboard dashboardId={42} customFilters={{ campaign_id: id }} />
    </>
  );
};

export default CampaignsDashboard;
