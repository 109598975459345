/**
 * Define de um item é pesável
 *
 * @param {string} unitMeasurement
 * @returns {boolean}
 */
export const isWeight = unitMeasurement =>
  unitMeasurement === 'KG' || unitMeasurement === 'kg';

/**
 * Define um valor por unidade caso não seja recebido
 */
export const weightPerUnit = (unitMeasurement, weightPerUnit) => {
  /**
   * = = = = = = = = = = T E M P O R A R Y = = = = = = = = = =
   */
  if (isWeight(unitMeasurement)) {
    return weightPerUnit || 0.5;
  }
  /**
   * = = = = = = = = = =  T E M P O R A R Y = = = = = = = = = =
   */

  return null;
};
