import * as types from './constants';

import {
  listOperatorsService,
  showOperatorService,
  createOperatorService,
  updateOperatorService,
  deleteOperatorService,
} from '~/services/operator-service';

import { history } from '~/helpers';

/**
 *
 * @exports indexLocation  Busca todos os operadores da empresa ou loja
 *
 * @exports showLocation Busca as informações de um operador
 * @param {string} id ID do operador
 *
 * @exports createLocation Criação de operador
 * @param {object} data  Dados para criação do operador
 *
 * @exports updateLocation Atualiza um operador
 * @param {object} data Dados para atualizar um operador
 *
 * @exports deleteLocation Deletar um operador
 * @param {string} id Id do operador que será deletado
 *
 */

export const listOperator = queryString => {
  return {
    type: types.OPERATOR_LIST,
    payload: listOperatorsService(queryString),
  };
};

export const showOperator = id => ({
  type: types.OPERATOR_GET_INFO,
  payload: showOperatorService(id),
  onError: () => {
    history.push(`/operators/list`);
  },
  getErrorFeedbackMsg: error => error.response.data.error,
});

export const createOperator = data => ({
  type: types.OPERATOR_SUBMIT_FORM,
  payload: createOperatorService(data),
  successFeedbackMsg: 'Operador adicionado com sucesso!',
  onSuccess: () => {
    history.push(`/operators/list`);
  },
  getErrorFeedbackMsg: error => error.response.data.error,
});

export const updateOperator = data => ({
  type: types.OPERATOR_UPDATE_INFO,
  payload: updateOperatorService(data),
  onSuccess: () => {
    history.push(`/operators/list`);
  },
  successFeedbackMsg: 'Operador atualizado com sucesso!',
  getErrorFeedbackMsg: error => error.response.data.error,
});

export const deleteOperator = id => ({
  type: types.OPERATOR_DELETE,
  payload: deleteOperatorService(id),
  successFeedbackMsg: 'Operador removido com sucesso!',
  onSuccess: () => {
    history.push(`/operators/list`);
  },
});
