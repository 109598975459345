import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import { formatSearchString } from '~/helpers'
import { handleSortDirection } from '~/helpers/sortData'

import useFetchSWR from '../useFetchSWR'

const SWRSortContext = createContext<SWRSortContextData>(
  {} as SWRSortContextData
)

const SWRSortProvider = ({
  extraParams = {},
  refreshInterval,
  url,
  sortDirectionDefault,
  sortKeyDefault,
  children
}: SWRSortProviderProps) => {
  const [sortDirection, setSortDirection] =
    useState<SortDirection>(sortDirectionDefault)
  const [sortKey, setSortKey] = useState<string>(sortKeyDefault)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  const [data, setData] = useState<unknown>()
  const [loading, setLoading] = useState(true)

  const {
    data: dataSWR,
    error,
    isValidating
  } = useFetchSWR({
    url: `${url}?${formatSearchString({
      order_by: sortKey,
      order_direction: sortDirection,
      page,
      quantity: perPage,
      ...extraParams
    })}`,

    refreshInterval,
    revalidateOnFocus: false
  })

  useEffect(() => {
    if (dataSWR || error) {
      setLoading(false)
    }
    if (error) {
      setData(undefined)
    }
  }, [dataSWR, error])

  useEffect(() => {
    if (dataSWR) {
      setData(dataSWR)
    }
  }, [dataSWR])

  const handlePagination = page => {
    setLoading(true)
    setPage(page)
  }

  const handleItemsPerPage = ({ value }) => {
    setLoading(true)
    setPerPage(value)
  }

  /**
   * Handle sort
   */
  const handleSortList = useCallback(
    (data: OnSortProps) => {
      setLoading(true)

      const selectedKey = data.key
      const sortInitialDirection = data.sortInitialDirection

      const currentSortDirection = sortDirection || sortDirectionDefault
      const currentSortKey = sortKey || sortKeyDefault

      const { sortDirection: newSortDirection, sortKey: newSortKey } =
        handleSortDirection({
          selectedKey,
          currentSortDirection,
          currentSortKey,
          sortInitialDirection
        })

      setSortDirection(newSortDirection)
      setSortKey(newSortKey)
    },
    [sortDirection, sortDirectionDefault, sortKey, sortKeyDefault]
  )

  return (
    <SWRSortContext.Provider
      value={{
        data: data?.data,
        loading,
        error,
        page,
        pages: data?.pages,
        perPage,
        total: data?.total,
        sortDirection,
        sortKey,
        isValidating,
        handleSortList,
        handlePagination,
        handleItemsPerPage
      }}
    >
      {children}
    </SWRSortContext.Provider>
  )
}

function useSWRSort<ResponseType>(): SWRSortContextData<ResponseType> {
  const context = useContext(SWRSortContext)

  if (!context) {
    throw new Error('useSWRSort must be used within an provider')
  }

  return context
}

export { SWRSortContext, SWRSortProvider, useSWRSort }
