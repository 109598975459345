import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    flex: 1;
    overflow-y: auto;

    /* Space to scrollbar */
    padding: 0 1rem 1rem;

    /* width */
    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.colors.primaryLighten60};
      border-radius: 0.5rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.secondaryLighten10};
      transition: 0.3s ease-in-out all;
      border-radius: 0.5rem;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.secondary};
    }
  `}
`;

const regionModifiers = {
  isActive: theme => css`
    background-color: ${theme.colors.light};
  `,
  hover: theme => css`
    background-color: ${theme.colors.inative};
  `,
};

export const Region = styled.button`
  ${({ theme, isActive }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    text-align: left;

    padding: ${theme.spacings.medium} 0;
    transition: 0.3s ease-in-out all;

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.tertiary};
    }

    .name {
      flex: 1;
      line-height: 1;
      padding: 0 ${theme.spacings.xxsmall};

      small {
        color: ${theme.colors.gray};
      }
    }

    &:hover {
      ${regionModifiers.hover(theme)}
    }

    ${isActive && regionModifiers.isActive(theme)}
  `}
`;
