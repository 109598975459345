import { useTranslation } from 'react-i18next'

import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import AdvertiserLinksTable from './components/AdvertiserLinksTable'

const AdvertisersConfig: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageTitle title={t('rm:config.advertisers.pageTitle')} />

      <HeaderPages title={t('rm:config.advertisers.pageTitle')} />

      <AdvertiserLinksTable />
    </>
  )
}

export default AdvertisersConfig
