import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const WrapperShadow = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  box-shadow: 0px 4px 20px rgba(199, 199, 199, 0.25);
  background-color: white;
  margin-bottom: 1rem;
  @media ${device.tablet} {
    margin-bottom: 24px;
  }
  border-radius: 4px;

  ${({ template, theme }) => css`
    ${template === 'warning' &&
    css`
      border-left: 3px solid ${theme.colors.warning};
    `}
  `}
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  flex-flow: wrap;

  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;

    span,
    a {
      display: flex;
      align-items: center;
      line-height: 1;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .wrapperIcon {
        margin-right: 0.5rem;
      }

      strong {
        margin-right: 0.25rem;
      }
    }

    &.inlineFeatures {
      flex-direction: row;
      flex-flow: wrap;
      justify-content: center;

      @media ${device.tablet} {
        justify-content: flex-start;
      }

      .box {
        margin: 0.25rem 1rem 0.25rem 0;
        white-space: nowrap;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1.25;
        max-width: 100%;

        &.equalPadding {
          padding: 0 0.25rem;
        }

        .icon {
          @media ${device.tablet_max} {
            &.hideMobile {
              display: none;
            }
          }

          display: flex;
          align-items: center;
          justify-content: center;

          margin-right: 0.5rem;

          border-radius: 0.25rem;
          height: 2rem;
          width: 2rem;
          min-width: 2rem;

          background-color: var(--light);
          @media ${device.tablet_max} {
            background-color: var(--primary);
          }

          svg {
            color: var(--primary);

            @media ${device.tablet_max} {
              color: var(--white);
              font-size: 1.125rem;
            }

            @media ${device.tablet} {
              font-size: 1rem;
            }
          }
        }

        .text {
          line-height: 1.1;
          max-width: 100%;

          @media ${device.tablet_max} {
            &.withIcon {
              max-width: calc(100% - 3rem);
            }
          }

          @media ${device.tablet} {
            display: flex;
            flex-direction: column;
          }

          strong {
            font-weight: 600;

            @media ${device.tablet} {
              font-size: 0.75rem;
              color: var(--dark);
            }
          }
        }
      }
    }
  }

  > aside {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    @media ${device.tablet_max} {
      * {
        font-size: 0.875rem;
      }
    }

    .tags {
      .danger {
        .icon {
          color: var(--danger);
        }
      }
      .success {
        .icon {
          color: var(--success);
        }
      }
    }

    .column {
      & + .column {
        margin-left: 0.5rem;
      }
    }

    @media ${device.tablet} {
      justify-content: flex-end;
      margin-top: 0;
    }

    .selectSearchableWrapper {
      min-width: 280px;
      display: flex;

      > div {
        flex: 1;
      }

      .SelectSearchable__placeholder {
        line-height: 1;
      }

      @media ${device.tablet_max} {
        div {
          order: -1;
        }

        button {
        }
      }
    }

    > .infoBox {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: var(--info);

      &.payment_partial_refunded {
        background: var(--info);
      }

      &.payment_refunded,
      &.payment_refused {
        background: var(--danger);
      }
      &.payment_approved,
      &.payment_authorized {
        background: var(--success);
      }
      &.waiting_payment,
      &.waiting_refund,
      &.payment_in_analysis {
        background: var(--warning);
      }

      color: white;
      border-radius: 0.25rem;
      padding: 0.5rem 0.75rem;

      min-width: 120px;

      white-space: nowrap;

      & + * {
        margin-left: 0.5rem;
      }
    }

    @media ${device.tablet_max} {
      &.featured {
        padding: 0;
        flex-basis: 100%;
        margin-top: 0.5rem;
        justify-content: center;
      }
    }

    > button + button {
      margin-left: 0.5rem;
    }
  }

  & + div {
    border-top: 1px solid ${props => props.theme.tertiary};
  }
`

export const Content = styled.div`
  padding: 16px 24px;

  &.noPaddingX {
    padding: 16px 0;

    @media ${device.tablet} {
      padding: 16px 24px;
    }
  }

  &.scrollX {
    @media ${device.tablet_max} {
      overflow: scroll;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  p {
    white-space: pre-line;
  }

  & + div {
    border-top: 1px solid ${props => props.theme.tertiary};
  }

  &.description {
    white-space: pre-line;

    p {
      margin: 0;

      + p {
        margin-top: 0.5rem;
      }
    }
  }

  &.extras {
    .subtitle {
      font-size: 1rem;
      display: flex;
      align-items: center;

      .wrapperIcon,
      svg {
        font-size: 0.875rem;
        margin-right: 0.25rem;
      }
    }

    .valuesWrapper {
      .value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${props => props.theme.spacings.xxsmall}
          ${props => props.theme.spacings.xsmall};
        margin-bottom: 0.5rem;

        background-color: ${props => props.theme.colors.light};

        > .inlineBagde {
          margin-right: 0.25rem;
        }

        &.isIndicator {
          --borderColor: ${props => props.theme.colors.light};

          &.success {
            --borderColor: ${props => props.theme.colors.success};
            background-color: ${props => props.theme.colors.successLighten45};
          }
          &.warning {
            --borderColor: ${props => props.theme.colors.warning};
            background-color: ${props => props.theme.colors.warningLighten50};
          }
          &.danger {
            --borderColor: ${props => props.theme.colors.danger};
            background-color: ${props => props.theme.colors.dangerLighten40};
          }

          &.info {
            --borderColor: ${props => props.theme.colors.info};
            background-color: ${props => props.theme.colors.infoLighten50};
          }
          border-left: 4px solid var(--borderColor);
        }
      }
    }

    .giftMessage {
      blockquote {
        padding: 0.25rem;
        background-color: ${props => props.theme.colors.secondaryLighten30};

        font-size: ${props => props.theme.font.sizes.xsmall};
        padding: ${props => props.theme.spacings.xsmall};
        border-left: ${props => props.theme.colors.secondary} 2px solid;
        border-radius: 0 0.4rem 0.4rem 0;
        line-height: 1.25;
        white-space: pre-line;
      }
    }
  }

  .payment__status {
    padding: 0;
    margin: 0;

    li {
      padding: 0.25rem 0.5rem;
      border-left: 0.5rem solid;
      list-style: none;
      margin: 0.125rem 0;
      border-radius: 0.25rem;
      margin: 0.25rem 0;
      background: var(--light);

      &.payment_partial_refunded {
        border-color: var(--info);
      }

      &.payment_refunded,
      &.payment_refused {
        border-color: var(--danger);
      }

      &.payment_approved,
      &.payment_authorized {
        border-color: var(--success);
      }

      &.waiting_payment,
      &.waiting_refund,
      &.payment_in_analysis {
        border-color: var(--warning);
      }
    }
  }
`

export const Footer = styled.footer`
  display: inline-flex;

  margin-top: auto;
  padding: 0 24px 24px;

  > * + * {
    margin-left: 1rem;
  }
`
