import React, { useCallback } from 'react'
import { FiPauseCircle } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { Spinner } from '~/components/Spinner'
import useConfirmationModal from '~/hooks/useConfirmationModal'

import usePromotion from '../../hooks/usePromotion'

const PauseButton = ({ promotion, keepsMenuOpen, menuItem = false }) => {
  const [ConfirmationhModal, showConfirmationModal, toggleConfirmationModal] =
    useConfirmationModal()

  const { handlePausePromotion } = usePromotion()

  const { loadingSaving: loading } = useSelector(state => state.promotional)

  const handleToogle = useCallback(() => {
    keepsMenuOpen && keepsMenuOpen(oldState => !oldState)

    toggleConfirmationModal()
  }, [keepsMenuOpen, toggleConfirmationModal])

  const handleSubmit = useCallback(() => {
    const data = {
      promotion,
      onSuccess: handleToogle
    }

    handlePausePromotion(data)
  }, [handlePausePromotion, promotion, handleToogle])

  return (
    <>
      {menuItem ? (
        <button type="button" onClick={handleToogle} className="warning">
          {loading ? <Spinner /> : <FiPauseCircle />} <span>Pausar</span>
        </button>
      ) : (
        <Button
          text="Pausar"
          type="button"
          template="warningOutlined"
          size="small"
          iconLeft={loading ? <Spinner /> : <FiPauseCircle />}
          customWidth="auto"
          onClick={handleToogle}
        />
      )}

      {/* Modal Pausar */}
      <ConfirmationhModal
        title="Deseja pausar a promoção?"
        isOpen={showConfirmationModal}
        onClose={handleToogle}
        onSubmit={handleSubmit}
        confirmationButton="Entendo e desejo pausar esta promoção"
      >
        <p>
          A promoção não sera finalizada e você poderá reativá-la a qualquer
          monento até a data de término.
        </p>
        {loading && <Loading status={loading}>Pausando promoção...</Loading>}
      </ConfirmationhModal>
    </>
  )
}

export default PauseButton

PauseButton.propTypes = {
  promotion: PropTypes.shape({}).isRequired,
  keepsMenuOpen: PropTypes.func,
  menuItem: PropTypes.bool
}

PauseButton.defaultProps = {
  menuItem: false,
  keepsMenuOpen: null
}
