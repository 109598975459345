import { produce } from 'immer';

import * as types from './constants';

const INITIAL_STATE = {
  listProducts: null,
  listProductsPage: 1,
  listProductsLoading: false,
  listProductsPerpage: 50,
  product: [],
  loading: false,
  loadingDelete: false,
  error: null,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.PRODUCTS_LIST}_PENDING`: {
        draft.listProductsLoading = true;
        break;
      }
      case `${types.PRODUCTS_LIST}_FULFILLED`: {
        draft.listProducts = action.payload;
        draft.listProductsLoading = false;
        draft.listProductsPage = action.page;
        break;
      }
      case `${types.PRODUCTS_LIST}_REJECTED`: {
        draft.listProductsLoading = false;
        draft.error = action.payload;
        break;
      }

      // Search offers

      case `${types.SEARCH_OFFERS}_PENDING`: {
        draft.listProductsLoading = true;
        break;
      }
      case `${types.SEARCH_OFFERS}_FULFILLED`: {
        draft.listProducts = action.payload;
        draft.listProductsLoading = false;
        draft.listProductsPage = action.page;
        break;
      }
      case `${types.SEARCH_OFFERS}_REJECTED`: {
        draft.listProductsLoading = false;
        draft.error = action.payload;
        break;
      }

      // Create product

      case `${types.PRODUCT_SUBMIT_FORM}_PENDING`: {
        draft.loading = true;
        break;
      }
      case `${types.PRODUCT_SUBMIT_FORM}_FULFILLED`: {
        draft.loading = false;
        draft.error = null;
        break;
      }
      case `${types.PRODUCT_SUBMIT_FORM}_REJECTED`: {
        draft.error = action.payload;
        draft.loading = false;
        break;
      }

      // Show product

      case `${types.PRODUCT_GET_INFO}_PENDING`: {
        draft.loading = true;
        break;
      }
      case `${types.PRODUCT_GET_INFO}_FULFILLED`: {
        draft.product = action.payload;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case `${types.PRODUCT_GET_INFO}_REJECTED`: {
        draft.error = action.payload;
        draft.loading = false;
        break;
      }

      // Set products per page
      case types.PRODUCTS_PER_PAGE_CHANGED: {
        draft.listProductsPerpage = action.payload;
        break;
      }

      // Delete product

      case `${types.PRODUCT_DELETE}_PENDING`: {
        draft.loadingDelete = true;
        break;
      }
      case `${types.PRODUCT_DELETE}_FULFILLED`: {
        draft.loadingDelete = false;
        draft.error = null;
        break;
      }
      case `${types.PRODUCT_DELETE}_REJECTED`: {
        draft.error = action.payload;
        draft.loadingDelete = false;
        break;
      }

      default:
        return state;
    }
  });
