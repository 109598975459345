import axios from 'axios'

import { templateAPI } from './'

const list = () => axios.get(`${templateAPI}`)

const show = id => axios.get(`${templateAPI}/${id}`)

const create = data => axios.post(`${templateAPI}`, data)

const update = data => axios.put(`${templateAPI}`, data)

const deleteItem = id => axios.delete(`${templateAPI}/${id}`)

export const templateService = {
  list,
  show,
  create,
  update,
  delete: deleteItem
}
