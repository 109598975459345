import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { FiX } from 'react-icons/fi'
import { toastr } from 'react-redux-toastr'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import ThumbProduct from '~/components/ThumbProduct'
import { getImageDimensions, getVideoDimensions } from '~/helpers'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import Image from '~/modules/retailMedia/components/BannersTable/TumbProduct'
import { campaign as service } from '~/modules/retailMedia/services/industry'

import * as S from './styles'

type FileToUploadProps = {
  data: { file: File; id: string }
  onSave(data: ItemBannerTable): void
  onDelete(id: ItemBannerTable): void
  getPlacementByDimension(data: Dimension): SizeBySegmentation
}

const FileToUpload: React.FC<FileToUploadProps> = ({
  data: { file: data, id },
  getPlacementByDimension,
  onDelete,
  onSave
}) => {
  const { t, tCapitalize } = useAPPTranslation()

  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [dimensions, setDimensions] = useState<VideoProps | Dimension>()

  const previewURL = useMemo(() => URL.createObjectURL(data), [data])
  const mediaType = useMemo(() => data?.type, [data])
  const isVideo = useMemo(() => mediaType === 'video/mp4', [mediaType])

  const getDimensions = useCallback(async () => {
    const action =
      mediaType === 'video/mp4' ? getVideoDimensions : getImageDimensions

    const imageDimensions = await action(previewURL)

    setDimensions(imageDimensions)
  }, [mediaType, previewURL])

  useLayoutEffect(() => {
    getDimensions()
  }, [getDimensions])

  const placement = useMemo(() => {
    if (!dimensions) {
      return null
    }

    const response = getPlacementByDimension(dimensions)

    return response
      ? { ...response, adSize: response.name, name: response.size }
      : null
  }, [getPlacementByDimension, dimensions])

  /**
   * Handle upload
   */

  const handleUpload = useCallback(async () => {
    const dataMultipart = new FormData()
    dataMultipart.append('file', data)

    Object.keys(data).forEach(key => dataMultipart.append(key, data[key]))

    setUploading(true)

    const options = {
      onUploadProgress: progressEvent => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )

        setUploadProgress(progress)

        console.log(t('rm:progressUploadMedia', { progress }))
      }
    }

    try {
      const action = isVideo ? service.uploadVideo : service.upload

      const response = await action({
        body: dataMultipart,
        axiosOptions: options
      })

      const formatBodyVideo: () => ItemMediaTable = () => ({
        id,
        media_url: response.data.url,
        fileMedia: data,
        ad_size_name: placement.name,
        ad_size: placement.adSize,
        dimensions: {
          height: placement.height,
          width: placement.width,
          duration: Math.floor(placement?.duration)
        }
      })

      const formatBodyImage: () => ItemBannerTable = () => ({
        id,
        media_url: response.data.url,
        fileMedia: data,
        ad_size_name: placement.name,
        ad_size: placement.adSize,
        dimensions: {
          height: placement.height,
          width: placement.width
        }
      })

      const bodyRequest = isVideo ? formatBodyVideo() : formatBodyImage()

      onSave(bodyRequest)
    } catch (error) {
      console.error(error)
      toastr.error(
        t('rm:campaign.create.FileToUpload.error.title'),
        t('rm:campaign.create.FileToUpload.error.message')
      )
    }
  }, [data, isVideo, onSave, id, placement, t])

  useEffect(() => {
    if (placement && !uploading) handleUpload()
  }, [handleUpload, placement, uploading])

  return (
    <S.Container isValid={!!placement}>
      {isVideo ? (
        <video style={{ width: '120px', marginRight: '.5rem' }} autoPlay muted>
          <source src={previewURL} type="video/mp4" />
        </video>
      ) : (
        <Image url={previewURL}>
          <ThumbProduct url={previewURL} />
        </Image>
      )}

      <div className="info">
        <strong>
          {tCapitalize('rm:size')}:{' '}
          <code>
            {placement?.name ||
              t('rm:campaign.create.FileToUpload.anyCompatible')}
          </code>
        </strong>

        <small className="title">{data.name}</small>

        <small className="dimensions">
          {t('rm:campaign.create.FileToUpload.dimensions')}: {dimensions?.width}
          px x {dimensions?.height}px
        </small>

        {dimensions?.duration && (
          <small className="duration">
            {t('rm:campaign.create.FileToUpload.duration')}:{' '}
            {t('c:words.secondCount', {
              count: Math.floor(dimensions.duration)
            })}
          </small>
        )}
      </div>

      <div className="d-flex align-items-center action">
        <Loading status={uploading}>
          {t('rm:campaign.create.FileToUpload.uploadingMedia')} {uploadProgress}
          %
        </Loading>

        {!placement && (
          <Button
            template="danger"
            text={t('c:actions.remove')}
            onClick={onDelete}
            iconLeft={<FiX />}
            customWidth="auto"
            size="small"
          />
        )}
      </div>
    </S.Container>
  )
}

export default FileToUpload
