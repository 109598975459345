import React from 'react'

import { DatepickerHeader } from '~/components/datepicker-header'
import ExternalDashboard from '~/components/ExternalDashboard'
import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import * as S from './styles'

const OrdersDashboard = () => {
  return (
    <S.Container>
      <PageTitle title="Relatórios de pedidos" />

      <HeaderPages
        title="Relatórios de pedidos"
        sideRightComponent={<DatepickerHeader />}
        className="has-datepicker"
      />

      <Filters locations channel />

      <ExternalDashboard dashboardId={31} useDate />
    </S.Container>
  )
}

export default OrdersDashboard
