import { createPromotion } from './createPromotion'
import { deletePromotion } from './deletePromotion'
import { handleProducts } from './handleProducts'
import { listPromotions } from './listPromotions'
import { updatePromotion } from './updatePromotion'
import { viewPromotion } from './viewPromotion'
import { viewPromotionMetrics } from './viewPromotionMetrics'

export default {
  ...listPromotions,
  ...viewPromotion,
  ...handleProducts,
  ...createPromotion,
  ...updatePromotion,
  ...deletePromotion,
  ...viewPromotionMetrics
}
