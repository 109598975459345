import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import SelectSearchable from '~/components/SelectSearchable'
import { setShoppingCartStatus } from '~/store/modules/filters/actions'
import { primary as theme } from '~/styles/themes'

/**
 * Componente para filtrar por status do carrinho.
 *
 * Não recebe nenhum parâmetro e retorna um select procurável.
 *
 * @example <FilterShoppingCartStatus />
 */

export const statusOptions = [
  { label: 'Qualquer status', value: null },
  {
    value: 'in_review',
    label: 'Carrinho em revisão',
    color: theme.colors.warning
  },
  {
    value: 'opened',
    label: 'Carrinho sendo revisado',
    color: theme.colors.info
  },
  {
    value: 'approved',
    label: 'Carrinho aprovado',
    color: theme.colors.successLighten20
  },
  {
    value: 'link_sent',
    label: 'Link enviado',
    color: theme.colors.success
  },
  {
    value: 'finished',
    label: 'Carrinho finalizado',
    color: theme.colors.successShade20
  },
  {
    value: 'deleted',
    label: 'Carrinho cancelado',
    color: theme.colors.danger
  }
]

const FilterShoppingCartStatus = ({ initialData, ignoreRedux = false }) => {
  const dispatch = useDispatch()

  const { shoppingCartStatus: filteredShoppingCartStatus } = useSelector(
    state => state.filters
  )

  const handleChangeStatusBot = selectedShoppingCartStatus => {
    dispatch(
      setShoppingCartStatus({
        filteredShoppingCartStatus: selectedShoppingCartStatus
      })
    )
  }

  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10
    }
  })

  const removeOption = () => ({
    ':after': {
      content: '"x"',
      position: 'absolute',
      top: '50%',
      right: '1rem',
      transform: 'translateY(-50%)',
      fontSize: '.875rem',
      color: theme.colors.gray
    }
  })

  const customStyles = {
    valueContainer: base => ({
      ...base,
      overflow: 'hidden',
      flexWrap: 'nowrap'
    }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    menuList: styles => ({
      ...styles,
      display: 'flex',
      flexDirection: 'column'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const { color } = data
      return {
        ...styles,
        position: 'relative',
        backgroundColor: (() => {
          if (isFocused) {
            return theme.colors.infoLighten50
          }

          return isSelected
            ? theme.colors.secondaryLighten30
            : theme.colors.white
        })(),
        color: isDisabled ? theme.colors.inative : theme.dark,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: () => {
            if (isFocused) {
              return theme.colors.infoLighten50
            }
            if (!isDisabled) {
              return isSelected
                ? theme.colors.light
                : theme.colors.secondaryLighten30
            }
          }
        },
        ...dot(color),
        ...(isSelected ? removeOption() : {})
      }
    }
  }

  useEffect(() => {
    if (!ignoreRedux && initialData === null) {
      dispatch(
        setShoppingCartStatus({
          filteredShoppingCartStatus: null
        })
      )
    }

    if (!ignoreRedux && initialData) {
      const foundOptions = statusOptions.find(
        option => option.value === initialData
      )

      if (foundOptions) {
        const filteredShoppingCartStatus = {
          value: foundOptions.value,
          label: foundOptions.label,
          color: foundOptions.color
        }

        dispatch(
          setShoppingCartStatus({
            filteredShoppingCartStatus
          })
        )
      }
    }
  }, [dispatch, initialData, ignoreRedux])

  return (
    <SelectSearchable
      placeholder="Filtrar por status"
      options={statusOptions}
      onChange={handleChangeStatusBot}
      defaultValue={[filteredShoppingCartStatus]}
      styles={customStyles}
    />
  )
}

export default FilterShoppingCartStatus

FilterShoppingCartStatus.propTypes = {
  initialData: PropTypes.string,
  ignoreRedux: PropTypes.bool
}

FilterShoppingCartStatus.defaultProps = {
  initialData: null,
  ignoreRedux: false
}
