import styled, { css } from 'styled-components';

export const LoadingMaster = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;

    background-color: rgba(255, 255, 255, 0.75);

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
  `}
`;
