import { modelFlyersList } from '~/modules/flyers/models/flyers/list';
import { modelFlyersView } from '~/modules/flyers/models/flyers/view';

import * as types from '../constants';

const index = {
  pending: `${types.FLYERS_UPDATE}_PENDING`,
  fulfilled: `${types.FLYERS_UPDATE}_FULFILLED`,
  rejected: `${types.FLYERS_UPDATE}_REJECTED`,
};

export const updateFlyer = {
  [index.pending]: ({ draft }) => {
    draft.flyers.update.loading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    const updateFlyer = action.payload;
    const currentFlyer = draft.flyers.view.data;
    const currentFlyers = draft?.flyers.list?.data;
    const updateFlyers = currentFlyers?.map(item =>
      item.id === updateFlyer.id ? updateFlyer : item,
    );

    draft.flyers.update.loading = false;

    draft.flyers.view.data = currentFlyer ? modelFlyersView(updateFlyer) : null;

    draft.flyers.list.data = currentFlyers
      ? modelFlyersList(updateFlyers)
      : null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.flyers.update.loading = false;
    draft.flyers.update.error = action?.payload || true;
  },
};
