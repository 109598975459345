import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'

import Loading from '~/components/Loading'
import {
  getDashboardAnalyticsUrl,
  getDashboardCustomersUrl,
  getDashboardKpi,
  getDashboardStock,
  getDashboardGroceryAnalyticsUrl,
  getDashboardGroceryCustomersUrl,
  getDashboardAttendance
} from '~/services/metabase-service'

import Alert from '../Alert'

import * as S from './styles'

const service = {
  analytics: getDashboardAnalyticsUrl,
  customers: getDashboardCustomersUrl,
  analyticsGrocery: getDashboardGroceryAnalyticsUrl,
  customersGrocery: getDashboardGroceryCustomersUrl,
  attendance: getDashboardAttendance,
  kpi: getDashboardKpi,
  stock: getDashboardStock
}

/**
 *
 * @param {string} type Indica qual dashboard será exibido. A partir disso será definido qual service bateremos.
 * @returns
 */

const MetabaseDashboard = ({ type }) => {
  const [dashboardUrl, setDashboardUrl] = useState('')

  const {
    supermarkets: filteredSupermarkets,
    stores: filteredStores,
    channels: filteredChannels,
    brands: filteredBrands,
    categories: filteredCategories,
    products: filteredProducts,
    aggregate: filteredAggregate,
    subcategories: filteredSubcategories
  } = useSelector(state => state.filtersSellOut)

  const { startDate: start_date, endDate: end_date } = useSelector(
    state => state.datepickerHeader
  )

  const supermarkets = useMemo(
    () =>
      filteredSupermarkets ? filteredSupermarkets?.map(item => item.value) : [],
    [filteredSupermarkets]
  )
  const stores = useMemo(
    () => (filteredStores ? filteredStores?.map(item => item.value) : []),
    [filteredStores]
  )
  const channels = useMemo(
    () => (filteredChannels ? filteredChannels?.map(item => item.value) : []),

    [filteredChannels]
  )
  const brands = useMemo(
    () => (filteredBrands ? filteredBrands?.map(item => item.value) : []),

    [filteredBrands]
  )
  const categories = useMemo(
    () =>
      filteredCategories ? filteredCategories?.map(item => item.value) : [],

    [filteredCategories]
  )
  const subcategories = useMemo(
    () =>
      filteredSubcategories
        ? filteredSubcategories?.map(item => item.value)
        : [],
    [filteredSubcategories]
  )
  const skus = useMemo(
    () => (filteredProducts ? filteredProducts?.map(item => item.value) : []),

    [filteredProducts]
  )

  const aggregate = useMemo(
    () => (filteredAggregate?.value ? [filteredAggregate.value] : []),
    [filteredAggregate]
  )

  const queries = useMemo(() => {
    const filters = {
      accounts: supermarkets,
      stores,
      channels,
      brands,
      categories,
      skus,
      aggregate,
      subcategories,
      start: [moment(start_date).format('YYYY-MM-DD')],
      finish: [moment(end_date).format('YYYY-MM-DD')]
    }

    return filters
  }, [
    supermarkets,
    stores,
    channels,
    brands,
    categories,
    skus,
    aggregate,
    start_date,
    end_date,
    subcategories
  ])

  useEffect(() => {
    async function fetchDashboard() {
      try {
        const { data } = await service[type]({ queries })

        setDashboardUrl(data.frame_url)
      } catch (error) {
        console.warn('Houve um erro ao gerar o relatório', error)
      }
    }

    fetchDashboard()
  }, [queries, type])

  return (
    <S.Container>
      <Alert active={!service?.[type]}>
        Não foi fornecido um tipo de relatório válido.
      </Alert>

      {!!service?.[type] && (
        <>
          {!dashboardUrl ? (
            <Loading status>Carregando relatório...</Loading>
          ) : (
            <iframe src={dashboardUrl} title="relatorio" />
          )}
        </>
      )}
    </S.Container>
  )
}

export default MetabaseDashboard

MetabaseDashboard.propTypes = {
  type: PropTypes.string.isRequired
}
