/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';

import { addLogos } from '~/helpers';

import { Name } from './styles';

export const schema = {
  name: {
    render: obj => (
      <div>
        <Name className="item__id">
          <Link to={`/customers/edit/${obj.id}`} alt="Editar cliente">
            {obj.name}
          </Link>
        </Name>
        <div className="item__name">
          <Link to={`/customers/edit/${obj.id}`} alt="Editar cliente">
            Editar cliente
          </Link>
        </div>
      </div>
    ),
    title: 'Nome',
    className: 'order__details col__infos',
    showTotal: true,
  },
  loyalty_program: {
    render: obj => (obj.loyalty_program ? 'Sim' : 'Não'),
    title: 'Fidelidade',
  },
  origin: {
    render: obj => addLogos(obj.channel_source),
    title: 'Canal',
  },
  application_source: {
    render: obj => addLogos(obj.application_source),
    title: 'Aplicação de origem',
  },
};
