export const getVideoDimensions = (url: string) => {
  const video = document.createElement('video')

  const promise = new Promise<VideoProps>((resolve, reject) => {
    video.addEventListener(
      'loadedmetadata',
      function () {
        // retrieve dimensions

        const poster = this.poster
        const duration = this.duration
        const height = this.videoHeight
        const width = this.videoWidth

        // send back result
        resolve({ height, width, poster, duration })
      },
      false
    )

    // Reject promise on error
    video.onerror = reject
  })

  // Setting the source makes it start downloading and eventually call `onload`
  video.src = url

  return promise
}

// How to use in an async function
// (async() => {
//   const imageUrl = 'http://your.website.com/userUploadedImage.jpg';
//   const imageDimensions = await imageSize(imageUrl);

//   console.info(imageDimensions); // {width: 1337, height: 42}
// })();
