import React from 'react';
import PropTypes from 'prop-types';

import Panel from '~/components/Panel';
import Table from '~/components/Table';

const schema = {
  product: {
    render: obj => obj.productName,
    title: 'Produto',
  },
  name: {
    render: obj => obj.quantity,
    title: 'Nome',
  },
  sku: {
    render: obj => obj.sku,
    title: 'SKU',
  },
  quantity: {
    render: obj => obj.quantity,
    title: 'Quantidade',
  },
  sell: {
    render: obj => obj.sell,
    title: 'Venda',
  },
  margin: {
    render: obj => obj.margin,
    title: 'Margem',
  },
};

const BestSellersTable = ({ data = {}, loading }) => (
  <Panel noPaddingBody title="Mais vendidos" isLoading={loading}>
    <Table schema={schema} data={data.stockTableData} />
  </Panel>
);

export default BestSellersTable;

BestSellersTable.propTypes = {
  data: PropTypes.shape(),
  loading: PropTypes.bool,
};

BestSellersTable.defaultProps = {
  data: {},
  loading: false,
};
