import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    header {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      padding: 0 1rem 0.5rem;

      border-bottom: 1px solid ${theme.colors.tertiary};

      .box {
        display: flex;
        align-items: center;
        margin: 0 0 0.5rem;
        line-height: 1;

        .wrapperIcon {
          margin-right: 0.5rem;
          font-size: 0.8125rem;
        }

        .text {
          font-size: 0.8125rem;
          font-weight: ${theme.font.light};
        }

        &.status {
          margin-bottom: 1rem;

          > div {
            font-size: 0.8125rem;
            text-align: center;
            font-weight: ${theme.font.bolder};
          }
        }
      }
    }

    dl {
      padding: 1rem 1rem 2rem;

      &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.tertiary};
      }

      &:last-child {
        padding-bottom: 1rem;
      }

      dt {
        margin-bottom: 0.5rem;

        font-weight: 700;
        font-size: 1rem;
      }

      dd {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.25rem;

        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }

        font-size: 0.8125rem;

        line-height: 1.25;
      }

      &.order__items {
        dd {
          padding: 0.5rem;
          margin: 0 -0.25rem;
          align-items: center;

          transition: 0.3s ease-in-out background;

          &:hover {
            background: ${theme.colors.primaryLighten60};
          }

          &:not(:last-child) {
            border-bottom: ${theme.colors.tertiary} 1px solid;
          }
        }

        .item {
          &__name {
            font-size: 0.75rem;
            line-height: 1;
            flex: 1;
          }

          &__original_name {
            display: block;
            color: ${theme.colors.gray};
          }

          &__price {
            font-size: 0.675rem;
            margin-left: 0.125rem;
            font-weight: 700;
          }

          &__status {
            display: inline-flex;
            margin: 0 0.25rem 0 -0.25rem;
            font-size: 0.75rem;

            &__confirmed {
              color: ${theme.colors.success};
            }
            &__stock_out {
              color: ${theme.colors.danger};
            }
            &__substitution {
              color: ${theme.colors.primary};
            }
            &__pending {
              color: ${theme.colors.warning};
            }
          }
        }
      }
    }
  `}
`
