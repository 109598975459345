/* eslint-disable max-len */
import React from 'react';

import AlertInfo from '~/components/AlertInfo';
import Panel from '~/components/Panel';
import { FormGroup, Label, Textarea } from '~/components/unform';

const Recipients = () => {
  return (
    <fieldset>
      <Panel title="Destinatários" className="form-grid" isCollapsible>
        <FormGroup className="span-full">
          <AlertInfo
            text={`Os telefones devem ser enviados no formato 21987654321 (ddd+numero) sem o código
            do país no início. Será adicionado o código do Brasil automaticamente pelo sistema.`}
          />
        </FormGroup>

        {/*
        <FormGroup className="span-full">
          <h3>Enviar lista de contatos .csv</h3>
          <Label>Importar contatos</Label>
          <FileInput name="file" />
        </FormGroup>
        */}

        <FormGroup className="span-full">
          <h3>Incluir destinatários por lista de telefones</h3>
          <Label isRequired>Números de telefones separados por vírgula</Label>
          <Textarea
            id="destinations"
            name="destinations"
            placeholder="Insira os números separados por vírgula ou por quebra de linha aqui"
            required
          />
        </FormGroup>
      </Panel>
    </fieldset>
  );
};

export default Recipients;
