import React, { useCallback, useRef, useState } from 'react';

import { Form } from '@unform/web';
import moment from 'moment';

import HeaderPages from '~/components/HeaderPages';
import Panel from '~/components/Panel';
import SelectSearchable from '~/components/SelectSearchable';
import {
  FormGroup,
  Input,
  InputMask,
  Label,
  Select,
  Textarea,
} from '~/components/unform';
import { formatHour, getDurationInDays } from '~/helpers';
import {
  DateWrapper,
  Group,
} from '~/modules/promotional/components/Form/styles';

import * as S from './styles';

const GeneralInfo = () => {
  const formRef = useRef(null);

  const [endDate, setEndDate] = useState('');
  const [startDate, setStartDate] = useState('');

  const trade_options = [
    {
      id: 'sell_out',
      text: 'Sell-out',
      value: 'sell_out',
    },
  ];

  const promotion_options = [
    {
      id: 'buy_pay',
      text: 'Leva e paga',
      value: 'buy_pay',
    },
    {
      id: 'discount',
      text: 'Desconto',
      value: 'discount',
    },
    {
      id: 'fixed_price',
      text: 'Preço fixo',
      value: 'fixed_price',
      disabled: true,
    },
  ];

  const handleChangeDate = useCallback(() => {
    const startDate = formRef?.current?.getFieldValue('start_date');
    const endDate = formRef?.current?.getFieldValue('end_date');

    const duration = getDurationInDays({ startDate, endDate });

    setEndDate(endDate);
    setStartDate(startDate);

    if (duration) formRef?.current?.setFieldValue('duration', duration);
  }, [formRef]);

  return (
    <>
      <HeaderPages title="Dados gerais" />

      <Panel title="Informações gerais" isCollapsible>
        <Form ref={formRef} onSubmit={() => {}}>
          <FormGroup>
            <Label htmlFor="#" isRequired>
              Nome do acordo
            </Label>
            <Input
              placeholder="Digite o nome do acordo"
              name="#"
              id="#"
              required
              autoFocus
            />
          </FormGroup>

          <Group className="general-info">
            <FormGroup>
              <Label htmlFor="#" isRequired>
                Evento
              </Label>
              <Input
                name="#"
                placeholder="Digite o tipo de evento"
                id="#"
                required
                autoFocus
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="#" isRequired>
                Tipo de negociação
              </Label>
              <Select name="#" options={trade_options} />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="#" isRequired>
                Tipo de promoção
              </Label>
              <Select name="#" options={promotion_options} />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="#" isRequired>
                Meta de margem (%)
              </Label>
              <Input
                placeholder="Digite a meta de margem"
                name="#"
                id="#"
                type="number"
                required
                autoFocus
              />
            </FormGroup>
          </Group>

          <FormGroup>
            <Label htmlFor="description">Descrição (opcional)</Label>
            <Textarea
              name="description"
              id="description"
              placeholder="Digite a descrição do acordo"
            />
          </FormGroup>

          <Group>
            <S.StrongTitle>Período do acordo</S.StrongTitle>
            <DateWrapper>
              <div className="start">
                <FormGroup>
                  <Label htmlFor="start_date" isRequired>
                    Data de início
                  </Label>
                  <Input
                    name="start_date"
                    type="date"
                    required
                    min={moment().format('YYYY-MM-DD')}
                    max={endDate}
                    onChange={handleChangeDate}
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="start_hour" isRequired>
                    Horário de início
                  </Label>
                  <InputMask
                    name="start_hour"
                    format={formatHour}
                    mask="_"
                    placeholder="HH:MM"
                    required
                  />
                </FormGroup>
              </div>

              <div className="end">
                <FormGroup>
                  <Label htmlFor="end_date" isRequired>
                    Data de término
                  </Label>
                  <Input
                    name="end_date"
                    type="date"
                    required
                    min={startDate}
                    onChange={handleChangeDate}
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="end_hour" isRequired>
                    Horário de término
                  </Label>
                  <InputMask
                    name="end_hour"
                    format={formatHour}
                    mask="_"
                    placeholder="HH:MM"
                    required
                  />
                </FormGroup>
              </div>

              <div className="duration">
                <FormGroup>
                  <Label>Duração</Label>
                  <Input name="duration" disabled readOnly />
                </FormGroup>
              </div>
            </DateWrapper>
          </Group>

          <Group>
            <Label htmlFor="#" isRequired>
              Fornecedor
            </Label>
            <SelectSearchable placeholder="Digite o nome do fornecedor" />
          </Group>
        </Form>
      </Panel>
    </>
  );
};

export default GeneralInfo;
