/* eslint-disable no-underscore-dangle */

import React from 'react'
import { FiCopy, FiEdit } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { ButtonNavLink } from '~/components/Buttons'

import ButtonCancel from '../../list/elements/ListMenuCampaigns/_elements/ButtonCancel'
import DeleteButton from '../ListMenu/DeleteButton'

import * as S from './styles'

const HeaderButtons = ({ isEditPage = false, status }) => {
  const {
    campaign: {
      item: { data, loading, error }
    }
  } = useSelector(state => state.messagingCampaigns)

  return !loading && data ? (
    <>
      <S.Container>
        {!isEditPage && (
          <>
            {status !== 'sent' && status !== 'canceled' && (
              <ButtonNavLink
                text="Editar"
                to={`/messaging-campaigns/edit/${data?._id}`}
                size="small"
                iconLeft={<FiEdit />}
                customWidth="auto"
              />
            )}

            <ButtonNavLink
              to={`/messaging-campaigns/duplicate/${data?._id}`}
              text="Duplicar"
              template="primaryOutlined"
              size="small"
              iconLeft={<FiCopy />}
              customWidth="auto"
            />
            {status !== 'canceled' && (
              <ButtonCancel template="header" id={data._id} />
            )}
          </>
        )}

        {/* exibir quando não for disparado */}
        {status === 'scheduled' && <DeleteButton id={data._id} />}
      </S.Container>
    </>
  ) : (
    <></>
  )
}

export default HeaderButtons

HeaderButtons.propTypes = {
  isEditPage: PropTypes.bool,
  status: PropTypes.oneOf(['sent', 'scheduled', 'canceled', 'enqueued'])
    .isRequired
}

HeaderButtons.defaultProps = {
  isEditPage: false
}
