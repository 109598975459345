import React from 'react'

import ExternalDashboard from '~/components/ExternalDashboard'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import * as S from './styles'

const CartAbandonment = () => {
  return (
    <S.Container>
      <PageTitle title="Relatórios de conversão" />

      <HeaderPages title="Relatórios de conversão" />

      <ExternalDashboard dashboardId={39} />
    </S.Container>
  )
}

export default CartAbandonment
