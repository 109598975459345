import { produce } from 'immer';

import * as types from './constants';

const INITIAL_STATE = {
  associatedChannels: null,
  error: null,
  loading: false,
};

export default function channels(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      // Store associated channels
      case `${types.LIST_CHANNELS}_PENDING`: {
        draft.loading = true;
        break;
      }

      case `${types.LIST_CHANNELS}_FULFILLED`: {
        const associatedChannels = action.payload.map(
          ({ channel: { name, image_url } }) => ({
            label: types.CHANNELS_LABEL_MAP[name] || name,
            value: name,
            image: image_url,
          }),
        );

        draft.associatedChannels = associatedChannels;
        draft.loading = false;
        draft.error = null;
        break;
      }

      case `${types.LIST_CHANNELS}_REJECTED`: {
        draft.error = action.payload;
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
