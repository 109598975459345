import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FiPlus } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { ButtonNavLink } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { history, isReadOnly } from '~/helpers'
import { useSidePanel } from '~/hooks/useSidePanel'

import EditPlacement from '../edit'
import PlacementsListTable from './Table'

const PlacementsList: React.FC = () => {
  const { t } = useTranslation()

  const { id: editId } = useParams()

  const { addSidePanel, removeSidePanel } = useSidePanel()

  useEffect(() => {
    if (editId && !isReadOnly()) {
      addSidePanel({
        title: t('rm:placements.edit.pageTitle'),
        type: 'content',
        content: <EditPlacement id={editId} />,
        callbackRemove: () => history.push('/retail-media/placements')
      })
    }

    if (!editId) {
      removeSidePanel()
    }
  }, [addSidePanel, editId, removeSidePanel, t])

  return (
    <>
      <PageTitle title={t('rm:placements.list.pageTitle')} />

      <HeaderPages
        title={t('rm:placements.list.pageTitle')}
        sideRightComponent={
          !isReadOnly() && (
            <ButtonNavLink
              text={t('rm:placements.cta.addPlacement')}
              iconLeft={<FiPlus />}
              size="small"
              to="/retail-media/placements/create"
            />
          )
        }
      />

      <PlacementsListTable />
    </>
  )
}

export default PlacementsList
