import React from 'react';
import PropTypes from 'prop-types';

import Panel from '~/components/Panel';

/**
 *
 * @param {boolean} loading Informa o estado do loading do reducer products
 * @param {ReactElement} Child Componente do formulário form-`NAME`.js
 * @param {string} title Título do painel do formulário
 * @param {object} initialValue Objeto recebido no modo edit
 *
 */

const ContainerForm = ({ Child, title, loading, initialValue }) => (
  <Panel title={title} className="form-default form-edit">
    {/* Conteúdo do formulário  */}

    <Child edit loading={loading} initialValue={initialValue} />
  </Panel>
);
export default ContainerForm;

ContainerForm.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  edit: PropTypes.bool,
  initialValue: PropTypes.shape(),
};

ContainerForm.deafultProps = {
  title: null,
  loading: false,
  edit: false,
  initialValue: {},
};
