import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { getUserId } from '~/helpers'

const ColumnStatus = ({ data }) => {
  const { status, order_unique_id } = data

  const statusType = useMemo(() => {
    if (status.type === 'deleted') {
      return 'canceled '
    }

    if (status.type === 'in_review') {
      return 'pending'
    }

    if (status.type === 'opened') {
      return 'info'
    }

    return 'success'
  }, [status])

  const showBadgeUser = useMemo(() => status.type === 'opened', [status])

  const reviewerId = useMemo(() => data?.review?.start.user_id || null, [data])

  const user = getUserId()

  const verifyUserLogged = useMemo(() => {
    return reviewerId === user
  }, [reviewerId, user])

  return (
    <div className={`status__${statusType}`}>
      {status.label || status.type || 'Sem status definido'}

      {!verifyUserLogged && showBadgeUser && (
        <>
          <br />
          <Button
            template="danger"
            size="xsmall"
            as="span"
            text="Sendo revisado por outra pessoa"
          />
        </>
      )}

      {verifyUserLogged && showBadgeUser && (
        <>
          <br />
          <Button
            template="primary"
            size="xsmall"
            as="span"
            text="Sendo revisado por você"
          />
        </>
      )}

      {order_unique_id && (
        <>
          <br />
          <NavLink to={`/order/${order_unique_id}`}>Ver pedido</NavLink>
        </>
      )}
    </div>
  )
}

export default ColumnStatus

ColumnStatus.propTypes = {
  data: PropTypes.shape({
    cart_id: PropTypes.string,
    order_unique_id: PropTypes.string,
    in_review: PropTypes.bool,
    link_sent: PropTypes.bool,
    status: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string
    }),
    review: PropTypes.shape({
      start: PropTypes.shape({
        at: PropTypes.string,
        user_id: PropTypes.string
      })
    })
  }).isRequired
}
