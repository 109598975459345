import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.gray};
    padding: ${theme.spacings.medium};
    border-radius: ${theme.border.radius.large};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.inative};
    height: 80px;
    margin-bottom: ${theme.spacings.xxxsmall};

    p {
      margin: 0;
    }
  `}
`;
