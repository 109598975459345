import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;

    // Colors
    --bgColor: ${theme.colors.primary};
    --dotColor: #242046;

    // Dimensions
    --dotSize: 1px;
    --dotSpace: 12px;

    background: var(--light);
    background-size: 12px 12px;
  `}
`

export const Main = styled.section`
  ${({ theme }) => css`
    width: 94vw;
    max-width: 680px;
    padding: clamp(1rem, 2rem, 5vw);

    > header {
      width: 100%;
      border-bottom: 1px solid ${theme.colors.light};
      text-align: center;

      h1 {
        margin-bottom: 0.125rem;
      }

      + * {
        margin-bottom: 1rem;
      }

      > p {
        padding: 0;
        margin: 0;
        margin-bottom: 0.25rem;

        border-radius: ${theme.border.radius.large};
        font-size: ${theme.font.sizes.small};
        line-height: 1.25;
      }
    }
  `}
`
