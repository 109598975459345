import React from 'react'
import { FiPlus } from 'react-icons/fi'

import { ButtonNavLink } from '~/components/Buttons'
import { DatepickerHeader } from '~/components/datepicker-header'
import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { PROMOTIONAL_REDUX_QUERY as reduxQuery } from '~/modules/promotional/store/promotion/constants'

import PromotionalTable from './_elements/PromotionalTable'

const PromotionalList = () => {
  return (
    <>
      <PageTitle title="Promoções > Listagem" />

      <HeaderPages
        title="Listagem de promoções"
        className="has-datepicker"
        sideRightComponent={
          <>
            <ButtonNavLink
              to="/promotional/create"
              text="Criar promoção"
              iconLeft={<FiPlus />}
              size="small"
            />
            <DatepickerHeader maxEndDate={null} />
          </>
        }
      />
      <Filters
        promotionalStatus
        promotionalType
        query
        liveQuery
        queryKeyState={`${reduxQuery}`}
        queryPlaceholder="Buscar Promoção (nome, código)"
      />

      <PromotionalTable />
    </>
  )
}

export default PromotionalList
