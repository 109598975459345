import * as types from '../constants';

const index = {
  pending: `${types.LIST_TEMPLATES}_PENDING`,
  fulfilled: `${types.LIST_TEMPLATES}_FULFILLED`,
  rejected: `${types.LIST_TEMPLATES}_REJECTED`,
};

export const listTemplates = {
  [index.pending]: ({ draft }) => {
    draft.loading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.loading = false;
    draft.templates = action.payload;
  },
  [index.rejected]: ({ draft }) => {
    draft.loading = false;
  },
};
