const prefix = '@retailMedia/industry/publisher'

export const PUBLISHERS_LIST = `${prefix}/LIST`
export const PUBLISHERS_LIST_QUERY = `${prefix}/LIST_QUERY`
export const PUBLISHERS_LIST_SET_PAGE = `${prefix}/LIST_SET_PAGE`
export const PUBLISHERS_LIST_PER_PAGE = `${prefix}/LIST_PER_PAGE`

export const PUBLISHERS_LIST_REPORT = `${prefix}/LIST_REPORT`

export const PUBLISHER_REDUX_QUERY = `${prefix}/REDUX_QUERY`

export const PUBLISHER_INFO = `${prefix}/INFO`

export const types = {
  list: PUBLISHERS_LIST,
  page: PUBLISHERS_LIST_SET_PAGE,
  perPage: PUBLISHERS_LIST_PER_PAGE,
  listReport: PUBLISHERS_LIST_REPORT,
  getPublisher: PUBLISHER_INFO
}
