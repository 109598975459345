import React from 'react'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import { getUserId } from '~/helpers'

import { useUserList } from '../../pages/List/hooks/useUsersList'

// import { Container } from './styles';

const UsersTableActionColumn: React.FC<{ data: UserResponse }> = ({ data }) => {
  const {
    loadingResendInvite,
    loadingResendInviteId,
    handleResendInvite,
    handleRemoveUser,
    loadingRemoveUser
  } = useUserList()

  return (
    <nav className="d-flex gap-2 justify-content-end">
      {data.status === 'waiting_subscription' && (
        <Button
          size="xsmall"
          customWidth="auto"
          text={t('users:resendInvite')}
          textLoading={t('users:sending')}
          loading={
            loadingResendInvite && loadingResendInviteId?.includes(data.id)
          }
          onClick={() => handleResendInvite(data)}
          template="light"
        />
      )}

      {getUserId() !== data.id && (
        <Button
          size="xsmall"
          customWidth="auto"
          template="deleteOutline"
          text={t('users:remove')}
          loading={loadingRemoveUser}
          onClick={() => handleRemoveUser(data)}
        />
      )}
    </nav>
  )
}

export default UsersTableActionColumn
