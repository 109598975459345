import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const primaryOutlinedButtonTemplate = ({
  theme,
  isSelected
}: ButtonTemplatesProps) => css`
  ${isSelected
    ? css`
        filter: brightness(1.75);
        border: 1px solid var(--primary);
      `
    : css`
        background: transparent;
        color: var(--primary);

        border: 1px solid var(--primary);

        &:not(:disabled) {
          &:hover {
            filter: brightness(1.75);
            border-color: var(--primary);
          }
        }

        &.active {
          background: var(--primary);
          color: var(--light);
          border: 1px solid var(--primary);

          &:not(:disabled) {
            &:hover {
              filter: brightness(1.5);
            }
          }
        }
      `}
`

export default primaryOutlinedButtonTemplate
