export const defaultColors = [
  'transparent',
  '#C9CDD2',
  '#616161',
  '#E72341',
  '#FF8D24',
  '#F7C325',
  '#06BA64',
  '#268EEA',
  '#9B36EB',
  '#F315B5',
];
