import { shoppingCartService, view, search } from '~/services/shopping-cart';
import store from '~/store';

import * as types from './constants';

export const restoreShoppingCart = ({ cart_id }) => {
  const body = {
    statusType: 'in_review',
  };

  return {
    type: types.UPDATE_STATUS,
    payload: shoppingCartService.updateShoppingCartStatus({ cart_id, body }),
    successFeedbackMsg: 'Carrinho restaurado com sucesso!',
  };
};

export const approveShoppingCart = ({ cart_id, successFeedbackMsg = null }) => {
  const body = {
    statusType: 'approved',
  };

  return {
    type: types.UPDATE_STATUS,
    payload: shoppingCartService.updateShoppingCartStatus({ cart_id, body }),
    successFeedbackMsg,
  };
};

export const linkSentShoppingCart = ({
  cart_id,
  successFeedbackMsg = null,
}) => {
  const body = {
    statusType: 'link_sent',
  };

  return {
    type: types.UPDATE_STATUS,
    payload: shoppingCartService.updateShoppingCartStatus({ cart_id, body }),
    successFeedbackMsg,
  };
};

export const openedShoppingCart = ({ cart_id, successFeedbackMsg = null }) => {
  const body = {
    statusType: 'opened',
  };

  return {
    type: types.UPDATE_STATUS,
    payload: shoppingCartService.updateShoppingCartStatus({ cart_id, body }),
    successFeedbackMsg,
  };
};

export const deleteShoppingCart = ({ cart_id }) => {
  const body = {
    statusType: 'deleted',
  };

  return {
    type: types.DELETE,
    payload: shoppingCartService.updateShoppingCartStatus({ cart_id, body }),
    successFeedbackMsg: 'Carrinho cancelado com sucesso!',
  };
};

export const sendLinkShoppingCart = payload => {
  const { dispatch } = store;

  const callback = () => dispatch(linkSentShoppingCart(payload));

  return {
    type: types.SEND_LINK,
    payload: shoppingCartService.sendLink(payload),
    onSuccess: callback,
    getErrorFeedbackMsg: error =>
      error.response?.data?.messages?.[0] || 'Houve um erro ao enviar o link.',
  };
};

export const getShoppingCartData = ({ id }) => {
  return {
    type: types.VIEW,
    payload: view({ id }),
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao carregar.',
  };
};

export const searchShoppingCart = ({ query }) => {
  return {
    type: types.SEARCH,
    payload: search({ query }),
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao carregar.',
  };
};

export const handleShouldRevalidateList = status => {
  return {
    type: types.SHOULD_REVALIDATE,
    payload: status,
  };
};
