import { types } from '../constants'

const index = {
  pending: `${types.minCosts}_PENDING`,
  fulfilled: `${types.minCosts}_FULFILLED`,
  rejected: `${types.minCosts}_REJECTED`
}

export const getMinCosts = {
  [index.pending]: ({ draft }) => {
    draft.grocery.configuration.minCosts.loading = true
    draft.grocery.configuration.minCosts.error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.grocery.configuration.minCosts.data = action.payload
    draft.grocery.configuration.minCosts.loading = false
  },
  [index.rejected]: ({ action, draft }) => {
    draft.grocery.configuration.minCosts.loading = false
    draft.grocery.configuration.minCosts.error = action.payload || true
  }
}
