/* eslint-disable react/display-name */
import React from 'react';
import { NavLink } from 'react-router-dom';

import ListMenu from '~/modules/flyers/components/ListMenu/ListMenu';

import ColumnExtra from './ColumnExtra';
import ColumnStatus from './ColumnStatus';

import * as S from './styles';

export const schema = {
  title: {
    title: 'Detalhes do encarte',
    render: obj => (
      <NavLink to={`/promotional-flyers/view/${obj.id}`}>
        <S.TitleWrapper>
          <strong className="title">{obj.name}</strong>
        </S.TitleWrapper>
      </NavLink>
    ),
    className: 'order__details col__infos col__title',
  },
  start_date: {
    title: 'Data de início',
    // sortable: 'start_date',
    render: obj => obj.start_date || '-',
    className: 'col__mobile_inline',
  },
  end_date: {
    title: 'Data de término',
    // sortable: 'end_date',
    render: obj => obj.end_date || '-',
    className: 'col__mobile_inline',
  },
  status: {
    title: 'Status',
    // sortable: 'status.type',
    className: `col__status`,
    render: obj => <ColumnStatus status={obj.status} />,
  },
  info: {
    title: 'Informações',
    render: obj => <ColumnExtra locationIds={obj?.location_ids || []} />,
    className: 'col__mobile_inline',
  },
  action: {
    render: obj => <ListMenu data={obj} />,
    className: 'col__menu',
  },
};
