import React, { useMemo, useEffect, useCallback, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { t } from 'i18next'

import { getAccountId, getDecodedToken, getRole } from '~/helpers'
import { getAccountInfo } from '~/helpers/account-info'
import { useUI } from '~/hooks/useUi'
import { gtagEvent } from '~/lib/gtag'
import { getUserAccounts } from '~/modules/users/store/actions'
import { UserAccount } from '~/services/user-service'
import { useAppSelector } from '~/store/hooks'
import { changeAccount } from '~/store/modules/account/actions'

import { FilterSearch } from '../FilterSearch'
import Account from './Account'

import * as S from './styles'

const ChangeAccount: React.FC = () => {
  const { userAccounts }: { userAccounts: UserAccount[] } = useAppSelector(
    state => state.user
  )

  const { query } = useSelector(({ filters }) => filters)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!userAccounts) {
      dispatch(getUserAccounts())
    }
  }, [userAccounts, dispatch])

  const filteredQuery = useMemo(() => query?.accountList || null, [query])

  const showFilter = useMemo(() => userAccounts?.length > 8, [userAccounts])

  /**
   * Filtrando lista por query
   */
  const currentAccountId = getAccountId()

  const currentAccount = useMemo(
    () => userAccounts?.filter(item => item.account_id === currentAccountId),
    [currentAccountId, userAccounts]
  )

  const filteredAccounts = useMemo(
    () =>
      userAccounts?.filter(item => {
        const sameAccount = item.account_id === currentAccountId
        if (sameAccount) return false

        const escaped = filteredQuery?.replace(
          /[-[\]{}()*+?.,\\^$|#\s]/g,
          '\\$&'
        )

        const condition = new RegExp(escaped, 'gi')

        return filteredQuery
          ? condition.test(item.name) || condition.test(item.type)
          : true
      }),
    [userAccounts, currentAccountId, filteredQuery]
  )

  /**
   * Single associations cases
   */

  const decodedToken = getDecodedToken()
  const accountInfo = getAccountInfo()

  const accountName = useMemo(() => accountInfo?.name, [accountInfo])
  const accountLogo = useMemo(() => accountInfo?.logo, [accountInfo])
  const bgLogo = useMemo(() => accountInfo?.bgLogo, [accountInfo])

  const role = getRole()

  const isSingleAssociation = useMemo(
    () => userAccounts?.length === 1,
    [userAccounts]
  )

  const singleAssociationData = useMemo(
    () => ({
      name: accountName,
      header_color: bgLogo,
      logo: accountLogo,
      role,
      type: decodedToken.current_account_type as 'grocery' | 'industry',
      isDefault: true
    }),
    [accountLogo, accountName, bgLogo, decodedToken, role]
  )

  /**
   * @function handleChangeAccount
   */

  const { addMasterLoading, removeMasterLoading } = useUI()

  const handleChangeAccount = useCallback(
    async (data: UserAccount) => {
      const body = { account_id: data.account_id }

      addMasterLoading({
        id: 'changeAccount',
        message: t('config:changeAccount.menu.searchAccount.changingLoading')
      })

      gtagEvent({
        category: 'Multicontas',
        action: 'Alternar conta',
        label: `Usar ${data.name}`
      })

      dispatch(
        changeAccount({
          body,
          onError: () => removeMasterLoading('changeAccount')
        })
      )
    },
    [addMasterLoading, dispatch, removeMasterLoading]
  )

  //

  const [selectedIndex, setSelectedIndex] = useState(-1)

  const scrollToItem = (index: number) => {
    if (listRef.current && listRef.current.children[index]) {
      listRef.current.children[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }

  const handleKeyDown = useCallback(
    e => {
      if (filteredAccounts.length === 0) return

      const toDown = e.key === 'ArrowDown' || (e.key === 'Tab' && !e.shiftKey)
      const toUp = e.key === 'ArrowUp' || (e.key === 'Tab' && e.shiftKey)
      const toGo = e.key === 'Enter'

      const handleToDown = (prevIndex: number) => {
        const newIndex =
          prevIndex < filteredAccounts.length - 1 ? prevIndex + 1 : 0
        scrollToItem(newIndex)
        return newIndex
      }

      const handleToUp = (prevIndex: number) => {
        const newIndex =
          prevIndex > 0 ? prevIndex - 1 : filteredAccounts.length - 1
        scrollToItem(newIndex)
        return newIndex
      }

      switch (true) {
        case toDown:
          e.preventDefault()
          setSelectedIndex(handleToDown)
          break

        case toUp:
          e.preventDefault()
          setSelectedIndex(handleToUp)
          break

        case toGo:
          e.preventDefault()
          if (selectedIndex >= 0) {
            handleChangeAccount(filteredAccounts[selectedIndex])
          }
          break
        default:
          setSelectedIndex(0)
          break
      }
    },
    [filteredAccounts, handleChangeAccount, selectedIndex]
  )

  const selectedIdIndex = useMemo(() => {
    if (selectedIndex < 0) return null
    return filteredAccounts?.[selectedIndex]?.user_account_id
  }, [filteredAccounts, selectedIndex])

  const listRef = useRef<HTMLDivElement>(null)

  return (
    <S.Container>
      {isSingleAssociation && (
        <Account
          data={singleAssociationData}
          showEmail
          readOnly
          isSingleAssociation
        />
      )}

      {currentAccount?.[0] && !isSingleAssociation && (
        <Account data={currentAccount[0]} showEmail readOnly />
      )}

      {!isSingleAssociation && (
        <>
          <strong>{t('config:changeAccount.menu.selectAccount')}</strong>

          {showFilter && (
            <FilterSearch
              keyState="accountList"
              live
              placeholder={t('config:changeAccount.menu.searchAccount')}
              autoFocus
              tabIndex="0"
              onKeyDown={handleKeyDown}
            />
          )}

          <S.ListAccounts ref={listRef}>
            {filteredAccounts?.map((account: UserAccount) => (
              <Account
                data={account}
                key={account.account_id}
                selectedIndex={selectedIdIndex}
                onChangeAccount={handleChangeAccount}
              />
            ))}

            {!filteredAccounts?.length &&
              t('config:changeAccount.menu.searchAccount.notFound')}
          </S.ListAccounts>
        </>
      )}
    </S.Container>
  )
}

export default ChangeAccount
