import { t } from 'i18next'

import CreateAd from './CreateAd'
import CreateCampaign from './CreateCampaign'

const PayloadCreate = ({ ...props }: DefaultChangeProps) => {
  const SimpleTerm = ({ value }: { value: string }) => (
    <span className="newValue">
      {t('rm:Term')}: <strong>{value}</strong>
    </span>
  )

  const renderComponent = {
    ad: <CreateAd {...props} />,
    campaign: <CreateCampaign {...props} />,
    campaign_keyword: <SimpleTerm value={props.newValue.keyword} />,
    campaign_category: <SimpleTerm value={props.note?.name} />
  }

  return renderComponent?.[props.entity] || null
}

export default PayloadCreate
