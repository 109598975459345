import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { CAPMPAIGNS_FILTERS_KEYS } from '~/modules/retailMedia/store/common/constants'
import { useAppSelector } from '~/store/hooks'
import { setFilter } from '~/store/modules/filters/actions'

import SelectSearchable from '../../SelectSearchable'

const GenericFilter = ({
  options: optionsRaw,
  placeholder,
  keyState = 'filter',
  dynamicAction,
  formatOptions,
  loading = false,
  ...rest
}: GenericFilterProps) => {
  const dispatch = useDispatch()

  const [mainKey, childKey = null] = keyState.split('.') as [
    mainKey: FiltersKeys,
    childKey: string | null
  ]

  const { [mainKey]: rootState } = useAppSelector(state => state.filters)

  const childKeyType = childKey as keyof typeof rootState

  const filteredValues = useMemo(
    () => (childKey ? rootState?.[childKeyType] : rootState),
    [childKey, childKeyType, rootState]
  )

  const handleChangeStatus = (selectedStatusType: HandleChangeStatusDTO) => {
    dispatch(setFilter({ type: keyState, data: selectedStatusType }))
  }

  /**
   * Handle dynamic values
   */

  const {
    advertiserPublisher: {
      publishers: { data: publisherData, loading: publisherLoading },
      advertisers: { data: advertiserData, loading: advertiserLoading }
    }
  } = useAppSelector(state => state.retailMedia)

  useEffect(() => {
    if (dynamicAction) {
      dispatch(
        dynamicAction({
          query: {
            count: true
          }
        })
      )
    }
  }, [dispatch, dynamicAction])

  const dynamicData = useMemo(() => {
    if (keyState === CAPMPAIGNS_FILTERS_KEYS.advertiser)
      return { data: advertiserData, loading: advertiserLoading }

    return { data: publisherData, loading: publisherLoading }
  }, [
    publisherData,
    publisherLoading,
    advertiserData,
    advertiserLoading,
    keyState
  ])

  /**
   *
   */

  const options = useMemo(
    () => (dynamicAction ? formatOptions(dynamicData.data) : optionsRaw),
    [dynamicAction, formatOptions, dynamicData.data, optionsRaw]
  )

  return (
    <SelectSearchable
      placeholder={placeholder}
      options={options}
      onChange={handleChangeStatus}
      value={[filteredValues]}
      loading={dynamicData?.loading || loading}
      disabled={loading}
      {...rest}
    />
  )
}

export default GenericFilter
