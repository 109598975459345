/* eslint-disable react/display-name */
import React from 'react'
import { Link } from 'react-router-dom'

import LocationName from '~/components/LocationName'

export const OperatorsSchema: TableSchema = {
  name: {
    render: operator => (
      <Link to={`/operators/edit/${operator.id}`}>
        {operator.name || 'Sem nome'}
      </Link>
    ),
    title: 'Título',
    className: 'title',
    showTotal: true
  },
  location: {
    render: operator => <LocationName locationId={operator.location_id} />,
    title: 'Loja'
  },
  role: {
    render: operator =>
      operator.role === 'store_operator' ? 'Operador de loja' : operator.role,
    title: 'Função'
  }
}
