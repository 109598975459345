import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.div`
  position: relative;
  display: inline-block;

  .popover-content {
    position: relative;
    display: inline-block;
  }
`

export const ContainerPortal = styled.div`
  ${({ theme }) => css`
    position: absolute;

    transform: translate(-50%, -103%);
    z-index: 9998;

    > div {
      border-radius: ${theme.spacings.tiny};
      padding: ${theme.spacings.medium};
      min-width: 340px;
      background-color: ${theme.colors.white};
      box-shadow: 0px 8px 24px 0px rgba(24, 21, 60, 0.16);

      border: 0 solid transparent !important;
    }

    @media ${device.tablet_max} {
      position: fixed;
      top: 0 !important;
      left: 0 !important;
      transform: none;

      display: flex;
      justify-content: center;
      align-items: center;

      background: ${theme.modal.overlay};
      width: 100vw;
      height: 100vh;

      > div {
        max-width: 90vw;
        max-height: 90vh;
      }
    }
  `}
`

export const TitleHeader = styled.header`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacings.xlarge};
    color: ${theme.colors.neutral.low.pure};

    h4 {
      font-size: ${theme.font.sizes.medium};
      font-style: normal;
      font-weight: ${theme.font.bolder};
      margin: 0;
    }
  `}
`
