import { InputHTMLAttributes, useEffect, useRef } from 'react'

import { useField } from '@unform/core'
import PropTypes from 'prop-types'

/**
 *
 * @example
 *  <Input
 *    name="address_street"
 *  />
 *
 * @param {string} name Recebe o name para o input
 *
 */

type InputHiddenProps = {
  onError?: (data: any) => void
  name: string
} & InputHTMLAttributes<HTMLInputElement>

const InputHidden = ({ name, onError, ...rest }: InputHiddenProps) => {
  const inputRef = useRef(null)

  const { fieldName, defaultValue, error, registerField } = useField(name)

  useEffect(() => {
    onError?.(error)
  }, [error, onError])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <input
      className={error ? 'input-error' : ''}
      name={fieldName}
      type="text"
      defaultValue={defaultValue}
      id={name}
      {...rest}
      ref={inputRef}
      style={{ display: 'none' }}
    />
  )
}

InputHidden.defaultProps = {
  onError: null
}

InputHidden.propTypes = {
  name: PropTypes.string.isRequired,
  onError: PropTypes.func,
  value: PropTypes.string
}

export default InputHidden
