import React from 'react';

import PropTypes from 'prop-types';

import * as S from './styles';

/**
 * **Componente de Barra de Progresso**
 *
 * @param { number }  value Valor que incrementa a porcentagem
 * @param { number } goal Valor total da porcentagem
 * @param { bool } showPercentage Mostra em tela a porcentagem
 * @param { bool } showTotal Mostra em tela a texto responsavel pelo total
 * @param { bool } isDesc Indica se é uma barra descrescente
 *
 * @example <ProgressBar />
 *
 */

const ProgressBar = ({
  value,
  goal,
  showPercentage,
  showTotal,
  template,
  isDesc,
}) => {
  const percentage = (value * 100) / goal;

  return (
    <S.Container>
      <S.Bar>
        <S.Progress
          percentage={Number(percentage.toFixed(2))}
          template={template}
          isDesc={isDesc}
        />
      </S.Bar>

      {showPercentage && <S.Label>{percentage.toFixed(2)}%</S.Label>}

      {showTotal && (
        <S.Label>
          {value}/{goal}
        </S.Label>
      )}
    </S.Container>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  goal: PropTypes.number.isRequired,
  showPercentage: PropTypes.bool,
  showTotal: PropTypes.bool,
  isDesc: PropTypes.bool,
  template: PropTypes.string,
};

ProgressBar.defaultProps = {
  showPercentage: false,
  showTotal: false,
  isDesc: false,
  template: 'default',
};

export default ProgressBar;
