import { t } from 'i18next'

import CampaignInfoComponent from './CampaignInfo'

export const campaignInfo: SchemaItems = {
  campaign: {
    title: t('rm:Campaign'),
    sortKey: 'campaign_name',
    render: CampaignInfoComponent
  }
}
