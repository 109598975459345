import React from 'react';

import PropTypes from 'prop-types';

import { InputNumber } from '~/components/unform';

const TriggerQuantity = ({ active, isSelectedOffers }) => (
  <>
    Conter ao menos{' '}
    <InputNumber
      name="trigger.items.min_quantity"
      placeholder="Digite o valor"
      required
      disabled={!active}
    />{' '}
    itens{isSelectedOffers && ' contendo os produtos selecionados'}
  </>
);

export default TriggerQuantity;

TriggerQuantity.propTypes = {
  active: PropTypes.bool.isRequired,
  isSelectedOffers: PropTypes.bool.isRequired,
};
