import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Panel from '~/components/Panel';
import { Badge } from '~/components/UI/Badge';

import * as S from '../styles';

const PromotionCustomers = () => {
  const { promotion } = useSelector(state => state.promotional);

  const labelCustomers = useMemo(() => {
    const length = promotion?.customers?.length || 0;

    switch (true) {
      case length === 1:
        return `${length} cliente selecionado`;

      case length > 1:
        return `${length} clientes selecionados`;

      default:
        return 'Todos os clientes';
    }
  }, [promotion]);

  return (
    <Panel
      title="Clientes elegíveis para a promoção"
      isCollapsible={!!promotion?.customers?.length}
      initialCollapsibleState={false}
      headerSideContent={<Badge>{labelCustomers}</Badge>}
    >
      {!!promotion?.customers && (
        <S.RowWrapper className="hasBorder">
          <S.Wrapper>
            {promotion?.customers.map(item => (
              <span key={item}>{item} </span>
            ))}
          </S.Wrapper>
        </S.RowWrapper>
      )}
    </Panel>
  );
};

export default PromotionCustomers;
