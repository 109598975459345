import { category as service } from '~/modules/retailMedia/services/industry'

import { types } from './constants'

export const listCategory = (queries = {}) => ({
  type: types.list,
  payload: service.list(queries)
})

export default {
  listCategory
}
