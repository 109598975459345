import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../../api'

const baseURL = `${baseAPI}`

/**
 * GET Valeus
 */

export const getMinCostService = ({ body }: GetMinCostsRequest) =>
  axios.post<GetMinCostsServiceResponse>(`${baseURL}/campaign/min-costs`, body)
