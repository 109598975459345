import React from 'react';
import PropTypes from 'prop-types';

import Form from '~/components/forms/form';

/**
 * Retorna a base para o formulário de cada módulo.
 *
 * @param {boolean} disabledStep Recebe um boolean para checar se o módulo está ativo ou não
 * @param {function} onSubmit Função disparado ao submit do formulário
 * @param {function} checkIsValid Função para lidar com a validação do formulário
 * @param {ReactElement} children Componentes filhos do formulário
 *
 */

const FormModule = ({ disabledStep, onSubmit, checkIsValid, children }) => {
  return (
    <Form
      onSubmit={onSubmit}
      checkIsValid={checkIsValid}
      className="form-default"
    >
      <fieldset disabled={disabledStep}>{children}</fieldset>
    </Form>
  );
};

export default FormModule;

FormModule.propTypes = {
  disabledStep: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  checkIsValid: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

FormModule.defaultProps = {
  disabledStep: false,
};
