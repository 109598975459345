export default {
  patch: {
    item: [],
    loading: false,
    error: null
  },
  cpcPatch: {
    item: [],
    loading: false,
    error: null
  },
  cpmPatch: {
    item: [],
    loading: false,
    error: null
  }
}
