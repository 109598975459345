import React from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import Panel from '~/components/Panel';
import { formatMoney } from '~/helpers';

import * as S from '../styles';
import Discount from './Discount';

const PromotionRulesView = () => {
  const { promotion } = useSelector(state => state.promotional);

  return (
    <Panel title="Regras da promoção">
      <S.RowWrapper className="hasBorder">
        <S.GroupWrapper className="promotionRules">
          {promotion?.promotion_trigger === 'coupon' && (
            <>
              <S.Wrapper>
                <strong>Código do cupom</strong>
                <p>{promotion?.code}</p>
              </S.Wrapper>
            </>
          )}

          <S.Wrapper>
            <strong>Tipo de promoção</strong>
            <p>{promotion?.type.label}</p>
          </S.Wrapper>

          {promotion?.promotion_type === 'buy_pay' && (
            <>
              <S.Wrapper>
                <strong>Leva</strong>
                <p>
                  {promotion?.trigger.quantity}{' '}
                  <small>itens participantes</small>
                </p>
              </S.Wrapper>

              <S.Wrapper>
                <strong>Paga</strong>
                <p>
                  {promotion?.benefits.pay} <small>itens</small>
                </p>
              </S.Wrapper>
            </>
          )}

          {promotion?.promotion_type === 'shipping_discount' && (
            <Discount
              value={promotion?.benefits?.shipping_discount.label}
              selectedOffers={promotion?.promotion_target === 'selected'}
            />
          )}

          {promotion?.promotion_type === 'items_discount' && (
            <Discount
              value={promotion?.benefits?.items_discount.label}
              selectedOffers={promotion?.promotion_target === 'selected'}
            />
          )}

          {promotion?.promotion_type === 'discount' && (
            <>
              <S.Wrapper>
                <strong>Leva</strong>
                <p>
                  {promotion?.trigger.quantity}{' '}
                  <small>itens participantes</small>
                </p>
              </S.Wrapper>

              <S.Wrapper>
                <strong>Desconto</strong>
                <p>
                  {promotion?.benefits.discount}%{' '}
                  <small>nos itens participantes</small>
                </p>
              </S.Wrapper>
            </>
          )}

          {promotion?.promotion_type === 'fixed_price' && (
            <>
              <S.Wrapper>
                <strong>Leva</strong>
                <p>
                  {promotion?.trigger.quantity}{' '}
                  <small>itens participantes</small>
                </p>
              </S.Wrapper>

              <S.Wrapper>
                <strong>Paga</strong>
                <p>{formatMoney(promotion?.benefits.price)}</p>
              </S.Wrapper>
            </>
          )}
        </S.GroupWrapper>
      </S.RowWrapper>

      {/* Limites de uso */}
      {(promotion?.limits?.total || promotion?.limits?.per_customer) && (
        <S.RowWrapper className="hasBorder">
          <S.Wrapper>
            <strong>Limite de uso</strong>
            {promotion?.limits?.total && (
              <p>
                <span className="icon success">
                  <FiCheckCircle />
                </span>{' '}
                Limitar a {promotion.limits.total} vezes que promoção pode ser
                usada no total
              </p>
            )}
            {promotion?.limits?.per_customer && (
              <p>
                <span className="icon success">
                  <FiCheckCircle />
                </span>{' '}
                Limitar a {promotion.limits.per_customer} uso(s) por cliente
              </p>
            )}
          </S.Wrapper>
        </S.RowWrapper>
      )}

      {/* Valor mínimo */}
      {!!promotion?.trigger?.total && (
        <S.RowWrapper className="hasBorder">
          <S.Wrapper>
            <strong>Valor mínimo</strong>
            <p>
              <span className="icon success">
                <FiCheckCircle />
              </span>{' '}
              Desconto aplicável somente em pedidos a partir de{' '}
              {formatMoney(promotion.trigger.total.min_value)}
            </p>
          </S.Wrapper>
        </S.RowWrapper>
      )}

      {/* Mínimos do carroinho */}
      {!!promotion?.trigger?.items && (
        <S.RowWrapper className="hasBorder">
          <S.Wrapper>
            <strong>Quantidade mínima de itens no carrinho</strong>
            <p>
              <span className="icon success">
                <FiCheckCircle />
              </span>{' '}
              Desconto aplicável somente em pedidos com no mínimo{' '}
              {promotion.trigger.items.min_quantity} itens
            </p>
          </S.Wrapper>
        </S.RowWrapper>
      )}
    </Panel>
  );
};

export default PromotionRulesView;
