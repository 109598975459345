import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/placement`

/**
 * Create
 */

export const createPlacementService = (
  body: PlacementCreateBody = {} as PlacementCreateBody
) => axios.post<ResponseListPlacements>(baseURL, body)

/**
 * List all
 */

export const listPlacementQueryKey = 'list-placements'

export const listPlacementsService = (
  params: RequestListPlacements = {} as RequestListPlacements
) => axios.get<ResponseListPlacements>(baseURL, { params })

/**
 * Get one
 */
export const getPlacementQueryKey = 'get-placement'

export const getPlacementService = (id: string) =>
  axios.get<Placement>(`${baseURL}/${id}`)

/**
 * PATCH
 */

export const patchPlacementService = ({
  body,
  id
}: RequestPatchPlacementParams) =>
  axios.patch<Placement>(`${baseURL}/${id}`, body)

/**
 * DELETE
 */

export const deletePlacementService = (id: string) =>
  axios.delete(`${baseURL}/${id}`)

/**
 * DELETE FORMAT
 */

export const deleteFormatPlacementService = (id: string) =>
  axios.delete(`${baseURL}/format/${id}`)
