import axios from 'axios';

export const putFormProfileUser = data => {
  return axios.put(`${process.env.REACT_APP_HOST}/api/user/profile`, data);
};

export const getDataUser = () => {
  return axios.get(`${process.env.REACT_APP_HOST}/api/user/profile`);
};

export const putFormProfileCompany = data => {
  return axios.put(`${process.env.REACT_APP_HOST}/api/account/profile`, data);
};

export const getDataCompany = () => {
  return axios.get(`${process.env.REACT_APP_HOST}/api/account/profile`);
};
