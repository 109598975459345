import React from 'react'

import { DatepickerHeader } from '~/components/datepicker-header'
import { Filters } from '~/components/Filters'
import { FilterSearch } from '~/components/FilterSearch'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import { useOrderTable } from '../../_hooks/useOrderBoard'
import * as types from '../../constants'
import Board from '../Board'

const BaseOrderBoard = () => {
  const { activeStatusKeys } = useOrderTable()

  return (
    <>
      {/* Meta <title> */}
      <PageTitle title="Pedidos > Quadro" />

      <HeaderPages
        title="Quadro de pedidos"
        sideRightComponent={<DatepickerHeader maxEndDate={null} />}
        className="has-datepicker"
        sideLeftComponent={
          <FilterSearch
            placeholder="Buscar Pedido (número, cliente, etc)"
            keyState={`${types.ORDER_REDUX_QUERY}`}
            live
          />
        }
      />

      <Filters
        orderStatusMulti
        locations
        channel
        payment
        initialDataStatusMulti={activeStatusKeys}
      />

      <Board />
    </>
  )
}

export default BaseOrderBoard
