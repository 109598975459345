import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import NumberFormat from 'react-number-format'
import { Spinner } from 'reactstrap'

import { useField } from '@unform/core'
import PropTypes from 'prop-types'

import { Container, Error, MessageError } from './styles'

/**
 *
 * @example
 * <InputPhone
 *   name="main_phone"
 *   placeholder="Insira o telefone"
 *   loading={loading}
 * />
 *
 * @param {string} name Recebe o name para o input
 * @param {ReactElement | string} icon Ícone que será exbido a esquerda do input.
 * @param {boolean} loading Exibe um loader ao lado do input
 * @param {function} onBlur Extendendo onBlur
 *
 * @returns O valor retornado é um objeto com value(formatado) e numAsString(sem formatação)
 *
 */

const InputPhone = ({
  name,
  icon: Icon,
  onBlur,
  loading,
  disabled,
  ...rest
}) => {
  const inputRef = useRef(null)

  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name)

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [valueInput, setValueInput] = useState('')

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    if (onBlur) {
      onBlur({
        value: inputRef.current.state.value,
        name: inputRef.current.state.name,
        clearError,
        setIsFilled
      })
    }

    setIsFilled(!!inputRef.current?.state.value)
  }, [clearError, onBlur])

  const defaultValueIsNull = defaultValue === null

  const defaultValueIsUndefined = typeof defaultValue === 'undefined'

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'state'
    })
  }, [inputRef, fieldName, registerField])

  useEffect(() => {
    if (!defaultValueIsNull && !defaultValueIsUndefined) {
      setValueInput(defaultValue)
    }
  }, [defaultValue, defaultValueIsNull, defaultValueIsUndefined])

  const inputChangedHandler = values => {
    setValueInput(values.value)
  }

  return (
    <>
      <Container
        isFocused={isFocused}
        isFilled={isFilled}
        isErrored={!!error}
        isDisabled={disabled}
      >
        {Icon && <Icon />}

        <NumberFormat
          type="tel"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          name={fieldName}
          disabled={disabled}
          aria-label={fieldName}
          isNumericString
          format={
            valueInput.toString().length < 11
              ? '(##) ####-#####'
              : '(##) #####-####'
          }
          onValueChange={inputChangedHandler}
          value={valueInput}
          defaultValue={defaultValue === null ? '' : defaultValue}
          {...rest}
          ref={inputRef}
        />

        {loading && <Spinner type="grow" size="sm" color="secondary" />}
      </Container>
      {error && <MessageError>{error}</MessageError>}
    </>
  )
}

export default InputPhone

InputPhone.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func
}

InputPhone.defaultProps = {
  icon: null,
  onBlur: null,
  loading: false,
  disabled: false
}
