export const CAMPAIGN_CREATE = '@messagingCampaign/CAMPAIGN_CREATE';
export const CAMPAIGNS_UPLOAD = '@messagingCampaign/CAMPAIGNS_UPLOAD';
export const CAMPAIGN_UPDATE = '@messagingCampaign/CAMPAIGN_UPDATE';
export const CAMPAIGN_DELETE = '@messagingCampaign/CAMPAIGN_DELETE';

export const CAMPAIGN_GET = '@messagingCampaign/CAMPAIGN_GET';

export const CAMPAIGNS_LIST = '@messagingCampaign/CAMPAIGNS_LIST';
export const CAMPAIGNS_LIST_SET_PAGE =
  '@messagingCampaign/CAMPAIGNS_LIST_SET_PAGE';
export const CAMPAIGNS_LIST_PER_PAGE =
  '@messagingCampaign/CAMPAIGNS_LIST_PER_PAGE';

export const CAMPAIGN_REDUX_QUERY = '@messagingCampaign/CAMPAIGN_REDUX_QUERY';

export const CAMPAIGNS_GET_DASHBOARD =
  '@messagingCampaign/CAMPAIGNS_GET_DASHBOARD';

export const CANCEL_CAMPAIGN = '@messagingCampaign/CANCEL_CAMPAIGN';

export const types = {
  upload: CAMPAIGNS_UPLOAD,
  list: CAMPAIGNS_LIST,
  page: CAMPAIGNS_LIST_SET_PAGE,
  perPage: CAMPAIGNS_LIST_PER_PAGE,
  get: CAMPAIGN_GET,
  create: CAMPAIGN_CREATE,
  update: CAMPAIGN_UPDATE,
  remove: CAMPAIGN_DELETE,
  dashboard: CAMPAIGNS_GET_DASHBOARD,
  cancelCamapign: CANCEL_CAMPAIGN,
};
