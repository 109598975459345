import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/advertiser-publisher`

export const list = params =>
  axios.get<GetAdvertiserPublishersResponse>(`${baseURL}/publishers`, {
    params
  })

export default {
  list
}
