import React, { useState, useMemo, useCallback } from 'react'

import type { AxiosError } from 'axios'

import AlertDismissibleNew from '~/components/AlertDismissibleNew'
import { formatAxiosError } from '~/helpers/errors'

const useErrorApiAlert: UseErrorApiAlert<AxiosError> = ({ title = 'Erro' }) => {
  const [errorRaw, setErrorRaw] =
    useState<AxiosError<APIErrorRawResponse> | null>(null)

  const error = useMemo(
    () => (errorRaw ? formatAxiosError(errorRaw) : null),
    [errorRaw]
  )

  const closeAlert = useCallback(() => {
    setErrorRaw(null)
  }, [])

  const component: React.FC<AlertComponentProps> = ({ type = 'danger' }) => {
    if (!error) return null

    return (
      <AlertDismissibleNew
        title={title}
        text={error || ''}
        handleClosedToast={closeAlert}
        template={type}
        colorBorder
      />
    )
  }

  return [component, setErrorRaw]
}

export { useErrorApiAlert }
