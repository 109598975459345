import { Spinner } from '~/components/Spinner'

import { Keybind, Tooltip } from '../styles'
import type { ButtonNavLinkProps } from './types'

import * as S from './styles'

/**
 *
 * Renderiza os NavLink com estilo de botão. Estão separados por templates.
 *
 * @component
 * @example
 * <ButtonNavLink
 *   to="/page/to/go"
 *   text="Salvar produto"
 *   template="primary"
 *   customWidth="auto"
 *   keybind="enter"
 *   className="btn-light-primary"
 * />
 *
 */

const ButtonNavLink: React.FC<ButtonNavLinkProps> = ({
  text,
  template = 'primary',
  size = 'medium',
  customWidth,
  keybind,
  loading,
  className,
  iconLeft,
  iconRight,
  iconMobile,
  hideMobile,
  textLoading,
  tooltip,
  to,
  children,
  ...rest
}) => (
  <S.Container
    className={`buttonNavLink button__${template}${
      className ? ` ${className}` : ``
    }`}
    to={to}
    template={template}
    $customWidth={customWidth}
    $iconMobile={!!iconMobile}
    $hideMobile={hideMobile}
    size={size}
    {...rest}
  >
    {iconMobile && <span className="icon icon__mobile">{iconMobile}</span>}

    {iconLeft && <span className="icon icon__left">{iconLeft}</span>}

    {loading ? (
      <>
        <Spinner />
        {textLoading && <span className="text"> {textLoading}</span>}
      </>
    ) : (
      <span className="text">{text}</span>
    )}

    {children}

    {keybind && <Keybind className="keybind">{keybind}</Keybind>}

    {iconRight && <span className="icon icon__right">{iconRight}</span>}

    {tooltip && <Tooltip>{tooltip}</Tooltip>}
  </S.Container>
)

export default ButtonNavLink
