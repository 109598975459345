import React, { useMemo } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'

import { formatDateExibition } from '~/helpers'

import { statusInProgress } from '../../_status/order'
import { SlotAlert } from './../styles'

const ColumnShipment = ({ data }) => {
  //
  const isNow = useMemo(() => data.shipments[0]?.use_delivery_time, [data])
  const shipment = useMemo(() => data.shipments?.[0], [data])
  const shipmentType = useMemo(() => data.shipments?.[0]?.type, [data])
  const currentSlot = useMemo(
    () => shipment?.slot || 'Sem slot cadastrado',
    [shipment]
  )

  const labelShipmentType = {
    shipping_from_store: 'Entrega em casa',
    pick_up_in_store: 'Retirada na loja'
  }

  const styles = {
    shipping_from_store: { color: '#55AA87' },
    pick_up_in_store: { color: '#AAAA22' }
  }

  const isToday = useMemo(
    () => moment().isSame(data?.schedule_datetime || data.schedule_date, 'day'),
    [data]
  )

  const limitHour = useMemo(() => {
    const endHour = data.schedule_datetime

    const startTime = moment()
    const endTime = moment(endHour)

    return endTime.diff(startTime, 'minutes')
  }, [data.schedule_datetime])

  const templateAlertSlot = useMemo(
    () => (limitHour < 30 ? 'danger' : 'warning'),
    [limitHour]
  )

  const showBadgeDelayOrder = useMemo(
    () => limitHour < 60 && statusInProgress.includes(data.status[0].type),
    [data.status, limitHour]
  )

  const labelForBadgeDelay = useMemo(() => {
    const normalizeTime = limitHour < 0 ? limitHour * -1 : limitHour

    function getTimeFromMins(mins) {
      var h = (mins / 60) | 0,
        m = mins % 60 | 0
      return moment.utc().hours(h).minutes(m).format('H:mm')
    }

    if (normalizeTime < 0) {
      return `Normal`
    }

    if (normalizeTime >= 1440 || normalizeTime < 0) {
      return `Atrasado`
    }

    const formatTime =
      normalizeTime > 60
        ? `${getTimeFromMins(normalizeTime)}h`
        : `${normalizeTime} minutos`

    return limitHour > 0 ? `Faltam ${formatTime}` : `Atrasado ${formatTime}`
  }, [limitHour])

  return (
    <div>
      {!!data.shipments?.[0]?.type && (
        <>
          <small>
            <strong style={{ ...styles[shipmentType] }}>
              {labelShipmentType?.[shipmentType]}
            </strong>
          </small>
          <br />
        </>
      )}
      {formatDateExibition(data?.schedule_datetime || data.schedule_date)}
      <br />
      <small>
        {isToday && isNow ? 'Hoje até ' : ''}
        {isToday && isNow ? shipment.schedule_time : currentSlot}
      </small>

      <br />
      {showBadgeDelayOrder && (
        <SlotAlert template={templateAlertSlot} isPast={limitHour < 0}>
          {labelForBadgeDelay}
        </SlotAlert>
      )}
    </div>
  )
}

ColumnShipment.propTypes = {
  data: PropTypes.shape({
    shipments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    schedule_date: PropTypes.string.isRequired
  }).isRequired
}

export default ColumnShipment
