import React from 'react'
import { FiPlus } from 'react-icons/fi'

import { ButtonNavLink } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import PromotionalFlyerTable from './_elements/Table'

const PromotionalList = () => {
  return (
    <>
      <PageTitle title="Encartes > Listagem" />

      <HeaderPages
        title="Listagem de encartes"
        sideRightComponent={
          <ButtonNavLink
            to="/promotional-flyers/create"
            text="Criar encarte"
            iconLeft={<FiPlus />}
            size="small"
          />
        }
      />

      <PromotionalFlyerTable />
    </>
  )
}

export default PromotionalList
