import { types } from '../constants'

const index = {
  pending: `${types.createTransaction}_PENDING`,
  fulfilled: `${types.createTransaction}_FULFILLED`,
  rejected: `${types.createTransaction}_REJECTED`
}

export const createTransaction = {
  [index.pending]: ({ draft }: CreateTransactionPending) => {
    draft.grocery.wallet.createTransaction.loading = true
  },
  [index.fulfilled]: ({ draft, action }: CreateTransactionFulfilled) => {
    /**
     *  O balanceAfter servirá para atualizarmos o valor
     *  renderizado na tela sem fazer uma nova requisição.
     *  */

    draft.grocery.wallet.createTransaction.data = {
      ...action.payload,
      balanceAfter: action.values?.balanceAfter
    }
    draft.grocery.wallet.createTransaction.loading = false
    draft.grocery.wallet.createTransaction.error = null
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft.grocery.wallet.createTransaction.loading = false
    draft.grocery.wallet.createTransaction.error = action.payload
    draft.grocery.wallet.createTransaction.data = null
  }
}
