import React from 'react'
import { FiChevronLeft } from 'react-icons/fi'

import { ButtonNavLink, Button } from '../Buttons'

import { Container } from './styles'

/**
 * Componente para renderizar uma barra de voltar. Pode receber uma função ou um link.
 */

const Breadcrumb = ({ backTo, goBack, label = 'Voltar' }: BreadcrumbProps) => (
  <Container className="mainBreadcrumb">
    {backTo && (
      <ButtonNavLink
        template="transparentDark"
        to={backTo}
        text={label}
        iconLeft={<FiChevronLeft />}
        customWidth="auto"
        size="xsmall"
      />
    )}

    {goBack && (
      <Button
        template="transparent"
        onClick={goBack}
        text={label}
        iconLeft={<FiChevronLeft />}
        customWidth="auto"
        size="xsmall"
      />
    )}
  </Container>
)

export default Breadcrumb
