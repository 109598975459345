import styled, { css } from 'styled-components'

export const Container = styled.label`
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  * {
    transition: 0.2s ease all;
  }

  &.centerY {
    justify-content: center;
  }

  &.fullWidth {
    display: block;
  }
`

export const Text = styled.div`
  line-height: 1;
  font-weight: 600;
  display: flex;
  align-items: center;

  > .icon {
    color: var(--primary);
  }

  > strong {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.125rem;

    .bullet {
      color: ${props => props.theme.primary};
      border-radius: 0.25rem;
      display: inline-flex;
      margin-right: 0.25rem;
    }

    .requiredBagde {
      display: inline-flex;
      margin-left: 0.25rem;
      padding: 0.25rem 0.3rem;
      border-radius: 0.25rem;
      background-color: ${props => props.theme.primary};
      color: ${props => props.theme.white};
      font-size: 0.625rem;
      font-weight: 700;
    }
  }

  > span:not(.icon) {
    color: ${props => props.theme.gray};
    font-size: 0.875rem;
    margin-left: 0.25rem;
  }
`

export const Badge = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.danger} !important;
  `}
`

export const Helper = styled.div`
  ${({ theme }) => css`
    flex: 1 1 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: ${theme.colors.stormGray};
    line-height: 1.125;
  `}
`
