/* eslint-disable no-underscore-dangle */
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { BiStore } from 'react-icons/bi'
import { FiEdit, FiGlobe, FiX, FiRefreshCw } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { useScroll } from '~/hooks/useScroll'
import payment from '~/store/modules/payment/actions'

import EditForm from '../EditForm'

import * as S from './styles'

/**
 * Card de cada configuração ou loja sem configuração
 * @param {*} locationId
 * @param {*} title
 * @returns
 */

const Card = ({ locationId = null, title, isOpen = false }) => {
  const [isEditing, setIsEditing] = useState(isOpen)
  const [executeScroll, scrollRef] = useScroll()

  const { paymentMethodsConfigList } = useSelector(({ payment }) => payment)
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (isEditing) executeScroll()
  }, [executeScroll, isEditing])

  const currentConfiguration = useMemo(() => {
    if (locationId) {
      return (
        paymentMethodsConfigList.find(
          item => item.location_id === locationId
        ) || null
      )
    }

    return paymentMethodsConfigList.find(item => !item.location_id)
  }, [locationId, paymentMethodsConfigList])

  const isLocalConfiguration = useMemo(
    () =>
      !!paymentMethodsConfigList.find(item => item.location_id === locationId),
    [locationId, paymentMethodsConfigList]
  )

  const handleVisibilityEditPanel = () => {
    setIsEditing(oldState => !oldState)
  }

  const handleRestoreGlobalConfig = () =>
    dispatch(
      payment.methodsConfig.deleteItem({
        id: currentConfiguration._id,
        onSuccess: handleVisibilityEditPanel
      })
    )

  const titleWithIcon = useMemo(
    () => (
      <>
        {isLocalConfiguration ? <BiStore /> : <FiGlobe />} {title}
      </>
    ),
    [isLocalConfiguration, title]
  )

  const configTypeLabel = useMemo(
    () =>
      isLocalConfiguration
        ? 'Usando configuração própria'
        : 'Usando configuração global',
    [isLocalConfiguration]
  )

  return (
    <S.Container
      ref={scrollRef}
      editMode={isEditing}
      onClick={isEditing ? null : handleVisibilityEditPanel}
    >
      <S.Header>
        <S.TitleContainer>
          <S.Title>{titleWithIcon}</S.Title>

          <S.ConfigType>{configTypeLabel}</S.ConfigType>
        </S.TitleContainer>

        <S.ButtonContainer>
          {/* {!isEditing && (
            <Button
              template="icon"
              iconLeft={<FiEye />}
              onClick={handleVisibilityEditPanel}
            />
          )} */}

          {isLocalConfiguration && isEditing && (
            <Button
              template="transparentDanger"
              iconLeft={<FiRefreshCw />}
              text="Restaurar configuração global"
              onClick={handleRestoreGlobalConfig}
              size="xsmall"
            />
          )}

          <Button
            text={isEditing ? 'Editando' : 'Editar'}
            type="button"
            iconLeft={<FiEdit />}
            template={isEditing ? 'warning' : 'primary'}
            onClick={!isEditing ? null : handleVisibilityEditPanel}
          />

          {isEditing && (
            <Button
              type="button"
              template="icon"
              iconLeft={<FiX />}
              onClick={handleVisibilityEditPanel}
            />
          )}
        </S.ButtonContainer>
      </S.Header>

      {isEditing && (
        <EditForm
          onCancel={handleVisibilityEditPanel}
          locationId={locationId}
          configId={currentConfiguration?._id || null}
        />
      )}
    </S.Container>
  )
}

Card.defaultProps = {
  locationId: null,
  isOpen: false
}

Card.propTypes = {
  isOpen: PropTypes.bool,
  locationId: PropTypes.string,
  title: PropTypes.string.isRequired
}

export default Card
