export const shipmentStatuses = [
  'pending',
  'pickup',
  'pickup_complete',
  'dropoff',
  'delivered',
  'failed',
  'canceled',
  'return',
  'returned'
]
