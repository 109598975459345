import i18n from '~/i18n'

export const ERRORS = {
  EMPTY: i18n.t('c:components.TagInput.alert.insertAtLeastOneEntry'),
  DUPLICATED: i18n.t('c:components.TagInput.alert.termAlreadyAdded')
}

export const validateInput = ({
  inputValues,
  tags
}: {
  inputValues: string[]
  tags: string[]
}) => {
  const errors: string[] = []

  const isEmpty = !inputValues?.length

  if (isEmpty) {
    errors.push(ERRORS.EMPTY)
  }

  const isDuplicated =
    inputValues.length === 1 &&
    tags.some(item => inputValues[0].toLowerCase() === item.toLowerCase())

  if (isDuplicated) {
    errors.push(ERRORS.DUPLICATED)
  }

  return errors
}
