import { handleSortDirection } from '~/helpers/sortData'

import { types } from '../constants'

const index = {
  pending: `${types.listAll}_PENDING`,
  fulfilled: `${types.listAll}_FULFILLED`,
  rejected: `${types.listAll}_REJECTED`,
  setPage: types.listAllSetPage,
  setPerPage: types.listAllPerPage,
  sort: types.listAllSort
}

export const listAll = {
  [index.pending]: ({ draft }: ActionData) => {
    draft.grocery.wallet.listAll.loading = true
  },
  [index.fulfilled]: ({ draft, action }: ActionData) => {
    draft.grocery.wallet.listAll.data = action.payload.data

    draft.grocery.wallet.listAll.pages = action.payload.pages
    draft.grocery.wallet.listAll.total = action.payload.total
    draft.grocery.wallet.listAll.page = action.payload.page

    draft.grocery.wallet.listAll.loading = false
    draft.grocery.wallet.listAll.error = null
  },
  [index.rejected]: ({ draft, action }: ActionData) => {
    draft.grocery.wallet.listAll.loading = false
    draft.grocery.wallet.listAll.error = action.payload
    draft.grocery.wallet.listAll.total = 0
    draft.grocery.wallet.listAll.data = null
  },
  [index.setPage]: ({ draft, action }: ActionData) => {
    draft.grocery.wallet.listAll.page = action.payload
  },
  [index.setPerPage]: ({ draft, action }: ActionData) => {
    draft.grocery.wallet.listAll.perPage = action.payload
  },
  [index.sort]: ({ draft, action }: ActionData) => {
    const selectedKey = action.payload
    const currentSortDirection =
      draft.grocery.wallet.listAll.sortDirection ||
      draft.grocery.wallet.listAll.sortDirectionDefault

    const currentSortKey =
      draft.grocery.wallet.listAll.sortKey ||
      draft.grocery.wallet.listAll.sortKeyDefault

    const { sortDirection, sortKey } = handleSortDirection({
      selectedKey,
      currentSortDirection,
      currentSortKey
    })

    draft.grocery.wallet.listAll.sortDirection = sortDirection
    draft.grocery.wallet.listAll.sortKey = sortKey
  }
}
