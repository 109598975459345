import { produce } from 'immer'

import { LOGIN_LOGGED_OUT } from '~/modules/auth/store/login/constants'

import * as types from './constants'
import { filterRoutes } from './helpers/filterRoutes'

const INITIAL_STATE = {
  modulesAccountId: null,
  modules: null,
  menu: null,
  loading: false,
  error: null
} as {
  modulesAccountId: string | null
  modules: AccessModulesResponse[]
  menu: Record<string, unknown>[]
  loading: boolean
  error: boolean | any[]
}

export default (
  state = INITIAL_STATE,
  action: ActionGeneric<
    AccessModulesResponse[],
    { accountType: AccountTypes; accountId: string }
  >
) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.ACCESS_MODULES_GET}_PENDING`: {
        draft.loading = true
        break
      }

      case `${types.ACCESS_MODULES_GET}_FULFILLED`: {
        const { accountType, accountId } = action.values

        const modules = action.payload as AccessModulesResponse[]

        draft.modulesAccountId = accountId
        draft.modules = modules

        const draftMenu = filterRoutes({ modules, accountType })

        draft.menu = draftMenu
        draft.loading = false
        break
      }

      case `${types.ACCESS_MODULES_GET}_REJECTED`: {
        draft.loading = false
        draft.error = action.payload
        break
      }

      //
      case `${LOGIN_LOGGED_OUT}`: {
        draft.modules = null
        break
      }

      default:
        return state
    }
  })
