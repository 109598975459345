import React from 'react'
import { FiImage } from 'react-icons/fi'
import { VscFilePdf } from 'react-icons/vsc'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { formattedMessageToHtml } from '~/helpers'

import useMsgCampaigns from '../../hooks/useMsgCampaigns'

import * as S from './styles'

const TemplateOption = ({ innerProps, data, isList }) => {
  const {
    label,
    buttons = null,
    message = null,
    hasImage,
    hasDocument,
    status,
    isDisabled
  } = data

  const { allowMediaOnTemplate } = useMsgCampaigns()

  return (
    <S.Container {...innerProps} isList={isList} isDisabled={isDisabled}>
      <header>
        <div>
          <strong className="titleColumn">{label} </strong>

          {allowMediaOnTemplate && hasImage && (
            <S.Tag>
              <FiImage />
              Permite imagem
            </S.Tag>
          )}

          {allowMediaOnTemplate && hasDocument && (
            <S.Tag>
              <VscFilePdf />
              Permite PDF
            </S.Tag>
          )}

          {status === 'in_review' && isDisabled && (
            <S.Tag template="warning">
              Esse template só poderá ser usado depois de aprovado pelo Facebook
            </S.Tag>
          )}
        </div>

        {!isList && !isDisabled && <Button text="Escolher" size="xsmall" />}
      </header>

      <S.Content>
        <div className="column message">
          <div>
            <p className="message">
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: formattedMessageToHtml(message)
                }}
              />
            </p>
          </div>
        </div>
        <div className="column buttons">
          <div>
            <strong className="titleColumn">Respostas rápidas</strong>
            {buttons?.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span className="button" key={index}>
                {item.text}
              </span>
            )) || (
              <small className="empty">
                Não tem botões cadastrados neste template
              </small>
            )}
          </div>
        </div>
      </S.Content>
    </S.Container>
  )
}

export default TemplateOption

TemplateOption.defaultProps = {
  isList: false,
  innerProps: {}
}

TemplateOption.propTypes = {
  innerProps: PropTypes.shape({}),
  data: PropTypes.shape({
    label: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
    hasImage: PropTypes.bool,
    hasDocument: PropTypes.bool,
    isDisabled: PropTypes.bool,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string
      })
    )
  }).isRequired,
  isList: PropTypes.bool
}
