import React from 'react';
import { VscFilePdf } from 'react-icons/vsc';

import PropTypes from 'prop-types';

const FilePDF = ({ data }) => {
  return (
    <div className="file_pdf">
      <a href={data.url} target="_blank" rel="noreferrer">
        <div className="file">
          <div className="icon">
            <VscFilePdf />
          </div>
          {data?.name && <div className="name">{data.name}</div>}
        </div>
      </a>
    </div>
  );
};

export default FilePDF;

FilePDF.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};
