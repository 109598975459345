import styled, { css } from 'styled-components'

export const Header = styled.header`
  .alertBrands {
    margin-bottom: 0.5rem;
  }
`

export const Image = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    /* margin-right: ${theme.spacings.medium}; */

    min-width: 4rem !important;
    max-width: 20rem !important;
    max-height: 4rem !important;
    width: 100%;

    border-radius: ${theme.border.radius.large};

    cursor: zoom-in;

    > img {
      min-width: 4rem !important;
      max-width: 20rem !important;
      max-height: 4rem !important;
      width: 100%;
    }

    .without-image {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 4rem;
      height: 4rem;

      background-color: ${theme.colors.light};
      color: ${theme.colors.primaryLighten30};
    }

    img {
      max-width: 3.75rem;
      max-height: 3.75rem;
      object-fit: contain;
      object-position: left;
    }
  `}
`

export const Container = styled.div`
  > .teste {
    background-color: red;
  }
`

export const ImageModal = styled.img`
  width: 100%;
  height: auto;
  margin-top: 1rem;
`

export const WrapperDestinationUrls = styled.section`
  .alertBrands {
    margin-bottom: 0.5rem;
  }

  .formGroup {
    & + .formGroup {
      margin-top: 0.5rem;
    }
  }
`
