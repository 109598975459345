import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import PropTypes from 'prop-types';

import { formatSearchString, queryDatesDatepickerIsolated } from '~/helpers';
import useFetchSWR from '~/hooks/useFetchSWR';
import {
  getOrdersList,
  getOrdersListByStatus,
  updateOrderStatusService,
} from '~/services/order-service';

import { statusListBoard } from '../_status/order';
import * as types from '../constants';

const OrderTableContext = createContext({});

const OrderTableProvider = ({ children }) => {
  /**
   * Armazena o conteúdo do primeiro carregamento. É importante para a validação nas atualizações.
   */
  const [initialData, setInitialData] = useState();
  /**
   * Responsável por bloquear o painel em carregamentos que impactam o quadro todo
   */
  const [loadingMaster, setLoadingMaster] = useState(true);
  /**
   * Ao mover um item, um objeto é gerado com informações sobre a ação.
   * Este é armazenado aqui para ser consultado pelos paineis.
   */
  const [resultDragging, setResultDragging] = useState();
  /**
   * Cópia dos dados do pedido que foi movido.
   */
  const [movedItem, setMovedItem] = useState();
  /**
   * Cópia dos dados do pedido que foi movido.
   */
  const [resultUpdateStatus, setResultUpdateStatus] = useState();
  /**
   * Chaves dos status que estão ativos. Ela sempre será repopulado com dados do local storage quando houver.
   */
  const [activeStatusKeys, setActiveStatusKeys] = useState(() => {
    const savedData = localStorage.getItem(
      types.LOCALSTORAGE_ORDER_TABLE_STATUS,
    );

    if (savedData) {
      const filteredData = JSON.parse(savedData).filter(item => !!item);
      if (filteredData.length) {
        return filteredData;
      }
    }

    return statusListBoard.map(status => status?.type);
  });
  /**
   * Objeto completo das colunas ativas.
   * Ele varre o `activeStatusKeys` e monta uma array com os valores necessários para montar cada painel
   */
  const activeColumns = useMemo(() => {
    const isActive = value =>
      activeStatusKeys.findIndex(item => item === value) > -1;

    const newData = statusListBoard.map(item => ({
      ...item,
      active: isActive(item.type),
    }));

    return newData;
  }, [activeStatusKeys]);

  /**
   * Gerenciando query string
   */

  const { datepickerHeader, filters, order } = useSelector(state => state);
  const { startDate, endDate } = datepickerHeader;
  const { listOrdersBoardPerPage } = order;

  const {
    locations: filteredLocations,
    channel: filteredChannel,
    status: filteredStatus,
    paymentStatus: filteredPaymentStatus,
    query,
  } = filters;

  const filteredLocationsIds = useMemo(
    () => filteredLocations?.value || null,
    [filteredLocations],
  );

  const filteredChannelId = useMemo(
    () => filteredChannel?.value || null,
    [filteredChannel],
  );

  const filteredStatusPaymentId = useMemo(
    () => filteredPaymentStatus?.value || null,
    [filteredPaymentStatus],
  );

  const filteredOrderStatus = useMemo(
    () => filteredStatus || null,
    [filteredStatus],
  );

  const filteredQuery = useMemo(
    () => query?.[`${types.ORDER_REDUX_QUERY}`] || null,
    [query],
  );

  const queryString = useCallback(
    ({ page, status } = { page: 1 }) =>
      formatSearchString({
        page,
        quantity: listOrdersBoardPerPage,
        location_id: [filteredLocationsIds],
        channel_id: filteredChannelId,
        status: status || activeStatusKeys,
        payment_status: filteredStatusPaymentId,
        query: filteredQuery,
        ...queryDatesDatepickerIsolated(startDate, endDate),
      }),
    [
      listOrdersBoardPerPage,
      filteredLocationsIds,
      filteredChannelId,
      activeStatusKeys,
      filteredStatusPaymentId,
      filteredQuery,
      startDate,
      endDate,
    ],
  );

  /**
   * @function handleactiveStatusKeys
   * Essa função é responsável por definir quais colunas ficarão visíveis.
   */

  const handleactiveStatusKeys = useCallback(({ filteredOrderStatus }) => {
    const updateList = filteredOrderStatus?.length
      ? filteredOrderStatus
      : statusListBoard;

    const list = updateList.map(status => status?.type);

    localStorage.setItem(
      types.LOCALSTORAGE_ORDER_TABLE_STATUS,
      JSON.stringify(list),
    );

    setActiveStatusKeys(list);
  }, []);

  useEffect(() => {
    if (filteredOrderStatus) {
      handleactiveStatusKeys({ filteredOrderStatus });
    }
  }, [filteredOrderStatus, handleactiveStatusKeys]);

  /**
   * @handle load initial data
   * Contexto responsável por definir quais colunas ficarão visíveis.
   */

  const {
    data: dataSWR,
    error: errorSWR,
    isValidating: isValidatingInitialData,
  } = useFetchSWR({
    url: `/search/orders-by-status?${queryString()}`,
    refreshInterval: 30000,
  });

  useEffect(() => {
    if (dataSWR) {
      setInitialData(dataSWR.data);

      setLoadingMaster(false);
    }
  }, [dataSWR]);

  /**
   * @function getInitialData
   * Essa função é responsável por pegar os dados iniciais para montar o quadro.
   * Ela passa os status selecionados e recebe um array separado por status
   */

  const getInitialData = useCallback(async () => {
    try {
      const { data } = await getOrdersListByStatus(queryString({ page: 1 }));

      setInitialData(data.data);

      setLoadingMaster(false);
    } catch (error) {
      console.log(error);
    }
  }, [queryString]);

  /**
   * @function getMoreOrders
   * Essa função é responsável por requisitar mais pedidos de um determinado status.
   */
  const getMoreOrders = useCallback(
    async ({ page, status }) => {
      try {
        const { data } = await getOrdersList(queryString({ page, status }));

        return data;
      } catch (error) {
        console.log(error);
      }
    },
    [queryString],
  );

  /**
   * @function handleOrderStatusOnDrag
   * Essa função é responsável por lidar com a mudança de status dos pedidos ao serem arrastados
   */
  const handleOrderStatusOnDrag = useCallback(async ({ result }) => {
    setResultDragging(result);

    const status = statusListBoard.find(
      item => item.id === result.destination.droppableId,
    );

    const movedItem = {
      toStatus: result.destination?.droppableId,
      fromStatus: result.source?.droppableId,
      orderId: result.draggableId,
    };

    const data = {
      status: {
        label: status.label,
        code: status.label,
        type: status.type,
      },
    };

    try {
      await updateOrderStatusService({
        id: result.draggableId,
        data,
      });

      setResultDragging();
      setResultUpdateStatus({ status: 'success', ...movedItem });
    } catch (error) {
      console.error(error);

      toastr.error(
        'Erro',
        error.response?.data?.error ||
          'Houve um erro ao atualizar o status do pedido.',
      );
      setResultDragging();
      setResultUpdateStatus({ status: 'error', ...movedItem });
    }
  }, []);

  return (
    <OrderTableContext.Provider
      value={{
        loadingMaster,
        initialData,
        resultDragging,
        activeStatusKeys,
        activeColumns,
        getInitialData,
        getMoreOrders,
        handleOrderStatusOnDrag,
        movedItem,
        resultUpdateStatus,
        setResultUpdateStatus,
        setMovedItem,
        isValidatingInitialData,
      }}
    >
      {children}
    </OrderTableContext.Provider>
  );
};

function useOrderTable() {
  const context = useContext(OrderTableContext);

  if (!context) {
    throw new Error('useOrderTable must be used within an OrderTableProvider');
  }

  return context;
}

export { OrderTableContext, OrderTableProvider, useOrderTable };

OrderTableProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
