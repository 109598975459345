import {
  FiCheck,
  FiCheckCircle,
  FiFileText,
  FiHome,
  FiList,
  FiPrinter,
  FiTruck,
  FiUser,
  FiWatch,
  FiXCircle,
  FiEye,
  FiPauseCircle,
  FiShoppingBag
} from 'react-icons/fi'

export const statusList = {
  PLACED: {
    type: 'order_placed',
    label: 'Pedido efetuado',
    code: 'Pedido efetuado',
    step: 0,
    canStartPicking: true,
    editable: false,
    template: 'info',
    icon: FiUser,
    inProgress: true
  },
  WAITING_CREATION_INTEGRATION: {
    type: 'waiting_creation_integration',
    label: 'Aguardando integração de criação',
    code: 'Aguardando integração de criação',
    step: 0,
    canStartPicking: false,
    editable: true,
    template: 'warning',
    icon: FiPauseCircle,
    inProgress: true
  },
  CONFIRMED: {
    type: 'confirmed_order',
    label: 'Pedido confirmado',
    code: 'Pedido confirmado',
    step: 0,
    canStartPicking: true,
    editable: true,
    template: 'warning',
    icon: FiCheck,
    inProgress: true
  },
  WAITING_PICKING: {
    type: 'waiting_picking',
    label: 'Aguardando separação',
    code: 'Aguardando separação',
    step: 1,
    canStartPicking: true,
    editable: true,
    template: 'warning',
    icon: FiWatch,
    inProgress: true
  },
  PRE_PICKING: {
    type: 'pre_picking',
    label: 'Pré-separação',
    code: 'Pré-separação',
    step: 2,
    canStartPicking: true,
    editable: true,
    template: 'warning',
    icon: FiFileText,
    inProgress: true
  },
  IN_PICKING: {
    type: 'in_picking',
    label: 'Em separação',
    code: 'Em separação',
    step: 2,
    canStartPicking: true,
    editable: true,
    template: 'warning',
    icon: FiList,
    inProgress: true
  },
  FINISHED_PICKING: {
    type: 'finished_picking',
    label: 'Separação finalizada',
    code: 'Separação finalizada',
    step: 2,
    canStartPicking: false,
    editable: false,
    template: 'info',
    icon: FiCheck,
    inProgress: true
  },
  WAITING_INVOICE: {
    type: 'waiting_invoice',
    label: 'Aguardando NF',
    code: 'Aguardando NF',
    step: 3,
    canStartPicking: false,
    editable: true,
    template: 'warning',
    icon: FiWatch,
    inProgress: true
  },
  INVOICE_ISSUED: {
    type: 'invoice_issued',
    label: 'NF emitida',
    code: 'NF emitida',
    step: 3,
    canStartPicking: false,
    editable: true,
    template: 'info',
    icon: FiPrinter,
    inProgress: true
  },
  WAITING_PICK_UP: {
    type: 'waiting_pick_up',
    label: 'Aguardando retirada',
    code: 'Aguardando retirada',
    step: 4,
    canStartPicking: false,
    editable: true,
    template: 'warning',
    icon: FiWatch
  },
  WAITING_DELIVERY: {
    type: 'waiting_delivery',
    label: 'Aguardando entrega',
    code: 'Aguardando entrega',
    step: 4,
    canStartPicking: false,
    editable: true,
    template: 'warning',
    icon: FiWatch,
    inProgress: true
  },
  WAITING_PAYMENT: {
    type: 'waiting_payment',
    label: 'Aguardando pagamento',
    code: 'Aguardando pagamento',
    step: 5,
    canStartPicking: false,
    editable: true,
    template: 'warning',
    icon: FiWatch,
    inProgress: true
  },
  PAYMENT_IN_ANALYSIS: {
    type: 'payment_in_analysis',
    label: 'Pagamento em análise',
    code: 'Pagamento em análise',
    step: 6,
    canStartPicking: false,
    editable: false,
    template: 'warning',
    icon: FiEye,
    inProgress: true
  },
  DELIVERY_ACCEPTED: {
    type: 'delivery_accepted',
    label: 'Entrega coletada',
    code: 'Entrega coletada',
    step: 7,
    canStartPicking: false,
    editable: true,
    template: 'warning',
    icon: FiTruck
  },
  IN_DELIVERY: {
    type: 'in_delivery',
    label: 'Em entrega',
    code: 'Em entrega',
    step: 8,
    canStartPicking: false,
    editable: true,
    template: 'warning',
    icon: FiTruck,
    inProgress: false
  },
  DELIVERED: {
    type: 'order_delivered',
    label: 'Pedido entregue',
    code: 'Pedido entregue',
    step: 9,
    canStartPicking: false,
    editable: true,
    template: 'success',
    icon: FiHome,
    inProgress: false
  },
  FINISHED: {
    type: 'finished',
    label: 'Atendido',
    code: 'Atendido',
    step: 0,
    canStartPicking: false,
    editable: true,
    template: 'success',
    icon: FiCheckCircle,
    inProgress: false
  },
  canceled: {
    type: 'order_canceled',
    label: 'Pedido cancelado',
    code: 'Pedido cancelado',
    step: 0,
    canStartPicking: false,
    editable: true,
    template: 'danger',
    icon: FiXCircle,
    inProgress: false
  },
  cancelled: {
    type: 'order_cancelled',
    label: 'Pedido cancelado',
    code: 'Pedido cancelado',
    step: 0,
    canStartPicking: false,
    editable: false,
    template: 'danger',
    icon: FiXCircle,
    inProgress: false
  },
  WAITING_CAPTURE: {
    type: 'waiting_capture',
    label: 'Aguardando captura',
    code: 'Aguardando captura',
    step: 0,
    canStartPicking: false,
    editable: false,
    template: 'warning',
    icon: FiWatch,
    inProgress: true
  },
  FINISHED_CAPTURE: {
    type: 'finished_capture',
    label: 'Captura finalizada',
    code: 'Captura finalizada',
    step: 0,
    canStartPicking: false,
    editable: false,
    template: 'warning',
    icon: FiCheck,
    inProgress: true
  }
}

export const statusInProgress = Object.values(statusList)
  .filter(status => status?.inProgress)
  .map(item => item.type)

export const statusListOptionsSelect = Object.values(statusList).map(
  status => ({
    id: status.type,
    label: status.label,
    value: status.type,
    type: status.type,
    editable: status.editable,
    step: status.step
  })
)

export const statusListBoard = Object.values(statusList).map(status => ({
  id: status.type,
  label: status.label,
  type: status.type,
  value: status.type,
  template: status.template,
  editable: status.editable,
  active: true
}))
