import React, { useMemo } from 'react';
import { FiFileText } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import { Icon } from '~/components/Icon';
import Loading from '~/components/Loading';
import { Content, Header, WrapperShadow } from '~/components/WrapperShadow';

const PanelDescription = () => {
  const {
    getOrderLoading,
    orderData: { items, channel_id: channelID, description },
  } = useSelector(state => state.order);

  /**
   * Checa se o id do produto é 'cesta-presentes-personalizada' pois nesse caso
   * o texto vem no formato livre e deve ser mostrado como um parágrafo <p>.
   */
  const isFreeDescription = useMemo(
    () => items?.[0]?.product_id === 'cesta-presentes-personalizada',
    [items],
  );

  return (
    <WrapperShadow>
      <Header>
        <div>
          <span>
            <Icon icon={FiFileText} /> <strong>Descrição</strong>
          </span>
        </div>
      </Header>

      <Loading status={getOrderLoading}>
        Carregando informações do pedido
      </Loading>

      {!getOrderLoading && (
        <Content className="description">
          {channelID === 'purchase' ? (
            <>
              {isFreeDescription ? (
                <p>{description}</p>
              ) : (
                <ul>
                  {description?.split(',').map(item => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              )}
            </>
          ) : (
            description
          )}
        </Content>
      )}
    </WrapperShadow>
  );
};

export default PanelDescription;
