import styled, { css } from 'styled-components'

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const slotAlertModifiers = {
  warning: theme => css`
    background-color: ${theme.colors.warningPure};
  `,
  danger: theme => css`
    background-color: ${theme.colors.danger};
  `,
  alert: theme => css`
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    background-color: ${theme.colors.dangerSaturate20};
  `
}

export const SlotAlert = styled.small`
  ${({ theme, template, isPast }) => css`
    display: inline-flex;

    padding: 0.125rem 0.25rem;
    margin-top: 0.125rem;

    font-weight: 600;

    border-radius: 0.25rem;

    color: white;

    ${template == 'danger' && slotAlertModifiers.danger(theme)}
    ${template == 'warning' && slotAlertModifiers.warning(theme)}
    ${isPast && slotAlertModifiers.alert(theme)}
  `}
`
