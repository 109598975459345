import styled from 'styled-components';

export const WrapperForm = styled.div`
  .defaultColors {
    display: flex;

    > * + * {
      margin-left: 0.5rem;
    }
  }

  .formGroup,
  footer {
    margin-top: 0.5rem;
  }

  footer {
    display: flex;
    justify-content: space-between;
  }
`;
