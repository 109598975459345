import styled, { DefaultTheme, css } from 'styled-components'

type ContainerProps = {
  isValid: boolean
}

const containerModifiers = {
  invalid: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.danger};
    background-color: ${theme.colors.dangerLighten40};
    order: 1;

    strong {
      code {
        color: ${theme.colors.dangerSaturate20};
      }
    }

    .imageWrapper {
      width: auto;
      margin-right: 0.5rem;

      img {
        width: 5rem;
        height: 5rem;
      }
    }
  `
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, isValid }) => css`
    display: flex;
    order: 0;

    margin-top: ${theme.spacings.xxxsmall};

    background-color: ${theme.colors.light};
    border-radius: 0.5rem;
    padding: ${theme.spacings.tiny};

    .imageWrapper {
      width: auto;
      margin-right: 0.5rem;

      img {
        object-fit: contain;
        width: 10rem;
        height: 10rem;
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.neutral.high.medium};
        padding: 0.25rem;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    ${!isValid && containerModifiers.invalid(theme)}
  `}
`
