import styled, { css } from 'styled-components'

const sizeVars = {
  small: () => css`
    --toggleWidth: 2.5rem;
    --toggleHeight: 1.25rem;
    --toggleInnerRadius: 1rem;
  `,
  medium: () => css`
    --toggleWidth: 3rem;
    --toggleHeight: 1.5rem;
    --toggleInnerRadius: 1.25rem;
  `
}

export const Container = styled.div<ContainerStyleProps>`
  ${({ theme, isLoading, size }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ${sizeVars[size]}

    label {
      font-size: 0.75rem;
      margin: 0 0.5rem 0 0;
    }

    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      width: var(--toggleWidth);
      height: var(--toggleHeight);
      border-radius: 1rem;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 0;
      background: ${theme.primary};
      transition: all 0.2s ease;
      box-shadow: 0 6px 16px -3px rgba(44, 44, 44, 0.1);

      &:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: var(--toggleInnerRadius);
        height: var(--toggleInnerRadius);
        border-radius: 50%;
        background: white;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.5);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }

      &:disabled {
        background: ${theme.colors.primaryLighten30};
      }

      &:checked {
        background: ${theme.success};
        &:after {
          transform: translatex(
            calc(var(--toggleWidth) - var(--toggleInnerRadius) - 4px)
          );
        }

        &:disabled {
          background: ${theme.colors.successLighten20};
        }
      }

      ${isLoading &&
      css`
        background: ${theme.warning} !important;
      `}
    }
  `}
`
