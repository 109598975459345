import { getRetailMediaAccountType } from '~/helpers'

import AdvertiserTransactions from './AdvertiserTransactions'
import PublisherTransactions from './PublisherTransactions'

const WalletView: React.FC = () => {
  const isPublisher = getRetailMediaAccountType() === 'publisher'
  return isPublisher ? <PublisherTransactions /> : <AdvertiserTransactions />
}

export default WalletView
