import { produce } from 'immer'

import { FormatDataBarChartComposed, listColorsChart } from '~/helpers'

import * as types from './constants'

const INITIAL_STATE = {
  cardsInfo: {
    data: [
      {
        'Orders.margin': 0,
        'Orders.gmv': 0,
        'Orders.ordersCount': 0,
        'Orders.averageTicket': 0
      }
    ]
  },
  orderData: {},
  cardsLoading: false,
  ordersPerDayInfo: {},
  ordersPerDayInfoDates: [],
  ordersPerDayLoading: false,
  topChannelsInfoList: [],
  topChannelsLoading: false,
  topChannelsChartData: {
    labels: [],
    datasets: []
  },
  topPaymentsInfoList: [],
  topPaymentsChartData: {
    labels: [],
    datasets: []
  },
  topPaymentsLoading: false,
  topProductsInfoList: [],
  ordersList: [],
  ordersListFilters: [],
  listOrdersLoading: true,
  listOrdersPage: 1,
  listOrdersPerPage: 100,
  listOrdersBoardPerPage: 50,
  error: null,

  getOrderLoading: false,
  cancelOrderLoading: false,
  refreshOrderLoading: false,
  updateLoading: false,
  loadingDelete: false,
  errorPaymentCancel: false,
  loadingPaymentCancel: false,

  loadingVerifyRefusal: false,

  // External delivery
  loadingRequestExternalDelivery: false,
  loadingCancelExternalDelivery: false,

  loadingErrorExternalDelivery: false,
  externalErrorDeliveryData: null,

  //
  loadingApproverFraudAnalysis: false,
  errorApproverFraudAnalysis: null,
  // Update recipient full name
  loadingUpdateRecipientFullName: false
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      // List orders
      case `${types.ORDERS_LIST}_PENDING`:
        draft.listOrdersLoading = true
        break

      case `${types.ORDERS_LIST}_FULFILLED`:
        draft.ordersList = action.payload
        draft.listOrdersLoading = false
        draft.listOrdersPage = action.page
        break

      case `${types.ORDERS_LIST}_REJECTED`:
        draft.listOrdersLoading = false
        draft.error = action.payload
        break

      // Show order
      case `${types.ORDER_GET_INFO}_PENDING`: {
        draft.getOrderLoading = true
        draft.orderData = {}
        draft.error = null
        break
      }
      case `${types.ORDER_GET_INFO}_FULFILLED`: {
        draft.orderData = action.payload
        draft.externalErrorDeliveryData = null
        draft.getOrderLoading = false
        draft.error = null
        break
      }
      case `${types.ORDER_GET_INFO}_REJECTED`: {
        draft.error = action.payload
        draft.getOrderLoading = false
        break
      }

      // Cancel Order
      case `${types.CANCEL_ORDER}_PENDING`: {
        draft.cancelOrderLoading = true
        draft.error = null
        break
      }
      case `${types.CANCEL_ORDER}_FULFILLED`: {
        draft.cancelOrderLoading = false
        draft.error = null
        break
      }
      case `${types.CANCEL_ORDER}_REJECTED`: {
        draft.error = action.payload
        draft.cancelOrderLoading = false
        break
      }

      // Auto refresh order
      case `${types.ORDER_GET_INFO_REFRESH}_PENDING`: {
        draft.refreshOrderLoading = true
        draft.error = null
        break
      }
      case `${types.ORDER_GET_INFO_REFRESH}_FULFILLED`: {
        draft.orderData = action.payload
        draft.refreshOrderLoading = false
        draft.error = null
        break
      }
      case `${types.ORDER_GET_INFO_REFRESH}_REJECTED`: {
        draft.error = action.payload
        draft.refreshOrderLoading = false
        break
      }

      // Clear order data
      case types.ORDER_CLEAR_INFO:
        draft.orderData = {}
        break

      // UPDATE order
      case `${types.ORDER_UPDATE_INFO}_PENDING`: {
        draft.updateLoading = true
        break
      }
      case `${types.ORDER_UPDATE_INFO}_FULFILLED`: {
        draft.updateLoading = false
        draft.error = null
        break
      }
      case `${types.ORDER_UPDATE_INFO}_REJECTED`: {
        draft.error = action.payload
        draft.updateLoading = false
        break
      }

      // UPDATE order discount
      case `${types.ORDER_UPDATE_DISCOUNT}_PENDING`: {
        draft.updateLoading = true
        break
      }
      case `${types.ORDER_UPDATE_DISCOUNT}_FULFILLED`: {
        draft.updateLoading = false
        draft.orderData = action.payload
        draft.error = null
        break
      }
      case `${types.ORDER_UPDATE_DISCOUNT}_REJECTED`: {
        draft.error = action.payload
        draft.updateLoading = false
        break
      }

      // UPDATE items order
      case `${types.ORDER_ITEMS_UPDATE}_PENDING`: {
        draft.updateLoading = true
        break
      }
      case `${types.ORDER_ITEMS_UPDATE}_FULFILLED`: {
        draft.updateLoading = false
        draft.orderData = action.payload
        draft.error = null
        break
      }
      case `${types.ORDER_ITEMS_UPDATE}_REJECTED`: {
        draft.error = action.payload
        draft.updateLoading = false
        break
      }

      // UPDATE order status
      case `${types.ORDER_UPDATE_STATUS}_PENDING`: {
        draft.updateLoading = true
        break
      }
      case `${types.ORDER_UPDATE_STATUS}_FULFILLED`: {
        draft.updateLoading = false
        draft.error = null
        break
      }
      case `${types.ORDER_UPDATE_STATUS}_REJECTED`: {
        draft.error = action.payload
        draft.updateLoading = false
        break
      }

      // Delete Order
      case `${types.ORDER_DELETE}_PENDING`: {
        draft.loadingDelete = true
        break
      }
      case `${types.ORDER_DELETE}_FULFILLED`: {
        draft.loadingDelete = false
        draft.error = null
        break
      }
      case `${types.ORDER_DELETE}_REJECTED`: {
        draft.error = action.payload
        draft.loadingDelete = false
        break
      }

      // Cancel payment
      case `${types.ORDER_PAYMENT_CANCEL}_PENDING`: {
        draft.loadingPaymentCancel = true
        break
      }
      case `${types.ORDER_PAYMENT_CANCEL}_FULFILLED`: {
        draft.errorPaymentCancel = null
        draft.loadingPaymentCancel = false
        break
      }
      case `${types.ORDER_PAYMENT_CANCEL}_REJECTED`: {
        draft.errorPaymentCancel = action.payload
        draft.loadingPaymentCancel = false
        break
      }

      // Approve fraud analysis
      case `${types.APPROVE_FRAUD_ANALYSIS}_PENDING`: {
        draft.loadingApproverFraudAnalysis = true
        break
      }
      case `${types.APPROVE_FRAUD_ANALYSIS}_FULFILLED`: {
        draft.errorApproverFraudAnalysis = null
        draft.loadingApproverFraudAnalysis = false
        break
      }
      case `${types.APPROVE_FRAUD_ANALYSIS}_REJECTED`: {
        draft.errorApproverFraudAnalysis = action.payload
        draft.loadingApproverFraudAnalysis = false
        break
      }

      // Delete Order Item
      case `${types.ORDER_ITEM_DELETE}_PENDING`: {
        draft.loadingDelete = true
        break
      }
      case `${types.ORDER_ITEM_DELETE}_FULFILLED`: {
        draft.loadingDelete = false
        break
      }
      case `${types.ORDER_ITEM_DELETE}_REJECTED`: {
        draft.loadingDelete = false
        break
      }

      // Verify payment refusal
      case `${types.PAYMENT_VERIFY_REFUSAL}_PENDING`: {
        draft.loadingVerifyRefusal = true
        break
      }
      case `${types.PAYMENT_VERIFY_REFUSAL}_FULFILLED`: {
        draft.loadingVerifyRefusal = false
        break
      }
      case `${types.PAYMENT_VERIFY_REFUSAL}_REJECTED`: {
        draft.loadingVerifyRefusal = false
        break
      }

      // Request external delivery
      case `${types.REQUEST_EXTERNAL_DELIVERY}_PENDING`: {
        draft.loadingRequestExternalDelivery = true
        break
      }
      case `${types.REQUEST_EXTERNAL_DELIVERY}_FULFILLED`: {
        const updatedOrder = {
          ...draft.orderData,
          shipments: [
            {
              ...draft.orderData.shipments[0],
              status: action.payload.status
            }
          ]
        }

        draft.loadingRequestExternalDelivery = false
        draft.orderData = updatedOrder
        break
      }
      case `${types.REQUEST_EXTERNAL_DELIVERY}_REJECTED`: {
        draft.loadingRequestExternalDelivery = false
        break
      }

      // Request external delivery
      case `${types.REQUEST_ERROR_EXTERNAL_DELIVERY}_PENDING`: {
        draft.loadingErrorExternalDelivery = true
        break
      }
      case `${types.REQUEST_ERROR_EXTERNAL_DELIVERY}_FULFILLED`: {
        draft.loadingErrorExternalDelivery = false
        draft.externalErrorDeliveryData =
          action.payload?.[0]?.refusal_errors?.[0] || null
        break
      }
      case `${types.REQUEST_ERROR_EXTERNAL_DELIVERY}_REJECTED`: {
        draft.loadingErrorExternalDelivery = false
        break
      }

      // Request external delivery
      case `${types.CANCEL_EXTERNAL_DELIVERY}_PENDING`: {
        draft.loadingCancelExternalDelivery = true
        break
      }
      case `${types.CANCEL_EXTERNAL_DELIVERY}_FULFILLED`: {
        const newStatus = action.payload.status

        const updatedOrder = {
          ...draft.orderData,
          shipments: [
            {
              ...draft.orderData.shipments[0],
              status: newStatus
            }
          ]
        }

        draft.loadingCancelExternalDelivery = false
        draft.orderData = updatedOrder
        break
      }
      case `${types.CANCEL_EXTERNAL_DELIVERY}_REJECTED`: {
        draft.loadingCancelExternalDelivery = false
        break
      }

      /**
       * Update recipient full name
       */

      case `${types.ORDER_UPDATE_RECIPIENT}_PENDING`: {
        draft.loadingUpdateRecipientFullName = true
        break
      }

      case `${types.ORDER_UPDATE_RECIPIENT}_FULFILLED`: {
        const updatedOrder = action.payload

        draft.orderData = updatedOrder
        draft.loadingUpdateRecipientFullName = false
        break
      }

      case `${types.ORDER_UPDATE_RECIPIENT}_REJECTED`: {
        draft.loadingUpdateRecipientFullName = false
        break
      }

      // ORDERS_LIST_SET_FILTERS
      case types.ORDERS_LIST_SET_FILTERS:
        draft.ordersListFilters = action.filters
        break

      // ORDERS_CARDS
      case `${types.ORDERS_CARDS}_PENDING`:
        draft.cardsLoading = true
        break

      case `${types.ORDERS_CARDS}_FULFILLED`:
        draft.cardsInfo = action.payload.data.reduce((acc, el) => {
          acc[`${[el['OrderStatuses.label']]}`] = el['Orders.ordersCount']
          return acc
        }, {})
        draft.cardsLoading = false
        break

      case `${types.ORDERS_CARDS}_REJECTED`:
        draft.cardsLoading = false
        draft.error = action.payload
        break

      // ORDERS_PER_DAY
      case `${types.ORDERS_PER_DAY}_PENDING`: {
        draft.ordersPerDayLoading = true
        break
      }
      case `${types.ORDERS_PER_DAY}_FULFILLED`: {
        const { finalData, dates } = FormatDataBarChartComposed({
          data: action.payload.data,
          dateKey: 'Orders.closedAt',
          labelKey: 'OrderStatuses.label',
          countKey: 'Orders.ordersCount'
        })

        draft.ordersPerDayInfo = finalData
        draft.ordersPerDayInfoDates = dates
        draft.ordersPerDayLoading = false
        break
      }

      case `${types.ORDERS_PER_DAY}_REJECTED`:
        draft.ordersPerDayLoading = false
        draft.error = action.payload
        break

      // ORDERS_TOP_PRODUCTS
      case `${types.ORDERS_TOP_PRODUCTS}_PENDING`:
        draft.topProductsLoading = true
        break

      case `${types.ORDERS_TOP_PRODUCTS}_FULFILLED`: {
        draft.topProductsInfoList = action.payload.data
        draft.topProductsLoading = false
        break
      }
      case `${types.ORDERS_TOP_PRODUCTS}_REJECTED`:
        draft.topProductsLoading = false
        draft.error = action.payload
        break

      // ORDERS_TOP_CHANNELS
      case `${types.ORDERS_TOP_CHANNELS}_PENDING`:
        draft.topChannelsLoading = true
        break

      case `${types.ORDERS_TOP_CHANNELS}_FULFILLED`: {
        draft.topChannelsInfoList = action.payload.data.map((el, index) => ({
          ...el,
          color: listColorsChart[index]
        }))
        draft.topChannelsLoading = false

        const topChannelsDataLabels = []
        const topPaymentsDataLabels = []

        action.payload.data.forEach(el => {
          topChannelsDataLabels.push(el['Orders.channelId'])
          topPaymentsDataLabels.push(el['Orders.ordersCount'])
        })

        draft.topChannelsChartData.labels = topChannelsDataLabels
        draft.topChannelsChartData.datasets = topPaymentsDataLabels
        break
      }

      case `${types.ORDERS_TOP_CHANNELS}_REJECTED`:
        draft.topChannelsLoading = false
        draft.error = action.payload
        break

      // ORDERS_TOP_PAYMENTS
      case `${types.ORDERS_TOP_PAYMENTS}_PENDING`:
        draft.topPaymentsLoading = true
        break

      case `${types.ORDERS_TOP_PAYMENTS}_FULFILLED`: {
        const topPaymentsDataLabels = []
        const topPaymentsDataDatasets = []
        action.payload.data.forEach(el => {
          topPaymentsDataLabels.push(el['OrderPayments.method'])
          topPaymentsDataDatasets.push(el['Orders.ordersCount'])
        })

        draft.topPaymentsChartData.labels = topPaymentsDataLabels
        draft.topPaymentsChartData.datasets = topPaymentsDataDatasets
        draft.topPaymentsInfoList = action.payload.data.map((el, index) => ({
          ...el,
          color: listColorsChart[index]
        }))
        draft.topPaymentsLoading = false
        break
      }

      case `${types.ORDERS_TOP_PAYMENTS}_REJECTED`:
        draft.topPaymentsLoading = false
        draft.error = action.payload
        break

      default:
        return state
    }
  })
