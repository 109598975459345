import React, { useCallback } from 'react'
import {
  AiOutlineBold,
  AiOutlineCode,
  AiOutlineItalic,
  AiOutlineSmile,
  AiOutlineStrikethrough
} from 'react-icons/ai'

import PropTypes from 'prop-types'
import { Transforms, Editor, Text } from 'slate'

import { Button } from '~/components/Buttons'

export const ToolBar = ({ editor, toggleEmoji }) => {
  /**
   *
   */
  const handleBold = useCallback(() => {
    const [match] = Editor.nodes(editor, {
      match: n => n.bold
    })

    Transforms.setNodes(
      editor,
      { bold: !match },
      { match: n => Text.isText(n), split: true }
    )
  }, [editor])

  /**
   *
   */
  const handleItalic = useCallback(() => {
    const [match] = Editor.nodes(editor, {
      match: n => n.italic
    })

    Transforms.setNodes(
      editor,
      { italic: !match },
      { match: n => Text.isText(n), split: true }
    )
  }, [editor])

  /**
   *
   */
  const handleStriketrough = useCallback(() => {
    const [match] = Editor.nodes(editor, {
      match: n => n.overline
    })

    Transforms.setNodes(
      editor,
      { overline: !match },
      { match: n => Text.isText(n), split: true }
    )
  }, [editor])

  /**
   *
   */
  const handleCode = useCallback(() => {
    const [match] = Editor.nodes(editor, {
      match: n => n.code
    })

    Transforms.setNodes(
      editor,
      { code: !match },
      { match: n => Text.isText(n), split: true }
    )
  }, [editor])

  return (
    <div className="toolBar">
      <Button
        text={<AiOutlineBold />}
        template="transparentDark"
        tooltip="Negrito"
        onClick={handleBold}
      />
      <Button
        text={<AiOutlineItalic />}
        template="transparentDark"
        tooltip="Itálico"
        onClick={handleItalic}
      />
      <Button
        text={<AiOutlineStrikethrough />}
        template="transparentDark"
        tooltip="Tachado"
        onClick={handleStriketrough}
      />
      <Button
        text={<AiOutlineCode />}
        template="transparentDark"
        tooltip="Código"
        onClick={handleCode}
      />
      <Button
        text={<AiOutlineSmile />}
        template="transparentDark"
        onClick={toggleEmoji}
        tooltip="Emoji"
      />
    </div>
  )
}

ToolBar.propTypes = {
  toggleEmoji: PropTypes.func.isRequired,
  editor: PropTypes.shape({}).isRequired
}
