import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '~/store/hooks'
import { setOrderChatsBy } from '~/modules/chat/store/actions'

import { chatSettings } from '../../settings'

import * as S from './styles'

const SortChats = () => {
  const [dropOpen, setDropOpen] = useState(false)
  const { sortChatsBy, selectedStatus } = useAppSelector(state => state.chats)
  const dispatch = useDispatch()

  const options = useMemo(
    () =>
      Object.entries(chatSettings.sortChatsBy).map(([_, value]) => ({
        ...value,
        selected: sortChatsBy === value.value
      })),
    [sortChatsBy]
  )

  const selectedLabel = useMemo(
    () => chatSettings?.sortChatsBy?.[sortChatsBy].label || sortChatsBy,
    [sortChatsBy]
  )

  const handleOpen = useCallback(() => {
    setDropOpen(o => !o)
  }, [])

  const handleChangeSortRule = useCallback(
    (value: SortChatByKeys) => {
      dispatch(setOrderChatsBy({ sortChatsByKey: value }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (selectedStatus !== 'waiting_contact') {
      dispatch(setOrderChatsBy({ sortChatsByKey: 'desc' }))
    } else {
      dispatch(setOrderChatsBy({ sortChatsByKey: 'asc' }))
    }
  }, [dispatch, selectedStatus])

  return (
    <S.Container isOpen={dropOpen} onClick={handleOpen}>
      <S.Wrapper>
        <S.Button type="button">
          <span className="title">Ordenar por:</span>
          <S.SelectedType>
            <span className="text">{selectedLabel}</span>
            <span className="icon">
              <FiChevronDown />
            </span>
          </S.SelectedType>
        </S.Button>

        <S.DropMenu isOpen={dropOpen}>
          {options.map(item => (
            <S.DropItem isActive={item.selected} key={item.value}>
              <button
                onClick={() => handleChangeSortRule(item.value)}
                type="button"
              >
                <span className="text">{item.label}</span>
              </button>
            </S.DropItem>
          ))}
        </S.DropMenu>
      </S.Wrapper>
    </S.Container>
  )
}

export default SortChats
