import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const RowWrapper = styled.div`
  ${({ theme }) => css`
    .LoadingContainer {
      margin-bottom: 0;
    }

    button + button {
      margin-left: 0.5rem;
    }

    &.hasBorder {
      & + .hasBorder {
        border-top: 1px solid ${theme.colors.tertiary};
        padding-top: 1rem;
      }
    }

    &:not(:first-child) {
      margin-top: 1rem;
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    &:not(:first-child) {
      margin-top: 0.5rem;
    }

    .inputContainer {
      margin-top: 0.25rem;
    }

    > strong {
      color: ${theme.colors.primary};
      margin-bottom: 0.25rem;
    }

    p {
      margin: 0;
      line-height: 1;

      + p {
        margin-top: 0.25rem;
      }

      .icon {
        &.success {
          color: ${theme.colors.success};
        }
      }
    }

    .dateLabel::first-letter {
      text-transform: capitalize;
    }

    strong {
      &.tag {
        color: ${theme.colors.white};
        font-size: ${theme.font.sizes.xsmall};
        padding: 0.125rem 0.5rem;
        border-radius: 0.5rem;
      }
      &.success {
        background: ${theme.colors.success};
      }
      &.danger {
        background: ${theme.colors.danger};
      }
    }

    .budget-alert {
      color: ${theme.colors.warning};
      border-color: ${theme.colors.warning};
    }

    .cpc-info {
      display: flex;
      > svg {
        margin-left: 0.25rem;
        margin-bottom: auto;
      }
    }
  `}
`

export const GroupWrapper = styled.div`
  ${({ theme }) => css`
    @media ${device.laptop} {
      display: flex;
      gap: 1.5rem;
      flex-wrap: wrap;
    }

    > ${Wrapper} {
      &:not(:first-child) {
        border: solid ${theme.colors.tertiary};
        border-width: 1px 0 0 0;
        margin: 0.125rem 0 0 0;
        padding: 0.125rem 0 0 0;

        @media ${device.laptop} {
          border-width: 0 0 0 1px;
          /* margin: 0 0 0 1.5rem; */
          padding: 0 0 0 1.5rem;
        }
      }
    }
  `}
`

export const Text = styled.strong`
  display: flex;
  > div {
    margin-top: -0.1rem;
  }
`

export const ContainerProgress = styled.div`
  ${({ theme }) => css`
    width: 15rem;
    margin-left: ${theme.spacings.medium};
    padding: ${theme.spacings.xxxsmall};

    border: 1px solid ${theme.colors.neutral.high.dark};
    border-radius: ${theme.border_radius};
    background-color: ${theme.colors.neutral.high.lightest};

    header {
      display: flex;
      justify-content: space-between;

      .column {
        .title {
          color: ${theme.colors.neutral.low.light};
          font-size: ${theme.font.sizes.xxsmall};
          margin: 0;
        }

        .value {
          color: ${theme.colors.neutral.low.dark};
          font-size: ${theme.font.sizes.small};
        }
      }
    }
  `}
`
