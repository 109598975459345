import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ButtonNavLink } from '~/components/Buttons'
import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { history } from '~/helpers'

import OperatorsTable from './elements/OperatorsTable'

const OperatorsList = () => {
  const { locationId } = useParams()

  const { locations: filteredLocations } = useSelector(state => state.filters)

  const filteredLocationLabel = useMemo(
    () => filteredLocations?.label || null,
    [filteredLocations]
  )

  const titlePage = useMemo(
    () => `Operadores ${filteredLocationLabel || ''} `,
    [filteredLocationLabel]
  )

  const handleChangeLocation = data =>
    history.push(`/operators/list/${data?.value || ''}`)

  return (
    <>
      <PageTitle title={`${titlePage}> Listagem`} />

      <HeaderPages
        title={titlePage}
        sideRightComponent={
          <ButtonNavLink
            template="primary"
            text="Adicionar operador"
            to="/operators/new"
          />
        }
      />

      <Filters
        locations
        initialLocation={locationId}
        onChangeLocation={handleChangeLocation}
      />

      <OperatorsTable />
    </>
  )
}

export default OperatorsList
