import { listAll } from './listAll'
import { listTransaction } from './listTransaction'
import { recharge } from './recharge'
import { transaction } from './transaction'

export default {
  ...listAll,
  ...listTransaction,
  ...recharge,
  ...transaction
}
