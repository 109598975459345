import { useState, useCallback } from 'react'
import { FiEdit, FiX } from 'react-icons/fi'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import i18next from 'i18next'

import { Button, ButtonNavLink } from '~/components/Buttons'
import DeleteConfirmation from '~/components/DeleteConfirmation'
import {
  deletePlacementService,
  listPlacementQueryKey
} from '~/modules/retailMedia/services/placements'

export const PlacementsActionColumn = ({ rowData }: { rowData: Placement }) => {
  const [loading, setLoading] = useState(false)
  const [deleteMode, setDeleteMode] = useState(false)

  const queryClient = useQueryClient()

  const deletePlacementMutation = useMutation({
    mutationFn: deletePlacementService,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [listPlacementQueryKey] })
      toastr.success(
        i18next.t('rm:placements.remove.messages.success.title'),
        i18next.t('rm:placements.remove.messages.success.message', {
          name: rowData.name
        })
      )
    },
    onError: () => {
      toastr.error(
        i18next.t('common:messages.errorOccurred.title'),
        i18next.t('rm:placements.remove.messages.error.message')
      )
    }
  })

  const handleDelete = useCallback(async () => {
    setLoading(true)

    try {
      await deletePlacementMutation.mutateAsync(rowData.id)
    } finally {
      setLoading(false)
    }
  }, [rowData, deletePlacementMutation])

  return (
    <>
      {!deleteMode && (
        <div className="d-flex gap-2">
          <ButtonNavLink
            to={`${rowData.id}/edit`}
            template="light"
            iconLeft={<FiEdit />}
            text={i18next.t('common:actions.edit')}
            size="xsmall"
            customWidth="auto"
          />
          <Button
            template="deleteOutline"
            iconLeft={<FiX />}
            text={i18next.t('common:actions.remove')}
            textLoading={i18next.t('common:actions.removing')}
            size="xsmall"
            customWidth="auto"
            onClick={() => setDeleteMode(true)}
          />
        </div>
      )}

      <DeleteConfirmation
        active={deleteMode}
        onDelete={handleDelete}
        onCancel={() => setDeleteMode(false)}
        yesMsg={i18next.t('rm:placements.remove.messages.confirm.yes')}
        loading={loading}
      >
        {i18next.t('rm:placements.remove.messages.confirm.message', {
          name: rowData.name
        })}
      </DeleteConfirmation>
    </>
  )
}
