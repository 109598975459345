import { dashboardPublisherListModel } from '~/modules/retailMedia/dtos/industry/publisher/list'

import { types } from '../constants'

const index = {
  pending: `${types.listReport}_PENDING`,
  fulfilled: `${types.listReport}_FULFILLED`,
  rejected: `${types.listReport}_REJECTED`
}

const reducerKey = 'publisher'

export const listReport = {
  [index.pending]: ({ draft }) => {
    draft.industry.publisher.listReport.loading = true
    draft.industry.publisher.listReport.error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.publisher.listReport.data = dashboardPublisherListModel(
      action.payload.data
    )

    draft.industry[reducerKey].listReport.pages = action.payload.pages || 1
    draft.industry[reducerKey].listReport.total =
      action.payload.total || action.payload.data.length
    draft.industry[reducerKey].listReport.page = action.payload.page || 1

    draft.industry.publisher.listReport.loading = false
    draft.industry.publisher.listReport.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.publisher.listReport.loading = false
    draft.industry.publisher.listReport.error = action.payload
  }
}
