import { useState } from 'react'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import { FormGroup } from '~/components/unform'
import { useGetFetchQuery } from '~/hooks/useGetFetchQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import {
  addCategoriesBulkService,
  listCategoriesServiceKey
} from '~/modules/retailMedia/services/campaign-category'

import PlacementCategory from '../../CampaignFormV2/elements/Targeting/Category'

const FormAddCategory = ({
  campaignId,
  publisherId,
  callback
}: FormAddCategoryProps) => {
  const [categories, setCategories] =
    useState<FormAddCategorySelectedCategories>({ categories: [] })
  const [buttonLoadingCategory, setButtonLoadingCategory] = useState(false)

  const { t, tToasts } = useAPPTranslation()

  const QUERY_KEY = [listCategoriesServiceKey, campaignId]
  const queryClient = useQueryClient()
  const cachedData = useGetFetchQuery(QUERY_KEY) as GetCategoriesServiceResponse

  const addCategoryMutation = useMutation({
    mutationFn: addCategoriesBulkService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY
      })

      setButtonLoadingCategory(false)

      tToasts({
        prefix: 'rm:categories.messages.addCategory.success',
        toastFunction: toastr.success
      })

      callback()
    },
    onError: () => {
      setButtonLoadingCategory(false)

      tToasts({
        prefix: 'rm:categories.messages.addCategory.error',
        toastFunction: toastr.error
      })
    }
  })

  const handleSubmit = async () => {
    setButtonLoadingCategory(true)

    const ids = categories.categories?.map(category => category.id)

    if (!ids?.length) {
      setButtonLoadingCategory(false)
      tToasts({
        prefix: 'rm:categories.messages.atLeastOne.error',
        toastFunction: toastr.error
      })
      return
    }

    await addCategoryMutation.mutateAsync({
      campaignId,
      categoryIds: ids
    })
  }

  /**
   * Format initial categories to tag format
   */

  type FormatInitialCategories = (data: CampaignCategory[]) => Tag[]
  const formatInitialCategories: FormatInitialCategories = data =>
    data?.map(item => ({ id: item.category_id, label: item.name }))

  return (
    <section className="px-3 pb-3">
      {/* @ts-expect-error - <Form /> com erro da lib depreciada */}
      <Form onSubmit={handleSubmit}>
        <PlacementCategory
          onChange={setCategories}
          publisherId={publisherId}
          initialCategories={formatInitialCategories(cachedData?.data)}
        />

        <hr />

        <FormGroup className="actionButtons">
          <Button
            onClick={callback}
            text={t('c:actions.cancel')}
            template="transparentDanger"
          />
          <Button
            type="submit"
            text={t('c:actions.save')}
            template="success"
            loading={buttonLoadingCategory}
          />
        </FormGroup>
      </Form>
    </section>
  )
}

export default FormAddCategory
