import i18n from '~/i18n'
import { createTransactionService } from '~/modules/retailMedia/services/checking-account'

import { types } from './constants'

// Wallet - List
export const setPageGroceryListAllWallet = (value: number) => ({
  type: types.listAllSetPage,
  payload: value
})

export const setPerPageGroceryListAllWallet = (value: number) => ({
  type: types.listAllPerPage,
  payload: value
})

export const setSortGroceryListAllWallet = (value: string) => ({
  type: types.listAllSort,
  payload: value
})

// Transaction - List
export const setPageGroceryListTransaction = (value: number) => ({
  type: types.listTransactionSetPage,
  payload: value
})

export const setPerPageGroceryListTransaction = (value: number) => ({
  type: types.listTransactionSetPerPage,
  payload: value
})

// Transaction - Create
export const createTransaction = (data: CreateTransactionActionProps) => ({
  type: types.createTransaction,
  values: { balanceAfter: data.values?.balanceAfter },
  payload: createTransactionService(data),
  onSuccess: (response: CreateTransactionResponse) => data.onSuccess(response),
  errorFeedbackMsg: i18n.t('rm:actions.wallet.transaction.error')
})

export default {
  setPageGroceryListAllWallet,
  setPerPageGroceryListAllWallet,
  setPageGroceryListTransaction,
  setPerPageGroceryListTransaction
}
