import { FiFolder } from 'react-icons/fi'

import { t } from 'i18next'
import { last } from 'lodash'

import ThumbProduct from '~/components/ThumbProduct'
import { Image } from '~/modules/retailMedia/components/ProductsTable/styles'
import { formatNumberByType as format } from '~/modules/retailMedia/dtos/common/helpers'

/**
 * Usado em sponsored brands pois os produtos também são anúncios e tem métricas
 */

export const schemaWithMetrics: TableSchema<CampaignProduct> = {
  title: {
    title: t('rm:Product'),
    render: rowData => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Image>
          <ThumbProduct url={rowData.image_url} title={rowData.name} />
        </Image>

        <div style={{ lineHeight: 1 }}>
          {!!rowData?.categories?.length && (
            <div className="text-sm text-gray">
              <span
                className="d-inline-flex me-1"
                style={{ transform: 'translateY(1px)' }}
              >
                <FiFolder />
              </span>
              <span>{rowData?.categories?.at(-1)}</span>
            </div>
          )}
          <strong className="title">{rowData.name}</strong>
          <br />
          <span className="text-sm">SKU: {rowData.product_sku}</span>
        </div>
      </div>
    ),
    className: 'order__details col__infos col__title'
  },
  impressions: {
    title: t('rm:metrics.impressions'),
    sortKey: 'impressions',
    sortInitialToggleDirection: 'DESC',
    render: rowData => format({ type: 'unit', value: rowData?.impressions })
  },
  clicks: {
    title: t('rm:metrics.clicks'),
    sortKey: 'clicks',
    sortInitialToggleDirection: 'DESC',
    render: rowData => format({ type: 'unit', value: rowData?.clicks })
  },
  conversions: {
    title: t('rm:metrics.conversions'),
    sortKey: 'conversions',
    sortInitialToggleDirection: 'DESC',
    render: rowData => format({ type: 'unit', value: rowData?.conversions })
  },
  conversions_quantity: {
    title: t('rm:metrics.SoldItems'),
    sortKey: 'conversions_quantity',
    sortInitialToggleDirection: 'DESC',
    helpText: t('rm:metrics.SoldItems.conversions.tooltip'),
    render: rowData =>
      format({ type: 'unit', value: rowData?.conversions_quantity })
  },
  conversions_value: {
    title: t('rm:metrics.sales'),
    sortKey: 'conversions_value',
    sortInitialToggleDirection: 'DESC',
    render: rowData =>
      format({ type: 'money', value: Number(rowData?.conversions_value) })
  }
}
