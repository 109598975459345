import styled, { css } from 'styled-components';

export const Sidebar = styled.aside`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    width: 335px;
    padding: 1rem 0;
    height: calc(100vh - 60px);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > form {
      display: grid;
      grid-gap: 1rem;
    }

    > section {
      margin-bottom: 0.75rem;

      &.selectLocations,
      &.createSection {
        padding: 0 1rem;
      }

      > header {
        margin-bottom: 0.5rem;

        .title {
          font-size: 1rem;
          font-weight: ${theme.font.bolder};
        }
      }
    }
  `}
`;

export const SubmitButtons = styled.footer`
  ${({ theme }) => css`
    display: grid;
    grid-gap: 1rem;

    .delete {
      padding-top: 1rem;
      border-top: 1px solid ${theme.colors.gray};
    }
  `}
`;
