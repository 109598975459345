import { IconType } from 'react-icons'
import { FiUsers as UsersIcon } from 'react-icons/fi'

import ForgotPassword from '~/modules/auth/pages/forgot-password'
import Login from '~/modules/auth/pages/login'
import Logout from '~/modules/auth/pages/login/logout'
import LoginMarketplace from '~/modules/auth/pages/marketplace'
import UpdatePassword from '~/modules/auth/pages/UpdatePassword'
import {
  activeInvitePathToken,
  resetPasswordPathToken
} from '~/modules/auth/store/updatePassword/constants'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import SignUp from '~/pages/auth/sign-up'

export const logout: RootMenuItem<IconType> = {
  id: null,
  path: '/logout',
  name: 'Sign out',
  pageTitle: 'Sair',
  component: Logout
}

export const auth: RootMenuItem<IconType> = {
  id: 'auth-001',
  path: '/auth',
  name: 'Auth',
  icon: UsersIcon,
  badgeColor: 'warning',
  badgeText: '12/24',
  children: [
    {
      id: null,
      path: '/login/:origin',
      name: 'Login marketplace',
      pageTitle: 'Autenticação',
      component: LoginMarketplace
    },
    {
      id: null,
      path: '/login',
      name: 'Login',
      pageTitle: 'Autenticação',
      component: Login
    },
    {
      id: null,
      path: '/login/:origin',
      name: 'Login marketplace',
      pageTitle: 'Autenticação',
      component: LoginMarketplace
    },
    // {
    //   id: null,
    //   path: '/sign-up',
    //   name: 'Sign Up',
    //   pageTitle: 'Criar conta',
    //   component: SignUp
    // },
    {
      id: null,
      path: '/forgot-password',
      name: 'Forgot Password',
      pageTitle: 'Recuperação de senha',
      component: ForgotPassword
    },
    {
      id: null,
      path: resetPasswordPathToken,
      name: 'Reset Password',
      pageTitle: 'Redefinir senha',
      component: UpdatePassword
    },
    {
      id: null,
      path: activeInvitePathToken,
      name: 'Confirm Invite',
      pageTitle: 'Confirmar convite',
      component: UpdatePassword
    }
  ]
}
