/* eslint-disable no-underscore-dangle */
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
  useMemo
} from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import PropTypes from 'prop-types'

import {
  createLocationRegions,
  deleteLocationRegions,
  updateLocationRegions,
  showAllOnToEdit
} from '~/pages/locations/actions'

import { updateRegionModel } from '../models/updateRegion'

const MapDrawContext = createContext({})

const MapDrawProvider = ({ children }) => {
  const { id: location_id = null } = useParams()

  /**
   * Utils
   */
  const [shouldResetMap, setShouldResetMap] = useState(true)
  /**
   * Armazena a referência do controle de edição do mapa.
   */
  const editMapRef = useRef()
  const setEditMapRef = e => {
    editMapRef.current = e
  }

  /**
   * Armazena a referência do featured control do mapa.
   */
  const editableFG = useRef()
  const setEditableFG = e => {
    editableFG.current = e
  }
  /**
   * Indica se o modo de desenho está ativo
   */
  const [drawing, setDrawing] = useState(false)
  /**
   * Indica se o modo desenho está servindo para criar ou editar uma região
   * new | edit
   */
  const [drawMode, setDrawMode] = useState('new')
  const isEditingMode = useMemo(() => drawMode === 'edit', [drawMode])

  /**
   * Armazena as informações da região que está sendo editada
   */
  const [editingRegion, setEditingRegion] = useState(null)
  const [currentEditable, setCurrentEditable] = useState(null)

  /**
   * Infica se a forma sendo desenhada é válido.
   * Ao menos, precisa ter 3 pontos.
   */
  const isValidShape = useMemo(
    () => editingRegion?.latlngs?.length > 2,
    [editingRegion]
  )

  // /**
  //  *
  //  *
  //  * @function toggleEditingMode
  //  * Essa função é responsável por lidar as alteração da região que está sendo editada.
  //  */

  // const toggleEditingMode = useCallback(
  //   ({ region, currentEditableRef }) => {
  //     const isSameRegion = editingRegion?.id === region?.id;

  //     if (isSameRegion) {
  //       // cancelEditing();
  //       return;
  //     }

  //     setDrawMode('edit');
  //     setEditingRegion({ ...region, currentEditableRef });
  //   },
  //   [editingRegion],
  // );

  /**
   *
   *
   * @function handleEditingRegion
   * Essa função é responsável por lidar as alteração da região que está sendo editada.
   */
  const handleEditingRegion = useCallback(
    ({ type, ...rest }) => {
      if (!editingRegion) {
        const defaultNewRegion = {
          name: 'Nova região',
          type: type || 1
        }

        setDrawMode('new')

        setEditingRegion(defaultNewRegion)

        return
      }

      if (!!editingRegion.type && !!type) {
        setEditingRegion(currentRegion => ({ ...currentRegion, type }))

        return
      }

      setEditingRegion(currentRegion => ({ ...currentRegion, ...rest }))
    },
    [editingRegion]
  )

  /**
   *
   *
   * @function handleStartDrawing
   * Essa função é responsável por habilitar o modo de edição do mapa.
   */
  const handleStartDraw = useCallback(
    ({ type }) => {
      handleEditingRegion({ type })

      if (!drawing) {
        editMapRef.current._toolbars.draw._modes.polygon.handler.enable()

        setDrawing(true)
      }
    },
    [drawing, handleEditingRegion]
  )

  /**
   *
   *
   * @function handleFinishDrawing
   * Essa função é responsável por finalizar o desenho do mapa.
   */
  const handleFinishDraw = useCallback(() => {
    editMapRef.current._toolbars.draw._modes.polygon.handler.completeShape()

    setDrawing(false)
  }, [])

  const cancelEditing = useCallback(() => {
    editingRegion.currentEditableRef.editing.disable()
    setDrawMode(null)
    setEditingRegion(null)
    setCurrentEditable(null)
    setShouldResetMap(true)
    dispatch(showAllOnToEdit({ data: [] }))
  }, [editingRegion])

  /**
   *
   *
   * @function handleToggleRegionEditMode
   * @param {Object} e LeafletMouseEvent
   * @param {Object} layer Leaflet layer
   * Essa função é responsável por lidar com ativação do modo de edição de uma região.
   */

  const handleToggleRegionEditMode = useCallback(
    ({ e, polygon, isPreview }) => {
      const isSameRegion = editingRegion?.id === polygon?.id

      if (isSameRegion) {
        cancelEditing()
        return
      }

      if (currentEditable?.edited) return cancelEditing()
      if (currentEditable) currentEditable.editing.disable()

      const currentEditableRef = e.target

      setCurrentEditable(currentEditableRef)

      if (!isPreview) {
        currentEditableRef.editing.enable()
      }
      setDrawMode('edit')
      setEditingRegion({ ...polygon, currentEditableRef })
    },
    [currentEditable, editingRegion, cancelEditing]
  )

  /**
   *
   *
   * @function handleDisableDrawing
   * Essa função é responsável desabilitar o modo de edição do mapa.
   */
  const handleDisableDraw = useCallback(() => {
    if (drawMode === 'edit') {
      cancelEditing()
    } else {
      editMapRef.current._toolbars.draw._modes.polygon.handler.disable()

      const deleteCurrentEditingLayer =
        editableFG.current?._layers?.[editingRegion.id]

      if (deleteCurrentEditingLayer) {
        editableFG.current._layers[editingRegion.id].remove()
      }

      setEditingRegion(null)

      setDrawing(false)
    }
  }, [drawMode, cancelEditing, editingRegion])

  /**
   *
   *
   * @function handleToogleDrawing
   * Essa função é responsável por
   */
  const handleToogleDrawing = useCallback(
    ({ type }) => {
      if (editMapRef) {
        if (!drawing) {
          handleStartDraw(type)
        } else {
          handleFinishDraw()
          handleDisableDraw()
        }
      }
    },
    [drawing, handleDisableDraw, handleFinishDraw, handleStartDraw]
  )

  /**
   *
   *
   * @function handleCreateRegion
   * Essa função é responsável por
   */
  const dispatch = useDispatch()

  const handleFinishCreateRegion = useCallback(() => {
    editMapRef.current._toolbars.draw._modes.polygon.handler.disable()
    handleDisableDraw()
    setShouldResetMap(true)
  }, [editMapRef, handleDisableDraw])

  const handleCreateRegion = useCallback(
    data => {
      const body = {
        location_id,
        region_type: editingRegion.type,
        points: [...editingRegion.latlngs, editingRegion.latlngs[0]],
        ...data
      }
      dispatch(
        createLocationRegions({ body, onSuccess: handleFinishCreateRegion })
      )
    },
    [dispatch, editingRegion, handleFinishCreateRegion, location_id]
  )

  /**
   *
   *
   * @function handleUpdateRegion
   * Essa função é responsável por chamar o tratamento do update da região
   */
  const handleUpdateRegion = useCallback(
    async formData => {
      const body = updateRegionModel({
        formData,
        locationId: location_id,
        updateRegion: editingRegion
      })

      dispatch(updateLocationRegions({ body, onSuccess: cancelEditing }))
    },
    [location_id, editingRegion, dispatch, cancelEditing]
  )

  /**
   *
   *
   * @function handleDeleteRegion
   * Essa função é responsável por
   */
  const handleDeleteRegion = useCallback(async () => {
    const locationId = editingRegion.location_id
    const regionId = editingRegion.id

    const body = {
      locationId,
      regionId
    }

    dispatch(deleteLocationRegions({ body, onSuccess: cancelEditing }))
  }, [cancelEditing, dispatch, editingRegion])

  /**
   *
   */
  const [polygonRefs, setPolygonRefs] = useState({})

  /**
   * Readme
   */
  const handleStorePolygonRef = useCallback(
    ({ id, ref, polygon }) => {
      if (ref === null) return

      const exist = Object.keys(polygonRefs).find(polygonId => polygonId === id)

      if (exist) return

      setPolygonRefs(oldState => ({ ...oldState, [id]: { ref, polygon } }))
    },
    [polygonRefs]
  )

  /**
   * Link to slots current slot
   */

  const currentRegionSlotsLink = useMemo(
    () => `/logistic/slots/weekdays/${location_id}/${editingRegion?.id}`,
    [location_id, editingRegion]
  )

  return (
    <MapDrawContext.Provider
      value={{
        // Refs
        editMapRef,
        setEditMapRef,
        //
        editableFG,
        setEditableFG,
        // Polygons refs
        handleStorePolygonRef,
        polygonRefs,
        //
        drawing,
        editingRegion,
        setEditingRegion,
        handleEditingRegion,
        isValidShape,
        drawMode,
        isEditingMode,
        // toggleEditingMode,
        //
        handleStartDraw,
        handleFinishDraw,
        handleDisableDraw,
        handleToogleDrawing,
        handleToggleRegionEditMode,
        //
        handleCreateRegion,
        handleUpdateRegion,
        handleDeleteRegion,
        //
        shouldResetMap,
        setShouldResetMap,
        //
        currentRegionSlotsLink
      }}
    >
      {children}
    </MapDrawContext.Provider>
  )
}

function useMapDraw() {
  const context = useContext(MapDrawContext)

  if (!context) {
    throw new Error('useMapDraw must be used within an MapDrawProvider')
  }

  return context
}

export { MapDrawContext, MapDrawProvider, useMapDraw }

MapDrawProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
