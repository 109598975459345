import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { t } from 'i18next'
import moment from 'moment-timezone'

import EmptyPage from '~/components/empty-page'
import { Filters } from '~/components/Filters'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import LoadingPopUp from '~/components/LoadingPopUp'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { formatSearchString } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import useFetchSWR from '~/hooks/useFetchSWR'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'
import {
  setPageGroceryListTransaction,
  setPerPageGroceryListTransaction
} from '~/modules/retailMedia/store/grocery/wallet/actions'
import { useAppSelector } from '~/store/hooks'

import { retailMediaWalletFilters } from '../../store/common/filters'
import { schema } from './schemas/schemas.grocery'

export const GroceryTransactionTable = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const { walletType } = useAppSelector(state => state.filters.retailMedia)

  const {
    grocery: {
      wallet: {
        listTransaction: { page, perPage }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  const queryParams = useMemo(
    () =>
      formatSearchString({
        page,
        quantity: perPage,
        user_info: true,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        transaction_type: walletType?.value
      }),
    [endDate, page, perPage, startDate, walletType]
  )

  const { data, error, isValidating, mutate } = useFetchSWR({
    url: `${reatailmediaApi}/checking-account/transaction/${id}?${queryParams}`,
    refreshInterval: 60000,
    revalidateOnFocus: false
  })

  const [AlertOnError] = useAlert(error)

  const handlePagination = useCallback(
    (page: number) => {
      dispatch(setPageGroceryListTransaction(page))
    },
    [dispatch]
  )

  const handleItemsPerPageChange = useCallback(
    ({ value }: { value: number }) => {
      dispatch(setPerPageGroceryListTransaction(value))
    },
    [dispatch]
  )

  return (
    <>
      <Loading status={!data && isValidating}>
        {t('common:actions.loadingData')}...
      </Loading>

      <LoadingPopUp isActive={isValidating} />

      <AlertOnError />

      <>
        <Panel
          isLoading={!data}
          title={t('rm:Movimentations')}
          description={t('rm:wallet.movimentation.panel.description')}
        >
          <Filters {...retailMediaWalletFilters} />

          {data?.total > 0 && (
            <Table
              schema={schema}
              data={data?.data}
              total={data?.total}
              sideBorder
            />
          )}

          {data?.total === 0 && (
            <EmptyPage
              title={t('common:table.empty')}
              subtitle={t('common:table.emptyFiltered')}
              titleButton={t('common:words.clearFilters')}
              svgPath="photos/orders-empty"
            />
          )}

          <footer>
            <ListPagination
              total={data?.total}
              label={t(
                `rm:${data?.total > 1 ? 'transactions' : 'transaction'}`
              )}
              currentPage={page}
              itemsPerPage={perPage}
              menuPlacement="top"
              onClickPagination={handlePagination}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </footer>
        </Panel>
      </>
    </>
  )
}
