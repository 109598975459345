import { methods } from '~/services/paymentHub';

import * as types from '../constants';

/**
 *
 * @returns
 */
export const get = () => ({
  type: types.GET_PAYMENT_METHODS,
  payload: methods.list(),
});
