import { useMemo } from 'react'
import {
  FiCreditCard,
  FiDollarSign,
  FiEye,
  FiHelpCircle,
  FiPercent,
  FiShoppingBag
} from 'react-icons/fi'
import { MdAdsClick } from 'react-icons/md'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'

import Alert from '~/components/Alert'
import Loading from '~/components/Loading'
import Tooltip from '~/components/Tooltip'
import { formatDateToString, getRetailMediaAccountType } from '~/helpers'
import { handleErrorMessage } from '~/helpers/errors'
import Card from '~/modules/retailMedia/components/Card'
import { WrapperCard } from '~/modules/retailMedia/components/Card/styles'
import { formatIndustryReportCampaign } from '~/modules/retailMedia/dtos/industry/campaign/report'
import {
  getCampaignsReportKey,
  getCampaignsReportService
} from '~/modules/retailMedia/services/report'
import { useAppSelector } from '~/store/hooks'

const CardsCampaign: React.FC = () => {
  const { id } = useParams()
  const accountType = getRetailMediaAccountType()
  const isPublisher = accountType === 'publisher'

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const queryParams = useMemo(
    () =>
      ({
        start_date: formatDateToString(startDate, 'YYYY-MM-DD'),
        end_date: formatDateToString(endDate, 'YYYY-MM-DD'),
        campaign_id: id
      }) as GetCampaignReportRequestParams,
    [startDate, endDate, id]
  )

  const {
    data,
    isLoading: loading,
    error
  } = useQuery({
    queryKey: [getCampaignsReportKey, id, ...Object.values(queryParams)],
    queryFn: async () => {
      const { data } = await getCampaignsReportService(queryParams)

      return formatIndustryReportCampaign(data)
    }
  })

  const icons = {
    prints: <FiEye className=" text-warning" />,
    clicks: <MdAdsClick className=" text-info" />,
    ctr: <FiPercent className=" text-primary" />,
    income: <FiDollarSign className=" text-success" />,
    conversion: <FiShoppingBag className=" text-success" />,
    totalSpent: <FiCreditCard className=" text-warning" />
  }

  type Card = {
    id: string
    title: string | JSX.Element
    value: number
    type: 'prints' | 'clicks' | 'ctr' | 'totalSpent' | 'income' | 'conversion'
  }

  const cards: Card[] = useMemo(
    () => [
      {
        id: 'card-01',
        title: t('rm:metrics.impressions'),
        value: data?.formatted?.impressions || 0,
        type: 'prints'
      },
      {
        id: 'card-02',
        title: t('rm:metrics.clicks'),
        value: data?.formatted?.clicks || 0,
        type: 'clicks'
      },
      {
        id: 'card-03',
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{t('rm:metrics.ctr')}</span>
            <Tooltip
              icon={<FiHelpCircle />}
              text={<>{t('rm:metrics.ctr.tooltip')}</>}
            />
          </div>
        ),
        value: data?.formatted?.ctr || 0,
        type: 'ctr'
      },
      {
        id: 'card-06',
        title: isPublisher ? t('rm:metrics.totalSpent') : t('rm:metrics.cost'),
        value: data?.formatted?.total_spent || 0,
        type: 'totalSpent'
      },
      {
        id: 'card-04',
        title: t('rm:metrics.sales'),
        value: data?.formatted?.income || 0,
        type: 'income'
      },
      {
        id: 'card-05',
        title: t('rm:metrics.roas'),
        value: data?.formatted?.roas || 0,
        type: 'conversion'
      }
    ],
    [data, isPublisher]
  )

  return (
    <div className="wrapperCards">
      <Loading status={loading}>{t('rm:metrics.cards.loading')}</Loading>

      <Alert active={!!error}>
        {t('rm:error')}:{' '}
        {handleErrorMessage({
          error,
          fallbackMessage: t('rm:metrics.cards.loading.fallbackMessage')
        })}
      </Alert>

      <WrapperCard>
        <div className="hold-cards">
          {cards.map((el, index) => (
            <Card
              key={index}
              title={el.title}
              value={el.value}
              loading={loading}
              icon={icons?.[el.type]}
            />
          ))}
        </div>
      </WrapperCard>
    </div>
  )
}

export default CardsCampaign
