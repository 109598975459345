import { GiFamilyTree as icon } from 'react-icons/gi'

import * as Pages from '~/modules/industry/kpi/pages'

export const kpi = {
  id: 'f0465f0f-ad09-4b9d-ba62-6ce5f38d5e75',
  path: '/kpi',
  name: 'Árvore de indicadores',
  pageTitle: 'Árvore de indicadores',
  icon,
  contentFullWidth: true,
  component: Pages.Dashboard,
  children: null,
  containsHome: true
}
