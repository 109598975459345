import { Container } from './styles'

type FormGroupProps = {
  children: React.ReactNode
  className?: string
}

const FormGroup = ({ children, className, ...rest }: FormGroupProps) => {
  return (
    <Container className={`formGroup ${className}`} {...rest}>
      {children}
    </Container>
  )
}

export { FormGroup }

FormGroup.defaultProps = {
  className: ''
}
