import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Container } from '~/components/Container'
import Navbar from '~/components/navbar/Navbar'
import Sidebar from '~/components/sidebar'
import { hideSidebar } from '~/components/sidebar/sidebar-actions'
import { Wrapper } from '~/components/Wrapper'
import { checkUserHasAccount } from '~/modules/auth/pages/sign-up/sign-up-actions'
import { useAppSelector } from '~/store/hooks'

import { checkIsMobile } from './layout-actions'

type MasterProps = {
  pageTitle?: string
  maxHeightViewport?: boolean
  contentFullWidth?: boolean
  contentFullScreen?: boolean
  hideFullMenu?: boolean
  sidebarStartsClosed?: boolean
}

const Master = ({
  children,
  pageTitle = null,
  maxHeightViewport = false,
  contentFullWidth = false,
  contentFullScreen = false,
  hideFullMenu = false,
  sidebarStartsClosed = false
}: React.PropsWithChildren<MasterProps>) => {
  const dispatch = useDispatch()

  const {
    sidebar,
    layout: { isMobile }
  } = useAppSelector(state => state)

  /**
   * Inital requests
   */
  useEffect(() => {
    dispatch(checkIsMobile(window.innerWidth))
    dispatch(checkUserHasAccount(true))
  }, [dispatch])

  useEffect(() => {
    function setMobile() {
      dispatch(checkIsMobile(window.innerWidth))
    }

    window.addEventListener('resize', setMobile)

    return function cleanup() {
      window.removeEventListener('resize', setMobile)
    }
  }, [dispatch])

  /**
   * Escondendo menu ao carregar
   */

  useLayoutEffect(() => {
    if (sidebarStartsClosed && !isMobile) dispatch(hideSidebar())
  }, [dispatch, isMobile, sidebarStartsClosed])

  return (
    <>
      <Wrapper maxHeightViewport={maxHeightViewport}>
        {!hideFullMenu && <Sidebar />}

        <div className="main">
          {!hideFullMenu && <Navbar pageTitle={pageTitle} />}

          <div
            className={`content__wrapper${
              sidebar.isOpen ? ' sidebar__open' : ''
            }`}
          >
            <Container
              full={contentFullWidth}
              fullscreen={contentFullScreen}
              className="content"
              hideFullMenu={hideFullMenu}
            >
              {children}
            </Container>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Master
