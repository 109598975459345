import React from 'react'
import { FiGlobe } from 'react-icons/fi'

import PropTypes from 'prop-types'

import Tooltip from '~/components/Tooltip'
import { Badge } from '~/components/UI/Badge'

import * as S from './styles'

const Slot = ({ slot }) => {
  return (
    <S.Container
      isNew={slot?.isNew}
      isRepeatable={slot.isRepeatable}
      template={slot.template}
      isGlobal={slot.isGlobal}
    >
      {slot.isGlobal && (
        <div className="icon">
          <FiGlobe />
        </div>
      )}

      <div className="text">
        {slot.startHour} - {slot.endHour}
        {!!slot?.maxOrders && (
          <>
            {' '}
            <Tooltip
              icon={<Badge template="primaryDark">{slot.maxOrders}</Badge>}
              text="Quantidade máxima de pedidos aceitos neste slot"
            />
          </>
        )}
      </div>
    </S.Container>
  )
}

export default Slot

Slot.propTypes = {
  slot: PropTypes.shape({
    _id: PropTypes.string,
    startHour: PropTypes.string,
    endHour: PropTypes.string,
    isNew: PropTypes.bool,
    template: PropTypes.string.isRequired,
    isGlobal: PropTypes.bool.isRequired,
    isRepeatable: PropTypes.bool.isRequired
  }).isRequired
}
