import React, { useState } from 'react';
import { Bell } from 'react-feather';
import { Alert } from 'reactstrap';

const AlertDismissible = ({
  messageText,
  isOpen,
  type,
  onClose,
  className,
  hideClose = false,
  ...rest
}) => {
  const [alert, setAlert] = useState(false);
  const [estado, setEstado] = useState(0);

  // 0 === FECHADO
  // 1 === ABERTO
  // 2 === FECHADO FORCADO

  function closeAlert() {
    setEstado(2);
    setAlert(false);
    onClose();
  }

  function openAlert() {
    setEstado(1);
    setAlert(true);
  }

  return (
    <Alert color="light" isOpen={alert} className="text-dark mt-3">
      {isOpen && estado === 0 ? openAlert() : ''}
      <div
        className={`fill-width alert-outline-coloured alert alert-${type} alert-dismissible fade show ${className}`}
        role="alert"
      >
        {!hideClose && (
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeAlert}
          >
            <span aria-hidden="true">×</span>
          </button>
        )}
        <div className="alert-icon">
          <Bell size={14} />
        </div>
        <div className="alert-message">{messageText}</div>
      </div>
    </Alert>
  );
};

export default AlertDismissible;
