import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import YupPassword from 'yup-password'

import { Button } from '~/components/Buttons'
import Panel from '~/components/Panel'
import RulesList from '~/components/RulesList'
import { FormGroup, Label } from '~/components/unform'
import { InputPassword } from '~/components/unform/Input'

import { getValidationErrors } from '../../../../helpers'
import { submitFormPassword } from '../profile-actions'

import * as S from './styles'

const FormPassword = ({ loading }) => {
  YupPassword(Yup)

  const formRef = useRef(null)

  const [newPassword, setNewPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [ruleErrors, setRuleErrors] = useState([])

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const rules = useMemo(
    () => ({
      currentRequired: t('auth:updatePassword.passwordRules.currentRequired'),
      required: t('auth:updatePassword.passwordRules.required'),
      length: t('auth:updatePassword.passwordRules.length'),
      minLowercase: t('auth:updatePassword.passwordRules.minLowercase'),
      minUppercase: t('auth:updatePassword.passwordRules.minUppercase'),
      minNumbers: t('auth:updatePassword.passwordRules.minNumbers'),
      minSymbols: t('auth:updatePassword.passwordRules.minSymbols'),
      isEqual: t('auth:updatePassword.passwordRules.isEqual')
    }),
    [t]
  )

  const schema = Yup.object().shape({
    currentPassword: Yup.string().required('currentRequired'),
    newPassword: Yup.string()
      .password()
      .min(8, 'length')
      .minLowercase(1, 'minLowercase')
      .minUppercase(1, 'minUppercase')
      .minNumbers(1, 'minNumbers')
      .minSymbols(1, 'minSymbols')
      .required('required')
  })

  const passwordsIsEqual = useMemo(
    () => newPassword === currentPassword,
    [newPassword, currentPassword]
  )

  const handleSubmitForm = useCallback(
    async data => {
      try {
        await schema.validate(data, { abortEarly: false })

        dispatch(submitFormPassword(data))
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)

          return toastr.error(
            'Erro',
            rules?.[err.inner?.[0]?.message] ||
              t('auth:updatePassword.form.passwordUpdateError')
          )
        }

        toastr.error('Erro', 'Houve um erro ao atualizar a senha.')
      }
    },
    [dispatch, rules, schema]
  )

  const handleChangeNewPassword = useCallback(({ value }) => {
    setNewPassword(value)
  }, [])
  const handleChangeCurrentPassword = useCallback(({ value }) => {
    setCurrentPassword(value)
  }, [])

  const checkRules = useCallback(async () => {
    const data = { newPassword, currentPassword }

    const errors = passwordsIsEqual ? ['isEqual'] : []

    try {
      await schema.validate(data, { abortEarly: false })

      setRuleErrors([...errors])
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = err.inner.map(error => error.message)

        setRuleErrors([...errors, ...validationErrors])
      }
    }
  }, [currentPassword, newPassword, passwordsIsEqual, schema])

  useEffect(() => {
    checkRules()
  }, [newPassword, currentPassword])

  const readyToSubmit = useMemo(() => ruleErrors.length <= 0, [ruleErrors])

  return (
    <Panel
      title={t('auth:updatePassword.resetPasswordPath.title')}
      isLoading={loading}
    >
      <S.ContainerPassword>
        <Form
          ref={formRef}
          onSubmit={handleSubmitForm}
          className="form-password"
        >
          <FormGroup>
            <Label htmlFor="currentPassword">
              {t('auth:updatePassword.form.currentPassword.label')}
            </Label>

            <InputPassword
              name="currentPassword"
              onChange={handleChangeCurrentPassword}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="newPassword">
              {t('auth:updatePassword.form.newPassword.label')}
            </Label>
            <InputPassword
              name="newPassword"
              onChange={handleChangeNewPassword}
            />
          </FormGroup>

          <Button
            type="submit"
            text={t('auth:updatePassword.form.submitButton.text')}
            loading={loading}
            disabled={!readyToSubmit}
          />
        </Form>

        <RulesList rules={rules} ruleErrors={ruleErrors} />
      </S.ContainerPassword>
    </Panel>
  )
}

export default FormPassword

FormPassword.propTypes = {
  loading: PropTypes.bool.isRequired
}
