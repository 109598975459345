import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import Alert from '~/components/Alert'
import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { getRole } from '~/helpers'
import { getTags } from '~/modules/chat/store/actions'
import { useAppSelector } from '~/store/hooks'

import FormTag from './elements/Form'
import Tag from './elements/Tag'

import * as S from './styles'

const Tags = () => {
  const dispatch = useDispatch()
  const { avaiableTags, loadingTags } = useAppSelector(({ chats }) => chats)

  useEffect(() => {
    if (!avaiableTags) {
      dispatch(getTags())
    }
  }, [avaiableTags, dispatch])

  const hasTags = useMemo(() => !!avaiableTags?.length, [avaiableTags])

  const isAdmin = useMemo(() => getRole() === 'admin', [])

  return (
    <>
      {/* Meta <title> */}
      <PageTitle title="Atendimento > Tags das conversas" />

      <HeaderPages title="Atendimento > Tags das conversas" />

      <S.Container>
        {isAdmin && (
          <S.Sidebar>
            <header>
              <h3>Cadastrar nova tag</h3>
            </header>

            <FormTag />
          </S.Sidebar>
        )}

        <S.List>
          <Loading status={loadingTags}>Carregando tags...</Loading>

          {!loadingTags && !hasTags && (
            <Alert active>Nenhuma tag está cadastrada no momento.</Alert>
          )}

          {!loadingTags &&
            hasTags &&
            avaiableTags.map(tag => (
              <Tag key={tag.tagId} data={tag} isAdmin={isAdmin} />
            ))}
        </S.List>
      </S.Container>
    </>
  )
}

export default Tags
