import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import Table from '~/components/Table'
import { Badge } from '~/components/UI/Badge'
import { FileInput, FormGroup, InputHidden, Label } from '~/components/unform'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { getPublisherConfiguration } from '~/modules/retailMedia/store/industry/publisherConfiguration/actions'
// import { useAppSelector } from '~/store/hooks'

import FileToUpload from '../FileToUpload'
import { schema } from './schema'

import * as S from './styles'

const mediaTypes = {
  'image/png': 'image',
  'image/jpeg': 'image'
}

export const FieldsetAssets = ({
  initialData
}: Pick<SponsoredBrandFieldsetProps, 'initialData'>) => {
  const [assetsToTable, setAssetsToTable] = useState<AssetsToTable[]>([])
  /**
   * Lógica de apoio
   */
  const { publisherId } = useMemo(
    () => ({
      publisherId: initialData?.publisher_id,
      publisherName: initialData?.publisher_name
    }),
    [initialData]
  )

  /**
   * Lógica para a funcionalidade principal
   */
  type FilesToUpload = { file: File; id: string }[]
  const [filesToUpload, setFilesToUpload] = useState<FilesToUpload>([])

  const dispatch = useDispatch()

  // const {
  //   industry: {
  //     publisherConfiguration: {
  //       list: { data, loading, error }
  //     }
  //   }
  // } = useAppSelector(state => state.retailMedia)

  useEffect(() => {
    if (publisherId) {
      dispatch(getPublisherConfiguration(publisherId))
    }
  }, [dispatch, publisherId])

  const handleDeleteAsset = useCallback(
    (data: DataAssetHandle) => {
      setAssetsToTable(state => state.filter(item => item.id !== data.id))
    },
    [setAssetsToTable]
  )

  const handleSaveBanner = useCallback(
    (data: ItemAssetTable) => {
      setFilesToUpload(prevState =>
        prevState.filter(item => item.id !== data.id)
      )

      setAssetsToTable(prevState => {
        return [
          ...prevState,
          {
            ...data,
            type: mediaTypes?.[data.fileMedia.type] || data.fileMedia.type,
            handleDeleteAsset
          }
        ]
      })
    },
    [handleDeleteAsset]
  )

  /**
   * Handle multiple upload
   */

  const handlePrepareToUploadImage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const fileList = Array.from(
        event.target.files as FileList,
        (item, index) => ({ file: item, id: `${item.name}_${index}` })
      )

      setFilesToUpload(fileList)
    },
    []
  )

  const handleDeleteFileBeforeUpload = (removeId: string) => {
    setFilesToUpload(prevState =>
      prevState.filter(item => item.id !== removeId)
    )
  }

  /**
   * Ad sizes
  //  */

  // const { status: statusAdSize, data: dataAdSize } = useQuery({
  //   queryKey: [
  //     'get-assets-sponsored-brand-size',
  //     publisherId,
  //     selectedSegmentation
  //   ],
  //   queryFn: async () => {
  //     const res = await listSizesBySegmentationService({
  //       publisher_id: publisherId,
  //       ad_type: 'sponsored_brand',
  //       type: 'banner',
  //       segmentation: selectedSegmentation
  //     })

  //     return res.data
  //   }
  // })

  const fakeAdSize: ResponseListSizeBySegmentation = [
    {
      height: 250,
      width: 970,
      label: 'Billboard',
      name: 'Billboard',
      size: '970x250'
    }
  ]

  const dataAdSize = fakeAdSize

  /**
   * Placements
   */

  const options = useMemo(() => {
    if (dataAdSize) {
      const arrayValue: OptionsInterface[] = dataAdSize.map(item => ({
        value: item.name,
        label: item.size,
        width: item.width,
        height: item.height
      }))

      arrayValue.sort((a, b) =>
        a.width !== b.width ? a.width - b.width : a.height - b.height
      )

      return arrayValue
    }
  }, [dataAdSize])

  const getPlacementByDimension = useCallback(
    ({ width, height }: Dimension) => {
      const findPlacement = dataAdSize?.find(
        item => Number(item.width) === width && Number(item.height) === height
      )

      return findPlacement
    },
    [dataAdSize]
  )

  const { t } = useAPPTranslation()

  return (
    <FormGroup>
      <Label
        text={t('rm:campaign.create.SponsoredBrand.FieldsetAssets.label')}
        helperText={t(
          'rm:campaign.create.SponsoredBrand.FieldsetAssets.helperText'
        )}
      />

      <section className="multipleUploadWrapper">
        <FileInput
          name="mediaFile"
          accept="image/*"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handlePrepareToUploadImage(event)
          }
          isMultiple
          hideIcons
        >
          <div className="pt-3 mt-3 border-top border-tertiary">
            <h4>{t('rm:allowedMediaSizes')}</h4>

            <div className="d-flex gap 2 justify-content-center flex-wrap">
              {options?.map(item => (
                <div key={item.value}>
                  <Badge template="primary">{item.label}</Badge>
                </div>
              ))}
            </div>
          </div>
        </FileInput>

        <S.UploadQueue>
          {filesToUpload?.map(item => (
            <FileToUpload
              data={item}
              key={item.id}
              getPlacementByDimension={getPlacementByDimension}
              onDelete={() => handleDeleteFileBeforeUpload(item.id)}
              onSave={handleSaveBanner}
            />
          ))}
        </S.UploadQueue>
      </section>

      {!!assetsToTable.length &&
        assetsToTable.map((item, index) => (
          <>
            <InputHidden name={`assets[${index}].type`} value={item.type} />
            <InputHidden name={`assets[${index}].url`} value={item.media_url} />
          </>
        ))}

      {!!assetsToTable.length && (
        <S.WrapperTabler>
          <Table schema={schema} data={assetsToTable} />
        </S.WrapperTabler>
      )}
    </FormGroup>
  )
}
