import styled, { DefaultTheme, css } from 'styled-components'

const containerModifiers = {
  isOpen: () => css`
    height: 100%;
    opacity: 1;
    transition: opacity ease-in-out 0.3s;
  `
}

export const Container = styled.div`
  ${({ theme, isOpen }) => css`
    &:after {
      transition: opacity ease-in-out 0.3s, height ease-in-out 0s 0.3s;

      content: '';

      position: absolute;
      height: 0;
      opacity: 0.1;
      width: 100%;

      z-index: 80;
      background: rgba(60, 53, 132, 0.85);

      ${isOpen && containerModifiers.isOpen()}
    }
  `}
`

export const Wrapper = styled.div`
  position: relative;
`

export const Button = styled.button`
  ${({ theme }) => css`
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    position: relative;
    z-index: 100;

    width: 100%;
    padding: ${theme.spacings.xxxsmall};

    border-bottom: 1px solid ${theme.colors.neutral.high.medium};

    line-height: 1;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.light};

    background: white;
  `}
`

export const SelectedType = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    gap: 0.125rem;

    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.neutral.low.medium};

    .icon {
      font-size: ${theme.font.sizes.small};
    }
  `}
`
// <ul> DropMenu

type DropMenuProps = {
  isOpen: boolean
}

const dropMenuModifiers = {
  isOpen: () => css`
    transform: translateY(calc(100% + 0.5rem));
    max-height: 10rem;
    box-shadow: 0px 4px 8px rgba(24, 21, 60, 0.08);
  `
}

export const DropMenu = styled.ul<DropMenuProps>`
  ${({ theme, isOpen }) => css`
    --borderColor: ${theme.colors.neutral.high.light};

    position: absolute;
    bottom: 0;
    left: 0.5rem;
    bottom: 0;
    transform: translateY(0);
    transition: all ease-in-out 0.3s;

    z-index: 90;
    overflow: hidden;

    width: calc(100% - 1rem);
    margin: 0;
    padding: 0.25rem;
    max-height: 0;

    background: white;

    border: 1px solid var(--borderColor);
    border-radius: 0.25rem;
    box-shadow: 0px 0 0 rgba(24, 21, 60, 0.08);

    ${isOpen && dropMenuModifiers.isOpen()}
  `}
`

type DropItemProps = {
  isActive: boolean
}

const dropItemModifiers = {
  isActive: (theme: DefaultTheme) => css`
    transform: translate(calc((-100% - 0.25rem)), -50%);
    opacity: 1;
  `
}

export const DropItem = styled.li<DropItemProps>`
  ${({ theme, isActive }) => css`
    list-style: none;

    + & {
      border-top: 1px solid ${theme.colors.neutral.high.light};
    }

    button {
      width: 100%;
      padding: 0.5rem;
      text-align: center;
      transition: all ease-in-out 0.3s;
      border-radius: 0.25rem;

      &:hover {
        background: ${theme.colors.primaryLighten60};
      }

      .text {
        position: relative;

        &:after {
          position: absolute;
          top: 50%;
          left: 0;

          transform: translate(0, -50%);
          opacity: 0;

          height: 6px;
          width: 6px;

          border-radius: 50%;

          content: '';

          transition: all ease-in-out 0.3s;
          background: ${theme.colors.primary};

          ${isActive && dropItemModifiers.isActive(theme)}
        }
      }
    }
  `}
`
