import { useMemo } from 'react'

import { Filters } from '~/components/Filters'
import { adTypeFilterConfig } from '~/modules/retailMedia/constants/adTypeFilterConfig'
import { useAdTypes } from '~/modules/retailMedia/hooks/useAdTypes'
import { queryCampaignNameFilter } from '~/modules/retailMedia/store/common/filters/query'
import { statusFilter } from '~/modules/retailMedia/store/common/filters/status'

const FilterHeaderCampaignList: React.FC = () => {
  const { data, isPending } = useAdTypes()

  const adTypeFilter = useMemo(
    () => adTypeFilterConfig({ types: data, isLoading: isPending }),
    [data, isPending]
  )

  return (
    <Filters
      {...queryCampaignNameFilter}
      genericFilters={[statusFilter, adTypeFilter]}
      showGlobalDatePicker
    />
  )
}

export default FilterHeaderCampaignList
