import React, { useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { FiEdit } from 'react-icons/fi'

import Popover from '~/components/popover'

const PopoverIcon = forwardRef((props, ref) => {
  const { children, target, title, icon } = props
  const [isCurrent, setIsCurrent] = useState(false)
  const popoverRef = useRef()
  useImperativeHandle(ref, () => ({
    closePopover() {
      popoverRef.current.closePopover()
    }
  }))
  return (
    <>
      <span
        className={`table-edit-button ${isCurrent ? '--hover' : ''}`}
        id={target}
        data-cy={props['data-cy']}
      >
        {icon}
      </span>
      <Popover
        {...props}
        ref={popoverRef}
        placement="left"
        target={target}
        onPopoverToggle={setIsCurrent}
        title={title}
      >
        {children}
      </Popover>
    </>
  )
})

export default PopoverIcon

PopoverIcon.propTypes = {}

PopoverIcon.defaultProps = {
  title: '',
  target: 'popover',
  icon: <FiEdit size="12" />
}
