export default {
  listAll: {
    data: [],
    loading: false,
    page: 1,
    perPage: 50,
    total: 0,
    pages: 0,
    error: null
  },
  listTransaction: {
    data: [],
    loading: false,
    page: 1,
    perPage: 50,
    total: 0,
    pages: 0,
    error: null
  },
  recharge: {
    loading: false,
    error: null,
    data: {}
  },
  transaction: {
    loading: false,
    error: null,
    data: {}
  }
}
