import { useCallback, useMemo } from 'react'
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai'
import { FiMenu, FiX } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { getAccountInfo } from '~/helpers/account-info'
import { useAppInfo } from '~/hooks/useAppInfo'
import { useAppSelector } from '~/store/hooks'
import { primary } from '~/styles/themes'

import { Button } from '../Buttons'
import { toggleSidebar } from '../sidebar/sidebar-actions'

import * as S from './styles'

const LogoMenu = () => {
  /**
   * Handle app info
   */
  const {
    headerLogo,
    headerTheme,
    colors: { header: headerColor },
    name,
    isWhiteLabel
  } = useAppInfo()

  const accountName = useMemo(() => name || 'Newtail', [name])
  const accountLogo = useMemo(
    () =>
      isWhiteLabel
        ? headerLogo
        : require('../../assets/img/brands/newtail-logo-white.png'),
    [isWhiteLabel, headerLogo]
  )

  /**
   * END - Handle app info
   */

  const dispatch = useDispatch()
  const { isOpen } = useAppSelector(state => state.sidebar)
  const { isMobile } = useAppSelector(state => state.layout)

  const handleToggleSidebar = useCallback(
    () => dispatch(toggleSidebar()),
    [dispatch]
  )

  const Icon = useMemo(() => {
    if (isMobile) {
      return isOpen ? FiMenu : FiX
    }

    return isOpen ? AiOutlineMenuFold : AiOutlineMenuUnfold
  }, [isOpen, isMobile])

  return (
    <S.Container
      sidebarIsOpened={isOpen}
      isMobile={isMobile}
      isWhiteLabel={isWhiteLabel}
      bgHeader={headerColor}
    >
      <a
        href="/"
        title="Ir para a página inicial"
        // className={isWhiteLabel ? 'logo white-label' : null}
      >
        <img
          id="main-logo"
          className="d-inline-block align-top me-1"
          style={{
            width: '130px',
            height: '29px',
            objectFit: 'contain',
            objectPosition: 'left center'
          }}
          src={accountLogo}
          alt={accountName}
        />
      </a>

      <Button
        onClick={handleToggleSidebar}
        text={<Icon />}
        customWidth="auto"
        template={
          isWhiteLabel && headerTheme === 'light'
            ? 'transparentPrimary'
            : 'transparentLight'
        }
        title="Abrir menu"
      />

      <a href="/" id="main-logo-closed-menu" title="Ir para a página inicial">
        <img
          className="d-inline-block align-top me-1"
          style={{
            width: '130px',
            height: '29px',
            objectFit: 'contain',
            objectPosition: 'left center'
          }}
          src={accountLogo}
          alt={name}
        />
      </a>
    </S.Container>
  )
}

export default LogoMenu
