import { produce } from 'immer';

import moment from 'moment';
import { REHYDRATE } from 'redux-persist';
import * as types from './datepicker-header-constants';

const periodDefault = {
  sevenDays: {
    startDate: moment().subtract(7, 'days'),
    endDate: moment(),
  },
};

const INITIAL_STATE = {
  startDate: periodDefault.sevenDays.startDate,
  endDate: periodDefault.sevenDays.endDate,
};

export default function datepickerHeader(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.DATEPICKER_HEADER_SET_DATES:
        draft.startDate = action.startDate;
        draft.endDate = action.endDate;

        break;

      case REHYDRATE: {
        if (action?.payload?.datepickerHeader) {
          const { startDate, endDate } = action.payload.datepickerHeader;

          if (startDate) {
            draft.startDate = moment(startDate);
          }

          if (endDate) {
            draft.endDate = moment(endDate);
          }
        }

        break;
      }

      default:
        return state;
    }
  });
}
