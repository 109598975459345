import { Form } from '@unform/web'

import CampaignType from './elements/CampaignType'
import Publisher from './elements/Publisher'
import { useCampaignForm } from './hooks/useCampaignForm'
import StrategiesCreateCampaign from './strategies'

const CampaignForm: React.FC = () => {
  const {
    selectedType: type,
    shouldSelectPublisher,
    showPublisherFieldset
  } = useCampaignForm()

  return (
    <section id="create-campaign-form">
      <Form initialData={{ type }}>
        {showPublisherFieldset && <Publisher />}

        {shouldSelectPublisher && <CampaignType />}
      </Form>

      {type && <StrategiesCreateCampaign />}
    </section>
  )
}

export default CampaignForm
