import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'
import { Collapse, CardBody, CardHeader } from 'reactstrap'

import { Button } from '../Buttons'
import Loader from '../Loader'

import * as S from './styles'

const Panel = ({
  template,
  title,
  description,
  headerSideContent,
  noPaddingBody,
  children,
  isLoading,
  isCollapsible,
  onCollapse,
  initialCollapsibleState = true,
  className,
  iconLeft,
  unmountContentIfCollpase = false,
  ...rest
}: PanelProps) => {
  const [isOpen, setIsOpen] = useState(initialCollapsibleState)

  const { t } = useTranslation()

  useEffect(() => setIsOpen(initialCollapsibleState), [initialCollapsibleState])

  const handeCollapse = () => {
    setIsOpen(oldState => {
      onCollapse?.(!oldState)

      return !oldState
    })
  }

  const IconCollapse = useMemo(
    () => (isOpen ? FiChevronUp : FiChevronDown),
    [isOpen]
  )
  const textCollapse = useMemo(
    () =>
      isOpen ? t('common:actions.showLess') : t('common:actions.showMore'),
    [isOpen, t]
  )

  return (
    <S.Container
      className={`panel ${className || ''}`}
      template={template}
      {...rest}
    >
      {(title || description || headerSideContent) && (
        <CardHeader
          {...(isCollapsible ? { onClick: handeCollapse, role: 'button' } : {})}
        >
          {iconLeft && (
            <div className="panel__header-icon__left">{iconLeft}</div>
          )}

          <div className="panel__header-heading">
            {!!title && <strong className="title">{title}</strong>}

            {!!description && (
              <span className="description">{description}</span>
            )}
          </div>
          <div className="panel__header-side-content">
            {headerSideContent}

            {!!isCollapsible && (
              <Button
                template={isOpen ? 'transparentPrimary' : 'light'}
                customWidth="auto"
                iconRight={<IconCollapse />}
                as="span"
                size="xsmall"
                text={textCollapse}
                style={{ whiteSpace: 'nowrap' }}
              />
            )}
          </div>
        </CardHeader>
      )}
      <Collapse isOpen={isOpen}>
        {unmountContentIfCollpase && !isOpen ? (
          <></>
        ) : (
          <CardBody className={noPaddingBody ? 'no-padding' : ''}>
            {isLoading ? <Loader /> : children}
          </CardBody>
        )}
      </Collapse>
    </S.Container>
  )
}

export default Panel

Panel.defaultProps = {
  title: null,
  template: 'default',
  description: null,
  headerSideContent: null,
  noPaddingBody: false,
  initialCollapsibleState: true,
  children: null,
  isLoading: false,
  isCollapsible: false,
  className: null
}
