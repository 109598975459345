import axios from 'axios';

export const api = '/api/shopping-cart';
export const publicApi = '/';

/**
 * Atualiza informações do carrinho
 * [bfF-Proxy] router.put('/:id', ShoppingCartController.updateCart)
 */
export const updateShoppingCart = ({ cart_id, body }) =>
  axios.put(`${api}/${cart_id}`, body);

/**
 * Atualiza status do carrinho
 * [bfF-Proxy] router.put('/:cart_id/status', ShoppingCartController.updateCartStatus)
 * {
 *   "statusType": "newStatus"
 * }
 */
export const updateShoppingCartStatus = ({ cart_id, body }) =>
  axios.put(`${api}/${cart_id}/status`, body);

/**
 * Envia o link do carrinho para o cliente
 * [bfF-Proxy] router.post('/send-link', ShoppingCartController.sendCartLink)
 */
export const sendLink = ({ cart_id }) =>
  axios.post(`${api}/send-link/${cart_id}`);

/**
 * Gera um carrinho novo
 * [bfF-Proxy] router.post('/generate', ShoppingCartController.generate)
 */
export const generate = ({ body }) => axios.post(`${api}/generate`, body);

/**
 * Pega dados de um carrinho
 */
export const view = ({ id }) =>
  axios.get(`${publicApi}shopping-cart/${id}?host=zonasul.newtail.com.br`);

/**
 * Buscar ofertas
 */

export const search = ({ query }) =>
  axios.get(`${publicApi}search/public-offers`, {
    params: query,
  });

export const shoppingCartService = {
  sendLink,
  updateShoppingCart,
  updateShoppingCartStatus,
  generate,
  view,
  search,
};

// BFF-Proxy
//

// router.get('/', ShoppingCartController.getCarts)
// router.get('/minified', ShoppingCartController.getCartsMinified)
// router.get('/:id', ShoppingCartController.getCart)
