/**
 * Parei tratando preview de arquivos.
 * Precisa melhorar placeholder e conteúdo real
 * Precisa esconder um tipo quando o outro for enviado
 */

import React, { useCallback } from 'react'
import { FiImage } from 'react-icons/fi'
import { VscFilePdf } from 'react-icons/vsc'

import PropTypes from 'prop-types'

import Loading from '../Loading'

import * as S from './styles'

const WhatsAppMessagePreview = ({
  messages,
  isEditing,
  allowMediaOnMessage = true,
  isLoadingImage
}) => {
  const renderImage = media => {
    if (media?.url)
      return (
        <>
          <img src={media.url} alt="Imagem da mensagem" />
          <Loading status={isLoadingImage} onlyLine />
        </>
      )

    if (!isEditing) return null

    return (
      <S.FilePreview>
        <div className="icon">
          <FiImage />
        </div>
        <div className="text">Se enviada, a imagem será exibida aqui.</div>
      </S.FilePreview>
    )
  }

  const renderDocument = media => {
    if (media?.type === 'document')
      return (
        <>
          <S.FilePreview
            {...(media?.url
              ? { as: 'a', href: media.url, target: '_blank' }
              : {})}
          >
            <div className="icon">
              <VscFilePdf />
            </div>
            <div className="text">
              {media.name || media.url.split('/').at(-1)}
            </div>
          </S.FilePreview>
          <Loading status={isLoadingImage} onlyLine />
        </>
      )

    if (!isEditing) return null

    return (
      <S.FilePreview>
        <div className="icon">
          <VscFilePdf />
        </div>
        <div className="text">Se enviado, o arquivo PDF será exibido aqui.</div>
      </S.FilePreview>
    )
  }

  /**
   * Memos
   */

  const showDocument = useCallback(
    media =>
      allowMediaOnMessage &&
      (media?.type === 'document' || (isEditing && !media?.type)),
    [allowMediaOnMessage, isEditing]
  )

  const showImage = useCallback(
    media =>
      allowMediaOnMessage &&
      (media?.type === 'image' || (isEditing && !media?.type)),
    [allowMediaOnMessage, isEditing]
  )

  return (
    <S.Container>
      {messages.map(({ text, media, isUser, buttons }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <S.MessageContainer key={index}>
          {showDocument(media) && (
            <S.Message isUser={isUser}>{renderDocument(media)}</S.Message>
          )}

          {(showImage(media) || text) && (
            <S.Message isUser={isUser}>
              {showImage(media) && renderImage(media)}

              {!!text && (
                <p // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: text
                  }}
                />
              )}
            </S.Message>
          )}

          {!!buttons?.length && (
            <div className="buttons">
              {buttons.map(item => (
                <button type="button" key={item.text}>
                  {item.text}
                </button>
              ))}
            </div>
          )}
        </S.MessageContainer>
      ))}
    </S.Container>
  )
}

WhatsAppMessagePreview.defaultProps = {
  isEditing: false,
  isLoadingImage: false,
  allowMediaOnMessage: true
}

WhatsAppMessagePreview.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      media: PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string
      })
    })
  ).isRequired,
  isEditing: PropTypes.bool,
  isLoadingImage: PropTypes.bool,
  allowMediaOnMessage: PropTypes.bool
}

export default WhatsAppMessagePreview
