import { formatIndustryReportHistoryCampaign } from '~/modules/retailMedia/dtos/industry/campaign/reportHistory'

import { types } from '../constants'

const parentKey = 'report'
const key = 'getHistory'
const reduxKey = types.getHistory

const index = {
  pending: `${reduxKey}_PENDING`,
  fulfilled: `${reduxKey}_FULFILLED`,
  rejected: `${reduxKey}_REJECTED`
}

export const getHistory = {
  [index.pending]: ({ draft }: ActionData) => {
    draft[parentKey][key].loading = true
    draft[parentKey][key].error = null
  },
  [index.fulfilled]: ({
    draft,
    action
  }: GetReportCampaignsHistoryFulfilled) => {
    draft[parentKey][key].data = formatIndustryReportHistoryCampaign(
      action.payload
    )
    draft[parentKey][key].loading = false
    draft[parentKey][key].error = null
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft[parentKey][key].loading = false
    draft[parentKey][key].error = action.payload
  }
}
