// Função para simular a requisição GET

import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

export const getAdTypesServiceKey = 'getAdTypes'

// export const getAdTypesService = async (params: GetAdTypesRequestParams) => {
//   await new Promise(resolve => setTimeout(resolve, 1000))

//   // const fakeData = ['sponsored_brand']
//   const fakeData = ['product', 'sponsored_brand']
//   // const fakeData = ['banner', 'product', 'sponsored_brand']

//   return { data: { ad_types: fakeData } } as {
//     data: GetAdTypesResponse
//   }
// }

export const getAdTypesService = (params: GetAdTypesRequestParams) => {
  return axios.get<GetAdTypesResponse>(`${baseAPI}/configuration/ad-types`, {
    params
  })
}

export const postAdTypesService = (body: PatchAdTypesRequest) => {
  return axios.post(`${baseAPI}/configuration/ad-types`, body)
}
