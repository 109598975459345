import React from 'react';

import { paymentMethods } from '~/helpers';

import { AuthorizationTypes } from './types';

const PaymentMethod = ({ payment }) => {
  if (!payment?.method) return null;

  return <>{paymentMethods(payment?.method)} </>;
};

PaymentMethod.propTypes = AuthorizationTypes;

export default PaymentMethod;
