import service from '~/modules/retailMedia/services/advertiser-publisher'

import { types } from './constants'

export const listPublisherAdvertisers = params => ({
  type: types.listAdvertisers,
  payload: service.listAdvertisers(params)
})

export const listAdvertiserPublishers = params => ({
  type: types.listPublishers,
  payload: service.listPublishers(params)
})

export default { listAdvertiserPublishers, listPublisherAdvertisers }
