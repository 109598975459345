import { history } from '~/helpers';

import { campaign as service } from '../../services';
import { types } from './constants';
/**
 * Create */
const create = ({
  body,
  successFeedbackMsg = 'Campanha criada  com sucesso',
  errorFeedbackMsg = 'Houve um erro ao criar a campanha',
}) => ({
  type: types.create,
  payload: service.create(body),
  successFeedbackMsg,
  errorFeedbackMsg,
  onSuccess: ({ data }) => {
    // eslint-disable-next-line no-underscore-dangle
    history.push(`/messaging-campaigns/view/${data._id}`);
  },
});

/**
 * Upload file */
const upload = ({
  body,
  axiosOptions = {},
  successFeedbackMsg = 'Arquivo enviado com sucesso',
  errorFeedbackMsg = 'Houve um erro ao enviar o arquivo',
  onSuccess,
}) => ({
  type: types.upload,
  payload: service.upload({ body, axiosOptions }),
  successFeedbackMsg,
  errorFeedbackMsg,
  onSuccess,
});

/**
 * Get one */
const get = id => ({
  type: types.get,
  payload: service.get(id),
});

const cancelCampaign = id => ({
  type: types.cancelCamapign,
  payload: service.cancelCampaign(id),
});

/**
 * Update
 *
 * @param {string} data.id Id do item atualizado
 * @param {object} data.body Corpo da requisição de atualização
 */
const update = data => ({
  type: types.update,
  payload: service.update(data),
  successFeedbackMsg: 'Campanha editada  com sucesso',
  errorFeedbackMsg: 'Houve um erro ao editar a campanha',
  onSuccess: response => {
    // eslint-disable-next-line no-underscore-dangle
    history.push(`/messaging-campaigns/view/${data.id}`);
  },
});

/**
 * Delete
 *
 * @param {string} data.id Id do item atualizado
 */
const remove = ({ id, onSuccess }) => ({
  type: types.remove,
  payload: service.remove(id),
  onSuccess,
});

/**
 * Get all */
const list = queries => ({
  type: types.list,
  payload: service.list(queries),
});

/**
 * Handle page */
const setPage = value => ({
  type: types.page,
  payload: value,
});

/**
 * Handle items por page */
const setPerPage = value => ({
  type: types.perPage,
  payload: value,
});

/**
 * Get dashboard data */
const dashboard = id => ({
  type: types.dashboard,
  payload: service.dashboard(id),
  errorFeedbackMsg: 'Houve um erro ao carregar os dados das campanhas',
});

export default {
  list,
  setPage,
  setPerPage,
  get,
  create,
  update,
  upload,
  remove,
  dashboard,
  cancelCampaign,
};
