import styled, { css } from 'styled-components';

export const Image = styled.img`
  max-width: 376px;
  display: block;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    .hasBorder {
      .text {
        margin-bottom: 0;

        &::first-letter {
          text-transform: capitalize;
        }
      }

      & + .hasBorder {
        border-top: 1px solid ${theme.colors.tertiary};
        margin-top: 1rem;
        padding-top: 1rem;
      }
    }

    .description {
      margin-top: ${theme.spacings.xxxsmall};
    }
  `}
`;
