import React from 'react'
import {
  FiCreditCard,
  FiDollarSign,
  FiEye,
  FiPercent,
  FiShoppingBag
} from 'react-icons/fi'
import { HiOutlineMegaphone } from 'react-icons/hi2'
import { IoRocketOutline } from 'react-icons/io5'
import { MdAdsClick } from 'react-icons/md'
import { RiWallet2Fill, RiWallet2Line } from 'react-icons/ri'

export const dashboardIcons: CardIcons = {
  publisher: {
    prints: <FiEye className="text-warning" />,
    clicks: <MdAdsClick className="text-info" />,
    ctr: <FiPercent className="text-primary" />,
    income: <FiDollarSign className="text-success" />,
    conversion: <FiShoppingBag className="text-success" />,
    actives_campaigns: <IoRocketOutline className="text-info" />,
    ads_income: <FiDollarSign className="text-success" />,
    balance: <RiWallet2Line className="text-success" />,
    sales_income: <FiShoppingBag className="text-warning" />,
    actives_advertisers: <HiOutlineMegaphone className=" text-primary" />
  },
  advertiser: {
    prints: <FiEye className=" text-warning" />,
    clicks: <MdAdsClick className=" text-info" />,
    ctr: <FiPercent className=" text-primary" />,
    income: <FiDollarSign className=" text-success" />,
    conversion: <FiShoppingBag className=" text-success" />,
    total_spent: <FiCreditCard className=" text-warning" />
  }
}
