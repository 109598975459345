import * as types from '../../constants';

const index = {
  pending: `${types.GET_PAYMENT_METHODS}_PENDING`,
  fulfilled: `${types.GET_PAYMENT_METHODS}_FULFILLED`,
  rejected: `${types.GET_PAYMENT_METHODS}_REJECTED`,
};

/**
 * Get payment methods list
 */

export const cases = {
  [index.pending]: ({ draft }) => {
    draft.paymentMethodsLoading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    const onlineMethods =
      action.payload.onlineMethods?.filter(method => method.active) || [];
    const inPersonMethods =
      action.payload.inPersonMethods?.filter(method => method.active) || [];

    draft.paymentMethodsList = { onlineMethods, inPersonMethods };
    draft.inPersonMethods = inPersonMethods;
    draft.onlineMethods = onlineMethods;

    draft.paymentMethodsLoading = false;
    draft.paymentMethodsError = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.paymentMethodsLoading = false;
    draft.paymentMethodsError = action.payload;
  },
};
