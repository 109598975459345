import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Panel from '~/components/Panel';
import Table from '~/components/Table';
import { formatMoney, formatPercent } from '~/helpers';

import { getTopProductsInfo } from '../dashboard-actions';

const schema = {
  name: {
    render: obj => obj['OrderItems.name'],
    title: 'Nome',
  },
  quantity: {
    render: obj => obj['OrderItems.quantitySum'].toFixed(),
    title: 'Quantidade',
  },
  price: {
    render: obj => formatMoney(obj['OrderItems.gmv']),
    title: 'Valor',
  },
  margin: {
    render: obj => formatPercent(obj['OrderItems.margin'], 0),
    title: 'Margem',
  },
  cost: {
    render: obj => formatMoney(obj['OrderItems.cost']),
    title: 'Custo',
  },
};

const TopProductsTable = () => {
  const { startDate, endDate } = useSelector(state => state.datepickerHeader);

  const { topProductsLoading, topProductsInfoList } = useSelector(
    state => state.dashboard,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTopProductsInfo(startDate, endDate));
  }, [startDate, endDate, dispatch]);

  return (
    <Panel
      noPaddingBody
      title="Top 10 produtos mais vendidos"
      isLoading={topProductsLoading}
    >
      <Table schema={schema} data={topProductsInfoList} />
    </Panel>
  );
};

export default TopProductsTable;
