import PropTypes, { bool } from 'prop-types'

export const AuthorizationTypes = {
  orderId: PropTypes.string,
  payment: PropTypes.shape({
    autorization_id: PropTypes.string,
    transaction_id: PropTypes.string,
    status: PropTypes.string,
    createdAt: PropTypes.string,
    method: PropTypes.string,
    method_info: PropTypes.string,
    transactioned_at: PropTypes.string,
    reserved_at: PropTypes.string,
    reserved_value: PropTypes.number,
    value: PropTypes.number,
    fraud_analysis_status: PropTypes.string
  }),
  hasWaitingRefund: bool
}
