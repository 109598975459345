import { IconType } from 'react-icons'
import { FiDollarSign as icon } from 'react-icons/fi'

import {
  PaymentsReport,
  PaymentsReport_OLD,
  PaymentMethods
} from '~/pages/financial'

export const financial: RootMenuItem<IconType> = {
  id: 'ee20b76d-8cff-45d9-a195-d2c33c79986c',
  path: '/financial',
  name: 'Financeiro',
  pageTitle: 'Financeiro',
  icon: icon,
  children: [
    {
      id: '1bdb2bb2-8d4f-4270-a5cf-7a88722c86c2',
      path: '/financial/payments',
      name: 'Relatório de pagamentos',
      pageTitle: 'Relatório de pagamentos',
      component: PaymentsReport
    },
    {
      id: null, // rota-sem-id
      path: '/financial/payments_old',
      name: 'Relatório de pagamentos - Antigo',
      pageTitle: 'Relatório de pagamentos',
      component: PaymentsReport_OLD,
      hideMenu: true
    },
    {
      id: '40f6930a-541f-45a5-b9ea-d014d34447a6',
      path: '/financial/payment-methods',
      name: 'Métodos de pagamento',
      pageTitle: 'Métodos de pagamento',
      component: PaymentMethods
    }
  ]
}
