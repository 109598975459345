import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'

import { isEqual } from 'lodash'
import moment from 'moment'

import {
  formatSearchString,
  getAccountType,
  getRetailMediaAccountType,
  getWhiteLabelPublisherId,
  requestRetailMediaAccountID
} from '~/helpers'
import handleQueryParams from '~/helpers/handleQueryParams'
import { handleSortDirection } from '~/helpers/sortData'
import { useAppInfo } from '~/hooks/useAppInfo'
import useFetchSWR from '~/hooks/useFetchSWR'
import useQueryParams from '~/hooks/useQuery'
import { formatListCampaign } from '~/modules/retailMedia/dtos/common/campaign/list'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'
import { useAppSelector } from '~/store/hooks'

import { schema } from '../schemas'

const CampaignTableContext = createContext<CampaignTableContextData>(
  {} as CampaignTableContextData
)

const dynamicKeys: (keyof GetCampaignsQueryParam)[] = [
  'publisher_id',
  'advertiser_id',
  'ad_type',
  'status',
  'name',
  'start_date',
  'end_date'
]

const CampaignTableProvider = ({ children }: React.PropsWithChildren) => {
  const { isWhiteLabel } = useAppInfo()

  const accountRMType = getRetailMediaAccountType()
  const isAdvertiser = accountRMType === 'advertiser'
  const isPublisher = accountRMType === 'publisher'

  const accountType = getAccountType() as 'grocery' | 'industry'

  const [data, setData] = useState(null)

  const searchParams = useQueryParams()

  const paramId = searchParams.get('rmid')

  const id = useMemo(
    () => (isWhiteLabel && isAdvertiser ? getWhiteLabelPublisherId() : paramId),
    [isAdvertiser, isWhiteLabel, paramId]
  )

  const isListAll = !id && (!isWhiteLabel || (isWhiteLabel && isPublisher))

  const [innerLoading, setInnerLoading] = useState(false)
  const [queryParams, setQueryParams] = useState({} as GetCampaignsQueryParam)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [sortDirection, setSortDirection] = useState(null)
  const [sortKey, setSortKey] = useState(null)

  /**
   * Redux
   */

  const { retailMedia: retailMediaFilters } = useAppSelector(
    state => state.filters
  )

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const params = useMemo(
    () =>
      ({
        [requestRetailMediaAccountID]: id,
        quantity: perPage,
        page,
        ad_type: retailMediaFilters?.adType?.value,
        status: retailMediaFilters?.status?.value,
        name: retailMediaFilters?.campaignName || null,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        account_info: isListAll,
        order_direction: sortDirection,
        order_by: sortKey
      }) as GetCampaignsQueryParam,
    [
      page,
      perPage,
      retailMediaFilters?.adType?.value,
      retailMediaFilters?.status?.value,
      retailMediaFilters?.campaignName,
      id,
      startDate,
      endDate,
      isListAll,
      sortDirection,
      sortKey
    ]
  )

  useLayoutEffect(() => {
    if (isEqual(params, queryParams)) {
      return
    }

    const { shouldResetFixedKeys } = handleQueryParams({
      params,
      currentParams: queryParams,
      dynamicKeys
    })

    if (shouldResetFixedKeys) {
      params.page = 1
      setPage(1)
    }
    setInnerLoading(true)
    setQueryParams(params)
  }, [params, queryParams])

  const urlGetCampaign = useMemo(
    () => `${reatailmediaApi}/campaign/v2?${formatSearchString(queryParams)}`,
    [queryParams]
  )

  const {
    data: dataSWR,
    error,
    isValidating,
    mutate
  } = useFetchSWR<ResponseListIndustryCampaign | ResponseListGroceryCampaign>({
    url: urlGetCampaign,
    refreshInterval: 60000,
    revalidateOnFocus: false,
    // queryParams is not empty
    shouldFetch: !!Object.keys(queryParams).length
  })

  useEffect(() => {
    if (dataSWR) {
      setData(dataSWR)
    }
  }, [accountType, dataSWR])

  const {
    data: campaigns,
    currentPage,
    pages,
    total
  }: CampaingDataMemo = useMemo(() => {
    setInnerLoading(false)
    return {
      currentPage: data?.currentPage,
      pages: data?.pages,
      total: data?.total,
      data: formatListCampaign(data?.data)
    }
  }, [data])

  /**
   * HANDLE TOGGLE ACTIVE CAMPAIGN
   */

  const handleMutate = useCallback(
    async ({ value, data }: HandleMutateProps) => {
      const id = data.id
      const mutateData = {
        ...dataSWR,
        data: dataSWR.data.map(item =>
          item.id === id ? { ...item, active: value } : item
        )
      }

      await mutate(mutateData)
    },
    [dataSWR, mutate]
  )
  /**
   * Handle list
   */

  const handlePagination = useCallback((page: number) => {
    setPage(page)
  }, [])

  const handleItemsPerPageChange = useCallback(
    ({ value }: { value: number }) => {
      setPerPage(value)
    },
    []
  )

  const handleSortList = useCallback(
    (data: OnSortProps) => {
      const selectedKey = data.key
      const sortInitialDirection = data?.sortInitialDirection

      const response = handleSortDirection({
        selectedKey,
        currentSortDirection: sortDirection,
        currentSortKey: sortKey,
        sortInitialDirection
      })

      setSortDirection(response.sortDirection)
      setSortKey(response.sortKey)
    },
    [sortDirection, sortKey]
  )

  return (
    <>
      <CampaignTableContext.Provider
        value={{
          data: campaigns,
          error,
          innerLoading,
          isValidating,
          currentPage,
          pages,
          page,
          perPage,
          total,
          schema: schema({ isListAll }),
          handlePagination,
          handleItemsPerPageChange,
          handleMutate,
          sortDirection,
          sortKey,
          handleSortList,
          queryParams: formatSearchString(queryParams)
        }}
      >
        {children}
      </CampaignTableContext.Provider>
    </>
  )
}

function useCampaignTable(): CampaignTableContextData {
  const context = useContext(CampaignTableContext)

  if (!context) {
    throw new Error(
      'useCampaignTable must be used within an CampaignTableProvider'
    )
  }

  return context
}

export { CampaignTableContext, CampaignTableProvider, useCampaignTable }
