export const getImageDimensions = (url: string) => {
  const img = document.createElement('img')

  const promise = new Promise<Dimension>((resolve, reject) => {
    img.onload = () => {
      // Natural size is the actual image size regardless of rendering.
      // The 'normal' `width`/`height` are for the **rendered** size.
      const width = img.naturalWidth
      const height = img.naturalHeight

      // Resolve promise with the width and height
      resolve({ width, height })
    }

    // Reject promise on error
    img.onerror = reject
  })

  // Setting the source makes it start downloading and eventually call `onload`
  img.src = url

  return promise
}

// How to use in an async function
// (async() => {
//   const imageUrl = 'http://your.website.com/userUploadedImage.jpg';
//   const imageDimensions = await imageSize(imageUrl);

//   console.info(imageDimensions); // {width: 1337, height: 42}
// })();
