import React from 'react'

import { DatepickerHeader } from '~/components/datepicker-header'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import GmvPerDayChart from './elements/gmv-per-day-chart'
import MarginPerDayChart from './elements/margin-per-day-chart'
import Statistics from './elements/statistics'
import TopCustomersTable from './elements/top-customers.table'
import TopProductsTable from './elements/top-products-table'

const Default = () => (
  <>
    {/* Meta <title> */}
    <PageTitle title="Dashboard" />

    <HeaderPages
      className="has-datepicker"
      title="Dashboard"
      sideRightComponent={<DatepickerHeader maxEndDate={'D0'} />}
    />

    <Statistics />

    <GmvPerDayChart />

    <TopProductsTable />

    {/* <MarginPerDayChart startDate={startDate} endDate={endDate} /> */}

    {/* <TopCustomersTable startDate={startDate} endDate={endDate} /> */}
  </>
)

export default Default
