import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

const ContainerModifier = {
  active: ({ theme }) => css`
    box-shadow: 0 1rem 2rem -12px ${transparentize(0.75, theme.primary)};
    transform: scale(1);
    opacity: 1;
    z-index: 111;
  `
}

export const Container = styled.div`
  ${({ theme, isActive, isOpen }) => css`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    overflow: hidden;

    transform: scale(0.95);
    opacity: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    /* margin: 0.5rem; */

    height: ${isOpen ? '100%' : '40px'};
    width: 100%;
    border-radius: ${theme.border.radius.medium};

    box-shadow: 0 0 ${transparentize(1, theme.primary)};
    background-color: ${transparentize(0.05, theme.primary)};

    font-size: ${theme.font.sizes.small};

    color: ${theme.light};

    transition: 0.2s ease-in-out all;

    ${isActive && ContainerModifier.active({ theme })};

    header {
      display: flex;
      align-items: center;

      min-height: 40px;
      padding: 0.5rem;

      > .tagsButton {
        background-color: ${theme.colors.hover} !important;
      }

      > .closeButton {
        > .icon {
          margin-right: 0.25rem;
          color: ${theme.secondary};
          font-size: ${theme.font.sizes.medium} !important;
        }
      }

      .label {
        flex: 1;
      }
    }
  `}
`
