import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmptyPage from '~/components/empty-page';
import ListPagination from '~/components/ListPagination';
import Loading from '~/components/Loading';
import Panel from '~/components/Panel';
import Table from '~/components/Table';
import { useAlert } from '~/hooks/useAlert';
import templateAction from '~/modules/messagingCampaigns/store/template/actions';
import { TEMPLATE_REDUX_QUERY as reduxQuery } from '~/modules/messagingCampaigns/store/template/constants';

import { schema } from './table-schema';

const TemplateTable = () => {
  const dispatch = useDispatch();

  const {
    template: {
      list: {
        data: { data, total },
        loading,
        page,
        perPage,
        error,
      },
    },
  } = useSelector(state => state.messagingCampaigns);

  const { query } = useSelector(state => state.filters);

  useSelector(state => state.filters);

  const { templateStatus: filteredTemplateType } = useSelector(
    state => state.filters,
  );

  const filteredQuery = useMemo(
    () => query?.[`${reduxQuery}`] || null,
    [query],
  );

  useEffect(() => {
    dispatch(
      templateAction.list({
        page: page,
        quantity: perPage,
        title: filteredQuery ? `${filteredQuery}%` : null,
        status: filteredTemplateType?.type,
      }),
    );
  }, [dispatch, perPage, page, filteredQuery, filteredTemplateType]);

  const [AlertOnError] = useAlert(error);

  /**
   * Handle pagination
   */
  const handlePagination = useCallback(
    page => {
      dispatch(templateAction.setPage(page));
    },
    [dispatch],
  );

  const handleItemsPerPageChange = useCallback(
    ({ value }) => {
      dispatch(templateAction.setPerPage(value));
    },
    [dispatch],
  );

  return (
    <>
      <Loading status={loading}>Carregando lista de templates</Loading>

      <AlertOnError />

      {!loading && !!total && (
        <>
          <ListPagination
            total={total}
            label={total > 1 ? 'templates' : 'template'}
            currentPage={page}
            itemsPerPage={perPage}
            onClickPagination={handlePagination}
            onItemsPerPageChange={handleItemsPerPageChange}
          />

          <Panel noPaddingBody isLoading={loading}>
            <Table
              schema={schema}
              data={data}
              total={total}
              striped={false}
              className={'purpleHover'}
            />
          </Panel>

          <ListPagination
            total={total}
            label={total > 1 ? 'templates' : 'template'}
            currentPage={page}
            itemsPerPage={perPage}
            onClickPagination={handlePagination}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </>
      )}

      {total === 0 && (
        <EmptyPage
          title="Templates"
          subtitle="Você ainda não tem templates cadastrados."
          svgPath="photos/chats-empty"
        />
      )}
    </>
  );
};

export default TemplateTable;
