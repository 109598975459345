import React, { useState, useEffect, useMemo } from 'react'
import { FiCalendar, FiMapPin, FiShoppingCart } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { Icon } from '~/components/Icon'
import Loading from '~/components/Loading'
import Status from '~/components/Status'
import { formatDateToString, channelName } from '~/helpers'
import useFetchSWR from '~/hooks/useFetchSWR'

import OrderItems from '../OrderItems'

import { Container } from './styles'

const OrderInfos = () => {
  const [loading, setLoading] = useState(true)

  const { orderOpen } = useSelector(({ chats }) => chats)

  const { id } = orderOpen

  const { data: orderData, error: errorSWR } = useFetchSWR({
    url: `${process.env.REACT_APP_HOST}/api/order/full-order/${id}`
  })

  useEffect(() => setLoading(!(orderData || errorSWR)), [orderData, errorSWR])

  const formattedDate = useMemo(
    () => formatDateToString(orderData?.createdAt),
    [orderData?.createdAt]
  )

  const channelFormatted = useMemo(
    () => channelName(orderData?.channel_id),
    [orderData]
  )

  const locationName = useMemo(() => {
    const name = orderData?.location?.[0]?.name

    return name || null
  }, [orderData])

  const status = useMemo(() => orderData?.status?.[0], [orderData?.status])

  return (
    <Container>
      <section>
        <Loading status={loading}>Carregando informações do pedido</Loading>

        {!loading && orderData && (
          <header>
            {status && (
              <div className="box status">
                <Status status={status} />
              </div>
            )}

            {formattedDate && (
              <div className="box">
                <Icon icon={FiCalendar} />
                <div className="text">
                  <strong>Realizado em:</strong> {formattedDate}
                </div>
              </div>
            )}

            {/* Canal de origem do pedido */}
            {channelFormatted && (
              <div className="box">
                <Icon icon={FiShoppingCart} />
                <div className="text">
                  <strong>Origem:</strong> {channelFormatted}
                </div>
              </div>
            )}

            {/* Loja responsável pelo pedido */}
            {locationName && (
              <div className="box">
                <Icon icon={FiMapPin} />
                <div className="text">
                  <strong>Local:</strong> {locationName}
                </div>
              </div>
            )}
          </header>
        )}

        {orderData?.items && (
          <OrderItems
            items={orderData.items}
            totalPrice={orderData.total_price}
            paymentsInfos={orderData?.payments}
            shippingInfos={orderData?.shipping_address}
            shipments={orderData?.shipments}
          />
        )}
      </section>
    </Container>
  )
}

export default OrderInfos
