/**
 *
 * @param date data atual
 * @param daysThreshold limite de dias que é considerado item novo
 * @returns retorna um boolean - diferença de doa <= limite de dias
 */

// adicionar type

interface Props {
  date: string
  daysThreshold: number
}

export const isNewItem = ({ date, daysThreshold }: Props) => {
  const currentDate = new Date()
  const itemDate = new Date(date)

  // Calcula a diferença de dias entre a data atual e a data do item
  const diffInDays =
    (currentDate.getTime() - itemDate.getTime()) / (1000 * 3600 * 24)

  return diffInDays <= daysThreshold
}
