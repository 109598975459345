import React from 'react';

import PropTypes from 'prop-types';

import Panel from '~/components/Panel';
import { FormGroup, Radio } from '~/components/unform';

const ActivelFields = ({ activeFieldsRef }) => {
  const optionsVisibility = [
    {
      id: `active_true'`,
      value: true,
      label: 'Local ativo',
    },
    {
      id: `active_false`,
      value: false,
      label: 'Local desativado',
    },
  ];

  return (
    <fieldset ref={activeFieldsRef}>
      <Panel>
        <FormGroup>
          <Radio options={optionsVisibility} name="active" required inline />
        </FormGroup>
      </Panel>
    </fieldset>
  );
};

export default ActivelFields;

ActivelFields.propTypes = {
  activeFieldsRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()])
    .isRequired,
};
