import { getAppInfo, getIsWhiteLabel } from '~/helpers'
import i18n from '~/i18n'
import { primary as theme } from '~/styles/themes'

import { formatStylesChart } from '../../helpers'
import { CreateDataset } from '../types'
import { OptionsScale } from './types'

const appInfo = getAppInfo()
const isWhiteLabel = getIsWhiteLabel()

const axisID = 'y-conversions'

const settings = {
  label: i18n.t('rm:metrics.conversions'),
  color: isWhiteLabel ? appInfo.colors.primary : theme.colors.success,
  dataKey: 'conversionNumber' as keyof ReportHistoryCampaigns,
  order: 11
}

const dataset: CreateDataset = ({ data }) => ({
  yAxisID: axisID,
  type: 'bar',
  label: settings.label,
  data: data?.[settings.dataKey] || null,
  order: settings.order,

  ...formatStylesChart({ color: settings.color, bgStyle: 'alphaGradient' })
})

const optionsScale: OptionsScale = ({
  showScaleRuler = true,
  drawOnChartArea = true,
  position = 'left'
}) => ({
  [axisID]: {
    display: showScaleRuler,
    grid: {
      // If true, draw lines on the chart area inside the axis lines. This is useful
      // when there are multiple axes and you need to control which grid lines are drawn.
      drawOnChartArea
    },
    position,
    suggestedMin: 0,
    beginAtZero: true,
    ticks: {
      z: 10,
      color: theme.colors.successDark
    }
  }
})

const conversionsPreset = { axisID, dataset, optionsScale, settings }

export default conversionsPreset
