import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/campaign`

export const listCampaignPlacesKey = 'listCampaignPlaces'
export const listCampaignPlacesService = (
  params: RequestGetPlacesServiceParams
) => {
  const { campaignId, count, page, quantity } = params
  return axios.get<GetPlacesServiceResponse>(`${baseURL}/${campaignId}/place`, {
    params: { count, quantity, page }
  })
}
