import React from 'react';
import { useSelector } from 'react-redux';

import Panel from '~/components/Panel';

import * as S from '../styles';

const GeneralInfoView = () => {
  const { data } = useSelector(
    ({ promotionFlyer }) => promotionFlyer.flyers.view,
  );

  return (
    <Panel title="Informações gerais">
      <S.RowWrapper className="hasBorder">
        <S.Wrapper>
          <strong>Título da campanha</strong>
          <p>{data?.name}</p>
        </S.Wrapper>
      </S.RowWrapper>

      {data?.description && (
        <S.RowWrapper className="hasBorder">
          <S.Wrapper>
            <strong>Descrição da campanha</strong>
            <p>{data?.description}</p>
          </S.Wrapper>
        </S.RowWrapper>
      )}

      <S.RowWrapper className="hasBorder">
        <S.GroupWrapper>
          <S.Wrapper>
            <strong>Data de início</strong>
            <p className="dateLabel">
              {data?.start_date || 'Nenhuma data cadastrada.'}
            </p>
          </S.Wrapper>

          <S.Wrapper>
            <strong>Data de término</strong>
            <p className="dateLabel">
              {data?.end_date || 'Nenhuma data cadastrada.'}
            </p>
          </S.Wrapper>

          <S.Wrapper>
            <strong>Duração</strong>
            <p>{data?.duration}</p>
          </S.Wrapper>
        </S.GroupWrapper>
      </S.RowWrapper>
    </Panel>
  );
};

export default GeneralInfoView;
