import * as types from '../constants';

const index = {
  pending: `${types.CREATE_CUSTOMER}_PENDING`,
  fulfilled: `${types.CREATE_CUSTOMER}_FULFILLED`,
  rejected: `${types.CREATE_CUSTOMER}_REJECTED`,
};

export const createCustomer = {
  [index.pending]: ({ draft }) => {
    draft.saveCustomersLoading = true;
    draft.error = null;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.customer = action.payload;
    draft.saveCustomersLoading = false;
    draft.error = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.saveCustomersLoading = false;
    draft.error = action.payload;
  },
};
