import styled, { css } from 'styled-components';

const containerModifiers = {
  isLast: theme => css`
    order: 3;
    margin-right: 0;
    margin-left: ${theme.spacings.xsmall};
  `,
};

export const Container = styled.div`
  ${({ theme, indicatorAtLast }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${theme.spacings.xsmall};

    order: -1;
    margin-right: ${theme.spacings.xsmall};

    ${indicatorAtLast && containerModifiers.isLast(theme)}
  `}
`;

export const ItemValue = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    min-width: 100px;
    height: 4rem;
    padding: 0.25rem 0.5rem;

    border-radius: ${theme.border.radius.large};

    background-color: ${theme.colors.primaryLighten60};

    .title {
      color: ${theme.colors.primary};
      text-align: center;
      font-size: ${theme.font.sizes.xsmall};
    }

    .subtitle {
      color: ${theme.colors.dark};
      text-align: center;
      font-size: ${theme.font.sizes.xxsmall};
    }

    .value {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25rem;
      margin-top: 0.25rem;

      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.bold};

      border-radius: ${theme.border.radius.large};

      background-color: ${theme.colors.white};
    }
  `}
`;

const valueModifiers = {
  indicatorIsFull: theme => css`
    color: ${theme.colors.white};

    &:before {
      background-color: ${theme.colors.warning};
    }
  `,
};

export const Value = styled.div`
  ${({ theme, indicatorValue }) => css`
    position: relative;

    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    margin-top: 0.25rem;

    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bolder};

    border-radius: ${theme.border.radius.medium};

    background-color: ${theme.colors.light};

    overflow: hidden;

    .text {
      z-index: 1;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      background-color: ${theme.colors.warningLighten40};
      height: 100%;
      width: ${`calc(100% - ${indicatorValue}%)`};
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: 0;
      background-color: ${theme.colors.warning};
      height: 2px;
      width: ${`calc(100% - ${indicatorValue}%)`};
    }

    ${indicatorValue <= 1 && valueModifiers.indicatorIsFull(theme)}
  `}
`;
