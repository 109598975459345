import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const ContainerTable = styled.section`
  ${({ theme }) => css`
    &[data-freeze='first-row-and-column'] {
      --spacing-right: 0;

      @media ${device.laptop} {
        --spacing-right: 2rem;
      }

      max-height: 90dvh;
      overflow: auto;

      /* Scrollbar  */

      /* width */
      ::-webkit-scrollbar {
        height: 1rem;
        border-radius: 0.75rem;
        position: sticky;
        bottom: 0;
        left: 0;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: ${theme.colors.tertiary};
        border-radius: 0.5rem;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${theme.colors.primary};
        border-radius: 0.5rem;
        /* Handle on hover */
        &:hover {
          background: ${theme.colors.hover};
        }
      }

      > table {
        table-layout: auto;

        tr {
          border-left: 0;
        }

        thead,
        tr > th,
        tr > td:first-child {
          position: sticky;
        }

        thead {
          top: 0;
          z-index: 50;
        }

        tbody {
          z-index: 20;
        }

        tr > th,
        tr > td {
          left: 0;
          z-index: 10;
        }

        thead tr > th:first-child {
          z-index: 35;
        }

        tbody tr > td:first-child {
          z-index: 30;
          border-left:;
        }

        tr > :first-child {
          &:after {
            content: '';
            background: rgb(222, 226, 230);
            width: 2px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  `}
`

export const Thead = styled.thead`
  ${({ theme }) => css`
    position: sticky;
    top: 0;
    background-color: ${theme.colors.neutral.high.medium};
    z-index: 3;
    > tr > th {
      background-color: ${theme.colors.neutral.high.medium};
    }
  `}

  /* Mobile */
  @media ${device.laptop_max} {
    table.cardOnMobile & {
      display: none;
    }
  }
`

export const Tbody = styled.tbody`
  ${({ theme }) => css`
    tr {
      transition: all 0.2s ease-in-out;
      border-left: 0.25rem solid transparent;

      td {
        .label-mobile {
          display: none;
        }
      }

      &:hover {
        td {
          background-color: ${theme.colors.neutral.high.medium} !important;
          border-bottom-color: ${theme.colors.hover};

          color: ${theme.colors.primary};
        }
      }
    }

    /* Mobile */
    @media ${device.laptop_max} {
      table.cardOnMobile & {
        tr {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          width: calc(100% - 1rem);
          border: 1px solid #eee;
          margin: 1.5rem 0.5rem;
          border-radius: 1rem;
          box-shadow: 0 0 3rem -0.75rem rgba(90, 90, 90, 0.25);

          > td {
            padding: 0.5rem 1rem;
            flex: 1 1 100%;

            .label-mobile {
              font-weight: 600;
              padding: 0;
            }

            &:first-child {
              border-top: none;
              padding-right: 1.25rem;
              padding-top: 0.75rem;
            }

            &:last-child {
              padding-bottom: 0.75rem;
            }

            &.col__menu,
            &.col__title {
              .label-mobile {
                display: none;
              }
            }

            &.col__menu {
              position: absolute;
              top: 0.25rem;
              right: 0.25rem;
              border-top: none;
              padding: 0;
            }

            &.col__mobile_inline {
              flex: 1 1 50%;
            }
          }
        }
      }
    }
  `}
`

export const Tr = styled.tr``

export const Th = styled.th<ThStylesProps>`
  ${({ theme, sideBorder, activeColumn }) => css`
    transition: 0.1s ease-in-out all;

    &:hover {
      background-color: rgba(130, 130, 130, 0.1);
    }

    &:not(:last-child) {
      --borderColor: #d8d8df;
      border-right: ${sideBorder ? '1px solid var(--borderColor)' : 'none'};
    }

    .mainInfo {
      &:after {
        transition: all 0.3s ease-in-out;
      }
    }

    ${activeColumn &&
    css`
      .mainInfo {
        position: relative;

        .text {
          position: relative;
          z-index: 2;
        }

        &:after {
          content: '';
          z-index: 1;
          position: absolute;
          background-color: rgba(130, 130, 130, 0.15);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: calc(100% + 0.75rem);
          height: calc(100% + 0.5rem);
          border-radius: 0.25rem;
          mix-blend-mode: multiply;
        }
      }
    `}
  `}
  > .contentCell {
    display: flex;
    justify-content: space-between;
  }

  &.col {
    &__infos {
      small {
        white-space: nowrap;
      }
    }
  }
`

export const Td = styled.td<TdStylesProps>`
  ${({ sideBorder, smallerRowPadding }) => css`
    padding: ${smallerRowPadding ? '.5rem .75rem' : '.75rem'} !important;

    &:not(:last-child) {
      border-right: ${sideBorder ? '1px solid #dee2e6' : 'none'};
    }
  `}

  &.nowrap {
    white-space: nowrap;
  }

  &.col {
    &__favicon {
      width: 3rem;

      div {
        display: flex;
        justify-content: center;
        align-items: center;

        color: var(--primary);

        &.channel {
          &__whatsapp,
          &__whatsapp_bot {
            color: #128c7e;
          }

          &__vtex,
          &__magento_ecommerce {
            font-size: 18px;
            color: #3e4e6b;
          }

          &__televendas {
            color: var(--primary);
          }

          &__dia_das_maes {
            color: #f05555;
          }

          &__b2b {
            color: #f30055;
          }

          &__purchase {
            color: #54412f;
          }
        }
      }
    }

    &__infos {
      .item {
        line-height: normal;

        &__id {
          font-weight: 700;
          font-size: 1rem;
          white-space: nowrap;
        }

        &__name {
          color: var(--gray);
          line-height: 1;
          font-size: 0.75rem;
          white-space: nowrap;
        }
      }
    }

    &__payment {
      text-align: center;
      line-height: normal;

      .paymentMethod {
        padding: 0.4rem 0.75rem;
        border-radius: 2rem;
        display: flex;
        justify-content: center;
        line-height: 1;
        margin: 0.2rem 0;
        white-space: nowrap;

        border: 1px solid;

        &.online {
          color: var(--primary);
        }
        &.in_person {
          color: var(--info);
        }
        &.credit_card {
          color: var(--success);
        }
        &.no_method_chosen {
          color: var(--gray);
        }
      }
    }

    &__shipment {
      white-space: nowrap;
      line-height: 1;
    }

    &__name {
      font-size: 0.875rem;
      max-width: 10rem;
      line-height: 1.25;

      .item__name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &__actions {
      .buttons {
        display: flex;

        > * + * {
          margin-left: 0.5rem;
        }
      }
    }

    &__status {
      div {
        padding-left: 1.25rem;
        position: relative;
        line-height: 1.25;
        white-space: nowrap;

        button {
          margin-top: 0.25rem;
          white-space: nowrap;
        }

        &:before {
          content: '';
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &.status__ {
          &:before {
            background-color: var(--primary);
          }
          /* Blue status */
          &info {
            &:before {
              background-color: var(--info);
            }
          }
          /* Green status */
          &success,
          &finished,
          &order_delivered,
          &confirmed_order {
            &:before {
              background-color: var(--success);
            }
          }
          /* Yellow status */
          &warning,
          &pending,
          &pre_picking,
          &finished_capture,
          &waiting_capture,
          &in_delivery,
          &waiting_creation_integration,
          &waiting_picking,
          &waiting_pick_up,
          &waiting_picking_up,
          &in_picking,
          &waiting_payment,
          &waiting_delivery,
          &delivery_accepted,
          &aberto,
          &waiting_invoice,
          &invoice_issued,
          &finished_picking,
          &order_placed,
          &payment_in_analysis {
            &:before {
              background-color: var(--warning);
            }
          }
          /* Red status */
          &danger,
          &canceled,
          &order_canceled {
            &:before {
              background-color: var(--danger);
            }
          }

          /* End .status__  */
        }
      }
    }
  }
`
export const ThContentCell = styled.div`
  ${({ theme }) => css`
    width: 100%;

    .sideBorder & {
      display: flex;
      justify-content: space-between;
    }

    .mainInfo {
      text-align: left;

      .text {
        font-weight: bold;
      }
    }

    aside {
      display: flex;
      gap: 0.125rem;
    }
  `}
`
