import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useQuery } from '@tanstack/react-query'

import Loading from '~/components/Loading'
import Table from '~/components/Table'
import { useAlert } from '~/hooks/useAlert'
import {
  listPlacementQueryKey,
  listPlacementsService
} from '~/modules/retailMedia/services/placements'
import actionPlacements from '~/modules/retailMedia/store/placements/actions'
import { useAppSelector } from '~/store/hooks'

import schema from './schema'

const PlacementsListTable: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { sortDirection, sortDirectionDefault, sortKey, sortKeyDefault } =
    useAppSelector(S => S.retailMedia.placements.list)

  const { status, data, error } = useQuery({
    queryKey: [listPlacementQueryKey],
    queryFn: async () => {
      const res = await listPlacementsService()

      return res.data.data
    }
  })

  const [AlertOnError] = useAlert(
    error && {
      response: {
        data: {
          message: [t('rm:placements.list.error')]
        }
      }
    }
  )

  /**
   * Sort table
   *
   *
   */

  const handleSortList = useCallback(
    (data: OnSortProps) => {
      dispatch(actionPlacements.setSorting(data))
    },
    [dispatch]
  )

  return (
    <>
      <Loading status={status === 'pending'}>
        {t('rm:placements.list.loading')}
      </Loading>

      <AlertOnError />

      {!!data && (
        <Table
          schema={schema()}
          data={data}
          activeSortDirection={sortDirection || sortDirectionDefault}
          activeSortKey={sortKey || sortKeyDefault}
          onSort={handleSortList}
          freeze="first-row-and-column"
          sideBorder
          smallerRowPadding
        />
      )}
    </>
  )
}

export default PlacementsListTable
