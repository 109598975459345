import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const ghostButtonTemplate = ({ theme, props }: ButtonTemplatesProps) => css`
  background: transparent;
  color: var(--dark);
  border: 1px solid var(--dark);
`

export default ghostButtonTemplate
