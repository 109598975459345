import axios from 'axios';

/**
 *
 * @endpoint /transaction/:id/verify-refusal
 * @exports getVerifyRefusal  Busca o motivo de recusa da transação
 * @param {string} id ID da transação que será verificada
 *
 */

export const getVerifyRefusal = id =>
  axios.get(`/api/payment-hub/transaction/${id}/verify-refusal`);
