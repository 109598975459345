import React from 'react'

import { DatepickerHeader } from '~/components/datepicker-header'
import HeaderPages from '~/components/HeaderPages'
import MetabaseDashboard from '~/components/MetabaseDashboard'

import { Container } from './styles'

const Attendance = () => {
  return (
    <Container>
      <HeaderPages
        title="Métricas de atendimento"
        sideRightComponent={<DatepickerHeader maxEndDate="D0" />}
        className="has-datepicker"
      />

      <MetabaseDashboard type="attendance" />
    </Container>
  )
}

export default Attendance
