import React from 'react'
import { injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import SelectSearchable from '~/components/SelectSearchable'
import { setFilteredTemplateStatus } from '~/store/modules/filters/actions'

const FilterTemplateStatus = ({ intl }) => {
  const dispatch = useDispatch()

  const { templateStatus: filteredTemplateType } = useSelector(
    state => state.filters
  )

  const types = ['in_review', 'approved', 'denied']

  const type_option = types.map((item, index) => ({
    label: intl.formatMessage({ id: item }),
    value: item,
    step: index,
    type: item
  }))

  const handleChangeStatus = selectedStatusType => {
    dispatch(
      setFilteredTemplateStatus({ filteredTemplateStatus: selectedStatusType })
    )
  }

  return (
    <SelectSearchable
      placeholder="Filtrar por status"
      options={type_option}
      onChange={handleChangeStatus}
      defaultValue={[filteredTemplateType]}
    />
  )
}

export default injectIntl(FilterTemplateStatus)

FilterTemplateStatus.propTypes = {
  intl: PropTypes.shape()
}

FilterTemplateStatus.defaultProps = {
  intl: null
}
