import { toastr } from 'react-redux-toastr'

import { jwtDecode } from 'jwt-decode'

import { JWTPayload, history, setLocalstorage } from '~/helpers'
import localstorageConstants from '~/helpers/localstorage-manipulation'
import { getUserAccounts } from '~/modules/users/store/actions'
import { signin } from '~/services/signin-service'
import store from '~/store'
import { getAccessModules } from '~/store/modules/accessModules/actions'
import { getAccountInfo } from '~/store/modules/account/actions'

import * as types from './constants'

/**
 * @param token
 * @param callback_url URL que devemos redirecionar após o login
 * @param origin  Chave que armazena origem do login
 *
 * Refatorar para usar spread
 */

export const setLoggedIn =
  (token, callback_url, origin = 'newtail') =>
  dispatch => {
    const decodeToken = jwtDecode<JWTPayload>(token)

    setLocalstorage(localstorageConstants.TOKEN, token)

    setLocalstorage(localstorageConstants.ORIGIN, origin)

    localStorage.removeItem(localstorageConstants.ACCOUNT_INFO)
    localStorage.removeItem(localstorageConstants.REDUX_PERSIST)

    dispatch(getAccessModules())

    dispatch(getAccountInfo(callback_url))

    dispatch(getUserAccounts())
  }

export const setExternalLoggedIn = props => dispatch => {
  const { token, callback_url, origin = 'newtail' } = props

  const decodeToken = jwtDecode<JWTPayload>(token)

  dispatch({
    type: `${types.LOGIN_REQUEST}_FULFILLED`,
    payload: decodeToken
  })

  setLocalstorage(localstorageConstants.TOKEN, token)

  setLocalstorage(localstorageConstants.ORIGIN, origin)

  localStorage.removeItem(localstorageConstants.ACCOUNT_INFO)
  localStorage.removeItem(localstorageConstants.REDUX_PERSIST)

  dispatch(getAccessModules())

  dispatch(getAccountInfo(callback_url))

  dispatch(getUserAccounts())
}

export const signIn = ({ email, password }, callback_url = null) => {
  const { dispatch } = store

  dispatch({
    type: types.LOGIN_REQUEST,
    payload: signin(email, password),
    onSuccess: resp => {
      const decodeToken = jwtDecode<JWTPayload>(resp.data.token)

      if (!decodeToken.current_account_id) {
        toastr.error('Erro', 'Usuário sem conta associada!')
        throw new Error()
      }

      const token = resp.data.token

      dispatch(setLoggedIn(token, callback_url))
    },
    hideError: true,
    getErrorFeedbackMsg: error => error.response.data.error.message
  })
}

export const signOut = () => dispatch => {
  const origin = localStorage.getItem(localstorageConstants.ORIGIN)
  const appInfo = JSON.parse(
    localStorage.getItem(localstorageConstants.APP_INFO)
  )

  localStorage.removeItem(localstorageConstants.TOKEN)
  localStorage.removeItem(localstorageConstants.ORIGIN)
  localStorage.removeItem(localstorageConstants.ACCOUNT_INFO)
  localStorage.removeItem(localstorageConstants.REDUX_PERSIST)

  // caches?.keys().then(names => {
  //   names.forEach(name => {
  //     caches.delete(name)
  //   })
  // })

  dispatch({ type: types.LOGIN_LOGGED_OUT })

  if (origin === 'marketplace' && appInfo?.callbackLogoutUrl) {
    history.push(appInfo?.callbackLogoutUrl)
  } else {
    history.push('/login')
  }
}

export const setAccountId = accountId => dispatch =>
  dispatch({ type: types.LOGIN_SET_ACCOUNT_ID, payload: accountId })
