import styled, { CSSModifiers, css } from 'styled-components'

const containerModifiers: CSSModifiers = {
  ASC: theme => css`
    svg {
      &:first-child {
        color: ${theme.colors.primary};
      }
    }
  `,
  DESC: theme => css`
    svg {
      &:last-child {
        color: ${theme.colors.primary};
      }
    }
  `
}

type ContainerProps = {
  direction: SortDirection
  isActive: boolean
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, isActive, direction = null }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 1rem;
    width: 1rem;
    padding: 0 0.5rem;

    svg {
      position: absolute;
      display: flex;
      color: ${theme.colors.neutral.low.lightest};
      transition: 0.1s ease-in-out all;

      &:first-child {
        top: 0;
      }

      &:last-child {
        bottom: 0;
      }
    }

    ${isActive && containerModifiers[direction](theme)}
  `}
`
