/* eslint-disable react/display-name */
import React from 'react';
import { FiPercent } from 'react-icons/fi';

import { Input } from '~/components/unform';
import InputMoney from '~/components/unform/Input/InputMoney';

import DeleteAction from './Columns/DeleteAction';
import SelectedProduct from './Columns/SelectedProduct';
import UpdatedPrice from './Columns/UpdatedPrice';

export const schema = formRef => ({
  product: {
    title: 'Produto',
    render: obj => <SelectedProduct />,
    className: 'product-column',
  },
  cust: {
    title: 'Custo',
    render: obj => <InputMoney name="#" placeholder="R$ 0,00" />,
    className: 'small-column',
  },
  current_margin: {
    title: 'Margem atual',
    render: obj => (
      <Input name="#" type="number" htmlRightIcon={<FiPercent />} />
    ),
    className: 'small-column',
  },
  current_price: {
    title: 'Preço atual',
    render: obj => <InputMoney name="#" placeholder="R$ 0,00" />,
    className: 'small-column',
  },
  goal_margin: {
    title: 'Meta margem (%)',
    render: obj => (
      <Input name="#" type="number" htmlRightIcon={<FiPercent />} />
    ),
    className: 'small-column',
  },
  updated_price: {
    title: 'Preço novo',
    render: obj => <UpdatedPrice formRef={formRef} data={obj} />,
    className: 'updated-price-input',
  },
  trade_price: {
    title: 'Negociação',
    render: obj => <InputMoney name="#" placeholder="R$ 0,00" />,
    className: 'small-column',
  },
  delete: {
    title: '',
    render: obj => <DeleteAction />,
    className: 'small-column',
  },
});
