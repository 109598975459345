import axios from 'axios'

/**
 * Get app info
 */

export const getAppInfoQueryKey = 'get-app-info'

// export const getAppInfoService = () =>
//   axios.get<GetAppInfoResponse>('http://localhost:4000/app-info')

export const getAppInfoService = () =>
  axios.get<GetAppInfoResponse>('/app-info')
