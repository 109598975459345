import styled, { css } from 'styled-components'

import { buttonDefaultStyles, buttonTemplates } from '../templates'

export const Container = styled.button<ContainerProps>`
  ${({ theme, template, ...rest }) => css`
    ${buttonDefaultStyles({ theme, props: { ...rest } })}
    ${buttonTemplates[template]({ theme, ...rest })}
  `}
`
