import React, { useEffect } from 'react'
import { FiChevronsRight, FiMessageSquare } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import SortChats from '~/modules/chat/pages/messenger/elements/SortChats'
import { getTags, closeSidePanels } from '~/modules/chat/store/actions'
import { useAppSelector } from '~/store/hooks'

import ChatLocationEdit from '../ChatLocationEdit'
import ChatMenu from '../ChatMenu'
import ChatsList from '../ChatsList'
import ChatTabs from '../ChatTabs'
import SidebarActions from '../SidebarActions'

import { Container } from './styles'

/**
 * Componente que renderiza o filtro por grupos, edição de chats, elemento de busca e a lista de conversas.
 * O componente é recolhido sempre que algum sliding panel for aberto.
 */
const Sidebar = () => {
  const { slidingPanelIsOpen, avaiableTags, loadingChats, selectedChat } =
    useAppSelector(({ chats }) => chats)

  const dispatch = useDispatch()

  const handlePanels = () => {
    dispatch(closeSidePanels())
  }

  useEffect(() => {
    if (!avaiableTags) {
      dispatch(getTags())
    }
  }, [avaiableTags, dispatch])

  return (
    <Container isOpen={!slidingPanelIsOpen} hasChatSelected={!!selectedChat}>
      <div className="opened">
        <div className="loading">
          <Loading onlyLine status={loadingChats} />
        </div>

        <ChatTabs />

        <SortChats />

        <ChatLocationEdit />

        <SidebarActions />

        <ChatMenu />

        <ChatsList />
      </div>

      <div className="closed">
        <Button
          template="icon"
          iconLeft={<FiMessageSquare />}
          onClick={handlePanels}
        />
        <Button
          template="icon"
          iconLeft={<FiChevronsRight />}
          onClick={handlePanels}
        />
      </div>
    </Container>
  )
}

export default Sidebar
