import { formatMoney, formatNumberWithDots, formatPercent } from '~/helpers'

import { ReportCampaigns } from './types'

export const formatIndustryReportCampaign = (
  data: GetCampaignsReportResponse
) =>
  ({
    _raw: {
      originPath: 'src/modules/retailMedia/dtos/industry/campaign/report.ts'
    },
    ...data,
    formatted: {
      actives_advertisers: isNaN(data.total_advertisers)
        ? '-'
        : formatNumberWithDots(data.total_advertisers),
      actives_campaigns: isNaN(data.total_campaigns)
        ? '-'
        : formatNumberWithDots(data.total_campaigns),
      impressions: formatNumberWithDots(data?.impressions || 0),
      clicks: formatNumberWithDots(data?.clicks || 0),
      conversions: formatNumberWithDots(data?.conversions || 0),
      conversion_rate: formatPercent(data?.conversion_rate || 0),
      ctr: formatPercent(data?.ctr || 0),
      roas: formatNumberWithDots(Number(data?.roas || 0)),
      income: formatMoney(Number(data?.income) || 0),
      total_spent: formatMoney(Number(data?.total_spent) || 0),
      balance: formatMoney(Number(data?.balance) || 0)
    }
  } as ReportCampaigns)
