import React, { useMemo } from 'react'
import { FiTruck } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import moment from 'moment'

import { AddressInline } from '~/components/Address'
import { Icon } from '~/components/Icon'
import OrderRequestDelivery from '~/components/Order/OrderRequestDelivery'
import NewtailDeliveryCard from '~/components/Order/OrderRequestDelivery/NewtailDelivery'
import { WrapperShadow, Header, Content } from '~/components/WrapperShadow'
import { formatDateExibitionUTC } from '~/helpers'

import RecipientFullName from './_elements/RecipientFullName'
import { formatDeliveryData } from './formatDeliveryData'

const PanelShipment = () => {
  const { orderData } = useSelector(state => state.order)

  const shipments = useMemo(() => orderData.shipments?.[0] || [], [orderData])

  const isNow = useMemo(() => shipments.use_delivery_time, [shipments])

  const isToday = useMemo(
    () => moment().isSame(orderData.schedule_date, 'day'),
    [orderData]
  )

  const titleShipmentSection = useMemo(
    () =>
      shipments?.type === 'pick_up_in_store'
        ? 'Dados da retirada'
        : 'Dados de entrega',
    [shipments]
  )

  const deliveryData = useMemo(() => {
    return formatDeliveryData(shipments)
  }, [shipments])

  const allowRequestExternalDelivery = useMemo(() => {
    const hasProvider = orderData?.config?.has_shipment_integration

    const allowedOrderStatus = [
      'waiting_delivery',
      'in_delivery',
      'delivery_accepted',
      'order_delivered'
    ]
    const checkOrderStatus = allowedOrderStatus.includes(
      orderData.status?.[0]?.type
    )

    return hasProvider && checkOrderStatus
  }, [orderData])

  return (
    <WrapperShadow>
      <Header>
        <div>
          <span>
            <Icon icon={FiTruck} /> <strong>{titleShipmentSection}</strong>
          </span>
        </div>
      </Header>

      <Content>
        {shipments?.type === 'pick_up_in_store' && (
          <>
            <RecipientFullName />
            <br />
            <strong>Loja:</strong> {orderData?.location[0]?.name}
            <br />
            <strong>Data agendada:</strong>{' '}
            {formatDateExibitionUTC(shipments.schedule_date)}
            <br />
            <strong>{isToday && isNow ? 'Entrega até:' : 'Turno:'}</strong>{' '}
            {isToday && isNow ? shipments.schedule_time : shipments.slot}
          </>
        )}

        {shipments?.type === 'shipping_from_store' && (
          <>
            <RecipientFullName />
            <br />
            <strong>Data agendada:</strong>{' '}
            {formatDateExibitionUTC(shipments.schedule_date)}
            <br />
            <strong>{isToday && isNow ? 'Entrega até:' : 'Turno:'}</strong>{' '}
            {isToday && isNow ? shipments.schedule_time : shipments.slot}
            <br />
            <strong>Endereço de entrega:</strong>
            <br />
            {orderData.shipping_address && orderData.shipping_address[0] && (
              <>
                <AddressInline
                  shippingAddress={orderData.shipping_address[0]}
                />
              </>
            )}
          </>
        )}

        {shipments.carrier_id && (
          <NewtailDeliveryCard deliveryData={deliveryData} />
        )}

        {allowRequestExternalDelivery && (
          <OrderRequestDelivery
            deliveryData={deliveryData}
            orderId={orderData.id}
            orderExternalId={orderData.order_id}
          />
        )}
      </Content>
    </WrapperShadow>
  )
}

export default PanelShipment
