import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

const containerModifiers = {
  isOpen: theme => css`
    /* position: absolute; */
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    --opacityBg: ${transparentize(0.2, theme.primary)};
    background: var(--opacityBg);
    left: 0;
  `
}

export const Container = styled.div`
  ${({ theme, isOpen }) => css`
    position: absolute;
    top: 0;
    left: 40px;
    z-index: 3;

    ${isOpen && containerModifiers.isOpen(theme)}
  `}
`

export const FormWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 1rem 1.5rem;
    background: #fff;
    border-radius: 1rem;
    overflow: hidden;
    width: 800px;
    max-width: 90%;
  `}
`

export const AddButton = styled.div`
  z-index: 9998;
  margin: 10px;

  button {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
    background: white;

    &:disabled {
      background: #ccc;
    }

    + button {
      margin-left: 0.5rem;
    }
  }
`
