import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

import Tooltip from '../_elements/Tooltip'

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    &.success {
      color: ${theme.colors.success};
    }
    &.error {
      color: ${theme.colors.danger};
    }
  `}
`

const containerAttrs = () => ({
  className: 'inputContainer'
})

type ContainerProps = {
  minHeightTextarea?: string | number
  isFilled?: boolean
  isFocused?: boolean
  isErrored?: boolean
  isDisabled?: boolean
  showWarning?: boolean
}

export const Container = styled.div.attrs(containerAttrs)<ContainerProps>`
  ${({
    theme,
    minHeightTextarea,
    isFilled = false,
    isFocused = false,
    isErrored = false,
    isDisabled = false,
    showWarning
  }) => css`
    --minWidth: 100px;

    @media ${device.laptop} {
      --minWidth: 2rem;
    }

    position: relative;

    display: flex;
    align-items: center;

    width: 100%;
    min-width: var(--minWidth);
    padding: 0.25rem 0.5rem;
    border-radius: ${theme.border_radius};

    border: 1px solid var(--tertiary);
    background: var(--white);

    transition: 0.3s border-color ease-in-out;

    & + div {
      margin-top: 0.5rem;
    }

    label {
      position: absolute;
      top: 0;
      line-height: 1;
      transform: translateY(-50%);
      font-size: 8px;
      text-transform: uppercase;
      background: white;
      padding: 0.125rem 0.25rem;
      font-weight: bold;
      letter-spacing: 0.5px;
      border-radius: 0.25rem;

      transition: 0.3s all ease-in-out;
    }

    input {
      background: transparent;
      color: var(--dark);
      flex: 1;
      border: 0;
      max-width: 100%;
      min-width: var(--minWidth);

      &::placeholder {
        color: var(--gray) !important;
        font-size: 0.75rem;
      }

      &:focus {
        outline: none;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px var(--white) inset;
      }
    }

    textarea {
      background: transparent;
      color: var(--dark);
      flex: 1;
      border: 0;
      max-width: 100%;
      min-width: 2rem;
      min-height: ${minHeightTextarea || '10rem'};
      resize: none;

      &::placeholder {
        color: var(--gray) !important;
      }

      &:focus {
        outline: none;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px var(--white) inset;
      }
    }

    select {
      background: transparent;
      color: var(--dark);
      flex: 1;
      border: 0;
      max-width: 100%;

      &:focus {
        outline: none;
      }
    }

    > svg {
      margin-right: 0.25rem;
      color: var(--primary);
      transition: 0.2s all;
      font-size: 1.125rem;
    }

    > .htmlIcon {
      display: inline-flex;
    }

    ${isFilled &&
    css`
      border-color: var(--success);

      > svg {
        color: var(--success);
      }
    `}

    ${showWarning &&
    css`
      border-color: var(--warning);

      > svg {
        color: var(--warning);
      }
    `}

    ${isErrored &&
    css`
      border-color: var(--danger);
    `}

    ${isFocused &&
    css`
      border-color: var(--hover);

      > svg {
        color: var(--hover);
      }
    `}


    ${isDisabled &&
    css`
      background: var(--inative);
    `}
  `}
`

export const Error = styled(Tooltip)`
  line-height: 1rem;
  height: 1rem;
  margin-left: 1rem;

  --mainColor: var(--danger);
  --mainColorText: var(--white);
`
export const MessageError = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.danger};
    font-size: 0.75rem;
  `}
`
