import { EditCpc } from './EditCpc'
import { EditCpm } from './EditCpm'

export const EditBudget = ({
  data: dataShemaTable,
  editType,
  campaignData
}: EditBudgetProxyProps) => {
  const allComponents = {
    cpc: EditCpc,
    cpm: EditCpm
  }

  const Component = allComponents[editType]

  return <Component data={dataShemaTable} campaignData={campaignData} />
}
