import React, { useCallback, useMemo, useState } from 'react'
import { FiEdit, FiX } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

import { Button } from '~/components/Buttons'
import SelectSearchable from '~/components/SelectSearchable'
import { getRole } from '~/helpers'

import { statusList, statusListOptionsSelect } from '../../_status/order'
import { updateOrderStatus } from '../../actions'

export const SelectStatus = () => {
  const [visibility, setVisibility] = useState(false)

  const isAdmin = useMemo(() => getRole() === 'admin', [])

  const { updateLoading, getOrderLoading, orderData } = useSelector(
    ({ order }) => order
  )

  const status = useMemo(() => orderData.status?.[0], [orderData.status])
  const isInvoiceIssued = useMemo(
    () => orderData.status?.[0]?.type === statusList.INVOICE_ISSUED.type,
    [orderData.status]
  )

  const shouldDisableOption = useCallback(
    status =>
      Boolean(
        isInvoiceIssued &&
          status.step < statusList.INVOICE_ISSUED.step &&
          !isAdmin
      ),
    [isAdmin, isInvoiceIssued]
  )

  const editableStatusOptions = statusListOptionsSelect.filter(
    item =>
      item.editable &&
      item.type !== 'order_canceled' &&
      !shouldDisableOption(item)
  )

  const defaultValue = useMemo(
    () => (status ? [{ label: status.label, value: status.type }] : null),
    [status]
  )

  const handleVisibility = () => setVisibility(!visibility)

  const dispatch = useDispatch()

  const handleChangeStatus = useCallback(
    async selectedStatus => {
      const { label, value } = selectedStatus

      const newStatus = {
        type: value,
        label,
        code: label
      }

      dispatch(updateOrderStatus({ id: orderData.id, newStatus }))

      setVisibility(false)
    },
    [orderData, dispatch]
  )

  return (
    <>
      {updateLoading || getOrderLoading ? (
        <Button
          iconLeft={<Spinner type="grow" size="sm" color="secondary" />}
          text="Salvando..."
          template="light"
          disabled
        />
      ) : (
        <>
          {!visibility ? (
            <Button
              iconRight={<FiEdit />}
              text={status?.label || 'Alterar status'}
              template="info"
              onClick={handleVisibility}
              className="edit"
            />
          ) : (
            <div className="selectSearchableWrapper">
              <Button
                iconRight={<FiX />}
                template="transparent"
                onClick={handleVisibility}
              />

              <SelectSearchable
                options={editableStatusOptions}
                placeholder="Status do pedido"
                onChange={handleChangeStatus}
                defaultValue={defaultValue}
                isClearable={false}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}
