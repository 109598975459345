import { t } from 'i18next'

import { PUBLISHERS_LIST_QUERY } from '~/modules/retailMedia/store/industry/publisher/constants'

export const queryFilterListPublishers = {
  query: true,
  querySize: '1x',
  queryPlaceholder: t('rm:filter.publisherName'),
  queryKeyState: PUBLISHERS_LIST_QUERY,
  queryWithDebounce: true,
  liveQuery: true
}
