import React from 'react';

import { FormGroup, InputNumber, Label } from '~/components/unform';

import * as S from './styles';

const BenefitsDiscount = () => {
  const limitDiscount = inputObj => {
    const { value } = inputObj;
    if (value > 0 && value <= 100) return inputObj;
  };

  return (
    <S.PromotionRulesGroup>
      <FormGroup>
        <Label htmlFor="type" isRequired text="Leva" />

        <InputNumber
          name="trigger.quantity"
          placeholder="Digite a quantidade mínima"
          type="tel"
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="benefits.discount" isRequired text="Ganha" />

        <InputNumber
          suffix="%"
          name="benefits.discount"
          placeholder="Digite o desconto"
          required
          isAllowed={limitDiscount}
        />
      </FormGroup>
    </S.PromotionRulesGroup>
  );
};

export default BenefitsDiscount;
