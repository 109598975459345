import { types } from '../constants'

const index = {
  pending: `${types.getAdvertiser}_PENDING`,
  fulfilled: `${types.getAdvertiser}_FULFILLED`,
  rejected: `${types.getAdvertiser}_REJECTED`
}

const reducerKey = 'advertiser'

export const info = {
  [index.pending]: ({ draft }) => {
    draft.grocery[reducerKey].getAdvertiser.loading = true
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.grocery[reducerKey].getAdvertiser.data = action.payload

    draft.grocery[reducerKey].getAdvertiser.loading = false
    draft.grocery[reducerKey].getAdvertiser.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.grocery[reducerKey].getAdvertiser.loading = false
    draft.grocery[reducerKey].getAdvertiser.error = action.payload
  }
}
