import styled, { DefaultTheme, css } from 'styled-components'

const containerTemplateModifiers = {
  successFill: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.success};
    color: white;
  `,
  warningFill: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.warning};
    color: white;
  `
}

export const Container = styled.div<IconCircleContainerProps>`
  ${({ theme, template }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.125rem;
    height: 1.125rem;
    padding: 0.125rem;
    aspect-ratio: 1/1;

    border-radius: 50%;

    ${containerTemplateModifiers[template](theme)}
  `}
`
