import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;

    .radioFieldWrapper {
      height: 50px;
      > label {
        padding: 0.8rem 0.875rem 0.8rem 3rem;
      }
    }

    > form {
      width: 100%;

      > * + * {
        margin-top: 0.2rem;
      }

      > div {
        margin-bottom: ${theme.spacings.xxxsmall};
      }
    }
  `}
`

export const WrapperAlert = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
    > div {
      padding: ${theme.spacings.xxxsmall};

      h5 {
        margin-bottom: 0.2rem;
      }
    }
  `}
`
