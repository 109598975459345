import { SortedCheckboxOption } from '../../SortedCheckbox'

export const callbackButtons = [
  {
    order: 0,
    id: 'purchase',
    label: 'Quero comprar',
    description:
      'Ao clicar nesse botão, o cliente inicia o fluxo de compra com o bot dentro do chat.'
  },
  {
    order: 1,
    id: 'support',
    label: 'Quero atendimento',
    description:
      'Ao clicar nesse botão, o cliente solicita um atendente e o bot é desativado.'
  },
  {
    order: 2,
    id: 'promotion',
    label: 'Quero ver ofertas',
    description:
      'Ao clicar nesse botão, o cliente recebe o encarte da loja mais próxima.'
  }
] as SortedCheckboxOption[]
