import React, { useMemo } from 'react';

import { formatDateToString } from '~/helpers';

import { AuthorizationTypes } from './types';

const PaymentDate = ({ payment }) => {
  const date = useMemo(() => {
    const date = payment.transactioned_at || payment.createdAt;

    return date ? `${formatDateToString(date)} • ` : '';
  }, [payment.createdAt, payment.transactioned_at]);

  return <>{date}</>;
};

export default PaymentDate;

PaymentDate.propTypes = AuthorizationTypes;
