/* eslint-disable react/display-name */
import React from 'react'

import { Badge } from '~/components/UI/Badge'
import { formatDateToString } from '~/helpers'

import ColumnAction from './ColumnAction'
import ColumnDelete from './ColumnDelete'
import ColumnStatus from './ColumnStatus'

export const schema = {
  date: {
    title: 'Gerado em',
    className: 'order__details col__infos',
    render: obj => (
      <>
        {!obj?.createdAt && 'Sem data'}
        {!!obj?.createdAt && (
          <div>
            <div className="item__id">
              {formatDateToString(obj.createdAt, 'HH:mm')}
            </div>
            <div className="item__name">
              {formatDateToString(obj.createdAt, 'DD/MM/YY')}
            </div>
          </div>
        )}
      </>
    )
  },
  customer: {
    title: 'Cliente',
    className: 'order__details col__infos',
    render: obj => (
      <div>
        <div className="item__id">
          {obj.customer?.name || 'Sem nome cadastrado'}
        </div>
        {obj?.is_recurrent && (
          <Badge template="successSolid">
            <small>Recorrência</small>
          </Badge>
        )}
      </div>
    )
  },
  location: {
    render: obj => obj.location.name || 'Sem loja definida',
    title: 'Local',
    className: 'order__details col__name'
  },
  status: {
    render: obj => <ColumnStatus data={obj} />,
    title: 'Status',
    className: `col__status`
  },
  action: {
    render: obj => <ColumnAction data={obj} />,
    title: 'Ação',
    className: `col__actions`
  },
  delete: {
    className: `col__actions`,
    render: obj => <ColumnDelete data={obj} />
  }
}
