import styled, { css } from 'styled-components'

/**
 * Time
 */
const containerModifiers = {
  editMode: () => css`
    opacity: 0.5;
  `,
  parentSelected: () => css`
    opacity: 1;
  `,
  columns: theme => css`
    &,
    > .containerGroup {
      flex-direction: column;
      margin: 0;

      & + .containerGroup {
        margin-top: ${theme.spacings.xxxsmall};
      }
    }
  `
}

export const Container = styled.div`
  ${({ theme, editMode, parentSelected, template }) => css`
    display: flex;

    margin: 0;
    padding: 0.5rem;
    flex: 1 1 100%;

    transition: ${theme.transition.default} all;

    > .containerGroup {
      display: flex;
      flex-wrap: wrap;
      margin: ${theme.spacings.tiny};

      > * {
        margin: 0.25rem;
      }
    }

    ${editMode && containerModifiers.editMode()}
    ${parentSelected && containerModifiers.parentSelected()}
    ${template === 'columns' && containerModifiers.columns(theme)}
  `}
`

export const Empty = styled.div`
  ${({ theme }) => css`
    flex: 1;

    display: flex;
    align-items: center;

    border-radius: 0.25rem;

    background-color: ${theme.light};
    color: ${theme.colors.gray};
    text-align: center;
    line-height: 1.125;
  `}
`
