import * as types from '../constants';

const index = {
  pending: `${types.DELETE}_PENDING`,
  fulfilled: `${types.DELETE}_FULFILLED`,
  rejected: `${types.DELETE}_REJECTED`,
};

export const cases = {
  [index.pending]: ({ draft }) => {
    draft.loadingDelete = true;
    draft.errorDelete = null;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.itemUpdated = action.payload;
    draft.loadingDelete = false;
    draft.errorDelete = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.loadingDelete = false;
    draft.errorDelete = action.payload;
  },
};
