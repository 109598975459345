import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  header {
    text-align: center !important;
    line-height: 1.25;
    * {
      color: var(--dark);
    }
  }

  .children {
    .selectWrapper,
    .searchWrapper {
      width: 330px;
      max-width: 100%;
    }
  }
`

export const Image = styled.div`
  margin: 2rem;
`
