// LOCATIONs

export const LOCATION_LIST = 'LOCATION_LIST'
export const LOCATION_LIST_MINIFIED = 'LOCATION_LIST_MINIFIED'
export const LOCATION_SUBMIT_FORM = 'LOCATION_SUBMIT_FORM'
export const LOCATION_GET_INFO = 'LOCATION_GET_INFO'
export const LOCATION_UPDATE_INFO = 'LOCATION_UPDATE_INFO'
export const LOCATION_DELETE = 'LOCATION_DELETE'
export const LOCATIONS_PER_PAGE_CHANGED = 'LOCATIONS_PER_PAGE_CHANGED'

export const LOCATION_DELIVERY_TYPES_LIST = 'LOCATION_DELIVERY_TYPES_LIST'

export const LOCATION_MAP_COORDINATES = 'LOCATION_MAP_COORDINATES'
export const LOCATION_MAP_COORDINATES_RESET = 'LOCATION_MAP_COORDINATES_RESET'

export const LOCATION_USERS_LIST = 'LOCATION_USERS_LIST'
export const LOCATION_USERS_CREATE_OPTIMISTIC =
  'LOCATION_USERS_CREATE_OPTIMISTIC'
export const LOCATION_USERS_CREATE = 'LOCATION_USERS_CREATE'
export const LOCATION_USERS_DELETE = 'LOCATION_USERS_DELETE'
export const LOCATION_USERS_DELETE_OPTIMISTIC =
  'LOCATION_USERS_DELETE_OPTIMISTIC'

/**
 * Multi região
 */
export const LOCATION_SHOW_TO_EDIT = 'LOCATION_SHOW_TO_EDIT'
export const LOCATION_REGIONS_LIST_ALL = 'LOCATION_REGIONS_LIST_ALL'
export const LOCATION_REGIONS_LIST = 'LOCATION_REGIONS_LIST'
export const LOCATION_REGIONS_CREATE = 'LOCATION_REGIONS_CREATE'
export const LOCATION_REGIONS_UPDATE = 'LOCATION_REGIONS_UPDATE'
export const LOCATION_REGIONS_DELETE = 'LOCATION_REGIONS_DELETE'

export const LOCATION_REDUX_QUERY = 'queryLocation'
