import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  AiOutlineAndroid,
  AiOutlineWindows,
  AiOutlineApple
} from 'react-icons/ai'
import { FiAlertCircle, FiWifi, FiZap } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import placeholderQrCode from '~/assets/img/elements/placeholderQrCode.png'
import Alert from '~/components/Alert'
import { Icon } from '~/components/Icon'
import { getQrCodeWppWeb } from '~/modules/chat/services'
import { setStatusConnectionWhatsApp } from '~/modules/chat/store/chatConnect/actions'

import * as S from './styles'

const Unauthenticated = () => {
  const [qrCodeURL, setQrCodeURL] = useState(null)
  const [isConnectedNow, setIsConnectedNow] = useState(false)
  const dispatch = useDispatch()

  const { isConnected } = useSelector(({ chatConnect }) => chatConnect)

  const qrCodeTimer = useRef(null)

  const handleQrCode = useCallback(async () => {
    const response = await getQrCodeWppWeb()

    if (response.data.status === 'CONNECTED') {
      clearInterval(qrCodeTimer.current)
      dispatch(setStatusConnectionWhatsApp())
      setIsConnectedNow(true)
    }

    setQrCodeURL(response.data.qrcode)
  }, [dispatch])

  useEffect(() => {
    if (!qrCodeURL) {
      handleQrCode()
    }
  }, [handleQrCode, qrCodeURL])

  useEffect(() => {
    if (!isConnected) {
      qrCodeTimer.current = setInterval(async () => {
        handleQrCode()
      }, 5000)
    }

    if (isConnected) {
      clearInterval(qrCodeTimer.current)
    }
  }, [handleQrCode, isConnected])

  return (
    <S.Container>
      <div className="wrapper">
        {isConnectedNow && (
          <Alert>
            <S.Wrapper>
              <FiAlertCircle /> WhatsApp connectado. Se as mensagens não
              carregarem, atualize a página.
            </S.Wrapper>
          </Alert>
        )}

        <div className="firstRow">
          <div className="qrCode">
            <img
              src={qrCodeURL || placeholderQrCode}
              width="264"
              height="264"
              style={{ objectFit: 'cover' }}
              alt="Entre no WhatsApp e leia esse Qrcode."
            />
          </div>
          <div className="infos">
            <header>
              <h2>Leia o QR Code do WhatsApp para começar</h2>
              <p>
                Utilize o WhatsApp em seu telefone para ler o código, assim como
                faz quando se conecta no WhatsApp Web
              </p>
            </header>

            <section>
              <strong>Importante</strong>
              <S.TextIcon>
                <Icon icon={FiZap} />
                <div className="text">
                  Tenha seu celular sempre ligado e carregando para não
                  interferir na troca de mensagens com seus clientes.
                </div>
              </S.TextIcon>
              <S.TextIcon>
                <Icon icon={FiWifi} />
                <div className="text">
                  Mantenha sempre seu celular conectado na internet para que a
                  não tenha problemas de conexão com seus clientes
                </div>
              </S.TextIcon>
            </section>
          </div>
        </div>

        <div className="secondRow">
          <h2>Como ler o QR Code via WhatsApp?</h2>
          <S.TextIcon>
            <Icon icon={AiOutlineAndroid} />
            <div className="text">
              <strong>Android</strong>
              Abra o WhatsApp - Botão Menu - WhatsApp Web
            </div>
          </S.TextIcon>
          <S.TextIcon>
            <Icon icon={AiOutlineApple} />
            <div className="text">
              <strong>iPhone</strong>
              Abra o WhatsApp - Configurações - WhatsApp Web/Computador
            </div>
          </S.TextIcon>
          <S.TextIcon>
            <Icon icon={AiOutlineWindows} />
            <div className="text">
              <strong>Windows</strong>
              Abra o WhatsApp - Menu - WhatsApp Web/Computador
            </div>
          </S.TextIcon>
        </div>
      </div>
    </S.Container>
  )
}

export default Unauthenticated
