import { FiHelpCircle } from 'react-icons/fi'

import Tooltip from '~/components/Tooltip'
import i18n from '~/i18n'

export const publisherCards: CardsSchema<
  ReportCampaigns,
  TypesPublisherCard
> = ({ data, loading }) => [
  {
    id: 'card-01',
    title: i18n.t('rm:metrics.publishedCampaigns'),
    value: data?.formatted?.actives_campaigns || '-',
    type: 'actives_campaigns',
    loading: loading,
    isRelativeInterval: false
  },
  {
    id: 'card-02',
    title: i18n.t('rm:metrics.impressions'),
    value: data?.formatted?.impressions || '-',
    type: 'prints',
    loading: loading
  },
  {
    id: 'card-03',
    title: i18n.t('rm:metrics.clicks'),
    value: data?.formatted?.clicks || '-',
    type: 'clicks',
    loading: loading
  },
  {
    id: 'card-04',
    title: i18n.t('rm:metrics.ctr'),
    value: data?.formatted?.ctr || '-',
    type: 'ctr',
    loading: loading
  },
  {
    id: 'card-05',
    title: i18n.t('rm:metrics.adsRevenue'),
    value: data?.formatted?.total_spent || '-',
    type: 'ads_income',
    loading: loading
  },
  {
    id: 'card-06',
    title: i18n.t('rm:metrics.roas'),
    value: data?.formatted?.roas || '-',
    type: 'roas',
    loading: loading
  },
  {
    id: 'card-07',
    title: i18n.t('rm:metrics.sales'),
    value: data?.formatted?.income || '-',
    type: 'income',
    loading: loading
  }
]

export const publisherListAllCards: CardsSchema<
  ReportCampaigns,
  TypesPublisherListAllCard
> = ({ data, loading }) => [
  {
    id: 'card-01',
    title: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{i18n.t('rm:metrics.advertisers')}</span>
        <Tooltip
          icon={<FiHelpCircle />}
          text={i18n.t('rm:metrics.advertisers.tooltip')}
        />
      </div>
    ),
    value: data?.formatted?.actives_advertisers || 0,
    type: 'actives_advertisers',
    loading: loading,
    isRelativeInterval: false
  },
  {
    id: 'card-02',
    title: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{i18n.t('rm:metrics.publishedCampaigns')}</span>
        <Tooltip
          icon={<FiHelpCircle />}
          text={i18n.t('rm:metrics.publishedCampaigns.tooltip')}
        />
      </div>
    ),
    value: data?.formatted?.actives_campaigns || 0,
    type: 'actives_campaigns',
    loading: loading,
    isRelativeInterval: false
  },
  {
    id: 'card-03',
    title: i18n.t('rm:metrics.impressions'),
    value: data?.formatted?.impressions || '-',
    type: 'prints',
    loading: loading,
    isRelativeInterval: true
  },
  {
    id: 'card-04',
    title: i18n.t('rm:metrics.clicks'),
    value: data?.formatted?.clicks || '-',
    type: 'clicks',
    loading: loading,
    isRelativeInterval: true
  },
  {
    id: 'card-ctr',
    title: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{i18n.t('rm:metrics.ctr')}</span>
        <Tooltip
          icon={<FiHelpCircle />}
          text={i18n.t('rm:metrics.ctr.tooltip')}
        />
      </div>
    ),
    value: data?.formatted?.ctr || 0,
    type: 'ctr',
    loading: loading
  },
  {
    id: 'card-05',
    title: i18n.t('rm:metrics.adsRevenue'),
    value: data?.formatted?.total_spent || 0,
    type: 'ads_income',
    loading: loading,
    isRelativeInterval: true
  },
  {
    id: 'card-conversions',
    title: i18n.t('rm:metrics.conversions'),
    value: data?.formatted?.conversions || 0,
    type: 'conversions',
    loading: loading
  },
  {
    id: 'card-conversion_rate',
    title: i18n.t('rm:metrics.conversion_rate'),
    value: data?.formatted?.conversion_rate || 0,
    type: 'conversion_rate',
    loading: loading
  },
  {
    id: 'card-07',
    title: i18n.t('rm:metrics.sales'),
    value: data?.formatted?.income || 0,
    type: 'income',
    loading: loading,
    isRelativeInterval: true
  },
  {
    id: 'card-roas',
    title: i18n.t('rm:metrics.roas'),
    value: data?.formatted?.roas || 0,
    type: 'roas',
    loading: loading
  }
]
