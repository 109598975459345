import React, { useState, useEffect, useMemo } from 'react'

import AlertDismissible from '~/components/AlertDismissible'
import { formatAxiosError } from '~/helpers/errors'

/**
 * Gerencia o componente de alerta. Recebe um objeto com o erro.
 *
 * @example
 *
 *  const [AlertOnError] = useAlert(error);
 *
 *  <AlertOnError />
 *
 * @returns Um componente de alerta com a mensagem de erro enviada
 *
 * @param {string} error Mensagem de erro
 *
 */

const useAlert = (errorRaw = null) => {
  const [alert, setAlert] = useState(true)

  const error = errorRaw ? formatAxiosError(errorRaw) : null

  // Gerenciando os alertas
  const handleAlert = useMemo(
    () => ({
      show: () => {
        if (error && !alert) {
          setAlert(true)
        }
      },
      close: () => {
        setAlert(false)
      }
    }),
    [error, alert]
  )

  useEffect(() => {
    handleAlert.show()

    if (!error && alert) {
      handleAlert.close()
    }
  }, [error, alert, handleAlert])

  const component = ({ type = 'danger' }) => {
    return (
      alert &&
      !!error && (
        <AlertDismissible
          type={type || 'danger'}
          isOpen={alert}
          onClose={handleAlert.close}
          className="login-form__alert"
          messageText={error || ''}
        />
      )
    )
  }

  return [component]
}

export { useAlert }
