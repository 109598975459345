import i18next from 'i18next'
import { produce } from 'immer'
import { AnyAction } from 'redux'

import * as types from './constants'

const INITIAL_STATE: UpdatePasswordState = {
  messageSuccess: '',
  messageError: '',
  loading: false,
  // Invite
  validToken: false,
  titleError: ''
}

export default (state = INITIAL_STATE, action: AnyAction) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.UPDATE_PASSWORD_REQUEST}_FULFILLED`:
        draft.loading = false
        draft.messageError = ''
        draft.messageSuccess = i18next.t(
          'auth:updatePassword.resetPasswordPath.successMessage'
        )
        break

      case `${types.UPDATE_PASSWORD_REQUEST}_REJECTED`:
        draft.loading = false
        draft.messageSuccess = ''
        draft.messageError = action.payload
        break

      case `${types.UPDATE_PASSWORD_REQUEST}_PENDING`: {
        draft.loading = true
        draft.messageError = ''
        draft.messageSuccess = ''

        break
      }

      // Invite
      case `${types.CONFIRM_INVITE_ACTIVATE}_FULFILLED`:
        draft.loading = false
        break
      case `${types.CONFIRM_INVITE_ACTIVATE}_REJECTED`:
        draft.messageError = action.payload
        draft.loading = false
        break
      case `${types.CONFIRM_INVITE_ACTIVATE}_PENDING`: {
        draft.loading = true
        break
      }
      case `${types.CONFIRM_INVITE_VALIDATE_TOKEN}_FULFILLED`:
        draft.loading = false
        break
      case `${types.CONFIRM_INVITE_VALIDATE_TOKEN}_REJECTED`:
        draft.loading = false
        break
      case `${types.CONFIRM_INVITE_VALIDATE_TOKEN}_PENDING`: {
        draft.loading = true
        break
      }
      case types.CONFIRM_INVITE_ERROR_MESSAGE: {
        draft.validToken = false
        draft.messageError = action.message
        draft.titleError = action.title
        break
      }
      case types.CONFIRM_INVITE_VALID_TOKEN: {
        draft.validToken = true
        break
      }

      default:
        return state
    }
  })
