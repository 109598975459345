import { ReactNode } from 'react'

import useModal from '~/hooks/useModal'

import * as S from './styles'

interface Props {
  children: ReactNode
  url: string
}

const TumbProduct = ({ children, url }: Props) => {
  const [Modal, showModal, toggle, setIsShowing] = useModal()

  const handleCloseModal = () => {
    setIsShowing(false)
  }

  return (
    <>
      <S.Image onClick={toggle} className="imageWrapper">
        {children}
      </S.Image>

      <Modal isOpen={!!showModal} onClose={handleCloseModal} maxWidth="auto">
        <S.ImageModal src={url} />
      </Modal>
    </>
  )
}

export default TumbProduct
