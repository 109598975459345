import styled, { css } from 'styled-components'

export const Container = styled.div`
  > form {
    padding: initial !important;
  }
`

export const WrapperBadge = styled.div`
  margin-bottom: 1rem;
`

export const TitleResponse = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-style: normal;
    font-weight: ${theme.font.bold};
    color: ${theme.colors.dark};
    margin-bottom: ${theme.spacings.xxxsmall};
  `}
`

export const WrapperButtonSubmit = styled.div`
  margin-top: 1rem;
  > button {
    width: 100%;
  }
`

export const WrapperRadioButton = styled.div`
  ${({ theme }) => css`
    /* max-height: 300px; */
    height: 300px;
    overflow: auto;

    /* width */
    ::-webkit-scrollbar {
      width: ${theme.spacings.xsmall};
      padding: ${theme.spacings.xtiny};
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.colors.inative};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.primary};
      border-radius: 0.5rem;
      transition: 0.3s ease-in-out all;

      /* Handle on hover */
      &:hover {
        background: ${theme.colors.hover};
      }
    }
  `}
`
export const WrapperFilters = styled.div`
  > div {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 1rem 0;

    > .filterWrapper > div > form {
      padding: initial;
      > div {
        border-radius: 4px;
      }
    }
  }
`

export const ContainerConfirmationStep = styled.div`
  min-width: 372px;
  .subtitleStep {
    margin-bottom: 1rem;
  }
`

export const WrapperButtonsOnSubmit = styled.div`
  display: flex;
  flex-direction: column;

  button:first-child {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`

export const WrapperBadgeStep = styled.div`
  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    padding: 8px;

    > div > svg {
      font-size: 1rem;
    }
  }
`
export const SubtitleText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.bold};
    font-style: normal;
    color: ${theme.colors.dark};
  `}
`

export const IconSwap = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    margin: 1rem 0;
    font-size: ${theme.font.sizes.xxlarge};
    color: ${theme.colors.primary};
  `}
`
