import { FILTERS_SEELOUT } from '~/store/modules/filtersSellOut/constants'

import { metabaseKeys } from './keysMetabase'

export default {
  metabaseType: metabaseKeys[FILTERS_SEELOUT.products],
  filteredBy: [
    FILTERS_SEELOUT.supermarkets,
    FILTERS_SEELOUT.brands,
    FILTERS_SEELOUT.categories,
    FILTERS_SEELOUT.subcategories
  ],
  placeholder: 'Filtrar por produtos',
  placeholderError: '🔴 Erro ao carregar produtos',
  labelMulti: 'produtos selecionados',
  labelAll: 'Todos os produtos',
  labelSearchAnything: 'Pesquise algum produto',
  searching: 'Buscando produtos...',
  bigData: true
}
