import styled, { css } from 'styled-components'

export const Container = styled.section`
  ${({ theme }) => css`
    padding: 0 0 2rem;
    width: 100%;

    header {
      line-height: 1.25;
      padding: 0 1rem;

      strong {
        font-weight: 700;
      }
    }

    section {
      padding: 0;

      > p {
        padding: 0.5rem 1rem;
      }

      > button {
        display: flex;
        align-items: center;
        flex: 1;
        margin: 0 auto 0.5rem;
        padding: 0.5rem 0;
        width: calc(100% - 2rem);

        border-radius: 0.25rem;
        padding: 0.5rem;
        background: ${theme.colors.light};
        /* border-bottom: 1px solid ${theme.colors.tertiary}; */

        outline: none;

        /* background: none; */
        color: ${theme.dark};

        > .icon {
          color: ${theme.colors.primary};
          width: 1rem;
          font-size: 1rem;
          margin-right: 0.75rem;
        }

        .id {
          flex: 1;
          text-align: left;
          line-height: 1;

          strong {
            color: ${theme.colors.primary};
          }
        }

        .action {
          text-align: right;
          line-height: 1;

          .text {
            font-size: 0.75rem;
          }
          .icon {
            svg {
              width: auto;
            }
          }
        }
      }
    }
  `}
`
