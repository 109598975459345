import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Search as IconSearch } from 'react-feather';
import { FormGroup } from 'reactstrap';

import Button from '../Button';
import Form from '../forms/form';
import Input from '../forms/inputs';
import { requiredValidation } from '../../helpers';

const defaultProps = {
  isLoading: false,
  onSubmit: () => {},
  className: '',
  placeholder: 'Digite o texto para busca aqui',
  showButton: true,
};

const Search = ({
  onSubmit,
  className,
  isLoading,
  placeholder,
  showButton,
}) => {
  const [formIsValid, setFormIsValid] = useState(false);

  return (
    <Form
      onSubmit={onSubmit}
      checkIsValid={isValid => setFormIsValid(isValid)}
      className={`search-component ${className}`}
    >
      <FormGroup>
        <Input
          name="search"
          placeholder={placeholder}
          iconLeft={<IconSearch />}
          validate={[requiredValidation]}
        />
      </FormGroup>

      {showButton && (
        <Button
          type="submit"
          color="primary"
          text="Buscar"
          size="lg"
          width="auto"
          loading={isLoading}
          spinner={{ type: 'grow', size: 'sm', color: 'secondary' }}
          disabled={!formIsValid}
        />
      )}
    </Form>
  );
};

Search.defaultProps = defaultProps;
Search.propTypes = {
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  showButton: PropTypes.bool,
};

export default Search;
