import styled, { css } from 'styled-components';

const MenuModifier = {
  open: () => css`
    padding: 0.5rem;
    max-width: 15rem;
    max-height: 20rem;
    opacity: 1;
  `,
  close: () => css`
    max-width: 0;
    max-height: 0;
    opacity: 0;
  `,
  up: () => css`
    transform: translateY(-100%);
  `,
  down: () => css`
    transform: translateY(1.5rem);
  `,
};

export const Menu = styled.nav`
  ${({ theme, isOpen, isUp, positionParent }) => css`
    position: fixed;
    right: 2rem;
    top: ${`${positionParent?.top}px`};
    z-index: 100;

    transition: 0.1s ease-in-out opacity;

    overflow: hidden;

    display: inline-flex;
    flex-direction: column;

    background-color: ${theme.colors.primary};
    box-shadow: 0 1rem 2rem -1rem rgba(0, 0, 0, 0.1);

    ${isUp && MenuModifier.up()}
    ${!isUp && MenuModifier.down()}

    ${isOpen && MenuModifier.open()}
    ${!isOpen && MenuModifier.close()}

    &:focus {
      outline: none;
    }

    button {
      white-space: nowrap;
      justify-content: flex-start;

      height: 1.5rem;
      margin: 0.25rem 0;

      font-size: ${theme.font.sizes.small};
    }
  `}
`;
