import React, { useMemo } from 'react';

import { formatMoney, paymentMethods } from '~/helpers';

import { AuthorizationTypes } from './types';

const PaymentValue = ({ payment }) => {
  const showPaymentValue = useMemo(() => payment?.value > 0, [payment]);

  return (
    !!showPaymentValue && (
      <>
        {' '}
        <strong>Valor da transação:</strong>{' '}
        {paymentMethods(formatMoney(payment.value))} •{' '}
      </>
    )
  );
};

export default PaymentValue;

PaymentValue.propTypes = AuthorizationTypes;
