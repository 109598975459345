import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme, isOpen }) => css`
    .wrap {
      width: var(--aside__width);
      min-width: var(--aside__width);
      max-height: 100%;

      overflow-x: hidden;
      overflow-y: auto;

      .customer-tags {
        display: flex;
        flex-wrap: wrap;

        margin-top: ${theme.spacings.tiny};
        /* padding: ${theme.spacings.medium}; */
      }

      .customer-tags-container {
        padding: ${theme.spacings.medium};
      }

      /* width */
      ::-webkit-scrollbar {
        width: 0.5rem;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: ${theme.colors.inative};
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${theme.colors.tertiary};
        transition: 0.3s ease-in-out all;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: ${theme.colors.gray};
      }
    }
  `}
`
