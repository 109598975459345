import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'
import { Scope } from '@unform/core'

import AlertInfo from '~/components/AlertInfo'
import Loading from '~/components/Loading'
import { Badge } from '~/components/UI/Badge'
import { FormGroup } from '~/components/unform'
import ChechboxList from '~/components/unform/CheckboxList/Checkbox'
import { getAdSizesService } from '~/modules/retailMedia/services/ad-size'

import { placementInitialDataDTO } from '../../edit/dtos/initialData'
import FormatQuantityAds from '../FormQuantity'

type FormatBannerProps = {
  initialData?: Placement
}

const FormatBanner = ({ initialData }: FormatBannerProps) => {
  const { t } = useTranslation()

  const { status, data } = useQuery({
    queryKey: ['get-banner-size'],
    queryFn: async () => {
      const res = await getAdSizesService()

      return res.data.data
    }
  })

  // Selected Size
  const selectedSizes: { [key: string]: boolean } | null = useMemo(() => {
    if (!initialData) {
      return null
    }

    const formatInitialdata = placementInitialDataDTO(initialData)

    return formatInitialdata?.formats?.banner?.sizesActive
  }, [initialData])

  const sizes = useMemo(() => {
    if (!data) return null

    const allSizes = data

    if (!selectedSizes) return allSizes

    const filterSizes = allSizes.reduce(
      (acc, item) => (selectedSizes?.[item.name] ? [...acc] : [...acc, item]),

      [] as AdSize[]
    )

    return filterSizes
  }, [data, selectedSizes])

  return (
    <FormGroup className="span-full">
      <Scope path="banner">
        <header>
          <strong>{t('rm:placements.form.supportedSizes.title')}</strong>
          <p>{t('rm:placements.form.supportedSizes.description')}</p>
        </header>

        <Loading status={status === 'pending'}>
          {t('rm:placements.form.supportedSizes.loading')}
        </Loading>

        {!!initialData && !!sizes && !sizes.length && (
          <AlertInfo template="success">
            {t('rm:placements.form.supportedSizes.allSelected')}
          </AlertInfo>
        )}

        {!!sizes && (
          <ChechboxList
            name="sizesActive"
            options={sizes.map(item => ({
              label: (
                <span>
                  {item.name} <Badge>ID: {item.label}</Badge>{' '}
                  <code>
                    <small>{t('common:words.dimensions')}:</small> {item.width}{' '}
                    px x {item.height} px
                  </code>
                </span>
              ),
              name: item.name,
              activeChildren: (
                <FormatQuantityAds name={`sizes.${item.name}.quantity`} />
              )
            }))}
          />
        )}
      </Scope>
    </FormGroup>
  )
}

export default FormatBanner
