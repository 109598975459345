// Função para simular a requisição GET

import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

export const getTermsOfServiceServiceKey = 'getTermsOfService'

export const getTermsOfServiceService = () =>
  axios.get<GetTermsOfServiceResponse>(
    `${baseAPI}/configuration/terms-of-service`
  )

export const patchTermsOfServiceService = (body: PatchTermsOfServiceRequest) =>
  axios.patch(`${baseAPI}/configuration/terms-of-service`, body)

export const checkTermsOfServiceKey = 'checkTermsOfService'
export const checkTermsOfServiceService = () =>
  axios.get<CheckTermsOfServiceResponse>(`${baseAPI}/terms-of-service/check`)

export const pendingTermsOfServiceKeyKey = 'pendingTermsOfServiceKey'
export const pendingTermsOfServiceKeyService = () =>
  axios.get<PendingTermsOfServiceResponse>(
    `${baseAPI}/terms-of-service/pending`
  )

export const assignTermsOfServiceService = (
  body: AssignTermsOfServiceRequest
) => axios.post(`${baseAPI}/terms-of-service/assign`, body)
