import React, { useCallback } from 'react'
import { FiCheck, FiPlus, FiTrash, FiX } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import ThumbProduct from '~/components/ThumbProduct'
import {
  addProductOnPromotion,
  deleteProductOnPromotion
} from '~/modules/promotional/store/promotion/actions'

import modelProduct from './model'

import * as S from './styles'

/**
 * Componente responsável por renderizar um produto nas listagens.
 * @param {Object} data Dados do produto
 * @param {boolean} isSelected Informa se o produto está na lista dos selecionados
 * @param {string[]} selectedSKUS Informa os SKUS selecionados para mudarmos o botão no painel de busca
 * @param {boolean} hasAction Informa se o produto está numa página de edição e deve exibir botões de ação
 */
const Product = ({
  data,
  isSelected = false,
  selectedSKUS = null,
  hasAction = false
}) => {
  const dispatch = useDispatch()

  const product = modelProduct(data)

  const isSelectedOnSearch = selectedSKUS?.find(
    item => item === data.external_id
  )

  const handleAddProduct = useCallback(
    () => dispatch(addProductOnPromotion({ product: data })),
    [data, dispatch]
  )

  const handleDeleteProduct = useCallback(
    () => dispatch(deleteProductOnPromotion({ product: data })),
    [data, dispatch]
  )

  return (
    <S.Container>
      {product.assets && (
        <S.Image>
          <ThumbProduct asset={product.assets} title={product.name} />
        </S.Image>
      )}

      <S.Infos>
        {product.name && (
          <div className="mainInfo" data-testid="productTest">
            <S.Name>{product.name}</S.Name>
          </div>
        )}

        <div className="secondary">
          {product.external_id ? (
            <S.InfoItem>ID: {product.external_id} </S.InfoItem>
          ) : (
            <AlertInfo text="ID não cadastrado" />
          )}
        </div>
      </S.Infos>

      {hasAction && (
        <S.Action>
          {isSelectedOnSearch ? (
            <S.SelectedOnSearch>
              <Button
                template="primary"
                text="Selecionado"
                iconLeft={<FiCheck />}
                className="selectedItem"
                size="small"
              />

              <Button
                template="transparentDanger"
                text="Remover"
                iconLeft={<FiX />}
                onClick={handleDeleteProduct}
                className="removeItem"
                size="small"
              />
            </S.SelectedOnSearch>
          ) : (
            <>
              {isSelected ? (
                <Button
                  template="transparentDanger"
                  text={<FiTrash />}
                  onClick={handleDeleteProduct}
                  size="xsmall"
                  customWidth="auto"
                  data-testid="buttonDelete"
                />
              ) : (
                <Button
                  template="primaryOutlined"
                  text="Selecionar"
                  iconLeft={<FiPlus />}
                  onClick={handleAddProduct}
                  size="small"
                  data-testid="buttonSelect"
                />
              )}
            </>
          )}
        </S.Action>
      )}
    </S.Container>
  )
}

export default Product

Product.defaultProps = {
  isSelected: false,
  selectedSKUS: null,
  hasAction: false
}

Product.propTypes = {
  selectedSKUS: PropTypes.arrayOf(PropTypes.string),
  isSelected: PropTypes.bool,
  hasAction: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    account_id: PropTypes.string,
    location_id: PropTypes.string,
    store_id: PropTypes.string,
    external_id: PropTypes.string,
    product_id: PropTypes.string,
    name: PropTypes.string,
    brand: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string
    }),
    current_stock: PropTypes.number,
    minimum_stock: PropTypes.number,
    price: PropTypes.number,
    promotional_price: PropTypes.number,
    gtin: PropTypes.string,
    additional_gtins: PropTypes.arrayOf(PropTypes.string),
    ncm: PropTypes.shape({
      _id: PropTypes.string,
      code: PropTypes.string
    }),
    cest: PropTypes.shape({
      _id: PropTypes.string,
      code: PropTypes.string
    }),
    categories: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    assets: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      ),
      large: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      ),
      medium: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      ),
      small: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          mime: PropTypes.string
        })
      )
    }),
    attributes: PropTypes.arrayOf(PropTypes.string),
    package: PropTypes.string,
    unit_weight: PropTypes.number,
    unit_measurement: PropTypes.string,
    weight_per_unit: PropTypes.number,
    indexed_at: PropTypes.string,
    additional_gtin: PropTypes.arrayOf(PropTypes.string),
    asset: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string
      })
    ),
    category: PropTypes.arrayOf(PropTypes.string),
    stock: PropTypes.number
  }).isRequired
}
