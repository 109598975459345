import { IconType } from 'react-icons'
import { FiMessageSquare as icon } from 'react-icons/fi'

import * as Chat from './pages/'

const chatRoutes: RootMenuItem<IconType> = {
  id: '18d5b7a0-8614-44db-8db0-2c15c119dc51',
  path: '/chats',
  name: 'Atendimento',
  icon,
  children: [
    {
      id: '344ab5bd-7727-45d3-8c69-be166a5afe19',
      path: '/chats',
      name: 'Conversas',
      pageTitle: 'Atendimento',
      component: Chat.Messenger,
      maxHeightViewport: true,
      contentFullWidth: true
    },
    {
      id: null,
      path: '/chats/attendants',
      name: 'Atendentes',
      pageTitle: 'Atendentes',
      component: Chat.Attendants
    },
    {
      id: '0df16aeb-ce8d-4aa5-bd82-08cbbed4ac22',
      path: '/chats/templates',
      name: 'Mensagens prontas',
      pageTitle: 'Mensagens prontas',
      component: Chat.Templates,
      hideMenu: false
    },
    {
      id: '6ece4d13-3148-4f93-b762-692039c13134',
      path: '/chats/tags',
      name: 'Tags das conversas',
      pageTitle: 'Tags de mensagens',
      component: Chat.Tags
      // permissionType: 'admin'
    },
    {
      id: null, // rota-sem-id
      path: '/chats/:id',
      name: 'Conversas',
      pageTitle: 'Atendimento',
      component: Chat.Messenger,
      maxHeightViewport: true,
      contentFullWidth: true,
      hideMenu: true
    },
    {
      id: null,
      path: '/chats/:id/chat',
      name: 'Conversas',
      pageTitle: 'Atendimento',
      component: Chat.SingleChat,
      maxHeightViewport: true,
      contentFullWidth: true,
      hideMenu: true
    },
    {
      id: 'ac7fd20d-1251-41a6-acd8-6f1ce6d324fc',
      path: '/chats/dashboard',
      name: 'Relatório de atendimento',
      pageTitle: 'Relatório de atendimento',
      component: Chat.Dashboard,
      // permissionType: 'admin',
      contentFullWidth: true
    },
    {
      id: '56412ae5-7801-4223-9523-642d398b9458',
      path: '/chats/dashboard-bot-steps',
      name: 'Relatório de passos do bot',
      pageTitle: 'Relatório de passos do bot',
      component: Chat.BotStepsDashboard,
      // permissionType: 'admin',
      contentFullWidth: true
    },
    {
      id: '32cfaaa8-6d8c-42f3-8c28-b28ea0ed0bae',
      path: '/chats/attendance',
      name: 'Métricas de atendimento',
      pageTitle: 'Métricas de atendimento',
      // permissionType: 'admin',
      component: Chat.Attendance
    }
  ]
}

export default chatRoutes
