export default {
  list: {
    page: 1,
    perPage: 25,
    sortKey: null,
    sortDirection: null,
    sortKeyDefault: null,
    sortDirectionDefault: null
  }
} as RetailMediaCheckingAccountState
