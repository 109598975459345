export const DTOCreateDigitalSignageCampaign = (
  data: DTOCreateDigitalSignageCampaignPayload
) => {
  /**
   * Handle places
   */
  const allPlaces = data.places.placesCheck === 'all'

  const getAllIds = () =>
    data.places.all.split(',').map(item => ({ id: Number(item) }))

  const getPlacesIds = () =>
    data.places?.place_ids
      ?.map(item => (item.checked ? { id: Number(item.value) } : null))
      .filter(Boolean)

  const places = allPlaces ? getAllIds() : getPlacesIds()

  const assets = data?.video?.assets?.map(item => ({
    ad_size: item.ad_size,
    media_url: item.media_url
  }))

  const body: DTOCreateDigitalSignageCampaignResponse = {
    type: 'in_store',
    ad_type: 'digital_signage',

    start_at: data.start_date,
    end_at: data.end_date,

    name: data.name,
    description: data?.description,

    video: {
      assets
    },
    // Mock da preguiça do BFF
    settings: {
      cpc: null,
      cpm: null,
      daily_bugget: null,
      places
    }
  }

  if (data.publisher_id) {
    body.publisher_id = data.publisher_id
  }

  return body
}
