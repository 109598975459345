import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import { ButtonNavLink } from '~/components/Buttons'
import { getRole } from '~/helpers'

import * as S from './styles'

const LocationNav = ({ locationId }) => {
  const isAdmin = useMemo(() => getRole() === 'admin', [])

  return isAdmin ? (
    <S.Container>
      <ButtonNavLink
        text="Dados gerais"
        to={`/locations/edit/${locationId}`}
        template="light"
      />
      <ButtonNavLink
        text="Usuários"
        to={`/locations/users/${locationId}`}
        template="light"
      />
      <ButtonNavLink
        template="light"
        text="Métodos de pagamento"
        to={`/locations/payment-methods/${locationId}`}
      />
      <ButtonNavLink
        template="light"
        text="Regiões de entrega"
        to={`/logistic/${locationId}/regions`}
      />
      <ButtonNavLink
        template="light"
        text="Operadores"
        to={`/operators/list/${locationId}`}
      />
    </S.Container>
  ) : (
    <></>
  )
}

export default LocationNav

LocationNav.propTypes = {
  locationId: PropTypes.string.isRequired
}
