import React from 'react';

import PropTypes from 'prop-types';

import { formatMoney } from '~/helpers';

import * as S from './styles';

const Indicators = ({ product, indicatorAtLast }) => {
  return (
    <S.Container indicatorAtLast={indicatorAtLast}>
      <S.ItemValue>
        <strong className="title">Preço</strong>
        <span className="subtitle ">Promocional</span>
        <S.Value>{formatMoney(product.promotional_price)}</S.Value>
      </S.ItemValue>

      <S.ItemValue>
        <strong className="title">Preço</strong>
        <span className="subtitle ">Original</span>
        <S.Value>{formatMoney(product.price)}</S.Value>
      </S.ItemValue>

      <S.ItemValue>
        <strong className="title">Estoque</strong>
        <span className="subtitle ">Atual / Mínimo</span>
        <S.Value indicatorValue={product.ratioStock}>
          <div className="text">
            {product.currentStock} / {product.minimumStock}
          </div>
        </S.Value>
      </S.ItemValue>
    </S.Container>
  );
};

export default Indicators;

Indicators.propTypes = {
  indicatorAtLast: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    promotional_price: PropTypes.number,
    price: PropTypes.number,
    ratioStock: PropTypes.number,
    currentStock: PropTypes.number,
    minimumStock: PropTypes.number,
  }).isRequired,
};
