import styled from 'styled-components';

import { device } from '~/styles/device';

export const FiltersWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  padding: 0 0 0.5rem 0;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  @media ${device.tablet} {
    grid-template-columns: repeat(4, minmax(220px, 30%));
  }

  .filterWrapper {
    @media ${device.tablet} {
      max-width: 100%;
    }

    z-index: 2;
    flex: 1;

    .SelectSearchable__control {
      height: 41px;
      z-index: 10;
    }

    .SelectSearchable__menu {
      z-index: 100;
    }
  }

  .searchWrapper {
    flex: 1;
  }

  .align__right {
    grid-column: 4;
    text-align: right;
  }
`;
