import React from 'react';

import PropTypes from 'prop-types';

import AlertInfo from '~/components/AlertInfo';
import Panel from '~/components/Panel';
import { Label, Radio } from '~/components/unform';

import * as S from './styles';

const PromotionTargets = ({ isEdit, onChange }) => {
  const optionsTypes = [
    {
      id: `promotion_target_all`,
      value: 'all',
      label: 'Desconto aplicável em qualquer produto',
      disabled: isEdit,
    },
    {
      id: `promotion_target_selectd`,
      value: 'selected',
      label: 'Desconto aplicável somente em produtos selecionados',
      description: 'Os produtos poderão ser selecionados na seção Produtos',
      disabled: isEdit,
    },
  ];

  return (
    <Panel
      title="Seleção de produtos"
      isCollapsible
      initialCollapsibleState={!isEdit}
    >
      <S.Group>
        <Label
          htmlFor="promotion_target"
          text="Defina se as regras de valor mínimo e quantidade mínina deverão levar em consideração produtos específicos"
          isRequired
        />

        <Radio
          options={optionsTypes}
          name="promotion_target"
          onChange={onChange}
          required
        />

        {isEdit && (
          <AlertInfo text="A regra de produtos alvos da promoção não pode ser alterada. Para alterar, duplique a promoção e cancele a atual." />
        )}
      </S.Group>
    </Panel>
  );
};

export default PromotionTargets;

PromotionTargets.propTypes = {
  isEdit: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  initialData: PropTypes.shape({}).isRequired,
};

PromotionTargets.defaultProps = {
  isEdit: false,
};
