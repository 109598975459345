import React, { useLayoutEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import MetaTitle from '~/components/PageTitle'
import { handleFullScreenMode } from '~/modules/chat/store/actions'
import { useAppSelector } from '~/store/hooks'

import Chat from './elements/Chat'
import HandleProvider from './elements/HandleProvider'

import * as S from './styles'

const SingleChat = () => {
  const [isReady, setIsReady] = useState(false)

  const dispatch = useDispatch()

  const { selectedChat, fullScreen } = useAppSelector(state => state.chats)

  const pageTitle = useMemo(() => {
    if (!selectedChat) {
      return 'Atendimento > Conversa'
    }

    const { unreadMessage: unread, unreadMessageCount: count } = selectedChat

    const prefix = unread && count ? `(${count}) ` : ''

    return `${prefix}${selectedChat.customerName} - Atendimento`
  }, [selectedChat])

  useLayoutEffect(() => {
    dispatch(handleFullScreenMode({ value: true }))
  }, [dispatch])

  useLayoutEffect(() => {
    if (fullScreen && !isReady) setIsReady(true)
  }, [fullScreen, isReady])

  return (
    <S.Container>
      <MetaTitle title={pageTitle} />

      {isReady && <Chat />}

      <HandleProvider />
    </S.Container>
  )
}

export default SingleChat
