import { useCallback, useState } from 'react'
import { toastr } from 'react-redux-toastr'

import { FormHandles } from '@unform/core'

import { FileInput, FormGroup, InputHidden, Label } from '~/components/unform'
import { getImgSize } from '~/helpers'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { campaign as service } from '~/modules/retailMedia/services/industry'

const MAX_SIZE_UPLOAD = 1 // 1MB
const imageDimensions = {
  dimensions: {
    width: 438,
    height: 100
  }
}

const FieldsetLogo: React.FC<
  Pick<SponsoredBrandFieldsetProps<FormHandles>, 'formRef'>
> = ({ formRef }) => {
  const [previewMedia, setPreviewMedia] = useState(null)
  const [fileMedia, setFileMedia] = useState<{ media_url: string }>(null)
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const { t, tToasts } = useAPPTranslation()
  /**
   * Handle upload
   */

  const handleUpload = useCallback(
    async (data: File) => {
      const dataMultipart = new FormData()
      dataMultipart.append('file', data)

      setUploading(true)

      const options = {
        onUploadProgress: progressEvent => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )

          setUploadProgress(progress)

          console.log(t('rm:progressUploadMedia', { progress }))
        }
      }

      try {
        const response = await service.uploadLogo({
          body: dataMultipart,
          axiosOptions: options
        })

        setFileMedia({
          media_url: response.data.url
        })
      } catch (error) {
        console.error(error)
        tToasts({
          prefix: 'c:messages.uploadImage.error',
          toastFunction: toastr.error
        })
      } finally {
        setUploading(false)
      }
    },
    [t, tToasts]
  )

  /**
   * Handle upload
   */

  const handlePrepareToUploadImage = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, sizeFile: number) => {
      // consts
      const maxSizeInMBPicture = sizeFile
      const maxSizeInBytes = maxSizeInMBPicture * 1024 * 1024

      // Pega primeiro arquivo da lista
      const [file] = event.target.files as FileList

      const handleError = (errorMessage: string) => {
        toastr.error('Erro', errorMessage)

        formRef.current.clearField('sponsored_brand.brand_url_mediaFile')
      }

      // Checa se tamanho é válido
      const fileIsBigger = file.size > maxSizeInBytes
      if (fileIsBigger) {
        const errorMessage = t('c:messages.imageSize', {
          maxSize: maxSizeInMBPicture
        })

        handleError(errorMessage)

        return
      }

      // Checa Se dimensões são válidas
      const dimensions: Dimension = await getImgSize(file)
      const isSameWidth = dimensions.width === imageDimensions.dimensions.width
      const isSameHeight =
        dimensions.height === imageDimensions.dimensions.height

      if (!isSameWidth && !isSameHeight) {
        const errorMessage = t('c:messages.imageDimensions', {
          width: imageDimensions.dimensions.width,
          height: imageDimensions.dimensions.height
        })

        handleError(errorMessage)

        return
      }

      // Handle success

      handleUpload(file)

      const previewURL = URL.createObjectURL(file)

      setPreviewMedia({
        type: file.type,
        url: previewURL,
        name: file.name
      })
    },
    [handleUpload, formRef, t]
  )

  return (
    <FormGroup className="mb-2">
      <Label
        htmlFor="brand_url_mediaFile"
        isRequired
        text={t('rm:campaign.create.SponsoredBrand.FieldsetLogo.label')}
        helperText={t(
          'rm:campaign.create.SponsoredBrand.FieldsetLogo.helperText'
        )}
      />

      <FileInput
        name="brand_url_mediaFile"
        accept="image/*"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handlePrepareToUploadImage(event, MAX_SIZE_UPLOAD)
        }
      >
        {!!previewMedia && (
          <div className="mx-auto">
            <img src={previewMedia.url} alt="" />
          </div>
        )}
      </FileInput>

      {!!fileMedia?.media_url && (
        <InputHidden name="brand_url" value={fileMedia?.media_url} />
      )}
    </FormGroup>
  )
}

export default FieldsetLogo
