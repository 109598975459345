import React from 'react';
import { FiHelpCircle } from 'react-icons/fi';

import Panel from '~/components/Panel';
import Tooltip from '~/components/Tooltip';
import { FormGroup, InputMask, Label } from '~/components/unform';

const ShipmentsFields = () => {
  return (
    <>
      <fieldset>
        <Panel title="Tempos médios" className="form-grid" isCollapsible>
          <FormGroup className="span2">
            <Label htmlFor="min_interval_schedule">
              Intervalo até o próximo slot disponível
              <Tooltip
                icon={<FiHelpCircle />}
                text="Este valor define o tempo mínimo necessário entre a hora atual e o próximo slot"
              />
            </Label>
            <InputMask
              name="min_interval_schedule"
              placeholder="Insira um valor em minutos"
              iconRight="minutos"
            />
          </FormGroup>

          <FormGroup className="span2">
            <Label htmlFor="sla_create_cart_time">
              SLA - Geração de carrinho
            </Label>
            <InputMask
              name="sla_create_cart_time"
              placeholder="Insira um tempo médio de gereção de carrinho"
              iconRight="minutos"
            />
          </FormGroup>
        </Panel>
      </fieldset>

      <fieldset>
        <Panel
          title="Configurações de entrega rápida"
          className="form-grid"
          isCollapsible
        >
          <FormGroup className="span-full">
            <Label
              htmlFor="delivery_time"
              text="Tempo da entrega rápida"
              helperText="A entrega rápida será ativada se este valor for maior do que 0 (zero)."
            />
            <InputMask
              name="delivery_time"
              placeholder="Insira um tempo médio de entrega"
              iconRight="minutos"
            />
          </FormGroup>

          <FormGroup className="span-full no-border">
            <Label
              htmlFor="min_qty_items_add_express_delivery"
              text="Incremento de tempo para pedidos grandes"
              helperText="Podemos adicionar um incremento de minutos para pedidos com muitos SKUs diferentes. Defina abaixo um limite de SKUs e quantos minutos devem ser adicionados ao tempo padrão."
            />
          </FormGroup>

          <FormGroup className="span2 span-start">
            <Label
              htmlFor="min_qty_items_add_express_delivery"
              text="Limite de SKUs"
              helperText="A partir de quantos SKUs devemos adicionar o tempo adicional?"
            />

            <InputMask
              name="min_qty_items_add_express_delivery"
              placeholder="Insira uma quantidade"
              iconRight="skus"
            />
          </FormGroup>

          <FormGroup className="span2">
            <Label
              htmlFor="add_time_express_delivery"
              text="Tempo adicional"
              helperText="Quanto tempo devemos adicionar se o limite de SKUs for atingido?"
            />

            <InputMask
              name="add_time_express_delivery"
              placeholder="Insira uma quantidade"
              iconRight="minutos"
            />
          </FormGroup>
        </Panel>
      </fieldset>
    </>
  );
};

export default ShipmentsFields;
