import React from 'react';
import { FiMessageCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import { Icon } from '~/components/Icon';
import { Content, Header, WrapperShadow } from '~/components/WrapperShadow';

const PanelDescription = () => {
  const {
    orderData: { note },
  } = useSelector(state => state.order);

  return note ? (
    <WrapperShadow template="warning">
      <Header>
        <div>
          <span>
            <Icon icon={FiMessageCircle} />{' '}
            <strong>Observações do cliente</strong>
          </span>
        </div>
      </Header>

      <Content className="description">
        <p>{note}</p>
      </Content>
    </WrapperShadow>
  ) : (
    <></>
  );
};

export default PanelDescription;
