import React, { useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import ExpandableSection from '~/components/ExpandableSection'
import AddressFields from '~/pages/locations/_elements/FormLocation/Address'
import { updateCustomer } from '~/store/modules/customers/actions'

import * as S from './styles'

const CustomerAddressForm = ({ onCancelEditMode, isEditMode }) => {
  const formRef = useRef(null)

  const dispatch = useDispatch()

  const { saveCustomersLoading: loading, customer } = useSelector(
    state => state.customers
  )

  const handleSubmit = useCallback(
    formData => {
      const body = { ...customer, ...formData, address_country: 'Brazil' }

      if (isEditMode) {
        dispatch(updateCustomer({ body, onSuccess: onCancelEditMode }))
        return
      }

      dispatch(updateCustomer({ body }))
    },
    [customer, dispatch, isEditMode, onCancelEditMode]
  )

  return (
    <S.FormAddress>
      {!isEditMode && (
        <AlertInfo text="O endereço do cliente está incompleto. Você pode completar o endereço abaixo." />
      )}

      {isEditMode && (
        <header>
          <Button
            type="button"
            text="Cancelar edição"
            textLoading="Salvando..."
            loading={loading}
            disabled={loading}
            size="xsmall"
            template="transparentDanger"
            customWidth="100%"
            onClick={onCancelEditMode}
          />
        </header>
      )}

      <Form onSubmit={handleSubmit} initialData={customer} ref={formRef}>
        <ExpandableSection>
          <AddressFields
            formRef={formRef}
            labelItem="do cliente"
            hideCoordinates
            allRequired
          />

          <div className="form-default__buttons">
            {isEditMode && (
              <Button
                type="button"
                text="Cancelar"
                textLoading="Salvando..."
                loading={loading}
                disabled={loading}
                size="small"
                template="transparentDanger"
                customWidth="auto"
                onClick={onCancelEditMode}
              />
            )}

            <Button
              type="submit"
              text="Salvar"
              textLoading="Salvando..."
              loading={loading}
              disabled={loading}
              size="small"
              template="success"
              customWidth="auto"
            />
          </div>
        </ExpandableSection>
      </Form>
    </S.FormAddress>
  )
}

export default CustomerAddressForm

CustomerAddressForm.propTypes = {
  onCancelEditMode: PropTypes.func,
  isEditMode: PropTypes.bool
}

CustomerAddressForm.defaultProps = {
  onCancelEditMode: null,
  isEditMode: false
}
