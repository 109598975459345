import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useScroll } from '~/hooks/useScroll';

import Panel from '~/components/Panel';

import { Container, ButtonOverlay } from './styles';

/**
 *
 * @param {boolean} loading Informa o estado do loading do reducer products
 * @param {boolean} isActive Informa se o passo está ativo
 * @param {number} step Informa qual é este passo
 * @param {number} currentStep Informa qual passo está ativo na tela
 * @param {ReactElement} Child Componente do formulário form-`NAME`.js
 * @param {string} slug Identificador para os containers dos formulários
 * @param {string} title Título do painel do formulário
 * @param {function} onNav Função para lidar com a nevegação dos passos
 * @param {function} onSubmitStep Função para executar ao submit de cada formulário
 * @param {boolean} isLastStep Informa se é o último passao do módulo para mudar o texto do botão
 *
 */

function ContainerForm({
  slug,
  isActive,
  step,
  currentStep,
  Child,
  title,
  onNav,
  onSubmitStep,
  loading,
  isLastStep,
}) {
  const [executeScroll, htmlElRef] = useScroll();

  useEffect(() => {
    if (isActive) {
      executeScroll();
    }
  }, [step, isActive, executeScroll]);

  const disabledStep = useMemo(() => step > currentStep, [step, currentStep]);

  const handleActiveStep = () => onNav(step);
  const handleNextStep = () => onNav(step + 1);
  const handlePreviousStep = () => onNav(step - 1);

  return (
    <Container
      ref={htmlElRef}
      activeStep={isActive}
      disabledStep={disabledStep}
      id={`form_${slug}`}
    >
      {/* Exibe o botão se a seção não estiver ativa e for passada */}
      {!isActive && (
        <ButtonOverlay
          type="button"
          onClick={handleActiveStep}
          disabled={step > currentStep || loading}
        />
      )}

      <Panel title={title} className="form-default">
        {/* Conteúdo do formulário  */}
        <Child
          onNext={handleNextStep}
          onPrevious={handlePreviousStep}
          disabledStep={disabledStep}
          onSubmitStep={onSubmitStep}
          loading={loading}
          isLastStep={isLastStep}
        />
      </Panel>
    </Container>
  );
}

export default ContainerForm;

ContainerForm.propTypes = {
  loading: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  Child: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string,
  onNav: PropTypes.func.isRequired,
  onSubmitStep: PropTypes.func.isRequired,
  isLastStep: PropTypes.bool,
};

ContainerForm.deafultProps = {
  title: null,
  loading: false,
  isLastStep: false,
};
