import React, { useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'

import { debounce } from 'lodash'

import Input from '~/components/Form/Input'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

const CatalogFilters: React.FC = () => {
  /**
   * core
   */
  const { t } = useAPPTranslation()

  const executeDebounceFunc = (func: () => void) => func()
  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  /**
   * logic
   */

  const [innerLoading, setInnerLoading] = useState(false)

  const params = useQueryParams()
  const query = params.get('query')

  const handleFilter = (data: OnChangeInputData) => {
    const { name, value } = data

    if (!value) {
      const newParams = new URLSearchParams(params.toString())
      newParams.delete(name)
      history.push({ search: newParams.toString() })
      setInnerLoading(false)

      return
    }

    params.set(name, String(value))
    params.set('page', '1')
    history.push({ search: params.toString() })

    setInnerLoading(false)
  }

  const handleChange = (data: OnChangeInputData) => {
    setInnerLoading(true)
    handleDebounce(() => handleFilter(data))
  }

  return (
    <nav>
      <form className="d-flex flex-column gap-2 mb-2">
        <Input
          name="query"
          onChange={handleChange}
          defaultValue={query}
          icon={FiSearch}
          placeholder={t('rm:filter.searchProductsPlaceholder')}
          type="search"
          loading={innerLoading}
        />
      </form>
    </nav>
  )
}

export default CatalogFilters
