import axios from 'axios'
import useSWR, { SWRConfiguration } from 'swr'

type UseFetchSWR<ResponseType> = {
  url: string
  refreshInterval?: number
  shouldFetch?: boolean
} & Omit<SWRConfiguration, 'initialData'>

function useFetchSWR<ResponseType>({
  url,
  refreshInterval = 86400000,
  shouldFetch = true,
  ...rest
}: UseFetchSWR<ResponseType>) {
  const { data, error, isValidating, mutate } = useSWR<ResponseType>(
    shouldFetch ? url : null,
    async url => {
      const response = await axios.get<ResponseType>(url)

      return response.data
    },
    { refreshInterval, ...rest }
  )

  return { data, error, isValidating, mutate }
}

export default useFetchSWR
