import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import {
  FiImage,
  FiMessageSquare,
  FiSend,
  FiShoppingCart,
  FiX
} from 'react-icons/fi'
import { VscFilePdf } from 'react-icons/vsc'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import { Form } from '@unform/web'
import axios from 'axios'

import { Button } from '~/components/Buttons'
import { Icon } from '~/components/Icon'
import Loading from '~/components/Loading'
import { ImageInput, Textarea } from '~/components/unform'
import { getUserId } from '~/helpers'
import {
  togglePanelTemplates,
  togglePanelShoppingCart,
  togglePanelCustomer
} from '~/modules/chat/store/actions'
import { resetSelectedTemplate } from '~/modules/chat/store/actions/templates'

import * as S from './styles'

const ChatSubmitBar = () => {
  const [previewImage, setPreviewImage] = useState()
  const [hasContent, setHasContent] = useState()
  const [contentType, setContentType] = useState()
  const [contentRaw, setContentRaw] = useState()
  const [hasImage, setHasImage] = useState()
  const [hasTemplate, setHasTemplate] = useState()
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const formRef = useRef(null)

  const { selectedChat, selectedTemplate } = useSelector(({ chats }) => chats)
  const { session, storeNumber, customerNumber } = selectedChat

  /**
   * Sinaliza se a conversa está finalizada
   * */
  const isFinished = useMemo(() => !!session?.finishedAt, [session])

  const dispatch = useDispatch()

  const userIdLogged = getUserId()

  useEffect(() => setHasImage(!!previewImage), [previewImage])

  const handleContentStatus = useCallback(() => {
    const message = formRef?.current?.getFieldValue('message')

    const messageHasContent = message !== '' && message !== '\n'

    if (hasImage) {
      return setHasContent(true)
    }

    if (!messageHasContent) {
      formRef.current.setData({
        message: ''
      })

      return setHasContent(false)
    }

    if (messageHasContent && !hasContent) {
      return setHasContent(true)
    }
  }, [hasImage, hasContent])

  /**
   * Ouvindo mudança no estado de template selecionado para aplicar o texto na caixa de envio
   */
  useEffect(() => {
    if (selectedTemplate) {
      formRef.current.setData({
        message: selectedTemplate.message
      })

      handleContentStatus()

      setHasTemplate(selectedTemplate.templateId)

      dispatch(resetSelectedTemplate())
    }
  }, [dispatch, handleContentStatus, previewImage, selectedTemplate])

  const handlePanelTemplate = () => dispatch(togglePanelTemplates())

  const isNewCcustomer = useMemo(
    () => !selectedChat?.customerId,
    [selectedChat]
  )

  const handlePanelShoppingCart = useCallback(() => {
    const action = isNewCcustomer
      ? togglePanelCustomer
      : togglePanelShoppingCart

    dispatch(action())
  }, [dispatch, isNewCcustomer])

  const handlePreview = useCallback(e => {
    const file = e.target.files?.[0]

    if (!file) {
      setPreviewImage(null)
    }

    const [fileType] = file.type.split('/')

    const isFile = fileType === 'application'
    const isImage = fileType === 'image'

    if (!isFile && !isImage) {
      toastr.error('Erro', `Ainda não suportamos esse formato de arquivo.`)
    }

    const maxSizeInMBFile = 100
    const maxSizeInMBPicture = 5
    const maxSizeInMB = isFile ? maxSizeInMBFile : maxSizeInMBPicture
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024

    if (file.size > maxSizeInBytes) {
      const errorSizeMessages = {
        application: `O tamanho máximo permitido do arquivo é de ${maxSizeInMBFile}mb`,
        image: `O tamanho máximo permitido da imagem é de ${maxSizeInMBPicture}mb`
      }

      const errorMsg = errorSizeMessages[fileType]
      toastr.error('Erro', errorMsg)
      return
    }

    const previewURL = URL.createObjectURL(file)
    setContentRaw(file)
    setContentType(fileType)
    setPreviewImage(previewURL)
    setHasContent(true)
  }, [])

  const handleRemoveImage = useCallback(() => {
    formRef.current.clearField('image')
    setPreviewImage(null)
    handleContentStatus()
  }, [formRef, handleContentStatus])

  const chatInfos = useMemo(
    () => ({
      chat_platform: session?.channel || 'whatsapp',
      chat_channel_number: storeNumber,
      chat_customer_number: customerNumber
    }),
    [session, customerNumber, storeNumber]
  )

  const handleSubmit = useCallback(
    async (formData, { reset }) => {
      const { message, image } = formData

      if (!hasContent) {
        formRef.current.setErrors({
          message: 'A mensagem não pode estar vazia.'
        })

        return
      }

      reset()

      const messageBody = message.replace(/\n$/, '')

      const messagePreview = {
        messageId: `id${Math.random()}`,
        fromStore: true,
        messageBody,
        preview: true,
        status: 'pending',
        image: previewImage || null,
        media: {
          type: contentType || null,
          url: previewImage || null,
          name: contentRaw?.name || null
        },
        userName: 'Mensagem enviada.',
        templateId: selectedTemplate?.templateId || null
      }

      dispatch({
        type: '@chats/ADD_NEW_MESSAGE_PREVIEW',
        payload: { message: messagePreview }
      })

      const data = {
        ...chatInfos,
        message_text: messageBody,
        templateId: hasTemplate || null
      }

      if (image) {
        const dataMultipart = new FormData()
        dataMultipart.append('file', image)

        Object.keys(data).forEach(key => dataMultipart.append(key, data[key]))

        setUploading(true)

        const options = {
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )

            setUploadProgress(progress)

            console.log(`A imagem  está ${progress}% carregada... `)
          }
        }

        try {
          await axios.post(
            `/api/notification/chat/send-media`,
            dataMultipart,
            options
          )

          handleRemoveImage()
        } catch (error) {
          console.error(error)
          toastr.error('Erro', 'Houve um erro ao enviar a mensagem.')
        } finally {
          setUploadProgress(0)
          setUploading(false)
          setContentType(null)
        }

        return
      }

      try {
        await axios.post(`/api/notification/chat/send-message`, data)
      } catch (error) {
        console.error(error)
        toastr.error('Erro', 'Houve um erro ao enviar a mensagem.')
      }

      setHasTemplate()
    },
    [
      hasContent,
      previewImage,
      contentType,
      contentRaw,
      selectedTemplate,
      dispatch,
      chatInfos,
      hasTemplate,
      handleRemoveImage
    ]
  )

  const onEnterPress = useCallback(
    e => {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault()
        formRef.current.submitForm()
      }
    },
    [formRef]
  )

  const isStartChat = useMemo(
    () => !!session?.userId && !session?.finishedAt,
    [session?.finishedAt, session?.userId]
  )

  const isUserOnStartChat = useMemo(
    () => session?.userId === userIdLogged,
    [session?.userId, userIdLogged]
  )

  return (
    <S.Container hasImage={hasImage} className="ChatSubmitBar">
      {!isFinished && (
        <>
          {previewImage && (
            <div className="overlayImage">
              {previewImage && contentType === 'image' && (
                <S.FilePreview>
                  <div className="image">
                    <img
                      src={previewImage}
                      alt="Preview da imagem que será enviada"
                    />
                  </div>

                  <Loading status={uploading}>
                    Enviando imagem... {uploadProgress}%
                  </Loading>
                </S.FilePreview>
              )}

              {previewImage && contentType === 'application' && (
                <S.FilePreview>
                  <div className="file">
                    <div className="icon">
                      <VscFilePdf />
                    </div>

                    <div className="info">
                      <strong>{contentRaw.name}</strong>
                      <br />
                      <small>
                        ({((contentRaw.size / 1000 ** 2) * 1000).toFixed(0)}
                        kb)
                      </small>
                    </div>
                  </div>

                  <Loading status={uploading}>
                    Enviando arquivo... {uploadProgress}%
                  </Loading>
                </S.FilePreview>
              )}
            </div>
          )}
          {!selectedChat?.session?.finishedAt &&
            isUserOnStartChat &&
            isStartChat && (
              <Form ref={formRef} onSubmit={handleSubmit}>
                {previewImage && (
                  <Button
                    template="icon"
                    iconLeft={<FiX />}
                    onClick={handleRemoveImage}
                  />
                )}

                <label htmlFor="image">
                  {!previewImage && <Icon icon={FiImage} />}
                  <ImageInput
                    onChange={handlePreview}
                    name="image"
                    accept="image/*,application/pdf"
                  />
                </label>

                <Button
                  type="button"
                  template="icon"
                  iconLeft={<FiMessageSquare />}
                  onClick={handlePanelTemplate}
                />

                <Button
                  type="button"
                  template="icon"
                  iconLeft={<FiShoppingCart />}
                  onClick={handlePanelShoppingCart}
                />

                <Textarea
                  name="message"
                  placeholder="Digite uma mensagem"
                  onKeyDown={onEnterPress}
                  onChange={handleContentStatus}
                  disabled={contentType === 'application'}
                />

                {hasContent && (
                  <Button
                    type="submit"
                    template="icon"
                    iconLeft={<FiSend />}
                    disabled={!hasContent}
                  />
                )}
              </Form>
            )}
        </>
      )}

      {/* {isFinished && <FinishSession />} */}
    </S.Container>
  )
}

export default ChatSubmitBar
