import React, { useCallback } from 'react'
import { injectIntl } from 'react-intl'

import PropTypes from 'prop-types'

import Panel from '~/components/Panel'
import { Label, FormGroup, Select } from '~/components/unform'

const TypeFields = ({ intl, onChangeType }) => {
  const types = [
    'local_store',
    'dark_store',
    'fulfillment_center',
    'customer_service_center'
  ]

  const type_option = types.map((item, index) => ({
    id: index,
    text: intl.formatMessage({ id: item }),
    value: item
  }))

  const handleChange = useCallback(
    data => {
      onChangeType(data)
    },
    [onChangeType]
  )

  return (
    <fieldset>
      <Panel className="form-grid">
        <FormGroup className="span2">
          <Label htmlFor="type">Tipo do local</Label>
          <Select
            id="type"
            name="type"
            placeholder="Escolha um tipo"
            type="select"
            options={type_option}
            onChange={handleChange}
          />
        </FormGroup>
      </Panel>
    </fieldset>
  )
}

export default injectIntl(TypeFields)

TypeFields.propTypes = {
  intl: PropTypes.shape(),
  onChangeType: PropTypes.func.isRequired
}

TypeFields.defaultProps = {
  intl: null
}
