import styled, { css } from 'styled-components';

export const Container = styled.li`
  ${({ theme }) => css`
    .refusal_reason {
      display: flex;
      align-items: center;
      background-color: ${theme.colors.infoLighten50};
      border: 1px solid ${theme.colors.info};
      margin-bottom: 0.25rem;
      border-radius: 0.25rem;

      .key {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        margin-left: 0.25rem;
        background: ${theme.colors.info};
        border-radius: 0.5rem;
        color: white;
        height: 100%;
      }

      .text {
        padding: 0.25rem;
        margin-left: 0.25rem;
      }
    }
  `}
`;
