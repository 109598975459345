import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/configuration`

/**
 * VIEW
 *
 * @param queries
 * @returns
 */

export const getPublisherConfigurationService = (publisherId: string) =>
  axios.get<PublisherConfiguration>(`${baseURL}/publisher/${publisherId}`)

export default {
  getPublisherConfigurationService
}
