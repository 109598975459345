import AlertInfo from '~/components/AlertInfo'

import { useCampaignForm } from '../hooks/useCampaignForm'
import Banner from './Banner'
import DigitalSignage from './DigitalSignage'
import Product from './Product'
import SponsoredBrands from './SponsoredBrands'

const FallbackComponent = () => (
  <AlertInfo template="warning" text="Tipo de campanha inválido" />
)

const StrategiesCreateCampaign: React.FC = () => {
  const { selectedType } = useCampaignForm()

  const Component =
    {
      product: Product,
      banner: Banner,
      sponsored_brand: SponsoredBrands,
      digital_signage: DigitalSignage
    }[selectedType] || FallbackComponent

  return <Component />
}

export default StrategiesCreateCampaign
