import { useMemo } from 'react'

import { t } from 'i18next'
import moment from 'moment-timezone'

import { useAppSelector } from '~/store/hooks'

import { Button } from '../Buttons'

import * as S from './styles'

const DatePresets = props => {
  const {
    // startDate,
    // endDate,
    showDateFormat = t('common:patterns.date'),
    handlePresets
  } = props
  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const today = moment()

  const presets = useMemo(
    () =>
      [
        {
          text: t('common:words.today'),
          start: today,
          end: today
        },
        {
          text: t('common:words.yesterday'),
          start: moment().subtract(1, 'day'),
          end: moment().subtract(1, 'day')
        },
        {
          text: t('common:labels.lastXDays', { days: 7 }),
          start: moment().subtract(1, 'week'),
          end: today
        },
        {
          text: t('common:labels.lastXDays', { days: 15 }),
          start: moment().subtract(15, 'day'),
          end: today
        },
        {
          text: t('common:labels.lastXDays', { days: 30 }),
          start: moment().subtract(30, 'day'),
          end: today
        }
      ].reverse(),
    [today]
  )

  const isChosen = ({ start, end }) =>
    moment(start).format(showDateFormat) ===
      moment(startDate).format(showDateFormat) &&
    moment(end).format(showDateFormat) ===
      moment(endDate).format(showDateFormat)

  return (
    <S.Container>
      {presets.map(({ text, start, end }) => {
        const selected = isChosen({ start, end })

        return (
          <Button
            key={text}
            text={text}
            template={selected ? 'primary' : 'primaryOutlined'}
            size="xsmall"
            onClick={() => handlePresets(start, end)}
            customWidth="auto"
          />
        )
      })}
    </S.Container>
  )
}
export default DatePresets
