import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';

import WrapperFields from './wrapper';
import Input from '~/components/forms/inputs';

const CodesForm = ({
  onNext,
  onPrevious,
  onSubmitStep,
  disabledStep,
  edit,
  initialValue,
  isLastStep,
}) => {
  const beforeSubmitOnCreateProduct = data => {
    const formattedData = {
      main: {
        ...data,
      },
    };

    onSubmitStep(formattedData);

    onNext();
  };

  return (
    <WrapperFields
      onSubmit={beforeSubmitOnCreateProduct}
      disabledStep={disabledStep}
      onPreviousStep={onPrevious}
      edit={edit}
      isLastStep={isLastStep}
    >
      <div>
        {/* SKU */}
        <FormGroup className="form-group__flex form-group__wrap form-group__inline">
          <Input
            label="Código do produto (SKU)"
            name="sku"
            defaultValue={initialValue.sku || null}
            placeholder="Ex.: 453792303"
          />
          <small>Este é o código de identificação única do produto.</small>
        </FormGroup>

        {/* GTIN */}
        <FormGroup className="form-group__flex form-group__wrap form-group__inline">
          <Input
            label="GTIN"
            name="gtin"
            defaultValue={initialValue.gtin || null}
            placeholder="Ex.: 453792303"
          />
        </FormGroup>

        {/* MPN */}
        <FormGroup className="form-group__flex form-group__wrap form-group__inline">
          <Input
            label="MPN"
            name="mpn"
            defaultValue={initialValue.mpn || null}
            placeholder="Ex.: 453792303"
          />
        </FormGroup>

        {/* NCM */}
        <FormGroup className="form-group__flex form-group__wrap form-group__inline">
          <Input
            label="NCM"
            name="ncm"
            defaultValue={initialValue.ncm || null}
            placeholder="Ex.: 453792303"
          />
        </FormGroup>
      </div>
    </WrapperFields>
  );
};

export default CodesForm;

CodesForm.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmitStep: PropTypes.func,
  disabledStep: PropTypes.bool,
  isLastStep: PropTypes.bool,
  initialValue: PropTypes.shape({
    sku: PropTypes.string,
    gtin: PropTypes.string,
    mpn: PropTypes.string,
    ncm: PropTypes.string,
  }),
};

CodesForm.defaultProps = {
  onNext: null,
  onNext: null,
  onPrevious: null,
  onSubmitStep: null,
  disabledStep: false,
  isLastStep: false,
  initialValue: {
    sku: null,
    gtin: null,
    mpn: null,
    ncm: null,
  },
};
