import styled from 'styled-components';

export const TableLink = styled.div`
  a {
    color: var(--dark);

    &:hover {
      text-decoration: none;
    }
  }
`;
