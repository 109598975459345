import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, isDraft }) => css`
    display: grid;
    grid-template-columns: 30% 20% 20% 30%;
    width: 100%;
    border-radius: ${theme.border.radius.large};
    border: 1px solid ${theme.colors.gray};
    padding: ${theme.spacings.xxlarge};
    margin-bottom: ${theme.spacings.medium};
    line-height: 1rem;
    /* background-color: ${isDraft
      ? theme.colors.inative
      : theme.colors.light}; */

    &:hover {
      border: 1px solid ${theme.colors.primary};
      background-color: #eeedf8;
      transition: ${theme.transition.fast};
      cursor: pointer;
    }
  `}
`;

export const Column = styled.div`
  ${({ theme }) => css`
    .tradeInfo {
      display: flex;
      align-items: center;
    }

    .tradeId {
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.stormGray};
    }

    .bullet {
      margin-right: ${theme.spacings.tiny};
      color: ${theme.colors.gray};
    }

    .title {
      font-weight: ${theme.font.bolder};
      font-size: ${theme.font.sizes.medium};
      margin: ${theme.spacings.tiny} 0;
      -webkit-line-clamp: 2;
      display: -webkit-inline-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .text {
      margin: ${theme.spacings.tiny} 0 0 0;
    }

    .industry {
      font-weight: ${theme.font.bolder};
      margin-bottom: ${theme.spacings.tiny};
    }

    .value {
      margin-bottom: ${theme.spacings.tiny};
      text-align: right;
    }

    .message {
      font-size: ${theme.font.sizes.xsmall};
      text-align: right;

      svg {
        color: ${theme.colors.success};
      }
    }
  `}
`;

const getStatusColor = (theme, type) => {
  const statusMap = {
    confirmed: theme.colors.successDark,
    finished: theme.colors.stormGray,
    pending: theme.colors.warning,
  };

  return statusMap[type] || theme.colors.dark;
};

export const Status = styled.span`
  ${({ theme, type }) => css`
    color: ${getStatusColor(theme, type)};
    font-size: 13px;
    font-weight: ${theme.font.bold};
  `}
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;

  .date {
    display: flex;
    align-items: center;

    & + .date {
      margin-left: 4px;
      padding-left: 16px;
      position: relative;

      &::before {
        background-color: #c1c2cc;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        height: 1px;
        width: 12px;
      }
    }
  }

  svg {
    margin-right: 4px;
  }
`;

export const StatusTag = styled.p`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.warning};
    border-radius: ${theme.border.radius.medium};
    font-size: 13px;
    font-weight: ${theme.font.bold};
    padding: ${theme.spacings.tiny} ${theme.spacings.xxxsmall};
    display: inline-flex;
    align-items: center;
    color: ${theme.colors.warning};

    svg {
      margin-right: ${theme.spacings.tiny};
    }
  `}
`;

export const ValuesContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  `}
`;
