import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { getWhiteLabelPublisherId } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import { deleteAllProductsOnCampaign } from '~/modules/retailMedia/store/campaign/actions'
import { getPublisherConfiguration } from '~/modules/retailMedia/store/industry/publisherConfiguration/actions'

import PublishersFieldset from '../../SelectPublishers'

// import { Container } from './styles';

const Pubisher: React.FC = () => {
  const [selectedPublisher, setSelectedPublisher] = useState<
    undefined | { value: string }
  >()

  const dispatch = useDispatch()

  const query = useQueryParams()
  const publisherIdParam = useMemo(() => query.get('rmid') || null, [query])

  const { isWhiteLabel } = useAppInfo()

  const publisherId = useMemo(
    () => (isWhiteLabel ? getWhiteLabelPublisherId() : publisherIdParam),
    [isWhiteLabel, publisherIdParam]
  )

  useLayoutEffect(
    () => setSelectedPublisher({ value: publisherId }),
    [publisherId]
  )

  /**
   * Handle publisher
   */

  const handleSelectedPublisher = useCallback(
    (publisher: { value: string; label: string }) => {
      if (publisher.value !== selectedPublisher?.value) {
        dispatch(deleteAllProductsOnCampaign())
      }
    },
    [dispatch, selectedPublisher]
  )

  /**
   * Handle publisher brands
   */

  useLayoutEffect(() => {
    if (publisherId) dispatch(getPublisherConfiguration(publisherId))
  }, [dispatch, publisherId])

  return (
    <>
      {!isWhiteLabel && !selectedPublisher?.value && (
        <PublishersFieldset onChange={handleSelectedPublisher} />
      )}
    </>
  )
}

export default Pubisher
