const calcRatio = (current, minimum) => {
  const value = ((Number(current) - Number(minimum)) * 100) / Number(current)

  return Number.isFinite(value) ? value : 1
}

const modelProduct = data => ({
  id: data.id,
  name: data.name,
  asset: data.asset,
  active: data.active,
  available: data.available,
  unit_measurement: data.unit_measurement,
  unit_weight: data.unit_weight,
  // Infos
  brand: data.brand?.name,
  categories: data.categories.length ? data.categories : null,
  // Code
  gtin: data.gtin,
  external_id: data.external_id,
  // Stock
  currentStock: data.current_stock,
  minimumStock: data.minimum_stock,
  ratioStock: calcRatio(data.current_stock, data.minimum_stock),
  // Price
  price: data.price,
  promotional_price: data.promotional_price,
  // Date
  indexedAt: data.indexed_at
})

export default modelProduct
