import { types } from '../constants'

const index = {
  pending: `${types.get}_PENDING`,
  fulfilled: `${types.get}_FULFILLED`,
  rejected: `${types.get}_REJECTED`
}

const parentKey = 'accountInfo'
const key = 'view'

export const view = {
  [index.pending]: ({ draft }: ActionData) => {
    draft[parentKey][key].loading = true
  },
  [index.fulfilled]: ({ draft, action }: GetAccountInfoFulfilled) => {
    draft[parentKey][key].data = action.payload

    draft[parentKey][key].loading = false
    draft[parentKey][key].error = null
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft[parentKey][key].loading = false
    draft[parentKey][key].error = action.payload
  }
}
