import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { FiX } from 'react-icons/fi'
import { toastr } from 'react-redux-toastr'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import ThumbProduct from '~/components/ThumbProduct'
import { getImageDimensions } from '~/helpers'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import Image from '~/modules/retailMedia/components/BannersTable/TumbProduct'
import { campaign as service } from '~/modules/retailMedia/services/industry'

import * as S from './styles'

type FileToUploadProps = {
  data: { file: File; id: string }
  onSave(data: ItemBannerTable): void
  getPlacementByDimension(data: Dimension): SizeBySegmentation
  onDelete(): void
}

const FileToUpload: React.FC<FileToUploadProps> = ({
  data: { file: data, id },
  getPlacementByDimension,
  onDelete,
  onSave
}) => {
  const { t, tCapitalize } = useAPPTranslation()

  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [dimensions, setDimensions] = useState<Dimension>()

  const previewURL = useMemo(() => URL.createObjectURL(data), [data])

  const getDimensions = useCallback(async () => {
    const imageDimensions = await getImageDimensions(previewURL)

    setDimensions(imageDimensions)
  }, [previewURL])

  useLayoutEffect(() => {
    getDimensions()
  }, [getDimensions])

  const placement = useMemo(() => {
    if (!dimensions) {
      return null
    }

    const response = getPlacementByDimension(dimensions)

    return response
      ? { ...response, adSize: response.name, name: response.size }
      : null
  }, [getPlacementByDimension, dimensions])

  /**
   * Handle upload
   */

  const handleUpload = useCallback(async () => {
    const dataMultipart = new FormData()
    dataMultipart.append('file', data)

    Object.keys(data).forEach(key => dataMultipart.append(key, data[key]))

    setUploading(true)

    const options = {
      onUploadProgress: progressEvent => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )

        setUploadProgress(progress)

        console.log(t('rm:progressUploadMedia', { progress }))
      }
    }

    try {
      const response = await service.upload({
        body: dataMultipart,
        axiosOptions: options
      })

      const bodyRequest: ItemBannerTable = {
        id,
        media_url: response.data.url,
        fileMedia: data,
        ad_size_name: placement.name,
        ad_size: placement.adSize,
        dimensions: {
          height: placement.height,
          width: placement.width
        }
      }

      onSave(bodyRequest)
    } catch (error) {
      console.error(error)
      toastr.error(
        t('rm:campaign.create.FileToUpload.error.title'),
        t('rm:campaign.create.FileToUpload.error.message')
      )
    }
  }, [data, onSave, id, placement, t])

  useEffect(() => {
    if (placement && !uploading) handleUpload()
  }, [handleUpload, placement, uploading])

  return (
    <S.Container isValid={!!placement}>
      <Image url={previewURL}>
        <ThumbProduct url={previewURL} />
      </Image>

      <div className="info">
        <strong>
          <code>
            {placement?.name ||
              t('rm:campaign.create.FileToUpload.anyCompatible')}
          </code>
        </strong>

        <small className="title">{data.name}</small>

        <small className="dimensions">
          {t('rm:campaign.create.FileToUpload.dimensions')}: {dimensions?.width}
          px x {dimensions?.height}px
        </small>
      </div>

      <div className="action">
        <Loading status={uploading}>
          {t('rm:campaign.create.FileToUpload.uploadingMedia')} {uploadProgress}
          %
        </Loading>

        {!placement && (
          <Button
            template="deleteOutline"
            onClick={onDelete}
            iconLeft={<FiX />}
            customWidth="auto"
          />
        )}
      </div>
    </S.Container>
  )
}

export default FileToUpload
