import { types } from '../constants'

const index = {
  pending: `${types.list}_PENDING`,
  fulfilled: `${types.list}_FULFILLED`,
  rejected: `${types.list}_REJECTED`
}

export const list = {
  [index.pending]: ({ draft }) => {
    draft.industry.publisherConfiguration.list.loading = true
    draft.industry.publisherConfiguration.list.error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.publisherConfiguration.list.data = action.payload

    draft.industry.publisherConfiguration.list.loading = false
    draft.industry.publisherConfiguration.list.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.publisherConfiguration.list.loading = false
    draft.industry.publisherConfiguration.list.error = action.payload
  }
}
