const prefix = '@retailMedia/industry/campaign'

export const CAMPAIGN_GET = `${prefix}/GET`

export const CAMPAIGN_CREATE = `${prefix}/CREATE`
export const CAMPAIGN_UPDATE = `${prefix}/UPDATE`
export const CAMPAIGN_PATCH = `${prefix}/PATCH`
export const CAMPAIGNS_UPLOAD = `${prefix}/UPLOAD`

export const CAMPAIGN_DELETE = `${prefix}/DELETE`

export const CAMPAIGN_REDUX_QUERY = `${prefix}/REDUX_QUERY`

export const CAMPAIGNS_GET_DASHBOARD = `${prefix}/GET_DASHBOARD`

export const CANCEL_CAMPAIGN = `${prefix}/CANCEL_CAMPAIGN`

export const ADD_PRODUCT = `${prefix}/ADD_PRODUCT`
export const ADD_PRODUCTS_BULK = `${prefix}/ADD_PRODUCTS_BULK`
export const DELETE_PRODUCT = `${prefix}/DELETE_PRODUCT`
export const DELETE_ALL_PRODUCTS = `${prefix}/DELETE_ALL_PRODUCTS`
export const CLEAR_CAMPAIGN_STORE = `${prefix}/CLEAR_CAMPAIGN_STORE`
export const PATCH_PRODUCT = `${prefix}/PATCH_PRODUCT`

export const CAMPAIGNS_GET_REPORT = `${prefix}/GET_REPORT`
export const CAMPAIGNS_GET_REPORT_HISTORY = `${prefix}/GET_REPORT_HISTORY`

export const types = {
  get: CAMPAIGN_GET,

  create: CAMPAIGN_CREATE,
  update: CAMPAIGN_UPDATE,
  patch: CAMPAIGN_PATCH,

  upload: CAMPAIGNS_UPLOAD,

  remove: CAMPAIGN_DELETE,

  cancelCamapign: CANCEL_CAMPAIGN,

  dashboard: CAMPAIGNS_GET_DASHBOARD,

  product: {
    add: ADD_PRODUCT,
    addBulk: ADD_PRODUCTS_BULK,
    remove: DELETE_PRODUCT,
    remove_all: DELETE_ALL_PRODUCTS
  },
  patch_product: PATCH_PRODUCT,
  report: {
    get: CAMPAIGNS_GET_REPORT,
    history: CAMPAIGNS_GET_REPORT_HISTORY
  },

  clearStore: CLEAR_CAMPAIGN_STORE
}
