import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { FormGroup, Input, Label } from '~/components/unform';

const CouponCode = ({ initialData }) => {
  const [message, setMessage] = useState(initialData?.code || '');

  const handleChange = ({ value }) => {
    const result = value.toUpperCase().replace(/\s/g, '');

    setMessage(result);
  };

  return (
    <FormGroup>
      <Label htmlFor="coupon.code" isRequired text="Código do cupom" />

      <Input
        name="code"
        placeholder="Digite o cupom"
        required
        autoFocus
        onChange={handleChange}
        value={message}
      />

      <small>
        <strong>Exemplo:</strong> BEMVINDO10
      </small>
    </FormGroup>
  );
};

export default CouponCode;

CouponCode.propTypes = {
  initialData: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};
