import styled, { css } from 'styled-components';

export const Container = styled.span`
  ${({ theme }) => css`
    display: inline-flex;

    flex-direction: row-reverse;

    border: 1px solid ${theme.colors.inative};
    background-color: ${theme.colors.light};
    border-radius: ${theme.border.radius.small};

    .label {
      padding: 0.25rem 0.5rem;
      background-color: ${theme.colors.primaryLighten45};
      border-radius: ${theme.border.radius.small};
    }

    .text {
      padding: 0.25rem 0.5rem;
    }
  `}
`;
