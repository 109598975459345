import { createGlobalStyle, css } from 'styled-components'

// ${css usado para o vscode indentar

const GlobalStyles = createGlobalStyle`
${({ theme }) => css`
  :root {
    --headerColor: ${theme.colors.header};
    --primary: ${theme.colors.primary};
    --secondary: ${theme.colors.secondary};

    --info: ${theme.colors.info};
    --success: ${theme.colors.success};
    --successAlert: ${theme.colors.successAlert};
    --warning: ${theme.colors.warning};
    --danger: ${theme.colors.danger};

    --hover: ${theme.colors.hover};
    --inative: ${theme.colors.inative};
    --loading: #ffb450;

    --white: ${theme.colors.white};
    --light: ${theme.colors.light};
    --tertiary: ${theme.colors.tertiary};
    --gray: ${theme.colors.gray};
    --stormGray: ${theme.colors.stormGray};
    --dark: ${theme.colors.dark};

    --mainHeaderHeight: 48px;
    --heightChatHeader: 57px;
    --heightChatSubmitBar: calc(80px + 2rem);

    --neutralLowLight: ${theme.colors.neutral.low.light};
    --neutralLowMediun: ${theme.colors.neutral.low.medium};
    --neutralHighDark: ${theme.colors.neutral.high.dark};

    --font-size-tiny: ${theme.font.sizes.tiny};
    --font-size-md: ${theme.font.sizes.medium};
    --font-size-sm: ${theme.font.sizes.small};
    --font-size-xsm: ${theme.font.sizes.xsmall};
    --font-size-2xsm: ${theme.font.sizes.xxsmall};
    --font-size-lg: ${theme.font.sizes.large};
    --font-size-xlg: ${theme.font.sizes.xlarge};
    --font-size-2xlg: ${theme.font.sizes.xxlarge};
    --font-size-huge: ${theme.font.sizes.xxxlarge};
  }

  .fill {
    &__primary {
      fill: var(--primary);
    }

    &__dark {
      fill: var(--dark);
    }

    &__secondary {
      fill: var(--secondary);
    }
  }

  *,
  &::after,
  &::before {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: ${theme.font.family};
  }

  /* Resets */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    /* -moz-appearance: textfield; */
  }

  ::-webkit-input-placeholder {
    color: var(---tertiary) !important;
  }
  ::-ms-input-placeholder {
    color: var(---tertiary) !important;
  }
  ::placeholder {
    color: var(---tertiary) !important;
  }

  a {
    color: var(--primary);
    text-decoration: underline;
  }

  button {
    font-family: ${theme.font.family};
    background: none;
    border: none;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    box-shadow: none;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button:focus {
    outline: 0;
  }

  /* Fake Lib  */

  .color,
  .text {
    &-primary {
      color: var(--primary);
    }

    &-gray,
    &__gray {
      color: var(--gray);
    }
  }
`}
`

export default GlobalStyles
