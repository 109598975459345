import React from 'react'; /* eslint-disable react/display-name */

import { addLogos, formatMoney } from '~/helpers';

const paymentMethod = ({ method }) =>
  method === 'in_person' ? 'Presencial' : 'Online';

const paymentMethodInfo = ({ method }) =>
  method === 'credit_card' ? 'Cartão de crédito' : method;

const valueFormatted = ({ value }) => formatMoney(value);

export const schema = {
  origin: {
    render: obj => (
      <div className={`channel__${obj.channel}`}>
        {addLogos(obj.channel || 'Sem canal')}
      </div>
    ),
    title: 'Canal',
    className: 'order__channel col__favicon',
  },
  orderId: {
    render: obj => obj.orderId || '-',
    title: 'ID do pedido',
  },
  location: {
    render: obj => obj.location.name || '-',
    title: 'Loja',
    sortable: 'location.name',
  },
  value: {
    render: obj => valueFormatted({ value: obj.orderPayment.value }),
    title: 'Valor',
    sortable: 'orderPayment.value',
  },
  paymentMethod: {
    render: obj => paymentMethod({ method: obj.orderPayment.method }),
    title: 'Tipo',
    sortable: 'orderPayment.method',
  },
  paymentMethodInfo: {
    render: obj => paymentMethodInfo({ method: obj.orderPayment.methodInfo }),
    title: 'Forma de pagamento',
  },
  authorizationId: {
    render: obj => obj.orderPayment.authorizationId || '-',
    title: 'Cód. autorização',
  },
  nsu: {
    render: obj => obj.orderPayment.nsu || '-',
    title: 'NSU',
  },
  cardNumber: {
    render: obj => obj.orderPayment.cardNumber || '-',
    title: 'Núm. cartão',
  },
  cardIssuer: {
    render: obj => obj.orderPayment.cardIssuer || '-',
    title: 'Bandeira cartão',
  },
  data: {
    render: obj => obj.orderPayment.createdAt,
    title: 'Data da transação',
    sortable: 'orderPayment.createdAt',
  },
};
