import { modelFlyersList } from '~/modules/flyers/models/flyers/list';

import * as types from '../constants';

const index = {
  pending: `${types.FLYERS_LIST}_PENDING`,
  fulfilled: `${types.FLYERS_LIST}_FULFILLED`,
  rejected: `${types.FLYERS_LIST}_REJECTED`,
  itemsPerPage: `${types.FLYERS_LIST_PER_PAGE}`,
};

export const listFlyers = {
  [index.pending]: ({ draft }) => {
    draft.flyers.list.loading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.flyers.list.loading = false;
    draft.flyers.list.data = modelFlyersList(
      action.payload.data || action.payload,
    );
    draft.flyers.list.total =
      action.payload.total || action.payload.length || 0;
    draft.flyers.list.pages = action.payload.pages || 1;
  },
  [index.rejected]: ({ draft }) => {
    draft.flyers.list.loading = false;
  },
  [index.itemsPerPage]: ({ draft, action }) => {
    draft.flyers.list.itemsPerPage = action.payload;
  },
};
