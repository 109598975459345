import { getAccountId, getRole } from '~/helpers'

const checkHasPermission = category => {
  if (category.permissionType) {
    return getRole() === category.permissionType
  }

  return true
}

const checkAccountPermission = category => {
  const shouldVerify = !!category?.accountRestriction

  if (!shouldVerify) return true

  const shouldShow = category?.accountsWithPermission?.includes(getAccountId())

  return shouldShow
}

const checkRules = category => {
  let isValidRole = true
  let isValidAccount = true

  const shouldVerifyAccount = !!category?.accountRestriction
  const shouldVerifyRole = !!category?.permissionType

  if (shouldVerifyAccount) {
    isValidAccount = checkAccountPermission(category)
  }

  if (shouldVerifyRole) {
    isValidRole = getRole() === category.permissionType
  }

  return isValidRole && isValidAccount
}

export { checkHasPermission, checkAccountPermission, checkRules }
