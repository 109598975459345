import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import SelectSearchable from '~/components/SelectSearchable';
import { indexLocationRegions } from '~/pages/locations/actions';
import {
  resetFilterLocationRegion,
  setFilteredLocationRegions,
} from '~/store/modules/filters/actions';

/**
 * Componente para filtrar a listagem por região.
 * Ele possibilita a seleção de região e mantém no estado global: filters.locationRegions.
 * @param { boolean } ignoreRedux pode ser usado sem armazenar no estado global e apenas retornar o valor selecionado.
 *
 * Pode receber um valor inicial no initialRegion e retorna um select procurável.
 *
 * @example <FilterLocationsRegions initialRegion="regionId" />
 */

const FilterLocationsRegions = ({
  initialRegion,
  initialLocation,
  defaultValue,
  onChange,
  placeholder,
  hideAll = false,
  labelAll = 'Todos as regiões',
  ignoreRedux = false,
  filterActiveRegion = false,
  filterWithoutRegion = false,
}) => {
  const [regions, setRegions] = useState();

  const {
    locationRegions: filteredLocationRegions,
    location: filteredLocations,
  } = useSelector(state => state.filters);

  const selectedLocationId = useMemo(
    () => filteredLocations?.value || initialLocation,
    [filteredLocations, initialLocation],
  );

  const {
    locationRegions,
    listLocationRegionsLoading,
    listLocationRegionsError,
  } = useSelector(state => state.locations);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLocationId) {
      dispatch(
        indexLocationRegions({
          locationId: selectedLocationId,
        }),
      );
    }

    if (!selectedLocationId) {
      setRegions(null);
    }
  }, [dispatch, selectedLocationId]);

  useEffect(() => {
    if (locationRegions) {
      const formattedRegions = locationRegions
        .filter(({ region_type }) => region_type === 1)
        .map(item => ({
          value: item.id,
          label: item.name,
        }));

      const checkValue = formattedRegions?.length
        ? [...formattedRegions]
        : null;

      setRegions(checkValue);
    }

    if (listLocationRegionsError) {
      const formattedLocations = [
        {
          value: null,
          label: 'Erro ao carregar locais...',
        },
      ];

      setRegions(formattedLocations);
    }
  }, [
    filterActiveRegion,
    hideAll,
    labelAll,
    filterWithoutRegion,
    locationRegions,
    listLocationRegionsError,
  ]);

  useEffect(() => {
    if (!ignoreRedux && initialRegion && locationRegions) {
      const foundRegion = locationRegions.find(
        region => region.id === initialRegion,
      );

      if (foundRegion) {
        const filteredLocationRegions = {
          value: foundRegion.id,
          label: foundRegion.name,
        };

        dispatch(
          setFilteredLocationRegions({
            filteredValues: filteredLocationRegions,
          }),
        );
      }
    }
  }, [dispatch, initialRegion, ignoreRedux, locationRegions]);

  const handleChangeLocationRegions = selectedLocationRegions => {
    if (onChange) {
      onChange(selectedLocationRegions);
    }

    if (!ignoreRedux) {
      dispatch(
        setFilteredLocationRegions({
          filteredValues: selectedLocationRegions,
        }),
      );
    }
  };

  const handleDefaultValue = useMemo(
    () => (ignoreRedux ? defaultValue : filteredLocationRegions),
    [defaultValue, filteredLocationRegions, ignoreRedux],
  );

  const placeholderRich = useMemo(() => {
    if (!selectedLocationId) return 'Filtro por regiões';

    if (!regions?.length) return 'Nenhuma região cadastrada';

    return placeholder || 'Filtrar por regiões';
  }, [regions, placeholder, selectedLocationId]);

  return (
    <SelectSearchable
      placeholder={placeholderRich}
      options={regions}
      loading={listLocationRegionsLoading}
      onChange={handleChangeLocationRegions}
      defaultValue={[handleDefaultValue]}
      value={[handleDefaultValue]}
      className="selectWrapper"
    />
  );
};

export default FilterLocationsRegions;

FilterLocationsRegions.propTypes = {
  hideAll: PropTypes.bool,
  labelAll: PropTypes.string,
  ignoreRedux: PropTypes.bool,
  initialRegion: PropTypes.string,
  initialLocation: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  filterActiveRegion: PropTypes.bool,
  filterWithoutRegion: PropTypes.bool,
};

FilterLocationsRegions.defaultProps = {
  hideAll: false,
  labelAll: 'Todos os locais',
  ignoreRedux: false,
  initialRegion: null,
  initialLocation: null,
  onChange: null,
  placeholder: null,
  defaultValue: null,
  filterActiveRegion: false,
  filterWithoutRegion: false,
};
