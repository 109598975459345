import * as Yup from 'yup'

export const schemaYup = Yup.object().shape({
  code: Yup.string().required(),
  note: Yup.string().when('code', (hasSelectedOther, field) => {
    return hasSelectedOther === 'other'
      ? field.required('Campo de descrição é obrigatório!')
      : field
  })
})
