import { RiWallet3Line } from 'react-icons/ri'
import { t } from 'i18next'

import { formatMoney, getRetailMediaAccountType } from '~/helpers'

import CheckingAccountRechargeRow from './components/Recharge'
import CheckingAccountRow from './components/Row'

import * as S from './styles'

const accountType = getRetailMediaAccountType()

const renderRecharge: TableSchema<GetCheckingAccountWithAccountInfoResponse> = {
  recharge: {
    title: t('rm:wallet.table.column.title.recharge'),
    render: rowData => <CheckingAccountRechargeRow data={rowData} />
  }
}

type SchemaCheckingAccount = (data: {
  hasRecharge: boolean
}) => TableSchema<GetCheckingAccountWithAccountInfoResponse>

export const schema: SchemaCheckingAccount = ({ hasRecharge }) => ({
  account: {
    title: t('rm:wallet.table.column.title.account'),
    render: rowData => <CheckingAccountRow data={rowData} />,
    sortKey: 'name',
    width: '50%'
  },
  balance: {
    title: t('rm:wallet.table.column.title.balance'),
    render: rowData => (
      <S.Subtitle>
        <RiWallet3Line />
        <span>{formatMoney(rowData.balance, false, 'R$ 0,00')}</span>
      </S.Subtitle>
    ),
    sortKey: 'balance',
    sortInitialToggleDirection: 'DESC'
  },
  ...(accountType === 'advertiser' && hasRecharge ? { ...renderRecharge } : {})
})
