import { FILTERS_SEELOUT } from '~/store/modules/filtersSellOut/constants'

import { metabaseKeys } from './keysMetabase'

export default {
  metabaseType: metabaseKeys[FILTERS_SEELOUT.supermarkets],
  filteredBy: null,
  placeholder: '',
  placeholderError: '',
  labelMulti: '',
  labelAll: '',
  searching: '',
  bigData: false
}
