import { FiX } from 'react-icons/fi'

import PropTypes from 'prop-types'

import {
  Modal as BaseModal,
  Header,
  Title,
  CloseButton
} from '~/components/ConfirmationModal/styles'

import * as S from './styles'

const Modal: React.FC<ModalProps> = ({
  title,
  children,
  isOpen = false,
  onClose,
  maxWidth,
  bluredBg = false
}) => {
  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onClose}
      maxWidth={maxWidth}
      bluredBg={bluredBg}
    >
      {(title || onClose) && (
        <Header>
          {title && <Title>{title}</Title>}

          {onClose && (
            <CloseButton type="button" onClick={onClose}>
              <FiX />
            </CloseButton>
          )}
        </Header>
      )}

      <S.Content>{children}</S.Content>
    </BaseModal>
  )
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string
}

export default Modal
