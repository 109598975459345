import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import { approveFraudAnalysis } from '~/pages/orders/actions'

import { AuthorizationTypes } from './types'

const FraudAnalysisButton = ({ orderId, payment }) => {
  const dispatch = useDispatch()

  const { loadingApproverFraudAnalysis } = useSelector(state => state.order)

  const handleApproveFrauAnalysys = useCallback(() => {
    const requestData = {
      orderId,
      body: {
        transaction_id: payment?.transaction_id
      }
    }

    dispatch(approveFraudAnalysis(requestData))
  }, [dispatch, orderId, payment?.transaction_id])

  const showButtonFraudAnalysis = useMemo(
    () => payment?.fraud_analysis_status === 'payment_in_analysis',
    [payment]
  )

  return (
    !!showButtonFraudAnalysis && (
      <>
        {' '}
        <Button
          text="Aprovar análise"
          onClick={handleApproveFrauAnalysys}
          customWidth="auto"
          size="xsmall"
          template="transparentDanger"
          loading={loadingApproverFraudAnalysis}
        />
      </>
    )
  )
}

FraudAnalysisButton.propTypes = AuthorizationTypes

export default FraudAnalysisButton
