import React, { useMemo, useCallback } from 'react';

import { useMapDraw } from '../../../hooks/useMapDraw';
import Icons from '../../Icons';

import * as S from './styles';

const DrawButtons = () => {
  const { editingRegion, handleStartDraw, handleEditingRegion, isEditingMode } =
    useMapDraw();

  const selectedType = useMemo(
    () => (editingRegion ? editingRegion.type : null),
    [editingRegion],
  );

  const buttons = [
    {
      type: 1,
      Icon: <Icons type="draw" />,
      title: 'Região de entrega',
      description: 'Desenhe a região que o local realizará entregas.',
      template: 'default',
    },
    {
      type: 2,
      Icon: <Icons type="forbidden" />,
      title: 'Região de exclusão',
      description:
        'Você não receberá pedidos de clientes na região de exclusão.',
      template: 'delete',
    },
  ];

  const handleClick = useCallback(
    type => {
      const action = isEditingMode ? handleEditingRegion : handleStartDraw;

      action({ type });
    },
    [handleEditingRegion, handleStartDraw, isEditingMode],
  );

  return (
    <S.SidebarButtons>
      {buttons.map(button => (
        <S.SidebarButton
          key={button.type}
          type="button"
          onClick={() => handleClick(button.type)}
          isActive={button.type === selectedType}
          isEditingMode={!!editingRegion?.type}
          {...(button.template === 'delete' ? { className: 'delete' } : {})}
        >
          {button.Icon}
          <div className="title">{button.title}</div>
          <div className="description">{button.description}</div>
        </S.SidebarButton>
      ))}
    </S.SidebarButtons>
  );
};

export default DrawButtons;
