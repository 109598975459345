import styled, { css } from 'styled-components';

export const InnerModal = styled.div`
  ${({ theme }) => css`
    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    animation: fade 0.1s linear normal;

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;

    background-color: rgba(255, 255, 255, 0.75);

    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      @keyframes slideUp {
        0% {
          transform: translateY(3rem);
        }
        100% {
          transform: translateY(0);
        }
      }

      animation: slideUp 0.3s linear normal;

      display: inline-flex;
      flex-direction: column;
      align-items: center;
      width: auto;
      padding: ${theme.spacings.xxxsmall};

      border-radius: 0.5rem;

      box-shadow: 0 0 2.5rem -1rem rgba(255, 60, 30, 0.2);
      background-color: ${theme.colors.white};

      .iconHeader {
        font-size: 2rem;

        &.delete {
          color: ${theme.colors.danger};
        }
      }

      .title {
        text-align: center;
        margin-bottom: 0.75rem;

        h3 {
          margin-bottom: 0;
        }
      }

      .slotDetails {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        justify-content: space-between;
        padding: 0.5rem;
        margin-bottom: 0.75rem;

        border-radius: 0.5rem;
        background-color: ${theme.colors.primaryLighten60};
      }

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-width: 280px;
      }
    }
  `}
`;
