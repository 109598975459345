import styled from 'styled-components';
import { device } from '~/styles/device';

export const SectionFlex = styled.section`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
  }

  > section {
    @media ${device.tablet} {
      width: calc(50% - 1rem);

      &:nth-child(odd) {
        margin-right: 2rem;
      }
    }
  }
`;
