import React from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { setFilteredPromotionalType } from '~/store/modules/filters/actions';

import SelectSearchable from '../SelectSearchable';

const FilterPromotionalType = ({ intl }) => {
  const dispatch = useDispatch();

  const { promotionalType } = useSelector(state => state.filters);

  const types = [
    'buy_pay',
    'discount',
    'fixed_price',
    'coupon',
    'shipping_discount',
    'items_discount',
  ];

  const type_option = types.map((item, index) => ({
    label: intl.formatMessage({ id: item }),
    value: item,
    step: index,
    type: item,
  }));

  const handleChangeStatus = selectedStatusType => {
    dispatch(
      setFilteredPromotionalType({ promotionalType: selectedStatusType }),
    );
  };

  return (
    <SelectSearchable
      placeholder="Filtrar por tipo de promoção"
      options={type_option}
      onChange={handleChangeStatus}
      defaultValue={[promotionalType]}
    />
  );
};

export default injectIntl(FilterPromotionalType);

FilterPromotionalType.propTypes = {
  intl: PropTypes.shape(),
};

FilterPromotionalType.defaultProps = {
  intl: null,
};
