import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  transform: translateX(100%);
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem 1.6rem 1rem 1rem;
  border-radius: 0.5rem;

  box-shadow: 1px 1px 1rem -0.25rem rgba(100, 100, 100, 0.5);
  opacity: 0;
  background-color: var(--loading);

  font-weight: bold;

  transition: 0.5s ease-in-out all;

  .text {
    margin-left: 0.5rem;
  }

  &[data-active='true'] {
    opacity: 1;
    transform: translateX(0);
  }
`
