import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../../api'

const baseURL = `${baseAPI}/configuration/campaign-permissions`

export const getCampaignPermissionsServiceKey = 'getCampaignPermissions'

export const getCampaignPermissionsService = (
  params: GetCampaignPermisionsRequestParams = {}
) => axios.get<GetCampaignPermisionsResponse>(baseURL, { params })

export const patchCampaignPermissionsService = (
  body: PatchCampaignPermisionsRequestParams
) => axios.patch<PatchCampaignPermisionsResponse>(baseURL, body)
