import React, { useCallback, useMemo } from 'react'
import { FiPlusCircle } from 'react-icons/fi'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import ToogleInput from '~/components/Toggle'
import InputCheckbox from '~/components/unform/Checkbox/InputCheckbox'

import { useLogisticSlots } from '../../useLogisticSlots'
import SlotList from '../SlotList'

import * as S from './styles'

/**
 * Componente responsável por renderizar um dia com slots na tela. Ele exibirá o nome do dia (dia da semana ou data),
 * a descrição da data se houver, checkbox para selecionar o dia e um botão para criar slot.
 * @param {object} data
 * @returns React.Element
 */
const Default = ({ data }) => {
  const {
    formOpen,
    selectedDays,
    handleSelectedDays,
    mode,
    handleWeekdayWithoutSlot,
    deleteConfig
  } = useLogisticSlots()

  const day = useMemo(() => data.day, [data])
  const dayWithoutWork = useMemo(() => !!data.dayWithoutWork, [data])
  const isGlobal = useMemo(() => data.isGlobal, [data])

  const handleSelect = useCallback(() => {
    handleSelectedDays(day.id)
  }, [day, handleSelectedDays])

  const isSelected = useMemo(
    () => selectedDays.some(item => item === day.id),
    [selectedDays, day]
  )

  const handleToggleActive = useCallback(
    ({ handleLoading }) => {
      const callbackAction = () => handleLoading(false)

      if (!dayWithoutWork) {
        handleWeekdayWithoutSlot({
          config: { day: day.id, dayWithoutWork },
          callbackAction
        })
      }

      if (dayWithoutWork) {
        deleteConfig({
          config: { ...data.slots[0], dayWithoutWork },
          callbackAction
        })
      }
    },
    [data, day, dayWithoutWork, deleteConfig, handleWeekdayWithoutSlot]
  )

  return (
    <S.Container
      hasitem={!!data?.slots?.length}
      editMode={formOpen}
      isSelected={isSelected}
      template={mode}
      isGlobal={isGlobal}
    >
      <S.Header isSelected={isSelected} editMode={formOpen} template={mode}>
        <div className="title">
          <InputCheckbox
            name={day.id}
            id={day.id}
            value={day.id}
            onChange={handleSelect}
            checked={isSelected}
            label={day.label}
          />

          {data.description && (
            <span className="description">{data.description}</span>
          )}
        </div>
        <div className="toogleActiveDay">
          <ToogleInput
            name={`${day.id}_dayIsActive`}
            isChecked={!dayWithoutWork}
            onChange={handleToggleActive}
            isAsync
          />
        </div>
      </S.Header>

      <SlotList
        dayId={day.id}
        dayIsSelected={isSelected}
        slots={data.slots}
        dayWithoutWork={dayWithoutWork}
      />

      {!formOpen && (
        <div className="action">
          <Button
            type="button"
            onClick={handleSelect}
            size="small"
            template="transparentDark"
            iconLeft={<FiPlusCircle />}
            text="Criar slot"
            disabled={false}
            customWidth="100%"
          />
        </div>
      )}
    </S.Container>
  )
}

export default Default

Default.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    slots: PropTypes.arrayOf(PropTypes.shape({})),
    dayWithoutWork: PropTypes.bool,
    isGlobal: PropTypes.bool,
    description: PropTypes.string,
    date: PropTypes.string,
    day: PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    })
  }).isRequired
}
