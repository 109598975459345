import styled, { css } from 'styled-components'

const containerModifiers = {
  wasMoved: () => css`
    display: none;
  `,
  isNew: ({ theme }) => css`
    @keyframes fade {
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
    opacity: 1;
    background-color: ${theme.colors.secondaryLighten30};
    animation: fade 1s linear infinite;
  `
}

export const Container = styled.li`
  ${({ theme, wasMoved, isNew }) => css`
    list-style: none;
    background-color: ${theme.colors.white};
    border-radius: 0.5rem;
    overflow: hidden;
    transition: 0.3s ease-in-out all;

    ${wasMoved && containerModifiers.wasMoved()}
    ${isNew && containerModifiers.isNew({ theme })}

    &:hover {
      background-color: ${theme.colors.light};
    }

    a:hover {
      text-decoration: none;
    }

    .orderInfos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      line-height: 1.25;
      padding: ${theme.spacings.xxsmall} ${theme.spacings.xxsmall}
        ${theme.spacings.tiny};

      .orderId {
        color: ${theme.colors.primary};
        font-weight: ${theme.font.bolder};
        font-size: ${theme.font.sizes.medium};
        flex: 1;
      }

      .channelId {
        margin-left: 0.5rem;
        font-size: ${theme.font.sizes.xsmall};

        .label {
          display: none;
        }

        .channel {
          &__whatsapp,
          &__whatsapp_bot {
            color: #128c7e;
          }

          &__dia_das_maes {
            color: #f05555;
          }

          &__b2b {
            color: #f30055;
          }

          &__televendas {
            color: var(--primary);
          }

          &__purchase {
            color: #54412f;
          }
        }
      }
    }

    .shippingSection {
      flex: 1 1 100%;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: ${theme.spacings.tiny} ${theme.spacings.xxsmall};

      .location {
        display: inline-flex;
        align-items: center;

        border-radius: 0.25rem;
        margin-right: 0.25rem;
        padding: ${theme.spacings.tiny};
        line-height: 1;
        height: 20px;

        background: ${theme.colors.dark};
        color: ${theme.colors.light};

        overflow: hidden;

        .name {
          font-size: ${theme.font.sizes.xsmall};
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        svg {
          margin-right: 0.25rem;
          font-size: ${theme.font.sizes.xsmall};
          min-width: ${theme.font.sizes.xsmall};
        }
      }

      .time {
        text-align: right;
        line-height: 1;
        font-size: ${theme.font.sizes.xsmall};
        white-space: nowrap;
      }
    }

    .customerName {
      display: flex;
      align-items: center;
      flex: 1 1 100%;
      width: 100%;
      padding: 0 ${theme.spacings.xxsmall} ${theme.spacings.tiny};
      overflow: hidden;

      color: ${theme.colors.dark};

      .name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: ${theme.font.bold};
        font-size: ${theme.font.sizes.xsmall};
      }

      svg {
        margin-right: 0.25rem;
        font-size: ${theme.font.sizes.small};
        min-width: ${theme.font.sizes.small};
      }
    }

    .paymentTags {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid ${theme.colors.tertiary};
      padding: ${theme.spacings.tiny} ${theme.spacings.xxsmall};

      > .title {
        font-size: ${theme.font.sizes.xsmall};
        font-weight: ${theme.font.bold};
      }

      > * {
        &:not(:last-child) {
          margin-right: 0.25rem;
        }
      }

      .paymentTag {
        display: flex;
        justify-content: center;
        align-items: center;

        line-height: 1;
        font-size: ${theme.font.sizes.xsmall};
        color: var(--dark);

        padding: 0.125rem 0.25rem;

        border-radius: 2rem;

        background-color: ${theme.colors.primaryLighten60};

        &:before {
          content: '';
          background-color: ${theme.colors.warning};
          border-radius: 50%;
          height: 0.5rem;
          width: 0.5rem;
          display: inline-flex;
          margin-right: ${theme.spacings.tiny};
        }

        .text {
          color: ${theme.dark};
          font-size: 0.75rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          transition: 0.3s ease-in-out all;
        }
      }

      .paymentStatus {
        &.waiting_payment {
          &:before {
            background-color: ${theme.colors.warning};
          }
        }
        &.payment_authorized,
        &.payment_approved {
          &:before {
            background-color: ${theme.colors.success};
          }
        }
        &.error,
        &.payment_refused,
        &.payment_refunded {
          &:before {
            background-color: ${theme.colors.danger};
          }
        }
      }

      .paymentMethod {
        &.online {
          &:before {
            background-color: ${theme.colors.primary};
          }
        }
        &.in_person {
          &:before {
            background-color: ${theme.colors.info};
          }
        }
        &.credit_card {
          &:before {
            background-color: ${theme.colors.hover};
          }
        }
      }
    }
  `}
`

const footerModifiers = {
  default: ({ theme }) => css`
    color: ${theme.colors.dark};
    padding: ${theme.spacings.tiny} ${theme.spacings.xxsmall};
    background-color: ${theme.colors.tertiary};
  `,
  hasCheckoutLink: ({ theme }) => css`
    color: ${theme.colors.white};
    padding: ${theme.spacings.tiny} ${theme.spacings.xxsmall};
    background-color: ${theme.colors.hover};
  `,
  isPending: ({ theme }) => css`
    background-color: ${theme.colors.secondaryLighten30};

    a {
      border-radius: 0;
    }
  `
}

export const Footer = styled.footer`
  ${({ theme, isPending, hasCheckoutLink }) => css`
    display: flex;
    justify-content: space-between;
    min-height: 2rem;

    .value {
      flex: 1;
      display: flex;
      align-items: center;

      > .text {
        font-size: ${theme.font.sizes.small};
        font-weight: ${theme.font.bold};
      }
    }

    .checkout {
      display: flex;
      align-items: center;
      height: 100%;
      color: ${theme.colors.white};

      .text {
        margin-right: ${theme.spacings.tiny};
        font-size: ${theme.font.sizes.small};
        font-weight: ${theme.font.bold};
      }
    }

    ${!isPending && footerModifiers.default({ theme })}
    ${isPending && footerModifiers.isPending({ theme })}
    ${hasCheckoutLink && footerModifiers.hasCheckoutLink({ theme })}
  `}
`
