import React from 'react'

import DatepickerHeader from '~/components/datepicker-header'
import ExternalDashboard from '~/components/ExternalDashboard'
import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import * as S from './styles'

const CartAbandonment = () => (
  <S.Container>
    <PageTitle title="Abandono de carrinhos" />

    <HeaderPages
      title="Abandono de carrinhos"
      sideRightComponent={<DatepickerHeader />}
      className="has-datepicker"
    />

    <Filters locations aggregate />

    <ExternalDashboard dashboardId={64} useDate />
  </S.Container>
)

export default CartAbandonment
