import { getAccountType } from '~/helpers'

import { GroceryTransactionTable } from './GroceryTransactionTable'
import { IndustryTransactionTable } from './IndustryTransactionTable'

export const WalletTableTransaction = () => {
  const allComponents = {
    industry: IndustryTransactionTable,
    grocery: GroceryTransactionTable
  }

  const accountType: 'industry' | 'grocery' = getAccountType()

  const Component = allComponents[accountType]

  return <Component />
}
