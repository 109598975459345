import { formatIndustryReportCampaign } from '~/modules/retailMedia/dtos/industry/campaign/report'
import { formatIndustryReportHistoryCampaign } from '~/modules/retailMedia/dtos/industry/campaign/reportHistory'

import { types } from '../constants'

const index = {
  pending: `${types.report.get}_PENDING`,
  fulfilled: `${types.report.get}_FULFILLED`,
  rejected: `${types.report.get}_REJECTED`
}

const getReport = {
  [index.pending]: ({ draft }) => {
    draft.industry.campaign.report.loading = true
    draft.industry.campaign.report.error = null
    draft.industry.campaign.report.data = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.campaign.report.data = formatIndustryReportCampaign(
      action.payload
    )
    draft.industry.campaign.report.loading = false
    draft.industry.campaign.report.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.campaign.report.loading = false
    draft.industry.campaign.report.error =
      action?.payload?.data || action?.payload || true
  }
}

const indexHistory = {
  pending: `${types.report.history}_PENDING`,
  fulfilled: `${types.report.history}_FULFILLED`,
  rejected: `${types.report.history}_REJECTED`
}

const getReportHistory = {
  [indexHistory.pending]: ({ draft }) => {
    draft.industry.campaign.reportHistory.loading = true
    draft.industry.campaign.reportHistory.error = null
    draft.industry.campaign.reportHistory.data = null
  },
  [indexHistory.fulfilled]: ({ draft, action }) => {
    draft.industry.campaign.reportHistory.data =
      formatIndustryReportHistoryCampaign(action.payload)
    draft.industry.campaign.reportHistory.loading = false
    draft.industry.campaign.reportHistory.error = null
  },
  [indexHistory.rejected]: ({ draft, action }) => {
    draft.industry.campaign.reportHistory.loading = false
    draft.industry.campaign.reportHistory.error =
      action?.payload?.data || action?.payload || true
  }
}

export default {
  ...getReport,
  ...getReportHistory
}
