import { IndustryRecharge, FormIndustryRecharge } from './types'

const normalizeNumbers = (value: string): string => {
  return `${value}`.replace(/\D/g, '')
}

export const formatIndustryRecharge = (
  data: FormIndustryRecharge
): IndustryRecharge => {
  const {
    publisher_id,
    amount,
    payment_method,
    name,
    social_id,
    phone,
    email,
    address_city,
    address_complement,
    address_neighborhood,
    address_number,
    address_postal_code,
    address_state,
    address_street,
    card_cvv,
    card_expiration,
    card_name,
    card_number,
    card_brand,
    installments,
    company_name
  } = data

  const getFormattedAddress = () => ({
    city: address_city,
    complement: address_complement,
    country: 'Brasil',
    neighborhood: address_neighborhood,
    number: address_number,
    postcode: normalizeNumbers(address_postal_code),
    region: address_state,
    street: address_street
  })

  const industryRecharge: IndustryRecharge = {
    publisher_id,
    amount: Number(amount),
    payment_method,
    customer: {
      name,
      social_id: normalizeNumbers(social_id),
      phone: normalizeNumbers(phone || ''),
      email
    }
  }

  if (payment_method === 'credit_card') {
    const [mm, yy] = card_expiration.split('/')

    industryRecharge.installments = Number(installments || 1)
    industryRecharge.credit_card = {
      brand: card_brand,
      expiration_date: `${mm}/20${yy}`,
      holder_name: card_name,
      number: normalizeNumbers(card_number),
      security_code: card_cvv
    }

    industryRecharge.billing_address = getFormattedAddress()
  }

  if (payment_method === 'boleto') {
    delete industryRecharge.customer

    industryRecharge.billing_address = getFormattedAddress()

    industryRecharge.social_id = normalizeNumbers(social_id)
    industryRecharge.company_name = company_name
    industryRecharge.due_date = new Date(
      new Date().getTime() + 5 * 24 * 60 * 60 * 1000
    ).toISOString()
  }

  return industryRecharge
}
