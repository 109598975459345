import moment from 'moment-timezone'

import i18n from '~/i18n'

import localstorageConstants from './localstorage-manipulation'

const getAppInfoTimezone = () => {
  const storageValue = localStorage.getItem(localstorageConstants.APP_INFO)
  let timezone = '-3'

  if (storageValue) {
    const appInfo = JSON.parse(storageValue)

    timezone = String(appInfo?.timezone)
  }

  return timezone
}

const timezones: { [key: string]: string } = {
  '+3': 'Europe/Moscow',
  '-3': 'America/Sao_Paulo',
  '-5': 'America/Bogota'
  // "-3.5": 'America/Argentina/Buenos_Aires'
}

// Função para obter o timezone baseado no código do país
function getTimezone(countryCode: '-3' | '-5' | string): string {
  return timezones?.[countryCode] || 'America/Sao_Paulo'
}

// Exemplo de uso
// const country = 'BR' // Isso poderia vir da sua API
const timezone = getTimezone(getAppInfoTimezone())
const language = i18n.language || 'pt-BR'

// Configura o momento para usar o timezone correto
moment.locale(language)
moment.tz.setDefault(timezone)

/**
 * Novo arquivo para armazenar helpers de datas.
 * Migrar conforme for locais em uso
 */

export function isPast(date) {
  return moment(date).diff(moment(0, 'HH')) < 0
}

export function isFuture(date) {
  return moment(date).diff(moment(0, 'HH')) > 0
}

export type GetDurationInDaysProps = {
  startDate: Date | string
  endDate?: string
  prefix?: [singular: string, plural: string]
  returnType: 'string' | 'number' | 'sentence'
}

export const getDurationInDays = ({
  startDate,
  endDate,
  prefix,
  returnType = 'sentence'
}: GetDurationInDaysProps) => {
  const start = moment(startDate).startOf('day')
  const end = moment(endDate).startOf('day')

  let duration = moment.duration(end.diff(start)).asDays()

  if (Number.isNaN(duration)) return ''

  duration += 1

  if (returnType === 'number') return duration

  const isPlural = duration !== 1
  // const helpTextDuration = isPlural ? 'dias' : 'dia'

  let text = i18n.t('c:words.day', { count: duration }) as string

  if (prefix) {
    const finalPrefix = isPlural ? prefix[1] : prefix[0]
    text = `${finalPrefix} ${text}`
  }

  return text
}

/**
 *
 * @param {string | date} date
 *
 * @example
 * formatDateToHour(date)
 *
 * @returns
 * 00:03
 */

export const formatDateToHour = date => {
  const returnFormat = 'HH:mm'

  const formattedDate = moment(date).tz(timezone).format(returnFormat)

  return formattedDate
}

/**
 *
 * @param {string | date} date
 * @param {string} returnFormat Formato desejado do retorno
 *
 * @example
 * formatDateToString(orderData.createdAt, 'DD/MM/YYYY [às] HH:mm')
 *
 * @returns
 * 23/01/1987 às 00:03
 */

export const formatDateToString = (
  date,
  returnFormat = 'DD/MM/YY [às] HH:mm'
) => {
  const formattedDate = moment(date).tz(timezone).format(returnFormat)

  return formattedDate
}

export const formatDateToStringIgnoreUTC = (
  date,
  returnFormat = 'DD/MM/YY [às] HH:mm'
) => {
  const formattedDate = moment(date).utc(false).format(returnFormat)

  return formattedDate
}

/**
 *
 * @param {string | date} date
 * @param {string} returnFormat Formato desejado do retorno
 *
 * @example
 * formatDateToString(orderData.createdAt, 'DD/MM/YYYY [às] HH:mm')
 *
 * @returns
 * 23/01/1987 às 00:03
 */

export const formatStringDateToTimezone = date => {
  const timezoneDate = moment.tz(date, timezone).format()

  return timezoneDate
}

export const timeDistance = date => {
  const createdAt = moment(date)
  const now = moment()
  const duration = moment.duration(now.diff(createdAt))

  const elapsedDays = duration.days()
  const elapsedHours = duration.hours()
  const elapsedMinutes = duration.minutes()

  if (elapsedDays >= 1) {
    return `${elapsedDays}d`
  } else if (elapsedHours >= 1) {
    return `${elapsedHours}h${String(elapsedMinutes).padStart(2, '0')}min`
  } else {
    return `${elapsedMinutes}min`
  }
}

export const timeDistanceInMinutes = date => {
  const createdAt = moment(date)
  const now = moment()
  const duration = moment.duration(now.diff(createdAt))

  const elapsedHours = duration.hours()
  const elapsedMinutes = duration.minutes()

  if (elapsedHours >= 1) {
    return `+60min`
  } else {
    return `${elapsedMinutes}min`
  }
}

export const updateElapsedTime = date => {
  const currentTime = moment()
  const targetTime = moment(date)
  const duration = moment.duration(currentTime.diff(targetTime))
  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()
  const formattedElapsedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`

  return formattedElapsedTime
}

/**
 * Legacy
 */

export const formatDate = (date, currentFormat = 'YYYY-MM-DD') => {
  const formattedDate = moment(date, currentFormat).format('DD-MM-YYYY')

  const response = moment(formattedDate, 'DD-MM-YYYY').isValid()
    ? formattedDate
    : ''

  return response
}

/**
 *
 * @param {string | date} date
 *
 * @example
 * formatRelativeTime(date)
 *
 * @returns
 * 00:03
 */

export const formatRelativeTime = date => {
  const returnFormat = 'HH:mm'

  const yesterday = moment().subtract(1, 'days').startOf('day')

  const isYesterday = moment(date).isSame(yesterday, 'd')

  const formattedDate = moment(date).tz(timezone).format(returnFormat)

  return isYesterday ? `Ontem às ${formattedDate}` : formattedDate
}

export const formatDateExibition = date => moment(date).format('DD/MM/YYYY')

export const formatDateExhibitionShort = date => moment(date).format('DD/MM/YY')

export const formatDateExibitionUTC = date =>
  moment.utc(date).format('DD/MM/YYYY')

export const formatDateToRequest = (date, currentFormat = 'DD/MM/YYYY') => {
  const formattedDate = moment(date, currentFormat).format('YYYY-MM-DD')
  return moment(formattedDate).isValid() ? formattedDate : null
}

/**
 *
 */

export const formatDateRelative = date =>
  moment(date, 'YYYY-MM-DD').calendar(null, {
    sameDay: '[Hoje]',
    lastDay: '[Ontem]',
    lastWeek: 'dddd',
    sameElse: 'LL'
  })
