import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Form } from '@unform/web'

import { AlertConfirmation } from '~/components/AlertConfirmation'
import { Button } from '~/components/Buttons'
import { FormGroup, Label, Radio } from '~/components/unform'
import { Textarea } from '~/components/unform/Input'
import useModal from '~/hooks/useModal'
import { cancelOrderData } from '~/pages/orders/actions'

import { schemaYup } from './yup'

import * as S from './styles'

const options = [
  {
    id: 'Desistência do cliente',
    label: 'Desistência do cliente',
    value: 'customer_canceled'
  },
  {
    id: 'Produto em falta',
    label: 'Produto em falta',
    value: 'offer_stock_out'
  },
  {
    id: 'Pedido na loja errada',
    label: 'Pedido na loja errada',
    value: 'wrong_location'
  },
  {
    id: 'Pedido duplicado',
    label: 'Pedido duplicado',
    value: 'duplicated_order'
  },
  {
    id: 'Outro',
    label: 'Outro',
    value: 'other'
  }
]

interface PropsModal {
  hasCancelled: boolean
}

interface FormData {
  code: string
  note: string
}

export const ModelConfirmationCancelOrder = ({ hasCancelled }: PropsModal) => {
  const [Modal, showConfirmationModal, toggleConfirmationModal, setIsShowing] =
    useModal()
  const [hasSelectedOther, setHasSelectedOther] = useState(false)

  const dispatch = useDispatch()

  const { id: orderId } = useParams()

  const handleSubmit = async (formData: FormData) => {
    await schemaYup.validate(formData, {
      abortEarly: false
    })

    dispatch(
      cancelOrderData({
        orderId,
        data: formData
      })
    )

    setIsShowing(false)
  }

  useEffect(() => {
    setHasSelectedOther(false)
  }, [showConfirmationModal])

  const handleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (value === 'other') {
      setHasSelectedOther(true)
    } else {
      setHasSelectedOther(false)
    }
  }

  return (
    <>
      {!hasCancelled && (
        <Button
          text="Cancelar pedido"
          customWidth="auto"
          template="transparentDanger"
          onClick={toggleConfirmationModal}
        />
      )}
      <Modal
        title="Deseja cancelar o pedido?"
        isOpen={showConfirmationModal}
        onClose={toggleConfirmationModal}
      >
        <S.WrapperAlert>
          <AlertConfirmation description="Esta ação não poderá ser desfeita e o pedido será cancelado." />
        </S.WrapperAlert>
        <S.Container>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label isRequired>
                Escolha o motivo do cancelamento do pedido:
              </Label>
              <Radio
                options={options}
                name="code"
                onChange={handleRadio}
                inline
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="note" isRequired={hasSelectedOther}>
                Se desejar, descreva o motivo do cancelamento
                {!hasSelectedOther && ` (opcional)`}
              </Label>
              <Textarea
                name="note"
                minHeight="4px"
                required={hasSelectedOther}
              />
            </FormGroup>

            <div>
              <Button template="danger" customWidth="100%" type="submit">
                Entendo e desejo cancelar esse pedido
              </Button>
            </div>
          </Form>
        </S.Container>
      </Modal>
    </>
  )
}
