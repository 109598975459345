import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  iframe {
    border: none;
    height: 100%;

    ${({ theme }) => css`
      overflow-y: auto;

      /* width */
      ::-webkit-scrollbar {
        width: 0.5rem;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: ${theme.colors.inative};
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${theme.colors.tertiary};
        transition: 0.3s ease-in-out all;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: ${theme.colors.gray};
      }
    `}
  }
`;

export const ButtonWrapper = styled.nav`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  margin-bottom: 1rem;
  gap: 0.5rem;
`;
