import { produce } from 'immer'

import { isPast } from '~/helpers'

import { chatSettings } from '../pages/messenger/settings'
import * as types from './constants'
import reducerCases from './reducerCases'

const defaultValues = {
  chatCounts: { active: 0, finished: 0, archived: 0 }
}

const INITIAL_STATE: ChatsState = {
  // Indicador global de loading
  loading: false,
  masterLoadingChats: false,
  // Indicador global de error
  error: null,
  // Armazena todao os chats carregados
  chats: [],
  chatsCount: defaultValues.chatCounts,
  currentPage: 1,
  pages: 1,
  sortChatsBy: chatSettings.sortChatsBy.desc.value,
  chatsLength: 0,
  selectedStatus: 'active',
  // Indicador de loading para a requisição de sessões
  loadingChats: false,
  // Sinaliza de deve atualizar a lista de sessões
  shouldRefreshChats: false,
  // Sinaliza de deve disparar o alarme
  shouldPlayNewContactNotifications: false,
  shouldPlayNotifications: false,
  // Informações do chat que será editada
  selectedChatToUpdate: null,
  // Indicador de loading para a atualização de chat
  updateChatLoading: false,
  // Indicador sobre estado do menu de um item na lista de sessões
  chatMenuOpen: null,
  // Informa se deve ficar olhando as notificações do socket e fazendo atualização das mensagens
  autoRefresh: false,
  // Informações da sessão selecionada
  selectedChat: null,
  // Armazena as mensagens carregadas da sessão selecionada
  messages: [],
  messagesCurrentPage: 1,
  messagesPages: 1,
  messagesTotal: 0,
  // Aramazena o id recebido via url
  chatIdByUrl: null,
  // Indicador sobre estado de seleção dos chats.
  selectChatsMode: false,
  // Chats selecionados para serem editados
  chatsSelectedToEdit: [],

  // Aramazena o customerId do cliente vinculado ao chat selecionado
  customerId: null,

  // Pedido selecionado para ser exibido no painel de pedidos
  orderOpen: {},

  // Indicador sobre estado de edição de location
  editLocationMode: false,

  loadingTags: false,
  // Armazena marcadores(tags) disponíveis na conta do cliente
  avaiableTags: null,
  // Marcadores(tags) selecionadas para serem aplicadas
  selectedTags: [],
  loadingCreateTag: false,
  loadingSavingTag: false,
  // Armazena o template selecionado para ser carregado no campo de submit
  selectedTemplate: null,
  // Chat filters
  showActiveChats: true,
  showFinishedChats: false,
  showArchivedChats: false,
  // Sliding Panels
  slidingPanelIsOpen: false,
  // Indicador sobre estado do painel de cliente
  customerPanelOpen: false,
  // Indicador sobre estado do painel de temples
  templatesPanelOpen: false,
  // Indicador sobre estado do painel de temples
  tagsPanelOpen: false,
  // Indicador sobre estado do painel de gerar carrinho
  shoppingCartPanelOpen: false
}

export default function chats(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      /**
       * CHATS
       */

      case types.SET_CHATS_ORDER_BY:
        draft.loadingChats = true
        draft.masterLoadingChats = true
        draft.sortChatsBy = action.payload
        break

      // Caso usado para ligarmos o loading enquanto o debounce segura a request
      case types.SET_LOADING_CHATS:
        draft.loadingChats = action.payload
        break

      // Set auto refresh
      case types.TOGGLE_AUTO_REFRESH:
        draft.autoRefresh = action.payload.status
        break

      // Get Chats
      case `${types.GET_CHATS}_PENDING`:
        draft.loadingChats = true
        draft.shouldRefreshChats = false

        break

      case `${types.GET_CHATS}_FULFILLED`: {
        const openChat = draft.selectedChat

        const chats = action.payload.data
        const {
          currentPage,
          pages,
          total,
          count = defaultValues.chatCounts
        } = action.payload

        draft.chats = chats
        draft.chatsCount = count || defaultValues.chatCounts

        draft.currentPage = Number(currentPage)
        draft.pages = pages
        draft.chatsLength = total || action.payload.length

        draft.loadingChats = false
        draft.masterLoadingChats = false
        draft.loading = false

        if (openChat) {
          const selectedChat = chats.findIndex(
            item => item.chatId === openChat.chatId
          )

          selectedChat > -1 && (draft.selectedChat = chats[selectedChat])
        }

        break
      }

      case `${types.GET_CHATS}_REJECTED`:
        draft.loadingChats = false
        draft.masterLoadingChats = false
        draft.error = action.payload
        break

      // Get Chat
      case `${types.GET_CHAT}_PENDING`:
        draft.loadingChats = true
        draft.shouldRefreshChats = false

        break

      case `${types.GET_CHAT}_FULFILLED`: {
        // Lógica igual do GET CHATS

        const openChat = draft.selectedChat

        const chats = action.payload.data
        const { currentPage, pages, total } = action.payload

        draft.chats = chats

        draft.currentPage = Number(currentPage)
        draft.pages = pages
        draft.chatsLength = total || action.payload.length

        draft.loadingChats = false
        draft.masterLoadingChats = false
        draft.loading = false

        if (openChat) {
          const selectedChat = chats.findIndex(
            item => item.chatId === openChat.chatId
          )

          selectedChat > -1 && (draft.selectedChat = chats[selectedChat])
        }
        // Fim - Lógica igual do GET CHATS

        const chat = chats?.[0]

        if (chat) {
          draft.customerId = chat.customerId

          const isArhcived = !!chat.session && isPast(chat.session.expireAt)
          const isFinished = chat?.session?.finishedAt

          if (isArhcived) {
            draft.selectedStatus = 'archived'
            const { session, ...coreChat } = chat

            draft.selectedChat = coreChat
          }

          if (!isArhcived && isFinished) {
            draft.selectedStatus = 'finished'
            draft.selectedChat = chat
          }
        }

        break
      }

      case `${types.GET_CHAT}_REJECTED`:
        draft.loadingChats = false
        draft.masterLoadingChats = false
        draft.error = action.payload
        break

      // Get More Chats
      case `${types.GET_MORE_CHATS}_PENDING`:
        draft.loadingChats = true
        break

      case `${types.GET_MORE_CHATS}_FULFILLED`: {
        const openChat = draft.selectedChat

        const chats = action.payload.data
        const { currentPage, pages, total } = action.payload

        draft.chats = [...draft.chats, ...chats]

        draft.currentPage = Number(currentPage)
        draft.pages = pages
        draft.chatsLength = total || action.payload.length

        draft.loading = false
        draft.loadingChats = false
        draft.masterLoadingChats = false
        draft.shouldRefreshChats = false

        if (openChat) {
          const selectedChat = chats.findIndex(
            item => item.chatId === openChat.chatId
          )

          selectedChat > -1 && (draft.selectedChat = chats[selectedChat])
        }

        break
      }

      case `${types.GET_MORE_CHATS}_REJECTED`:
        draft.loadingChats = false
        draft.masterLoadingChats = false
        draft.error = action.payload
        break

      /**
       * Get initial messages
       */
      case `${types.GET_MESSAGES_INITIAL}_PENDING`: {
        draft.loading = true
        draft.messages = []
        break
      }

      case `${types.GET_MESSAGES_INITIAL}_FULFILLED`: {
        const { data, currentPage, pages, total } = action.payload

        const newMessages = [...data]

        draft.messages = [...newMessages]

        draft.messagesCurrentPage = Number(currentPage)
        draft.messagesPages = pages
        draft.messagesTotal = total
        draft.loading = false
        break
      }

      case `${types.GET_MESSAGES_INITIAL}_REJECTED`:
        draft.loading = false
        draft.error = action.payload
        break

      /**
       * Get more messages
       */
      case `${types.GET_MESSAGES_MORE}_PENDING`: {
        draft.loading = true
        break
      }

      case `${types.GET_MESSAGES_MORE}_FULFILLED`: {
        const { data, currentPage, pages, total } = action.payload

        const newMessages = [...data]

        draft.messages = [...draft.messages, ...newMessages]

        draft.messagesCurrentPage = currentPage
        draft.messagesPages = pages
        draft.messagesTotal = total
        draft.loading = false
        break
      }

      case `${types.GET_MESSAGES_MORE}_REJECTED`:
        draft.loading = false
        draft.error = action.payload
        break

      /**
       * Set selected chat
       */
      case types.SET_SELECTED_CHAT: {
        draft.selectedChat = action.payload.selectedChat
        draft.customerId = action.payload.selectedChat?.customerId || null
        draft.orderOpen = {}
        break
      }

      // Set id URL
      case types.SET_ID_URL: {
        draft.chatIdByUrl = action.payload.id
        draft.loading = true
        break
      }

      // Toogle panel customer status
      case types.TOGGLE_PANEL_CUSTOMER: {
        draft.templatesPanelOpen = false
        draft.tagsPanelOpen = false
        draft.shoppingCartPanelOpen = false
        draft.slidingPanelIsOpen = !draft.customerPanelOpen
        draft.customerPanelOpen = !draft.customerPanelOpen
        break
      }

      // Toogle panel templates status
      case types.TOGGLE_PANEL_TEMPLATES: {
        draft.customerPanelOpen = false
        draft.tagsPanelOpen = false
        draft.shoppingCartPanelOpen = false
        draft.slidingPanelIsOpen = !draft.templatesPanelOpen
        draft.templatesPanelOpen = !draft.templatesPanelOpen
        break
      }

      // Toogle panel shopping cart
      case types.TOGGLE_PANEL_SHOPPING_CART: {
        draft.customerPanelOpen = !draft.customerId
        draft.tagsPanelOpen = false
        draft.templatesPanelOpen = false
        draft.slidingPanelIsOpen = !draft.shoppingCartPanelOpen
        draft.shoppingCartPanelOpen = !draft.shoppingCartPanelOpen
        break
      }

      // Toogle panel templates status
      case types.TOGGLE_PANEL_TAGS: {
        // Reset chat list edit mode
        draft.selectChatsMode = false
        draft.chatsSelectedToEdit = []
        draft.selectedTags = []
        // Set panels
        draft.customerPanelOpen = false
        draft.templatesPanelOpen = false
        draft.shoppingCartPanelOpen = false
        draft.slidingPanelIsOpen = !draft.tagsPanelOpen
        draft.tagsPanelOpen = !draft.tagsPanelOpen
        break
      }

      // Close all panels
      case types.CLOSE_SIDE_PANELS: {
        draft.customerPanelOpen = false
        draft.templatesPanelOpen = false
        draft.tagsPanelOpen = false
        draft.slidingPanelIsOpen = false
        draft.shoppingCartPanelOpen = false
        break
      }

      // Set Chat Menu Open
      case types.SET_CHAT_MENU_OPEN: {
        const { chatId, sessionId, positionParent } = action.payload

        const selectedChats = draft.chats.find(item => item.chatId === chatId)

        const customerNumber = selectedChats?.customerNumber

        const menuIsOpen = chatId !== draft.chatMenuOpen?.chatId
        const addItem = { chatId, sessionId, positionParent, customerNumber }
        const removeItem = {
          chatId: null,
          sessionId: null,
          positionParent,
          customerNumber: null
        }

        const newStatus = menuIsOpen ? addItem : removeItem

        draft.chatMenuOpen = newStatus

        break
      }

      // Toogle mode select chats status
      case types.TOGGLE_SELECT_CHATS_MODE: {
        const { initialChat } = action.payload
        draft.selectChatsMode = !draft.selectChatsMode
        draft.chatMenuOpen = null

        if (initialChat) {
          draft.chatsSelectedToEdit = [initialChat]
        }

        break
      }

      /**
       * LOCATION
       */

      // Toogle mode select chats status
      case types.TOGGLE_EDIT_LOCATIONS_MODE: {
        draft.editLocationMode = !draft.editLocationMode

        if (draft.chatMenuOpen?.chatId) {
          const selectedChats = draft.chats.find(
            item => item.chatId === draft.chatMenuOpen.chatId
          )

          draft.selectedChatToUpdate = selectedChats
        } else {
          draft.selectedChatToUpdate = null
        }

        draft.chatMenuOpen = null

        break
      }

      case `${types.UPDATE_CHAT_CHAT}_PENDING`: {
        draft.updateChatLoading = true

        break
      }

      case `${types.UPDATE_CHAT_CHAT}_FULFILLED`: {
        const updatedChat = action.payload
        const openChat = draft.selectedChat

        const { chats } = draft
        // Atualizando chat correspondente
        const oldChat = chats.findIndex(
          item => item.chatId === updatedChat.chatId
        )

        chats[oldChat] = updatedChat

        // Atualizando informação do chat aberto
        if (openChat?.chatId === updatedChat.chatId) {
          draft.selectedChat = updatedChat
        }

        draft.editLocationMode = false
        draft.selectedChatToUpdate = null
        draft.chatMenuOpen = null
        draft.chats = chats
        draft.updateChatLoading = false
        draft.customerId = updatedChat?.customerId

        break
      }

      case `${types.UPDATE_CHAT_CHAT}_REJECTED`: {
        draft.updateChatLoading = false

        break
      }

      // Update session
      case `${types.UPDATE_SESSION}_PENDING`: {
        draft.updateChatLoading = true

        break
      }

      case `${types.UPDATE_SESSION}_FULFILLED`: {
        const updatedSession = action.payload
        const openChat = draft.selectedChat

        const { chats } = draft
        // Atualizando chat correspondente
        const oldChat = chats.findIndex(
          item => item.session.sessionId === updatedSession.sessionId
        )

        chats[oldChat] = {
          ...chats[oldChat],
          session: { ...updatedSession }
        }

        // Atualizando informação do chat aberto
        if (openChat?.session?.sessionId === updatedSession?.sessionId) {
          draft.selectedChat = {
            ...draft.selectedChat,
            session: { ...updatedSession }
          }
        }

        draft.chats = chats

        draft.editLocationMode = false
        draft.selectedChatToUpdate = null
        draft.chatMenuOpen = null
        draft.updateChatLoading = false
        draft.shouldRefreshChats = true

        break
      }

      case `${types.UPDATE_SESSION}_REJECTED`: {
        draft.updateChatLoading = false

        break
      }

      // Clear the selects chats
      case types.CLEAN_SELECTEDS_CHATS: {
        draft.selectChatsMode = false
        draft.chatsSelectedToEdit = []
        draft.selectedTags = []

        break
      }

      // Set setected chat
      case types.SET_SELECTED_CHAT_TO_EDIT: {
        const { chatId, sessionId } = action.payload
        const selectedChats = draft.chatsSelectedToEdit

        const chatsIsSelected = selectedChats.findIndex(
          item => item.chatId === chatId
        )

        if (chatsIsSelected > -1) {
          selectedChats.splice(chatsIsSelected, 1)
          draft.chatsSelectedToEdit = selectedChats

          break
        }

        draft.chatsSelectedToEdit = [...selectedChats, { chatId, sessionId }]

        break
      }

      // Set
      case types.SET_ORDER_OPEN: {
        draft.orderOpen = action.payload.orderOpen
        break
      }

      // Set message status read
      case `${types.SET_MESSAGE_STATUS_READ}_FULFILLED`: {
        draft.shouldRefreshChats = true

        draft.selectedChat = {
          ...draft.selectedChat,
          unreadMessage: false,
          unreadMessageCount: 0
        }

        break
      }

      /**
       * Add new message from socket.io
       */
      case types.ADD_NEW_MESSAGE: {
        const { selectedStatus, selectedChat } = draft

        const newMessage = action.payload.message
        const { filteredLocation } = action.payload

        const newMessageIsFilterdLocation =
          newMessage?.locationId === filteredLocation

        const messageIsFromStore = action.payload.message.fromStore

        const { botActive, messageBody, status } = action.payload.message

        const thisChatIsOpen =
          newMessage?.sessionId &&
          selectedChat?.session?.sessionId === newMessage.sessionId

        if (
          messageIsFromStore &&
          status === 'delivered' &&
          messageBody ===
            'Por enquanto, conte-nos mais sobre como podemos te ajudar...'
        ) {
          draft.shouldPlayNewContactNotifications = true
        }

        if (botActive === false && !messageIsFromStore) {
          draft.shouldPlayNotifications = true
        } else {
          draft.shouldPlayNotifications = false
        }

        if (!newMessageIsFilterdLocation) {
          draft.shouldPlayNotifications = false
        }

        /**
         * Handle messages on active chat
         */

        if (!!selectedChat && thisChatIsOpen) {
          const oldMessages = [...draft.messages]

          const { messageBody } = newMessage

          // Start - Checando se tem preview para apagar
          const hasPreview = oldMessages.findIndex(
            message => message.messageBody === messageBody && message.preview
          )

          if (hasPreview > -1) {
            oldMessages.splice(hasPreview, 1)
          }
          // End - Checando se tem preview para apagar

          // Start - Checando se tem mesmo ID
          const hasSameID = oldMessages.findIndex(
            message => message.messageId === newMessage.messageId
          )

          if (hasSameID > -1) {
            oldMessages[hasSameID] = newMessage
            draft.messages = oldMessages
            break
          }
          // End - Checando se tem mesmo ID

          draft.messages = [newMessage, ...oldMessages]
        }

        // Update chats list

        if (selectedStatus === 'active') {
          const currentChats = [...draft.chats]

          const currentChatIndex = currentChats.findIndex(
            item => item.session.sessionId === newMessage.sessionId
          )

          if (currentChatIndex > -1) {
            const currentChat = currentChats[currentChatIndex]

            currentChats.splice(currentChatIndex, 1)

            const messageIsUnred = () => {
              if (thisChatIsOpen || botActive !== false) return false

              if (messageIsFromStore) return currentChat.unreadMessage

              return true
            }

            const messageCount = () => {
              if (thisChatIsOpen || botActive !== false) return 0

              if (messageIsFromStore) return currentChat.unreadMessageCount

              return (currentChat.unreadMessageCount || 0) + 1
            }

            const updatedChat = {
              ...currentChat,
              lastMessageAt: newMessage.lastMessageAt,
              unreadMessage: messageIsUnred(),
              unreadMessageCount: messageCount(),
              session: {
                ...currentChat.session,
                botActive
              }
            }

            if (draft.sortChatsBy === 'desc') {
              draft.chats = [updatedChat, ...currentChats]
            }

            if (draft.sortChatsBy === 'asc') {
              draft.chats = [...currentChats, updatedChat]
            }
          } else {
            if (filteredLocation) {
              draft.shouldRefreshChats =
                newMessage?.locationId === filteredLocation
              return
            }

            draft.shouldRefreshChats = false
          }
        }

        break
      }

      case types.SET_NOTIFICATIONS_PLAYED: {
        draft.shouldPlayNotifications = false
        draft.shouldPlayNewContactNotifications = false
        break
      }

      // Add new message from preview
      case types.ADD_NEW_MESSAGE_PREVIEW: {
        const newMessage = action.payload.message
        const oldMessages = [...draft.messages]

        draft.messages = [newMessage, ...oldMessages]

        break
      }

      /**
       * TAGS
       */

      // Create tags

      case types.CREATE_TAG_OPTIMISTIC: {
        const newTag = action.payload
        const oldTags = draft.avaiableTags

        draft.avaiableTags = [...oldTags, newTag]

        break
      }

      case `${types.CREATE_TAG}_PENDING`: {
        draft.loadingCreateTag = true
        break
      }

      case `${types.CREATE_TAG}_FULFILLED`: {
        const newTag = action.payload
        const oldTags = draft.avaiableTags

        const filteredldTags = oldTags.filter(tag => !tag.saving)

        draft.avaiableTags = [...filteredldTags, newTag]
        draft.loadingCreateTag = false

        break
      }

      case `${types.CREATE_TAG}_REJECTED`: {
        const oldTags = draft.avaiableTags

        const filteredldTags = oldTags.filter(tag => !tag.saving)

        draft.avaiableTags = [...filteredldTags]
        draft.loadingCreateTag = false

        break
      }

      // Update tags

      case `${types.UPDATE_TAG}_PENDING`: {
        draft.loadingSavingTag = true
        break
      }

      case `${types.UPDATE_TAG}_FULFILLED`: {
        const updatedTag = action.payload
        const oldTags = draft.avaiableTags

        const filteredldTags = oldTags.map(tag =>
          tag.tagId === updatedTag.tagId ? updatedTag : tag
        )

        draft.avaiableTags = [...filteredldTags]

        draft.loadingSavingTag = false

        break
      }

      case `${types.UPDATE_TAG}_REJECTED`: {
        draft.loadingSavingTag = false

        break
      }

      // Delete tag
      case types.DELETE_TAG_OPTIMISTIC: {
        const deletedId = action.payload
        const oldTags = draft.avaiableTags

        const filteredldTags = oldTags.map(tag =>
          tag.tagId !== deletedId
            ? tag
            : { ...tag, deleted: true, saving: true }
        )

        draft.avaiableTags = [...filteredldTags]

        break
      }

      case `${types.DELETE_TAG}_FULFILLED`: {
        const oldTags = draft.avaiableTags

        const filteredldTags = oldTags.filter(tag => !tag?.deleted)

        draft.avaiableTags = [...filteredldTags]

        break
      }

      case `${types.DELETE_TAG}_REJECTED`: {
        const oldTags = draft.avaiableTags

        const filteredldTags = oldTags.map(tag =>
          tag.deleted ? { ...tag, deleted: false, saving: false } : tag
        )

        draft.avaiableTags = [...filteredldTags]
        break
      }

      // Get tags

      case `${types.GET_AVAIABLE_TAGS}_PENDING`: {
        draft.loadingTags = true
        break
      }

      case `${types.GET_AVAIABLE_TAGS}_FULFILLED`: {
        draft.avaiableTags = action.payload
        draft.loadingTags = false
        break
      }

      case `${types.GET_AVAIABLE_TAGS}_REJECTED`: {
        draft.loadingTags = true
        break
      }

      // Update chat tags
      case types.UPDATE_CHATS_TAGS_OPTIMISTIC: {
        const { sessionIds, tags } = action.payload

        const oldChats = draft.chats
        const openChat = draft.selectedChat

        sessionIds.forEach(sessionId => {
          if (sessionId) {
            const selectedChat = oldChats.findIndex(
              item => item.session?.sessionId === sessionId
            )

            // eslint-disable-next-line no-unused-vars

            draft.chats[selectedChat] = {
              ...draft.chats[selectedChat],
              session: { ...draft.chats[selectedChat].session, tags }
            }

            if (sessionId === openChat?.session.sessionId) {
              draft.selectedChat = {
                ...openChat,
                session: { ...openChat, tags }
              }
            }
          }
        })

        break
      }

      case types.UPDATE_CHATS_TAGS: {
        draft.chatsSelectedToEdit = []
        draft.selectChatsMode = false
        draft.chatMenuOpen = null
        draft.selectedTags = []
        draft.shouldRefreshChats = true

        break
      }

      case types.SET_SELECTED_TAG: {
        const { tagId, initialData } = action.payload
        const { selectedTags } = draft

        const tagIsSelected = selectedTags.indexOf(tagId)

        if (tagIsSelected > -1) {
          selectedTags.splice(tagIsSelected, 1)
          draft.selectedTags = selectedTags

          break
        }

        if (initialData) {
          draft.selectedTags = [...selectedTags, ...initialData]
        }

        if (tagId) {
          draft.selectedTags = [...selectedTags, tagId]
        }

        break
      }

      /**
       * CHATS STATUS BOT
       */
      case `${types.TOGGLE_BOT_STATUS}_PENDING`:
        draft.updateChatLoading = true
        break

      case `${types.TOGGLE_BOT_STATUS}_FULFILLED`: {
        const session = action.payload

        const selectedChat = draft.chats.findIndex(
          item => item.chatId === session.chatId
        )

        draft.selectedChat = selectedChat

        draft.updateChatLoading = false
        draft.shouldRefreshChats = false

        break
      }

      case `${types.TOGGLE_BOT_STATUS}_REJECTED`:
        draft.updateChatLoading = false
        draft.error = action.payload
        break

      /**
       * CHATS STATUS BOT OPTIMISTIC
       */
      case types.TOGGLE_BOT_STATUS_OPTIMISTIC: {
        const {
          chatId,
          data: { botActive }
        } = action.payload

        const oldChats = draft.chats

        const selectedChat = oldChats.findIndex(item => item.chatId === chatId)

        draft.chats[selectedChat] = {
          ...draft.chats[selectedChat],
          session: {
            ...draft.chats[selectedChat].session,
            botActive
          }
        }

        break
      }

      /**
       * Filters chats
       */

      case types.FILTER_CHATS_BY_STATUS: {
        draft.selectedStatus = action.payload
        draft.shouldRefreshChats = true
        draft.chats = []
        draft.chatsLength = 0
        draft.currentPage = 1
        draft.pages = 1
        break
      }

      /**
       * CHATS STATUS BOT OPTIMISTIC
       */

      case types.SET_SELECTED_TEMPLATE:
        reducerCases[`${types.SET_SELECTED_TEMPLATE}`]({ draft, action })
        break

      case types.RESET_SELECTED_TEMPLATE:
        reducerCases[`${types.RESET_SELECTED_TEMPLATE}`]({ draft })
        break

      default:
    }
  })
}
