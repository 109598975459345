import { useQuery } from '@tanstack/react-query'
import { Scope } from '@unform/core'

import Panel from '~/components/Panel'
import { FormGroup, InputHidden, Radio } from '~/components/unform'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import {
  listPlacesKey,
  listPlacesService
} from '~/modules/retailMedia/services/place'

import PlacesCheckboxesList from '../../../PlacesRadioList'

const Places: React.FC = () => {
  const { t } = useAPPTranslation()

  const optionsTypes: RadioOptionProps[] = [
    {
      id: `placesCheck_all`,
      value: 'all',
      label: t('rm:campaign.create.Places.options.all.label'),
      description: t('rm:campaign.create.Places.options.all.description')
    },
    {
      id: `placesCheck_selected`,
      value: 'selected',
      label: t('rm:campaign.create.Places.options.selected.label'),
      description: t('rm:campaign.create.Places.options.selected.description'),
      activeChildren: <PlacesCheckboxesList />
    }
  ]

  const publisherId = useQueryParams().get('rmid')

  const { data } = useQuery({
    queryKey: [listPlacesKey, publisherId],
    queryFn: async () => {
      const response = await listPlacesService({ publisher_id: publisherId })
      return response.data
    }
  })

  return (
    <Panel
      title={t('rm:campaign.create.Places.title')}
      description={t('rm:campaign.create.Places.description')}
    >
      <Scope path="places">
        <InputHidden name="all" value={data?.data?.map(item => item.id)} />

        <FormGroup>
          <Radio options={optionsTypes} name="placesCheck" required />
        </FormGroup>
      </Scope>
    </Panel>
  )
}

export default Places
