import { Middleware, configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import thunk, { ThunkMiddleware } from 'redux-thunk'

import reduxPromiseMiddleware from '~/middlewares/redux-promise-middleware/'
import socketMiddleware from '~/middlewares/socket-middleware'
import { rootReducer } from '~/store/reducers'

import persistReducers from './persistReducers'

const middlewares: Middleware[] = [
  reduxPromiseMiddleware,
  socketMiddleware,
  thunk as ThunkMiddleware<RootState>
].filter(Boolean)

const store = configureStore({
  reducer: persistReducers(rootReducer),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(middlewares)
})

export const persistor = persistStore(store)

export default store
