import React from 'react'
import { Line } from 'react-chartjs-2'
import ReactPlaceholder from 'react-placeholder'

const LineChart = ({ data, options = {}, loading }) => {
  const defaultOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      intersect: false
    },
    hover: {
      intersect: true
    },
    plugins: {
      filler: {
        propagate: false
      }
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: 'rgba(0,0,0,0.05)'
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            // beginAtZero: true,
            // stepSize: 100
          },
          display: true,
          borderDash: [5, 15],
          gridLines: {
            color: 'rgba(0,0,0,0.05)',
            fontColor: '#fff'
          }
        }
      ]
    }
  }

  return (
    <ReactPlaceholder showLoadingAnimation type="rect" ready={!loading}>
      <div className="chart chart-md">
        <Line data={data} options={{ ...defaultOptions, ...options }} />
      </div>
    </ReactPlaceholder>
  )
}

export default LineChart
