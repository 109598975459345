import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Datepicker from '../datepicker'
import { setDates } from './datepicker-header-actions'

/**
 * Para retornar um calendário sem limites de data passe maxEndDate null
 */

export const DatepickerHeader = ({ maxEndDate = 'D0' }) => {
  const { startDate, endDate } = useSelector(state => state.datepickerHeader)

  const dispatch = useDispatch()

  const callSetDates = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      dispatch(setDates({ startDate, endDate }))
    }
  }

  return (
    <div className="wrapper-datepicker-header">
      <Datepicker
        isRangeDate
        defaultStartDate={startDate}
        defaultEndDate={endDate}
        onChange={callSetDates}
        className="datepicker-header"
        maxEndDate={maxEndDate}
      />
    </div>
  )
}

export default DatepickerHeader
