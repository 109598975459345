export const placementInitialDataDTO: PlacementInitialDataDTO = data => {
  const { formats } = data

  /**
   * Product
   */

  const productFormat = formats.find(item => item.type === 'product')
  const hasProductSize = !!productFormat

  const product = {
    quantity: productFormat?.quantity || null,
    id: productFormat?.id || undefined
  }

  /**
   * Banner
   */

  const bannerFormats = formats.filter(item => item.type === 'banner')
  const hasBannerSize = !!bannerFormats?.length

  const bannerFormatsActiveSizes = bannerFormats.reduce(
    (acc, item) => ({ ...acc, [item.size]: true }),
    {}
  )

  const bannerFormatSizes = bannerFormats.reduce(
    (acc, item) => ({
      ...acc,
      [item.size]: {
        quantity: item.quantity,
        id: item.id
      }
    }),
    {}
  )

  const banner = {
    sizesActive: bannerFormatsActiveSizes,
    sizes: bannerFormatSizes
  }

  return {
    name: data.name,
    full_name: data.full_name,
    description: data.description,
    context: data.context,
    formats: {
      active: {
        banner: hasBannerSize,
        product: hasProductSize
      },
      banner,
      product
    }
  }
}
