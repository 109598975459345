import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/ad-size`

/**
 * List all
 */
export const getAdSizesService = () => axios.get<ResponseGetAdSize>(baseURL)

/**
 * Create
 */
export const createAdSizesService = (body: RequestBodyAdSize) =>
  axios.post<AdSize>(baseURL, body)

/**
 * Delete
 */
export const deleteAdSizesService = (id: string) =>
  axios.delete(`${baseURL}/${id}`)
