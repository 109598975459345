type SizesMapObjectEntries = [key: CampaignAdType, value: SizeConfig]

export const bodyPatchPlacementDTO = (data: FormDataPlacement) => {
  const { formats: formatsRaw, ...rest } = data

  const formattedData: Partial<PlacementCreateBody> = { ...rest }

  const handleReduce = (acc: PlacementPatchBodyFormat, key: CampaignAdType) => {
    switch (true) {
      case key === 'product': {
        const newValue: PlacementFormatCreate = {
          type: key,
          quantity: Number(formatsRaw.product.quantity.value),
          id: formatsRaw.product?.id ? formatsRaw.product.id : undefined
        }

        return [...acc, newValue]
      }

      case key === 'sponsored_brand': {
        const newValue: PlacementFormatCreate = {
          type: key,
          quantity: Number(formatsRaw.sponsored_brand.quantity.value),
          id: formatsRaw.sponsored_brand?.id
            ? formatsRaw.sponsored_brand.id
            : undefined
        }

        return [...acc, newValue]
      }

      case key === 'banner': {
        const { sizes } = formatsRaw.banner

        if (!sizes) {
          return acc
        }

        const formattedSizes: PlacementFormatCreate[] = Object.entries(
          sizes
        ).map(([size, value]: SizesMapObjectEntries) => ({
          type: key,
          quantity: Number(value.quantity.value),
          size: size,
          id: value?.id ? value.id : undefined
        }))

        return [...acc, ...formattedSizes]
      }

      default:
        return acc
    }
  }

  const formats: PlacementPatchBodyFormat | null = formatsRaw
    ? Object.keys(formatsRaw).reduce(handleReduce, [])
    : null

  if (formats) formattedData.formats = formats

  return formattedData
}
