import React, { useCallback, useEffect } from 'react'
import { FiUser } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ButtonNavLink } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import Panel from '~/components/Panel'
import { useAlert } from '~/hooks/useAlert'
import { useAppSelector } from '~/store/hooks'
import { getCustomer } from '~/store/modules/customers/actions'

import FormGenerateShoppingCart from './FormGenerateShoppingCart'

import * as S from './styles'

const ShoppingCartGenerate = () => {
  const dispatch = useDispatch()

  const { customerId: id } = useParams()

  const {
    getCustomersLoading: loading,
    error,
    customer
  } = useAppSelector(state => state.customers)

  const [AlertOnError] = useAlert(error)

  const loadCustomer = useCallback(() => {
    dispatch(getCustomer(id))
  }, [dispatch, id])

  useEffect(() => {
    if (id !== customer?.id) {
      loadCustomer()
    }
  }, [customer?.id, id, loadCustomer])

  return (
    <S.Container>
      {/* Meta <title> */}
      <PageTitle title="Carrinhos > Gerador de carrinhos" />

      <HeaderPages title="Gerador de carrinhos" />

      <AlertOnError />

      <Loading status={loading}>Carregando informações do cliente</Loading>

      {customer && (
        <>
          <Panel>
            <header className="customerHeader">
              <div>
                <FiUser /> Gerando carrinho para:{' '}
                <strong>{customer?.name || 'Sem nome definido'}</strong>
              </div>

              <aside>
                <ButtonNavLink
                  text="Ir para a página do cliente"
                  to={`/customers/edit/${customer?.id}`}
                  size="small"
                  style={{ marginLeft: 'auto' }}
                />
              </aside>
            </header>
          </Panel>

          <FormGenerateShoppingCart />
        </>
      )}
    </S.Container>
  )
}

export default ShoppingCartGenerate
