import React from 'react';

import PropTypes from 'prop-types';

import { Tag } from './styles';

const DestinationsTag = ({ total }) => {
  return (
    <Tag>
      {total} {total > 1 ? 'destinatários' : 'destinatário'}
    </Tag>
  );
};

DestinationsTag.defaultProps = {
  total: null,
};

DestinationsTag.propTypes = {
  total: PropTypes.number,
};

export default DestinationsTag;
