import L from 'leaflet';

import blackLeafletMarkerIconImg from '~/assets/img/map-markers/marker-icon-2x-black.png';
import blueLeafletMarkerIconImg from '~/assets/img/map-markers/marker-icon-2x-blue.png';
import goldLeafletMarkerIconImg from '~/assets/img/map-markers/marker-icon-2x-gold.png';
import greenLeafletMarkerIconImg from '~/assets/img/map-markers/marker-icon-2x-green.png';
import greyLeafletMarkerIconImg from '~/assets/img/map-markers/marker-icon-2x-grey.png';
import orangeLeafletMarkerIconImg from '~/assets/img/map-markers/marker-icon-2x-orange.png';
import redLeafletMarkerIconImg from '~/assets/img/map-markers/marker-icon-2x-red.png';
import violetLeafletMarkerIconImg from '~/assets/img/map-markers/marker-icon-2x-violet.png';
import yellowLeafletMarkerIconImg from '~/assets/img/map-markers/marker-icon-2x-yellow.png';
import markerShadow from '~/assets/img/map-markers/marker-shadow.png';

export const blueLeafletMarkerIcon = new L.Icon({
  iconUrl: blueLeafletMarkerIconImg,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const goldLeafletMarkerIcon = new L.Icon({
  iconUrl: goldLeafletMarkerIconImg,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const redLeafletMarkerIcon = new L.Icon({
  iconUrl: redLeafletMarkerIconImg,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const greenLeafletMarkerIcon = new L.Icon({
  iconUrl: greenLeafletMarkerIconImg,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const orangeLeafletMarkerIcon = new L.Icon({
  iconUrl: orangeLeafletMarkerIconImg,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const yellowLeafletMarkerIcon = new L.Icon({
  iconUrl: yellowLeafletMarkerIconImg,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const violetLeafletMarkerIcon = new L.Icon({
  iconUrl: violetLeafletMarkerIconImg,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const greyLeafletMarkerIcon = new L.Icon({
  iconUrl: greyLeafletMarkerIconImg,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const blackLeafletMarkerIcon = new L.Icon({
  iconUrl: blackLeafletMarkerIconImg,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
