import styled, { css } from 'styled-components'

export const Value = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > p {
      margin-bottom: initial;
      margin-right: 0.25rem;
    }

    .alert {
      color: ${theme.colors.danger};
      margin-bottom: initial;
      margin-right: 0.25rem;
    }
  `}
`

type SubtitleSmallProps = {
  isDanger?: boolean
}

export const SubtitleSmall = styled.small<SubtitleSmallProps>`
  ${({ theme, isDanger = true }) => css`
    display: inline-block;
    line-height: 1.25;
    color: ${isDanger ? theme.colors.danger : theme.colors.info};
  `}
`

export const ButtonCPCDefault = styled.div`
  margin-top: 0.25rem;

  .Badge_UI_Component {
    margin: 0;
  }

  button {
    padding: initial;

    .text {
      white-space: nowrap;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

export const WrapperInput = styled.div`
  ${({ theme }) => css`
    margin-bottom: 0.25rem;
    /* width: 189px; */

    > div {
      width: 116px;
    }

    .tooltip-icon {
      color: ${theme.colors.danger};
    }
  `}
`

export const WrapperButtons = styled.div`
  display: flex;

  .button-cancel {
    margin-right: 0.25rem;
    padding: 0px 0.4rem;
  }
`
