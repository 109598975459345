import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons/'

/**
 *
 * @param {function} onPreviousStep - Função apra lidar com o volta de passo. Se não for passado, o botão não será exibido.
 * @param {boolean} loading - Gerencia o spinner do botão
 * @param {boolean} formIsValid - Gerencia o estado do botão de acordo com os campos obrigatórios
 * @param {boolean} edit - Valor para saber se está no modo edit para alterar o texto do botão de submit
 * @param {boolean} isLastStep - Valor para saber se está no último passo para alterar o botão de submit
 *
 */

function FormFooter({
  onPreviousStep,
  loading,
  formIsValid,
  edit,
  isLastStep
}) {
  const textButton = useMemo(
    () => (edit || isLastStep ? 'Salvar' : 'Próximo'),
    [edit]
  )

  return (
    <div className="form-default__buttons">
      {onPreviousStep && (
        <Button
          type="button"
          text="Voltar"
          template="light"
          onClick={onPreviousStep}
          disabled={loading}
          loading={loading}
          size="large"
          customWidth="8rem"
        />
      )}

      <Button
        type="submit"
        text={textButton}
        loading={loading}
        disabled={!formIsValid}
        template="primary"
        keybind="enter"
        size="large"
      />
    </div>
  )
}

export default FormFooter

FormFooter.propTypes = {
  loading: PropTypes.bool,
  onPreviousStep: PropTypes.func,
  formIsValid: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  isLastStep: PropTypes.bool
}

FormFooter.defaultProps = {
  loading: false,
  onPreviousStep: null,
  edit: false,
  isLastStep: false
}
