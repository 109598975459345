import { useMemo } from 'react'
import { FiTrendingUp } from 'react-icons/fi'

import CardIcon from '~/components/cards/card-icon'
import { formatDateToString } from '~/helpers'
import i18n from '~/i18n'
import { useAppSelector } from '~/store/hooks'

const Card = ({
  title,
  value,
  loading,
  icon,
  showInterval = true,
  isRelativeInterval = true
}) => {
  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const selectedIinterval = useMemo(() => {
    const start = formatDateToString(
      startDate,
      i18n.t('common:patterns.dateMonth')
    )
    const end = formatDateToString(endDate, i18n.t('common:patterns.dateMonth'))

    const interval = `${start} - ${end}`

    return interval
  }, [endDate, startDate])

  return (
    <CardIcon
      title={title}
      value={value}
      loading={loading}
      icon={icon || <FiTrendingUp className="text-primary" />}
      {...(showInterval
        ? { badge: isRelativeInterval ? selectedIinterval : i18n.t('rm:Today') }
        : {})}
    />
  )
}

export default Card
