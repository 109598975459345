import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FiAlertCircle, FiCheckCircle, FiXCircle } from 'react-icons/fi'
import NumberFormat from 'react-number-format'
import { Spinner } from 'reactstrap'

import { useField } from '@unform/core'
import PropTypes from 'prop-types'

import * as S from './styles'

/**
 *
 * @example
 * <InputMask
 *   name="address_postal_code"
 *   onBlur={handleBlurCep}
 *   loading={loadingCep}
 *   format="#####-###"
 *   placeholder="Insira o cep (12345-567)"
 * />
 *
 * @param {string} name Recebe o name para o input
 * @param {ReactElement | string} icon Ícone que será exbido a esquerda do input.
 * @param {boolean} loading Exibe um loader ao lado do input
 * @param {function} onBlur Extendendo onBlur
 * @param {string} format Formato que terá o retorno do input.
 * @param {string} mask Máscara para guia dos espaços vazios.
 *
 */

const InputMask = ({
  name,
  icon: Icon,
  format,
  mask,
  onBlur,
  loading,
  showBadgeIsvalid = false,
  showBadgeIsInvalid = false,
  disabled = false,
  showWarningOnRequired = true,
  iconRight,
  ...rest
}) => {
  const inputRef = useRef(null)

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [valueInput, setValueInput] = useState('')

  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    if (onBlur) {
      onBlur({
        value: inputRef.current.state.value,
        name: inputRef.current.state.name,
        clearError,
        setIsFilled
      })
    }

    setIsFilled(!!inputRef.current?.state.value)
  }, [clearError, onBlur])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'state.value'
    })
  }, [fieldName, registerField])

  /**
   * Handle initial value
   */
  const defaultValueIsNull = defaultValue === null

  const defaultValueIsUndefined = typeof defaultValue === 'undefined'

  useEffect(() => {
    if (!defaultValueIsNull && !defaultValueIsUndefined) {
      setValueInput(defaultValue)
      setIsFilled(true)
    }
  }, [defaultValue, defaultValueIsNull, defaultValueIsUndefined])

  return (
    <>
      <S.Container
        isFocused={isFocused}
        isFilled={isFilled}
        isErrored={!!error}
        isDisabled={disabled}
        showWarning={!isFilled && showWarningOnRequired && rest?.required}
      >
        {Icon && <Icon />}

        <NumberFormat
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          name={fieldName}
          aria-label={fieldName}
          mask={mask || '_'}
          defaultValue={defaultValue === null ? '' : defaultValue}
          ref={inputRef}
          disabled={disabled}
          {...(format ? { format } : {})}
          {...rest}
        />

        {showBadgeIsvalid && (
          <S.IconWrapper className="success">
            <FiCheckCircle />
          </S.IconWrapper>
        )}

        {showBadgeIsInvalid && (
          <S.IconWrapper className="error">
            <FiXCircle />
          </S.IconWrapper>
        )}

        {loading && <Spinner type="grow" size="sm" color="secondary" />}

        {iconRight && <div className="right-icon">{iconRight}</div>}
      </S.Container>
      {error && <S.MessageError>{error}</S.MessageError>}
    </>
  )
}

export default InputMask

InputMask.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  mask: PropTypes.string,
  onBlur: PropTypes.func,
  showBadgeIsvalid: PropTypes.bool,
  showBadgeIsInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  iconRight: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  showWarningOnRequired: PropTypes.bool
}

InputMask.defaultProps = {
  icon: null,
  loading: false,
  mask: '_',
  onBlur: null,
  showBadgeIsvalid: false,
  showBadgeIsInvalid: false,
  disabled: false,
  iconRight: null,
  format: null,
  showWarningOnRequired: true
}
