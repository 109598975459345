import React, { useMemo, useEffect } from 'react'
import { FiX } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux'

import { FilterSearch } from '~/components/FilterSearch'
import Loading from '~/components/Loading'
import { togglePanelTemplates } from '~/modules/chat/store/actions'
import { CHAT_TEMPLATES_REDUX_QUERY } from '~/modules/chat/store/constants'
import { listTemplates } from '~/store/modules/chatTemplates/actions'

import Template from '../Template'

import { Container } from './styles'

const TemplatePanel = () => {
  const { templatesPanelOpen } = useSelector(({ chats }) => chats)
  const { query } = useSelector(({ filters }) => filters)
  const filteredQuery = useMemo(
    () => query?.queryChatTemplates || null,
    [query]
  )

  const dispatch = useDispatch()
  const { loading, templates } = useSelector(
    ({ chatTemplates }) => chatTemplates
  )

  useEffect(() => {
    if (!templates) {
      dispatch(listTemplates())
    }
  }, [templates, dispatch])

  const hasTemplates = useMemo(() => !!templates?.length, [templates])

  const handlePanelTemplate = () => dispatch(togglePanelTemplates())

  const filteredTemplates = useMemo(
    () =>
      hasTemplates &&
      templates.filter(item => {
        const escaped = filteredQuery?.replace(
          /[-[\]{}()*+?.,\\^$|#\s]/g,
          '\\$&'
        )

        const condition = new RegExp(escaped, 'gi')

        return filteredQuery
          ? condition.test(item.title) || condition.test(item.message)
          : true
      }),
    [templates, filteredQuery, hasTemplates]
  )

  const hasTemplatesOnSearch = useMemo(
    () => !!filteredTemplates?.length,
    [filteredTemplates?.length]
  )

  return (
    <Container isOpen={templatesPanelOpen}>
      <header>
        <strong className="title">Mensagens prontas</strong>
        <p className="description">
          Escolha abaixo o modelo de mensagem que deseja usar. Você poderá
          editar antes de enviar.
        </p>

        <button
          type="button"
          onClick={handlePanelTemplate}
          className="icon__close"
        >
          <FiX />
        </button>

        <FilterSearch
          live
          placeholder="Buscar templates"
          autoComplete="off"
          keyState={CHAT_TEMPLATES_REDUX_QUERY}
          name={CHAT_TEMPLATES_REDUX_QUERY}
        />
      </header>

      <section>
        <Loading status={loading}>Carregando mensagens prontas...</Loading>

        <div className="wrapMessages">
          {!loading &&
            !hasTemplates &&
            'Nenhuma mensagem pronta está cadastrada no momento.'}
          {!loading &&
            hasTemplates &&
            !hasTemplatesOnSearch &&
            'Nenhuma mensagem pronta foi encontrada.'}
          {hasTemplates &&
            filteredTemplates.map(template => (
              <Template key={template.templateId} data={template} />
            ))}{' '}
        </div>
      </section>
    </Container>
  )
}

export default TemplatePanel
