import axios from 'axios'

/**
 *
 * @endpoint /search/orders
 * @exports getOrdersList  Busca todos os clientes da loja
 * @param {string} queryString ?query=<campo de busca>&page=<opcional>&quantity=<tam da página, opcional>
 *
 * @endpoint /api/order/full-order/
 * @exports getOrderData
 *
 * @endpoint /api/order/
 * @exports updateOrderData
 * @exports deleteOrderService
 *
 * @endpoint /api/orderitem
 * @exports updateOrderItemService
 * @exports deleteOrderItemService
 *
 * @endpoint /api/data-service/order
 * @exports getCardsData
 * @exports getOrdersPerDayData
 * @exports getTopChannel
 * @exports getOrderTopProducts
 * @exports getTopPayments
 *
 */

export const getOrdersList = queryString =>
  axios.get(`/search/orders?${queryString}`)

export const getOrdersListByStatus = queryString =>
  axios.get(`/search/orders-by-status?${queryString}`)

/**
 *
 * API Order
 */

export const getOrderData = ({ id }) => axios.get(`/api/order/full-order/${id}`)

export const updateOrderData = data => axios.post(`/api/order/`, data)

export const updateOrderStatusService = ({ id, data }) =>
  axios.post(`/api/picking/status/${id}`, data)

export const deleteOrderService = id => axios.delete(`/api/order/${id}`)

export const cancelOrder = ({ orderId, data }) =>
  axios.post(`/api/order/cancel/${orderId}`, data)

/**
 *
 * Request
 * {
 *	"id": "de734e80-01e5-11ec-83c7-2b5ff32c7cbc",
 *	"total_discount": 10
 * }
 */
export const updateOrderDiscountService = data =>
  axios.put(`/api/order/discount`, data)

/**
 * Order item
 */

export const updateOrderItemService = ({ id, data }) =>
  axios.post(`/api/order/items/${id}`, data)

export const deleteOrderItemService = id => axios.delete(`/api/orderitem/${id}`)

/**
 * Payment order
 */

export const cancelPaymentService = ({ orderId, body }) =>
  axios.put(`/api/payment-hub/payment/refund/${orderId}`, body)

/**
 * Approve fraud analysis
 */

export const approveFraudAnalysisService = ({ orderId, body }) =>
  axios.put(`/api/payment-hub/fraud-analyze/${orderId}/status`, body)

/**
 * Request external delivery
 */

export const requestExternalDeliveryService = ({ body }) =>
  axios.post(`/api/delivery-hub/create`, body)

export const requestHandlerError = ({ order_id }) =>
  axios.get(`/api/delivery-hub/delivery-orders?order_id=${order_id}`)

/**
 * Request external delivery
 */

export const cancelExternalDeliveryService = ({ body }) =>
  axios.post(`/api/delivery-hub/cancel`, body)

/**
 *
 * Data service
 */

export const getCardsData = queryString =>
  axios.get(`/api/data-service/order/cards?${queryString || ''}`)

export const getOrdersPerDayData = queryString =>
  axios.get(`/api/data-service/order/status-per-day?${queryString || ''}`)

export const getTopChannel = queryString =>
  axios.get(`/api/data-service/order/top-channels?${queryString || ''}`)

export const getOrderTopProducts = queryString =>
  axios.get(`/api/data-service/order/top-products?${queryString || ''}`)

export const getTopPayments = queryString =>
  axios.get(`/api/data-service/order/top-payments?${queryString || ''}`)

/**
 *
 * Restart picking
 */

export const restartPicking = id => axios.post(`/api/picking/restart/${id}`)

export const editFullNameRequest = ({ id, body }) =>
  axios.post(`/api/order/address/${id}`, body)
