import { t } from 'i18next'
import ThumbZoomable from '~/components/ThumbZoomable'
import { formatMoney } from '~/helpers'

import { RightArrow } from './commons'

const Costs = ({ prevValue, newValue, entity, note }: DefaultChangeProps) => {
  const getAdText = (value: string | boolean | null) =>
    value === null ? <i>{t('rm:campaign.logs.cost.title')}</i> : formatMoney(value)

  const prevText =
    entity === 'ad' ? getAdText(prevValue) : formatMoney(prevValue)

  const nextText = entity === 'ad' ? getAdText(newValue) : formatMoney(newValue)

  const parsedNote = Array.isArray(note) ? note?.[0] : note

  const campaignType = parsedNote?.media_url ? 'banner' : 'product'

  return (
    <>
      <span className="prevValue">{prevText}</span>

      <RightArrow />

      <span className="newValue">{nextText}</span>

      {note && entity === 'ad' && campaignType === 'banner' && (
        <p className="notes inline">
          <ThumbZoomable
            url={parsedNote.media_url}
            title={parsedNote.alt_text}
          />
          <small className="adSize">
            <strong>{t('rm:Format')}:</strong> {parsedNote.ad_size}
          </small>
        </p>
      )}

      {note && entity === 'ad' && campaignType === 'product' && (
        <p className="notes">
          {parsedNote?.product_name && (
            <>
              <small className="productName">
                <strong>{parsedNote.product_name}</strong>
              </small>
              <br />
              <small className="productSKU">
                <strong>SKU:</strong> {parsedNote.sku}
              </small>
            </>
          )}
        </p>
      )}
    </>
  )
}

export default Costs
