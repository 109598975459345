import { NavLink } from 'react-router-dom'

import styled from 'styled-components'

export const WrapperLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 12rem;
`

export const Container = styled.div`
  line-height: 1.1125;
  font-size: 0.75rem;

  .title {
    word-break: break-all;
    font-weight: 600;
  }
`
