import { FiX } from 'react-icons/fi'
import { RiInformationFill } from 'react-icons/ri'

import * as S from './styles'

/**
 * **Componente de Alerta**
 *
 * @param { string }  title Titulo do alerta
 * @param { string } text Texto com o conteudo
 * @param { function } handleClosedToast Função que permite a logica de fechar o componente
 * @param { string } template Define a cor base do alerta
 * @param { boolean } colorBorder Indica que a borde deve seguir a cor do template
 *
 * @example <AlertDismissibleNew />
 *
 */

const AlertDismissibleNew = ({
  title,
  text,
  handleClosedToast,
  template,
  colorBorder = false
}: AlertDismissibleNewProps) => {
  return (
    <S.Shape data-template={template} data-border={colorBorder}>
      {handleClosedToast && (
        <div className="position-absolute  closed">
          <button type="button" onClick={handleClosedToast} className="p-2">
            <FiX className="icon-closed" />
          </button>
        </div>
      )}

      <div className="info-text text-center">
        <RiInformationFill className="icon" />
        <span className="title">{title}</span>
        <span className="text">{text}</span>
      </div>
    </S.Shape>
  )
}

export default AlertDismissibleNew
