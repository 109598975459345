import { HiOutlineUserCircle } from 'react-icons/hi2'
import { RiArrowUpDownFill } from 'react-icons/ri'
import { useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import { Badge } from '~/components/UI/Badge'

import * as S from '../styles'

export const ConfirmationStep = ({
  selectedAttendent,
  handleActionCancel
}: PropsConfirmationStep) => {
  const {
    chats: { listAttendantData, agentTransferLoading, selectedChat }
  } = useSelector(state => state)

  const newAttendent = listAttendantData.find(
    attendent => attendent.user_id === selectedAttendent
  )

  return (
    <S.ContainerConfirmationStep>
      <div className="subtitleStep">
        <S.SubtitleText>
          Tem certeza que deseja transferir esse atendimento?
        </S.SubtitleText>
      </div>
      <div>
        <S.SubtitleText>De:</S.SubtitleText>
      </div>
      <S.WrapperBadgeStep>
        <Badge template="primary" iconLeft={<HiOutlineUserCircle />}>
          {selectedChat?.session?.userName
            ? selectedChat?.session?.userName
            : 'Sem atendente'}
        </Badge>
      </S.WrapperBadgeStep>
      <S.IconSwap>
        <RiArrowUpDownFill />
      </S.IconSwap>
      <div>
        <S.SubtitleText>Para:</S.SubtitleText>
      </div>
      <S.WrapperBadgeStep>
        <Badge template="primary" iconLeft={<HiOutlineUserCircle />}>
          {newAttendent.name}
        </Badge>
      </S.WrapperBadgeStep>
      <S.WrapperButtonsOnSubmit>
        <Button
          type="submit"
          text="Salvar"
          loading={agentTransferLoading}
          textLoading="Salvando..."
        />
        <Button
          text="Cancelar"
          template="transparentDark"
          onClick={handleActionCancel}
        />
      </S.WrapperButtonsOnSubmit>
    </S.ContainerConfirmationStep>
  )
}
