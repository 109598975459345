import { types } from '../constants'

const index = {
  pending: `${types.list}_PENDING`,
  fulfilled: `${types.list}_FULFILLED`,
  rejected: `${types.list}_REJECTED`
}

export const list = {
  [index.pending]: ({ draft }: ActionData) => {
    draft.industry.advertiserPublisher.list.loading = true
    draft.industry.advertiserPublisher.list.error = null
  },
  [index.fulfilled]: ({
    draft,
    action
  }: ActionData<GetAdvertiserPublishersResponse>) => {
    draft.industry.advertiserPublisher.list.data = action.payload

    draft.industry.advertiserPublisher.list.loading = false
    draft.industry.advertiserPublisher.list.error = null
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft.industry.advertiserPublisher.list.loading = false
    draft.industry.advertiserPublisher.list.error = action.payload
  }
}
