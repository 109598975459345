import { t } from 'i18next'

import { formatDateToString, formatMoney } from '~/helpers'
import {
  labelSubtypes,
  labelTypes
} from '~/modules/retailMedia/store/common/constants'

import {
  ColumnMainAndDetail,
  ValueTransaction
} from '../WalletTableTransaction/styles'

export const schema: TableSchema<CheckingAccountTransaction> = {
  date: {
    title: t('rm:wallet.movimentations.table.column.title.date'),
    render: rowData => (
      <strong>{formatDateToString(rowData?.created_at)}</strong>
    )
  },
  value: {
    title: t('rm:wallet.movimentations.table.column.title.value'),
    render: rowData => (
      <ValueTransaction transactionType={rowData?.transaction_type}>
        <strong className="amount">
          {formatMoney(rowData?.amount, false, 'R$ 0,00')}
        </strong>
      </ValueTransaction>
    )
  },
  type: {
    title: t('rm:wallet.movimentations.table.column.title.type'),
    render: rowData => (
      <ColumnMainAndDetail>
        <span className="mainInfo">
          {labelTypes?.[rowData?.transaction_type] || rowData?.transaction_type}
        </span>{' '}
        <small className="detail">
          {labelSubtypes?.[rowData?.transaction_subtype]}
        </small>
      </ColumnMainAndDetail>
    )
  }
}
