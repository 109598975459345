import styled, { css } from 'styled-components'

import { FormGroup } from '~/components/unform'

export const Group = styled(FormGroup)`
  ${({ theme }) => css`
    & + & {
      margin-top: ${theme.spacings.medium};
    }

    > input {
      width: 100%;
    }

    /* &:last-child:not(:first-child) { */
    &:not(:first-child) {
      padding-top: ${theme.spacings.xsmall};
      border-top: 1px solid ${theme.colors.inative};
    }
  `}
`

export const WrapperAlerts = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};

    .alertInfo + .alertInfo {
      margin-top: ${theme.spacings.xxxsmall};
    }
  `}
`
