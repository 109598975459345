import DebugRaw from '~/components/Debug'

const Debug = ({
  prevValue,
  newValue,
  fieldName,
  note
}: DefaultChangeProps) => {
  const values = [
    {
      label: 'field_name',
      value: fieldName
    },
    {
      label: 'prev_value',
      value: JSON.stringify(prevValue)
    },
    {
      label: 'new_value',
      value: JSON.stringify(newValue)
    },
    {
      label: 'note',
      value: JSON.stringify(note)
    }
  ]

  return (
    <DebugRaw values={values} isFixed={false} style={{ flex: '1 1 100%' }} />
  )
}

export default Debug
