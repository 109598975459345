import React, { useCallback, useRef } from 'react'

import { Form } from '@unform/web'

import Alert from '~/components/Alert'
import { Button } from '~/components/Buttons'
import { FormGroup, Input, Label } from '~/components/unform'

import { useLogisticSlots } from '../../useLogisticSlots'

import * as S from './styles'

/**
 * Esse componente renderiza o formulário para cadastro de datas
 */
const DateForm = () => {
  const { isDateMode, formDateOpen, handleFormDate, handleAddDates, error } =
    useLogisticSlots()

  const formRef = useRef(null)

  const handleSubmit = useCallback(
    data => {
      handleAddDates(data)
    },
    [handleAddDates]
  )

  const handleCloseForm = useCallback(() => {
    handleFormDate()
  }, [handleFormDate])

  return isDateMode && formDateOpen ? (
    <S.Container formIsOpen={formDateOpen} position="top">
      <Form ref={formRef} onSubmit={handleSubmit}>
        <header>
          <h3>Adicionar data</h3>
        </header>

        <fieldset className="fields">
          <FormGroup>
            <Label htmlFor="new_date">Defina uma data futura</Label>
            <Input
              name="new_date"
              placeholder="Insira uma data"
              type="date"
              required
            />
          </FormGroup>

          <FormGroup className="description">
            <Label htmlFor="description">
              Descrição para a exceção (opcional)
            </Label>
            <Input name="description" placeholder="Insira uma descrição" />
          </FormGroup>

          <Button text="Adicionar" type="submit" template="success" />

          <Button
            text="Cancelar"
            onClick={handleCloseForm}
            template="transparentDanger"
          />
        </fieldset>

        {error?.key === 'createDate' && <Alert>{error.message}</Alert>}
      </Form>
    </S.Container>
  ) : (
    <></>
  )
}

export default DateForm
