import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: inline-flex;

  margin-left: 0.25rem;
`

const positions = {
  'center-top': () => css`
    bottom: 1.4rem;
    left: 50%;
    transform: translate(-50%, 0);

    &::after {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
    }
  `,
  'center-bottom': () => css`
    top: 1.4rem;
    left: 50%;
    transform: translate(-50%, 0);

    &::after {
      bottom: 100%;
      right: 50%;
      transform: rotate(180deg) translate(-50%, 0);
    }
  `,
  top: () => css`
    bottom: 1.6rem;
    left: -100%;
  `,
  bottom: () => css`
    top: 80%;
    left: -100%;
  `,
  right: () => css`
    bottom: 50%;
    left: 100%;
    transform: translate(2%, 50%);

    &::after {
      bottom: 25%;
      left: -9px;
      transform: rotate(90deg);
    }
  `,
  left: () => css`
    bottom: 50%;
    right: 100%;
    transform: translate(-1%, 50%);

    &::after {
      bottom: 25%;
      right: -10px;
      transform: rotate(270deg);
    }
  `,
  'right-top': () => css`
    bottom: 1.4rem;
    left: 0;

    &::after {
      left: 3px;
      top: 100%;
    }
  `,
  'right-bottom': () => css`
    top: 1.4rem;
    left: -10%;

    &::after {
      bottom: 100%;
      left: 0.25rem;
      transform: rotate(180deg);
    }
  `,
  'left-top': () => css`
    bottom: 1.4rem;
    right: 0;

    &::after {
      bottom: 0;
      top: 100%;
    }
  `,
  'left-bottom': () => css`
    top: 1.8rem;
    right: -10%;
    &::after {
      bottom: 100%;
      right: 10%;
      top: -50%;
      transform: rotate(180deg) translate(5%, 0);
    }
  `
}

interface Props {
  position:
    | 'right'
    | 'bottom'
    | 'top'
    | 'left'
    | 'right-top'
    | 'right-bottom'
    | 'left-top'
    | 'left-bottom'
    | 'center-top'
    | 'center-bottom'
}
export const TooltipContent = styled.div<Props>`
  ${({ position, theme }) => css`
    position: absolute;
    z-index: 9999;

    /* display: none; */
    /* transform: translateY(3px); */
    transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out;
    /* max-width: 0; */
    max-height: 0;
    width: auto;
    overflow: hidden;
    opacity: 0;

    white-space: nowrap;
    color: ${theme.colors.white};
    line-height: 1.125;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: normal;
    border-radius: 0.25rem;
    background: ${theme.colors.info};

    &::after {
      content: '';
      position: absolute;
      /* margin-left: -5px; */
      border-width: 5px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.info} transparent transparent
        transparent;
    }

    ${Container}:hover & {
      /* ${Container} & { */
      /* display: block; */
      /* min-width: 237px; */
      overflow: visible;
      max-width: 90vw;
      max-height: 100rem;
      /* transform: translateY(0); */
      padding: 0.5rem 0.75rem;
      /* word-break: break-all; */
      opacity: 1;

      box-shadow: 0px 12px 1rem -10px rgba(110, 113, 145, 0.8),
        0px 12px 1rem -10px rgba(110, 113, 145, 0.6);
    }

    ${positions[position]}
  `}
`

export const TriggerContent = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`

export const Text = styled.div``
