import React, { useMemo } from 'react'
import { FiHelpCircle } from 'react-icons/fi'
// import { injectIntl } from 'react-intl';

import PropTypes from 'prop-types'

import Panel from '~/components/Panel'
import Tooltip from '~/components/Tooltip'
import { Label, FormGroup, Input, InputMask } from '~/components/unform'

const GeneralFields = ({ activeType }) => {
  const showFields = useMemo(
    () => activeType !== 'customer_service_center',
    [activeType]
  )

  return (
    <fieldset>
      <Panel title="Dados gerais" className="form-grid" isCollapsible>
        <FormGroup className="span2">
          <Label htmlFor="name">Nome do local</Label>
          <Input id="name" name="name" placeholder="Insira o nome do local" />
        </FormGroup>

        {showFields && (
          <FormGroup className="span2">
            <Label htmlFor="company_document_id" isRequired>
              CNPJ
            </Label>
            <InputMask
              id="company_document_id"
              name="company_document_id"
              required
              format="########/####-##"
              mask="_"
              placeholder="Insira o CNPJ da local"
            />
          </FormGroup>
        )}

        <FormGroup className="span2">
          <Label htmlFor="type">
            ID externo
            <Tooltip
              icon={<FiHelpCircle />}
              text="ID usado para integração entre a plataforma Newtail e outros sistemas, como um ERP, por exemplo."
            />
          </Label>
          <Input
            id="external_id"
            name="external_id"
            placeholder="Insira o ID externo"
          />
        </FormGroup>
      </Panel>
    </fieldset>
  )
}

export default GeneralFields

GeneralFields.propTypes = {
  activeType: PropTypes.string
}

GeneralFields.defaultProps = {
  activeType: null
}
