import { rgba, shade } from 'polished'
import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const warningOutlinedButtonTemplate = ({ theme }: ButtonTemplatesProps) => css`
  background: ${rgba(theme.warning, 0.05)};
  color: var(--warning);

  border: 1px solid var(--warning);

  &:not(:disabled) {
    &:hover {
      color: ${theme.white};
      border-color: ${shade(0.15, theme.warning)};
      background: ${theme.warning};
    }
  }
`

export default warningOutlinedButtonTemplate
