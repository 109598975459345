/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';

import TemplateOption from '~/modules/messagingCampaigns/components/TemplateOption';

import Status from './TemplateListColumns/Status';

import { TableLink } from './styles';

export const schema = {
  template: {
    title: 'Template',
    render: ({ messages, title, _id, media }) => {
      const [message] = messages;
      const { text, buttons } = message;

      const data = {
        buttons,
        label: title,
        message: text,
        hasImage: media?.type === 'image',
        hasDocument: media?.type === 'document',
      };

      return (
        <TableLink>
          <Link
            to={`/messaging-campaigns/templates/view/${_id}`}
            title="Ver template"
          >
            <TemplateOption data={data} isList />
          </Link>
        </TableLink>
      );
    },
  },
  status: {
    render: obj => <Status data={obj.status} />,
    title: 'Status',
    className: `col__status`,
  },
};
