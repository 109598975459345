import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { FiDownload } from 'react-icons/fi'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'

import { useOrderTable } from '../../_hooks/useOrderBoard'
import Item from '../Item'

import * as S from './styles'

const StatusPanel = ({ status, isDragging }) => {
  const [loading, setLoading] = useState(true)
  const [laodingMore, setLoadingMore] = useState(false)
  const [initialOrders, setInitialOrders] = useState([])
  const [orders, setOrders] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [loadingPanel, setLoadingPanel] = useState(false)
  const isLastPage = useMemo(() => page >= totalPages, [page, totalPages])

  const {
    initialData,
    getMoreOrders,
    resultDragging,
    movedItem,
    setMovedItem,
    resultUpdateStatus,
    setResultUpdateStatus
  } = useOrderTable()

  useEffect(() => {
    setInitialOrders(initialData?.[status.type]?.data || [])

    if (initialOrders !== initialData?.[status.type]?.data) {
      setOrders(initialData?.[status.type]?.data || [])
      setTotal(initialData?.[status.type]?.total || 0)
      setTotalPages(initialData?.[status.type]?.pages || 0)
    }

    if (initialData?.[status.type]?.data) {
      setLoading(false)
    }
  }, [initialData, initialOrders, status.type])

  /**
   * Gerenciando a paginação
   */

  const handleLoadMore = useCallback(async () => {
    const newPage = page + 1
    setLoadingMore(true)

    try {
      const newsOrders = await getMoreOrders({
        status: status.type,
        page: newPage
      })

      setOrders(orders => [...orders, ...newsOrders.data])

      setPage(newPage)
      setLoadingMore(false)
    } catch (error) {
      console.error(error)
      setLoadingMore(false)
    }
  }, [getMoreOrders, page, status.type])

  const hasOrders = useMemo(() => !!orders.length, [orders])

  const InnerList = useMemo(
    () =>
      hasOrders &&
      orders.map((item, index) => (
        <Item key={item.id} data={item} index={index} />
      )),
    [hasOrders, orders]
  )

  const draggedToHere = useMemo(
    () => resultDragging?.destination?.droppableId === status.type,
    [resultDragging, status.type]
  )

  const draggedFromHere = useMemo(
    () => resultDragging?.source?.droppableId === status.type,
    [resultDragging, status.type]
  )

  useEffect(() => {
    if (draggedToHere || draggedFromHere) setLoadingPanel(true)
  }, [draggedToHere, draggedFromHere])

  useEffect(() => {
    if (draggedFromHere && !movedItem) {
      const findIndex = orders.findIndex(
        item => item.id === resultDragging.draggableId
      )

      setMovedItem({
        status: resultDragging?.destination?.droppableId,
        fromStatus: resultDragging?.source?.droppableId,
        order: { ...orders[findIndex], isNew: true }
      })

      const filteredOrders = orders.map(item =>
        item.id === resultDragging.draggableId
          ? { ...item, wasMoved: true }
          : { ...item }
      )

      setOrders(filteredOrders)
    }
  }, [draggedFromHere, orders, resultDragging, setMovedItem, movedItem])

  useEffect(() => {
    if (movedItem) {
      const alreadyExistOrder =
        orders.findIndex(item => item.tempId === movedItem.order?.id) > -1

      if (
        !alreadyExistOrder &&
        movedItem.status === status.type &&
        !draggedFromHere
      ) {
        setOrders(currentStatus => [
          {
            ...movedItem.order,
            id: 'movedItem',
            tempId: movedItem.order.id,
            wasMoved: false
          },
          ...currentStatus
        ])
      }
    }
  }, [movedItem, status.type, draggedFromHere, setMovedItem, orders])

  useEffect(() => {
    if (resultUpdateStatus?.status === 'success') {
      if (resultUpdateStatus?.fromStatus === status.type) {
        const filteredOrders = orders.filter(
          item => item.id !== resultUpdateStatus.orderId
        )

        setTotal(total => total - 1)

        setOrders(filteredOrders)
      }

      if (resultUpdateStatus?.toStatus === status.type) {
        const filteredOrders = orders.map(item => ({
          ...item,
          id: item?.tempId || item.id,
          isNew: false
        }))

        setTotal(total => total + 1)
        setOrders(filteredOrders)
      }

      setLoadingPanel(false)
      setResultUpdateStatus(null)
      setMovedItem(null)
    }

    if (resultUpdateStatus?.status === 'error') {
      if (resultUpdateStatus.fromStatus === status.type) {
        const filteredOrders = orders.map(item =>
          item.wasMoved ? { ...item, wasMoved: false } : item
        )

        setOrders(filteredOrders)
      }

      if (resultUpdateStatus.toStatus === status.type) {
        const filteredOrders = orders.filter(item => !item?.isNew)

        setOrders(filteredOrders)
      }

      setLoadingPanel(false)
      setResultUpdateStatus(null)
      setMovedItem(null)
    }
  }, [
    movedItem,
    status.type,
    draggedFromHere,
    setMovedItem,
    orders,
    resultUpdateStatus,
    setResultUpdateStatus
  ])

  return (
    <Droppable droppableId={status.type} type="ORDERS">
      {(dropProvided, dropSnapshot) => (
        <S.Container
          template={status.template}
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDragging={isDragging}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
        >
          <S.Header template={status.template}>
            <div className="title">{status.label}</div>
            {(loading || loadingPanel) && <Spinner />}
            {!loading && !loadingPanel && hasOrders && (
              <div className="total">{total}</div>
            )}
          </S.Header>

          <S.WrapperList>
            {isDragging && !dropSnapshot.draggingFromThisWith && (
              <S.Placeholder
                className="placeholder"
                isDraggingOver={dropSnapshot.isDraggingOver}
              >
                <div className="icon">
                  <FiDownload />
                </div>
                <div className="text">
                  Solte o pedido aqui para movê-lo para:
                  <div className="title">{status.label}</div>
                </div>
              </S.Placeholder>
            )}

            {dropProvided.placeholder}

            <S.List>
              {(draggedToHere || draggedFromHere) && (
                <li className="list__message">
                  <Spinner /> Salvando alterações...
                </li>
              )}

              {loading && (
                <li className="list__message">
                  <Spinner /> Montando painel...
                </li>
              )}

              {InnerList}

              {!hasOrders && !loading && (
                <li className="list__message">Nenhum pedido.</li>
              )}

              {laodingMore && (
                <li className="list__message">
                  <Spinner /> Carregando mais...
                </li>
              )}

              {!isLastPage && !laodingMore && (
                <li className="load__more">
                  <Button
                    text={`Carregar mais - Página ${page} de ${totalPages} `}
                    size="small"
                    onClick={handleLoadMore}
                    customWidth="100%"
                  />
                </li>
              )}
            </S.List>
          </S.WrapperList>
        </S.Container>
      )}
    </Droppable>
  )
}

export default StatusPanel

StatusPanel.propTypes = {
  isDragging: PropTypes.bool,
  status: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    template: PropTypes.oneOf(['info', 'danger', 'warning', 'success'])
  }).isRequired
}

StatusPanel.defaultProps = {
  isDragging: false
}
