import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { Form } from '@unform/web'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { FormGroup, InputMoney, Label } from '~/components/unform'
import { formatMoney, unformatMoney } from '~/helpers'
import { useAppSelector } from '~/store/hooks'

import { patch } from '../../store/industry/campaign/actions'

// import { Container } from './styles';

const FormEditDailyCost: React.FC = ({ data }: ViewCampaignProps) => {
  const campaignId = data.id
  const initiaData = {
    settings: {
      daily_cost: formatMoney(data.settings?.daily_cost)
    }
  }

  const dispatch = useDispatch()

  const {
    campaign: {
      patch: { loading: loadingSave }
    }
  } = useAppSelector(state => state.retailMedia.industry)

  /**
   * handleSubmit
   */
  type FormData = {
    settings: {
      daily_cost: { value: string }
    }
  }

  const handleSubmit = useCallback(
    (formData: FormData) => {
      const settings = {
        daily_cost: unformatMoney(formData.settings.daily_cost.value)
      }

      dispatch(
        patch({
          body: { settings },
          id: campaignId,
          successFeedbackMsg: t('rm:dailyCost.updated'),
          values: {
            patchKey: 'daily_cost'
          }
        })
      )
    },
    [campaignId, dispatch]
  )

  return (
    <Form onSubmit={handleSubmit} initialData={initiaData}>
      <FormGroup style={{ maxWidth: '30rem' }}>
        <Label
          htmlFor="settings.daily_cost"
          isRequired
          text={t('rm:dailyCost.form.value.text')}
        />

        <InputMoney
          id="settings.daily_cost"
          name="settings.daily_cost"
          required
        />
      </FormGroup>

      <FormGroup className="mt-2">
        <Loading status={loadingSave}>{t('common:actions.saving')}...</Loading>

        <Button
          type="submit"
          template="success"
          loading={loadingSave}
          disabled={loadingSave}
          text={t('common:actions.save')}
          textLoading={t('common:actions.saving')}
        />
      </FormGroup>
    </Form>
  )
}

export default FormEditDailyCost
