import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;

  form {
    display: flex;

    input {
      height: 31px;
    }

    button {
      margin-left: 0.5rem;
      height: 41px;
      display: inline-flex;
    }
  }
`
