import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import Panel from '~/components/Panel';
import { Badge } from '~/components/UI/Badge';
import { Label, Radio } from '~/components/unform';

import CustomersList from '../FormGroups/CustomerList';

import * as S from './styles';

const PromotionCustomers = ({ initialData, isEdit }) => {
  const optionsTypes = [
    {
      id: `customersCheck_all`,
      value: 'all',
      label: 'Todos',
    },
    {
      id: `customersCheck_selected`,
      value: 'selected',
      label: 'Clientes específicos',
      activeChildren: <CustomersList initialData={initialData} />,
    },
  ];

  const labelCustomers = useMemo(() => {
    const length = initialData?.customers?.length || 0;

    switch (true) {
      case length === 1:
        return `${length} cliente selecionado`;

      case length > 1:
        return `${length} clientes selecionados`;

      default:
        return 'Todos os clientes';
    }
  }, [initialData]);

  return (
    <Panel
      title="Elegibilidade do cliente"
      isCollapsible
      initialCollapsibleState={!isEdit}
      headerSideContent={<Badge>{labelCustomers}</Badge>}
    >
      <S.Group>
        <Label
          htmlFor="customersCheck"
          isRequired
          text="Escolha quem terá acesso a esta promoção."
        />

        <Radio options={optionsTypes} name="customersCheck" required />
      </S.Group>
    </Panel>
  );
};

export default PromotionCustomers;

PromotionCustomers.propTypes = {
  initialData: PropTypes.shape({
    customers: PropTypes.arrayOf([PropTypes.string]),
  }),
  isEdit: PropTypes.bool.isRequired,
};

PromotionCustomers.defaultProps = {
  initialData: {},
};
