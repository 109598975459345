import { useEffect, useMemo, useState } from 'react'

import { MasterLoadingKeys } from '~/hooks/useUi'

import * as S from './styles'

type MasterLoadingProps = {
  messages: MasterLoadingKeys[]
}

const MasterLoading = ({ messages = [] }: MasterLoadingProps) => {
  const [showComponent, setShowComponent] = useState(false)

  const isActive = useMemo(() => !!messages?.length, [messages])

  useEffect(() => {
    if (isActive && !showComponent) return setShowComponent(true)

    if (!isActive && showComponent)
      setTimeout(() => {
        setShowComponent(false)
      }, 1000)
  }, [isActive, showComponent])

  return showComponent ? (
    <S.Container isActive={isActive}>
      <div className="spinners">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 311 359"
          className="spinner"
        >
          <g
            id="path14"
            transform="matrix(0.15627,0,0,-0.156269,-114.681,485.502)"
          >
            <path d="M2097.36,1744.75L1729.46,1532.33L1361.54,1744.75L1361.54,2169.62L1729.46,2381.95L2097.36,2169.62L2097.36,1744.75ZM2725.06,1382.37L2725.06,2532L1729.46,3106.83L1101.77,2744.43L1296.66,2631.82L1729.46,2881.69L2530.17,2419.49L2530.17,1607.39L2292.26,1744.75L2292.26,2282.13L1729.46,2607.07L1264.1,2338.39L831.305,2588.26L733.867,2532L733.867,1382.37L1729.46,807.441L2357.14,1169.94L2162.26,1282.46L1729.46,1032.59L928.738,1494.9L928.738,2306.88L1166.65,2169.52L1166.65,1632.24L1729.46,1307.2L2194.82,1575.89L2627.6,1326.12L2725.06,1382.37" />
          </g>
        </svg>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 311 359"
          className="spinner_white"
        >
          <g
            id="path14"
            transform="matrix(0.15627,0,0,-0.156269,-114.681,485.502)"
          >
            <path d="M2097.36,1744.75L1729.46,1532.33L1361.54,1744.75L1361.54,2169.62L1729.46,2381.95L2097.36,2169.62L2097.36,1744.75ZM2725.06,1382.37L2725.06,2532L1729.46,3106.83L1101.77,2744.43L1296.66,2631.82L1729.46,2881.69L2530.17,2419.49L2530.17,1607.39L2292.26,1744.75L2292.26,2282.13L1729.46,2607.07L1264.1,2338.39L831.305,2588.26L733.867,2532L733.867,1382.37L1729.46,807.441L2357.14,1169.94L2162.26,1282.46L1729.46,1032.59L928.738,1494.9L928.738,2306.88L1166.65,2169.52L1166.65,1632.24L1729.46,1307.2L2194.82,1575.89L2627.6,1326.12L2725.06,1382.37" />
          </g>
        </svg>
      </div>

      <div className="messages">
        {messages.map(item => (
          <div key={item.id}>{item.message}</div>
        ))}
      </div>
    </S.Container>
  ) : (
    <></>
  )
}

export default MasterLoading
