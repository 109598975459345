import React from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import ButtonChat from '../ButtonChat'
import ButtonPrint from '../ButtonPrint'

import { Container } from './styles'

const HeaderSide = ({ contentToPrint }) => {
  const { getOrderLoading, orderData } = useSelector(state => state.order)

  return (
    <Container>
      {orderData?.customer && <ButtonChat />}

      <ButtonPrint
        label="Imprimir pedido"
        contentToPrint={contentToPrint}
        loading={getOrderLoading}
        hideMobile
        size="small"
      />
    </Container>
  )
}

export default HeaderSide

HeaderSide.propTypes = {
  contentToPrint: PropTypes.shape({})
}

HeaderSide.defaultProps = {
  contentToPrint: null
}
