import styled, { css } from 'styled-components'

const containerModifier = {
  opened: () => css`
    transform: translateX(0);
    box-shadow: -1rem 0 20px 20px rgba(199, 199, 199, 0.25);
  `,
  closed: () => css`
    transform: translateX(100%);
    box-shadow: -1rem 0 20px 20px rgba(199, 199, 199, 0);
  `
}

export const Container = styled.div`
  ${({ theme }) => css`
    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.colors.inative};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.tertiary};
      transition: 0.3s ease-in-out all;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.gray};
    }
  `}

  position: absolute;
  top: 0;
  left: 0;

  width: var(--aside__width);
  min-width: var(--aside__width);
  height: 100%;

  background-color: var(--white);

  transition: ease-in-out all 0.3s;

  ${({ isOpen }) => css`
    ${isOpen && containerModifier.opened}
    ${!isOpen && containerModifier.closed}
  `}

  > header {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 0.25rem;
    padding: 1rem;

    text-align: center;
    line-height: 1.25;

    .infos {
      flex: 1;
      .name {
        font-weight: 700;
        width: 100%;
      }
    }

    .icon__close,
    .icon__back {
      flex-basis: 0;
      padding: 0;

      border: 0;
      outline: none;

      background-color: transparent;
    }
  }
`
