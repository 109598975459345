import styled from 'styled-components'

export const SmallLineClamp = styled.small`
  margin-top: 0.25rem;
  line-height: 1;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
