import * as types from '../constants';

const index = {
  pending: `${types.UPDATE}_PENDING`,
  fulfilled: `${types.UPDATE}_FULFILLED`,
  rejected: `${types.UPDATE}_REJECTED`,
};

export const cases = {
  [index.pending]: ({ draft }) => {
    draft.loadingUpdate = true;
    draft.errorUpdate = null;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.itemUpdated = action.payload;
    draft.loadingUpdate = false;
    draft.errorUpdate = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.loadingUpdate = false;
    draft.errorUpdate = action.payload;
  },
};
