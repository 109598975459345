import React from 'react';
import { FaBarcode } from 'react-icons/fa';

import ThumbProduct from '~/components/ThumbProduct';

import { Container, ImageWrapper, ProductInfo } from './styles';

const SelectedProduct = () => {
  return (
    <Container>
      <ImageWrapper>
        <ThumbProduct
          asset={[
            {
              type: 'image',
              mime: 'image/webp',
              url: 'https://www.paodeacucar.com/img/uploads/1/753/604753.png',
            },
          ]}
          title="Miojo Nissin Turma da Mônica Galinha Caipira e Tomate verde"
        />
      </ImageWrapper>

      <ProductInfo>
        <p className="product-name">
          Miojo Nissin Turma da Mônica Galinha Caipira e Tomate verde
        </p>
        <p className="unit-measurement">85g</p>

        <div className="product-external-id">
          <FaBarcode />
          <p>12345678912</p>
        </div>
      </ProductInfo>
    </Container>
  );
};

export default SelectedProduct;
