import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/placement/sizes`

/**
 * List all
 */

export const listSizesBySegmentationService = (
  params: RequestListSizeBySegmentation
) => axios.get<ResponseListSizeBySegmentation>(baseURL, { params })
