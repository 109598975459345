import React, { useCallback, useMemo } from 'react'
import { FiMessageSquare } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { setSelectedTemplate } from '~/modules/chat/store/actions/templates'

import * as S from './styles'

const Template = ({ data }) => {
  const dispatch = useDispatch()
  const { query } = useSelector(({ filters }) => filters)
  const filteredQuery = useMemo(
    () => query?.queryChatTemplates || null,
    [query]
  )

  const handleTemplate = useCallback(
    () => dispatch(setSelectedTemplate({ message: data })),
    [data, dispatch]
  )

  const title = useMemo(() => {
    const results = data.title.replace(
      new RegExp(filteredQuery, 'gi'),
      match => `<mark>${match}</mark>`
    )

    return results
  }, [data.title, filteredQuery])

  const message = useMemo(() => {
    const results = data.message.replace(
      new RegExp(filteredQuery, 'gi'),
      match => `<mark>${match}</mark>`
    )

    return results
  }, [data.message, filteredQuery])

  return (
    <S.Container
      title="Clique para usar essa mensagem"
      onClick={handleTemplate}
    >
      <header>
        <span className="title">
          <FiMessageSquare />
          <strong // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: title
            }}
          />
        </span>
      </header>

      <section>
        <p // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: message
          }}
        />
      </section>
    </S.Container>
  )
}

export default Template

Template.propTypes = {
  data: PropTypes.shape({
    templateId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    global: PropTypes.bool,
    // System
    saving: PropTypes.bool,
    deleted: PropTypes.bool
  }).isRequired
}
