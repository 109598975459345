import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

const ChatContextWrapper = ({ children }) => <Container>{children}</Container>;

export default ChatContextWrapper;

ChatContextWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
