export const DTOCreateProductCampaign = (
  data: DTOCreateProductCampaignPayload & { lengthCentsCurrency: boolean }
) => {
  /**
   * Handle products linkded to campaign
   */
  const { lengthCentsCurrency } = data

  const products =
    data?.products?.map(item => ({
      id: item.id ? String(item.id) : undefined,
      sku: item.sku ? String(item.sku) : undefined
    })) || null

  /**
   * Handle campaign settings
   */

  const cpcRaw = data.budget.cpc.numAsString
  const cpc = lengthCentsCurrency ? Number(cpcRaw) / 100 : Number(cpcRaw)

  const dailyBudgetRaw = data.budget.daily.numAsString
  const dailyBudget = lengthCentsCurrency
    ? Number(dailyBudgetRaw) / 100
    : Number(dailyBudgetRaw)

  const settings: DTOCreateProductCampaignSettings = {
    daily_budget: dailyBudget,
    cpc
  }

  if (data?.placements?.placementsCheck === 'selected') {
    const placements = data?.placements?.placements_ids
    const selectedPlacements = Object.values(placements).filter(
      values => values.checked
    )

    settings.placements = selectedPlacements.map(({ value: id }) => ({ id }))
  }

  if (data?.settings?.budget_type) {
    settings.budget_type = data.settings.budget_type
  }

  const body: DTOCreateProductCampaignResponse = {
    publisher_id: data?.publisher_id,

    name: data.name,
    description: data?.description,

    type: 'on_site',
    ad_type: 'product',

    start_at: data.start_date,
    end_at: data?.end_date || null,

    products,

    settings
  }

  return body
}
