import React from 'react';

import {
  FormGroup,
  InputMoney,
  InputNumber,
  Label,
  Radio,
} from '~/components/unform';

const BenefitsShippingDiscount = () => {
  const limitDiscount = inputObj => {
    const { value } = inputObj;
    if (value > 0 && value <= 100) return inputObj;
  };

  const options = [
    {
      id: 'percent',
      value: 'percent',
      label: 'Porcentagem',
      activeChildren: (
        <FormGroup>
          <InputNumber
            suffix="%"
            name="benefits.shipping_discount.value"
            placeholder="Digite o desconto"
            required
            isAllowed={limitDiscount}
          />
        </FormGroup>
      ),
    },
    {
      id: 'fixed',
      value: 'fixed',
      label: 'Valor fixo',
      activeChildren: (
        <FormGroup>
          <InputMoney
            name="benefits.shipping_discount.value"
            placeholder="Digite o valor"
            required
          />
        </FormGroup>
      ),
    },
  ];

  return (
    <div>
      <FormGroup>
        <Label
          htmlFor="benefits.shipping_discount.type"
          text="Defina um valor"
          isRequired
        />

        <Radio
          options={options}
          name="benefits.shipping_discount.type"
          required
        />
      </FormGroup>
    </div>
  );
};

export default BenefitsShippingDiscount;
