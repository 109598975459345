import React from 'react'

import { DatepickerHeader } from '~/components/datepicker-header'
import ExternalDashboard from '~/components/ExternalDashboard'
import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import * as S from './styles'

const RuptureDashboard = () => {
  return (
    <S.Container>
      <PageTitle title="Relatório de ruptura" />

      <HeaderPages
        title="Relatório de ruptura"
        sideRightComponent={<DatepickerHeader />}
        className="has-datepicker"
      />

      <Filters locations />

      <ExternalDashboard dashboardId={65} useDate />
    </S.Container>
  )
}

export default RuptureDashboard
