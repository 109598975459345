import React, { useCallback, useMemo, useState } from 'react'
import { FiCheck, FiSend, FiShoppingCart } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'
import { getUserId } from '~/helpers'
import { getAccountInfo } from '~/helpers/account-info'
import {
  sendLinkShoppingCart,
  approveShoppingCart,
  openedShoppingCart
} from '~/store/modules/shoppingCart/actions'

const ColumnAction = ({ data }) => {
  const [cartIsOpen, setCartIsOpen] = useState(false)
  const { loadingSendLink, loadingUpdate } = useSelector(
    ({ shoppingCart }) => shoppingCart
  )
  const { host } = getAccountInfo()

  const userID = useMemo(() => getUserId(), [])

  const dispatch = useDispatch()

  const isPending = useMemo(() => data.status === 'in_review', [data])
  const isOpened = useMemo(() => data.status === 'opened', [data])

  const templateButtonCart = useMemo(
    () => (isPending && !cartIsOpen ? 'primary' : 'light'),
    [isPending, cartIsOpen]
  )

  const { cart_id, status } = data

  const statusType = useMemo(() => {
    if (status.type === 'deleted') {
      return 'canceled'
    }

    if (status.type === 'finished') {
      return 'finished'
    }

    if (status.type === 'link_sent') {
      return 'linkSent'
    }

    if (status.type === 'in_review') {
      return 'pending'
    }

    if (status.type === 'opened') {
      return 'opened'
    }

    return 'success'
  }, [status])

  const handleOpenCart = useCallback(() => {
    setCartIsOpen(true)
    dispatch(
      openedShoppingCart({
        cart_id,
        successFeedbackMsg: 'Carrinho aberto com sucesso!'
      })
    )
  }, [cart_id, dispatch])

  const handleSendLinkShoppingCart = useCallback(
    () =>
      dispatch(
        sendLinkShoppingCart({
          cart_id,
          successFeedbackMsg: 'Link enviado com sucesso!'
        })
      ),
    [cart_id, dispatch]
  )

  const simpleHost = useMemo(() => host.split('.')[0], [host])

  const externalCartLink = useMemo(
    () =>
      `https://cart.newtail.com.br/${simpleHost}/cart/${data.cart_id}?userId=${userID}`,
    [data, simpleHost, userID]
  )

  const handleAproveShoppingCart = useCallback(
    () =>
      dispatch(
        approveShoppingCart({
          cart_id,
          successFeedbackMsg: 'Carrinho aprovado com sucesso!'
        })
      ),
    [cart_id, dispatch]
  )

  return (
    <div className="buttons">
      {statusType === 'pending' && (
        <Button
          href={externalCartLink}
          template={templateButtonCart}
          text="Revisar carrinho"
          size="small"
          onClick={handleOpenCart}
          target="_blank"
          rel="noreferrer"
          iconLeft={<FiShoppingCart />}
          customWidth="auto"
          as="a"
        />
      )}

      {statusType === 'opened' && (
        <Button
          template="success"
          onClick={handleAproveShoppingCart}
          text="Aprovar"
          size="small"
          // disabled={!isOpened}
          iconLeft={loadingUpdate ? <Spinner /> : <FiCheck />}
          customWidth="auto"
          // {...(!cartIsOpen ? { tooltip: 'Veja o carrinho primeiro' } : {})}
        />
      )}

      {/* {statusType === 'linkSent' && (
        <Button
          template="light"
          text="Reenviar link"
          size="small"
          iconLeft={loadingSendLink ? <Spinner /> : <FiSend />}
          customWidth="auto"
          onClick={handleSendLinkShoppingCart}
        />
      )} */}

      {statusType === 'success' && (
        <Button
          template="success"
          text="Enviar link"
          size="small"
          iconLeft={loadingSendLink ? <Spinner /> : <FiSend />}
          customWidth="auto"
          onClick={handleSendLinkShoppingCart}
        />
      )}

      {statusType !== 'pending' && (
        <Button
          href={externalCartLink}
          template={templateButtonCart}
          text="Ver"
          size="small"
          target="_blank"
          rel="noreferrer"
          iconLeft={<FiShoppingCart />}
          customWidth="auto"
          as="a"
        />
      )}

      {/* {statusType === 'canceled' && (
        <Button
          href={externalCartLink}
          as="a"
          target="_blank"
          rel="noreferrer"
          template="light"
          text="Ver detalhes"
          size="small"
          customWidth="auto"
        />
      )} */}
    </div>
  )
}

export default ColumnAction

ColumnAction.propTypes = {
  data: PropTypes.shape({
    cart_id: PropTypes.string,
    order_id: PropTypes.string,
    in_review: PropTypes.bool,
    link_sent: PropTypes.bool,
    deletedAt: PropTypes.string,
    status: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string
    })
  }).isRequired
}
