import { useCallback, useMemo } from 'react'
import {
  FiAlertCircle,
  FiCheckCircle,
  FiCircle,
  FiRepeat,
  FiXCircle
} from 'react-icons/fi'

import PropTypes from 'prop-types'

import { AddressInline } from '~/components/Address'
import {
  formatDateExibitionUTC,
  formatDateToString,
  formatMoney,
  paymentMethods,
  paymentStatus
} from '~/helpers'

const OrderItems = ({
  items,
  totalPrice,
  paymentsInfos,
  shippingInfos,
  shipments
}) => {
  const renderIcon = useCallback(status => {
    const icons = {
      confirmed: FiCheckCircle,
      stock_out: FiXCircle,
      substitution: FiRepeat,
      pending: FiAlertCircle
    }

    const Icon = icons?.[status] || FiCircle

    return <Icon />
  }, [])

  return (
    <>
      <dl className="order__items">
        <dt>Itens do pedido</dt>

        {items.map(item => (
          <dd key={item.id}>
            <div className={`item__status item__status__${item.status}`}>
              {renderIcon(item.status)}
            </div>
            <div className="item__name">
              {item.status === 'substitution' && (
                <small className="item__original_name">
                  {item.original_name}
                </small>
              )}
              {item.name}
            </div>
            <div className="item__price">
              {formatMoney(item.original_price)}
            </div>
          </dd>
        ))}
      </dl>

      <dl>
        <dt>Valor do pedido</dt>

        <dd>
          <div className="item__name">Total:</div>
          <div className="item__price">{formatMoney(totalPrice)}</div>
        </dd>
      </dl>

      {paymentsInfos && (
        <dl>
          <dt>Pagamento</dt>

          {paymentsInfos.map(payment => (
            <dd key={payment.id}>
              {formatDateToString(payment.createdAt)}

              {payment.method && <> - {paymentMethods(payment.method)}</>}

              {payment.status && <> - Status {paymentStatus(payment.status)}</>}

              {payment.autorization_id && (
                <> - Autorização {payment.autorization_id}</>
              )}
            </dd>
          ))}
        </dl>
      )}

      {(shippingInfos || shipments) && (
        <dl>
          <dt>Entrega</dt>

          {shippingInfos.map(shipping => (
            <dd key={shipping.id}>
              <div>
                {shippingInfos?.[0] && (
                  <div>
                    <AddressInline shippingAddress={shippingInfos[0]} /> <br />
                  </div>
                )}

                {shipping?.full_name && (
                  <>
                    Destinatário: {shipping.full_name}
                    <br />
                  </>
                )}

                {shipments?.[0]?.slot && (
                  <>
                    Data agendada:{' '}
                    {formatDateExibitionUTC(shipments[0].schedule_date)}
                    <br />
                  </>
                )}

                {shipments?.[0]?.slot && <>Turno: {shipments[0].slot} </>}
              </div>
            </dd>
          ))}
        </dl>
      )}
    </>
  )
}

export default OrderItems

OrderItems.propTypes = {
  totalPrice: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number
    })
  ).isRequired,
  paymentsInfos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shippingInfos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shipments: PropTypes.arrayOf(PropTypes.shape()).isRequired
}
