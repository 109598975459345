import React, { useEffect, useMemo, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Alert from '~/components/Alert';
import HeaderPages from '~/components/HeaderPages';
import Loading from '~/components/Loading';
import PageTitle from '~/components/PageTitle';
import { formatSearchString } from '~/helpers';
import HeaderButtons from '~/modules/promotional/components/HeaderButtons';
import {
  getPromotion,
  clearPromotionStore,
} from '~/modules/promotional/store/promotion/actions';
import { indexLocation } from '~/pages/locations/actions';

import ColumnStatus from '../list/_elements/ColumnStatus';
import GeneralInfoView from './_elements/GeneralInfoView';
import ProductsListView from './_elements/ProductsListView';
import PromotionCouponsMetrics from './_elements/PromotionCouponsMetrics';
import PromotionCustomers from './_elements/PromotionCustomers';
import PromotionLocations from './_elements/PromotionLocations';
import PromotionRulesView from './_elements/PromotionRules';

const PromotionView = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { promotion, loading, error } = useSelector(state => state.promotional);

  useLayoutEffect(() => {
    if (promotion?.id !== id) {
      dispatch(clearPromotionStore());
    }
  }, [dispatch, id, promotion]);

  useEffect(() => {
    dispatch(getPromotion({ id }));
  }, [dispatch, id]);

  /**
   * Checando se tem listagem de lojas no estado global
   */

  const { listLocations } = useSelector(state => state.locations);
  /**
   * REFATORAR
   * Precisamos usar o endpoint que traz o retorno simples de locais.
   * Ainda não temos essa chamada no Redux.
   */
  const queryStringLocation = useMemo(
    () =>
      formatSearchString({
        page: 0,
        quantity: 500,
      }),
    [],
  );

  useEffect(() => {
    if (!listLocations.data) {
      dispatch(indexLocation(queryStringLocation));
    }
  }, [dispatch, listLocations, queryStringLocation]);

  return (
    <>
      <PageTitle title={`Promoção - ${promotion?.name || 'Carregando...'}`} />

      <HeaderPages
        title={promotion?.name || 'Carregando...'}
        subtitle={
          promotion?.status && <ColumnStatus status={promotion?.status} />
        }
        sideRightComponent={<HeaderButtons />}
      />

      <Loading status={loading}>Carregando promoção...</Loading>

      <Alert active={error}>Erro: {error}</Alert>

      {promotion && (
        <div>
          {promotion?.promotion_trigger === 'coupon' && (
            <PromotionCouponsMetrics />
          )}

          {/* Informações gerais  */}
          <GeneralInfoView />

          {/* Regras da promoção */}
          <PromotionRulesView />

          {/* Regras da promoção */}
          <PromotionLocations />

          {/* Regras da promoção */}
          {promotion?.promotion_type === 'coupon' && <PromotionCustomers />}

          {/* Produtos selecionados */}
          {promotion?.selected_offers?.length && <ProductsListView />}

          {/* WhatsApp fidelidade */}
          {/* <PromotionLoyaltyView /> */}
        </div>
      )}
    </>
  );
};

export default PromotionView;
