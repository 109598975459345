export const getFileType = file => {
  if (!file) throw new Error('File not found')

  const typesWhitelist = ['image/', 'video/', 'application/pdf']

  const type = typesWhitelist.find(type => file.type.includes(type))

  if (!type) throw new Error('Invalid mime type')

  switch (type) {
    case 'image/':
      return 'image'
    case 'video/':
      return 'video'
    default:
      return 'document'
  }
}

export const getImgSize = (image: File): Promise<Dimension> => {
  return new Promise((resolve, reject) => {
    const newImg = new Image()

    newImg.src = URL.createObjectURL(image)
    newImg.onload = () => {
      resolve({
        width: newImg.width,
        height: newImg.height
      })
    }
    newImg.onerror = reject
  })
}
