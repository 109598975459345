import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LineChart from '~/components/Chart/LineChart'
import Panel from '~/components/Panel'
import { formatMoney } from '~/helpers'
import { useAppSelector } from '~/store/hooks'

import { getGmvPerDayInfo } from '../dashboard-actions'

const formatDataAxis = ['', value => formatMoney(value, 0)]

const GmvPerDayChart = () => {
  const { startDate, endDate } = useSelector(state => state.datepickerHeader)

  const { gmvPerDayInfo, gmvPerDayLoading } = useSelector(
    state => state.dashboard
  )

  const dispatch = useDispatch()

  const { currentTheme: theme } = useAppSelector(state => state.theme)

  useEffect(() => {
    dispatch(getGmvPerDayInfo(startDate, endDate))
  }, [startDate, endDate, dispatch])

  const chartIsReady = useMemo(
    () => !!gmvPerDayInfo?.date?.length,
    [gmvPerDayInfo]
  )

  const chartConfig = useMemo(
    () => ({
      labels: gmvPerDayInfo?.date || null,
      datasets: [
        {
          type: 'line',
          label: 'Total de pedidos',
          data: gmvPerDayInfo.orders,
          fill: false,
          borderColor: theme.primary,
          backgroundColor: theme.primary,
          pointBorderColor: theme.primary,
          pointBackgroundColor: theme.primary,
          pointHoverBackgroundColor: theme.primary,
          pointHoverBorderColor: theme.primary,
          yAxisID: 'y-axis-2',
          tension: 0.4
        },
        {
          type: 'bar',
          label: 'GMV',
          data: gmvPerDayInfo.gmv,
          fill: false,
          backgroundColor: theme.success,
          borderColor: theme.success,
          hoverBackgroundColor: theme.success,
          hoverBorderColor: theme.success,
          yAxisID: 'y-axis-1',
          typeData: 'money'
        }
      ]
    }),
    [
      gmvPerDayInfo?.date,
      gmvPerDayInfo.gmv,
      gmvPerDayInfo.orders,
      theme.primary,
      theme.success
    ]
  )

  const optionsChart = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: '💡 Dica: Clique para exibir ou esconder um dado',
          position: 'bottom',
          padding: 0,
          color: theme.colors.dark,
          font: { weight: 'normal', size: 12 }
        },
        legend: {
          position: 'bottom',
          reverse: true,
          labels: {
            boxWidth: 24,
            fontSize: 14,
            useBorderRadius: true,
            borderRadius: 4
          }
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          title: 'data'
        }
      },
      scales: {
        x: {
          display: true,
          grid: {
            display: false
          }
        },
        y: {
          display: false,
          grid: {
            display: false
          }
        },
        'y-axis-1': {
          display: true,
          position: 'left',
          grid: {
            display: true
          },
          ticks: {
            suggestedMin: 0,
            callback: value =>
              formatDataAxis && formatDataAxis[1]
                ? formatDataAxis[1](value)
                : value,
            showLabelBackdrop: true,
            backdropPadding: 2
          }
        },
        'y-axis-2': {
          display: true,
          position: 'right',
          grid: {
            display: false
          },
          ticks: {
            suggestedMin: 50,
            suggestedMax: 100,
            callback: value =>
              formatDataAxis && formatDataAxis[0]
                ? formatDataAxis[0](value)
                : value,
            showLabelBackdrop: true,
            backdropPadding: 2
          }
        }
      }
    }),
    [theme.colors.dark]
  )

  return (
    <Panel title="Pedidos e faturamento por dia" isLoading={gmvPerDayLoading}>
      {!!chartIsReady && (
        <LineChart
          datasets={chartConfig.datasets}
          labels={chartConfig.labels}
          optionsChart={optionsChart}
        />
      )}
    </Panel>
  )
}

export default GmvPerDayChart
