import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Alert from '~/components/Alert'
import { Container } from '~/components/Container'
import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { formatSearchString } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import { getUsersList } from '~/modules/users/store/actions'
import { USER_ROLE_REDUX_QUERY } from '~/modules/users/store/constants'

import LocationNav from '../_elements/LocationNav'
import { showLocation, indexLocationUsers } from '../actions'
import UserItem from './elements/UserItem'

import * as S from './styles'

const LocationsUsers = () => {
  const dispatch = useDispatch()

  /* Handle Location */
  /**
   * Pega o ID passado na rota
   */
  const { id: locationId } = useParams()
  /**
   * Pega dados do reducer locations
   */
  const {
    location,
    loading,
    error: errorLocation,
    listLocationUsers
  } = useSelector(state => state.locations)
  /**
   * Dados do local armazenado no reducer memoizado
   */
  const locations = useMemo(() => location?.location, [location?.location])
  /**
   * Pega dados da location
   */
  useEffect(() => {
    dispatch(showLocation(locationId))
  }, [dispatch, locationId])
  /**
   * Pega dados dos usuários vinculados à location
   */
  useEffect(() => {
    dispatch(indexLocationUsers({ locationId }))
  }, [dispatch, locationId])
  /**
   * Define título da página
   */
  const titlePage = useMemo(
    () => (locations ? `Local: ${locations.name}` : 'Locais'),
    [locations]
  )

  /**
   * Handle filters
   */
  const { query, userRoles } = useSelector(state => state.filters)

  const filteredQuery = useMemo(
    () => query?.[`${USER_ROLE_REDUX_QUERY}`] || null,
    [query]
  )

  const filteredRole = useMemo(() => userRoles?.value || null, [userRoles])

  /** Handle users */
  /**
   * Pega dados do reducer users
   */
  const { usersList, listUsersPerPage, loadingListUsers } = useSelector(
    ({ user }) => user
  )

  const queryString = useMemo(
    () => ({
      quantity: 1000,
      name: filteredQuery,
      role: userRoles?.value || null
    }),
    [filteredQuery, userRoles?.value]
  )

  useEffect(() => {
    dispatch(getUsersList({ params: queryString }))
  }, [dispatch, queryString])

  /**
   * Local filter
   */

  const [AlertOnError] = useAlert(errorLocation)

  const testQuery = useCallback(
    item => {
      const escaped = filteredQuery?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

      const condition = new RegExp(escaped, 'gi')

      return filteredQuery
        ? condition.test(item.name) || condition.test(item.email)
        : true
    },
    [filteredQuery]
  )

  const testRole = useCallback(
    item => {
      const escaped = filteredQuery?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

      const condition = new RegExp(escaped, 'gi')

      return filteredRole ? condition.test(item.role) : true
    },
    [filteredQuery, filteredRole]
  )

  const activeUsers = useMemo(() => {
    const filteredList = filteredQuery
      ? usersList.filter(item => testQuery(item))
      : usersList

    const filteredByRole = filteredRole
      ? filteredList.filter(item => testRole(item))
      : filteredList

    const filterByLink = filteredByRole
      ?.map(user => {
        const [association] = listLocationUsers?.filter(
          linkedUser => user.id === linkedUser.user_id
        )
        return association
          ? { ...user, updated_at: association.updated_at }
          : undefined
      })
      .filter(item => !!item)

    return filterByLink
  }, [
    filteredQuery,
    filteredRole,
    listLocationUsers,
    testQuery,
    testRole,
    usersList
  ])

  const avaiableUsers = useMemo(() => {
    const filteredList = filteredQuery
      ? usersList.filter(item => testQuery(item))
      : usersList

    const filteredByRole = filteredRole
      ? filteredList.filter(item => testRole(item))
      : filteredList

    const filterByLink = filteredByRole?.filter(
      user =>
        !listLocationUsers?.some(linkedUser => user.id === linkedUser.user_id)
    )

    return filterByLink
  }, [
    filteredQuery,
    filteredRole,
    listLocationUsers,
    testQuery,
    testRole,
    usersList
  ])

  // Memos
  const usersIsReady = useMemo(
    () => !loading && !loadingListUsers,
    [loading, loadingListUsers]
  )

  const hasUsers = useMemo(() => Boolean(usersList?.length), [usersList])

  const hasLocationUsers = useMemo(
    () => Boolean(listLocationUsers?.length),
    [listLocationUsers]
  )

  const hasResultSearchOnLinkedUsers = useMemo(
    () => hasLocationUsers && Boolean(!activeUsers?.length),
    [hasLocationUsers, activeUsers]
  )

  const hasResultSearchOnAvaiableUsers = useMemo(
    () => hasUsers && Boolean(!avaiableUsers?.length),
    [hasUsers, avaiableUsers]
  )

  const allUserLinked = useMemo(
    () =>
      hasLocationUsers &&
      !avaiableUsers?.length &&
      !filteredQuery &&
      !filteredRole,
    [avaiableUsers, filteredQuery, filteredRole, hasLocationUsers]
  )

  const noResultMessageActiveList = useMemo(() => {
    switch (true) {
      case !hasLocationUsers:
        return 'Nenhum usuário está vinculado a esta loja.'

      case hasResultSearchOnLinkedUsers:
        return 'Nenhum usuário corresponde a sua pesquisa.'

      default:
        return null
    }
  }, [hasLocationUsers, hasResultSearchOnLinkedUsers])

  const noResultMessageAvaibleList = useMemo(() => {
    switch (true) {
      case !hasUsers:
        return 'Nenhum usuário disponível.'

      case allUserLinked:
        return 'Todos usuários já estão vinculados a essa loja.'

      case hasResultSearchOnAvaiableUsers:
        return 'Nenhum usuário corresponde a sua pesquisa.'

      default:
        return null
    }
  }, [allUserLinked, hasResultSearchOnAvaiableUsers, hasUsers])

  return (
    <Container>
      <PageTitle title={titlePage} />

      <HeaderPages
        title={titlePage}
        backTo="/locations/list"
        labelBreadcrumb="Voltar aos locais"
      />

      <AlertOnError />

      <Loading status={loading}>Carregando informações do local</Loading>

      <LocationNav locationId={locationId} />

      <Filters
        query
        liveQuery
        queryKeyState={USER_ROLE_REDUX_QUERY}
        queryPlaceholder="Buscar usuário"
        userRoles
      />

      <S.UserLists>
        <S.UserList>
          <S.Header>
            <S.Title>Usuários vinculados</S.Title>

            <Loading status={!usersIsReady}>
              Carregando usuários vinculados...
            </Loading>

            {usersIsReady && (
              <Alert active={Boolean(noResultMessageActiveList)}>
                {noResultMessageActiveList}
              </Alert>
            )}
          </S.Header>

          {activeUsers?.map(item => (
            <UserItem
              key={item.id}
              data={item}
              locationId={locationId}
              isActive
              isSaving={listLocationUsers?.some(inner => {
                return inner?.saving && inner.user_id === item.id
              })}
              isDeleted={listLocationUsers?.some(inner => {
                return inner?.deleted && inner.user_id === item.id
              })}
            />
          ))}
        </S.UserList>

        <S.UserList>
          <S.Header>
            <S.Title>Usuários disponíveis</S.Title>

            <Loading status={!usersIsReady}>Carregando usuários...</Loading>

            {usersIsReady && (
              <Alert active={Boolean(noResultMessageAvaibleList)}>
                {noResultMessageAvaibleList}
              </Alert>
            )}
          </S.Header>

          {avaiableUsers?.map(item => (
            <UserItem key={item.id} data={item} locationId={locationId} />
          ))}
        </S.UserList>
      </S.UserLists>
    </Container>
  )
}
export default LocationsUsers
