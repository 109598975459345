import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    margin-top: 0.25rem;
    display: flex;
    align-items: center;

    > svg {
      margin-right: 0.25rem;
    }

    > span {
      border-radius: 0.125rem;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      padding: 0.25rem 0.375rem;
      margin-left: 0.25rem;

      font-size: 0.625rem;
      font-weight: 700;
    }
  `}
`
