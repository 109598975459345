import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useParams } from 'react-router-dom'

import { t } from 'i18next'
import moment from 'moment'

import ToggleInput from '~/components/Toggle'
import { isPublisher, isReadOnly } from '~/helpers'
import { useCampaignTable } from '~/modules/retailMedia/components/_tables/CampaignTable/hooks/useCampaignTable'
import { campaign as service } from '~/modules/retailMedia/services/industry'
import Actions from '~/modules/retailMedia/store/industry/campaign/actions'
import { useAppSelector } from '~/store/hooks'

const ToogleActiveCampaign = ({
  data,
  showLabel = false,
  isList = false
}: {
  data: Partial<IndustryCampaign | IndustryItemListCampaign>
  showLabel?: boolean
  isList?: boolean
}) => {
  // const [checked, setChecked] = useState(data.active)
  const { handleMutate } = useCampaignTable()
  const { id } = useParams()
  const dispatch = useDispatch()

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const queryParams = useMemo(
    () =>
      ({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        campaign_id: id
      }) as GetCampaignsReportRequestParams,
    [startDate, endDate, id]
  )

  const handleSuccess = useCallback(() => {
    dispatch(
      Actions.get({
        ...queryParams,
        account_info: true,
        requestInBackground: true
      })
    )
  }, [dispatch, queryParams])

  const handleChangeToggle = useCallback(
    async ({ value, disabled, onSuccess, data }) => {
      if (disabled) {
        console.log('Publisher cannot edit campaign')
        return
      }

      try {
        const id = data.id
        await service.patch({
          id,
          body: { active: value }
        })

        toastr.success(
          t('rm:toast.success'),
          t('rm:message.updated.campaign.success')
        )

        if (handleMutate) {
          await handleMutate({ data, value })
        }

        onSuccess?.(value)
      } catch (error) {
        console.error(error)
        toastr.error(
          t('rm:toast.error'),
          t('rm:message.updated.campaign.error')
        )
        onSuccess?.(!value)
      }
    },
    [handleMutate]
  )

  const disabled = isPublisher || isReadOnly()

  /**
   * Alerta de gambiarra
   * A separaçao do isList é pq usamos o mesmo componente na list e na view.
   * Na list usamos SWR, na view redux action.
   * Na lista fazemos um mutate.
   * Na view fazemos um get em backgroud.
   */
  const handleChange = useCallback(
    ({ e, handleLoading }) => {
      const value = e.target.checked
      const onSuccess = () => {
        if (!isList) {
          handleSuccess()
        }

        handleLoading(value)
      }

      handleChangeToggle({ value, disabled, data, onSuccess })
    },
    [data, disabled, handleChangeToggle, handleSuccess, isList]
  )

  const label = useMemo(
    () => (data?.active ? t('rm:activeCampaign') : t('rm:disabledCampaign')),
    [data]
  )
  const isChecked = useMemo(() => data.active, [data.active])

  return (
    <>
      <ToggleInput
        name={`active_${data?.id}`}
        isChecked={isChecked}
        onChange={handleChange}
        isAsync
        disabled={disabled}
        label={showLabel ? label : null}
        size="small"
      />
    </>
  )
}

export default ToogleActiveCampaign
