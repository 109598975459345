import axios from 'axios'

import { slotsAPI } from './'

/**
 * Lista os slots padrões para os dias da semana
 */
export const listWeekly = query => axios.get(`${slotsAPI}/weekly/?${query}`)

/**
 * Lista os slots para dias específicos
 */
export const listDates = query => axios.get(`${slotsAPI}/dates/?${query}`)

/**
 * Cria slots para dias específicos ou dias de semana
 */
export const createSlot = ({ data }) => axios.post(`${slotsAPI}`, data)

/**
 * Deleta slots de dias específicos ou dias de semana
 */
export const deleteSlot = slotConfigId =>
  axios.delete(`${slotsAPI}/${slotConfigId}`)

/**
 * Lista os dias sem funcionamento
 */
export const listDayWithoutWork = query =>
  axios.get(`${slotsAPI}/without-work/?${query}`)

/**
 * Cria dia sem funcionamento
 */
export const createDayWithoutWork = ({ data }) =>
  axios.post(`${slotsAPI}/without-work`, data)

/**
 * Delete dia sem funcionamento
 */
export const deleteDayWithoutWork = dayId =>
  axios.delete(`${slotsAPI}/without-work/${dayId}`)

export const slotsService = {
  listWeekly,
  listDates,
  // Post
  createSlot,
  // Delete
  deleteSlot,
  // Day without work
  listDayWithoutWork,
  createDayWithoutWork,
  deleteDayWithoutWork
}
