import React, { useCallback, useState } from 'react';
import { FiMaximize, FiX } from 'react-icons/fi';

import PropTypes from 'prop-types';

import { Button } from '../Buttons';

import * as S from './styles';

const ExpandableSection = ({ children }) => {
  /**
   * ExpandableFieldset
   */
  const [expanded, setExpanded] = useState(false);
  const handleExpand = useCallback(() => setExpanded(e => !e), []);

  return (
    <S.Container isActive={expanded}>
      <Button
        template="transparent"
        onClick={handleExpand}
        className="toggleButton"
      >
        {expanded ? <FiX /> : <FiMaximize />}
      </Button>
      {children}
    </S.Container>
  );
};

export default ExpandableSection;

ExpandableSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
