import { chatSettings } from '~/modules/chat/pages/messenger/settings'

const chatsCountDefaultValues: { [key in ChatTabTypes]: number } = {
  in_contact: 0,
  waiting_contact: 0,
  available: 0,
  finished: 0
}

export const defaultValues: Partial<ChatsState> = {
  chatsCount: chatsCountDefaultValues
}

export const INITIAL_STATE: ChatsState = {
  // UI
  fullScreen: false,
  // Indicador global de loading
  loading: false,
  masterLoadingChats: false,
  // Indicador global de error
  error: null,
  // Armazena todao os chats carregados
  chats: [],
  chatsCount: defaultValues.chatsCount,
  currentPage: 1,
  pages: 1,
  sortChatsBy: chatSettings.sortChatsBy.asc.value,
  chatsLength: 0,
  selectedStatus: 'in_contact',
  // Indicador de loading para a requisição de sessões
  loadingChats: false,
  // Sinaliza de deve atualizar a lista de sessões
  shouldRefreshChats: false,
  // Sinaliza de deve disparar o alarme
  shouldPlayNewContactNotifications: false,
  shouldPlayNotifications: false,
  // Informações do chat que será editada
  selectedChatToUpdate: null,
  // Indicador de loading para a atualização de chat
  updateChatLoading: false,
  // Indicador sobre estado do menu de um item na lista de sessões
  chatMenuOpen: null,
  // Informa se deve ficar olhando as notificações do socket e fazendo atualização das mensagens
  autoRefresh: false,
  // Informações da sessão selecionada
  selectedChat: null,
  // Armazena as mensagens carregadas da sessão selecionada
  messages: [],
  messagesCurrentPage: 1,
  messagesPages: 1,
  messagesTotal: 0,
  // Aramazena o id recebido via url
  chatIdByUrl: null,
  // Indicador sobre estado de seleção dos chats.
  selectChatsMode: false,
  // Chats selecionados para serem editados
  chatsSelectedToEdit: [],

  // Aramazena o customerId do cliente vinculado ao chat selecionado
  customerId: null,

  // Pedido selecionado para ser exibido no painel de pedidos
  orderOpen: {},

  // Indicador sobre estado de edição de location
  editLocationMode: false,

  loadingTags: false,
  // Armazena marcadores(tags) disponíveis na conta do cliente
  avaiableTags: null,
  // Marcadores(tags) selecionadas para serem aplicadas
  selectedTags: [],
  loadingCreateTag: false,
  loadingSavingTag: false,
  // Armazena o template selecionado para ser carregado no campo de submit
  selectedTemplate: null,
  // Chat filters
  showActiveChats: true,
  showFinishedChats: false,
  showArchivedChats: false,
  // Sliding Panels
  slidingPanelIsOpen: false,
  // Indicador sobre estado do painel de cliente
  customerPanelOpen: false,
  // Indicador sobre estado do painel de temples
  templatesPanelOpen: false,
  // Indicador sobre estado do painel de temples
  tagsPanelOpen: false,
  // Indicador sobre estado do painel de gerar carrinho
  shoppingCartPanelOpen: false,

  // Inicia o batepapo
  finishServiceData: [],
  finishServiceLoading: false,
  startServiceData: [],
  startServiceLoading: false,

  // Lista atendentes
  listAttendantData: [],
  listAttendantLoading: false,

  // Transferencia de agentes
  agentTransferData: [],
  agentTransferLoading: false,
  // Seta o estado paara abrir o panel
  templatePanelHistory: false,
  // Estado para armazenar o historico de chats
  historyChat: [],
  // Estado de loading para o historiico do chat
  historyChatLoading: false
}
