import React from 'react';

import LocationsRadioList from '~/components/LocationsRadioList';
import Panel from '~/components/Panel';
import { FormGroup, Label, Radio } from '~/components/unform';

const FlyerLocations = () => {
  const optionsTypes = [
    {
      id: `locationsCheck_all`,
      value: 'all',
      label: 'Todos os locais',
    },
    {
      id: `locationsCheck_selected`,
      value: 'selected',
      label: 'Selecionar locais',
      activeChildren: (
        <LocationsRadioList label="Selecione um ou mais locais que vão enviar o encarte" />
      ),
    },
  ];

  return (
    <Panel title="Locais participantes" isCollapsible>
      <FormGroup>
        <Label
          htmlFor="locationsCheck"
          isRequired
          text="Defina a quais locais o encarte se refere"
        />

        <Radio options={optionsTypes} name="locationsCheck" required />
      </FormGroup>
    </Panel>
  );
};

export default FlyerLocations;
