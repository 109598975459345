import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { newFilterChatsByStatus } from '~/modules/chat/store/actions/filters'
import { useAppSelector } from '~/store/hooks'

import { chatTabs } from './constants'

import * as S from './styles'

const ChatTabs = () => {
  const dispatch = useDispatch()

  const { selectedStatus: status, chatsCount } = useAppSelector(
    ({ chats }) => chats
  )

  const { query } = useAppSelector(({ filters }) => filters)

  const handleFilterChatsByStatus = useCallback(
    (status: ChatTabTypes) => {
      dispatch(newFilterChatsByStatus(status))
    },
    [dispatch]
  )

  /**
   * Conjuntado de regras para exibir o contados
   */
  const handleShowCounter = useCallback(
    (item: ChatTab) => {
      const { type } = item

      const hasValue = !!chatsCount?.[type]

      const isBelowThousand = chatsCount[type] < 9999

      const isFinished = type === 'finished'
      // Checar se foi busca específica e teve resultado no arquivado
      const shuoldShowFinished = !isFinished || !!query?.queryChat

      return hasValue && isBelowThousand && shuoldShowFinished
    },
    [chatsCount, query?.queryChat]
  )

  return (
    <S.Container>
      {chatTabs.map(item => (
        <S.Item key={item.type} isActive={status === item.type}>
          <S.Button
            title={item.title}
            isActive={status === item.type}
            onClick={() => handleFilterChatsByStatus(item.type)}
            role="button"
          >
            {handleShowCounter(item) && (
              <S.Counter>{chatsCount[item.type]}</S.Counter>
            )}

            {item.label}
          </S.Button>
        </S.Item>
      ))}
    </S.Container>
  )
}

export default ChatTabs
