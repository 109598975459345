import { history } from '~/helpers'
import {
  promotion as promotionalService,
  promotionMetrics as promotionMetricsService
} from '~/modules/promotional/services/'
import { searchOffersService } from '~/services/search-service'

import { modelPromotionCreate } from '../../models/create'
import * as types from './constants'

/**
 *
 * @exports createPromotion Cria uma promoção
 * @param {object} formData Objeto com valores recebidos pelo formulário
 * @param {array} offerIds Array com ids dos produtos selecionados
 *
 */
export const createPromotion = ({ formData, offerIds }) => {
  const body = modelPromotionCreate({ formData, offerIds })

  return {
    type: types.PROMOTIONS_CREATE,
    payload: promotionalService.create(body),
    successFeedbackMsg: 'Promoção criada com sucesso.',
    onSuccess: response => {
      history.push(`/promotional/view/${response.data.id}`)
    }
  }
}

/**
 *
 * @exports updatePromotion Busca todos as promoções filtradas
 * @param {object} formData Objeto com valores recebidos pelo formulário
 * @param {array} offerIds Array com ids dos produtos selecionados
 * @param {string} successFeedbackMsg Mensagem para ser exibida no toast de sucesso
 * @param {string} redirectOnSuccess URL alternativa para redirecionamento após sucesso
 *
 */
export const updatePromotion = ({
  formData,
  offerIds,
  id,
  successFeedbackMsg,
  redirectOnSuccess,
  useModel = true,
  onSuccess
}) => {
  const body = useModel
    ? modelPromotionCreate({ formData, offerIds })
    : formData

  return {
    type: types.PROMOTIONS_UPDATE,
    payload: promotionalService.update({ body, id }),
    successFeedbackMsg:
      successFeedbackMsg || 'Promoção atualizada com sucesso.',
    onSuccess: () => {
      onSuccess
        ? onSuccess()
        : history.push(redirectOnSuccess || `/promotional/view/${id}`)
    }
  }
}

/**
 *
 * @exports productsList Busca todos as promoções filtradas
 * @param {number} page Gerencia a paginação
 *
 */
export const listPromotions = ({
  page = 1,
  quantity,
  query,
  location_id,
  status,
  promotion_type,
  start_date,
  end_date
}) => {
  const queryString = {
    page,
    quantity,
    location_id,
    query,
    status,
    promotion_type,
    start_promotion: start_date,
    end_promotion: end_date
  }

  return {
    type: types.PROMOTIONS_LIST,
    payload: promotionalService.list(queryString),
    page
  }
}

/**
 * Action para lidar com a mudança de quantidade de itens que serão exibidos em peagina
 * @param {number} value Valor de itens que serão exibidos por página
 * @returns
 */

export const handlePromotionsPerPage = value => ({
  type: types.PROMOTIONS_LIST_PER_PAGE,
  payload: value
})

/**
 * Action para lidar com a requisição de dados de uma promoção
 * @param {string} props.id
 * @returns
 */
export const getPromotion = ({ id }) => {
  return {
    type: types.PROMOTION_VIEW,
    payload: promotionalService.get(id),
    onError: () => {
      history.push(`/promotional/list/`)
    }
  }
}

/**
 * Action para lidar com a requisição de dados de desempenho de uma promoção
 * @param {string} props.id
 * @returns
 */
export const getPromotionMetrics = ({ id }) => {
  return {
    type: types.PROMOTION_METRICS_VIEW,
    payload: promotionMetricsService.get(id)
  }
}

/**
 * Action para lidar com a exclusão de uma promoção
 * @param {string} props.id
 * @returns
 */
export const deletePromotion = ({ id, onSuccess }) => {
  return {
    type: types.PROMOTIONS_DELETE,
    payload: promotionalService.remove(id),
    successFeedbackMsg: 'Promoção deletada com sucesso.',
    onSuccess: () =>
      onSuccess ? onSuccess() : history.push(`/promotional/list/`),
    onError: () => {
      history.push(`/promotional/list/`)
    }
  }
}

/**
 * Adiciona uma lista de IDS de produtos
 */

export const addProductsBulk = ({ productsIds }) => {
  const external_ids = productsIds
    .toString()
    .replace(/[,;]/g, '\n')
    .replace(/\r\n/g, '\n')
    .split('\n')
    .filter(item => !!item.trim())

  return {
    type: types.ADD_PRODUCTS_BULK,
    payload: searchOffersService({ external_ids })
  }
}

/**
 * Adiciona um produto na lista de selecionados
 */

export const addProductOnPromotion = ({ product }) => ({
  type: types.ADD_PRODUCT,
  payload: product
})

/**
 * Remove um produto da lista de selecionados
 */

export const deleteProductOnPromotion = ({ product }) => ({
  type: types.DELETE_PRODUCT,
  payload: product
})

/**
 * Remove todos os produto da lista de selecionados
 */

export const deleteAllProductsOnPromotion = () => ({
  type: types.DELETE_ALL_PRODUCTS
})

/**
 * Limpa store
 */

export const clearPromotionStore = () => ({
  type: types.CLEAR_PROMOTION_STORE
})
