import styled from 'styled-components'

export const ContainerContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 0.5rem;

  margin-left: 54px;
  margin-bottom: 1rem;

  p {
    margin-bottom: 0;
  }

  .notes {
    flex: 1 1 100%;
    padding-left: 0.5rem;
    border-left: 1px solid #ddd;

    &.inline {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
`
