import React, { useState } from 'react'

import { t } from 'i18next'

import { FormGroup, InputHidden, Label } from '~/components/unform'
import TagInput from '~/components/unform/TagInput'

type PlacementSearchProps = {
  onChange?: (data: { keywords: string[] }) => void
}

const PlacementSearch = ({ onChange }: PlacementSearchProps) => {
  const [values, setValues] = useState([])

  const handleCreateKeyword = (value: string[]) => {
    onChange?.({ keywords: value })

    setValues(value)
  }

  const handleDeleteKeyword = (index: number) => {
    const handleAction = (keywords: string[]) => {
      const newData = keywords?.filter((_, i) => index !== i)

      onChange?.({ keywords: newData })

      return newData
    }

    setValues(handleAction)
  }

  return (
    <FormGroup className="fullWidth">
      <Label
        htmlFor="desktop.keywords"
        isRequired
        text={t('rm:keywords')}
        helperText={t('rm:campaign.formV2.targeting.keywords.helperText')}
      />

      <TagInput
        name="desktop.keywords"
        placeholder={t('rm:campaign.formV2.targeting.keywords.placeholder')}
        onCreate={handleCreateKeyword}
        onDelete={handleDeleteKeyword}
        tags={values}
      />

      <InputHidden value={String(values)} name="keywords" />
    </FormGroup>
  )
}

export default PlacementSearch
