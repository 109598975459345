const prefix = '@retailMedia/grocery/campaign'

export const CAMPAIGNS_LIST = `${prefix}/LIST`
export const CAMPAIGNS_LIST_SET_PAGE = `${prefix}/LIST_SET_PAGE`
export const CAMPAIGNS_LIST_PER_PAGE = `${prefix}/LIST_PER_PAGE`

export const CAMPAIGN_GET = `${prefix}/GET`

export const CAMPAIGN_CREATE = `${prefix}/CREATE`
export const CAMPAIGN_UPDATE = `${prefix}/UPDATE`
export const CAMPAIGN_UPDATE_ITEM_LOCAL = `${prefix}/UPDATE_ITEM_LOCAL`
export const CAMPAIGNS_UPLOAD = `${prefix}/UPLOAD`
export const CAMPAIGN_PATCH = `${prefix}/PATCH`

export const CAMPAIGN_DELETE = `${prefix}/DELETE`

export const CAMPAIGN_REDUX_QUERY = `${prefix}/REDUX_QUERY`

export const CAMPAIGNS_GET_DASHBOARD = `${prefix}/GET_DASHBOARD`

export const CANCEL_CAMPAIGN = `${prefix}/CANCEL_CAMPAIGN`

export const CAMPAIGNS_GET_REPORT = `${prefix}/GET_REPORT`
export const CAMPAIGNS_GET_REPORT_HISTORY = `${prefix}/GET_REPORT_HISTORY`

export const CAMPAIGN_GET_COUNT_STATUS = `${prefix}/GET_COUNT_STATUS`

export const types = {
  list: CAMPAIGNS_LIST,
  page: CAMPAIGNS_LIST_SET_PAGE,
  perPage: CAMPAIGNS_LIST_PER_PAGE,

  get: CAMPAIGN_GET,

  create: CAMPAIGN_CREATE,
  update: CAMPAIGN_UPDATE,
  patch: CAMPAIGN_PATCH,

  upload: CAMPAIGNS_UPLOAD,
  updateItemLocal: CAMPAIGN_UPDATE_ITEM_LOCAL,

  remove: CAMPAIGN_DELETE,

  cancelCamapign: CANCEL_CAMPAIGN,

  dashboard: CAMPAIGNS_GET_DASHBOARD,

  report: {
    get: CAMPAIGNS_GET_REPORT,
    history: CAMPAIGNS_GET_REPORT_HISTORY
  },

  countStatus: CAMPAIGN_GET_COUNT_STATUS
}
