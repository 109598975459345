import { Scope } from '@unform/core'
import i18next from 'i18next'

import Panel from '~/components/Panel'
import { CheckboxList } from '~/components/unform/CheckboxList'
import { useAdTypes } from '~/modules/retailMedia/hooks/useAdTypes'

import FormatBanner from './FormatBanner'
import FormatProduct from './FormatProduct'
import FormatSponsoredBrands from './FormatSponsoredBrands'

type FormFormatProps = {
  initialData?: Placement
}

const FormFormats = ({ initialData }: FormFormatProps) => {
  const { data } = useAdTypes()

  /**
   * Precisa refatorar
   */

  const productIsSelected =
    initialData &&
    Object.values(initialData?.formats).findIndex(
      item => item.type === 'product'
    ) > -1

  const sponsoredBrandIsSelected =
    initialData &&
    Object.values(initialData?.formats).findIndex(
      item => item.type === 'sponsored_brand'
    ) > -1

  type OptionsAdTypesWithChildren = {
    label: string
    name: CampaignAdType
    activeChildren: JSX.Element
  }

  const banner: OptionsAdTypesWithChildren = {
    label: i18next.t('common:capitalize', {
      value: i18next.t('rm:banner')
    }),
    name: 'banner',
    activeChildren: <FormatBanner initialData={initialData} />
  }

  const product: OptionsAdTypesWithChildren = {
    label: i18next.t('common:capitalize', {
      value: i18next.t('rm:product')
    }),
    name: 'product',
    activeChildren: <FormatProduct />
  }

  const sponsoredBrand: OptionsAdTypesWithChildren = {
    label: i18next.t('common:capitalize', {
      value: i18next.t('rm:sponsored_brand')
    }),
    name: 'sponsored_brand',
    activeChildren: <FormatSponsoredBrands />
  }

  const allOptions = [banner, product, sponsoredBrand]

  const availableOptions = allOptions.filter(item => data.includes(item.name))

  const options = availableOptions.filter(item => {
    if (item.name === 'banner') return true
    if (item.name === 'product') return !productIsSelected
    if (item.name === 'sponsored_brand') return !sponsoredBrandIsSelected
  })

  return (
    <Panel
      title={i18next.t('rm:placements.form.formats.title')}
      description={i18next.t('rm:placements.form.formats.description')}
    >
      <Scope path="formats">
        <CheckboxList name="active" options={options} />
      </Scope>
    </Panel>
  )
}

export default FormFormats
