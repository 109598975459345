import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme, isEditMode }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    border-top: ${theme.colors.tertiary} 1px solid;
    border-bottom: ${theme.colors.inative} 1px solid;

    padding: ${isEditMode ? '1rem .5rem' : '.5rem'};
    border: ${isEditMode && `2px solid ${theme.colors.secondary}`};
    background-color: ${theme.colors.primaryLighten60};

    form {
      display: flex;
      flex-direction: column;

      .fields {
        display: flex;
        flex-direction: column;

        .buttons {
          > * + * {
            margin-left: 0.25rem;
          }
        }

        .input {
          display: flex;
          margin: 0.5rem 0;
          max-height: 1.75rem;
        }
      }

      footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.25rem;

        > * + * {
          margin-left: 0.25rem;
        }
      }
    }

    header {
      text-align: right;
      line-height: 1;

      + aside {
        margin-left: ${theme.spacings.xxsmall};
      }
    }

    .value {
      min-width: 4rem;
      text-align: right;
      margin-left: 0.5rem;
    }
  `}
`
