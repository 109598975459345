export default {
  listAll: {
    data: [],
    loading: false,
    page: 1,
    perPage: 50,
    total: 0,
    pages: 0,
    error: null,
    sortKey: 'name',
    sortDirection: 'ASC',
    sortKeyDefault: 'name',
    sortDirectionDefault: 'ASC'
  },
  listTransaction: {
    data: [],
    loading: false,
    page: 1,
    perPage: 50,
    total: 0,
    pages: 0,
    error: null
  },
  createTransaction: {
    loading: false,
    error: null,
    data: null
  }
}
