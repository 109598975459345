import React from 'react'
import { injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import SelectSearchable from '~/components/SelectSearchable'
import { setFilteredLocationType } from '~/store/modules/filters/actions'

/**
 * Componente para filtrar a listagem por tipo da loja.
 * Ele possibilita a seleção de diversos tipos de lojas e mantém no estado global: filters.channel.
 *
 * Não recebe nenhum parâmetro e retorna um select procurável.
 *
 * @example <FilterLocationType />
 */

const FilterLocationType = ({ intl }) => {
  const dispatch = useDispatch()

  const { locationType: filteredLocationType } = useSelector(
    state => state.filters
  )

  const types = [
    'local_store',
    'dark_store',
    'fulfillment_center',
    'customer_service_center'
  ]

  const type_option = types.map((item, index) => ({
    label: intl.formatMessage({ id: item }),
    value: item,
    step: index,
    type: item
  }))

  const handleChangeLocationType = selectedLocationType => {
    dispatch(
      setFilteredLocationType({ filteredLocationType: selectedLocationType })
    )
  }

  return (
    <SelectSearchable
      placeholder="Filtrar por tipo de loja"
      options={type_option}
      onChange={handleChangeLocationType}
      defaultValue={[filteredLocationType]}
    />
  )
}

export default injectIntl(FilterLocationType)

FilterLocationType.propTypes = {
  intl: PropTypes.shape()
}

FilterLocationType.defaultProps = {
  intl: null
}
