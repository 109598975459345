import React, { useEffect, useState } from 'react'

import { Scope } from '@unform/core'

import Loading from '~/components/Loading'
import { FormGroup, Label, Checkbox } from '~/components/unform'
import { brazilianStates } from '~/helpers'
import { useGetFetchQuery } from '~/hooks/useGetFetchQuery'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { listPlacesKey } from '../../services/place'

import * as S from './styles'

const PlacesCheckboxesList = ({ label = null, pathUnform = 'place_ids' }) => {
  const [loading, setLoading] = useState(true)
  const [locations, setLocations] = useState()

  const { t } = useAPPTranslation()

  const publisherId = useQueryParams().get('rmid')

  const QUERY_KEY = [listPlacesKey, publisherId]
  const data = useGetFetchQuery(QUERY_KEY) as ResponseListPlaces
  const error = false

  useEffect(() => {
    if (data) {
      const { data: placesRaw } = data

      const placesWithState = placesRaw.reduce((acc, place) => {
        if (!acc[`${place.state}`]) {
          acc[`${place.state}`] = []
        }

        acc[`${place.state}`].push(place)

        return acc
      }, {})

      const formattedLocations = locationsFiltered =>
        locationsFiltered
          .filter(({ active }) => active)
          .map(item => ({
            value: item.id,
            label: item.name
          }))

      const handlePlaces = Object.keys(placesWithState).map(item => ({
        label: brazilianStates?.[`${item}`] || item,
        options: formattedLocations(placesWithState[`${item}`])
      }))

      const customPlaces = []

      customPlaces.push({
        label: t('rm:campaign.create.Places.options.all.label'),
        value: 'all'
      })

      setLocations([...customPlaces, ...handlePlaces])

      setLoading(false)
    }

    if (error) {
      const formattedLocations = [
        {
          value: null,
          label: t('rm:campaign.create.Places.loading.error')
        }
      ]

      setLocations(formattedLocations)
      setLoading(false)
    }
  }, [data, error, t])

  //
  // const [selectAll, setSelectAll] = useState(true)

  // const handleToggleAll = useCallback(() => {
  //   setSelectAll(oldState => !oldState)
  // }, [])

  return (
    <FormGroup style={{ flex: 1 }}>
      {label && <Label htmlFor="location_ids" isRequired text={label} />}

      <Loading status={loading}>
        {t('rm:campaign.create.Places.loading')}
      </Loading>

      <Scope path={pathUnform}>
        {locations?.map(
          region =>
            region?.options && (
              <S.Group key={region?.label}>
                <Label
                  text={
                    region.label || t('rm:campaign.create.Places.unnamedRegion')
                  }
                />

                <S.LocationGroup>
                  {region.options?.map(location => (
                    <S.LocationItem key={location.value}>
                      <Checkbox
                        name={`${location.value}`}
                        id={`${location.value}`}
                        value={location.value}
                        label={location.label}
                        returnValue
                      />
                    </S.LocationItem>
                  ))}
                </S.LocationGroup>
              </S.Group>
            )
        )}
      </Scope>
    </FormGroup>
  )
}

export default PlacesCheckboxesList
