import React from 'react';

import PropTypes from 'prop-types';

import { InputNumber } from '~/components/unform';

const LimitsPerCustomer = ({ active }) => (
  <>
    Limitar a{' '}
    <InputNumber
      name="limits.per_customer"
      placeholder="Digite o valor"
      required
      disabled={!active}
      readOnly={!active}
    />{' '}
    uso(s) por cliente
  </>
);

export default LimitsPerCustomer;

LimitsPerCustomer.propTypes = {
  active: PropTypes.bool.isRequired,
};
