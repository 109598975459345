import { SET_SELECTED_TEMPLATE, RESET_SELECTED_TEMPLATE } from '../constants';

const index = {
  set: SET_SELECTED_TEMPLATE,
  reset: RESET_SELECTED_TEMPLATE,
};

export const templates = {
  /**
   * Define the selected template that will be used in the chat send field
   */
  [index.set]: ({ action, draft }) => {
    const selectedTemplate = action.payload;

    draft.selectedTemplate = selectedTemplate;
  },
  /**
   * Clear the selected template
   */
  [index.reset]: ({ draft }) => {
    draft.selectedTemplate = null;
  },
};
