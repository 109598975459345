export const masks = {
  date: '99/99/9999',
  phone: '(99)99999-9999',
  cpf: '999.999.999-99',
  cnpj: '999999999/9999-99',
  cep: '99999-999',
};

const limit = (val, max) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = `0${val}`;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '00';

      // this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
};

export const formatHour = val => {
  const hour = limit(val.substring(0, 2), '23');
  const minute = limit(val.substring(2, 4), '59');

  return hour + (minute.length ? `:${minute}` : '00');
};
