import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { t } from 'i18next'

import useQueryParams from '~/hooks/useQuery'

import { IndustryMovimentationsTable } from '../MovimentationsTable'
import { IndustryPaymentsTable } from '../PaymentsTable'
import Tabs from '../Tabs'

type TabNameInterface = 'movimentations' | 'transactions'

export const IndustryTransactionTable = () => {
  const { id, section } = useParams() as {
    id: string
    section: TabNameInterface
  }
  const publisherId = useQueryParams().get('rmid')

  const tabs: TabsProps['tabs'] = useMemo(
    () => [
      {
        to: `/retail-media/wallet/${id}/movimentations?rmid=${publisherId}`,
        label: t('rm:Movimentations')
      },
      {
        to: `/retail-media/wallet/${id}/transactions?rmid=${publisherId}`,
        label: t('rm:Payments')
      }
    ],
    [id, publisherId]
  )

  return (
    <>
      <Tabs tabs={tabs} />

      {section === 'movimentations' && <IndustryMovimentationsTable />}
      {section === 'transactions' && <IndustryPaymentsTable />}
    </>
  )
}
