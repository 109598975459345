import React from 'react';

import PropTypes from 'prop-types';

import { InputMoney } from '~/components/unform';

const TriggerTotalMinValue = ({ active, isSelectedOffers }) => (
  <>
    Aplicável a partir de{' '}
    <InputMoney
      name="trigger.total.min_value"
      placeholder="Digite o valor"
      required
      disabled={!active}
    />
    {isSelectedOffers && ' em produtos selecionados'}
  </>
);

export default TriggerTotalMinValue;

TriggerTotalMinValue.propTypes = {
  active: PropTypes.bool.isRequired,
  isSelectedOffers: PropTypes.bool.isRequired,
};
