import React from 'react';
import { BiCheckDouble, BiCheck, BiTime } from 'react-icons/bi';

import PropTypes from 'prop-types';

import { formatDateToHour } from '~/helpers';

const StatusTime = ({ date, status, isSent }) => {
  return (
    <aside>
      {!!date && <div className="time">{formatDateToHour(date)} </div>}
      {isSent && (
        <div className={`status ${status}`}>
          {status === 'pending' && <BiTime />}
          {status === 'delivered' && <BiCheck />}
          {status === 'read' && <BiCheckDouble />}
        </div>
      )}
    </aside>
  );
};

export default StatusTime;

StatusTime.propTypes = {
  date: PropTypes.string,
  status: PropTypes.string.isRequired,
  isSent: PropTypes.bool.isRequired,
};

StatusTime.defaultProps = {
  date: null,
};
