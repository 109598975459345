import styled, { css } from 'styled-components'

import { FormGroup, Label } from '~/components/unform'
import { device } from '~/styles/device'

export const DateWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    @media ${device.laptop_max} {
      flex-direction: column;
    }

    > div {
      display: flex;
      position: relative;

      @media ${device.laptop} {
        + div {
          margin-left: 2rem;
        }
      }

      @media ${device.laptop_max} {
        + div {
          border-top: 1px solid ${theme.colors.inative};
          padding-top: 0.5rem;
          margin-top: 0.5rem;
        }
      }

      &.end {
        @media ${device.laptop} {
          &::before {
            content: '';
            position: absolute;
            background-color: ${theme.colors.tertiary};

            height: 1px;
            width: 1rem;
            bottom: 1rem;
            left: -1rem;
            transform: translateX(-50%);
          }
        }
      }

      &.duration {
        @media ${device.laptop} {
          &::before {
            content: '';
            position: absolute;
            background-color: ${theme.colors.tertiary};

            bottom: 0;
            height: 100%;
            left: -1rem;
            width: 1px;
          }
        }
      }

      .formGroup {
        + .formGroup {
          margin-left: 1rem;
        }
      }
    }
  `}
`

export const Group = styled(FormGroup)`
  ${({ theme }) => css`
    & + & {
      margin-top: ${theme.spacings.xsmall};
    }

    > input {
      width: 100%;
    }

    .alertMessage {
      margin-top: 0.5rem;
    }

    /* &:last-child:not(:first-child) { */
    &:not(:first-child) {
      padding-top: ${theme.spacings.xsmall};
      border-top: 1px solid ${theme.colors.inative};
    }

    > .radioListWrapper {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      line-height: 1;
    }

    .customerSocialId {
      &.invalid {
        .value {
          color: ${theme.colors.danger};
        }
      }
    }
  `}
`

export const StrongTitle = styled(Label)`
  ${({ theme }) => css`
    font-weight: ${theme.font.bolder};
  `}
`

export const WrapperCheckbox = styled.div<WrapperCheckboxProps>`
  ${({ theme, hasEndDate }) => css`
    margin-top: 1.563rem;
    &::before {
      content: '';
      position: absolute;
      background-color: ${theme.colors.tertiary};
    }

    ${hasEndDate
      ? css`
          @media ${device.laptop} {
            &::before {
              height: 100%;
              width: 1px;

              bottom: 0;
              left: -1rem;
            }
          }
        `
      : css`
          @media ${device.laptop} {
            &::before {
              height: 1px;
              width: 1rem;

              bottom: 1rem;
              left: -1rem;

              transform: translateX(-50%);
            }
          }
        `}
  `}
`
