import React, { useMemo } from 'react';

import RulesList from '~/components/RulesList';
import { FormGroup, Input, Label } from '~/components/unform';

import { useMapDraw } from '../../../hooks/useMapDraw';
import DrawButtons from '../DrawButtons';

const MainFields = () => {
  const { isValidShape, editingRegion } = useMapDraw();

  const rules = {
    length: 'A área precisa ter no mínimo 3 pontos.',
  };

  const ruleErrors = useMemo(
    () => (isValidShape ? [] : ['length']),
    [isValidShape],
  );

  return (
    <div className="fields fields__main">
      <FormGroup>
        <Label htmlFor="name" isRequired>
          Nome da região
        </Label>

        <Input
          id="name"
          name="name"
          placeholder="Insira o nome da região"
          {...(editingRegion?.name
            ? { defaultValue: editingRegion?.name }
            : {})}
          autoFocus
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="name" isRequired>
          Tipo da região
        </Label>

        <DrawButtons />
      </FormGroup>

      <hr />

      <FormGroup>
        <RulesList rules={rules} ruleErrors={ruleErrors} />
      </FormGroup>
    </div>
  );
};

export default MainFields;
