import React, { useMemo } from 'react'
import {
  FiCheckCircle,
  FiExternalLink,
  FiGift,
  FiPlusSquare,
  FiRefreshCw,
  FiShoppingBag,
  FiShoppingCart,
  FiTag,
  FiXCircle
} from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import { Icon } from '~/components/Icon'
import Loading from '~/components/Loading'
import { Content, Header, WrapperShadow } from '~/components/WrapperShadow'
import { getAccountInfo } from '~/helpers/account-info'

const PanelExtras = () => {
  const {
    getOrderLoading,
    orderData: {
      gift_message: giftMessage,
      include_bag,
      allow_item_substitution,
      coupon_id,
      metadata: metadataRaw = { coupon_code: 'Ver cupom', cart_id: null }
    }
  } = useSelector(state => state.order)

  const { coupon_code, cart_id } = metadataRaw || { coupon_code: 'Ver cupom' }

  // Handle shopping cart link

  const { host } = getAccountInfo()

  const simpleHost = useMemo(() => host.split('.')[0], [host])

  const externalCartLink = useMemo(
    () =>
      cart_id
        ? `https://cart.newtail.com.br/${simpleHost}/cart/${cart_id}`
        : null,
    [simpleHost, cart_id]
  )

  return (
    <WrapperShadow>
      <Header>
        <div>
          <span>
            <Icon icon={FiPlusSquare} /> <strong>Informações extras</strong>
          </span>
        </div>
      </Header>

      <Loading status={getOrderLoading}>
        Carregando informações do pedido
      </Loading>

      {!getOrderLoading && (
        <Content className="extras">
          <div className="valuesWrapper">
            {/* Carrinho */}

            {!!cart_id && (
              <div className="value isIndicator info">
                <div className="subtitle">
                  <FiShoppingCart />
                  <strong>Carrinho</strong>{' '}
                </div>
                <div className="inlineBagde">
                  <Button
                    text="Ver carrinho"
                    iconRight={<FiExternalLink />}
                    template="primary"
                    as="a"
                    customWidth="auto"
                    size="xsmall"
                    href={externalCartLink}
                    target="_blank"
                  />
                </div>
              </div>
            )}

            {/* Cupom */}

            {!!coupon_id && (
              <div className="value isIndicator info">
                <div className="subtitle">
                  <FiTag />
                  <strong>Cupom aplicado</strong>{' '}
                </div>
                <div className="inlineBagde">
                  <Button
                    text={coupon_code}
                    iconRight={<FiExternalLink />}
                    template="primary"
                    as="a"
                    customWidth="auto"
                    size="xsmall"
                    href={`/promotional/view/${coupon_id}`}
                    target="_blank"
                  />
                </div>
              </div>
            )}

            {/* Sacola */}
            <div
              className={`value isIndicator ${
                include_bag ? 'success' : 'danger'
              }`}
            >
              <div className="subtitle">
                <FiShoppingBag /> <strong>Incluir sacola?</strong>{' '}
              </div>
              <div className="inlineBagde">
                <Button
                  text={include_bag ? 'Sim' : 'Não'}
                  iconLeft={
                    allow_item_substitution ? <FiCheckCircle /> : <FiXCircle />
                  }
                  template={include_bag ? 'success' : 'danger'}
                  as="span"
                  customWidth="auto"
                  size="xsmall"
                />
              </div>
            </div>

            {/* Subistituição */}
            <div
              className={`value isIndicator ${
                allow_item_substitution ? 'success' : 'danger'
              }`}
            >
              <div className="subtitle">
                <FiRefreshCw />
                <strong>Aceita substituição?</strong>{' '}
              </div>
              <div className="inlineBagde">
                <Button
                  text={allow_item_substitution ? 'Sim' : 'Não'}
                  iconLeft={
                    allow_item_substitution ? <FiCheckCircle /> : <FiXCircle />
                  }
                  template={allow_item_substitution ? 'success' : 'danger'}
                  as="span"
                  customWidth="auto"
                  size="xsmall"
                />
              </div>
            </div>
          </div>

          {giftMessage && (
            <div className="giftMessage">
              <div className="subtitle">
                <Icon icon={FiGift} /> <strong>Cartão com mensagem</strong>
              </div>
              <blockquote>{giftMessage}</blockquote>
            </div>
          )}
        </Content>
      )}
    </WrapperShadow>
  )
}

export default PanelExtras
