import {
  formatDateToHour,
  getDurationInDays,
  formatDateToString,
  formatMoney
} from '~/helpers'
import { PROMOTION_TYPES } from '~/modules/promotional/constants/promotionTypes'

import { getStatus } from '../helpers/getStatus'

export const modelPromotionsView = data => {
  const {
    start_promotion: startDate,
    end_promotion: endDate,
    promotion_type: promotionTypeRaw,
    benefits: benefitsRaw
  } = data

  const isCoupon = promotionTypeRaw === 'coupon'

  const promotion_trigger = isCoupon ? 'coupon' : 'auto'

  /**
   * Handle promotion type
   * Por que usamos esse virtual type?
   * A primeira concepção do promocional usava o tipo da promoção como a forma de definir sua mecânica.
   * Depois que o cupom foi implementando, tivemos o cupom, que é um método de ativação, salvo no type.
   * E a mecânica do cupom não ficou salva em lugar nenhum. Precisamos checar através do benefits qual é a mecânica.
   */

  const getVirtualPromotionType = () => {
    if (benefitsRaw?.shipping_discount) {
      return 'shipping_discount'
    }

    if (benefitsRaw?.items_discount) {
      return 'items_discount'
    }

    return promotionTypeRaw
  }

  const promotion_type = isCoupon ? getVirtualPromotionType() : promotionTypeRaw

  /**
   * Handle benefits
   */
  const formatBenefits = data => {
    const { type, value: valueRaw } = data

    let label = ''
    let value = valueRaw

    if (type === 'percent') {
      label = `${value}%`
    }

    if (type === 'fixed') {
      label = formatMoney(value) // Lidando com inputs Money
      value = label
    }

    const formattedData = {
      type,
      valueRaw,
      value,
      label
    }

    return formattedData
  }

  const handleBenefits = () => {
    if (benefitsRaw?.shipping_discount) {
      return {
        shipping_discount: formatBenefits(benefitsRaw.shipping_discount)
      }
    }

    if (benefitsRaw?.items_discount) {
      return {
        items_discount: formatBenefits(benefitsRaw.items_discount)
      }
    }

    return { ...benefitsRaw }
  }

  const benefits = handleBenefits()

  /**
   * Handle target
   * Target é como definimos se as regras do cupom serão aplicadas a certos produtos ou de forma geral.
   * Como isso, exibimos ou não certas informações na interface.
   */

  const hasOffersIds =
    data.trigger.total?.offers_ids?.length ||
    data.trigger.items?.offers_ids?.length

  const promotion_target = hasOffersIds ? 'selected' : 'all'

  /**
   * Handle locations
   */

  const hasLocations = data.location_ids?.length

  const locationsCheck = hasLocations ? 'selected' : 'all'

  const formatLocations = locationIds =>
    locationIds.reduce((acc, item) => ({ ...acc, [item]: true }), {})

  const location_ids = hasLocations ? formatLocations(data.location_ids) : []

  /**
   * Handle customers
   */
  const customersCheck = data.customers?.length ? 'selected' : 'all'

  return {
    ...data,
    promotion_type,
    benefits,
    start_date: formatDateToString(startDate, 'dddd - DD/MM/YY [às] HH:mm'),
    start_hour: formatDateToHour(startDate),
    end_date: formatDateToString(endDate, 'dddd - DD/MM/YY [às] HH:mm'),
    end_hour: formatDateToHour(endDate),
    duration: getDurationInDays({ startDate, endDate }),
    type: {
      type: promotion_type,
      label: PROMOTION_TYPES[promotion_type]
    },
    status: getStatus(data),
    location_ids, // Location array formatado para ser exibido no formulário {string: boolean}[]
    // Campo virtual apenas para uso na interface
    location_ids_raw: data.location_ids, // Locations raw como recebemos da API string[]
    locationsCheck,
    customersCheck,
    promotion_trigger,
    promotion_target,
    promotionRules: {
      totalUsage: !!data?.limits?.total,
      perCustomer: !!data?.limits?.per_customer,
      totalValue: !!data?.trigger?.total?.min_value,
      orderItems: !!data?.trigger?.items?.min_quantity
    }
  }
}
