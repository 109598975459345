import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { Filters } from '~/components/Filters'
import Loading from '~/components/Loading'
import { formatSearchString, queryDatesDatepickerIsolated } from '~/helpers'
import { getDashboarUrl } from '~/modules/chat/services'

import * as S from './styles'

const ExternalDashboard = ({
  dashboardId,
  filterLocations,
  filterChannels,
  useDate = false,
  customFilters
}) => {
  const [dashboardUrl, setDashboardUrl] = useState('')

  const {
    locations: filteredLocations,
    channel: filteredChannel,
    states: filteredState
  } = useSelector(state => state.filters)

  const { aggregate: filteredAggregate } = useSelector(
    state => state.filtersSellOut
  )

  const { startDate: start_date, endDate: end_date } = useSelector(
    state => state.datepickerHeader
  )

  const location_id = useMemo(
    () => filteredLocations?.value || null,
    [filteredLocations]
  )

  const channel = useMemo(
    () => filteredChannel?.value || null,
    [filteredChannel]
  )

  const state = useMemo(() => filteredState?.value || null, [filteredState])

  const aggregate = useMemo(
    () => filteredAggregate?.value || null,
    [filteredAggregate]
  )

  const queries = useMemo(() => {
    const dates = useDate
      ? queryDatesDatepickerIsolated(start_date, end_date)
      : {}

    const filters = formatSearchString({
      dashboard_id: dashboardId,
      channel,
      location_id,
      state,
      aggregate,
      ...dates,
      ...customFilters
    })

    return filters
  }, [
    dashboardId,
    useDate,
    start_date,
    end_date,
    channel,
    location_id,
    state,
    aggregate,
    customFilters
  ])

  useEffect(() => {
    async function fetchDashboard() {
      try {
        const { data } = await getDashboarUrl({ queries })

        setDashboardUrl(data.frame_url)
      } catch (error) {
        console.warn('Houve um erro ao gerar o relatório', error)
      }
    }

    fetchDashboard()
  }, [dashboardId, queries])

  return (
    <S.Container>
      {(filterLocations || filterChannels) && (
        <Filters locations={filterLocations} channel={filterChannels} />
      )}

      {!dashboardUrl ? (
        <Loading status>Carregando relatório...</Loading>
      ) : (
        <iframe src={dashboardUrl} title="relatorio" />
      )}
    </S.Container>
  )
}

export default ExternalDashboard

ExternalDashboard.propTypes = {
  dashboardId: PropTypes.number.isRequired,
  filterLocations: PropTypes.bool,
  filterChannels: PropTypes.bool,
  useDate: PropTypes.bool,
  customFilters: PropTypes.shape({})
}

ExternalDashboard.defaultProps = {
  filterLocations: false,
  filterChannels: false,
  useDate: false,
  customFilters: {}
}
