import React, { useCallback, useMemo } from 'react'
import { toastr } from 'react-redux-toastr'

import { t } from 'i18next'

import ToggleInput from '~/components/Toggle'
import { changeStatusKeywordService } from '~/modules/retailMedia/services/campaign-keyword'

const ToogleActiveCampaignKeyword = ({ data }: { data: CampaignKeyword }) => {
  const handleChange = useCallback(
    async ({ e, handleLoading }: HandleChange) => {
      const value = e.target.checked

      try {
        const id = data.id
        await changeStatusKeywordService({
          id,
          active: value
        })

        const status = value ? t('rm:activated') : t('rm:disabled')
        toastr.success(
          t('rm:toast.success'),
          t('rm:actions.keyword.activatedOrDisabled.success.message', {
            status
          })
        )

        handleLoading(value)
      } catch (error) {
        console.error(error)
        toastr.error(t('rm:toast.error'), t('rm:actions.keyword.edit.error'))
        handleLoading(!value)
      }
    },
    [data]
  )

  const isChecked = useMemo(() => !data.disabled_at, [data.disabled_at])

  return (
    <ToggleInput
      name={`active_${data?.id}`}
      isChecked={isChecked}
      onChange={handleChange}
      disabled={false}
      isAsync
      size="small"
    />
  )
}

export default ToogleActiveCampaignKeyword
