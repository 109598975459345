import React, { useState } from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons/'

import Input from './Input'

const InputPassword = ({ onChange, name, ...rest }) => {
  const [show, setShow] = useState(false)

  const EyeIcon = (
    <Button
      customWidth="auto"
      className="px-2"
      template="light"
      size="xsmall"
      onClick={() => setShow(state => !state)}
      tabIndex={-1}
    >
      {show ? <FiEye /> : <FiEyeOff />}
    </Button>
  )

  return (
    <Input
      name={name}
      onChange={onChange || null}
      autoComplete="off"
      htmlRightIcon={EyeIcon}
      type={show ? 'text' : 'password'}
      {...rest}
    />
  )
}

export default InputPassword

InputPassword.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired
}

InputPassword.defaultProps = {
  onChange: null
}
