import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { lighten } from 'polished'

import Alert from '~/components/Alert'
import LineChart from '~/components/Chart/LineChart'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import SelectSearchable from '~/components/SelectSearchable'
import { getRetailMediaAccountType } from '~/helpers'
import { handleErrorMessage } from '~/helpers/errors'
import { primary as theme } from '~/styles/themes'

import { defaultOptionsChart, metricsChartConfig } from './options'
import incomePreset from './presets/metrics/income'
import totalSpentPreset from './presets/metrics/totalSpent'
import { metricsOptionsSelect as metricsOptionsSelectRaw } from './selectOptions'

import * as S from './styles'

// import { Container } from './styles';

const Chart: React.FC<ChartProps> = ({
  isValidContent = true,
  chartData,
  loading = false,
  error = null,
  initialMetrics,
  additionalMetricsKeys = null,
  selectQuantity = 0,
  dynamicQuantity = false,
  maxQuantity = null,
  isRealtime = false
}) => {
  const { t } = useTranslation()

  const accountType = getRetailMediaAccountType()
  const metricsOptionsSelect = isRealtime
    ? metricsOptionsSelectRaw.publisherRealtime
    : metricsOptionsSelectRaw?.[accountType]

  const [selectedMetrics, setSelectedMetrics] =
    useState<ChartProps['initialMetrics']>(initialMetrics)

  /**
   * Aqui vamos armazenar quantos selects de métricas devemos usar.
   * Será útil de fato no caso de quantidade dinâmica.
   */
  const [selectedQtyMetrics, setQtySelectedMetrics] =
    useState<ChartProps['selectQuantity']>(selectQuantity)

  const chartDataIsReady = useMemo(() => !!chartData?.date?.length, [chartData])

  const metricsToShow = useMemo(
    () =>
      Object.values(selectedMetrics)
        .map(item => item)
        .filter(item => !!item),
    [selectedMetrics]
  )

  const handleChangeMetrics = ({ data, index }) => {
    setSelectedMetrics(prevState => ({
      ...prevState,
      [index]: data?.value || null
    }))
  }

  const getMetricsOptionsByIndex = useMemo(() => {
    const options = metricsOptionsSelect.map(item => ({
      ...item,
      isDisabled: Object.values(selectedMetrics).indexOf(item.value) > -1
    }))

    const getDefaultValue = (index: number) => {
      const selectedKey = selectedMetrics?.[index]

      const foundItem = () =>
        metricsOptionsSelect.find(item => item.value === selectedMetrics[index])

      return !selectedKey ? null : foundItem()
    }

    const data = (index: number) => ({
      defaultValue: getDefaultValue(index),
      options
    })

    const response = Array.from({ length: selectQuantity }).map((_, index) =>
      data(index)
    )

    return response
  }, [metricsOptionsSelect, selectQuantity, selectedMetrics])

  /**
   * ## `additionalData` Dados adicionais para o tooltip
   * Aqui vamos manipular os dados adicionais que serão exibidos no tooltip além das métricas
   * exibidas no gráfico. Caso alguma métrica seja selecionada para ser exibida no gráfico, ela
   * será ocultada desta lita.
   */
  const additionalData = useMemo(() => {
    if (!additionalMetricsKeys) return null

    const filterKeys = additionalMetricsKeys.filter(
      item => Object.values(selectedMetrics).indexOf(item) === -1
    )

    if (!filterKeys) return null

    type ConfigKeys = {
      [key in AvailablePresets]?: (index: number) => {
        label: string
        data: string
      }
    }

    const configKeys: ConfigKeys = {
      totalSpent: (index: number) => ({
        label: totalSpentPreset.settings.label,
        data: totalSpentPreset.settings.formatAdditionalData(
          chartData.totalSpentNumber[index]
        )
      }),
      income: (index: number) => ({
        label: incomePreset.settings.label,
        data: incomePreset.settings.formatAdditionalData(
          chartData.incomeNumber[index]
        )
      })
    }

    const getDataByDate = (index: number) =>
      filterKeys.reduce(
        (acc, item) => ({
          ...acc,
          [item]: configKeys?.[item](index)
        }),
        {}
      )

    return chartData?.date.reduce(
      (acc, item, index) => ({
        ...acc,
        [`${item}`]: getDataByDate(index)
      }),
      {}
    )
  }, [
    chartData?.date,
    chartData?.incomeNumber,
    chartData?.totalSpentNumber,
    selectedMetrics,
    additionalMetricsKeys
  ])

  /**
   * Handle data
   */

  const chartConfig = useMemo(
    () =>
      metricsChartConfig({
        data: chartData,
        metricPresets: 'dynamic',
        metrics: metricsToShow
      }),
    [chartData, metricsToShow]
  )

  /**
   * Config chart
   */

  const optionsChart = useMemo(
    () =>
      defaultOptionsChart({
        metricPresets: 'dynamic',
        metrics: metricsToShow,
        data: chartData,
        additionalData
      }),
    [chartData, metricsToShow, additionalData]
  )

  /**
   * Styles select
   */

  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: lighten(0.3, color),
      border: `2px solid ${color}`,
      borderRadius: 4,
      content: '" "',
      display: 'block',
      marginRight: 4,
      height: 12,
      width: 12,
      minWidth: 12
    }
  })

  const customStyles = {
    valueContainer: base => ({
      ...base,
      overflow: 'hidden',
      flexWrap: 'nowrap'
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...dot(data.color),
      whiteSpace: 'normal',
      lineHeight: 0.875,
      fontSize: 13
    }),
    menuList: styles => ({
      ...styles,
      display: 'flex',
      flexDirection: 'column'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const { color } = data
      return {
        ...styles,
        position: 'relative',
        backgroundColor: (() => {
          return isSelected || isDisabled
            ? theme.colors.secondaryLighten30
            : theme.colors.white
        })(),
        color: isDisabled ? theme.colors.dark : theme.dark,
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontWeight: isFocused ? 'bold' : 'normal',
        // fontSize: 12,

        ':active': {
          ...styles[':active'],
          backgroundColor: () => {
            if (isFocused) {
              return theme.colors.infoLighten50
            }
            if (!isDisabled) {
              return isSelected
                ? theme.colors.light
                : theme.colors.secondaryLighten30
            }
          }
        },
        ...dot(color)
        // ...(isSelected && metricsToShow.length > 1 ? removeOption() : {})
      }
    }
  }

  return (
    <Panel>
      <div className="wrapperChart">
        <Loading status={loading}>{t('rm:metrics.loading')}...</Loading>

        <Alert active={!!error}>
          Erro:{' '}
          {handleErrorMessage({
            error,
            fallbackMessage: t('rm:chart.loadingError.fallback')
          })}
        </Alert>

        <S.ContainerSelects className="selectMetrics" style={{}}>
          {Array.from({ length: selectQuantity }).map((_, index) => (
            <div className="selectMetrics-item" key={index}>
              <SelectSearchable
                options={getMetricsOptionsByIndex[index].options}
                defaultValue={getMetricsOptionsByIndex[index].defaultValue}
                placeholder={t('rm:chart.selectMetric.placeholder')}
                onChange={data => handleChangeMetrics({ data, index: index })}
                isClearable={metricsToShow.length > 1}
                styles={customStyles}
              />
            </div>
          ))}
        </S.ContainerSelects>

        {isValidContent && !loading && !!chartData && (
          <>
            {!!chartDataIsReady && (
              <LineChart
                datasets={chartConfig.datasets}
                labels={chartConfig.labels}
                optionsChart={optionsChart}
              />
            )}
          </>
        )}
      </div>
    </Panel>
  )
}

export default Chart
