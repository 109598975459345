import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const NavbarUserAvatarWrapper = styled.span`
  ${({ theme }) => css`
    position: relative;

    button {
      &:hover {
        background-color: inherit;
        border-color: transparent;
      }

      &.user-info {
        &.btn {
          &-white {
            --userNameColor: var(--dark);
            --iconRoleColor: var(--warning);
          }

          &-primary {
            --userNameColor: var(--light);
            --iconRoleColor: var(--warning);
          }

          .user_info-wrapper {
            display: flex;
            flex-direction: column;
            line-height: 1.25;

            span {
              text-align: right;

              &.account-name {
                font-size: ${theme.font.sizes.xsmall};
                color: var(--userNameColor);
                opacity: 0.5;
              }
            }

            .user-name {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              color: var(--userNameColor);
              line-height: 1.125;

              .name {
                max-width: 30vw;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .user-role-icon {
                display: inline-flex;
                margin-left: 0.325rem;
                font-size: 0.75rem;
                color: var(--iconRoleColor);
              }
            }
          }
        }
      }
    }

    > a {
      z-index: 1;
      position: relative;
      padding-left: 0;
    }
  `}
`

// Novo

export const MainHeader = styled.header`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    height: ${theme.topBarHeight};
    background-color: var(--headerColor);

    color: ${theme.colors.light};

    .pageTitle {
      padding-left: 2.25rem;

      @media ${device.laptop} {
        display: none;
      }

      h1 {
        color: var(--light);
        margin-bottom: 0;
        font-size: 0.875rem;
        line-height: 1.1125;
      }
    }

    aside {
      display: flex;
      align-items: center;
      gap: 1rem;

      @media ${device.laptop} {
        margin-right: 1rem;
      }
    }
  `}
`
