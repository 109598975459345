import { t } from 'i18next'

import AlertInfo from '~/components/AlertInfo'
import { joinWithCommasAndAnd } from '~/helpers'

const AlertBrandsPureComponent: React.FC<AlertBrandsPureComponentProps> = ({
  publisherBrands = []
}) => {
  return (
    <>
      {!!publisherBrands?.length && (
        <AlertInfo
          template="info"
          text={
            <p className="mb-0">
              {t('rm:publisher.multiple.brands.message')}{' '}
              <strong>
                {joinWithCommasAndAnd({
                  values: publisherBrands?.map(({ name }) => name)
                })}
              </strong>
              .
            </p>
          }
        />
      )}
    </>
  )
}

export default AlertBrandsPureComponent
