import React from 'react'

import Breadcrumb from '../Breadcrumb'
import DatepickerHeader from '../datepicker-header'

import * as S from './styles'

/**
 * @example
 *
 *   <HeaderPages
 *     title="Adicionar Produtos"
 *     backTo="/products/list"
 *     sideRightComponent={
 *       <Button
 *         template="light"
 *         text="Adição em massa"
 *         customWidth="auto"
 *         onClick={() => {}}
 *       />
 *     }
 *   />
 *
 * @param {string} title Título da página
 * @param {string} subtitle Subtítulo da página
 * @param {string} backTo URL relativa que deve ser chamada no click - Permite abrir em nova aba
 * @param {fucntion} goBack Função para usar o go back last do history - Não permite abrir em nova aba
 * @param {ReactElement|string} sideRightComponent Algo pare ser renderizado na parte direita do cabeçalho
 * @param {string} className Classes adicionais para o cabeçalho
 * @param {boolean} injectDatepicker Informa se deve injetar o componente padrão seletor de data
 *
 */

export interface HeaderPageProps {
  title?: string
  subtitle?: string | React.ReactNode
  backTo?: string
  goBack?: () => void
  labelBreadcrumb?: string
  sideRightComponent?: string | React.ReactNode
  sideLeftComponent?: string | React.ReactNode
  className?: string
  titleSideRightComponent?: React.ReactNode
  injectDatepicker?: boolean
}

const HeaderPages = ({
  title,
  subtitle,
  backTo,
  goBack,
  labelBreadcrumb,
  sideRightComponent,
  sideLeftComponent,
  className: classNameRaw,
  titleSideRightComponent,
  injectDatepicker
}: HeaderPageProps) => {
  const className = `${injectDatepicker && `has-datepicker`} ${classNameRaw}`

  return (
    <S.Container className="HeaderPages__Container">
      {(backTo || goBack) && (
        <Breadcrumb backTo={backTo} goBack={goBack} label={labelBreadcrumb} />
      )}

      <S.MainInfo {...(className ? { className } : {})}>
        {titleSideRightComponent && (
          <div className="titleSideRightComponent">
            {titleSideRightComponent}
          </div>
        )}

        {(title || subtitle) && (
          <div className="title">
            <div className="wrap">
              <h1>{title}</h1>
              {subtitle && <div className="subtitle">{subtitle}</div>}
            </div>
            <div className="wrap">{sideLeftComponent}</div>
          </div>
        )}

        {(sideRightComponent || injectDatepicker) && (
          <div className="sideRightComponent">
            {sideRightComponent}
            {injectDatepicker && <DatepickerHeader />}
          </div>
        )}
      </S.MainInfo>
    </S.Container>
  )
}

export default HeaderPages

HeaderPages.defaultProps = {
  subtitle: '',
  className: '',
  sideRightComponent: '',
  sideLeftComponent: '',
  backTo: null,
  goBack: null,
  labelBreadcrumb: null,
  injectDatepicker: false
}
