import { history } from '~/helpers'
import { campaign as service } from '~/modules/retailMedia/services/industry'
import * as report from '~/modules/retailMedia/services/report'
import { t } from 'i18next'

import { types } from './constants'

/**
 * Get reports */
export const reportIndustryCampaigns = (
  queries: GetCampaignReportRequestParams
) => ({
  type: types.report.get,
  payload: report.getCampaignsReportService(queries)
})

/**
 * Get all with history*/
export const reportIndustryCampaignsHistory = (
  queries: GetCampaignReportRequestParams
) => ({
  type: types.report.history,
  payload: report.getCampaignHistoryReportService(queries)
})

/**
 * Create */
export const createIndustryCampaigns = ({
  body,
  successFeedbackMsg = t('rm:actions.campaign.create.success'),
  errorFeedbackMsg = t('rm:actions.campaign.create.error')
}) => ({
  type: types.create,
  payload: service.create(body),
  successFeedbackMsg,
  errorFeedbackMsg,
  onSuccess: ({ data }) => {
    // eslint-disable-next-line no-underscore-dangle
    history.push(`/retail-media/campaign/view/${data.id}`)
  }
})

/**
 * Upload file */
const upload = ({
  body,
  axiosOptions = {},
  successFeedbackMsg = t('rm:actions.campaign.upload.media.success'),
  errorFeedbackMsg = t('rm:actions.campaign.upload.media.error'),
  onSuccess
}) => ({
  type: types.upload,
  payload: service.upload({ body, axiosOptions }),
  successFeedbackMsg,
  errorFeedbackMsg,
  onSuccess
})

/**
 * Get one */
type GetProps = {
  requestInBackground?: boolean
  account_info: boolean
  start_date: string
  end_date: string
  campaign_id: string
}

const get = ({ requestInBackground, ...data }: GetProps) => ({
  type: types.get, // Handle on src/modules/retailMedia/store/industry/campaign/reducerCases/get.ts
  payload: service.get(data),
  values: { requestInBackground }
})

// const cancelCampaign = id => ({
//   type: types.cancelCamapign,
//   payload: service.cancelCampaign(id)
// })

/**
 * Update
 *
 * @param {string} data.id Id do item atualizado
 * @param {object} data.body Corpo da requisição de atualização
 */
const update = data => ({
  type: types.update,
  payload: service.update(data),
  successFeedbackMsg: t('rm:actions.campaign.edit.success'),
  errorFeedbackMsg: t('rm:actions.campaign.edit.error'),
  onSuccess: response => {
    // eslint-disable-next-line no-underscore-dangle
    history.push(`/retail-media/campaign/view/${data.id}`)
  }
})

export const patch = ({
  onSuccess = null,
  successFeedbackMsg = t('rm:actions.campaign.edit.successEdit'),
  ...data
}) => ({
  type: types.patch,
  payload: service.patch(data),
  successFeedbackMsg,
  errorFeedbackMsg: t('rm:actions.campaign.edit.error'),
  onSuccess
})
/**
 * Toggle active
 *
 * @param {string} data.id Id do item atualizado
 * @param {object} data.body Corpo da requisição de atualização
 */

export const toggleActiveCampaign = ({ onSuccess, ...data }) => ({
  type: types.patch,
  payload: service.patch(data),
  successFeedbackMsg: t('rm:actions.campaign.edit.success'),
  errorFeedbackMsg: `${t('rm:actions.campaign.edit.error')} [active]`,
  onSuccess
})

/**
 * Edit campaign
 */

export const editCampaign = ({ data, onSuccess }) => ({
  type: types.patch_product,
  payload: service.patch(data),
  successFeedbackMsg: t('rm:actions.campaign.add.products.success'),
  errorFeedbackMsg: t('rm:actions.campaign.add.ads.error'),
  onSuccess
})

/**
 * Delete
 *
 * @param {string} data.id Id do item atualizado
 */
const remove = ({ id, onSuccess }) => ({
  type: types.remove,
  payload: service.remove(id),
  onSuccess
})

/**
 * Get dashboard data */
const dashboard = () => ({
  type: types.dashboard,
  payload: service.dashboard.get(),
  errorFeedbackMsg: t('rm:actions.campaign.dash.list.error')
})

/**
 * Adiciona uma lista de IDS de produtos
 */

// export const addProductsBulk = ({ productsIds }) => {
//   const external_ids = productsIds
//     .toString()
//     .replace(/[,;]/g, '\n')
//     .replace(/\r\n/g, '\n')
//     .split('\n')
//     .filter(item => !!item.trim());

//   return {
//     type: types.ADD_PRODUCTS_BULK,
//     payload: searchOffersService({ external_ids }),
//   };
// };

/**
 * Adiciona um produto na lista de selecionados
 */

export const addProductOnCampaign = ({ product }) => ({
  type: types.product.add,
  payload: product
})

/**
 * Remove um produto da lista de selecionados
 */

export const deleteProductOnCampaign = ({ product }) => ({
  type: types.product.remove,
  payload: product
})

/**
 * Remove todos os produto da lista de selecionados
 */

export const deleteAllProductsOnCampaign = (isEdit?: boolean) => {
  return {
    type: types.product.remove_all,
    payload: isEdit
  }
}

/**
 * Limpa store
 */

export const clearCampaignStore = () => ({
  type: types.clearStore
})

/**
 * Adicionar uma lista de SKUs de produtos
 */
export const addProducts = ({ body }) => {
  const external_ids = body.product_skus
    .toString()
    .replace(/[,;]/g, '\n')
    .replace(/\r\n/g, '\n')
    .split('\n')
    .filter(item => !!item.trim())

  return {
    type: types.product.addBulk,
    values: { product_skus: external_ids },
    payload: service.searchOffersService({
      ...body,
      product_skus: external_ids
    })
  }
}

export default {
  get,
  update,
  patch,
  upload,
  remove,
  dashboard,
  // cancelCampaign,
  deleteAllProductsOnCampaign,
  addProducts
}
