/* eslint-disable max-len */
import React, { useMemo, useCallback } from 'react';
import { RiFileExcel2Line } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import axios from 'axios';
import FileDownload from 'js-file-download';

import { Button } from '~/components/Buttons';
import FilterChannel from '~/components/FilterChannel';
import FilterLocations from '~/components/FilterLocations';
import { FiltersWrapper } from '~/components/Filters/styles';
import {
  formatSearchString,
  formatDateToString,
  queryDatesDatepicker,
} from '~/helpers';

export const Actions = () => {
  const { startDate, endDate } = useSelector(state => state.datepickerHeader);

  const { locations: filteredLocations, channel: filteredChannel } =
    useSelector(state => state.filters);

  const filteredLocationsIds = useMemo(
    () => filteredLocations?.value || null,
    [filteredLocations],
  );

  const filteredChannelId = useMemo(
    () => filteredChannel?.value || null,
    [filteredChannel],
  );

  const queryString = useMemo(
    () =>
      formatSearchString({
        location_id: [filteredLocationsIds],
        channel_id: filteredChannelId,
        ...queryDatesDatepicker(startDate, endDate),
      }),
    [filteredLocationsIds, filteredChannelId, startDate, endDate],
  );

  const url = useMemo(
    () =>
      `/api/data-service/order-payments/by-location/download?${queryString}`,
    [queryString],
  );

  const fileName = useMemo(() => {
    const locationName = filteredLocations?.label || 'Geral';
    const channelName = filteredChannel?.label || 'Todos os canais';
    const startDateFormatted = formatDateToString(startDate, 'DD.MM.YYYY');
    const endDateFormatted = formatDateToString(endDate, 'DD.MM.YYYY');

    const finalName = `Relatório de pagamentos - ${locationName} - ${channelName} (${startDateFormatted} até ${endDateFormatted})`;

    return finalName;
  }, [endDate, filteredChannel?.label, filteredLocations?.label, startDate]);

  const handleDownloadXLS = useCallback(async () => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });

      FileDownload(response.data, `${fileName}.xlsx`);
    } catch (error) {
      toastr.error('Erro', 'Houve um erro ao gerar a planilha.');

      console.warn('Houve um erro ao gerar a planilha.', error);
    }
  }, [url, fileName]);

  return (
    <FiltersWrapper>
      <div className="filterWrapper selectSearchableWrapper">
        <FilterLocations />
      </div>

      <div className="filterWrapper selectSearchableWrapper">
        <FilterChannel />
      </div>

      {/* <div className="filterWrapper selectSearchableWrapper">
        <FilterPaymentMethod />
      </div> */}

      <div className="align__right">
        <Button
          onClick={handleDownloadXLS}
          text="Exportar para Excel"
          iconLeft={<RiFileExcel2Line />}
        />
      </div>
    </FiltersWrapper>
  );
};
