import styled, { css } from 'styled-components';

export const SidebarButtons = styled.nav`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  `}
`;

const sidebarButtonModifiers = {
  isActive: theme => css`
    border: 1px solid ${theme.colors.primary};
    opacity: 1;
  `,
  isEditingMode: () => css`
    opacity: 0.5;
  `,
};

export const SidebarButton = styled.button`
  ${({ theme, isActive, isEditingMode }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid ${theme.colors.tertiary};

    line-height: 1.125;

    transition: ease-in-out 0.3s all;

    &:hover {
      border: 1px solid ${theme.colors.hover};
      opacity: 1;
    }

    .title {
      margin: 0.5rem 0 0.25rem;
      font-size: 12px;
    }
    .description {
      color: ${theme.colors.gray};
      font-size: 12px;
    }

    ${isEditingMode && sidebarButtonModifiers.isEditingMode(theme)}
    ${isActive && sidebarButtonModifiers.isActive(theme)}
  `}
`;
