import React from 'react';

import { paymentMethods } from '~/helpers';

import { AuthorizationTypes } from './types';

const PaymentMethodInfo = ({ payment }) => {
  if (!payment?.method_info) return null;

  return <> - {paymentMethods(payment.method_info)} </>;
};

PaymentMethodInfo.propTypes = AuthorizationTypes;

export default PaymentMethodInfo;
