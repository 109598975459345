import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const deleteButtonTemplate = ({ theme }: ButtonTemplatesProps) => css`
  --primaryColor: ${theme.colors.danger};

  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  background-color: white;

  &:not(:disabled) {
    &:hover {
      background-color: var(--primaryColor);
      color: white;
    }
  }

  &:disabled {
    opacity: 1;
    filter: brightness(0.9);
  }
`

export default deleteButtonTemplate
