import styled from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.div<ContainerProps>`
  --bgIconColor: rgba(255, 255, 255, 0.5);
  --bgIconColorHover: white;
  --iconColor: var(--dark);
  --iconColorHover: var(--primary);

  --bgIconColor-show: white;
  --iconColor-show: var(--primary);
  --iconOutlineColor-show: var(--light);

  &[data-wl='true'] {
    --bgIconColor: var(--light);
    --bgIconColorHover: var(--tertiary);
    --iconColor: var(--dark);
    --iconColorHover: var(--primary);
  }

  .help-widget {
    button {
      padding: 0.125rem;
      border-radius: 50%;
      font-size: 16px;
      height: 24px;
      width: 24px;
      background-color: var(--bgIconColor);
      color: var(--iconColor);

      &:hover {
        background-color: var(--bgIconColorHover);
        color: var(--iconColorHover);
      }
    }
  }

  .show & {
    .help-widget {
      button {
        background-color: var(--bgIconColor-show);
        color: var(--iconColor-show);
        outline: 1px solid var(--iconOutlineColor-show);
      }
    }

    .Tooltip__Content {
      display: none;
    }
  }

  .Tooltip__Container {
    margin-left: 0;

    .Tooltip__Content {
      transform: translateY(0.5rem);
    }
  }

  > a.nav-link.help-widget {
    @media ${device.mobileL_max} {
      padding-right: 0px !important;
      padding-left: 0 !important;

      button {
        border-radius: 50% 0 0 50%;
      }
    }
  }
`
