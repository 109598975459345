import React from 'react'
import { FiSave, FiX } from 'react-icons/fi'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'

import { ActionRow } from './styles'

const ActionSection = ({ onCancel, onSave, updateLoading, isEditMode }) =>
  isEditMode ? (
    <ActionRow>
      <Button
        text="Cancelar"
        iconLeft={<FiX />}
        className="button__cancel"
        size="small"
        customWidth="auto"
        onClick={onCancel}
        template="transparentDanger"
        loading={updateLoading}
      />

      <Button
        text="Salvar"
        iconLeft={<FiSave />}
        size="small"
        customWidth="auto"
        onClick={onSave}
        template="success"
        loading={updateLoading}
      />
    </ActionRow>
  ) : (
    <></>
  )

export default ActionSection

ActionSection.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  updateLoading: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired
}
