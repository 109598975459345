import React, { PureComponent } from 'react'
import 'react-dates/initialize'
import DayPickerRangeController from 'react-dates/lib/components/DayPickerRangeController'
import { connect } from 'react-redux'

import i18next from 'i18next'
import moment from 'moment'
import PropTypes from 'prop-types'

import i18n from '~/i18n'

import DatePresets from '../datepicker/Presets'
// import 'moment/locale/pt-br'
// import 'moment/locale/es'
// import 'moment/locale/en-sg'

const language = i18n.language || 'pt-BR'
moment.locale(language)

export class Calendar extends PureComponent {
  constructor(props) {
    super()
    this.state = {
      focusedInput: 'startDate'
    }

    this.changeDate = this.changeDate.bind(this)
    this.isOutsideRange = this.isOutsideRange.bind(this)
    this.outsideClick = this.outsideClick.bind(this)
  }

  static defaultProps = {
    onChange: () => {},
    label: i18next.t('common:words.date'),
    align: 'left',
    monthsToShow: 2,
    range: 48,
    initialMonth: moment().subtract(1, 'month'),
    disabled: false,
    isMobile: false,
    isRangeDate: false,
    isOutsideRange: () => false
  }

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultStartDate: PropTypes.instanceOf(moment),
    defaultEndDate: PropTypes.instanceOf(moment),
    defaultSingleDate: PropTypes.instanceOf(moment),
    label: PropTypes.string,
    align: PropTypes.oneOf(['left', 'right']),
    monthsToShow: PropTypes.number,
    initialMonth: PropTypes.instanceOf(moment),
    isMobile: PropTypes.bool,
    locale: PropTypes.string,
    isRangeDate: PropTypes.bool
  }

  changeDate({ startDate, endDate, isPreset = false }) {
    const newState = {}

    let dateValues = {
      startDate,
      endDate: null
    }

    if ((this.state.focusedInput === 'endDate' || isPreset) && endDate) {
      dateValues.endDate = endDate
      newState.focusedInput = 'startDate'
    }

    if (!this.props.isRangeDate) {
      dateValues = {
        date: startDate
      }
      newState.focusedInput = 'startDate'
    }

    this.props.onChange(dateValues)

    this.setState({ ...newState })
  }

  isOutsideRange(day) {
    const { range, startDate } = this.props
    const { focusedInput } = this.state
    let endIsOutside = false

    if (focusedInput === 'endDate') {
      endIsOutside = day.isAfter(moment(startDate).add(range, 'months'))
    }

    return endIsOutside || this.props.isOutsideRange(day)
  }

  outsideClick() {
    const { startDate, endDate, isRangeDate } = this.props
    let resetDates = {}

    if (!startDate && isRangeDate) {
      resetDates = { startDate: null, endDate: null }
    } else if (!endDate && isRangeDate) {
      resetDates = { startDate, endDate: startDate }
    }
    this.setState({ focusedInput: 'startDate' })
    this.props.onChange(resetDates)
    this.props.onOutsideClick()
  }

  //

  render() {
    const {
      monthsToShow,
      isMobile,
      onOutsideClick,
      startDate,
      endDate,
      date,
      initialMonth
    } = this.props

    return (
      <DayPickerRangeController
        startDate={startDate || date}
        endDate={endDate}
        onDatesChange={({ startDate, endDate }) => {
          this.changeDate({ startDate, endDate })
        }}
        displayFormat={i18next.t('common:patterns.date')}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.setState({ focusedInput })}
        hideKeyboardShortcutsPanel
        numberOfMonths={isMobile ? 1 : monthsToShow}
        isOutsideRange={this.isOutsideRange}
        onOutsideClick={onOutsideClick && this.outsideClick}
        minimumNights={0}
        initialVisibleMonth={() => initialMonth}
        renderCalendarInfo={() => (
          <DatePresets
            // startDate={startDate}
            // endDate={endDate}
            // dateFormat={dateFormat}
            handlePresets={(start, end) => {
              this.changeDate({
                startDate: start,
                endDate: end,
                isPreset: true
              })
            }}
          />
        )}
      />
    )
  }
}

export default connect(
  state => ({
    isMobile: state.layout.isMobile
  }),
  {}
)(Calendar)
