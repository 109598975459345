// import { isAfter, isBefore, parseJSON } from 'date-fns'
// import {
//   AlternativeProduct,
//   ItemConfirmed,
//   MainProduct
// } from 'services/shoppingCart/types'

export const formatPrices = item => {
  // const today = new Date()

  // const promotionStart =
  //   item?.main_product?.start_promotion &&
  //   parseJSON(item.main_product.start_promotion)

  // const promotionEnd =
  //   item?.main_product?.end_promotion &&
  //   parseJSON(item.main_product.end_promotion)

  const promotionIsActive = false;
  // promotionStart && promotionEnd
  //   ? isAfter(today, promotionStart) && isBefore(today, promotionEnd)
  //   : false

  const originalPrice = promotionIsActive
    ? item?.main_product?.promotional_price
    : item?.main_product?.price;

  return {
    startPromotionalPrice: item?.main_product?.start_promotion || null,
    endPromotionalPrice: item?.main_product?.end_promotion || null,
    // promotionIsActive,
    // before: promotionIsActive ? item?.main_product?.price : null,
    originalPrice,
    subtotal: item.sub_total || null,
    discount: item.discount || 0,
  };
};

export const formatPricesOffer = item => {
  // const today = new Date()

  // const promotionStart =
  //   item?.start_promotion && parseJSON(item.start_promotion)

  // const promotionEnd = item?.end_promotion && parseJSON(item.end_promotion)

  const promotionIsActive = false;
  // promotionStart && promotionEnd
  //   ? isAfter(today, promotionStart) && isBefore(today, promotionEnd)
  //   : false

  const originalPrice = promotionIsActive
    ? item?.promotional_price
    : item?.price;

  return {
    startPromotionalPrice: item?.start_promotion || null,
    endPromotionalPrice: item?.end_promotion || null,
    // promotionIsActive,
    // before: promotionIsActive ? item?.price : null,
    originalPrice,
    discount: item?.discount || 0,
  };
};
