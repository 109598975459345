import styled, { css } from 'styled-components'

export const WrapperTabler = styled.div`
  margin-top: 1rem;
`

// export const EmptyPage = styled.div`
//   ${({ theme }) => css`
//     display: flex;
//     justify-content: center;
//     background-color: ${theme.colors.neutral.high.lightest};
//     border-radius: ${theme.border.radius.large};
//     margin: ${theme.spacings.xsmall} 0;
//     padding: ${theme.spacings.xlarge};
//   `}
// `
