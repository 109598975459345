import Loading from '../Loading'

import * as S from './styles'

const CardNew = ({ title, value, compare, isLoading }: CardNewProps) => {
  return (
    <S.Container>
      <header className="title">{title}</header>

      <Loading status={isLoading} onlyLine />

      {!isLoading && (
        <div className="content">
          <div className="value">{value}</div>
          {compare && <div className="compare"> / {compare}</div>}
        </div>
      )}
    </S.Container>
  )
}

export default CardNew
