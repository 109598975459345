import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Scope } from '@unform/core'

import Alert from '~/components/Alert'
import AlertInfo from '~/components/AlertInfo'
import { ButtonNavLink } from '~/components/Buttons'
import Panel from '~/components/Panel'
import { FormGroup, Input, Label } from '~/components/unform'
import { Checkbox } from '~/components/unform/Checkbox'
import { useAlert } from '~/hooks/useAlert'

import { indexLocationDeliveryTypes, setMapCoordinates } from '../../actions'

const DeliveryTypes = ({ initialData }) => {
  const { id } = useParams()

  const {
    location,
    listLocationsDeliveryTypes: list,
    listLocationsDeliveryError: error
  } = useSelector(state => state.locations)

  const [AlertOnError] = useAlert(error)

  const dispatch = useDispatch()
  const loadLocationDeliveryTypes = useCallback(() => {
    dispatch(indexLocationDeliveryTypes())
  }, [dispatch])

  useEffect(() => {
    if (!list) {
      loadLocationDeliveryTypes()
    }
  }, [list, loadLocationDeliveryTypes])

  const handleChangeRadius = e => {
    const { name, value } = e

    const regex = /[^[\]]+(?=])/
    const idDeliveryType = name.match(regex)
    dispatch(
      setMapCoordinates({
        [`${idDeliveryType[0]}`]: value,
        zoom: 13.25 - value * 0.125
      })
    )
  }

  const typeLabel = {
    shipping_from_store: 'entrega em casa',
    pick_up_in_store: 'retirada na loja'
  }

  return (
    <fieldset>
      <AlertOnError />

      {!error && list && (
        <Panel
          title="Configurações de entrega"
          description="Defina abaixo quais métodos de entrega estão disponíveis para este local. Você pode definir o raio de atuação nesta página ou usar regiões de entregas."
          className="form-grid"
          headerSideContent={
            <ButtonNavLink
              size="small"
              text="Usar regiões de entregas"
              to={`/logistic/${id}/regions`}
            />
          }
        >
          {list.map(item => (
            <Scope path={`deliveryTypes[${item.id}]`} key={item.id}>
              <FormGroup className="span2">
                <div
                  style={{
                    marginBottom: '.5rem',
                    paddingBottom: '.5rem',
                    borderBottom: '1px solid #ddd'
                  }}
                >
                  <Checkbox
                    name="active"
                    label={`Ativar: ${item.name}`}
                    id={item.id}
                  />
                </div>

                <div>
                  <Label
                    htmlFor="delivery_radius"
                    helperText="Para desativar atuação por raio, utilize 0 (zero) como raio."
                  >
                    Raio de atuação para {typeLabel?.[item?.type] || item?.type}
                  </Label>

                  <Input
                    name="delivery_radius"
                    placeholder="Insira o raio em km"
                    type="number"
                    min="0"
                    step=".001"
                    onChange={handleChangeRadius}
                    htmlRightIcon="km"
                    // disabled={!initialData?.deliveryTypes[item.id].active}
                  />
                </div>
              </FormGroup>
            </Scope>
          ))}
        </Panel>
      )}
    </fieldset>
  )
}

export default DeliveryTypes
