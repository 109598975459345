export const optionsGender = [
  {
    id: `gender_female'`,
    value: 'female',
    label: 'Feminino',
  },
  {
    id: `gender_male`,
    value: 'male',
    label: 'Masculino',
  },
  {
    id: `gender_unknown`,
    value: 'unknown',
    label: 'Não informado',
  },
];
