import { useEffect, useMemo, useState } from 'react'
import { FiCopy, FiInfo, FiClock } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { t } from 'i18next'

import pixLogo from '~/assets/img/brands/pix-logo.png'
import AlertInfo from '~/components/AlertInfo'
import { ButtonCopyToClipboard } from '~/components/Buttons'
import Countdown from '~/components/CountDown'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import Panel from '~/components/Panel'
import { Badge } from '~/components/UI/Badge'
import { Label } from '~/components/unform'
import { formatDateToString, formatMoney } from '~/helpers'
import useFetchSWR from '~/hooks/useFetchSWR'
import useQueryParams from '~/hooks/useQuery'
import WalletHeader from '~/modules/retailMedia/components/WalltetHeader'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'
import { transactionStatus } from '~/modules/retailMedia/store/common/constants'

import * as S from './styles'

const RechargeTransaction = () => {
  const { id, transactionId } = useParams()
  const publisherId = useQueryParams().get('rmid')

  const [shouldRevalidate, setShouldRevalidate] = useState(true)

  const { data: transaction, isValidating } = useFetchSWR<TransactionResponse>({
    url: `${reatailmediaApi}/payment/transaction/detail/${transactionId}`,
    refreshInterval: shouldRevalidate ? 10000 : null,
    revalidateOnFocus: false
  })

  useEffect(() => {
    if (transaction?.status) {
      const { payment_method, status } = transaction
      setShouldRevalidate(
        payment_method === 'pix' && status === 'waiting_payment'
      )
    }
  }, [transaction])

  const showPixSection = useMemo(
    () =>
      transaction &&
      transaction.payment_method === 'pix' &&
      transaction.status === 'waiting_payment',
    [transaction]
  )

  return (
    <>
      <PageTitle title={t('rm:recharge.details.title')} />

      <WalletHeader
        title={t('rm:recharge.details.header.title')}
        labelBreadcrumb={t('rm:backToTransactions')}
        backTo={`/retail-media/wallet/${id}/transactions?rmid=${publisherId}`}
      />

      <Loading status={isValidating && !transaction}>
        {t('common:actions.loadingData')}...
      </Loading>

      {!!transaction && (
        <S.Container hasSidebar={showPixSection}>
          <Panel title={t('rm:rechargeSummary')}>
            <S.ResumeContainer className="rechargeInfos">
              <div className="mainInfo">
                <h4 className="title">{t('rm:paymentDetails')}</h4>

                <Badge
                  template={transactionStatus?.[transaction.status].template}
                >
                  {transactionStatus?.[transaction.status].label}
                </Badge>

                <p>
                  <strong>{formatDateToString(transaction?.date)}</strong>
                  <br />
                  <strong>{t('rm:Responsible')}:</strong>{' '}
                  {transaction?.user_name}
                  <br />
                  <strong>ID:</strong> {transactionId}
                </p>
              </div>

              <div className="rechargeDetail">
                <h4 className="title">{t('rm:rechargeDetails')}</h4>
                <p>
                  <strong>{t('rm:Wallet')}:</strong>{' '}
                  {transaction.publisher_name}
                  <br />
                  <strong>{t('rm:Value')}:</strong>{' '}
                  {formatMoney(transaction.amount)}
                </p>
              </div>

              <div className="paymentMethod">
                <h4 className="title">{t('rm:paymentMethod')}</h4>

                <S.PaymentMethodWrapper>
                  {transaction?.payment_method === 'credit_card' && (
                    <>
                      {transaction.brand && (
                        <img
                          src={`https://github.com/aaronfagan/svg-credit-card-payment-icons/raw/main/logo/${transaction.brand}.svg`}
                          width={60}
                          height={40}
                        />
                      )}
                      <S.PaymentInfoWrapper>
                        <strong>{t('rm:Credit')}</strong>
                        {transaction.card_number && (
                          <Label>
                            {transaction.brand.toUpperCase()} •{' '}
                            {transaction.card_number}
                          </Label>
                        )}
                      </S.PaymentInfoWrapper>
                    </>
                  )}
                  {transaction?.payment_method === 'pix' && (
                    <>
                      <img src={pixLogo} width={100} />
                    </>
                  )}
                </S.PaymentMethodWrapper>
              </div>
            </S.ResumeContainer>
          </Panel>

          {/* Pagamento com pix esperando ser pago */}
          {showPixSection && (
            <Panel title={t('rm:Payment')} className="form-grid">
              <S.PixContainer>
                <S.QrCodeWrapper>
                  <S.QrCodeWrapperInside>
                    <img src={transaction.qr_code_url} width="250" />

                    <p>
                      <span style={{ fontWeight: 600, fontSize: '1.1rem' }}>
                        {formatMoney(transaction.amount)}
                      </span>{' '}
                    </p>

                    <ButtonCopyToClipboard
                      textBefore={t('rm:recharge.transaction.copyCode')}
                      textAfter={t('rm:recharge.transaction.codeCopied')}
                      iconLeft={<FiCopy />}
                      toCopy={transaction.qr_code}
                      style={{ width: '100%' }}
                    />

                    <S.CountDownWrapper>
                      <FiClock />

                      <Label
                        className="mx-2"
                        text={t('rm:recharge.transaction.qrCodePixExpiresIn')}
                      />

                      <Countdown timeToExpiry={transaction.qrcode_expires_at} />
                    </S.CountDownWrapper>
                  </S.QrCodeWrapperInside>
                </S.QrCodeWrapper>

                <S.PixInstructionsWrapper>
                  <AlertInfo
                    template="warning"
                    text={t(
                      'rm:recharge.transaction.paymentBeforeQrCodeExpires.alert'
                    )}
                  />

                  <dl>
                    <S.TitleInstructionsWrapper>
                      <h4>
                        <span className="icon">
                          <FiInfo />
                        </span>
                        <strong>
                          {t(
                            'rm:recharge.transaction.instructions.howToPayWithQrCode'
                          )}
                        </strong>
                      </h4>
                    </S.TitleInstructionsWrapper>

                    <dd>
                      {t(
                        'rm:recharge.transaction.instructions.openBankAppAndGoTo'
                      )}{' '}
                      <strong>{t('rm:recharge.transaction.pixArea')}</strong>
                    </dd>
                    <dd>
                      {t('rm:selectOption')}{' '}
                      <strong>
                        {t('rm:recharge.transaction.payWithQrCode')}
                      </strong>
                    </dd>
                    <dd>
                      {t(
                        'rm:recharge.transaction.instructions.scanImageCheckInfoAndCompletePayment'
                      )}
                    </dd>
                    <dd>
                      {t(
                        'rm:recharge.transaction.instructions.afterPaymentOrderWillBeProcessed'
                      )}
                    </dd>
                  </dl>

                  <dl>
                    <S.TitleInstructionsWrapper>
                      <h4>
                        <span className="icon">
                          <FiInfo />
                        </span>
                        <strong>
                          {t(
                            'rm:recharge.transaction.instructions.howToPayWithPixCopyPaste'
                          )}
                        </strong>
                      </h4>
                    </S.TitleInstructionsWrapper>

                    <dd>
                      {t(
                        'rm:recharge.transaction.instructions.copyGeneratedPixCode'
                      )}
                    </dd>
                    <dd>
                      {t(
                        'rm:recharge.transaction.instructions.openBankAppAndGoTo'
                      )}{' '}
                      <strong>{t('rm:recharge.transaction.pixArea')}</strong>
                    </dd>
                    <dd>
                      {t('rm:selectOption')}{' '}
                      <strong>
                        {t('rm:recharge.transaction.pixCopyPaste')}
                      </strong>
                    </dd>
                    <dd>
                      {t(
                        'rm:recharge.transaction.instructions.pasteCodeCheckInfoAndCompletePayment'
                      )}
                    </dd>
                    <dd>
                      {t(
                        'rm:recharge.transaction.instructions.afterPaymentOrderWillBeProcessed'
                      )}
                    </dd>
                  </dl>
                </S.PixInstructionsWrapper>
              </S.PixContainer>
            </Panel>
          )}
        </S.Container>
      )}
    </>
  )
}

export default RechargeTransaction
