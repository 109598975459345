import { t } from 'i18next'

import * as S from './styles'

interface Props {
  textMessage: string
}

export const RejectMessage = ({ textMessage }: Props) => {
  return (
    <S.RejectTextMessage>
      <span>
        <strong>{t('rm:messageRejection')}: </strong>
        {textMessage}
      </span>
    </S.RejectTextMessage>
  )
}
