import styled from 'styled-components'

export const Container = styled.div`
  address {
    font-style: normal;
    margin-bottom: 0;

    span {
      & ~ span {
        &:not(.number):not(.postalCode) {
          &:before {
            content: ',';
          }
        }
      }
    }
  }
`
