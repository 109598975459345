import React, { useMemo } from 'react'
import { FiCreditCard } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { Icon } from '~/components/Icon'
import { WrapperShadow, Header, Content } from '~/components/WrapperShadow'
import { formatMoney, paymentAnalysis, paymentStatus } from '~/helpers'

import Payment from '../Payment'

const PanelPayment = () => {
  const { orderData } = useSelector(state => state.order)

  let payments = useMemo(() => orderData.payments || [], [orderData])

  const lastPaymentStatus = useMemo(() => {
    if (payments?.[0]?.status === 'payment_partial_refunded') {
      return payments?.[1]?.status || payments?.[0]?.status
    }

    return payments?.[0]?.status
  }, [payments])

  const hasWaitingRefund = transaction =>
    transaction.status === 'waiting_refund'

  const verifyHasWaitingRefund = useMemo(
    () => !!payments.find(hasWaitingRefund),
    [payments]
  )

  return (
    <WrapperShadow>
      <Header>
        <div>
          <span>
            <Icon icon={FiCreditCard} /> <strong>Pagamento</strong>
          </span>
        </div>

        <aside>
          {payments?.[0]?.fraud_analysis_status && (
            <div
              className={`column infoBox ${payments[0].fraud_analysis_status}`}
            >
              {paymentAnalysis(payments[0].fraud_analysis_status)}
            </div>
          )}

          {payments?.[0] && (
            <div className={`column infoBox ${lastPaymentStatus}`}>
              {paymentStatus(lastPaymentStatus)}
            </div>
          )}
        </aside>
      </Header>

      <Content>
        <strong>Valor do pedido:</strong>{' '}
        {orderData.total_price && formatMoney(orderData.total_price)}
        <hr />
        <strong>Histórico de transações:</strong>
        <br />
        <ul className="payment__status">
          {payments.map(payment => (
            <Payment
              key={payment.id}
              payment={payment}
              orderId={orderData.id}
              hasWaitingRefund={verifyHasWaitingRefund}
            />
          ))}
        </ul>
      </Content>
    </WrapperShadow>
  )
}

export default PanelPayment
