import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  FiAlertTriangle,
  FiCheck,
  FiSend,
  FiShoppingCart
} from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Spinner } from 'reactstrap'

import { mutate } from 'swr'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { formatSearchString, getDecodedToken, getUserId } from '~/helpers'
import useFetchSWR from '~/hooks/useFetchSWR'
import { getUserService } from '~/services/user-service'
import {
  approveShoppingCart,
  handleShouldRevalidateList,
  openedShoppingCart,
  sendLinkShoppingCart
} from '~/store/modules/shoppingCart/actions'

import * as S from './styles'

const ReviewBar = () => {
  const dispatch = useDispatch()

  const { cart_id } = useParams()

  const {
    loadingUpdate,
    loadingSendLink,
    errorSendLink,
    shouldRevalidateList
  } = useSelector(({ shoppingCart }) => shoppingCart)

  const handleOpenCart = useCallback(() => {
    dispatch(openedShoppingCart({ cart_id }))
  }, [cart_id, dispatch])

  const { data: dataSWR } = useFetchSWR({
    url: `${process.env.REACT_APP_HOST}/api/shopping-cart/minified?cart_id=${cart_id}`,
    refreshInterval: 6000,
    revalidateOnFocus: true
  })

  const [loading, setLoading] = useState(!dataSWR)

  useEffect(() => {
    if (dataSWR) {
      setLoading(false)
    }
  }, [dataSWR])

  useEffect(() => {
    if (shouldRevalidateList) {
      mutate(
        `${process.env.REACT_APP_HOST}/api/shopping-cart/minified?cart_id=${cart_id}`
      )

      dispatch(handleShouldRevalidateList(false))
    }
  }, [dispatch, cart_id, shouldRevalidateList])

  const status = dataSWR?.data[0].status

  const statusType = useMemo(() => {
    if (status?.type === 'deleted') {
      return 'canceled'
    }

    if (status?.type === 'finished') {
      return 'finished'
    }

    if (status?.type === 'link_sent') {
      return 'linkSent'
    }

    if (status?.type === 'in_review') {
      return 'pending'
    }

    if (status?.type === 'opened') {
      return 'opened'
    }

    return 'success'
  }, [status])

  const handleSendLinkShoppingCart = useCallback(
    () => dispatch(sendLinkShoppingCart({ cart_id })),
    [cart_id, dispatch]
  )

  const handleAproveShoppingCart = useCallback(
    () => dispatch(approveShoppingCart({ cart_id })),
    [cart_id, dispatch]
  )

  const reviewerId = useMemo(() => {
    return statusType === 'success'
      ? dataSWR?.data[0].review.finish?.user_id
      : dataSWR?.data[0].review.start?.user_id
  }, [dataSWR?.data, statusType])

  const queryString = useMemo(
    () =>
      formatSearchString({
        id: reviewerId
      }),
    [reviewerId]
  )

  const [userInfo, setUserInfo] = useState([])

  const getUserInfo = useCallback(async () => {
    const { data } = await getUserService({ queryString })
    setUserInfo(data)
  }, [queryString])

  const user = getUserId()

  const verifyUserLogged = useMemo(() => {
    return reviewerId === user
  }, [reviewerId, user])

  useEffect(() => {
    if (reviewerId && !verifyUserLogged) {
      getUserInfo()
    }
  }, [getUserInfo, reviewerId, verifyUserLogged])

  /**
   * Reviewer name
   */
  const decodedToken = getDecodedToken()
  const accountName = useMemo(() => decodedToken?.name, [decodedToken])

  const reviewerName = useMemo(() => {
    if (verifyUserLogged) {
      return accountName
    }

    if (userInfo?.[0]?.name) {
      return userInfo[0].name
    }

    return 'outra pessoa'
  }, [accountName, userInfo, verifyUserLogged])

  return (
    <S.Container isOwner={verifyUserLogged}>
      <Loading status={loading} onlyLine />

      {!loading && (
        <>
          {['success', 'opened'].includes(statusType) && (
            <div>
              <span>
                Carrinho sendo revisado por <b>{reviewerName}</b>
                {verifyUserLogged && ' (Você)'}
              </span>
            </div>
          )}

          {['pending'].includes(statusType) && (
            <div>
              <span>Carrinho aguardando aprovação</span>
            </div>
          )}

          {!verifyUserLogged && <FiAlertTriangle size="24" />}

          <S.WrapperActions>
            {statusType === 'pending' && (
              <Button
                template="primary"
                text="Revisar carrinho"
                className="button__sm-wide"
                size="small"
                onClick={handleOpenCart}
                target="_blank"
                rel="noreferrer"
                iconLeft={<FiShoppingCart />}
                customWidth="auto"
                as="a"
                disabled={!verifyUserLogged}
              />
            )}

            {statusType === 'opened' && (
              <Button
                template={verifyUserLogged ? 'success' : 'light'}
                onClick={handleAproveShoppingCart}
                text="Aprovar carrinho"
                className="button__sm-wide"
                size="small"
                customWidth="auto"
                iconLeft={
                  loadingUpdate ? (
                    <Spinner className="button__sm-spinner" />
                  ) : (
                    <FiCheck />
                  )
                }
                disabled={!verifyUserLogged}
              />
            )}

            {statusType === 'success' && (
              <>
                {errorSendLink || ''}
                <Button
                  template={verifyUserLogged ? 'primary' : 'light'}
                  text="Enviar link"
                  className="button__sm-light"
                  customWidth="auto"
                  onClick={handleSendLinkShoppingCart}
                  iconLeft={
                    loadingSendLink ? (
                      <Spinner className="button__sm-spinner" />
                    ) : (
                      <FiSend />
                    )
                  }
                  disabled={!verifyUserLogged}
                />
              </>
            )}
          </S.WrapperActions>
        </>
      )}
    </S.Container>
  )
}

export default ReviewBar
