import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from '@unform/web'
import { parseToRgb } from 'polished'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { FormGroup, Input, InputHidden } from '~/components/unform'
import { createTag, updateTag } from '~/modules/chat/store/actions'

import ColorButton from '../ColorButton'
import { defaultColors } from './defaultColors'

import * as S from './styles'

const FormTag = ({ initialData, onSuccess }) => {
  const [currentColor, setCurrentColor] = useState()
  const [customColor, setCustomColor] = useState(false)
  const formRef = useRef(null)

  const isEdit = useMemo(() => !!initialData, [initialData])

  const { loadingSavingTag, loadingCreateTag } = useSelector(
    ({ chats }) => chats
  )

  const loading = useMemo(
    () => (isEdit ? loadingSavingTag : loadingCreateTag),
    [isEdit, loadingCreateTag, loadingSavingTag]
  )

  const dispatch = useDispatch()

  const handleChangeColor = useCallback(({ value, customColor = false }) => {
    setCurrentColor(value)
    setCustomColor(customColor)
    formRef.current.setFieldValue('color', value)
  }, [])

  useEffect(() => {
    if (initialData) {
      const parsedColor = () => {
        try {
          parseToRgb(initialData.color)
          return initialData.color
        } catch (error) {
          return '#cccccc'
        }
      }

      setCurrentColor(parsedColor())

      const isCustomColor = defaultColors.indexOf(parsedColor()) === -1

      setCustomColor(isCustomColor)
    }
  }, [initialData])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        dispatch(
          isEdit
            ? updateTag({ ...initialData, ...data })
            : createTag({ ...data, type: 'store' })
        )

        onSuccess && onSuccess()

        if (!isEdit) {
          formRef.current.reset()
          setCurrentColor(null)
          setCustomColor(false)
        }
      } catch (err) {
        console.error(err)
      }
    },
    [dispatch, initialData, onSuccess, isEdit]
  )

  const textButton = useMemo(
    () => (initialData ? 'Salvar alteração' : 'Salvar'),
    [initialData]
  )

  return (
    <S.WrapperForm>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={initialData || {}}
      >
        <FormGroup>
          <label htmlFor="label">Texto da tag</label>
          <Input
            id="label"
            name="label"
            placeholder="Insira o conteúdo da tag"
            required
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="label">Cor da tag (opcional)</label>
          <InputHidden name="color" />

          <div className="defaultColors">
            {defaultColors.map(color => (
              <ColorButton
                value={color}
                key={color}
                onClick={handleChangeColor}
                currentColor={currentColor}
              />
            ))}
            {customColor && (
              <ColorButton
                value={currentColor}
                onClick={handleChangeColor}
                currentColor={currentColor}
              />
            )}

            <ColorButton value="picker" onClick={handleChangeColor} />
          </div>
        </FormGroup>

        <footer>
          <Button
            type="submit"
            text={textButton}
            loading={loading}
            textLoading="Salvando..."
            template="primary"
            size="small"
          />
        </footer>
      </Form>
    </S.WrapperForm>
  )
}

export default FormTag

FormTag.propTypes = {
  initialData: PropTypes.shape({
    color: PropTypes.string
  }),
  onSuccess: PropTypes.func
}

FormTag.defaultProps = {
  initialData: null,
  onSuccess: null
}
