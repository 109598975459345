import React, { useState, useMemo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiCopy } from 'react-icons/fi';

import PropTypes from 'prop-types';

import Button from '~/components/Buttons/Button';

const ButtonCopyToClipboard = ({ toCopy, textBefore, textAfter, ...rest }) => {
  const [copied, setCopied] = useState(false);

  const label = useMemo(
    () => (copied ? textAfter : textBefore),
    [copied, textBefore, textAfter],
  );

  useMemo(
    () => copied && window.setTimeout(() => setCopied(false), 3000),
    [copied],
  );

  return (
    <div>
      <CopyToClipboard text={toCopy} onCopy={() => setCopied(true)}>
        <Button
          type="button"
          iconLeft={rest.iconLeft || <FiCopy />}
          text={label}
          {...rest}
        />
      </CopyToClipboard>
    </div>
  );
};

export default ButtonCopyToClipboard;

ButtonCopyToClipboard.propTypes = {
  toCopy: PropTypes.string.isRequired,
  textBefore: PropTypes.string,
  textAfter: PropTypes.string,
};

ButtonCopyToClipboard.defaultProps = {
  textBefore: 'Copiar',
  textAfter: 'Copiado',
};
