import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FiAlertCircle } from 'react-icons/fi';

import EmojiPicker from 'emoji-picker-react';
import PropTypes from 'prop-types';
import { createEditor, Transforms } from 'slate';
import { withReact } from 'slate-react';

import { Button } from '../Buttons';
import { Textarea } from '../unform';
import { deserializing } from './helpers/deserializing';
import { reduceSlate } from './helpers/reduceSlate';
import SlateWrapper from './SlateWrapper';
import { ToolBar } from './Toolbar';

import * as S from './styles';

const WhatsAppTextEditor = ({
  open,
  onText,
  onDelete,
  name,
  formRef,
  // Refactor
  // O componente não ficar dependente de um escopo.
  // Precisamos rever isso.
  scopePath,
}) => {
  const keyInput = `${scopePath}.${name}`;

  const editor = useMemo(() => withReact(createEditor()), []);
  const [error, setError] = useState(false);

  const [valueTextEditor, setValueTextEditor] = useState();

  const messageLength = useMemo(
    () => (valueTextEditor ? reduceSlate(valueTextEditor)?.length : 0),
    [valueTextEditor],
  );

  const [emoji, setEmoji] = useState(false);

  const toggleEmoji = () => {
    setEmoji(!emoji);
  };

  const onEmojiClick = (event, emojiObject) => {
    Transforms.insertText(editor, emojiObject.emoji);
  };

  const handleChange = useCallback(
    originalValue => {
      const serializedValue = reduceSlate(originalValue);

      setValueTextEditor(originalValue);
      onText && onText(originalValue, serializedValue);

      formRef?.current?.setFieldValue(keyInput, serializedValue);
    },
    [formRef, keyInput, onText],
  );

  useEffect(() => {
    const messageInput = formRef?.current.getFieldRef(keyInput);

    if (!messageInput?.value) {
      setValueTextEditor([
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ]);
    }

    if (messageInput?.value && editor) {
      setValueTextEditor([
        {
          type: 'paragraph',
          children: deserializing(messageInput.value),
        },
      ]);
    }
  }, [editor, formRef, keyInput]);

  return (
    <>
      <S.Container error={error}>
        {!!open && <ToolBar editor={editor} toggleEmoji={toggleEmoji} />}

        <section>
          <div className="textArea">
            <div className="inputHidden" style={{ display: 'none' }}>
              <Textarea name={name} onError={setError} />
            </div>

            {valueTextEditor && (
              <SlateWrapper
                editor={editor}
                valueTextEditor={valueTextEditor}
                onChange={handleChange}
              />
            )}

            {!open && onDelete && (
              <Button
                className="DeleteButton"
                text={<AiOutlineDelete />}
                template="secondary"
                onClick={() => {
                  onDelete();
                }}
              />
            )}
          </div>

          {!!emoji && (
            <div className="emojiPicker">
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </div>
          )}

          {error && (
            <S.Error title={error}>
              <FiAlertCircle />
            </S.Error>
          )}
        </section>
      </S.Container>

      <S.Footer>
        {/* <S.HelperText>
          Use chaves duplas para indicar onde você planeja usar o conteúdo
          dinâmico. Por exemplo, para enviar &quot;Seu código de login para
          Newtail é 1234&quot;, o modelo seria: &quot;Seu código de login para{' '}
          {'{{1}}'} é {'{{2}}'}&quot;
        </S.HelperText> */}

        <aside>
          <S.Count isValid={messageLength < 1025}>
            <code>({messageLength}/1024)</code>
          </S.Count>
        </aside>
      </S.Footer>
    </>
  );
};

WhatsAppTextEditor.defaultProps = {
  formRef: null,
  onText: null,
  onDelete: null,
  scopePath: 'messages',
};

WhatsAppTextEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onText: PropTypes.func,
  onDelete: PropTypes.func,
  name: PropTypes.string.isRequired,
  scopePath: PropTypes.string,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
};

export default WhatsAppTextEditor;
