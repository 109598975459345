import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi'

import { Scope } from '@unform/core'

import { IconCircle } from '~/components/Icon/Circle'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { Badge } from '~/components/UI/Badge'
import { FileInput, InputHidden, Label } from '~/components/unform'

import FileToUpload from '../FileToUpload'
import { schema } from './schema'

import * as S from './styles'

// mock
const dataAdSize: ResponseListSizeBySegmentation = [
  {
    height: 1080,
    width: 1920,
    label: '1080p',
    name: '1080p',
    size: '1920x1080',
    duration: 15
  },
  {
    height: 1080,
    width: 1920,
    label: '1080p',
    name: '1080p',
    size: '1920x1080',
    duration: 30
  }
]

import { useAPPTranslation } from '~/i18n/useAPPTranslation'
export const Video = ({ formRef, initialData }: VideoUploadProps) => {
  const { t } = useAPPTranslation()
  const [uploadedMediaToTable, setUploadedMediaToTable] = useState<
    UploadedMediaTableItem[]
  >([])

  /**
   * Separando arquivos para inserir na fila de upload
   */
  type FilesToUpload = { file: File; id: string }[]
  const [filesToUpload, setFilesToUpload] = useState<FilesToUpload>([])

  /**
   * Lógica de apoio
   */
  const { publisherId } = useMemo(
    () => ({
      publisherId: initialData?.publisher_id,
      publisherName: initialData?.publisher_name
    }),
    [initialData]
  )

  /**
   * Handle section is valid
   * * Refactor
   */

  const sectionIsValid = useMemo(
    () => !!uploadedMediaToTable?.length,
    [uploadedMediaToTable]
  )

  /**
   * Handle props panel
   */
  const handlePrepareToUploadImage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const fileList = Array.from(
        event.target.files as FileList,
        (item, index) => ({ file: item, id: `${item.name}_${index}` })
      )

      setFilesToUpload(fileList)
    },
    []
  )

  const panelProps = useMemo(
    () => ({
      title: t('rm:campaign.create.Video.title'),
      iconLeft: (
        <IconCircle
          icon={sectionIsValid ? FiCheckCircle : FiAlertCircle}
          template={sectionIsValid ? 'successFill' : 'warningFill'}
        />
      ),
      isCollapsible: true
    }),
    [sectionIsValid, t]
  )

  /**
   * Handle props file input
   */
  const fileInputProps: FileInputProps = {
    name: 'mediaFile',
    accept: 'video/mp4,video/x-m4v,video/*',
    onChange: (event: ChangeEvent<HTMLInputElement>) =>
      handlePrepareToUploadImage(event),
    hideIcons: true
  }

  /**
   * Get media configs
   * Mockado!
   */

  // const { status: statusAdSize, data: dataAdSize } = useQuery({
  //   queryKey: ['get-banner-size', publisherId, selectedSegmentation],
  //   queryFn: async () => {
  //     const res = await listSizesBySegmentationService({
  //       publisher_id: publisherId,
  //       type: 'banner',
  //       segmentation: selectedSegmentation
  //     })

  //     return res.data
  //   }
  // })

  /**
   * Available media configs
   * Formatando as configurações de mídia disponíveis recebidas da API
   */

  const availableMediaConfigs = useMemo(() => {
    if (dataAdSize) {
      const arrayValue: AvailableMediaConfigs[] = dataAdSize.map(item => ({
        value: item.name,
        label: item.size,
        width: item.width,
        height: item.height,
        duration: item.duration
      }))

      arrayValue.sort((a, b) =>
        a.width !== b.width ? a.width - b.width : a.height - b.height
      )

      return arrayValue
    }
  }, [dataAdSize])

  /**
   * Lidando com ações de upload
   */
  const handleDeleteMedia = useCallback(
    (data: ItemMediaTable) => {
      const filterState = (state: UploadedMediaTableItem[]) =>
        state.filter(item => item.id !== data.id)

      setUploadedMediaToTable(filterState)
    },
    [setUploadedMediaToTable]
  )

  const getPlacementByDimension = useCallback(
    ({ width, height }: VideoProps) => {
      const findPlacement = dataAdSize?.find(
        item => Number(item.width) === width && Number(item.height) === height
      )

      return findPlacement
    },
    [dataAdSize]
  )

  const handleSaveMedia = useCallback(
    (data: ItemMediaTable) => {
      const altText = formRef.current.getFieldValue('video.alt_text')

      setFilesToUpload(prevState =>
        prevState.filter(item => item.id !== data.id)
      )

      setUploadedMediaToTable(prevState => {
        return [...prevState, { ...data, alt_text: altText, handleDeleteMedia }]
      })
    },
    [formRef, handleDeleteMedia, setUploadedMediaToTable]
  )

  const handleDeleteFileBeforeUpload = (removeId: string) => {
    setFilesToUpload(prevState =>
      prevState.filter(item => item.id !== removeId)
    )
  }

  return (
    <Panel {...panelProps}>
      <Scope path="video">
        {!filesToUpload?.length && !uploadedMediaToTable.length && (
          <>
            <header>
              <Label
                text={t('rm:campaign.create.Video.label')}
                helperText={t('rm:campaign.create.Video.helperText')}
              />
            </header>
            <section className="wrapper-file-input">
              <FileInput {...fileInputProps}>
                <div className="pt-3 mt-3 border-top border-tertiary">
                  <h4>{t('rm:allowedMediaSizes')}</h4>

                  <div className="d-flex gap 2 justify-content-center flex-wrap">
                    {availableMediaConfigs?.map(item => (
                      <div key={item.value}>
                        <Badge template="primary">
                          {item.label} ({item.duration}s)
                        </Badge>
                      </div>
                    ))}
                  </div>
                </div>
              </FileInput>
            </section>
          </>
        )}

        <section className="wrapper-video-to-upload d-flex flex-column">
          {filesToUpload?.map(item => (
            <FileToUpload
              data={item}
              key={item.id}
              getPlacementByDimension={getPlacementByDimension}
              onSave={handleSaveMedia}
              onDelete={() => handleDeleteFileBeforeUpload(item.id)}
            />
          ))}
        </section>

        {!!uploadedMediaToTable.length && (
          <>
            <S.WrapperTabler>
              <Table schema={schema} data={uploadedMediaToTable} />
            </S.WrapperTabler>

            <video controls className="w-100">
              <source
                src={uploadedMediaToTable[0].media_url}
                type="video/mp4"
              />
            </video>

            {uploadedMediaToTable.map((item, index) => (
              <Scope path={`assets[${index}]`} key={item.id}>
                <InputHidden
                  type="hidden"
                  name={`media_url`}
                  value={item.media_url}
                />
                <InputHidden
                  type="hidden"
                  name={`ad_size`}
                  value={item.ad_size}
                />
                <InputHidden
                  type="hidden"
                  name={`alt_text`}
                  value={item.alt_text}
                />
              </Scope>
            ))}
          </>
        )}
      </Scope>
    </Panel>
  )
}
