import React from 'react';

import ExternalDashboard from '~/components/ExternalDashboard';
import HeaderPages from '~/components/HeaderPages';

import { Container } from './styles';

const CustomerDashboard = () => {
  return (
    <Container>
      <HeaderPages title="Métricas de clientes" />
      <ExternalDashboard dashboardId={45} />
    </Container>
  );
};

export default CustomerDashboard;
