/* eslint-disable indent */
import { history } from '~/helpers'
import {
  formatSearchString,
  queryDatesDatepickerIsolated,
  queryDatesDatepicker
} from '~/helpers/format-query-string'
import {
  getCardsData,
  getOrdersPerDayData,
  getOrderTopProducts,
  getTopChannel,
  getTopPayments,
  getOrdersList,
  getOrderData,
  cancelOrder,
  updateOrderData,
  deleteOrderService,
  updateOrderItemService,
  deleteOrderItemService,
  cancelPaymentService,
  updateOrderStatusService,
  updateOrderDiscountService,
  restartPicking,
  approveFraudAnalysisService,
  requestExternalDeliveryService,
  cancelExternalDeliveryService,
  editFullNameRequest,
  requestHandlerError
} from '~/services/order-service'
import { getVerifyRefusal } from '~/services/payment-hub'
import store from '~/store'

import * as types from './constants'

/**
 * Pega a Listagem de pedidos
 */
export const ordersList =
  ({ page = 1, quantity = 10, startDate, endDate, ...rest }) =>
  dispatch => {
    const queryString = formatSearchString({
      page,
      quantity,
      ...queryDatesDatepickerIsolated(startDate, endDate),
      ...rest
    })

    dispatch({
      type: types.ORDERS_LIST,
      payload: getOrdersList(queryString),
      page
    })
  }

/**
 * Limpa os dados de pedido no estado global
 */
export const clearOrderInfo = () => {
  return {
    type: types.ORDER_CLEAR_INFO
  }
}

/**
 * Pega informações de um pedido
 */
export const getOrderInfo = ({ id }) => {
  return {
    type: types.ORDER_GET_INFO,
    payload: getOrderData({ id }),
    onError: () => {
      history.push(`/orders/list`)
    },
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao carregar.'
  }
}

export const cancelOrderData = ({ orderId, data }) => {
  const { dispatch } = store

  return {
    type: types.CANCEL_ORDER,
    payload: cancelOrder({ orderId, data }),
    onSuccess: () => {
      dispatch(getOrderInfo({ id: orderId }))
    }
  }
}

/**
 * Refresh de informações de um pedido
 * Separado para tratamos o loading de forma diferente
 */
export const getOrderInfoRefresh = ({ id }) => {
  return {
    type: types.ORDER_GET_INFO_REFRESH,
    payload: getOrderData({ id }),
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao atualizar.'
  }
}

/**
 * Pega informações sobre a recusa de um pagamento
 */
export const verifyRefusal = ({ transactionId, onSuccess }) => {
  return {
    type: types.PAYMENT_VERIFY_REFUSAL,
    onSuccess,
    payload: getVerifyRefusal(transactionId),
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao carregar.'
  }
}

/**
 * Apaga um pedido
 */
export const deleteOrder = id => {
  return {
    type: types.ORDER_DELETE,
    payload: deleteOrderService(id),
    successFeedbackMsg: 'Pedido removido com sucesso!',
    onSuccess: () => {
      history.push(`/orders/list`)
    }
  }
}

/**
 * Cancela um pagamento
 */
export const cancelPaymentOrder = ({ orderId, body }) => {
  const { dispatch } = store

  return {
    type: types.ORDER_PAYMENT_CANCEL,
    payload: cancelPaymentService({ orderId, body }),
    successFeedbackMsg: 'Pagamento cancelado com sucesso!',
    onSuccess: response => {
      const { id } = response.data.order

      dispatch(getOrderInfo({ id }))
    }
  }
}

/**
 * Atualiza qualquer informação do pedido
 */

export const updateOrderInfo = data => {
  const { dispatch } = store

  return {
    type: types.ORDER_UPDATE_INFO,
    payload: updateOrderData(data),
    successFeedbackMsg: 'Pedido atualizado com sucesso!',
    onSuccess: response => {
      const { id } = response.data

      dispatch(getOrderInfo({ id }))
    },
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro na atualização.'
  }
}

/**
 * Atualiza status do pedido
 * @param {string} id
 * @param {object} data
 * @param {object} data.label
 * @param {object} data.code
 * @param {object} data.type
 */

export const updateOrderStatus = ({ id, newStatus: status }) => {
  const { dispatch } = store

  return {
    type: types.ORDER_UPDATE_STATUS,
    payload: updateOrderStatusService({ id, data: { status } }),
    successFeedbackMsg: 'Pedido atualizado com sucesso!',
    onSuccess: response => {
      const { id } = response.data

      dispatch(getOrderInfo({ id }))
    },
    getErrorFeedbackMsg: error =>
      error.request?.status === 400
        ? 'Não é permitido mudar para esse status'
        : error.response?.data?.error || 'Houve um erro na atualização.'
  }
}

/**
 * Reinicia a separação do pedido.
 */

export const restartOrderPicking = id => {
  const { dispatch } = store

  return {
    type: types.RESTART_PICKING,
    payload: restartPicking(id),
    successFeedbackMsg: 'Separação reiniciada!',
    onSuccess: response => {
      const { id } = response.data

      dispatch(getOrderInfo({ id }))
    },
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro na solicitação.'
  }
}

/**
 * Atualiza informações de um item do pedido.
 */
export const updateOrderItems = data => {
  return {
    type: types.ORDER_ITEMS_UPDATE,
    payload: updateOrderItemService(data),
    successFeedbackMsg: 'Pedido atualizado com sucesso!',
    // onSuccess: response => {
    //   const orderId = response.data.id;

    //   history.go(`/order/${orderId}`);
    // },
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao atualizar os itens.'
  }
}

/**
 * Atualiza desconto do pedido.
 * - Request
 * {
 *	"id": "de734e80-01e5-11ec-83c7-2b5ff32c7cbc",
 *	"total_discount": 10
 * }
 */
export const updateOrderDiscount = data => {
  return {
    type: types.ORDER_UPDATE_DISCOUNT,
    payload: updateOrderDiscountService(data),
    successFeedbackMsg: 'Pedido atualizado com sucesso!',
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao atualizar o pedido.'
  }
}

/**
 * Deleta um item do pedido.
 */
export const deleteOrderItem = ({ itemId, orderId }) => {
  return {
    type: types.ORDER_ITEM_DELETE,
    payload: deleteOrderItemService(itemId),
    successFeedbackMsg: 'Produto removido com sucesso!',
    onSuccess: () => {
      history.go(`/order/${orderId}`)
    },
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao deletar o item.'
  }
}

/**
 * Aprovação manual da análise de fraude.
 */
export const approveFraudAnalysis = requestData => {
  return {
    type: types.APPROVE_FRAUD_ANALYSIS,
    payload: approveFraudAnalysisService(requestData),
    successFeedbackMsg: 'Análise de fraude aprovada com sucesso!',
    onSuccess: () => {
      history.go(`/order/${requestData.orderId}`)
    },
    getErrorFeedbackMsg: error =>
      error.response?.data?.error ||
      'Houve um erro ao aprovar a análise de fraude.'
  }
}

/**
 * Requisição de entrega externa
 */
export const requestExternalDelivery = ({ body, onSuccess }) => {
  return {
    type: types.REQUEST_EXTERNAL_DELIVERY,
    payload: requestExternalDeliveryService({ body }),
    successFeedbackMsg: 'Solicitação de entrega realizada com sucesso.',
    onSuccess,
    getErrorFeedbackMsg: error => {
      return (
        error.response?.data?.error || 'Houve um erro ao solicitar uma entrega.'
      )
    }
  }
}

export const getErrorExternalDelivery = ({ order_id }) => {
  return {
    type: types.REQUEST_ERROR_EXTERNAL_DELIVERY,
    payload: requestHandlerError({ order_id })
  }
}

/**
 * Requisição de entrega externa
 */
export const cancelExternalDelivery = requestData => {
  return {
    type: types.CANCEL_EXTERNAL_DELIVERY,
    payload: cancelExternalDeliveryService(requestData),
    successFeedbackMsg:
      'Cancelamento da solicitação de entrega feita com sucesso!',
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao cancelar uma entrega.'
  }
}

// Data services

export const getCardsInfo = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate)
  })

  dispatch({
    type: types.ORDERS_CARDS,
    payload: getCardsData(queryString)
  })
}

export const getOrdersPerDayInfo = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate)
  })
  dispatch({
    type: types.ORDERS_PER_DAY,
    payload: getOrdersPerDayData(queryString),
    startDate,
    endDate
  })
}

export const getTopProductsInfo = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate)
  })
  dispatch({
    type: types.ORDERS_TOP_PRODUCTS,
    payload: getOrderTopProducts(queryString)
  })
}

export const getOrdersTopChannelInfo = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate)
  })
  dispatch({
    type: types.ORDERS_TOP_CHANNELS,
    payload: getTopChannel(queryString)
  })
}

export const getTopPaymentsInfo = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate)
  })
  dispatch({
    type: types.ORDERS_TOP_PAYMENTS,
    payload: getTopPayments(queryString)
  })
}

export const filterOrdersList = filters => dispatch => {
  dispatch({ type: types.ORDERS_LIST_SET_FILTERS, filters })
  dispatch(ordersList())
}

export const updateFullName = ({ id, data, onSuccess = null }) => {
  return {
    type: types.ORDER_UPDATE_RECIPIENT,
    payload: editFullNameRequest({ id, body: data }),
    onSuccess
  }
}
