import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/checking-account`

/**
 * VIEW
 */

export const getCheckingAccount = (queries: GetCheckingAccountRequestParams) =>
  axios.get<GetCheckingAccountResponse>(`${baseURL}`, {
    params: { ...queries }
  })

/**
 * VIEW
 */

export const listCheckingAccountService = (
  params: ListCheckingAccountRequestParams
) =>
  axios.get<GetCheckingAccountResponse>(`${baseURL}`, {
    params
  })

/**
 * Create transaction
 * POST https://api-retail-media.newtail.com.br/checking-account/transaction/[checking_account_id]
 * Authorization: Bearer Token
 */

export const createTransactionService = ({
  checkingAccountId,
  body
}: CreateTransactionServiceProps) =>
  axios.post<CreateTransactionResponse>(
    `${baseURL}/transaction/${checkingAccountId}`,
    body
  )

export default {
  baseURL,
  getCheckingAccount,
  createTransactionService
}
