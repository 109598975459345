import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, auto));
    max-width: 100%;
    width: 100%;
    gap: 1rem;
    align-items: center;
    /* flex-wrap: wrap; */

    > form {
      width: 280px;

      > :not(:first-child) {
        margin-top: 0.5rem;
      }
    }
  `}
`;
