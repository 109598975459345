import { jsx } from 'slate-hyperscript';

const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  }
  if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true;
      break;
    case 'EM':
      nodeAttributes.italic = true;
      break;
    case 'STRIKE':
      nodeAttributes.overline = true;
      break;
    case 'CODE':
      nodeAttributes.code = true;
      break;
    case 'BR':
      nodeAttributes.text = '\n';
      break;
    default:
  }

  const children = Array.from(el.childNodes)
    .map(node => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    default:
      return children;
  }
};

export function deserializing(value) {
  const HTMLtags = {
    '*': 'strong',
    '~': 'strike',
    _: 'em',
    '```': 'code',
  };

  const formatterMessage = (_, p1, p2) =>
    `<${HTMLtags[p1]}>${p2}</${HTMLtags[p1]}>`;

  const formatterLink = match =>
    `<a href="${match}" target="_blank">${match}</a>`;

  const formatterPhone = match =>
    `<a href="tel:${match}" target="_blank">${match}</a>`;

  const regexWhatsAppStyles = /([*~_]|```)([a-zA-Z0-9].+?)\1(?!\w)/g;
  const regexURL =
    /(?:(?:https|http):\/\/|\b(?:[a-z\d]+\.))[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?/gim;
  const regexPhone =
    /(?:(?:\(?([0-9]{2,11})\))|(?:([0-9]{2,11})))?[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/gm;

  const formattedMessage = messageBody =>
    messageBody
      .replace(regexURL, formatterLink)
      .replace(regexPhone, formatterPhone)
      .replace(regexWhatsAppStyles, formatterMessage);

  const document = new DOMParser().parseFromString(
    formattedMessage(value),
    'text/html',
  );
  // console.log(value);
  // console.log('%cdeserializing', 'color:#AA0187; font-size:32px');
  // console.log(formattedMessage(value));
  // console.log(deserialize(document.body), document.body);

  return deserialize(document.body);
}
