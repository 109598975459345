import { create } from './create';
import { get } from './get';
import { list } from './list';
import { update } from './update';

export default {
  ...get,
  ...list,
  ...create,
  ...update,
};
