import React from 'react';
import ReduxToastr from 'react-redux-toastr';

const Feedback = () => (
  <ReduxToastr
    timeOut={5000}
    newestOnTop={false}
    preventDuplicates
    position="top-right"
    getState={state => state.toastr}
    transitionIn="fadeIn"
    transitionOut="fadeOut"
    progressBar
    closeOnToastrClick
    className="feedback"
  />
);

export default Feedback;
