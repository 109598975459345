import analytics from '~/modules/analytics-grocery/routes'
import chat from '~/modules/chat/routes'
import messagingCampaigns from '~/modules/messagingCampaigns/routes'
import promotional from '~/modules/promotional/routes'
import { retailMedialRoutes as retailMedia } from '~/modules/retailMedia/routes/index'
import { users } from '~/modules/users/routes'

import { auth, logout } from './auth'
import { config } from './config'
import { customers } from './customers'
import { dashboard } from './dashboard'
import { financial } from './financial'
import { integration } from './integrations'
import { locations } from './locations'
import { logistic } from './logistic'
import { operators } from './operators'
import { orders } from './orders'
import { products } from './products'
import { shoppingCarts } from './shopping-carts'
import { trade } from './trade'

const Routes = {
  retailMedia,
  analytics,
  auth,
  logout,
  chat,
  // chatV1,
  config,
  messagingCampaigns,
  customers,
  dashboard,
  financial,
  integration,
  locations,
  logistic,
  operators,
  orders,
  shoppingCarts,
  products,
  promotional,
  users,
  trade
}

export default Routes
