import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Container } from './styles';

const TagLabel = ({ tagId }) => {
  const { avaiableTags } = useSelector(({ chats }) => chats);

  const filteredTagAtAvaiableTags = avaiableTags?.find(
    item => item.tagId === tagId,
  );

  return filteredTagAtAvaiableTags?.label ? (
    <Container color={filteredTagAtAvaiableTags?.color} className="TagLabel">
      <span className="text">{filteredTagAtAvaiableTags?.label}</span>
    </Container>
  ) : (
    <></>
  );
};

export default TagLabel;

TagLabel.propTypes = {
  tagId: PropTypes.string.isRequired,
};
