import styled, { css } from 'styled-components'

interface ValueTransactionInterface {
  transactionType: TransactionTypes
}

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr;
  justify-items: start;
  grid-gap: 1rem;

  > .wrapper-datepicker-header {
    > div {
      justify-content: flex-start;
    }
  }
`

export const ValueTransaction = styled.div<ValueTransactionInterface>`
  ${({ transactionType, theme }) => css`
    > .amount {
      font-size: ${theme.font.sizes.small};

      color: ${['debit', 'refund'].includes(transactionType)
        ? theme.colors.danger
        : theme.colors.success};

      ${transactionType === 'credit' &&
      css`
        &:before {
          content: '+';
          font-size: ${theme.font.sizes.medium};
          color: ${theme.colors.success};
        }
      `}
    }
  `}
`

export const ColumnMainAndDetail = styled.div`
  ${({ theme }) => css`
    .mainInfo {
      font-size: ${theme.font.sizes.small};
      font-style: normal;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.neutralLow};
    }

    .detail {
      font-size: ${theme.font.sizes.xsmall};
      font-style: normal;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.neutral.low.light};
    }
  `}
`

export const Note = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-style: normal;
    font-weight: ${theme.font.normal};
    color: ${theme.colors.neutralLow};
  `}
`
