import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FiExternalLink, FiHelpCircle } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { Scope } from '@unform/core'
import { Form } from '@unform/web'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import Tooltip from '~/components/Tooltip'
import { FormGroup, Input, Label } from '~/components/unform'
import InputURL from '~/components/unform/Input/InputURL'
import { Container as ContainerInput } from '~/components/unform/Input/styles'
import { getAccountType } from '~/helpers'
import { useAppSelector } from '~/store/hooks'

import { patch } from '../../store/industry/campaign/actions'
import { getPublisherConfiguration } from '../../store/industry/publisherConfiguration/actions'
import AlertBrands from '../AlertBrands'
import EditButton from '../EditButton'

import * as S from './styles'

/**
 * Este componente renderiza o painel que gerencia os links de destino de uma campanha.
 * Ele consegue lidar com contas multimarcas e marcas única.
 * Os componentes se alternam nesse caso. Assim como os campos retornados pela API.
 *
 * O componente também conta com dois modos: visualização e edição(desativado).
 * No código tem um bloco para cada modo.
 *
 * @param {object} props.data Dados da campanha
 * @param {boolean} props.loading Indicativo de loading do componente pai
 * @returns
 */

const DISABLE_EDIT = true

const DestinationUrls = ({
  data,
  loading
}: {
  data: CampaignView
  loading: boolean
}) => {
  const [isEditMode, setIsEditMode] = useState(false)

  const publisherId = useMemo(() => data?.publisher?.id, [data])
  const isGrocery = getAccountType() === 'grocery'
  const canEdit = useMemo(
    () => !isGrocery && data?.canEditCampaign,
    [data?.canEditCampaign, isGrocery]
  )

  const dispatch = useDispatch()
  const {
    industry: {
      campaign: {
        patch: { loadingKeys: loadingPatch }
      },
      publisherConfiguration: {
        list: {
          data: configurationData,
          loading: configurationLoading,
          error: configurationError
        }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  const patchKey = 'destination_url'
  const loadingSave = useMemo(
    () => loadingPatch?.includes(patchKey),
    [loadingPatch]
  )

  useEffect(() => {
    dispatch(getPublisherConfiguration(publisherId))
  }, [dispatch, publisherId])

  const { destinationUrl, destinationUrls } = data

  const publisherBrands = useMemo(
    () => configurationData?.configuration?.brands,
    [configurationData]
  )

  const formattedDesinationUrls = useMemo(() => {
    if (destinationUrl) return [{ url: destinationUrl }]

    const getBrandName = (code: string) =>
      publisherBrands?.find(item => item.code === code)?.name || code

    if (destinationUrls) {
      const objectToArray = destinationUrls.map(({ brand, url }) => ({
        code: brand,
        brand: getBrandName(brand),
        url
      }))

      return objectToArray
    }
  }, [destinationUrl, destinationUrls, publisherBrands])

  /**
   * Handle submit
   */

  const handleSubmit = useCallback(
    (formData: DestinationURLsFormData) => {
      const body = {
        destination_url: formData?.destination_url || null,
        destination_urls: formData?.destination_urls || null
      }

      dispatch(
        patch({
          body,
          id: data?.id,
          patchKey,
          successFeedbackMsg: t('rm:campaign.successfully.updated'),
          onSuccess: () => setIsEditMode(false)
        })
      )
    },
    [data, dispatch]
  )

  return (
    <Panel
      title={t('rm:adDestination')}
      description={t('rm:adDestination.description')}
      isLoading={loading || configurationLoading}
      template={isEditMode ? 'borderWarning' : 'default'}
      headerSideContent={
        !DISABLE_EDIT &&
        canEdit && (
          <EditButton
            onClick={() => setIsEditMode((e: boolean) => !e)}
            isActive={isEditMode}
          />
        )
      }
    >
      <S.Header>
        <AlertBrands publisherId={publisherId} />
      </S.Header>

      {/* Modo visualização */}
      {!isEditMode && (
        <S.WrapperDestinationUrls>
          {formattedDesinationUrls?.length
            ? formattedDesinationUrls.map(
                ({ code = null, brand = null, url }) => (
                  <FormGroup key={code}>
                    {brand && (
                      <Label>
                        {brand}{' '}
                        <Tooltip
                          text={t('rm:adDestination.descriptionWithBrand', {
                            brand
                          })}
                          icon={<FiHelpCircle />}
                        />
                      </Label>
                    )}

                    <ContainerInput isDisabled>
                      <div className="htmlIcon">
                        <Button
                          as="a"
                          href={url}
                          target="_blank"
                          rel="noreferrer"
                          text={t('rm:accessLink')}
                          iconRight={<FiExternalLink />}
                          size="xsmall"
                          template="light"
                          customWidth="auto"
                          style={{ padding: '0 .25rem', marginRight: '.25rem' }}
                        />
                      </div>

                      <input value={url} disabled />
                    </ContainerInput>
                  </FormGroup>
                )
              )
            : t('rm:noUrlsRegistered')}
        </S.WrapperDestinationUrls>
      )}
      {/* Modo edição */}
      {!DISABLE_EDIT && isEditMode && (
        <Form
          ref={null}
          onSubmit={handleSubmit}
          initialData={{
            destination_url: data.banners.destinationUrl,
            destination_urls: data.banners.destinationUrls
          }}
        >
          <FormGroup>
            <Label helperText={t('rm:adsDestination.helperText')} />

            {/* REFACToR BEFORE RELEASE - @brenonovelli */}
            {!configurationData?.configuration?.brands?.length && (
              <Input name="destination_url" id="destination_url" />
            )}

            {!!configurationData?.configuration?.brands?.length && (
              <Scope path="destination_urls">
                {configurationData?.configuration?.brands.map(item => (
                  <FormGroup key={item.code} style={{ marginBottom: '.5rem' }}>
                    <Label
                      text={item.name}
                      htmlFor={`destination_url[${item.code}]`}
                    />
                    <Input
                      name={item.code}
                      id={`destination_url[${item.code}]`}
                    />
                  </FormGroup>
                ))}
              </Scope>
            )}
          </FormGroup>

          <FormGroup className="actionButtons form-default__buttons-pb0">
            <Loading status={loadingSave}>{t('rm:savingChanges')}</Loading>

            {!loadingSave && (
              <>
                <Button
                  template="transparentDanger"
                  onClick={() => setIsEditMode(false)}
                  text={t('rm:button.Cancel')}
                  loading={loadingSave}
                />

                <Button
                  type="submit"
                  template="success"
                  loading={loadingSave}
                  disabled={loadingSave}
                  text={t('rm:button.Save')}
                  textLoading={t('rm:saving')}
                />
              </>
            )}
          </FormGroup>
        </Form>
      )}
    </Panel>
  )
}

export default DestinationUrls
