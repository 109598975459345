import { types } from '../constants'

const index = {
  pending: `${types.create}_PENDING`,
  fulfilled: `${types.create}_FULFILLED`,
  rejected: `${types.create}_REJECTED`
}

const reducerKey = 'create'

export const create = {
  [index.pending]: ({ draft }) => {
    draft.industry.campaign[reducerKey].loading = true
    draft.industry.campaign[reducerKey].error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.campaign[reducerKey].loading = false
    draft.industry.campaign[reducerKey].error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.campaign[reducerKey].loading = false
    draft.industry.campaign[reducerKey].error = action.payload
  }
}
