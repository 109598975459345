import React from 'react'
import { injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { setFilteredPromotionalStatus } from '~/store/modules/filters/actions'

import SelectSearchable from '../SelectSearchable'

const FilterStatusPromotional = ({ intl }) => {
  const dispatch = useDispatch()

  const { promotionalStatus: filteredTemplateType } = useSelector(
    state => state.filters
  )

  const types = ['running', 'scheduled', 'finished', 'paused']

  const type_option = types.map((item, index) => ({
    label: intl.formatMessage({ id: item }),
    value: item,
    step: index,
    type: item
  }))

  const handleChangeStatus = selectedStatusType => {
    dispatch(
      setFilteredPromotionalStatus({
        filteredPromotionalStatus: selectedStatusType
      })
    )
  }

  return (
    <SelectSearchable
      placeholder="Filtrar por status"
      options={type_option}
      onChange={handleChangeStatus}
      defaultValue={[filteredTemplateType]}
    />
  )
}

export default injectIntl(FilterStatusPromotional)

FilterStatusPromotional.propTypes = {
  intl: PropTypes.shape()
}

FilterStatusPromotional.defaultProps = {
  intl: null
}
