import { PiStorefrontLight } from 'react-icons/pi'

import { Badge } from '~/components/UI/Badge'

const ProductSellers: React.FC<{ data: PublisherProduct }> = ({ data }) => {
  const dataIsValid = !!data?.sellers?.filter(Boolean)?.length
  const values = data?.sellers

  return dataIsValid ? (
    <div className="d-flex flex-wrap gap-1">
      {values?.map(item => (
        <>
          <Badge
            size="small"
            iconLeft={<PiStorefrontLight />}
            key={`seller_${item}`}
            text={item}
          />{' '}
        </>
      ))}
    </div>
  ) : (
    <></>
  )
}

export default ProductSellers
