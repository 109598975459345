import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const transparentDangerButtonTemplate = ({
  theme
}: ButtonTemplatesProps) => css`
  --primaryColor: ${theme.colors.danger};

  color: var(--primaryColor);
  background: transparent;

  svg {
    color: var(--primaryColor);
  }

  &:disabled {
    opacity: 1;
    filter: brightness(0.9);
  }
`

export default transparentDangerButtonTemplate
