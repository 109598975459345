import { createFlyer } from './create';
import { deleteFlyer } from './delete';
import { listFlyers } from './list';
import { updateFlyer } from './update';
import { viewFlyer } from './view';

export default {
  ...listFlyers,
  ...viewFlyer,
  ...createFlyer,
  ...updateFlyer,
  ...deleteFlyer,
};
