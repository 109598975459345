import { types } from '../constants'

const index = {
  pending: `${types.getTransaction}_PENDING`,
  fulfilled: `${types.getTransaction}_FULFILLED`,
  rejected: `${types.getTransaction}_REJECTED`
}

export const transaction = {
  [index.pending]: ({ draft }) => {
    draft.industry.wallet.transaction.loading = true
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.wallet.transaction.data = action.payload.data
    draft.industry.wallet.transaction.error = null
    draft.industry.wallet.transaction.loading = false
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.wallet.transaction.error = action.payload
    draft.industry.wallet.transaction.data = null
    draft.industry.wallet.transaction.loading = false
  }
}
