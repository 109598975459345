import styled, { css } from 'styled-components';

export const Container = styled.section`
  transition: 0.2s ease-in-out opacity;
  position: relative;

  opacity: ${props => (props.activeStep ? 1 : 0.3)};

  ${props =>
    !props.disabledStep &&
    css`
      &:hover {
        opacity: ${props => (props.activeStep ? 1 : 0.75)};
      }
    `}
`;

export const ButtonOverlay = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  width: 100%;
  height: 100%;

  border: 0;
  background: none;

  &:focus {
    outline: none;
  }
`;
