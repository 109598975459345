import React, { useEffect, useMemo, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Alert from '~/components/Alert';
import HeaderPages from '~/components/HeaderPages';
import Loading from '~/components/Loading';
import PageTitle from '~/components/PageTitle';
import WhatsAppMessagePreview from '~/components/WhatsAppMessagePreview';
import { formatSearchString, formattedMessageToHtml } from '~/helpers';
import HeaderButtons from '~/modules/flyers/components/HeaderButtons';
import {
  clearFlyerStore,
  getFlyer,
} from '~/modules/flyers/store/flyers/actions';
import { indexLocation } from '~/pages/locations/actions';

import ColumnStatus from '../list/_elements/ColumnStatus';
import FlyerLocations from './_elements/FlyerLocations';
import GeneralInfoView from './_elements/GeneralInfoView';

const FlyerView = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(
    ({ promotionFlyer }) => promotionFlyer.flyers.view,
  );

  useLayoutEffect(() => {
    if (data?.id !== id) {
      dispatch(clearFlyerStore());
    }
  }, [dispatch, id, data]);

  useEffect(() => {
    dispatch(getFlyer({ id }));
  }, [dispatch, id]);

  /**
   * Checando se tem listagem de lojas no estado global
   */

  const { listLocations } = useSelector(state => state.locations);
  /**
   * REFATORAR
   * Precisamos usar o endpoint que traz o retorno simples de locais.
   * Ainda não temos essa chamada no Redux.
   */
  const queryStringLocation = useMemo(
    () =>
      formatSearchString({
        page: 0,
        quantity: 500,
      }),
    [],
  );

  useEffect(() => {
    if (!listLocations.data) {
      dispatch(indexLocation(queryStringLocation));
    }
  }, [dispatch, listLocations, queryStringLocation]);

  /**
   * Messagem formatado para o message preview
   */

  const messagePreviewData = useMemo(
    () => [
      {
        text: formattedMessageToHtml(data?.message),
        media: data?.media,
      },
    ],
    [data],
  );

  return (
    <>
      <PageTitle title={`Encarte - ${data?.name || 'Carregando...'}`} />

      <HeaderPages
        title={data?.name || 'Carregando...'}
        subtitle={data?.status && <ColumnStatus status={data?.status} />}
        sideRightComponent={<HeaderButtons />}
        backTo="/promotional-flyers/list"
        labelBreadcrumb="Voltar aos encartes"
      />

      <Loading status={loading}>Carregando encarte...</Loading>

      <Alert active={error}>Erro: {error}</Alert>

      {data && (
        <div>
          {/* Informações gerais  */}
          <GeneralInfoView />

          {/* Locais participantes do encarte */}
          <FlyerLocations />

          {/* Message preview */}
          <WhatsAppMessagePreview messages={messagePreviewData} />
        </div>
      )}
    </>
  );
};

export default FlyerView;
