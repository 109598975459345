import { methodsConfig } from '~/services/paymentHub';

import * as types from '../constants';

/**
 *
 * @returns
 */
export const get = () => ({
  type: types.GET_PAYMENT_METHODS_CONFIG,
  payload: methodsConfig.list(),
});

/**
 * Create method config
 */
export const create = ({ onSuccess, ...data }) => ({
  type: types.CREATE_PAYMENT_METHODS_CONFIG,
  payload: methodsConfig.create(data),
  successFeedbackMsg: 'Configuração criada com sucesso',
  onSuccess,
});

/**
 * Update method config
 */
export const update = ({ onSuccess, ...data }) => ({
  type: types.UPDATE_PAYMENT_METHODS_CONFIG,
  payload: methodsConfig.update(data),
  successFeedbackMsg: 'Configuração alterada com sucesso',
  onSuccess,
});

/**
 * Delete method config
 */
export const deleteItem = ({ onSuccess, id }) => ({
  type: types.DELETE_PAYMENT_METHODS_CONFIG,
  values: { id },
  payload: methodsConfig.delete({ id }),
  successFeedbackMsg: 'Configuração restaurada com sucesso',
  onSuccess,
});
