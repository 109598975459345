import { useCallback, useEffect, useMemo, useState } from 'react'
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi'

import { FormHandles } from '@unform/core'
import { t } from 'i18next'
import PropTypes from 'prop-types'

import AlertInfo from '~/components/AlertInfo'
import { IconCircle } from '~/components/Icon/Circle'
import Panel from '~/components/Panel'
import { Radio } from '~/components/unform'

import * as S from '../styles'
import PlacementCategory from './Targeting/Category'
import PlacementSearch from './Targeting/Keywords'

type ChildrenData = {
  keywords?: string[]
  categories?: Tag[]
} | null

type CampaignTargetingProps = {
  isEdit?: boolean
  handleSectionValidity(data: boolean): void
  onChange?(data: {
    targeting: RetailMediaCampaignSegmentation
    data: ChildrenData
  }): void
  formRef?: FormHandles
}

const CampaignTargeting = ({
  isEdit = false,
  handleSectionValidity,
  onChange,
  formRef
}: CampaignTargetingProps) => {
  const [selectedSettings, setSelectedSettings] =
    useState<RetailMediaCampaignSegmentation>(null)
  const [childrenData, setChildrenData] = useState<ChildrenData>(null)

  const handleSettingChange = (data: React.ChangeEvent<HTMLInputElement>) => {
    const value = data.target.value as RetailMediaCampaignSegmentation
    onChange?.({ targeting: value, data: null })
    setSelectedSettings(value)
    setChildrenData(null)
  }

  const handleChildrenChange = useCallback(
    (data: ChildrenData) => {
      onChange?.({ targeting: selectedSettings, data })
      setChildrenData(data)
    },
    [onChange, selectedSettings]
  )

  const sectionIsValid = useMemo(() => {
    switch (true) {
      case selectedSettings === 'keyword':
        return childrenData?.keywords?.length > 0

      case selectedSettings === 'category':
        return childrenData?.categories?.length > 0

      default:
        return false
    }
  }, [childrenData, selectedSettings])

  useEffect(
    () => handleSectionValidity(sectionIsValid),
    [handleSectionValidity, sectionIsValid]
  )

  const optionsTypes = [
    {
      id: `placement_search`,
      value: 'keyword',
      label: t('rm:campaign.targeting.search'),
      description: t('rm:campaign.targeting.keyword.description'),
      disabled: isEdit,
      activeChildren: <PlacementSearch onChange={handleChildrenChange} />
    },
    {
      id: `placement_category`,
      value: 'category',
      label: t('rm:campaign.targeting.category'),
      description: t('rm:campaign.targeting.category.description'),
      disabled: isEdit,
      activeChildren: (
        <PlacementCategory onChange={handleChildrenChange} formRef={formRef} />
      )
    }
  ]

  return (
    <>
      <Panel
        iconLeft={
          sectionIsValid ? (
            <IconCircle icon={FiCheckCircle} template="successFill" />
          ) : (
            <IconCircle icon={FiAlertCircle} template="warningFill" />
          )
        }
        title={t('rm:campaign.targeting.title.displayConfiguration')}
        description={t(
          'rm:campaign.targeting.description.displayConfiguration'
        )}
        isCollapsible
        initialCollapsibleState={!isEdit}
      >
        <S.Group>
          <Radio
            options={optionsTypes}
            name="context"
            required
            onChange={handleSettingChange}
          />
        </S.Group>

        {isEdit && (
          <AlertInfo text={t('rm:campaign.targeting.not.updated.alert')} />
        )}
      </Panel>
    </>
  )
}

export default CampaignTargeting

CampaignTargeting.propTypes = {
  isEdit: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    promotion_trigger: PropTypes.string
  }).isRequired
}

CampaignTargeting.defaultProps = {
  isEdit: false
}
