import React, { useMemo, useState } from 'react'
import { FiFilter } from 'react-icons/fi'

import { Button } from '~/components/Buttons'
import { Filters } from '~/components/Filters'
import { getRole } from '~/helpers'
import { CHAT_REDUX_QUERY } from '~/modules/chat/store/constants'
import { useAppSelector } from '~/store/hooks'

import * as S from './styles'

const ChatFilters = () => {
  const [showFilters, setShowFilters] = useState(true)

  const { loadingChats, loading, selectedStatus, selectedChat, fullScreen } =
    useAppSelector(({ chats }) => chats)

  const { isMobile } = useAppSelector(state => state.layout)

  const role = getRole()

  const isAdmin = useMemo(() => role === 'admin', [role])

  const shouldShowFilters = useMemo(
    () => (!fullScreen && !isMobile) || (isMobile && showFilters),
    [fullScreen, isMobile, showFilters]
  )

  return (
    <S.WrapperFilters hasSelectedChat={!!selectedChat}>
      {shouldShowFilters && (
        <Filters
          columns={isAdmin ? 5 : 4}
          query
          liveQuery
          queryKeyState={CHAT_REDUX_QUERY}
          queryPlaceholder="Buscar conversas"
          queryLoading={loadingChats || loading}
          queryAutoComplete="off"
          querySize="1x"
          locations
          filterWithoutLocation
          chatBotStatus={selectedStatus === 'available'}
          filterChatAgent={selectedStatus === 'in_contact' && isAdmin}
          chatTags
        />
      )}

      {isMobile && (
        <footer>
          <Button
            text={`${showFilters ? 'Ocultar' : 'Ver'} filtros`}
            iconLeft={<FiFilter />}
            onClick={() => setShowFilters(oldState => !oldState)}
            size="small"
            customWidth="100%"
            template="light"
          />
        </footer>
      )}
    </S.WrapperFilters>
  )
}

export default ChatFilters
