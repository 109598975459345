import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  z-index: 3;

  form {
    display: flex;
    flex: 1;
    z-index: 2;

    background-color: white;

    padding: 0.25rem;
    @media ${device.tablet} {
      padding: 0.5rem 1rem;
    }

    > button[type='button'] {
      margin-right: 0.5rem;
    }

    > label {
      display: flex;
      align-items: center;

      margin-right: 0.5rem;
      margin-bottom: 0;
    }

    > div {
      border-radius: 1rem;

      textarea {
        min-height: 1rem;
        height: 45px;
        max-height: 100%;
        line-height: 1.125rem;
      }
    }

    input[type='file'] {
      display: none;
    }

    > button[type='submit'] {
      margin-left: 0.5rem;
    }
  }

  ${({ hasImage, theme }) => css`
    .overlayImage {
      position: absolute;
      bottom: var(--heightChatSubmitBar);
      left: 0;
      overflow: hidden;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: calc(100% - var(--heightChatHeader) - var(--heightChatSubmitBar));
      transform: ${hasImage ? `translateY(0)` : `translateY(100%)`};

      transition: 0.3s ease-in-out all;

      --opacityBg: ${transparentize(0.2, theme.primary)};
      background: ${hasImage ? 'var(--opacityBg)' : 'transparent'};

      backdrop-filter: saturate(80%) blur(5px);

      img {
        width: 90%;
        object-fit: contain;
        max-height: 100%;
      }
    }
  `}
`

export const FilePreview = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 90%;
    max-width: 90%;

    .file,
    .image {
      + * {
        margin-top: 1rem;
        width: 100%;
        max-width: 14rem;
      }
    }

    .image {
      max-width: 96%;
      display: flex;
      justify-content: center;
      overflow: hidden;
    }

    .file {
      display: flex;
      align-items: center;
      flex-direction: column;

      background: white;
      padding: 1rem 2rem;
      border-radius: 0.25rem;
      width: 14rem;
      max-width: 14rem;

      .info {
        text-align: center;
        line-height: 1.25;

        small {
          margin-top: 0.125rem;
        }
      }

      > .icon {
        font-size: 4rem;
        line-height: 1;
        color: ${theme.colors.primary};
      }
    }
  `}
`
