import { css } from 'styled-components'

const primaryButtonTemplate = () => css`
  background: var(--primary);
  color: var(--white);

  &:hover {
    filter: brightness(1.5);
  }
`

export default primaryButtonTemplate
