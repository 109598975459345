const modelProduct = (data: PublisherProduct) => ({
  id: data.id,
  product_sku: data.product_sku,
  name: data.name?.toLowerCase(),
  image: data.image_url,
  active: data.active,
  gtin: data.gtins,
  reference: data.additional_references,
  categories: data.categories
})

export default modelProduct
