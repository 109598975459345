import styled, { DefaultTheme, css } from 'styled-components'

export const WrapperStatus = styled.div`
  display: flex;
  align-items: center;
`

export const TitleStrong = styled.strong`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  margin-right: 1rem;
`

interface StatusContainerInterface {
  variant?:
    | 'success'
    | 'success_alert'
    | 'warning'
    | 'danger'
    | 'default'
    | 'info'
}

const containerStatusBackground = {
  info: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.infoLighten50};
  `,
  success: () => css`
    background-color: rgba(75, 191, 115, 0.3);
  `,
  success_alert: () => css`
    background-color: rgba(155, 191, 115, 0.3);
  `,
  warning: () => css`
    background-color: rgba(224, 131, 0, 0.3);
  `,
  danger: () => css`
    background-color: rgba(217, 83, 79, 0.3);
  `,
  default: () => css`
    background-color: rgba(157, 155, 170, 0.3);
  `
}

export const StatusContainer = styled.div<StatusContainerInterface>`
  ${({ theme, variant }) => css`
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;

    ${variant === 'info' && containerStatusBackground.info(theme)};
    ${variant === 'success' && containerStatusBackground.success(theme)};
    ${variant === 'warning' && containerStatusBackground.warning(theme)};
    ${variant === 'danger' && containerStatusBackground.danger(theme)};
    ${variant === 'success_alert' &&
    containerStatusBackground.success_alert(theme)};
    ${variant === 'default' && containerStatusBackground.default(theme)};
  `}
`

export const WrapperInteractions = styled.div`
  display: flex;
  margin-top: 0.5rem;
  gap: 0.5rem;
`

export const TextButtonEdit = styled.span`
  display: flex;
  gap: 0.3rem;
  align-items: center;
`
