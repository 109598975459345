import { formatNumberWithDots } from '~/helpers'

import { types } from '../constants'

const index = {
  pending: `${types.dashboard}_PENDING`,
  fulfilled: `${types.dashboard}_FULFILLED`,
  rejected: `${types.dashboard}_REJECTED`
}

const formatDashboardData = response => {
  const {
    total_dispatch_month,
    total_dispatch_today,
    limit_per_day = 'unlimited',
    balance
  } = response

  // Debug data
  // total_dispatch_today = 10000;
  // balance = 800;

  const isUnlimited = limit_per_day === 'unlimited'

  const todayLimit = isUnlimited
    ? total_dispatch_today * 2 - total_dispatch_today
    : limit_per_day - total_dispatch_today

  const todayBalance = todayLimit > balance ? balance : null

  const dashData = {
    isUnlimited,
    daily_usage: formatNumberWithDots(total_dispatch_today),
    daily_limit: formatNumberWithDots(limit_per_day),
    today_limit: formatNumberWithDots(todayLimit),
    balance: formatNumberWithDots(balance),
    todayBalance: todayBalance ? formatNumberWithDots(todayBalance) : null,
    messagesThisMonth: formatNumberWithDots(total_dispatch_month),
    raw: {
      ...response,
      todayLimit,
      todayBalance
      // total_dispatch_today,
      // balance,
    }
  }

  return dashData
}

export const dashboard = {
  [index.pending]: ({ draft }) => {
    draft.campaign.dashboard.loading = true
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.campaign.dashboard.data = formatDashboardData(action.payload)
    draft.campaign.dashboard.loading = false
    draft.campaign.dashboard.error = false
  },
  [index.rejected]: ({ draft }) => {
    draft.campaign.dashboard.loading = false
    draft.campaign.dashboard.error =
      'Houve um erro ao carregar os dados de créditos campanhas.'
  }
}
