/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import {
  MapContainer,
  TileLayer,
  Polygon,
  FeatureGroup,
  Tooltip,
  ImageOverlay,
  Marker,
  useMap,
  Popup
} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import PropTypes, { element } from 'prop-types'

import { AddressInline } from '~/components/Address'
import Debug from '~/components/Debug'
import {
  blueLeafletMarkerIcon,
  redLeafletMarkerIcon
} from '~/lib/leaftletMapMarkers'

import { useMapDraw } from '../../hooks/useMapDraw'
import { getCentroid } from '../../utils/getCentroid'
import { pathOptions } from './pathOptions'

import * as S from './styles'

const MapDraw = ({ extenalCenterMap, isPreview, isShowingEdit }) => {
  const {
    editingRegion,
    setEditMapRef,
    setEditableFG,
    setEditingRegion,
    handleEditingRegion,
    handleToggleRegionEditMode,
    handleStorePolygonRef,
    //
    drawing,
    drawMode,
    isEditingMode,
    shouldResetMap,
    setShouldResetMap
  } = useMapDraw()

  const [center, setCenter] = useState(null)
  const [externalCenter, setExternalCenter] = useState(null)
  const [searchedAddress, setSearchedAddress] = useState(null)
  const [map, setMap] = useState(null)
  const [draggable, setDraggable] = useState(false)
  const editRef = useRef()

  const {
    mapCoordinates,
    locationRegions,
    location: { location = null },
    showRegionsOnEditData
  } = useSelector(state => state.locations)

  const { zoom } = mapCoordinates

  const { mode } = useParams()

  useEffect(() => {
    if (drawing || editingRegion?.currentEditableRef?.edited) {
      return
    }

    // Centralizando o mapa para a região editada
    if (editingRegion?.points) {
      const newCenter = getCentroid(editingRegion.points)

      setCenter(newCenter)

      map?.setView(newCenter, zoom, {
        animate: true
      })

      return
    }

    // Centralizando o mapa para o local selecionado
    if (location && location?.latitude && location?.longitude) {
      const { latitude, longitude } = location

      const newCenter = [latitude, longitude]

      map?.setView(newCenter, zoom, {
        animate: true
      })

      setCenter([latitude, longitude])

      return
    }

    // Centralizando de acordo com a primeira região da lista
    if (isPreview && locationRegions) {
      const newCenter = locationRegions?.[0]?.points
        ? getCentroid(locationRegions[0].points)
        : [-22.911849, -43.359474]

      setCenter(newCenter)

      map?.setView(newCenter, zoom, {
        animate: true
      })

      return
    }
  }, [drawing, location, editingRegion, map, locationRegions, isPreview])

  // Recentralizando de acordo com o pin temporário
  useEffect(() => {
    if (extenalCenterMap === null) {
      setExternalCenter(null)

      setSearchedAddress(null)
    }

    if (extenalCenterMap) {
      const { latitude, longitude } = extenalCenterMap

      const coords = [latitude, longitude]

      setExternalCenter(coords)

      setSearchedAddress(extenalCenterMap)

      map?.setView(coords, 15, {
        animate: true
      })
    }
  }, [extenalCenterMap])

  /**
   * Draw
   */
  const [mapLayers, setMapLayers] = useState()
  const [newLayer, setNewLayer] = useState()
  const [layerShowEdit, setLayerShowEdit] = useState()
  const [layerShadow, setLayerShadow] = useState([])

  const mapRef = useRef()

  useEffect(() => {
    if (showRegionsOnEditData && locationRegions && !mapLayers) {
      const sortByType = (a, b) => {
        if (a.type < b.type) return -1

        if (a.type > b.type) return 1

        return 0
      }

      const newMapLayers = locationRegions
        .map(region => ({
          ...region,
          id: region.id,
          latlngs: region.points,
          type: region.region_type,
          pathOptions:
            region.region_type === 1
              ? pathOptions.polygon.default
              : pathOptions.polygon.forbidden
        }))
        .sort(sortByType)

      setMapLayers(newMapLayers)
      setShouldResetMap(false)

      const showEditMapLayers = showRegionsOnEditData
        ?.map(region => ({
          ...region,
          id: region.id,
          latlngs: region.points,
          type: region.region_type,
          pathOptions: pathOptions.polygon.showEdit
        }))
        .sort(sortByType)

      setLayerShowEdit(showEditMapLayers)
    }
  }, [locationRegions, mapLayers, showRegionsOnEditData, setShouldResetMap])

  useMemo(() => {
    const isOk = (arr1, arr2) => arr1?.id === arr2?.id

    const array = (firtsArr, secondArr, compareFunction) =>
      firtsArr?.filter(
        firstArrItem =>
          !secondArr.some(secondArrItem =>
            compareFunction(firstArrItem, secondArrItem)
          )
      )

    const newArrayDiff = array(layerShowEdit, mapLayers, isOk)

    setLayerShadow(newArrayDiff)
  }, [layerShowEdit, mapLayers])

  useEffect(() => {
    if (shouldResetMap) {
      setLayerShowEdit(null)
      setMapLayers(null)
      setShouldResetMap(false)
    }
  }, [shouldResetMap, setShouldResetMap])

  useEffect(() => {
    if (newLayer) {
      handleEditingRegion(newLayer)
    }
  }, [newLayer])

  const handleAddPoint = useCallback(e => {
    // console.log('ON_DRAW_VERTEX', e);

    const { layers } = e

    const latlngs = Object.keys(layers._layers).map(
      item => layers._layers[item]._latlng
    )

    setNewLayer({ latlngs })
  }, [])

  const handleEditPolygon = useCallback(e => {
    const latlngs = e.target.editing.latlngs[0][0]

    setNewLayer({ latlngs })
  }, [])

  const onCreate = useCallback(
    e => {
      // console.log('ON_CREATE', e);

      const { layerType, layer } = e

      if (layerType === 'polygon') {
        const { _leaflet_id } = layer

        const updatededLayers = [
          // ...(mapLayers || []),
          { id: _leaflet_id, latlngs: layer.getLatLngs()[0] }
        ]

        setEditingRegion(oldState => ({ ...oldState, ...updatededLayers[0] }))
      }
    },
    [setEditingRegion]
  )

  const onEdited = e => {
    // console.log('ON_EDITED', e);

    const {
      layers: { _layers }
    } = e

    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers(layers =>
        layers.map(layer =>
          layer.id === _leaflet_id
            ? { ...layer, latlngs: { ...editing.latlngs[0] } }
            : layer
        )
      )
    })
  }

  const onDeleted = e => {
    // console.log('ON_DEETED', e);
    const {
      layers: { _layers }
    } = e

    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers(layers => layers.filter(l => l.id !== _leaflet_id))
    })
  }

  /**
   * Mudando cor enquanto edita.
   * Desabilitei por enquanto por estava dando conflito para começar um novo desenho.
   */
  // useEffect(() => {
  //   const baseColor = editingRegion?.type === 2 ? '#DC0A15' : '#383386';

  //   if (editMapRef?.current) {
  //     if (
  //       editMapRef?.current._toolbars?.draw._modes?.polygon?.handler?.options
  //     ) {
  //       editMapRef.current._toolbars.draw._modes.polygon.handler.options.shapeOptions.color =
  //         baseColor;
  //       editMapRef.current._toolbars.draw._modes.polygon.handler.options.shapeOptions.fillColor =
  //         baseColor;
  //     }
  //   }
  // }, [editMapRef, editingRegion]);

  const shapeOptions = {
    stroke: true,
    color: '#00AA99',
    weight: 2,
    opacity: 1,
    fill: true,
    fillColor: '#00FF00',
    fillOpacity: 0.3,
    clickable: true
  }

  const shapeSelectedOptions = {
    color: '#00FF00',
    weight: 3,
    fillOpacity: 0.5
  }

  const debugValues = useMemo(
    () => [
      {
        label: 'drawing',
        value: drawing
      },
      {
        label: 'drawMode',
        value: drawMode
      },
      {
        label: 'isEditingMode',
        value: isEditingMode
      }
    ],
    [drawing, drawMode, isEditingMode]
  )

  const DEBUG = false

  return (
    <S.MapContainer>
      {DEBUG && <Debug values={debugValues} />}

      {center && (
        <MapContainer
          center={center}
          zoom={13}
          zoomControl
          scrollWheelZoom
          style={{ height: '100%', zIndex: 2 }}
          whenReady={({ target }) => setMap(target)}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {externalCenter && (
            <Marker position={externalCenter} icon={redLeafletMarkerIcon}>
              <Popup>
                {searchedAddress && (
                  <AddressInline location={searchedAddress} />
                )}
              </Popup>
            </Marker>
          )}

          {!isPreview && (
            <Marker position={center} icon={blueLeafletMarkerIcon} />
          )}

          <FeatureGroup
            ref={featureGroupRef => {
              setEditableFG(featureGroupRef)
            }}
          >
            {mode === 'edit' &&
              isShowingEdit &&
              layerShadow?.map(item => (
                <Polygon
                  className={`region_${item.id} region_shadow`}
                  key={item.id}
                  pathOptions={item.pathOptions}
                  positions={item.latlngs}
                >
                  <Tooltip opacity={1}>{item.name}</Tooltip>
                </Polygon>
              ))}

            {mapLayers?.map(item => (
              <Polygon
                className={`region_${item.id}`}
                key={item.id}
                pathOptions={
                  item.id === editingRegion?.id
                    ? { ...item.pathOptions, ...shapeSelectedOptions }
                    : item.pathOptions
                }
                positions={item.latlngs}
                ref={polygonRef => {
                  handleStorePolygonRef({
                    id: item.id,
                    polygon: item,
                    ref: polygonRef
                  })
                }}
                eventHandlers={
                  isPreview
                    ? null
                    : {
                        click: e =>
                          handleToggleRegionEditMode({ e, polygon: item }),
                        edit: handleEditPolygon
                      }
                }
              >
                <Tooltip permanent opacity={1}>
                  {item.name}
                </Tooltip>
              </Polygon>
            ))}

            {!isPreview && (
              <EditControl
                onMounted={setEditMapRef}
                position="topright"
                onCreated={onCreate}
                onEdited={onEdited}
                onDeleted={onDeleted}
                onDrawVertex={handleAddPoint}
                draw={{
                  rectangle: false,
                  polyline: false,
                  circle: false,
                  circlemarker: false,
                  marker: false,
                  polygon: {
                    showLength: true,
                    showArea: true,
                    shapeOptions
                  }
                }}
              />
            )}
          </FeatureGroup>
        </MapContainer>
      )}
    </S.MapContainer>
  )
}

export default MapDraw

MapDraw.propTypes = {
  extenalCenterMap: PropTypes.shape({}),
  isPreview: PropTypes.bool,
  isShowingEdit: PropTypes.bool
}

MapDraw.defaultProps = {
  extenalCenterMap: null,
  isPreview: false,
  isShowingEdit: false
}
