import React, { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'
import { Scope } from '@unform/core'
import { t } from 'i18next'

import AlertInfo from '~/components/AlertInfo'
import Loading from '~/components/Loading'
import { FormGroup, Label, Checkbox } from '~/components/unform'
import useQueryParams from '~/hooks/useQuery'

import {
  listPlacementQueryKey,
  listPlacementsService
} from '../../services/placements'
import { formatPlacementsWithContext } from './helpers/formatPlacementsWithContext'

import * as S from './styles'

const PlacementsCheckboxesList: React.FC<PlacementsCheckboxesListProps> = ({
  label = null,
  pathUnform = 'placements_ids',
  targeting = null,
  adType
}) => {
  const [loading, setLoading] = useState(true)

  const publisherId = useQueryParams().get('rmid')

  const QUERY_KEY = [listPlacementQueryKey, publisherId]

  const { data, error } = useQuery({
    queryKey: QUERY_KEY,
    queryFn: async () => {
      const res = await listPlacementsService({ publisher_id: publisherId })

      return res.data.data
    }
  })

  const placements: PlacementsOptions = useMemo(() => {
    if (data) {
      const groupedOptionsPlacements = formatPlacementsWithContext({
        data,
        targeting,
        adType
      })

      setLoading(false)

      return groupedOptionsPlacements
    }

    if (error) {
      setLoading(false)
      return [
        {
          label: t('rm:placements.list.error'),
        }
      ]
    }
  }, [adType, data, error, targeting])

  return (
    <FormGroup className="flex-grow-1">
      {label && <Label htmlFor="placements_ids" isRequired text={label} />}

      <Loading status={loading}>{t('common:actions.loadingData')}...</Loading>

      <AlertInfo
        template="warning"
        text={
          <>
            <Trans
              components={{ strong: <strong /> }}
              i18nKey="rm:placements.alert.attention"
            />
          </>
        }
      />

      <Scope path={pathUnform}>
        {placements?.map(
          context =>
            context?.options && (
              <S.Group key={context?.label}>
                <Label text={context.label} />

                <S.Children>
                  {context.options?.map(placement => (
                    <S.Child key={placement.value}>
                      <Checkbox
                        name={`${placement.value}`}
                        id={`${placement.value}`}
                        value={placement.value}
                        label={placement.label}
                        returnValue
                      />
                    </S.Child>
                  ))}
                </S.Children>
              </S.Group>
            )
        )}
      </Scope>
    </FormGroup>
  )
}

export default PlacementsCheckboxesList
