import React from 'react'
import { FiCopy, FiEdit } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { ButtonNavLink } from '~/components/Buttons'

import CancelButton from '../ListMenu/CancelButton'
import DeleteButton from '../ListMenu/DeleteButton'
import FinishButton from '../ListMenu/FinishButton'
import PauseButton from '../ListMenu/PauseButton'
import ReactiveButton from '../ListMenu/ReactiveButton'

import * as S from './styles'

const HeaderButtons = ({ isEditPage = false }) => {
  const { loading, promotion } = useSelector(state => state.promotional)
  const status = promotion?.status?.type

  return !loading && promotion ? (
    <>
      <S.Container>
        {!isEditPage && status !== 'finished' && status !== 'canceled' && (
          <ButtonNavLink
            text="Editar"
            to={`/promotional/edit/${promotion?.id}`}
            size="small"
            iconLeft={<FiEdit />}
            customWidth="auto"
          />
        )}

        <ButtonNavLink
          to={`/promotional/create/?duplicate=${promotion?.id}`}
          text="Duplicar"
          template="primaryOutlined"
          size="small"
          iconLeft={<FiCopy />}
          customWidth="auto"
        />

        {status === 'active' && <PauseButton promotion={promotion} />}

        {status === 'pause' && <ReactiveButton promotion={promotion} />}

        {status === 'pause' && <FinishButton promotion={promotion} />}

        {status === 'scheduled' && <CancelButton promotion={promotion} />}

        {status !== 'active' && status !== 'pause' && (
          <DeleteButton promotion={promotion} />
        )}
      </S.Container>
    </>
  ) : (
    <></>
  )
}

export default HeaderButtons

HeaderButtons.propTypes = {
  isEditPage: PropTypes.bool
}

HeaderButtons.defaultProps = {
  isEditPage: false
}
