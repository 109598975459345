export const itemStatusList = {
  PENDING: {
    label: 'Pendente',
    type: 'pending',
    allowZeroValue: false,
    step: 'pending'
  },
  WAITING_QUANTITY: {
    label: 'Aguardando pesagem',
    type: 'waiting_quantity',
    allowZeroValue: false,
    step: 'pending'
  },
  WAITING_CONFIRMATION: {
    label: 'Aguardando confirmação',
    type: 'waiting_confirmation',
    allowZeroValue: false,
    step: 'pending'
  },
  CONFIRMED: {
    label: 'Confirmado',
    type: 'confirmed',
    allowZeroValue: false,
    step: 'confirmed'
  },
  SUBSTITUTION: {
    label: 'Substituido',
    type: 'substitution',
    allowZeroValue: false,
    step: 'confirmed'
  },
  STOCK_OUT: {
    label: 'Em falta',
    type: 'stock_out',
    allowZeroValue: true,
    step: 'out'
  },
  DELETED: {
    label: 'Excluído',
    type: 'deleted',
    allowZeroValue: true,
    step: 'out'
  }
}

export const itemStatusListOptionsSelect = Object.values(itemStatusList).map(
  status => ({
    id: status.type,
    text: status.label,
    value: status.type
  })
)
