import React, { useEffect, useCallback, useMemo } from 'react'
import Barcode from 'react-barcode'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { ButtonCopyToClipboard } from '~/components/Buttons'
import EmptyPage from '~/components/empty-page'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import { formatSearchString } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import useModal from '~/hooks/useModal'
import { indexLocation } from '~/pages/locations/actions'
import { resetFilters } from '~/store/modules/filters/actions'
import {
  handleProductsPerPage,
  productsList
} from '~/store/modules/products/actions'
import * as types from '~/store/modules/products/constants'

import Product from '../Product'

import * as S from './styles'

const ProductsTable = ({ indicatorAtLast }) => {
  const {
    listProductsLoading,
    listProducts,
    listProductsPage,
    listProductsPerpage,
    error
  } = useSelector(state => state.products)

  const { data = null, total = -1 } = listProducts || {}

  const [Modal, currentBarCode, , setCurrentBarCode] = useModal(false)

  const { locations: filteredLocations, query } = useSelector(
    state => state.filters
  )

  const filteredLocationsIds = useMemo(
    () => filteredLocations?.value,
    [filteredLocations]
  )

  const filteredQuery = useMemo(
    () => query?.[`${types.PRODUCT_REDUX_QUERY}`] || null,
    [query]
  )

  const [AlertOnError] = useAlert(error)

  const dispatch = useDispatch()

  const { listLocations } = useSelector(state => state.locations)

  const queryStringLocation = useMemo(
    () =>
      formatSearchString({
        page: 0,
        quantity: 500
      }),
    []
  )

  useEffect(() => {
    if (!listLocations.data) {
      dispatch(indexLocation(queryStringLocation))
    }
  }, [dispatch, listLocations, queryStringLocation])

  useEffect(() => {
    if (filteredLocationsIds) {
      dispatch(
        productsList({
          quantity: listProductsPerpage,
          location_id: [filteredLocationsIds],
          query: filteredQuery
        })
      )
    }
  }, [
    dispatch,
    filteredLocationsIds,
    filteredQuery,
    listProductsPerpage,
    query
  ])

  const handlePagination = useCallback(
    page => {
      dispatch(
        productsList({
          page,
          quantity: listProductsPerpage,
          location_id: [filteredLocationsIds],
          query: filteredQuery
        })
      )
    },
    [dispatch, filteredLocationsIds, filteredQuery, listProductsPerpage]
  )

  /**
   * Handle reset filters
   */

  const handleResetFilter = useCallback(
    () => dispatch(resetFilters()),
    [dispatch]
  )

  const handleItemsPerPageChange = ({ value }) => {
    dispatch(handleProductsPerPage(value))
  }

  const handleCloseModal = () => {
    setCurrentBarCode('')
  }

  return (
    <>
      <Modal
        title="Código de Barras"
        isOpen={!!currentBarCode}
        onClose={handleCloseModal}
      >
        <Barcode value={currentBarCode} width={3} />
        <ButtonCopyToClipboard
          toCopy={currentBarCode}
          textBefore="Copiar código de barras"
          textAfter="Código de barras copiado!"
          template="primary"
          size="xsmall"
        />
      </Modal>

      <Loading status={listProductsLoading}>
        Carregando lista de produtos
      </Loading>

      <AlertOnError />

      {!filteredLocationsIds && (
        <EmptyPage
          title="Escolha um local primeiro."
          subtitle="Use o filtro acima para escolher um local."
          svgPath="photos/products-empty"
        />
      )}

      {filteredLocationsIds && !listProductsLoading && total === 0 && (
        <EmptyPage
          title="Nenhum produto encontrado"
          subtitle="Não encontramos produtos com os filtros aplicados."
          titleButton="Limpar filtros"
          svgPath="photos/products-empty"
          onClick={handleResetFilter}
        />
      )}

      {!listProductsLoading && filteredLocationsIds && (
        <S.ProductList>
          {total > 0 && (
            <ListPagination
              total={total}
              label={total > 1 ? 'produtos' : 'produto'}
              currentPage={listProductsPage}
              itemsPerPage={listProductsPerpage}
              onClickPagination={handlePagination}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}

          {data &&
            total > 0 &&
            data.map(product => (
              <Product
                key={product.id}
                data={product}
                indicatorAtLast={indicatorAtLast}
                onBarCodeClick={setCurrentBarCode}
              />
            ))}

          {total > 0 && (
            <S.Footer>
              <ListPagination
                total={total}
                currentPage={listProductsPage}
                itemsPerPage={listProductsPerpage}
                menuPlacement="top"
                onClickPagination={handlePagination}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </S.Footer>
          )}
        </S.ProductList>
      )}
    </>
  )
}

export default ProductsTable

ProductsTable.propTypes = {
  indicatorAtLast: PropTypes.bool.isRequired
}
