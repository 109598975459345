/* Cópia tags do chat */

import styled, { css } from 'styled-components';

export const UserLists = styled.div`
  ${({ theme }) => css`
    display: grid;

    grid-template-columns: repeat(2, minmax(320px, 1fr));

    margin-top: ${theme.spacings.xsmall};

    max-height: calc(100vh - 20px);
  `}
`;

export const UserList = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    z-index: 5;
    position: relative;
    overflow-y: auto;

    max-height: calc(100vh - 20px);

    /* Space to scrollbar */
    padding-right: 0.5rem;

    /* width */
    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.colors.inative};
      border-radius: 0.5rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.tertiary};
      transition: 0.3s ease-in-out all;
      border-radius: 0.5rem;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.gray};
    }

    > div + div {
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    z-index: 10;
    position: sticky;
    top: 0;
    padding: ${theme.spacings.xsmall} 0;

    background-color: var(--light);

    .alertWrapper {
      margin: 0;
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    margin: 0;
  `}
`;
