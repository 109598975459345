import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.header`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .mainBreadcrumb {
      border-bottom: 1px solid ${theme.colors.neutral.high.medium};

      a {
        max-height: 1.25rem;
        padding: 0;
      }
    }
  `}
`

export const MainInfo = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 0 1rem;

  @media ${device.laptop} {
    justify-content: space-between;
    flex-direction: row;

    margin: 0 0 0.5rem;

    // Deixei essa gambiarra até mudarmos para SC
    + hr {
      margin-top: -1.25rem;
    }

    + hr + .wrapper-datepicker-header,
    + .wrapper-datepicker-header {
      margin: 0 0 1.25rem;
    }
  }

  > div {
    display: flex;
    align-items: center;
  }

  .titleSideRightComponent {
    margin-right: 11px;
  }

  .title {
    display: flex;
    flex: 1;

    @media ${device.laptop_max} {
      flex-direction: column;
      align-items: flex-start;
    }

    .wrap {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;

      @media ${device.laptop} {
        width: auto;

        & + .wrap {
          margin-left: 1rem;
        }

        .selectWrapper,
        .searchWrapper {
          width: 330px;
          max-width: 100%;
        }
      }

      @media ${device.laptop_max} {
        & + .wrap {
          margin-left: 0;
        }
      }

      h1 {
        font-size: 1.125rem;
        font-weight: 700;
        color: var(--dark);
        text-align: center;
        margin-bottom: 0.75rem;

        @media ${device.laptop} {
          margin-bottom: 0;
          font-size: 1rem;
          text-align: left;
        }
      }

      .subtitle {
        text-align: center;

        @media ${device.laptop} {
          text-align: left;
        }
      }
    }
  }

  .sideLeftComponent {
    justify-content: flex-start;

    .searchWrapper,
    .selectWrapper {
      flex: 1;
    }
  }

  .sideRightComponent {
    justify-content: center;

    > * + * {
      margin-left: 0.5rem;
    }

    .selectWrapper,
    .searchWrapper {
      width: 330px;
      max-width: 100%;
    }

    .filtersWrapper {
      margin: 0;
      padding: 0;
    }
  }

  @media ${device.laptop_max} {
    &.has-datepicker {
      margin: 0 0 0.75rem;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid var(--tertiary);

      .title {
        padding: 0.75rem 0 0;
      }

      .sideLeftComponent {
        padding: 1.25rem 0 0.75rem;
        margin-bottom: 0.5rem;
        justify-content: center;
        border-bottom: 1px solid $disabled;

        .flex1 {
          flex: 1;
        }
      }

      .sideRightComponent {
        margin-top: 0.5rem;
        justify-content: center;
        flex-wrap: wrap;

        .wrapper-datepicker-header {
          flex: 1 1 100%;

          .datepicker__wrapper {
            justify-content: center;

            .labelMobile {
              margin-right: 0.25rem;
            }
          }
        }
      }
    }
  }

  h3 {
    margin-bottom: 0;

    + p {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }
`
