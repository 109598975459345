import React from 'react';
import PropTypes from 'prop-types';

import Panel from '~/components/Panel';
import LineChart from '~/components/charts/line-chart';

const Competitiveness = ({ loading, data = {} }) => (
  <Panel title="Competitividade" noPaddingBody isLoading={loading}>
    <LineChart data={data.orderIncomeByDay} loading={loading} />
  </Panel>
);

export default Competitiveness;

Competitiveness.propTypes = {
  data: PropTypes.shape(),
  loading: PropTypes.bool,
};

Competitiveness.defaultProps = {
  data: {},
  loading: false,
};
