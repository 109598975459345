import { useCallback } from 'react'
import { FiGrid, FiMenu } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { Button } from '~/components/Buttons'
import { handleMetricsChartLayout } from '~/layouts/layout-actions'
import { useAppSelector } from '~/store/hooks'

import * as S from './styles'

const WrapperChartAndCards = ({ children }: { children: React.ReactNode }) => {
  const { metricsLayout } = useAppSelector(state => state.layout)

  const dispatch = useDispatch()

  const handleLayout = useCallback(
    layout => {
      dispatch(handleMetricsChartLayout({ mode: layout }))
    },
    [dispatch]
  )

  return (
    <S.WrapperMetrics gridLayout={'full'}>
      {children}

      {/* <S.NavLayout>
        <Button
          template={
            metricsLayout === 'compact' ? 'transparentPrimary' : 'transparent'
          }
          size="xsmall"
          iconLeft={<FiGrid />}
          text="Compacto"
          onClick={() => handleLayout('compact')}
        />

        <Button
          template={
            metricsLayout === 'full' ? 'transparentPrimary' : 'transparent'
          }
          size="xsmall"
          iconLeft={<FiMenu />}
          text="Expandido"
          onClick={() => handleLayout('full')}
        />
      </S.NavLayout> */}
    </S.WrapperMetrics>
  )
}

export default WrapperChartAndCards
