import styled, { css } from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
  padding: 0;
  position: relative;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }

  .text {
    font-size: 0.75rem;
    text-align: left;
    margin-left: 0.5rem;
    color: var(--gray);
    max-width: 0;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
    white-space: nowrap;
  }

  &:hover {
    .text {
      max-width: 10rem;
    }
  }

  ${({ template }) =>
    template === 'lite' &&
    css`
      .text {
        display: none;
      }
    `}

  ${({ useBot }) =>
    !useBot &&
    css`
      cursor: not-allowed !important;
    `}

  .forbidden {
    position: absolute;
    left: 4px;
    width: 21px;
    height: 1px;
    background-color: var(--danger);
    transform: rotate(-22.5deg);
    z-index: 2;
  }
`;
/**
 *
 */

const botMofifiers = {
  isLoading: () => css`
    color: var(--warning);
    &::after,
    &::before {
      color: var(--warning);
    }
  `,
  active: () => css`
    color: var(--success);
    &::after,
    &::before {
      color: var(--success);
      box-shadow: 0 0 0 2px;
    }
  `,
  dontUseBot: () => css`
    color: var(--tertiary);
    &::after,
    &::before {
      color: var(--tertiary);
      box-shadow: 0 0 0 2px;
    }
  `,
};

export const Bot = styled.span`
  ${({ active, isLoading, useBot }) => css`
    & {
      box-sizing: border-box;
      position: relative;
      display: block;
      width: 21px;
      height: 10px;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 1px solid transparent;
      border-radius: 12px;
      box-shadow: 0 0 0 2px, -4px 0 0 -1px, 4px 0 0 -1px, inset 0 -4px 0 -2px;

      transition: 0.3s all ease-in-out;
    }
    &::after,
    &::before {
      transition: 0.3s all ease-in-out;

      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 2px;
      height: 2px;
      box-shadow: 00 0 0 2px, 0 0.5px 0 -0.5px inset;
      top: 3px;
    }
    &::before {
      left: -4px;
      border-radius: 3px;
    }
    &::after {
      border-radius: 100px;
      right: -4px;
    }

    color: var(--danger);
    &::after,
    &::before {
      color: var(--danger);
      box-shadow: 0 0 0 2px, 0 0.5px 0 1.5px inset;
    }

    ${active && botMofifiers.active()}
    ${isLoading && botMofifiers.isLoading()}
    ${!useBot && botMofifiers.dontUseBot()}
  `}
`;
