import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LocationName from '~/components/LocationName';
import * as LocationRadioListStyles from '~/components/LocationsRadioList/styles';
import Panel from '~/components/Panel';
import { Badge } from '~/components/UI/Badge';

const PromotionLocations = () => {
  const { data } = useSelector(
    ({ promotionFlyer }) => promotionFlyer.flyers.view,
  );

  const labelLocations = useMemo(() => {
    const length = data?.location_ids_raw?.length || 0;

    switch (true) {
      case length === 1:
        return `${length} local selecionado`;

      case length > 1:
        return `${length} locais selecionados`;

      default:
        return 'Todos os locais';
    }
  }, [data]);

  return (
    <Panel
      title="Locais participantes"
      isCollapsible={!!data?.location_ids_raw?.length}
      initialCollapsibleState={false}
      headerSideContent={<Badge>{labelLocations}</Badge>}
    >
      {!!data?.location_ids_raw && (
        <LocationRadioListStyles.LocationGroup>
          {data?.location_ids_raw.map(item => (
            <LocationRadioListStyles.LocationItem key={item}>
              <div>
                <label>
                  <LocationName locationId={item} />
                </label>
              </div>
            </LocationRadioListStyles.LocationItem>
          ))}
        </LocationRadioListStyles.LocationGroup>
      )}
    </Panel>
  );
};

export default PromotionLocations;
