import * as types from '../constants';

const index = {
  pending: `${types.FLYERS_DELETE}_PENDING`,
  fulfilled: `${types.FLYERS_DELETE}_FULFILLED`,
  rejected: `${types.FLYERS_DELETE}_REJECTED`,
};

export const deleteFlyer = {
  [index.pending]: ({ draft }) => {
    draft.flyers.remove.loading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    const { deleted_id } = action.payload;
    const oldflyers = draft.flyers.list.data;

    const filteredflyers = oldflyers?.filter(item => item.id !== deleted_id);

    draft.flyers.remove.loading = false;
    draft.flyers.list.data = filteredflyers;
  },
  [index.rejected]: ({ draft }) => {
    draft.flyers.remove.loading = false;
  },
};
