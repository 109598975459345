import styled, { DefaultTheme, css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${theme.spacings.medium};
    margin-top: ${theme.spacings.xxlarge};
    margin-bottom: ${theme.spacings.medium};
  `}
`

const cardStyle = (theme: DefaultTheme) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: ${theme.spacings.medium};

  border: 1px solid ${theme.colors.neutral.high.dark};

  border-radius: ${theme.spacings.xxxsmall};

  background-color: ${theme.colors.white};
`

export const BalanceWrapper = styled.div`
  ${({ theme }) => css`
    ${cardStyle(theme)}

    .balance {
      display: flex;
      align-items: center;

      > svg {
        font-size: ${theme.font.sizes.xxlarge};
        color: #383386;
        margin-right: ${theme.spacings.xxxsmall};
      }
      > span {
        font-size: ${theme.font.sizes.xxlarge};
        font-style: normal;
        font-weight: ${theme.font.bold};
        color: ${theme.colors.neutralLow};
      }
    }

    .LoadingContainer {
      width: 100%;
    }
  `}
`

export const RechargeWrapper = styled.div`
  ${({ theme }) => css`
    ${cardStyle(theme)}

    > form {
      display: flex;

      .inputContainer {
        max-height: 2.375rem;
        width: 15rem;
        border-radius: 0.25rem 0 0 0.25rem;
      }

      button {
        border-radius: 0 0.25rem 0.25rem 0;
      }
    }
  `}
`

export const LastTransactions = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: 1px solid ${theme.colors.neutralHigh};
    border-radius: ${theme.spacings.xxxsmall};

    > div {
      padding: ${theme.spacings.medium};
      width: 50%;
      display: flex;

      .title {
        font-size: ${theme.font.sizes.xsmall};
        font-style: normal;
        font-weight: ${theme.font.bolder};
        color: ${theme.colors.neutralLow};
      }

      .subtitle {
        font-size: ${theme.font.sizes.xsmall};
        font-style: normal;
        font-weight: ${theme.font.bold};
        color: ${theme.colors.stormGray};
        margin-top: ${theme.spacings.xxxsmall};
      }

      .balance {
        font-size: ${theme.font.sizes.xlarge};
        font-style: normal;
        font-weight: ${theme.font.bold};
        color: #404256;
        margin-top: ${theme.spacings.xsmall};
      }

      .not-balance {
        font-size: ${theme.font.sizes.xsmall};
        font-style: normal;
        font-weight: ${theme.font.bold};
        color: ${theme.colors.neutralColor};
        margin-top: ${theme.spacings.xsmall};
      }
    }

    .text-value {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
    }

    .icon {
      font-size: ${theme.font.sizes.xxlarge};
      margin-right: ${theme.spacings.xxxsmall};
    }
  `}
`

export const DebitWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.spacings.xxxsmall} 0 0 ${theme.spacings.xxxsmall};
    background-color: ${theme.colors.neutral.high.light};
    border-right: 1px solid ${theme.colors.neutral.high.dark};

    .icon {
      color: #dc0a15;
    }
  `}
`

export const CreditWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: 0 ${theme.spacings.xxxsmall} ${theme.spacings.xxxsmall} 0;
    background-color: ${theme.colors.white};

    .icon {
      color: #52c41a;
    }
  `}
`
export const TextRecharge = styled.section`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.normal};

    a {
      text-decoration: underline;
    }
  `}
`
export const WizardRechargeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50vh;
`

/**
 * Modal
 */
export const ModalContent = styled.section`
  form {
    .formGroup {
      &:not(:first-child) {
        margin-top: 1rem;
      }

      .radioListWrapper {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      }
    }

    textarea {
      min-height: 4rem;
    }
  }
`

export const TextModal = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-style: normal;
    font-weight: ${theme.font.normal};
    margin-bottom: 2rem;

    a {
      text-decoration: underline;
    }
  `}
`
