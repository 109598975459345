import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';

import logo from '../../assets/img/brands/newtail-logo.png';
import { requiredValidation, history } from '../../helpers';
import Button from '../../components/Button';
import Form from '../../components/forms/form';
import Input from '../../components/forms/inputs';
import Panel from '../../components/Panel';
import Progress from '../../components/progress';
import { integrationBling } from './integration-actions';

const IntegrationForm = ({
  intl,
  integrate,
  loading,
  integrationBling,
  progress,
  finishIntegration,
}) => {
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    finishIntegration && history.push('/sign-up');
  }, [finishIntegration]);

  const renderForm = () => {
    return (
      <Form
        className="integration__form"
        onSubmit={integrationBling}
        checkIsValid={isValid => setFormIsValid(isValid)}
      >
        <FormGroup>
          <Input name="token" label="Token" validate={[requiredValidation]} />
        </FormGroup>
        <div className="text-center mt-2">
          <Button
            type="submit"
            color="primary"
            size="lg"
            text="Continuar"
            loading={loading}
            spinner={{ type: 'grow', size: 'sm', color: 'secondary' }}
            disabled={!formIsValid}
          />
          <div className="auth-form__small-text">
            <small>
              Está com dúvidas? <Link to="#">Clique aqui</Link>
            </small>
          </div>
        </div>
      </Form>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Progress value={progress} />
        <p className="auth-form__small-text">
          <Link to="/">Ir para o dashboard</Link>
        </p>
      </>
    );
  };

  return (
    <div className="integration">
      <div className="integration__content">
        <img
          src={logo}
          alt={intl.formatMessage({ id: 'signup.logo.alt' })}
          className="img-fluid rounded-circle"
          width="228"
          height="89"
        />
        <h3>Integração</h3>
        <Panel className="integration__panel">
          <img
            src="../../../../../public/images/bling-logo.png"
            width="80"
            className="integration__logo"
          />
          <p className="integration__subtitle">
            {loading
              ? 'Estamos importando seus dados. Aguarde um instante :)'
              : 'Insira o token da sua conta Bling. Você encontra o token no painel de administração da plataforma Bling'}
          </p>
          {loading ? renderLoading() : renderForm()}
        </Panel>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    loading: state.integration.loading,
    progress: state.integration.progress,
    finishIntegration: state.integration.finishIntegration,
  }),
  {
    integrationBling,
  },
)(injectIntl(IntegrationForm));
