import styled, { css } from 'styled-components'

const messageTemplate = {
  received: theme => css`
    align-items: flex-start;

    .wrap {
      border-radius: 0.5rem 0.5rem 0.5rem 0;

      background-color: ${theme.primary};
      color: ${theme.white};

      a {
        color: ${theme.white};
      }

      .rhap_play-pause-button,
      .rhap_time {
        color: ${theme.light};
      }
    }
  `,
  sent: theme => css`
    align-items: flex-end;

    .wrap {
      border: 1px solid ${theme.inative};
      border-radius: 0.5rem 0.5rem 0 0.5rem;
      background-color: ${theme.white};
    }

    .senderName {
      margin-top: 0.25rem;
      color: ${theme.gray};
      font-size: 0.75rem;
      font-weight: 500;
      text-align: right;
    }
  `,
  isGrouped: () => css`
    margin-bottom: 0.125rem;

    .wrap {
      border-radius: 0.5rem;
    }
  `,
  hasImage: () => css`
    .wrap {
      padding: 0.5rem;

      .message {
        flex-direction: column;

        .text {
          padding: 0 1rem 0.5rem 1rem;
        }
      }
    }
  `,
  hasFile: () => css`
    .wrap {
      padding: 0.5rem;

      .message {
        flex-direction: column;
        .text {
          display: none;
        }
      }
    }
  `,
  hasVideo: () => css`
    .wrap {
      padding: 0.25rem 0.25rem 0.5rem;

      .message {
        flex-direction: column;
      }
    }
  `,
  hasAudio: () => css`
    .wrap {
      padding: 0.5rem;

      .message {
        .audio {
          width: 300px;
        }
      }
    }
  `
}

export const Container = styled.div`
  ${({
    isSent,
    theme,
    hasImage,
    hasAudio,
    hasVideo,
    hasFile,
    isGrouped
  }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;

    .wrap {
      display: inline-flex;
      flex-direction: column;

      padding: 0.5rem 0;

      max-width: 30rem;

      .message {
        word-break: break-word;
        display: flex;
        flex-flow: wrap;
        justify-content: flex-end;
        flex-wrap: nowrap;

        font-size: 0.8125rem;

        .text {
          padding: 0 1rem;
          /* white-space: pre-line; */

          a {
            text-decoration: underline;
          }
        }

        .image {
          margin-bottom: 0.25rem;

          padding: 0.25rem;

          img {
            max-width: 100%;
            max-height: 400px;
            border-radius: 0.5rem;
          }
        }

        .file_pdf {
          background: var(--light);
          padding: 0.5rem;
          border-radius: 0.25rem;

          .file {
            display: flex;
            align-items: center;

            .name {
              margin-left: 0.25rem;
              line-height: 1.25;
              font-weight: 600;
              color: var(--dark);
            }

            .icon {
              background: ${theme.colors.danger};
              width: 2rem;
              height: 2rem;
              padding: 0.125rem;
              border-radius: 0.25rem;

              svg {
                color: ${theme.colors.white};
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .video {
          margin-bottom: 0.5rem;
          padding: 0;
          width: 320px;

          &:focus {
            outline: none;
          }
        }
      }
    }

    aside {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      line-height: 1;
      flex-wrap: nowrap;

      margin-left: 1.25rem;
      flex-shrink: 1;

      color: ${theme.gray};
      font-size: ${theme.font.sizes.xsmall};

      padding: 0 1rem 0 0;

      .time {
        white-space: nowrap;
      }

      .status {
        font-size: ${theme.font.sizes.small};
        min-width: 14px;
        margin-left: 0.25rem;

        &.read {
          color: ${theme.info};
        }
      }
    }

    ${isSent && messageTemplate.sent(theme)}
    ${!isSent && messageTemplate.received(theme)};
    ${hasImage && messageTemplate.hasImage()};
    ${hasAudio && messageTemplate.hasAudio(theme)};
    ${hasVideo && messageTemplate.hasVideo(theme)};
    ${hasFile && messageTemplate.hasFile(theme)};
    ${isGrouped && messageTemplate.isGrouped(theme)};
  `}
`
