import { produce } from 'immer';
import * as types from './sign-up-constants';

const INITIAL_STATE = {
  loading: false,
  error: null,
  step: 1,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.SIGN_UP_CREATE_USER}_FULFILLED`: {
        draft.loading = false;
        break;
      }
      case `${types.SIGN_UP_CREATE_USER}_REJECTED`: {
        draft.loading = false;
        break;
      }
      case `${types.SIGN_UP_CREATE_USER}_PENDING`:
        draft.loading = true;
        break;
      case types.SIGN_UP_CHANGE_STEP:
        draft.step = action.payload;
        break;
      default:
        return state;
    }
  });
