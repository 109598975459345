import React, { useMemo, useCallback } from 'react'
import { FiCheckSquare, FiSquare, FiX } from 'react-icons/fi'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'
import { formatDateToString } from '~/helpers'
import {
  createLocationUsers,
  deleteLocationUsers
} from '~/pages/locations/actions'

import * as S from './styles'

const UserItem = ({ data, locationId, isActive, isSaving, isDeleted }) => {
  const dispatch = useDispatch()

  const bodyCreate = useMemo(
    () => ({
      location_id: locationId,
      user_id: data.id
    }),
    [data.id, locationId]
  )

  const paramsDelete = useMemo(
    () => ({
      locationId,
      userId: data.id
    }),
    [data.id, locationId]
  )

  const action = useCallback(
    () =>
      isActive
        ? deleteLocationUsers(paramsDelete)
        : createLocationUsers({ body: bodyCreate }),
    [bodyCreate, isActive, paramsDelete]
  )

  const handleClick = useCallback(async () => {
    dispatch(action())
  }, [dispatch, action])

  return (
    <S.Container isChecked={isActive} isSaving={isSaving} isDeleted={isDeleted}>
      <button onClick={handleClick} type="button" className="check">
        {isActive ? <FiCheckSquare /> : <FiSquare />}
      </button>

      <div className="label">
        <div className="userInfos">
          <strong className="name">{data.name}</strong>
          <span className="email">{data.email}</span>

          <small>Vinculado em: {formatDateToString(data.updated_at)}</small>
        </div>

        <div className="role">
          <FormattedMessage id={data.role} />
        </div>

        {isSaving && (
          <div className="loadingIndicator">
            <Spinner />
          </div>
        )}

        {isActive && (
          <Button
            template="deleteOutline"
            className="closeButton"
            onClick={handleClick}
            iconLeft={<FiX />}
            customWidth="20px"
            size="xsmall"
            title="Remover associação"
          />
        )}
      </div>
    </S.Container>
  )
}

export default UserItem

UserItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    saving: PropTypes.bool
  }).isRequired,
  locationId: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isSaving: PropTypes.bool,
  isDeleted: PropTypes.bool
}

UserItem.defaultProps = {
  isActive: false,
  isSaving: false,
  isDeleted: false
}
