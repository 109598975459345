import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropType from 'prop-types'

import { getAccountId, getAccountType } from '~/helpers'
import { setFilter } from '~/store/modules/filtersSellOut/actions'
import { FILTERS_SEELOUT } from '~/store/modules/filtersSellOut/constants'

import FilterAggregate from './Aggregate'
import GenericFilter from './Generic'
import { genericFilters } from './Generic/data'
import FilterSupermarkets from './Supermarkets'

import { FiltersWrapper } from './styles'

export const FiltersMetabase = ({ filters }) => {
  const { supermarkets: filteredSupermarketsIds, categories } = useSelector(
    state => state.filtersSellOut
  )

  const isGrocery = getAccountType() === 'grocery'
  const accountId = getAccountId()

  const dispatch = useDispatch()

  useEffect(() => {
    if (isGrocery) {
      const updatedStatus = [{ value: accountId, label: 'Mercado selecionado' }]

      dispatch(
        setFilter({ type: FILTERS_SEELOUT.supermarkets, data: updatedStatus })
      )
    }
  }, [accountId, dispatch, isGrocery])

  return (
    <FiltersWrapper>
      {filters.includes('supermarkets') && !isGrocery && (
        <div className="filterWrapper selectSearchableWrapper">
          <FilterSupermarkets />
        </div>
      )}

      {filteredSupermarketsIds && (
        <>
          {genericFilters.map(
            key =>
              filters.includes(key) && (
                <div
                  key={key}
                  className="filterWrapper selectSearchableWrapper"
                >
                  <GenericFilter filterKey={FILTERS_SEELOUT[key]} />
                </div>
              )
          )}

          {filters.includes('aggregate') && (
            <div className="filterWrapper selectSearchableWrapper">
              <FilterAggregate />
            </div>
          )}
        </>
      )}
    </FiltersWrapper>
  )
}

FiltersMetabase.defaultProps = {
  filters: [
    'supermarkets',
    'stores',
    'channels',
    'brands',
    'categories',
    'subcategories',
    'products',
    'aggregate'
  ]
}

FiltersMetabase.propTypes = {
  filters: PropType.arrayOf(PropType.string)
}
