/* eslint-disable react/display-name */
import React from 'react';
import { NavLink } from 'react-router-dom';

import ListMenu from '~/modules/promotional/components/ListMenu/ListMenu';

import ColumnExtra from './ColumnExtra';
import ColumnStatus from './ColumnStatus';
import ColumnType from './ColumnType';

import * as S from './styles';

export const schema = {
  title: {
    title: 'Detalhes da promoção',
    render: obj => (
      <NavLink to={`/promotional/view/${obj.id}`}>
        <S.TitleWrapper>
          <strong className="title">{obj.name}</strong>
          <span className="description">{obj.description}</span>
        </S.TitleWrapper>
      </NavLink>
    ),
    className: 'order__details col__infos col__title',
    showTotal: true,
  },
  start_date: {
    title: 'Data de início',
    // sortable: 'start_date',
    render: obj => obj.start_date || '-',
    className: 'col__mobile_inline',
  },
  end_date: {
    title: 'Data de término',
    // sortable: 'end_date',
    render: obj => obj.end_date || '-',
    className: 'col__mobile_inline',
  },
  status: {
    title: 'Status',
    // sortable: 'status.type',
    className: `col__status`,
    render: obj => <ColumnStatus status={obj.status} />,
  },
  promotion_type: {
    title: 'Tipo de promoção',
    // sortable: 'promotion_type',
    render: obj => (
      <ColumnType
        type={obj.type.label}
        code={obj?.code}
        isLoyalty={obj.is_loyalty_promotion}
      />
    ),
    className: 'col__mobile_inline',
  },
  info: {
    title: 'Informações',
    render: obj => (
      <ColumnExtra
        locationIds={obj?.location_ids || []}
        code={obj?.code}
        isLoyalty={obj.is_loyalty_promotion}
      />
    ),
    className: 'col__mobile_inline',
  },

  action: {
    render: obj => <ListMenu promotion={obj} />,
    className: 'col__menu',
  },
};
