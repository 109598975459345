import { statusType } from '~/modules/retailMedia/components/StatusBar/statusType'

import { formatMetrics } from '../metrics'

export const formatAds: FormatAds = ({
  ads,
  campaignStatus: campaignStatusRaw
}) => {
  return ads.map(({ metrics, ...ad }) => {
    const campaignStatus = campaignStatusRaw || ad?.campaign_status || null

    const canEditCampaign = campaignStatus
      ? statusType?.[campaignStatus]?.canEditDate
      : statusType?.[ad.campaign_status]?.canEditDate

    return {
      ...ad,
      _raw: {
        originPath: 'src/modules/retailMedia/dtos/common/ads/index.ts'
      },
      publisher: {
        id: ad.publisher_id,
        accounttId: ad.publisher_account_id,
        name: ad.publisher_name,
        bgColor: ad?.publisher_account_theme?.header_color || '#fff',
        logo: ad?.publisher_account_logo
      },
      advertiser: {
        id: ad.advertiser_id,
        accounttId: ad.advertiser_account_id,
        name: ad.advertiser_name,
        bgColor: ad?.advertiser_account_theme?.header_color || '#fff',
        logo: ad?.advertiser_account_logo
      },
      id: ad.id,
      status: ad.status,
      name: ad.name,
      image_url: ad.image_url,
      isRunning: ad?.is_running,
      campaignStatus,
      canEditCampaign,
      metrics: formatMetrics({ data: metrics })
    }
  })
}
