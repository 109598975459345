import { useCallback, useEffect, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import AlertInfo from '~/components/AlertInfo'
import HeaderPages from '~/components/HeaderPages'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import Table from '~/components/Table'
import { getAccountId, history } from '~/helpers'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { product as service } from '~/modules/retailMedia/services/industry'

import Filters from './Filters'
import catalogTableSchema from './schema'

const accountsRequireQuery = [
  '2d1ef520-e47a-11ed-a037-afba601350a4' // CB ADS
]

const RetailMediaCatalog: React.FC = () => {
  const { t } = useAPPTranslation()
  const [AlertOnError, setError] = useErrorApiAlert({
    title: t('c:messages.errorOccurred.title')
  })

  const queryParams = useQueryParams()
  const page = Number(queryParams.get('page')) || 1
  const quantity = Number(queryParams.get('quantity')) || 50
  const query = queryParams.get('query')

  const queryIsRequired = useMemo(
    () => accountsRequireQuery.includes(getAccountId()) && !query,
    [query]
  )

  const body: SearchProductsReqParams = useMemo(
    () => ({
      page,
      quantity,
      query,
      count: true
    }),
    [page, quantity, query]
  )

  const { status, data, error } = useQuery({
    queryKey: ['get-catalog-rm', `${body.page}-${body.quantity}-${body.query}`],
    enabled: !queryIsRequired,
    queryFn: async () => {
      const res = await service.list(body)

      return res.data
    }
  })

  useEffect(() => {
    if (error) setError(error)
  }, [error, setError])

  /**
   * Gerenciando a paginação
   */
  const handlePagination = useCallback(
    (value: number) => {
      queryParams.set('page', String(value))
      history.push({ search: queryParams.toString() })
    },
    [queryParams]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      queryParams.set('quantity', String(value.value))
      queryParams.set('page', '1')
      history.push({ search: queryParams.toString() })
    },
    [queryParams]
  )

  return (
    <>
      <PageTitle title={t('rm:config.catalog.pageTitle')} />

      <HeaderPages title={t('rm:config.catalog.pageTitle')} />

      <AlertOnError />

      <Filters />

      {queryIsRequired && (
        <AlertInfo
          template="info"
          text={t('rm:config.catalog.list.queryIsRequired')}
        />
      )}

      {!error && data?.data && (
        <section>
          <Table
            schema={catalogTableSchema}
            data={data.data}
            smallerRowPadding
            total={data?.total || 0}
            sideBorder
            striped
            freeze="first-row-and-column"
          />

          {!!data && data?.total === 0 && (
            <AlertInfo
              template="warning"
              text={t('common:table.emptyFiltered')}
            />
          )}

          <footer className="d-flex flex-direction-column align-items-center">
            <ListPagination
              total={data?.total}
              label={data?.total > 1 ? t('rm:products') : t('rm:product')}
              currentPage={page}
              itemsPerPage={quantity}
              menuPlacement="top"
              onClickPagination={handlePagination}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </footer>
        </section>
      )}

      <Loading status={status === 'pending' && !error && !queryIsRequired} />
    </>
  )
}

export default RetailMediaCatalog
