import { formatDateToString, formatDateToHour } from '~/helpers';

const initialText =
  '🍅 No sacolão de ofertas da semana tem muita economia!\nOs melhores ingredientes para fechar o mês com o carrinho cheio! 🛒\n\nAhhh e o atendimento é até a meia noite! 🌒';

const formatInitialData = flyer => {
  const { start_at: startDate, end_at: endDate } = flyer;

  const data = {
    ...flyer,
    start_date: formatDateToString(flyer.start_at, 'YYYY-MM-DD'),
    end_date: formatDateToString(flyer.end_at, 'YYYY-MM-DD'),
    start_hour: formatDateToHour(startDate),
    end_hour: formatDateToHour(endDate),
    messages: [
      {
        // eslint-disable-next-line max-len
        text: flyer?.message,
        media: flyer.media,
      },
    ],
  };

  return data;
};

const defaultInitialData = {
  name: null,
  start_hour: '00:00',
  end_hour: '23:59',
  locationsCheck: 'all',
  location_ids: {
    all: true,
  },
  messages: [
    {
      // eslint-disable-next-line max-len
      text: initialText,
    },
  ],
};

export { formatInitialData, defaultInitialData };
