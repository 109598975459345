import React, { useCallback, useEffect, useRef } from 'react'
import { FiX } from 'react-icons/fi'

import { Form } from '@unform/web'

import Alert from '~/components/Alert'
import { Button } from '~/components/Buttons'
import { FormGroup, InputMask, InputNumber, Label } from '~/components/unform'
import { formatHour } from '~/helpers'
import { useScroll } from '~/hooks/useScroll'

import { useLogisticSlots } from '../../useLogisticSlots'

import * as S from './styles'

const SlotForm = () => {
  const {
    formOpen,
    handleSelectedDays,
    handleChangeTimes,
    startHour,
    endHour,
    createSlot,
    error,
    selectedDays,
    setFormSlotOpen
  } = useLogisticSlots()

  const [executeScroll, scrollRef] = useScroll()

  useEffect(() => {
    if (formOpen) executeScroll()
  }, [executeScroll, formOpen])

  const formRef = useRef(null)

  const handleCloseForm = useCallback(() => {
    setFormSlotOpen(false)
    handleSelectedDays(null)
  }, [handleSelectedDays, setFormSlotOpen])

  const limitOrders = inputObj => {
    const { value } = inputObj
    if (value >= 0 && value <= 999999) return inputObj
  }

  const limitRepeat = inputObj => {
    const { value } = inputObj
    if (value > 0 && value <= 1440) return inputObj
  }

  return (
    <S.Container
      ref={scrollRef}
      formIsOpen={formOpen}
      position="top"
      hideButton
    >
      {formOpen && (
        <Form ref={formRef} onSubmit={createSlot} initialData={{ repeat: 1 }}>
          <header>
            <strong>Criar slot</strong>
            <span>
              {' '}
              - Defina um intervalo(horário de início e fim), quantas vezes ele
              deve se repetir e o limite de pedidos aceitos por intervalo.
            </span>
          </header>

          <fieldset className="fields">
            <FormGroup>
              <Label htmlFor="hour_start">Horário de início</Label>
              <InputMask
                name="hour_start"
                defaultValue={startHour.raw || null}
                format={formatHour}
                mask="_"
                placeholder="HH:MM"
                onChange={handleChangeTimes}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="hour_end">Horário de fim</Label>
              <InputMask
                name="hour_end"
                defaultValue={endHour.raw || null}
                format={formatHour}
                mask="_"
                placeholder="HH:MM"
                onChange={handleChangeTimes}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="repeat">Repetição do intervalo</Label>
              <InputNumber
                name="repeat"
                onChange={handleChangeTimes}
                allowNegative={false}
                decimalScale={0}
                type="tel"
                format="##"
                isAllowed={limitRepeat}
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="repeat">Limite de pedidos no slot</Label>
              <InputNumber
                name="max_orders"
                placeholder="Se não houver, não preencha."
                onChange={handleChangeTimes}
                allowNegative={false}
                decimalScale={0}
                type="tel"
                format="######"
                isAllowed={limitOrders}
              />
            </FormGroup>

            <Button
              text="Criar"
              type="submit"
              template="success"
              disabled={!selectedDays?.length}
            />

            <button
              type="button"
              className="closeButton"
              onClick={handleCloseForm}
            >
              <FiX />
            </button>
          </fieldset>

          {error?.key === 'createSlot' && <Alert>{error.message}</Alert>}
        </Form>
      )}
    </S.Container>
  )
}

export default SlotForm
