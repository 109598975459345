import EditBudgetColumn from './EditBudget'

type CostColumnProps = {
  isListAll: boolean
  campaignAdType?: CampaignAdType
}
type CostColumn = (data: CostColumnProps) => SchemaItems

export const cost: CostColumn = ({ isListAll, campaignAdType }) => {
  if (campaignAdType === 'sponsored_brand') return {}

  const label = {
    product: {
      label: 'CPC',
      helpText:
        'Custo por clique - valor cobrado por cada clique em um anúncio.'
    },
    banner: {
      label: 'CPM',
      helpText: 'Custo por milhar - valor cobrado por cada mil impressões.'
    }
  } as { [key in CampaignAdType]: { label: string; helpText?: string } }

  return {
    cost: {
      title: isListAll ? 'CPC/CPM' : label?.[campaignAdType]?.label || 'Custo',
      helpText: label?.[campaignAdType]?.helpText,
      render: EditBudgetColumn
    }
  }
}
