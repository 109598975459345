import styled from 'styled-components'

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;

  position: sticky;
  bottom: 0.5rem;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: saturate(80%) blur(5px);

  outline: 0.5rem rgba(255, 255, 255, 0.9) solid;

  > button {
    margin-left: 1rem;
  }
`
