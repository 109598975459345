import { useMemo } from 'react'

import { AxiosResponse } from 'axios'

import { useGetFetchQuery } from '~/hooks/useGetFetchQuery'

const LocationName = ({ locationId }: { locationId: string }) => {
  const cachedData = useGetFetchQuery<
    AxiosResponse<ListLocationMinifiedServiceResponse>
  >(['listLocationsMinified'])

  const data = cachedData?.data?.data

  const location = useMemo(
    () => data?.find(location => location.id === locationId),
    [data, locationId]
  )

  return location?.name || locationId?.slice(0, 6) || 'Sem loja definida'
}

export default LocationName
