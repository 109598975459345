import { FiHash } from 'react-icons/fi'

import { Badge } from '~/components/UI/Badge'

const ProductAdditionalReferences: React.FC<{ data: PublisherProduct }> = ({
  data
}) => {
  const dataIsValid = !!data?.additional_references?.filter(Boolean)?.length
  const values = data?.additional_references

  return dataIsValid ? (
    <div className="d-flex flex-wrap gap-1">
      {values?.map(item => (
        <>
          <Badge
            size="small"
            iconLeft={<FiHash />}
            key={`ref_${item}`}
            text={item}
          />{' '}
        </>
      ))}
    </div>
  ) : (
    <></>
  )
}

export default ProductAdditionalReferences
