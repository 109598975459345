import styled, { css } from 'styled-components'

export const WrapperViewAddBanner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > label {
    font-size: 1rem;
  }
`

export const Header = styled.header`
  .alertBrands {
    margin-bottom: 0.5rem;
  }
`

export const WrapperTabler = styled.div`
  margin-top: 1rem;
`

export const EmptyPage = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    background-color: ${theme.colors.neutral.high.lightest};
    border-radius: ${theme.border.radius.large};
    margin: ${theme.spacings.xsmall} 0;
    padding: ${theme.spacings.xlarge};
  `}
`

export const UploadQueue = styled.div`
  display: flex;
  flex-direction: column;
`
