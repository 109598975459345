import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Panel from '../../../components/Panel';
import BarChart from '../../../components/charts/bar-chart';
import { formatPercent } from '../../../helpers';
import { getMarginPerDayInfo } from '../dashboard-actions';

const formatData = value => formatPercent(value, 0);
const MarginPerDayChart = ({
  startDate,
  endDate,
  getMarginPerDayInfo,
  loading,
  marginPerDayInfo,
}) => {
  useEffect(() => {
    getMarginPerDayInfo(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <Panel title="Margem diária" isLoading={loading}>
      <BarChart
        barData={marginPerDayInfo.margin}
        labels={marginPerDayInfo.date}
        title="Margem diária"
        formatData={formatData}
      />
    </Panel>
  );
};

export default connect(
  state => ({
    marginPerDayInfo: state.dashboard.marginPerDayInfo,
    loading: state.dashboard.marginPerDayLoading,
  }),
  {
    getMarginPerDayInfo,
  },
)(MarginPerDayChart);
