import styled from 'styled-components';

export const Container = styled.div`
  .form-group + .form-group {
    margin-top: ${({ theme }) => theme.spacings.medium};
  }

  .form-group {
    &:not(:first-child) {
      padding-top: ${({ theme }) => theme.spacings.xsmall};
      border-top: 1px solid ${({ theme }) => theme.colors.inative};
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      gap: 32px;
      margin-bottom: 32px;
    }

    .file-input-wrapper {
      margin-top: ${({ theme }) => theme.spacings.xxxsmall};
    }
  }
`;
