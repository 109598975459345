import React from 'react';

import PropTypes from 'prop-types';

export const Wrapper = ({ children, maxHeightViewport }) => (
  <div className={`wrapper ${maxHeightViewport ? 'maxHeightViewport' : ''}`}>
    {children}
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  maxHeightViewport: PropTypes.bool.isRequired,
};
