import {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { history, getAccountId } from '~/helpers'
import useConfirmationModal from '~/hooks/useConfirmationModal'
import useQueryParams from '~/hooks/useQuery'
import {
  listUserByAccountService,
  listUserByAccountServiceKey,
  resendInviteUserService
} from '~/modules/users/services/user-service'
import { removeUserService } from '~/services/user-service'

const UseUserListContext = createContext({} as UseUserListContext)

const UserListProvider = ({ children }: React.PropsWithChildren) => {
  const queryParams = useQueryParams()
  const page = Number(queryParams.get('page')) || 1
  const quantity = Number(queryParams.get('quantity')) || 50
  const name = queryParams.get('name')
  const email = queryParams.get('email')
  const role = queryParams.get('role') as UserRoles

  const { t } = useTranslation()

  const params: ListUserByAccountServiceParams = useMemo(
    () => ({
      page,
      quantity,
      role,
      email,
      name
    }),
    [email, name, page, quantity, role]
  )

  const {
    data: { data, total } = {},
    isPending,
    error
  } = useQuery({
    queryKey: [listUserByAccountServiceKey, params],
    queryFn: async () => {
      const { data } = await listUserByAccountService(params)

      return {
        total: data?.total,
        data: data?.data
      }
    }
  })

  /**
   * Gerenciando a paginação
   */
  const handlePagination = useCallback(
    (value: number) => {
      queryParams.set('page', String(value))
      history.push({ search: queryParams.toString() })
    },
    [queryParams]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      queryParams.set('quantity', String(value.value))
      queryParams.set('page', '1')
      history.push({ search: queryParams.toString() })
    },
    [queryParams]
  )

  /**
   * Handle invite
   */
  const [loadingResendInviteId, setLoadingResendInviteId] = useState<
    string[] | null
  >(null)

  const { isPending: loadingResendInvite, mutateAsync: resendInvite } =
    useMutation({
      mutationFn: resendInviteUserService,
      onSuccess: (data, requestData) => {
        toastr.success(
          t('users:inviteResentSuccess'),
          t('users:inviteResentSuccess.message')
        )

        setLoadingResendInviteId(prevState =>
          prevState?.filter(id => id !== requestData.user_id)
        )
      },
      onError: (error, requestData) => {
        toastr.error(
          t('common:messages.errorOccurred.title'),
          t('users:inviteResentSuccess.error.message')
        )

        setLoadingResendInviteId(prevState =>
          prevState?.filter(id => id !== requestData.user_id)
        )
      }
    })

  const handleResendInvite = async data => {
    setLoadingResendInviteId(prevState => [...(prevState || []), data.id])

    await resendInvite({
      user_id: data.id,
      name: data.name,
      email: data.email,
      account_id: getAccountId()
    })
  }

  /**
   * Handle remove
   */
  const [
    ConfirmationModal,
    showConfirmationModal,
    toggleConfirmationModal,
    setIsShowing
  ] = useConfirmationModal()

  const [contentModal, setContentModal] = useState<UserResponse>(null)

  const queryClient = useQueryClient()

  const { isPending: loadingRemoveUser, mutateAsync: removeUser } = useMutation(
    {
      mutationFn: removeUserService,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [listUserByAccountServiceKey, params]
        })

        setIsShowing(false)
        setContentModal(null)

        toastr.success(
          t('users:userRemovedSuccess'),
          t('users:userRemovedSuccess.message')
        )
      },
      onError: () => {
        toastr.error(
          t('common:messages.errorOccurred.title'),
          t('users:removeUser.error.message')
        )
      }
    }
  )

  const submitRemoveUser = async (id: string) => {
    await removeUser(id)
  }

  const handleRemoveUser = async (data: UserResponse) => {
    setContentModal(data)
    setIsShowing(true)
  }

  return (
    <UseUserListContext.Provider
      value={{
        isPending,
        data,
        total,
        page,
        error,
        quantity,
        handlePagination,
        handleItemsPerPageChange,
        loadingResendInvite,
        loadingResendInviteId,
        handleResendInvite,
        handleRemoveUser,
        loadingRemoveUser
      }}
    >
      {children}

      <ConfirmationModal
        title={t('users:removeUser.confirm')}
        isOpen={showConfirmationModal}
        onClose={toggleConfirmationModal}
        onSubmit={() => submitRemoveUser(contentModal?.id)}
        cancelButton={t('common:actions.cancel')}
        confirmationButton={t('common:actions.confirm')}
        // confirmationValue={contentModal?.email}
        submitLoading={loadingRemoveUser}
      >
        {t('users:removeUser.confirmation', {
          name: contentModal?.name,
          email: contentModal?.email
        })}
      </ConfirmationModal>
    </UseUserListContext.Provider>
  )
}

function useUserList(): UseUserListContext {
  const context = useContext(UseUserListContext)

  if (!context) {
    throw new Error('useUserList must be used within an UserListProvider')
  }

  return context
}

export { UseUserListContext, UserListProvider, useUserList }
