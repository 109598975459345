import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { formatSearchString } from '~/helpers'
import { getUser } from '~/modules/users/store/actions'

const UserEdit = () => {
  const { id: userId } = useParams()

  const dispatch = useDispatch()

  const queryString = useMemo(
    () =>
      formatSearchString({
        id: userId
      }),
    [userId]
  )

  useEffect(() => {
    dispatch(getUser({ queryString }))
  }, [dispatch, queryString])

  return (
    <>
      {/* Meta <title> */}
      <PageTitle title="Usuários > Editar" />

      <HeaderPages title="Usuários > Editar" />
    </>
  )
}

export default UserEdit
