import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import LoadingPopUp from '~/components/LoadingPopUp'
import PageTitle from '~/components/PageTitle'
import { formatSearchString } from '~/helpers'
import useFetchSWR from '~/hooks/useFetchSWR'
import { HeaderWalletBalance } from '~/modules/retailMedia/components/HeaderWalletBalance/HeaderWalletBalance'
import { WalletTableTransaction } from '~/modules/retailMedia/components/WalletTableTransaction'
import HeaderWallet from '~/modules/retailMedia/components/WalltetHeader'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'
import { useAppSelector } from '~/store/hooks'

const PublisherTransactions = () => {
  const [shouldUpdate, setShouldUpdate] = useState(false)

  const { id: walletId } = useParams()

  const formattedURL = useMemo(() => {
    const queryParams = formatSearchString({
      account_info: true
    })

    return `${reatailmediaApi}/checking-account/${walletId}?${queryParams}`
  }, [walletId])

  const {
    data: dataSWR,
    error,
    isValidating,
    mutate
  } = useFetchSWR<GetCheckingAccountWithAccountInfoResponse>({
    url: formattedURL,
    refreshInterval: 60000,
    revalidateOnFocus: false
  })

  const data = useMemo<DTOCheckingAccountResponse>(
    () => ({
      ...dataSWR,
      valueAsNumber: {
        balance: Number(dataSWR?.balance || 0),
        creditTotal: Number(dataSWR?.credit_total || 0),
        debitTotal: Number(dataSWR?.debit_total || 0)
      }
    }),
    [dataSWR]
  )

  const initialLoading = useMemo(
    () => !dataSWR && isValidating,
    [dataSWR, isValidating]
  )

  // Checking after new transaction
  const { loading: loadingCreateTransaction, data: responseCreateTransaction } =
    useAppSelector(state => state.retailMedia.grocery.wallet.createTransaction)

  const handleMutate = useCallback(
    async ({ balance }: HandleMutateCheckoingAccount) => {
      const mutateData = {
        ...dataSWR,
        balance
      } as GetCheckingAccountWithAccountInfoResponse
      await mutate(mutateData)
    },
    [dataSWR, mutate]
  )

  /**
   * Nesse useEffect vamos tratar se devemos atualizar o valor após
   * uma transação criada. No primeiro IF vamos ver se a requisição
   * no modo pending, ou seja, com loading. Se sim, sinalizamos que
   * precisará atualizar em breve. Já o segundo IF pegará o momento fulfilled
   * da action. Junto com o shoudUpdate local ele fará um mutate no dado
   * existente no SWR. Recebemos o valor atualizado do componente de criação
   * de transação pelo Redux, nas actions, via values.
   */
  useEffect(() => {
    if (loadingCreateTransaction) {
      setShouldUpdate(true)
    }

    const isReadyToMutate =
      !loadingCreateTransaction &&
      shouldUpdate &&
      responseCreateTransaction?.balanceAfter

    if (isReadyToMutate) {
      handleMutate({ balance: responseCreateTransaction?.balanceAfter })
      setShouldUpdate(false)
    }
  }, [
    loadingCreateTransaction,
    mutate,
    shouldUpdate,
    formattedURL,
    handleMutate,
    responseCreateTransaction
  ])

  return (
    <>
      <PageTitle
        title={
          initialLoading
            ? 'Carregando...'
            : `Retal Media - ${data?.advertiser_name}`
        }
      />

      <HeaderWallet />

      {/* <Loading status={!data && isValidating}>Carregando carteira...</Loading> */}

      <HeaderWalletBalance
        initialLoading={initialLoading}
        balance={data.valueAsNumber.balance}
        credit={data.valueAsNumber.creditTotal}
        debit={data.valueAsNumber.debitTotal}
      />

      <WalletTableTransaction />

      <LoadingPopUp isActive={isValidating} />
    </>
  )
}

export default PublisherTransactions
