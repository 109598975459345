import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 0 0 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid ${theme.colors.inative};

    aside {
      display: flex;

      > * + * {
        margin-left: 1rem;
      }
    }
  `}
`

export const BulkSection = styled.section`
  ${({ theme }) => css`
    textarea {
      height: 40vh;
    }
  `}
`

/**
 * List
 */

export const ProductsLists = styled.div`
  ${({ theme }) => css`
    @media ${device.laptop} {
      display: grid;

      grid-template-columns: repeat(2, minmax(320px, 1fr));

      margin-top: ${theme.spacings.xsmall};

      min-height: calc(50vh - 20px);
      max-height: calc(90vh - 20px);
    }

    @media ${device.laptop_max} {
      display: flex;
      flex-direction: column;
    }
  `}
`

export const ProductsList = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    z-index: 5;
    position: relative;
    overflow-y: auto;

    max-height: calc(90vh - 20px);
    min-height: calc(50vh - 20px);

    .empty {
      background: ${theme.colors.light};
      border-radius: 1rem;
      width: 100%;
      min-height: 40vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /* Space to scrollbar */

    &:first-child {
      @media ${device.laptop} {
        padding-right: 1rem;
      }

      @media ${device.laptop_max} {
        max-height: 75vh;
      }
    }

    &:last-child {
      @media ${device.laptop_max} {
        max-height: unset;
        min-height: unset;
      }

      @media ${device.laptop} {
        padding-right: 0.5rem;
        padding-left: 1rem;
        border-left: 1px solid ${theme.colors.tertiary};
      }
    }

    /* width */
    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.colors.inative};
      border-radius: 0.5rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.tertiary};
      transition: 0.3s ease-in-out all;
      border-radius: 0.5rem;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.gray};
    }

    > div + div {
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`

export const ProductsListHeader = styled.header`
  ${({ theme }) => css`
    z-index: 10;
    position: sticky;
    top: 0;
    padding: ${theme.spacings.xsmall} 0;
    background: ${theme.colors.white};

    .alertWrapper {
      margin: 0;
      margin-top: ${theme.spacings.xsmall};
    }
  `}
`

export const Heading = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Nav = styled.nav`
  margin-bottom: 0.75rem;

  > * + * {
    margin-left: 0.75rem;
  }
`

export const Title = styled.h3`
  height: 2rem;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.75rem;
`
