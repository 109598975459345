/* eslint-disable react/display-name */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { AddressInline } from '~/components/Address';
import { formattedDistance } from '~/helpers';

export const schema = {
  name: {
    render: location => (
      <div>
        <div className="item__id">
          <Link
            to={`/locations/edit/${location.id}`}
            alt="Ver detalhes do local"
          >
            {location.name || 'Sem nome'}
          </Link>
        </div>
        {location.external_id && (
          <div className="item__name">
            <strong>ID:</strong> {location.external_id}
          </div>
        )}
      </div>
    ),
    title: 'Nome do local',
    className: 'title order__details col__infos',
  },
  type: {
    render: obj => (
      <FormattedMessage id={obj.type}>{message => message}</FormattedMessage>
    ),
    title: 'Tipo do local',
    className: 'type nowrap',
  },
  address: {
    render: location =>
      !!(location.type !== 'customer_service_center') && (
        <AddressInline location={location} />
      ),
    title: 'Endereço',
    className: 'address',
  },
  distance: {
    render: obj => formattedDistance({ value: obj.distance }),
    title: 'Distância',
  },
};
