import React, { useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import Tag from '~/components/Tag'
import useFetchSWR from '~/hooks/useFetchSWR'

import CreateCustomer from '../CreateCustomer'
import { CustomerTag } from '../HeaderChat/styles'
import OrderPanel from '../OrderPanel'
import OrdersRecent from '../OrdersRecent'
import SenderInfos from '../SenderInfos'

import * as S from './styles'

const UserPanel = () => {
  const [loadingOrders, setLoadingOrders] = useState(true)

  const { customer } = useSelector(({ customers }) => customers)

  const hasCustomer = useMemo(() => customer?.id, [customer?.id])

  const queryString = useMemo(
    () => `customer_id=${customer?.id}&quantity=5`,
    [customer?.id]
  )

  const { data: dataSWR, error: errorSWR } = useFetchSWR({
    url: `${process.env.REACT_APP_HOST}/search/orders?${queryString}`,
    shouldFetch: !!hasCustomer
  })

  useEffect(() => setLoadingOrders(!(dataSWR || errorSWR)), [dataSWR, errorSWR])

  return (
    <S.Container>
      <div className="wrap">
        <SenderInfos />

        {!hasCustomer && <CreateCustomer />}

        {customer && !customer.use_bot && (
          <div className="customer-tags-container">
            <Tag>Bot desativado permanentemente</Tag>
          </div>
        )}

        {!!customer?.metadata?.tags?.length && (
          <div className="customer-tags-container">
            <header>
              <strong>Tags do cliente</strong>
            </header>
            <div className="customer-tags">
              {customer.metadata?.tags.map((tag, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <CustomerTag key={index}>{tag}</CustomerTag>
              ))}
            </div>
          </div>
        )}
        {hasCustomer && (
          <OrdersRecent
            loadingOrders={loadingOrders}
            data={dataSWR?.data}
            total={dataSWR?.total}
            error={errorSWR}
            socialId={hasCustomer}
          />
        )}
      </div>

      {hasCustomer && dataSWR?.total > 0 && (
        <div className="orderPanel">
          <OrderPanel />
        </div>
      )}
    </S.Container>
  )
}

export default UserPanel
