import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { history } from '~/helpers'
import { getChatProvider } from '~/modules/chat/store/chatConnect/actions'
import campaignActions from '~/modules/messagingCampaigns/store/campaign/actions'

const useMsgCampaigns = () => {
  const dispatch = useDispatch()

  const { provider, isWhatsAppWeb } = useSelector(
    ({ chatConnect }) => chatConnect
  )

  useEffect(() => {
    if (!provider) {
      dispatch(getChatProvider())
    }
  }, [dispatch, provider])

  /**
   * Memos
   */

  const isProviderOfficialPartner = useMemo(
    () => provider && !isWhatsAppWeb,
    [isWhatsAppWeb, provider]
  )
  const allowMediaOnTemplate = useMemo(
    () => isProviderOfficialPartner,
    [isProviderOfficialPartner]
  )

  const onlyApprovedTemplates = useMemo(
    () => isProviderOfficialPartner,
    [isProviderOfficialPartner]
  )

  /**
   * Handles
   */

  const handleDelete = ({ id }) => {
    dispatch(
      campaignActions.remove({
        id,
        onSuccess: history.push('/messaging-campaigns/list')
      })
    )
  }

  return {
    // Memos
    allowMediaOnTemplate,
    onlyApprovedTemplates,
    // Handles
    handleDelete
  }
}

export default useMsgCampaigns
