import React from 'react';

import PropTypes from 'prop-types';

const Image = ({ data }) => {
  return (
    <div className="image">
      <a
        href={data}
        target="_blank"
        rel="noreferrer"
        title="Clique para exibir numa nova aba"
      >
        <img src={data} alt="Imagem" />
      </a>
    </div>
  );
};

export default Image;

Image.propTypes = {
  data: PropTypes.string.isRequired,
};
