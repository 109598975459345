export const chatSettings: ChatSettings = {
  sortChatsBy: {
    desc: {
      value: 'desc',
      label: 'Mais recentes'
    },
    asc: {
      value: 'asc',
      label: 'Mais antigas'
    }
  }
}
