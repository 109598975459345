import { primary } from '~/styles/themes'

import * as types from './theme-constants'

const themes = {
  primary,
  classic: {
    primary: '#3C3584',
    secondary: '#FFB450',
    tertiary: '#A6A6A6',
    success: '#4BBF73',
    info: '#1F9BCF',
    warning: '#E08300',
    danger: '#e51c23'
  },
  corporate: {
    primary: '#3C3584',
    secondary: '#FFB450',
    tertiary: '#A6A6A6',
    success: '#4BBF73',
    info: '#1F9BCF',
    warning: '#E08300',
    danger: '#e51c23'
  },
  modern: {
    primary: '#3C3584',
    secondary: '#FFB450',
    tertiary: '#A6A6A6',
    success: '#4BBF73',
    info: '#1F9BCF',
    warning: '#E08300',
    danger: '#e51c23'
  }
}

// --primary: #3C3584;
// --secondary: #FFB450;
// --success: #4BBF73;
// --info: #1F9BCF;
// --warning: #E08300;
// --danger: #e51c23;
// --light: #F8F9FA;
// --dark: #354052;
// --tertiary: #A6A6A6;

const initialState = {
  currentTheme: themes.primary,
  themes
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.THEME_TOGGLE:
      return {
        ...state,
        currentTheme: state.themes[actions.payload]
      }

    default:
      return state
  }
}
