import styled from 'styled-components'

export const Container = styled.section`
  padding: 1rem;
  width: 100%;

  > header {
    text-align: center;
    line-height: 1.25;
    margin-bottom: 1rem;
    position: relative;

    .name {
      font-weight: 700;
    }

    .since__date {
      color: ${({ theme }) => theme.colors.gray};
      font-size: ${({ theme }) => theme.font.sizes.small};
      font-weight: 400;
    }

    .icon__close {
      position: absolute;
      top: 0;
      right: 0;
      outline: none;
      background-color: transparent;
      padding: 0;
      border: 0;
    }
  }

  section {
    > div {
      display: flex;
      align-items: center;

      &.buttonLocations {
        justify-content: center;
      }

      .icon {
        color: ${({ theme }) => theme.colors.primary};
        width: 1.25rem;
        font-size: 1.25rem;
        margin-right: 1rem;
      }
    }
  }
`
