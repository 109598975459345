import styled from 'styled-components'

export const WrapperCard = styled.div`
  .hold-cards {
    @media only screen and (min-width: 1199.9px) and (max-width: 1517px) {
      display: flex;
      grid-gap: 0.5rem;
      > div {
        margin: 0;
      }
      margin-bottom: 20px;
    }
  }
`
