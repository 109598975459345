const prefix = `@SHOPPING_CART`;

export const UPDATE = `${prefix}/UPDATE`;
export const UPDATE_STATUS = `${prefix}/UPDATE_STATUS`;
export const DELETE = `${prefix}/DELETE`;
export const SEND_LINK = `${prefix}/SEND_LINK`;
export const SHOW_ONLY_PENDING = `${prefix}/SHOW_ONLY_PENDING`;
export const VIEW = `${prefix}/VIEW`;
export const SEARCH = `${prefix}/SEARCH`;
export const SHOULD_REVALIDATE = `${prefix}/SHOULD_REVALIDATE`;
