import { cases as createCases } from './create';
import { cases as deleteCases } from './delete';
import { cases as listCases } from './list';
import { cases as updateCases } from './update';

export default {
  ...deleteCases,
  ...updateCases,
  ...listCases,
  ...createCases,
};
