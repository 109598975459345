import styled, { css } from 'styled-components'

type ContainerProps = {
  hasSidebar: boolean
}

const containerModifiers = {
  hasSidebar: () => css`
    > :first-child {
      width: 40%;
      max-width: 360px;
    }

    .rechargeInfos {
      flex-direction: column;
    }
  `
}

export const Container = styled.section<ContainerProps>`
  ${({ theme, hasSidebar = false }) => css`
    display: flex;
    gap: ${theme.spacings.medium};

    ${hasSidebar && containerModifiers.hasSidebar()}
  `}
`
export const ResumeContainer = styled.section`
  ${({ theme }) => css`
    display: flex;
    gap: 1rem;

    > div {
      flex: 1 1 33%;

      p {
        margin-bottom: 0;
      }
    }

    .title {
      font-weight: ${theme.font.bold};
      font-size: ${theme.font.sizes.medium};
    }
  `}
`
export const PixContainer = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex: 1 0 65%;
  `}
`

export const PaymentMethodWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.large};

    display: flex;
    flex-direction: row;
    vertical-align: middle;

    background-color: ${theme.colors.light};
    border-radius: 0.3rem;
    border: 1px solid ${theme.colors.gray};
    padding: ${theme.spacings.medium};
  `}
`
export const PaymentInfoWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-left: ${theme.spacings.medium};

    label {
      margin: 0;
    }
  `}
`
export const QrCodeWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${theme.spacings.medium};

    width: 60%;
  `}
`
export const QrCodeWrapperInside = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${theme.spacings.medium};
    padding: ${theme.spacings.medium};
    width: 100% !important;

    background-color: ${theme.colors.light};
    border-radius: 0.3rem;
    border: 1px solid ${theme.colors.outlineGray};

    width: 60%;

    p {
      margin: 0;
    }

    div {
      width: 100%;
    }
  `}
`
export const PixInstructionsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0 ${theme.spacings.xxxlarge};

    row-gap: 1rem;

    dl {
      margin-bottom: 0;

      + dl {
        padding: 1rem 0 0;
        border-top: 1px solid ${theme.colors.inative};
      }

      dd + dd {
        margin-top: ${theme.spacings.xxxsmall};
        margin-bottom: 0;
      }
    }
  `}
`
export const TitleInstructionsWrapper = styled.dt`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xxxsmall};

    h4 {
      display: flex;
      align-items: center;

      .icon {
        display: inline-flex;

        svg {
          font-size: ${theme.font.sizes.medium};
          margin-right: ${theme.spacings.tiny};
        }
      }

      strong {
        font-size: ${theme.font.sizes.medium};
      }
    }
  `}
`
export const CountDownWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: ${theme.colors.white};
    border-radius: 0.3rem;
    border: 1px solid ${theme.colors.outlineGray};
    padding: ${theme.spacings.xsmall} ${theme.spacings.medium};

    label {
      margin: 0;
    }

    svg {
      font-size: ${theme.font.sizes.xxlarge};
    }
  `}
`
