export const formatDeliveryData = data => {
  if (data.carrier_id) {
    return formatNewtailDeliveryData(data)
  }

  return {
    shipmentData: {
      status: data.status || 'open',
      type: data?.delivery_id?.startsWith('ret_') ? 'return' : 'delivery',
      provider: data.delivery_provider,
      delivery_id: data.delivery_id,
      uber_delivery_id: data.metadata?.external_id,
      user_name: data?.metadata?.courier?.name || 'Sem nome definido',
      user_picture: data?.metadata?.courier?.img_href,
      vehicle_type: data?.metadata?.courier?.vehicle_type,
      vehicle: {
        vehicle_color: data?.metadata?.courier?.vehicle_color,
        vehicle_make: data?.metadata?.courier?.vehicle_make,
        vehicle_model: data?.metadata?.courier?.vehicle_model
      },
      phone_number: data?.metadata?.courier?.phone_number,
      courier_imminent: data?.metadata?.courier?.imminent,
      tracking_url: data.metadata?.tracking_url,
      shipment_raw: data
    }
  }
}

export const formatNewtailDeliveryData = data => {
  const {
    carrier_name,
    carrier_email: user_email,
    start_delivery_at,
    finish_delivery_at,
    tracks
  } = data

  const user_name = carrier_name || 'Sem nome definido'

  const tracking = tracks ? JSON.parse(tracks) : null

  const status = () => {
    if (start_delivery_at && !finish_delivery_at) {
      return 'dropoff'
    }

    if (finish_delivery_at) {
      return 'delivered'
    }

    return null
  }

  return {
    shipmentData: {
      status: status(),
      type: 'delivery',
      provider: 'newtail',
      delivery_id: data.delivery_id,
      carrier_id: data.carrier_id,
      user_name,
      user_email,
      acceptDeliveryAt: tracking?.accept_delivery_at,
      acceptDeliveryBy: tracking?.full_name,
      shipment_raw: data,
      items: data.items
    }
  }
}
