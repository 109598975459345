import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import {
  toggleSelectChatsMode,
  toggleEditLocationsMode,
  setChatMenuOpen,
  updateChat
} from '~/modules/chat/store/actions'
import { CHAT_REDUX_QUERY } from '~/modules/chat/store/constants'
import { setQuery } from '~/store/modules/filters/actions'

import { Menu } from './styles'

/**
 * Componente que renderiza o menu auxiliar de cada item(conversa) da lista de conversas.
 * Ele recebe as coordenadas do elemento que o ativou para ser posicionado corretamente.
 *
 * @returns
 */

const ChatMenu = () => {
  const [menuOpen, setMenuOpen] = useState(null)
  const [menuIsBelow, setMenuIsBelow] = useState(true)
  const [positionParent, setPositionParent] = useState({ top: 0, left: 0 })

  const itemRef = useRef(null)

  const dispatch = useDispatch()

  const { chatMenuOpen, chats } = useSelector(({ chats }) => chats)

  const ChatIdMenuOpen = useMemo(
    () => chatMenuOpen?.chatId || null,
    [chatMenuOpen]
  )

  const sessiontIdMenuOpen = useMemo(
    () => chatMenuOpen?.sessionId || null,
    [chatMenuOpen]
  )

  // Armazena informações do chat que o menu foi aberto
  const chatSelected = useMemo(
    () => chats?.find(chat => chat.chatId === ChatIdMenuOpen),
    [chats, ChatIdMenuOpen]
  )

  const positionChat = useMemo(
    () => chatMenuOpen?.positionParent || { top: 0, left: 0 },
    [chatMenuOpen]
  )

  const calcRealPosition = useMemo(
    () => ({
      top: positionChat.top - positionParent.top,
      left: positionChat.left - positionParent.left
    }),
    [
      positionChat.left,
      positionChat.top,
      positionParent.left,
      positionParent.top
    ]
  )

  const customerNumberChatMenuOpen = useMemo(
    () => chatMenuOpen?.customerNumber || null,
    [chatMenuOpen?.customerNumber]
  )

  useEffect(() => {
    const alturaLista = itemRef?.current?.parentElement?.clientHeight

    setPositionParent({
      top: itemRef?.current?.parentElement.getBoundingClientRect().top,
      left: itemRef?.current?.parentElement.getBoundingClientRect().left
    })

    const posYElemento =
      chatMenuOpen?.positionParent?.top -
      itemRef?.current.parentElement.getBoundingClientRect().top

    setMenuIsBelow(alturaLista * 0.75 < posYElemento)

    setMenuOpen(chatMenuOpen?.chatId)
  }, [itemRef, chatMenuOpen])

  useEffect(() => {
    if (menuOpen) {
      itemRef?.current?.focus()
    }
  }, [itemRef, chatMenuOpen, menuOpen])

  const handleTag = useCallback(
    e => {
      e.preventDefault()

      dispatch(
        toggleSelectChatsMode({
          initialChat: {
            chatId: ChatIdMenuOpen,
            sessionId: sessiontIdMenuOpen
          }
        })
      )
    },
    [dispatch, ChatIdMenuOpen, sessiontIdMenuOpen]
  )

  const handleLocation = useCallback(
    e => {
      e.preventDefault()

      dispatch(
        setQuery({
          query: { [`${CHAT_REDUX_QUERY}`]: customerNumberChatMenuOpen },
          keyState: CHAT_REDUX_QUERY
        })
      )

      dispatch(toggleEditLocationsMode({ chatId: ChatIdMenuOpen }))
    },
    [customerNumberChatMenuOpen, dispatch, ChatIdMenuOpen]
  )

  const onSuccessAction = useCallback(
    () =>
      dispatch(
        setQuery({
          query: { [`${CHAT_REDUX_QUERY}`]: null },
          keyState: CHAT_REDUX_QUERY
        })
      ),
    [dispatch]
  )

  const handleSetUnread = useCallback(
    e => {
      e.preventDefault()

      const updatedChat = {
        ...chatSelected,
        unreadMessage: true
      }

      const actionData = {
        data: updatedChat,
        successFeedbackMsg: 'Conversa definida como não lida.',
        onSuccess: onSuccessAction
      }

      dispatch(updateChat(actionData))
    },
    [dispatch, chatSelected, onSuccessAction]
  )

  const handleBlur = useCallback(
    e => {
      e.preventDefault()

      if (
        !e.currentTarget.contains(e.relatedTarget) &&
        e.currentTarget === e.target
      ) {
        dispatch(
          setChatMenuOpen({
            chatId: ChatIdMenuOpen,
            positionParent: chatMenuOpen?.positionParent
          })
        )
      }
    },
    [dispatch, ChatIdMenuOpen, chatMenuOpen?.positionParent]
  )

  return (
    <Menu
      isOpen={!!menuOpen}
      isUp={menuIsBelow}
      positionParent={calcRealPosition}
      ref={itemRef}
      onBlur={handleBlur}
      tabIndex="0"
    >
      <Button template="primary" onClick={handleLocation}>
        Editar local
      </Button>
      <Button template="primary" onClick={handleTag}>
        Editar tag
      </Button>
      <Button template="primary" onClick={handleSetUnread}>
        Marcar como não lida
      </Button>
    </Menu>
  )
}

export default ChatMenu
