import axios from 'axios';

import { messagingCampaign as baseAPI } from './api';

const baseURL = `${baseAPI}/template`;

export const list = queries => {
  return axios.get(`${baseURL}`, { params: { ...queries } });
};

export const get = id => {
  return axios.get(`${baseURL}/${id}`);
};

export const create = body => {
  return axios.post(`${baseURL}`, body);
};

export const update = ({ id, body }) => {
  return axios.put(`${baseURL}/${id}`, body);
};

export const remove = id => {
  return axios.delete(`${baseURL}/${id}`);
};

export default {
  list,
  get,
  create,
  update,
  remove,
};
