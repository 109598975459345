// import { Container } from './styles';

import Status from '~/components/Status'

import * as S from './styles'

type TitleWithStatusProps = {
  title: string
  linkTo: string
  statusColor: StatusTemplates
}

const TitleWithStatus: React.FC<TitleWithStatusProps> = ({
  title,
  linkTo,
  statusColor
}) => {
  return (
    <S.WrapperLink to={linkTo}>
      <S.Container>
        <Status
          onlyBullet
          status={{
            type: statusColor || 'default'
          }}
        />

        <span className="title">{title}</span>
      </S.Container>
    </S.WrapperLink>
  )
}

export default TitleWithStatus
