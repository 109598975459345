import { useCallback } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { FiMenu } from 'react-icons/fi'

import { Scope } from '@unform/core'

import { Button } from '~/components/Buttons'

import { Checkbox } from '../Checkbox'

import * as S from './styles'

export type SortedCheckboxOption = {
  order: number
  id: string
  label: string
  description?: string
}

type SortedCheckboxProps = {
  name: string
  options: SortedCheckboxOption[]
  onChange?: () => any
}

const SortedCheckbox = ({ name, options, onChange }: SortedCheckboxProps) => {
  const handleChange = useCallback(
    ({
      e: {
        target: { name, value, checked }
      },
      order
    }) => {
      onChange?.({ name, value, checked, order })
    },
    [onChange]
  )

  return (
    <S.Container>
      <Scope path={name}>
        {options.map((item: SortedCheckboxOption) => (
          <S.Card key={item.id}>
            <div className="drag">
              <FiMenu />
            </div>

            <div className="checkbox">
              <Checkbox
                returnValue
                name={item.id}
                value={item.label}
                onChange={e => handleChange({ e, order: item.order })}
              />
            </div>

            <div className="content">
              <div className="button">
                <Button text={item.label} template="whatsapp" />
              </div>

              <div className="description">
                <span className="icon">
                  <AiFillInfoCircle />{' '}
                </span>
                <span className="text">{item.description}</span>
              </div>
            </div>
          </S.Card>
        ))}
      </Scope>
    </S.Container>
  )
}

export default SortedCheckbox
