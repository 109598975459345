import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import SelectSearchable from '~/components/SelectSearchable';
import { brazilianStates } from '~/helpers';
import useFetchSWR from '~/hooks/useFetchSWR';
import { setFilteredStates } from '~/store/modules/filters/actions';

/**
 * Componente para filtrar a listagens por estados.
 * Ele possibilita a seleção de estados e mantém no estado global: filters.states.
 * @param { boolean } ignoreRedux pode ser usado sem armazenar no estado global e apenas retornar o valor selecionado.
 *
 * Pode receber um valor inicial no initialState e retorna um select procurável.
 *
 * @example <FilterStates initialState="state" />
 */

const FilterStates = ({
  initialState,
  defaultValue,
  onChange,
  placeholder,
  hideAll = false,
  labelAll = 'Todos os estados',
  ignoreRedux = false,
  filterActiveLocations = false,
}) => {
  const [states, setStates] = useState();
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const { states: filteredStates } = useSelector(state => state.filters);

  const { data, error } = useFetchSWR({
    url: `/api/location/minified`,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (data) {
      const { data: locationsAPI } = data;

      const activeStates = locationsAPI
        .filter(({ active }) => (filterActiveLocations ? active : true))
        .reduce((acc, location) => [...acc, location.address_state], []);

      const uniqueStates = [...new Set(activeStates)];

      const sortedStates = uniqueStates.sort();

      const handleStates = sortedStates.map(item => ({
        label: brazilianStates[`${item}`],
        value: item,
      }));

      if (hideAll) {
        setStates([...handleStates]);
      }

      if (!hideAll) {
        setStates([{ label: labelAll, value: null }, ...handleStates]);
      }

      setLoading(false);
    }

    if (error) {
      const formattedStates = [
        {
          value: null,
          label: 'Erro ao carregar estados...',
        },
      ];

      setStates(formattedStates);
      setLoading(false);
    }
  }, [data, error, filterActiveLocations, hideAll, labelAll]);

  useEffect(() => {
    if (!ignoreRedux && initialState && data) {
      const { address_state = null } = data.data.find(
        location => location.address_state === initialState,
      );

      if (address_state) {
        const filteredStates = {
          value: brazilianStates[`${address_state}`],
          label: address_state,
        };

        dispatch(
          setFilteredStates({
            filteredStates,
          }),
        );
      }
    }
  }, [dispatch, initialState, data, ignoreRedux]);

  const handleChangeStates = selectedStates => {
    if (onChange) {
      return onChange(selectedStates);
    }

    dispatch(setFilteredStates({ filteredStates: selectedStates }));
  };

  const handleDefaultValue = useMemo(
    () => (ignoreRedux ? defaultValue : filteredStates),
    [defaultValue, filteredStates, ignoreRedux],
  );

  return (
    <SelectSearchable
      placeholder={placeholder || 'Filtrar por estados'}
      options={states}
      loading={loading}
      onChange={handleChangeStates}
      defaultValue={[handleDefaultValue]}
      className="selectWrapper"
    />
  );
};

export default FilterStates;

FilterStates.propTypes = {
  hideAll: PropTypes.bool,
  labelAll: PropTypes.string,
  ignoreRedux: PropTypes.bool,
  initialState: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  filterActiveLocations: PropTypes.bool,
};

FilterStates.defaultProps = {
  hideAll: false,
  labelAll: 'Todos os locais',
  ignoreRedux: false,
  initialState: null,
  onChange: null,
  placeholder: null,
  defaultValue: null,
  filterActiveLocations: false,
};
