import React, { useEffect, useState } from 'react';
import { IoMdAlert } from 'react-icons/io';

import InputMoney from '~/components/unform/Input/InputMoney';
import { formatMoney } from '~/helpers';

import { Price, Container } from './styles';

const UpdatedPrice = ({ data, formRef }) => {
  const { updated_price } = data;

  const [price, setPrice] = useState(updated_price);

  useEffect(() => {
    formRef?.current?.setFieldValue('updatedPrice', updated_price);
  }, [formRef, updated_price]);

  // Revisar toda a lógica de exibir o valor que falta.
  const handleChangeInput = e => {
    const inputValue = formRef?.current?.getFieldValue('updatedPrice');

    // console.log(formRef, formRef?.current, e.target, inputValue, updated_price);

    setPrice(inputValue.numAsString);
  };

  const missingAmount = updated_price - price / 100;

  return (
    <Container>
      <InputMoney
        name="updatedPrice"
        placeholder="R$ 0,00"
        onChange={handleChangeInput}
        iconRight={
          missingAmount > 0 ? (
            <IoMdAlert color="#e08300" />
          ) : (
            <IoMdAlert color="#4BBF73" />
          )
        }
      />
      <Price>Preço sugerido: {formatMoney(updated_price)}</Price>

      {missingAmount > 0 && (
        <p className="missing-amount">Faltam: {formatMoney(missingAmount)}</p>
      )}
    </Container>
  );
};

export default UpdatedPrice;
