import axios from 'axios';

import { promotion as baseAPI } from './api';

const baseURL = baseAPI;

/**
 * VIEW
 */
export const get = id => {
  return axios.get(`${baseURL}/${id}/metrics`);
};

export default {
  get,
};
