import styled from 'styled-components';

export const Container = styled.div`
  .product-column {
    width: 30%;
  }

  .small-column {
    width: 10%;

    .inputContainer {
      width: 100px;
    }
  }

  .updated-price-input {
    width: 20%;
  }
`;
