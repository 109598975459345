import { PropsWithChildren } from 'react'

import * as S from './styles'

export const AlertNew = ({
  color = 'warning',
  children
}: PropsWithChildren<AlertNewProps>) => {
  return (
    <S.Container data-template={color}>
      <div className="children">{children}</div>
    </S.Container>
  )
}

export default AlertNew
