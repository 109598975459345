import { getIsNetwork } from '~/helpers'
import { getAdvertiserService } from '../../services/advertiser'
import { getPublisherService } from '../../services/publisher'
import { types } from './constants'

/**
 * Get account config and info
 */
export const getAccountInfoAction: GetAccountInfosAction = ({
  id,
  params = {},
  accountType,
  ...restReduxMiddlewareActions
}) => {
  const services = {
    grocery: getAdvertiserService,
    publisher: getAdvertiserService,
    industry: getPublisherService,
    advertiser: getPublisherService
  }

  const service = services[accountType]

  const body = { url: { id }, params }

  return {
    type: types.get,
    payload: service(body),
    ...restReduxMiddlewareActions
  }
}
