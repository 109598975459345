import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Panel from '~/components/Panel'
import { FormGroup, Input, InputMask, Select, Label } from '~/components/unform'
import { indexLocationMinified } from '~/pages/locations/actions'

const GeneralFields = () => {
  const dispatch = useDispatch()

  const {
    listLocationsMinified: { data: dataMinified },
    listLocationsMinifiedLoading
  } = useSelector(state => state.locations)

  useEffect(() => {
    if (!dataMinified) {
      dispatch(indexLocationMinified())
    }
  }, [dataMinified, dispatch])

  const options = useMemo(
    () =>
      dataMinified?.map(location => ({
        id: location.id,
        text: location.name,
        value: location.id
      })),
    [dataMinified]
  )

  return (
    <fieldset>
      <Panel className="form-grid">
        <FormGroup>
          <Label htmlFor="name">Nome do operador</Label>
          <Input
            id="name"
            name="name"
            placeholder="Insira o nome"
            autoFocus
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="pin">PIN (4 números)</Label>

          <InputMask
            id="pin"
            name="pin"
            required
            format="####"
            mask="_ "
            placeholder="Insira um PIN de acesso"
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="location">Local</Label>
          <Select
            id="location_id"
            name="location_id"
            placeholder={
              listLocationsMinifiedLoading
                ? 'Carregando locais...'
                : 'Escolha um local'
            }
            disabled={listLocationsMinifiedLoading}
            type="select"
            options={options}
            required
          />
        </FormGroup>
      </Panel>
    </fieldset>
  )
}

export default GeneralFields
