export const pathOptions = {
  polygon: {
    default: {
      stroke: true,
      color: '#383386',
      weight: 2,
      opacity: 1,
      fill: true,
      fillColor: '#383386',
      fillOpacity: 0.3,
      clickable: true
    },
    forbidden: {
      stroke: true,
      color: '#DC0A15',
      weight: 2,
      opacity: 1,
      fill: true,
      fillColor: '#DC0A15',
      fillOpacity: 0.3,
      clickable: true,
      dashArray: '5,10',
      lineCap: 'square'
    },
    showEdit: {
      stroke: true,
      color: '#7B7B7B',
      weight: 2,
      opacity: 5,
      fill: true,
      fillColor: '#7B7B7B',
      fillOpacity: 0.2,
      clickable: false
    }
  }
}
