import { useMemo } from 'react'
import { FiChevronRight, FiGlobe } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { formatDateToHour, formatDateExibitionUTC } from '~/helpers'
import { setOrderOpen } from '~/modules/chat/store/actions'

import { Container } from './styles'

const OrdersRecent = ({ data, error, total, loadingOrders, hasCustomer }) => {
  const dispatch = useDispatch()

  const handleOrderOpened = ({ order_id, id }) =>
    dispatch(setOrderOpen({ open: true, orderId: order_id, id }))

  const shouldRenderList = useMemo(() => !!(total > 0 && !!data), [total, data])

  return (
    <Container>
      <header>
        <strong>Pedidos Recentes</strong>
      </header>

      <Loading status={loadingOrders}>Carregando pedidos recentes</Loading>

      <section>
        {!hasCustomer && !loadingOrders && error && (
          <p>Não foi possível buscar os pedidos do cliente.</p>
        )}

        {total === 0 && <p>Não foi encontrado pedido associado ao cliente.</p>}

        {error && <p>{error}</p>}

        {shouldRenderList &&
          data.map(({ id, order_id, createdAt }) => (
            <button
              key={id}
              type="button"
              onClick={() => handleOrderOpened({ order_id, id })}
            >
              {/* <div className="icon">
                <FiGlobe />
              </div> */}
              <div className="id">
                <strong>#{order_id}</strong>
                <br />
                <small>
                  {formatDateExibitionUTC(createdAt)} -{' '}
                  {formatDateToHour(createdAt)}
                </small>
              </div>

              <div className="action">
                <Button
                  template="transparentPrimary"
                  iconRight={<FiChevronRight />}
                  text="Ver resumo"
                  size="xsmall"
                  customWidth="auto"
                />
              </div>

              {/* <div className="icon icon__left">
                <FiChevronRight />
              </div> */}
            </button>
          ))}

        {total === 1 && <p>{total} pedido no total</p>}

        {total > 1 && <p>{total} pedidos no total</p>}
      </section>
    </Container>
  )
}

export default OrdersRecent

OrdersRecent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  total: PropTypes.number,
  error: PropTypes.string,
  loadingOrders: PropTypes.bool.isRequired,
  hasCustomer: PropTypes.string
}

OrdersRecent.defaultProps = {
  data: null,
  total: 0,
  error: null,
  hasCustomer: null
}
