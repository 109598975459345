import { Badge } from '~/components/UI/Badge'
import { getRetailMediaAccountType } from '~/helpers'

import ToogleActiveCampaignKeyword from '../../../ToogleActiveCampaignKeyword'

const accountType = getRetailMediaAccountType()

const renderSwitch = {
  active: {
    title: '',
    render: (rowData: CampaignPlace) => (
      <ToogleActiveCampaignKeyword data={rowData} key={rowData.id} />
    ),
    width: 50
  }
}

export const schemaPlaces: TableSchema<CampaignPlace> = {
  // ...(accountType === 'advertiser' ? { ...renderSwitch } : {}),
  title: {
    title: 'Locais',
    render: rowData => rowData.name,
    className: 'order__details col__infos col__title',
    showTotal: true
  }
}
