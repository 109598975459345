import { FiImage } from 'react-icons/fi'

import * as S from './styles'

interface LogoStoreInterface {
  linkLogo?: string
  alt?: string
  backgroundLogo?: string
  size?: 'small' | 'medium' | 'large'
}

export const LogoStore = ({
  backgroundLogo,
  linkLogo,
  alt = '',
  size = 'small'
}: LogoStoreInterface) => {
  return (
    <S.Container
      backgroundLogo={backgroundLogo}
      size={size}
      withoutImage={!linkLogo}
    >
      {linkLogo ? <img src={linkLogo} alt={alt} title={alt} /> : <FiImage />}
    </S.Container>
  )
}
