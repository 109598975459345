import { MutableRefObject } from 'react'
import { toastr } from 'react-redux-toastr'

import { FormHandles } from '@unform/core'
import { AxiosError } from 'axios'
import i18next from 'i18next'
import { ValidationError } from 'yup'

export const requiredValidation = value =>
  value ? undefined : i18next.t('config:profile.generalData.requiredField')

export const emailValidation = value => {
  if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Endereço de email inválido'
  }
  return undefined
}

export const passwordValidation = value => {
  if (value && !/^(?=.*[a-z])(?=.*[0-9])(?=.*[\W])(?=.{8,})/i.test(value)) {
    return 'Senha inválida'
  }
  return undefined
}

export const getValidationErrors = err => {
  const validationErrors = {}

  err.inner.forEach(error => {
    validationErrors[error.path] = error.message
  })

  return validationErrors
}

export const handleValidationsErrors = (error, formRef) => {
  if (error instanceof ValidationError) {
    const errors = getValidationErrors(error)

    console.error(errors.formData)

    formRef.current?.setErrors(errors)

    return toastr.error('Erro', 'Houve um erro ao validar os campos.')
  }
}

export const getValidationErrorsAPI = (err: ValidationBffErrors[]) => {
  const validationErrors: { [key: string]: string } = {}

  err.forEach(error => {
    validationErrors[error.property] = error.errors
      .map(e => e.message)
      .join(', ')
  })

  return validationErrors
}

const isValidationError = (
  error: AxiosError<APIErrorRawResponse> | Error
): error is AxiosError<ValidationBffError> => {
  return (
    'response' in error &&
    error.response !== undefined &&
    'data' in error.response &&
    'error_type' in error.response.data &&
    error.response.data.error_type === 'VALIDATION_ERRORS'
  )
}

export const handleValidationsErrorsAPI = (
  error: AxiosError<APIErrorRawResponse> | Error,
  formRef: MutableRefObject<FormHandles>
) => {
  if (isValidationError(error)) {
    const response = error.response!.data as ValidationBffError
    const errors = getValidationErrorsAPI(response.validations)

    console.error(errors)

    formRef.current?.setErrors(errors)
  }
}
