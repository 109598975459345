const cleanSocialId = data =>
  data
    ? data
        .toString()
        .replace(/[,;]/g, '\n')
        .replace(/\r\n/g, '\n')
        .split('\n')
        .filter(item => !!item.trim())
        .map(item => item.replace(/\s/g, '').replace(/([^a-z0-9]+)/gi, ''))
    : [];

export { cleanSocialId };
