import { FILTERS_SEELOUT } from '~/store/modules/filtersSellOut/constants'

export const metabaseKeys = {
  [FILTERS_SEELOUT.supermarkets]: 'account',
  [FILTERS_SEELOUT.stores]: 'store',
  [FILTERS_SEELOUT.channels]: 'channel',
  [FILTERS_SEELOUT.brands]: 'brand',
  [FILTERS_SEELOUT.categories]: 'category',
  [FILTERS_SEELOUT.subcategories]: 'subcategory',
  [FILTERS_SEELOUT.products]: 'product'
} as const

export type MetabaseTypesKeys = keyof typeof metabaseKeys
export type MetabaseTypesValues = (typeof metabaseKeys)[MetabaseTypesKeys]
