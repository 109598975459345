import {
  FILTERS_SEELOUT,
  FiltersSellOutKeysTypes
} from '~/store/modules/filtersSellOut/constants'

import brands from './brands'
import categories from './categories'
import channels from './channels'
import { MetabaseTypesValues } from './keysMetabase'
import products from './products'
import stores from './stores'
import subcategories from './subcategories'
import supermarkets from './supermarkets'

export type FiltersDataKeys = Extract<
  FiltersSellOutKeysTypes,
  | 'supermarkets'
  | 'brands'
  | 'categories'
  | 'products'
  | 'subcategories'
  | 'stores'
  | 'channels'
>

/**
 * O objeto com parâmetros para montar filtros para dados recebidos do Metabase
 *
 *  @param { string } metabaseType Type esperado pelo metabase
 *  @param { string } placeholder Placeholder principal quando já tivermos dados
 *  @param { string } placeholderError Placeholdr para casos de erro
 *  @param { string } labelMulti Label no plural
 *  @param { string } labelAll Label usado para o caso de selecionar todos
 *  @param { string } labelSearchAnything Label para o estado inicial sem dados
 *  @param { string } searching Label para o estado de busca em execução
 *  @param { boolean } bigData Informa se o dado é muito grande.
 */

type FiltersData = {
  [key in FiltersDataKeys]: {
    metabaseType: MetabaseTypesValues
    filteredBy?: FiltersSellOutKeysTypes[] | null
    placeholder: string
    placeholderError: string
    labelMulti: string
    labelAll: string
    labelSearchAnything?: string
    searching: string
    bigData?: boolean
  }
}

export const filtersSellOutDataset: FiltersData = {
  [FILTERS_SEELOUT.supermarkets]: { ...supermarkets },
  [FILTERS_SEELOUT.stores]: { ...stores },
  [FILTERS_SEELOUT.channels]: { ...channels },
  [FILTERS_SEELOUT.brands]: { ...brands },
  [FILTERS_SEELOUT.categories]: { ...categories },
  [FILTERS_SEELOUT.subcategories]: { ...subcategories },
  [FILTERS_SEELOUT.products]: { ...products }
}

// Separando os filtros que já estão prontos enquanto migramos todos
export const genericFilters = [
  FILTERS_SEELOUT.stores,
  FILTERS_SEELOUT.channels,
  FILTERS_SEELOUT.brands,
  FILTERS_SEELOUT.categories,
  FILTERS_SEELOUT.subcategories,
  FILTERS_SEELOUT.products
]
