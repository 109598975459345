import React, { useEffect, useState, useMemo } from 'react'
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet'
import { useSelector } from 'react-redux'

const MapLocation = () => {
  const [center, setCenter] = useState(null)
  const [map, setMap] = useState(null)

  const { mapCoordinates } = useSelector(state => state.locations)

  const { latitude, longitude, zoom, ...rest } = mapCoordinates

  const color = [{ color: 'lime' }, { color: 'purple' }]

  const radius = useMemo(
    () => Object.keys(rest).map(item => Number(rest[`${item}`]) * 1000),
    [rest]
  )

  useEffect(() => {
    if (latitude && longitude) {
      return setCenter([latitude, longitude])
    }

    setCenter(null)
  }, [latitude, longitude])

  useEffect(() => {
    if (center && map) {
      map.setView(center, zoom)
    }
  }, [center, map, zoom])

  return (
    <>
      {center && (
        <MapContainer
          center={center}
          zoom={zoom}
          scrollWheelZoom={false}
          style={{ height: '480px', marginBottom: '1rem', zIndex: 2 }}
          whenCreated={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={center}>
            <Popup>
              Está é a localização
              <br /> aproximada do seu local.
            </Popup>
          </Marker>

          {radius?.map((item, index) => (
            <Circle
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              center={center}
              pathOptions={color[index]}
              radius={item}
            />
          ))}
        </MapContainer>
      )}
    </>
  )
}

export default MapLocation
