import CampaignForm from './form'
import { CampaignFormProvider } from './hooks/useCampaignForm'

const CampaignFormV2: React.FC = () => {
  return (
    <CampaignFormProvider>
      <CampaignForm />
    </CampaignFormProvider>
  )
}

export default CampaignFormV2
