import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/category`

export const list = (queries: RequestListCategory) =>
  axios.get(`${baseURL}`, { params: { ...queries } })

export default {
  list
}
