import { animated } from 'react-spring'

import styled, { css } from 'styled-components'

export const AnimatedContainer = styled(animated.div)`
  position: relative;
  z-index: 10;
  height: 100dvh;
  min-width: 50dvw;
  width: 600px;
  max-width: 90dvw;
  margin-left: auto;
`

export const Container = styled.div`
  &[data-size='small'] {
    --minWidth: 25dvw;
    --width: 300px;
  }

  &[data-size='medium'] {
    --minWidth: 50dvw;
    --width: 600px;
  }

  &[data-size='large'] {
    --minWidth: 75dvw;
    --width: 1280px;
  }

  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;

    overflow: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.colors.inative};
      border-radius: 0.5rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.tertiary};
      transition: 0.3s ease-in-out all;
      border-radius: 0.5rem;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.gray};
    }

    display: flex;
    flex-direction: column;

    height: 100vh;
    max-height: 100vh;

    min-width: var(--minWidth);
    width: var(--width);
    max-width: 90dvw;

    box-shadow: ${theme.shadows.fromEnd.dp20}, ${theme.shadows.fromEnd.dp12};

    background: var(--white);
    color: var(--dark);
  `}
`

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    padding: ${theme.spacings.large} 0 ${theme.spacings.xsmall};

    z-index: 10;

    strong {
      font-size: ${theme.font.sizes.xlarge};
      font-weight: ${theme.font.bold};
    }
  `}
`
export const Content = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  /* display: inline-flex; */
  overflow: auto;
`

export const Overlay = styled(animated.div)`
  ${({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;

    content: '';
    width: 100vw;
    height: 100vh;
    display: block;

    background-color: ${`${theme.colors.neutral.low.medium}dd`};
    z-index: 1;
  `}
`
