import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    p {
      font-size: ${theme.font.sizes.small};
    }
  `}
`

export const FilteredTagsWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    width: 50%;
    min-height: 2.2rem;
    left: 0;
    position: absolute;
    max-height: 75svh;
    overflow-y: auto;
    top: 100%;

    margin-top: 0 !important;
    border: 1px solid #eaecef;
    border-radius: 0.25rem;

    border-top-left-radius: unset;
    border-top-right-radius: unset;

    z-index: 6;

    box-shadow: 0 0.25rem 5rem 0 rgb(72 74 86 / 50%);

    .alertInfo {
      margin: 0.5rem;
    }

    .highlight {
      color: ${theme.colors.primary};
    }

    p {
      line-height: 42px;
      margin: 0;
      padding: 0 12px;
    }
  `}
`

export const TagButton = styled.button<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    font-weight: ${isSelected ? '600' : '400'};

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: ${theme.spacings.xsmall} ${theme.spacings.small};
    width: 100%;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.dark};
    text-align: left;

    &:hover {
      background-color: ${theme.colors.primaryLighten60};

      * {
        color: ${theme.colors.primary};
      }
    }

    & + button {
      border-top: 1px solid #eaecef;
    }
  `}
`

export const TagWrapper = styled.div``

export const Tag = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: ${theme.colors.outlineGray};
    padding: ${theme.spacings.tiny} ${theme.spacings.xsmall};
    border-radius: ${theme.border.radius.medium};
    border: 1px solid ${theme.colors.dark};

    margin-bottom: ${theme.spacings.xxsmall};

    font-size: ${theme.font.sizes.xsmall};

    margin-right: ${theme.spacings.xxxsmall};

    button {
      display: flex;
      align-items: center;
      margin-left: ${theme.spacings.tiny};
      transition: color 200ms ease;

      &:hover {
        color: ${theme.colors.gray};
      }

      svg {
        font-size: ${theme.font.sizes.small};
      }
    }
  `}
`

export const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const InputContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
    margin-top: ${theme.spacings.xxsmall};
    position: relative;

    .filtered {
      display: none;
    }

    &:focus-within {
      .filtered {
        display: block;
      }
    }

    .closed-icon {
      color: ${theme.colors.primary};
      cursor: pointer;
    }
  `}
`
interface Props {
  showAutocomplete: boolean
}
export const WrapperInput = styled.div<Props>`
  ${({ showAutocomplete }) => css`
    .inputContainer {
      position: relative;
      z-index: 7;
      border-bottom-left-radius: ${showAutocomplete ? 'unset' : '0.25rem'};
      border-bottom-right-radius: ${showAutocomplete ? 'unset' : '0.25rem'};
    }
  `}
`
