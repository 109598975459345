import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const SetValueContext = createContext(() => {});

const Form = ({ checkIsValid, onSubmit, className, children, ...props }) => {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});

  const handleErrors = (error, name) => {
    if (error) {
      errors[name] = error;
    } else {
      delete errors[name];
    }

    const isValid = Object.keys(errors).length <= 0;

    setErrors(errors);

    if (checkIsValid) {
      checkIsValid(isValid);
    }
  };

  const handleChange = params => {
    const { name, value, error } = params;

    handleErrors(error, name);

    setData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(data);
    return false;
  };

  return (
    <SetValueContext.Provider value={handleChange}>
      <form {...props} onSubmit={handleSubmit} className={className}>
        {children}
      </form>
    </SetValueContext.Provider>
  );
};

export default Form;

Form.propTypes = {
  children: PropTypes.any.isRequired,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  isValid: PropTypes.func,
};
