import { IconType } from 'react-icons'
import { FiLayout as Icon } from 'react-icons/fi'

import * as Orders from '~/pages/orders'

export const orders: RootMenuItem<IconType> = {
  id: '50ed401f-0982-4f63-aaa3-bd1387aaa183',
  path: '/orders',
  name: 'Pedidos',
  icon: Icon,
  children: [
    {
      id: 'a81795bc-75fe-4ee4-88c4-bd2426af261e',
      path: '/orders/list',
      name: 'Listagem de pedidos',
      pageTitle: 'Listagem de pedidos',
      component: Orders.OrdersList
    },
    {
      id: '0e4f6764-2692-4e05-b3c7-0f6d1ae1b06c',
      path: '/orders/board',
      name: 'Quadro de pedidos',
      pageTitle: 'Quadro de pedidos',
      component: Orders.OrdersBoard,
      maxHeightViewport: true,
      contentFullWidth: true
    },
    {
      id: 'ea987244-fa52-4012-8b3d-2999d2a9c633',
      path: '/orders/orders-dashboard/',
      name: 'Relatórios de pedidos',
      pageTitle: 'Relatórios de pedidos',
      component: Orders.OrdersDashboard,
      contentFullWidth: true
    },
    {
      id: '38c3b55c-4ab0-4ad7-a659-e4fe9da8c3ca',
      path: '/orders/picking-dashboard/:mode',
      name: 'Relatórios de separação',
      pageTitle: 'Relatórios de separação',
      component: Orders.PickingDashboard,
      contentFullWidth: true,
      maxHeightViewport: true,
      hideMenu: true
    },
    {
      id: '38c3b55c-4ab0-4ad7-a659-e4fe9da8c3ca',
      path: '/orders/picking-dashboard/',
      name: 'Relatórios de separação',
      pageTitle: 'Relatórios de separação',
      component: Orders.PickingDashboard,
      contentFullWidth: true
    },
    {
      id: 'f757ea51-6e25-4908-8dc1-18d3db3a9438',
      path: '/orders/conversion-dashboard/',
      name: 'Relatórios de conversão',
      pageTitle: 'Relatórios de conversão',
      component: Orders.ConversionDashboard,
      contentFullWidth: true
    },
    {
      id: 'd010145d-3b5b-4017-bf91-066d5d9690dd',
      path: '/orders/rupture-dashboard/',
      name: 'Relatório de rupturas',
      pageTitle: 'Relatório de ruptura',
      component: Orders.RuptureDashboard,
      contentFullWidth: true
    },
    {
      id: null, // rota-sem-id
      path: '/order/:id',
      name: 'Informações do pedido',
      pageTitle: 'Informações do pedido',
      component: Orders.OrderInfo,
      hideMenu: true
    }
  ]
}
