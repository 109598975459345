import * as types from '../constants';

const index = {
  optimistic: `${types.UPDATE_TEMPLATE_OPTIMISTIC}`,
  pending: `${types.UPDATE_TEMPLATE}_PENDING`,
  fulfilled: `${types.UPDATE_TEMPLATE}_FULFILLED`,
  rejected: `${types.UPDATE_TEMPLATE}_REJECTED`,
};

export const updateTemplate = {
  /**
   * Optimistic
   */
  [index.optimistic]: ({ action, draft }) => {
    const updatedTemplate = {
      ...action.payload,
      saving: true,
    };

    const oldTemplates = draft.templates;

    const currentTemplateIndex = oldTemplates.findIndex(
      item => item.templateId === updatedTemplate.templateId,
    );

    oldTemplates[currentTemplateIndex] = updatedTemplate;

    draft.templates = [...oldTemplates];
  },
  /**
   * Pending
   */
  [index.pending]: ({ draft }) => {
    draft.loadingSaving = true;
  },
  /**
   *
   * Fulfilled
   */
  [index.fulfilled]: ({ draft }) => {
    const oldTemplates = draft.templates;

    const filteredldTemplates = oldTemplates.map(template =>
      template?.saving ? { ...template, saving: false } : { ...template },
    );

    draft.templates = [...filteredldTemplates];

    draft.loadingSaving = false;
  },
  /**
   * Rejected
   */
  [index.rejected]: ({ draft }) => {
    const oldTemplates = draft.templates;

    const filteredldTemplates = oldTemplates.filter(
      template => !template.saving,
    );

    draft.templates = [...filteredldTemplates];

    draft.loadingSaving = false;
  },
};
