import axios from 'axios'

/**
 *
 * @endpoint /api/locations
 *
 * @exports indexLocationService Busca todos os locais da loja
 *
 * @exports showLocationService Busca as informações de um local
 * @param {string} id ID do local
 *
 * @exports createLocationService Criação de local
 * @param {object} data  Dados para criação do local
 *
 * @exports updateLocationService Atualiza um local
 * @param {object} data Dados para atualizar um local
 *
 * @exports deleteLocationService Deletar um local
 * @param {string} id Id do local que será deletado
 *
 * @endpoint /api/locations/delivery-type
 *
 * @exports indexLocationDeliveryTypesService Busca os tipos de entrega cadastrados na conta
 *
 * @exports indexLocationUsersService
 * @exports updateLocationUsersService
 *
 * @exports getInfoAddressByPostalCode Consulta o CEP informado
 * @exports getInfoAddressByPostalCodeForCoordinates Consulta o CEP informado trazendo a informação de cordenadas
 *
 */

export const indexLocationService = queryString =>
  axios.get(`/api/location${queryString ? `?${queryString}` : ''}`)

export const indexLocationMinifiedService = queryString =>
  axios.get<ListLocationMinifiedServiceResponse>(
    `/api/location/minified${queryString ? `?${queryString}` : ''}`
  )

export const showLocationService = id => axios.get(`/api/location/${id}`)

export const createLocationService = data => axios.post(`/api/location/`, data)

export const updateLocationService = data => axios.put(`/api/location/`, data)

export const deleteLocationService = id => axios.delete(`/api/location/${id}`)

/**
 * Delivery type
 */

export const indexLocationDeliveryTypesService = () =>
  axios.get(`/api/location/delivery-type`)

export const searchLocationByDeliveryType = ({ query, host }) =>
  axios.get(`/api/location/search-by-polygon?host=${host}`, {
    params: query,
    headers: {
      Referer: host
    }
  })

/**
 * Info Address
 */

export const getInfoAddressByPostalCodeForCoordinates = ({ postalCode }) =>
  axios.get(`/api/location/geocode?address=${postalCode}`)

export const getInfoAddressByPostalCode = postalCode =>
  axios.get(`/api/location/geocode?address=${postalCode}`)

/**
 * Location users
 */

export const indexLocationUsersService = ({ locationId }) =>
  axios.get(`/api/location/user-location/?location_id=${locationId}`)

export const createLocationUsersService = ({ body }) =>
  axios.post(`/api/location/user-location`, body)

export const deleteLocationUsersService = ({ locationId, userId }) =>
  axios.delete(`/api/location/user-location/${locationId}/${userId}`)

/**
 * Location regions
 */

export const indexAllLocationRegionsService = ({ data }) =>
  axios.post(`/api/location/region/find-by-account`, data)

export const indexLocationRegionsService = ({ locationId }) =>
  axios.get(`/api/location/region/${locationId}`)

export const createLocationRegionsService = body =>
  axios.post(`/api/location/region/`, body)

export const updateLocationRegionsService = ({ locationId, regionId, body }) =>
  axios.put(`/api/location/region/${locationId}/${regionId}`, body)

export const deleteLocationRegionsService = ({ locationId, regionId }) =>
  axios.delete(`/api/location/region/${locationId}/${regionId}`)
