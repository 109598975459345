import React from 'react';

import LocationsRadioList from '~/components/LocationsRadioList';
import Panel from '~/components/Panel';
import { Label, Radio } from '~/components/unform';

import * as S from './styles';

const PromotionLocations = () => {
  const optionsTypes = [
    {
      id: `locationsCheck_all`,
      value: 'all',
      label: 'Todos os locais',
    },
    {
      id: `locationsCheck_selected`,
      value: 'selected',
      label: 'Selecionar locais',
      activeChildren: (
        <LocationsRadioList label="Defina quais locais participarão da promoção" />
      ),
    },
  ];

  return (
    <Panel title="Locais participantes" isCollapsible>
      <S.Group>
        <Label
          htmlFor="locationsCheck"
          isRequired
          text="Escolha quais locais terão acesso a esta promoção."
        />

        <Radio options={optionsTypes} name="locationsCheck" required />
      </S.Group>
    </Panel>
  );
};

export default PromotionLocations;
