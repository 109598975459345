import styled, { css } from 'styled-components';

const containerGroupModifiers = {
  isRepeatable: theme => css`
    > * {
      z-index: 1;
    }

    &:after {
      position: absolute;
      z-index: 0;
      content: '';
      border: 1px solid ${theme.colors.primaryLighten30};
      border-radius: 0.25rem;
      width: calc(100% + 0.25rem);
      height: calc(100% + 0.25rem);

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .wrapperDelete {
      transform: translate(50%, -50%);
    }
  `,
};

export const ContainerGroup = styled.div`
  ${({ theme, isRepeatable }) => css`
    position: relative;

    .wrapperDelete {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.25rem;
      width: 1.25rem;
      opacity: 0;

      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      transform: translate(25%, -25%);

      background: ${theme.colors.white};
      border-radius: 50%;
      box-shadow: -2px 0 0.5rem 0.2px rgb(87 62 113 / 20%);

      transition: 0.3s ease-in-out all;

      svg {
        color: ${theme.colors.danger};
      }
    }

    &:hover {
      .wrapperDelete {
        opacity: 1;
      }
    }

    ${isRepeatable && containerGroupModifiers.isRepeatable(theme)}
  `}
`;
