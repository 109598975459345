import cardValidations from 'card-validator'
import * as Yup from 'yup'

const ccValidations = {
  card_cvv: Yup.string().test(
    (value: string) => cardValidations.cvv(value).isValid
  ),

  card_expiration: Yup.string().test(
    (value: string) => cardValidations.expirationDate(value).isValid
  ),

  card_name: Yup.string().test(
    (value: string) => cardValidations.cardholderName(value).isValid
  ),

  card_number: Yup.string().test(
    (value: string) => cardValidations.number(value).isValid
  )
}

const FormCard = Yup.object().shape(ccValidations)

export { FormCard }
