import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.neutralHigh};

    padding-top: 0.5rem;
    margin-top: 0.5rem;

    .failure__button {
      margin: 0.5rem 0;
    }

    footer {
      display: flex;
      flex-direction: column;
      width: 100%;

      > * + * {
        margin-top: 0.5rem;
      }
    }
  `}
`

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;

      span {
        color: ${theme.colors.neutralColor};
        line-height: 1.125;
        font-size: ${theme.font.sizes.xsmall};
      }
    }

    aside {
      img {
        height: 16px;
      }
    }
  `}
`

export const WrapperError = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    border-left: 4px solid ${theme.colors.danger};
    background-color: #fdf7f7;
    padding: 12px;

    margin: 0.5rem 0 0;
  `}
`

export const WrapperInfo = styled.div`
  ${({ theme }) => css`
    border-left: 4px solid ${theme.colors.info};
    background-color: ${theme.colors.infoLighten50};
    padding: 0.4rem 0.8rem;

    margin: 0.5rem 0 0;
  `}
`

export const Card = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0.5rem 0;

    overflow: hidden;

    border-radius: 0.5rem;

    background: ${theme.colors.light};

    .button_open-modal:not([disabled]) {
      cursor: zoom-in;
    }

    .button_open-modal:disabled {
      cursor: default;
    }

    .user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      padding: ${theme.spacings.xsmall};

      .userInfo {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;

        line-height: 1.125;

        .vehicle {
          display: flex;
          align-items: center;

          > span {
            font-size: 0.875rem;
            color: ${theme.colors.grayScale};
            margin-right: 0.25rem;
          }
        }
      }

      .avatar {
        margin-right: ${theme.spacings.xxxsmall};
        max-width: 52px;

        overflow: hidden;
        img {
          width: clamp(52px, 52px, 52px);
          height: clamp(52px, 52px, 52px);
          border-radius: 50%;
          object-fit: cover;
        }

        .fake {
          display: flex;
          justify-content: center;
          align-items: center;

          width: clamp(52px, 52px, 52px);
          height: clamp(52px, 52px, 52px);
          font-size: 1.5rem;

          border-radius: 50%;
          background: ${theme.colors.outlineGray};
          color: ${theme.colors.dark};
        }
      }
    }

    .phoneNumber {
      padding: ${theme.spacings.xsmall};

      .iconPhone {
        font-size: 1.125rem;
        color: ${theme.colors.primary};
      }
    }
  `}
`

export const VehicleColor = styled.span`
  ${({ theme, vehicleColor = '#fff' }) => css`
    display: inline-flex;
    height: 1rem;
    width: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    background: ${vehicleColor || 'white'};
  `}
`

export const HeaderCard = styled.header`
  ${({ theme }) => css`
    position: relative;

    flex: 1 1 100%;

    background: ${theme.colors.outlineGray};
    color: ${theme.colors.dark};
    line-height: 1.25;
    font-size: ${theme.font.sizes.xsmall};

    padding: 0.25rem;
    text-align: center;

    .uber_delivery_id {
      letter-spacing: 2px;
      padding: 0 0.5rem;
    }

    .LoadingContainer {
      position: absolute;
      bottom: -2px;
      left: 0;

      width: 100%;
    }
  `}
`
