import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import HeaderPages from '~/components/HeaderPages';
import Loading from '~/components/Loading';
import PageTitle from '~/components/PageTitle';
import { formatDateToHour, formatDateToString } from '~/helpers';
import { useAlert } from '~/hooks/useAlert';
import campaignActions from '~/modules/messagingCampaigns/store/campaign/actions';
import templateActions from '~/modules/messagingCampaigns/store/template/actions';

import FormCampaign from '../_elements/FormCampaign';

const CampaignsDuplicate = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const {
    campaign: {
      item: { data, loading: campaignLoading, error },
    },
    template: {
      item: { data: templateData = {}, loading: templateLoading },
    },
  } = useSelector(state => state.messagingCampaigns);

  const loading = campaignLoading || templateLoading;

  const [AlertOnError] = useAlert(error);

  const loadCampaign = useCallback(() => {
    dispatch(campaignActions.get(id));
  }, [dispatch, id]);

  useEffect(() => {
    loadCampaign();
  }, [loadCampaign]);

  useEffect(() => {
    if (data?.template_id) {
      dispatch(templateActions.get(data?.template_id));
    }
  }, [data?.template_id, dispatch]);

  const initialData = useMemo(() => {
    if (!data || !templateData) return {};

    const { _id: templateId } = templateData;
    const { schedule_date, expire_at } = data;

    return {
      ...data,
      name: `${data.name} cópia`,
      template_id: templateId,
      scheduleMode: 'sendLater',
      start_date: formatDateToString(schedule_date, 'YYYY-MM-DD'),
      start_hour: formatDateToHour(schedule_date),
      end_date: formatDateToString(expire_at, 'YYYY-MM-DD'),
      end_hour: formatDateToHour(expire_at),
      destinations: data.destinations.reduce(
        (acc, item) => `${acc}${item.customer_number}, `,
        '',
      ),
      callbacks: [
        {
          ...(data.callbacks?.[0] ? { ...data.callbacks[0] } : {}),
          // eslint-disable-next-line max-len
          message: data.callbacks?.[0]?.message,
        },
      ],
    };
  }, [data, templateData]);

  return (
    <>
      <PageTitle title="Campanhas > duplicar" />

      <HeaderPages
        title="Duplicar campanha"
        backTo="/messaging-campaigns/list"
        labelBreadcrumb="Voltar para campanhas"
      />

      <AlertOnError />

      <Loading status={loading}>Carregando informações da campanha</Loading>

      {!loading && initialData && <FormCampaign initialData={initialData} />}
    </>
  );
};

export default CampaignsDuplicate;
