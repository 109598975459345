import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';

import PropTypes from 'prop-types';

import { Button } from '~/components/Buttons';

import { Container } from './styles';

const ROLE_FALLBACK_STRING = 'Sem cargo adicionado';

const ApproverCard = ({ name, approver_role, email }) => {
  return (
    <Container className="approver-card-container">
      <div className="approver-info">
        <p className="approver-name">{name}</p>
        <p className="approver-role">{approver_role || ROLE_FALLBACK_STRING}</p>
        <p className="approver-email">{email}</p>
      </div>
      <div className="buttons-container">
        <Button template="icon" iconLeft={<AiOutlineEdit />} />

        <Button className="trash-icon" template="icon" iconLeft={<BiTrash />} />
      </div>
    </Container>
  );
};

ApproverCard.propTypes = {
  name: PropTypes.string.isRequired,
  approver_role: PropTypes.string,
  email: PropTypes.string.isRequired,
};

ApproverCard.defaultProps = {
  approver_role: 'Sem cargo adicionado',
};

export default ApproverCard;
