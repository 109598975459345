import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  overflow: hidden;

  width: 40px;
  height: 40px;

  border-radius: 20px;

  background: var(--gray);
  color: var(--stormGray);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    padding: 0.25rem;
  }
`
