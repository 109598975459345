import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    padding: 0.5rem 0.75rem;

    border-radius: ${theme.border.radius.large};

    background-color: ${theme.colors.primaryLighten60};

    .label {
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.bold};
    }

    .value {
      font-size: ${theme.font.sizes.small};

      transition: 0.3s ease-in-out;
    }

    &:hover {
      .value {
        letter-spacing: 3px;
        font-weight: bold;
      }
    }
  `}
`;
