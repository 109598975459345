import * as types from '../constants';

const index = {
  pending: `${types.UPDATE_STATUS}_PENDING`,
  fulfilled: `${types.UPDATE_STATUS}_FULFILLED`,
  rejected: `${types.UPDATE_STATUS}_REJECTED`,
  shouldRevalidateList: `${types.SHOULD_REVALIDATE}`,
};

export const cases = {
  [index.pending]: ({ draft }) => {
    draft.loadingUpdate = true;
    draft.errorUpdate = null;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.itemUpdated = action.payload;
    draft.loadingUpdate = false;
    draft.errorUpdate = null;
    draft.shouldRevalidateList = true;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.loadingUpdate = false;
    draft.errorUpdate = action.payload;
  },
  [index.shouldRevalidateList]: ({ draft, action }) => {
    draft.shouldRevalidateList = action.payload;
  },
};
