import React, { useCallback, useRef } from 'react'
import { FiSave, FiX } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { FormGroup, Input } from '~/components/unform'
import { updateFullName } from '~/pages/orders/actions'

import * as S from './styles'

const FormEdit = ({ initialData, handleToggleShowEditForm }) => {
  const formRef = useRef(null)

  const dispatch = useDispatch()

  const { orderData, loadingUpdateRecipientFullName } = useSelector(
    state => state.order
  )

  const handleSubmit = useCallback(
    query => {
      const { id } = orderData

      const data = {
        ...orderData.shipping_address?.[0],
        full_name: query.full_name
      }

      dispatch(
        updateFullName({ id, data, onSuccess: handleToggleShowEditForm })
      )
    },
    [dispatch, handleToggleShowEditForm, orderData]
  )

  return (
    <Form onSubmit={handleSubmit} ref={formRef} initialData={initialData}>
      <FormGroup>
        <S.WrapperInput>
          <Input name="full_name" id="full_name" />
        </S.WrapperInput>

        <S.WrapperButtons>
          <Button
            text="Cancelar"
            onClick={handleToggleShowEditForm}
            template="transparentDanger"
            iconLeft={<FiX />}
            size="small"
            disabled={loadingUpdateRecipientFullName}
          />

          <Button
            type="submit"
            text="Salvar"
            template="success"
            iconLeft={<FiSave />}
            size="small"
            loading={loadingUpdateRecipientFullName}
            textLoading="Salvando nome..."
          />
        </S.WrapperButtons>
      </FormGroup>
    </Form>
  )
}

FormEdit.propTypes = {
  initialData: PropTypes.shape({
    full_name: PropTypes.string
  }),
  handleToggleShowEditForm: PropTypes.func
}
FormEdit.defaultProps = {
  initialData: null,
  handleToggleShowEditForm: null
}

export default FormEdit
