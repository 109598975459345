import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';

import WrapperFields from './wrapper';
import Input from '~/components/forms/inputs';

const SizesForm = ({
  onNext,
  onPrevious,
  onSubmitStep,
  disabledStep,
  edit,
  initialValue,
  isLastStep,
}) => {
  const beforeSubmitOnCreateProduct = data => {
    const formattedData = {
      main: {
        ...data,
      },
    };

    onNext();

    onSubmitStep(formattedData);
  };

  return (
    <WrapperFields
      onSubmit={beforeSubmitOnCreateProduct}
      disabledStep={disabledStep}
      onPreviousStep={onPrevious}
      edit={edit}
      isLastStep={isLastStep}
    >
      <div>
        {/* width */}
        <FormGroup className="form-group__flex form-group__wrap form-group__inline form-group__third-width">
          <Input
            type="number"
            label="Largura"
            name="width"
            defaultValue={initialValue.width}
            iconRight="cm"
          />
        </FormGroup>
        {/* height */}
        <FormGroup className="form-group__flex form-group__wrap form-group__inline  form-group__third-width">
          <Input
            type="number"
            label="Altura"
            name="height"
            defaultValue={initialValue.height}
            iconRight="cm"
          />
        </FormGroup>
      </div>

      <div>
        {/* depth */}
        <FormGroup className="form-group__flex form-group__wrap form-group__inline  form-group__third-width">
          <Input
            type="number"
            label="Comprimento"
            name="depth"
            defaultValue={initialValue.depth}
            iconRight="cm"
          />
        </FormGroup>
        {/* weight */}
        <FormGroup className="form-group__flex form-group__wrap form-group__inline  form-group__third-width">
          <Input
            type="number"
            label="Peso"
            name="weight"
            defaultValue={initialValue.weight}
            iconRight="g"
          />
        </FormGroup>
      </div>
    </WrapperFields>
  );
};

export default SizesForm;

SizesForm.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmitStep: PropTypes.func,
  disabledStep: PropTypes.bool,
  isLastStep: PropTypes.bool,
  initialValue: PropTypes.shape(),
};

SizesForm.defaultProps = {
  onNext: null,
  onNext: null,
  onPrevious: null,
  onSubmitStep: null,
  disabledStep: false,
  isLastStep: false,
  initialValue: {},
};
