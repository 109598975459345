import { shade } from 'polished'
import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const iconButtonTemplate = ({ theme, props }: ButtonTemplatesProps) => css`
  background: transparent;
  color: var(--primary);

  min-width: unset;
  height: auto;
  padding: 0.125rem 0.25rem;

  &:not(:disabled) {
    &:hover {
      color: var(--primary);
    }
  }

  &,
  .icon {
    width: 1.5rem;
    font-size: 1.5rem;

    svg {
      width: 1.5rem;
    }
  }

  .icon {
    margin-right: 0;
  }
`

export default iconButtonTemplate
