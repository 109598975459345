import { useTranslation } from 'react-i18next'

import AlertInfo from '~/components/AlertInfo'
import Panel from '~/components/Panel'
import { FormGroup, Input, Label } from '~/components/unform'

const FormMainInfo: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Panel>
      <FormGroup className="span2">
        <Label
          text={t('rm:placements.form.name.label')}
          helperText={t('rm:placements.form.name.labelHelperText')}
          htmlFor="name"
          isRequired
        />
        <Input
          name="name"
          placeholder={t('rm:placements.form.name.placeholder')}
          required
        />
        <AlertInfo template="info">
          {t('rm:placements.form.name.warning')}
        </AlertInfo>
      </FormGroup>

      <FormGroup className="span-start">
        <Label
          text={t('rm:placements.form.full_name.label')}
          htmlFor="full_name"
          helperText={t('rm:placements.form.full_name.labelHelperText')}
        />
        <Input
          name="full_name"
          placeholder={t('rm:placements.form.full_name.placeholder')}
        />
      </FormGroup>

      <FormGroup>
        <Label
          text={t('rm:placements.form.description.label')}
          helperText={t('rm:placements.form.description.labelHelperText')}
          htmlFor="description"
        />
        <Input
          name="description"
          placeholder={t('rm:placements.form.description.placeholder')}
        />
      </FormGroup>
    </Panel>
  )
}

export default FormMainInfo
