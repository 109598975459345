import React from 'react'

import Panel from '~/components/Panel'
import { Label, FormGroup, Input, InputPhone } from '~/components/unform'

const ContactFields = () => (
  <fieldset>
    <Panel title="Contatos do local" className="form-grid" isCollapsible>
      <FormGroup className="span-start  span2">
        <Label htmlFor="main_phone">Telefone</Label>
        <InputPhone
          name="main_phone"
          placeholder="Insira o telefone"
          type="tel"
        />
      </FormGroup>

      <FormGroup className="span2">
        <Label htmlFor="email">E-mail</Label>
        <Input
          id="email"
          name="email"
          placeholder="Insira o e-mail do local"
          tyoe="email"
        />
      </FormGroup>
    </Panel>
  </fieldset>
)

export default ContactFields
