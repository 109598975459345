import React from 'react';

import {
  getIsLocalConfiguration,
  getPopulatedPaymentMethods,
} from '../../helpers';
import Card from '../Card';

const Locations = ({
  filteredLocations = [],
  paymentMethodsList,
  paymentMethodsConfigList,
  handleFormSubmit,
}) => {
  return filteredLocations?.map(({ id, name }) => (
    <Card key={id} title={name} locationId={id} />
  ));
};

export default Locations;
