import styled, { css } from 'styled-components';

const containerModifiers = {
  editMode: theme => css`
    border: 1px solid ${theme.colors.warning};
    background-color: ${theme.colors.secondaryLighten30};

    &:not(:first-child) {
      margin-top: ${theme.spacings.xxsmall};
    }

    &:not(:last-child) {
      margin-bottom: ${theme.spacings.xxsmall};
    }
  `,
  deleteMode: theme => css`
    border: 1px solid ${theme.colors.danger};
  `,
  isDeleted: () => css`
    opacity: 0.5;
  `,
  isSaving: () => css`
    aside {
      .global {
        transform: translateX(-1rem);
      }
    }
  `,
};

export const Container = styled.div`
  ${({ theme, editMode, deleteMode, isDeleted, isSaving }) => css`
    border: 1px solid ${theme.colors.inative};
    border-radius: 0.25rem;
    background-color: ${theme.colors.white};

    transition: ${theme.transition.default} all;

    position: relative;

    .loadingIndicator {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }

    > header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: ${theme.spacings.xsmall} ${theme.spacings.xsmall} 0;

      aside {
        .global {
          display: flex;
          align-items: center;
          padding: ${theme.spacings.tiny};
          color: ${theme.colors.primary};
          background-color: ${theme.colors.primaryLighten60};
          font-size: ${theme.font.sizes.xsmall};
          border-radius: 0.25rem;
          line-height: 1;
          transition: ${theme.transition.default} all;

          svg {
            margin-right: 0.125rem;
          }
        }
      }
    }

    > section {
      margin: ${theme.spacings.tiny} 0 ${theme.spacings.xsmall};
      padding: 0 ${theme.spacings.xsmall};

      > p {
        margin: 0;
        line-height: 1.125rem;

        + p {
          margin-top: 1rem;
        }
      }
    }

    > footer {
      display: flex;
      justify-content: space-between;
      margin: 0.5rem;
      padding-top: 0.5rem;
      border-top: 1px solid ${theme.colors.inative};

      *:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    ${editMode && containerModifiers.editMode(theme)}
    ${deleteMode && containerModifiers.deleteMode(theme)}
    ${isDeleted && containerModifiers.isDeleted()}
    ${isSaving && containerModifiers.isSaving()}
  `}
`;

export const ContainerEdit = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xsmall};
    margin: ${theme.spacings.tiny} 0 ${theme.spacings.xsmall};
  `}
`;
