import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import Alert from '~/components/Alert';

import { Wrapper } from './styles';

const PanelOpenValue = () => (
  <Alert>
    <Wrapper>
      <FiAlertCircle /> Este pedido contém produto com o valor aberto.
    </Wrapper>
  </Alert>
);

export default PanelOpenValue;
