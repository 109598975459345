import React from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { FaCaretUp } from 'react-icons/fa';
import { RiAlertFill } from 'react-icons/ri';

import PropTypes from 'prop-types';

import { formatDateToString, formatMoney } from '~/helpers';

import ProgressBar from './ProgressBar';
import * as S from './styles';

const TradeCard = ({ data }) => {
  const {
    id,
    status,
    title,
    start_date,
    end_date,
    industry_name,
    type,
    channels,
    warnings,
    values,
  } = data;

  const hasPercentageIncrease = values.percentage_increase;
  const hasMaxValue = values.max_value;
  const hasGoal = values.goal;

  const TRADE_TYPE = {
    sell_out: 'Sell-out',
  };

  return (
    <S.Wrapper>
      <S.Column>
        <div className="tradeInfo">
          <span className="tradeId">
            #{id} <span className="bullet">•</span>
          </span>
          <S.Status type={status.type}>{status.label}</S.Status>
        </div>
        <p className="title">{title}</p>
        <S.DateContainer>
          <div className="date">
            <AiOutlineCalendar /> {formatDateToString(start_date, 'DD/MM/YY')}
          </div>
          <div className="date">
            <AiOutlineCalendar /> {formatDateToString(end_date, 'DD/MM/YY')}
          </div>
        </S.DateContainer>
      </S.Column>

      <S.Column>
        <p className="industry">{industry_name}</p>
        <p className="text">{TRADE_TYPE[type]}</p>
        <p className="text">{channels}</p>
      </S.Column>

      <S.Column>
        {warnings && (
          <S.StatusTag>
            <RiAlertFill />
            {warnings.label}
          </S.StatusTag>
        )}
      </S.Column>

      <S.Column>
        <S.ValuesContainer>
          <p className="value">
            <strong>{formatMoney(values.reached)}</strong>
            {hasMaxValue && <>/{formatMoney(values.max_value)}</>}
            {hasGoal && <>/{formatMoney(values.goal)}</>}
          </p>

          {hasGoal && <ProgressBar value={values.reached} goal={values.goal} />}
        </S.ValuesContainer>

        {hasPercentageIncrease && (
          <p className="message">
            <FaCaretUp /> Aumento médio de {values.percentage_increase}% nas
            vendas
          </p>
        )}
      </S.Column>
    </S.Wrapper>
  );
};

TradeCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string,
    }),
    title: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    industry_name: PropTypes.string,
    type: PropTypes.string,
    channels: PropTypes.arrayOf(PropTypes.string),
    warnings: PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string,
    }),
    values: PropTypes.shape({
      max_value: PropTypes.number,
      reached: PropTypes.number,
      percentage_increase: PropTypes.number,
      goal: PropTypes.number,
    }),
  }).isRequired,
};

export default TradeCard;
