import React, { useRef, useEffect } from 'react'

import { useField } from '@unform/core'

import SelectSearchable from '../../SelectSearchable'

export default function ReactSelect({ name, ...rest }) {
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return []
          }
          return ref.state.value.map(option => option.value)
        }
        if (!ref.state.value) {
          return ''
        }
        return ref.state.value.value
      }
    })
  }, [fieldName, registerField, rest.isMulti])

  return (
    <SelectSearchable
      defaultValue={defaultValue}
      selectRef={selectRef}
      {...rest}
    />
  )
}
