import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, hasSideButton }) => css`
    display: flex;
    width: 100%;

    > nav {
      width: 100%;

      ${hasSideButton &&
      css`
        padding-right: 0.5rem;
        margin-right: 0.5rem;
        border-right: 1px solid ${theme.colors.tertiary};
      `}
    }
  `}
`;
