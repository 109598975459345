import { IconType } from 'react-icons'
import { FiMapPin as icon } from 'react-icons/fi'

import {
  LocationsList,
  LocationsNew,
  LocationsEdit,
  LocationsUsers,
  LocationsPayment,
  // LocationsRegions,
  SearchLocations
} from '~/pages/locations'
import accessControl from '~/utils/access-control'

export const locations: RootMenuItem<IconType> = {
  id: '5c6c0bef-8ac5-4a70-a727-d6fe4461a197', // rota-sem-id
  path: '/locations',
  name: 'Locais',
  pageTitle: 'Locais',
  icon,
  children: [
    {
      id: '42b3aabd-28a8-4ac6-8c74-011c75417d72', // rota-sem-id
      path: '/locations/list',
      name: 'Listagem de locais',
      pageTitle: 'Listagem de locais',
      component: LocationsList
    },
    {
      id: '43ea706f-2db6-4d5c-a980-6b10f50564de', // rota-sem-id
      path: '/locations/new',
      name: 'Criar local',
      pageTitle: 'Criar local',
      component: LocationsNew
      // component: () =>
      // accessControl({ role: 'admin', ComposedComponent: LocationsNew })
      // permissionType: 'admin'
    },
    {
      id: null, // rota-sem-id
      path: '/locations/edit/:id',
      name: 'Editar local',
      pageTitle: 'Editar local',
      hideMenu: true,
      component: LocationsEdit
      // component: () =>
      // accessControl({ role: 'admin', ComposedComponent: LocationsEdit })
      // permissionType: 'admin'
    },
    {
      id: null, // rota-sem-id
      path: '/locations/users/:id',
      name: 'Usuários do local',
      pageTitle: 'Usuários do local',
      hideMenu: true,
      component: LocationsUsers
      // component: () =>
      // accessControl({ role: 'admin', ComposedComponent: LocationsUsers })

      // permissionType: 'admin'
    },
    {
      id: null, // rota-sem-id
      path: '/locations/payment-methods/:id',
      name: 'Métodos de Pagamento',
      pageTitle: 'Métodos de Pagamento',
      hideMenu: true,
      component: LocationsPayment
      // component: () =>
      // accessControl({ role: 'admin', ComposedComponent: LocationsPayment })
      // permissionType: 'admin'
    },
    {
      id: '3c2e2f94-03c8-4b6f-a61d-fe69d8724260', // rota-sem-id
      path: '/locations/search-locations',
      name: 'Encontrar locais próximos',
      pageTitle: 'Encontrar locais próximos',
      component: SearchLocations
    }
  ]
}
