import { Draft } from '@reduxjs/toolkit'
import { produce } from 'immer'

import * as types from './constants'

type Payload = GenericOldFilters &
  GenericQueryFilter & {
    type: GenericFiltersKeys
    data: FilterValueOption
    keyState: string
  }

export default function filters(
  state = types.INITIAL_STATE,
  action: ActionGeneric<Payload>
) {
  return produce(state, (draft: Draft<typeof types.INITIAL_STATE>) => {
    switch (action.type) {
      /**
       * =====> NOVA ESTRUTURA DE ACTION <=====
       * Fazer as novas nesse formato
       */

      // Store generic filter {value, label}
      case types.SET_FILTER: {
        const { type = 'filter', data } = action.payload

        const [mainKey, childKey = null] = type.split('.') as [
          mainKey: GenericFiltersKeys,
          childKey: string | null
        ]

        if (childKey) {
          const oldState = draft[mainKey]

          draft[mainKey] = { ...oldState, [childKey]: data }

          break
        }

        draft[type] = data
        break
      }

      /**
       * =====> Antiga ESTRUTURA DE ACTION <=====
       * Dar preferência ao formato a cima
       */

      // Store query = String for free search
      case types.SET_QUERY: {
        const { keyState: type, query } = action.payload

        const [mainKey, childKey = null] = type?.split('.') as [
          mainKey: FiltersKeys,
          childKey: string | null
        ]

        if (childKey) {
          const oldState = draft[mainKey]

          draft[mainKey] = { ...oldState, [childKey]: query[type] }

          break
        }

        draft.query = { ...draft.query, ...query }
        break
      }

      // Store filtered status bot {value, label}
      case types.SET_FILTERED_SHOPPING_CART_STATUS: {
        draft.shoppingCartStatus = action.payload.shoppingCartStatus
        break
      }

      // Store filtered shopping cart status {value, label}
      case types.SET_FILTERED_chatBotStatus: {
        draft.chatBotStatus = action.payload.chatBotStatus
        break
      }

      case types.SET_FILTERED_filterChatAgent: {
        draft.filterChatAgent = action.payload.filteredAgent
        break
      }

      // Store filtered chat tags {value, label}
      case types.SET_FILTERED_CHATTAGS: {
        draft.chatTags = action.payload.chatTags
        break
      }

      // Store filtered locations {value, label}
      case types.SET_FILTERED_LOCATIONS: {
        draft.locations = action.payload.locations
        break
      }

      // Store filtered location regions {value, label}
      case types.SET_LOCATION_REGION: {
        draft.locationRegions = action.payload.locationRegions
        break
      }

      // Store filtered states {value, label}
      case types.SET_FILTERED_STATES: {
        draft.states = action.payload.states
        break
      }

      // Store filtered channels {value, label}
      case types.SET_FILTERED_CHANNEL: {
        draft.channel = action.payload.channel
        break
      }

      // Store filtered user role {value, label}
      case types.SET_FILTERED_USER_ROLE: {
        draft.userRoles = action.payload.userRoles
        break
      }

      // Store filtered status {value, label}
      case types.SET_FILTERED_STATUS: {
        draft.status = action.payload.status
        break
      }

      case types.SET_FILTERED_STORE_TYPE: {
        draft.locationType = action.payload.locationType
        break
      }

      case types.SET_FILTERED_LOCATION_STATUS: {
        draft.locationStatus = action.payload.locationStatus
        break
      }

      case types.SET_FILTERED_TEMPLATE_STATUS: {
        draft.templateStatus = action.payload.status
        break
      }

      case types.SET_FILTERED_CAMPAIGN_STATUS: {
        draft.campaignStatus = action.payload.status
        break
      }

      case types.SET_FILTERED_PROMOTIONAL_STATUS: {
        draft.promotionalStatus = action.payload.status
        break
      }

      case types.SET_FILTERED_PROMOTIONAL_TYPE: {
        draft.promotionalType = action.payload.promotionalType
        break
      }

      // Store filtered payment status {value, label}
      case types.SET_FILTERED_PAYMENT_STATUS: {
        draft.paymentStatus = action.payload.paymentStatus
        break
      }

      // Store filtered payment method {value, label}
      case types.SET_FILTERED_PAYMENT_METHOD: {
        draft.paymentMethod = action.payload.paymentMethod
        break
      }

      // Reset filters
      case types.RESET_FILTERS: {
        draft.locations = null
        draft.chatTags = null
        draft.channel = null
        draft.status = null
        draft.locationType = null
        draft.locationStatus = null
        draft.userRoles = null
        draft.paymentStatus = null
        draft.shoppingCartStatus = null
        draft.query = null
        break
      }

      case types.RESET_FILTER_LOCATION: {
        draft.locations = null
        break
      }

      case types.RESET_FILTER_LOCATION_REGION: {
        draft.locationRegions = null
        break
      }

      default:
    }
  })
}
