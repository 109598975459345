const prefix = '@retailMedia/report'

export const CAMPAIGNS_GET_REPORT = `${prefix}/GET_REPORT`
export const CAMPAIGNS_GET_REPORT_REALTIME = `${prefix}/GET_REPORT_REALTIME`
export const CAMPAIGNS_GET_REPORT_HISTORY = `${prefix}/GET_REPORT_HISTORY`

export const types = {
  get: CAMPAIGNS_GET_REPORT,
  getRealtime: CAMPAIGNS_GET_REPORT_REALTIME,
  getHistory: CAMPAIGNS_GET_REPORT_HISTORY
} as const
