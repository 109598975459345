import { useEffect, useMemo } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { t } from 'i18next'

import { ButtonNavLink } from '~/components/Buttons'
import { DatepickerHeader } from '~/components/datepicker-header'
import HeaderPages from '~/components/HeaderPages'
import { LogoStore } from '~/components/LogoStore'
import PageTitle from '~/components/PageTitle'
import {
  getRetailMediaAccountType,
  isReadOnly,
  requestRetailMediaAccount
} from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import CampaignTable from '~/modules/retailMedia/components/_tables/CampaignTable'
import DashboardRetailMediaCampaigns from '~/modules/retailMedia/components/DashboardRetailMediaCampaigns'
import MainTabs from '~/modules/retailMedia/components/MainTabs'
import { useAppSelector } from '~/store/hooks'

import BalanceByPublisher from '../../../components/BalanceByPublisher'
import { getAccountInfoAction } from '../../../store/accountInfo/actions'
import Filters from './FilterHeader'

import * as S from './styles'

const RetailMediaCampaignsDashboard = () => {
  const searchParams = useQueryParams()
  const id = searchParams.get('rmid')

  const accountType = getRetailMediaAccountType()

  const isAdvertiser = accountType === 'advertiser'

  /**
   * Redux pra bicar depois
   * Pegando informação da conta vículo para usar no header
   */
  const dispatch = useDispatch()

  const {
    accountInfo: {
      view: { data, loading }
    }
  } = useAppSelector(state => state.retailMedia)

  useEffect(() => {
    if (id) {
      dispatch(
        getAccountInfoAction({
          id,
          params: { account_info: true },
          accountType
        })
      )
    }
  }, [accountType, dispatch, id])

  /**
   * Consts
   */

  const pageTitle = useMemo(
    () =>
      id
        ? `${data?.name || t('rm:Loading')} - ${t('rm:Campaigns')} - Retail Media`
        : `Retail Media - ${t('rm:CampaignsDashBoard')}`,
    [data?.name, id]
  )

  const createLinkParam = useMemo(() => (id ? `?rmid=${id} ` : ''), [id])

  /**
   * Memos
   */
  const insideProps = useMemo(() => {
    if (!id) {
      return {}
    }

    const labelByAccountType = isAdvertiser ? 'publishers' : t('rm:advertisers')

    return {
      titleSideRightComponent: (
        <>
          {!loading && (
            <LogoStore
              size="large"
              linkLogo={data?.account_logo}
              alt={data?.name}
              backgroundLogo={
                data?.account_theme?.header_color || 'transparent'
              }
            />
          )}
        </>
      ),
      subtitle: (
        <BalanceByPublisher
          id={id}
          idAccountType={isAdvertiser ? 'industry' : 'grocery'}
        />
      ),
      labelBreadcrumb: t('rm:BackToDashBoard', { title: labelByAccountType }),
      backTo: `/retail-media/${requestRetailMediaAccount}s`
    }
  }, [
    data?.account_logo,
    data?.account_theme?.header_color,
    data?.name,
    id,
    isAdvertiser,
    loading
  ])

  return (
    <>
      <PageTitle title={pageTitle} />

      <HeaderPages
        title={id ? data?.name : pageTitle}
        className="has-datepicker"
        sideRightComponent={
          <S.WrapperHeader>
            {isAdvertiser && !isReadOnly() && (
              <ButtonNavLink
                to={`/retail-media/campaign/create${createLinkParam}`}
                text={t('rm:createCampaign')}
                iconLeft={<FiPlus />}
              />
            )}
            <DatepickerHeader />
          </S.WrapperHeader>
        }
        {...insideProps}
      />

      <MainTabs />

      <Filters />

      <DashboardRetailMediaCampaigns origin="campaign" />

      <CampaignTable />
    </>
  )
}

export default RetailMediaCampaignsDashboard
