import styled, { DefaultTheme, css } from 'styled-components'

type CardTemplates = 'default' | 'light'

type ContainerProps = {
  minHeight?: string
  template: CardTemplates
}

const containerModifiers = {
  default: (theme: DefaultTheme) => css`
    --backgroundColor: ${theme.colors.white};
    --borderColor: ${theme.colors.neutralHigh};
    --textColor: ${theme.colors.neutralColorDark};
  `,
  light: (theme: DefaultTheme) => css`
    --backgroundColor: ${theme.colors.neutral.high.lightest};
    --borderColor: ${theme.colors.neutral.high.medium};
    --textColor: ${theme.colors.neutral.low.pure};
  `
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, minHeight = '137px', template }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    min-height: ${minHeight};

    background-color: var(--backgroundColor);
    border: 1px solid var(--borderColor);
    border-radius: 0.5rem;

    margin-bottom: 1rem;
    padding: ${theme.spacings.medium};

    .featured-text {
      font-weight: 700;
      font-size: ${theme.font.sizes.xxlarge};
      color: var(--textColor);
      margin: 0.75rem 0 0.625rem 0;
    }

    ${containerModifiers[template](theme)}
  `}
`

export const WrapperTitileAndTooltip = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    line-height: 1;

    .title {
      color: var(--textColor);
      font-weight: 600;
      font-size: ${theme.font.sizes.medium};
    }

    svg {
      color: ${theme.colors.neutralHigh};
      font-size: ${theme.font.sizes.medium};
    }
  `}
`
