import { handleSortDirection } from '~/helpers/sortData'
import { formatListCampaign } from '~/modules/retailMedia/dtos/common/campaign/list'

import { campaignList as C } from '../constants'

const index = {
  pending: `${C.list}_PENDING`,
  fulfilled: `${C.list}_FULFILLED`,
  rejected: `${C.list}_REJECTED`,
  setPage: C.page,
  setPerPage: C.perPage,
  sort: C.sort
}

const parentKey = 'campaign'
const key = 'list'

export const list = {
  [index.pending]: ({ draft }: ActionData) => {
    draft.campaign.list.loading = true
    draft.campaign.list.error = null
  },
  [index.fulfilled]: ({ draft, action }: ActionData) => {
    draft.campaign.list.data = formatListCampaign(action.payload.data)
    draft.campaign.list.pages = action.payload.pages
    draft.campaign.list.total = action.payload.total
    draft.campaign.list.page = action.payload.page

    draft.campaign.list.loading = false
    draft.campaign.list.error = null
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft.campaign.list.loading = false
    draft.campaign.list.error = action.payload
  },
  [index.setPage]: ({ draft, action }: ActionData) => {
    draft.campaign.list.page = action.payload
  },
  [index.setPerPage]: ({ draft, action }: ActionData) => {
    draft.campaign.list.perPage = action.payload
  },
  [index.sort]: ({ draft, action }: ActionData<OnSortProps>) => {
    const selectedKey = action.payload.key
    const sortInitialDirection = action.payload?.sortInitialDirection

    const currentSortDirection =
      draft[parentKey][key].sortDirection ||
      draft[parentKey][key].sortDirectionDefault

    const currentSortKey =
      draft[parentKey][key].sortKey || draft[parentKey][key].sortKeyDefault

    const { sortDirection, sortKey } = handleSortDirection({
      selectedKey,
      currentSortDirection,
      currentSortKey,
      sortInitialDirection
    })

    draft[parentKey][key].sortDirection = sortDirection
    draft[parentKey][key].sortKey = sortKey
  }
}
