import { useRef } from 'react'
import { useDispatch } from 'react-redux'

import { t } from 'i18next'

import Button from '~/components/Button'
import Form from '~/components/forms/form'
import Input from '~/components/forms/inputs'
import PopoverIcon from '~/components/popover-icon'
import { requiredValidation } from '~/helpers'
import { useAppSelector } from '~/store/hooks'

import { editUserRole } from '../../store/actions'

const EditPermission = ({ data }) => {
  const popoverPermissionRef = useRef()

  const dispatch = useDispatch()

  const submitEdit = (formData, id) => {
    const newData = { ...formData, user_id: id }
    dispatch(editUserRole(newData))
  }

  const { loadingEditUser } = useAppSelector(({ user }) => user)

  return (
    <PopoverIcon
      target={`permission-${data.id}`}
      ref={popoverPermissionRef}
      title={t('users:editPermission')}
      data-cy="edit-permission"
    >
      <Form onSubmit={formData => submitEdit(formData, data.id)}>
        <Input
          name="role"
          label={t('users:permission')}
          type="select"
          options={['admin', 'user', 'read_only', 'guest', 'agent', 'carrier']}
          validate={[requiredValidation]}
          defaultValue={data.role}
        />
        <Button
          color="tertiary"
          size="md"
          text={t('common:actions.cancel')}
          width="100px"
          className="btn-cancel btn-outline-gray"
          onClick={() => popoverPermissionRef.current.closePopover()}
        />
        <Button
          color="primary"
          size="md"
          text={t('common:actions.save')}
          loading={loadingEditUser}
          width="130px"
          spinner={{ type: 'grow', size: 'sm', color: 'secondary' }}
          type="submit"
        />
      </Form>
    </PopoverIcon>
  )
}

export default EditPermission
