import {
  formatDateToStringIgnoreUTC,
  formatNumberWithDots,
  formatPercent
} from '~/helpers'

const defaultValues = {
  viewNumber: [] as number[],
  impressionsNumber: [] as number[],
  clicksNumber: [] as number[],
  conversionNumber: [] as number[],
  incomeNumber: [] as number[],
  totalSpentNumber: [] as number[],
  view: [],
  clicks: [],
  date: [],
  conversion: [],
  conversion_rate: [],
  ctr: [],
  roas: [],
  income: []
}

export const formatIndustryReportHistoryCampaign = (
  data: GetCampaignHistoryReportResponse
) => {
  const formattedData = data.reduce(
    (acc, item) => {
      const {
        impressions,
        clicks,
        views,
        groupby,
        conversions,
        conversion_rate,
        ctr,
        roas,
        income,
        total_spent
      } = item

      const newData = {
        viewNumber: [...acc.viewNumber, views],
        impressionsNumber: [...acc.impressionsNumber, impressions],
        clicksNumber: [...acc.clicksNumber, clicks],
        conversionNumber: [...acc.conversionNumber, conversions],
        totalSpentNumber: [...acc.totalSpentNumber, Number(total_spent)],
        totalSpentNoScale: [...acc.totalSpentNumber, Number(total_spent)],
        incomeNumber: [...acc.incomeNumber, Number(income)],

        view: [...acc.view, formatNumberWithDots(impressions)],
        clicks: [...acc.clicks, formatNumberWithDots(clicks)],
        conversion: [...acc.conversion, formatNumberWithDots(conversions)],

        date: [...acc.date, formatDateToStringIgnoreUTC(groupby, 'DD/MM')],
        conversion_rate: [
          ...acc.conversion_rate,
          formatPercent(conversion_rate)
        ],
        ctr: [...acc.ctr, formatPercent(ctr)],
        roas: [...acc.roas, roas],
        income: [...acc.income, income]
      }

      return newData
    },
    { ...defaultValues }
  )

  return {
    raw: data.reverse(),
    _raw: {
      originPath:
        'src/modules/retailMedia/dtos/industry/campaign/reportHistory.ts'
    },
    ...formattedData
  } as ReportHistoryCampaigns
}

const defaultValuesRealtime = {
  viewNumber: [] as number[],
  impressionsNumber: [] as number[],
  impressionsNumberDiff: [] as number[],
  clicksNumber: [] as number[],
  conversionNumber: [] as number[],
  incomeNumber: [] as number[],
  totalSpentNumber: [] as number[],
  view: [] as string[],
  clicks: [] as string[],
  date: [] as string[],
  conversion: [] as string[],
  conversion_rate: [] as string[],
  ctr: [] as string[],
  roas: [] as string[],
  income: [] as string[],
  cpa: [] as string[],
  ecpm: [] as string[],
  avgCpc: [] as string[],
  avgCpm: [] as string[],
  totalCampaigns: [] as string[],
  balance: [] as string[],
  adCost: [] as string[],
  totalAdvertisers: [] as string[]
}

export const formatIndustryReportHistoryCampaignRealtime = (
  data: [GetCampaignsReportResponse & { groupby: string }],
  refreshTime = 1
) => {
  const formattedData = data.reduce(
    (acc, item, index) => {
      const {
        impressions,
        clicks,
        views,
        groupby,
        conversions,
        conversion_rate,
        ctr,
        roas,
        income,
        total_spent,
        ecpm,
        cpa,
        avg_cpc,
        avg_cpm,
        total_campaigns,
        balance,
        adcost,
        total_advertisers
      } = item

      const newData = {
        viewNumber: [...acc.viewNumber, views],
        impressionsNumber: [...acc.impressionsNumber, impressions],
        impressionsNumberDiff: [
          ...acc.impressionsNumberDiff,
          index === 0
            ? null
            : (impressions - (acc.impressionsNumber?.[index - 1] || 0)) /
              Number(refreshTime)
        ],
        clicksNumber: [...acc.clicksNumber, clicks],
        conversionNumber: [...acc.conversionNumber, conversions],
        totalSpentNumber: [...acc.totalSpentNumber, Number(total_spent)],
        totalSpentNoScale: [...acc.totalSpentNumber, Number(total_spent)],
        incomeNumber: [...acc.incomeNumber, Number(income)],

        view: [...acc.view, formatNumberWithDots(impressions)],
        clicks: [...acc.clicks, formatNumberWithDots(clicks)],
        conversion: [...acc.conversion, formatNumberWithDots(conversions)],

        date: [...acc.date, groupby],
        conversion_rate: [
          ...acc.conversion_rate,
          formatPercent(conversion_rate)
        ],
        ctr: [...acc.ctr, formatPercent(ctr)],
        roas: [...acc.roas, roas],
        income: [...acc.income, income],
        cpa: [...acc.cpa, cpa],
        ecpm: [...acc.ecpm, ecpm],
        avgCpc: [...acc.avgCpc, avg_cpc],
        avgCpm: [...acc.avgCpm, avg_cpm],
        totalCampaigns: [...acc.totalCampaigns, total_campaigns],
        balance: [...acc.balance, Number(balance)],
        adCost: [...acc.adCost, Number(adcost)],
        totalAdvertisers: [...acc.totalAdvertisers, total_advertisers]
      }

      return newData
    },
    { ...defaultValuesRealtime }
  )

  return {
    raw: data.reverse(),
    _raw: {
      originPath:
        'src/modules/retailMedia/dtos/industry/campaign/reportHistory.ts'
    },
    ...formattedData
  } as ReportHistoryCampaignsRealtime
}
