import React, { useState, useEffect, useContext } from 'react'
import InputMask from 'react-input-mask'
import { FormattedMessage } from 'react-intl'
import NumberFormat from 'react-number-format'
import {
  FormFeedback,
  FormGroup,
  Label,
  Input as InputReactstrap
} from 'reactstrap'

import PropTypes from 'prop-types'

import { masks } from '~/helpers'
import { formatMoneyInputs } from '~/helpers/format-number'

import { SetValueContext } from './form'

const Input = props => {
  const [errorMessage, setErrorMessage] = useState()
  const [value, setValue] = useState()

  const handleChangeForm = useContext(SetValueContext)

  useEffect(() => {
    onChange(props.defaultValue, true)
  }, [props.defaultValue])

  const validateData = (value, hideMessage) => {
    const { validate } = props
    let errors

    if (validate) {
      errors = validate.reduce((list, prop) => {
        const error = prop(value)
        if (error !== undefined) {
          list.push(error)
        }
        return list
      }, [])
    }

    if (!hideMessage) {
      setErrorMessage(Array.isArray(errors) ? errors[0] : undefined)
    }

    return Array.isArray(errors) && errors.length ? errors : undefined
  }

  const setFormChange = (data, errorBind) => {
    const error = validateData(data.value, errorBind)
    const newData = { ...data }

    if (error && error[0] !== '') {
      newData.error = error
    }

    if (handleChangeForm) {
      handleChangeForm(newData)
    }
  }

  const onChange = value => {
    const { name, onChange } = props

    const data = {
      name,
      value
    }

    setFormChange(data, true)

    if (onChange && value !== undefined) {
      onChange(data)
    }

    setValue(value)

    return false
  }

  const onBlur = () => {
    const { name, onBlur } = props

    const data = {
      name,
      value
    }

    if (onBlur && value !== undefined) {
      onBlur(data)
    }

    setFormChange(data)
  }

  const renderCheckbox = () => {
    const { hasError, className, label, id, name, controlId } = props
    const errorState = errorMessage || hasError

    return (
      <FormGroup
        className={`${className || ''} hold-input`}
        // validationState={errorState && 'error'}
        // controlId={id || name}
      >
        <Label className="label__checkbox">
          <InputReactstrap
            type="checkbox"
            onChange={e => onChange(e.target.checked)}
          />{' '}
          <span
            className="check_text"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </Label>
        {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
      </FormGroup>
    )
  }

  const renderSelect = () => {
    const {
      hasError,
      className,
      label,
      id,
      name,
      controlId,
      options = [],
      ...rest
    } = props
    const errorState = errorMessage || hasError
    return (
      <FormGroup
        className={`${className || ''} hold-input`}
        // validationState={errorState && 'error'}
        // controlId={id || name}
      >
        {label && <Label>{label}</Label>}
        <InputReactstrap
          {...rest}
          bsSize="lg"
          type="select"
          onChange={e => onChange(e.target.value)}
          name={name}
          id={id}
          className={className}
        >
          <option value="" disabled selected>
            Selecione um valor
          </option>
          {options.map(option => (
            <FormattedMessage id={option}>
              {message => <option value={option}>{message}</option>}
            </FormattedMessage>
          ))}
        </InputReactstrap>
        {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
      </FormGroup>
    )
  }

  const renderTextarea = () => {
    const {
      label,
      tooltip,
      className,
      helpText,
      hasError,
      onClickIconLeft,
      iconLeft,
      onClickIconRight,
      iconRight,
      id,
      name,
      mask,
      controlId,
      ...rest
    } = props
    const errorState = errorMessage || hasError
    return (
      <>
        {label && <Label>{label}</Label>}
        <FormGroup
          className={`${className} hold-input`}
          // validationState={errorState && 'error'}
          // controlId={id || name}
        >
          <textarea
            {...rest}
            value={value}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}
            className="form-control form-control-lg"
            name={name}
            id={id}
          />
          {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
        </FormGroup>
      </>
    )
  }

  const renderInputDefault = () => {
    const {
      label,
      id,
      name,
      type,
      tooltip,
      className,
      helpText,
      hasError,
      onClickIconLeft,
      iconLeft,
      onClickIconRight,
      iconRight,
      mask,
      showLength,
      validate,
      controlId,
      ...rest
    } = props
    const errorState = errorMessage || hasError

    return (
      <>
        {label && <Label>{label}</Label>}

        {showLength && rest.maxLength && (
          <span className="length">
            {value ? value.length : 0}/{rest.maxLength}
          </span>
        )}

        <FormGroup
          className={`${className} hold-input ${
            iconLeft ? 'has-icon-left' : ''
          }`}
          // validationState={errorState && 'error'}
          // controlId={id || name}
        >
          {iconLeft && (
            <span className="hold-input__icon-left" onClick={onClickIconLeft}>
              {iconLeft}
            </span>
          )}

          {type === 'money' ? (
            <NumberFormat
              format={formatMoneyInputs}
              id={id}
              name={name}
              value={value}
              onChange={e => onChange(e.target.value)}
              onBlur={onBlur}
              className="form-control form-control-lg"
              {...rest}
              type="text"
            />
          ) : (
            <InputMask
              {...rest}
              id={id}
              name={name}
              type={type}
              mask={masks[mask] || ''}
              maskChar=""
              value={value}
              onChange={e => onChange(e.target.value)}
              onBlur={onBlur}
              className="form-control form-control-lg"
            />
          )}

          {iconRight && (
            <span className="hold-input__icon-right" onClick={onClickIconRight}>
              {iconRight}
            </span>
          )}

          {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
        </FormGroup>
      </>
    )
  }

  const render = () => {
    switch (props.type) {
      case 'checkbox':
        return renderCheckbox()
      case 'select':
        return renderSelect()
      case 'textarea':
        return renderTextarea()
      default:
        return renderInputDefault()
    }
  }

  return <>{render()}</>
}

export default Input
