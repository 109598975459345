/* eslint-disable indent */
import { shade, transparentize } from 'polished'
import styled, { css } from 'styled-components'

import bgChat from '~/assets/img/elements/bg_chat.jpeg'

export const MessageContainer = styled.div`
  ${({ theme }) => css`
    position: sticky;
    top: 1rem;

    display: flex;
    flex-direction: column;

    .buttons {
      max-width: 480px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(49%, 49%));
      gap: 0.5rem;
      margin-top: 0.25rem;
      grid-auto-flow: dense;

      > *:nth-child(3n) {
        grid-column: span 2;
      }

      button {
        background-color: ${theme.colors.white};
        border-radius: ${theme.border.radius.large};
        padding: ${theme.spacings.tiny};
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
        text-align: center;
        color: ${theme.colors.info};
        height: 36px;
      }
    }
  `};
`

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium};
    border-radius: ${theme.border.radius.large};
    background: rgb(236, 234, 231);
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    > * {
      z-index: 1;
      width: 100%;

      + * {
        margin-top: 0.5rem;
      }
    }

    &:after {
      background: url(${bgChat});
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      mix-blend-mode: multiply;
      background-size: 21rem;
      opacity: 0.05;
      z-index: 0;
    }
  `}
`

export const Message = styled.div`
  ${({ theme, isUser }) => css`
    border-radius: ${isUser
      ? '0.5rem 0.5rem 0 0.5rem'
      : '0.5rem 0.5rem 0.5rem 0'};
    background-color: ${isUser ? theme.colors.primary : theme.colors.white};
    color: ${isUser ? theme.colors.white : theme.colors.dark};
    padding: ${theme.spacings.xxxsmall} ${theme.spacings.xxsmall};
    align-self: ${isUser ? 'flex-end' : 'flex-start'};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);

    width: 480px;
    max-width: 85%;

    & + & {
      margin-top: 0.5rem;
    }

    p {
      margin-bottom: 0;
      font-size: 0.875rem;
    }

    img {
      display: block;
      width: 100%;
      border-radius: ${theme.border.radius.medium};
    }

    code {
      color: ${theme.colors.dark};
    }

    > * + * {
      margin-top: 0.5rem;
    }
  `}
`

export const FilePreview = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 6rem;

    background: ${theme.colors.light};
    color: ${theme.colors.stormGray};
    border-radius: ${theme.border.radius.medium};

    display: flex;
    flex-direction: column;
    align-items: center;

    background: ${transparentize(0.94, shade(0.5, theme.colors.gray))};
    padding: 1rem 2rem;
    border-radius: 0.25rem;
    width: 100%;

    > .LoadingContainer {
      width: 100%;
    }

    > .icon {
      margin-bottom: 0.5rem;
      font-size: 2rem;
      line-height: 1;
      color: ${theme.colors.primary};
    }

    > .text {
      font-size: 0.875rem;
    }
  `}
`
