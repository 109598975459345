import styled from 'styled-components'

export const Container = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem;

  .buttonNavLink {
    text-decoration: underline;
  }
`
