import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const FiltersWrapper = styled.div`
  ${({ align, columns = 4 }) => css`
    display: grid;
    width: 100%;
    gap: 0.5rem;
    justify-content: ${align === 'right' ? 'flex-end' : 'flex-start'};

    padding: 0 0 0.5rem 0;

    --columns: ${columns};

    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

    @media ${device.laptopL} {
      grid-template-columns: repeat(var(--columns), minmax(220px, 30%));
    }

    .filterWrapper {
      @media ${device.tablet} {
        max-width: 100%;
      }

      z-index: 2;
      flex: 1;

      .SelectSearchable__control {
        height: 41px;
        z-index: 10;
      }

      .SelectSearchable__menu {
        z-index: 100;
      }
    }

    .searchWrapper {
      flex: 1;
    }

    .align__right {
      grid-column: 4;
      text-align: right;
    }

    .datePicker {
      z-index: unset;
      .datepicker__wrapper,
      .datepicker__button {
        width: 100%;
        height: 41px;
      }
    }
  `}
`
