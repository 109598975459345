import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    > header {
      &.headerSection {
        margin-bottom: 0.25rem;

        .icon {
          color: #3c3584;
          width: 1.25rem;
          font-size: 1.25rem;
          margin-right: 0.5rem;
        }

        .original_address {
          background: ${theme.colors.light};
          padding: 0.5rem;
          border-radius: 0.25rem;
          line-height: 1.25;
        }
      }
    }

    div {
      > span {
        margin: 0 0.25rem 0.25rem 0;
        display: inline-flex;
      }
    }
  `}
`
