import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/publisher`
const reportBaseURL = `${baseAPI}/report/v2/publishers`
export const reportPublishersBaseURL = `${baseAPI}/report/v2/publishers`

/**
 * LIST
 */
// export const list = () => Promise.resolve(listResponse)
export const list = (queries = {}) =>
  axios.get<ResponseListIndustryPublishers>(`${baseURL}`, {
    params: { ...queries }
  })

/**
 * LIST REPORT
 */
export const listReport = (queries = {}) =>
  axios.get(`${reportBaseURL}`, {
    params: { ...queries }
  })

/**
 * VIEW
 * PODE REMOVER - Já tem pasta própria /services/publisher
 */
export const get = ({ id, params = {} }: { id: string; params: object }) =>
  axios.get(`${baseURL}/${id}`, { params })

export default {
  list,
  listReport,
  get
}
