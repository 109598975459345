import React, { useCallback, useEffect } from 'react'
import { FiShoppingCart } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ButtonNavLink } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { useAlert } from '~/hooks/useAlert'
import { getCustomer } from '~/store/modules/customers/actions'

import FormCustomer from '../_elements/FormCustomer'

const CustomersEdit = () => {
  const dispatch = useDispatch()

  const { id } = useParams()

  const {
    getCustomersLoading: loading,
    error,
    customer
  } = useSelector(state => state.customers)

  const [AlertOnError] = useAlert(error)

  const loadCustomer = useCallback(() => {
    dispatch(getCustomer(id))
  }, [dispatch, id])

  useEffect(() => {
    loadCustomer()
  }, [loadCustomer])

  return (
    <>
      {/* Meta <title> */}
      <PageTitle title="Clientes > Editar" />

      <HeaderPages
        title="Editando clientes"
        backTo="/customers/list"
        labelBreadcrumb="Voltar aos clientes"
        sideRightComponent={
          <>
            <ButtonNavLink
              to={`/shopping-carts/generate/${customer?.id}`}
              text="Gerar carrinho para o cliente"
              iconLeft={<FiShoppingCart />}
              size="small"
            />
          </>
        }
      />

      <AlertOnError />

      <Loading status={loading}>Carregando informações do cliente</Loading>

      {!loading && <FormCustomer />}
    </>
  )
}

export default CustomersEdit
