import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useLayoutEffect
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useParams } from 'react-router-dom'

import { Form } from '@unform/web'
import axios from 'axios'
import PropTypes from 'prop-types'

import { Button, ButtonNavLink } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { FormGroup } from '~/components/unform'
import NameAndTimeInterval from '~/components/unform/FormGroup/NameAndTimeInterval'
import { handleValidationsErrors } from '~/helpers'
import {
  createPromotion,
  updatePromotion
} from '~/modules/promotional/store/promotion/actions'

import {
  defaultInitialData,
  formatInitialData
} from '../../models/formInitialData'
import ProductsPanel from '../ProductsPanel'
import PromotionCustomers from './PromotionCustomers'
import PromotionLocations from './PromotionLocations'
import PromotionRules from './PromotionRules'
import PromotionTargets from './PromotionTargets'
import PromotionTrigger from './PromotionTrigger'
import PromotionTypes from './PromotionTypes'
import { schemaYup } from './yup'

const FormPromotion = () => {
  const { id: promotionId } = useParams()

  const [loadingUploadImage, setLoadingUploadImage] = useState(false)
  const [shouldRemoveImage, setShouldRemoveImage] = useState(false)
  const [selectedTrigger, setSelectedTrigger] = useState()
  const [selectedTarget, setSelectedTarget] = useState()
  const [shouldShowSelectProduts, setShouldShowSelectProduts] = useState()

  const {
    promotion,
    loading,
    error,
    selectecProducts,
    loadingCreate,
    loadingSaving
  } = useSelector(state => state.promotional)

  const formRef = useRef(null)
  const dispatch = useDispatch()

  const isSaving = useMemo(
    () => loadingCreate || loadingSaving,
    [loadingCreate, loadingSaving]
  )

  const offerIds = selectecProducts.map(item => item.external_id)

  const handleUploadImage = async image => {
    setLoadingUploadImage(true)

    const dataMultipart = new FormData()

    dataMultipart.append('file', image)

    try {
      const response = await axios.post(`/api/promotion/upload`, dataMultipart)

      return response?.data?.imageUrl
    } catch (err) {
      console.error(err)

      toastr.error('Erro', 'Houve um erro ao enviar a imagem de capa.')
    } finally {
      setLoadingUploadImage(false)
    }
  }

  const handleSubmit = useCallback(
    async formData => {
      try {
        formRef.current?.setErrors({})

        delete formData.bulkProducts

        await schemaYup.validate(formData, {
          abortEarly: false
        })

        const requestData = { formData, offerIds, id: promotion?.id }

        if (promotion?.cover_url && shouldRemoveImage) {
          requestData.formData.cover_url = null
        }

        if (formData?.coverUrlUploadField) {
          const coverUrl = await handleUploadImage(
            formData?.coverUrlUploadField
          )

          requestData.formData.cover_url = coverUrl
        }

        const action = promotionId ? updatePromotion : createPromotion

        dispatch(action(requestData))
      } catch (err) {
        console.error({ err })

        handleValidationsErrors(err, formRef)
      }
    },
    [offerIds, promotion, shouldRemoveImage, promotionId, dispatch]
  )

  const handleChangeTrigger = useCallback(e => {
    const { value } = e.target

    setSelectedTrigger(value)
  }, [])

  const handleChangeTarget = useCallback(e => {
    const { value } = e.target

    setSelectedTarget(value)
  }, [])

  const handleChangeRules = useCallback(values => {
    const { totalValue = false, orderItems = false } = values

    setShouldShowSelectProduts(totalValue || orderItems)
  }, [])

  /**
   * Data
   */

  const labelButton = useMemo(
    () => (promotionId ? 'Salvar promoção' : 'Criar promoção'),
    [promotionId]
  )

  const labelButtonLoading = useMemo(() => {
    if (loadingUploadImage) {
      return 'Enviando imagem...'
    }

    return promotionId ? 'Salvando promoção' : 'Criando promoção'
  }, [promotionId, loadingUploadImage])

  /**
   * Form data
   */

  const formattedInitialData = useMemo(() => {
    if (!loading && promotion) {
      return formatInitialData(promotion)
    }

    return defaultInitialData
  }, [loading, promotion])

  useLayoutEffect(() => {
    if (formattedInitialData) {
      setSelectedTrigger(formattedInitialData.promotion_trigger)
      setSelectedTarget(formattedInitialData.promotion_target)
      handleChangeRules(formattedInitialData.promotionRules)
    }
    return () => {
      setSelectedTrigger(null)
      setSelectedTarget(null)
      setShouldShowSelectProduts(false)
    }
  }, [formattedInitialData, handleChangeRules])

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={formattedInitialData}
    >
      <Loading status={isSaving}>Criando promoção...</Loading>

      {/* Informações gerais */}
      <NameAndTimeInterval
        formRef={formRef}
        labelTitle="Nome da promoção"
        placeholderTitle="Digite o nome da promoção"
        hasDescription
        labelDescription="Descrição (opcional)"
        placeholderDescription="Digite a descrição da promoção"
        labelInterval="Período da promoção"
      />
      {/* <GeneralInfo  /> */}

      {/* Regras de ativação da promoção */}
      <PromotionTrigger
        isEdit={!!promotionId}
        onChange={handleChangeTrigger}
        initialData={formattedInitialData}
      />

      {/* Mecânica da promoção */}
      <PromotionTypes
        quantitySelected={formattedInitialData?.trigger?.quantity}
        selectedTrigger={selectedTrigger}
        typeSelected={formattedInitialData?.promotion_type}
        isEdit={!!promotionId}
      />

      {/* Regras da promoção */}
      {selectedTrigger === 'coupon' && (
        <PromotionRules
          initialData={formattedInitialData}
          selectedTarget={selectedTarget}
          onChange={handleChangeRules}
        />
      )}

      {/* Regras de produtos alvos */}
      {selectedTrigger === 'coupon' && shouldShowSelectProduts && (
        <PromotionTargets
          isEdit={!!promotionId}
          onChange={handleChangeTarget}
          initialData={formattedInitialData}
        />
      )}

      {/* Produtos */}
      {(selectedTrigger === 'auto' || selectedTarget === 'selected') && (
        <ProductsPanel formRef={formRef} isEdit={!!promotionId} />
      )}

      {/* Regras de elegibilidade de local */}
      <PromotionLocations />

      {/* Regras de elegibilidade de cliente */}
      {selectedTrigger === 'coupon' && (
        <PromotionCustomers
          initialData={formattedInitialData}
          isEdit={!!promotionId}
        />
      )}

      {/* WhatsApp fidelidade */}
      {/* <PromotionLoyalty
        isEdit={!!promotionId}
        initialData={initialData}
        onRemoveCoverImage={handleRemoveCoverImage}
      /> */}

      <Loading status={isSaving || loadingUploadImage}>
        {labelButtonLoading}
      </Loading>

      <FormGroup className="actionButtons form-default__buttons">
        <ButtonNavLink
          to={`/promotional/view/${promotionId}`}
          text="Cancelar"
          template="transparentDanger"
        />
        <Button
          text={labelButton}
          textLoading={labelButtonLoading}
          type="submit"
          disabled={isSaving}
          template="success"
        />
      </FormGroup>
    </Form>
  )
}

export default FormPromotion

FormPromotion.defaultProps = {
  initialData: null
}

FormPromotion.propTypes = {
  initialData: PropTypes.shape({
    id: PropTypes.string,
    template: PropTypes.string,
    cover_url: PropTypes.string,
    promotion_trigger: PropTypes.string,
    promotion_type: PropTypes.string,
    is_loyalty_promotion: PropTypes.bool,
    trigger: PropTypes.shape({
      quantity: PropTypes.number
    })
  })
}
