import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/ad`

export const patch = ({
  body,
  id
}: {
  id: string
  body: { active: boolean }
}) => axios.patch(`${baseURL}/${id}`, body)

export const cpcPatch = ({
  body,
  id
}: {
  id: string
  body: { cpc: number | null }
}) => axios.patch(`${baseURL}/${id}/cpc`, body)

export const cpmPatch = ({
  body,
  id
}: {
  id: string
  body: { cpm: number | null }
}) => axios.patch(`${baseURL}/${id}/cpm`, body)

/**
 *
 */

export const getMinCosts = ({ body }: GetMinCostsRequestParams) =>
  axios.post<GetMinCostsResponse>(`${baseURL}/min-costs`, body)

export default {
  baseURL,
  patch,
  cpcPatch,
  cpmPatch,
  getMinCosts
}
