import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'

import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { history } from '~/helpers'
import { createPlacementService } from '~/modules/retailMedia/services/placements'

import { bodyCreatePlacementDTO } from './dtos/bodyCreatePlacement'
import PlacementForm from '../components/PlacementForm'

const PlacementsList: React.FC = () => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const handleSubmit = useCallback(async (formData: FormDataPlacement) => {
    setLoading(true)

    try {
      await createPlacementService(bodyCreatePlacementDTO(formData))

      toastr.success(
        t('rm:placements.create.messages.success.title'),
        t('rm:placements.create.messages.success.message')
      )

      history.push('/retail-media/placements')
    } catch (error) {
      console.error(error)

      toastr.error(
        t('common:messages.errorOccurred.title'),
        t('common:messages.errorOccurred.message')
      )
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <>
      <PageTitle title={t('rm:placements.create.pageTitle')} />

      <HeaderPages
        title={t('rm:placements.create.pageTitle')}
        backTo="/retail-media/placements"
        labelBreadcrumb={t('rm:placements.list.backTo')}
      />

      <PlacementForm onSubmit={handleSubmit} isLoading={loading} />
    </>
  )
}

export default PlacementsList
