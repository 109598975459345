import React, { useMemo } from 'react'
import { FiAlertTriangle, FiCheckCircle, FiX } from 'react-icons/fi'

import { Button } from '~/components/Buttons'

import { useLogisticSlots } from '../../useLogisticSlots'
import SlotConfig from '../SlotConfig'

import * as S from './styles'

const DeleteModal = () => {
  const {
    isWeekday,
    isWithoutWorkDates,
    deleteModal,
    deleteConfig,
    handleWeekdayWithoutSlot
  } = useLogisticSlots()

  const buttonActions = useMemo(
    () => ({
      delete: deleteConfig,
      weekdayWithoutWork: handleWeekdayWithoutSlot
    }),
    [deleteConfig, handleWeekdayWithoutSlot]
  )

  const handleButtonsAction = buttonActions[deleteModal?.key || 'delete']

  return (
    <>
      {!!deleteModal && (
        <S.InnerModal>
          <div className="wrapper">
            <div className="iconHeader delete">
              <FiAlertTriangle />
            </div>

            <div className="title">
              <h3>{deleteModal.title}</h3>

              <div className="description">{deleteModal.descriptionModal}</div>
            </div>

            {(deleteModal.startSlot || deleteModal.weekday) && (
              <div className="slotDetails">
                <div className="config">
                  <div className="day">
                    <strong>Dia:</strong>{' '}
                    {isWeekday ? deleteModal.weekday : deleteModal.date}
                  </div>

                  {!!deleteModal.startSlot && (
                    <div className="startHour">
                      <strong>Horário de início:</strong>{' '}
                      {deleteModal.startSlot}
                    </div>
                  )}

                  {!!deleteModal.repeat && (
                    <div className="repeat">
                      <strong>Repetições:</strong> {deleteModal.repeat}
                    </div>
                  )}
                </div>

                <div className="slots">
                  <SlotConfig data={deleteModal} template="columns" />
                </div>
              </div>
            )}

            <div className="actions">
              <Button
                template="transparentDanger"
                text="Cancelar"
                iconLeft={<FiX />}
                onClick={() =>
                  handleButtonsAction({
                    callbackAction: deleteModal?.callbackAction
                  })
                }
              />

              <Button
                template="success"
                text="Confirmar"
                iconRight={<FiCheckCircle />}
                onClick={() =>
                  handleButtonsAction({
                    config: deleteModal,
                    confirmed: true,
                    dayOnly: isWithoutWorkDates,
                    callbackAction: deleteModal?.callbackAction
                  })
                }
              />
            </div>
          </div>
        </S.InnerModal>
      )}
    </>
  )
}

export default DeleteModal
