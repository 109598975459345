import React from 'react'
import { FiArchive } from 'react-icons/fi'

import { getRole } from '~/helpers'

const userRole = getRole()
const isAdminUser = userRole === 'admin'

export const chatTabs: ChatTabs = [
  {
    type: 'in_contact',
    title: 'Ver conversas ativas',
    label: isAdminUser ? 'Em atendimento' : 'Meus atendimentos'
  },
  {
    type: 'waiting_contact',
    title: 'Ver conversas na fila',
    label: 'Aguardando atendimento'
  },
  {
    type: 'available',
    title: 'Ver todas conversas ativa',
    label: 'Todas'
  },
  {
    type: 'finished',
    title: 'Ver conversas arquivadas',
    label: <FiArchive />
  }
]
