import React from 'react';

import PropTypes from 'prop-types';

import { InputNumber } from '~/components/unform';

const LimitsTotal = ({ active }) => (
  <>
    Limitar a{' '}
    <InputNumber
      name="limits.total"
      placeholder="Digite o valor"
      required
      disabled={!active}
      readOnly={!active}
    />{' '}
    vezes que promoção pode ser usada no total
  </>
);

export default LimitsTotal;

LimitsTotal.propTypes = {
  active: PropTypes.bool.isRequired,
};
