import { getDecodedToken, history } from '~/helpers'
import { setLoggedIn, setAccountId } from '~/modules/auth/store/login/actions'
import {
  createUser as createUserService,
  createAccount as createAccountService,
  createIntegration as createIntegrationService,
  getOnboardingStep,
  skipIntegration as skipIntegrationService
} from '~/services/signup-service'

import * as types from './sign-up-constants'

export const changeStep = step => dispatch => {
  dispatch({
    type: types.SIGN_UP_CHANGE_STEP,
    payload: step
  })
}

export const createUser = data => dispatch => {
  dispatch({
    type: types.SIGN_UP_CREATE_USER,
    payload: createUserService(data),
    onSuccess: resp => {
      dispatch(changeStep(2))
      dispatch(setLoggedIn(resp.data.user.token))
    }
  })
}

export const createAccount = data => dispatch => {
  const { user_id } = getDecodedToken()
  const newData = { ...data, user_id }

  dispatch({
    type: types.SIGN_UP_CREATE_ACCOUNT,
    payload: createAccountService(newData),
    onSuccess: resp => {
      dispatch(changeStep(3))
      dispatch(setLoggedIn(resp.data.token.newToken))
      dispatch(setAccountId(resp.data.account.id))
    }
  })
}

export const createIntegration = data => dispatch =>
  dispatch({
    type: types.SIGN_UP_CREATE_INTEGRATION,
    payload: createIntegrationService(data)
  })

export const skipIntegration = () => (dispatch, getState) => {
  const { accountId } = getState().signin

  dispatch({
    type: types.SIGN_UP_SKIP_INTEGRATION,
    payload: skipIntegrationService({ account_id: accountId }),
    onSuccess: () => {
      history.push('/')
    }
  })
}

export const onboardingStep = accountId => dispatch =>
  dispatch({
    type: 'teste',
    payload: getOnboardingStep(accountId)
  })

export const checkUserHasAccount =
  shouldChangeRoute => (dispatch, getState) => {
    const { accountId, userId } = getState().signin
    if (!userId) {
      dispatch(changeStep(1))
    } else if (!accountId) {
      dispatch(changeStep(2))
      // shouldChangeRoute && history.push('/sign-up');
    } else if (accountId && userId) {
      dispatch(changeStep(3))
    }
  }
