import { types } from '../constants'

const index = {
  pending: `${types.cpc_patch}_PENDING`,
  fulfilled: `${types.cpc_patch}_FULFILLED`,
  rejected: `${types.cpc_patch}_REJECTED`
}

export const cpcPatch = {
  [index.pending]: ({ draft }) => {
    draft.industry.ad.cpcPatch.loading = true
    draft.industry.ad.cpcPatch.error = null
  },
  [index.fulfilled]: ({ draft, action }: ActionData) => {
    const values = action.values

    const oldItemCampaign = draft.campaign.item.data.ads.data

    const updatedAds = oldItemCampaign.map(ad => {
      if (ad.id === values.id) {
        return {
          ...ad,
          settings: {
            ...ad.settings,
            cpc: values.body.cpc
          }
        }
      } else {
        return ad
      }
    })

    draft.campaign.item.data.ads.data = updatedAds

    draft.industry.ad.cpcPatch.loading = false
    draft.industry.ad.cpcPatch.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.ad.cpcPatch.loading = false
    draft.industry.ad.cpcPatch.error = action.payload
  }
}
