import { FiBarChart2 as icon } from 'react-icons/fi'

import * as Pages from '~/modules/industry/sellOut/pages'

export const sellout = {
  id: '2bfa9f6b-8de0-418c-9094-dac2f3c03494',
  path: '/sell-out',
  name: 'Vendas',
  pageTitle: 'Relatório de vendas',
  icon,
  contentFullWidth: true,
  component: Pages.Dashboard,
  children: null,
  containsHome: true
}
