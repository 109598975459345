import styled, { css } from 'styled-components';

import { device } from '~/styles/device';

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 0.5rem;
    background-color: ${theme.colors.white};
    width: max-content;
    z-index: 1;
    box-shadow: 0.5rem 4px 4rem -2rem rgb(24 21 60 / 50%),
      -1.5rem 4px 5rem -1.5rem rgb(24 21 60 / 30%);
    border-radius: ${theme.border.radius.medium};
    padding: 0;

    transform: translate(-4rem, -50%);
    transition: 0.3s ease-in-out all;

    @media ${device.laptop_max} {
      top: 0;
      right: 2rem;
      transform: translate(0, -0.125rem);
    }

    &:after {
      content: '';
      width: 2rem;
      height: 2rem;
      border-radius: 0.5rem;
      background: white;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translate(38%, -50%) rotate(45deg);
      z-index: -1;

      @media ${device.laptop_max} {
        top: 0;
        right: 1rem;
        transform: translate(1.5rem, 0.25rem) rotate(45deg);
      }
    }

    button,
    a {
      display: flex;
      align-items: center;
      font-size: ${theme.font.sizes.medium};
      padding: 1rem;
      line-height: 1;
      transition: 0.3s ease-in-out all;
      z-index: 2;
      position: relative;
      background: ${theme.colors.white};

      &:hover {
        background: ${theme.colors.light};
      }

      svg {
        margin-right: ${theme.spacings.xxsmall};
        color: ${theme.colors.primary};
        height: 24px;
        width: 24px;
      }

      & + * {
        border-top: 1px solid #f5f5f6;
      }

      &.success {
        svg {
          color: ${theme.colors.success};
        }
      }
      &.danger {
        svg {
          color: ${theme.colors.danger};
        }
      }
      &.warning {
        svg {
          color: ${theme.colors.warning};
        }
      }
    }
  `}
`;

export const Container = styled.div`
  position: relative;
`;
