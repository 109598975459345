import { formatStringDateToTimezone } from '~/helpers';

export const modelFlyerCreate = ({ formData }) => {
  const {
    start_date,
    start_hour = '00:00',
    end_date,
    end_hour = '23:59',
    location_ids: locationsId,
    locationsCheck,
    messages,
    ...rest
  } = formData;

  const filteredLocations = () =>
    Object.keys(locationsId)
      .filter(item => locationsId[item])
      .map(item => item);

  const location_ids = locationsCheck === 'all' ? [] : filteredLocations();

  const start_at = formatStringDateToTimezone(`${start_date} ${start_hour}`);
  const end_at = formatStringDateToTimezone(`${end_date} ${end_hour}`);

  delete rest.duration;
  delete rest.mediaFile;

  const { text = null, media } = messages[0];

  const body = {
    start_at,
    end_at,
    location_ids,
    message: text.replace(/<br\s*\/?>/g, '\n'),
    active: true,
    media_url: media.url,
    media_type: media.type,
    media_name: media.name,
    ...rest,
  };

  return { ...body };
};
