/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAlert } from '~/hooks/useAlert';
import { submitFormProduct } from '~/store/modules/products/actions';

import Forms from './forms';
import ContainerForm from './forms/container';

/**
 * O cadastro está feito por módulo e esse elemento é o pai de todos.
 * Ele gerencia:
 * - renderização de cada módulo (./forms)
 * - submit de cada filho
 * - submit final
 * - navegação entre os passos
 * - alertas de erro e sucesso
 */

const FormProduct = () => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});

  const isLastStep = useMemo(() => Forms.length === step, [step]);

  const { error, loading } = useSelector(state => state.products);

  const dispatch = useDispatch();

  const [AlertOnError] = useAlert(error);

  // Gerenciando o submit final do cadastro
  const handleMasterSubmit = useCallback(() => {
    const maindData = { category_id: 0, ...data.main };

    dispatch(submitFormProduct(maindData));
  }, [data.main, dispatch]);

  // Se for o último passo, enviará os dados para a API
  useEffect(() => {
    if (isLastStep) {
      handleMasterSubmit();
    }
  }, [handleMasterSubmit, isLastStep]);

  // Gerenciando o submit de cada módulo
  const handleSubmit = formData => {
    const { main, ...rest } = formData;

    const newData = {
      ...data,
      main: { ...data.main, ...main },
      ...rest,
    };

    setData(newData);
  };

  return (
    <>
      {Forms.map((child, index) => (
        <ContainerForm
          key={index}
          slug={child.slug}
          step={index}
          currentStep={step}
          isActive={step === index}
          title={child.title}
          Child={child.component}
          onNav={setStep}
          onSubmitStep={handleSubmit}
          loading={loading}
          isLastStep={index + 1 === Forms.length}
        />
      ))}

      <AlertOnError />
    </>
  );
};

export default FormProduct;
