import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '~/components/Alert';
import HeaderPages from '~/components/HeaderPages';
import Loading from '~/components/Loading';
import PageTitle from '~/components/PageTitle';
import { history } from '~/helpers';
import useQuery from '~/hooks/useQuery';
import FormFlyer from '~/modules/flyers/components/Form';
import {
  clearFlyerStore,
  getFlyer,
} from '~/modules/flyers/store/flyers/actions';

const FlyerCreate = () => {
  // Handle duplicate mode
  const query = useQuery();
  const duplicate = query.get('duplicate');

  const pageTitle = useMemo(
    () => (duplicate ? 'Duplicar' : 'Criar'),
    [duplicate],
  );

  // Redux
  const dispatch = useDispatch();

  const {
    data: flyer,
    loading,
    error,
  } = useSelector(({ promotionFlyer }) => promotionFlyer.flyers.view);

  /**
   * Handle cache data
   */

  // Essse estado serve para evitarmos de o formulário vir preenchido com dados errados.
  const [formIsReady, setFormIsReady] = useState(false);

  useEffect(() => {
    if (!duplicate && !flyer) {
      setFormIsReady(true);
      return;
    }

    if (!duplicate && flyer) {
      dispatch(clearFlyerStore());
      setFormIsReady(false);
      return;
    }

    if (!!duplicate && flyer?.id === duplicate) {
      setFormIsReady(true);
      return;
    }

    if (!!duplicate && flyer && flyer?.id !== duplicate) {
      dispatch(clearFlyerStore());
      setFormIsReady(false);
      return;
    }

    if (!!duplicate && !flyer) {
      dispatch(getFlyer({ id: duplicate }));
    }
  }, [dispatch, duplicate, flyer]);

  return (
    <>
      <PageTitle title={`Encartes > ${pageTitle}`} />

      <HeaderPages
        title={`${pageTitle} encarte`}
        goBack={() => history.back()}
        labelBreadcrumb="Voltar"
      />

      <Alert active={error}>Erro: {error}</Alert>

      {loading && <Loading status={loading}>Carregando informações...</Loading>}

      {!loading && formIsReady && <FormFlyer />}
    </>
  );
};

export default FlyerCreate;
