import React, { useCallback, useState, useRef, useMemo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FiEdit, FiX } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import { Form } from '@unform/web'

import { Address } from '~/components/Address'
import Alert from '~/components/Alert'
import { Button, ButtonCopyToClipboard } from '~/components/Buttons'
import FilterLocations from '~/components/FilterLocations'
import LocationsNear from '~/components/LocationsNear'
import { Label, Textarea } from '~/components/unform'
import { getAccountInfo } from '~/helpers/account-info'
import { togglePanelShoppingCart } from '~/modules/chat/store/actions'
import { shoppingCartService } from '~/services/shopping-cart'

import CustomerAddressForm from './CustomerAddressForm'
import { bodyShoppingCart } from './model'

import * as S from './styles'

const ShoppingCartPanel = () => {
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState(null)
  const [cartId, setCartId] = useState()
  const [editAddress, setEditAddress] = useState(false)

  const formRef = useRef(null)

  const { host } = getAccountInfo()

  const handleChangeLocation = useCallback(location => {
    setLocation(location)
  }, [])

  const { customerId } = useSelector(({ chats }) => chats)
  const { customer } = useSelector(({ customers }) => customers)

  const shippingAddress = useMemo(
    () => ({
      type: 'shipping',
      full_name: customer?.name,
      street: customer.address_street,
      number: customer.address_number,
      city: customer.address_city,
      region: customer.address_state,
      neighborhood: customer.address_neighborhood,
      postcode: customer.address_postal_code,
      complement: customer.address_complement,
      country: customer.address_country
    }),
    [customer]
  )

  const shippingAddressIsValid = useMemo(() => {
    const { address_street, address_number, address_city, address_state } =
      customer

    return address_street && address_number && address_city && address_state
  }, [customer])

  const dispatch = useDispatch()

  const handlePanelShoppingCart = () => dispatch(togglePanelShoppingCart())

  const simpleHost = useMemo(() => host?.split('.')[0], [host])

  const handleSubmit = useCallback(
    async data => {
      setLoading(true)
      const { description } = data
      const locationId = location?.value

      const body = bodyShoppingCart({
        description,
        customerId,
        locationId,
        shippingAddress,
        originSource: 'chat'
      })

      try {
        const response = await shoppingCartService.generate({ body })

        const { cart_id } = response.data

        formRef.current.reset()

        setCartId(cart_id)
        setLocation()
        setLoading(false)
      } catch (err) {
        toastr.error('Erro', 'Houve um erro ao criar o carrinho.')

        console.error(err)

        setLoading(false)
      }
    },
    [location, customerId, shippingAddress]
  )

  const buttonText = useMemo(
    () => (location ? 'Gerar carrinho' : 'Escolha uma loja'),
    [location]
  )

  const handleEditAddress = useCallback(
    () => setEditAddress(oldState => !oldState),
    []
  )

  return (
    <S.Container>
      <header>
        <button
          type="button"
          onClick={handlePanelShoppingCart}
          className="icon__close"
        >
          <FiX />
        </button>

        <strong className="title">Gerar carrinho para este cliente</strong>

        <p className="description">Escolha uma loja e insira a lista abaixo.</p>

        {/* Depois que gerou o carrinho */}
        {cartId && (
          <S.CartCard>
            <div className="text">
              <span>Carrinho gerado com sucesso:</span>
              <a
                href={`https://cart.newtail.com.br/${simpleHost}/cart/${cartId}`}
                target="_blank"
                rel="noreferrer"
              >
                Acesse o carrinho
              </a>
            </div>

            <button
              type="button"
              onClick={() => setCartId()}
              className="icon__close"
            >
              <FiX />
            </button>
          </S.CartCard>
        )}

        {/* Se por algum motivo o host não foi carregado */}
        {!host && (
          <Alert>
            Não foi um encontrado um host para esta conta. O host é necessário
            para gerar o carrinho. Tenta atualizar a página, fazer o login
            novamente ou entrar em contato com o suporte técnico.
          </Alert>
        )}

        {shippingAddressIsValid && !editAddress && (
          <>
            <div className="addressSection__wrapper">
              <header>
                <strong>Endereço de entrega:</strong>

                <Button
                  template="transparentPrimary"
                  text="Alterar"
                  iconLeft={<FiEdit />}
                  size="xsmall"
                  onClick={handleEditAddress}
                />
              </header>

              {shippingAddress && <Address shippingAddress={shippingAddress} />}
            </div>

            {!location && <LocationsNear address={customer} />}

            {!location && (
              <Label isRequired text="Escolha uma loja para entrega:" />
            )}

            <FilterLocations
              placeholder="Escolha uma loja"
              onChange={handleChangeLocation}
              defaultValue={location}
              hideAll
              ignoreRedux
              filterActiveLocations
            />
          </>
        )}
      </header>

      {(!shippingAddressIsValid || editAddress) && (
        <CustomerAddressForm
          onCancelEditMode={handleEditAddress}
          isEditMode={editAddress}
        />
      )}

      {shippingAddressIsValid && !editAddress && (
        <>
          <S.Form>
            <Form ref={formRef} onSubmit={handleSubmit}>
              {location && (
                <div className="wrapTextField">
                  <strong>Lista de compras:</strong>
                  <Textarea
                    name="description"
                    // eslint-disable-next-line max-len
                    placeholder={`- 200g de batata inglesa\n- 1kg arroz branco tio joão\n- 2 pasta de dente colgate tripla ação`}
                    required
                  />
                </div>
              )}

              <footer>
                <Button
                  type="submit"
                  text={buttonText}
                  loading={loading}
                  disabled={!location}
                />
              </footer>
            </Form>
          </S.Form>
        </>
      )}
    </S.Container>
  )
}

export default ShoppingCartPanel
