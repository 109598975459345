import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Alert from '~/components/Alert'
import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import FormPromotion from '~/modules/promotional/components/Form'
import HeaderButtons from '~/modules/promotional/components/HeaderButtons'
import {
  clearPromotionStore,
  getPromotion
} from '~/modules/promotional/store/promotion/actions'
import { PRODUCT_REDUX_QUERY } from '~/modules/promotional/store/promotion/constants'
import { setQuery } from '~/store/modules/filters/actions'

const PromotionalEdit = () => {
  const { id } = useParams()

  const dispatch = useDispatch()
  const { promotion, loading, error } = useSelector(state => state.promotional)

  useLayoutEffect(() => {
    if (promotion?.id !== id) {
      dispatch(clearPromotionStore())
    }
  }, [dispatch, id, promotion])

  useEffect(() => {
    if (!promotion) {
      dispatch(
        setQuery({
          query: { [PRODUCT_REDUX_QUERY]: null },
          keyState: PRODUCT_REDUX_QUERY
        })
      )
      dispatch(getPromotion({ id }))
    }
  }, [dispatch, id, promotion])

  return (
    <>
      <PageTitle title="Promoções > Editar" />

      <HeaderPages
        title="Editar de promoção"
        sideRightComponent={<HeaderButtons isEditPage />}
      />

      <Loading status={loading}>Carregando promoção...</Loading>

      <Alert active={error}>Erro: {error}</Alert>

      {!loading && promotion && !error && <FormPromotion />}
    </>
  )
}

export default PromotionalEdit
