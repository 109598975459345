/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import EmptyPage from '~/components/empty-page';
import Loading from '~/components/Loading';
import Pagination from '~/components/pagination';
import Panel from '~/components/Panel';
import Table from '~/components/Table';
import { formatSearchString, queryDatesDatepickerIsolated } from '~/helpers';
import { useAlert } from '~/hooks/useAlert';
import useFetchSWR from '~/hooks/useFetchSWR';
import { resetFilters } from '~/store/modules/filters/actions';

import { clearOrderInfo } from '../../actions';
import * as types from '../../constants';
import * as S from '../styles';
import { schema } from './table-schema';

const OrdersTable = () => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const { listOrdersPerPage, orderData } = useSelector(state => state.order);
  const { startDate, endDate } = useSelector(state => state.datepickerHeader);

  const {
    locations: filteredLocations,
    channel: filteredChannel,
    status: filteredStatus,
    paymentStatus: filteredPaymentStatus,
    query,
  } = useSelector(state => state.filters);

  const filteredLocationsIds = useMemo(
    () => filteredLocations?.value || null,
    [filteredLocations],
  );

  const filteredChannelId = useMemo(
    () => filteredChannel?.value || null,
    [filteredChannel],
  );

  const filteredStatusId = useMemo(
    () => filteredStatus?.value || null,
    [filteredStatus],
  );

  const filteredStatusPaymentId = useMemo(
    () => filteredPaymentStatus?.value || null,
    [filteredPaymentStatus],
  );

  const filteredQuery = useMemo(
    () => query?.[`${types.ORDER_REDUX_QUERY}`] || null,
    [query],
  );

  /**
   * Gerenciando a Listagem de pedidos
   */

  const queryString = useMemo(
    () =>
      formatSearchString({
        page,
        quantity: listOrdersPerPage,
        location_id: [filteredLocationsIds],
        channel_id: filteredChannelId,
        status: filteredStatusId,
        payment_status: filteredStatusPaymentId,
        query: filteredQuery,
        ...queryDatesDatepickerIsolated(startDate, endDate),
      }),
    [
      listOrdersPerPage,
      filteredLocationsIds,
      filteredChannelId,
      filteredStatusId,
      filteredStatusPaymentId,
      filteredQuery,
      startDate,
      endDate,
      page,
    ],
  );

  const { data: dataSWR, error: errorSWR } = useFetchSWR({
    url: `${process.env.REACT_APP_HOST}/search/orders?${queryString}`,
    refreshInterval: 10000,
  });

  const [loading, setLoading] = useState(!dataSWR);
  const [total, setTotal] = useState();
  const [errorOnPage, setErrorOnPage] = useState(null);
  const [AlertOnError] = useAlert(errorOnPage);

  useEffect(() => {
    if (Object.entries(orderData).length > 0) {
      dispatch(clearOrderInfo());
    }
  }, [dispatch, orderData]);

  useEffect(() => {
    setLoading(true);
    setPage(1);
  }, [
    listOrdersPerPage,
    filteredLocationsIds,
    filteredChannelId,
    filteredStatusId,
    filteredStatusPaymentId,
    filteredQuery,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (dataSWR) {
      setTotal(dataSWR.total);
      setLoading(false);
    }
  }, [dataSWR]);

  useEffect(() => {
    if (errorSWR) {
      setErrorOnPage('Houve um erro com a conexão.');
    }
    if (!errorSWR) {
      setErrorOnPage(null);
    }
  }, [errorSWR]);

  /**
   * Gerenciando a paginação
   */

  const handlePagination = page => {
    setLoading(true);
    setPage(page);
  };

  /**
   * Handle reset filters
   */

  const handleResetFilter = useCallback(
    () => dispatch(resetFilters()),
    [dispatch],
  );

  return (
    <>
      <Loading status={loading}>Carregando lista de pedidos</Loading>

      <AlertOnError />

      {total === 0 && (
        <EmptyPage
          title="Nenhum pedido encontrado"
          subtitle="Não encontramos pedidos com os filtros aplicados."
          titleButton="Limpar filtros"
          svgPath="photos/orders-empty"
          onClick={handleResetFilter}
        />
      )}

      <Panel noPaddingBody isLoading={loading}>
        {dataSWR && total > 0 && (
          <Table schema={schema} data={dataSWR.data} total={total} />
        )}
      </Panel>

      {total > 0 && total > listOrdersPerPage && (
        <S.Footer>
          <Pagination
            count={total}
            page={page}
            limit={listOrdersPerPage}
            onClickPagination={handlePagination}
            hasFirstLast
            hasPagination
            alignCenter
          />
          <p>
            {total} {total > 1 ? 'itens' : 'item'}
          </p>
        </S.Footer>
      )}
    </>
  );
};

export default OrdersTable;
