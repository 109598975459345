import { Reducer } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

/**
 * datepickerHeader - datas que gerenciam os dados
 * account - dados da conta
 * chatConnect - informações sobre a conexão com a mensageria
 */

export default (reducers: Reducer) => {
  const persistedReducer = persistReducer(
    {
      key: 'newtail',
      storage,
      whitelist: [
        'datepickerHeader',
        'filters',
        'account',
        'chatConnect',
        'layout'
      ]
    },
    reducers
  )

  return persistedReducer
}
