/* eslint-disable react/no-array-index-key */
import React from 'react'

import * as S from './styles'

const AvatarDefaultImg = ({
  name = '',
  accountLogo,
  accountName,
  bgLogo
}: AvatarDefaultImgProps) => {
  const names = name?.split(' ')

  const initals = names.map(el => el[0])

  return (
    <S.Container>
      {accountLogo && (
        <img
          src={accountLogo}
          alt={`Logo ${accountName}`}
          style={{ backgroundColor: bgLogo || 'white' }}
        />
      )}

      {!accountLogo &&
        initals
          .filter((i, index) => index < 2)
          .map((character, index) => (
            <span key={`key_${index}_${character}`}>{character}</span>
          ))}
    </S.Container>
  )
}

export default AvatarDefaultImg
