import * as types from './layout-constants'

export function toggleBoxedLayout() {
  return {
    type: types.LAYOUT_BOXED_TOGGLE
  }
}

export function enableBoxedLayout() {
  return {
    type: types.LAYOUT_BOXED_ENABLE
  }
}

export function disableBoxedLayout() {
  return {
    type: types.LAYOUT_BOXED_DISABLE
  }
}

export const handleMetricsChartLayout = ({ mode }) => ({
  type: types.LAYOUT_METRICS_CHART,
  payload: { mode }
})

export const checkIsMobile = width => dispatch => {
  dispatch({
    type: types.LAYOUT_IS_MOBILE,
    payload: width
  })
}
