import { types } from '../constants'

const index = {
  pending: `${types.list}_PENDING`,
  fulfilled: `${types.list}_FULFILLED`,
  rejected: `${types.list}_REJECTED`,
  setPage: types.page,
  setPerPage: types.perPage
}

export const list = {
  [index.pending]: ({ draft }) => {
    draft.industry.publisher.list.loading = true
    draft.industry.publisher.list.error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.publisher.list.data = action.payload.data

    draft.industry.publisher.list.pages = action.payload.pages
    draft.industry.publisher.list.total = action.payload.total
    draft.industry.publisher.list.page = action.payload.page

    draft.industry.publisher.list.loading = false
    draft.industry.publisher.list.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.publisher.list.loading = false
    draft.industry.publisher.list.error = action.payload
  },
  [index.setPage]: ({ draft, action }) => {
    draft.industry.publisher.list.page = action.payload
  },
  [index.setPerPage]: ({ draft, action }) => {
    draft.industry.publisher.list.perPage = action.payload
  }
}
