import { getAccountId, isPublisher } from '~/helpers'
import i18n from '~/i18n'
import { formatViewCampaign } from '~/modules/retailMedia/dtos/common/campaign/get'

import { campaignView as types } from '../constants'

const index = {
  pending: `${types.get}_PENDING`,
  fulfilled: `${types.get}_FULFILLED`,
  rejected: `${types.get}_REJECTED`
}

const reducerKey = 'item'

export const get = {
  [index.pending]: ({
    draft,
    action
  }: ActionData<null, { requestInBackground: boolean }>) => {
    const requestInBackground = !!action?.values?.requestInBackground

    if (!requestInBackground) {
      draft.campaign[reducerKey].loading = false
      draft.campaign[reducerKey].data = null
      draft.campaign[reducerKey].error = null
    }
  },
  [index.fulfilled]: ({ draft, action }: ActionData<CampaignViewResponse>) => {
    const data = action.payload

    const accountId = isPublisher
      ? data?.publisher_account_id
      : data?.advertiser_account_id

    const allowed = accountId === getAccountId()

    if (!allowed) {
      draft.campaign[reducerKey].error = i18n.t(
        'c:messages.unauthorized.message'
      )
      draft.campaign[reducerKey].loading = false
      return
    }

    const formatedViewCampaign = formatViewCampaign(data)

    draft.campaign[reducerKey].data = formatedViewCampaign

    draft.campaign.products.selectecProducts =
      formatedViewCampaign.selectedProducts

    draft.campaign[reducerKey].loading = false
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft.campaign[reducerKey].loading = false
    draft.campaign[reducerKey].error =
      action?.payload?.data || action?.payload || true
  }
}
