import styled, { css } from 'styled-components';

import { device } from '~/styles/device';

export const Container = styled.main`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-start;

    height: 100%;
    padding: 2rem;
    border-radius: 0.5rem;

    background-color: ${theme.colors.white};

    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--inative);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--tertiary);
      transition: 0.3s ease-in-out all;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: var(--gray);
    }

    .wrapper {
      display: inline-flex;
      flex-direction: column;

      margin: 0 auto;
      max-width: 800px;
      width: auto;

      h2 {
        color: ${theme.colors.primary};
      }

      .firstRow {
        display: flex;

        @media ${device.laptop_max} {
          flex-direction: column;
        }

        margin-bottom: 2rem;

        .qrCode {
          margin-right: 1.25rem;

          @media ${device.laptop_max} {
            margin: 0 auto;
          }
        }

        .infos {
          padding-top: 1rem;
          header {
            margin-bottom: 1rem;
          }
        }
      }
    }
  `}
`;

export const TextIcon = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .text {
    display: flex;
    flex-direction: column;
  }

  .wrapperIcon {
    margin-right: 1rem;
  }

  ${Container} .secondRow & {
    .wrapperIcon {
      font-size: 2.5rem;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;

  svg {
    margin-right: 0.5rem;
  }
`;
