import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 0.5rem;
    border-bottom: 1px solid ${theme.colors.outlineGray};
  `}
`

export const ListAccounts = styled.div`
  padding: 0 0.5rem;
  max-height: calc(-10rem + 90vh);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`
