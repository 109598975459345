import styled, { css } from 'styled-components';

const containerModifiers = {
  editMode: theme => css`
    &,
    &:hover {
      border-color: ${theme.colors.primaryLighten30};
      background-color: ${theme.colors.white};

      border-color: ${theme.colors.warning};
      background-color: ${theme.colors.warningLighten50};
      cursor: default;
    }
  `,
};

export const Container = styled.div`
  ${({ theme, editMode }) => css`
    display: flex;
    flex-direction: column;

    margin-bottom: ${theme.spacings.small};

    padding: ${`${theme.spacings.small} ${theme.spacings.medium}`};
    border: 1px solid ${theme.colors.tertiary};

    border-radius: ${theme.border.radius.large};
    background-color: ${theme.colors.primaryLighten60};

    transition: ${theme.transition.default} all;

    cursor: pointer;

    &:hover {
      border-color: ${theme.colors.primaryLighten30};
      background-color: ${theme.colors.white};
    }

    ${editMode && containerModifiers.editMode(theme)}
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h5`
  ${({ theme }) => css`
    margin: 0;
    font-weight: ${theme.font.bolder};
    display: flex;
    align-items: center;

    font-size: ${theme.font.sizes.large};

    svg {
      margin-right: ${theme.spacings.xxxsmall};
    }
  `}
`;

export const ConfigType = styled.p`
  ${({ theme }) => css`
    margin: 0 0 0 ${theme.spacings.medium};
    padding: ${theme.spacings.tiny} ${theme.spacings.xxxsmall};
    border-radius: ${theme.border.radius.medium};

    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.light};
    background-color: ${theme.colors.primaryLighten30};
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  button + button {
    margin-left: ${({ theme }) => theme.spacings.xlarge};
  }
`;
