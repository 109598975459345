import { getDecodedToken } from '~/helpers'

import { adsStatusFilter } from './adsStatus'
import { adTypeFilter } from './adType'
import { advertiserPublisherFilters } from './advertiserPublisher'
import { queryFilter, queryFilterWallet, queryAdsSKUFilter } from './query'
import { statusFilter } from './status'
import { walletType } from './walletType'

const { current_account_type: accountType } = getDecodedToken()

export const retailMediaCampaignFilters = {
  ...queryFilter,
  genericFilters:
    accountType === 'grocery'
      ? [statusFilter, adTypeFilter]
      : [statusFilter, adTypeFilter]
}

export const retailMediaAdsFiltersHeader = {
  genericFilters: [adTypeFilter],
  showGlobalDatePicker: true
}

export const retailMediaAdsFilters = {
  // ...queryAdsFilter,
  ...queryAdsSKUFilter,
  genericFilters: [adsStatusFilter, adTypeFilter],
  showGlobalDatePicker: true
}

export const retailMediaAdsFiltersInsideCampaign = {
  ...queryAdsSKUFilter,
  genericFilters: [adsStatusFilter],
  showGlobalDatePicker: true
}

export const retailMediaAdsFiltersInsideCampaignBanner = {
  genericFilters: [adsStatusFilter],
  showGlobalDatePicker: true
}

export const retailMediaCampaignAllFilters = {
  ...queryFilter,
  genericFilters:
    accountType === 'grocery'
      ? [statusFilter, adTypeFilter]
      : [advertiserPublisherFilters.industry, statusFilter, adTypeFilter]
}

export const retailMediaWalletFilters = {
  genericFilters: [walletType],
  showGlobalDatePicker: true
}

export const retailMediaWallets = {
  ...queryFilterWallet
}
