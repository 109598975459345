const prefix = `@PROMOTIONAL`;

export const FLYERS_CREATE = `${prefix}/FLYERS_CREATE`;
export const FLYERS_DELETE = `${prefix}/FLYERS_DELETE`;
export const FLYERS_UPDATE = `${prefix}/FLYERS_UPDATE`;
export const FLYERS_LIST = `${prefix}/FLYERS_LIST`;
export const FLYERS_LIST_PER_PAGE = `${prefix}/FLYERS_LIST_PER_PAGE`;
export const FLYER_VIEW = `${prefix}/FLYER_VIEW`;
export const FLYER_METRICS_VIEW = `${prefix}/FLYER_METRICS_VIEW`;

export const CLEAR_FLYER_STORE = `${prefix}/CLEAR_FLYER_STORE`;
