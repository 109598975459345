import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { t } from 'i18next'

import HeaderPages from '~/components/HeaderPages'
import { LogoStore } from '~/components/LogoStore'
import PageTitle from '~/components/PageTitle'
import { getRetailMediaAccountType, isAdvertiser } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import BalanceByPublisher from '~/modules/retailMedia/components/BalanceByPublisher'
import CampaignForm from '~/modules/retailMedia/components/CampaignFormV2'
import { getAccountInfoAction } from '~/modules/retailMedia/store/accountInfo/actions'
import { useAppSelector } from '~/store/hooks'

const accountType = getRetailMediaAccountType()

const CampaignCreate = () => {
  const searchParams = useQueryParams()
  const id = searchParams.get('rmid')

  /**
   * Redux pra bicar depois
   * Pegando informação da conta vículo para usar no header
   */
  const dispatch = useDispatch()

  const {
    accountInfo: {
      view: { data, loading }
    }
  } = useAppSelector(state => state.retailMedia)

  useEffect(() => {
    if (id) {
      dispatch(
        getAccountInfoAction({
          id,
          params: { account_info: true },
          accountType
        })
      )
    }
  }, [dispatch, id])

  /**
   * Memos
   */
  const insideProps = useMemo(() => {
    if (!id) {
      return {}
    }

    return {
      titleSideRightComponent: (
        <>
          {!loading && (
            <LogoStore
              size="large"
              linkLogo={data?.account_logo}
              alt={data?.name}
              backgroundLogo={
                data?.account_theme?.header_color || 'transparent'
              }
            />
          )}
        </>
      ),
      subtitle: (
        <BalanceByPublisher
          id={id}
          idAccountType={isAdvertiser ? 'industry' : 'grocery'}
        />
      ),

      labelBreadcrumb: `${t('rm:backToCampaignsWithName', { name: data?.name })}`,
      backTo: `/retail-media/campaigns?rmid=${id}`
    }
  }, [
    data?.account_logo,
    data?.account_theme?.header_color,
    data?.name,
    id,
    loading
  ])

  const headerWhiteLabel = {
    backTo: '/retail-media/campaigns',
    labelBreadcrumb: t('rm:backToCampaigns')
  }

  return (
    <>
      <PageTitle title={`${t('rm:Campaign')} > ${t('rm:create')}`} />

      {id && <HeaderPages title={data?.name} {...insideProps} />}

      <HeaderPages
        title={t('rm:createCampaign')}
        {...(!id ? headerWhiteLabel : {})}
      />

      <CampaignForm />
    </>
  )
}

export default CampaignCreate
