import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DatepickerHeader } from '~/components/datepicker-header';
import EmptyPage from '~/components/empty-page';
import ExternalDashboard from '~/components/ExternalDashboard';
import { Filters } from '~/components/Filters';
import HeaderPages from '~/components/HeaderPages';
import PageTitle from '~/components/PageTitle';
import TabsNav from '~/components/TabsNav';
import { history } from '~/helpers';

import * as S from './styles';

const PickingDashboard = () => {
  const { mode } = useParams();

  useEffect(() => {
    if (!mode) return history.push('/orders/picking-dashboard/follow-up/');
  }, [mode]);

  const dashboardId = {
    'follow-up': 19,
    analysis: 22,
    'separate-orders': 20,
    'separate-products': 21,
  };

  const pageTitle = {
    'follow-up': 'Acompanhamento de picking',
    analysis: 'Análise de picking',
    'separate-orders': 'Pedidos separados',
    'separate-products': 'Produtos separados',
  };

  const navData = [
    {
      id: 'follow-up',
      text: 'Acompanhamento de picking',
      to: `/orders/picking-dashboard/follow-up/`,
    },
    {
      id: 'analysis',
      text: 'Análise de picking',
      to: `/orders/picking-dashboard/analysis/`,
    },
    {
      id: 'separate-orders',
      text: 'Pedidos separados',
      to: `/orders/picking-dashboard/separate-orders/`,
    },
    {
      id: 'separate-products',
      text: 'Produtos separados',
      to: `/orders/picking-dashboard/separate-products/`,
    },
  ];

  return (
    <S.Container>
      <PageTitle
        title={`Relatórios de separação - ${
          pageTitle?.[mode] || 'Nenhum encontrado'
        }`}
      />

      <HeaderPages
        title="Relatórios de separação"
        sideRightComponent={<DatepickerHeader />}
        className="has-datepicker"
      />

      <Filters locations channel states />

      <TabsNav data={navData} />

      {dashboardId?.[mode] && (
        <ExternalDashboard dashboardId={dashboardId[mode]} useDate />
      )}

      {!mode && (
        <EmptyPage
          title="Nenhum relatório selecionado"
          subtitle="Selecione um relatório no menu acima."
          svgPath="photos/login"
        />
      )}

      {mode && !dashboardId?.[mode] && (
        <EmptyPage
          title="Relatório não encontrado"
          subtitle="Selecione um relatório no menu acima."
          svgPath="photos/login"
        />
      )}
    </S.Container>
  );
};

export default PickingDashboard;
