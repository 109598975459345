import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.25rem;

  .finishButton {
    margin-left: auto;
  }

  @media ${device.tablet_max} {
    flex-wrap: wrap;
  }
`

export const SectionAttendant = styled.section`
  display: flex;
  align-items: center;

  @media ${device.tablet_max} {
    margin-bottom: 0.25rem;
  }
`

export const WrapperAttendant = styled.div`
  display: flex;
  align-items: center;
`

export const Attendant = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    line-height: 1;

    small {
      line-height: 1;

      .name {
        color: ${theme.colors.primary};
      }
    }

    > svg {
      width: 1rem;
      height: 1rem;
      margin-right: 4px;
    }

    &::after {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #d9d9d9;
      margin: 0 8px 0 8px;
    }
  `}
`
