import { createTemplate } from './createTemplate'
import { deleteTemplate } from './deleteTemplate'
import { listTemplates } from './listTemplates'
import { updateTemplate } from './updateTemplate'

export default {
  ...createTemplate,
  ...deleteTemplate,
  ...listTemplates,
  ...updateTemplate
}
