import React, { useMemo } from 'react';

import { formatDateToString, formatMoney, paymentMethods } from '~/helpers';

import { AuthorizationTypes } from './types';

const ReservedValue = ({ payment }) => {
  const showReservedValue = useMemo(
    () =>
      !!payment?.reserved_value &&
      payment?.reserved_value > 0 &&
      payment?.reserved_at !== null,
    [payment],
  );

  return (
    !!showReservedValue && (
      <>
        {' • '}
        <strong>Valor pré-autorizado:</strong>{' '}
        <>{formatMoney(payment?.reserved_value)} - </>
        <>{paymentMethods(formatDateToString(payment?.reserved_at))} </>
      </>
    )
  );
};

export default ReservedValue;

ReservedValue.propTypes = AuthorizationTypes;
