import React, { useCallback, useMemo } from 'react'
import { FiTrash, FiRefreshCw } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'
import {
  deleteShoppingCart,
  restoreShoppingCart
} from '~/store/modules/shoppingCart/actions'

const ColumnDelete = ({ data }) => {
  const { loadingDelete, loadingUpdate } = useSelector(
    ({ shoppingCart }) => shoppingCart
  )

  const isDeleted = useMemo(() => data.status.type === 'deleted', [data])
  const isInReview = useMemo(() => data.status.type === 'in_review', [data])
  const isFinished = useMemo(() => data.status.type === 'finished', [data])

  const canDelete = useMemo(
    () => !isDeleted && !isFinished,
    [isDeleted, isFinished]
  )

  const canRestore = useMemo(
    () => !isInReview && !isFinished,
    [isInReview, isFinished]
  )

  const dispatch = useDispatch()

  const { cart_id } = data

  const handleDeleteShoppingCart = useCallback(
    () => dispatch(deleteShoppingCart({ cart_id })),
    [cart_id, dispatch]
  )

  const handleRestoreShoppingCart = useCallback(
    () => dispatch(restoreShoppingCart({ cart_id })),
    [cart_id, dispatch]
  )

  return (
    <div className="buttons">
      {canDelete && (
        <Button
          template="transparentDanger"
          size="small"
          customWidth="auto"
          onClick={handleDeleteShoppingCart}
          title="Cancelar carrinho"
          style={{ marginRight: '.5rem' }}
        >
          {loadingDelete ? <Spinner /> : <FiTrash />}
        </Button>
      )}

      {canRestore && (
        <Button
          template="transparent"
          size="small"
          customWidth="auto"
          onClick={handleRestoreShoppingCart}
          title="Restaurar carrinho"
          style={{ marginLeft: 'auto' }}
        >
          {loadingUpdate ? <Spinner /> : <FiRefreshCw />}
        </Button>
      )}
    </div>
  )
}

export default ColumnDelete

ColumnDelete.propTypes = {
  data: PropTypes.shape({
    cart_id: PropTypes.string,
    order_id: PropTypes.string,
    in_review: PropTypes.bool,
    link_sent: PropTypes.bool,
    deletedAt: PropTypes.string,
    status: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string
    })
  }).isRequired
}
