import { shade, transparentize } from 'polished';
import styled, { css } from 'styled-components';

const containerModifiers = {
  transparent: theme => css`
    border: ${theme.colors.gray} 1px solid;
    background-color: white;

    .checked {
      color: ${theme.colors.gray};
      background: white;
      z-index: 4;
    }

    &:after {
      content: '';
      position: absolute;
      height: 150%;
      width: 1px;
      top: -6px;
      left: 15px;
      transform: rotate(45deg);
      background-color: ${theme.colors.gray};

      z-index: 1;
    }
  `,
  isActive: currentColor => css`
    /* border: 3px solid ${shade(0.1, currentColor)}; */

    box-shadow: 0 6px 0.75rem -0.125rem ${transparentize(0.25, currentColor)};

    .checked {
      opacity: 1;
      transform: translateY(0);
    }
  `,
};

export const Container = styled.button`
  ${({ theme, currentColor, isActive }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background-color: ${currentColor};

    height: 2rem;
    width: 2rem;
    border: 2px solid ${currentColor};

    transition: ${theme.transition.fast} all;
    overflow: hidden;
    position: relative;

    border-radius: 0.5rem;
    overflow: hidden;

    .checked {
      color: ${theme.colors.white};
      font-size: ${theme.font.sizes.large};
      opacity: 0;
      transform: translateY(-2px);
      transition: ${theme.transition.fast} all;
    }

    ${isActive && containerModifiers.isActive(currentColor)}
    ${currentColor === 'transparent' && containerModifiers.transparent(theme)}
  `}
`;

export const ContainerFake = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background-color: white;

    height: 2rem;
    width: 2rem;

    transition: ${theme.transition.fast} all;
    overflow: hidden;
    position: relative;

    border-radius: 0.5rem;
    overflow: hidden;

    background-color: white;

    .colorpicker {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      border: 6px solid transparent;
      border-image: linear-gradient(
        to bottom,
        #e72341 0%,
        #ff8d24 10%,
        #f7c325 35%,
        #06ba64 65%,
        #268eea 90%,
        #9b36eb 100%
      );
      border-image-slice: 1;

      z-index: 0;
    }

    &:after {
      content: '';
      position: absolute;
      height: calc(100% - 6px);
      width: calc(100% - 6px);
      top: 3px;
      left: 3px;
      border-radius: 0.25rem;

      background-color: white;

      z-index: 1;
    }

    svg {
      z-index: 2;
      color: ${theme.colors.dark};
    }
  `}
`;
