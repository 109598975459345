import {
  getCampaignService,
  getCampaignsService
} from '../../services/campaign'
import { searchOffersService } from '../../services/industry/campaign'
import { campaignList, campaignView, product as productType } from './constants'

/**
 * Get all */
export const listCampaign = (queries = {}) => ({
  type: campaignList.list,
  payload: getCampaignsService(queries)
})

/**
 * Get all - Handle page */
export const setPageLisCampaign = (value: number) => ({
  type: campaignList.page,
  payload: value
})

/**
 * Get all - Handle items por page */
export const setPerPageLisCampaign = (value: number) => ({
  type: campaignList.perPage,
  payload: value
})

/**
 * Get all- Handle sort list
 */
export const setSortListLisCampaign = (data: OnSortProps) => ({
  type: campaignList.sort,
  payload: data
})

/* Get one */
export const getCampaign = ({
  requestInBackground,
  ...data
}: GetCampaignProps) => ({
  type: campaignView.get, // Handle on src/modules/retailMedia/store/campaign/reducerCases/get.ts
  payload: getCampaignService(data),
  values: { requestInBackground }
})

/**
 * Products
 */
/**
 * Adicionar uma lista de SKUs de produtos
 */

export const addProducts = ({ body }: { body: AddProductsBody }) => {
  const { products, queryKey = 'product_skus', ...rest } = body

  const external_ids = products
    .toString()
    .split(/[\n,;]+/)
    .map(item => item.trim())
    .filter(Boolean)

  return {
    type: productType.addBulk,
    values: { products: external_ids, queryKey },
    payload: searchOffersService({
      ...rest,
      [queryKey]: external_ids
    })
  }
}
/**
 * Adiciona um produto na lista de selecionados
 */

export const addProductOnCampaign = ({ product }) => ({
  type: productType.add,
  payload: product
})

/**
 * Remove um produto da lista de selecionados
 */

export const deleteProductOnCampaign = ({ product }) => ({
  type: productType.remove,
  payload: product
})

/**
 * Remove todos os produto da lista de selecionados
 */

export const deleteAllProductsOnCampaign = (isEdit?: boolean) => {
  return {
    type: productType.remove_all,
    payload: isEdit
  }
}
