export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const CONFIRM_INVITE_VALIDATE_TOKEN = 'CONFIRM_INVITE_VALIDATE_TOKEN'
export const CONFIRM_INVITE_ERROR_MESSAGE = 'CONFIRM_INVITE_ERROR_MESSAGE'
export const CONFIRM_INVITE_VALID_TOKEN = 'CONFIRM_INVITE_VALID_TOKEN'
export const CONFIRM_INVITE_ACTIVATE = 'CONFIRM_INVITE_ACTIVATE'

export const resetPasswordPath = 'reset-password'
export const activeInvitePath = 'active-invite'

export const resetPasswordPathToken = '/reset-password/:token'
export const activeInvitePathToken = '/active-invite/:token'
