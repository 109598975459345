import React from 'react'
import { connect } from 'react-redux'

import LineChart from '~/components/charts/line-chart'
import Panel from '~/components/Panel'

const SaleCurveChart = ({ loading, data = {} }) => (
  <Panel noPaddingBody title="Curva de venda" isLoading={loading}>
    <LineChart data={data.orderIncomeByDay} loading={loading} />
  </Panel>
)

export default connect(store => ({
  theme: store.theme.currentTheme
}))(SaleCurveChart)
