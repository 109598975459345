import React, { useMemo } from 'react'
import { AiOutlineSync } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'
import useConfirmationModal from '~/hooks/useConfirmationModal'

import { restartOrderPicking } from '../../actions'

const RestartPicking = () => {
  const dispatch = useDispatch()

  const { updateLoading, getOrderLoading, orderData } = useSelector(
    state => state.order
  )

  const [ConfirmationModal, showConfirmationModal, toggleConfirmationModal] =
    useConfirmationModal()

  const handleRestartPicking = () => {
    dispatch(restartOrderPicking(orderData.id))
  }

  const templateButton = useMemo(
    () =>
      updateLoading
        ? {
            icon: <Spinner />,
            text: 'Reiniciando...',
            disabled: true
          }
        : {
            icon: <AiOutlineSync />,
            text: 'Reiniciar separação',
            disabled: false
          },
    [updateLoading]
  )

  return (
    <>
      <ConfirmationModal
        title="Deseja reiniciar a separação?"
        isOpen={showConfirmationModal}
        onClose={toggleConfirmationModal}
        onSubmit={handleRestartPicking}
        confirmationValue="sim"
        confirmationButton="Entendo e desejo reiniciar a separação"
      >
        <p>
          Esta pedido já tem uma nota fiscal emitida. Uma nova separação
          resultará na emissão de uma nova nota fiscal e cancelamento da antiga.
        </p>
      </ConfirmationModal>

      {!getOrderLoading && (
        <Button
          iconLeft={templateButton.icon}
          text={templateButton.text}
          template="warningOutlined"
          onClick={toggleConfirmationModal}
          disabled={templateButton.disabled}
        />
      )}
    </>
  )
}

export default RestartPicking
