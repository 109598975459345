const prefix = '@retailMedia/industry/wallet'

export const WALLET_LIST_ALL = `${prefix}/LIST_ALL`
export const WALLET_LIST_ALL_SET_PAGE = `${prefix}/LIST_ALL_SET_PAGE`
export const WALLET_LIST_ALL_PER_PAGE = `${prefix}/LIST_ALL_PER_PAG`

export const WALLET_LIST_TRANSACTION = `${prefix}/LIST_TRANSACTION`
export const WALLET_LIST_TRANSACTION_SET_PAGE = `${prefix}/LIST_TRANSACTION_SET_PAGE`
export const WALLET_LIST_TRANSACTION_PER_PAGE = `${prefix}/LIST_TRANSACTION_PER_PAGE `

export const RECHARGE = `${prefix}/RECHARGE`

export const GET_TRANSACTION = `${prefix}/GET_TRANSACTION`

export const types = {
  listAll: WALLET_LIST_ALL,
  listAllSetPage: WALLET_LIST_ALL_SET_PAGE,
  listAllPerPage: WALLET_LIST_ALL_PER_PAGE,

  listTransaction: WALLET_LIST_TRANSACTION,
  listTransactionSetPage: WALLET_LIST_TRANSACTION_SET_PAGE,
  listTransactionSetPerPage: WALLET_LIST_TRANSACTION_PER_PAGE,

  recharge: RECHARGE,

  getTransaction: GET_TRANSACTION
}
