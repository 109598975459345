/**
 * **Transforma números em distâncias**
 *
 * Retorna uma string com o valor da distância formatado de acordo com seu valor e as casas decimais enviadas
 *
 * @param { number } value Valor a ser formatado
 * @param { number } decimals Número de casa decimais a ser retornado
 *
 * @returns Retorna uma string com o valor formatado em metros ou quilômetros
 *
 */

export const formattedDistance = ({ value, decimals = 3 }) => {
  if (value < 1) {
    const meters = (value * 1000).toFixed();

    return `${meters} m`;
  }

  return `${value.toFixed(decimals).replace(/\./g, ',')} km`;
};
