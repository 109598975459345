export const campaignButtonsOptions = [
  {
    id: 'without_buttons',
    value: 'without_buttons',
    label: 'Sem botões',
    description: 'Não usar botões nesse template',
  },
  {
    id: 'with_buttons',
    value: 'with_buttons',
    label: 'Botão padrão',
    description: 'Usar botão com texto "Quero aproveitar"',
  },
];

export const default__buttons = [
  {
    text: 'Quero aproveitar',
  },
  {
    text: 'Não quero mensagens',
  },
];
