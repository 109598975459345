import React from 'react';

import ExternalDashboard from '~/components/ExternalDashboard';
import HeaderPages from '~/components/HeaderPages';

import { Container } from './styles';

const CampaignDashboard = () => {
  return (
    <Container>
      <HeaderPages />

      <ExternalDashboard dashboardId={41} />
    </Container>
  );
};

export default CampaignDashboard;
