import styled from 'styled-components';

export const PrintContent = styled.section`
  display: none;
  padding: 2cm;

  .description {
    white-space: pre-line;
  }

  @media print {
    display: block;
  }
`;
