import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Container } from '~/components/Container/'
import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { formatMoney } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import { useScroll } from '~/hooks/useScroll'

import DeleteLocation from '../_elements/Delete'
import FormLocation from '../_elements/FormLocation'
import LocationNav from '../_elements/LocationNav'
import {
  showLocation,
  indexLocationDeliveryTypes,
  setMapCoordinates
} from '../actions'

const LocationsEdit = () => {
  const [scrollToActiveFields, activeFieldsRef] = useScroll()

  const [initialValue, setInitialValue] = useState()
  const [localLoading, setLocalLoading] = useState(true)

  useEffect(() => {
    setInitialValue()
  }, [])

  const { location, error, loading, listLocationsDeliveryTypes } = useSelector(
    state => state.locations
  )

  const { id } = useParams()

  // Flag para evitar que campos do formulário carregam dados em cache
  const isReady = useMemo(
    () => id === initialValue?.id && initialValue && listLocationsDeliveryTypes,
    [id, initialValue, listLocationsDeliveryTypes]
  )

  const dispatch = useDispatch()

  const [AlertOnError] = useAlert(error)

  const loadLocation = useCallback(() => {
    dispatch(showLocation(id))
  }, [dispatch, id])

  const loadLocationDeliveryTypes = useCallback(() => {
    dispatch(indexLocationDeliveryTypes())
    setLocalLoading(false)
  }, [dispatch])

  useEffect(() => {
    loadLocation()
    loadLocationDeliveryTypes()
  }, [loadLocationDeliveryTypes, loadLocation, initialValue])

  useEffect(() => {
    if (!localLoading && location?.location && !initialValue) {
      const { deliveryType, location: { latitude, longitude } = {} } = location

      if (latitude && longitude) {
        dispatch(setMapCoordinates({ latitude, longitude }))
      }
      /**
       * Formatando os dados iniciais para tratar os dados
       * de tipos de entregas.
       */
      const deliveryTypes = {}

      deliveryType.forEach(
        ({
          LocationDeliveryType: { active, delivery_radius, delivery_type_id }
        }) => {
          deliveryTypes[`${delivery_type_id}`] = {
            active,
            delivery_radius
          }

          dispatch(
            setMapCoordinates({ [`${delivery_type_id}`]: delivery_radius })
          )
        }
      )

      const formattedInitialData = {
        ...location.location,
        deliveryTypes,
        min_order_value: formatMoney(location.location.min_order_value),
        min_free_shipping: formatMoney(location.location.min_free_shipping),
        shipping_cost: formatMoney(location.location.shipping_cost)
      }

      setInitialValue(formattedInitialData)
    }
  }, [
    dispatch,
    initialValue,
    listLocationsDeliveryTypes,
    localLoading,
    location
  ])

  const titlePage = useMemo(
    () => (initialValue ? `Local: ${initialValue.name}` : 'Locais'),
    [initialValue]
  )

  return (
    <Container>
      <PageTitle title={titlePage} />

      <HeaderPages
        title={titlePage}
        backTo="/locations/list"
        labelBreadcrumb="Voltar aos locais"
      />

      <AlertOnError />

      <LocationNav locationId={id} />

      {(!isReady || loading) && (
        <Loading status={loading}>Carregando informações do local</Loading>
      )}

      {isReady && !loading && (
        <>
          <FormLocation
            edit
            initialData={initialValue}
            activeFieldsRef={activeFieldsRef}
          />

          {/**
           * Removendo excluir local enquanto pensamos um modelo melhor.
           *  */}

          {/* <DeleteLocation
            locationId={id}
            validateTerm={initialValue.name}
            scrollToActiveFields={scrollToActiveFields}
          /> */}
        </>
      )}
    </Container>
  )
}
export default LocationsEdit
