import { getUserId } from '~/helpers';

import * as types from '../constants';

const index = {
  optimistic: `${types.CREATE_TEMPLATES_OPTIMISTIC}`,
  pending: `${types.CREATE_TEMPLATES}_PENDING`,
  fulfilled: `${types.CREATE_TEMPLATES}_FULFILLED`,
  rejected: `${types.CREATE_TEMPLATES}_REJECTED`,
};

export const createTemplate = {
  /**
   * Optimistic
   */
  [index.optimistic]: ({ action, draft }) => {
    const newTemplate = {
      ...action.payload,
      templateId: 'tempTag',
      saving: true,
      user_id: action.payload.global ? null : getUserId(),
    };
    const oldTemplates = draft.templates;

    draft.templates = [...oldTemplates, newTemplate];
  },
  /**
   * Pending
   */
  [index.pending]: ({ draft }) => {
    draft.loadingCreate = true;
  },
  /**
   *
   * Fulfilled
   */
  [index.fulfilled]: ({ draft, action }) => {
    const createdTemplate = action.payload;
    const oldTemplates = draft.templates;

    const createdTemplateIndex = oldTemplates.findIndex(
      item => item?.saving && item?.title === createdTemplate.title,
    );

    oldTemplates[createdTemplateIndex] = createdTemplate;

    draft.templates = oldTemplates;
    draft.loadingCreate = false;
  },
  /**
   * Rejected
   */
  [index.rejected]: ({ draft }) => {
    const oldTemplates = draft.templates;

    const filteredldTemplates = oldTemplates.filter(
      template => !template.saving,
    );

    draft.templates = [...filteredldTemplates];
    draft.loadingCreate = false;
  },
};
