import React from 'react';

import { Checkbox, FormGroup, Label } from '~/components/unform';

import { globalConfigPropTypes } from './globalConfigPropTypes';

const GlobalConfig = ({ onChangeGlobalConfig }) => {
  return (
    <FormGroup>
      <Label
        htmlFor="global_config"
        helperText="Eessa opção usa as mesmas configurações de horários e valores configuradas no local."
      >
        <Checkbox
          name="global_config"
          label="Usar configuração global do local"
          onChange={onChangeGlobalConfig}
        />
      </Label>
    </FormGroup>
  );
};

export default GlobalConfig;

GlobalConfig.propTypes = {
  ...globalConfigPropTypes.onChangeGlobalConfig,
};
