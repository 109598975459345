/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import Panel from '~/components/Panel';
import WhatsAppMessagePreview from '~/components/WhatsAppMessagePreview';
import { formattedMessageToHtml } from '~/helpers';
// import MessagePreview from '~/modules/messagingCampaigns/components/MessagePreview';

import * as S from './styles';

const TemplateView = ({ data }) => {
  // Data formatado para exibir no MessagePreview
  const messagePreviewData = useMemo(() => {
    const templateHasButtons = !!data?.messages[0].buttons?.length;

    const steps = [
      {
        text: formattedMessageToHtml(data?.messages[0].text),
        media: data?.media,
        buttons: data?.messages[0]?.buttons,
      },
    ];

    if (templateHasButtons) {
      steps.push({
        isUser: true,
        text: data?.messages[0].buttons[0].text,
      });
    }

    return steps;
  }, [data]);
  return (
    <S.Container>
      <Panel title="Informações gerais">
        <div className="hasBorder">
          <strong>Título do template</strong>
          <p className="text">{data?.title}</p>
        </div>

        {data?.description && (
          <div className="hasBorder">
            <strong>Descrição do template</strong>
            <p className="text">{data?.description}</p>
          </div>
        )}

        {data?.denied_reason && (
          <div className="hasBorder">
            <strong>Motivo da recusa</strong>
            <p className="text">{data.denied_reason}</p>
          </div>
        )}
      </Panel>

      <Panel title="Template da mensagem">
        <WhatsAppMessagePreview messages={messagePreviewData} />
      </Panel>
    </S.Container>
  );
};

TemplateView.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    denied_reason: PropTypes.string,
    status: PropTypes.string,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        buttons: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
          }),
        ),
      }),
    ),
    media: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
};

export default TemplateView;
