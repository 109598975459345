import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

const ContainerModifier = {
  active: () => css`
    transform: scale(1);
    opacity: 1;
    z-index: 10;
  `
}

export const Container = styled.div`
  ${({ theme, isActive }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;

    transform: scale(0.95);
    opacity: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: calc(100% - 9.5rem);
    border-radius: ${theme.border.radius.medium};

    box-shadow: 0 1rem 2rem -12px ${transparentize(0.75, theme.primary)};
    background-color: ${transparentize(0.05, theme.primary)};

    font-size: ${theme.font.sizes.small};

    transition: 0.2s ease-in-out all;

    ${isActive && ContainerModifier.active({ theme })};

    header {
      display: flex;
      align-items: center;

      height: 57px;
      padding: 0.5rem 1rem;

      color: ${theme.light};

      > button {
        .icon {
          margin-right: 0.25rem;
          color: ${theme.secondary};
        }
      }

      .label {
        flex: 1;
      }
    }

    .wrapperLocationsList {
      height: calc(100% - 57px - 1rem);
      padding: 0 1rem;
    }
  `}
`
