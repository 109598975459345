import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    button + button {
      margin-left: ${theme.spacings.medium};
    }
  `}
`;
