/**
 * Recebe um array de coordenadas e retorna o centroide dessa região.
 * @param {Array.<{lat: Number, lng: String}>} points Array de coordenadas
 * @returns {Array.<number[lat], number[lng]>} Retorna um array com as coordenadas do centroide da região.
 */

export const getCentroid = points => {
  const arr = points.map(item => [item.lat, item.lng]);

  let twoTimesSignedArea = 0;
  let cxTimes6SignedArea = 0;
  let cyTimes6SignedArea = 0;

  const { length } = arr;

  const x = i => arr[i % length][0];

  const y = i => arr[i % length][1];

  for (let i = 0; i < arr.length; i += 1) {
    const twoSA = x(i) * y(i + 1) - x(i + 1) * y(i);
    twoTimesSignedArea += twoSA;
    cxTimes6SignedArea += (x(i) + x(i + 1)) * twoSA;
    cyTimes6SignedArea += (y(i) + y(i + 1)) * twoSA;
  }

  const sixSignedArea = 3 * twoTimesSignedArea;

  return [
    cxTimes6SignedArea / sixSignedArea,
    cyTimes6SignedArea / sixSignedArea,
  ];
};
