import { DefaultTheme, FlattenSimpleInterpolation } from 'styled-components'

import alert from './alert'
import danger from './danger'
import defaultButtonTemplate from './default'
import deleteOutline from './deleteOutline'
import ghost from './ghost'
import icon from './icon'
import info from './info'
import light from './light'
import primary from './primary'
import primaryOutlined from './primaryOutlined'
import success from './success'
import transparent from './transparent'
import transparentDanger from './transparentDanger'
import transparentDark from './transparentDark'
import transparentLight from './transparentLight'
import transparentPrimary from './transparentPrimary'
import warning from './warning'
import warningOutlined from './warningOutlined'
import whatsapp from './whatsapp'

/**
 * Templates
 */

export type ButtonTemplatesProps = {
  theme: DefaultTheme
  isSelected?: boolean
}

type IButtonTemplates = {
  [key in ButtonTemplates]: (
    props: ButtonTemplatesProps
  ) => FlattenSimpleInterpolation
}

export const buttonTemplates: IButtonTemplates = {
  primary: props => primary(props),
  primaryOutlined: props => primaryOutlined(props),

  light: props => light(props),

  transparentDanger: props => transparentDanger(props),
  deleteOutline: props => deleteOutline(props),

  transparent: props => transparent(props),
  transparentPrimary: props => transparentPrimary(props),
  transparentDark: props => transparentDark(props),
  transparentLight: props => transparentLight(props),

  icon: props => icon(props),

  info: props => info(props),
  alert: props => alert(props),

  success: props => success(props),
  warning: props => warning(props),
  warningOutlined: props => warningOutlined(props),
  danger: props => danger(props),
  ghost: props => ghost(props),
  whatsapp: props => whatsapp(props)
}

/**
 * Default styles
 */

export type ContainerDefaultProps = ContainerProps &
  Pick<ButtonCoreProps, 'size'>

export type ButtonDefaultTemplateProps = {
  props: ContainerDefaultProps
} & ButtonTemplatesProps

type ButtonDefaultStyles = (
  props: ButtonDefaultTemplateProps
) => FlattenSimpleInterpolation

export const buttonDefaultStyles: ButtonDefaultStyles = props =>
  defaultButtonTemplate(props)
