import React, { useMemo, useState } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import VerticalTimeline from '~/components/VerticalTimeline'
import { useAlert } from '~/hooks/useAlert'
import useQuery from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { getAuditLogService } from '../../services/audit-log'
import { flattenData } from './lib/flattenQueryData'
import formatGroupByDate from './lib/formatGroupByDate'

const CampaignLogsPure = ({
  campaignId,
  requestOnLoad = false
}: CampaignLogsProps) => {
  const { t } = useAPPTranslation()

  // Lidando com modo de debug do componente. Se ativo, exibirá alguns valores raw
  const query = useQuery()
  const debug = Boolean(query?.get('debug') || false)

  // Estado para controlar se o painel está aberto e não. Assim podemos fazer a requisição só quando preciso.
  const [panelIsOpen, setPanelIstOpen] = useState(requestOnLoad)

  // Configuração da requisição
  const requestQueryParams: ResquestGetAuditLog = useMemo(
    () => ({ entity_id: campaignId, quantity: 10 }),
    [campaignId]
  )

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    enabled: panelIsOpen,
    queryKey: ['audit-log'],
    queryFn: async ({ pageParam }) => {
      const res = await getAuditLogService({
        page: pageParam,
        ...requestQueryParams
      })

      return res.data
    },
    initialPageParam: 1,
    getPreviousPageParam: ({ currentPage }) => currentPage - 1 ?? undefined,
    getNextPageParam: ({ currentPage, pages }) =>
      currentPage + 1 <= pages ? currentPage + 1 : undefined
  })

  // Lidando com alerta de erro
  const [AlertOnError] = useAlert(error)

  // Agrupando dados por data

  const groupedLogsByDate = useMemo(() => {
    if (!data?.pages) return []

    return formatGroupByDate({ data: flattenData(data), debug })
  }, [data, debug])

  const noData = useMemo(
    () =>
      !groupedLogsByDate?.length && status !== 'pending' && !isFetchingNextPage,
    [groupedLogsByDate?.length, isFetchingNextPage, status]
  )

  return (
    <Panel
      isCollapsible
      initialCollapsibleState={panelIsOpen}
      onCollapse={setPanelIstOpen}
      unmountContentIfCollpase
      title={t('rm:campaignChangeHistory')}
    >
      <Loading status={status === 'pending'}>
        {t('rm:loadingCampaignChangeHistory')}
      </Loading>

      <AlertOnError />

      <VerticalTimeline
        data={groupedLogsByDate}
        className="campaign-logs mh-3"
      />

      {noData && (
        <AlertInfo text={t('rm:notFoundCampaign')} template="warning" />
      )}

      {hasNextPage && (
        <footer>
          <Button
            customWidth="100%"
            loading={isFetchingNextPage}
            textLoading={`${t('rm:loadingMore')}...`}
            text={t('rm:loadingMore')}
            onClick={fetchNextPage}
            disabled={isFetchingNextPage}
          />
        </footer>
      )}

      <Loading
        status={status !== 'pending' && isFetching && !isFetchingNextPage}
      >
        {t('rm:updatingResults')}
      </Loading>
    </Panel>
  )
}

export default CampaignLogsPure
