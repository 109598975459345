import React, { useRef } from 'react';

import { Form } from '@unform/web';

import Table from '~/components/Table';

import fakeProducts from './fakeProducts.json';
import { schema } from './schema';
import { Container } from './styles';

const SelectedProductsTable = () => {
  const formRef = useRef(null);

  return (
    <Form ref={formRef} onSubmit={() => {}}>
      <Container>
        <Table schema={schema(formRef)} data={fakeProducts} />
      </Container>
    </Form>
  );
};

export default SelectedProductsTable;
