import { create } from './create';
import { dashboard } from './dashboard';
import { get } from './get';
import { list } from './list';
import { update } from './update';

export default {
  ...get,
  ...list,
  ...create,
  ...update,
  ...dashboard,
};
