import { FiltersSellOutKeysTypes, SET_FILTER } from './constants'

type SetFilterProps = {
  type: FiltersSellOutKeysTypes
  data: {
    value: string | null
    label: string
  } | null
}

export const setFilter = ({ type, data }: SetFilterProps) => ({
  type: SET_FILTER,
  payload: { type, data }
})
