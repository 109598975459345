import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'

import { useQuery } from '@tanstack/react-query'

import { AlertNew } from '~/components/AlertNew'
import EmptyPage from '~/components/empty-page'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { formatDateToString } from '~/helpers'
import { useGetFetchQuery } from '~/hooks/useGetFetchQuery'
import { useAppSelector } from '~/store/hooks'

import { formatIndustryReportHistoryCampaign } from '../../dtos/industry/campaign/reportHistory'
import {
  getCampaignHistoryReportService,
  getCampaignsReportHistoryKey
} from '../../services/report'
import { getSchema } from './schema'

interface Props {
  campaignType: CampaignAdType
}

const DailyHistoryTable = ({ campaignType }: Props) => {
  const { id } = useParams()

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const queryParams = useMemo(
    () => ({
      start_date: formatDateToString(startDate, 'YYYY-MM-DD'),
      end_date: formatDateToString(endDate, 'YYYY-MM-DD'),
      campaign_id: id
    }),
    [startDate, endDate, id]
  )

  const queryKey = useMemo(
    () => [getCampaignsReportHistoryKey, id, ...Object.values(queryParams)],
    [id, queryParams]
  )

  const cachedData = useGetFetchQuery(queryKey)

  const {
    data: response,
    isLoading: loading,
    error: error
  } = useQuery({
    enabled: !cachedData,
    queryKey: queryKey,
    queryFn: async () => {
      const { data } = await getCampaignHistoryReportService(queryParams)

      return formatIndustryReportHistoryCampaign(data)
    }
  })

  const data = cachedData || response

  return (
    <>
      {data?.raw?.length === 0 && (
        <EmptyPage
          title={t('rm:daily.history.table.emptyPage.title')}
          subtitle={t('rm:daily.history.table.emptyPage.subtitle')}
          svgPath="photos/orders-empty"
        />
      )}
      <Panel noPaddingBody isLoading={loading}>
        {error && (
          <AlertNew color="danger">
            {t('rm:daily.history.table.alert.error')}
          </AlertNew>
        )}

        {data?.raw && !!data?.raw?.length && (
          <Table
            schema={getSchema({ campaignType })}
            data={data.raw}
            sideBorder
          />
        )}
      </Panel>
    </>
  )
}

export default DailyHistoryTable
