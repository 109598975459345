import i18next from 'i18next'

import { CAPMPAIGNS_FILTERS_KEYS } from '../store/common/constants'

type AdTypeFilterConfig = (data: {
  types: CampaignAdType[]
  isLoading?: boolean
}) => GenericFilterProps

export const adTypeFilterConfig: AdTypeFilterConfig = ({
  types,
  isLoading = false
}) => {
  const adTypes: CampaignAdType[] = types.length
    ? types
    : ['product', 'banner', 'sponsored_brand', 'digital_signage']

  return {
    keyState: CAPMPAIGNS_FILTERS_KEYS.adType,
    placeholder: i18next.t('rm:filterByAdType'),
    loading: isLoading,
    options: adTypes.map(type => ({
      label: i18next.t(`rm:adTypes.${type}`),
      value: type
    }))
  }
}
