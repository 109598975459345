import { FILTERS_SEELOUT } from '~/store/modules/filtersSellOut/constants'

import { metabaseKeys } from './keysMetabase'

export default {
  metabaseType: metabaseKeys[FILTERS_SEELOUT.categories],
  filteredBy: [FILTERS_SEELOUT.supermarkets, FILTERS_SEELOUT.brands],
  placeholder: 'Filtrar por categorias',
  placeholderError: '🔴 Erro ao carregar categorias',
  labelMulti: 'categorias selecionadas',
  labelAll: 'Todas as categorias',
  labelSearchAnything: 'Pesquise alguma categoria',
  searching: 'Buscando categorias...',
  bigData: true
}
