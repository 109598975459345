import React from 'react'

import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import FormCustomer from '../_elements/FormCustomer'

const Customer = () => (
  <>
    {/* Meta <title> */}
    <PageTitle title="Clientes > Adicionar" />

    <HeaderPages
      title="Adicionar Cliente"
      backTo="/customers/list"
      labelBreadcrumb="Clientes"
    />

    <FormCustomer isCreate />
  </>
)

export default Customer
