/* eslint-disable no-underscore-dangle */
import * as types from '../../constants';

const index = {
  pending: `${types.DELETE_PAYMENT_METHODS_CONFIG}_PENDING`,
  fulfilled: `${types.DELETE_PAYMENT_METHODS_CONFIG}_FULFILLED`,
  rejected: `${types.DELETE_PAYMENT_METHODS_CONFIG}_REJECTED`,
};

export const cases = {
  [index.pending]: ({ draft, action }) => {
    draft.paymentMethodsConfigDeleteLoading = true;
    draft.paymentMethodsConfigDeleteID = action.values.id;
  },
  [index.fulfilled]: ({ draft, action }) => {
    const deletedID = draft.paymentMethodsConfigDeleteID;

    const filteredConfigList = draft.paymentMethodsConfigList.filter(
      item => item._id !== deletedID,
    );

    draft.paymentMethodsConfigList = filteredConfigList;
    draft.paymentMethodsConfigDeleteLoading = false;
  },
  [index.rejected]: ({ draft }) => {
    draft.paymentMethodsConfigDeleteLoading = false;
  },
};
