import { t } from 'i18next'

import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import CheckingAccountTable from '~/modules/retailMedia/components/CheckingAccountTable'

const WalletsList = () => {
  return (
    <>
      <PageTitle title={t('rm:wallet.page.title')} />

      <HeaderPages title={t('rm:Wallet')} subtitle={t('rm:wallet.subtitle')} />

      <CheckingAccountTable />
    </>
  )
}

export default WalletsList
