import React from 'react'

import { DatepickerHeader } from '~/components/datepicker-header'
import { Filters } from '~/components/Filters'
import { FilterSearch } from '~/components/FilterSearch'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import * as types from '../constants'
import OrdersTable from './elements/OrdersTable'

const OrdersList = () => (
  <>
    {/* Meta <title> */}
    <PageTitle title="Pedidos > Listagem" />

    <HeaderPages
      title="Listagem de pedidos"
      sideLeftComponent={
        <FilterSearch
          placeholder="Buscar Pedido (número, cliente, etc)"
          searchOnEnter={false}
          keyState={`${types.ORDER_REDUX_QUERY}`}
          live
        />
      }
      sideRightComponent={<DatepickerHeader maxEndDate={null} />}
      className="has-datepicker"
    />

    <Filters locations channel payment status />

    <OrdersTable />
  </>
)

export default OrdersList
