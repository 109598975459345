import React, { useMemo } from 'react'
import { FiChevronsRight, FiClock, FiUser } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { Icon } from '~/components/Icon'
import { WrapperShadow, Header, Content } from '~/components/WrapperShadow'
import { formatDateToString } from '~/helpers'
import { statusList } from '~/pages/orders/_status/order'

import PDVID from '../PDVID'

import * as S from './styles'

/**
 *
 * @param {object} orderData ID do pedido que servirá para montar o link do checkout
 */

const optionsCancelCode = {
  customer_canceled: {
    label: 'Desistência do cliente'
  },
  offer_stock_out: {
    label: 'Produto em falta'
  },
  wrong_location: {
    label: 'Pedido na loja errada'
  },
  duplicated_order: {
    label: 'Pedido duplicado'
  },
  other: {
    label: 'Outro'
  }
}

const PanelHistory = ({ activeFieldsRef }) => {
  const {
    orderData: {
      status = null,
      integration_id = null,
      shipments: [shipment] = [{}]
    }
  } = useSelector(state => state.order)

  const groupedStatusByDate = useMemo(() => {
    const statusFormatted = Object.values(statusList).map(status => ({
      type: status.type,
      Icon: status?.icon || FiChevronsRight,
      template: status.template
    }))

    const data = status?.reduce((prev, current) => {
      const currentSatus = statusFormatted.find(
        item => item.type === current.type
      )

      const date = formatDateToString(current.updatedAt, 'YYYY-MM-DD')

      const formattedCurrent = {
        ...(current || []),
        ...currentSatus
      }

      return {
        ...prev,
        [date]: [...(prev[date] || []), formattedCurrent]
      }
    }, {})

    return data
  }, [status])

  const DeliveryIcon = statusList.IN_DELIVERY.icon

  const firstInvoiceStatus = useMemo(() => {
    const invoiceStatus = status?.filter(
      item => item.type === 'waiting_invoice' || item.type === 'invoice_issued'
    )

    return invoiceStatus?.[0]?.id || null
  }, [status])

  return (
    <WrapperShadow ref={activeFieldsRef}>
      <Header>
        <div>
          <span>
            <Icon icon={FiClock} /> <strong>Histórico</strong>
          </span>
        </div>
      </Header>

      <Content>
        <S.Container className="history__status">
          {!!groupedStatusByDate &&
            Object.entries(groupedStatusByDate).map(([date, status]) => (
              <React.Fragment key={date}>
                <dt>{formatDateToString(date, 'dddd DD/MM/YYYY')}</dt>
                {status?.map(
                  (
                    {
                      id,
                      template,
                      createdAt,
                      label,
                      Icon = null,
                      operator_name = null,
                      user_name = null,
                      user_email = null,
                      type,
                      cancellation
                    },
                    index
                  ) => (
                    <S.Item key={id} template={template}>
                      <S.Time>{formatDateToString(createdAt, 'HH:mm')}</S.Time>

                      <S.Icon>{Icon ? <Icon /> : <FiChevronsRight />}</S.Icon>

                      <S.Title>
                        {label}
                        {[
                          statusList.IN_DELIVERY.type,
                          statusList.DELIVERED.type,
                          statusList.DELIVERY_ACCEPTED.type
                        ].includes(type) &&
                          shipment.carrier_name && (
                            <S.Operator>
                              <div className="icon">
                                <DeliveryIcon />
                              </div>
                              <span className="name">
                                {shipment.carrier_name}
                                {' - '}
                                {shipment.carrier_email}
                              </span>
                            </S.Operator>
                          )}
                        {type === statusList.DELIVERY_ACCEPTED.type &&
                          shipment.start_delivery_at && (
                            <S.Operator>
                              <span className="name">
                                Corrida iniciada:{' '}
                                {formatDateToString(
                                  shipment.start_delivery_at,
                                  'DD/MM/YYYY HH:mm'
                                )}
                              </span>
                            </S.Operator>
                          )}
                        {type === statusList.DELIVERY_ACCEPTED.type &&
                          shipment.items && (
                            <S.Operator>
                              <span className="name">
                                Caixas: {shipment.items}
                              </span>
                            </S.Operator>
                          )}
                        {(!!operator_name || !!user_name) && (
                          <>
                            <S.Operator>
                              <div className="icon">
                                <FiUser />
                              </div>
                              {user_name && (
                                <div className="name">{user_name}</div>
                              )}
                              {user_email && (
                                <div className="name">{user_email}</div>
                              )}
                              {operator_name && (
                                <div className="name">
                                  Operador: {operator_name}
                                </div>
                              )}
                            </S.Operator>
                            {cancellation && (
                              <S.Operator>
                                {type === statusList.canceled.type && (
                                  <div>
                                    {cancellation.code && (
                                      <span className="name">
                                        <strong className="name">
                                          Motivo:
                                        </strong>
                                        {
                                          optionsCancelCode[cancellation.code]
                                            .label
                                        }
                                      </span>
                                    )}
                                    {!!cancellation.note && (
                                      <span className="name">
                                        <strong className="name">
                                          Descrição:
                                        </strong>
                                        {cancellation.note}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </S.Operator>
                            )}
                          </>
                        )}
                      </S.Title>

                      <S.Aside>
                        <PDVID
                          currentIndex={index}
                          integrationId={integration_id}
                          firstInvoiceStatus={firstInvoiceStatus}
                          statusId={id}
                        />
                      </S.Aside>
                    </S.Item>
                  )
                )}
              </React.Fragment>
            ))}
        </S.Container>
      </Content>
    </WrapperShadow>
  )
}

export default PanelHistory
