import { formatNumberByType as format } from '../helpers'

export const formatMetrics: FormatMetrics = ({ data }) => ({
  conversionRate: format({ type: 'percent', value: data?.conversion_rate }),
  ecpm: format({ type: 'money', value: data?.ecpm }),
  impressions: format({ type: 'unit', value: data?.impressions }),
  views: format({ type: 'unit', value: data?.views }),
  clicks: format({ type: 'unit', value: data?.clicks }),
  requests: format({ type: 'unit', value: data?.requests }),
  conversions: format({
    type: 'unit',
    value: data?.conversions
  }),
  totalConversionsItemsQuantity: format({
    type: 'unit',
    value: data?.total_conversions_items_quantity
  }),
  income: format({
    type: 'money',
    value: data?.income
  }),
  totalSpent: format({
    type: 'money',
    value: data?.total_spent
  }),
  avgCpc: format({
    type: 'money',
    value: data?.avg_cpc
  }),
  avgCpm: format({
    type: 'money',
    value: data?.avg_cpm
  }),
  cpc: format({ type: 'money', value: data?.cpc }),
  cpa: format({ type: 'money', value: data?.cpa }),
  conversion_rate: format({
    type: 'percent',
    value: data?.conversion_rate
  }),
  ctr: format({ type: 'percent', value: data?.ctr }),
  roas: format({ type: 'unit', value: data?.roas }),
  adcost: format({ type: 'percent', value: data?.adcost }),
  totalCampaigns: format({ type: 'unit', value: data?.total_campaigns }),
  totalDailyBudget: format({ type: 'money', value: data?.total_daily_budget }),
  balance: format({ type: 'money', value: data?.balance })
})
