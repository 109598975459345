const prefix = '@retailMedia/industry/category'

export const CATEGORY_LIST = `${prefix}/LIST`
export const CATEGORYLIST_SET_PAGE = `${prefix}/LIST_SET_PAGE`
export const CATEGORY_LIST_PER_PAGE = `${prefix}/LIST_PER_PAGE`

export const CATEGORY_REDUX_QUERY = `${prefix}/REDUX_QUERY`

export const types = {
  list: CATEGORY_LIST,
  page: CATEGORYLIST_SET_PAGE,
  perPage: CATEGORY_LIST_PER_PAGE
}
