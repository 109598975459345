import styled, { css } from 'styled-components';

import { device } from '~/styles/device';

export const WrapperCards = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: ${theme.spacings.medium};

    @media ${device.tablet_max} {
      grid-template-columns: none;
    }

    .sent-this-month {
      color: ${theme.colors.neutralColorDark};
      font-weight: 400;
      font-size: ${theme.font.sizes.small};
    }

    .todayBalance {
      s {
        font-weight: ${theme.font.normal};
        color: ${theme.colors.gray};
        margin-right: ${theme.spacings.xxsmall};
        };
      }
    }
  `}
`;

export const WrapperProgressBar = styled.div`
  width: 100%;

  & > div {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
`;
