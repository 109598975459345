import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Container } from 'reactstrap'

import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { history } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import { useScroll } from '~/hooks/useScroll'

import { getOrderInfo, getOrderInfoRefresh } from '../actions'
import ButtonPrint from './elements/ButtonPrint'
import HeaderSide from './elements/HeaderSide'
import PanelCheckout from './elements/PanelCheckout'
import PanelCustomer from './elements/PanelCustomer'
import PanelDescription from './elements/PanelDescription'
import PanelExtras from './elements/PanelExtras'
import PanelHistory from './elements/PanelHistory'
import PanelMainInfos from './elements/PanelMainInfos'
import PanelMetadata from './elements/PanelMetadata'
import PanelNotes from './elements/PanelNotes'
import PanelPayment from './elements/PanelPayment'
import PanelShipment from './elements/PanelShipment'
import PDVID from './elements/PDVID'
import { PrintOrder } from './elements/PrintOrder'
import ProductsList from './elements/ProductsList'

import * as S from './styles'

const OrderInfo = () => {
  const { id: orderId } = useParams()

  const dispatch = useDispatch()

  const { getOrderLoading, updateLoading, orderData, error } = useSelector(
    state => state.order
  )

  const [AlertOnError] = useAlert(error)

  /**
   * Pegando dados do pedido na API
   */

  useEffect(() => {
    dispatch(getOrderInfo({ id: orderId }))
  }, [dispatch, orderId])

  const titlePage = useMemo(
    () =>
      orderData?.order_id
        ? `Pedido: ${orderData.order_id}`
        : 'Carregando pedido...',
    [orderData]
  )

  /**
   * Handle print order
   */
  const printRef = useRef()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contentToPrint = useMemo(() => printRef.current, [printRef.current])

  /**
   * Verificando se devemos fazer auto refresh do pedido
   * - Por enquanto apenas olhando status de delivery
   */
  const shouldAutoRefreshOrder = useMemo(() => {
    const { status } = orderData?.shipments?.[0] || {}

    const forbiddenStatus = ['open', 'canceled', 'returned', 'delivered']

    return !!(status && !forbiddenStatus.includes(status))
  }, [orderData])

  const getOrderTimer = useRef(null)

  useEffect(() => {
    if (shouldAutoRefreshOrder) {
      getOrderTimer.current = setInterval(async () => {
        dispatch(getOrderInfoRefresh({ id: orderId }))
      }, 10000)
    }

    return () => {
      clearInterval(getOrderTimer.current)
    }
  }, [dispatch, getOrderTimer, orderId, shouldAutoRefreshOrder])

  const [scrollToActiveFields, activeFieldsRef] = useScroll()

  return (
    <Container fluid className="p-0">
      {/* Meta <title> */}
      <PageTitle title={titlePage} />

      <HeaderPages
        title={titlePage}
        goBack={() => history.back()}
        labelBreadcrumb="Voltar"
        sideRightComponent={<HeaderSide contentToPrint={contentToPrint} />}
      />

      <AlertOnError />

      <Loading status={getOrderLoading}>
        Carregando informações do pedido
      </Loading>

      <Loading status={updateLoading}>
        Atualizando informações do pedido
      </Loading>

      {!getOrderLoading && orderData?.order_id && (
        <>
          {/* Data / Canal de origem / Loja / Status */}
          <PanelMainInfos scrollToActiveFields={scrollToActiveFields} />

          {/* Painel para lidar com a exibição do link de checkout */}
          <PanelCheckout />

          {/* Lista de produtos */}
          <ProductsList />

          {/* Descrição do pedido */}
          <PanelNotes />

          <S.SectionFlex>
            {/* Descrição do pedido */}
            <PanelDescription />

            {/* Informações extra do pedido */}
            <PanelExtras />
          </S.SectionFlex>

          <S.SectionFlex>
            <PanelCustomer />

            <PanelShipment />
          </S.SectionFlex>

          <PanelPayment />

          <PanelHistory activeFieldsRef={activeFieldsRef} />

          <PanelMetadata />

          <ButtonPrint
            label="Imprimir pedido"
            contentToPrint={contentToPrint}
            loading={getOrderLoading}
            hideMobile={false}
            size="xsmall"
          />
        </>
      )}

      <PrintOrder printRef={printRef} />
    </Container>
  )
}

export default OrderInfo
