import React, { useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { callbackButtons } from '~/components/unform/FormGroup/MessageWithMediaAndPreview/callbackButtons'
import { formatDateToHour, formatDateToString } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import campaignActions from '~/modules/messagingCampaigns/store/campaign/actions'
import templateActions from '~/modules/messagingCampaigns/store/template/actions'

import FormCampaign from '../_elements/FormCampaign'
import HeaderButtons from '../_elements/HeaderButtons'

const CampaignsEdit = () => {
  const dispatch = useDispatch()

  const { id } = useParams()

  const {
    campaign: {
      item: { data, loading: campaignLoading, error }
    },
    template: {
      item: { data: templateData = {}, loading: templateLoading }
    }
  } = useSelector(state => state.messagingCampaigns)

  const loading = campaignLoading || templateLoading

  const [AlertOnError] = useAlert(error)

  const loadCampaign = useCallback(() => {
    dispatch(campaignActions.get(id))
  }, [dispatch, id])

  useEffect(() => {
    loadCampaign()
  }, [loadCampaign])

  useEffect(() => {
    if (data?.template_id) {
      dispatch(templateActions.get(data?.template_id))
    }
  }, [data?.template_id, dispatch])

  const initialData = useMemo(() => {
    if (!data || !templateData) return {}

    const { _id: templateId } = templateData
    const { schedule_date, expire_at } = data

    const getButtonsData = buttons => {
      const data = buttons.reduce((acc, item) => {
        const text = item.text
        const buttonData = callbackButtons.find(
          cbButton => cbButton.label === text
        )
        const key = buttonData.id

        return { ...acc, [key]: true }
      }, {})

      return data
    }

    return {
      ...data,
      template_id: templateId,
      scheduleMode: 'sendLater',
      start_date: formatDateToString(schedule_date, 'YYYY-MM-DD'),
      start_hour: formatDateToHour(schedule_date),
      end_date: formatDateToString(expire_at, 'YYYY-MM-DD'),
      end_hour: formatDateToHour(expire_at),
      destinations: data.destinations.reduce(
        (acc, item) => `${acc}${item.customer_number}, `,
        ''
      ),
      callbacks: [
        {
          ...(data.callbacks?.[0] ? { ...data.callbacks[0] } : {}),
          // eslint-disable-next-line max-len
          message: data.callbacks?.[0]?.message,
          buttons: data.callbacks?.[0]?.buttons
            ? getButtonsData(data.callbacks?.[0]?.buttons)
            : null,
          buttonsRaw: data.callbacks?.[0]?.buttons
        }
      ]
    }
  }, [data, templateData])

  return (
    <>
      {/* Meta <title> */}
      <PageTitle title="Campanhas > Editar" />

      <HeaderPages
        title="Editar de campanhas"
        backTo="/messaging-campaigns/list"
        labelBreadcrumb="Voltar às campanhas"
        sideRightComponent={<HeaderButtons isEditPage />}
      />

      <AlertOnError />

      <Loading status={loading}>Carregando informações da campanha</Loading>

      {!loading && initialData && (
        <FormCampaign initialData={initialData} isEdit />
      )}
    </>
  )
}

export default CampaignsEdit
