import React, { useCallback, useState } from 'react'
import { FiEdit, FiTrash2, FiX } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'
import { useDeleteConfirmation } from '~/hooks/useDeleteConfirmation'
import { deleteTag } from '~/modules/chat/store/actions'

import FormTag from '../Form'

import * as S from './styles'

const Tag = ({ data, isAdmin }) => {
  const [editMode, setEditMode] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const dispatch = useDispatch()

  const [DeleteConfirmation, activeConfirmation, setActiveConfirmation] =
    useDeleteConfirmation()

  const handleEditMode = () => setEditMode(prevState => !prevState)

  const handleDelete = useCallback(() => {
    setLoadingDelete(true)

    dispatch(deleteTag(data.tagId))

    setActiveConfirmation()
    setLoadingDelete(false)
  }, [data.tagId, dispatch, setActiveConfirmation])

  return (
    <S.Container
      tagColor={data?.color}
      editMode={editMode}
      deleteMode={activeConfirmation}
      isDeleted={data?.deleted}
    >
      <header>
        <div className="info">
          <strong>{data.label}</strong>
        </div>

        <aside>
          {isAdmin && (
            <>
              {editMode ? (
                <Button
                  size="xsmall"
                  iconLeft={<FiX />}
                  text="Cancelar edição"
                  customWidth="auto"
                  template="warning"
                  onClick={handleEditMode}
                />
              ) : (
                <Button
                  size="xsmall"
                  iconLeft={<FiEdit />}
                  text="Editar"
                  customWidth="auto"
                  template="transparent"
                  onClick={handleEditMode}
                />
              )}

              <Button
                template="transparentDanger"
                iconLeft={<FiTrash2 />}
                text="Apagar"
                customWidth="auto"
                size="xsmall"
                onClick={setActiveConfirmation}
                loading={loadingDelete}
              />
            </>
          )}

          {data?.saving && <Spinner />}
        </aside>
      </header>

      {editMode && (
        <S.ContainerEdit>
          <FormTag initialData={data} onSuccess={handleEditMode} />
        </S.ContainerEdit>
      )}

      {isAdmin && (
        <div className="wrapperConfirmDelete">
          <DeleteConfirmation
            active={activeConfirmation}
            onDelete={handleDelete}
            onCancel={setActiveConfirmation}
            yesMsg="Confirmar"
            cancelMsg="Cancelar"
            loading={loadingDelete}
            elementPosition="absolute"
          >
            Excluir a tag: <strong>{data.label}</strong>?
          </DeleteConfirmation>
        </div>
      )}
    </S.Container>
  )
}

export default Tag

Tag.propTypes = {
  data: PropTypes.shape({
    tagId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.string,
    saving: PropTypes.bool,
    deleted: PropTypes.bool
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired
}
