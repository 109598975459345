import { produce } from 'immer';
import moment from 'moment';

import * as types from './dashboard-constants';

const INITIAL_STATE = {
  cardsInfo: {
    data: [
      {
        'Orders.margin': 0,
        'Orders.gmv': 0,
        'Orders.ordersCount': 0,
        'Orders.averageTicket': 0,
      },
    ],
  },
  cardsLoading: false,
  gmvPerDayInfo: {},
  gmvPerDayLoading: false,
  marginPerDayInfo: {},
  marginPerDayLoading: false,
  topProductsInfoList: [],
  topProductsLoading: false,
  topCustomersInfoList: [],
  topCustomersLoading: false,
};

const formatLabel = data => {
  const {
    'Orders.createdAt.hour': hour = null,
    'Orders.createdAt': date = null,
  } = data;

  const label = {
    value: hour || date,
    format: hour ? 'HH:mm' : 'DD/MM/YY',
  };

  return moment(label.value).format(label.format);
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.DASHBOARD_CARDS}_PENDING`:
        draft.cardsLoading = true;
        break;
      case `${types.DASHBOARD_CARDS}_FULFILLED`:
        draft.cardsInfo = action.payload;
        draft.cardsLoading = false;
        break;
      case `${types.DASHBOARD_CARDS}_REJECTED`:
        draft.cardsLoading = false;
        break;

      case `${types.DASHBOARD_GMV_PER_DAY}_PENDING`:
        draft.gmvPerDayLoading = true;
        break;
      case `${types.DASHBOARD_GMV_PER_DAY}_FULFILLED`: {
        const newData = {
          gmv: [],
          orders: [],
          date: [],
        };

        action.payload.data.forEach(item => {
          newData.gmv.push(item['Orders.gmv']);
          newData.orders.push(item['Orders.ordersCount']);
          newData.date.push(formatLabel(item));
        });

        draft.gmvPerDayInfo = newData;

        draft.gmvPerDayLoading = false;
        break;
      }
      case `${types.DASHBOARD_GMV_PER_DAY}_REJECTED`:
        draft.gmvPerDayLoading = false;
        break;

      case `${types.DASHBOARD_MARGIN_PER_DAY}_PENDING`:
        draft.marginPerDayLoading = true;
        break;
      case `${types.DASHBOARD_MARGIN_PER_DAY}_FULFILLED`: {
        const newData = {
          margin: [],
          date: [],
        };
        const dataChartFormat = action.payload.data.map(el => {
          newData.margin.push(el['Orders.margin']);
          newData.date.push(moment(el['Orders.closedAt']).format('DD/MM/YY'));
        });
        draft.marginPerDayInfo = newData;
        draft.marginPerDayLoading = false;
        break;
      }
      case `${types.DASHBOARD_MARGIN_PER_DAY}_REJECTED`:
        draft.marginPerDayLoading = false;
        break;

      case `${types.DASHBOARD_TOP_PRODUCTS}_PENDING`:
        draft.topProductsLoading = true;
        break;
      case `${types.DASHBOARD_TOP_PRODUCTS}_FULFILLED`: {
        draft.topProductsInfoList = action.payload.data;
        draft.topProductsLoading = false;
        break;
      }
      case `${types.DASHBOARD_TOP_PRODUCTS}_REJECTED`:
        draft.topProductsLoading = false;
        break;
      case `${types.DASHBOARD_TOP_CUSTOMERS}_PENDING`:
        draft.topCustomersLoading = true;
        break;
      case `${types.DASHBOARD_TOP_CUSTOMERS}_FULFILLED`: {
        draft.topCustomersInfoList = action.payload.data;
        draft.topCustomersLoading = false;
        break;
      }
      case `${types.DASHBOARD_TOP_CUSTOMERS}_REJECTED`:
        draft.topCustomersLoading = false;
        break;
      default:
        return state;
    }
  });
