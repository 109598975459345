import React, { useState, useCallback, useLayoutEffect } from 'react';

import PropTypes from 'prop-types';

import Panel from '~/components/Panel';
import { Checkbox, Label } from '~/components/unform';

import LimitsPerCustomer from '../LabelWithInput/LimitsPerCustomer';
import LimitsTotal from '../LabelWithInput/LimitsTotal';
import TriggerQuantity from '../LabelWithInput/TriggerQuantity';
import TriggerTotalMinValue from '../LabelWithInput/TriggerTotalMinValue';

import * as S from './styles';

const PromotionRules = ({ initialData, onChange, selectedTarget }) => {
  const [rules, setRules] = useState({});

  useLayoutEffect(
    () => setRules({ ...initialData.promotionRules }),
    [initialData],
  );

  /**
   * Function
   */
  const handleActivate = useCallback(
    e => {
      const { checked, name } = e.target;

      const key = name.split('.')[1];

      const value = { [key]: checked };

      const newRules = old => {
        const values = { ...old, ...value };

        onChange(values);

        return values;
      };

      setRules(newRules);
    },
    [onChange],
  );

  return (
    <Panel title="Regras da promoção" isCollapsible>
      <S.Group>
        Defina as regras que a promoção terá, como limite de uso, valor mínimo e
        outros.
      </S.Group>

      <S.Group>
        <Label text="Limite de uso" />

        <S.CheckWithInputInline>
          <Checkbox
            label={<LimitsTotal active={!!rules?.totalUsage} />}
            name="promotionRules.totalUsage"
            id="promotionRules.totalUsage"
            onChange={handleActivate}
          />
        </S.CheckWithInputInline>

        <S.CheckWithInputInline>
          <Checkbox
            label={<LimitsPerCustomer active={!!rules?.perCustomer} />}
            name="promotionRules.perCustomer"
            id="promotionRules.perCustomer"
            onChange={handleActivate}
          />
        </S.CheckWithInputInline>
      </S.Group>

      <S.Group>
        <Label text="Valor mínimo" />

        <S.CheckWithInputInline>
          <Checkbox
            label={
              <TriggerTotalMinValue
                active={!!rules?.totalValue}
                isSelectedOffers={selectedTarget === 'selected'}
              />
            }
            name="promotionRules.totalValue"
            id="promotionRules.totalValue"
            onChange={handleActivate}
          />
        </S.CheckWithInputInline>
      </S.Group>

      <S.Group>
        <Label text="Quantidade mínima de itens no pedido" />
        <S.CheckWithInputInline>
          <Checkbox
            label={
              <TriggerQuantity
                active={!!rules?.orderItems}
                isSelectedOffers={selectedTarget === 'selected'}
              />
            }
            name="promotionRules.orderItems"
            id="promotionRules.orderItems"
            onChange={handleActivate}
          />
        </S.CheckWithInputInline>
      </S.Group>
    </Panel>
  );
};

export default PromotionRules;

PromotionRules.propTypes = {
  initialData: PropTypes.shape({
    promotionRules: PropTypes.shape({}),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedTarget: PropTypes.string.isRequired,
};
