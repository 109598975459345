import React from 'react';
import { FiMapPin } from 'react-icons/fi';

import PropTypes from 'prop-types';

const ColumnExtra = ({ locationIds = [] }) => {
  const labelLocations = () => {
    const length = locationIds?.length || 0;

    switch (true) {
      case length === 1:
        return `${length} local selecionado`;

      case length > 1:
        return `${length} locais selecionados`;

      default:
        return 'Todos os locais';
    }
  };

  return (
    <div style={{ lineHeight: 1 }}>
      <div>
        <small>
          <FiMapPin /> {labelLocations()}
        </small>
      </div>
    </div>
  );
};

export default ColumnExtra;

ColumnExtra.propTypes = {
  locationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};
