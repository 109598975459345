import * as types from '../../constants';

const index = {
  pending: `${types.GET_PAYMENT_METHODS_CONFIG}_PENDING`,
  fulfilled: `${types.GET_PAYMENT_METHODS_CONFIG}_FULFILLED`,
  rejected: `${types.GET_PAYMENT_METHODS_CONFIG}_REJECTED`,
};

export const cases = {
  [index.pending]: ({ draft }) => {
    draft.paymentMethodsConfigLoading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.paymentMethodsConfigLoading = false;
    draft.paymentMethodsConfigList = action.payload;
    draft.paymentMethodsConfigError = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.paymentMethodsConfigLoading = false;
    draft.paymentMethodsConfigError = action.payload;
  },
};
