import { confirmedCartItemModel } from './confirmedCartItemModel';

const formatPendingItems = item => ({
  id: item.item_id,
  status: item.status,
  match: item.name,
});

export const pendingCartItemModel = ({ item }) => {
  function hasId(item) {
    return !!(
      item?.main_product?.product_id || item?.main_product?.external_id
    );
  }

  if (hasId(item)) {
    return confirmedCartItemModel({ item });
  }

  return formatPendingItems(item);
};
