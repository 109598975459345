import { alternativeProducts } from './alternativesProducts';
import { formatPrices } from './pricesItem';
import { isWeight, weightPerUnit } from './tools';

export const confirmedCartItemModel = ({ item }) => ({
  id: item.item_id,
  // Deixaremos de usar product_id.
  productId: item.main_product?.product_id || null,
  externalId: item.main_product?.external_id || null,
  // External id será o identificador do produto
  gtin: item.main_product?.gtin || null,
  // promotion: promotionCartItemModel(item?.main_product?.promotion || null),
  status: item.status,
  match: item.name,
  note: item.detail,
  query: item.query,
  title: item?.main_product?.name,
  quantity: item.quantity,
  sizePack: item.main_product?.size_pack,
  isWeight: isWeight(item.main_product?.unit_measurement),
  weightPerUnit: weightPerUnit(
    item.main_product?.unit_measurement,
    item.main_product?.weight_per_unit,
  ),
  chosenUnitMeasurement: item?.unit_measurement || 'un',
  unitMeasurement: item.main_product?.unit_measurement || 'un',
  assets: { thumbnail: item.main_product?.asset?.[0] },
  prices: formatPrices(item),
  tags: item.main_product?.tags,
  alternativeProducts: alternativeProducts(item.alternative_products),
});
