import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
  }
`

export const Label = styled.small`
  margin-top: 4px;
`
export const WrapperStatusBullet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const WrapperStatusWithSwitch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .toggle {
    margin-bottom: 4px;
  }
`
