import React, { useState, useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'

import { Button } from '../../Buttons'
import * as S from '../styles'
import ConfirmationModalInput from './ConfirmationModalInput'

const ConfirmationModalForm = ({
  onSubmit,
  confirmationButton,
  confirmationValue,
  submitLoading
}) => {
  const [input, setInput] = useState('')
  const canSubmit = useMemo(
    () => !confirmationValue || input === confirmationValue,
    [confirmationValue, input]
  )

  const handleInputChange = e => {
    setInput(e.target.value)
  }

  const handleFormSubmit = useCallback(
    e => {
      e.preventDefault()

      if (canSubmit) onSubmit()
    },
    [canSubmit, onSubmit]
  )

  return (
    <form onSubmit={handleFormSubmit}>
      {confirmationValue && (
        <ConfirmationModalInput
          onChange={handleInputChange}
          value={input}
          confirmationValue={confirmationValue}
        />
      )}
      <S.ButtonContainer hasInput={!!confirmationValue}>
        <Button
          template="danger"
          customWidth="100%"
          type="submit"
          disabled={!canSubmit}
          loading={submitLoading}
        >
          {confirmationButton}
        </Button>
      </S.ButtonContainer>
    </form>
  )
}

ConfirmationModalForm.defaultProps = {
  confirmationButton: 'Confirmar',
  confirmationValue: ''
}

ConfirmationModalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  confirmationValue: PropTypes.string,
  confirmationButton: PropTypes.string
}

export default ConfirmationModalForm
