import { produce } from 'immer'

import * as types from './constants'

// type of INITIAL_STATE named UserState

const INITIAL_STATE = {
  loadingSaveUser: false,
  loadingListUsers: false,
  loadingResendInvite: false,
  loadingEditUser: false,
  loadingRemoveUser: false,

  usersList: [],
  listUsersPerPage: 500,
  page: 0,
  total: 0,

  userAccounts: null,
  userAccountsLoading: false,
  userAccountsError: null,

  setDefaultUserAccountLoading: false,
  setDefaultUserAccountError: null
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.USERS_SUBMIT_USER}_PENDING`: {
        draft.loadingSaveUser = true
        break
      }
      case `${types.USERS_SUBMIT_USER}_FULFILLED`: {
        draft.loadingSaveUser = false
        break
      }
      case `${types.USERS_SUBMIT_USER}_REJECTED`: {
        draft.loadingSaveUser = false
        break
      }

      case `${types.USERS_LIST_USERS}_PENDING`: {
        draft.loadingListUsers = true
        break
      }
      case `${types.USERS_LIST_USERS}_FULFILLED`: {
        draft.loadingListUsers = false
        draft.total = action.payload?.total || action.payload.length

        const usersData = action.payload?.data || action.payload

        const compareRole = (a, b) => {
          if (a.role < b.role) return -1

          if (a.role > b.role) return 1

          return 0
        }

        const compareName = (a, b) => {
          if (a.name < b.name) return -1

          if (a.name > b.name) return 1

          return 0
        }

        const userssortByName = usersData.slice().sort(compareName)
        const usersSortByRole = userssortByName.sort(compareRole)

        draft.usersList = usersSortByRole
        break
      }
      case `${types.USERS_LIST_USERS}_REJECTED`: {
        draft.loadingListUsers = false
        break
      }

      case `${types.USERS_RESEND_INVITE}_PENDING`: {
        draft.loadingResendInvite = true
        break
      }
      case `${types.USERS_RESEND_INVITE}_FULFILLED`: {
        draft.loadingResendInvite = false
        break
      }
      case `${types.USERS_RESEND_INVITE}_REJECTED`: {
        draft.loadingResendInvite = false
        break
      }

      case `${types.USERS_EDIT}_PENDING`: {
        draft.loadingEditUser = true
        break
      }
      case `${types.USERS_EDIT}_FULFILLED`: {
        draft.loadingEditUser = false
        break
      }
      case `${types.USERS_EDIT}_REJECTED`: {
        draft.loadingEditUser = false
        break
      }

      case `${types.USERS_REMOVE}_PENDING`: {
        draft.loadingRemoveUser = true
        break
      }
      case `${types.USERS_REMOVE}_FULFILLED`: {
        draft.loadingRemoveUser = false
        break
      }
      case `${types.USERS_REMOVE}_REJECTED`: {
        draft.loadingRemoveUser = false
        break
      }

      // User accounts
      case `${types.USER_ACCOUNTS_GET}_PENDING`: {
        draft.userAccountsLoading = true
        break
      }
      case `${types.USER_ACCOUNTS_GET}_FULFILLED`: {
        const data = action.payload

        const compareName = (a, b) => {
          if (a.name < b.name) return -1

          if (a.name > b.name) return 1

          return 0
        }

        const compareType = (a, b) => {
          if (a.type < b.type) return -1

          if (a.type > b.type) return 1

          return 0
        }

        const accountsSortByName = data.sort(compareName)
        const accountsSortByType = accountsSortByName.sort(compareType)
        const sortByDefaultAccount = accountsSortByType.sort(
          (a, b) => b.is_default_account - a.is_default_account
        )

        draft.userAccounts = sortByDefaultAccount
        draft.userAccountsLoading = false
        draft.userAccountsError = null
        break
      }
      case `${types.USER_ACCOUNTS_GET}_REJECTED`: {
        draft.userAccountsLoading = false
        draft.userAccountsError = action.payload
        break
      }

      // Set default user account
      case `${types.USER_DEFAULT_ACCOUNT_SET}_PENDING`: {
        draft.setDefaultUserAccountLoading = true
        break
      }
      case `${types.USER_DEFAULT_ACCOUNT_SET}_FULFILLED`: {
        const currentUserAccountList = draft.userAccounts

        const response = action.payload as UserChangeAccountDefaultResponse

        const { id = null, is_default_account } = response?.updated || {}

        const updatedUserAccountId = id
        const newStatus = is_default_account

        let updatedListUserAccounts = []

        if (newStatus === false) {
          updatedListUserAccounts = currentUserAccountList.map(
            (item: UserAccount) =>
              item.user_account_id === updatedUserAccountId
                ? { ...item, is_default_account: newStatus }
                : item
          )
        }

        if (newStatus === true) {
          updatedListUserAccounts = currentUserAccountList.map(
            (item: UserAccount) =>
              item.user_account_id === updatedUserAccountId
                ? { ...item, is_default_account: newStatus }
                : { ...item, is_default_account: false }
          )
        }

        draft.userAccounts = updatedListUserAccounts
        draft.setDefaultUserAccountLoading = false
        draft.setDefaultUserAccountError = null
        break
      }
      case `${types.USER_DEFAULT_ACCOUNT_SET}_REJECTED`: {
        draft.setDefaultUserAccountLoading = false
        draft.setDefaultUserAccountError = action.payload
        break
      }

      default:
        return state
    }
  })
