import React, { useCallback, useMemo, useRef } from 'react'
import { FiGlobe, FiLock } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { FormGroup, Input, Textarea } from '~/components/unform'
import { Radio } from '~/components/unform/Radio'
import { getRole, getUserId } from '~/helpers'
import {
  createTemplate,
  updateTemplate
} from '~/store/modules/chatTemplates/actions'

import * as S from './styles'

const FormTag = ({ initialData, onSuccess }) => {
  const formRef = useRef(null)

  const isEdit = useMemo(() => !!initialData, [initialData])
  const isAdmin = useMemo(() => getRole() === 'admin', [])
  const userID = useMemo(() => getUserId(), [])

  const { loadingSaving, loadingCreate } = useSelector(
    ({ chatTemplates }) => chatTemplates
  )

  const loading = useMemo(
    () => (isEdit ? loadingSaving : loadingCreate),
    [isEdit, loadingCreate, loadingSaving]
  )

  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        data.global = data.global === 'true'
        data.user_id = data.global ? null : userID

        dispatch(
          isEdit
            ? updateTemplate({ ...initialData, ...data })
            : createTemplate(data)
        )

        onSuccess && onSuccess()
      } catch (err) {
        console.error(err)
      }
    },
    [userID, dispatch, isEdit, initialData, onSuccess]
  )

  const handleResetForm = useCallback(() => formRef.current.reset(), [formRef])

  const textButton = useMemo(
    () => (initialData ? 'Salvar alteração' : 'Salvar'),
    [initialData]
  )

  const optionsVisibility = [
    {
      id: `visibility_global_${initialData?.templateId || 'new'}`,
      value: true,
      label: 'Global',
      icon: <FiGlobe />,
      description:
        'Uma mensagem global pode ser vista por todos usuários da conta'
    },
    {
      id: `visibility_private_${initialData?.templateId || 'new'}`,
      value: false,
      label: 'Privada',
      icon: <FiLock />,
      description:
        'Uma mensagem privada pode ser vista apenas pelo perfil que criou.'
    }
  ]

  const handleInitialData = useMemo(() => {
    const data = {
      ...(initialData || {}),
      global: initialData ? !initialData.user_id : false
    }

    return data
  }, [initialData])

  return (
    <S.WrapperForm>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={handleInitialData}
      >
        <FormGroup>
          <label htmlFor="label">Título da mensagem pronta</label>
          <Input
            id="title"
            name="title"
            placeholder="Insira o título da mensagem pronta"
            required
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="type">Texto da mensagem pronta</label>
          <Textarea
            id="message"
            name="message"
            placeholder="Insira uma messagem."
            required
          />
        </FormGroup>

        {isAdmin && (
          <FormGroup>
            <label htmlFor="type">Controle de acesso</label>
            <Radio options={optionsVisibility} name="global" required />
          </FormGroup>
        )}

        <footer>
          <Button
            type="button"
            onClick={handleResetForm}
            text="Limpar formulário"
            template="transparent"
            size="small"
          />
          <Button
            type="submit"
            text={textButton}
            loading={loading}
            textLoading="Salvando..."
            template="primary"
            size="small"
          />
        </footer>
      </Form>
    </S.WrapperForm>
  )
}

export default FormTag

FormTag.propTypes = {
  initialData: PropTypes.shape({
    templateId: PropTypes.string,
    user_id: PropTypes.string
  }),
  onSuccess: PropTypes.func
}

FormTag.defaultProps = {
  initialData: null,
  onSuccess: null
}
