import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ isFixed = true }) =>
    !!isFixed &&
    css`
      position: fixed;
      bottom: 1rem;
      left: 1rem;
    `}

  display: inline-flex;
  flex-direction: column;
  padding: 0.3rem 0.5rem;
  background-color: rgb(40, 42, 54);
  color: rgb(80, 250, 123);
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.8rem;
  font-weight: 900;

  font-family: 'Fira Code', monospace;

  small {
    display: flex;
    justify-content: flex-start;
    padding: 0.1rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }

    strong {
      min-width: 5rem;
      color: rgb(248, 248, 242);
      margin-right: 1rem;
    }
  }
`
