import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Buttons'

import * as S from './styles'

interface Props {
  children: ReactNode
  content: ReactNode
  title: string
  handleShowPopover: (value: boolean) => void
  showPopover: boolean
  handleCancel: () => void
}

export const NewPopover = ({
  content,
  children,
  title,
  handleShowPopover,
  showPopover,
  handleCancel
}: Props) => {
  const popoverRef = useRef(null)
  const containerRef = useRef(null)

  const [coords, setCoords] = useState({})

  const togglePopover = useCallback(
    e => {
      const rect = e.target.getBoundingClientRect()

      setCoords({
        left: rect.x + rect.width / 2,
        top: rect.y + window.scrollY
      })

      handleShowPopover(state => !state)
    },
    [handleShowPopover]
  )

  const closed = useCallback(() => {
    handleShowPopover(false)
    handleCancel()
    setCoords({})
  }, [handleCancel, handleShowPopover])

  const closePopover = useCallback(
    event => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        closed()
      }
    },
    [closed]
  )

  useEffect(() => {
    window.addEventListener('mousedown', closePopover)
    return () => {
      window.removeEventListener('mousedown', closePopover)
    }
  }, [closePopover])

  return (
    <S.Container ref={containerRef}>
      <div className="popover-content" onClick={togglePopover}>
        {content}
      </div>

      {showPopover &&
        createPortal(
          <S.ContainerPortal style={{ ...coords }}>
            <div ref={popoverRef}>
              <S.TitleHeader>
                <h4>{title}</h4>
                <Button template="transparent" onClick={closed}>
                  <FiX />
                </Button>
              </S.TitleHeader>
              {children}
            </div>
          </S.ContainerPortal>,
          document.body
        )}
    </S.Container>
  )
}
