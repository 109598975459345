import styled from 'styled-components';

export const WrapperInput = styled.div`
  padding-bottom: 10px;
`;

export const WrapperButtons = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
`;
