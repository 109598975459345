import { Scope } from '@unform/core'

import { FormGroup } from '~/components/unform'

import FormatQuantityAds from '../FormQuantity'

const FormatProduct: React.FC = () => (
  <FormGroup className="span-full">
    <Scope path="product">
      <FormatQuantityAds />
    </Scope>
  </FormGroup>
)

export default FormatProduct
