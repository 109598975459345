import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import BadgeIsValid from '~/components/BadgeIsValid'
import { ButtonNavLink } from '~/components/Buttons'
import Panel from '~/components/Panel'
import { FormGroup } from '~/components/unform'
import Select from '~/components/unform/ReactSelect'
import { history } from '~/helpers'
import useQuery from '~/hooks/useQuery'
import { useAppSelector } from '~/store/hooks'

import { listAdvertiserPublisher } from '../../store/industry/advertiserPublisher/actions'
import AlertBrands from '../AlertBrands'
import BalanceByPublisher from '../BalanceByPublisher'

type PublishersFieldsetProps = {
  onChange(publisher: { value: string; label: string }): void
  description?: string
  parentLink?: string
  showBrands?: boolean
}

const PublishersFieldset = ({
  onChange,
  description: descriptionRaw,
  parentLink = '/retail-media/campaign/create',
  showBrands = true
}: PublishersFieldsetProps) => {
  const dispatch = useDispatch()
  const query = useQuery()

  const { t } = useTranslation()
  const description =
    descriptionRaw || t('rm:campaign.create.publisherdescription')

  /**
   * Lógica de apoio
   */
  const rmid = useMemo(() => query.get('rmid') || null, [query])

  /**
   * Lógica para a funcionalidade principal
   */
  useEffect(() => {
    dispatch(listAdvertiserPublisher({ count: false, quantity: 5000 }))
  }, [dispatch])

  const {
    industry: {
      advertiserPublisher: {
        list: { data, loading }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  /**
   * Tratando casos com apenas um publisher
   */
  const hasOnlyOnePublisher = data?.publishers?.length === 1

  useEffect(() => {
    if (!rmid && hasOnlyOnePublisher) {
      history.push(`${parentLink}?rmid=${data?.publishers[0].publisher_id}`)
    }
  }, [data?.publishers, hasOnlyOnePublisher, parentLink, rmid])

  const options = useMemo(() => {
    if (!data || !data.publishers.length) return []

    const formattedData = data.publishers?.map(item => ({
      label: item.publisher_name,
      value: item.publisher_id
    }))

    return formattedData
  }, [data])

  const defaultValue = useMemo(
    () => (rmid ? options.filter(item => item.value === rmid) : null),
    [rmid, options]
  )

  const handleChange = useCallback(
    (publisher: { value: string; label: string }) => {
      history.push(`${parentLink}?rmid=${publisher.value}`)
      onChange(publisher)
    },
    [onChange, parentLink]
  )

  return (
    <Panel
      title={t('rm:Publisher')}
      description={description}
      className="form-grid"
      iconLeft={<BadgeIsValid isValid={!!defaultValue?.length} />}
      isCollapsible
    >
      <FormGroup className="span2">
        <Select
          placeholder={t('rm:campaign.create.publisherplaceholder')}
          name="publisher_id"
          options={options}
          onChange={handleChange}
          isClearable={false}
          required
          defaultMenuIsOpen={!rmid}
          disabled={rmid}
          loading={loading}
          value={defaultValue}
        />
      </FormGroup>

      {rmid && !hasOnlyOnePublisher && (
        <FormGroup className="span2">
          <ButtonNavLink to={parentLink}>
            {t('rm:campaign.create.publisherchangePublisher')}
          </ButtonNavLink>
        </FormGroup>
      )}

      {rmid && !hasOnlyOnePublisher && (
        <footer>
          <BalanceByPublisher
            id={rmid}
            idAccountType="industry"
            showZeroAlert
          />
        </footer>
      )}

      {rmid && !hasOnlyOnePublisher && showBrands && (
        <footer>
          <AlertBrands rmid={rmid} />
        </footer>
      )}
    </Panel>
  )
}

export default PublishersFieldset
