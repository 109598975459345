/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import { FilterSearch } from '~/components/FilterSearch';
import HeaderPages from '~/components/HeaderPages';
import PageTitle from '~/components/PageTitle';
import { compareStrings, formatSearchString } from '~/helpers';
import { indexLocation } from '~/pages/locations/actions';
import paymentActions from '~/store/modules/payment/actions';

import Card from './elements/Card';
import Locations from './elements/Locations';

import * as S from './styles';

/**
 *
 * Componente responsável por exibir a lista com as configurações global e das locations.
 * Cada location pode ter sua configuração própria. Se não tiver, a configuração global será utilizada.
 *
 */

const PaymentMethodsList = () => {
  const dispatch = useDispatch();

  /**
   * Handle payment methods
   */
  const { paymentMethodsList, paymentMethodsConfigList } = useSelector(
    ({ payment }) => payment,
  );

  useEffect(() => {
    dispatch(paymentActions.methods.get());
    dispatch(paymentActions.methodsConfig.get());
  }, [dispatch]);

  /**
   * Handle locations
   */
  const { listLocations } = useSelector(({ locations }) => locations);

  const queryStringLocation = useMemo(
    () =>
      formatSearchString({
        page: 0,
        quantity: 500,
      }),
    [],
  );

  useEffect(() => {
    if (!listLocations.data) {
      dispatch(indexLocation(queryStringLocation));
    }
  }, [dispatch, listLocations, queryStringLocation]);

  /**
   * Filter location
   */

  const { query } = useSelector(({ filters }) => filters);
  const filteredQuery = useMemo(
    () => query?.locationPaymentMethod || null,
    [query],
  );

  const filteredLocations = useMemo(() => {
    if (!filteredQuery) return listLocations.data;

    return listLocations.data.filter(location =>
      compareStrings(location.name, filteredQuery),
    );
  }, [filteredQuery, listLocations.data]);

  return (
    <>
      <PageTitle title="Pagamentos > Métodos" />

      <HeaderPages
        title="Métodos de pagamento"
        subtitle="Configure as formas de pagamento aceitas pelas lojas"
      />

      <Card title="Configuração global" />

      <S.ListLocations>
        <header>
          <S.Title>Locais</S.Title>

          <FilterSearch
            live
            placeholder="Busque por nome do local"
            autoComplete="off"
            keyState="locationPaymentMethod"
            name="locationPaymentMethod"
          />
        </header>

        <Locations
          filteredLocations={filteredLocations}
          paymentMethodsList={paymentMethodsList}
          paymentMethodsConfigList={paymentMethodsConfigList}
        />
      </S.ListLocations>
    </>
  );
};

export default PaymentMethodsList;
