import axios from 'axios'

export const putFormUser = (data: any) => {
  return axios.post(`/api/onboarding/add-user`, data)
}

export const getUsers = ({ queryString }: { [key: string]: string }) => {
  return axios.get(`/api/user/list-users/?${queryString}`)
}

export const getUserService = ({ queryString }: { [key: string]: string }) => {
  return axios.get(`/api/user/?${queryString}`)
}

export const resendInviteUser = (data: any) => {
  return axios.post(`/api/onboarding/resend-invite`, data)
}

export const userChangeRole = (data: any) => {
  return axios.put(`/api/user/change-role`, data)
}

export const removeUserService = (id: string) => {
  return axios.delete(`/api/user/${id}/remove-from-account`)
}

/**
 *
 * @endpoint /api/account/user-accounts
 * @exports getUserAccountsService  Busca as contas associadas ao usuário
 *
 */

export type UserAccount = {
  user_account_id: string
  account_id: string
  logo?: string
  name: string
  type: string
  header_color: string
  role: string
  is_default_account: boolean
}

export const getUserAccountsService = () => axios.get(`/api/user/accounts`)

/**
 * Define se uma conta deve ser a conta padrão de um usuário.
 *
 * @param data { user_account_id, is_default_account }
 * @returns { UserChangeAccountDefaultResponse }
 */

export type UserChangeAccountDefault = {
  user_account_id: string
  is_default_account: boolean
}

export type UserChangeAccountDefaultResponse = {
  updated: {
    id: string
    user_id: string
    is_default_account: boolean
  }
}

export const userChangeAccountDefault = ({
  user_account_id,
  is_default_account
}: UserChangeAccountDefault) =>
  axios.put<UserChangeAccountDefaultResponse>(
    `/api/user/${user_account_id}/change-default-account`,
    {
      is_default_account
    }
  )
