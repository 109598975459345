import React from 'react'
import { FiHelpCircle } from 'react-icons/fi'

import { t } from 'i18next'

import Tooltip from '~/components/Tooltip'
import {
  formatDateExibition,
  formatDateExibitionUTC,
  formatMoney,
  formatNumberWithDots,
  formatPercent
} from '~/helpers'

type GetSchemaProps = {
  campaignType: CampaignAdType
}

type GetSchema = (data: GetSchemaProps) => TableSchema

const avgCost = {
  product: {
    avg_cpc: {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>CPC</span>
          <Tooltip icon={<FiHelpCircle />} text={t('rm:metrics.cpc.tooltip')} />
        </div>
      ),
      render: rowData =>
        rowData?.avg_cpc ? formatMoney(Number(rowData?.avg_cpc)) : '-'
    }
  },
  banner: {
    avg_cpm: {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>CPM</span>
          <Tooltip icon={<FiHelpCircle />} text={t('rm:metrics.cpm.tooltip')} />
        </div>
      ),
      render: rowData =>
        rowData?.avg_cpm ? formatMoney(Number(rowData?.avg_cpm)) : '-'
    }
  }
}

/**
 * Esta função espera o tipo da campanha para saber quais campos deve retornar no schema.
 * Hoje trata apenas custo médio de CPC e CPM
 */
export const getSchema: GetSchema = ({ campaignType }) => ({
  data: {
    title: t('rm:Date'),
    render: rowData => formatDateExibitionUTC(rowData?.groupby)
  },
  impressions: {
    title: t('rm:metrics.impressions'),
    render: rowData => formatNumberWithDots(rowData.impressions)
  },
  clicks: {
    title: t('rm:metrics.clicks'),
    render: rowData => formatNumberWithDots(rowData.clicks)
  },
  conversions: {
    title: t('rm:metrics.conversions'),
    render: rowData => formatNumberWithDots(rowData.conversions)
  },
  ctr: {
    title: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{t('rm:metrics.ctr')}</span>
        <Tooltip
          icon={<FiHelpCircle />}
          text={<>{t('rm:metrics.ctr.tooltip')}</>}
        />
      </div>
    ),
    render: rowData => formatPercent(rowData?.ctr)
  },
  conversionRate: {
    title: t('rm:metrics.conversion_rate'),
    render: rowData => formatPercent(rowData?.conversion_rate)
  },
  ...avgCost[campaignType],
  cpa: {
    title: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{t('rm:metrics.cpa')}</span>
        <Tooltip icon={<FiHelpCircle />} text={t('rm:metrics.cpa.tooltip')} />
      </div>
    ),
    render: rowData => formatMoney(Number(rowData?.cpa))
  },
  cost: {
    title: t('rm:metrics.cost'),
    render: rowData => formatMoney(Number(rowData?.total_spent))
  },
  income: {
    title: t('rm:metrics.income'),
    render: rowData => formatMoney(Number(rowData?.income))
  },
  roas: {
    title: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>ROAS</span>
        <Tooltip icon={<FiHelpCircle />} text={t('rm:metrics.roas.tooltip')} />
      </div>
    ),
    render: rowData => formatNumberWithDots(rowData?.roas)
  }
})
