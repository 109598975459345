import React from 'react'
import { FiPlus } from 'react-icons/fi'

import { ButtonNavLink } from '~/components/Buttons'
import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import DashboardCampaign from '~/modules/messagingCampaigns/components/DashboardCampaign'
import { TEMPLATE_REDUX_QUERY as reduxQuery } from '~/modules/messagingCampaigns/store/template/constants'

import CampaignsTable from './elements/CampaignsTable'

const CampaignsList = () => (
  <>
    {/* Meta <title> */}
    <PageTitle title="Campanhas > Listagem" />

    <HeaderPages
      title="Listagem de campanhas"
      // sideLeftComponent={
      //   <FilterSearch
      //     placeholder="Buscar campanhas por nome"
      //     keyState={`${reduxQuery}`}
      //     live
      //   />
      // }
      sideRightComponent={
        <ButtonNavLink
          to="/messaging-campaigns/create"
          text="Criar campanha"
          size="small"
          iconLeft={<FiPlus />}
        />
      }
    />

    <Filters
      statusCampaign
      query
      liveQuery
      queryKeyState={`${reduxQuery}`}
      queryPlaceholder="Buscar campanha por nome"
    />

    <DashboardCampaign />

    <CampaignsTable />
  </>
)

export default CampaignsList
