import React from 'react'
import { FiPlus } from 'react-icons/fi'

import { ButtonNavLink } from '~/components/Buttons'
import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { TEMPLATE_REDUX_QUERY as reduxQuery } from '~/modules/messagingCampaigns/store/template/constants'

import Table from './elements/Table'

const TemplatesList = () => (
  <>
    {/* Meta <title> */}
    <PageTitle title="Campanhas > Templates > Listagem" />

    <HeaderPages
      title="Listagem de templates de mensagens"
      sideRightComponent={
        <ButtonNavLink
          to="/messaging-campaigns/templates/create"
          text="Criar template"
          size="small"
          iconLeft={<FiPlus />}
        />
      }
    />

    <Filters
      templateStatus
      query
      liveQuery
      queryKeyState={`${reduxQuery}`}
      queryPlaceholder="Buscar templates por nome"
    />

    <Table />
  </>
)

export default TemplatesList
