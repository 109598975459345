import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/product`
// const baseURL = `${baseAPI}/industry/publishers`

/**
 * LIST
 */
// export const list = () => Promise.resolve(listResponse)
export const list = (queries: SearchProductsReqParams) =>
  axios.get<ResponseListPublishersProducts>(`${baseURL}`, {
    params: { ...queries }
  })

/**
 * VIEW
 */
export const get = (id: string) => axios.get(`${baseURL}/${id}`)

export default {
  list,
  get
}
