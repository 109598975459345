import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  doc,
  setDoc
} from 'firebase/firestore/lite'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import { JWTPayload, getDecodedToken } from '~/helpers'
import { getAccountInfo } from '~/helpers/account-info'

import { firebaseConfig } from '../firebase'

const ActivateTV: React.FC = () => {
  const [activeData, setActiveData] = useState<DeviceInfo<JWTPayload>>(null)

  const [activating, setActivating] = useState(false)

  const { device_token } = useParams()

  const app = initializeApp(firebaseConfig)
  const db = getFirestore(app)
  const devicesRef = collection(db, 'devices')

  const getData = useCallback(async () => {
    try {
      const q = query(devicesRef, where('token', '==', device_token))
      const deviceSnapshot = await getDocs(q)

      const devicesActive = deviceSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }))

      setActiveData(devicesActive?.[0])
    } catch (error) {
      console.error('Erro ao buscar dados do Firestore:', error)
    }
  }, [devicesRef, device_token])

  useEffect(() => {
    if (!activeData) {
      getData()
    }
  }, [activeData])

  const handleActiveTV = useCallback(async () => {
    setActivating(true)
    const tokenInfo = getDecodedToken()
    const {
      name: accountName,
      logo: accountLogo,
      bgLogo: accountLogoBg
    } = getAccountInfo()

    const deviceRef = doc(db, 'devices', activeData.id)

    try {
      const newData = {
        active: true,
        ...tokenInfo,
        accountName,
        accountLogo,
        accountLogoBg
      }

      const response = await setDoc(deviceRef, newData, { merge: true })

      console.log('Response setDoc', response)

      setActiveData(prevState => ({ ...prevState, ...newData }))
    } catch (error) {
      console.log('Houve um erro ao tentar salva')
    } finally {
      setActivating(false)
    }
  }, [activeData?.id, db])

  return (
    <>
      {activeData && (
        <>
          Tv: {activeData?.deviceName || t('rm:unknown')}
          <br />
          {t('rm:tv.devices.deviceManufacturer')}:{' '}
          {activeData?.deviceManufacturer || t('rm:unknown')}
          <br />
          id: {activeData?.deviceID || t('rm:unknown')}
          <br />
        </>
      )}
      <hr />
      {activeData?.active && (
        <>
          {t('rm:tv.devices.user')}: {activeData?.name}({activeData?.email})
          <br />
        </>
      )}
      {activeData?.id && (
        <Button
          text={
            activeData?.active
              ? t('rm:tv.devices.activated')
              : t('rm:tv.devices.activate')
          }
          onClick={handleActiveTV}
          loading={activating}
          textLoading={t('rm:tv.devices.activating')}
        />
      )}
    </>
  )
}

export default ActivateTV
