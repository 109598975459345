import axios, { AxiosResponse } from 'axios'

import {
  ChangeAccountProps,
  ChangeAccountResponse,
  GetAccountInfoResponse
} from '.'

/**
 *
 * @endpoint /api/account/account-info
 * @exports getAccountInfoService  Busca as informações da account
 *
 */

export const getAccountInfoService = () =>
  axios.get<GetAccountInfoResponse>(`/api/account/account-info`)

/**
 *
 * @endpoint /changeaccount
 * @exports chageAccountService Alterna entre contas associadas ao usuário logado
 *
 */

export type ChangeAccount = (
  data: ChangeAccountProps
) => Promise<AxiosResponse<ChangeAccountResponse>>

export const chageAccountService: ChangeAccount = ({ body }) =>
  axios.post(`/changeaccount`, body)
