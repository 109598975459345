import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// elements

import { signOut } from '~/modules/auth/store/login/actions'

const Logout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(signOut())
  }, [dispatch])

  return <>Fazendo logout...</>
}

export default Logout
