import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'

import Button from '../../../../components/Button'
import Form from '../../../../components/forms/form'
import Input from '../../../../components/forms/inputs'
import Panel from '../../../../components/Panel'
import {
  requiredValidation,
  emailValidation,
  formatDate,
  formatDateToRequest
} from '../../../../helpers'
import { submitFormProfileUser, getUserInfo } from '../profile-actions'

const GeneralForm = ({
  user,
  getUserInfo,
  submitFormProfileUser,
  loadingSaveUser,
  loadingGetUser
}) => {
  useEffect(() => {
    getUserInfo()
  }, [])

  const [formIsValid, setFormIsValid] = useState(false)

  const beforeSubmit = data => {
    const { email, ...rest } = data

    const formattedData = {
      ...rest,
      date_of_birth: formatDateToRequest(data.date_of_birth)
    }

    submitFormProfileUser(formattedData)
  }

  const { t } = useTranslation()

  return (
    <Panel title={t('config:profile.generalData')} isLoading={loadingGetUser}>
      <Form
        onSubmit={beforeSubmit}
        checkIsValid={setFormIsValid}
        className="form-profile__form-info"
        data-cy="form-user"
      >
        <div className="form-profile__flex">
          <FormGroup>
            <Input
              label={t('config:profile.name')}
              name="name"
              defaultValue={user.name}
              validate={[requiredValidation]}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label={t('config:profile.email')}
              name="email"
              defaultValue={user.email}
              readOnly
              validate={[requiredValidation, emailValidation]}
            />
          </FormGroup>
        </div>
        <div className="form-profile__flex">
          <FormGroup>
            <Input
              label={t('config:profile.phone')}
              name="main_phone"
              mask="phone"
              defaultValue={user.main_phone}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label={t('config:profile.dateOfBirth')}
              name="date_of_birth"
              mask="date"
              defaultValue={formatDate(user.date_of_birth)}
            />
          </FormGroup>
          <FormGroup>
            <Input
              label={t('config:profile.socialId')}
              name="social_id"
              mask="cpf"
              defaultValue={user.social_id}
            />
          </FormGroup>
        </div>

        <div className="form-profile__button-save">
          <Button
            type="submit"
            color="primary"
            size="lg"
            text={t('config:profile.save')}
            loading={loadingSaveUser}
            spinner={{ type: 'grow', size: 'sm', color: 'secondary' }}
            disabled={!formIsValid}
            data-cy="button-update-user"
          />
        </div>
      </Form>
    </Panel>
  )
}

export default connect(
  state => ({
    user: state.profile.user,
    loadingSaveUser: state.profile.loadingSaveUser,
    loadingGetUser: state.profile.loadingGetUser
  }),
  { submitFormProfileUser, getUserInfo }
)(GeneralForm)
