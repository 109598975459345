import axios from 'axios';

/**
 *
 * @param {Object} data Request bodye
 * @param {String} data.email E-mail do usuário que terá a senha recuperada
 */
export function forgotPasswordService(data) {
  return axios.post(`/password-recovery`, data);
}

/**
 * @param {Object} data Request body
 * @param {String} data.password New password
 * @param {String} data.token Token recebido pela URL
 */
export function resetPasswordService(data) {
  return axios.post(`/update-password`, data);
}

export function changePasswordService(data) {
  return axios.post(`/api/user/change-password`, data);
}
