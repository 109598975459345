import React from 'react';

import PropTypes from 'prop-types';

import * as S from './styles';

const Categories = ({ categories }) => (
  <>
    {categories.map((category, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <S.Tag key={index}>{category.name}</S.Tag>
    ))}
  </>
);

export default Categories;

Categories.propTypes = {
  categories: PropTypes.arrayOf().isRequired,
};
