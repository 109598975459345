import { Scope } from '@unform/core'

import AlertInfo from '~/components/AlertInfo'
import Panel from '~/components/Panel'
import { FormGroup, InputMoney, Label } from '~/components/unform'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

const DailyCostScope: React.FC = () => {
  const { t } = useAPPTranslation()

  return (
    <Panel title={t('rm:DailyCostScope.Panel.title')}>
      <Scope path="settings">
        <FormGroup>
          <Label
            htmlFor="daily_cost"
            isRequired
            text={t('rm:DailyCostScope.label')}
          />

          <InputMoney id="daily_cost" name="daily_cost" />

          <AlertInfo template="info" text={t('rm:DailyCostScope.alertoInfo')} />
        </FormGroup>
      </Scope>
    </Panel>
  )
}

export default DailyCostScope
