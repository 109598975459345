import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import EmptyPage from '~/components/empty-page';
import ListPagination from '~/components/ListPagination';
import Loading from '~/components/Loading';
import Panel from '~/components/Panel';
import Table from '~/components/Table';
import {
  handleFlyersPerPage,
  listFlyers,
} from '~/modules/flyers/store/flyers/actions';

import { schema } from './schema';

import * as S from './styles';

const PromotionalTable = () => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const { loading, data, total, pages, itemsPerPage } = useSelector(
    ({ promotionFlyer }) => promotionFlyer.flyers.list,
  );

  const queryString = useMemo(
    () => ({
      page,
      quantity: itemsPerPage,
    }),
    [itemsPerPage, page],
  );

  useEffect(() => {
    dispatch(listFlyers(queryString));
  }, [dispatch, queryString]);

  const handlePagination = useCallback(page => {
    setPage(page);
  }, []);

  const handleItemsPerPageChange = ({ value }) => {
    dispatch(handleFlyersPerPage(value));
  };

  return (
    <>
      <Loading status={loading}>Carregando encartes...</Loading>

      {total === 0 && (
        <EmptyPage
          title="Nenhum encarte encontrado"
          // subtitle="Não encontramos encartes com os filtros aplicados."
          // titleButton="Limpar filtros"
          svgPath="photos/orders-empty"
        />
      )}

      {total > 0 && (
        <>
          <ListPagination
            total={total}
            label={total > 1 ? 'encartes' : 'encarte'}
            currentPage={page}
            itemsPerPage={itemsPerPage}
            menuPlacement="top"
            onClickPagination={handlePagination}
            onItemsPerPageChange={handleItemsPerPageChange}
          />

          <Panel noPaddingBody isLoading={loading}>
            {!!data && <Table schema={schema} data={data} total={total} />}
          </Panel>

          <S.Footer>
            <ListPagination
              total={total}
              label={total > 1 ? 'encartes' : 'encarte'}
              currentPage={page}
              itemsPerPage={itemsPerPage}
              menuPlacement="top"
              onClickPagination={handlePagination}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </S.Footer>
        </>
      )}
    </>
  );
};

export default PromotionalTable;
