import i18next from 'i18next'

import { changePasswordService } from '../../../services/password-service'
import {
  putFormProfileUser,
  getDataUser,
  putFormProfileCompany,
  getDataCompany
} from '../../../services/profile-service'
import * as types from './profile-constants'

export const submitFormProfileUser = data => dispatch => {
  dispatch({
    type: types.PROFILE_SUBMIT_USER,
    payload: putFormProfileUser(data),
    successFeedbackMsg: i18next.t(
      'config:profile.generalData.successMessage'
    )
  })
}

export const getUserInfo = () => dispatch => {
  dispatch({
    type: types.PROFILE_GET_DATA_USER,
    payload: getDataUser()
  })
}

export const submitFormProfileCompany = data => dispatch => {
  dispatch({
    type: types.PROFILE_SUBMIT_COMPANY,
    payload: putFormProfileCompany(data),
    successFeedbackMsg: 'Dados da empresa atualizados com sucesso!'
  })
}

export const getCompanyInfo = () => dispatch => {
  dispatch({
    type: types.PROFILE_GET_DATA_COMPANY,
    payload: getDataCompany()
  })
}

export const submitFormPassword = data => dispatch => {
  dispatch({
    type: types.PROFILE_SUBMIT_PASSWORD,
    payload: changePasswordService(data),
    successFeedbackMsg: i18next.t(
      'auth:updatePassword.resetPasswordPath.successMessage'
    )
  })
}
