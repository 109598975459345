import { ChartOptions, ChartTypeRegistry, TooltipItem } from 'chart.js'

import { primary as theme } from '~/styles/themes'

import { handleItemSortTooltip, handleLabelCallbackTooltip } from './helpers'
import { chartPresets } from './presets'

export const metricsChartConfig = ({
  data,
  metricPresets = 'advanced',
  metrics
}: MetricsChartConfigProps) => ({
  labels: data?.date || null,
  datasets: [...chartPresets[metricPresets]({ data, metrics }).datasets]
})

export const defaultOptionsChart = ({
  data,
  additionalData,
  metricPresets = 'advanced',
  metrics
}: MetricsChartConfigProps) =>
  ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: 'Gráfico de indicadores',
        position: 'bottom',
        align: 'center',
        padding: {
          top: 14,
          bottom: 0
        },
        color: theme.colors.dark,
        font: { size: 12, weight: 'normal' }
      },
      legend: {
        display: false,
        color: theme.colors.dark,
        position: 'top',
        align: 'center',
        // reverse: true,
        labels: {
          font: {
            size: 12
          },
          padding: 16,
          useBorderRadius: true,
          borderRadius: 4,
          usePointStyle: true
        }
      },
      tooltip: {
        mode: 'index',
        position: 'nearest',
        titleColor: theme.colors.dark,
        bodyColor: theme.colors.dark,
        footerColor: theme.colors.dark,
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.neutral.high.dark,
        borderWidth: 1,
        intersect: false,
        title: 'data',
        bodySpacing: 6,
        padding: 12,
        usePointStyle: true,
        callbacks: {
          label: handleLabelCallbackTooltip,
          // Lidando com dados adicionais
          afterBody: function (
            context: TooltipItem<keyof ChartTypeRegistry>[]
          ) {
            const day = context[0].label

            const currentAdditionalData = additionalData?.[day]

            if (!currentAdditionalData) {
              return []
            }

            const arrayAdditionalData = Object.values(currentAdditionalData)

            const formattedValues = arrayAdditionalData.map(
              value => `${value.label}: ${value.data}`
            )

            return formattedValues
          }
        },
        itemSort: handleItemSortTooltip
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false
        }
      },
      ...chartPresets[metricPresets]({ data, metrics }).chartOptions.scales
    }
  } as ChartOptions<'line'>)
