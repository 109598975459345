import { AxiosError } from 'axios'

import { NEW_FILTER_CHATS_BY_STATUS } from '../constants'

export const newFilterChatsByStatus = (status: ChatTabTypes) => ({
  type: NEW_FILTER_CHATS_BY_STATUS,
  payload: status,
  getErrorFeedbackMsg: (error?: AxiosError) =>
    error.response?.data?.error || 'Houve um erro ao filtar as conversas.'
})
