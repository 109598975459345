import styled, { css } from 'styled-components';

import { FormGroup, Label } from '~/components/unform';
import { device } from '~/styles/device';

export const DateWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    @media ${device.laptop_max} {
      flex-direction: column;
    }

    > div {
      display: flex;
      position: relative;

      @media ${device.laptop} {
        + div {
          margin-left: 2rem;
        }
      }

      @media ${device.laptop_max} {
        + div {
          border-top: 1px solid ${theme.colors.inative};
          padding-top: 0.5rem;
          margin-top: 0.5rem;
        }
      }

      &.start {
      }

      &.end {
        @media ${device.laptop} {
          &::before {
            content: '';
            position: absolute;
            background-color: ${theme.colors.tertiary};

            height: 1px;
            width: 1rem;
            bottom: 1rem;
            left: -1rem;
            transform: translateX(-50%);
          }
        }
      }

      &.duration {
        @media ${device.laptop} {
          &::before {
            content: '';
            position: absolute;
            background-color: ${theme.colors.tertiary};

            bottom: 0;
            height: 100%;
            left: -1rem;
            width: 1px;
          }
        }
      }

      .formGroup {
        + .formGroup {
          margin-left: 1rem;
        }
      }
    }
  `}
`;

export const Group = styled(FormGroup)`
  ${({ theme }) => css`
    & + & {
      margin-top: ${theme.spacings.medium};
    }

    > input {
      width: 100%;
    }

    /* &:last-child:not(:first-child) { */
    &:not(:first-child) {
      padding-top: ${theme.spacings.xsmall};
      border-top: 1px solid ${theme.colors.inative};
    }

    > .radioListWrapper {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      line-height: 1;
    }

    .customerSocialId {
      &.invalid {
        .value {
          color: ${theme.colors.danger};
        }
      }
    }
  `}
`;

export const CheckWithInputInline = styled.div`
  .inputContainer {
    width: 6rem;
    height: 2rem;
    margin: 0 0.25rem;
  }
`;

export const PromotionRulesGroup = styled.section`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.medium};
  `}

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
`;

export const StrongTitle = styled(Label)`
  ${({ theme }) => css`
    font-weight: ${theme.font.bolder};
  `}
`;

export const CoverImage = styled.img`
  ${({ theme }) => css`
    aspect-ratio: 16/9;
    object-fit: cover;
    max-height: 300px;
    border-radius: 1rem;
  `}
`;
