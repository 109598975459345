import styled, { css } from 'styled-components';

export const Container = styled.span`
  background: transparent;
  border: 0;
  outline: 0;

  transition: 0.3s ease-in-out all;

  ${({ theme, color }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0.125rem 0.25rem;

    border: 1px solid ${theme.colors.tertiary};
    border-radius: 2rem;

    background-color: ${theme.colors.white};

    &:before {
      content: '';
      background-color: ${color};
      border-radius: 50%;
      height: 0.5rem;
      width: 0.5rem;
      display: inline-flex;
      margin-right: ${theme.spacings.tiny};
      flex-shrink: 0;
    }

    .text {
      color: ${theme.dark};
      font-size: 0.75rem;
      text-overflow: ellipsis;
      transition: 0.3s ease-in-out all;
    }
  `}
`;
