import {
  getPlacementService,
  listPlacementsService
} from '../../services/placements'
import { types } from './constants'

export const getPlacement = (id: string) => ({
  type: types.list,
  payload: getPlacementService(id)
})

export const listPlacements = (
  params: RequestListPlacements = {} as RequestListPlacements
) => ({
  type: types.list,
  payload: listPlacementsService(params)
})

export const setPerPageListPlacements = (value: number) => ({
  type: types.listPerPage,
  payload: value
})

export const setSortListPlacements = (value: OnSortProps) => ({
  type: types.listSort,
  payload: value
})

const actionPlacements = {
  get: getPlacement,
  list: listPlacements,
  setPerPage: setPerPageListPlacements,
  setSorting: setSortListPlacements
}

export default actionPlacements
