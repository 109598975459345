/* eslint-disable no-underscore-dangle */
import React, { useRef, useState, useCallback, useMemo } from 'react'
import { FiEye, FiEyeOff, FiMapPin, FiPenTool, FiX } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'

import Alert from '~/components/Alert'
import { Button } from '~/components/Buttons'
import { FormGroup } from '~/components/unform'
import { getRole, history } from '~/helpers'
import AddressFields from '~/pages/locations/_elements/FormLocation/Address'

import * as S from './styles'

const AddMapPoint = ({
  onChange,
  handleShowAllRegions,
  isPreview,
  isShowingEdit
}) => {
  const { id: locationId = null, mode } = useParams()

  const [open, setOpen] = useState(false)
  const [address, setAddress] = useState(null)

  const formRef = useRef(null)

  const handleSubmit = useCallback(
    formData => {
      if (!!formData && !formData?.latitude) {
        return
      }

      setAddress(formData)

      if (formData?.latitude && formData?.longitude) {
        onChange?.(formData)
        setOpen(oldState => !oldState)
        return
      }

      onChange?.(null)
      // setOpen(oldState => !oldState);
    },
    [onChange]
  )

  const isAdmin = useMemo(() => getRole() === 'admin', [])

  const handleGoToEdit = useCallback(() => {
    return mode !== 'edit'
      ? history.push(`/logistic/${locationId}/regions/edit`)
      : history.push(`/logistic/${locationId}/regions`)
  }, [locationId, mode])

  return (
    <S.Container isOpen={open}>
      {open && (
        <S.FormWrapper>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <AddressFields
              formRef={formRef}
              allRequired
              labelItem="do cliente"
              hideComplement
            />

            {address && !address?.latitude && (
              <Alert>
                Não foi possível encontrar as coordenadas do endereço fornecido.
                Você pode inserir manualmente se preferir.
              </Alert>
            )}

            <FormGroup className="actionButtons">
              <Button
                text={open ? 'Cancelar' : 'Adicionar marcação ao mapa'}
                template={open ? 'danger' : 'primary'}
                iconLeft={open ? <FiX /> : <FiMapPin />}
                onClick={() => setOpen(e => !e)}
              />

              <Button
                text="Adicionar ao mapa"
                textLoading="Adicionando..."
                type="submit"
              />
            </FormGroup>
          </Form>
        </S.FormWrapper>
      )}

      {!open && (
        <S.AddButton>
          {!address && (
            <Button
              text={open ? 'Cancelar' : 'Adicionar marcação ao mapa'}
              template={open ? 'danger' : 'light'}
              iconLeft={open ? <FiX /> : <FiMapPin />}
              onClick={() => setOpen(e => !e)}
              size="small"
            />
          )}

          {address && (
            <Button
              text="Remover marcação do mapa"
              template="transparentDanger"
              iconLeft={<FiX />}
              onClick={() => handleSubmit(null)}
              size="small"
            />
          )}

          {isAdmin && !!locationId && (
            <Button
              text={
                mode !== 'edit' ? 'Ir para o modo de edição' : 'Modo de edição'
              }
              template={mode !== 'edit' ? 'light' : 'warningOutlined'}
              iconLeft={<FiPenTool />}
              onClick={handleGoToEdit}
              size="small"
            />
          )}

          {mode === 'edit' && !isPreview && (
            <Button
              text={
                isShowingEdit
                  ? 'Esconder regiões de outras lojas'
                  : 'Exibir regiões de outras lojas'
              }
              template="light"
              iconLeft={isShowingEdit ? <FiEyeOff /> : <FiEye />}
              onClick={handleShowAllRegions}
              size="small"
            />
          )}

          {isAdmin && !locationId && mode !== 'edit' && (
            <Button
              text="Escolha um local para começar a editar"
              template="light"
              iconLeft={<FiPenTool />}
              onClick={handleGoToEdit}
              disabled
              size="small"
            />
          )}
        </S.AddButton>
      )}
    </S.Container>
  )
}

export default AddMapPoint

AddMapPoint.propTypes = {
  onChange: PropTypes.func.isRequired,
  handleShowAllRegions: PropTypes.func,
  isShowingEdit: PropTypes.bool,
  isPreview: PropTypes.bool
}

AddMapPoint.defaultProps = {
  isPreview: false
}
