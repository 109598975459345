/* eslint-disable no-console */
import Axios from 'axios';
import PropTypes from 'prop-types';

/**
 * Esta função consulta o status da tarefa de consulta disparada no Metabase.
 *
 * Dúvidas sobre a motivação dessa função?
 * Consulte o README.md na raiz de FilterMetabase
 */

/**
 *
 * @param {string} idTask ID da tarefa que será consultada
 * @param {function} onSuccess Função que retornará o objeto formatado para ser usado no select
 * @param {function} onError Função que tratará um possível erro na request
 * @param {function} handleLoading Função para lidar com status de loading
 * @param {string} type Tipo do filtro para exibirmos no erro
 * @returns {Promise}
 */
const checkQuery = async ({
  onSuccess,
  handleLoading,
  onError,
  idTask,
  type,
}) => {
  const run = async () => {
    const { data } = await Axios.get(
      `/api/analytics/tasks/${idTask}/result?orient=records`,
    );

    if (data.status === 'failure') {
      console.group(`🚨 Error on filter ${type}`);
      console.error(data);
      console.groupEnd();

      onError?.(data);

      handleLoading(false);
    }

    const shouldCheckAgain =
      data.status === 'running' || data.status === 'queued';

    if (shouldCheckAgain) {
      return setTimeout(() => {
        run();
      }, 1000);
    }

    const formattedData =
      data?.data?.map(item => ({
        value: item.ID,
        label: item.LABEL,
      })) || [];

    onSuccess(formattedData);

    handleLoading(false);
  };

  run();
};

export default checkQuery;

checkQuery.propTypes = {
  idTask: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  handleLoading: PropTypes.func.isRequired,
};
