import { types } from '../constants'

const index = {
  pending: `${types.listTransaction}_PENDING`,
  fulfilled: `${types.listTransaction}_FULFILLED`,
  rejected: `${types.listTransaction}_REJECTED`,
  setPage: types.listTransactionSetPage,
  setPerPage: types.listTransactionSetPerPage
}

export const listTransaction = {
  [index.pending]: ({ draft }) => {
    draft.grocery.wallet.listTransaction.loading = true
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.grocery.wallet.listTransaction.data = action.payload.data

    draft.grocery.wallet.listTransaction.pages = action.payload.pages
    draft.grocery.wallet.listTransaction.total = action.payload.total
    draft.grocery.wallet.listTransaction.page = action.payload.page

    draft.grocery.wallet.listTransaction.loading = false
    draft.grocery.wallet.listTransaction.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.grocery.wallet.listTransaction.loading = false
    draft.grocery.wallet.listTransaction.error = action.payload
    draft.grocery.wallet.listTransaction.total = 0
    draft.grocery.wallet.listTransaction.data = null
  },
  [index.setPage]: ({ draft, action }) => {
    draft.grocery.wallet.listTransaction.page = action.payload
  },
  [index.setPerPage]: ({ draft, action }) => {
    draft.grocery.wallet.listTransaction.perPage = action.payload
  }
}
