import { types } from '../constants'

const index = {
  pending: `${types.getPublisher}_PENDING`,
  fulfilled: `${types.getPublisher}_FULFILLED`,
  rejected: `${types.getPublisher}_REJECTED`
}

const reducerKey = 'publisher'

export const info = {
  [index.pending]: ({ draft }) => {
    draft.industry[reducerKey].getPublisher.loading = true
  },
  [index.fulfilled]: ({ draft, action }: ActionData) => {
    draft.industry[reducerKey].getPublisher.data = action.payload

    draft.industry[reducerKey].getPublisher.loading = false
    draft.industry[reducerKey].getPublisher.error = null
  },
  [index.rejected]: ({ draft, action }: ActionData) => {
    draft.industry[reducerKey].getPublisher.loading = false
    draft.industry[reducerKey].getPublisher.error = action.payload
  }
}
