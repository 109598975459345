import { FiX } from 'react-icons/fi'

import * as S from './styles'

export const TagsList = ({
  tags = null,
  onDelete = null,
  children = null,
  title
}: TagsListProps) =>
  !!tags?.length || !!children ? (
    <S.Wrapper>
      {!!tags?.length && (
        <>
          {title && <p>{title}</p>}
          <S.TagList>
            {tags.map(tag => (
              <div key={tag.id}>
                <S.Tag>
                  {typeof onDelete === 'function' && (
                    <button
                      type="button"
                      onClick={() => onDelete(tag)}
                      data-testid="delete-button"
                    >
                      <FiX />
                    </button>
                  )}

                  <span className="label">{tag.label}</span>
                </S.Tag>
              </div>
            ))}
          </S.TagList>
        </>
      )}

      {children}
    </S.Wrapper>
  ) : (
    <></>
  )
