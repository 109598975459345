import React from 'react'
import { t } from 'i18next'

import { ButtonNavLink } from '~/components/Buttons'
import { useAppSelector } from '~/store/hooks'

type CheckingAccountRowProps = {
  data: GetCheckingAccountWithAccountInfoResponse
}

const CheckingAccountRechargeRow = ({ data }: CheckingAccountRowProps) => {
  const { accountType } = useAppSelector(state => state.signin)
  const key = accountType === 'grocery' ? 'advertiser' : 'publisher'

  const accountInfo = {
    id: data?.[`${key}_id`]
  }

  return (
    <ButtonNavLink
      to={`/retail-media/wallet/${data?.id}/recharge?rmid=${accountInfo.id}`}
      text={t('rm:wallet.table.actions.addCredit')}
      size="small"
      customWidth="auto"
    />
  )
}

export default CheckingAccountRechargeRow
