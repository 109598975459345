import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import * as S from './styles';

const PDVID = ({ statusId, firstInvoiceStatus, integrationId }) => {
  const show = useMemo(
    () => firstInvoiceStatus === statusId && integrationId,
    [firstInvoiceStatus, integrationId, statusId],
  );

  return show ? (
    <S.Container className="PDV_ID_Container">
      <div className="label">Código de integração:</div>&nbsp;
      <div className="value">{integrationId}</div>
    </S.Container>
  ) : (
    <></>
  );
};

export default PDVID;

PDVID.propTypes = {
  integrationId: PropTypes.string,
  firstInvoiceStatus: PropTypes.string,
  statusId: PropTypes.string.isRequired,
};

PDVID.defaultProps = {
  integrationId: null,
  firstInvoiceStatus: null,
};
