import React from 'react'
import { Chart } from 'react-chartjs-2'

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
  Filler
} from 'chart.js'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
  Filler
)

const LineChart = ({ labels, datasets, optionsChart }) => {
  return (
    <div className="chart chart-lg">
      <Chart type="line" data={{ datasets, labels }} options={optionsChart} />
    </div>
  )
}

export default LineChart
