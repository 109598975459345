import styled from 'styled-components'

import { device } from '~/styles/device'

export const PaginationContainer = styled.div`
  @media ${device.laptop_max} {
    flex-direction: column;
    gap: 0.5rem;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 98vw;
  margin: ${({ theme }) => theme.spacings.medium} 0;

  .hold-pagination {
    &,
    .pagination {
      margin: 0;
    }
  }
`

export const ProductsCount = styled.p`
  border-right: 1px solid ${({ theme }) => theme.colors.primaryLighten45};
  margin: 0 15px 0 0;
  padding-right: 15px;
`

export const SelectContainer = styled.div`
  align-items: center;
  display: flex;

  label {
    align-items: center;
    display: flex;
    margin: 0;

    > div {
      margin: 0 10px;
    }
  }
`
