import { useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { TagsList } from '~/components/TagsList'
import { FormGroup, InputHidden, Label } from '~/components/unform'
import { AutocompleteInput } from '~/components/unform/AutocompleteInput'
import { getWhiteLabelPublisherId } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQuery from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { listCategory } from '~/modules/retailMedia/store/industry/category/actions'
import * as types from '~/modules/retailMedia/store/industry/category/constants'
import { useAppSelector } from '~/store/hooks'

const PlacementCategory = ({
  onChange,
  publisherId,
  initialCategories = [],
  formRef
}: PlacementCategoryProps) => {
  const [selectedCategories, setSelectedCategories] = useState<Tag[]>([])

  const { t } = useAPPTranslation()

  const dispatch = useDispatch()

  const queryParams = useQuery()
  const publisherIdParam = useMemo(
    () => queryParams.get('rmid') || null,
    [queryParams]
  )

  const { isWhiteLabel } = useAppInfo()

  const selectedPublisherId = useMemo(() => {
    if (publisherId) return publisherId

    return isWhiteLabel ? getWhiteLabelPublisherId() : publisherIdParam
  }, [isWhiteLabel, publisherId, publisherIdParam])

  const {
    industry: {
      // A categoria é do grocery / publisher
      category: {
        list: {
          data: searchCategory,
          loading: listCategoryLoading,
          total,
          perPage,
          totalGlobal
        }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  const { query } = useAppSelector(state => state.filters)

  const filteredQuery = useMemo(
    () => query?.[`${types.CATEGORY_REDUX_QUERY}`] || null,
    [query]
  )

  const queryStringSearchProduct = useMemo(
    () => ({
      quantity: perPage,
      name: filteredQuery,
      publisher_id: selectedPublisherId
    }),
    [filteredQuery, perPage, selectedPublisherId]
  )

  useEffect(() => {
    dispatch(listCategory(queryStringSearchProduct))
  }, [dispatch, queryStringSearchProduct, filteredQuery])

  type HandleActionProps = FormAddCategorySelectedCategories['categories']

  const onCreate = (value: Tag) => {
    const handleAction = (categories: HandleActionProps) => {
      const compareName = (a: Tag, b: Tag) => {
        if (a.label < b.label) return -1

        if (a.label > b.label) return 1

        return 0
      }

      const newData = [...categories, value].sort(compareName)

      onChange({ categories: newData })

      return newData
    }

    setSelectedCategories(handleAction)
  }

  const onDelete = (value: Tag) => {
    const handleAction = (categories: HandleActionProps) => {
      const newData = categories.filter(({ id }) => id !== value.id)

      onChange({ categories: newData })

      return newData
    }

    setSelectedCategories(handleAction)
  }

  const tags = useMemo(
    () =>
      searchCategory?.map(category => ({
        id: category.id,
        label: category.name
      })),
    [searchCategory]
  )

  const values = useMemo(() => {
    return selectedCategories.map(item => item.label)
  }, [selectedCategories])

  /**
   * memo
   */

  const helperText = useMemo(() => {
    if (listCategoryLoading) {
      return t('rm:campaign.create.targeting.category.loading')
    }

    if (filteredQuery) {
      return (
        <Trans
          components={{ strong: <strong /> }}
          i18nKey="rm:campaign.create.targeting.category.helperText.searching"
          values={{
            filteredQuery,
            total,
            totalGlobal
          }}
        />
      )
    }

    return t(
      'rm:campaign.create.targeting.category.helperText.availableCategory',
      { count: totalGlobal || total }
    )
  }, [filteredQuery, listCategoryLoading, t, total, totalGlobal])

  /**
   *
   */
  useEffect(() => {
    formRef?.current.setFieldValue('targeting.categories', values)
  }, [formRef, values])

  return (
    <FormGroup className="w-100">
      <Label
        htmlFor="desktop.categories"
        isRequired
        text={t('rm:campaign.create.targeting.category.label')}
        helperText={helperText}
      />

      <AutocompleteInput
        name="desktop.categories"
        placeholder={t('rm:campaign.create.targeting.category.placeholder')}
        onCreate={onCreate}
        selectedTags={[...initialCategories, ...selectedCategories]}
        tags={tags}
        keyState={`${types.CATEGORY_REDUX_QUERY}`}
        loading={listCategoryLoading}
        showQuantityAlert
        perPage={perPage}
        total={total}
      />

      <TagsList tags={selectedCategories} onDelete={onDelete} />

      <InputHidden name="categories" />
    </FormGroup>
  )
}

export default PlacementCategory
