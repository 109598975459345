import React from 'react';

import { DatepickerHeader } from '~/components/datepicker-header';
import { Filters } from '~/components/Filters';
import HeaderPages from '~/components/HeaderPages';
import PageTitle from '~/components/PageTitle';
import Panel from '~/components/Panel';

import CartsTable from './elements/CartsTable';
import MyCartsTable from './elements/MyCartsTable';

import * as S from './styles';

const ShoppingCarts = () => (
  <>
    {/* Meta <title> */}
    <PageTitle title="Carrinhos > Listagem" />

    <HeaderPages
      title="Listagem de carrinhos"
      sideRightComponent={<DatepickerHeader />}
      className="has-datepicker"
    />

    <MyCartsTable />

    <Filters locations shoppingCartStatus />

    <CartsTable />
  </>
);

export default ShoppingCarts;
