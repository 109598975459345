import { produce } from 'immer'

import * as types from './layout-constants'

const INITIAL_STATE = {
  isBoxed: false,
  isMobile: false,
  metricsLayout: 'full',
  width: 0
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LAYOUT_BOXED_TOGGLE: {
        draft.isBoxed = !state.isBoxed
        break
      }
      case types.LAYOUT_BOXED_ENABLE: {
        draft.isBoxed = true
        break
      }
      case types.LAYOUT_BOXED_DISABLE:
        draft.isBoxed = false
        break
      case types.LAYOUT_IS_MOBILE: {
        const checkIsMobile = action.payload <= 1024
        draft.width = action.perpage
        draft.isMobile = checkIsMobile
        break
      }
      case types.LAYOUT_METRICS_CHART: {
        draft.metricsLayout = action.payload.mode

        break
      }
      default:
        return state
    }
  })
