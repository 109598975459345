import Status from '~/components/Status'
import { Badge } from '~/components/UI/Badge'
import { getRole, getUserId } from '~/helpers'
import i18n from '~/i18n'

import UsersTableActionColumn from './Actions'
import EditPermission from './EditPermission'

const { t } = i18n

export const schema: TableSchema<UserResponse> = {
  name: {
    render: rowData => (
      <div style={{ lineHeight: 1, fontSize: '.875rem' }}>
        <strong>
          {rowData.name}{' '}
          {getUserId() === rowData.id && (
            <Badge template="successSolid">{t('users:you')}</Badge>
          )}
        </strong>
        <br />
        <small className="item__name">{rowData.email}</small>
      </div>
    ),
    title: t('users:name'),
    className: 'order__details col__infos',
    showTotal: true
  },
  role: {
    render: rowData => (
      <span style={{ whiteSpace: 'nowrap', fontSize: '.75rem' }}>
        {t(`users:${rowData.role}`)}{' '}
        {getUserId() !== rowData.id && <EditPermission data={rowData} />}
      </span>
    ),

    title: t('users:permission')
  },
  status: {
    render: ({ status }) => (
      <span style={{ whiteSpace: 'nowrap', fontSize: '.75rem' }}>
        <Status
          label={t(`users:status.${status}`)}
          template={status === 'registered' ? 'success' : 'warning'}
        />
      </span>
    ),
    title: t('users:status')
  },
  removerUser: {
    render: rowData =>
      getRole() === 'admin' && <UsersTableActionColumn data={rowData} />
  }
}
