import styled from 'styled-components'

export const WrapperHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  > a {
    margin-right: 1rem;
    padding: 0 1rem;
  }

  > .wrapper-datepicker-header {
    > .datepicker__wrapper {
      width: auto;
    }
  }
`
