import styled, { css } from 'styled-components';

export const TemplateWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

const previewImageModifiers = {
  loading: () => css`
    img {
      animation: fade 3s ease-in-out infinite;
    }
  `,
};

export const PreviewMedia = styled.div`
  ${({ isLoading, theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacings.small};
    position: relative;

    @keyframes fade {
      0% {
        opacity: 0.5;
      }
      50% {
        opacity: 0.75;
      }
      100% {
        opacity: 0.5;
      }
    }

    max-width: 200px;

    ${isLoading && previewImageModifiers.loading()}

    .file {
      display: flex;
      align-items: center;
      flex-direction: column;

      padding: 1rem 2rem;
      border-radius: 0.25rem;
      width: 14rem;
      max-width: 14rem;

      background: ${theme.colors.light};
      border: 1px solid ${theme.colors.tertiary};

      .info {
        text-align: center;
        line-height: 1.25;

        small {
          margin-top: 0.125rem;
        }
      }

      > .icon {
        font-size: 4rem;
        line-height: 1;
        color: ${theme.colors.primary};
      }
    }
  `}
`;
