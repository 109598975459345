import { ad as service } from '~/modules/retailMedia/services/industry'

import { types } from './constants'

export const getMinCosts = ({ body }: GetMinCostsRequestParams) => ({
  type: types.getMinCosts,
  payload: service.getMinCosts({ body })
})

export default {
  getMinCosts
}
