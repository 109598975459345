import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getTopCustomersInfo } from '../dashboard-actions';
import { formatMoney, formatPercent } from '../../../helpers';
import Panel from '../../../components/Panel';
import Table from '../../../components/Table';

const schema = {
  id: {
    render: obj => obj['Customers.socialId'],
    title: 'Id',
  },
  name: {
    render: obj => obj['Customers.name'],
    title: 'Nome',
  },
  quantity: {
    render: obj => obj['Orders.ordersCount'],
    title: 'Quantidade',
  },
  averageTicket: {
    render: obj => formatMoney(obj['Orders.averageTicket']),
    title: 'Ticket médio',
  },
  margin: {
    render: obj => formatPercent(obj['Orders.margin'], 0),
    title: 'Margem',
  },
  gmv: {
    render: obj => formatMoney(obj['Orders.gmv']),
    title: 'GMV',
  },
};

const TopCustomersTable = ({
  startDate,
  endDate,
  loading,
  topCustomersInfoList,
  getTopCustomersInfo,
}) => {
  useEffect(() => {
    getTopCustomersInfo(startDate, endDate);
  }, [startDate, endDate]);
  return (
    <Panel noPaddingBody title="Top 10 clientes do período" isLoading={loading}>
      <Table schema={schema} data={topCustomersInfoList} />
    </Panel>
  );
};

export default connect(
  state => ({
    topCustomersInfoList: state.dashboard.topCustomersInfoList,
    loading: state.dashboard.topCustomersLoading,
  }),
  {
    getTopCustomersInfo,
  },
)(TopCustomersTable);
