export default {
  advertisers: {
    data: null,
    loading: false,
    error: null
  },
  publishers: {
    data: null,
    loading: false,
    error: null
  }
}
