import React from 'react';

import ExternalDashboard from '~/components/ExternalDashboard';
import HeaderPages from '~/components/HeaderPages';

import { Container } from './styles';

const ChatDashboard = () => {
  return (
    <Container>
      <HeaderPages title="Relatório de Atendimento" />
      <ExternalDashboard dashboardId={7} filterLocations />
    </Container>
  );
};

export default ChatDashboard;
