/* eslint-disable max-len */
import React, { useState, useMemo, useCallback } from 'react'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import Panel from '~/components/Panel'
import { FormGroup, Input, Label, Radio, Textarea } from '~/components/unform'
import MessageWithMediaAndPreview from '~/components/unform/FormGroup/MessageWithMediaAndPreview'
import { campaignButtonsOptions } from '~/modules/messagingCampaigns/constants/buttons'
import useMsgCampaigns from '~/modules/messagingCampaigns/hooks/useMsgCampaigns'

const initialText =
  'Esse é um texto de *exemplo* com _italic_, ~strike~ and ```code```.\n\nOlá, amigo cliente! ❤️\n\nNosso festival de frutas da estação começou com tudo! 🍒🍇🍉😋\n\nQuer aproveitar as promoções?'

const FormTemplate = ({
  onSubmit,
  formRef,
  onUpload,
  loading,
  loadingUpload,
  mediaFile
}) => {
  const [text, setText] = useState(initialText)
  const [showButtons, setShowButtons] = useState(false)

  const { allowMediaOnTemplate } = useMsgCampaigns()

  const submitDisabled = useMemo(() => initialText === text, [text])

  const handleButtonsChange = useCallback(event => {
    const { value } = event.target

    setShowButtons(value === 'with_buttons')
  }, [])

  return (
    <Form
      onSubmit={onSubmit}
      ref={formRef}
      initialData={{
        messages: [
          {
            // eslint-disable-next-line max-len
            text: initialText
          }
        ]
      }}
    >
      <fieldset>
        <Panel title="Informações gerais" isCollapsible>
          {/* <div className="grid"> */}
          <FormGroup className="form-group">
            <Label
              htmlFor="title"
              isRequired
              text="Nome do template"
              helperText="Deve ser um nome exclusivo e pode conter somente caracteres alfanuméricos minúsculos e sublinhados."
            />
            <Input
              id="title"
              name="title"
              placeholder="Insira o nome do template"
              required
            />
          </FormGroup>

          {/* <FormGroup className="form-group">
            <Label htmlFor="category">Categoria do template</Label>
            <Select id="category" name="category" />
            <div>Selecione o que melhor se adapta ao seu caso de uso.</div>
          </FormGroup> */}
          {/* </div> */}

          <FormGroup className="form-group">
            <Label htmlFor="description">Descrição (opcional)</Label>
            <Textarea
              id="description"
              name="description"
              placeholder="Descreva o seu template e em quais situações pode ser usado."
            />
          </FormGroup>
        </Panel>

        <MessageWithMediaAndPreview
          formRef={formRef}
          panelTitle="Template da mensagem"
          allowMedia={allowMediaOnTemplate}
          handleUpload={onUpload}
          mediaFile={mediaFile}
          initialText={initialText}
          mediaLabel="Mídia (opcional)"
          mediaDescription="Sua mensagem será enviada com uma mídia? Você poderá alterar a mídia a cada campanha criada."
          messagesIsRequired
          onChangeTextMessage={setText}
          showDefaultButtons={showButtons}
        >
          <FormGroup className="form-group">
            <Label htmlFor="buttons">Botões</Label>
            <Radio
              name="buttons"
              options={campaignButtonsOptions}
              onChange={handleButtonsChange}
              required
            />
          </FormGroup>
        </MessageWithMediaAndPreview>
      </fieldset>

      <div className="form-default__buttons">
        <Button
          type="submit"
          text="Criar template"
          loading={loading || loadingUpload}
          size="large"
          template="success"
          disabled={submitDisabled}
        />
      </div>
    </Form>
  )
}

FormTemplate.defaultProps = {
  mediaFile: null,
  loading: false
}

FormTemplate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  onUpload: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingUpload: PropTypes.bool.isRequired,
  mediaFile: PropTypes.string
}

export default FormTemplate
