import React, { PureComponent } from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

class Pager extends PureComponent {
  mapPaginationObject(start, end, currentPage) {
    const range = []

    for (let i = start; i < end + 1; ++i) {
      range.push(i)
    }

    return {
      range,
      currentPage
    }
  }

  getOffset() {
    const { page, limit } = this.props
    return (page - 1) * limit
  }

  getTotalPages() {
    const { count, limit } = this.props
    return Math.ceil(count / limit)
  }

  getPageRange({ limit, maxPagesToShow = 10 }) {
    const offset = this.getOffset()
    const currentPage = Math.ceil(offset / limit) + 1
    const maxPages = this.getTotalPages()
    let start = 1
    let end = maxPagesToShow

    if (maxPages <= maxPagesToShow) {
      // less than 10 total pages, then show all
      start = 1
      end = maxPages

      return this.mapPaginationObject(start, end, currentPage)
    }

    // more than "maxPagesToShow" total pages
    if (currentPage <= 6) {
      start = 1
      end = maxPagesToShow
    } else if (currentPage + 4 >= maxPages) {
      start = maxPages - 9
      end = maxPages
    } else {
      start = currentPage - 5
      end = currentPage + 4
    }

    return this.mapPaginationObject(start, end, currentPage)
  }

  renderPaginationBtn({ type, number = false, currentPage = 1, key }) {
    const { onClickPagination, limit, count, page } = this.props

    const offset = this.getOffset()
    const totalPages = this.getTotalPages()

    const btn = {
      first: {
        icon: 'first',
        disabled: offset === 0,
        onClick: () => onClickPagination(1)
      },
      prev: {
        icon: 'previous',
        disabled: offset === 0,
        onClick: () => onClickPagination(page - 1)
      },
      next: {
        icon: 'next',
        disabled: offset + limit >= count,
        onClick: () => onClickPagination(page + 1)
      },
      last: {
        icon: 'last',
        disabled: offset + limit >= count,
        onClick: () => onClickPagination(totalPages)
      },
      goto: {
        onClick: () => onClickPagination(number),
        disabled: false
      }
    }
    return (
      <PaginationItem active={number === currentPage} key={key}>
        <PaginationLink
          disabled={btn[type].disabled}
          onClick={btn[type].onClick}
          previous={btn[type].icon === 'previous'}
          next={btn[type].icon === 'next'}
          first={btn[type].icon === 'first'}
          last={btn[type].icon === 'last'}
        >
          {number}
        </PaginationLink>
      </PaginationItem>
    )
  }

  render() {
    const {
      count,
      limit,
      hasFirstLast,
      hasPagination,
      alignCenter,
      maxPagesToShow = 10
    } = this.props

    if (count === undefined || limit === undefined) {
      return null
    }

    const { range, currentPage } = this.getPageRange({
      count,
      limit,
      maxPagesToShow
    })

    return (
      <div className={`hold-pagination ${alignCenter ? '--align-center' : ''}`}>
        <Pagination aria-label="Page navigation example">
          {hasFirstLast && this.renderPaginationBtn({ type: 'first' })}
          {this.renderPaginationBtn({ type: 'prev' })}
          {range.map((number, index) =>
            this.renderPaginationBtn({
              type: 'goto',
              number,
              currentPage,
              key: index
            })
          )}
          {this.renderPaginationBtn({ type: 'next' })}
          {hasFirstLast && this.renderPaginationBtn({ type: 'last' })}
        </Pagination>
      </div>
    )
  }
}

export default Pager
