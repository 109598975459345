import styled, { css } from 'styled-components'

export const Container = styled.div``

export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    padding: ${theme.spacings.xsmall} ${theme.spacings.xxxsmall};

    border-radius: ${theme.border.radius.medium};
    background: ${theme.colors.light};

    & + & {
      margin-top: ${theme.spacings.xxxsmall};
    }

    .drag {
      padding: 0 ${theme.spacings.tiny};
    }

    .checkbox {
      padding: 0 ${theme.spacings.tiny};

      > div label {
        padding-right: 0;
      }
    }

    .content {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;

      .description {
        display: flex;
        align-items: center;

        margin-top: ${theme.spacings.xxxsmall};

        .icon {
          display: inline-flex;
          margin-right: 2px;
          color: ${theme.colors.neutralHigh};
        }

        .text {
          font-size: ${theme.font.sizes.xsmall};
          color: ${theme.colors.stormGray};
        }
      }
    }
  `}
`
