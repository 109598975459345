import BadgeIsValid from '~/components/BadgeIsValid'
import Panel from '~/components/Panel'
import { FormGroup, Input, InputMask, Label } from '~/components/unform'
import InputCpfCnpj from '~/components/unform/Input/InputCpfCnpj'

export const PersonForm = ({ personStepIsValid }) => {
  return (
    <Panel
      title="Informações de cobrança"
      description="Informações adicionais para a cobrança"
      className="form-grid"
      iconLeft={<BadgeIsValid isValid={personStepIsValid} />}
    >
      <>
        <FormGroup className="span2">
          <Label>Nome</Label>
          <Input name="name" placeholder="Nome" required />
        </FormGroup>
        <FormGroup className="span2">
          <Label>CPF/CNPJ</Label>
          <InputCpfCnpj
            id="social_id"
            name="social_id"
            placeholder="CPF/CNPJ"
            required
            initialData=""
          />
        </FormGroup>
        <FormGroup className="span2">
          <Label>Telefone</Label>
          <InputMask
            name="phone"
            format="(##) #####-####"
            placeholder="Telefone"
            mask="_"
            required
          />
        </FormGroup>
        <FormGroup className="span2">
          <Label>E-mail</Label>
          <Input name="email" placeholder="E-mail" required />
        </FormGroup>
      </>
    </Panel>
  )
}
