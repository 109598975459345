import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    margin-top: 21px;

    .missing-amount {
      font-size: ${theme.font.sizes.xsmall};
      color: ${theme.colors.warning};
      margin: 0;
    }
  `}
`;

export const Price = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.gray};
  `}
`;
