import React from 'react'

import { DatepickerHeader } from '~/components/datepicker-header'
import ExternalDashboard from '~/components/ExternalDashboard'
import HeaderPages from '~/components/HeaderPages'

import { Container } from './styles'

const BotStepsDashboard = () => (
  <Container>
    <HeaderPages
      title="Relatório de passos do bot"
      sideRightComponent={<DatepickerHeader />}
      className="has-datepicker"
    />

    <ExternalDashboard dashboardId={88} />
  </Container>
)

export default BotStepsDashboard
