import ViewBannerCampaign from './Banner'
import ViewDigitalSignageCampaign from './DigitalSignage'
import ViewProductCampaign from './Product'
import ViewSponsoredBrandCampaign from './SponsoredBrand'

type ViewPages = {
  [key in CampaignAdType]: React.FC<ViewCampaignProps>
}

const DetailsViewCampaign: React.FC<ViewCampaignProps> = props => {
  const adType = props.data.adType

  const Pages: ViewPages = {
    banner: ViewBannerCampaign,
    product: ViewProductCampaign,
    sponsored_brand: ViewSponsoredBrandCampaign,
    digital_signage: ViewDigitalSignageCampaign
  }

  const SelectedPage = Pages[adType] || Pages.product

  return <SelectedPage {...props} />
}

export default DetailsViewCampaign
