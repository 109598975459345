import { t } from 'i18next'

import { formatDateToString, formatMoney } from '~/helpers'
import {
  labelSubtypes,
  labelTypes
} from '~/modules/retailMedia/store/common/constants'

import * as S from '../styles'

export const schema = {
  date: {
    title: t('rm:wallet.movimentations.table.column.title.date'),
    render: rowData => (
      <strong>{formatDateToString(rowData?.created_at)}</strong>
    )
  },
  value: {
    title: t('rm:wallet.movimentations.table.column.title.value'),
    render: rowData => (
      <S.ValueTransaction transactionType={rowData?.transaction_type}>
        <strong className="amount">
          {formatMoney(rowData?.amount, false, 'R$ 0,00')}
        </strong>
      </S.ValueTransaction>
    )
  },
  type: {
    title: t('rm:wallet.movimentations.table.column.title.type'),
    render: rowData => (
      <S.ColumnMainAndDetail>
        <span className="mainInfo">
          {labelTypes?.[rowData?.transaction_type] || rowData?.transaction_type}
        </span>{' '}
        <small className="detail">
          {labelSubtypes?.[rowData?.transaction_subtype]}
        </small>
      </S.ColumnMainAndDetail>
    )
  },
  user: {
    title: t('rm:wallet.movimentations.table.column.title.user'),
    render: rowData => (
      <S.ColumnMainAndDetail>
        <span className="mainInfo">{rowData?.user_name}</span>{' '}
        <small className="detail">{rowData?.user_email}</small>
      </S.ColumnMainAndDetail>
    )
  },
  nota: {
    title: t('rm:wallet.movimentations.table.column.title.note'),
    render: rowData => (
      <S.Note>
        <span>{rowData?.note}</span>
      </S.Note>
    )
  }
}
