import styled, { css } from 'styled-components';

const containerModifiers = {
  columns: () => css`
    min-height: 100%;
  `,
  rows: theme => css`
    min-height: 0;

    &:not(:last-child) {
      margin-bottom: ${theme.spacings.small};
    }
  `,
  hasitem: theme => css`
    --baseColor: ${theme.colors.primaryLighten30};
    background-color: ${theme.colors.primaryLighten60};
  `,
  editMode: theme => css`
    --baseColor: ${theme.colors.tertiary};

    background-color: ${theme.colors.inative};
  `,
  isDateLocal: theme => css`
    --baseColor: ${theme.colors.primaryLighten30};

    background-color: ${theme.colors.white};
  `,
  isSelected: theme => css`
    --baseColor: ${theme.colors.primary};

    background-color: ${theme.colors.primaryLighten60};
    box-shadow: 0 32px 2.5rem -1rem rgb(87 62 113 / 20%);
  `,
};

export const Container = styled.div`
  ${({ theme, editMode, hasitem, isSelected, template, isGlobal }) => css`
    position: relative;
    display: flex;
    flex-direction: column;

    border-radius: 0.25rem;
    background-color: ${theme.colors.inative};

    box-shadow: 0;

    transition: ${theme.transition.default} all;

    --baseColor: ${theme.colors.tertiary};
    /* border: 1px solid var(--baseColor); */
    border-top: 4px solid var(--baseColor);

    .action {
      padding: ${theme.spacings.tiny};
      opacity: 0.5;

      transition: ${theme.transition.default} all;

      button {
        white-space: nowrap;
      }
    }

    &:hover {
      --baseColor: ${theme.colors.primary};
      box-shadow: 0 16px 2.5rem -1rem rgb(87 62 113 / 10%);

      .action {
        opacity: 1;
      }
    }

    ${hasitem && containerModifiers.hasitem(theme)}
    ${editMode && containerModifiers.editMode(theme)}
    ${isSelected && containerModifiers.isSelected(theme)}
    ${template === 'weekdays' && containerModifiers.columns()}
    ${template === 'exceptions' && containerModifiers.rows(theme)}
    ${template === 'without-work' && containerModifiers.rows(theme)}
    ${template === 'without-work' &&
    isGlobal &&
    containerModifiers.isDateLocal(theme)}
  `}
`;

/**
 * Time
 */
const timeListModifiers = {
  editMode: () => css`
    opacity: 0.5;
  `,
  isSelected: () => css`
    opacity: 1;
  `,
  columns: theme => css`
    &,
    > .containerGroup {
      flex-direction: column;

      & + .containerGroup {
        margin-top: ${theme.spacings.xxxsmall};
      }
    }
  `,
};

export const TimeList = styled.div`
  ${({ theme, editMode, isSelected, template }) => css`
    display: flex;

    margin: 0;
    padding: 0.5rem;
    flex: 1 1 100%;

    transition: ${theme.transition.default} all;

    > .containerGroup {
      display: flex;

      > * {
        margin: 0.25rem;
      }
    }

    ${editMode && timeListModifiers.editMode()}
    ${isSelected && timeListModifiers.isSelected()}
    ${template === 'weekdays' && timeListModifiers.columns(theme)}
  `}
`;

/**
 * Header
 */

const headerModifier = {
  default: theme => css`
    --baseColor: ${theme.colors.tertiary};
  `,
  isSelected: theme => css`
    --baseColor: ${theme.colors.primary};
  `,
  rows: theme => css`
    border-top: 0;
  `,
  withoutWork: theme => css`
    > .title {
      display: flex;

      .description {
        width: 100%;
        flex: 1 1 100%;
        margin-left: 0;
      }
    }
  `,
};

export const Header = styled.header`
  ${({ theme, isSelected, template }) => css`
    --baseColor: ${theme.colors.tertiary};

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 0.25rem;
    padding: ${theme.spacings.xsmall} ${theme.spacings.xsmall}
      ${theme.spacings.xxxsmall};

    > .title {
      display: flex;
      align-items: center;

      font-weight: ${theme.font.bolder};
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.dark};
      text-overflow: ellipsis;
      flex: 1;
      flex-wrap: wrap;

      > div {
        width: 100%;

        label {
          margin-bottom: 0;
          padding-right: 0;
        }
      }

      .globalTag {
        display: inline-flex;
        align-items: center;
        margin-top: ${theme.spacings.tiny};
        padding: 0.25rem 0.5rem;
        width: auto;

        border-radius: 0.25rem;

        font-size: ${theme.font.sizes.xsmall};
        font-weight: normal;
        background-color: ${theme.colors.light};
        border: 1px solid ${theme.colors.tertiary};
        color: ${theme.colors.dark};

        svg {
          margin-right: ${theme.spacings.tiny};
          color: ${theme.colors.primary};
        }
      }

      .description {
        font-weight: normal;
        margin-left: 0.25rem;
        line-height: 1.125rem;
      }
    }

    ${headerModifier.default(theme)}
    ${isSelected && headerModifier.isSelected(theme)}
    ${template === 'exceptions' && headerModifier.rows(theme)}
    ${template === 'without-work' && headerModifier.rows(theme)}
    ${template === 'without-work' && headerModifier.withoutWork(theme)}

    > .total {
      display: flex;
      align-items: center;

      padding: 0.25rem;
      border-radius: 0.25rem;

      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.small};
      line-height: 1;

      background-color: var(--baseColor);
      color: ${theme.colors.white};
    }
  `}
`;
