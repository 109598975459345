/**
 * Formatação para itens simples. Retorna menos informações, apenas as básicas para identificação.
//  */

// import { promotionCartItemModel } from 'models/promotions'
// import { AlternativeProduct } from 'services/shoppingCart/types'

// import { SimpleProduct } from 'hooks/useCart/types/types'

import { formatPricesOffer } from './pricesItem';

export const simpleItemModel = data => ({
  id: data.id,
  externalId: data.external_id,
  gtin: data.gtin,
  title: data.name,
  sizePack: data?.size_pack,
  status: data?.status,
  stock: data?.stock,
  unitMeasurement: data?.unit_measurement,
  assets: { thumbnail: data?.asset?.[0] },
  prices: formatPricesOffer(data),
  tags: data?.tags || null,
  // promotion: promotionCartItemModel(data?.promotion || null)
});
