import { produce } from 'immer';
import * as types from './integration-constants';

const INITIAL_STATE = {
  loading: false,
  progress: 0,
  finishIntegration: false,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.INTEGRATION_BLING_REQUEST}_FULFILLED`:
        draft.loading = true;
        break;
      case 'integration_sync':
        draft.progress = action.data.progress;
        draft.loading = true;
        if (action.data.progress >= 100) {
          draft.finishIntegration = true;
          draft.loading = false;
        }
        break;
      default:
        return state;
    }
  });
