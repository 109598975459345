import { Spinner } from '../Spinner'
import { t } from 'i18next'
import { Container } from './styles'

type LoadingPopUpProps = {
  isActive: boolean
  text?: string
}
/**
 * Indicador de loading pop up que aparece no canto inferior direito da tela
 */

const LoadingPopUp: React.FC<LoadingPopUpProps> = ({
  isActive,
  text = t('rm:table.list.updating'),
}) => {
  return (
    <Container data-active={isActive}>
      <Spinner color="#ffffff" />
      <div className="text">{text}</div>
    </Container>
  )
}

export default LoadingPopUp
