import styled, { css } from 'styled-components';

const containerModifiers = {
  isGlobal: theme => css`
    background-color: ${theme.colors.light};
    color: ${theme.colors.primaryLighten30};
    border: 1px solid ${theme.colors.white};
  `,
  isNew: theme => css`
    background-color: ${theme.colors.warning};

    .icon,
    .text {
      color: ${theme.colors.white};
    }
  `,
  isRepeatable: theme => css`
    &:not(:last-child) {
      margin-right: ${theme.spacings.xxxsmall};

      &:after {
        z-index: -1;
        position: absolute;
        left: 100%;
        bottom: 50%;

        transform: translate(1px, 100%);
        content: '';
        border-top: 1px dashed ${theme.colors.primaryLighten30};
        height: 1px;
        width: 0.5rem;
      }
    }
  `,
  columns: theme => css`
    &:not(:last-child) {
      margin-bottom: ${theme.spacings.xxxsmall};
      margin-right: 0;

      &:after {
        border-left: 1px dashed ${theme.colors.primaryLighten30};
        width: 1px;
        height: 0.5rem;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
      }
    }
  `,
};

export const Container = styled.div`
  ${({ theme, isNew, isRepeatable, isGlobal, template }) => css`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.5rem;
    border-radius: 0.25rem;

    background-color: ${theme.colors.white};

    position: relative;

    z-index: 1;

    line-height: 1;

    .icon {
      margin-right: ${theme.spacings.xxxsmall};
      font-size: ${theme.font.sizes.xsmall};
    }

    .text {
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.bold};

      white-space: nowrap;
    }

    ${isGlobal && containerModifiers.isGlobal(theme)}
    ${isNew && containerModifiers.isNew(theme)}
    ${isRepeatable && containerModifiers.isRepeatable(theme)}
    ${isRepeatable &&
    template === 'columns' &&
    containerModifiers.columns(theme)}

&[role='button'] {
      cursor: pointer;
      background-color: ${theme.colors.thistle};

      .text {
        color: ${theme.colors.primary};
      }
    }
  `}
`;
