import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.section`
  background-color: white;

  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  ${({ theme }) => css`
    @media ${device.tablet} {
      border-radius: ${theme.border.radius.medium};
    }

    @media ${device.tablet_max} {
      border-radius: 0;

      .goBack__button {
        background-color: ${theme.colors.hover};
        border-color: ${theme.colors.hover};
      }

      .goBack__button,
      .HeaderChat {
        order: 0;
      }

      .AttendantActions {
        order: 1;
      }

      .ChatContent,
      .ChatSubmitBar {
        order: 2;
      }
    }
  `}

  max-width: 100%;
  overflow: hidden;

  .empty-page {
    svg {
      max-height: 300px;
    }

    .header-pages .title .wrap {
      p,
      h1 {
        text-align: center;
      }
    }
  }
`
