import React from 'react';

import { DatepickerHeader } from '~/components/datepicker-header';
import HeaderPages from '~/components/HeaderPages';
import PageTitle from '~/components/PageTitle';

import { Actions } from './elements/Actions';
import PaymentsTable from './elements/PaymentsTable';

const PaymentsReport = () => (
  <>
    {/* Meta <title> */}
    <PageTitle title="Pagamentos > Relatórios" />

    <HeaderPages
      title="Relatório de pagamento"
      sideRightComponent={<DatepickerHeader />}
      className="has-datepicker"
    />

    <Actions />

    <PaymentsTable />
  </>
);

export default PaymentsReport;
