import styled, { DefaultTheme, css } from 'styled-components'

/**
 * Common Props
 */
type CommonProps = {
  isActive: boolean
}

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(4, minmax(52px, auto));
    background: ${theme.colors.primaryLighten60};
    z-index: 110;
  `}
`

export const Item = styled.div<CommonProps>`
  ${({ theme, isActive }) => css`
    &:not(:last-child) {
      border-right: 1px solid ${theme.colors.primaryLighten61};
    }
  `}
`

const buttonModifiers = {
  isActive: (theme: DefaultTheme) => css`
    --bgLength: ${theme.colors.hover};

    color: ${theme.colors.hover};
    border-bottom-color: ${theme.colors.hover};

    font-weight: ${theme.font.bold};
  `
}

export const Button = styled.div<CommonProps>`
  ${({ theme, isActive }) => css`
    --bgLength: ${theme.colors.stormGray};

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.5rem;

    width: 100%;
    height: 100%;

    border-bottom: 3px solid transparent;

    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.bold};

    color: ${theme.colors.primary};

    transition: 0.3s ease-in-out all;

    cursor: pointer;

    ${isActive && buttonModifiers.isActive(theme)}
  `}
`

export const Counter = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1rem;
    min-width: 1rem;
    max-width: 3rem;
    padding: 0.25rem;
    background: var(--bgLength);
    color: ${theme.colors.white};
    border-radius: 2rem;
    margin-right: 0.25rem;
    font-size: ${theme.font.sizes.xxsmall};
  `}
`
