import React from 'react'
import { useSelector } from 'react-redux'

import { HistoryPanel } from '../HistoryPanel'
import ShoppingCartPanel from '../ShoppingCartPanel'
import TagsPanel from '../TagsPanel'
import TemplatePanel from '../TemplatesPanel'
import UserPanel from '../UserPanel'

import * as S from './styles'

const SlidingPanels = () => {
  const {
    customerPanelOpen,
    templatesPanelOpen,
    tagsPanelOpen,
    slidingPanelIsOpen,
    shoppingCartPanelOpen,
    templatePanelHistory
  } = useSelector(({ chats }) => chats)

  return (
    <S.Container isOpen={slidingPanelIsOpen}>
      {customerPanelOpen && <UserPanel />}

      {templatePanelHistory && <HistoryPanel />}

      {templatesPanelOpen && <TemplatePanel />}

      {tagsPanelOpen && <TagsPanel />}

      {shoppingCartPanelOpen && <ShoppingCartPanel />}
    </S.Container>
  )
}

export default SlidingPanels
