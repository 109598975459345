import { useCallback, useEffect, useMemo } from 'react'
import { AiOutlineCalendar } from 'react-icons/ai'
import { FiX } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { formatDateToString } from '~/helpers'
import { historyChat, togglePanelHistory } from '~/modules/chat/store/actions'
import { useAppSelector } from '~/store/hooks'

import { actions } from './constants'

import * as S from './styles'

export const HistoryPanel = () => {
  const dispatch = useDispatch()

  const {
    selectedChat,
    historyChat: historyChatData,
    historyChatLoading
  } = useAppSelector(({ chats }) => chats)

  useEffect(() => {
    dispatch(
      historyChat({
        // sessionId: selectedChat?.session?.sessionId
        chatId: selectedChat?.chatId
      })
    )
  }, [dispatch, selectedChat?.chatId, selectedChat?.session?.sessionId])

  const handlePanelHistory = useCallback(() => {
    dispatch(togglePanelHistory())
  }, [dispatch])

  const groupedChatByDate: GroupedChatByDate = useMemo(() => {
    const data = historyChatData.reduce((prev, current) => {
      const date = formatDateToString(current.createdAt, 'DD/MM/YYYY')

      const formattedCurrent = {
        ...(current || [])
      }

      return {
        ...prev,
        [date]: [...(prev[date] || []), formattedCurrent]
      }
    }, {})

    return data
  }, [historyChatData])

  const chatHistoryData = useMemo((): ChatHistoryData[] => {
    return Object.entries(groupedChatByDate).map(chatHistory => chatHistory)
  }, [groupedChatByDate])

  const hasNewUser = useCallback(
    (item: History) => item?.metadata?.newUserId === null,
    []
  )

  const formatDate = useCallback(
    (date: string) => formatDateToString(date, 'HH:mm'),
    []
  )

  const isTransferChat = useCallback(
    (item: History) =>
      !!item?.metadata?.newUserId && item.action === 'transfer',
    []
  )

  return (
    <S.Container>
      <header>
        <span>Histórico</span>
        <Button
          size="small"
          text={<FiX />}
          template="transparent"
          onClick={handlePanelHistory}
        />
      </header>

      <Loading status={historyChatLoading}>Carregando histórico</Loading>

      <dl>
        {!!chatHistoryData &&
          chatHistoryData.map(([date, data]) => (
            <div key={date}>
              <S.HeaderList>
                <AiOutlineCalendar />
                {date}
              </S.HeaderList>

              {data?.map((item: History) => (
                <S.ContentHistory key={item.createdAt}>
                  <S.ActionTitle>
                    {hasNewUser(item) ? actions.unbind : actions[item.action]}
                  </S.ActionTitle>
                  <dd>
                    {isTransferChat(item) ? (
                      <>
                        <S.Text>De: {item.metadata.oldUserName}</S.Text>
                        <S.ContentName>
                          <S.Text>Para: {item.metadata.newUserName}</S.Text>
                          <S.Text>{formatDate(item.createdAt)}</S.Text>
                        </S.ContentName>
                      </>
                    ) : (
                      <S.ContentName>
                        <S.Text>
                          {hasNewUser(item)
                            ? item?.metadata?.oldUserName
                            : item?.metadata?.userName}
                        </S.Text>
                        <S.Text>{formatDate(item.createdAt)}</S.Text>
                      </S.ContentName>
                    )}
                  </dd>
                </S.ContentHistory>
              ))}
            </div>
          ))}
      </dl>
    </S.Container>
  )
}
