import { AdsTableProvider } from './hooks/useAdsTable'
import Table from './Table'

const AdsTable = ({
  campaignAdType
}: Pick<AdsTableProviderProps, 'campaignAdType'>) => (
  <AdsTableProvider campaignAdType={campaignAdType}>
    <Table />
  </AdsTableProvider>
)

export default AdsTable
