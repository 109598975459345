import styled from 'styled-components';

export const Container = styled.section`
  padding: 1rem;
  width: 100%;

  form {
    > * + * {
      margin-top: 1rem;
    }
  }
`;
