import React, { useRef, useMemo, useState, useCallback } from 'react';
import { FiSave, FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';

import { Button } from '~/components/Buttons';
import { Spinner } from '~/components/Spinner';
import paymentActions from '~/store/modules/payment/actions/';

import * as S from './styles';
import Type from './Type';

const EditForm = ({ locationId, onCancel, configId }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const {
    paymentMethodsConfigList,
    inPersonMethods,
    onlineMethods,
    paymentMethodsConfigUpdateLoading,
  } = useSelector(({ payment }) => payment);

  const compareMethods = ({ item, toFilter }) => {
    return item.map(item =>
      toFilter.find(config => config.method === item.method)
        ? { ...item, active: true }
        : { ...item, active: false },
    );
  };

  const compareSubMethod = ({ item, toFilter }) => {
    const { method } = item;

    const filteredItem = toFilter.find(item => item.method === method);

    return item?.sub_methods?.map(item =>
      filteredItem?.sub_methods.find(subItem => subItem.method === item.method)
        ? { ...item, active: true }
        : { ...item, active: false },
    );
  };

  const currentConfig = useMemo(() => {
    let filtered;

    filtered = paymentMethodsConfigList.find(
      item => item.location_id === locationId,
    );

    if (!filtered) {
      filtered = paymentMethodsConfigList.find(item => !item.locationId) || {
        online_payment_methods: [],
        in_person_payment_methods: [],
      };
    }

    if (filtered) {
      const onlinePaymentMethodsFiltered = compareMethods({
        item: onlineMethods,
        toFilter: filtered.online_payment_methods,
      });

      const online_payment_methods = onlinePaymentMethodsFiltered.map(item => {
        const sub_methods = compareSubMethod({
          item,
          toFilter: filtered.online_payment_methods,
        });

        return { ...item, sub_methods };
      });

      const inPersonPaymentMethodsFiltered = compareMethods({
        item: inPersonMethods,
        toFilter: filtered.in_person_payment_methods,
      });

      const in_person_payment_methods = inPersonPaymentMethodsFiltered.map(
        item => {
          const sub_methods = compareSubMethod({
            item,
            toFilter: filtered.in_person_payment_methods,
          });

          return { ...item, sub_methods };
        },
      );

      filtered = { online_payment_methods, in_person_payment_methods };
    }

    return filtered;
  }, [inPersonMethods, locationId, onlineMethods, paymentMethodsConfigList]);

  /**
   * Função que gerencia o checkbox dos Submétodos
   * Marcando o método caso algum submétodo esteja ativo
   */

  const handleSubMethodClick = ({ method }) => {
    if (!formRef.current) return;

    formRef.current.setFieldValue(`${method}.active`, true);
  };

  const types = [
    {
      type: 'online',
      scope: 'online_payment_methods',
      title: 'Pagamento online',
      subtitle:
        'Selecione as formas de pagamento online que o local irá aceitar',
    },
    {
      type: 'in_person',
      scope: 'in_person_payment_methods',
      title: 'Pagamento presencial',
      subtitle:
        'Selecione as formas de pagamento presencial que o local irá aceitar',
    },
  ];

  /**
   * Essa função tem a responsabilidade de filtrar os métodos ativos para enviar na request
   */
  const filterActiveMethods = ({ methods }) => {
    const activeMethods = methods?.filter(method => method.active) || [];

    const filterSubMethods = method => {
      if (method?.sub_methods) {
        return {
          ...method,
          sub_methods: method?.sub_methods.filter(
            subMethod => subMethod.active,
          ),
        };
      }

      return method;
    };

    const filteredMethodsAndSubMethods = activeMethods.map(method =>
      filterSubMethods(method),
    );

    return filteredMethodsAndSubMethods;
  };

  /**
   * Handle submit form
   */
  const dispatch = useDispatch();

  const handleFormSubmit = useCallback(
    async formData => {
      try {
        setLoading(true);

        const requestBody = {
          in_person_payment_methods: filterActiveMethods({
            methods: formData.in_person_payment_methods,
          }),
          online_payment_methods: filterActiveMethods({
            methods: formData.online_payment_methods,
          }),
        };

        if (configId) {
          dispatch(
            paymentActions.methodsConfig.update({
              id: configId,
              body: requestBody,
              onSuccess: onCancel,
            }),
          );
        } else {
          const body = locationId
            ? { ...requestBody, location_id: locationId }
            : { ...requestBody };

          dispatch(
            paymentActions.methodsConfig.create({
              body,
              onSuccess: onCancel,
            }),
          );
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    },
    [configId, dispatch, locationId, onCancel],
  );

  return (
    <>
      {currentConfig && (
        <Form
          ref={formRef}
          onSubmit={handleFormSubmit}
          initialData={currentConfig}
        >
          <S.Container>
            <S.Content>
              {types.map(type => (
                <Type
                  key={type.scope}
                  onSubMethodClick={handleSubMethodClick}
                  locationId={locationId}
                  type={type.type}
                  scope={type.scope}
                  title={type.title}
                  subtitle={type.subtitle}
                  formRef={formRef}
                />
              ))}
            </S.Content>

            <S.ButtonContainer>
              <Button
                template="danger"
                text="Cancelar"
                onClick={onCancel}
                iconLeft={<FiX />}
              />

              <Button
                template="success"
                type="submit"
                text="Salvar"
                iconLeft={
                  loading || paymentMethodsConfigUpdateLoading ? (
                    <Spinner />
                  ) : (
                    <FiSave />
                  )
                }
              />
            </S.ButtonContainer>
          </S.Container>
        </Form>
      )}
    </>
  );
};

export default EditForm;

EditForm.defaultProps = {
  locationId: null,
  configId: null,
};

EditForm.propTypes = {
  locationId: PropTypes.string,
  configId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
};
