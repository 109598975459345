import { produce } from 'immer';

import * as types from './constants';

const INITIAL_STATE = {
  listOperators: null,
  listOperatorsLoading: false,
  operator: null,
  loading: false,
  loadingDelete: false,
  error: null,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.OPERATOR_LIST}_PENDING`: {
        draft.listOperatorsLoading = true;
        draft.listOperators = null;
        break;
      }
      case `${types.OPERATOR_LIST}_FULFILLED`: {
        draft.listOperators = action.payload;
        draft.listOperatorsLoading = false;
        draft.error = null;
        break;
      }
      case `${types.OPERATOR_LIST}_REJECTED`: {
        draft.listOperatorsLoading = false;
        draft.error = action.payload;
        break;
      }

      // Create operator

      case `${types.OPERATOR_SUBMIT_FORM}_PENDING`: {
        draft.loading = true;
        break;
      }
      case `${types.OPERATOR_SUBMIT_FORM}_FULFILLED`: {
        draft.loading = false;
        draft.error = null;
        break;
      }
      case `${types.OPERATOR_SUBMIT_FORM}_REJECTED`: {
        draft.error = action.payload;
        draft.loading = false;
        break;
      }

      // Update operator

      case `${types.OPERATOR_UPDATE_INFO}_PENDING`: {
        draft.loading = true;
        break;
      }
      case `${types.OPERATOR_UPDATE_INFO}_FULFILLED`: {
        draft.loading = false;
        draft.error = null;
        break;
      }
      case `${types.OPERATOR_UPDATE_INFO}_REJECTED`: {
        draft.error = action.payload;
        draft.loading = false;
        break;
      }

      // Show operator

      case `${types.OPERATOR_GET_INFO}_PENDING`: {
        draft.loading = true;
        draft.operator = null;
        break;
      }
      case `${types.OPERATOR_GET_INFO}_FULFILLED`: {
        draft.operator = action.payload;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case `${types.OPERATOR_GET_INFO}_REJECTED`: {
        draft.error = action.payload;
        draft.loading = false;
        break;
      }

      // Delete operator

      case `${types.OPERATOR_DELETE}_PENDING`: {
        draft.loadingDelete = true;
        break;
      }
      case `${types.OPERATOR_DELETE}_FULFILLED`: {
        draft.loadingDelete = false;
        draft.error = null;
        break;
      }
      case `${types.OPERATOR_DELETE}_REJECTED`: {
        draft.error = action.payload;
        draft.loadingDelete = false;
        break;
      }

      default:
        return state;
    }
  });
