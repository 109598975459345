import React, { useState, useRef } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'

import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import Panel from '~/components/Panel'

import ApproverCard from '../ApproverCard'
import ApproverForm from '../ApproverForm'
import EmptyApproverCard from '../EmptyApproverCard'

import * as S from './styles'

const Approvers = () => {
  const formRef = useRef(null)

  const [showApproverForm, setShowApproverForm] = useState(false)

  const handleApproverForm = () => {
    setShowApproverForm(true)
  }
  return (
    <>
      <HeaderPages title="Fluxo de aprovação" />

      <Panel
        title="Aprovadores"
        description="Preencha as informações dos responsáveis pela aprovação da campanha."
        isCollapsible
      >
        <Form ref={formRef} onSubmit={() => {}}>
          <S.ApproversWrapper>
            <div className="approvers-content">
              <div className="approver-header">
                <h3>Zona Sul</h3>
                <p>Adicione os responsáveis por aprovar essa campanha.</p>
              </div>
              <div>
                {showApproverForm ? (
                  <ApproverForm />
                ) : (
                  <>
                    <ApproverCard
                      name="João da Silva"
                      approver_role="Gerente de compras"
                      email="joao.silva@exemplo.com"
                    />

                    <ApproverCard
                      name="Maria da Silva"
                      approver_role="Gerente de compras"
                      email="maria.silva@exemplo.com"
                    />
                    <Button
                      template="primaryOutlined"
                      text="Adicionar responsável por aprovação"
                      iconLeft={<AiOutlinePlus />}
                      onClick={handleApproverForm}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="approvers-content">
              <div className="approver-header">
                <h3>Unilever</h3>
                <p>Adicione os responsáveis por aprovar essa campanha.</p>
              </div>
              <div>
                <EmptyApproverCard />

                {/* <ApproverCard
                name="João Souza"
                approver_role="Vendedor"
                email="joao.souza@exemplo.com"
              />

              <ApproverCard
                name="João Souza"
                approver_role="Vendedor"
                email="joao.souza@exemplo.com"
              /> */}
              </div>

              <Button
                template="primaryOutlined"
                text="Adicionar responsável por aprovação"
                iconLeft={<AiOutlinePlus />}
              />
            </div>
          </S.ApproversWrapper>
        </Form>
      </Panel>
    </>
  )
}

export default Approvers
