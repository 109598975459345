import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';

import PropTypes from 'prop-types';

import SelectSearchable from '~/components/SelectSearchable';
import { statusListBoard } from '~/pages/orders/_status/order';
import { setFilteredStatus } from '~/store/modules/filters/actions';
import { primary as theme } from '~/styles/themes';

/**
 * Componente para filtrar os status do pedido
 * Ele possibilita a seleção de diversos status e mantém no estado global: filters.status.
 *
 * Pode receber initialData e props adicionais para o select.
 *
 * @example <FilterOrderStatusMulti initialData={initialData} {...props} />
 */

const FilterOrderStatusMulti = ({ initialData, props }) => {
  const options = useMemo(
    () => [
      {
        label: 'Todos os status',
        value: 'resetAllStatus-systemDefault',
        reset: true,
      },
      ...statusListBoard,
    ],
    [],
  );

  const dispatch = useDispatch();

  const { status: filteredStatus } = useSelector(state => state.filters);

  useEffect(() => {
    if (initialData === null && filteredStatus !== null) {
      return dispatch(
        setFilteredStatus({
          filteredStatus: null,
        }),
      );
    }

    if (initialData.length === statusListBoard.length) {
      return dispatch(
        setFilteredStatus({
          filteredStatus: null,
        }),
      );
    }

    if (filteredStatus === null && initialData.length && initialData !== null) {
      const filteredStatus = initialData.map(item =>
        options.find(option => option.value === item),
      );

      dispatch(
        setFilteredStatus({
          filteredStatus,
        }),
      );
    }
  }, [dispatch, filteredStatus, initialData, options]);

  const handleChangeStatus = selectedStatus => {
    const shouldReset = selectedStatus?.findIndex(item => item?.reset) > -1;

    const updatedStatus = shouldReset ? null : selectedStatus;

    dispatch(setFilteredStatus({ filteredStatus: updatedStatus }));
  };

  const dot = isSelected => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: isSelected ? theme.colors.success : theme.colors.gray,
      borderRadius: 10,
      content: '""',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const removeOption = () => ({
    ':after': {
      content: '"x"',
      position: 'absolute',
      top: '50%',
      right: '1rem',
      transform: 'translateY(-50%)',
      fontSize: '.875rem',
      color: theme.colors.gray,
    },
  });

  const customStyles = {
    valueContainer: styles => ({
      ...styles,
      color: 'hsl(0,0%,50%)',
    }),
    singleValue: (styles, { isSelected }) => ({
      ...styles,
      ...dot(isSelected),
    }),
    menuList: styles => ({
      ...styles,
      display: 'flex',
      flexDirection: 'column',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        position: 'relative',
        backgroundColor: (() => {
          if (isFocused) {
            return theme.colors.hover;
          }

          return isSelected ? theme.colors.primary : theme.colors.white;
        })(),
        color: (() => {
          if (isDisabled) {
            return theme.colors.inative;
          }

          if (isFocused) {
            return theme.colors.light;
          }

          return isSelected ? theme.colors.light : theme.colors.dark;
        })(),
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: () => {
            if (isFocused) {
              return theme.colors.infoLighten50;
            }
            if (!isDisabled) {
              return isSelected
                ? theme.colors.light
                : theme.colors.secondaryLighten30;
            }
          },
        },
        ...dot(isSelected),
        ...(isSelected ? removeOption() : {}),
      };
    },
  };

  const ValueContainer = ({ children, getValue, ...rest }) => {
    const { length } = getValue();

    return (
      <components.ValueContainer {...rest}>
        {`Filtrar por status${
          length ? ` (${length} selecionado${length !== 1 ? 's' : ''})` : ''
        }`}
      </components.ValueContainer>
    );
  };

  ValueContainer.propTypes = {
    children: PropTypes.node.isRequired,
    getValue: PropTypes.func.isRequired,
  };

  return (
    <SelectSearchable
      placeholder="Filtrar status"
      options={options}
      onChange={handleChangeStatus}
      defaultValue={filteredStatus}
      isMulti
      className="selectWrapper"
      styles={customStyles}
      hideSelectedOptions={false}
      components={{ ValueContainer }}
      {...props}
    />
  );
};

export default FilterOrderStatusMulti;

FilterOrderStatusMulti.propTypes = {
  props: PropTypes.shape({}),
  initialData: PropTypes.arrayOf(PropTypes.string),
};

FilterOrderStatusMulti.defaultProps = {
  props: null,
  initialData: null,
};
