import { produce } from 'immer'
import { AnyAction } from 'redux'

import { LOGIN_LOGGED_OUT } from '~/modules/auth/store/login/constants'

import * as types from './constants'

const INITIAL_STATE: AccountState = {
  accountInfo: null,
  listAccountInfoLoading: false,
  error: null
}

export default (state = INITIAL_STATE, action: AnyAction) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.ACCOUNT_INFO_GET}_PENDING`: {
        draft.listAccountInfoLoading = true
        break
      }
      case `${types.ACCOUNT_INFO_GET}_FULFILLED`: {
        draft.accountInfo = { ...action.payload[0] }
        draft.listAccountInfoLoading = false
        break
      }
      case `${types.ACCOUNT_INFO_GET}_REJECTED`: {
        draft.listAccountInfoLoading = false
        draft.error = action.payload
        break
      }

      //
      case `${LOGIN_LOGGED_OUT}`: {
        draft.accountInfo = null
        break
      }

      default:
        return state
    }
  })
