import React from 'react';
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi';

import PropTypes from 'prop-types';

import * as S from './styles';

const RulesList = ({ rules, ruleErrors = [] }) => {
  return (
    <S.Container>
      {Object.keys(rules).map(item => (
        <div className="rule" key={item}>
          {ruleErrors.some(rule => rule === item) ? (
            <div className="icon error">
              <FiAlertCircle />
            </div>
          ) : (
            <div className="icon success">
              <FiCheckCircle />
            </div>
          )}{' '}
          <div className="text">{rules[item]}</div>
        </div>
      ))}
    </S.Container>
  );
};

export default RulesList;

RulesList.propTypes = {
  rules: PropTypes.shape({}).isRequired,
  ruleErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
};
