import styled, { css } from 'styled-components';

const containerModifiers = {
  isDraggingOver: ({ theme }) => css`
    background-color: ${theme.colors.primaryLighten60};
    border: 1px dashed ${theme.colors.primaryLighten30};
  `,
  isLoading: () => css`
    animation: pulseBg 2s ease-in-out infinite;
    -webkit-animation: pulseBg 2s ease-in-out infinite;
  `,
};

export const Container = styled.div`
  ${({ theme, isDraggingOver, isLoading }) => css`
    @-webkit-keyframes pulseBg {
      0% {
        background: ${theme.colors.primaryLighten60};
      }
      50% {
        background: ${theme.colors.secondaryLighten30};
      }
      100% {
        background: ${theme.colors.primaryLighten60};
      }
    }

    @keyframes pulseBg {
      0% {
        background: ${theme.colors.primaryLighten60};
      }
      50% {
        background: ${theme.colors.secondaryLighten30};
      }
      100% {
        background: ${theme.colors.primaryLighten60};
      }
    }

    position: relative;
    background: ${theme.colors.light};
    border: 1px dashed ${theme.colors.gray};
    border-radius: 1rem;
    min-height: 48px;

    transition: ${theme.transition.default} all;

    ${isDraggingOver && containerModifiers.isDraggingOver({ theme })}
    ${isLoading && containerModifiers.isLoading({ theme })}

    .content {
      padding: 1rem;

      .legend {
        text-align: center;

        .icon {
          color: ${theme.colors.primary};
          font-size: 2rem;
        }
      }
    }

    > input {
      position: absolute;
      top: 0;
      left: 0;
      min-height: 100%;
      width: 100%;
      opacity: 0;
      z-index: 2;
    }
  `}
`;

export const Preview = styled.div`
  display: flex;
  justify-content: center;
`;

export const File = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 0.25rem;
    margin: 0.25rem;
    border-radius: 0.25rem;
    font-size: ${theme.font.sizes.xsmall};
    background: ${theme.colors.primary};
    color: ${theme.colors.white};

    .icon {
      color: ${theme.colors.secondary};
      margin-right: 0.125rem;
    }
  `}
`;
