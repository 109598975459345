import React, { useMemo } from 'react'

import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import LoadingPopUp from '~/components/LoadingPopUp'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { useAlert } from '~/hooks/useAlert'
import { useSWRSort } from '~/hooks/useSWRSort'

import { schema } from './schemas'
import { schemaWithMetrics } from './schemas/with-metrics'

const AdsProductsTable = ({ adType = null }: { adType: CampaignAdType }) => {
  const {
    data,
    total,
    page,
    perPage,
    sortDirection,
    sortKey,
    handleSortList,
    loading,
    error,
    isValidating,
    handlePagination,
    handleItemsPerPage
  } = useSWRSort<CampaignProduct[]>()

  const [AlertOnError] = useAlert(error)

  const currentSchema = useMemo(
    () => (adType === 'sponsored_brand' ? schemaWithMetrics : schema),
    [adType]
  )

  return (
    <>
      <AlertOnError />

      <Loading status={loading && !data}>Carregando produtos...</Loading>

      <LoadingPopUp
        isActive={isValidating || loading}
        text="Atualizando lista de produtos..."
      />

      <Panel noPaddingBody>
        <Table
          schema={currentSchema}
          data={data}
          striped
          sideBorder
          smallerRowPadding
          activeSortDirection={sortDirection}
          activeSortKey={sortKey}
          onSort={handleSortList}
        />
      </Panel>

      <footer>
        <ListPagination
          total={total}
          label={total > 1 ? 'produtos' : 'produto'}
          currentPage={page}
          itemsPerPage={perPage}
          menuPlacement="top"
          onClickPagination={handlePagination}
          onItemsPerPageChange={handleItemsPerPage}
        />
      </footer>
    </>
  )
}

export default AdsProductsTable
