import { Scope } from '@unform/core'

import { FormGroup, Label, Radio } from '~/components/unform'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { useCampaignForm } from '../../hooks/useCampaignForm'

const CostTypeScope: React.FC = () => {
  const { setCostType } = useCampaignForm()
  const { t, tCapitalize } = useAPPTranslation()

  const optionsTypes: RadioOptionProps[] = [
    {
      id: `cost_type_cpmcpc`,
      value: 'cpmcpc',
      label: `CPM ${t('c:words.or')} CPC`
    },
    {
      id: `cost_type_daily_cost`,
      value: 'daily_cost',
      label: tCapitalize('rm:dailyCost')
    }
  ]

  const handleCostType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as CampaignCostType
    setCostType(value)
  }

  return (
    <Scope path="cost">
      <FormGroup>
        <Label
          text={t('rm:CostTypeScope.label')}
          helperText={t('rm:CostTypeScope.helperText')}
        />

        <Radio
          options={optionsTypes}
          name="cost_type"
          required
          onChange={handleCostType}
        />
      </FormGroup>
    </Scope>
  )
}

export default CostTypeScope
