import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { Scope } from '@unform/core';
import { Form } from '@unform/web';

import OldForm from '~/components/forms/form';
import Panel from '~/components/Panel';
import { FormGroup, Input, Label, Textarea } from '~/components/unform';
import { unformatMoney } from '~/helpers';
import { useAlert } from '~/hooks/useAlert';
import {
  getProductInfo,
  updateProductInfo,
} from '~/store/modules/products/actions';

import Forms from './forms';
import ContainerForm from './forms/container-edit';
import FormFooter from './forms/footer';

/**
 * A atualização não está sendo feita por módulos como na criação.
 * De resto, segue o padrão e os componentes do form de criação.
 * Esse arquivo gerencia:
 * - renderização de cada parte do formulário (./forms)
 * - submit
 * - alertas de erro e sucesso
 */

const FormProductEdit = ({ id }) => {
  const [formIsValid, setFormIsValid] = useState(false);

  const { product, loading, error } = useSelector(state => state.products);

  const [AlertOnError] = useAlert(error);

  const dispatch = useDispatch();

  // Busca informações do produto
  useEffect(() => {
    dispatch(getProductInfo({ id }));
  }, [dispatch, id]);

  // Gerenciando o submit do update
  const handleSubmit = formData => {
    const { price, cost_price, site, store, single_stock, ...rest } = formData;

    const price_unformatted = price ? unformatMoney(price) : null;

    const cost_price_unformatted = cost_price
      ? unformatMoney(cost_price)
      : null;

    const updateProduct = {
      location: { site, store, single_stock },
      main: {
        ...rest,
        price: price_unformatted,
        cost_price: cost_price_unformatted,
      },
    };

    dispatch(updateProductInfo({ ...product, ...updateProduct.main }));
  };

  return (
    <>
      {loading && (
        <Panel>
          <Spinner type="grow" size="sm" color="secondary" /> Carregando
          informações do produto
        </Panel>
      )}

      {product && (
        <Form onSubmit={data => console.log(data)} initialData={product}>
          {/* Name */}
          <Panel>
            <FormGroup>
              <Label htmlFor="name">Nome do produto</Label>
              <Input
                name="name"
                placeholder="Ex.: Celular Samsung Galaxy S9 64GB Preto"
                maxLength="60"
                showLength
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="name">Descrição do produto</Label>
              <Textarea
                name="description"
                placeholder="Fale algo sobre o produto aqui..."
              />
            </FormGroup>
          </Panel>

          {/* Brand */}
          <Panel>
            <Scope path="brand">
              <FormGroup>
                <Label htmlFor="brand.name">Marca do produto</Label>
                <Input
                  name="name"
                  placeholder="Ex.: Celular Samsung Galaxy S9 64GB Preto"
                  maxLength="60"
                  showLength
                />
              </FormGroup>
            </Scope>
            <FormGroup>
              <Label htmlFor="name">Descrição do produto</Label>
              <Textarea
                name="description"
                placeholder="Fale algo sobre o produto aqui..."
              />
            </FormGroup>
          </Panel>
        </Form>
      )}
      {product && (
        <OldForm
          onSubmit={handleSubmit}
          checkIsValid={setFormIsValid}
          className={`form-default ${loading && 'loading-data'}`}
        >
          {Forms.map((child, index) => (
            <ContainerForm
              key={index}
              edit
              title={child.title}
              Child={child.component}
              loading={loading}
              initialValue={product}
              onSubmitStep={handleSubmit}
            />
          ))}

          <FormFooter loading={loading} formIsValid={formIsValid} edit />
        </OldForm>
      )}

      <AlertOnError />
    </>
  );
};

export default FormProductEdit;
