export default {
  get: {
    data: null,
    loading: false,
    error: null
  },
  getRealtime: {
    data: [],
    loading: false,
    error: null
  },
  getHistory: {
    data: null,
    loading: false,
    error: null
  }
}
