import { history, formatSearchString } from '~/helpers';
import {
  postFormProduct,
  getProductData,
  updateProductData,
  getProductsList,
  deleteProductService,
} from '~/services/products-service';
import { searchOffersService } from '~/services/search-service';

import * as types from './constants';

/**
 *
 * @exports submitFormProduct Criação de produto
 * @param {object} data  Dados para criação do produto
 *
 * @exports getProductInfo Busca as informações de um produto
 * @param {string} object.id ID do produto
 *
 * @exports updateProductInfo Atualiza o produto
 * @param {object} data Dados para atualizar o produtos
 *
 * @exports productsList Busca todos os produtos da loja
 * @param {number} page Gerencia a paginação
 *
 * @exports deleteProduct Delete produto
 * @param {string} id Gerencia a paginação
 *
 */

export const submitFormProduct = data => {
  return {
    type: types.PRODUCT_SUBMIT_FORM,
    payload: postFormProduct(data),
    onSuccess: response => {
      const idNewProduct = response.data.id;

      history.push(`/products/${idNewProduct}`);
    },
    getErrorFeedbackMsg: error => error.response.data.error,
  };
};

export const getProductInfo = ({ id }) => {
  return {
    type: types.PRODUCT_GET_INFO,
    payload: getProductData({ id }),
    onError: () => {
      history.push(`/products/list`);
    },
    getErrorFeedbackMsg: error => error.response.data.error,
  };
};

export const updateProductInfo = data => {
  return {
    type: types.PRODUCT_UPDATE_INFO,
    payload: updateProductData(data),
    onSuccess: response => {
      const idNewProduct = response.data.updated.id;

      history.push(`/products/${idNewProduct}`);
    },
    getErrorFeedbackMsg: error => error.response.data.error,
  };
};

export const productsList = ({ page = 1, quantity, query, location_id }) => {
  const queryString = formatSearchString({
    page,
    quantity,
    location_id,
    query,
  });

  return {
    type: types.PRODUCTS_LIST,
    payload: getProductsList(queryString),
    page,
  };
};

export const searchOffers = ({ page = 1, ...queryString }) => ({
  type: types.SEARCH_OFFERS,
  payload: searchOffersService({ page, ...queryString }),
  page,
});

export const deleteProduct = id => {
  return {
    type: types.PRODUCT_DELETE,
    payload: deleteProductService(id),
    successFeedbackMsg: 'Produto removido com sucesso!',
    onSuccess: () => {
      history.push(`/products/list`);
    },
  };
};

export const handleProductsPerPage = value => {
  return {
    type: types.PRODUCTS_PER_PAGE_CHANGED,
    payload: value,
  };
};
