import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    p {
      font-size: ${theme.font.sizes.small};
    }
  `}
`

export const Tag = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: ${theme.spacings.tiny} ${theme.spacings.xxsmall};

    font-size: ${theme.font.sizes.xsmall};

    border-radius: ${theme.border.radius.medium};
    border: 1px solid ${theme.colors.neutral.high.dark};
    background-color: ${theme.colors.neutral.high.light};

    button {
      display: flex;
      align-items: center;
      margin-right: ${theme.spacings.tiny};
      transition: color 200ms ease;
      color: ${theme.colors.danger};

      &:hover {
        color: ${theme.colors.dangerSaturate20};
      }

      svg {
        font-size: ${theme.font.sizes.small};
      }
    }
  `}
`

export const TagList = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacings.xxsmall};
  `}
`
