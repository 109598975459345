import styled from 'styled-components';

export const WrapperForm = styled.div`
  .defaultColors {
    display: flex;
    flex-wrap: wrap;

    > * {
      margin-bottom: 0.25rem;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  .formGroup,
  footer {
    margin-top: 0.5rem;
  }
  footer {
    text-align: right;
  }
`;
