import React from 'react'

import Panel from '~/components/Panel'
import Tooltip from '~/components/Tooltip'
import { Checkbox, FormGroup, Label, Radio } from '~/components/unform'

const SalesFields = () => {
  const options = [
    {
      id: `available_for_sale_true'`,
      value: true,
      label: 'Ativado'
    },
    {
      id: `available_for_sale_false`,
      value: false,
      label: 'Desativado'
    }
  ]

  return (
    <fieldset>
      <Panel title="Vendas" className="form-grid">
        <FormGroup className="span-full">
          <Label
            htmlFor="available_for_sale"
            text="Vendas pelo WhatsApp Bot"
            helperText="Esse local pode realizar vendas via WhatsApp ou optar apenas por trabalhar com atendimento, envio de encartes e ações promocionais. Você pode ativar e desativar as vendas por meio das opções abaixo."
          />
          <Radio options={options} name="available_for_sale" required inline />
        </FormGroup>

        <FormGroup className="span-full">
          <Checkbox
            name="assisted_sale_bot_flow"
            label={
              <Label
                htmlFor="assisted_sale_bot_flow"
                text="Venda assistida no fluxo do WhatsApp Bot"
                helperText="Esta funcionalidade adiciona um fluxo de venda assistida por um ser humano junto à opção de compra automática com o bot."
                as="span"
              />
            }
            id="assisted_sale_bot_flow"
          />
        </FormGroup>
      </Panel>
    </fieldset>
  )
}

export default SalesFields
