const prefix = '@retailMedia/PLACEMENTS'

export const PLACEMENTS_LIST = `${prefix}/LIST`
export const PLACEMENTS_LIST_SORT = `${prefix}/LIST_SORT`
export const PLACEMENTS_LIST_SET_PAGE = `${prefix}/LIST_SET_PAGE`
export const PLACEMENTS_LIST_PER_PAGE = `${prefix}/LIST_PER_PAGE`

export const types = {
  list: PLACEMENTS_LIST,
  listSort: PLACEMENTS_LIST_SORT,
  listSetPage: PLACEMENTS_LIST_SET_PAGE,
  listPerPage: PLACEMENTS_LIST_PER_PAGE
}
