import * as Yup from 'yup'

export const schemaYup = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  description: Yup.string(),
  promotion_type: Yup.string().required('O tipo de promoção é obrigatório'),
  start_date: Yup.string().required('A data de início é obrigatória'),
  start_hour: Yup.string().required('A data de início é obrigatória'),
  end_date: Yup.string().required('A hora de início é obrigatória'),
  end_hour: Yup.string().required('A hora de término é obrigatória'),
  trigger: Yup.object().shape({
    quantity: Yup.object().shape({
      value: Yup.string()
    })
  }),
  benefits: Yup.object().shape({
    shipping_discount: Yup.object().shape({
      value: Yup.lazy(value => {
        switch (typeof value) {
          case 'object':
            return Yup.object().shape({
              value: Yup.string(),
              numAsString: Yup.string(),
              mounted: Yup.string()
            })
          case 'string':
            return Yup.string()
          default:
            return Yup.mixed() // here you can decide what is the default
        }
      })
    }),
    pay: Yup.object().shape({
      value: Yup.string()
    }),
    price: Yup.object().shape({
      value: Yup.string()
    }),
    discount: Yup.object().shape({
      value: Yup.string()
    })
  })
})
