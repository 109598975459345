import { AxiosError } from 'axios'

type HandleErrorMessage = {
  fallbackMessage?: string
  error:
    | {
        message: string
      }
    | string
}

export const handleErrorMessage = ({
  error,
  fallbackMessage
}: HandleErrorMessage) => {
  if (typeof error === 'string') return error

  if (error?.message) return error.message

  return fallbackMessage || 'Houve um erro'
}

//
export const formatAxiosError = (error: AxiosError<APIErrorRawResponse>) => {
  if (typeof error === 'object' && error.response) {
    const { status, data } = error.response
    const errorMessage = `HTTP Status ${status}`

    if ('message' in data || 'error_id' in data) {
      const message =
        (data as BffError).message || (data as DefaultErrors)?.messages?.[0]
      const errorId = (data as BffError).error_id

      if (errorId && message) {
        return `${message} (ID: ${errorId})`
      }

      if (errorId) {
        return `Erro ID: ${errorId}`
      }

      if (message) {
        return `${message}`
      }
    }

    return errorMessage
  }

  return error.toString()
}
