import React, { PropsWithChildren } from 'react'
import { FiAlertCircle, FiAlertTriangle } from 'react-icons/fi'

import * as S from './styles'

/**
 * # AlertInfo
 * ## Componente de barra de informação
 * Recebe:
 * - `text`: que será a mensagem, podendo ser string ou elemento.
 * - `template`: definição da cor do componente
 *    - `info` | `success` | `success_alert` | `warning` | `danger` | `default`
 *    - Por padrão será usado o template `warning`.
 * - Todas as demais propriedades serão repassadas atavés do `...rest` para o `container` que por sua vez é uma `div`
 */
const AlertInfo = ({
  text,
  template = 'warning',
  children,
  ...rest
}: PropsWithChildren<AlertInfoProps>) => (
  <S.Container
    className="alertInfo"
    data-template={template}
    template={template}
    {...rest}
  >
    <div className="icon">
      {template === 'warning' ? <FiAlertTriangle /> : <FiAlertCircle />}
    </div>
    <div className="content">
      {text}
      {children}
    </div>
  </S.Container>
)

export default AlertInfo
