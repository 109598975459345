type HandleSortDirection = {
  // Valor da chave clicada
  selectedKey: string
  // Chave que está ativa no momento
  currentSortKey: string
  // Direção ativa no momento
  currentSortDirection: SortDirection
  // Direção que deve iniciar a alternância
  sortInitialDirection?: SortDirection
}

export const handleSortDirection = ({
  selectedKey,
  currentSortKey,
  currentSortDirection,
  sortInitialDirection = 'ASC'
}: HandleSortDirection) => {
  const initialDirectionIsAsc = sortInitialDirection === 'ASC'
  const keysRaw = ['ASC', 'DESC'] as SortDirection[]

  const keys = initialDirectionIsAsc ? keysRaw : keysRaw.reverse()

  let sortDirection = currentSortDirection
  let sortKey = currentSortKey

  const isCurrentKey = currentSortKey === selectedKey

  switch (true) {
    case !isCurrentKey:
      sortDirection = keys[0]
      sortKey = selectedKey
      break

    case currentSortDirection === keys[0]:
      sortDirection = keys[1]
      break

    case currentSortDirection === keys[1]:
      sortDirection = keys[0]
      break

    default:
      sortDirection = keys[0]
      return
  }

  return { sortDirection, sortKey }
}
