import axios from 'axios'

// New Endpoints
export const listUserByAccountServiceKey = 'listUserByAccountKey'
export const listUserByAccountService = (
  params: ListUserByAccountServiceParams
) =>
  axios.get<ListUserByAccountServiceResponse>(`/api/user/list-by-account`, {
    params
  })

/**
 *
 * @endpoint /api/account/user-accounts
 * @exports getUserAccountsService  Busca as contas associadas ao usuário
 *
 */

export const getUserAccountsService = () => axios.get(`/api/user/accounts`)

/**
 * Define se uma conta deve ser a conta padrão de um usuário.
 *
 * @param data { user_account_id, is_default_account }
 * @returns { UserChangeAccountDefaultResponse }
 */

export const userChangeAccountDefault = ({
  user_account_id,
  is_default_account
}: UserChangeAccountDefault) =>
  axios.put<UserChangeAccountDefaultResponse>(
    `/api/user/${user_account_id}/change-default-account`,
    {
      is_default_account
    }
  )

// Current
export const putFormUser = data => {
  return axios.post(`/api/onboarding/add-user`, data)
}

export const getUsers = ({ queryString }) => {
  return axios.get(`/api/user/list-users/?${queryString}`)
}

export const getUserService = ({ queryString }) => {
  return axios.get(`/api/user/?${queryString}`)
}

export const resendInviteUserService = data => {
  return axios.post(`/api/onboarding/resend-invite`, data)
}

export const userChangeRole = data => {
  return axios.put(`/api/user/change-role`, data)
}

export const removeUserService = (id: string) => {
  return axios.delete(`/api/user/${id}/remove-from-account`)
}
