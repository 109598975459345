import styled, { css } from 'styled-components';

export const ListLocations = styled.div`
  margin-top: 1rem;

  header {
    margin-bottom: 0.75rem;
  }
`;

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xlarge};
    font-weight: ${theme.font.bolder};
  `}
`;
