/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import PropTypes from 'prop-types'

import Panel from '~/components/Panel'
import { FormGroup, Label } from '~/components/unform'
import MessageWithMediaAndPreview from '~/components/unform/FormGroup/MessageWithMediaAndPreview'
import WhatsAppTextEditor from '~/components/WhatsAppTextEditor'
import { getFileType, handleUploadProgressAxios } from '~/helpers'
import campaignActions from '~/modules/messagingCampaigns/store/campaign/actions'

const Callback = ({ formRef, initialData }) => {
  const pathScopeUnform = 'callbacks[0]'
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [previewMedia, setPreviewMedia] = useState(null)

  const dispatch = useDispatch()

  const handlePreviewMedia = event => {
    setPreviewMedia(null)

    const file = event.target.files?.[0]

    if (!file) return

    const fileType = getFileType(file)

    const isFile = fileType === 'document'
    const isImage = fileType === 'image'

    if (!isFile && !isImage) {
      toastr.error('Erro', `Ainda não suportamos esse formato de arquivo.`)
    }

    const maxSizeInMBFile = 100
    const maxSizeInMBPicture = 5
    const maxSizeInMB = isFile ? maxSizeInMBFile : maxSizeInMBPicture
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024

    if (file.size > maxSizeInBytes) {
      const errorSizeMessages = {
        application: `O tamanho máximo permitido do arquivo é de ${maxSizeInMBFile}mb`,
        image: `O tamanho máximo permitido da imagem é de ${maxSizeInMBPicture}mb`
      }

      const errorMsg = errorSizeMessages[fileType]
      toastr.error('Erro', errorMsg)
      return
    }

    const previewURL = URL.createObjectURL(file)

    setPreviewMedia({ type: fileType, url: previewURL, name: file.name })
  }

  const handleUpload = useCallback(
    async event => {
      setLoadingUpload(true)

      handlePreviewMedia(event)

      const body = new FormData()

      try {
        const [file] = event.target.files
        body.append('file', file)

        const fileType = getFileType(file)

        const axiosOptions = handleUploadProgressAxios({
          onChange: setUploadProgress
        })

        /**
         * Função que será chamada ao final da promise disparada pelo Redux
         */
        const handleUploadSuccess = ({ data }) => {
          setPreviewMedia(oldState => ({ ...oldState, url: data.url }))
          setLoadingUpload(false)
          setUploadProgress(0)

          formRef?.current?.setFieldValue(
            `${pathScopeUnform}.media.type`,
            fileType
          )
          formRef?.current?.setFieldValue(
            `${pathScopeUnform}.media.url`,
            data.url
          )
          formRef?.current?.setFieldValue(
            `${pathScopeUnform}.media.name`,
            file.name
          )
        }

        dispatch(
          campaignActions.upload({
            body,
            onSuccess: handleUploadSuccess,
            successFeedbackMsg: 'Mídia carregada com sucesso!',
            errorFeedbackMsg:
              'Houve um erro ao carregar a mídia. Tente novamente.',
            axiosOptions
          })
        )
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Erro', 'Houve um erro ao enviar a mídia.')
        setUploadProgress(0)
        setLoadingUpload(false)
      }
    },
    [dispatch, formRef]
  )
  /**
   * Handle initial data
   */
  useEffect(() => {
    if (initialData?.callbacks?.[0]?.media?.url) {
      const { type, url, name } = initialData.callbacks[0].media
      setPreviewMedia({ type, url, name })
    }
  }, [initialData])

  return (
    <fieldset>
      <MessageWithMediaAndPreview
        panelTitle="Mensagem de resposta"
        panelDescription="Essa mensagem será enviada como resposta à mensagem template caso o destinatário escolha a opção: Quero aproveitar."
        path={pathScopeUnform}
        pathMessage="message"
        formRef={formRef}
        handleUpload={handleUpload}
        isLoading={loadingUpload}
        uploadProgress={uploadProgress}
        mediaFile={previewMedia}
        onClearMedia={() => setPreviewMedia(null)}
        messagesIsRequired={false}
        mediaIsRequired={false}
        initialText={initialData?.callbacks?.[0]?.message}
        //
        allowHandleButtons
        pathButtons="buttons"
        initialButtons={initialData?.callbacks?.[0]?.buttonsRaw}
      />
    </fieldset>
  )
}

export default Callback

Callback.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  initialData: PropTypes.shape()
}

Callback.defaultProps = {
  initialData: null
}
