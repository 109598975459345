import { useMemo } from 'react'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'

import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import { useAlert } from '~/hooks/useAlert'
import { adTypesOptions } from '~/modules/retailMedia/constants/adTypesOptions'
import { useAdTypes } from '~/modules/retailMedia/hooks/useAdTypes'
import { postAdTypesService } from '~/modules/retailMedia/services/configuration/ad-types'

import FormEditLink from '../components/FormEditLink'

// import { Container } from './styles';

const PanelPublisherAdTypes: React.FC = () => {
  const { data: adTypes = [], error, isPending, queryKey } = useAdTypes()
  const [AlertError] = useAlert(error)

  const initialData = useMemo(
    () => ({
      type: adTypes?.reduce(
        (acc, item) => ({ ...acc, [`type_${item}`]: true }),
        {}
      )
    }),
    [adTypes]
  )

  /**
   * Handle update
   */

  const queryClient = useQueryClient()

  const patchPublisherAdTypesMutation = useMutation({
    mutationFn: postAdTypesService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey
      })

      toastr.success(
        t('common:messages.success.title'),
        t('rm:adtype.create.messages.success.message')
      )
    },
    onError: () => {
      toastr.error(
        t('common:messages.errorOccurred.title'),
        t('common:messages.errorOccurred.message')
      )
    }
  })

  const handleUpdateSubmit = async (data: FormData) => {
    const body: AdvertiserPublisherLinkPatchRequestBody = {
      ad_types: data.type.all
        .filter(item => !!item.checked)
        .map(item => item.value)
    }

    await patchPublisherAdTypesMutation.mutateAsync(body)
  }

  return (
    <Panel
      title={t('rm:adtype.panel.title')}
      description={t('rm:adtype.panel.description')}
    >
      <Loading status={isPending}>{t('common:actions.loadingData')}</Loading>

      <AlertError />

      <FormEditLink
        options={adTypesOptions}
        initialData={initialData}
        onSubmit={handleUpdateSubmit}
        fields={['adTypes']}
      />
    </Panel>
  )
}

export default PanelPublisherAdTypes
