import { useState } from 'react';

import DeleteConfirmation from '~/components/DeleteConfirmation';

/**
 * Gerencia o componente de confirmação de exclusão
 *
 * @exports useDeleteConfirmation;
 * @exports useDeleteConfirmation.DeleteConfirmation - Element - Componente do painel <DeleteConfirmation />
 * @exports useDeleteConfirmation.activeConfirmation - boolean - Informa de o painel está ativo ou não
 * @exports useDeleteConfirmation.setActiveConfirmation - function - Seta se o painel está ativo ou não
 *
 * @example
 *
 *  const [DeleteConfirmation, activeConfirmation, setActiveConfirmation] = useDeleteConfirmation();
 *
 *  <Button onClick={setActiveConfirmation} />
 *
 *   <DeleteConfirmation
 *      active={activeConfirmation}
 *      onDelete={handleDelete}
 *      onCancel={setActiveConfirmation}
 *      yesMsg="Sim, desejo apagar"
 *      cancelMsg="Cancelar"
 *      loading={loadingDelete}
 *    >
 *      Tem certeza que deseja apagar este produto?
 *    </DeleteConfirmation>
 *
 */

const useDeleteConfirmation = (initialValue = false) => {
  const [activeConfirmation, setActiveConfirmation] = useState(initialValue);

  const handleConfirmation = () => setActiveConfirmation(!activeConfirmation);

  return [
    DeleteConfirmation,
    activeConfirmation,
    handleConfirmation,
    setActiveConfirmation,
  ];
};

export { useDeleteConfirmation };
