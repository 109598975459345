import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiX } from 'react-icons/fi'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Button } from '~/components/Buttons'
import {
  deleteFormatPlacementService,
  getPlacementQueryKey,
  listPlacementQueryKey
} from '~/modules/retailMedia/services/placements'

const RemoveFormatButton = ({
  placementId,
  formatId
}: {
  placementId?: string
  formatId?: string
}) => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  /**
   * Handle delete
   */

  const queryClient = useQueryClient()

  const deletePlacementMutation = useMutation({
    mutationFn: deleteFormatPlacementService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [listPlacementQueryKey]
      })
      queryClient.invalidateQueries({
        queryKey: [getPlacementQueryKey, placementId]
      })
      toastr.success(
        t('rm:placements.form.supportedSizes.messages.remove.success.title'),
        t('rm:placements.form.supportedSizes.messages.remove.success.message')
      )
    },
    onError: () => {
      toastr.error(
        t('common:messages.errorOccurred.title'),
        t('rm:placements.form.supportedSizes.messages.remove.error.message')
      )
    }
  })

  const handleDelete = useCallback(async () => {
    try {
      await deletePlacementMutation.mutateAsync(formatId)
    } finally {
      setLoading(false)
    }
  }, [deletePlacementMutation, formatId])

  return (
    <Button
      className="ms-auto"
      loading={loading}
      onClick={handleDelete}
      template="transparentDanger"
      iconLeft={<FiX />}
      size="xsmall"
      text={t('rm:placements.cta.removeFormat')}
      customWidth="auto"
    />
  )
}

export default RemoveFormatButton
