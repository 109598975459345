import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FiCheckCircle, FiXCircle } from 'react-icons/fi'
import { Spinner } from 'reactstrap'

import { useField } from '@unform/core'
import * as Yup from 'yup'

import * as S from './styles'

/**
 *
 * @example
 *  <Input
 *    name="address_street"
 *    placeholder="Insira a rua"
 *    loading={loadingCep}
 *    icon={FiSearch}
 *  />
 *
 * @param {string} name Recebe o name para o input
 * @param {ReactElement | string} icon Ícone que será exbido a esquerda do input.
 * @param {boolean} loading Exibe um loader ao lado do input
 * @param {function} onBlur Extendendo onBlur
 * @param {string} helpText Exibe texto explicativo sobre o campo
 *
 */

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  loading,
  onBlur,
  htmlIcon,
  htmlRightIcon,
  showLength,
  onChange,
  disabled = false,
  showWarningOnRequired = true,
  showBadgeIsvalid = false,
  showBadgeIsInvalid = false,
  helpText,
  ...rest
}) => {
  const inputRef = useRef(null)

  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const [valueInput, setValueInput] = useState('')

  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    let isFilled = !!inputRef.current?.value

    if (rest.type === 'email') {
      const schema = Yup.string().email().required()

      isFilled = schema.isValidSync(inputRef.current?.value)
    }

    setIsFilled(isFilled)

    if (onBlur) {
      onBlur({
        value: inputRef.current.value,
        name: inputRef.current.name,
        clearError,
        setIsFilled
      })
    }

    if (inputRef.current?.value) {
      clearError()
    }
  }, [clearError, onBlur])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  const defaultValueIsNull = defaultValue === null

  const defaultValueIsUndefined = typeof defaultValue === 'undefined'

  useEffect(() => {
    if (!defaultValueIsNull && !defaultValueIsUndefined) {
      setValueInput(defaultValue)
      setIsFilled(true)
    }
  }, [defaultValue, defaultValueIsNull, defaultValueIsUndefined])

  const inputChangedHandler = e => {
    const { value, name } = e.target

    onChange && onChange({ value, name })

    setValueInput(value)
  }

  return (
    <>
      <S.Container
        isFocused={isFocused}
        isFilled={isFilled}
        isErrored={!!error}
        isDisabled={disabled}
        showWarning={!isFilled && showWarningOnRequired && rest?.required}
      >
        {Icon && <Icon />}

        {htmlIcon && <div className="htmlIcon">{htmlIcon}</div>}

        <input
          onFocus={handleInputFocus}
          name={fieldName}
          onBlur={handleInputBlur}
          type="text"
          defaultValue={defaultValue}
          id={name}
          onChange={inputChangedHandler}
          disabled={disabled}
          {...rest}
          ref={inputRef}
        />

        {showBadgeIsvalid && (
          <S.IconWrapper className="success">
            <FiCheckCircle />
          </S.IconWrapper>
        )}

        {showBadgeIsInvalid && (
          <S.IconWrapper className="error">
            <FiXCircle />
          </S.IconWrapper>
        )}

        {loading && <Spinner type="grow" size="sm" color="secondary" />}

        {showLength && rest.maxLength && (
          <span className="length">
            {valueInput ? valueInput.length : 0}/{rest.maxLength}
          </span>
        )}

        {htmlRightIcon && (
          <div className="htmlIcon htmlRightIcon">{htmlRightIcon}</div>
        )}
      </S.Container>

      {error && <S.MessageError>{error}</S.MessageError>}

      {helpText && <div className="help-text">{helpText}</div>}
    </>
  )
}

export default Input

Input.defaultProps = {
  icon: null,
  htmlIcon: null,
  htmlRightIcon: null,
  loading: false,
  onBlur: null,
  showLength: false,
  disabled: false,
  onChange: null,
  helpText: null,
  showWarningOnRequired: true
}
