import React from 'react'
import { Image } from 'react-feather'

import PropTypes from 'prop-types'

const ThumbProduct = ({ asset, url, title }) => {
  const assetUrl = asset?.find(
    item => item.type === 'image' || item.mime === 'image/webp'
  )
  const thumbUrl = url || assetUrl?.url

  return thumbUrl && thumbUrl !== 'null' ? (
    <img src={thumbUrl} alt={title} className="ThumbProduct overflow-hidden" />
  ) : (
    <span className="without-image">
      <Image />
    </span>
  )
}

export default ThumbProduct

ThumbProduct.propTypes = {
  asset: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  url: PropTypes.string
}

ThumbProduct.defaultProps = {
  asset: null,
  title: null,
  url: null
}
