import { queryDatesDatepickerIsolated } from '~/helpers'
import { SWRSortProvider } from '~/hooks/useSWRSort'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'
import { useAppSelector } from '~/store/hooks'

import Table from './Table'

const ProductTable = ({
  campaignId,
  adType = null
}: {
  campaignId: string
  adType?: CampaignAdType
}) => {
  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  return (
    <SWRSortProvider
      extraParams={{
        campaign_id: campaignId,
        ...queryDatesDatepickerIsolated(startDate, endDate)
      }}
      refreshInterval={1000 * 60}
      url={`${reatailmediaApi}/product/results`}
    >
      <Table adType={adType} />
    </SWRSortProvider>
  )
}

export default ProductTable
