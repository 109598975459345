import * as _ from 'lodash';

import * as types from '../constants';

const index = {
  pending: `${types.SEARCH}_PENDING`,
  fulfilled: `${types.SEARCH}_FULFILLED`,
  rejected: `${types.SEARCH}_REJECTED`,
};

export const cases = {
  [index.pending]: ({ draft }) => {
    draft.loadingSearchOffers = true;
    draft.searchOffersResults = null;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.searchOffersResults = action.payload;
    draft.loadingSearchOffers = false;
    draft.errorSearchOffers = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.loadingSearchOffers = false;
    draft.errorSearchOffers = action.payload;
  },
};
