import i18n from '~/i18n'

import adCostPreset from './presets/metrics/adCost'
import balancePreset from './presets/metrics/balance'
import clicksPreset from './presets/metrics/clicks'
import conversionsPreset from './presets/metrics/conversions'
import impressionsPreset from './presets/metrics/impressions'
import incomePreset from './presets/metrics/income'
import roasPreset from './presets/metrics/roas'
import totalCampaignsPreset from './presets/metrics/totalCampaigns'
import totalSpentPreset from './presets/metrics/totalSpent'
import totalSpentNoScalePreset from './presets/metrics/totalSpentNoScale'
import viewsPreset from './presets/metrics/views'

type MetricsOptionsSelect = {
  label: string
  value: AvailablePresets
  color: string
}[]

const publisher: MetricsOptionsSelect = [
  {
    label: i18n.t('rm:metrics.clicks'),
    value: 'clicks',
    color: clicksPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.conversions'),
    value: 'conversions',
    color: conversionsPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.impressions'),
    value: 'impressions',
    color: impressionsPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.views'),
    value: 'views',
    color: viewsPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.totalSpent'),
    value: 'totalSpent',
    color: totalSpentPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.totalSpentNoScale'),
    value: 'totalSpentNoScale',
    color: totalSpentNoScalePreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.roas'),
    value: 'roas',
    color: roasPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.income'),
    value: 'income',
    color: incomePreset.settings.color
  }
]

const publisherRealtime: MetricsOptionsSelect = [
  {
    label: i18n.t('rm:metrics.clicks'),
    value: 'clicks',
    color: clicksPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.conversions'),
    value: 'conversions',
    color: conversionsPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.impressions'),
    value: 'impressions',
    color: impressionsPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.views'),
    value: 'views',
    color: viewsPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.totalSpent'),
    value: 'totalSpent',
    color: totalSpentPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.totalSpentNoScale'),
    value: 'totalSpentNoScale',
    color: totalSpentNoScalePreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.income'),
    value: 'income',
    color: incomePreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.roas'),
    value: 'roas',
    color: roasPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.totalCampaigns'),
    value: 'totalCampaigns',
    color: totalCampaignsPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.adCost'),
    value: 'adCost',
    color: adCostPreset.settings.color
  }
]

const advertiser: MetricsOptionsSelect = [
  {
    label: i18n.t('rm:metrics.clicks'),
    value: 'clicks',
    color: clicksPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.conversions'),
    value: 'conversions',
    color: conversionsPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.impressions'),
    value: 'impressions',
    color: impressionsPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.views'),
    value: 'views',
    color: viewsPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.cost'),
    value: 'totalSpent',
    color: totalSpentPreset.settings.color
  },
  {
    label: i18n.t('rm:metrics.income'),
    value: 'income',
    color: incomePreset.settings.color
  }
]

export const metricsOptionsSelect = { publisher, publisherRealtime, advertiser }
