import styled, { css } from 'styled-components';

const containerModifiers = {
  hideButton: theme => css`
    max-height: 0;
    opacity: 0;
    margin: 0;
  `,
  formIsOpen: theme => css`
    max-height: 100vh;
    opacity: 1;
  `,
  onTop: theme => css`
    margin-bottom: ${theme.spacings.xsmall};
  `,
  onBottom: theme => css`
    margin-top: ${theme.spacings.xsmall};
  `,
};

export const Container = styled.div`
  ${({ theme, formIsOpen, hideButton, position }) => css`
    position: relative;

    max-height: 3rem;

    transition: all 0.1s ease-in-out;

    ${position === 'top' && containerModifiers.onTop(theme)}
    ${position === 'bottom' && containerModifiers.onBottom(theme)}
    ${hideButton && !formIsOpen && containerModifiers.hideButton(theme)}
    ${formIsOpen && containerModifiers.formIsOpen(theme)}

    form {
      padding: ${theme.spacings.xxxsmall} ${theme.spacings.xsmall};
      border-radius: 0.5rem 0 0.5rem 0.5rem;
      border: 1px solid ${theme.colors.hover};

      background-color: ${theme.colors.white};

      header {
        border-bottom: 1px solid ${theme.colors.tertiary};
        margin-bottom: 0.25rem;
        padding-bottom: 0.25rem;

        strong {
          color: ${theme.colors.primary};
        }

        span {
        }
      }

      fieldset {
        display: flex;
        flex: 1 1 100%;
        align-items: flex-end;

        .formGroup {
          flex: 1 1 100%;
        }

        > * + * {
          margin-left: 0.5rem;
        }

        + * {
          margin-top: ${theme.spacings.xsmall};
        }

        .closeButton {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2rem;
          width: 2rem;

          position: absolute;
          top: 0;
          right: 0;

          transition: 0.3s ease-in-out all;

          svg {
            color: ${theme.colors.danger};
          }
        }
      }
    }
  `}
`;
