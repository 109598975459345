import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/payment`

/**
 * RECHARGE
 */
export const recharge = body =>
  axios.post(`${baseURL}/v2/transaction`, body)

export const getTransaction = id =>
  axios.get<any>(`${baseURL}/transaction/detail/${id}`)

export default {
  recharge,
  getTransaction
}
