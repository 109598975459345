import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import * as S from './styled'

interface PropsTooltip {
  text: string | ReactNode
  icon: ReactNode
  positionProp?:
    | 'center-top'
    | 'right-top'
    | 'left-top'
    | 'center-bottom'
    | 'right-bottom'
    | 'left-bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
}

const positionMap = {
  'center-top': ['left', 'right', 'top'],
  'right-top': ['right', 'top'],
  'left-top': ['left', 'top'],
  'center-bottom': ['left', 'right', 'bottom'],
  'right-bottom': ['right', 'bottom'],
  'left-bottom': ['left', 'bottom']
}

const Tooltip = ({ text, icon, positionProp = null }: PropsTooltip) => {
  const [position, setPosition] = useState(null)

  const targetRef = useRef(null)
  const tooltipRef = useRef(null)

  const handlePosition = useCallback((positions: string[]) => {
    for (const [position, values] of Object.entries(positionMap)) {
      if (
        positions.length === values.length &&
        values.every(val => positions.includes(val))
      ) {
        return position
      }
    }

    return positions[0]
  }, [])

  const updatePosition = useCallback(() => {
    const targetElement = targetRef.current
    const tooltipElement = tooltipRef.current

    if (targetElement && tooltipElement) {
      const targetRect = targetElement.getBoundingClientRect()
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight
      const positions = ['left', 'right', 'top', 'bottom']

      const availablePositions = positions.filter(pos => {
        let isAvailable = true

        switch (pos) {
          case 'left':
            isAvailable = targetRect.left >= tooltipElement.offsetWidth
            break
          case 'right':
            isAvailable =
              targetRect.right + tooltipElement.offsetWidth <= windowWidth
            break
          case 'top':
            isAvailable = targetRect.top >= tooltipElement.offsetHeight
            break
          case 'bottom':
            isAvailable =
              targetRect.bottom + tooltipElement.offsetHeight <= windowHeight
            break
          default:
            break
        }

        return isAvailable
      })

      const allPositions = availablePositions.length === 4

      const position = allPositions
        ? 'center-top'
        : handlePosition(availablePositions)

      setPosition(position || null)
    }
  }, [handlePosition])

  useEffect(() => {
    if (positionProp) {
      updatePosition()

      window.addEventListener('resize', updatePosition)

      return () => {
        window.removeEventListener('resize', updatePosition)
      }
    }
  }, [positionProp, updatePosition])

  return (
    <S.Container className="Tooltip__Container">
      <S.TriggerContent
        onMouseEnter={positionProp ? null : updatePosition}
        ref={targetRef}
      >
        {icon}
      </S.TriggerContent>

      <S.TooltipContent
        position={positionProp ? positionProp : position}
        ref={tooltipRef}
        data-position={position}
        className="Tooltip__Content"
      >
        {text}
      </S.TooltipContent>
    </S.Container>
  )
}

export default Tooltip
