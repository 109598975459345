import { useEffect, useMemo } from 'react'
import { IconType } from 'react-icons'
import { FiBarChart, FiEye, FiMessageSquare, FiUser } from 'react-icons/fi'
import { TbCrown } from 'react-icons/tb'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { Badge } from '~/components/UI/Badge'
import { useAlert } from '~/hooks/useAlert'
import { CHAT_REDUX_QUERY_ATTENDANT } from '~/modules/chat/store/constants'
import { useAppSelector } from '~/store/hooks'
import { listAttendant } from '~/modules/chat/store/actions'

const Attendants = () => {
  const dispatch = useDispatch()

  const {
    chats: { listAttendantData, listAttendantLoading, listAttendantError },
    filters: {
      query,
      locations: filteredLocations,
      userRoles: filteredUserRoles
    }
  } = useAppSelector(state => state)

  const [AlertOnError] = useAlert(listAttendantError)

  const filteredLocationsIds = useMemo(
    () => filteredLocations?.value || null,
    [filteredLocations]
  )

  const filteredUserRolesIds = useMemo(
    () => filteredUserRoles?.value || null,
    [filteredUserRoles]
  )

  const params = useMemo(
    () => ({
      name: query?.queryAttendant,
      location_id: filteredLocationsIds,
      role: filteredUserRolesIds
    }),
    [filteredLocationsIds, filteredUserRolesIds, query?.queryAttendant]
  )

  useEffect(() => {
    dispatch(
      listAttendant({
        params
      })
    )
  }, [dispatch, params])

  type Icons = {
    [key in UserRoles]: IconType
  }

  const icons: Icons = {
    admin: TbCrown,
    user: FiUser,
    carrier: FiUser,
    guest: FiEye,
    agent: FiMessageSquare,
    personal_service: FiBarChart
  }

  const icon = (role: UserRoles) => {
    const Icon = role ? (icons[role] as IconType) : <></>

    return <Icon />
  }

  const schema = {
    user: {
      title: 'Usuário',
      render: (rowData: AttendantServiceResponse) => (
        <div style={{ lineHeight: 1 }}>
          {rowData.total > 0 ? (
            <strong>{rowData.name}</strong>
          ) : (
            <span>{rowData.name}</span>
          )}
          <br />
          <small>
            {icon(rowData.role || 'user')}{' '}
            <FormattedMessage id={rowData?.role || 'user'} /> - {rowData.email}
          </small>
        </div>
      )
    },
    count: {
      title: 'Atendimentos em andamento',
      render: (rowData: AttendantServiceResponse) => (
        <Badge template={rowData.total > 0 ? 'primary' : null}>
          {rowData.total}
        </Badge>
      )
    }
  }

  return (
    <>
      <HeaderPages
        title="Listagem de atendentes"
        subtitle="Essa listagem traz um contador de quantos atendimentos estão sob responsabilidade de cada atendente."
      />

      <Filters
        query
        liveQuery
        queryKeyState={CHAT_REDUX_QUERY_ATTENDANT}
        queryPlaceholder="Buscar atendente"
        locations
        userRoles
      />

      <AlertOnError />

      <Loading status={listAttendantLoading} />

      <Panel noPaddingBody>
        <Table schema={schema} data={listAttendantData} />
      </Panel>
    </>
  )
}

export default Attendants
