import React from 'react';

import Days from '../Days';
import DeleteModal from '../DeleteModal';
import { DateForm, SlotForm } from '../Forms';
import LoadingSlots from '../LoadingSlots';

import * as S from './styles';

const Board = () => (
  <S.Container>
    <DeleteModal />

    <LoadingSlots />

    <DateForm />

    <SlotForm />

    <Days />
  </S.Container>
);

export default Board;
