export const schemaPlacements: TableSchema<CampaignPlacement> = {
  title: {
    title: 'Placements',
    render: ({ placement }) => (
      <div>
        <div className="item__id">
          <code>{placement.name}</code>
        </div>

        <div className="item__name">
          <strong>{placement.full_name}</strong>

          {placement?.description && <p>{placement.description}</p>}
        </div>
      </div>
    ),
    className: 'order__details col__infos col__title',
    showTotal: true
  }
}
