import React from 'react';

import HeaderPages from '~/components/HeaderPages';
import PageTitle from '~/components/PageTitle';

import CreateTemplate from '../_elements/CreateTemplate';

const TemplatesEdit = () => {
  return (
    <>
      <PageTitle title="Templates > Criar" />

      <HeaderPages
        title="Criar template"
        backTo="/messaging-campaigns/templates/list"
        labelBreadcrumb="Voltar para templates"
      />

      <CreateTemplate />
    </>
  );
};

export default TemplatesEdit;
