import React from 'react';

import HeaderPages from '~/components/HeaderPages';
import PageTitle from '~/components/PageTitle';

import FormCampaign from '../_elements/FormCampaign';

const CampaignsNew = () => (
  <>
    <PageTitle title="Campanhas > criar" />

    <HeaderPages
      title="Criar campanha"
      backTo="/messaging-campaigns/list"
      labelBreadcrumb="Voltar para campanhas"
    />

    <FormCampaign />
  </>
);

export default CampaignsNew;
