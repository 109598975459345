import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

type WrapperFiltersProps = {
  hasSelectedChat: boolean
}

export const WrapperFilters = styled.div<WrapperFiltersProps>`
  ${({ hasSelectedChat }) => css`
    @media ${device.tablet_max} {
      display: ${hasSelectedChat ? 'none' : 'block'};
      padding: 0.25rem 0.25rem 0 0.25rem;
    }

    footer {
      margin-bottom: 0.5rem;
    }
  `}
`
