import { t } from 'i18next'

import { getDecodedToken } from '~/helpers'

import { CAPMPAIGNS_FILTERS_KEYS } from '../constants'

export const queryFilter = {
  query: true,
  querySize: '1x',
  queryPlaceholder: t('rm:filterByCampaignName'),
  queryKeyState: CAPMPAIGNS_FILTERS_KEYS.campaignName,
  liveQuery: true,
  queryWithDebounce: true
}

export const queryCampaignNameFilter = {
  query: true,
  querySize: '1x',
  queryPlaceholder: t('rm:filterByCampaignName'),
  queryKeyState: CAPMPAIGNS_FILTERS_KEYS.campaignName,
  liveQuery: true,
  queryWithDebounce: true
}

export const queryAdsFilter = {
  query: true,
  querySize: '1x',
  queryPlaceholder: t('rm:filterByCampaignName'),
  queryKeyState: CAPMPAIGNS_FILTERS_KEYS.campaignName,
  liveQuery: true,
  queryWithDebounce: true
}

export const queryAdsSKUFilter = {
  query: true,
  querySize: '1x',
  queryPlaceholder: t('rm:filterBySKU'),
  queryKeyState: CAPMPAIGNS_FILTERS_KEYS.productSKU,
  liveQuery: true,
  queryWithDebounce: true
}

const { current_account_type: accountType } = getDecodedToken()

export const queryFilterWallet = {
  query: true,
  // querySize: '1x',
  queryPlaceholder: `${t('rm:filterBy')} ${
    accountType === 'grocery' ? t('rm:advertiser') : 'publishers'
  }`,
  queryKeyState: CAPMPAIGNS_FILTERS_KEYS.queryFilterWallet,
  liveQuery: true
}
