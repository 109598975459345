import styled, { css } from 'styled-components'

export const Container = styled.dl`
  dt {
    text-transform: capitalize;
  }
  dd + dt {
    margin-top: 1rem;
  }
`

/**
 * Item
 */

const itemModifiers = {
  success: theme => css`
    --bulletColor: ${theme.colors.success};
  `,
  warning: theme => css`
    --bulletColor: ${theme.colors.warning};
  `,
  danger: theme => css`
    --bulletColor: ${theme.colors.danger};
  `
}

export const Item = styled.dd`
  ${({ theme, template }) => css`
    --bulletColor: ${theme.colors.primary};

    ${template === 'success' && itemModifiers.success(theme)}
    ${template === 'danger' && itemModifiers.danger(theme)}

    position: relative;
    display: flex;
    align-items: center;
    min-height: 4rem;
    margin: 0 0 0 1.5rem;
    line-height: 1.25rem;
    border-bottom: 1px solid transparent;
    border-top: 1px solid ${theme.colors.tertiary};
    transition: 0.3s ease all;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -1.5rem;
      transform: translateY(-50%);

      height: 1rem;
      width: 1rem;

      border: 1px solid var(--bulletColor);
      border-radius: 50%;

      background: transparent;

      transition: 0.3s ease all;
    }

    ${Container} > dt + & {
      border-top: 1px solid transparent;
    }

    ${Container} > dt:first-child + & {
      border-top: 1px solid transparent;

      &:before {
        background: var(--bulletColor);
        border: none;
      }
    }

    &:hover {
      background: ${theme.colors.light};

      &:before {
        filter: brightness(1.25);
        background: var(--bulletColor);
      }
    }

    & + dd {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -1rem;
        transform: translateY(-50%);

        height: calc(100% - 1.5rem);
        width: 1px;
        background: var(--bulletColor);
      }
    }
  `}
`

export const Time = styled.div`
  ${({ theme }) => css`
    padding: 0 0 0 0.5rem;
    font-size: ${theme.font.sizes.small};
  `}
`

export const Icon = styled.div`
  ${({ theme }) => css`
    padding: 0 1.25rem;

    font-size: ${theme.font.sizes.xlarge};
    color: var(--bulletColor);
  `}
`

export const Title = styled.div`
  ${({ theme }) =>
    css`
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.bold};
    `}
`

export const Operator = styled.small`
  ${({ theme }) =>
    css`
      display: flex;
      align-items: center;
      line-height: 1rem;
      margin-top: 0.125rem;
      color: ${theme.colors.gray};
      transition: 0.3s ease all;

      &:hover {
        color: ${theme.colors.hover};
      }

      .name {
        line-height: 1rem;
        margin-right: 0.5rem;
      }

      .icon {
        display: inline-flex;
        margin-right: 0.25rem;
      }
    `}
`

export const Aside = styled.aside`
  ${({ theme }) =>
    css`
      margin-left: auto;
    `}
`
