import { produce } from 'immer'

import * as types from './constants'

const INITIAL_STATE = {
  listLocations: {},
  listLocationsPerPage: 50,
  listLocationsLoading: false,
  listLocationsPage: 1,

  listLocationsMinified: [],
  listLocationsMinifiedLoading: false,

  listLocationsDeliveryTypes: null,
  listLocationsDeliveryTypesLoading: false,
  listLocationsDeliveryError: null,

  listLocationUsers: null,
  listLocationUsersLoading: false,
  listLocationUsersError: null,

  location: [],

  loading: false,
  loadingUpdate: false,
  loadingDelete: false,

  mapCoordinates: {
    zoom: 13
  },

  error: null,

  locationRegions: null,
  locationRegionsTotal: 0,
  listLocationRegionsLoading: false,
  listLocationRegionsError: false,
  createLocationRegionsLoading: false,
  createLocationRegionsError: false,
  updateLocationRegionsLoading: false,
  updateLocationRegionsError: false,
  deleteLocationRegionsLoading: false,
  deleteLocationRegionsError: false,
  showRegionsOnEditData: null,
  showRegionsOnEditLoading: false,
  showRegionsOnEditError: false
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.LOCATION_LIST}_PENDING`: {
        draft.listLocationsLoading = true
        break
      }
      case `${types.LOCATION_LIST}_FULFILLED`: {
        draft.listLocations = action.payload
        draft.listLocationsLoading = false
        draft.error = null
        draft.listLocationsPage = action.page
        break
      }
      case `${types.LOCATION_LIST}_REJECTED`: {
        draft.listLocationsLoading = false
        draft.error = action.payload
        break
      }

      case types.LOCATIONS_PER_PAGE_CHANGED: {
        draft.listLocationsPerPage = action.payload
        break
      }

      // List minified

      case `${types.LOCATION_LIST_MINIFIED}_PENDING`: {
        draft.listLocationsMinifiedLoading = true
        break
      }
      case `${types.LOCATION_LIST_MINIFIED}_FULFILLED`: {
        draft.listLocationsMinified = action.payload
        draft.listLocationsMinifiedLoading = false
        draft.error = null
        break
      }
      case `${types.LOCATION_LIST_MINIFIED}_REJECTED`: {
        draft.listLocationsMinifiedLoading = false
        draft.error = action.payload
        break
      }
      // Lista Location Delivery Types

      case `${types.LOCATION_DELIVERY_TYPES_LIST}_PENDING`: {
        draft.listLocationsDeliveryTypesLoading = true
        break
      }

      case `${types.LOCATION_DELIVERY_TYPES_LIST}_FULFILLED`: {
        draft.listLocationsDeliveryTypes = action.payload.data
        draft.listLocationsDeliveryTypesLoading = false
        draft.listLocationsDeliveryError = null
        break
      }
      case `${types.LOCATION_DELIVERY_TYPES_LIST}_REJECTED`: {
        draft.listLocationsDeliveryTypesLoading = false
        draft.listLocationsDeliveryError = action.payload
        break
      }

      // Create location

      case `${types.LOCATION_SUBMIT_FORM}_PENDING`: {
        draft.loading = true
        break
      }
      case `${types.LOCATION_SUBMIT_FORM}_FULFILLED`: {
        draft.loading = false
        draft.error = null
        break
      }
      case `${types.LOCATION_SUBMIT_FORM}_REJECTED`: {
        draft.error = action.payload
        draft.loading = false
        break
      }

      // Update location

      case `${types.LOCATION_UPDATE_INFO}_PENDING`: {
        draft.loadingUpdate = true
        break
      }
      case `${types.LOCATION_UPDATE_INFO}_FULFILLED`: {
        draft.loadingUpdate = false
        draft.error = null
        break
      }
      case `${types.LOCATION_UPDATE_INFO}_REJECTED`: {
        draft.error = action.payload
        draft.loadingUpdate = false
        break
      }

      // Show location

      case `${types.LOCATION_GET_INFO}_PENDING`: {
        draft.loading = true
        draft.location = []
        break
      }
      case `${types.LOCATION_GET_INFO}_FULFILLED`: {
        draft.location = action.payload
        draft.loading = false
        draft.error = null
        break
      }
      case `${types.LOCATION_GET_INFO}_REJECTED`: {
        draft.error = action.payload
        draft.loading = false
        break
      }

      // Delete location

      case `${types.LOCATION_DELETE}_PENDING`: {
        draft.loadingDelete = true
        break
      }
      case `${types.LOCATION_DELETE}_FULFILLED`: {
        draft.loadingDelete = false
        draft.error = null
        break
      }
      case `${types.LOCATION_DELETE}_REJECTED`: {
        draft.error = action.payload
        draft.loadingDelete = false
        break
      }

      // mapCoordinates
      case types.LOCATION_MAP_COORDINATES: {
        draft.mapCoordinates = { ...draft.mapCoordinates, ...action.payload }
        break
      }
      // Reset mapCoordinates
      case types.LOCATION_MAP_COORDINATES_RESET: {
        draft.mapCoordinates = { zoom: 13 }
        break
      }

      /**
       * List Location Users
       */

      case `${types.LOCATION_USERS_LIST}_PENDING`: {
        draft.listLocationUsersLoading = true
        break
      }

      case `${types.LOCATION_USERS_LIST}_FULFILLED`: {
        draft.listLocationUsers = action.payload.data
        draft.listLocationUsersLoading = false
        draft.listLocationUsersError = null
        break
      }

      case `${types.LOCATION_USERS_LIST}_REJECTED`: {
        draft.listLocationUsersLoading = false
        draft.listLocationUsersError = action.payload
        break
      }

      /**
       * Create location Users
       */

      case types.LOCATION_USERS_CREATE_OPTIMISTIC: {
        const oldList = draft.listLocationUsers
        const newItem = action.payload.body
        const newList = [
          ...oldList,
          { ...newItem, saving: true, id: newItem.user_id }
        ]

        draft.listLocationUsers = newList
        break
      }

      case `${types.LOCATION_USERS_CREATE}_PENDING`: {
        break
      }

      case `${types.LOCATION_USERS_CREATE}_FULFILLED`: {
        const oldList = draft.listLocationUsers
        const newList = oldList.map(item =>
          item.saving ? { ...item, saving: false } : { ...item }
        )

        draft.listLocationUsers = newList

        break
      }

      case `${types.LOCATION_USERS_CREATE}_REJECTED`: {
        const oldList = draft.listLocationUsers
        const newList = oldList.filter(item => !item.saving)

        draft.listLocationUsers = newList
        break
      }

      /**
       * Create location Users
       */

      case types.LOCATION_USERS_DELETE_OPTIMISTIC: {
        const oldList = draft.listLocationUsers
        const { userId } = action.payload
        const newList = oldList.map(item =>
          item.user_id === userId
            ? { ...item, deleted: true, saving: true }
            : { ...item }
        )

        draft.listLocationUsers = newList
        break
      }

      case `${types.LOCATION_USERS_DELETE}_PENDING`: {
        break
      }

      case `${types.LOCATION_USERS_DELETE}_FULFILLED`: {
        const oldList = draft.listLocationUsers
        const newList = oldList.filter(item => !item.deleted)

        draft.listLocationUsers = newList
        break
      }

      case `${types.LOCATION_USERS_DELETE}_REJECTED`: {
        const oldList = draft.listLocationUsers
        const newList = oldList.map(item =>
          item.deleted
            ? { ...item, deleted: false, saving: false }
            : { ...item }
        )

        draft.listLocationUsers = newList
        break
      }

      /**
       * List Location Regions
       */

      case `${types.LOCATION_REGIONS_LIST_ALL}_PENDING`: {
        draft.listLocationRegionsLoading = true
        break
      }

      case `${types.LOCATION_REGIONS_LIST_ALL}_FULFILLED`: {
        draft.locationRegions = action.payload
        draft.locationRegionsTotal = action.payload.length.total
        draft.listLocationRegionsLoading = false
        draft.listLocationRegionsError = null
        break
      }

      case `${types.LOCATION_REGIONS_LIST_ALL}_REJECTED`: {
        draft.listLocationRegionsLoading = false
        draft.listLocationRegionsError = action.payload
        break
      }

      /**
       * List Location Regions
       */

      case `${types.LOCATION_REGIONS_LIST}_PENDING`: {
        draft.listLocationRegionsLoading = true
        break
      }

      case `${types.LOCATION_REGIONS_LIST}_FULFILLED`: {
        draft.locationRegions = action.payload.data
        draft.locationRegionsTotal = action.payload.total
        draft.listLocationRegionsLoading = false
        draft.listLocationRegionsError = null
        break
      }

      case `${types.LOCATION_REGIONS_LIST}_REJECTED`: {
        draft.listLocationRegionsLoading = false
        draft.listLocationRegionsError = action.payload
        break
      }

      case `${types.LOCATION_SHOW_TO_EDIT}_PENDING`: {
        draft.showRegionsOnEditLoading = true
        break
      }

      case `${types.LOCATION_SHOW_TO_EDIT}_FULFILLED`: {
        draft.showRegionsOnEditLoading = false
        draft.showRegionsOnEditData = action.payload
        break
      }

      case `${types.LOCATION_SHOW_TO_EDIT}_REJECTED`: {
        draft.showRegionsOnEditLoading = false
        draft.showRegionsOnEditError = action.payload
        break
      }

      /**
       * Create Location Regions
       */

      case `${types.LOCATION_REGIONS_CREATE}_PENDING`: {
        draft.createLocationRegionsLoading = true
        break
      }

      case `${types.LOCATION_REGIONS_CREATE}_FULFILLED`: {
        const currentRegions = draft.locationRegions
        const newRegions = [...currentRegions, action.payload]
        draft.locationRegions = newRegions
        draft.locationRegionsTotal = newRegions.length
        draft.createLocationRegionsLoading = false
        draft.createLocationRegionsError = null
        break
      }

      case `${types.LOCATION_REGIONS_CREATE}_REJECTED`: {
        draft.createLocationRegionsLoading = false
        draft.createLocationRegionsError = action.payload
        break
      }

      /**
       * Update Location Regions
       */

      case `${types.LOCATION_REGIONS_UPDATE}_PENDING`: {
        draft.updateLocationRegionsLoading = true
        break
      }

      case `${types.LOCATION_REGIONS_UPDATE}_FULFILLED`: {
        const currentRegions = draft.locationRegions
        const updatedIndex = currentRegions.findIndex(
          item => item.id === action.payload.id
        )

        if (updatedIndex > -1 && currentRegions?.[updatedIndex]) {
          currentRegions[updatedIndex] = action.payload
        }

        draft.locationRegions = currentRegions
        draft.updateLocationRegionsLoading = false
        draft.updateLocationRegionsError = null
        break
      }

      case `${types.LOCATION_REGIONS_UPDATE}_REJECTED`: {
        draft.updateLocationRegionsLoading = false
        draft.updateLocationRegionsError = action.payload
        break
      }

      /**
       * Update Location Regions
       */

      case `${types.LOCATION_REGIONS_DELETE}_PENDING`: {
        draft.deleteLocationRegionsLoading = true
        break
      }

      case `${types.LOCATION_REGIONS_DELETE}_FULFILLED`: {
        const deletedId = action.values.id

        const currentRegions = draft.locationRegions
        const newRegions = currentRegions.filter(item => item.id !== deletedId)

        draft.locationRegions = newRegions
        draft.locationRegionsTotal = newRegions.length
        draft.deleteLocationRegionsLoading = false
        draft.deleteLocationRegionsError = null
        break
      }

      case `${types.LOCATION_REGIONS_DELETE}_REJECTED`: {
        draft.deleteLocationRegionsLoading = false
        draft.deleteLocationRegionsError = action.payload
        break
      }

      default:
        return state
    }
  })
