import React from 'react';

import Panel from '~/components/Panel';
import { FormGroup, InputMoney, Label } from '~/components/unform';

const ShoppingCartFields = () => {
  return (
    <fieldset>
      <Panel title="Valores carrinhos" className="form-grid" isCollapsible>
        <FormGroup>
          <Label htmlFor="min_order_value">Valor mínimo para um pedido</Label>
          <InputMoney
            name="min_order_value"
            placeholder="Insira um valor em reais"
            type="money"
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="min_free_shipping">
            Valor mínimo para frete grátis
          </Label>
          <InputMoney
            name="min_free_shipping"
            placeholder="Insira um valor em reais"
            type="money"
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="shipping_cost">Valor padrão do frete</Label>
          <InputMoney
            name="shipping_cost"
            placeholder="Insira um valor em reais"
            type="money"
          />
        </FormGroup>
      </Panel>
    </fieldset>
  );
};

export default ShoppingCartFields;
