import { IconType } from 'react-icons'
import { FiShoppingCart as icon } from 'react-icons/fi'

import * as ShoppingCarts from '~/pages/shoppingCarts'

export const shoppingCarts: RootMenuItem<IconType> = {
  id: 'fd6619b8-b0e0-4b0f-89b9-f7713f0f1649',
  path: '/shopping-carts',
  name: 'Carrinhos',
  icon,
  children: [
    {
      id: '89da0f68-5b7d-4fd8-9739-0763ee27ce56',
      path: '/shopping-carts',
      name: 'Listagem de carrinhos',
      pageTitle: 'Listagem de carrinhos',
      component: ShoppingCarts.ShoppingCartsList
    },
    {
      id: null, // rota-sem-id
      path: '/shopping-carts/generate/:customerId',
      name: 'Gerar carrinho',
      pageTitle: 'Gerar carrinho',
      component: ShoppingCarts.ShoppingCartGenerate,
      hideMenu: true
    },
    {
      id: null, // rota-sem-id
      path: '/shopping-cart/:id',
      name: 'Aprovação de carrinhos',
      pageTitle: 'Aprovação de carrinhos',
      component: ShoppingCarts.ShoppingCartView,
      hideMenu: true,
      // maxHeightViewport: true,
      contentFullWidth: true
    },
    {
      id: null, // rota-sem-id
      path: '/shopping-carts/review-bar/:cart_id',
      name: 'Revisão de carrinho',
      pageTitle: 'Revisão de carrinho',
      component: ShoppingCarts.ReviewBar,
      hideMenu: true,
      hideFullMenu: true,
      contentFullWidth: true
    },
    {
      id: 'abb11496-d6cf-45d6-b711-f76a4211469b',
      path: '/shopping-carts/metrics/cart-abandonment',
      name: 'Abandono de carrinhos',
      pageTitle: 'Métricas - Abandono de carrinhos',
      component: ShoppingCarts.CartAbandonment,
      contentFullWidth: true
    }
  ]
}
