import React, { useCallback } from 'react'
import { FiStopCircle } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { Spinner } from '~/components/Spinner'
import useConfirmationModal from '~/hooks/useConfirmationModal'

import useFlyers from '../../hooks/useFlyers'

const FinishButton = ({ data, keepsMenuOpen, menuItem = false }) => {
  const [ConfirmationhModal, showConfirmationModal, toggleConfirmationModal] =
    useConfirmationModal()

  const { handleFinishFlyer } = useFlyers()

  const { loading } = useSelector(
    ({ promotionFlyer }) => promotionFlyer.flyers.update
  )

  const handleToogle = useCallback(() => {
    keepsMenuOpen && keepsMenuOpen(oldState => !oldState)

    toggleConfirmationModal()
  }, [keepsMenuOpen, toggleConfirmationModal])

  const handleSubmit = useCallback(() => {
    const body = {
      data,
      onSuccess: handleToogle
    }

    handleFinishFlyer(body)
  }, [handleFinishFlyer, data, handleToogle])

  return (
    <>
      {menuItem ? (
        <button
          type="button"
          onClick={handleToogle}
          className="danger"
          disabled={loading}
        >
          {loading ? <Spinner /> : <FiStopCircle />} <span>Finalizar</span>
        </button>
      ) : (
        <Button
          text="Finalizar"
          type="button"
          template="transparentDanger"
          size="small"
          iconLeft={loading ? <Spinner /> : <FiStopCircle />}
          customWidth="auto"
          onClick={handleToogle}
        />
      )}

      {/* Modal finalizar */}
      <ConfirmationhModal
        title="Deseja finalizar o encarte?"
        isOpen={showConfirmationModal}
        onClose={handleToogle}
        onSubmit={handleSubmit}
        confirmationButton="Entendo e desejo finalizar este encarte"
      >
        <p>
          Esta ação não poderá ser desfeita e as ações em decorrência desse ato
          não poderão ser desfeitas.
        </p>
        <p>
          Se deseja apenas pausar o encarte e retornar em uma data breve,
          utilize a opção pausar encarte.
        </p>
        {loading && <Loading status={loading}>Finalizando encarte...</Loading>}
      </ConfirmationhModal>
    </>
  )
}

export default FinishButton

FinishButton.propTypes = {
  data: PropTypes.shape({}).isRequired,
  keepsMenuOpen: PropTypes.func,
  menuItem: PropTypes.bool
}

FinishButton.defaultProps = {
  menuItem: false,
  keepsMenuOpen: null
}
