import React from 'react'
import { FaSortDown, FaSortUp } from 'react-icons/fa'

import PropTypes from 'prop-types'

import * as S from './styles'

const SortableButton = ({ isActive, direction, ...rest }) => {
  return (
    <S.Container
      className="sortableButton"
      isActive={isActive}
      direction={direction}
      {...rest}
    >
      <FaSortUp />
      <FaSortDown />
    </S.Container>
  )
}

export default SortableButton

SortableButton.defaultProps = {
  direction: null
}

SortableButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  direction: PropTypes.string
}
