import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/advertiser`

/**
 * VIEW
 * Retorna dados do publisher com opção de retornar dados da conta
 */
export const getAdvertiserService: GetAccountInfoService = ({
  url: { id },
  params
}) => axios.get(`${baseURL}/${id}`, { params })
