import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'

import { Form } from '@unform/web'

import Alert from '~/components/Alert'
import ToggleInput from '~/components/Toggle'
import {
  Checkbox,
  FormGroup,
  Input,
  InputNumber,
  Label,
  Radio
} from '~/components/unform'
import ReactSelect from '~/components/unform/ReactSelect'
import {
  formatDateToString,
  formatSearchString,
  getRetailMediaAccountType
} from '~/helpers'
import useFetchSWR from '~/hooks/useFetchSWR'
import useQuery from '~/hooks/useQuery'
import Card from '~/modules/retailMedia/components/Card'
import Chart from '~/modules/retailMedia/components/Chart'
import WrapperChartAndCards from '~/modules/retailMedia/components/WrapperChartAndCards'
import {
  reportCampaigns,
  reportCampaignsHistory,
  reportCampaignsRealtime
} from '~/modules/retailMedia/store/report/actions'
import { useAppSelector } from '~/store/hooks'

import {
  formatIndustryReportHistoryCampaign,
  formatIndustryReportHistoryCampaignRealtime
} from '../../dtos/industry/campaign/reportHistory'
import { getCampaignsReportServiceURL } from '../../services/report'
import cardsByType from './cards'
import { dashboardIcons } from './cards/icons'

import * as S from './styles'

const RealtimeDashboardRetailMedia: React.FC = () => {
  const [refreshTime, setRefreshTime] = useState()
  const [mainLimit, setMainLimit] = useState(180)
  const [limit, setLimit] = useState(true)
  const { id } = useParams()

  const query = useQuery()
  // const mainLimit = Number(query.get('mainLimit') || 180)

  // const seconds = query.get('seconds') || 60
  // const msRAW = Number(seconds) * 1000
  const dangerSecondsRaw = query.get('dangerSeconds')
  const dangerSeconds = dangerSecondsRaw
    ? Number(dangerSecondsRaw) >= 3
      ? Number(dangerSecondsRaw)
      : 3
    : null

  // const ms = Number(msRAW) >= 10000 ? Number(msRAW) : 10000
  // const dangerMs =
  //   dangerMsRaw && Number(dangerMsRaw) >= 3000 ? Number(dangerMsRaw) : 3000

  // const finalMS = dangerSecondsRaw ? dangerMs : ms

  useLayoutEffect(() => {
    setRefreshTime(Number(dangerSeconds) || 60)
  }, [dangerSeconds])

  const dispatch = useDispatch()
  const accountType = getRetailMediaAccountType()

  const {
    report: {
      get: { data: cardData, loading: cardLoading },
      getRealtime: { data, loading, error }
    }
  } = useAppSelector(state => state.retailMedia)

  /**
   * 1. Requisição para os dados dos cards
   * 2. Requisição para os dados do gráfico
   */
  const requestKeyId =
    accountType === 'publisher' ? 'advertiser_id' : 'publisher_id'

  const queryParams = useMemo(
    () =>
      ({
        start_date: formatDateToString(new Date(), 'YYYY-MM-DD'),
        end_date: formatDateToString(new Date(), 'YYYY-MM-DD'),

        [requestKeyId]: id
      } as GetReportCampaignsActionProps),
    [requestKeyId, id]
  )

  const getOrderTimer = useRef(null)

  useEffect(() => {
    dispatch(reportCampaigns(queryParams))
    dispatch(reportCampaignsRealtime(queryParams))
  }, [dispatch, queryParams])

  useEffect(() => {
    getOrderTimer.current = setInterval(async () => {
      dispatch(reportCampaigns(queryParams))
      dispatch(reportCampaignsRealtime(queryParams))
    }, Number(refreshTime * 1000))

    return () => {
      clearInterval(getOrderTimer.current)
    }
  }, [dispatch, queryParams, refreshTime])

  /**
   * Configuração dos cards
   */
  const icons = useMemo(() => dashboardIcons[accountType], [accountType])

  const cards = useMemo(() => {
    const formatter = id ? cardsByType : cardsByType.listAll
    const formatted = formatter[accountType]({
      data: cardData,
      loading: cardLoading
    })

    return formatted
  }, [accountType, cardData, cardLoading, id])

  /**
   * Realtime
   */
  const dataRealtime = useMemo(
    () =>
      data
        ? formatIndustryReportHistoryCampaignRealtime(
            [...data.slice(limit ? mainLimit * -1 : 0)],
            refreshTime
          )
        : [],
    [data, limit, mainLimit, refreshTime]
  )

  const dataRealtimeDiff = useMemo(() => {
    const formattedData = dataRealtime

    return data ? formattedData : []
  }, [data, dataRealtime])

  const dataRealtimeLast30 = useMemo(
    () =>
      data
        ? formatIndustryReportHistoryCampaignRealtime([...data.slice(-30)])
        : [],
    [data]
  )

  return (
    <WrapperChartAndCards>
      <Alert>
        {t('rm:metrics.dashboard.realtime.alert.text')}{refreshTime} {t('rm:metrics.dashboard.realtime.unit.seconds')}.
      </Alert>

      <div className="wrapperCards">
        <S.WrapperCard>
          <div className="hold-cards">
            {cards.map((item, index) => (
              <Card
                key={index}
                title={item.title}
                value={item.value}
                // loading={item.loading}
                icon={icons?.[item.type]}
                isRelativeInterval={false}
              />
            ))}
          </div>
        </S.WrapperCard>
      </div>

      {dataRealtime && (
        <>
          <nav style={{ display: 'flex' }}>
            <Form
              onSubmit={data => console.log(data)}
              initialData={{ refreshTime }}
              style={{ display: 'flex', gap: '.5rem' }}
            >
              <FormGroup>
                <Label text={`${t('rm:metrics.dashboard.realtime.updateTime')}:`} htmlFor="refreshTime" />
                <ReactSelect
                  name="refreshTime"
                  isClearable={false}
                  onChange={data => setRefreshTime(data.value)}
                  options={[
                    { label: `1 ${t('rm:metrics.dashboard.realtime.unit.hour')}`, value: 3600 },
                    { label: `30 ${t('rm:metrics.dashboard.realtime.unit.minutes')}`, value: 1800 },
                    { label: `15 ${t('rm:metrics.dashboard.realtime.unit.minutes')}`, value: 900 },
                    { label: `10 ${t('rm:metrics.dashboard.realtime.unit.minutes')}`, value: 600 },
                    { label: `5 ${t('rm:metrics.dashboard.realtime.unit.minutes')}`, value: 300 },
                    { label: `1 ${t('rm:metrics.dashboard.realtime.unit.minute')}`, value: 60 },
                    { label: `30 ${t('rm:metrics.dashboard.realtime.unit.seconds')}`, value: 30 },
                    {
                      ...(dangerSecondsRaw
                        ? { label: `15 ${t('rm:metrics.dashboard.realtime.unit.seconds')}`, value: 15 }
                        : null)
                    },
                    {
                      ...(dangerSecondsRaw
                        ? { label: `10 ${t('rm:metrics.dashboard.realtime.unit.seconds')}`, value: 10 }
                        : null)
                    },
                    {
                      ...(dangerSecondsRaw
                        ? { label: `3 ${t('rm:metrics.dashboard.realtime.unit.seconds')}`, value: 3 }
                        : null)
                    }
                  ].filter(item => !!item?.label)}
                />
              </FormGroup>

              {limit && (
                <FormGroup style={{ marginRight: '8px' }}>
                  <Label text={`${t('rm:metrics.dashboard.realtime.resultsOnChart')}:`} htmlFor="mainLimit" />
                  <Input
                    defaultValue={mainLimit}
                    type="number"
                    max="300"
                    name="mainLimit"
                    onChange={data => setMainLimit(data.value)}
                  />
                </FormGroup>
              )}

              <ToggleInput
                name={`mainLimitToggle`}
                isChecked={limit}
                label={t('rm:metrics.dashboard.realtime.limitResults')}
                onChange={() => setLimit(o => !o)}
                data-testid="toggleAdverts"
                size="small"
              />
            </Form>
          </nav>

          <Chart
            isRealtime
            chartData={dataRealtimeDiff}
            initialMetrics={['impressionsDiff']}
            additionalMetricsKeys={['totalSpent', 'income']}
            selectQuantity={3}
          />

          <Chart
            isRealtime
            chartData={dataRealtime}
            initialMetrics={['clicks', 'impressions', 'conversions']}
            additionalMetricsKeys={['totalSpent', 'income']}
            selectQuantity={3}
          />

          <hr />

          <h2>{t('rm:metrics.dashboard.realtime.last30Results')}</h2>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
              gap: '1rem'
            }}
          >
            <Chart
              isRealtime
              chartData={dataRealtimeLast30}
              initialMetrics={['clicks']}
              selectQuantity={1}
            />

            <Chart
              isRealtime
              chartData={dataRealtimeLast30}
              initialMetrics={['impressions']}
              selectQuantity={1}
            />

            <Chart
              isRealtime
              chartData={dataRealtimeLast30}
              initialMetrics={['conversions']}
              selectQuantity={1}
            />

            <Chart
              isRealtime
              chartData={dataRealtimeLast30}
              initialMetrics={['views']}
              selectQuantity={1}
            />

            <Chart
              isRealtime
              chartData={dataRealtimeLast30}
              initialMetrics={['income']}
              selectQuantity={1}
            />

            <Chart
              isRealtime
              chartData={dataRealtimeLast30}
              initialMetrics={['totalSpent']}
              selectQuantity={1}
            />

            <Chart
              isRealtime
              chartData={dataRealtimeLast30}
              initialMetrics={['roas']}
              selectQuantity={1}
            />

            <Chart
              isRealtime
              chartData={dataRealtimeLast30}
              initialMetrics={['adCost']}
              selectQuantity={1}
            />

            {/* <Chart
              isRealtime
              chartData={dataRealtimeLast30}
              initialMetrics={['balance']}
              selectQuantity={1}
            /> */}
          </div>
        </>
      )}
    </WrapperChartAndCards>
  )
}

export default RealtimeDashboardRetailMedia
