import React, { useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { injectIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation, Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import FormUpdatePassword from '~/components/FormUpdatePassword'
import LanguageToggle from '~/components/LanguageToggle'
import PageTitle from '~/components/PageTitle'
import { useAppInfo } from '~/hooks/useAppInfo'
import {
  chekTokenIsValid,
  activeInvite,
  resetPassword
} from '~/modules/auth/store/updatePassword/actions'
import {
  resetPasswordPath,
  activeInvitePath
} from '~/modules/auth/store/updatePassword/constants'

import * as S from './styles'

const UpdatePassword = () => {
  const { t } = useTranslation()

  const { loading, messageError, validToken } = useSelector(
    ({ updatePassword }) => updatePassword
  )

  /**
   * Handle app info
   */
  const { logo, name } = useAppInfo()
  const urlLogo = logo
  /**
   * END = Handle app info
   */

  const dispatch = useDispatch()

  const { pathname } = useLocation()
  const { token } = useParams()

  const path = useMemo(() => pathname.split('/')[1], [pathname])

  const dataPage = useMemo(
    () => ({
      [resetPasswordPath]: {
        title: t('auth:updatePassword.resetPasswordPath.title'),
        sectionTitle: t('auth:updatePassword.resetPasswordPath.sectionTitle'),
        intro: t('auth:updatePassword.resetPasswordPath.intro'),
        onSubmit: data => dispatch(resetPassword(data)),
        onSuccessMessage: t(
          'auth:updatePassword.resetPasswordPath.successMessage'
        ),
        tokenIsValid: true,
        tokenExpiredTitle: t(
          'auth:updatePassword.resetPasswordPath.tokenExpiredTitle'
        ),
        tokenExpiredMessage: t(
          'auth:updatePassword.resetPasswordPath.tokenExpiredMessage'
        )
      },
      [activeInvitePath]: {
        title: t('auth:updatePassword.activeInvitePath.title'),
        sectionTitle: t('auth:updatePassword.activeInvitePath.sectionTitle'),
        intro: t('auth:updatePassword.activeInvitePath.intro'),
        onSubmit: data => dispatch(activeInvite(data)),
        validate: token => dispatch(chekTokenIsValid(token)),
        onSuccessMessage: t(
          'auth:updatePassword.activeInvitePath.successMessage'
        ),
        tokenIsValid: validToken,
        tokenExpiredTitle: t(
          'auth:updatePassword.activeInvitePath.tokenExpiredTitle'
        ),
        tokenExpiredMessage: t(
          'auth:updatePassword.activeInvitePath.tokenExpiredMessage'
        )
      }
    }),
    [dispatch, t, validToken]
  )

  useEffect(() => {
    if (dataPage[path].validate) {
      dataPage[path].validate(token)
    }
  }, [dataPage, path, token])

  return (
    <S.Container>
      <PageTitle title={dataPage[path]?.title} />

      <S.Main className="m-auto px-5 py-4 bg-white border rounded-3">
        <header>
          <Link to="/">
            <img
              src={urlLogo}
              className="mb-1 object-fit-contain"
              alt={name || 'Newtail'}
              width="228"
              height="89"
            />
          </Link>

          {dataPage[path].tokenIsValid && (
            <div className="pt-3 mx-auto text-center border-top border-tertiary">
              <h3>{dataPage[path].sectionTitle}</h3>
              <p>{dataPage[path].intro}</p>
            </div>
          )}

          {!loading && !dataPage[path].tokenIsValid && (
            <div className="pt-3 mx-auto text-center border-top border-tertiary">
              <h3>{dataPage[path].tokenExpiredTitle}</h3>
              <p>{messageError}</p>
            </div>
          )}
        </header>

        {dataPage[path].tokenIsValid && (
          <FormUpdatePassword
            token={token}
            isLoading={loading}
            onSubmit={dataPage[path].onSubmit}
            onSuccessMessage={dataPage[path].onSuccessMessage}
          />
        )}

        <footer>
          <LanguageToggle />
        </footer>
      </S.Main>
    </S.Container>
  )
}

export default injectIntl(UpdatePassword)
