import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import PropTypes from 'prop-types'

import { getAuthToken } from '~/helpers'
// import { checkTermsOfServiceService } from '~/modules/retailMedia/services/configuration/terms-of-service'

const PrivateRoute = ({ isPrivate, children }) => {
  const location = useLocation()

  const isAuthenticated = getAuthToken()

  if (isPrivate) {
    return isAuthenticated ? (
      <>{children}</>
    ) : (
      <Navigate to={`/login?callback_url=${location.pathname}`} />
    )
  }

  return <>{children}</>
}

export default PrivateRoute

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isPrivate: PropTypes.bool
}

PrivateRoute.defaultProps = {
  isPrivate: true
}
