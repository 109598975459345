import React, { useState, useCallback, useMemo } from 'react';

import { cpf, cnpj } from 'cpf-cnpj-validator';
import PropTypes from 'prop-types';

import InputMask from '../InputMask';

const InputCpfCnpj = ({ onChange, initialData, ...props }) => {
  const [value, setValue] = useState(initialData || null);

  const socialIdFormat = val => {
    const isCNPJ = val.toString().length > 11;

    if (!isCNPJ) {
      const part1 = val.substring(0, 3);
      const part2 = val.substring(3, 6);
      const part3 = val.substring(6, 9);
      const part4 = val.substring(9, 11);

      return `${part1}.${part2 || '___'}.${part3 || '___'}-${part4 || '__'}`;
    }

    if (isCNPJ) {
      const part1 = val.substring(0, 2);
      const part2 = val.substring(2, 5);
      const part3 = val.substring(5, 8);
      const part4 = val.substring(8, 12);
      const part5 = val.substring(12, 14);

      return `${part1}.${part2 || '___'}.${part3 || '___'}/${part4 || '____'}-${
        part5 || '__'
      }`;
    }

    return val;
  };

  const handleChange = useCallback(
    e => {
      const { value } = e.target;

      setValue(value);

      onChange && onChange(e);
    },
    [onChange],
  );

  const isValid = useMemo(
    () => cpf.isValid(value) || cnpj.isValid(value),
    [value],
  );

  return (
    <InputMask
      {...props}
      type="tel"
      format={socialIdFormat}
      onChange={handleChange}
      showBadgeIsvalid={isValid}
      showBadgeIsInvalid={!isValid && props.required}
    />
  );
};

export default InputCpfCnpj;

InputCpfCnpj.propTypes = {
  onChange: PropTypes.func,
  initialData: PropTypes.string,
  required: PropTypes.bool,
};

InputCpfCnpj.defaultProps = {
  onChange: null,
  initialData: null,
  required: false,
};
