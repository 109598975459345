import ReactModal from 'react-modal'

import styled, { css } from 'styled-components'

export const Header = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.large};
    position: relative;
  `}
`

export const Title = styled.h4`
  ${({ theme }) => css`
    font-weight: ${theme.font.bolder};
    margin: 0;
  `}
`

export const CloseButton = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    border: none;
    position: absolute;
    top: -0.125rem;
    right: -0.25rem;
    padding: ${theme.spacings.tiny};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color ${theme.transition.fast};

    > svg {
      font-size: ${theme.font.sizes.medium};
    }

    &:hover {
      svg {
        color: ${theme.colors.gray};
      }
    }
  `}
`

export const ConfirmationContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    background-color: ${theme.colors.dangerLighten40};
    border-left: 3px solid ${theme.colors.danger};
    border-radius: ${theme.border.radius.medium};
    padding: ${theme.spacings.large};
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacings.medium};
  `}
`

export const WarningIcon = styled.div`
  ${({ theme }) => css`
    flex-shrink: 0;

    > svg {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.danger};
    }
  `}
`

export const ConfirmationTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.colors.danger};
    font-weight: ${theme.font.bolder};
  `}
`

export const Description = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xxsmall};
    margin-bottom: 0;

    .highlight {
      font-weight: ${({ theme }) => theme.font.bolder};
      text-decoration: underline;
      cursor: pointer;
    }
  `}
`

export const FormGroup = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacings.xxxlarge};
  `}
`

export const Label = styled.label`
  line-height: 1;
`

export const Input = styled.input`
  ${({ theme }) => css`
    border-radius: ${theme.border.radius.medium};
    border: 1px solid ${theme.colors.tertiary};
    outline: none;
    padding: ${theme.spacings.xxxsmall};
    transition: border-color ${theme.transition.default};

    &:focus {
      border-radius: ${theme.border.radius.medium};
      border-color: ${theme.colors.primary};
    }
  `}
`

export const CancelButton = styled.button`
  ${({ theme }) => css`
    background: transparent;
    color: ${theme.colors.dark};
    padding: ${({ theme }) => `${theme.spacings.xtiny} ${theme.spacings.tiny}`};
    font-size: 16px;

    &:hover {
      color: ${theme.colors.gray};
    }
  `}
`

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacings.xxxlarge};
  `}
`

export const Modal = styled(ReactModal).attrs(
  ({ theme, maxWidth = '500px', bluredBg }) => ({
    style: {
      content: {
        backgroundColor: 'white',
        maxWidth: maxWidth,
        borderRadius: theme.border.radius.medium,
        padding: theme.spacings.xxxlarge
      },
      overlay: {
        backgroundColor: theme.modal.overlay,
        backdropFilter: bluredBg ? 'saturate(80%) blur(5px)' : null,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '9999'
      }
    }
  })
)``
