import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 1rem;

    form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    footer {
      .navLink {
        border-top: 1px solid ${theme.colors.tertiary};
        padding: 1rem 0;
        text-align: center;
      }
    }
  `}
`;

export const Nav = styled.nav`
  ${({ theme, activePanel }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem 0;
    margin-bottom: 1rem;
    position: relative;

    &:after {
      content: '';
      width: calc(100% / 3 - 2rem);
      border-radius: 4px;
      height: 2px;
      background: ${theme.colors.secondary};

      position: absolute;
      bottom: 8px;
      left: 0;
      transition: all 0.3s ease-in-out;
      border: solid transparent;
      border-width: 0 8px;
    }

    ${activePanel === 'main' &&
    css`
      > .button__main {
        font-weight: bold;
      }

      &:after {
        left: 1rem;
      }
    `}

    ${activePanel === 'times' &&
    css`
      > .button__times {
        font-weight: bold;
      }

      &:after {
        left: calc(33.3% + 1rem);
      }
    `}

    ${activePanel === 'values' &&
    css`
      > .button__values {
        font-weight: bold;
      }

      &:after {
        left: calc(66.6% + 1rem);
      }
    `}
  `}
`;

export const FieldsWrapper = styled.div`
  ${({ theme, activePanel }) => css`
    display: flex;
    margin-bottom: 1rem;
    max-width: 100%;
    overflow-x: hidden;

    > .panel {
      display: flex;
      max-width: 100%;
      transition: all 0.3s ease-in-out;

      > .fields {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        transition: all 0.3s ease-in-out;
        opacity: 0;

        > * + * {
          margin-top: 1rem;
        }
      }
    }

    ${activePanel === 'main' &&
    css`
      > .panel {
        transform: translateX(0);

        .fields__main {
          opacity: 1;
        }
      }
    `}

    ${activePanel === 'times' &&
    css`
      > .panel {
        transform: translateX(-100%);

        .fields__times {
          opacity: 1;
        }
      }
    `}

    ${activePanel === 'values' &&
    css`
      > .panel {
        transform: translateX(-200%);

        .fields__values {
          opacity: 1;
        }
      }
    `}
  `}
`;

export const GlobalConfigWrapper = styled.div`
  ${({ activePanel }) => css`
    transition: all 0.3s ease-in-out;
    max-height: 0;
    opacity: 0;
    overflow: hidden;

    ${(activePanel === 'times' || activePanel === 'values') &&
    css`
      max-height: 20rem;
      opacity: 1;
      }
    `}
  `}
`;
