import styled, { css } from 'styled-components';

export const Container = styled.button`
  background: transparent;
  border: 0;
  outline: 0;

  ${({ theme, color, isChecked }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin: 0.25rem 0;
    border-radius: 0.25rem;
    outline: none;
    font-size: 0.875rem;

    &:focus {
      outline: none;
    }

    .check {
      display: inline-flex;

      margin-right: 0.5rem;

      border-radius: 0.25rem;

      background: ${theme.light};

      font-size: 1.25rem;
      color: ${isChecked ? theme.success : theme.gray};
    }

    .label {
      color: ${theme.dark};

      &:before {
        content: '';
        background-color: ${color || theme.light};
        border-radius: 50%;
        height: 0.5rem;
        width: 0.5rem;
        display: inline-flex;
        margin-right: ${theme.spacings.tiny};
      }
    }
  `}
`;
