import { produce } from 'immer';

import flyers from './flyers';

const INITIAL_STATE = { flyers: flyers.initialState };

const reducerCases = { ...flyers.reducerCases };

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const reducer = Object.keys(reducerCases).filter(
      item => item === action.type,
    );

    if (reducer?.length) {
      reducerCases[reducer]({ state, draft, action });
    }
  });
