import { formatDateToString } from '~/helpers'
import i18n from '~/i18n'

import RequestContent from '../components/RequestContent'
import RequestTitle from '../components/RequestTitle'

type GroupedLogsByDate = {
  data: AuditLogItem[]
  debug: boolean
}

const formatGroupByDate = ({ data, debug }: GroupedLogsByDate) => {
  const flattenChangesReduce = (
    acc: FlattenChanges,
    change: AuditLogItemChange
  ) => [
    ...acc,
    ...change.details
      .map(detail => ({ ...change, ...detail }))
      .filter(filteredKeys => !!filteredKeys)
  ]

  const formatChangesMap = (change: FlattenChange) => {
    const hideContent =
      change.field_name === 'payload_create' &&
      change.entity === 'campaign' &&
      !debug

    return {
      id: `${change.action}${change.id}`,
      template: change.entity === 'ad' ? 'primary' : 'success',
      time: formatDateToString(change.created_at, 'HH:mm'),
      title: <RequestTitle data={change} />,
      content: hideContent ? null : (
        <RequestContent data={change} isDebug={!!debug} />
      )
    } as ItemVerticalTimeline
  }

  const formattedDataByDate: FormattedDataByDate = data.reduce((acc, item) => {
    const date = formatDateToString(
      item.changes[0].created_at,
      i18n.t('c:patterns.dateWithWeekday')
    )

    // Juntando todas mudanças contidas na requisição no mesmo nível
    const flattenChanges = item.changes.reduce(flattenChangesReduce, [])

    // Formatando para o componente de timeline
    const formattedChanges = flattenChanges.map(formatChangesMap)

    // Concatendando mudanças feitas na mesma data
    const currentDate = [...(acc[date] || []), ...formattedChanges]

    return {
      ...acc,
      [date]: currentDate
    }
  }, {} as FormattedDataByDate)

  const formattedDataToArray = Object.entries(formattedDataByDate).map(
    ([title, data]) => ({
      title,
      data,
      id: title
    })
  )

  return formattedDataToArray
}

export default formatGroupByDate
