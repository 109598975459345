import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { useAlert } from '~/hooks/useAlert'
import templateActions from '~/modules/messagingCampaigns/store/template/actions'

import DeleteTemplateButton from './components/DeleteTemplateButton'
import StatusTag from './components/StatusTag'
import TemplateView from './components/TemplateView'

const TemplatesView = () => {
  const dispatch = useDispatch()

  const { id } = useParams()

  const {
    template: {
      item: { data, loading, error }
    }
  } = useSelector(state => state.messagingCampaigns)

  const [AlertOnError] = useAlert(error)

  const loadTemplate = useCallback(() => {
    dispatch(templateActions.get(id))
  }, [dispatch, id])

  useEffect(() => {
    loadTemplate()
  }, [loadTemplate])

  return (
    <>
      <PageTitle title={`Template - ${data?.title || 'Carregando...'}`} />

      <HeaderPages
        title={data?.title || 'Carregando...'}
        subtitle={<StatusTag status={data?.status} />}
        sideRightComponent={<DeleteTemplateButton />}
        backTo="/messaging-campaigns/templates/list"
        labelBreadcrumb="Voltar aos templates"
      />

      <AlertOnError />

      <Loading status={loading}>Carregando template</Loading>

      {!loading && !!data && <TemplateView data={data} />}
    </>
  )
}

export default TemplatesView
