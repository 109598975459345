import React from 'react';
import { components } from 'react-select';

import PropTypes from 'prop-types';

import { primary as theme } from '~/styles/themes';

const removeOption = () => ({
  ':after': {
    content: '"x"',
    position: 'absolute',
    top: '50%',
    right: '1rem',
    transform: 'translateY(-50%)',
    fontSize: '.875rem',
    color: theme.colors.gray,
  },
});

export const customStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    '& .lengthLabel': {
      display: 'inline',
      backgroundColor: theme.colors.primary,
      color: theme.colors.light,
      padding: '.25rem .5rem',
      borderRadius: '.25rem',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: isFocused ? '75%' : '95%',
    },
  }),
  menuList: styles => ({
    ...styles,
    display: 'flex',
    flexDirection: 'column',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      position: 'relative',
      textTransform: 'capitalize',
      fontSize: '.75rem',
      backgroundColor: (() => {
        if (isFocused) {
          return theme.colors.hover;
        }

        return isSelected ? theme.colors.primary : theme.colors.white;
      })(),
      color: (() => {
        if (isDisabled) {
          return theme.colors.inative;
        }

        if (isFocused) {
          return theme.colors.light;
        }

        return isSelected ? theme.colors.light : theme.colors.dark;
      })(),
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: () => {
          if (isFocused) {
            return theme.colors.infoLighten50;
          }
          if (!isDisabled) {
            return isSelected
              ? theme.colors.light
              : theme.colors.secondaryLighten30;
          }
        },
      },
      ...(isSelected ? removeOption() : {}),
    };
  },
};

export const ValueContainer = ({
  getValue,
  hasValue,
  children,
  selectProps,
  ...rest
}) => {
  const { length, ...values } = getValue();
  const firstValue = values?.[0]?.label;
  const labelMulti = selectProps.labelMulti || 'selecionados';

  return (
    <components.ValueContainer {...rest}>
      {hasValue && (
        <span className="lengthLabel">
          {length === 1 && firstValue}
          {length > 1 && `${length} ${labelMulti}`}
        </span>
      )}
      {children}
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
  getValue: PropTypes.func.isRequired,
  hasValue: PropTypes.bool.isRequired,
  selectProps: PropTypes.shape({
    labelMulti: PropTypes.string,
  }).isRequired,
};

export const MultiValue = () => {
  return <></>;
};
