import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const RowWrapper = styled.div`
  ${({ theme }) => css`
    &.hasBorder {
      & + .hasBorder {
        border-top: 1px solid ${theme.colors.tertiary};
        padding-top: 1rem;
      }
    }

    &:not(:first-child) {
      margin-top: 1rem;
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .wrapper-publisher {
      display: flex;
    }

    .logo-store {
      margin-right: 18px;
    }

    .wrapper-publisher_name {
      > strong {
        color: ${theme.colors.primary};
        margin-bottom: 0.25rem;
      }
    }

    &:not(:first-child) {
      margin-top: 0.5rem;
    }

    > strong {
      color: ${theme.colors.primary};
      margin-bottom: 0.25rem;
    }

    p {
      margin: 0;
      line-height: 1;

      + p {
        margin-top: 0.25rem;
      }

      .icon {
        &.success {
          color: ${theme.colors.success};
        }
      }
    }

    .dateLabel::first-letter {
      text-transform: capitalize;
    }

    strong {
      &.tag {
        color: ${theme.colors.white};
        font-size: ${theme.font.sizes.xsmall};
        padding: 0.125rem 0.5rem;
        border-radius: 0.5rem;
      }
      &.success {
        background: ${theme.colors.success};
      }
      &.danger {
        background: ${theme.colors.danger};
      }
    }
  `}
`

export const GroupWrapper = styled.div`
  ${({ theme }) => css`
    @media ${device.laptop} {
      display: flex;
    }

    > ${Wrapper} {
      &:not(:first-child) {
        border: solid ${theme.colors.tertiary};
        border-width: 1px 0 0 0;
        margin: 0.125rem 0 0 0;
        padding: 0.125rem 0 0 0;

        @media ${device.laptop} {
          border-width: 0 0 0 1px;
          margin: 0 0 0 1.5rem;
          padding: 0 0 0 1.5rem;
        }
      }
    }
  `}
`

export const Text = styled.strong`
  display: flex;
  > div {
    margin-top: -0.1rem;
  }
`
