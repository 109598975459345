import React, { useMemo } from 'react'

import MetaTitle from '~/components/PageTitle'
import { useAppSelector } from '~/store/hooks'

import Chat from './elements/Chat'
import HandleProvider from './elements/HandleProvider'

import * as S from './styles'

const Messenger = () => {
  const { selectedChat } = useAppSelector(state => state.chats)

  const pageTitle = useMemo(() => {
    if (!selectedChat) {
      return 'Atendimento > Conversa'
    }

    const { unreadMessage: unread, unreadMessageCount: count } = selectedChat

    const prefix = unread && count ? `(${count}) ` : ''

    return `${prefix}${selectedChat.customerName} - Atendimento`
  }, [selectedChat])

  return (
    <S.Container>
      <MetaTitle title={pageTitle} />

      <Chat />

      <HandleProvider />
    </S.Container>
  )
}

export default Messenger
