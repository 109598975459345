import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'

import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import { getAuthToken, history } from '~/helpers'
import { useAppSelector } from '~/store/hooks'

const Page404 = () => {
  useEffect(() => {
    const token = getAuthToken()

    if (!token) {
      history.push('/login')
    }
  }, [])

  const { modules } = useAppSelector(state => state.accessModules)

  return (
    <>
      <Panel className="hold-notfound-content">
        {!modules?.length && (
          <Loading status>
            Configurando módulos - Se estiver demorando, tente recarregar a
            página ou tente <NavLink to="/logout"> logar novamente</NavLink>.
          </Loading>
        )}

        {!!modules?.length && (
          <>
            <h1 className="display-1 font-weight-bold">404</h1>

            <p className="h1">
              <FormattedMessage id="notfound.title" />
            </p>

            <p className="h3 font-weight-normal mt-3 mb-4">
              <FormattedMessage id="notfound.subtitle" />
            </p>
          </>
        )}
      </Panel>
    </>
  )
}

export default Page404
