import * as types from './filter-constants';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FILTER_OPEN_CLOSE:
      return {
        ...state,
        [action.payload.name]: {
          open: action.payload.open,
        },
      };
    default:
      return state;
  }
};
