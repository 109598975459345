import React, { useCallback, Fragment } from 'react';
import { useSelector } from 'react-redux';

import { formatMoney } from '~/helpers';
import { itemStatusList } from '~/pages/orders/_status/items';

import TotalDiscount from '../TotalDiscount';
import TotalValues from '../TotalValues';
import Product from './Product';
import * as S from './styles';

const ProductsListToPrint = () => {
  const { orderData } = useSelector(state => state.order);

  const { items, sub_total } = orderData;

  const shouldShowThisStatus = useCallback(
    status => items.findIndex(item => item.status === status.type) > -1,
    [items],
  );

  return (
    <>
      {/* Lista de produtos */}
      <>
        {items ? (
          <>
            <S.List>
              <S.Item hasActionColumn={false} className="headerTable">
                <div className="name">Itens do pedido</div>
                <div className="original_price">Valor un.</div>
                <div className="quantity grayColumn">
                  Quantidade
                  <br />
                  <small>(cliente / loja)</small>
                </div>
                <div className="discount ">Desconto</div>
                <div className="total_value grayColumn">Subtotal</div>
                <div className="status">Status do item</div>
              </S.Item>
              {/* Iteração nos itens sem status */}
              {items.map(
                item => !item.status && <Product key={item.id} item={item} />,
              )}
              {/* Iteração nos status */}
              {Object.values(itemStatusList).map(
                status =>
                  shouldShowThisStatus(status) && (
                    <Fragment key={status.type}>
                      {items.map(
                        item =>
                          item.status === status.type && (
                            <Product key={item.id} item={item} />
                          ),
                      )}
                    </Fragment>
                  ),
              )}
            </S.List>
          </>
        ) : (
          <S.List>
            <S.Item>
              <div className="name">
                Nenhum produto está cadastrado neste pedido.
              </div>
            </S.Item>
          </S.List>
        )}

        <TotalValues
          canEditOrder={false}
          isEditMode={false}
          subTotalItems={formatMoney(sub_total)}
        />
      </>
    </>
  );
};

export default ProductsListToPrint;
