import styled, { DefaultTheme, css } from 'styled-components'

interface LogoStoreInterface {
  backgroundLogo: string
  withoutImage: boolean
  size?: 'small' | 'medium' | 'large'
}

const sizeModifiers = {
  small: () => css`
    --size: 32px;
  `,
  medium: () => css`
    --size: 48px;
  `,
  large: () => css`
    --size: 64px;
  `
}

const containerModifiers = {
  withoutImage: (theme: DefaultTheme) => css`
    background: white;
    color: ${theme.colors.neutral.low.lightest};
  `
}

export const Container = styled.div<LogoStoreInterface>`
  ${({ theme, backgroundLogo, size, withoutImage }) => css`
    --size: 128px;
    --padding: 0.125rem;

    ${sizeModifiers[size]}

    display: inline-flex;
    align-items: center;
    justify-content: center;

    min-width: var(--size);
    min-height: var(--size);
    width: var(--size);
    height: var(--size);
    padding: var(--padding);

    outline: 1px solid ${theme.colors.neutral.high.light};

    font-size: calc(var(--size) * 0.65);

    border-radius: 4px;
    background: ${backgroundLogo};

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    ${withoutImage && containerModifiers.withoutImage(theme)}
  `}
`
