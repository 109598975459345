import {
  RETAIL_MEDIA_FILTER_INITIAL_STATE,
  RETAIL_MEDIA_FILTER_KEY
} from '~/modules/retailMedia/store/common/constants'

export const SET_FILTER = '@filters/SET_FILTER'

export const SET_FILTERED_LOCATIONS = '@filters/SET_LOCATIONS'
export const SET_LOCATION_REGION = '@filters/SET_LOCATION_REGION'
export const SET_FILTERED_STATES = '@filters/SET_STATES'
export const SET_FILTERED_chatBotStatus = '@filters/SET_chatBotStatus'
export const SET_FILTERED_filterChatAgent = '@filters/SET_filterChatAgent'
export const SET_FILTERED_CHATTAGS = '@filters/SET_FILTERED_CHATTAGS'
export const SET_FILTERED_CHANNEL = '@filters/SET_CHANNEL'
export const SET_FILTERED_STATUS = '@filters/SET_STATUS'
export const SET_FILTERED_STORE_TYPE = '@filters/SET_STORE_TYPE'
export const SET_FILTERED_LOCATION_STATUS = '@filters/SET_LOCATION_STATUS'
export const SET_FILTERED_TEMPLATE_STATUS = '@filters/SET_TEMPLATE_STATUS'
export const SET_FILTERED_CAMPAIGN_STATUS = '@filters/SET_CAMPAIGN_STATUS'
export const SET_FILTERED_PROMOTIONAL_STATUS = '@filters/SET_PROMOTIONAL_STATUS'
export const SET_FILTERED_PROMOTIONAL_TYPE = '@filters/SET_PROMOTIONAL_TYPE'
export const SET_FILTERED_USER_ROLE = '@filters/SET_USER_ROLE'
export const SET_FILTERED_PAYMENT_STATUS = '@filters/SET_PAYMENT_STATUS'
export const SET_FILTERED_PAYMENT_METHOD = '@filters/SET_PAYMENT_METHOD'
export const SET_FILTERED_SHOPPING_CART_STATUS =
  '@filters/SET_SHOPPING_CART_STATUS'

export const SET_QUERY = '@filters/SET_QUERY'

export const RESET_FILTERS = '@filters/RESET_FILTERS'
export const RESET_FILTER_LOCATION = '@filters/RESET_FILTER_LOCATION'
export const RESET_FILTER_LOCATION_REGION =
  '@filters/RESET_FILTER_LOCATION_REGION'

export const INITIAL_STATE = {
  query: null,
  locations: null,
  locationRegions: null,
  channel: null,
  chatBotStatus: null,
  filterChatAgent: null,
  chatTags: null,
  states: null,
  status: null,
  locationType: null,
  locationStatus: null,
  templateStatus: null,
  campaignStatus: null,
  promotionalStatus: null,
  promotionalType: null,
  paymentMethod: null,
  paymentStatus: null,
  shoppingCartStatus: null,
  userRoles: null,
  supermarkets: null,
  account: null,
  stores: null,
  channels: null,
  brands: null,
  categories: null,
  subcategories: null,
  products: null,
  aggregate: null,
  [RETAIL_MEDIA_FILTER_KEY]: {
    ...RETAIL_MEDIA_FILTER_INITIAL_STATE
  }
} as FilterState

export const FILTERS = {
  query: 'query',
  locations: 'locations',
  locationRegions: 'locationRegions',
  channel: 'channel',
  chatBotStatus: 'chatBotStatus',
  filterChatAgent: 'filterChatAgent',
  chatTags: 'chatTags',
  states: 'states',
  status: 'status',
  locationType: 'locationType',
  locationStatus: 'locationStatus',
  templateStatus: 'templateStatus',
  campaignStatus: 'campaignStatus',
  promotionalStatus: 'promotionalStatus',
  promotionalType: 'promotionalType',
  paymentMethod: 'paymentMethod',
  paymentStatus: 'paymentStatus',
  shoppingCartStatus: 'shoppingCartStatus',
  userRoles: 'userRoles',
  // Sell out
  supermarkets: 'supermarkets',
  account: 'account',
  stores: 'stores',
  channels: 'channels',
  brands: 'brands',
  categories: 'categories',
  subcategories: 'subcategories',
  products: 'products',
  aggregate: 'aggregate'
} as const

export type FiltersKeysTypes = keyof typeof FILTERS
