import React, { useEffect, useMemo, useState } from 'react'

import { t } from 'i18next'

import Loading from '~/components/Loading'
import { queryDatesDatepickerIsolated } from '~/helpers'
import { useAppSelector } from '~/store/hooks'

import { getExternalDashboardService } from '../../services/report'

import * as S from './styles'

type CustomFiltersProps =
  | {
      advertiser_id?: string
      publisher_id?: string
    }
  | Record<string, unknown>

type RetailMediaExternalReportProps = {
  useDate?: boolean
  customFilters?: CustomFiltersProps
  dashboardName: ExternalDashboardNames
}

const RetailMediaExternalReport = ({
  useDate = false,
  customFilters = {},
  dashboardName
}: RetailMediaExternalReportProps) => {
  const [dashboardUrl, setDashboardUrl] = useState('')
  const [loading, setLoading] = useState(true)

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const queries = useMemo(() => {
    const filters: GetExternalDashboardServiceParams = {
      ...customFilters
    }

    if (useDate) {
      const dates = queryDatesDatepickerIsolated(startDate, endDate)
      filters.start_at = dates.start_date
      filters.end_at = dates.end_date
    }

    return filters
  }, [startDate, endDate, useDate, customFilters])

  useEffect(() => {
    async function fetchDashboard() {
      try {
        const { data } = await getExternalDashboardService({
          queries,
          dashboardName
        })

        setDashboardUrl(data.iframe)
      } catch (error) {
        console.warn('Houve um erro ao gerar o relatório', error)
      } finally {
        setLoading(false)
      }
    }

    fetchDashboard()
  }, [queries])

  return (
    <S.Container>
      {loading ? (
        <Loading status>{t('rm:report.loading')}</Loading>
      ) : (
        <iframe src={dashboardUrl} title={t('rm:report')} />
      )}
    </S.Container>
  )
}

export default RetailMediaExternalReport
