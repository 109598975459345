export const bodyCreatePlacementDTO = (data: FormDataPlacement) => {
  const { formats: formatsRaw, ...rest } = data

  const formats: PlacementCreateBodyFormat = Object.keys(formatsRaw).reduce(
    (acc, key: string) => {
      if (key === 'product') {
        return [
          ...acc,
          {
            type: 'product',
            quantity: Number(formatsRaw.product.quantity.value)
          }
        ]
      }

      if (key === 'sponsored_brand') {
        return [
          ...acc,
          {
            type: 'sponsored_brand',
            quantity: Number(formatsRaw.sponsored_brand.quantity.value)
          }
        ]
      }

      if (key === 'banner') {
        const { sizes } = formatsRaw.banner
        const formattedSizes = Object.entries(sizes).map(([key, value]) => ({
          type: 'banner',
          quantity: Number(value.quantity.value),
          size: key
        }))

        return [...acc, ...formattedSizes]
      }

      return acc
    },
    []
  )

  const formattedData: PlacementCreateBody = { ...rest, formats }

  return formattedData
}
