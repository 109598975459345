import i18n from '~/i18n'
import { ADVERTISERS_LIST_QUERY } from '~/modules/retailMedia/store/advertiser/constants'
import { PUBLISHERS_LIST_QUERY } from '~/modules/retailMedia/store/industry/publisher/constants'

export const queryFilterListAdvertisers = {
  query: true,
  querySize: '1x',
  queryPlaceholder: i18n.t('rm:filter.advertiserName'),
  queryKeyState: ADVERTISERS_LIST_QUERY,
  queryWithDebounce: true,
  liveQuery: true
}

export const queryFilterListPublishers = {
  query: true,
  querySize: '1x',
  queryPlaceholder: i18n.t('rm:filter.publisherName'),
  queryKeyState: PUBLISHERS_LIST_QUERY,
  queryWithDebounce: true,
  liveQuery: true
}
