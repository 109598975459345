import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from '~/components/Alert'
import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import { history } from '~/helpers'
import useQuery from '~/hooks/useQuery'
import FormPromotion from '~/modules/promotional/components/Form'
import {
  clearPromotionStore,
  getPromotion
} from '~/modules/promotional/store/promotion/actions'
import { PRODUCT_REDUX_QUERY } from '~/modules/promotional/store/promotion/constants'
import { setQuery } from '~/store/modules/filters/actions'

const PromotionalCreate = () => {
  const query = useQuery()
  const duplicate = query.get('duplicate')

  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.promotional)

  useEffect(() => {
    dispatch(clearPromotionStore())
    if (duplicate) {
      dispatch(getPromotion({ id: duplicate }))
    }

    dispatch(
      setQuery({
        query: { [PRODUCT_REDUX_QUERY]: null },
        keyState: PRODUCT_REDUX_QUERY
      })
    )
  }, [dispatch, duplicate])

  const pageTitle = useMemo(
    () => (duplicate ? 'Duplicar' : 'Criar'),
    [duplicate]
  )

  return (
    <>
      <PageTitle title={`Promoções > ${pageTitle}`} />

      <HeaderPages
        title={`${pageTitle} promoção`}
        goBack={() => history.back()}
        labelBreadcrumb="Voltar"
      />

      <Alert active={error}>Erro: {error}</Alert>

      {loading && <Loading status={loading}>Carregando informações</Loading>}

      {!loading && <FormPromotion />}
    </>
  )
}

export default PromotionalCreate
