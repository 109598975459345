import { useMemo } from 'react'

import { Filters } from '~/components/Filters'
import { adTypeFilterConfig } from '~/modules/retailMedia/constants/adTypeFilterConfig'
import { useAdTypes } from '~/modules/retailMedia/hooks/useAdTypes'
import { adsStatusFilter } from '~/modules/retailMedia/store/common/filters/adsStatus'
import { queryAdsSKUFilter } from '~/modules/retailMedia/store/common/filters/query'

import { useAdsTable } from './hooks/useAdsTable'

type AdsTableFiltersProps = {
  adType: CampaignAdType
}

const AdsTableFilters: React.FC<AdsTableFiltersProps> = ({ adType }) => {
  const { insideCampaign } = useAdsTable()

  const { data: adTypes, isPending } = useAdTypes()

  const adTypeFilter = useMemo(
    () => adTypeFilterConfig({ types: adTypes, isLoading: isPending }),
    [adTypes, isPending]
  )

  const isSingleAdCampaign = adType === 'sponsored_brand'

  return (
    <>
      {!insideCampaign && (
        <Filters
          {...queryAdsSKUFilter}
          genericFilters={[adsStatusFilter, adTypeFilter]}
          showGlobalDatePicker
          columns={5}
        />
      )}

      {!isSingleAdCampaign && insideCampaign && (
        <Filters
          {...(adType === 'product' ? queryAdsSKUFilter : {})}
          genericFilters={[adsStatusFilter]}
          showGlobalDatePicker
          columns={4}
        />
      )}
    </>
  )
}

export default AdsTableFilters
