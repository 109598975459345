import { useState } from 'react'

import i18next from 'i18next'

import { Button } from '~/components/Buttons'
import { Badge } from '~/components/UI/Badge'

import PlacementForm from '../components/PlacementForm'

const MainInfo = ({ data, onSubmit, isLoading }: EditChildrenProps) => {
  const [isEditMode, setIsEditMode] = useState(false)

  const handleCancel = () => setIsEditMode(false)

  return (
    <article className="d-flex justify-content-between align-items-center gap-1">
      {!isEditMode && (
        <>
          <section className="d-inline-flex align-items-start flex-column gap-1 lh-1">
            <header>
              <Badge className="name m-0 f-3" template="primary">
                {data.name}
              </Badge>{' '}
              <Badge className="context m-0 f-3">
                {i18next.t(`rm:context.${data.context}`)}
              </Badge>
            </header>

            <p className="mb-0">
              {!!data?.full_name && (
                <strong className="text-primary fullName">
                  {data.full_name}
                </strong>
              )}
              <br />
              {!!data?.description && (
                <small className="description">{data.description}</small>
              )}
            </p>
          </section>

          <aside>
            <Button
              template="light"
              text={i18next.t('common:actions.editInfo')}
              size="xsmall"
              customWidth="auto"
              onClick={() => setIsEditMode(true)}
            />
          </aside>
        </>
      )}

      {isEditMode && (
        <PlacementForm
          initialData={data}
          fieldsets={['main', 'context']}
          onSubmit={formData => onSubmit(formData, handleCancel)}
          onCancel={handleCancel}
          isLoading={isLoading}
        />
      )}
    </article>
  )
}

export default MainInfo
