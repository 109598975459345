import React from 'react'
import { ArrowUp, ArrowDown, ArrowRight } from 'react-feather'
import { FormattedMessage } from 'react-intl'
import { Container } from 'reactstrap'

import { formatPercent } from '../../helpers'

const ArrowUpDown = ({ percent, textId }) => {
  if (percent > 0) {
    return (
      <div className="text-success text-sm mb-0 pb-0">
        <ArrowUp size={14} className="feather-md up-arrow-center-md" />
        {textId ? (
          <FormattedMessage
            id={textId}
            values={{
              percent: formatPercent(percent)
            }}
          />
        ) : (
          formatPercent(percent)
        )}
      </div>
    )
  }

  if (percent < 0) {
    return (
      <div className="text-danger text-sm">
        <ArrowDown size={14} className="feather-md up-arrow-center-md" />
        {textId ? (
          <FormattedMessage
            id={textId}
            values={{
              percent: formatPercent(percent)
            }}
          />
        ) : (
          formatPercent(percent)
        )}
      </div>
    )
  }

  if (percent === 0) {
    return (
      <div className="text-primary text-sm mb-0 pb-0">
        <ArrowRight size={14} className="feather-md up-arrow-center-md" />
        {textId ? (
          <FormattedMessage
            id={textId}
            values={{
              percent: formatPercent(percent)
            }}
          />
        ) : (
          formatPercent(percent)
        )}
      </div>
    )
  }

  return <Container />
}

export default ArrowUpDown
