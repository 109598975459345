import styled, { css } from 'styled-components'

export const Container = styled.article`
  ${({ theme }) => css`
    --padding: ${theme.spacings.medium};
    --borderRadius: ${theme.border.radius.large};
    --borderColor: ${theme.colors.outlineGray};

    --title-color: ${theme.colors.stormGray};
    --title-marginBottom: ${theme.spacings.small};

    --content-value-fontSize: ${theme.font.sizes.xxlarge};
    --content-value-fontWeight: ${theme.font.bolder};

    --content-compare-fontSize: var(--content-value-fontSize);
    --content-compare-color: ${theme.colors.neutralColor};
  `}

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--padding);
  border-radius: var(--borderRadius);
  border: 1px solid var(--borderColor);

  > .title {
    color: var(--title-color);
    margin-bottom: var(--title-marginBottom);
    line-height: 1;
  }

  > .LoadingContainer {
    margin-bottom: 0;
    width: 100%;
  }

  .content {
    display: flex;

    .value {
      line-height: 1;
      font-weight: var(--content-value-fontWeight);
      font-size: var(--content-value-fontSize);
    }

    .compare {
      margin-left: 0.5rem;
      line-height: 1;
      font-size: var(--content-compare-fontSize);
      color: var(--content-compare-color);
    }
  }
`

export const HoldCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1rem;
`
