import React from 'react'
import { NavLink } from 'react-router-dom'

import { t } from 'i18next'

import Status from '~/components/Status'
import { Badge } from '~/components/UI/Badge'
import { statusType } from '~/modules/retailMedia/components/StatusBar/statusType'
import { getLabelAdType } from '~/modules/retailMedia/dtos/common/campaign/format'

import { CampaignTitleWrapper } from './styles'

const CampaignInfo: RowComponent = data => {
  const statusProps: StatusProps['status'] = {
    label: statusType[data.campaign_status]?.label || data.campaign_status,
    type: statusType[data.campaign_status]?.color || 'default'
  }

  const typeProps: { [key in CampaignAdType]: BadgeTemplates } = {
    banner: 'success',
    sponsored_brand: 'info',
    product: 'primary'
  }

  return (
    <CampaignTitleWrapper>
      <NavLink
        to={`/retail-media/campaign/view/${data.campaign_id}`}
        className="text-decoration-none"
      >
        <Badge template={typeProps[data.ad_type]}>
          <small>{getLabelAdType(data.ad_type)}</small>
        </Badge>

        <strong className="title">{data.campaign_name}</strong>

        <div className="lh-1" style={{ fontSize: '.75rem' }}>
          <Status status={statusProps} />
        </div>
        <small className="text-decoration-underline">
          {t('rm:viewCampaign')}
        </small>
      </NavLink>
    </CampaignTitleWrapper>
  )
}

export default CampaignInfo
