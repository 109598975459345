import { formatMoney, formatNumberWithDots, formatPercent } from '~/helpers'

import {
  formatIntervalDates,
  getLabelAdType
} from '../../common/campaign/format'

const format: Format = (value, type) => {
  if (isNaN(Number(value))) return '-'

  const formatByType = {
    money: formatMoney,
    percent: formatPercent,
    numberWithDots: formatNumberWithDots
  }

  return formatByType?.[type](Number(value)) || value || '-'
}

export const formatGroceryListCampaign = (data: GroceryCampaign[]) => {
  if (!data) return null

  return data.map(campaign => ({
    _raw: {
      originPath: 'src/modules/retailMedia/dtos/grocery/campaign/list.ts'
    },
    raw: campaign,
    id: campaign.id,
    title: campaign.name,
    createdAt: campaign.created_at,
    adType: getLabelAdType(campaign.ad_type),
    type: campaign.type,
    active: campaign.active,
    status: campaign.status,
    advertiser: {
      id: campaign?.advertiser_id,
      name: campaign?.advertiser_name,
      logo: campaign?.advertiser_account_logo,
      theme: { ...campaign?.advertiser_account_theme }
    },
    publisher: {
      id: campaign?.publisher_id,
      name: campaign?.publisher_name,
      logo: campaign?.publisher_logo
    },
    ...formatIntervalDates({
      start_at: campaign.start_at,
      end_at: campaign.end_at,
      ignoreUTC: true
    }),
    budget: {
      total: format(campaign?.settings?.daily_budget, 'money'),
      spent: format(campaign?.consumed_budget, 'money'),
      raw: {
        dailyBudget: Number(campaign?.settings?.daily_budget),
        dailyConsumed: Number(campaign?.consumed_budget)
      }
    },
    metrics: {
      impressions: format(campaign?.metrics?.impressions, 'numberWithDots'),
      views: format(campaign?.metrics?.views, 'numberWithDots'),
      clicks: format(campaign?.metrics?.clicks, 'numberWithDots'),
      conversions: format(campaign?.metrics?.conversions, 'numberWithDots'),
      income: format(campaign?.metrics?.income, 'money'),
      ctr: format(campaign?.metrics?.ctr, 'percent'),
      conversion_rate: format(campaign?.metrics?.conversion_rate, 'percent'),
      ecpm: format(campaign?.metrics?.ecpm, 'money'),
      totalSpent: format(campaign?.metrics?.total_spent, 'money'),
      cpa: format(campaign?.metrics?.cpa, 'money'),
      avgCpc: format(campaign?.metrics?.avg_cpc, 'money'),
      avgCpm: format(campaign?.metrics?.avg_cpm, 'money'),
      roas: format(campaign?.metrics?.roas, 'numberWithDots'),
      adcost: format(campaign?.metrics?.adcost, 'money')
    }
  })) as GroceryItemListCampaign[]
}
