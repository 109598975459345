import { types } from '../constants';

const index = {
  pending: `${types.list}_PENDING`,
  fulfilled: `${types.list}_FULFILLED`,
  rejected: `${types.list}_REJECTED`,
  setPage: types.page,
  setPerPage: types.perPage,
};

export const list = {
  [index.pending]: ({ draft }) => {
    draft.template.list.loading = true;
    draft.template.list.error = null;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.template.list.data = action.payload;
    draft.template.list.loading = false;
    draft.template.list.error = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.template.list.loading = false;
    draft.template.list.error = action.payload;
  },
  [index.setPage]: ({ draft, action }) => {
    draft.template.list.page = action.payload;
  },
  [index.setPerPage]: ({ draft, action }) => {
    draft.template.list.perPage = action.payload;
    draft.template.list.page = 1;
  },
};
