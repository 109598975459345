const formatLocationAddress = ({ location }: FormatLocationAddressProps) => {
  if (typeof location === 'string') {
    const data = {
      street: location,
      number: '-',
      complement: '-',
      neighborhood: '-',
      city: '-',
      state: '-',
      postalCode: '-',
      country: '-'
    }

    return data
  }

  if (typeof location !== 'string' && Object.keys(location)?.length > 0) {
    const data = {
      street: location.address_street,
      number: location.address_number,
      complement: location.address_complement,
      neighborhood: location.address_neighborhood,
      city: location.address_city,
      state: location.address_state,
      postalCode: location.address_postal_code,
      country: location.address_country
    }

    return data
  }

  return null
}

const formatShippingAddress = ({
  shippingAddress
}: FormatShippingAddressProps) => {
  if (typeof shippingAddress === 'string') {
    const data = {
      street: shippingAddress,
      number: '-',
      complement: '-',
      neighborhood: '-',
      city: '-',
      state: '-',
      postalCode: '-',
      country: '-'
    }

    return data
  }

  if (
    typeof shippingAddress !== 'string' &&
    Object.keys(shippingAddress)?.length > 0
  ) {
    const data = {
      street: shippingAddress.street,
      number: shippingAddress.number,
      complement: shippingAddress.complement,
      neighborhood: shippingAddress.neighborhood,
      city: shippingAddress.city,
      state: shippingAddress.state || shippingAddress.region,
      country: shippingAddress.country,
      postalCode: shippingAddress.postcode
    }

    return data
  }

  return null
}

const formatAddress = ({ location, shippingAddress }: AddressProps) => {
  if (location) {
    return formatLocationAddress({ location })
  }

  if (shippingAddress) {
    return formatShippingAddress({ shippingAddress })
  }

  return null
}

export { formatAddress, formatLocationAddress, formatShippingAddress }
