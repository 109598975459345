import React from 'react';

import PropTypes from 'prop-types';

const ColumnType = ({ type }) => {
  return <div style={{ lineHeight: 1 }}>{type}</div>;
};

export default ColumnType;

ColumnType.propTypes = {
  type: PropTypes.string.isRequired,
};
