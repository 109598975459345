import { FiPlus } from 'react-icons/fi'

import i from 'i18next'

import { ButtonNavLink } from '~/components/Buttons'
import { DatepickerHeader } from '~/components/datepicker-header'
import { Filters } from '~/components/Filters'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { getRetailMediaAccountType } from '~/helpers'
import PublisherTable from '~/modules/retailMedia/components/_tables/PublisherTable'
import DashboardRetailMediaCampaigns from '~/modules/retailMedia/components/DashboardRetailMediaCampaigns'
import MainTabs from '~/modules/retailMedia/components/MainTabs'

import AdvertisersFilters from '../../components/_tables/AdvertiserFilters'
import AdvertisersTable from '../../components/_tables/AdvertisersTable'
import {
  queryFilterListAdvertisers,
  queryFilterListPublishers
} from './filters'

import * as S from './styles'

const RetailMediaLinks = () => {
  const accountType = getRetailMediaAccountType()
  const isAdvertiser = accountType === 'advertiser'

  // Informa ao gráfico qual a origem dos dados. Se pertence a advertiser ou publisher
  const originKey = isAdvertiser ? 'publisher' : 'advertiser'
  const titleTerm = isAdvertiser ? i.t('rm:publishers') : i.t('rm:advertisers')
  const title = i.t('common:capitalize', {
    value: titleTerm
  })

  const filters = isAdvertiser
    ? queryFilterListPublishers
    : queryFilterListAdvertisers

  const table = isAdvertiser ? <PublisherTable /> : <AdvertisersTable />

  return (
    <>
      <PageTitle title={i.t('rm:dashboard.title', { title })} />

      <HeaderPages
        title={i.t('rm:dashboard.title', { title })}
        className="has-datepicker"
        sideRightComponent={
          <S.WrapperHeader>
            {isAdvertiser && (
              <ButtonNavLink
                to="/retail-media/campaign/create"
                text={i.t('rm:createCampaign')}
                iconLeft={<FiPlus />}
              />
            )}

            <DatepickerHeader />
          </S.WrapperHeader>
        }
      />

      <MainTabs />

      {originKey === 'advertiser' && (
        <section>
          <AdvertisersFilters />
        </section>
      )}

      {/* Método antigo com redux */}
      {originKey === 'publisher' && <Filters {...filters} />}

      <DashboardRetailMediaCampaigns origin={originKey} />

      {table}
    </>
  )
}

export default RetailMediaLinks
