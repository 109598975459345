import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    max-height: 40rem;
    overflow-y: auto;
    position: relative;

    /* width */
    ::-webkit-scrollbar {
      height: 0.5rem;
      border-radius: 0.75rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.colors.tertiary};
      border-radius: 0.5rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.primary};
      border-radius: 0.5rem;
      /* Handle on hover */
      &:hover {
        background: ${theme.colors.hover};
      }
    }
  `}
`;

export const Label = styled.label`
  font-weight: ${({ theme }) => theme.font.bolder};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`;

export const SubOptions = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xxxsmall} ${theme.spacings.xxsmall};
    margin-left: ${theme.spacings.large};
    margin-right: ${theme.spacings.xsmall};
    margin-bottom: ${theme.spacings.xsmall};
    border: 1px solid ${theme.colors.primaryLighten60};

    background: ${theme.colors.light};
    border-radius: 0 ${theme.border.radius.medium} ${theme.border.radius.medium}
      ${theme.border.radius.medium};
  `}
`;
