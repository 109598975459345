import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import Alert from '~/components/Alert';

import { Wrapper } from './styles';

const PanelStatusPending = () => (
  <Alert>
    <Wrapper>
      <FiAlertCircle /> Este pedido contém itens com status pendentes.
    </Wrapper>
  </Alert>
);

export default PanelStatusPending;
