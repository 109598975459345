import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/campaign-placement`

export const listCampaignPlacementsKey = 'listCampaignPlacements'
export const listCampaignPlacementsService = ({
  campaignId
}: RequestGetPlacementsServiceParams) =>
  axios.get<GetPlacementsServiceResponse>(`${baseURL}/${campaignId}`)
