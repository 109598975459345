import { shade } from 'polished'
import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const warningButtonTemplate = ({ theme }: ButtonTemplatesProps) => css`
  background: var(--warning);
  color: var(--white);

  border: 1px solid var(--warning);

  &:not(:disabled) {
    &:hover {
      background: ${shade(0.1, theme.warning)};
    }
  }
`

export default warningButtonTemplate
