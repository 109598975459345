import { NavLink } from 'react-router-dom'
import i18n from '~/i18n'

import { LogoStore } from '~/components/LogoStore'
import { SmallLineClamp } from '~/components/UI/SmallLineClamp'
import { requestRetailMediaAccount } from '~/helpers'

const key = requestRetailMediaAccount
const keyLabel = {
  advertiser: i18n.t('rm:Advertiser'),
  publisher: 'Publisher'
}

export const accountInfo: SchemaItems = {
  account: {
    sortKey: `${key}_name`,
    title: keyLabel[key],
    render: rowData => {
      const data = rowData[key]

      return (
        <NavLink to={`/retail-media/campaigns?rmid=${data.id}`}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '.25rem',
              maxWidth: '10rem'
            }}
          >
            {data?.logo && (
              <LogoStore
                alt={data.name}
                linkLogo={data.logo}
                backgroundLogo={data.bgColor}
                size="small"
              />
            )}

            <SmallLineClamp>{data.name}</SmallLineClamp>
          </div>
        </NavLink>
      )
    }
  }
}
