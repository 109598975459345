import { formatGroceryListCampaign } from '~/modules/retailMedia/dtos/grocery/campaign/list'

import { types } from '../constants'

const index = {
  pending: `${types.list}_PENDING`,
  fulfilled: `${types.list}_FULFILLED`,
  rejected: `${types.list}_REJECTED`,
  setPage: types.page,
  setPerPage: types.perPage
}

export const list = {
  [index.pending]: ({ draft }) => {
    draft.grocery.campaign.list.loading = true
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.grocery.campaign.list.data = formatGroceryListCampaign(
      action.payload.data
    )
    draft.grocery.campaign.list.pages = action.payload.pages
    draft.grocery.campaign.list.total = action.payload.total
    draft.grocery.campaign.list.page = action.payload.page

    draft.grocery.campaign.list.loading = false
    draft.grocery.campaign.list.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.grocery.campaign.list.loading = false
    draft.grocery.campaign.list.error = action.payload
    draft.grocery.campaign.list.total = 0
    draft.grocery.campaign.list.data = null
  },
  [index.setPage]: ({ draft, action }) => {
    draft.grocery.campaign.list.page = action.payload
  },
  [index.setPerPage]: ({ draft, action }) => {
    draft.grocery.campaign.list.perPage = action.payload
  }
}
