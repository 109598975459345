import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Fragment
} from 'react'
import { FiEdit, FiList } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

import { Button } from '~/components/Buttons'
import { Icon } from '~/components/Icon'
import { Content, Header, WrapperShadow } from '~/components/WrapperShadow'
import { brlToFloat, formatMoney } from '~/helpers'
import { ABNT_5891_1977 as RoundABNT } from '~/helpers/abnt-round'
import { getAccountInfo } from '~/helpers/account-info'
import { gtagEvent } from '~/lib/gtag'
import { itemStatusList } from '~/pages/orders/_status/items'
import { updateOrderItems } from '~/pages/orders/actions'

import TotalValues from '../TotalValues'
import ActionSection from './ActionSection'
import Product from './Product'

import * as S from './styles'

const ProductsList = () => {
  const [edit, setEdit] = useState(false)
  const [itemsDraft, setItemsDraft] = useState()

  const {
    orderData,
    getOrderLoading: loading,
    updateLoading
  } = useSelector(state => state.order)

  const dispatch = useDispatch()

  const { items, payments, total_discount } = orderData

  useEffect(() => {
    setItemsDraft(items)
  }, [items, total_discount])

  const paymentsStatus = useMemo(() => payments?.[0]?.status, [payments])
  const isWaitingPayment = useMemo(
    () =>
      paymentsStatus === 'waiting_payment' ||
      paymentsStatus === 'payment_refused',
    [paymentsStatus]
  )

  const handleUndoItem = useCallback(
    itemId => {
      if (itemsDraft) {
        const draftOriginal = [...items]
        const draft = [...itemsDraft]

        const originalItem = draftOriginal.find(item => item.id === itemId)
        const indexItem = draft.findIndex(item => item.id === itemId)

        draft[indexItem] = {
          ...originalItem
        }

        setItemsDraft(draft)
      }
    },
    [items, itemsDraft]
  )

  const handleCancelEdit = useCallback(() => {
    setEdit(false)
    setItemsDraft(items)
  }, [items])

  const handleChangesChildren = useCallback(
    data => {
      if (itemsDraft) {
        const draft = [...itemsDraft]
        const indexItem = draft.findIndex(item => item.id === data.id)
        draft[indexItem] = {
          ...draft[indexItem],
          special_price: data.original_price,
          original_price: data.original_price,
          quantity: data.quantity,
          status: data.status
        }

        setItemsDraft(draft)
      }
    },
    [itemsDraft]
  )

  const handleDeleteItem = useCallback(
    data => {
      if (itemsDraft) {
        const draft = [...itemsDraft]
        const indexItem = draft.findIndex(item => item.id === data.id)
        draft[indexItem] = {
          ...draft[indexItem],
          status: itemStatusList.DELETED.type
        }

        setItemsDraft(draft)
      }
    },
    [itemsDraft]
  )

  const handleUpdateItems = useCallback(() => {
    const { id } = orderData

    dispatch(updateOrderItems({ id, data: itemsDraft }))

    setEdit(false)
  }, [itemsDraft, orderData, dispatch])

  /**
   * Handle Sum
   */
  const shouldSum = status =>
    status === itemStatusList.DELETED.type ||
    status === itemStatusList.STOCK_OUT.type

  const abnt = new RoundABNT(2)

  const sumItem = useCallback(({ acc, item }) => {
    const multiplier = item.quantity * item.original_price

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })

    const valueNumber = Number(multiplier).toFixed(3)

    const valueFormatted = formatter.format(valueNumber)

    const formattedNumber = abnt.arredonda(
      brlToFloat(valueFormatted.replace('R$', '').trim())
    )

    return shouldSum(item.status) ? acc : Number(formattedNumber) + acc
  }, [])

  const subTotalItems = useMemo(
    () => itemsDraft?.reduce((acc, item) => sumItem({ acc, item }), 0) || 0,
    [itemsDraft, sumItem]
  )

  /**
   * Função para separar os itens por status.
   * Ela recebe um status da lista de status possíveis(itemStatusList) e renderiza ordendando por status.
   */
  const sortByStatus = useCallback(
    status => itemsDraft.findIndex(item => item.status === status.type) > -1,
    [itemsDraft]
  )

  const canEditOrder = useMemo(
    () => Boolean(!orderData?.integration_id && isWaitingPayment),
    [orderData, isWaitingPayment]
  )

  /**
   * GTAG
   */
  const accountInfo = getAccountInfo()

  const accountName = useMemo(() => accountInfo?.name, [accountInfo])

  const handleEditItems = useCallback(() => {
    setEdit(true)

    gtagEvent({
      category: 'Pedido',
      action: 'Editar itens',
      label: `[${accountName}] - Clicou em editar itens`
    })
  }, [accountName])

  return (
    <WrapperShadow>
      <Header>
        <div>
          <span>
            <Icon icon={FiList} /> <strong>Itens do pedido</strong>
          </span>
        </div>

        {canEditOrder && (
          <aside>
            {edit ? (
              <ActionSection
                onCancel={handleCancelEdit}
                onSave={handleUpdateItems}
                updateLoading={updateLoading}
                isEditMode={edit}
              />
            ) : (
              <Button
                text="Editar itens"
                iconLeft={<FiEdit />}
                className="button__edit"
                size="small"
                customWidth="auto"
                onClick={handleEditItems}
                loading={updateLoading}
              />
            )}
          </aside>
        )}
      </Header>

      {/* Lista de produtos */}
      <Content className="noPaddingX scrollX">
        <S.List>
          {itemsDraft ? (
            <>
              {/* Header da tabela */}
              <S.Item
                hasActionColumn={isWaitingPayment}
                isEditMode={edit}
                className="headerTable"
              >
                <div className="name">
                  Produtos ({itemsDraft?.length} itens)
                </div>
                <div className="original_price">Valor un.</div>
                <div className="quantity grayColumn">
                  Quantidade
                  <br />
                  <small>(cliente / loja)</small>
                </div>
                <div className="discount ">Desconto</div>
                <div className="total_value grayColumn">
                  Subtotal
                  <br />
                  <small>(com desconto)</small>
                </div>
                {edit && <div className="undo_edit" />}
                <div className="status">Status do item</div>
              </S.Item>
              {/* Loading geral */}
              {loading && (
                <S.Item>
                  <div className="loading">
                    <Spinner type="grow" size="sm" color="secondary" />{' '}
                    Carregando produtos...
                  </div>
                </S.Item>
              )}
              {/* Loading da atualização */}
              {updateLoading && (
                <S.Item>
                  <div className="loading">
                    <Spinner type="grow" size="sm" color="secondary" />{' '}
                    Atualizando produtos...
                  </div>
                </S.Item>
              )}
              {/* Iteração nos itens sem status */}
              {itemsDraft.map(
                item =>
                  !item.status && (
                    <Product
                      key={item.id}
                      item={item}
                      isWaitingPayment={isWaitingPayment}
                      isEditMode={edit}
                      onChange={handleChangesChildren}
                      onUndo={handleUndoItem}
                      onDelete={handleDeleteItem}
                    />
                  )
              )}
              {/* Iteração nos status */}
              {Object.values(itemStatusList).map(
                status =>
                  sortByStatus(status) && (
                    <Fragment key={status.type}>
                      {itemsDraft.map(
                        item =>
                          item.status === status.type && (
                            <Product
                              key={item.id}
                              item={item}
                              isWaitingPayment={isWaitingPayment}
                              isEditMode={edit}
                              onChange={handleChangesChildren}
                              onUndo={handleUndoItem}
                              onDelete={handleDeleteItem}
                            />
                          )
                      )}
                    </Fragment>
                  )
              )}
            </>
          ) : (
            <S.Item>
              <div className="name">
                Nenhum produto está cadastrado neste pedido.
              </div>
            </S.Item>
          )}
        </S.List>

        {/* Botão de ação */}
        <ActionSection
          isEditMode={edit}
          onCancel={handleCancelEdit}
          onSave={handleUpdateItems}
          updateLoading={updateLoading}
        />

        <TotalValues
          canEditOrder={canEditOrder}
          isEditMode={edit}
          subTotalItems={subTotalItems}
        />
      </Content>
    </WrapperShadow>
  )
}

export default ProductsList
