/* eslint-disable max-len */
import React, { useState, useMemo, useCallback } from 'react'
import { FiX } from 'react-icons/fi'
import { VscFilePdf } from 'react-icons/vsc'

import { Scope } from '@unform/core'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import WhatsAppMessagePreview from '~/components/WhatsAppMessagePreview'
import WhatsAppTextEditor from '~/components/WhatsAppTextEditor'
import { formattedMessageToHtml } from '~/helpers'
import { default__buttons } from '~/modules/messagingCampaigns/constants/buttons'

import { FormGroup } from '..'

import { FileInput, InputHidden } from '../../Input'
import { Label } from '../../Label'
import SortedCheckbox from '../../SortedCheckbox'
import { callbackButtons } from './callbackButtons'

import * as S from './styles'

const defaultValues = {
  initialText:
    'Esse é um texto de *exemplo* com _italic_, ~strike~ and ```code```.\n\nOlá, amigo cliente! ❤️\n\nNosso festival de frutas da estação começou com tudo! 🍒🍇🍉😋\n\nQuer aproveitar as promoções?',
  mediaLabel: 'Imagem ou PDF'
}

/**
 * Esse componente renderiza um grupo para usar no formulário (Unform) para cadastro de mensagens para o WhatsApp.
 * Tem possibilidade de cadastrar mensagem e mídia e tem um painel de pré-visualização da mensgem.
 * @component
 * @example <MessageWithMediaAndPreview {...props} />
 */

const MessageWithMediaAndPreview = ({
  formRef,
  panelTitle,
  panelDescription,
  showTextEditor = true,
  allowMedia,
  messagesIsRequired,
  mediaIsRequired,
  handleUpload,
  uploadProgress,
  isLoading,
  mediaFile,
  mediaTypeAllowed,
  onClearMedia,
  initialText,
  mediaLabel,
  mediaDescription,
  onChangeTextMessage,
  children,
  alwaysShowFileInput,
  canRemoveMedia,
  path,
  pathMessage,
  pathButtons,
  showDefaultButtons,
  allowHandleButtons,
  initialButtons
}) => {
  const [text, setText] = useState(initialText)
  const [customButtons, setCustomButtons] = useState([])

  const messages = useMemo(() => {
    const getButtons = () => {
      if (customButtons?.length) {
        return customButtons
      }

      if (initialButtons) {
        return initialButtons
      }

      return showDefaultButtons ? default__buttons : null
    }

    return [
      {
        text: formattedMessageToHtml(text),
        media: mediaFile,
        buttons: getButtons()
      }
    ]
  }, [mediaFile, showDefaultButtons, text, customButtons, initialButtons])

  const handleChangeTextMessage = useCallback(
    (_, formattedText) => {
      setText(formattedText)
      onChangeTextMessage?.(formattedText)
    },
    [onChangeTextMessage]
  )

  const handleClearMedia = useCallback(() => {
    if (formRef?.current) {
      formRef.current.clearField(`${path}.media.type`)
      formRef.current.clearField(`${path}.media.url`)
      formRef.current.clearField(`${path}.media.name`)
      formRef.current.clearField(`${path}.mediaFile`)
    }

    onClearMedia()
  }, [formRef, onClearMedia, path])

  const handleCustomButton = useCallback(data => {
    const isAdd = !!data.checked

    const sortByOrder = (a, b) => {
      if (a.order < b.order) return -1

      if (a.order > b.order) return 1

      return 0
    }

    const newConfig = {
      text: data.value,
      order: data.order
    }

    const handleState = oldState => {
      if (isAdd) {
        const sortedList = [...oldState, newConfig].sort(sortByOrder)

        return sortedList
      }

      const filteredState = oldState.filter(item => item.text !== data.value)

      return filteredState
    }

    setCustomButtons(handleState)
  }, [])

  return (
    <Panel title={panelTitle} description={panelDescription} isCollapsible>
      <Scope path={path}>
        <S.TemplateWrapper>
          <div className="edit-message-template">
            {showTextEditor && (
              <FormGroup className="form-group">
                <Label
                  htmlFor={`${path}.${pathMessage}`}
                  isRequired={messagesIsRequired}
                >
                  Corpo da mensagem {!messagesIsRequired && '(opcional)'}
                  <span>(até 1024 caracteres)</span>
                </Label>

                <WhatsAppTextEditor
                  name={`${pathMessage}`}
                  scopePath={path}
                  id={`${path}.${pathMessage}`}
                  open
                  formRef={formRef}
                  onText={handleChangeTextMessage}
                />
              </FormGroup>
            )}

            {allowMedia && (
              <FormGroup className="form-group">
                <Label htmlFor="mediaFile" isRequired={!messagesIsRequired}>
                  {mediaLabel}
                </Label>

                {mediaDescription && <div>{mediaDescription}</div>}

                <Loading status={isLoading}>
                  Enviando mídia... {uploadProgress ? `${uploadProgress}` : ``}%
                </Loading>

                {mediaFile && (
                  <S.PreviewMedia
                    isLoading={isLoading}
                    {...(mediaFile?.url
                      ? { as: 'a', href: mediaFile.url, target: '_blank' }
                      : {})}
                  >
                    {mediaFile.type === 'image' && (
                      <img src={mediaFile.url} width="200" alt="Preview" />
                    )}

                    {mediaFile.type === 'document' && (
                      <div className="file">
                        <div className="icon">
                          <VscFilePdf />
                        </div>

                        <div className="info">
                          <strong>{mediaFile.name}</strong>
                          {mediaFile?.size && (
                            <>
                              <br />
                              <small>
                                (
                                {((mediaFile.size / 1000 ** 2) * 1000).toFixed(
                                  0
                                )}
                                kb)
                              </small>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </S.PreviewMedia>
                )}

                {canRemoveMedia && mediaFile && !isLoading && (
                  <Button
                    template="transparentDanger"
                    size="xsmall"
                    disabled={isLoading}
                    onClick={handleClearMedia}
                    type="button"
                    iconLeft={<FiX />}
                    text="Remover mídia"
                  />
                )}

                <div className="file-input-wrapper">
                  {(!mediaFile || alwaysShowFileInput) && (
                    <FileInput
                      name="mediaFile"
                      accept={mediaTypeAllowed}
                      onChange={handleUpload}
                      data-test="file-input-template"
                      isLoading={isLoading}
                    >
                      <small>
                        Apenas arquivos nos formatos PNG, JPG ou JPEG com
                        tamanho máximo de 5MB ou PDF com tamanho máximo de 50MB
                      </small>
                    </FileInput>
                  )}
                  <InputHidden name="media.type" required={mediaIsRequired} />
                  <InputHidden name="media.url" required={mediaIsRequired} />
                  <InputHidden name="media.name" required={mediaIsRequired} />
                </div>
              </FormGroup>
            )}

            {allowHandleButtons && (
              <FormGroup className="form-group">
                <Label
                  htmlFor="mediaFile"
                  text="Incluir botão (opcional)"
                  helperText="Escolha até 3 botões para serem enviados na mensagem de resposta."
                />
                <SortedCheckbox
                  options={callbackButtons}
                  onChange={handleCustomButton}
                  name={`${pathButtons}`}
                />
              </FormGroup>
            )}
          </div>

          <div className="preview-message-template">
            <WhatsAppMessagePreview
              isEditing
              messages={messages}
              isLoadingImage={isLoading}
              allowMediaOnMessage={allowMedia}
            />
          </div>
        </S.TemplateWrapper>
      </Scope>
      {children}
    </Panel>
  )
}

export default MessageWithMediaAndPreview

MessageWithMediaAndPreview.propTypes = {
  path: PropTypes.string,
  pathMessage: PropTypes.string,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  panelTitle: PropTypes.string,
  panelDescription: PropTypes.string,
  showTextEditor: PropTypes.bool,
  allowMedia: PropTypes.bool,
  uploadProgress: PropTypes.number,
  isLoading: PropTypes.bool,
  messagesIsRequired: PropTypes.bool,
  mediaIsRequired: PropTypes.bool,
  mediaFile: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number
  }),
  mediaTypeAllowed: PropTypes.string,
  handleUpload: PropTypes.func,
  onClearMedia: PropTypes.func,
  initialText: PropTypes.string,
  mediaLabel: PropTypes.string,
  mediaDescription: PropTypes.string,
  onChangeTextMessage: PropTypes.func,
  alwaysShowFileInput: PropTypes.bool,
  canRemoveMedia: PropTypes.bool,
  showDefaultButtons: PropTypes.bool,
  allowHandleButtons: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

MessageWithMediaAndPreview.defaultProps = {
  path: 'messages[0]',
  pathMessage: 'text',
  panelTitle: 'Mensagem',
  panelDescription: null,
  showTextEditor: true,
  allowMedia: true,
  isLoading: false,
  showDefaultButtons: false,
  messagesIsRequired: true,
  mediaIsRequired: false,
  handleUpload: null,
  uploadProgress: null,
  onClearMedia: null,
  mediaFile: null,
  initialText: defaultValues.initialText,
  mediaLabel: defaultValues.mediaLabel,
  mediaDescription: null,
  onChangeTextMessage: null,
  alwaysShowFileInput: false,
  canRemoveMedia: true,
  mediaTypeAllowed: 'image/png,image/jpg,image/jpeg,application/pdf',
  children: null,
  allowHandleButtons: false
}
