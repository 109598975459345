import axios from 'axios';

import { baseAPI } from './_prefix';

export const methodsAPI = `${baseAPI}/method`;

/**
 *
 * @endpoint /payment/method
 * @exports getPaymentMethods Retorna todos métodos de pagamento da conta.
 *
 */

export const list = () => {
  return axios.get(`${methodsAPI}`);
};

export default {
  list,
};
