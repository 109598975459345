import { shade } from 'polished'
import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const successButtonTemplate = ({ theme }: ButtonTemplatesProps) => css`
  background: var(--success);
  color: var(--white);

  &:disabled {
    opacity: 1;
    background: ${theme.colors.successLighten20};
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover {
      background: ${shade(0.1, theme.success)};
    }
  }
`

export default successButtonTemplate
