export default {
  // Indicador global de loading
  loading: false,
  // Indicador de loading ao criar
  loadingCreate: false,
  // Indicador de loading ao salvar
  loadingSaving: false,
  // Indicador de loading ao excluir
  loadingDelete: false,
  // Indicador de loading ao adicionar vários itens
  loadingAddBulk: false,
  // Indicador global de error
  error: null,
  // Armazena todas as promoções carregadas
  promotions: null,
  // Armazena o total de registros disponíveis no filtro aplicado para a listagem
  total: null,
  // Armazena quantas páginas de promoções estão disponíveis na listagem atual
  pages: null,
  // Armazena a quantidade de itens que devem ser exibidos por página
  itemsPerPage: 50,
  promotion: null,
  // Armazena os itens selecionados na promoção
  selectecProducts: [],
  // Metrics
  promotionMetrics: null,
  loadingMetrics: false,
  errorMetrics: null,
};
