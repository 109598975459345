import * as S from '../styles'
import contentComponents from './elements'

type RequestContentProps = {
  data: AuditLogItemChange & AuditLogItemChangeDetail
  isDebug: boolean
}

const RequestContent: React.FC<RequestContentProps> = ({ data, isDebug }) => {
  const entity = data.entity
  const fieldName = data?.field_name

  const prevValue = JSON.parse(data.previous_value)
  const newValue = JSON.parse(data.new_value)

  const note = JSON.parse(data?.note)

  const contentProps = { prevValue, newValue, entity, note, fieldName }

  const isValidKey = Object.keys(contentComponents).includes(fieldName)

  const Component = isValidKey
    ? contentComponents[fieldName]
    : contentComponents.generic

  return (
    <S.ContainerContent>
      <Component {...contentProps} />

      {isDebug && <contentComponents.debug {...contentProps} />}
    </S.ContainerContent>
  )
}

export default RequestContent
