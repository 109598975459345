import { useMemo } from 'react'

import { Filters } from '~/components/Filters'

import { adTypeFilterConfig } from '../../constants/adTypeFilterConfig'
import { useAdTypes } from '../../hooks/useAdTypes'
import { adsStatusFilter } from '../../store/common/filters/adsStatus'
import { queryAdsSKUFilter } from '../../store/common/filters/query'

const FilterHeader: React.FC = () => {
  const { data, isPending } = useAdTypes()

  const adTypeFilter = useMemo(
    () => adTypeFilterConfig({ types: data, isLoading: isPending }),
    [data, isPending]
  )

  return (
    <Filters
      {...queryAdsSKUFilter}
      genericFilters={[adsStatusFilter, adTypeFilter]}
      showGlobalDatePicker
    />
  )
}

export default FilterHeader
