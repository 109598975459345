const promotionalFlyerTexts = {
  menu: {
    list: 'Listagem de encartes',
    create: 'Criar encarte',
    view: 'Ver encarte',
    edit: 'Editar encarte',
  },
};

export { promotionalFlyerTexts };
