import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';

import { requiredValidation, formatMoney, unformatMoney } from '~/helpers';

import WrapperFields from './wrapper';
import Input from '~/components/forms/inputs';

const PriceForm = ({
  onNext,
  onPrevious,
  onSubmitStep,
  disabledStep,
  edit,
  initialValue,
  isLastStep,
}) => {
  const beforeSubmitOnCreateProduct = data => {
    const { price, cost_price } = data;

    const price_unformatted = price ? unformatMoney(data.price) : null;

    const cost_price_unformatted = cost_price
      ? unformatMoney(data.cost_price)
      : null;

    const formattedData = {
      main: {
        price: price_unformatted,
        cost_price: cost_price_unformatted,
      },
    };

    onSubmitStep(formattedData);

    onNext();
  };

  return (
    <WrapperFields
      onSubmit={beforeSubmitOnCreateProduct}
      disabledStep={disabledStep}
      onPreviousStep={onPrevious}
      edit={edit}
      isLastStep={isLastStep}
    >
      <FormGroup className="form-group__flex form-group__wrap">
        <Input
          label="Preço de venda"
          name="price"
          defaultValue={formatMoney(initialValue.price)}
          validate={[requiredValidation]}
          type="money"
          placeholder="R$ 0,00"
        />
      </FormGroup>

      <FormGroup className="form-group__flex form-group__wrap">
        <Input
          label="Preço de custo"
          name="cost_price"
          defaultValue={formatMoney(initialValue.cost_price)}
          type="money"
          placeholder="R$ 0,00"
        />
        <small>Essa informação é usada apenas para cálculo de margem.</small>
      </FormGroup>
    </WrapperFields>
  );
};

export default PriceForm;

PriceForm.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmitStep: PropTypes.func,
  disabledStep: PropTypes.bool,
  isLastStep: PropTypes.bool,
  initialValue: PropTypes.shape(),
};

PriceForm.defaultProps = {
  onNext: null,
  onNext: null,
  onPrevious: null,
  onSubmitStep: null,
  disabledStep: false,
  isLastStep: false,
  initialValue: {},
};
