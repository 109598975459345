const prefix = '@retailMedia/CHECKING_ACCOUNT'

export const CHECKING_ACCOUNT_LIST = `${prefix}/LIST`
export const CHECKING_ACCOUNT_LIST_SORT = `${prefix}/LIST_SORT`
export const CHECKING_ACCOUNT_LIST_SET_PAGE = `${prefix}/LIST_SET_PAGE`
export const CHECKING_ACCOUNT_LIST_PER_PAGE = `${prefix}/LIST_PER_PAGE`

export const types = {
  list: CHECKING_ACCOUNT_LIST,
  listSort: CHECKING_ACCOUNT_LIST_SORT,
  listSetPage: CHECKING_ACCOUNT_LIST_SET_PAGE,
  listPerPage: CHECKING_ACCOUNT_LIST_PER_PAGE
}
