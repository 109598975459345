import NameForm from './form-name';
import GeneralForm from './form-general';
import PriceForm from './form-price';
import CodesForm from './form-codes';
import StockForm from './form-stock';
import SizesForm from './form-sizes';

/**
 * Este arquivo reunúe os módulos dos formulários.
 * Cada componente exportado contém os campos dos forms e as funções específicas para cada form.
 * Os campos serão os mesmos no formulário de criação e edição.
 *
 * Cada módulo ficará dentro de um wrapper que mudará de acordo com o modo: criação ou edição.
 * Os wrappers (wrapper.js)  poderão ser:
 * - um form para cada módulo no caso de adição
 * - um fieldset no caso de edição. Nesse caso teremos um form no container envolvendo tudo.
 *
 * O módulos terão navegação e ação de submit final gerenciadas pelos containers: container e container-edit
 *
 */

const Forms = [
  {
    title: 'Novo produto',
    slug: 'NameForm',
    component: NameForm,
  },
  {
    title: 'Dados gerais',
    slug: 'GeneralForm',
    component: GeneralForm,
  },
  {
    title: 'Preço',
    slug: 'PriceForm',
    component: PriceForm,
  },
  {
    title: 'Códigos do produto',
    slug: 'CodesForm',
    component: CodesForm,
  },
  {
    title: 'Estoque',
    slug: 'StockForm',
    component: StockForm,
  },
  {
    title: 'Medidas',
    slug: 'SizesForm',
    component: SizesForm,
  },
];

export default Forms;
