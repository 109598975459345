import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.div`
  ${({ theme }) => css`
    --borderRadius: ${theme.border_radius};
    --background: var(--warning);

    --fontColor: ${theme.colors.neutral.high.pure};
    --fontWeight: ${theme.font.bolder};
  `}

  &[data-template='warning'] {
    --background: var(--warning);
  }

  &[data-template='neutral'] {
    --background: var(--gray);
  }

  &[data-template='success'] {
    --background: var(--success);
  }

  &[data-template='danger'] {
    --background: var(--danger);
  }

  &[data-template='info'] {
    --background: var(--info);
  }

  &[data-template='primary'] {
    --background: var(--primary);
  }

  &[data-template='secondary'] {
    --background: var(--secondary);
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;

  z-index: 20;
  overflow: hidden;

  max-height: 0;
  padding: 0 0.75rem;
  width: 100%;

  border-radius: var(--borderRadius);
  background: var(--background);

  color: var(--fontColor);
  line-height: 1.25;
  font-weight: var(--fontWeight);

  transition: all 0.2s ease-in-out;

  &[data-active='true'] {
    max-height: 7rem;
    padding: 0.5rem 0.75rem;

    @media ${device.laptop} {
      padding: 1rem;
      max-height: 4rem;
    }

    border-width: 1px;
    margin-bottom: 1.25rem;
  }
`
