import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import debounce from 'lodash/debounce'
import useSound from 'use-sound'

import beepSound from '~/assets/sound/beep.mp3'
import notificationsSound from '~/assets/sound/notification.mp3'
import Loading from '~/components/Loading'
import { formatSearchString, getUserId } from '~/helpers'
import {
  getChat,
  getChats,
  getMoreChats,
  setChatsLoading,
  setNotificationPlayed,
  setSelectedChat
} from '~/modules/chat/store/actions'
import { indexLocationMinified } from '~/pages/locations/actions'
import { useAppSelector } from '~/store/hooks'

import ChatItem from '../ChatItem'

import * as S from './styles'

const ChatsList = () => {
  const [internalLoading, setInternalLoading] = useState(false)
  const [page, setPage] = useState(1)

  const dispatch = useDispatch()

  const {
    chats,
    sortChatsBy,
    loadingChats,
    masterLoadingChats,
    shouldPlayNotifications,
    shouldPlayNewContactNotifications,
    selectedChat,
    currentPage,
    selectedStatus: status,
    pages,
    chatsLength,
    error
  } = useAppSelector(({ chats }) => chats)

  const shouldRefreshChats = false

  const {
    locations: filteredLocations,
    listLocationsMinifiedLoading,
    chatTags,
    chatBotStatus,
    query,
    filterChatAgent
  } = useAppSelector(({ filters }) => filters)

  const { listLocationsMinified } = useAppSelector(({ locations }) => locations)

  const filteredQuery = useMemo(() => query?.queryChat || null, [query])

  const queryStringLocation = useMemo(
    () =>
      formatSearchString({
        page: 0,
        quantity: 500
      }),
    []
  )

  useEffect(() => {
    if (!listLocationsMinified.data && !listLocationsMinifiedLoading) {
      dispatch(indexLocationMinified(queryStringLocation))
    }
  }, [
    dispatch,
    listLocationsMinified,
    listLocationsMinifiedLoading,
    queryStringLocation
  ])

  const filteredLocationsIds = useMemo(
    () => filteredLocations?.value || null,
    [filteredLocations]
  )

  const filteredChatTagsIds = useMemo(
    () => chatTags?.map(tag => tag.value),
    [chatTags]
  )

  const filteredchatBotStatusValue = useMemo(
    () => chatBotStatus?.value || null,
    [chatBotStatus]
  )

  // Só deve filtar por bot se for aba todas
  const filterByBotStatus = useMemo(() => {
    const isAble =
      `${filteredchatBotStatusValue}` !== 'null' && status === 'available'

    return isAble ? { botActive: `${filteredchatBotStatusValue}` } : {}
  }, [filteredchatBotStatusValue, status])

  const queryString = useMemo(
    () => ({
      status,
      locationId: filteredLocationsIds,
      query: filteredQuery,
      tags: filteredChatTagsIds,
      page: 1,
      quantity: status === 'finished' ? 100 : 500,
      count: true,
      sort_by: sortChatsBy,
      user_id: filterChatAgent?.value,
      ...{ ...filterByBotStatus }
    }),
    [
      status,
      filteredLocationsIds,
      filteredQuery,
      filteredChatTagsIds,
      sortChatsBy,
      filterChatAgent?.value,
      filterByBotStatus
    ]
  )

  const { id: chatId } = useParams()

  useEffect(() => {
    if (selectedChat?.chatId === chatId) {
      return
    }

    if (chatId && chats.length > 0) {
      const hasChatByURL = chats.find(chat => chat.chatId === chatId)
      if (hasChatByURL) {
        if (selectedChat?.chatId !== hasChatByURL?.chatId) {
          dispatch(setSelectedChat({ selectedChat: hasChatByURL }))
        }
      } else {
        console.log('O chat informado pela URL não foi encontrado')

        if (!selectedChat) {
          dispatch(getChat({ chatId: chatId }))
        }
      }
    }
  }, [chatId, chats, dispatch, selectedChat])

  /**
   * Handle filter chat by qyery
   */
  const filterChatsByTerm = useCallback(() => {
    dispatch(
      getChats({ queryString, onSuccess: () => setInternalLoading(false) })
    )
    setPage(1)
  }, [dispatch, queryString])

  /**
   * executeDebounceFunc()
   *
   * A função executeDebounceFunc serve para certificar que sempre estaremos
   * executando a versão atualizada da queryString que se mantém
   * atualizada através do useCallback.
   * */

  const executeDebounceFunc = func => func()

  /**
   * Handling a delay to not overlap calls
   * Executa a função após 1 segundo sem interação com o componente
   */
  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  useEffect(() => {
    setInternalLoading(true)
    dispatch(setChatsLoading({ value: true }))
  }, [dispatch, filteredQuery])

  const [playOnNew] = useSound(beepSound, { volume: 0.5 })

  useEffect(() => {
    if (shouldPlayNewContactNotifications) {
      playOnNew()

      dispatch(setNotificationPlayed())
    }
  }, [playOnNew, dispatch, shouldPlayNewContactNotifications])

  const [playOn] = useSound(notificationsSound, { volume: 0.5 })

  useEffect(() => {
    if (shouldPlayNotifications) {
      playOn()

      dispatch(setNotificationPlayed())
    }
  }, [playOn, dispatch, shouldPlayNotifications])

  const getListTimer = useRef(null)

  useEffect(() => {
    if (shouldRefreshChats) {
      dispatch(
        getChats({ queryString, onSuccess: () => setInternalLoading(false) })
      )
    } else {
      handleDebounce(() => filterChatsByTerm())
    }
  }, [
    dispatch,
    filterChatsByTerm,
    handleDebounce,
    queryString,
    shouldRefreshChats
  ])

  const userId = getUserId()

  useEffect(() => {
    if (
      status === 'waiting_contact' ||
      status === 'in_contact' ||
      status === 'available'
    ) {
      getListTimer.current = setInterval(async () => {
        dispatch(
          getChats({ queryString, onSuccess: () => setInternalLoading(false) })
        )
      }, 10000)
    }

    return () => {
      clearInterval(getListTimer.current)
    }
  }, [dispatch, queryString, selectedChat, status, userId])

  /**
   * Load more
   */

  const listRef = useRef(null)
  const loadRef = useRef(null)

  const isLastPage = useMemo(() => page >= pages, [page, pages])
  const handleLoadMore = useCallback(() => {
    const newPage = currentPage + 1

    setPage(newPage)

    dispatch(
      getMoreChats({
        queryString: { ...queryString, page: newPage },
        onSuccess: () => setInternalLoading(false)
      })
    )
  }, [dispatch, queryString, currentPage])

  const handleScroll = useCallback(() => {
    const position = loadRef?.current?.getBoundingClientRect().bottom
    const { innerHeight: windowHeight } = window
    const positionToLoad = position && position - windowHeight < 50

    if (!isLastPage && !loadingChats && !internalLoading && positionToLoad) {
      setInternalLoading(true)
      handleLoadMore()
    }
  }, [handleLoadMore, internalLoading, isLastPage, loadingChats])

  useEffect(() => {
    const ref = listRef.current

    if (listRef && !isLastPage) {
      ref.addEventListener('scroll', handleScroll, {
        passive: true
      })

      return () => {
        ref.removeEventListener('scroll', handleScroll)
      }
    }
  }, [listRef, handleScroll, isLastPage])

  return (
    <S.Container ref={listRef} isMasterLoading={masterLoadingChats}>
      {chats?.length > 0 &&
        chats.map(chat => <ChatItem data={chat} key={chat.chatId} />)}

      {(!chats?.length || !!error || chats?.length === 0) && (
        <div className="empty">Nenhuma conversa encontrada.</div>
      )}

      <S.LoadingPopUp
        isActive={loadingChats || internalLoading || masterLoadingChats}
      >
        <Loading status={loadingChats || internalLoading} alignCenter>
          Carregando conversas...
        </Loading>
      </S.LoadingPopUp>

      <button
        type="button"
        onClick={handleLoadMore}
        ref={loadRef}
        className="loadMoreMsg"
      >
        Carregar mais... | Página: {currentPage}/{pages} - Total: {chatsLength}{' '}
        / {chats.length}
      </button>
    </S.Container>
  )
}

export default ChatsList
