import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const WrapperList = styled.div`
  ${() => css`
    border-radius: 0.5rem;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    > li {
      display: none !important;
    }
  `}
`;

const containerModifiers = {
  hasOrder: ({ theme }) => css`
    min-width: 360px;
    background-color: ${theme.colors.primaryLighten60};
  `,
  isEmpty: ({ theme }) => css`
    min-width: auto;
    background-color: ${theme.colors.primaryLighten60};
    opacity: 0.5;

    ${WrapperList} {
      max-width: 0;
      opacity: 0;
      transition: 0.1s ease-in-out all;
    }
  `,
  isDraggingOver: ({ theme }) => css`
    background-color: ${theme.colors.successLighten30};
  `,
  isDraggingFrom: ({ theme }) => css`
    background-color: ${theme.colors.secondaryLighten30};
  `,
};

export const Container = styled.div`
  ${({ theme, isEmpty, isDraggingOver, isDraggingFrom }) => css`
    display: flex;
    flex-direction: column;

    position: relative;
    max-width: 90vw;
    flex-shrink: 1;

    border-radius: 0.25rem;

    transition: 0.1s ease-in-out all;

    ${!isEmpty && containerModifiers.hasOrder({ theme })}
    ${isEmpty && containerModifiers.isEmpty({ theme })}
    ${isDraggingOver && containerModifiers.isDraggingOver({ theme })}
    ${isDraggingFrom && containerModifiers.isDraggingFrom({ theme })}
  `}
`;

/**
 * Header
 */

const headerModifier = {
  default: theme => css`
    --baseColor: ${theme.colors.tertiary};
    --textColor: ${theme.colors.white};
  `,
  info: theme => css`
    --baseColor: ${theme.colors.info};
    --textColor: ${theme.colors.white};
  `,
  danger: theme => css`
    --baseColor: ${theme.colors.danger};
    --textColor: ${theme.colors.white};
  `,
  warning: theme => css`
    --baseColor: ${theme.colors.warning};
    --textColor: ${theme.colors.white};
  `,
  success: theme => css`
    --baseColor: ${theme.colors.success};
    --textColor: ${theme.colors.white};
  `,
};

export const Header = styled.header`
  ${({ theme, template = 'default' }) => css`
    --baseColor: ${theme.colors.tertiary};
    --textColor: ${theme.colors.white};

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacings.xsmall};
    border-top: 4px solid var(--baseColor);
    border-radius: 0.25rem;

    > .title {
      margin-right: 0.25rem;
      font-weight: ${theme.font.bolder};
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.dark};
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    ${headerModifier[template] && headerModifier[template](theme)}

    > .total {
      display: flex;
      align-items: center;

      padding: 0.25rem;
      border-radius: 0.25rem;

      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.small};
      line-height: 1;

      background-color: var(--baseColor);
      color: var(--textColor);
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    display: grid;
    gap: 1rem;
    margin: 0;
    padding: ${theme.spacings.xsmall};
    list-style: none;

    .list__message {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;

      background-color: ${theme.colors.light};
      box-shadow: 1px 1px 1rem -0.25rem rgba(100, 100, 100, 0.25);
      border-radius: 0.5rem;

      color: ${theme.colors.dark};
      white-space: nowrap;
    }
  `}
`;

const placeHolderModifiers = {
  isDraggingOver: ({ theme }) => css`
    background-color: ${theme.colors.successLighten30};

    .icon,
    .text {
      color: ${theme.colors.white};
      .title {
        color: ${theme.colors.white};
      }
    }
    &:after {
      border-color: ${theme.colors.successShade20};
      background-color: ${theme.colors.successShade20};
    }
  `,
};

export const Placeholder = styled.div`
  ${({ theme, isDraggingOver }) => css`
    position: absolute;
    bottom: 0;
    left: 0;

    z-index: 10;

    height: calc(100% - 3rem);
    width: 100%;

    background-color: ${transparentize(0.1, theme.colors.primaryLighten60)};
    border-radius: 0.25rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;

    transition: 0.1s ease-in-out all;

    .icon {
      font-size: 2rem;
      margin-bottom: 1rem;
      color: ${theme.colors.hover};
      text-align: center;
      z-index: 10;
    }

    .text {
      font-size: 1rem;
      z-index: 10;

      color: ${theme.colors.hover};
      text-align: center;

      .title {
        font-size: 1.25rem;
        font-weight: bold;
        color: ${theme.colors.hover};
        display: block;
        text-align: center;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      height: calc(100% - 2rem);
      width: calc(100% - 2rem);
      border: dashed 3px ${theme.colors.hover};
      border-radius: 2rem;
      border-collapse: separate;
      border-spacing: 15px 50px;

      transition: all 0.1s ease-in-out;
    }

    ${isDraggingOver && placeHolderModifiers.isDraggingOver({ theme })}
  `}
`;
