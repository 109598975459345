import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.header`
  ${({ theme }) => css`
    padding: 0.25rem 0.5rem;

    border-bottom: 1px solid ${theme.inative};

    .customer-tags {
      align-items: center;
      display: inline-flex;

      &__list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -${theme.spacings.tiny};
      }

      svg {
        margin-left: ${theme.spacings.xtiny};
        margin-right: ${theme.spacings.tiny};
        flex-shrink: 0;
      }
    }

    .user__infos {
      display: flex;
      align-items: center;
      max-width: 60%;
      margin-right: 0.5rem;

      .avatar {
        margin-right: 8px;
      }

      .infos {
        max-width: 100%;
        /* overflow: hidden; */

        .name {
          overflow: hidden;
          white-space: nowrap;
          justify-content: flex-start;

          max-width: 100%;
          height: 1rem;

          font-size: 0.875rem;
          font-weight: 600;
          text-align: left;
          text-overflow: ellipsis;

          color: ${theme.dark};

          .text {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .tagsRow {
          display: flex;
          line-height: 1;
          margin-top: 0.125rem;
        }
      }
    }
  `}
`

type UserInfoContainerProps = {
  isCollapse: boolean
}

export const UserInfoContainer = styled.div<UserInfoContainerProps>`
  ${({ isCollapse }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--heightChatHeader);

    .user__infos {
      position: relative;

      .collapseButton {
        display: none;
        position: absolute;
        right: 0.25rem;
        top: 50%;
        transform: translateY(-50%);

        button {
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
          min-width: unset;
        }
      }
    }

    @media ${device.tablet_max} {
      display: flex;
      flex-direction: ${isCollapse ? 'row' : 'column'};
      align-items: flex-start;
      height: auto;
      width: 100%;

      .user__infos {
        padding-right: 3rem;
        max-width: unset;
        width: 100%;
        margin-right: 0;

        .collapseButton {
          display: flex;
        }
      }
    }
  `}
`

export const CustomerTag = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    padding: ${theme.spacings.tiny};

    margin: 0 ${theme.spacings.xtiny} ${theme.spacings.tiny} 0;

    background-color: ${theme.colors.light};
    border-radius: ${theme.border.radius.medium};
    border: 1px solid ${theme.colors.outlineGray};
    font-size: ${theme.font.sizes.xsmall};
    line-height: 1;
  `}
`

export const WrapperButtonHistory = styled.div`
  ${({ theme }) => css`
    &::after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 1rem;
      margin: 0px 5px;
      vertical-align: middle;
      background: ${theme.colors.inative};
    }
  `}
`

type AsideProps = {
  isCollapse: boolean
}
export const Aside = styled.aside<AsideProps>`
  ${({ theme, isCollapse }) => css`
    display: ${isCollapse ? 'none' : 'flex'};
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;

    @media ${device.tablet_max} {
      align-items: flex-start;
      width: 100%;
    }

    .row {
      margin: 0;

      &.customerInfo {
        margin: 0.125rem 0 0;
      }

      &.tags {
        align-items: center;
        justify-content: flex-end;
        flex-wrap: nowrap;

        > .TagLabel {
          margin: 0.125rem 0.125rem 0.125rem 0;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 24px;
          height: 24px;

          border: 1px solid ${theme.colors.tertiary};
          border-radius: 2rem;
          font-size: 0.75rem;
          color: ${theme.dark};

          .text {
            padding: 0 0.5rem;
          }

          svg {
            font-size: 0.75rem;
            color: ${theme.dark};
          }
        }
      }
    }
  `}
`
