import { history } from '~/helpers';
import { flyersService } from '~/modules/flyers/services/';

import { modelFlyerCreate } from '../../models/flyers/create';

// import { modelPromotionCreate } from '../../models/create';
import * as types from './constants';

/**
 *
 * @exports productsList Busca todos as encartes filtradas
 * @param {number} page Gerencia a paginação
 *
 */
export const listFlyers = ({ page = 1, quantity, query, location_id }) => {
  const queryString = {
    page,
    quantity,
    location_id,
    query,
  };

  return {
    type: types.FLYERS_LIST,
    payload: flyersService.list(queryString),
    page,
  };
};

/**
 * Action para lidar com a mudança de quantidade de itens que serão exibidos em peagina
 * @param {number} value Valor de itens que serão exibidos por página
 * @returns
 */

export const handleFlyersPerPage = value => ({
  type: types.FLYERS_LIST_PER_PAGE,
  payload: value,
});

/**
 * Action para lidar com a requisição de dados de um encarte
 * @param {string} props.id
 * @returns
 */
export const getFlyer = ({ id }) => ({
  type: types.FLYER_VIEW,
  payload: flyersService.get(id),
  onError: error => {
    console.error(error);
    history.push(`/promotional-flyers/list/`);
  },
});

/**
 *
 * @exports createPromotion Cria uma encarte
 * @param {object} formData Objeto com valores recebidos pelo formulário
 * @param {array} offerIds Array com ids dos produtos selecionados
 *
 */
export const createFlyer = ({ formData }) => {
  const body = modelFlyerCreate({ formData });

  return {
    type: types.FLYERS_CREATE,
    payload: flyersService.create(body),
    successFeedbackMsg: 'Encarte criado com sucesso.',
    onSuccess: response => {
      history.push(`/promotional-flyers/view/${response.data.id}`);
    },
  };
};

/**
 *
 * @exports updatePromotion Busca todos as encartes filtradas
 * @param {object} formData Objeto com valores recebidos pelo formulário
 * @param {array} offerIds Array com ids dos produtos selecionados
 * @param {string} successFeedbackMsg Mensagem para ser exibida no toast de sucesso
 * @param {string} redirectOnSuccess URL alternativa para redirecionamento após sucesso
 *
 */
export const updateFlyer = ({
  formData,
  id,
  successFeedbackMsg,
  redirectOnSuccess,
  useModel = true,
  onSuccess,
}) => {
  const body = useModel ? modelFlyerCreate({ formData }) : formData;

  return {
    type: types.FLYERS_UPDATE,
    payload: flyersService.update({ body, id }),
    successFeedbackMsg: successFeedbackMsg || 'Encarte atualizado com sucesso.',
    onSuccess: () => {
      onSuccess
        ? onSuccess()
        : history.push(redirectOnSuccess || `/promotional-flyers/view/${id}`);
    },
  };
};

/**
 * Action para lidar com a requisição de dados de desempenho de uma encarte
 * @param {string} props.id
 * @returns
 */
// export const getPromotionMetrics = ({ id }) => {
//   return {
//     type: types.PROMOTION_METRICS_VIEW,
//     payload: promotionMetricsService.get(id),
//   };
// };

/**
 * Action para lidar com a exclusão de uma encarte
 * @param {string} props.id
 * @returns
 */
export const deleteFlyer = ({ id, onSuccess }) => {
  return {
    type: types.FLYERS_DELETE,
    payload: flyersService.remove(id),
    successFeedbackMsg: 'Encarte deletado com sucesso.',
    onSuccess: () =>
      onSuccess ? onSuccess() : history.push(`/promotional-flyers/list/`),
  };
};

/**
 * Limpa store
 */

export const clearFlyerStore = () => ({
  type: types.CLEAR_FLYER_STORE,
});
