export const TEMPLATE_CREATE = '@messagingCampaign/TEMPLATE_CREATE';
export const TEMPLATE_UPDATE = '@messagingCampaign/TEMPLATE_UPDATE';

export const TEMPLATE_GET = '@messagingCampaign/TEMPLATE_GET';

export const TEMPLATE_REMOVE = '@messagingCampaign/TEMPLATE_REMOVE';

export const TEMPLATES_LIST = '@messagingCampaign/TEMPLATES_LIST';
export const TEMPLATES_LIST_SET_PAGE =
  '@messagingCampaign/TEMPLATES_LIST_SET_PAGE';
export const TEMPLATES_LIST_PER_PAGE =
  '@messagingCampaign/TEMPLATES_LIST_PER_PAGE';

export const TEMPLATE_REDUX_QUERY = '@messagingCampaign/TEMPLATE_REDUX_QUERY';

export const types = {
  list: TEMPLATES_LIST,
  page: TEMPLATES_LIST_SET_PAGE,
  perPage: TEMPLATES_LIST_PER_PAGE,
  get: TEMPLATE_GET,
  create: TEMPLATE_CREATE,
  update: TEMPLATE_UPDATE,
  delete: TEMPLATE_REMOVE,
};
