import React, { useMemo } from 'react'
import { FiCreditCard } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { ButtonCopyToClipboard } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { Header, WrapperShadow } from '~/components/WrapperShadow'
import useCheckout from '~/pages/orders/_hooks/useCheckout'

const PanelLinkCheckout = () => {
  const { orderData } = useSelector(state => state.order)

  const { urlCheckout } = useCheckout({ order: orderData })

  const content = useMemo(
    () => ({
      url: urlCheckout,
      textBox: 'Link para o checkout:',
      titleLink: 'Link para o checkout deste pedido',
      textToCopy: 'Copiar link',
      textCopied: 'Link copiado'
    }),
    [urlCheckout]
  )

  return urlCheckout ? (
    <WrapperShadow>
      <Header>
        <div className="inlineFeatures">
          <span className="box equalPadding">
            <div className="icon">
              <FiCreditCard />
            </div>
            <div className="text withIcon">
              <strong>{content.textBox}</strong>{' '}
              <a
                href={content.url}
                title={content.titleLink}
                target="_blank"
                rel="noreferrer"
                className="textOverflow"
              >
                {content.url}
              </a>
            </div>
          </span>
        </div>

        <aside className="featured">
          <ButtonCopyToClipboard
            toCopy={content.url}
            textBefore={content.textToCopy}
            textAfter={content.textCopied}
            template="light"
            size="small"
            customWidth="auto"
          />
        </aside>
      </Header>
    </WrapperShadow>
  ) : (
    <Loading status={!urlCheckout}>Gerando link de checkout...</Loading>
  )
}

export default PanelLinkCheckout
