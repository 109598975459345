import { produce } from 'immer'

import reducerCases from './reducerCases'

const INITIAL_STATE = {
  customer: null,
  getCustomersLoading: false,
  error: null,

  isEditable: true,
  listCustomers: [],
  listCustomersPage: 1,
  listCustomersPerpage: 50,
  listCustomersLoading: false,

  saveCustomersLoading: false,

  listCustomerByTypeLoading: false,
  listCustomerByType: [],

  customerPerDayInfo: {},
  customerPerDayInfoDates: [],
  chartCustomerPerDayLoading: false,

  customerTopChannelsLoading: false,
  customerPerTimeInfo: {},
  chartCustomerPerTimeLoading: false,

  customerTopChannelsInfoList: [],
  customerTopChannelsChartData: {
    labels: [],
    datasets: []
  },

  customerPerGmvLoading: false,
  customerPerGmvChartData: {
    labels: [],
    datasets: []
  },
  customerPerGmvInfoList: []
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const reducer = Object.keys(reducerCases).filter(
      item => item === action.type
    )

    if (reducer?.length) {
      reducerCases[reducer]({ state, draft, action })
    }
  })
