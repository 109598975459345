const prefix = '@retailMedia/product'

export const PRODUCTS_LIST = `${prefix}/LIST`
export const PRODUCTS_LIST_SET_PAGE = `${prefix}/LIST_SET_PAGE`
export const PRODUCTS_LIST_PER_PAGE = `${prefix}/LIST_PER_PAGE`

export const PRODUCT_REDUX_QUERY = `${prefix}/REDUX_QUERY`

export const types = {
  list: PRODUCTS_LIST,
  page: PRODUCTS_LIST_SET_PAGE,
  perPage: PRODUCTS_LIST_PER_PAGE
}
