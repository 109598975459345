import React from 'react';

import Panel from '~/components/Panel';
import Table from '~/components/Table';

import { formatMoney } from '~/helpers';

const schema = {
  id: {
    render: obj => obj.id,
    title: '#',
  },
  name: {
    render: obj => obj.productName,
    title: 'Nome',
  },
  value: {
    render: obj => formatMoney(obj.incomeValue),
    title: 'Valor',
  },
  quantity: {
    render: obj => obj.quantity,
    title: 'Quantidade',
  },
};

const DataProducts = ({ loading, data = {} }) => (
  <Panel title="Dados produto" noPaddingBody isLoading={loading}>
    <Table schema={schema} data={data.info} />
  </Panel>
);

export default DataProducts;
