/* eslint-disable indent */
import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import Alert from '~/components/Alert'
import { Button } from '~/components/Buttons/'
import { getValidationErrors, unformatMoney } from '~/helpers'
import { createLocation, updateLocation } from '~/pages/locations/actions'

import ActiveFields from './Active'
import AddressFields from './Address'
import CatalogFields from './Catalog'
import ContactFields from './Contact'
import DeliveryTypes from './DeliveryTypes'
import GeneralFields from './General'
import HoursFields from './Hours'
import MapLocation from './MapLocation'
import SalesFields from './Sales'
import ShipmentsFields from './Shipments'
import ShoppingCartFields from './ShoppingCart'
import TypeFields from './Type'

/**
 *
 * @example
 * // New
 * <FormLocation />
 *
 * // Edit
 * <FormLocation edit initialData={data} />
 *
 * @param {object} initialData Objeto recebido para edição
 * @param {object} edit Recebe se está em modo de edição
 *
 */

const FormLocation = ({ initialData, edit = false, activeFieldsRef }) => {
  const [type, setStype] = useState(null)

  const formRef = useRef(null)

  const { loadingUpdate } = useSelector(state => state.locations)

  const dispatch = useDispatch()

  const textButton = useMemo(() => (edit ? 'Salvar' : 'Adicionar'), [edit])

  const schema = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    type: Yup.string().required('O tipo é obrigatório'),
    address_street: Yup.string().required('A rua é obrigatória'),
    address_number: Yup.string().required('O número é obrigatório'),
    address_neighborhood: Yup.string().required('O bairro é obrigatório'),
    address_postal_code: Yup.string().required('O CEP é obrigatório'),
    address_city: Yup.string().required('A cidade é obrigatória'),
    address_state: Yup.string().required('O estado é obrigatório'),
    main_phone: Yup.string().nullable().min(10, 'No mínimo 10 números')
  })

  const handleSubmit = useCallback(
    async data => {
      try {
        data.active = data.active === 'true'

        const zeroToNull = value =>
          unformatMoney(value) > 0 ? unformatMoney(value) : null

        // TODO Refactor
        if (data.type === 'customer_service_center') {
          const requestData = edit
            ? {
                id: initialData.id,
                ...data
              }
            : { ...data }

          const action = edit
            ? updateLocation(requestData)
            : createLocation(requestData)

          dispatch(action)

          return
        }

        const local = {
          ...data,
          latitude: Number(data.latitude),
          longitude: Number(data.longitude),
          main_phone: data.main_phone.numAsString || null,
          min_order_value: zeroToNull(data.min_order_value.value),
          min_free_shipping: zeroToNull(data.min_free_shipping.value),
          shipping_cost: zeroToNull(data.shipping_cost.value)
        }

        // console.log(local);
        // return;

        formRef.current?.setErrors({})

        await schema.validate(local, {
          abortEarly: false
        })

        const deliveryTypes = Object.keys(local.deliveryTypes).map(item => ({
          ...local.deliveryTypes[`${item}`],
          delivery_type_id: item,
          available_time_start: local.hour_start,
          available_time_end: local.hour_end
        }))

        const requestData = edit
          ? {
              id: initialData.id,
              ...local,
              deliveryTypes
            }
          : { ...local, deliveryTypes }

        const action = edit
          ? updateLocation(requestData)
          : createLocation(requestData)

        dispatch(action)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRef.current?.setErrors(errors)
        }
      }
    },
    [dispatch, edit, initialData, schema]
  )

  const showFields = useMemo(() => type !== 'customer_service_center', [type])

  useEffect(() => {
    if (initialData.type) {
      setStype(initialData.type)
    }
  }, [initialData])

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={initialData}
        className="longForm"
      >
        <ActiveFields activeFieldsRef={activeFieldsRef} />

        <TypeFields onChangeType={setStype} />

        {!!type && (
          <>
            <GeneralFields activeType={type} />

            {showFields && <SalesFields />}

            {showFields && <ContactFields />}

            <HoursFields />

            {showFields && (
              <>
                <CatalogFields />

                <ShipmentsFields />

                <ShoppingCartFields />

                <AddressFields formRef={formRef} />

                <DeliveryTypes initialData={initialData} />

                <MapLocation />

                <Alert>
                  Cuidado ao desativar um método de entrega. Seu raio de atuação
                  e regiões de entrega serão desativados também. Para desativar
                  apenas o raio de atuação, defina o valor 0 (zero) km. Para
                  desativar uma região de entrega, acessa a página de regiões de
                  entrega e exclua a região.
                </Alert>
              </>
            )}
          </>
        )}
        <div className="form-default__buttons">
          <Button
            type="submit"
            text={textButton}
            loading={loadingUpdate}
            size="large"
            template="success"
          />
        </div>
      </Form>
    </>
  )
}

export default FormLocation

FormLocation.propTypes = {
  initialData: PropTypes.shape(),
  edit: PropTypes.bool,
  activeFieldsRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()])
    .isRequired
}

FormLocation.defaultProps = {
  initialData: {
    id: null
  },
  edit: false
}
