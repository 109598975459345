import { t } from 'i18next'

import { availableAdTypes } from '~/modules/retailMedia/config/adTypes'
import { campaign } from '~/modules/retailMedia/i18n/pt-br'

import { CAPMPAIGNS_FILTERS_KEYS } from '../constants'

export const adTypeFilter = {
  keyState: CAPMPAIGNS_FILTERS_KEYS.adType,
  placeholder: t('rm:filterByCampaignType'),
  options: availableAdTypes.map(type => ({
    label: campaign.ad_types[type],
    value: type
  }))
} as GenericFilterProps
