import styled, { css } from 'styled-components'

const containerModifier = {
  default: ({ theme }) => css`
    --main-color: ${theme.colors.light};
  `,
  success: ({ theme }) => css`
    --main-color: ${theme.colors.success};
  `,
  success_alert: ({ theme }) => css`
    --main-color: ${theme.colors.successAlert};
  `,
  warning: ({ theme }) => css`
    --main-color: ${theme.colors.warning};
  `,
  danger: ({ theme }) => css`
    --main-color: ${theme.colors.danger};
  `,
  info: ({ theme }) => css`
    --main-color: ${theme.colors.info};
  `
}

export const Container = styled.div<ContainerStatusStylesProps>`
  ${({ type, template, onlyBullet = false, theme }) => css`
    display: ${onlyBullet ? 'inline-flex' : 'flex'};
    align-items: center;

    line-height: 1;

    color: ${theme.colors.dark};

    ${containerModifier.default({ theme })}

    ${type === 'success' && containerModifier.success({ theme })}
    ${type === 'success_alert' && containerModifier.success_alert({ theme })}
    ${type === 'warning' && containerModifier.warning({ theme })}
    ${type === 'danger' && containerModifier.danger({ theme })}
    ${type === 'info' && containerModifier.info({ theme })}

    ${type === 'order_placed' && containerModifier.success({ theme })}
    ${type === 'confirmed_order' && containerModifier.success({ theme })}
    ${type === 'waiting_payment' && containerModifier.warning({ theme })}
    ${type === 'waiting_delivery' && containerModifier.warning({ theme })}
    ${type === 'waiting_picking' && containerModifier.warning({ theme })}
    ${type === 'in_picking' && containerModifier.warning({ theme })}
    ${type === 'in_delivery' && containerModifier.warning({ theme })}
    ${type === 'order_delivered' && containerModifier.success({ theme })}
    ${type === 'order_canceled' && containerModifier.danger({ theme })}

    ${template && containerModifier?.[template]({ theme })}

    &:before {
      content: '';
      display: inline-flex;
      flex-shrink: 0;

      height: 0.5rem;
      width: 0.5rem;

      margin-right: 0.5em;

      border-radius: 50%;
      background: var(--main-color);
    }
  `}
`
