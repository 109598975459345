import React, { useCallback, useMemo, useRef } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { setChatMenuOpen } from '~/modules/chat/store/actions'

import { Container } from './styles'

const ChatItemMenu = ({ chatId, sessionId }) => {
  const itemRef = useRef(null)

  const dispatch = useDispatch()

  const { chatMenuOpen } = useSelector(({ chats }) => chats)

  const chatIdMenuOpen = useMemo(
    () => chatMenuOpen?.chatId || null,
    [chatMenuOpen]
  )

  const handleMenu = useCallback(
    e => {
      e.preventDefault()

      const positionParent = {
        left: itemRef?.current.getBoundingClientRect().left,
        top: itemRef?.current.getBoundingClientRect().top
      }

      dispatch(setChatMenuOpen({ chatId, sessionId, positionParent }))
    },
    [dispatch, chatId, sessionId]
  )

  const disabledButton = useMemo(
    () => chatIdMenuOpen === chatId,
    [chatId, chatIdMenuOpen]
  )

  return (
    <Container ref={itemRef}>
      <button type="button" onClick={handleMenu} disabled={disabledButton}>
        <FiChevronDown />
      </button>
    </Container>
  )
}

export default ChatItemMenu

ChatItemMenu.propTypes = {
  chatId: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired
}
