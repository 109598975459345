/* eslint-disable no-console */
/**
 * **Lidando com progresso de upload de um arquivo com o Axios**
 *
 * Retorna um objeto com uma `option` para ser enviada no campo `options` de uma requisição Axios.
 * A cada passo no progresso do upload, a função onChange será disparada.
 *
 * @param {function} {onChange} Função chamada com o valor do progresso
 * @param {boolean} {debug} Chave para ligar o modo debug
 *
 * @returns AxiosOptions: { onUploadProgress }
 *
 * @example handleUploadProgressAxios({onChange: setUploadProgress})
 */

export const handleUploadProgressAxios = ({ onChange, debug = false }) => {
  const options = {
    onUploadProgress: progressEvent => {
      const progress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );

      onChange(progress);

      if (debug) {
        console.warn(`O arquivo está ${progress}% carregado... `);
      }
    },
  };

  return options;
};
