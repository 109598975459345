import { formatIndustryReportCampaign } from '~/modules/retailMedia/dtos/industry/campaign/report'

import { types } from '../constants'

const parentKey = 'report'
const key = 'get'
const reduxKey = types.get

const index = {
  pending: `${reduxKey}_PENDING`,
  fulfilled: `${reduxKey}_FULFILLED`,
  rejected: `${reduxKey}_REJECTED`
}

export const get = {
  [index.pending]: ({ draft }: ActionData) => {
    draft[parentKey][key].loading = true
    draft[parentKey][key].error = null
  },
  [index.fulfilled]: ({ draft, action }: GetReportCampaignsFulfilled) => {
    draft[parentKey][key].data = formatIndustryReportCampaign(action.payload)
    draft[parentKey][key].loading = false
    draft[parentKey][key].error = null
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft[parentKey][key].loading = false
    draft[parentKey][key].error = action.payload
  }
}
