import styled from 'styled-components'

export const MapContainer = styled.div`
  height: calc(100vh - 60px);
  flex: 1;

  .leaflet-draw {
    display: none;
  }

  .region_shadow {
    outline: 0;
    &:hover {
      fill-opacity: 0.4;
    }
  }
`
