import React from 'react';

import PropTypes from 'prop-types';

import AlertInfo from '~/components/AlertInfo';
import Panel from '~/components/Panel';
import { Badge } from '~/components/UI/Badge';
import { Label, Radio } from '~/components/unform';

import CouponCode from '../FormGroups/CouponCode';

import * as S from './styles';

const PromotionTrigger = ({ isEdit, onChange, initialData }) => {
  const optionsTypes = [
    {
      id: `promotion_trigger_auto`,
      value: 'auto',
      label: 'Automático',
      disabled: isEdit,
    },
    {
      id: `promotion_trigger_coupon`,
      value: 'coupon',
      label: 'Cupom de desconto',
      disabled: isEdit,
      activeChildren: <CouponCode initialData={initialData} />,
    },
  ];

  const sideLabel =
    initialData.promotion_trigger === 'coupon'
      ? optionsTypes[1].label
      : optionsTypes[0].label;

  return (
    <>
      <Panel
        title="Regras de ativação"
        isCollapsible
        initialCollapsibleState={!isEdit}
        headerSideContent={<Badge>{sideLabel}</Badge>}
      >
        <S.Group>
          <Label
            htmlFor="promotion_trigger"
            isRequired
            text="Escolha como o cliente ativará esta promoção."
          />

          <Radio
            options={optionsTypes}
            name="promotion_trigger"
            onChange={onChange}
            required
          />
        </S.Group>

        {isEdit && (
          <AlertInfo text="A regra de ativação da promoção não pode ser alterada. Para alterar, duplique a promoção e cancele a atual." />
        )}
      </Panel>
    </>
  );
};

export default PromotionTrigger;

PromotionTrigger.propTypes = {
  isEdit: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    promotion_trigger: PropTypes.string,
  }).isRequired,
};

PromotionTrigger.defaultProps = {
  isEdit: false,
};
