import { Scope } from '@unform/core'

import { Badge } from '~/components/UI/Badge'
import { FormGroup, Label, Radio } from '~/components/unform'
import i18n from '~/i18n'

const BudgetTypeScope: React.FC = () => {
  const optionsTypes: RadioOptionProps[] = [
    {
      id: `budget_type_fast_as_possible`,
      value: 'fast_as_possible',
      label: (
        <div className="d-flex align-items-center gap-2">
          <strong>
            {i18n.t('rm:settings.budget_type.values.fast_as_possible.short')}
          </strong>{' '}
          <Badge template="success" size="small">
            {i18n.t('common:words.default')}
          </Badge>
        </div>
      ),
      description: i18n.t(
        'rm:settings.budget_type.values.fast_as_possible.description'
      )
    },
    {
      id: `budget_type_evenly`,
      value: 'evenly',
      label: i18n.t('rm:settings.budget_type.values.evenly.short'),
      description: i18n.t('rm:settings.budget_type.values.evenly.description')
    }
  ]

  return (
    <Scope path="settings">
      <FormGroup>
        <Label
          text={i18n.t('rm:settings.budget_type.title')}
          helperText={i18n.t('rm:settings.budget_type.create.description')}
        />

        <Radio options={optionsTypes} name="budget_type" required />
      </FormGroup>
    </Scope>
  )
}

export default BudgetTypeScope
