/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';

import EmptyPage from '~/components/empty-page';
import Loading from '~/components/Loading';
import Panel from '~/components/Panel';
import Table from '~/components/Table';
import {
  formatDateExhibitionShort,
  formatSearchString,
  queryDatesDatepicker,
} from '~/helpers';
import { useAlert } from '~/hooks/useAlert';
import useFetchSWR from '~/hooks/useFetchSWR';
import { getOrderPaymentsByLocationServiceURL } from '~/services/financial-service';

import { schema } from './table-schema';

const PaymentsTable = () => {
  const [loading, setLoading] = useState(false);

  const { startDate, endDate } = useSelector(state => state.datepickerHeader);
  const {
    locations: filteredLocations,
    channel: filteredChannel,
    paymentMethod
  } = useSelector(state => state.filters);


  const filteredLocationsIds = useMemo(() => filteredLocations?.value || null, [
    filteredLocations,
  ]);

  const filteredChannelId = useMemo(() => filteredChannel?.value || null, [
    filteredChannel,
  ]);

  const filteredPaymentMethod = useMemo(() => paymentMethod?.value || null, [
    paymentMethod,
  ]);

  /**
   * Gerenciando o carregamento da listagem de pagamentos
   */

  const queryString = useMemo(
    () =>
      formatSearchString({
        location_id: [filteredLocationsIds],
        channel_id: filteredChannelId,
        payment_type: filteredPaymentMethod,
        ...queryDatesDatepicker(startDate, endDate),
      }),
    [filteredLocationsIds, filteredChannelId, filteredPaymentMethod, startDate, endDate],
  );

  const getOrderPayments = useMemo(
    () => getOrderPaymentsByLocationServiceURL(queryString),
    [queryString],
  );

  const { data: dataSWR, error: errorSWR } = useFetchSWR({
    url: getOrderPayments,
    // refreshInterval: 10000,
  });
  const total = useMemo(() => dataSWR?.data?.length, [dataSWR]);

  // Ouvindo se existe alteração no filtro locations
  useEffect(() => setLoading(true), [filteredLocations]);

  useEffect(() => setLoading(!dataSWR), [dataSWR]);

  /**
   * Gerenciando erros
   */

  const [errorOnPage, setErrorOnPage] = useState(null);
  const [AlertOnError] = useAlert(errorOnPage);

  useEffect(
    () => setErrorOnPage(errorSWR ? 'Houve um erro com a conexão.' : null),
    [errorSWR],
  );

  const formattedData = useMemo(
    () =>
      dataSWR?.data.map(item => ({
        channel: item?.channelId,
        orderId: item.orderId,
        location: {
          name: item.location.name,
        },
        orderPayment: {
          value: item.orderPayment.value,
          method: item['OrderPayments.method'],
          methodInfo: item.orderPayment.methodInfo,
          authorizationId: item.orderPayment.authorizationId,
          nsu: item.orderPayment.nsu,
          cardNumber: item.orderPayment.cardNumber,
          cardIssuer: item.orderPayment.cardIssuer,
          createdAt: `${formatDateExhibitionShort(
            moment(item.orderPayment.createdDate, 'DD/MM/YYYY'),
          )} - ${item.orderPayment.createdHour}`,
        },
      })),
    [dataSWR],
  );

  return (
    <>
      <Loading status={loading}>Carregando relatório de pagamentos...</Loading>

      <AlertOnError />

      {!loading && formattedData && total === 0 && (
        <EmptyPage
          title="Nenhum pagamento encontrado"
          subtitle="Não encontramos pagamento com os filtros aplicados."
          titleButton="Limpar filtros"
          svgPath="photos/orders-empty"
        />
      )}

      {!loading && formattedData && !!total && (
        <Panel
          title={total && `Total: ${total}`}
          noPaddingBody
          isLoading={loading}
        >
          {formattedData && <Table schema={schema} data={formattedData} />}
        </Panel>
      )}
    </>
  );
};

export default PaymentsTable;
