import styled, { css } from 'styled-components';

const containerDaysModifiers = {
  weekdays: () => css`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: flex-start;
    gap: 0.5rem;
    height: 100%;
    width: 100%;
  `,
};

export const ContainerDays = styled.div`
  ${({ template }) => css`
    padding: 0;

    ${template === 'weekdays' && containerDaysModifiers.weekdays()}
  `}
`;
