export const paymentMethodsLabels = {
  online: 'Online',
  food_voucher: 'Vale-alimentação',
  in_person: 'Presencial',
  credit_card: 'Cartão de crédito',
  debit_card: 'Cartão de débito',
  cash: 'Dinheiro',
  no_method_chosen: 'Método pendente'
}

export const cardIssuerLabels = {
  alelo: 'Alelo',
  vr: 'VR',
  Ticket: 'Ticket',
  sodexo: 'Sodexo',
  mastercard: 'Mastercard',
  Master: 'Mastercard',
  visa: 'Visa',
  elo: 'Elo',
  amex: 'American Express'
}

export const paymentStatusLabels = {
  waiting_payment: { full: 'Pagamento pendente', short: 'Pendente' },
  payment_authorized: { full: 'Pagamento autorizado', short: 'Autorizado' },
  payment_approved: { full: 'Pagamento aprovado', short: 'Aprovado' },
  payment_refused: { full: 'Pagamento recusado', short: 'Recusado' },
  waiting_refund: { full: 'Aguardando estorno', short: 'Aguard. estorno' },
  payment_refunded: { full: 'Pagamento cancelado', short: 'Cancelado' },
  payment_partial_refunded: {
    full: 'Parcialmente estornado',
    short: 'Parc. estornado'
  }
}

export const paymentAnalysisLabels = {
  payment_approved: { full: 'Análise aprovada', short: 'Aprovada' },
  payment_approved_manually: {
    full: 'Análise aprovada manualmente',
    short: 'Aprovada'
  },
  payment_refused: { full: 'Análise recusada', short: 'Recusada' },
  payment_in_analysis: { full: 'Em análise', short: 'Em análise' }
}

export const paymentMethods = method => paymentMethodsLabels[method] || method

export const paymentStatus = status =>
  paymentStatusLabels?.[status]?.full || status

export const paymentAnalysis = status =>
  paymentAnalysisLabels?.[status]?.full || status

export const paymentStatusShort = status =>
  paymentStatusLabels?.[status]?.short || status

export const cardIssuer = issuer => cardIssuerLabels?.[issuer] || issuer

export const brazilianStates = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins'
}
