import styled, { css } from 'styled-components';

export const Container = styled.section`
  display: flex;

  .items {
    display: flex;
    flex-direction: column;

    width: 50%;
  }

  .item {
    display: flex;
    align-items: center;

    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 8px;

    border-width: 1px 1px 1px 4px;
    border-color: var(--borderColor);
    border-style: solid;

    &.confirmed {
      --borderColor: var(--success);
    }
    &.pending {
      --borderColor: var(--warning);
    }
    &.stock_out {
      --borderColor: var(--danger);
    }

    &__info {
      flex: 1;
    }
  }

  .actions {
    position: sticky;
    top: 8px;
    padding-left: 8px;
    max-height: calc(100vh - 16px);
    overflow-y: auto;
    width: 50%;

    .list {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
      width: 100%;

      .results {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        gap: 4px;

        &__cards {
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 8px;
        }

        .card {
          &__image {
          }
          &__name {
            line-height: 1.125;
            font-size: 12px;
          }
        }
      }
    }
  }
`;
