import React from 'react'

import PropTypes from 'prop-types'

import DatepickerHeader from '../datepicker-header'
import FilterChannel from '../FilterChannel'
import { FilterChatAgent } from '../FilterChatAgent'
import FilterChatBotStatus from '../FilterChatBotStatus'
import FilterChatTags from '../FilterChatTags'
import FilterLocations from '../FilterLocations'
import FilterLocationsRegions from '../FilterLocationsRegions'
import FilterLocationStatus from '../FilterLocationStatus'
import FilterLocationType from '../FilterLocationType'
import FilterOrderStatusMulti from '../FilterOrderStatusMulti'
import FilterPayment from '../FilterPaymentStatus'
import FilterPromotionalType from '../FilterPromotionalType'
import { FilterSearch } from '../FilterSearch'
import FilterShoppingCartStatus from '../FilterShoppingCartStatus'
import FilterAggregate from '../FiltersMetabase/Aggregate'
import FilterStates from '../FilterStates'
import FilterStatus from '../FilterStatus'
import FilterStatusCampaign from '../FilterStatusCampaign'
import FilterStatusPromotional from '../FilterStatusPromotional'
import FilterTemplateStatus from '../FilterTemplateStatus'
import FilterUserRoles from '../FilterUserRoles'
import GenericFilter from './GenericFilter'

import { FiltersWrapper } from './styles'

export const Filters = ({
  align = 'left',
  columns = 4,
  //
  query,
  liveQuery,
  queryWithDebounce = false,
  queryKeyState,
  queryPlaceholder,
  queryShowSubmitButton,
  queryLoading = false,
  queryAutoComplete = null,
  querySize = '2x',
  channel,
  status,
  initialDataStatusMulti,
  orderStatusMulti,
  payment,
  chatBotStatus,
  chatTags,
  userRoles,
  filterChatAgent,
  // States
  states,
  initialState,
  ignoreReduxState,
  // Location
  locations,
  initialLocation,
  onChangeLocation,
  ignoreReduxLocation = false,
  labelAllLocation,
  hideAllLocation,
  filterWithoutLocation,
  // Location Regions
  locationRegions,
  initialRegion,
  initialLocationRegion,
  onChangeRegion,
  ignoreReduxRegion = false,
  labelAllRegion,
  hideAllRegion,
  filterWithoutRegion,
  // Shopping cart
  shoppingCartStatus,
  initialShoppingCartStatus,
  // Location type
  locationType,
  // Location status
  locationStatus,
  // Templates
  templateStatus,
  //
  statusCampaign,
  // Promotional
  promotionalStatus,
  promotionalType,
  // Metabase
  aggregate,
  //
  genericFilters,
  //
  showGlobalDatePicker = false,
  //
  children,
  ...rest
}: FiltersProps) => (
  <FiltersWrapper className="filtersWrapper" align={align} columns={columns}>
    {query && (
      <div
        className="filterWrapper selectSearchableWrapper"
        style={querySize === '2x' ? { gridColumn: 'span 2' } : {}}
      >
        <FilterSearch
          keyState={queryKeyState}
          placeholder={queryPlaceholder}
          live={liveQuery}
          showSubmitButton={queryShowSubmitButton}
          loading={queryLoading}
          autoComplete={queryAutoComplete}
          isDebounce={queryWithDebounce}
        />
      </div>
    )}

    {genericFilters?.map((item: GenericFilterProps) => (
      <div className="filterWrapper selectSearchableWrapper" key={item.key}>
        <GenericFilter {...item} />
      </div>
    ))}

    {orderStatusMulti && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterOrderStatusMulti initialData={initialDataStatusMulti} />
      </div>
    )}

    {channel && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterChannel {...rest} />
      </div>
    )}

    {states && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterStates
          initialState={initialState}
          ignoreRedux={ignoreReduxState}
        />
      </div>
    )}

    {locations && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterLocations
          initialLocation={initialLocation}
          onChange={onChangeLocation}
          ignoreRedux={ignoreReduxLocation}
          labelAll={labelAllLocation}
          hideAll={hideAllLocation}
          filterWithoutLocation={filterWithoutLocation}
        />
      </div>
    )}

    {locationRegions && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterLocationsRegions
          initialRegion={initialRegion}
          initialLocation={initialLocationRegion}
          onChange={onChangeRegion}
          ignoreRedux={ignoreReduxRegion}
          labelAll={labelAllRegion}
          hideAll={hideAllRegion}
          filterWithoutRegion={filterWithoutRegion}
        />
      </div>
    )}

    {filterChatAgent && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterChatAgent />
      </div>
    )}
    {status && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterStatus />
      </div>
    )}

    {payment && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterPayment />
      </div>
    )}

    {shoppingCartStatus && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterShoppingCartStatus initialData={initialShoppingCartStatus} />
      </div>
    )}

    {chatTags && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterChatTags />
      </div>
    )}

    {chatBotStatus && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterChatBotStatus />
      </div>
    )}

    {userRoles && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterUserRoles />
      </div>
    )}
    {locationType && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterLocationType />
      </div>
    )}
    {locationStatus && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterLocationStatus />
      </div>
    )}
    {statusCampaign && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterStatusCampaign />
      </div>
    )}

    {templateStatus && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterTemplateStatus />
      </div>
    )}

    {promotionalStatus && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterStatusPromotional />
      </div>
    )}

    {promotionalType && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterPromotionalType />
      </div>
    )}

    {aggregate && (
      <div className="filterWrapper selectSearchableWrapper">
        <FilterAggregate />
      </div>
    )}

    {showGlobalDatePicker && (
      <div className="filterWrapper selectSearchableWrapper datePicker">
        <DatepickerHeader />
      </div>
    )}

    {children && (
      <div className="filterWrapper selectSearchableWrapper childrenWrapper">
        {children}
      </div>
    )}
  </FiltersWrapper>
)

Filters.propTypes = {
  // Styles
  align: PropTypes.string,
  columns: PropTypes.number,
  // Filters
  locations: PropTypes.bool,
  initialLocation: PropTypes.string,
  labelAllLocation: PropTypes.string,
  hideAllLocation: PropTypes.string,
  onChangeLocation: PropTypes.func,
  ignoreReduxLocation: PropTypes.bool,
  filterWithoutLocation: PropTypes.bool,

  locationRegions: PropTypes.bool,
  initialRegion: PropTypes.string,
  initialLocationRegion: PropTypes.string,
  onChangeRegion: PropTypes.string,
  ignoreReduxRegion: PropTypes.bool,
  labelAllRegion: PropTypes.bool,
  hideAllRegion: PropTypes.bool,
  filterWithoutRegion: PropTypes.bool,

  query: PropTypes.bool,
  queryLoading: PropTypes.bool,
  queryShowSubmitButton: PropTypes.bool,
  liveQuery: PropTypes.bool,
  querySize: PropTypes.string,
  queryAutoComplete: PropTypes.string,
  queryKeyState: PropTypes.string,
  queryPlaceholder: PropTypes.string,

  channel: PropTypes.bool,
  status: PropTypes.bool,
  orderStatusMulti: PropTypes.bool,
  initialDataStatusMulti: PropTypes.arrayOf(PropTypes.string),
  // Shopping cart
  shoppingCartStatus: PropTypes.bool,
  initialShoppingCartStatus: PropTypes.string,

  states: PropTypes.bool,
  initialState: PropTypes.string,
  ignoreReduxState: PropTypes.bool,

  payment: PropTypes.bool,

  chatBotStatus: PropTypes.bool,
  chatTags: PropTypes.bool,
  filterChatAgent: PropTypes.bool,

  userRoles: PropTypes.bool,

  statusCampaign: PropTypes.bool,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),

  locationType: PropTypes.bool,

  locationStatus: PropTypes.bool,

  templateStatus: PropTypes.bool,

  // Promotional
  promotionalStatus: PropTypes.bool,
  promotionalType: PropTypes.bool,

  showGlobalDatePicker: PropTypes.bool
}

Filters.defaultProps = {
  align: 'left',
  columns: 4,

  locations: false,
  initialLocation: null,
  labelAllLocation: null,
  hideAllLocation: null,
  onChangeLocation: null,
  ignoreReduxLocation: false,
  filterWithoutLocation: false,

  locationRegions: false,
  initialRegion: null,
  initialLocationRegion: null,
  labelAllRegion: null,
  hideAllRegion: null,
  onChangeRegion: null,
  ignoreReduxRegion: false,
  filterWithoutRegion: false,

  query: false,
  queryLoading: false,
  queryShowSubmitButton: false,
  querySize: '2x',
  queryAutoComplete: null,
  liveQuery: false,
  queryKeyState: null,
  queryPlaceholder: null,

  states: false,
  initialState: null,
  ignoreReduxState: false,

  channel: false,
  status: false,
  orderStatusMulti: false,
  initialDataStatusMulti: null,
  // Shopping cart
  shoppingCartStatus: false,
  initialShoppingCartStatus: null,

  payment: false,

  chatBotStatus: false,
  chatTags: false,
  filterChatAgent: false,

  userRoles: false,

  children: null,

  locationType: false,

  locationStatus: false,

  statusCampaign: false,
  templateStatus: false,

  // Promotional
  promotionalStatus: false,
  promotionalType: false,

  showGlobalDatePicker: false
}
