import styled, { css } from 'styled-components'

/**
 * CSS vars:
 * src/pages/chat/messenger/styles.js
 */

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    overflow: hidden;
    z-index: 110px;

    display: flex;
    flex-direction: column;
    align-items: center;

    /* width: var(--chatlist__width); */
    height: 100%;

    transition: ease-in-out all 0.3s;

    border-radius: ${theme.border.radius.medium};
    background-color: ${theme.colors.white};

    > header {
      padding: 1rem 1rem 0.5rem;
      position: relative;
      min-width: var(--aside__width);

      .title {
        strong {
          font-weight: 700;
        }
      }

      .description {
        color: ${({ theme }) => theme.colors.gray};
        font-size: ${({ theme }) => theme.font.sizes.small};
        font-weight: 400;
        margin-bottom: 0;
        line-height: 1.125;
      }

      form {
        margin-top: 0.25rem;
      }

      .icon__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        outline: none;
        background-color: transparent;
        padding: 0;
        border: 0;
      }
    }

    > section {
      width: 100%;
      min-width: var(--aside__width);
      padding: 0.5rem 1rem 1rem;
      overflow-x: hidden;
      overflow-y: auto;

      /* Custom scrollbar - width */
      ::-webkit-scrollbar {
        width: 0.5rem;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: ${theme.colors.inative};
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${theme.colors.tertiary};
        transition: 0.3s ease-in-out all;
        /* Handle on hover */
        &:hover {
          background: ${theme.colors.gray};
        }
      }
    }

    footer {
      width: 100%;
      margin-top: 0.5rem;
      padding-top: 0.5rem;
      border-top: 1px solid ${theme.colors.inative};
      text-align: center;
    }
  `}
`

export const List = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;

    .title {
      font-weight: 600;
      color: ${theme.dark};
    }

    .separator {
      color: ${theme.colors.gray};
      font-size: ${({ theme }) => theme.font.sizes.small};
      margin-top: 0.5rem;
    }

    > .TagButton {
      margin: 0.5rem 0;
    }
  `}
`
