import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const transparentButtonTemplate = ({ theme }: ButtonTemplatesProps) => css`
  background: transparent;
  color: var(--gray);

  min-width: unset;
  height: auto;
  padding: 0.125rem 0.25rem;

  &:not(:disabled) {
    &:hover {
      color: var(--dark);
    }
  }
`

export default transparentButtonTemplate
