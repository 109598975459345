import { toastr } from 'react-redux-toastr'

import { resetPasswordService } from '~/services/password-service'
import { checkTokenInvite, activateInvite } from '~/services/signup-service'

import * as types from './constants'

/**
 * @param {Object} data Request body
 * @param {String} data.password New password
 * @param {String} data.token Token recebido pela URL
 */
export const resetPassword =
  ({ token, password, onSuccess }) =>
  dispatch => {
    dispatch({
      type: types.UPDATE_PASSWORD_REQUEST,
      payload: resetPasswordService({ token, password }),
      onSuccess
    })
  }

export const chekTokenIsValid = token => dispatch => {
  dispatch({
    type: types.CONFIRM_INVITE_VALIDATE_TOKEN,
    payload: checkTokenInvite(token),
    onSuccess: () => {
      dispatch({
        type: types.CONFIRM_INVITE_VALID_TOKEN
      })
    },
    hideError: true,
    onError: ({ response }) => {
      const { error } = response.data
      let messageError
      let titleError
      if (error.includes('not exists')) {
        titleError = 'Convite não encontrado!'
        messageError = `Entre em contato com o administrador da conta solicitando um novo convite`
      } else if (error.includes('Invite expired!')) {
        titleError = 'Convite expirado!'
        messageError = `Seu convite para acessar a plataforma expirou.
        Entre em contato com o administrador da conta solicitando um novo convite.`
        toastr.error(titleError, messageError)
      } else {
        toastr.error('Erro', error)
      }

      dispatch({
        type: types.CONFIRM_INVITE_ERROR_MESSAGE,
        message: messageError,
        title: titleError
      })
    }
  })
}

export const activeInvite =
  ({ token, password, onSuccess }) =>
  dispatch => {
    dispatch({
      type: types.CONFIRM_INVITE_ACTIVATE,
      payload: activateInvite({ token_invite: token, password }),
      onSuccess,
      getErrorFeedbackMsg: error => {
        if (error.response.data.message.includes('expired')) {
          return `Seu convite para acessar a plataforma expirou.
        Entre em contato com o administrador da conta solicitando um novo convite.`
        }

        return error.response.data.message || 'Algo de errado aconteceu.'
      }
    })
  }
