import { t } from 'i18next'

export const actions = {
  update: t('rm:constants.updated'),
  create: t('rm:constants.created'),
  delete: t('rm:constants.deleted')
} as { [key in ResponseGetAuditLogActions]: string }

export const entities = {
  campaign: t('rm:campaign'),
  ad: t('rm:ad'),
  campaign_keyword: t('rm:keyword'),
  campaign_category: t('rm:category')
} as { [key in ResponseGetAuditLogEntities]: string }
