import styled from 'styled-components'

import { device } from '~/styles/device'

export const ContainerSelects = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  .selectMetrics-item {
    min-width: 12rem;
  }

  @media ${device.mobileL_max} {
    flex-direction: column;
  }
`
