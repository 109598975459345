import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { history } from '~/helpers'
import useQuery from '~/hooks/useQuery'

import { setExternalLoggedIn } from '../../store/login/actions'

const LoginMarketplace: React.FC = () => {
  const dispatch = useDispatch()

  const { origin } = useParams()

  const query = useQuery()
  const token = query.get('token')
  const callback_url = query.get('callback_url')

  useEffect(() => {
    if (token) {
      dispatch(setExternalLoggedIn({ token, callback_url, origin }))
    }

    if (!token) history.push('/login')
  }, [callback_url, dispatch, origin, token])

  return <></>
}

export default LoginMarketplace
