import { modelFlyersView } from '~/modules/flyers/models/flyers/view';

import * as types from '../constants';

const index = {
  pending: `${types.FLYER_VIEW}_PENDING`,
  fulfilled: `${types.FLYER_VIEW}_FULFILLED`,
  rejected: `${types.FLYER_VIEW}_REJECTED`,
};

export const viewFlyer = {
  [index.pending]: ({ draft }) => {
    draft.flyers.view.loading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.flyers.view.loading = false;
    draft.flyers.view.data = modelFlyersView(action.payload);
  },
  [index.rejected]: ({ draft }) => {
    draft.flyers.view.loading = false;
  },
};
