import React from 'react'

import PropTypes from 'prop-types'

import { ButtonNavLink } from '~/components/Buttons'

import * as S from './styles'

const TabsNav = ({ data }) => (
  <S.Container>
    {data.map(item => (
      <ButtonNavLink
        key={item.id}
        activeClassName="active"
        template="light"
        customWidth="auto"
        size="small"
        text={item.text}
        to={item.to}
        {...(item.onClick ? { onClick: item.onClick } : {})}
      />
    ))}
  </S.Container>
)

export default TabsNav

TabsNav.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      to: PropTypes.string,
      text: PropTypes.string,
      onClick: PropTypes.func
    })
  ).isRequired
}
