import styled, { css } from 'styled-components';

const containerModifiers = {
  active: theme => css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    width: 100vw;
    height: 100vh;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.85);

    .toggleButton {
      color: ${theme.colors.danger};
    }
  `,
};

export const Container = styled.section`
  ${({ theme, isActive }) => css`
    position: relative;
    transition: 0.3s ease-in-out all;

    .toggleButton {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 9999;

      background: white;
      transition: 0.1s ease-in-out all;
    }

    ${isActive && containerModifiers.active(theme)}
  `}
`;
