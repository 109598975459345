import React from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';

const BarChart = ({ theme, labels, title, barData, formatData }) => {
  const data = {
    labels,
    datasets: [
      {
        label: title,
        backgroundColor: theme.primary,
        borderColor: theme.primary,
        hoverBackgroundColor: theme.primary,
        hoverBorderColor: theme.primary,
        data: barData,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltipItem, data) =>
          `${data.datasets[0].label}: ${
              formatData ? formatData(tooltipItem.yLabel) : tooltipItem.yLabel
          }`,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: false,
          ticks: {
            stepSize: 10,
            suggestedMin: 0,
            maxTicksLimit: 10,
            callback: value => (formatData ? formatData(value) : value),
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: 'transparent',
          },
        },
      ],
    },
  };

  return (
    <div className="chart chart-m">
      <Bar data={data} options={options} />
    </div>
  );
};

export default connect(store => ({
  theme: store.theme.currentTheme,
}))(BarChart);
