import styled, { css } from 'styled-components';

export const Label = styled.span`
  background: transparent;
  border: 0;
  outline: 0;

  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin: 0.125rem 0.25rem 0.125rem 0;
    padding: 0.125rem 0.25rem;

    border-radius: 0.25rem;
    border: 1px solid ${theme.light};

    background: ${theme.inative};

    color: ${theme.dark};
    font-size: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;
