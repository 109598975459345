import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Alert from '~/components/Alert'
import EmptyPage from '~/components/empty-page'
import Loading from '~/components/Loading'
import {
  getChatProvider,
  getConnectionStatus
} from '~/modules/chat/store/chatConnect/actions'

import Unauthenticated from '../Unauthenticated'

/**
 * Este componente é responsável pela verificação do provider de mensageria
 */
const HandleProvider = () => {
  const { isConnected, loadingAuthStatus, provider, isWhatsAppWeb } =
    useSelector(({ chatConnect }) => chatConnect)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!provider) {
      dispatch(getChatProvider())
    }
  }, [dispatch, provider])

  useEffect(() => {
    if (isWhatsAppWeb) {
      dispatch(getConnectionStatus())
    }
  }, [dispatch, isWhatsAppWeb, provider])

  const isLoadingMessages = useMemo(
    () => provider && loadingAuthStatus && !isConnected,
    [provider, loadingAuthStatus, isConnected]
  )

  const shouldLoadQrCode = useMemo(() => isWhatsAppWeb, [isWhatsAppWeb])

  const isUnauthenticated = useMemo(
    () => provider && shouldLoadQrCode && !isConnected && !loadingAuthStatus,
    [provider, loadingAuthStatus, isConnected, shouldLoadQrCode]
  )

  return (
    <>
      {/* Suspense - Buscando provider */}
      {!provider && (
        <Loading status={!!provider} alignCenter>
          Carregando configuração da mensageria...
        </Loading>
      )}

      {/* Suspense - Buscando status da conexão */}
      {isLoadingMessages && (
        <Loading status={loadingAuthStatus}>Conectando WhatsApp...</Loading>
      )}

      {/* No auth */}
      {isUnauthenticated && <Unauthenticated />}

      {/* No config */}

      {!provider && (
        <Alert active>Não há uma configuração para esta conta</Alert>
      )}

      {(!provider || isLoadingMessages) && (
        <>
          <EmptyPage
            title="Atendimento via WhatsApp"
            svgPath="photos/chats-empty"
          />
        </>
      )}
    </>
  )
}

export default HandleProvider
