import {
  getChatProviderService,
  getQrCodeWppWeb,
  getStatusAuth
} from '~/modules/chat/services'

import * as types from './constants'

/**
 *
 * @exports getAuthStatus Get messenger auth status
 *
 */

/**
 * Get  messenger auth status
 */
export const getConnectionStatus = () => {
  return {
    type: types.GET_AUTH_STATUS,
    payload: getStatusAuth(),
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao carregar a mensageria.'
  }
}

export const getChatProvider = () => {
  return {
    type: types.GET_CHAT_PROVIDER,
    payload: getChatProviderService(),
    getErrorFeedbackMsg: error =>
      error.response?.data?.error ||
      'Houve um erro no carregamento da mensageria.'
  }
}

export const getQrCodeWhatsAppWeb = () => {
  return {
    type: types.GET_QRCODE_WPP_WEB,
    payload: getQrCodeWppWeb(),
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao carregar o QrCode.'
  }
}

export const setStatusConnectionWhatsApp = () => {
  return {
    type: types.SET_CONNECTION_STATUS
  }
}
