import { types } from '../constants'

const index = {
  pending: `${types.cpm_pactch}_PENDING`,
  fulfilled: `${types.cpm_pactch}_FULFILLED`,
  rejected: `${types.cpm_pactch}_REJECTED`
}

export const cpmPatch = {
  [index.pending]: ({ draft }) => {
    draft.industry.ad.cpmPatch.loading = true
    draft.industry.ad.cpmPatch.error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    const values = action.values
    const oldItemCampaign = draft.campaign.item.data.ads.data

    const updatedAds = oldItemCampaign.map(ad => {
      if (ad.id === values.id) {
        return {
          ...ad,
          settings: {
            ...ad.settings,
            cpm: values.body.cpm
          }
        }
      } else {
        return ad
      }
    })

    draft.campaign.item.data.ads.data = updatedAds

    draft.industry.ad.cpmPatch.loading = false
    draft.industry.ad.cpmPatch.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.ad.cpmPatch.loading = false
    draft.industry.ad.cpmPatch.error = action.payload
  }
}
