import React from 'react';

import Panel from '~/components/Panel';
import { FormGroup, Input, Label } from '~/components/unform';
import { Group } from '~/modules/promotional/components/Form/styles';

import { AddButton, ApproverFormWrapper } from './styles';

const ApproverForm = () => {
  return (
    <ApproverFormWrapper>
      <p className="title">Adicionar responsável por aprovação</p>
      <Group>
        <FormGroup>
          <Label htmlFor="#" isRequired>
            Nome do responsável
          </Label>
          <Input
            placeholder="Digite o nome do responsável"
            name="#"
            id="#"
            required
            autoFocus
          />
        </FormGroup>
      </Group>

      <Group>
        <FormGroup>
          <Label htmlFor="#">Cargo</Label>
          <Input
            placeholder="Digite o cargo"
            name="#"
            id="#"
            required
            autoFocus
          />
        </FormGroup>
      </Group>

      <Group>
        <FormGroup>
          <Label htmlFor="#" isRequired>
            E-mail
          </Label>
          <Input
            placeholder="Digite o e-mail"
            name="#"
            id="#"
            required
            autoFocus
          />
        </FormGroup>
      </Group>
      <AddButton text="Adicionar" />
    </ApproverFormWrapper>
  );
};

export default ApproverForm;
