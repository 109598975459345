import Panel from '~/components/Panel'
import ThumbZoomable from '~/components/ThumbZoomable'

const DetailsSponsoredBrands: React.FC<ViewCampaignProps> = ({ data }) => {
  return (
    <Panel title="Definições da vitrine">
      <p>
        <strong>Título:</strong> {data.settings.headline || 'Não definido'}
        <br />
        <strong>Descrição:</strong>{' '}
        {data.settings.description || 'Não definido'}
        <br />
        <strong>Nome da marca:</strong>{' '}
        {data.settings.brand_name || 'Não definido'}
      </p>

      {!!data?.settings?.brand_url && (
        <p>
          <strong>Logo:</strong>
          <br />
          <img src={data.settings.brand_url} />
        </p>
      )}

      {!!data.settings?.assets?.length && (
        <section>
          <strong>Mídias:</strong>
          <br />
          <div className="d-flex gap-2">
            {data.settings.assets.map(asset => (
              <ThumbZoomable
                key={asset.url}
                url={asset.url}
                title="Clique para dar zoom"
              />
            ))}
          </div>
        </section>
      )}
    </Panel>
  )
}

export default DetailsSponsoredBrands
