import styled, { css } from 'styled-components';

import { device } from '~/styles/device';

export const Container = styled.div`
  ${({ theme, isOwner }) => css`
    --bgColor: ${isOwner ? theme.colors.neutralLow : theme.colors.danger};

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--bgColor);
    color: ${theme.colors.white};
    overflow: hidden;
    width: 100%;
    padding: 0.375rem 2rem 0.375rem 1rem;

    .LoadingContainer {
      width: 100%;
    }

    height: 33px;

    @media ${device.mobileL_max} {
      height: 57px;
    }

    @media ${device.mobileS_max} {
      height: 78px;
    }

    @media ${device.tablet_max} {
      flex-direction: column;
      padding-right: 1rem;
    }
  `}
`;

export const WrapperActions = styled.div`
  display: flex;
  align-items: center;

  .icon__left .button__sm-spinner {
    font-size: 0.313rem;
    height: 1.25rem;
    width: 1.25rem;
  }

  .button__sm-wide {
    height: 1.5rem;
  }

  .button__sm-light {
    height: 1.5rem;
    margin-left: 0.5rem;
  }
`;
