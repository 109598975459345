import React, { useEffect, useCallback, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useSelector, useDispatch } from 'react-redux'

import LoadingPopUp from '~/components/LoadingPopUp'
import { Spinner } from '~/components/Spinner'

import { useOrderTable } from '../../_hooks/useOrderBoard'
import { clearOrderInfo } from '../../actions'
import StatusPanel from '../StatusPanel'
import * as S from './style'

const Board = () => {
  const [isDragging, setIsDragging] = useState(false)

  const {
    activeColumns,
    loadingMaster,
    isValidatingInitialData,
    handleOrderStatusOnDrag
  } = useOrderTable()

  const dispatch = useDispatch()
  const { orderData } = useSelector(({ order }) => order)

  const columns = activeColumns.filter(item => item.active)

  const onBeforeDragStart = useCallback(() => {
    setIsDragging(true)
  }, [])

  const onDragEnd = useCallback(
    async result => {
      setIsDragging(false)

      // dropped nowhere
      if (!result.destination) {
        return
      }

      const { source, destination } = result

      if (source.droppableId === destination.droppableId) {
        return
      }

      if (result.type === 'ORDERS') {
        handleOrderStatusOnDrag({ result })
      }
    },
    [handleOrderStatusOnDrag]
  )

  /**
   * Pendência
   * Esta função está servindo para limpar o cache da página de pedidos
   */
  useEffect(() => {
    if (Object.entries(orderData).length > 0) {
      dispatch(clearOrderInfo())
    }
  }, [dispatch, orderData])

  return (
    <DragDropContext
      onBeforeDragStart={onBeforeDragStart}
      onDragEnd={onDragEnd}
    >
      <S.Container>
        {!loadingMaster &&
          columns.map((status, index) => (
            <StatusPanel
              key={status.id}
              status={status}
              index={index}
              isDragging={isDragging}
            />
          ))}

        {loadingMaster && (
          <S.LoadingMaster>
            <Spinner /> Carregando quadro de pedidos...
          </S.LoadingMaster>
        )}

        <LoadingPopUp
          isActive={isValidatingInitialData}
          text="Atualizando pedidos..."
        />
      </S.Container>
    </DragDropContext>
  )
}

export default Board
