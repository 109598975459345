import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'

import { useMapDraw } from '../../hooks/useMapDraw'
import DeleteRegion from './DeleteRegion'

import * as S from './styles'

const SubmitButtons = () => {
  const { handleDisableDraw, isValidShape, editingRegion, isEditingMode } =
    useMapDraw()

  const {
    createLocationRegionsLoading: createLoading,
    updateLocationRegionsLoading: updateLoading
  } = useSelector(state => state.locations)

  const labelSave = useMemo(() => {
    if (isEditingMode) {
      return 'Salvar modificações'
    }

    return editingRegion?.type === 1
      ? `Criar região de entrega`
      : `Criar região de exclusão`
  }, [editingRegion, isEditingMode])

  return (
    <S.SubmitButtons>
      <Button
        customWidth="100%"
        text={labelSave}
        type="submit"
        disabled={!isValidShape || createLoading || updateLoading}
        iconLeft={createLoading || updateLoading ? <Spinner /> : null}
      />

      <Button
        customWidth="100%"
        text="Cancelar"
        template="primaryOutlined"
        onClick={handleDisableDraw}
      />

      {isEditingMode && <DeleteRegion />}
    </S.SubmitButtons>
  )
}

export default SubmitButtons
