import React, { useCallback, useMemo } from 'react';
import { FiCheck } from 'react-icons/fi';
import { MdColorize } from 'react-icons/md';

import PropTypes from 'prop-types';

import * as S from './styles';

const ColorButton = ({ value, onClick, currentColor }) => {
  const handleClick = useCallback(() => onClick({ value }), [onClick, value]);
  const isActive = useMemo(() => currentColor === value, [currentColor, value]);

  const handleChangeColor = useCallback(
    event => {
      const { value } = event.target;
      onClick({ value, customColor: true });
    },
    [onClick],
  );

  return value === 'picker' ? (
    <>
      <S.ContainerFake>
        <MdColorize />

        <input
          id="colorpicker"
          type="color"
          name="colorpicker"
          className="colorpicker"
          onChange={handleChangeColor}
        />
      </S.ContainerFake>
    </>
  ) : (
    <S.Container
      type="button"
      currentColor={value}
      onClick={handleClick}
      isActive={isActive}
    >
      <div className="checked">
        <FiCheck />
      </div>
    </S.Container>
  );
};

export default ColorButton;

ColorButton.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  currentColor: PropTypes.string,
};

ColorButton.defaultProps = {
  onClick: null,
  currentColor: null,
};
