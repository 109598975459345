import { t } from 'i18next'
import { formatDateExibition } from '~/helpers'

import { RightArrow } from './commons'

const Dates = ({ prevValue, newValue, fieldName }: DefaultChangeProps) => {
  const getText = (value: string | null) =>
    value === null ? <i>{t('rm:campaign.end_date.empty')}</i> : formatDateExibition(value)

  const prevText =
    fieldName === 'end_at' ? getText(prevValue) : formatDateExibition(prevValue)

  const nextText =
    fieldName === 'end_at' ? getText(newValue) : formatDateExibition(newValue)

  return (
    <>
      <span className="prevValue">{prevText}</span>

      <RightArrow />

      <span className="newValue">{nextText}</span>
    </>
  )
}

export default Dates
