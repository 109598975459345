import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmptyPage from '~/components/empty-page';
import ListPagination from '~/components/ListPagination';
import Loading from '~/components/Loading';
import Panel from '~/components/Panel';
import Table from '~/components/Table';
import { useAlert } from '~/hooks/useAlert';
import campaignActions from '~/modules/messagingCampaigns/store/campaign/actions';
import { CAMPAIGN_REDUX_QUERY as reduxQuery } from '~/modules/messagingCampaigns/store/campaign/constants';
import { TEMPLATE_REDUX_QUERY as reduxQuerySearch } from '~/modules/messagingCampaigns/store/template/constants';

import { schema } from './table-schema';

const CampaignsTable = () => {
  const dispatch = useDispatch();

  const {
    campaign: {
      list: {
        data: { data, total },
        loading,
        page,
        perPage,
        error,
      },
    },
  } = useSelector(state => state.messagingCampaigns);

  const { query, campaignStatus } = useSelector(state => state.filters);

  const filteredQuery = useMemo(
    () => query?.[`${reduxQuery}`] || null,
    [query],
  );

  const filteredQuerySearch = useMemo(
    () => query?.[`${reduxQuerySearch}`] || null,
    [query],
  );

  useEffect(() => {
    dispatch(
      campaignActions.list({
        page,
        quantity: perPage,
        query: filteredQuery,
        status: campaignStatus?.type,
        sort_direction: 'DESC',
        name: filteredQuerySearch ? `${filteredQuerySearch}%` : null,
      }),
    );
  }, [
    dispatch,
    perPage,
    page,
    filteredQuery,
    campaignStatus,
    filteredQuerySearch,
  ]);

  const [AlertOnError] = useAlert(error);

  /**
   * Handle pagination
   */

  const handlePagination = useCallback(
    page => {
      dispatch(campaignActions.setPage(page));
    },
    [dispatch],
  );

  const handleItemsPerPageChange = useCallback(
    ({ value }) => {
      dispatch(campaignActions.setPerPage(value));
    },
    [dispatch],
  );

  return (
    <>
      <Loading status={loading}>Carregando lista de campanhas</Loading>

      <AlertOnError />

      {!loading && !!total && (
        <>
          <ListPagination
            total={total}
            label={total > 1 ? 'campanhas' : 'campanha'}
            currentPage={page}
            itemsPerPage={perPage}
            onClickPagination={handlePagination}
            onItemsPerPageChange={handleItemsPerPageChange}
          />

          <Panel noPaddingBody isLoading={loading}>
            <Table schema={schema} data={data} total={total} />
          </Panel>

          <ListPagination
            total={total}
            label={total > 1 ? 'campanhas' : 'campanha'}
            currentPage={page}
            itemsPerPage={perPage}
            onClickPagination={handlePagination}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </>
      )}

      {total === 0 && (
        <EmptyPage
          title="Campanhas de memsagens"
          subtitle="Você ainda não tem campanhas cadastradas."
          svgPath="photos/chats-empty"
        />
      )}
    </>
  );
};

export default CampaignsTable;
