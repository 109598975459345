import { useMemo } from 'react'
import { FiCode } from 'react-icons/fi'

import { Icon } from '~/components/Icon'
import Table from '~/components/Table'
import { WrapperShadow, Header, Content } from '~/components/WrapperShadow'
import { useAppSelector } from '~/store/hooks'

const PanelMetadata = () => {
  const {
    orderData: { metadata }
  } = useAppSelector(state => state.order)

  const attributes = useMemo(() => {
    return metadata?.attributes || []
  }, [metadata])

  const schema: TableSchema = {
    key: {
      title: 'Campo',
      render: rowData => rowData.name
    },
    value: {
      title: 'Valor',
      render: rowData => rowData.value
    }
  }

  return (
    !!attributes?.length && (
      <WrapperShadow>
        <Header>
          <div>
            <span>
              <Icon icon={FiCode} /> <strong>Metadados</strong>
            </span>
          </div>
        </Header>

        <Content>
          <Table data={attributes} schema={schema} />
        </Content>
      </WrapperShadow>
    )
  )
}

export default PanelMetadata
