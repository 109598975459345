import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.div`
  width: clamp(350px, 420px, 90vw);
`

export const ContainerReviewCart = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${theme.colors.neutralLow};
    color: ${theme.colors.white};

    width: 100%;
    padding: 0.375rem 2rem 0.375rem 1rem;

    a {
      text-decoration: underline;
      color: ${theme.colors.white};
      font-weight: bold;
    }

    @media ${device.tablet_max} {
      flex-direction: column;
      padding-right: 1rem;
    }
  `}
`
