import { cases as deleteCases } from './delete';
import { cases as searchCases } from './search';
import { cases as sendLinkCases } from './sendLink';
import { cases as updateCases } from './update';
import { cases as updateStatusCases } from './updateStatus';
import { cases as viewCases } from './view';

export default {
  ...viewCases,
  ...searchCases,
  ...deleteCases,
  ...updateCases,
  ...updateStatusCases,
  ...sendLinkCases,
};
