import React, { useCallback, useMemo } from 'react'
import { FiCircle, FiPlusCircle, FiXCircle } from 'react-icons/fi'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import TabsNav from '~/components/TabsNav'

import { useLogisticSlots } from '../../useLogisticSlots'

import * as S from './styles'

const SlotNav = ({ locationId }) => {
  const {
    setFormSlotOpen,
    isWeekday,
    isDateMode,
    handleFormDate,
    formOpen,
    formDateOpen
  } = useLogisticSlots()

  const navData = useMemo(
    () => [
      {
        id: 'weekday_link',
        text: 'Dias da semana',
        to: `/logistic/slots/weekdays/${locationId || ''}`
      },
      {
        id: 'exceptions_link',
        text: 'Exceções',
        to: `/logistic/slots/exceptions/${locationId || ''}`
      },
      {
        id: 'withoutwork_link',
        text: 'Sem funcionamento',
        to: `/logistic/slots/without-work/${locationId || ''}`
      }
    ],
    [locationId]
  )

  const handleCreateSlotButtonClick = () => {
    setFormSlotOpen(prevState => !prevState)
  }

  return (
    <S.Container hasSideButton={isWeekday || isDateMode}>
      <TabsNav data={navData} />

      {isWeekday && (
        <Button
          template={formOpen ? 'danger' : 'success'}
          text={formOpen ? 'Cancelar' : 'Criar slot'}
          customWidth="132px;"
          iconLeft={formOpen ? <FiXCircle /> : <FiPlusCircle />}
          onClick={handleCreateSlotButtonClick}
          size="small"
        />
      )}

      {isDateMode && (
        <Button
          template={formDateOpen ? 'danger' : 'success'}
          text={formDateOpen ? 'Cancelar' : 'Adicionar data'}
          iconLeft={formDateOpen ? <FiXCircle /> : <FiPlusCircle />}
          onClick={handleFormDate}
          customWidth="132px;"
          size="small"
        />
      )}
    </S.Container>
  )
}

export default SlotNav

SlotNav.propTypes = {
  locationId: PropTypes.string
}

SlotNav.defaultProps = {
  locationId: null
}
