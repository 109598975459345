import React from 'react';

import { DatepickerHeader } from '~/components/datepicker-header';
import ExternalDashboard from '~/components/ExternalDashboard';
import HeaderPages from '~/components/HeaderPages';
import PageTitle from '~/components/PageTitle';

import * as S from './styles';

const ConversionDashboard = () => {
  return (
    <S.Container>
      <PageTitle title="Relatório de entregas" />

      <HeaderPages
        title="Relatório de entregas"
        sideRightComponent={<DatepickerHeader />}
        className="has-datepicker"
      />

      <ExternalDashboard dashboardId={47} useDate />
    </S.Container>
  );
};

export default ConversionDashboard;
