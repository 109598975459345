export const updateRegionModel = ({ formData, locationId, updateRegion }) => {
  const { name, global_config, min_interval_schedule, delivery_time } =
    formData;

  return {
    locationId,
    regionId: updateRegion.id,
    body: {
      name,
      min_interval_schedule: global_config
        ? null
        : Number(min_interval_schedule),
      delivery_time: global_config ? null : Number(delivery_time),
      region_type: updateRegion.type,
      points: [...updateRegion.latlngs, updateRegion.latlngs[0]],
    },
  };
};
