import * as Yup from 'yup'
import { setLocale } from 'yup'
import { t } from 'i18next'

setLocale({
  mixed: {
    default: t('rm:fieldInvalid'),
    required: t('rm:fieldRequired')
  },
  string: {
    email: t('rm:invalidEmail')
  }
})

const paymentMethods = ['pix', 'credit_card', 'boleto']

const whenPaymentMethod = (value: RetailMediaRechargePaymentMethods) => {
  switch (value) {
    case 'credit_card':
    case 'boleto':
      return Yup.string().required()
    default:
      return Yup.string()
  }
}

const addressValidations = {
  address_city: Yup.string().when('payment_method', whenPaymentMethod),
  address_neighborhood: Yup.string().when('payment_method', whenPaymentMethod),
  address_number: Yup.string().when('payment_method', whenPaymentMethod),
  address_postal_code: Yup.string().when('payment_method', whenPaymentMethod),
  address_state: Yup.string().when('payment_method', whenPaymentMethod),
  address_street: Yup.string().when('payment_method', whenPaymentMethod)
}

const personValidations = {
  name: Yup.string().required(),
  social_id: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().required()
}

const additionalBoletoValidations = {
  social_id: Yup.string().required(),
  company_name: Yup.string().required()
}

const FormIndustryRechargeSchema = {
  pix: Yup.object().shape({
    publisher_id: Yup.string().required(),

    amount: Yup.string().required(),

    payment_method: Yup.string().oneOf(paymentMethods).required(),

    ...personValidations
  }),

  credit_card: Yup.object().shape({
    publisher_id: Yup.string().required(),

    amount: Yup.string().required(),

    payment_method: Yup.string().oneOf(paymentMethods).required(),

    ...personValidations,
    ...addressValidations
  }),

  boleto: Yup.object().shape({
    publisher_id: Yup.string().required(),

    amount: Yup.string().required(),

    payment_method: Yup.string().oneOf(paymentMethods).required(),

    ...addressValidations,
    ...additionalBoletoValidations
  })
}

const FormAddress = Yup.object().shape(addressValidations)
const FormPerson = Yup.object().shape(personValidations)
const FormAdditionalBoleto = Yup.object().shape(additionalBoletoValidations)

export {
  FormIndustryRechargeSchema,
  FormAddress,
  FormPerson,
  FormAdditionalBoleto
}
