import { useRef } from 'react'

const useScroll = () => {
  const htmlElRef = useRef(null)

  const executeScroll = () => {
    window.scrollTo(0, htmlElRef.current.offsetTop - 32)
  }

  return [executeScroll, htmlElRef]
}

export { useScroll }
