import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';

import { getDecodedToken } from '~/helpers';

import WrapperFields from './wrapper';
import Input from '~/components/forms/inputs';

const StockForm = ({
  onNext,
  onPrevious,
  onSubmitStep,
  disabledStep,
  edit,
  initialValue,
  isLastStep,
}) => {
  const [singleStock, setSingleStock] = useState(false);

  const decodedToken = getDecodedToken();

  const handleSingleStock = e => {
    setSingleStock(!singleStock);
  };

  const beforeSubmitOnCreateProduct = data => {
    const formattedData = {
      stock: {
        ...data,
      },
    };

    onSubmitStep(formattedData);

    onNext();
  };

  return (
    <WrapperFields
      onSubmit={beforeSubmitOnCreateProduct}
      disabledStep={disabledStep}
      onPreviousStep={onPrevious}
      edit={edit}
      isLastStep={isLastStep}
    >
      {/* Stock */}
      <FormGroup className="form-group__flex form-group__wrap">
        <Input
          type="checkbox"
          label="Estoque único"
          name="single_stock"
          onChange={handleSingleStock}
          value="single"
        />
      </FormGroup>

      <div>
        <FormGroup className="form-group__flex form-group__wrap form-group__inline">
          <Input
            label={!singleStock && `Loja ${decodedToken.name} `}
            name="store"
            defaultValue={initialValue.store}
            placeholder="Insira a quantidade"
          />
        </FormGroup>

        {!singleStock && (
          <FormGroup className="form-group__flex form-group__wrap form-group__inline">
            <Input
              label={`Site ${decodedToken.name} `}
              name="site"
              defaultValue={initialValue.site}
              placeholder="Insira a quantidade"
            />
          </FormGroup>
        )}
      </div>
    </WrapperFields>
  );
};

export default StockForm;

StockForm.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmitStep: PropTypes.func,
  disabledStep: PropTypes.bool,
  isLastStep: PropTypes.bool,
  initialValue: PropTypes.shape(),
};

StockForm.defaultProps = {
  onNext: null,
  onNext: null,
  onPrevious: null,
  onSubmitStep: null,
  disabledStep: false,
  isLastStep: false,
  initialValue: {},
};
