import styled, { css } from 'styled-components';

const containerSizeModifiers = {
  small: () => css`
    --size: 1.25rem;
    font-size: 12px;
  `,
  medium: () => css`
    --size: 2rem;
  `,
};

const containerTemplateModifiers = {
  default: theme => css`
    --base-color: ${theme.colors.primary};

    background: var(--base-color);
    color: ${theme.colors.white};
  `,
  forbidden: theme => css`
    --base-color: ${theme.colors.danger};

    color: var(--base-color);
    border: 1px dashed;
  `,
};

export const Container = styled.div`
  ${({ theme, template = 'default', size = 'medium' }) => css`
    ${containerSizeModifiers[size]()};
    ${containerTemplateModifiers[template](theme)};

    display: flex;
    align-items: center;
    justify-content: center;

    height: var(--size);
    width: var(--size);

    border-radius: 0.25rem;
  `}
`;
