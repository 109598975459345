import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { FiFile, FiFilePlus } from 'react-icons/fi'

import { useField } from '@unform/core'
import PropTypes from 'prop-types'

import Loading from '~/components/Loading'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import * as S from './styles'

const FileInput: React.FC<FileInputProps> = ({
  name,
  onChange,
  children,
  isLoading,
  accept,
  isMultiple = false,
  hideIcons = false,
  ...rest
}) => {
  const { t } = useAPPTranslation()
  const inputRef = useRef(null)
  const [dragState, setDragState] = useState('off')

  const { fieldName, registerField, defaultValue } = useField(name)
  const [preview, setPreview] = useState(defaultValue)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = ''
        setPreview(null)
      },
      setValue(_, value) {
        setPreview(value)
      }
    })
  }, [fieldName, registerField])

  const handleDragEnter = e => {
    setDragState('enter')
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragLeave = e => {
    setDragState('leave')
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragOver = e => {
    setDragState('over')
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDrop = e => {
    setDragState('drop')
  }

  const handleChange = useCallback(
    e => {
      !!onChange && onChange(e)

      const files = [...e.target.files]

      setPreview(files.map(item => item.name))
    },
    [onChange]
  )

  return (
    <S.Container
      className="drag-drop-zone"
      isDraggingOver={dragState === 'over'}
      onDrop={e => handleDrop(e)}
      onDragOver={e => (dragState === 'over' ? null : handleDragOver(e))}
      onDragEnter={e => handleDragEnter(e)}
      onDragLeave={e => handleDragLeave(e)}
      isLoading={isLoading}
    >
      <div className="content">
        <div className="legend">
          {!isLoading && (
            <>
              <div className="icon">
                <FiFilePlus />
              </div>

              <Trans
                components={{ strong: <strong /> }}
                i18nKey="c:components.FileInput.messages.instructions"
                count={isMultiple ? 2 : 1}
              />

              <div>{children}</div>
            </>
          )}

          <Loading status={!!isLoading}>
            {t('c:components.FileInput.messages.loading')}
          </Loading>
        </div>

        {preview && !hideIcons && (
          <S.Preview>
            {preview.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <S.File key={index}>
                <div className="icon">
                  <FiFile />
                </div>{' '}
                {item}
              </S.File>
            ))}
          </S.Preview>
        )}
      </div>

      <input
        id={name}
        type="file"
        ref={inputRef}
        accept={accept}
        onChange={handleChange}
        multiple={isMultiple}
        {...rest}
      />
    </S.Container>
  )
}

export default FileInput

FileInput.defaultProps = {
  onChange: null,
  accept: null,
  isLoading: false
}

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isLoading: PropTypes.bool
}
