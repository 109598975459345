import { produce } from 'immer';

import reducerCases from './reducerCases';

const INITIAL_STATE = {
  // Indicador global de loading
  loading: false,
  // Indicador de loading ao criar
  loadingCreate: false,
  // Indicador de loading ao salvar
  loadingSaving: false,
  // Indicador global de error
  error: null,
  // Armazena todas as templates carregadas
  templates: null,
};

/**
 * Chat templates
 * Os templates de chats são textos pré-definidos para serem utilizados pelos atendentes do chat.
 */

const chatTemplates = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const reducer = Object.keys(reducerCases).filter(
      item => item === action.type,
    );

    if (reducer?.length) {
      reducerCases[reducer]({ state, draft, action });
    }
  });

export default chatTemplates;
