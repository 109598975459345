import { ButtonNavLink } from '~/components/Buttons'

import * as S from './styles'

const Tabs: React.FC<TabsProps> = ({ tabs }) => (
  <S.TabsWrapper className="mb-3 border-bottom border-2">
    {tabs?.map(({ label, to, ...rest }) => (
      <ButtonNavLink
        key={label}
        to={to}
        text={label}
        template="transparentPrimary"
        className="p-3"
        end
        {...rest}
      />
    ))}
  </S.TabsWrapper>
)

export default Tabs
