import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi'

import { IconCircle } from '../Icon/Circle'

const themes: BadgeIsValidTheme = {
  valid: {
    icon: FiCheckCircle,
    template: 'successFill'
  },
  invalid: {
    icon: FiAlertCircle,
    template: 'warningFill'
  }
}

const BadgeIsValid = ({ isValid }: BadgeIsValidProps) => (
  <IconCircle {...(isValid ? themes.valid : themes.invalid)} />
)

export default BadgeIsValid
