import { types } from '../constants';

const index = {
  pending: `${types.create}_PENDING`,
  fulfilled: `${types.create}_FULFILLED`,
  rejected: `${types.create}_REJECTED`,
};

export const create = {
  [index.pending]: ({ draft }) => {
    draft.campaign.create.loading = true;
    draft.campaign.create.error = null;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.campaign.item.data = action.payload;
    draft.campaign.create.loading = false;
    draft.campaign.create.error = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.campaign.create.loading = false;
    draft.campaign.create.error = action.payload;
  },
};
