import { ChartOptions } from 'chart.js'

import { chartPresets } from '..'

import clicksPreset from '../metrics/clicks'
import conversionsPreset from '../metrics/conversions'
import impressionsPreset from '../metrics/impressions'
import incomePreset from '../metrics/income'
import totalSpentPreset from '../metrics/totalSpent'
import viewsPreset from '../metrics/views'
import { ChartPreset } from '../types'

const datasets = ({ data }: Pick<MetricsChartConfigProps, 'data'>) =>
  [
    ...chartPresets.simple({ data }).datasets,
    viewsPreset.dataset({ data }),
    incomePreset.dataset({ data }),
    totalSpentPreset.dataset({ data })
  ] as ChartConfig[]

const chartOptions: ChartOptions<'line'> = {
  scales: {
    ...impressionsPreset.optionsScale({ drawOnChartArea: true }),
    ...clicksPreset.optionsScale({ drawOnChartArea: true }),
    ...conversionsPreset.optionsScale({ drawOnChartArea: true }),
    ...viewsPreset.optionsScale({ drawOnChartArea: true }),
    ...incomePreset.optionsScale({ drawOnChartArea: true }),
    ...totalSpentPreset.optionsScale({ drawOnChartArea: true })
  }
}

export const advancedChartPreset: ChartPreset = ({ data }) => ({
  datasets: datasets({ data }),
  chartOptions
})
