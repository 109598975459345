import React from 'react'

import PropTypes from 'prop-types'

import { Container, ContainerCircle } from './styles'

export const Spinner = ({ color: SpinnerColor }) => (
  <Container className="generic-spinner" color={SpinnerColor} />
)

Spinner.propTypes = {
  color: PropTypes.string
}

Spinner.defaultProps = {
  color: null
}

export const SpinnerCircle = ({ color: SpinnerColor }) => (
  <ContainerCircle className="generic-circle-spinner" color={SpinnerColor} />
)

SpinnerCircle.propTypes = {
  color: PropTypes.string
}

SpinnerCircle.defaultProps = {
  color: null
}
