import { Button } from '~/components/Buttons'
import Tooltip from '~/components/Tooltip'

import { actions, entities } from '../../lib/constants'
import { fieldNamesByEntity } from '../../lib/fieldNamesByEntity'

type RequestTitleProps = {
  data: AuditLogItemChange & AuditLogItemChangeDetail
}

const RequestTitle: React.FC<RequestTitleProps> = ({ data }) => {
  const { entity, action, user_name, user_email, field_name } = data

  const buttonText = entities[entity]
  const buttonTemplates: {
    [key in ResponseGetAuditLogEntities]: ButtonTemplates
  } = {
    ad: 'primary',
    campaign: 'success',
    campaign_keyword: 'info',
    campaign_category: 'info'
  }
  const buttonTemplate = buttonTemplates[entity]

  const actionText = actions?.[action] || action
  const fieldName = fieldNamesByEntity({
    entity: entity,
    action: action,
    fieldName: field_name
  })

  return (
    <div>
      <Button
        as="span"
        text={buttonText}
        template={buttonTemplate}
        size="xsmall"
        customWidth="5rem"
        style={{ padding: '0 .5rem', cursor: 'initial' }}
      />{' '}
      <Tooltip
        icon={<strong className="userName">{user_name}</strong>}
        text={user_email}
        positionProp="center-top"
      />{' '}
      <span className="action" style={{ fontWeight: 'normal' }}>
        {actionText}
      </span>{' '}
      <strong className="fieldName">{fieldName}</strong>
    </div>
  )
}

export default RequestTitle
