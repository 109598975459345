import { FiTag } from 'react-icons/fi'

import { Badge } from '~/components/UI/Badge'
import i18n from '~/i18n'

import ProductAdditionalReferences from './elements/ProductAdditionalReferences'
import ProductDate from './elements/ProductDate'
import ProductGtins from './elements/ProductGtins'
import ProductInfo from './elements/ProductInfo'
import ProductSellers from './elements/ProductSellers'
const { t } = i18n

const catalogTableSchema: TableSchema<PublisherProduct> = {
  info: {
    title: t('rm:config.catalog.list.table.column.main'),
    showTotal: true,
    width: 300,
    render: rowData => <ProductInfo data={rowData} key={rowData.id} />
  },
  sku: {
    title: t('rm:ProductsPanel.Sku'),
    render: rowData => (
      <Badge size="small" iconLeft={<FiTag />} text={rowData.product_sku} />
    )
  },
  gtin: {
    title: t('rm:ProductsPanel.Gtin'),
    render: rowData => <ProductGtins data={rowData} />
  },
  additionalReferences: {
    title: t('rm:ProductsPanel.AdditionalReferences'),
    render: rowData => <ProductAdditionalReferences data={rowData} />
  },
  sellers: {
    title: t('rm:config.catalog.list.table.column.sellers'),
    render: rowData => <ProductSellers data={rowData} />
  },
  brand: {
    title: t('rm:brand'),
    render: rowData => rowData.brand
  },
  updated_at: {
    title: t('c:terms.updated_at'),
    render: rowData => <ProductDate date={rowData.updated_at} />
  },
  created_at: {
    title: t('c:terms.created_at'),
    render: rowData => <ProductDate date={rowData.created_at} />
  }
}

export default catalogTableSchema
