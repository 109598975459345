import { types } from '../constants'

const index = {
  pending: `${types.listAll}_PENDING`,
  fulfilled: `${types.listAll}_FULFILLED`,
  rejected: `${types.listAll}_REJECTED`,
  setPage: types.listAllSetPage,
  setPerPage: types.listAllPerPage
}

export const listAll = {
  [index.pending]: ({ draft }) => {
    draft.industry.wallet.listAll.loading = true
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.wallet.listAll.data = action.payload.data

    draft.industry.wallet.listAll.pages = action.payload.pages
    draft.industry.wallet.listAll.total = action.payload.total
    draft.industry.wallet.listAll.page = action.payload.page

    draft.industry.wallet.listAll.loading = false
    draft.industry.wallet.listAll.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.wallet.listAll.loading = false
    draft.industry.wallet.listAll.error = action.payload
    draft.industry.wallet.listAll.total = 0
    draft.industry.wallet.listAll.data = null
  },
  [index.setPage]: ({ draft, action }) => {
    draft.industry.wallet.listAll.page = action.payload
  },
  [index.setPerPage]: ({ draft, action }) => {
    draft.industry.wallet.listAll.perPage = action.payload
  }
}
