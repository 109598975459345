import { produce } from 'immer';

import reducerCases from './reducerCases';

const INITIAL_STATE = {
  paymentMethodsLoading: false,
  paymentMethodsConfigCreateLoading: false,
  paymentMethodsConfigUpdateLoading: false,
  paymentMethodsConfigDeleteLoading: false,
  paymentMethodsConfigDeleteID: null,

  paymentMethodsList: {},
  inPersonMethods: [],
  onlineMethods: [],

  paymentMethodsError: null,
  paymentMethodsConfigList: [],
  paymentMethodsConfigLoading: false,
  paymentMethodsConfigError: null,
};

const payment = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const reducer = Object.keys(reducerCases).filter(
      item => item === action.type,
    );

    if (reducer?.length) {
      reducerCases[reducer]({ state, draft, action });
    }
  });

export default payment;
