import i18n from '~/i18n'

import { allowedContexts } from '../allowedContexts'

export const formatPlacementsWithContext = ({
  data,
  targeting,
  adType
}: FormatPlacementsWithContext) => {
  const filterPlacementsByType = data.filter(item =>
    item.formats.some(item => item.type === adType)
  )

  const placementsByContext = filterPlacementsByType.reduce(
    (acc, placement) => {
      const context = placement.context
      const hasTargeting = !!targeting

      const allowedContext = hasTargeting
        ? allowedContexts[`${targeting}`]?.includes(context)
        : allowedContexts.all

      if (allowedContext) {
        if (!acc[context]) {
          acc[context] = []
        }

        acc[context].push(placement)
      }

      return acc
    },
    {} as PlacementsByContext
  )

  const formattedPlacements = (placement: Placement[]) =>
    placement.map(({ id: value, name: label }) => ({
      value,
      label
    }))

  const groupedOptionsPlacements = Object.keys(placementsByContext).map(
    (item: CampaignContext) => ({
      label: i18n.t('common:capitalize', {
        value: i18n.t(`rm:context.${item}`)
      }),
      options: formattedPlacements(placementsByContext[`${item}`])
    })
  )

  return groupedOptionsPlacements
}
