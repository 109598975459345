import React from 'react';

import PropTypes from 'prop-types';
import { Slate, Editable } from 'slate-react';

import { DefaultElement } from './DefaultElement';
import { Leaf } from './Leaf';

const SlateWrapper = ({ editor, onChange, valueTextEditor, ...props }) => (
  <Slate editor={editor} value={valueTextEditor} onChange={onChange}>
    <Editable renderElement={DefaultElement} renderLeaf={Leaf} {...props} />
  </Slate>
);

export default SlateWrapper;

SlateWrapper.defaultProps = {
  placeholder: 'Digite o texto aqui...',
};

SlateWrapper.propTypes = {
  valueTextEditor: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editor: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};
