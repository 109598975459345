import React from 'react'
import { useTranslation } from 'react-i18next'

import HeaderPages from '~/components/HeaderPages'
import LanguageToggle from '~/components/LanguageToggle'
import PageTitle from '~/components/PageTitle'

// import FormCompany from './elements/form-company'
import FormGeneral from './elements/form-general'
import FormPassword from './elements/form-password'

const Profile = () => {
  const { t } = useTranslation()

  return (
    <>
      {/* Meta <title> */}
      <PageTitle title={t('config:profile.pageTitle')} />

      <HeaderPages title={t('config:profile.headerTitle')} />

      <FormGeneral />

      <FormPassword />

      <LanguageToggle />

      {/* <FormCompany /> */}
    </>
  )
}

export default Profile
