import { formatMetrics } from '../../common/metrics'

export const formatListSubPublisher: FormatSubPublisherList = data =>
  data.map(item => ({
    _raw: {
      originPath: 'src/modules/retailMedia/dtos/grocery/subPublisher/list.ts'
    },
    id: item.id,
    title: item.name,
    metrics: formatMetrics({ data: item.metrics }),
    logo_url: item.account_logo,
    account_theme: { ...item.account_theme }
  }))
