import React from 'react';
import { FiEdit3, FiScissors } from 'react-icons/fi';

import PropTypes from 'prop-types';

import * as S from './styles';

const Icons = ({ type, size }) => {
  const elements = {
    draw: {
      icon: FiEdit3,
      template: 'default',
    },
    forbidden: {
      icon: FiScissors,
      template: 'forbidden',
    },
  };

  const { template, icon: Icon } = elements[type];

  return (
    <S.Container size={size} template={template}>
      <Icon />
    </S.Container>
  );
};

export default Icons;

Icons.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
};

Icons.defaultProps = {
  type: 'draw',
  size: 'medium',
};
