import { formatViewCampaign } from '~/modules/retailMedia/dtos/common/campaign/get'

import { types } from '../constants'

const index = {
  pending: `${types.get}_PENDING`,
  fulfilled: `${types.get}_FULFILLED`,
  rejected: `${types.get}_REJECTED`
}

export const get = {
  [index.pending]: ({ draft }) => {
    draft.grocery.campaign.item.loading = true
    draft.grocery.campaign.item.error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.grocery.campaign.item.data = formatViewCampaign(action.payload)
    draft.grocery.campaign.item.loading = false
  },
  [index.rejected]: ({ draft }) => {
    draft.grocery.campaign.item.loading = false
    draft.grocery.campaign.item.error = true
  }
}
