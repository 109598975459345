const promotionalTexts = {
  menu: {
    list: 'Listagem de promoções',
    create: 'Criar promoção',
    view: 'Ver promoção',
    edit: 'Editar promoção',
    couponMetrics: 'Métricas de cupons',
  },
};

export { promotionalTexts };
