import * as types from '../constants';

const index = {
  pending: `${types.CUSTOMER_LIST}_PENDING`,
  fulfilled: `${types.CUSTOMER_LIST}_FULFILLED`,
  rejected: `${types.CUSTOMER_LIST}_REJECTED`,
  customersSetPage: types.CUSTOMER_LIST_SET_PAGE,
  customersPerPage: types.CUSTOMER_LIST_PER_PAGE,
};

export const listCustomers = {
  [index.pending]: ({ draft }) => {
    draft.listCustomersLoading = true;
    draft.error = null;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.listCustomers = action.payload;
    draft.listCustomersLoading = false;
    draft.error = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.listCustomersLoading = false;
    draft.error = action.payload;
  },
  [index.customersSetPage]: ({ draft, action }) => {
    draft.listCustomersPage = action.payload;
  },
  [index.customersPerPage]: ({ draft, action }) => {
    draft.listCustomersPerpage = action.payload;
  },
};
