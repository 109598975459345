import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../Buttons'

const LanguageToggle = () => {
  const { i18n } = useTranslation()

  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }

  const languages = [
    { code: 'pt-BR', label: 'Português' },
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Español' },
    { code: 'es-AR', label: 'Español (Argentina)' },
    { code: 'es-CO', label: 'Español (Colombia)' }
  ]

  return (
    <div className="d-flex gap-2 justify-content-center mt-3 pt-2 border-top flex-wrap">
      {languages.map(({ code, label }) => (
        <Button
          key={code}
          size="xsmall"
          template={
            i18n.language === code ? 'transparentPrimary' : 'transparent'
          }
          onClick={() => changeLanguage(code)}
        >
          {label}
        </Button>
      ))}
    </div>
  )
}

export default LanguageToggle
