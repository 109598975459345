/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useParams } from 'react-router-dom'

import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { FormGroup } from '~/components/unform'
import MessageWithMediaAndPreview from '~/components/unform/FormGroup/MessageWithMediaAndPreview'
import NameAndTimeInterval from '~/components/unform/FormGroup/NameAndTimeInterval'
import {
  getFileType,
  handleValidationsErrors,
  handleUploadProgressAxios
} from '~/helpers'
import campaignActions from '~/modules/messagingCampaigns/store/campaign/actions'

import {
  defaultInitialData,
  formatInitialData
} from '../../models/flyers/formInitialData'
import { createFlyer, updateFlyer } from '../../store/flyers/actions'
import FlyerLocations from './FlyersLocations'
import { schemaYup } from './yup'

const initialText =
  '🍅 No sacolão de ofertas da semana tem muita economia!\nOs melhores ingredientes para fechar o mês com o carrinho cheio! 🛒\n\nAhhh e o atendimento é até a meia noite! 🌒'

const scopePathMessage = 'messages[0]'

const FormFlyer = () => {
  const { id: flyerId } = useParams()

  const [previewMedia, setPreviewMedia] = useState(null)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const formRef = useRef(null)

  const dispatch = useDispatch()

  const {
    view: { loading: loadingView, data: flyer },
    create: { loading: loadingCreate },
    update: { loading: loadingUpdate }
  } = useSelector(({ promotionFlyer }) => promotionFlyer.flyers)

  // Refatorar DRY - Função igual usada em mais lugares
  const handlePreviewMedia = event => {
    setPreviewMedia(null)

    const file = event.target.files?.[0]

    if (!file) return

    const fileType = getFileType(file)

    const isFile = fileType === 'document'
    const isImage = fileType === 'image'

    if (!isFile && !isImage) {
      toastr.error('Erro', `Ainda não suportamos esse formato de arquivo.`)
    }

    const maxSizeInMBFile = 100
    const maxSizeInMBPicture = 5
    const maxSizeInMB = isFile ? maxSizeInMBFile : maxSizeInMBPicture
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024

    if (file.size > maxSizeInBytes) {
      const errorSizeMessages = {
        application: `O tamanho máximo permitido do arquivo é de ${maxSizeInMBFile}mb`,
        image: `O tamanho máximo permitido da imagem é de ${maxSizeInMBPicture}mb`
      }

      const errorMsg = errorSizeMessages[fileType]
      toastr.error('Erro', errorMsg)
      return
    }

    const previewURL = URL.createObjectURL(file)

    setPreviewMedia({ type: fileType, url: previewURL, name: file.name })
  }

  const handleUpload = useCallback(
    async event => {
      setLoadingUpload(true)

      handlePreviewMedia(event)

      const body = new FormData()

      try {
        const [file] = event.target.files
        body.append('file', file)

        const fileType = getFileType(file)

        const axiosOptions = handleUploadProgressAxios({
          onChange: setUploadProgress
        })

        /**
         * Função que será chamada ao final da promise disparada pelo Redux
         */
        const handleUploadSuccess = ({ data }) => {
          setPreviewMedia(oldState => ({ ...oldState, url: data.url }))
          setLoadingUpload(false)
          setUploadProgress(0)

          formRef?.current?.setFieldValue(
            `${scopePathMessage}.media.type`,
            fileType
          )
          formRef?.current?.setFieldValue(
            `${scopePathMessage}.media.url`,
            data.url
          )
          formRef?.current?.setFieldValue(
            `${scopePathMessage}.media.name`,
            file.name
          )
        }

        dispatch(
          campaignActions.upload({
            body,
            onSuccess: handleUploadSuccess,
            successFeedbackMsg: 'Mídia carregada com sucesso!',
            errorFeedbackMsg:
              'Houve um erro ao carregar a mídia. Tente novamente.',
            axiosOptions
          })
        )
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Erro', 'Houve um erro ao enviar a mídia.')
        setUploadProgress(0)
        setLoadingUpload(false)
      }
    },
    [dispatch, formRef]
  )

  const handleSubmit = useCallback(
    async formData => {
      try {
        formRef.current?.setErrors({})

        await schemaYup.validate(formData, {
          abortEarly: false
        })

        const requestData = { formData, id: flyerId }

        const action = flyerId ? updateFlyer : createFlyer

        dispatch(action(requestData))
      } catch (err) {
        console.error({ err })

        handleValidationsErrors(err, formRef)
      }
    },
    [dispatch, flyerId]
  )

  const formattedInitialData = useMemo(() => {
    if (!loadingView && flyer) {
      return formatInitialData(flyer)
    }

    return defaultInitialData
  }, [loadingView, flyer])

  useEffect(() => {
    if (formattedInitialData?.media?.url) {
      const { type, url, name } = formattedInitialData?.media
      setPreviewMedia({ type, url, name })
    }
  }, [formattedInitialData])

  /**
   * Data
   */

  const labelButton = useMemo(
    () => (flyerId ? 'Salvar encarte' : 'Criar encarte'),
    [flyerId]
  )

  const labelButtonLoading = useMemo(() => {
    if (loadingUpload) {
      return 'Enviando imagem...'
    }

    return flyerId ? 'Salvando encarte' : 'Criando encarte'
  }, [flyerId, loadingUpload])

  const isSaving = useMemo(
    () => loadingCreate || loadingUpdate,
    [loadingCreate, loadingUpdate]
  )

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={formattedInitialData}
    >
      <NameAndTimeInterval
        formRef={formRef}
        labelTitle="Nome do encarte"
        placeholderTitle="Digite uma boa descrição para o encarte"
        labelInterval="Período de validade"
        isEditMode={!!flyerId}
      />

      <MessageWithMediaAndPreview
        formRef={formRef}
        panelTitle="Conteúdo"
        initialText={formattedInitialData?.messages?.[0]?.text || initialText}
        handleUpload={handleUpload}
        isLoading={loadingUpload}
        uploadProgress={uploadProgress}
        mediaFile={previewMedia}
        onClearMedia={() => setPreviewMedia(null)}
        messagesIsRequired={false}
        path={scopePathMessage}
      />

      <FlyerLocations />

      <Loading status={isSaving || loadingUpload}>{labelButtonLoading}</Loading>

      <FormGroup className="actionButtons form-default__buttons">
        <Button
          type="submit"
          text={labelButton}
          textLoading={labelButtonLoading}
          loading={loadingView || isSaving}
          size="large"
          template="success"
        />
      </FormGroup>
    </Form>
  )
}

export default FormFlyer
