import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback
} from 'react'

import { useField } from '@unform/core'

import * as S from './styles'

const RadioInput = ({
  name,
  options = [],
  inline,
  itemSize = 'small',
  onChange,
  ...rest
}: RadioInputProps) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, registerField, defaultValue } = useField(name)
  const [selectedItemValue, setSelectedItemValue] = useState<string | boolean>()

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      //@ts-expect-error erro do unform - tratar assim que possível
      getValue: (refs: HTMLInputElement[]) => {
        const checkedInput = refs.find(ref => ref?.checked)

        return checkedInput?.value
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false
        })
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach(ref => {
          if (values?.includes(ref.id)) {
            ref.checked = true
          }
        })
      }
    })
  }, [defaultValue, fieldName, registerField])

  useLayoutEffect(() => {
    setSelectedItemValue(defaultValue)
  }, [defaultValue])

  const handleChange = useCallback(
    (data: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(data)

      const { value } = data.target

      setSelectedItemValue(value)
    },
    [onChange]
  )

  return (
    <S.Container className="radioListWrapper" inline={inline}>
      {options.map((option, index) => (
        <React.Fragment key={`${name}${option.value}`}>
          <S.Item
            hasActiveChildren={!!option?.activeChildren}
            isSelected={selectedItemValue === option.value}
            size={itemSize}
            className={`radioFieldWrapper ${
              option?.disabled ? 'disabled' : ''
            }`}
          >
            <input
              defaultChecked={defaultValue == option.value}
              ref={ref => {
                inputRefs.current[index] = ref as HTMLInputElement
              }}
              //@ts-expect-error erro do unform - tratar assim que possível
              value={option.value}
              type="radio"
              id={option?.id || `${name}${option.value}`}
              name={name}
              disabled={option?.disabled}
              onChange={handleChange}
              {...rest}
            />

            <label htmlFor={option.id || `${name}${option.value}`}>
              <S.LabelWrapper>
                {option.icon && <S.Icon className="icon">{option.icon}</S.Icon>}
                {option.label && (
                  <>
                    <S.LabelText className="label">{option.label}</S.LabelText>
                    {option.sideRight}
                  </>
                )}
              </S.LabelWrapper>
              {option.description && (
                <S.Description>{option.description}</S.Description>
              )}
            </label>
          </S.Item>

          {option?.activeChildren && selectedItemValue === option.value && (
            <S.ActiveChildren>{option.activeChildren}</S.ActiveChildren>
          )}
        </React.Fragment>
      ))}
    </S.Container>
  )
}

export default RadioInput
