import React from 'react';

import Panel from '~/components/Panel';

import SelectedProductsTable from './SelectedProductsTable';

const SelectedProducts = () => {
  return (
    <Panel
      title="Produtos selecionados"
      description="Aqui estão os produtos selecionados que serão inclusos na campanha"
    >
      <SelectedProductsTable />
    </Panel>
  );
};

export default SelectedProducts;
