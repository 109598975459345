import React from 'react';

import { paymentStatus } from '~/helpers';

import { AuthorizationTypes } from './types';

const PaymentStatus = ({ payment }) => {
  return (
    <>
      {' • '}
      <strong>Status:</strong> <>{paymentStatus(payment?.status)}</>
    </>
  );
};

export default PaymentStatus;

PaymentStatus.propTypes = AuthorizationTypes;
