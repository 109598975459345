import React, { useState } from 'react'

import CardIssuer from './CardIssuer'
import FraudAnalysisButton from './FraudAnalysisButton'
import PaymentAuthorization from './PaymentAuthorization'
import PaymentCancelButton from './PaymentCancelButton'
import PaymentDate from './PaymentDate'
import PaymentMethod from './PaymentMethod'
import PaymentMethodInfo from './PaymentMethodInfo'
import PaymentStatus from './PaymentStatus'
import PaymentValue from './PaymentValue'
import Refusal from './Refusal'
import RefusalButton from './RefusalButton'
import ReservedValue from './ReservedValue'
import { AuthorizationTypes } from './types'

import * as S from './styles'

const PaymentItem = ({ payment, orderId, hasWaitingRefund }) => {
  const [refusal, setRefusal] = useState()

  return (
    <S.Container className={payment.status}>
      {/*  */}
      <PaymentDate payment={payment} />
      {/*  */}
      <PaymentValue payment={payment} />
      {/*  */}
      <PaymentMethod payment={payment} />
      {/*  */}
      <PaymentMethodInfo payment={payment} />
      {/*  */}
      <CardIssuer payment={payment} />
      {/*  */}
      <PaymentStatus payment={payment} />
      {/*  */}
      <ReservedValue payment={payment} />

      {/*  */}
      {!!payment?.autorization_id && (
        <>
          <PaymentAuthorization payment={payment} />
          {/*  */}
          {payment?.value > 0 && (
            <PaymentCancelButton
              payment={payment}
              orderId={orderId}
              hasWaitingRefund={hasWaitingRefund}
            />
          )}
        </>
      )}

      {/*  */}
      <FraudAnalysisButton payment={payment} orderId={orderId} />
      {/*  */}
      <RefusalButton
        payment={payment}
        setRefusal={setRefusal}
        refusal={refusal}
      />
      {/*  */}
      <Refusal payment={payment} refusal={refusal} />
    </S.Container>
  )
}

export default PaymentItem

PaymentItem.propTypes = AuthorizationTypes
