import { types } from '../constants'

const index = {
  pending: `${types.recharge}_PENDING`,
  fulfilled: `${types.recharge}_FULFILLED`,
  rejected: `${types.recharge}_REJECTED`
}

export const recharge = {
  [index.pending]: ({ draft }) => {
    draft.industry.wallet.recharge.loading = true
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.wallet.recharge.data = action.payload.data
    draft.industry.wallet.recharge.error = null
    draft.industry.wallet.recharge.loading = false
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.wallet.recharge.error = action.payload
    draft.industry.wallet.recharge.data = null
    draft.industry.wallet.recharge.loading = false
  }
}
