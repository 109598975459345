import styled from 'styled-components'

import { device } from '~/styles/device'

export const Container = styled.div`
  display: flex;
  padding: 13px;
  gap: 0.25rem;
  flex-wrap: wrap;

  @media ${device.laptop} {
    padding: 0 8px 8px;
  }
`
