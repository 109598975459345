import React from 'react'

import PropTypes from 'prop-types'

import { useMapDraw } from '../../hooks/useMapDraw'
import ListRegions from '../ListRegions'
import CreatePanel from './CreatePanel'
import EditPanel from './EditPanel'
import SelectLocations from './SelectLocations'

import * as S from './styles'

const SidebarRegions = ({ isPreview }) => {
  const { isEditingMode, drawing } = useMapDraw()

  return (
    <S.Sidebar>
      {((!isEditingMode && !drawing) || isPreview) && (
        <SelectLocations isPreview={isPreview} />
      )}

      {!isEditingMode && !isPreview && <CreatePanel />}

      {((!isEditingMode && !drawing) || isPreview) && (
        <ListRegions isPreview={isPreview} />
      )}

      {isEditingMode && !isPreview && <EditPanel />}
    </S.Sidebar>
  )
}

export default SidebarRegions

SidebarRegions.propTypes = {
  isPreview: PropTypes.bool
}

SidebarRegions.defaultProps = {
  isPreview: false
}
