import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styles'

/**
 *
 *  {
 *    label: string
 *    value: string | number | boolean
 *   }[]
 *
 */

const Debug = ({ values, isFixed = true, ...rest }) => {
  return (
    <S.Container isFixed={isFixed} {...rest}>
      {values.map(item => (
        <small key={`${Math.random()}`}>
          <strong>{item.label}:</strong> {`${item.value}`}
        </small>
      ))}
    </S.Container>
  )
}

export default Debug

Debug.defaultValues = {
  isFixed: true
}

Debug.propTypes = {
  isFixed: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    })
  ).isRequired
}
