import React from 'react';
import { connect } from 'react-redux';

import Panel from '~/components/Panel';
import LineChart from '~/components/charts/line-chart';

const RevenueChart = ({ loading, data = {} }) => (
  <Panel title="Receita" isLoading={loading}>
    <LineChart data={data.incomeHomeDash} loading={loading} />
  </Panel>
);

export default connect(store => ({
  theme: store.theme.currentTheme,
}))(RevenueChart);
