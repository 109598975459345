import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    max-width: 100%;
    padding-bottom: 0.5rem;

    overflow-y: auto;
    position: relative;

    /* width */
    ::-webkit-scrollbar {
      height: 1rem;
      border-radius: 0.75rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.colors.tertiary};
      border-radius: 0.5rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.primary};
      border-radius: 0.5rem;
      /* Handle on hover */
      &:hover {
        background: ${theme.colors.hover};
      }
    }

    height: 100%;

    & > * + * {
      margin-left: 1rem;
    }
  `}
`

export const LoadingMaster = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;

    background-color: rgba(255, 255, 255, 0.75);

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
  `}
`
