import React from 'react'
import { NavLink } from 'react-router-dom'
import { t } from 'i18next'

import { LogoStore } from '~/components/LogoStore'
import { useAppSelector } from '~/store/hooks'

import * as S from '../styles'

type CheckingAccountRowProps = {
  data: GetCheckingAccountWithAccountInfoResponse
}

const CheckingAccountRow = ({ data }: CheckingAccountRowProps) => {
  const { accountType } = useAppSelector(state => state.signin)
  const key = accountType === 'grocery' ? 'advertiser' : 'publisher'

  const accountInfo = {
    bgColor: data?.[`${key}_account_theme`]?.header_color,
    urlLogo: data?.[`${key}_account_logo`],
    name: data?.[`${key}_name`] || t('rm:account.name.empty'),
    id: data?.[`${key}_id`]
  }

  return (
    <NavLink
      to={`/retail-media/wallet/${data?.id}/movimentations?rmid=${accountInfo.id}`}
      className="d-flex align-items-center gap-2"
    >
      <LogoStore
        backgroundLogo={accountInfo.bgColor}
        linkLogo={accountInfo.urlLogo}
        alt={accountInfo.name}
        size="small"
      />

      <strong>{accountInfo.name}</strong>
    </NavLink>
  )
}

export default CheckingAccountRow
