import React from 'react';

import HeaderPages from '~/components/HeaderPages';
import PageTitle from '~/components/PageTitle';

import FormOperator from '../_elements/FormOperator';

const OperatorsNew = () => (
  <>
    <PageTitle title="Operadores > Listagem" />

    <HeaderPages
      title="Adicionar operador"
      labelBreadcrumb="Operadores"
      backTo="/operators/list"
    />

    <FormOperator />
  </>
);

export default OperatorsNew;
