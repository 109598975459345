import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 0.25rem 0;

    & + & {
      border-top: 1px solid ${theme.colors.outlineGray};
    }

    &.focused {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: -1px;
        left: -0.5rem;
        width: calc(100% + 1rem);
        z-index: 0;
        background: ${theme.colors.neutral.high.medium};
        border-radius: 0.5rem;
        height: calc(100% + 2px);
      }
    }

    > .wrapperInfo {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      flex: 1;

      position: relative;
      z-index: 1;

      &.active:hover {
        color: var(--hover);
      }
    }
  `}
`
//
type ContainerLogoProps = {
  bgLogo?: string
}

export const ContainerLogo = styled.div<ContainerLogoProps>`
  ${({ theme, bgLogo }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    width: 48px;
    height: 48px;

    border-radius: 50%;

    background: ${theme.colors.gray};
    color: ${theme.colors.stormGray};
    border: 1px solid ${theme.colors.outlineGray};
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: ${bgLogo || 'white'};
      padding: 0.25rem;
    }
  `}
`

export const AccountInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
    line-height: 1.125;
    flex: 1;

    > strong {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.email {
        color: ${theme.colors.primary};
        font-size: 0.75rem;
      }
    }
  `}
`

type DefaultAccountProps = {
  isDefault: boolean
}

export const DefaultAccount = styled.button<DefaultAccountProps>`
  ${({ theme, isDefault }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    z-index: 1;

    color: ${isDefault ? theme.colors.warning : theme.colors.primary};

    transition: 0.1s ease-in-out all;

    &:hover {
      color: ${theme.colors.warning};
    }
  `}
`
