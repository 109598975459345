import { useState } from 'react'
import Cards from 'react-credit-cards-2'
import 'react-credit-cards-2/dist/es/styles-compiled.css'

import cardValidations from 'card-validator'

import { FormGroup, Input, InputMask, Label } from '~/components/unform'

import { FormCard } from './validations'

import * as S from './styles'

export const CreditCardForm = ({ onChange }) => {
  const fieldMap = {
    card_name: 'name',
    card_number: 'number',
    card_expiration: 'expiry',
    card_cvv: 'cvc'
  }

  const [card, setCard] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: ''
  })

  const handleInputFocus = e => {
    const name = e.target.name
    setCard(prev => ({ ...prev, focus: fieldMap[name] }))
  }

  const handleChangeCardInputs = e => {
    const { name, value } = e.target || e
    setCard(prev => ({ ...prev, [fieldMap[name]]: value }))

    const data = {
      card_name: card.name,
      card_number: card.number,
      card_expiration: card.expiry,
      card_cvv: card.cvc,
      [name]: value
    }

    try {
      const response = FormCard.validateSync(data, { abortEarly: false })

      const {
        card: { type: brand }
      } = cardValidations.number(data.card_number)

      onChange({ isValid: true, brand })
    } catch (err) {
      onChange({ isValid: false })
    }
  }

  return (
    <S.CardForm>
      <fieldset>
        <FormGroup>
          <Label>Nome do titular do cartão</Label>
          <Input
            name="card_name"
            onChange={handleChangeCardInputs}
            onBlur={handleChangeCardInputs}
            onFocus={handleInputFocus}
            placeholder="Nome impresso no cartão"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Número do cartão</Label>
          <InputMask
            name="card_number"
            format="#### #### #### ####"
            mask="•"
            placeholder="•••• •••• •••• ••••"
            onFocus={handleInputFocus}
            onChange={handleChangeCardInputs}
            onBlur={handleChangeCardInputs}
            required
          />
        </FormGroup>
        <S.CardDateAndCVVWrapper>
          <FormGroup>
            <Label>Validade</Label>
            <InputMask
              name="card_expiration"
              format="##/##"
              placeholder="MM/AA"
              mask="•"
              onFocus={handleInputFocus}
              onChange={handleChangeCardInputs}
              onBlur={handleChangeCardInputs}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>CVV</Label>
            <InputMask
              name="card_cvv"
              format="###"
              placeholder="•••"
              mask="•"
              onFocus={handleInputFocus}
              onChange={handleChangeCardInputs}
              onBlur={handleChangeCardInputs}
              required
            />
          </FormGroup>
        </S.CardDateAndCVVWrapper>
      </fieldset>

      <aside>
        <Cards
          cvc={card.cvc}
          expiry={card.expiry}
          focused={card.focus}
          name={card.name}
          number={card.number}
          locale={{ valid: 'Válido até' }}
          placeholders={{
            name: 'Nome impresso no cartão'
          }}
        />
      </aside>
    </S.CardForm>
  )
}
