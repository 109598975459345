import React, { useMemo, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Input } from '~/components/unform';
import { compareStrings } from '~/helpers';

import Method from '../Method';
import * as S from './styles';

// Componente para exibição do tipo de pagamento presencial

const Type = ({
  type,
  scope,
  title,
  subtitle,
  locationId,
  onSubMethodClick,
  formRef,
}) => {
  const [search, setSearch] = useState('');
  const { inPersonMethods, onlineMethods } = useSelector(
    ({ payment }) => payment,
  );

  const methods = useMemo(
    () => (type === 'online' ? onlineMethods : inPersonMethods),
    [inPersonMethods, onlineMethods, type],
  );

  const handleInputSearch = ({ value }) => {
    setSearch(value);
  };

  const filteredPaymentMethods = useMemo(() => {
    if (!search) return methods;

    return methods.filter(
      method =>
        compareStrings(method.label, search) ||
        method.sub_methods.some(subMethod =>
          compareStrings(subMethod.label, search),
        ),
    );
  }, [methods, search]);

  return (
    <S.Container>
      <S.Title>{title}</S.Title>

      <S.Subtitle>{subtitle}</S.Subtitle>

      <Input
        name="payment_method"
        placeholder="Busque por forma de pagamento ou bandeira"
        icon={FiSearch}
        onChange={handleInputSearch}
        value={search}
      />

      <S.ContainerMethods>
        {filteredPaymentMethods.map(
          ({ _id: methodId, method, label, sub_methods }, index) => {
            const baseIdentifier = `${
              locationId || 'global'
            }_method_${methodId}`;

            return (
              <Method
                key={methodId}
                path={`${scope}[${index}]`}
                label={label}
                baseIdentifier={baseIdentifier}
                method={method}
                subMethods={sub_methods}
                onSubMethodClick={onSubMethodClick}
                formRef={formRef}
              />
            );
          },
        )}
      </S.ContainerMethods>
    </S.Container>
  );
};

Type.defaultProps = {
  locationId: null,
  onSubMethodClick: null,
};

Type.propTypes = {
  type: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  locationId: PropTypes.string,
  onSubMethodClick: PropTypes.func,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
};

export default Type;
