/* eslint-disable indent */
import styled, { css } from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
`;

export const Bar = styled.div`
  background-color: ${({ theme }) => theme.colors.tertiary};
  border-radius: 50px;
  height: 8px;
  position: relative;
  width: 100px;
`;

const progressModifiers = {
  default: ({ theme, percentage }) =>
    css`
      --bg-color: ${percentage < 100
        ? theme.colors.primary
        : theme.colors.success};
    `,
  success: ({ theme }) =>
    css`
      --bg-color: ${theme.colors.success};
    `,
  warning: ({ theme }) =>
    css`
      --bg-color: ${theme.colors.warning};
    `,
  danger: ({ theme }) =>
    css`
      --bg-color: ${theme.colors.danger};
    `,
};

export const Progress = styled.div`
  ${({ theme, percentage, isDesc, template = 'default' }) => css`
    ${progressModifiers[template]({ theme, percentage })}

    border-radius: inherit;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: right;
    top: 0;
    width: ${`${Math.min(isDesc ? 100 - percentage : percentage, 100)}%`};

    background-color: var(--bg-color);
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.dark};
    font-size: ${theme.font.sizes.xsmall};
    line-height: 1;
    margin-left: ${theme.spacings.xxxsmall};
  `}
`;
