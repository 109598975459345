import React, { useCallback } from 'react'
import { FiX, FiXCircle } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import useConfirmationModal from '~/hooks/useConfirmationModal'
import campaignActions from '~/modules/messagingCampaigns/store/campaign/actions'

const ButtonCancel = ({ id, keepsMenuOpen, template }) => {
  const [ConfirmationhModal, showConfirmationModal, toggleConfirmationModal] =
    useConfirmationModal()

  const { loading } = useSelector(state => state.promotional)

  const handleToogle = useCallback(() => {
    keepsMenuOpen && keepsMenuOpen(oldState => !oldState)

    toggleConfirmationModal()
  }, [keepsMenuOpen, toggleConfirmationModal])

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const {
    campaign: {
      list: { page, perPage }
    }
  } = useSelector(state => state.messagingCampaigns)

  const handleSubmit = useCallback(async () => {
    const actions = await campaignActions.cancelCampaign(id)
    const response = await actions.payload

    response.status === 200 && navigate('/messaging-campaigns/list')

    dispatch(
      campaignActions.list({
        page,
        quantity: perPage
      })
    )

    handleToogle()
  }, [id, handleToogle, dispatch, navigate, page, perPage])

  return (
    <>
      {template === 'menu' && (
        <Button
          template="deleteOutline"
          customWidth="auto"
          iconLeft={<FiX />}
          text="Cancelar"
          onClick={handleToogle}
        />
      )}

      {template === 'header' && (
        <Button
          template="transparentDanger"
          size="small"
          customWidth="auto"
          iconLeft={<FiXCircle />}
          text="Cancelar"
          onClick={handleToogle}
        />
      )}

      {/* MODAL */}
      <ConfirmationhModal
        title="Deseja cancelar a campanha?"
        isOpen={showConfirmationModal}
        onClose={handleToogle}
        onSubmit={handleSubmit}
        confirmationButton="Entendo e desejo cancelar esta campanha"
      >
        <p>
          Esta ação não poderá ser desfeita. A campanha permanecerá na listagem
          e poderá ser duplicada no futuro.
        </p>
        {loading && <Loading status={loading}>Cancelando campanha...</Loading>}
      </ConfirmationhModal>
    </>
  )
}

ButtonCancel.propTypes = {
  id: PropTypes.string.isRequired,
  keepsMenuOpen: PropTypes.func,
  template: PropTypes.oneOf(['header', 'menu'])
}

ButtonCancel.defaultProps = {
  keepsMenuOpen: null,
  template: 'menu'
}

export default ButtonCancel
