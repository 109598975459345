import React, { useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Filters } from '~/components/Filters';
import HeaderPages from '~/components/HeaderPages';
import PageTitle from '~/components/PageTitle';
import { hideSidebar } from '~/components/sidebar/sidebar-actions';
import { history } from '~/helpers';
import { resetFilterLocationRegion } from '~/store/modules/filters/actions';

import { useLogisticSlots } from '../../useLogisticSlots';
import Board from '../Board';
import SlotNav from '../Nav';

const WrapperSlots = () => {
  const {
    locationId: routeLocationId,
    regionId: routeRegionId,
    mode,
  } = useParams();

  const dispatch = useDispatch();

  const {
    locations: filteredLocations,
    locationRegions: filteredLocationRegions,
  } = useSelector(state => state.filters);

  const locationId = useMemo(
    () => routeLocationId || filteredLocations?.value || null,
    [routeLocationId, filteredLocations],
  );

  const regionId = useMemo(
    () => routeRegionId || filteredLocationRegions?.value || null,
    [routeRegionId, filteredLocationRegions],
  );

  /**
   * Lidando com as mudanças dos filtros
   */

  const handleChangeLocation = data => {
    dispatch(resetFilterLocationRegion());
    history.replace(`/logistic/slots/${mode}/${data?.value || ''}`);
  };

  const handleChangeRegion = data =>
    history.replace(
      `/logistic/slots/${mode}/${locationId}/${data?.value || ''}`,
    );

  /**
   * Setando título da página
   */

  const pageTitle = {
    weekdays: 'Dias da semana',
    exceptions: 'Exceções',
    'without-work': 'Dias sem funcionamento',
  };

  /**
   * Escondando menu ao carregar
   */

  useLayoutEffect(() => {
    dispatch(hideSidebar());
  }, [dispatch]);

  /**
   * Setando configurações do quadro
   */

  const { setBoardConfig } = useLogisticSlots();

  useEffect(() => {
    setBoardConfig({ locationId, regionId, mode });
  }, [setBoardConfig, locationId, regionId, mode]);

  return (
    <>
      <PageTitle
        title={`Slots de entrega - ${pageTitle[mode || 'weekdays']}`}
      />

      <HeaderPages
        title="Slots de entrega"
        sideRightComponent={
          <Filters
            locations
            labelAllLocation="Configuração global"
            initialLocation={locationId}
            onChangeLocation={handleChangeLocation}
            //
            locationRegions
            labelAllRegion="Configuração global"
            initialLocationRegion={locationId}
            initialRegion={regionId}
            onChangeRegion={handleChangeRegion}
            //
            align="right"
            columns={2}
          />
        }
      />

      <SlotNav locationId={locationId} />

      {mode && <Board />}
    </>
  );
};

export default WrapperSlots;
