/* eslint-disable max-len */
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import debounce from 'lodash/debounce'

import SelectSearchable from '~/components/SelectSearchable'
import { getAccountType } from '~/helpers'
import { setFilter } from '~/store/modules/filtersSellOut/actions'
import { FiltersSellOutKeysTypes } from '~/store/modules/filtersSellOut/constants'

import { customStyles, MultiValue, ValueContainer } from '../customSelect'
import { initialRequestToGetValues } from '../helpers/initialRequest'
import { FiltersDataKeys, filtersSellOutDataset } from './data'

/**
 * Componente para filtrar usandos dados recebidos pelo metabase.
 * Este é um filtro com valores dinâmicos. Os options são dados obtidos com a API.
 * Atualmente o único service é o Metabase e está estático no código.
 *
 * Cada item teu sem arquivo de configuração salvo em: `/data/[filterKey].ts`
 *
 * Os filtros podem ser interligados através do campo `filteredBy` setado nas configurações.
 *
 * Ele possibilita a seleção de diversos valores e mantém no estado global: `filtersSellOut[filterKey]`.
 *
 * Recebe uma chabe como parâmetro que traz chaves, definições e textos necessários para o componente.
 *
 *  @param { string } filterKey Chave responsável por montar o componente com os dados certos recebidos do filtersData
 *
 * @example
 * <GenericFilter filterKey={FiltersDataKeys} />
 */

type GenericFilter = {
  filterKey: FiltersDataKeys
}

const GenericFilter = ({ filterKey }: GenericFilter) => {
  const isGrocery = getAccountType() === 'grocery'

  const staticData = filtersSellOutDataset[filterKey]

  const {
    filteredBy = null,
    placeholder,
    placeholderError,
    labelMulti,
    labelAll,
    labelSearchAnything,
    searching,
    bigData
  } = staticData

  const type = filterKey

  const isSearchable = bigData && isGrocery

  const [rawOptions, setRawOptions] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [preLoading, setPreLoading] = useState(false)
  const [query, setQuery] = useState(null)

  const dispatch = useDispatch()

  const allFilters = useSelector(({ filtersSellOut }) => filtersSellOut)

  /**
   * Lidando com o filtro e ações no estado global
   */

  // const state = useSelector(state => state.filters)
  const filteredValues = allFilters[type]

  const handleChange = data => {
    const shouldReset = data?.findIndex(item => item?.reset) > -1

    const updatedStatus = shouldReset ? null : data

    dispatch(setFilter({ type, data: updatedStatus }))
  }

  /**
   * Este é um filtro com valores dinâmicos.
   * Os options são dados obtidos com a API.
   */

  const handleLoading = (value: boolean) => {
    setLoading(value)
    setPreLoading(value)
  }

  type StateItem = {
    key: Exclude<FiltersSellOutKeysTypes, 'aggregate'>
    value: { label: string; value: string }[]
  }

  const findOnState = useCallback(
    (filterKey: Exclude<FiltersSellOutKeysTypes, 'aggregate'>) => {
      const keyIsValid = Object.keys(allFilters).indexOf(filterKey) > -1

      if (!keyIsValid) {
        console.log(`Invalid key filter: ${filterKey}`)

        return null
      }

      return { key: filterKey, value: allFilters[filterKey] }
    },
    [allFilters]
  )

  const filterValues = useMemo(() => {
    const getFilteredByValue = filteredBy?.map(findOnState)

    const response = getFilteredByValue?.reduce((acc, item: StateItem) => {
      const key = filtersSellOutDataset[item.key]?.metabaseType

      if (!item?.value || !key) return acc

      return {
        ...acc,
        [key]: {
          value: item.value.map(item => item.value),
          data_type: 'string',
          operator: 'equal'
        }
      }
    }, {})

    return response
  }, [filteredBy, findOnState])

  const searchableValue = useMemo(() => {
    const queryBody = {
      label: {
        value: query
      }
    }

    return isSearchable ? queryBody : {}
  }, [isSearchable, query])

  const body = useMemo(
    () => ({
      values: {
        ...filterValues,
        ...searchableValue
      }
    }),
    [filterValues, searchableValue]
  )

  const initialRequestData = useMemo(
    () => ({
      type,
      onSuccess: setRawOptions,
      onError: setError,
      handleLoading,
      body
    }),
    [type, body]
  )

  useEffect(() => {
    if (query || !isSearchable) {
      initialRequestToGetValues(initialRequestData)
      setLoading(true)
    }
  }, [query, initialRequestData, isSearchable])

  const options = useMemo(() => {
    const initialOption =
      query || !isSearchable
        ? { label: labelAll, value: null, reset: true }
        : { label: labelSearchAnything || labelAll, value: null }

    const data = [{ ...initialOption }, ...rawOptions]

    return data
  }, [query, isSearchable, labelAll, labelSearchAnything, rawOptions])

  /**
   * executeDebounceFunc()
   *
   * A função executeDebounceFunc serve para certificar que sempre estaremos
   * executando a versão atualizada da queryString que se mantém
   * atualizada através do useCallback.
   * */

  const executeDebounceFunc = func => func()

  /**
   * Handling a delay to not overlap calls
   * Executa a função após 1 segundo sem interação com o componente
   */
  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  /**
   * Handle search term
   */
  const handleQueryTerm = useCallback(
    term => {
      setPreLoading(!!term)

      if (term !== '') {
        handleDebounce(() => setQuery(term))
      }
    },
    [handleDebounce]
  )

  /**
   * Placeholder
   */

  const formattedPlaceholder = useMemo(() => {
    if (loading) return searching

    if (error) return placeholderError

    if (query) return `${placeholder} (filtrado por: ${query})`

    return placeholder
  }, [error, loading, placeholder, placeholderError, searching, query])

  return (
    <SelectSearchable
      placeholder={formattedPlaceholder}
      options={options}
      onChange={handleChange}
      defaultValue={filteredValues}
      loading={loading || preLoading}
      isMulti
      hideSelectedOptions={false}
      labelMulti={labelMulti}
      components={{ ValueContainer, MultiValue }}
      styles={customStyles}
      closeMenuOnSelect={false}
      loadingMessage={() => 'Carregando...'}
      noOptionsMessage={() => 'Nenhum resultado.'}
      allowCreateWhileLoading
      {...(isSearchable ? { onInputChange: handleQueryTerm } : {})}
    />
  )
}

export default GenericFilter

GenericFilter.defaultProps = {
  filteredBy: null
}
