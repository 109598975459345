import React, { ChangeEvent, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Form } from '@unform/web'

import { Button, ButtonNavLink } from '~/components/Buttons'
import { userRolesOptions } from '~/components/FilterUserRoles'
import Panel from '~/components/Panel'
import { FormGroup, Input, Label } from '~/components/unform'
import ReactSelect from '~/components/unform/ReactSelect'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { useAppSelector } from '~/store/hooks'

import { submitFormUser } from '../store/actions'

type FormData = {
  name: string
  lastname?: string
  email: string
  role: UserRoles
}

type OnChangeRole = {
  label: string
  value: UserRoles
} | null

const GeneralForm = () => {
  const [formData, setFormData] = useState<FormData>({} as FormData)

  const dispatch = useDispatch()
  const { t } = useAPPTranslation()

  const { loadingSaveUser } = useAppSelector(state => state.user)

  const beforeSubmit = (formData: FormData) => {
    const data = {
      ...formData,
      name: `${formData.name} ${formData.lastname}`.trim()
    }

    dispatch(submitFormUser(data))
  }

  const handleChange = (event: ChangeEvent<HTMLFormElement>) => {
    const { name, value } = event.target

    setFormData(prevState => ({ ...prevState, [name]: value }))
  }

  const handleChangeRole = (data: OnChangeRole) =>
    setFormData(prevState => ({ ...prevState, role: data?.value || null }))

  const formIsValid = useMemo(
    () => formData.name && formData.email && formData.role,
    [formData]
  )

  return (
    <Form onSubmit={beforeSubmit} data-cy="form-user" onChange={handleChange}>
      <Panel className="form-grid">
        <FormGroup className="span2">
          <Label isRequired text={t('users:name')} />
          <Input
            name="name"
            required
            showWarningOnRequired
            showBadgeIsvalid={!!formData?.name}
          />
        </FormGroup>

        <FormGroup className="span2">
          <Label text={t('users:surname')} />
          <Input name="lastname" />
        </FormGroup>

        <FormGroup className="span2 span-start">
          <Label isRequired text={t('users:email')} />
          <Input
            type="email"
            name="email"
            showBadgeIsvalid={!!formData?.email}
          />
        </FormGroup>

        <FormGroup className="span2  span-start">
          <Label isRequired text={t('users:permission')} />
          <ReactSelect
            options={userRolesOptions.slice(1)}
            placeholder={t('users:selectPermission')}
            name="role"
            onChange={handleChangeRole}
            isClearable={false}
          />
          {/* <FilterUserRoles ignoreRedux /> */}
        </FormGroup>

        <footer className="form-default__buttons justify-content-between">
          <ButtonNavLink
            to="/users/list"
            text={t('common:actions.cancel')}
            data-cy="button-update-user-cancel"
            template="transparent"
          />
          <Button
            text={t('users:sendInvite')}
            loading={loadingSaveUser}
            data-cy="button-update-user"
            disabled={loadingSaveUser || !formIsValid}
            type="submit"
          />
        </footer>
      </Panel>
    </Form>
  )
}

export default GeneralForm
