type HandleQueryParamsProps = {
  params: { [key: string]: unknown }
  currentParams: { [key: string]: unknown }
  dynamicKeys: string[]
}

type HandleQueryParamsResponse = { shouldResetFixedKeys: boolean }
type HandleQueryParams = (
  props: HandleQueryParamsProps
) => HandleQueryParamsResponse

/**
 * Função para manipular os parâmetros da consulta.
 *
 * Esta função valida quais chaves são dinâmicas, ou seja, podem alterar a quantidade final de resultados
 * e, com isso, podem afetar a paginação da consulta.
 *
 * @param {Object} options - Um objeto contendo os parâmetros da consulta.
 * @param {Object} options.params - Os novos parâmetros da consulta.
 * @param {string[]} options.dynamicKeys - As chaves dinâmicas para os parâmetros da consulta.
 * @param {Object} options.currentParams - Os parâmetros atuais da consulta.
 * @returns {Object} - Um objeto contendo a chave shouldResetFixedKeys indicando
 * se as chaves fixas devem ser redefinidas na consulta.
 */

const handleQueryParams: HandleQueryParams = ({
  params,
  dynamicKeys,
  currentParams
}) => {
  // Filtra as chaves dinâmicas que foram alteradas
  const changedDynamicKeys = dynamicKeys.filter(
    key => params[key] !== currentParams[key]
  )

  // Determina se as chaves fixas devem ser redefinidas com base nas chaves dinâmicas alteradas
  const shouldResetFixedKeys = !!changedDynamicKeys.length

  return { shouldResetFixedKeys }
}

export default handleQueryParams
