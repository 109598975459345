import { shade } from 'polished'
import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const whatsappButtonTemplate = ({ theme }: ButtonTemplatesProps) => css`
  background-color: ${theme.colors.white};
  border-radius: ${theme.border.radius.large};
  padding: ${theme.spacings.tiny} ${theme.spacings.xlarge};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
  color: ${theme.colors.info};
  height: 36px;
  font-size: ${theme.font.sizes.xsmall};

  &:not(:disabled) {
    &:hover {
      background: ${shade(0.1, theme.colors.light)};
    }
  }
`

export default whatsappButtonTemplate
