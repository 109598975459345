import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FiTrash } from 'react-icons/fi'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Form } from '@unform/web'
import { t } from 'i18next'

import Alert from '~/components/Alert'
import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import Loading from '~/components/Loading'
import PageTitle from '~/components/PageTitle'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { Badge } from '~/components/UI/Badge'
import { FormGroup, Input, Label } from '~/components/unform'
import { isReadOnly } from '~/helpers'

import {
  createAdSizesService,
  deleteAdSizesService,
  getAdSizesService
} from '../../services/ad-size'

// import { Container } from './styles';
const initialFormData = {
  width: '',
  height: '',
  name: '',
  label: ''
}
const AdSizes: React.FC = () => {
  const [formData, setFormData] = useState<Partial<AdSize>>(initialFormData)

  const formRef = useRef(null)

  const { status, data } = useQuery({
    queryKey: ['get-ad-sizes'],
    queryFn: async () => {
      const res = await getAdSizesService()

      return res.data.data
    }
  })

  /**
   * Handle PATCH request and collateral effects
   */

  const queryClient = useQueryClient()

  const { isPending, mutateAsync } = useMutation({
    mutationFn: createAdSizesService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-ad-sizes']
      })

      formRef.current.reset()

      setFormData(initialFormData)

      toastr.success(
        t('rm:adsize.create.messages.success.title'),
        t('rm:adsize.create.messages.success.message')
      )
    },
    onError: () => {
      toastr.error(
        t('common:messages.errorOccurred.title'),
        t('rm:adsize.create.messages.error.message')
      )
    }
  })

  const handleSubmit = useCallback(
    async (formData: RequestBodyAdSize) => {
      await mutateAsync(formData)
    },
    [mutateAsync]
  )

  /**
   * Handle delete
   */
  const { isPending: isPendingDelete, mutateAsync: mutateAsyncDelete } =
    useMutation({
      mutationFn: deleteAdSizesService,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['get-ad-sizes']
        })

        toastr.success(
          t('rm:adsize.remove.messages.success.title'),
          t('rm:adsize.remove.messages.success.message')
        )
      },
      onError: () => {
        toastr.error(
          t('common:messages.errorOccurred.title'),
          t('rm:adsize.remove.messages.error.message')
        )
      }
    })

  const handleDelete = useCallback(
    async (id: string) => {
      await mutateAsyncDelete(id)
    },
    [mutateAsyncDelete]
  )

  /**
   * Schema
   */

  const schema: TableSchema<AdSize> = useMemo(
    () => ({
      id: {
        title: t('rm:adsize.list.table.column.title.id'),
        helpText: t('rm:adsize.list.table.column.helpText.id'),
        render: rowData => rowData.name
      },
      name: {
        title: t('rm:adsize.list.table.column.title.name'),
        helpText: t('rm:adsize.list.table.column.helpText.name'),
        render: rowData => rowData.label
      },
      dimensions: {
        title: t('rm:adsize.list.table.column.title.dimensions'),
        helpText: t('rm:adsize.list.table.column.helpText.dimensions'),
        render: rowData => (
          <span className="d-flex align-items-center text-nowrap gap-1">
            <Badge
              template={
                formData.width === rowData.width &&
                formData.height === rowData.height
                  ? 'successSolid'
                  : 'primary'
              }
              className="m-0"
            >
              <code>{rowData.width}px</code>
            </Badge>
            <code>x</code>
            <Badge
              template={
                formData.width === rowData.width &&
                formData.height === rowData.height
                  ? 'successSolid'
                  : 'primary'
              }
              className="m-0"
            >
              <code>{rowData.height}px</code>
            </Badge>
          </span>
        )
      },
      ...(isReadOnly()
        ? null
        : {
            actions: {
              render: rowData => (
                <Button
                  template="transparentDanger"
                  onClick={() => handleDelete(rowData.id)}
                  loading={isPendingDelete}
                  disabled={isPendingDelete}
                  customWidth="auto"
                  size="xsmall"
                >
                  <FiTrash />
                </Button>
              )
            }
          })
    }),
    [formData, handleDelete, isPendingDelete]
  )

  //

  const handleChange = useCallback((inputData: OnChangeInputData) => {
    const { name, value } = inputData

    setFormData(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const initialData = useMemo(
    () => ({
      width: formData.width,
      height: formData.height,
      name: `${formData.width}x${formData.height}`,
      label: `${formData.width}x${formData.height}`
    }),
    [formData.height, formData.width]
  )

  useEffect(() => {
    formRef?.current?.setData(initialData)
  }, [initialData])

  /**
   * Check if size already exists
   */

  const sizeExists = useMemo(
    () =>
      data?.some(
        item => item.height === formData.height && item.width === formData.width
      ),
    [data, formData.height, formData.width]
  )

  return (
    <>
      <PageTitle title={t('rm:adsize.page.title')} />

      <HeaderPages title={t('rm:adsize.page.title')} />

      <Loading status={status === 'pending'} />

      <section className="container">
        <div className="row">
          <div className="col p-0">
            <Table schema={schema} data={data} sideBorder striped />
          </div>

          {!isReadOnly() && (
            <div className="col">
              <Panel title={t('rm:adsize.form.title')}>
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="d-flex flex-wrap gap-3"
                  initialData={initialData}
                >
                  <FormGroup>
                    <Label
                      text={t('rm:adsize.form.text.width')}
                      htmlFor="width"
                      helperText={t('rm:adsize.form.helpText.width')}
                      isRequired
                    />
                    <Input
                      name="width"
                      type="number"
                      min={0}
                      htmlRightIcon={<code>px</code>}
                      required
                      onChange={handleChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label
                      text={t('rm:adsize.form.text.height')}
                      htmlFor="height"
                      helperText={t('rm:adsize.form.helpText.height')}
                      isRequired
                    />
                    <Input
                      name="height"
                      type="number"
                      min={0}
                      htmlRightIcon={<code>px</code>}
                      required
                      onChange={handleChange}
                    />
                  </FormGroup>

                  {sizeExists && (
                    <FormGroup className="w-100">
                      <AlertInfo text={t('rm:adsize.form.alerts.sizeExists')} />
                    </FormGroup>
                  )}

                  {!sizeExists && formData.width && formData.height && (
                    <>
                      <FormGroup className="w-100">
                        <Label
                          text={t('rm:adsize.form.text.identifier')}
                          helperText={t('rm:adsize.form.helpText.identifier')}
                          htmlFor="name"
                        />
                        <Input name="name" />
                      </FormGroup>

                      <FormGroup className="w-100">
                        <Label
                          text={t('rm:adsize.form.text.label')}
                          helperText={t('rm:adsize.form.helpText.label')}
                          htmlFor="label"
                        />
                        <Input name="label" />
                      </FormGroup>

                      <FormGroup className="w-100">
                        <Button
                          type="submit"
                          text={t('common:actions.save')}
                          template="success"
                          loading={isPending}
                          disabled={isPending}
                          textLoading={`${t('common:actions.saving')}...`}
                        />
                      </FormGroup>
                    </>
                  )}
                </Form>
              </Panel>
            </div>
          )}
        </div>
      </section>
    </>
  )
}

export default AdSizes
