import { FiChevronRight } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { t } from 'i18next'

import { ButtonNavLink } from '~/components/Buttons'
import Status from '~/components/Status'
import { formatDateToString, formatMoney } from '~/helpers'
import { transactionStatus } from '~/modules/retailMedia/store/common/constants'

import { paymentMethods } from './constants'

type PaymentSchemaProps = {
  publisherId: string
  walletId: string
}

type PaymentSchema = (data: PaymentSchemaProps) => TableSchema<WalltetPayment>

export const schema: PaymentSchema = ({ publisherId, walletId }) => ({
  date: {
    title: t('rm:wallet.movimentations.table.column.title.date'),
    render: rowData => (
      <NavLink
        to={`/retail-media/wallet/${walletId}/transaction/${rowData?.id}?rmid=${publisherId}`}
        title={t('rm:wallet.movimentations.table.actions.seeDetails')}
      >
        <strong>{formatDateToString(rowData?.created_at)}</strong>
      </NavLink>
    )
  },
  status: {
    title: t('rm:wallet.movimentations.table.column.title.status'),
    render: rowData => (
      <div style={{ textTransform: 'capitalize' }}>
        <Status
          label={transactionStatus[rowData?.status]?.label || rowData.status}
          template={transactionStatus[rowData?.status]?.template || 'default'}
        />
      </div>
    )
  },
  amount: {
    title: t('rm:wallet.movimentations.table.column.title.value'),
    render: rowData => (
      <strong className="amount">
        {formatMoney(rowData?.amount, false, 'R$ 0,00')}
      </strong>
    )
  },
  paymentMethod: {
    title: t('rm:wallet.movimentations.table.column.title.paymentMethod'),
    render: rowData => (
      <span className="amount">
        {paymentMethods?.[rowData.payment_method] || rowData.payment_method}
      </span>
    )
  },
  id: {
    title: t('rm:wallet.movimentations.table.column.title.transactionId'),
    render: rowData => (
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between'
        }}
      >
        <small>
          <code style={{ color: 'var(--dark)' }}>{rowData?.id}</code>
        </small>

        <ButtonNavLink
          to={`/retail-media/wallet/${walletId}/transaction/${rowData?.id}?rmid=${publisherId}`}
          text={t('rm:wallet.movimentations.table.actions.seeDetails')}
          size="xsmall"
          template="light"
          iconRight={<FiChevronRight />}
          title={t('rm:wallet.movimentations.table.actions.seeDetails')}
        />
      </div>
    )
  }
})
