import { history } from '~/helpers'
import {
  getChatsService,
  getChatMessages,
  setMessageStatusToReadService,
  createTagService,
  setChatsTags,
  getTagsService,
  updatedChatService,
  toggleBotStatusService,
  deleteTagService,
  updateTagService,
  updatedSessionService,
  putStartService,
  putFinishService,
  setNewMessageStatusToReadService,
  listAttendantService,
  agentTransferService,
  historyChatSession
} from '~/modules/chat/services'
import store from '~/store'

import * as types from '../constants'

/**
 *
 * @exports getChats Get active chats
 * @param {object} queryString Object with options to filter
 *
 * @exports updateChat
 * @param {object} data Chat object with new data
 * @param {string} successFeedbackMsg Message for the success toast
 *
 * @exports updateSession
 *
 * @exports getMessages Get messages from a chat
 * @param {string} id Chat ID
 *
 * @exports setMessageStatusToRead Sets the read status of messages for a chat
 * @param {string} chatId Chat ID
 *
 * @exports setSelectedChat Sets which chat is selected for reading
 * @param {object} selectedChat Object with all information from the selected chat
 *
 * @exports handleNewMessage
 * @exports setChatIdByUrl
 * @exports togglePanelCustomer
 * @exports togglePanelTemplates
 * @exports togglePanelTags
 * @exports setOrderOpen
 * @exports setChatMenuOpen
 * @exports toggleSelectChatsMode
 * @exports setSelectedChatToEdit
 * @exports cleanSelectedsChats
 * @exports toggleEditLocationsMode
 * @exports setSelectedTag
 * @exports getTags
 * @exports updateChatsTagsOptimistic
 * @exports updateChatsTags
 *
 * @exports toggleBotStatus
 *
 */

/**
 * Handle fullscreen mode
 * @param {boolean} value Value to loading
 */
export const handleFullScreenMode = ({ value = true }) => ({
  type: types.SET_FULL_SCREEN,
  payload: value
})

/**
 * Set loading chat
 * @param {boolean} value Value to loading
 */
export const setChatsLoading = ({ value = true }) => ({
  type: types.SET_LOADING_CHATS,
  payload: value
})

/**
 * Get active chats
 * @param {object} queryString Object with options to filter
 */
export const getChats = ({ queryString, onSuccess }) => ({
  type: types.GET_CHATS,
  payload: getChatsService({ queryString }),
  onSuccess,
  onError: error => {
    history.push(`/chats`)
  },
  getErrorFeedbackMsg: error =>
    error.response?.data?.error || 'Houve um erro ao carregar as conversas.'
})

export const getChat = ({ chatId }: { chatId: string }) => {
  return {
    type: types.GET_CHAT,
    payload: getChatsService({ queryString: { chatId } })
  }
}

export const setOrderChatsBy = ({
  sortChatsByKey
}: {
  sortChatsByKey: SortChatByKeys
}) => {
  return {
    type: types.SET_CHATS_ORDER_BY,
    payload: sortChatsByKey
  }
}

/**
 * Get more chats
 * @param {object} queryString Object with options to filter
 */
export const getMoreChats = ({ queryString, onSuccess }) => ({
  type: types.GET_MORE_CHATS,
  payload: getChatsService({ queryString }),
  onSuccess,
  onError: error => {
    history.push(`/chats`)
  },
  getErrorFeedbackMsg: error =>
    error.response?.data?.error || 'Houve um erro ao carregar as conversas.'
})

/**
 * Update chat
 * @param {object} data Chat object with new data
 * @param {string} successFeedbackMsg Message for the success toast
 */
export const updateChat = ({ data, successFeedbackMsg, onSuccess }) => {
  return {
    type: types.UPDATE_CHAT_CHAT,
    payload: updatedChatService(data),
    titleSuccessFeedBack: 'Conversa atualizada.',
    successFeedbackMsg:
      successFeedbackMsg || 'Tudo certo com a atualização da conversa.',
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao atualizar.',
    onSuccess
  }
}

/**
 * Update chat
 * @param {object} data Chat object with new data
 * @param {string} successFeedbackMsg Message for the success toast
 */
export const updateSession = ({
  data,
  successFeedbackMsg,
  onSuccess,
  feedBackMsg = true
}) => {
  const hasFeedbackMsg = {
    titleSuccessFeedBack: feedBackMsg ? 'Conversa atualizada.' : null,
    successFeedbackMsg: feedBackMsg
      ? successFeedbackMsg || 'Tudo certo com a atualização da conversa.'
      : null,
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao atualizar.'
  }
  return {
    type: types.UPDATE_SESSION,
    payload: updatedSessionService(data),
    onSuccess,
    ...hasFeedbackMsg
  }
}

/**
 * Get messages from a chat
 * @param {string} id Chat ID
 */
export const getMessages = ({ id, page = 1 }) => {
  return {
    type: types.GET_MESSAGES_INITIAL,
    payload: getChatMessages({ id, page }),
    onError: () => {
      history.push(`/chats/`)
    },
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao carregar a conversa.'
  }
}

/**
 * Get more messages from a chat
 * @param {string} id Chat ID
 * @param {number} page
 */
export const getMoreMessages = ({ id, page }) => {
  return {
    type: types.GET_MESSAGES_MORE,
    payload: getChatMessages({ id, page }),
    onError: () => {
      history.push(`/chats/`)
    },
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao carregar a conversa.'
  }
}

/**
 * Sets the read status of messages for a chat
 * @param {string} chat_id Chat ID
 * @param {array} messages Unread messages that will have read status updated
 */
export const setMessageStatusToRead = ({ chatId }) => {
  const data = { chatId }

  return {
    type: types.SET_MESSAGE_STATUS_READ,
    payload: setMessageStatusToReadService(data),
    getErrorFeedbackMsg: error =>
      error.response?.data?.error ||
      'Houve um erro ao atualizar o status de leitura.'
  }
}

/**
 * Sets which chat is selected for reading
 * @param {object} selectedChat Object with all information from the selected chat
 */
export function setSelectedChat({ selectedChat }) {
  return {
    type: types.SET_SELECTED_CHAT,
    payload: { selectedChat }
  }
}

/**
 * Limpando estado
 */
export function clearSeletectedChat() {
  return {
    type: types.CLEAR_SELECTED_CHAT
  }
}

export function handleNewMessage({ message }) {
  return {
    type: types.ADD_NEW_MESSAGE,
    payload: { message }
  }
}

export function toggleAutoRefresh(status) {
  return {
    type: types.TOGGLE_AUTO_REFRESH,
    payload: { status }
  }
}

export function setChatIdByUrl(chatId) {
  return {
    type: types.SET_ID_URL,
    payload: { id: chatId }
  }
}

export function setNotificationPlayed() {
  return {
    type: types.SET_NOTIFICATIONS_PLAYED
  }
}

export function togglePanelCustomer() {
  return {
    type: types.TOGGLE_PANEL_CUSTOMER
  }
}

export function togglePanelHistory() {
  return {
    type: types.TOGGLE_PANEL_HISTORY
  }
}

export function togglePanelTemplates() {
  return {
    type: types.TOGGLE_PANEL_TEMPLATES
  }
}

export function togglePanelTags() {
  return {
    type: types.TOGGLE_PANEL_TAGS
  }
}

export function togglePanelShoppingCart() {
  return {
    type: types.TOGGLE_PANEL_SHOPPING_CART
  }
}

export function closeSidePanels() {
  return {
    type: types.CLOSE_SIDE_PANELS
  }
}

export function setOrderOpen({ open, orderId, id }) {
  return {
    type: types.SET_ORDER_OPEN,
    payload: { orderOpen: { open, orderId, id } }
  }
}

export function setChatMenuOpen(data) {
  return {
    type: types.SET_CHAT_MENU_OPEN,
    payload: data
  }
}

export function toggleSelectChatsMode({ initialChat }) {
  return {
    type: types.TOGGLE_SELECT_CHATS_MODE,
    payload: { initialChat }
  }
}

export function setSelectedChatToEdit({ chatId, sessionId }) {
  return {
    type: types.SET_SELECTED_CHAT_TO_EDIT,
    payload: { chatId, sessionId }
  }
}

export function cleanSelectedsChats() {
  return {
    type: types.CLEAN_SELECTEDS_CHATS
  }
}

export function toggleEditLocationsMode() {
  return {
    type: types.TOGGLE_EDIT_LOCATIONS_MODE
  }
}

/**
 * Tags
 */

export function setSelectedTag({ tagId, initialData = null }) {
  return {
    type: types.SET_SELECTED_TAG,
    payload: { tagId, initialData }
  }
}

export const createTagOptimistic = data => {
  return {
    type: types.CREATE_TAG_OPTIMISTIC,
    payload: { ...data, saving: true, tagId: 'temp_tag' }
  }
}

export const createTag = data => {
  const { dispatch } = store

  dispatch(createTagOptimistic(data))

  return {
    type: types.CREATE_TAG,
    payload: createTagService(data),
    successFeedbackMsg: 'Tag criada com sucesso.',
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao criar a tag.'
  }
}

export const deleteTagOptimistic = tagId => {
  return {
    type: types.DELETE_TAG_OPTIMISTIC,
    payload: tagId
  }
}

export const deleteTag = tagId => {
  const { dispatch } = store

  dispatch(deleteTagOptimistic(tagId))

  return {
    type: types.DELETE_TAG,
    payload: deleteTagService(tagId),
    successFeedbackMsg: 'Tag deletada com sucesso.',
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao deletar a tag.'
  }
}

export const updateTag = data => {
  return {
    type: types.UPDATE_TAG,
    payload: updateTagService(data),
    successFeedbackMsg: 'Tag atualizada com sucesso.',
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao atualizar a tag.'
  }
}

export function getTags() {
  return {
    type: types.GET_AVAIABLE_TAGS,
    payload: getTagsService(),
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao buscar os marcadores.'
  }
}

export const updateChatsTagsOptimistic = data => {
  return {
    type: types.UPDATE_CHATS_TAGS_OPTIMISTIC,
    payload: data
  }
}

export const updateChatsTags = data => {
  const { dispatch } = store

  dispatch({
    type: types.UPDATE_CHATS_TAGS_OPTIMISTIC,
    payload: data
  })

  return {
    type: types.UPDATE_CHATS_TAGS,
    payload: setChatsTags(data),
    titleSuccessFeedBack: 'Tudo certo!',
    successFeedbackMsg: 'Marcadores inseridos com sucesso.',
    getErrorFeedbackMsg: error =>
      error.response?.data?.error || 'Houve um erro ao inserir os marcadores.'
  }
}

export const toggleBotStatus = ({ id, sessionId, data }) => {
  const { dispatch } = store

  dispatch({
    type: types.TOGGLE_BOT_STATUS_OPTIMISTIC,
    payload: { chatId: id, data }
  })

  return {
    type: types.TOGGLE_BOT_STATUS,
    payload: toggleBotStatusService({ sessionId, data }),
    titleSuccessFeedBack: 'Tudo certo!',
    successFeedbackMsg: 'Status atualizado com sucesso.',
    getErrorFeedbackMsg: error =>
      error.response?.data?.error ||
      'Houve um erro ao atualizar o status do bot.'
  }
}

export const startService = ({ sessionId }) => {
  return {
    type: types.START_SERVICE,
    payload: putStartService({ sessionId })
  }
}

export const finishServiceChat = ({
  sessionId,
  onSuccess
}: {
  sessionId: string
  onSuccess?: () => void
}) => {
  return {
    type: types.FINISH_SERVICE,
    payload: putFinishService({ sessionId }),
    titleSuccessFeedBack: 'Tudo certo!',
    successFeedbackMsg: 'Atendimento finalizado.',
    onSuccess
  }
}

export const setNewMessageStatusToRead = ({ chatId }) => {
  const data = { chatId }

  return {
    type: types.SET_NEW_MESSAGE_STATUS_READ,
    payload: setNewMessageStatusToReadService(data),
    getErrorFeedbackMsg: error =>
      error.response?.data?.error ||
      'Houve um erro ao atualizar o status de leitura.'
  }
}

export const listAttendant = ({ params }: ListAttendant) => {
  return {
    type: types.LIST_ATTENDANT,
    payload: listAttendantService({ params })
  }
}

export const agentTransfer = ({
  values,
  sessionId,
  data,
  onSuccess,
  successFeedbackMsg
}: {
  values?: any
  sessionId: string
  data: { userId: string | null }
  onSuccess?: () => void
  successFeedbackMsg?: string
}) => {
  return {
    type: types.AGENT_TRANSFER,
    payload: agentTransferService({ sessionId, data }),
    values,
    titleSuccessFeedBack: 'Tudo certo!',
    successFeedbackMsg: successFeedbackMsg
      ? successFeedbackMsg
      : 'Atendimento transferido.',
    onSuccess
  }
}

export const historyChat = ({ sessionId, chatId }: HistoryChatSession) => {
  return {
    type: types.HISTORY_CHAT,
    payload: historyChatSession({ sessionId, chatId })
  }
}
