import React, { useCallback, useEffect, useState } from 'react'

import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  doc,
  deleteDoc
} from 'firebase/firestore/lite'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import EmptyPage from '~/components/empty-page'
import Table from '~/components/Table'
import { JWTPayload, getAccountId } from '~/helpers'

import { firebaseConfig } from '../firebase'

const Devices: React.FC = () => {
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [activeDevices, setActiveDevices] =
    useState<DeviceInfo<JWTPayload>[]>(null)
  console.log('🚀 ~ file: index.tsx:24 ~ activeDevices:', activeDevices)

  const app = initializeApp(firebaseConfig)
  const db = getFirestore(app)
  const devicesRef = collection(db, 'devices')

  const accountId = getAccountId()

  const getData = useCallback(async () => {
    try {
      const q = query(devicesRef, where('current_account_id', '==', accountId))
      const deviceSnapshot = await getDocs(q)

      const devicesActive = deviceSnapshot.docs.map(
        doc =>
          ({
            ...doc.data(),
            id: doc.id
          }) as DeviceInfo<JWTPayload>
      )

      setActiveDevices(devicesActive)
    } catch (error) {
      console.error('Erro ao buscar dados do Firestore:', error)
    }
  }, [devicesRef])

  useEffect(() => {
    if (!activeDevices) {
      getData()
    }
  }, [activeDevices])

  /**
   * Delete device
   */
  const handleDeleteDevice = async ({ deviceId }) => {
    try {
      setLoadingDelete(true)
      await deleteDoc(doc(db, 'devices', deviceId))
      getData()
      console.log('Entrada apagada com sucesso!')
    } catch (erro) {
      console.error('Erro ao apagar entrada:', erro)
    } finally {
      setLoadingDelete(false)
    }
  }

  const schema: TableSchema<DeviceInfo<JWTPayload>> = {
    name: {
      title: t('rm:tv.devices.deviceName'),
      render: rowData => rowData.deviceName
    },
    id: {
      title: t('rm:tv.devices.deviceId'),
      render: rowData => rowData.deviceID
    },
    deviceManufacturer: {
      title: t('rm:tv.devices.deviceManufacturer'),
      render: rowData => rowData.deviceManufacturer
    },
    user: {
      title: t('rm:tv.devices.user'),
      render: rowData => rowData.name
    },
    remove: {
      title: t('rm:remove'),
      render: rowData => (
        <Button
          template="deleteOutline"
          size="xsmall"
          text={t('rm:button.removeDevice')}
          loading={loadingDelete}
          onClick={() => handleDeleteDevice({ deviceId: rowData.id })}
        />
      )
    }
  }

  return (
    <>
      {!activeDevices?.length && (
        <EmptyPage
          title={t('rm:tv.devices.noActiveTV.title')}
          subtitle={t('rm:tv.devices.noActiveTV.subtitle')}
          svgPath="photos/orders-empty"
        />
      )}

      {activeDevices?.length > 0 && (
        <Table schema={schema} data={activeDevices} />
      )}
    </>
  )
}

export default Devices
