import { produce } from 'immer'
import { Action } from 'redux'

import accountInfo from './accountInfo/'
import ads from './ads/'
import advertiser from './advertiser'
import advertiserPublisher from './advertiserPublisher'
import campaign from './campaign'
import checkingAccount from './checkingAccount/'
import groceryCampaign from './grocery/campaign'
import * as groceryConfiguration from './grocery/configuration'
import groceryWallet from './grocery/wallet'
import industryAd from './industry/ad'
import industryAdvertiserPublisher from './industry/advertiserPublisher'
import industryBudget from './industry/budget'
import industryCampaign from './industry/campaign'
import industryCategory from './industry/category'
import industryPublisher from './industry/publisher'
import industryPublisherConfiguration from './industry/publisherConfiguration'
import industryWallet from './industry/wallet'
import placements from './placements/'
import product from './product'
import publisher from './publisher'
import report from './report/'

const INITIAL_STATE: RetailMediaState = {
  accountInfo: {
    ...accountInfo.initialState
  },
  ads: {
    ...ads.initialState
  },
  campaign: {
    ...campaign.initialState
  },
  advertiser: { ...advertiser.initialState },
  publisher: { ...publisher.initialState },
  checkingAccount: {
    ...checkingAccount.initialState
  },
  placements: { ...placements.initialState },
  product: { ...product.initialState },
  report: { ...report.initialState },
  advertiserPublisher: { ...advertiserPublisher.initialState },
  grocery: {
    campaign: { ...groceryCampaign.initialState },
    configuration: { ...groceryConfiguration.initialState },
    wallet: { ...groceryWallet.initialState }
  },
  industry: {
    campaign: { ...industryCampaign.initialState },
    // product: { ...industryProduct.initialState },
    publisher: { ...industryPublisher.initialState },
    publisherConfiguration: { ...industryPublisherConfiguration.initialState },
    advertiserPublisher: { ...industryAdvertiserPublisher.initialState },
    category: { ...industryCategory.initialState },
    ad: { ...industryAd.initialState },
    budget: { ...industryBudget.initialState },
    wallet: { ...industryWallet.initialState }
  }
}

const reducerCases = {
  ...accountInfo.reducerCases,
  ...ads.reducerCases,
  ...checkingAccount.reduceCases,
  ...campaign.reducerCases,
  ...placements.reducerCases,
  ...report.reducerCases,
  ...product.reducerCases,
  // Old structure
  // Grocery
  ...groceryCampaign.reducerCases,
  ...advertiser.reducerCases,
  ...publisher.reducerCases,
  ...groceryConfiguration.reducerCases,
  ...groceryWallet.reduceCases,
  // Industry
  ...industryCampaign.reducerCases,
  ...industryPublisher.reducerCases,
  ...industryPublisherConfiguration.reducerCases,
  ...industryAdvertiserPublisher.reduceCases,
  ...industryCategory.reducerCases,
  ...industryAd.reducerCases,
  ...industryBudget.reducerCases,
  ...industryWallet.reducerCases,

  ...advertiserPublisher.reduceCases
}

export default (state: RetailMediaState = INITIAL_STATE, action: Action) =>
  produce(state, draft => {
    const reducer = Object.keys(reducerCases).find(item => item === action.type)

    const props = { state, draft, action }

    if (reducer?.length) {
      reducerCases[reducer](props)
    }
  })
