import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'

import { AddressInline } from '~/components/Address'
import {
  formatCPF,
  formatDateExibitionUTC,
  formatDateToString,
  getAccountId
} from '~/helpers'

import ProductsListToPrint from '../ProductsListToPrint'

import { PrintContent } from './styles'

export const PrintOrder = ({ printRef }) => {
  const { orderData } = useSelector(state => state.order)

  const {
    order_id,
    customer,
    createdAt,
    items,
    channel_id,
    description,
    location,
    shipping_address,
    billing_address
  } = orderData

  /**
   * Checa se o id do produto é 'cesta-presentes-personalizada' pois nesse caso
   * o texto vem no formato livre e deve ser mostrado como um parágrafo <p>.
   */
  const isFreeDescription = useMemo(
    () => items?.[0]?.product_id === 'cesta-presentes-personalizada',
    [items]
  )

  const shipments = useMemo(() => orderData.shipments?.[0] || [], [orderData])

  const titleShipmentSection = useMemo(
    () =>
      shipments?.type === 'pick_up_in_store'
        ? 'Dados da retirada'
        : 'Dados de entrega',
    [shipments]
  )

  //

  const isNow = useMemo(() => shipments.use_delivery_time, [shipments])

  const isToday = useMemo(
    () => moment().isSame(orderData.schedule_date, 'day'),
    [orderData]
  )

  return (
    <PrintContent className="printOrder_List" ref={printRef}>
      {orderData?.order_id && (
        <>
          <header>
            <h2>Pedido #{order_id}</h2>
            <span>
              <strong>Cliente:</strong> {customer?.name} |{' '}
              <strong>Celular:</strong> {customer?.main_phone} |{' '}
              {getAccountId() !== 'be56d620-019c-11eb-883d-8980b7e89d71' && (
                <>
                  <strong>CPF:</strong>{' '}
                  {customer?.social_id && formatCPF(customer.social_id)} |{' '}
                </>
              )}
              <strong>Realizada em:</strong> {formatDateToString(createdAt)}
            </span>
          </header>
          <hr />

          {items && (
            <>
              <div className="products">
                <ProductsListToPrint />
              </div>
              <hr />
            </>
          )}

          <div className="description">
            {channel_id === 'purchase' ? (
              <>
                {isFreeDescription ? (
                  <p>{description}</p>
                ) : (
                  <ul>
                    {description?.split(',').map(item => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                )}
              </>
            ) : (
              description
            )}
          </div>
          <hr />
          <div>
            <div>
              <span>
                <strong>{titleShipmentSection}</strong>
              </span>
            </div>

            {shipments?.type === 'pick_up_in_store' && (
              <div>
                <strong>Loja:</strong> {location[0]?.name}
                <br />
                <strong>Data agendada:</strong>{' '}
                {formatDateExibitionUTC(shipments.schedule_date)}
                <br />
                <strong>
                  {isToday && isNow ? 'Entrega até:' : 'Turno:'}
                </strong>{' '}
                {isToday && isNow ? shipments.schedule_time : shipments.slot}
              </div>
            )}

            {shipments?.type === 'shipping_from_store' && (
              <div>
                <strong>Destinatário:</strong>{' '}
                {shipping_address?.[0]?.full_name || 'Sem nome cadastrado'}
                <br />
                <strong>Data agendada:</strong>{' '}
                {formatDateExibitionUTC(shipments.schedule_date)}
                <br />
                <strong>
                  {isToday && isNow ? 'Entrega até:' : 'Turno:'}
                </strong>{' '}
                {isToday && isNow ? shipments.schedule_time : shipments.slot}
                <br />
                <strong>Endereço de entrega:</strong>
                <br />
                {shipping_address && shipping_address[0] && (
                  <>
                    <AddressInline shippingAddress={shipping_address[0]} />
                  </>
                )}
              </div>
            )}

            <hr />

            {billing_address && billing_address[0] && (
              <div>
                <strong>Endereço de cobrança:</strong>
                <br />
                Nome: {billing_address[0].full_name}
                <br />
                <AddressInline shippingAddress={billing_address[0]} />
              </div>
            )}
          </div>
        </>
      )}
    </PrintContent>
  )
}
