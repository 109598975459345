import React, { useCallback } from 'react'
import { FiX } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import SelectLocations from '~/components/SelectLocations'
import {
  toggleEditLocationsMode,
  updateSession
} from '~/modules/chat/store/actions'
import { CHAT_REDUX_QUERY } from '~/modules/chat/store/constants'
import { setQuery } from '~/store/modules/filters/actions'

import { Container } from './styles'

const ChatLocationEdit = () => {
  const dispatch = useDispatch()

  const { selectedChatToUpdate, editLocationMode, updateChatLoading } =
    useSelector(({ chats }) => chats)

  const handleCancelEdit = useCallback(() => {
    dispatch(
      setQuery({
        query: { [`${CHAT_REDUX_QUERY}`]: null },
        keyState: CHAT_REDUX_QUERY
      })
    )

    dispatch(toggleEditLocationsMode())
  }, [dispatch])

  const handleChange = useCallback(
    data => {
      const updateSessionData = {
        ...selectedChatToUpdate.session,
        locationId: data.value
      }

      const actionData = {
        data: updateSessionData,
        successFeedbackMsg: 'Novo local atualizado com sucesso.'
      }

      dispatch(updateSession(actionData))
    },
    [dispatch, selectedChatToUpdate]
  )

  return (
    <Container className="ChatLocationEdit" isActive={editLocationMode}>
      <header className="wrapper">
        <Button template="icon" iconLeft={<FiX />} onClick={handleCancelEdit} />{' '}
        <span className="label">Alterar local responsável</span>
      </header>

      <div className="wrapperLocationsList">
        {updateChatLoading ? (
          <Loading status={updateChatLoading}>Salvando mudanças...</Loading>
        ) : (
          editLocationMode && <SelectLocations onChange={handleChange} />
        )}
      </div>
    </Container>
  )
}

export default ChatLocationEdit
