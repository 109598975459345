import { product as service } from '~/modules/retailMedia/services/industry'

import { types } from './constants'

/**
 * Get all */
export const listProducts = (queries = {}) => ({
  type: types.list,
  payload: service.list(queries)
})

export default {
  listProducts
}
