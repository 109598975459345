import styled from 'styled-components';

export const Name = styled.div`
  a {
    overflow: hidden;
    max-width: 350px;
    text-overflow: ellipsis;
    display: block;
  }
`;
