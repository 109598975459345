import { FiHelpCircle } from 'react-icons/fi'

import { t } from 'i18next'

import Tooltip from '~/components/Tooltip'

export const advertiserCards: CardsSchema<
  ReportCampaigns,
  TypesAdvertiserCard
> = ({ data, loading }) => [
  {
    id: 'card-01',
    title: t('rm:metrics.impressions'),
    value: data?.formatted?.impressions || 0,
    type: 'prints',
    loading: loading
  },
  {
    id: 'card-02',
    title: t('rm:metrics.clicks'),
    value: data?.formatted?.clicks || 0,
    type: 'clicks',
    loading: loading
  },
  {
    id: 'card-03',
    title: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{t('rm:metrics.ctr')}</span>
        <Tooltip
          icon={<FiHelpCircle />}
          text={<>{t('rm:metrics.ctr.tooltip')}</>}
        />
      </div>
    ),
    value: data?.formatted?.ctr || 0,
    type: 'ctr',
    loading: loading
  },
  {
    id: 'card-06',
    title: t('rm:metrics.cost'),
    value: data?.formatted?.total_spent || 0,
    type: 'total_spent',
    loading: loading
  },
  {
    id: 'card-04',
    title: t('rm:metrics.sales'),
    value: data?.formatted?.income || 0,
    type: 'income',
    loading: loading
  },
  {
    id: 'card-05',
    title: t('rm:metrics.roas'),
    value: data?.formatted?.roas || 0,
    type: 'conversion',
    loading: loading
  }
]

export const advertiserListAllCards: CardsSchema<
  ReportCampaigns,
  TypesAdvertiserCard
> = ({ data, loading }) => [
  {
    id: 'card-01',
    title: t('rm:metrics.impressions'),
    value: data?.formatted?.impressions || 0,
    type: 'prints',
    loading: loading
  },
  {
    id: 'card-02',
    title: t('rm:metrics.clicks'),
    value: data?.formatted?.clicks || 0,
    type: 'clicks',
    loading: loading
  },
  {
    id: 'card-03',
    title: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{t('rm:metrics.ctr')}</span>
        <Tooltip
          icon={<FiHelpCircle />}
          text={<>{t('rm:metrics.ctr.tooltip')}</>}
        />
      </div>
    ),
    value: data?.formatted?.ctr || 0,
    type: 'ctr',
    loading: loading
  },
  {
    id: 'card-06',
    title: t('rm:metrics.cost'),
    value: data?.formatted?.total_spent || 0,
    type: 'total_spent',
    loading: loading
  },
  {
    id: 'card-04',
    title: t('rm:metrics.sales'),
    value: data?.formatted?.income || 0,
    type: 'income',
    loading: loading
  },
  {
    id: 'card-05',
    title: t('rm:metrics.roas'),
    value: data?.formatted?.roas || 0,
    type: 'conversion',
    loading: loading
  }
]
