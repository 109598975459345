import { forgotPasswordService } from '~/services/password-service'

import * as types from './constants'

export const forgotPassword = email => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload: forgotPasswordService(email)
})

export function forgotPasswordSuccess(message) {
  return { type: types.FORGOT_PASSWORD_SUCCESS, payload: message }
}
export function forgotPasswordFailure(error) {
  return { type: types.FORGOT_PASSWORD_FAILURE, payload: error }
}
