import styled, { css } from 'styled-components'

export const Container = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    padding: 0.25rem;

    border: 2px solid #ce8d36;
    border-width: 0 1px 1px 0;
    border-radius: 0 0.25rem 0.25rem 0;

    font-size: 1.5rem;

    box-shadow: 0.125rem 0.125rem 1rem -0.25rem rgba(0, 0, 0, 0.75);
    background: var(--secondary);
    color: var(--dark);

    span {
      display: none;
      font-size: 0.75rem;
    }

    &:hover {
      span {
        display: inline-flex;
      }
    }
  `}
`
