import { transparentize, shade } from 'polished'
import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

export const List = styled.ul`
  margin: 0;
  padding: 0;

  @media ${device.tablet_max} {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    padding: 0 0.5rem;
    width: 100%;

    &:after {
      /* content: ''; */
      width: calc(100% - 48px);
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 24px;
      border-radius: 4px;
      background-color: var(--tertiary);
    }
  }
  @media ${device.tablet} {
    border: 0;
  }
`

const itemModifier = {
  pending: () => css`
    --colorStatus: var(--warning);
  `,
  confirmed: () => css`
    --colorStatus: var(--success);
  `,
  out: () => css`
    --colorStatus: var(--danger);

    opacity: 0.75;
  `,
  hasDetails: theme => css`
    &.hasDetails {
      border: none;
      margin: 0 -0.25rem;
      border: 1px solid #f3f3f3 !important;
      border-bottom: none !important;
      border-radius: 0.75rem;
      padding: 0 0.25rem;

      .grayColumn {
        border-radius: 0 !important;
      }

      + li.hasDetails {
        margin-top: 0.5rem;

        + li:not(.hasDetails) {
          border: none;
        }
      }

      + .hasDetails {
        .grayColumn {
          &::after {
            content: '';
            width: 100%;
            height: 0.5rem;
            background: rgba(156, 159, 162, 0.06);
            position: absolute;
            top: -9px;
            left: 0;
          }
        }
      }
    }
  `
}

export const Item = styled.li`
  ${({ theme, isEditMode, statusStep, hasDetails, showDetails }) => css`
    --gridDesktop: 2fr 100px 110px 100px 100px 200px;
    --colorStatus: var(--dark);

    ${isEditMode &&
    css`
      --gridDesktop: 2fr 110px 110px 100px 100px 100px 200px;
    `}

    list-style: none;

    display: flex;
    align-items: center;
    flex-flow: wrap;
    padding: 0;

    transition: all 0.3s ease-in-out;

    position: relative;
    z-index: 1;

    ${hasDetails &&
    !isEditMode &&
    css`
      cursor: pointer;

      &[aria-expanded='false']::after {
        content: ' ';
        width: 6px;
        height: 6px;
        border: solid;
        border-width: 0 0.1rem 0.1rem 0;
        display: inline-block;
        padding: 1px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;

        position: absolute;
        right: 0.5rem;
      }

      &[aria-expanded='true']::after {
        content: ' ';
        width: 6px;
        height: 6px;
        border: solid;
        border-width: 0 0.1rem 0.1rem 0;
        display: inline-block;
        padding: 1px;
        -webkit-transform: rotate(223deg);
        -ms-transform: rotate(223deg);
        transform: rotate(223deg);
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;

        position: absolute;
        right: 1rem;
        top: 1.5rem;
      }
    `}

    ${showDetails && itemModifier.hasDetails(theme)}

    @media ${device.tablet} {
      display: grid;
      /* grid-template-columns: var(--gridMobile); */
      grid-template-columns: var(--gridDesktop);
      column-gap: 0.5rem;
    }

    @media ${device.tablet_max} {
      &:not(:first-child) {
        /* background: var(--light);
        border: 1px solid var(--tertiary); */
        border-radius: 0.5rem;
        margin: 0 0 0.5rem;
        background: var(--light);
        border-bottom: 1px solid var(--tertiary);
      }
    }

    &:not(:first-child):not(.columnSingleTitle) {
      &:hover {
        ${!showDetails &&
        css`
          background-color: var(--light);
        `}

        .name {
          color: var(--primary);
        }
      }

      &:focus-within {
        background-color: ${transparentize(0.9, theme.colors.secondary)};
        .name {
          color: var(--primary);
        }
      }
    }

    @media ${device.tablet} {
      &:not(:first-child) {
        border-top: 1px solid ${theme.colors.inative};
      }
    }

    > div {
      display: flex;

      flex-direction: column;

      flex: 1;
      padding: 0.5rem 1rem;

      @media ${device.tablet_max} {
        order: 10;
        padding: 0.5rem;
      }

      .label {
        line-height: 1;
        font-weight: ${theme.font.bold};
        font-size: ${theme.font.sizes.xsmall};
      }

      @media ${device.tablet} {
        flex-direction: row;
        align-items: center;
        padding: 0.5rem;

        .label {
          display: none;
        }
      }

      height: 100%;

      &.grayColumn {
        position: relative;
        background: ${transparentize(0.94, shade(0.1, theme.colors.gray))};
      }
    }

    &:first-child {
      &.headerTable {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;

        @media ${device.tablet_max} {
          display: none;
        }
      }
      > div {
        font-weight: ${theme.font.bold};

        &.grayColumn {
          border-radius: 0.5rem 0.5rem 0 0;
        }
      }
    }

    @media ${device.tablet} {
      &:last-child {
        > div {
          &.grayColumn {
            border-radius: 0 0 0.5rem 0.5rem;
          }
        }
      }
    }

    &.columnSingleTitle {
      border: 0;

      > div {
        padding-top: 1rem;
      }

      .title {
        padding-left: 0;

        .wrapper {
          display: flex;
          align-items: center;
          flex-direction: row;
          flex: 0;
          border-radius: 0.25rem;
          padding: 0.5rem;

          background-color: var(--light);
          color: var(--dark);
          line-height: 1.1;
          font-weight: ${theme.font.bold};
          white-space: nowrap;

          svg {
            margin-right: 0.5rem;
            color: var(--warning);
          }
        }
      }
    }

    .name {
      align-items: flex-start;
      line-height: 1.25;
      flex-flow: wrap;

      width: 100%;
      flex: 1 0 100%;

      @media ${device.tablet_max} {
        order: 0;
        flex-direction: row;
        padding-bottom: 0;
      }

      .substitution {
        color: var(--danger);
        font-size: ${theme.font.sizes.xsmall};
        width: 100%;
        flex-basis: 100%;
      }

      .nameProduct {
        display: inline-flex;
        align-items: center;
        margin-right: 0.25rem;

        .icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: ${theme.colors.primaryLighten60};
          color: var(--hover);
          font-size: ${theme.font.sizes.small};
          width: 1rem;
          height: 1rem;
          border-radius: 0.25rem;
          margin-right: 0.25rem;
          padding: 0.25rem;
        }
      }

      .note {
        display: flex;
        align-items: center;
        flex: 1 0 100%;
        width: 100%;

        .text {
          padding: 0.125rem 0;

          .helpText {
            font-size: 12px;
            color: var(--gray);
          }

          .noteText {
            border-radius: 0 0.25rem 0.25rem;
            padding: 0.125rem 0.25rem;
            background-color: ${theme.colors.secondaryLighten30};
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }

    .productId {
      font-size: ${theme.font.sizes.small};
    }

    .original_price {
      .text_price {
        min-width: 4rem;
      }

      button {
        &:not(:first-child) {
          margin-left: 0.5rem;
        }
      }
    }

    .quantity,
    .total_value {
      justify-content: center;
      flex-direction: column;
      line-height: 1;
    }

    @media ${device.tablet_max} {
      .original_price,
      .quantity,
      .total_value {
        max-width: 33%;
      }
    }

    .status {
      @media ${device.tablet_max} {
        order: 1;
        flex-basis: 100%;
        padding-bottom: 0.25rem;
        padding-top: 0;
      }

      .text_status {
        color: var(--colorStatus);
      }
      form {
        max-width: 100%;

        select {
          max-width: 100%;
        }
      }
    }

    .edit_item {
      display: flex;

      max-width: 100%;

      > form {
        display: flex;

        max-width: 100%;

        > div {
          padding: 0.25rem 0.5rem;
          font-size: ${theme.font.sizes.small};
        }
      }
    }

    .delete_item {
      min-width: 6rem;
      justify-content: flex-end;
    }

    ${statusStep === 'pending' && itemModifier.pending()}
    ${statusStep === 'confirmed' && itemModifier.confirmed()}
    ${statusStep === 'out' && itemModifier.out()}
  `}
`

export const ActionRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;

    @media ${device.tablet_max} {
      margin: 0;

      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 1000;

      width: 100vw;
      height: 3.5rem;
      align-items: center;
      justify-content: center;

      box-shadow: 0 0 ${transparentize(1, theme.primary)};
      background-color: ${transparentize(0.25, theme.primary)};
      backdrop-filter: saturate(180%) blur(5px);
    }

    > * + * {
      margin-left: 0.5rem;
    }
  `}
`

export const Details = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.warningLighten50};
    grid-column: 1/-1;
    /* border: 4px solid #fff; */
    border-top: 1px solid #ddd;
    display: unset !important;
    font-size: 0.75rem;

    margin: 0 -0.25rem;
    padding: 0.25rem 0.75rem !important;
    border-radius: 0 0 0.75rem 0.75rem;
  `}/* > span > strong {
    margin-right: 2px;

    &:first-child {
      margin-left: 2px;
    }
  } */
`
