import * as S from './styles'

interface LabelProps {
  children?: React.ReactNode
  icon?: React.ReactNode
  className?: string
  htmlFor?: string
  isRequired?: boolean
  text?: string
  helperText?: string | React.JSX.Element
  as?: string
}

const Label: React.FC<LabelProps> = ({
  children,
  className,
  htmlFor,
  isRequired,
  text,
  helperText,
  icon,
  ...rest
}) => (
  <S.Container
    htmlFor={htmlFor}
    className={`${className && `${className}`}`}
    {...rest}
  >
    <S.Text>
      {icon && <span className="icon me-1 d-inline-flex">{icon}</span>}
      {children || text}{' '}
      {isRequired && <S.Badge aria-label="Campo obrigatório">*</S.Badge>}
    </S.Text>

    {helperText && <S.Helper>{helperText}</S.Helper>}
  </S.Container>
)

export { Label }

Label.defaultProps = {
  className: '',
  htmlFor: '',
  isRequired: false,
  children: null,
  text: null,
  helperText: null
}
