import moment from 'moment-timezone';

import { FLYERS_STATUS } from '../constants/status';

moment.locale('pt-br');

export const getStatus = flyer => {
  const isActive = flyer.active;
  const isDisabled = flyer.disabled_at;
  const isBefore = moment().isBefore(flyer.start_at);
  const isAfter = moment().isAfter(flyer.end_at);
  const isBetweeen = moment().isBetween(flyer.start_at, flyer.end_at);

  let status;

  // scheduled
  if (isActive && isBefore) {
    status = 'scheduled';
  }
  // active
  if (isActive && isBetweeen) {
    status = 'active';
  }
  // finished
  if (isAfter) {
    status = 'finished';
  }
  // pause
  if (!isActive && isDisabled && isBetweeen) {
    status = 'pause';
  }
  // canceled
  if (!isActive && isDisabled && isAfter) {
    status = 'canceled';
  }

  return { type: status, ...FLYERS_STATUS[status] };
};
