import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Form } from '@unform/web'
import * as Yup from 'yup'

// Custom Components
import AlertDismissible from '~/components/AlertDismissible'
import { Button } from '~/components/Buttons'
import { Input, InputPassword, FormGroup, Label } from '~/components/unform'
import { handleValidationsErrors } from '~/helpers'
import useQuery from '~/hooks/useQuery'
import { useAppSelector } from '~/store/hooks'

import { signIn } from '../store/login/actions'

type FormData = {
  email: string
  password: string
}

const LogInForm = () => {
  const { t } = useTranslation()

  const query = useQuery()
  const callback_url = query.get('callback_url')

  const [alert, setAlert] = useState(false)
  const [data, setData] = useState({})
  const { loading, error } = useAppSelector(state => state.signin)

  const formRef = useRef(null)

  const showAlert = () => {
    if (error) {
      if (!alert) {
        setAlert(true)
      }
    }
  }

  const closedAlert = () => {
    setAlert(false)
  }

  useEffect(() => {
    showAlert()
  }, [error])

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().min(6).required()
  })

  const handleSubmit = useCallback(async (formData: FormData) => {
    try {
      await schema.validate(formData, {
        abortEarly: false
      })

      // Validation passed
      signIn(formData, callback_url)
    } catch (err) {
      console.error({ err })

      handleValidationsErrors(err, formRef)
    }
  }, [])

  const handleChange = e => {
    const { name, value } = e.target

    setData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const formIsValid = useMemo(() => schema.isValidSync(data), [data, schema])

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onInvalid={e => console.log(e)}
    >
      <FormGroup>
        <Label>{t('auth:login.form.email.label')}</Label>
        <Input
          name="email"
          type="email"
          placeholder={t('auth:login.form.email.placeholder')}
          required
          showWarningOnRequired={false}
        />
      </FormGroup>

      <br />

      <FormGroup>
        <Label>{t('auth:login.form.password.label')}</Label>
        <InputPassword
          placeholder={t('auth:login.form.password.placeholder')}
          name="password"
          required
          showWarningOnRequired={false}
        />
      </FormGroup>

      {alert && (
        <AlertDismissible
          type="danger"
          isOpen={alert}
          onClose={closedAlert}
          className="login-form__alert"
          messageText={
            error
              ? t('auth:login.form.alert.error')
              : t('auth:login.form.alert.empty')
          }
        />
      )}

      <FormGroup className="actionButtons">
        <Button
          type="submit"
          size="large"
          text={t('auth:login.form.submit.text')}
          textLoading={t('auth:login.form.submit.textLoading')}
          loading={loading}
          disabled={!formIsValid}
          className="mx-auto"
        />
      </FormGroup>

      <footer className="mt-4 pt-3 mx-auto text-center border-top border-tertiary">
        <Link to="/forgot-password">{t('auth:login.form.forgotPassword')}</Link>
      </footer>
    </Form>
  )
}

export default LogInForm
