import { useMemo } from 'react'

import { formatAddress } from './helpers/formatAddress'

import * as S from './styles'

/**
 * Componente para retornar um elemento address formatado. Pode receber dados no
 * formato de `location` ou de `shipping_address`.
 *
 * @example
 * `<AddressInline location={location} shippingAddress={shippingAddress} />`
 *
 * @returns Elemento `<address>` com o endereço formatado
 */

export const AddressInline = ({ location, shippingAddress }: AddressProps) => {
  const address = useMemo(
    () => formatAddress({ location, shippingAddress }),
    [location, shippingAddress]
  )

  return (
    <S.Container>
      {address ? (
        <address>
          {address.street && <span title="Logradouro">{address.street}</span>}

          {address.street && address.number && (
            <span className="number" title="Número">
              , {address.number}
            </span>
          )}

          {address.complement && (
            <span title="Complemento"> {address.complement}</span>
          )}

          {address.neighborhood && (
            <span title="Bairro"> {address.neighborhood}</span>
          )}

          {address.city && <span title="Cidade"> {address.city}</span>}

          {address.state && <span title="Estado"> {address.state}</span>}

          {address.country && <span title="País"> {address.country}</span>}

          {address.postalCode && <span title="CEP"> {address.postalCode}</span>}
        </address>
      ) : (
        'Erro ao carregar o endereço.'
      )}
    </S.Container>
  )
}
