import React from 'react';

import Panel from '~/components/Panel';
import Table from '~/components/Table';

const schema = {
  id: {
    render: obj => obj.id,
    title: 'SKU',
  },
  name: {
    render: obj => obj.productName,
    title: 'Estoque',
  },
  value: {
    render: obj => obj.date,
    title: 'Reposição',
  },
};

const PositionStock = ({ loading, data = {} }) => (
  <Panel title="Posição Estoque" noPaddingBody isLoading={loading}>
    <Table schema={schema} data={data.info} />
  </Panel>
);

export default PositionStock;
