import styled from 'styled-components';

export const ContainerPassword = styled.div`
  display: flex;

  > form {
    min-width: 300px;
    margin-right: 1rem;

    > * + * {
      margin-top: 0.5rem;
    }
  }
`;
