export const listColorsChart = [
  '#3c3584',
  '#4bbf73',
  '#e08300',
  '#E8EAED',
  '#f44455',
  '#1f9bcf',
  '#fcc100',
  '#9d7bd8',
  '#47bac1',
  '#adb5bd',
];
