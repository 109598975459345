import React, { useCallback, useState, useMemo } from 'react'
import { FiEdit, FiTrash2, FiX, FiGlobe, FiLock } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'
import { getRole, getUserId } from '~/helpers'
import { useDeleteConfirmation } from '~/hooks/useDeleteConfirmation'
import { transparentDanger } from '~/store/modules/chatTemplates/actions'

import CreateTemplate from '../Form'

import * as S from './styles'

const Template = ({ data }) => {
  const [editMode, setEditMode] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const dispatch = useDispatch()

  const isAdmin = useMemo(() => getRole() === 'admin', [])
  const isOwner = useMemo(() => getUserId() === data.user_id, [data])
  const isPrivate = useMemo(() => !!data.user_id, [data])

  const isEditable = useMemo(
    () => (!isPrivate && isAdmin) || (isPrivate && isOwner),
    [isPrivate, isAdmin, isOwner]
  )

  const isSaving = useMemo(() => data?.saving, [data])

  const [DeleteConfirmation, activeConfirmation, setActiveConfirmation] =
    useDeleteConfirmation()

  const handleEditMode = () => setEditMode(prevState => !prevState)

  const handleDelete = useCallback(() => {
    setLoadingDelete(true)

    dispatch(transparentDanger(data.templateId))

    setActiveConfirmation()
    setLoadingDelete(false)
  }, [data.templateId, dispatch, setActiveConfirmation])

  return (
    <S.Container
      editMode={editMode}
      deleteMode={activeConfirmation}
      isDeleted={data?.deleted}
      isSaving={isSaving}
    >
      {isSaving && (
        <div className="loadingIndicator">
          <Spinner />
        </div>
      )}

      <header>
        <strong>{data.title}</strong>
        <aside>
          <div className="global">
            {isPrivate ? (
              <>
                <FiLock /> Privada
              </>
            ) : (
              <>
                <FiGlobe /> Global
              </>
            )}
          </div>
        </aside>
      </header>

      {!editMode && (
        <section>
          <p>{data.message}</p>
        </section>
      )}

      {editMode && (
        <S.ContainerEdit>
          <CreateTemplate initialData={data} onSuccess={handleEditMode} />
        </S.ContainerEdit>
      )}

      {isEditable && (
        <div className="wrapperConfirmDelete">
          <DeleteConfirmation
            active={activeConfirmation}
            onDelete={handleDelete}
            onCancel={setActiveConfirmation}
            yesMsg="Confirmar"
            cancelMsg="Cancelar"
            loading={loadingDelete}
            elementPosition="absolute"
          >
            Excluir a mensagem pronta <strong>{data.title}</strong>?
          </DeleteConfirmation>
        </div>
      )}

      {isEditable && (
        <footer>
          <>
            <Button
              template="danger"
              iconLeft={<FiTrash2 />}
              text="Excluir"
              customWidth="auto"
              size="xsmall"
              onClick={setActiveConfirmation}
              loading={loadingDelete}
              disabled={data?.saving}
            />

            {editMode ? (
              <Button
                size="xsmall"
                iconLeft={<FiX />}
                text="Cancelar edição"
                customWidth="auto"
                template="warning"
                onClick={handleEditMode}
                disabled={data?.saving}
              />
            ) : (
              <Button
                size="xsmall"
                iconLeft={<FiEdit />}
                text="Editar"
                customWidth="auto"
                template="info"
                onClick={handleEditMode}
                disabled={data?.saving}
              />
            )}
          </>
        </footer>
      )}
    </S.Container>
  )
}

export default Template

Template.propTypes = {
  data: PropTypes.shape({
    templateId: PropTypes.string.isRequired,
    user_id: PropTypes.string,
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    global: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    // System
    saving: PropTypes.bool,
    deleted: PropTypes.bool
  }).isRequired
}
