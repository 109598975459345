import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Container } from '~/components/Container';
import HeaderPages from '~/components/HeaderPages';
import Loading from '~/components/Loading';
import PageTitle from '~/components/PageTitle';
import { useAlert } from '~/hooks/useAlert';
import Card from '~/pages/financial/PaymentMethods/elements/Card';
import { showLocation } from '~/pages/locations/actions';
import paymentActions from '~/store/modules/payment/actions';

import LocationNav from '../_elements/LocationNav';

const LocationsPayment = () => {
  const { location, error, loading } = useSelector(state => state.locations);

  const { id } = useParams();

  const dispatch = useDispatch();

  const [AlertOnError] = useAlert(error);

  const loadLocation = useCallback(() => {
    dispatch(showLocation(id));
  }, [dispatch, id]);

  useEffect(() => {
    loadLocation();
    dispatch(paymentActions.methods.get());
    dispatch(paymentActions.methodsConfig.get());
  }, [dispatch, loadLocation]);

  const titlePage = useMemo(
    () =>
      location?.location?.name
        ? `Local: ${location.location.name}`
        : 'Carregando...',
    [location],
  );

  return (
    <Container>
      <PageTitle title={titlePage} />

      <HeaderPages
        title={titlePage}
        backTo="/locations/list"
        labelBreadcrumb="Voltar aos locais"
      />

      <AlertOnError />

      <Loading status={loading}>Carregando informações do local</Loading>

      <LocationNav locationId={id} />

      {location?.location && (
        <Card locationId={id} title={location.location.name} isOpen />
      )}
    </Container>
  );
};

export default LocationsPayment;
