import styled, { css } from 'styled-components'

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    max-width: 22rem;

    .mainInfo {
      line-height: 1;
      min-width: 200px;

      .title {
        margin-bottom: ${theme.spacings.tiny};
        font-size: ${theme.font.sizes.xsmall};
        color: ${theme.colors.primary};
      }

      .description {
        font-size: ${theme.font.sizes.xsmall};
      }
    }
  `}
`

export const Image = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 4rem;
    max-width: 4rem;
    max-height: 4rem;
    margin-right: ${theme.spacings.xxxsmall};
    border: 0.25rem solid ${theme.colors.light};

    border-radius: ${theme.border.radius.large};

    background-color: ${theme.colors.light};

    .without-image {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 4rem;
      height: 4rem;

      background-color: ${theme.colors.light};
      color: ${theme.colors.primaryLighten30};
    }

    img {
      mix-blend-mode: multiply;
      max-width: 3.75rem;
      max-height: 3.75rem;
      object-fit: cover;
    }
  `}
`
