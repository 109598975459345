import styled, { css } from 'styled-components';

const containerModifiers = {
  editMode: theme => css`
    border: 1px solid ${theme.colors.warning};
    background-color: ${theme.colors.secondaryLighten30};

    &:not(:first-child) {
      margin-top: ${theme.spacings.xxsmall};
    }

    &:not(:last-child) {
      margin-bottom: ${theme.spacings.xxsmall};
    }
  `,
  deleteMode: theme => css`
    border: 1px solid ${theme.colors.danger};
  `,
  isDeleted: () => css`
    opacity: 0.5;
  `,
};

export const Container = styled.div`
  ${({ theme, tagColor, editMode, deleteMode, isDeleted }) => css`
    padding: ${theme.spacings.xsmall};
    border: 1px solid ${theme.colors.inative};
    border-radius: 0.25rem;
    background-color: ${theme.colors.white};
    position: relative;

    transition: ${theme.transition.default} all;

    ${editMode && containerModifiers.editMode(theme)}
    ${deleteMode && containerModifiers.deleteMode(theme)}
    ${isDeleted && containerModifiers.isDeleted()}

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info {
        display: inline-flex;
        align-items: center;
        background-color: ${theme.colors.white};
        border-radius: 3rem;
        padding: ${theme.spacings.xxxsmall} ${theme.spacings.xxsmall};

        &:before {
          content: '';
          background-color: ${tagColor};
          border-radius: 50%;
          height: 0.5rem;
          width: 0.5rem;
          display: inline-flex;
          margin-right: ${theme.spacings.tiny};
        }
      }

      aside {
        > * + * {
          margin-left: 0.25rem;
        }
      }
    }
  `}
`;

export const ContainerEdit = styled.div``;
