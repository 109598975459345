import { types } from '../constants'

const index = {
  pending: `${types.listAdvertisers}_PENDING`,
  fulfilled: `${types.listAdvertisers}_FULFILLED`,
  rejected: `${types.listAdvertisers}_REJECTED`
}

export const listAdvertisers = {
  [index.pending]: ({ draft }: ActionData) => {
    draft.advertiserPublisher.advertisers.loading = true
    draft.advertiserPublisher.advertisers.error = null
  },
  [index.fulfilled]: ({ draft, action }: GetPublisherAdvertisersFulfilled) => {
    draft.advertiserPublisher.advertisers.data = action.payload.advertisers.map(
      item => ({
        ...item,
        id: item.advertiser_id,
        name: item.advertiser_name
      })
    )

    draft.advertiserPublisher.advertisers.loading = false
    draft.advertiserPublisher.advertisers.error = null
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft.advertiserPublisher.advertisers.loading = false
    draft.advertiserPublisher.advertisers.error = action.payload
  }
}
