import { handleSortDirection } from '~/helpers/sortData'
import { formatIndustryListPublisher } from '~/modules/retailMedia/dtos/industry/publisher/list'

import { types } from '../constants'

const index = {
  pending: `${types.list}_PENDING`,
  fulfilled: `${types.list}_FULFILLED`,
  rejected: `${types.list}_REJECTED`,
  setPage: types.page,
  setPerPage: types.perPage,
  sort: types.listSort
}

const parentKey = 'publisher'
const key = 'list'

export const list = {
  [index.pending]: ({ draft }: ActionData) => {
    draft[parentKey][key].loading = true
  },
  [index.fulfilled]: ({
    draft,
    action
  }: ActionData<ResponseListGroceryAdvertisers>) => {
    const formattedData = formatIndustryListPublisher(action.payload.data)

    draft[parentKey][key].data = formattedData
    draft[parentKey][key].pages = action.payload.pages || 1
    draft[parentKey][key].total =
      action.payload.total || action.payload.data.length
    draft[parentKey][key].page = action.payload.currentPage

    draft[parentKey][key].loading = false
    draft[parentKey][key].error = null
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft[parentKey][key].loading = false
    draft[parentKey][key].error = action.payload
  },
  [index.setPage]: ({ draft, action }: ActionData) => {
    draft[parentKey][key].page = action.payload
  },
  [index.setPerPage]: ({ draft, action }: ActionData) => {
    draft[parentKey][key].perPage = action.payload
  },
  [index.sort]: ({ draft, action }: ActionData<OnSortProps>) => {
    const selectedKey = action.payload.key
    const sortInitialDirection = action.payload?.sortInitialDirection

    const currentSortDirection =
      draft[parentKey][key].sortDirection ||
      draft[parentKey][key].sortDirectionDefault

    const currentSortKey =
      draft[parentKey][key].sortKey || draft[parentKey][key].sortKeyDefault

    const { sortDirection, sortKey } = handleSortDirection({
      selectedKey,
      currentSortDirection,
      currentSortKey,
      sortInitialDirection
    })

    draft[parentKey][key].sortDirection = sortDirection
    draft[parentKey][key].sortKey = sortKey
  }
}
