import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import { UncontrolledPopover, PopoverBody, PopoverHeader } from 'reactstrap';

const Popover = forwardRef((props, ref) => {
  const {
    children,
    title,
    target,
    placement,
    onPopoverToggle,
    className,
  } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    closePopover() {
      setPopoverOpen(false);
    },
  }));

  useEffect(() => {
    onPopoverToggle(popoverOpen);
  }, [popoverOpen]);

  return (
    <>
      <UncontrolledPopover
        placement={placement}
        target={target}
        isOpen={popoverOpen}
        trigger="legacy"
        toggle={() => setPopoverOpen(!popoverOpen)}
        className={className}
      >
        {title && <PopoverHeader>{title}</PopoverHeader>}
        <PopoverBody>{children}</PopoverBody>
      </UncontrolledPopover>
    </>
  );
});

Popover.defaultProps = {
  placement: 'bottom',
  className: '',
  onPopoverToggle: () => {},
};

export default Popover;
