/* Cópia tags do chat */

import styled, { css } from 'styled-components'

const containerModifiers = {
  deleteMode: theme => css`
    border: 1px solid ${theme.colors.danger};
  `,
  isDeleted: () => css`
    opacity: 0.5;
  `,
  isSaving: () => css`
    .role {
      transform: translateX(-1.25rem);
    }
  `
}

export const Container = styled.div`
  ${({ theme, isChecked, deleteMode, isDeleted, isSaving }) => css`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0.75rem 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid ${theme.colors.inative};
    border: 0;
    outline: 0;

    background: ${theme.colors.white};
    color: ${theme.colors.dark};
    font-size: 0.875rem;

    transition: ${theme.transition.default} all;

    &:focus {
      outline: none;
    }

    .check {
      display: inline-flex;

      margin-right: 0.5rem;

      border-radius: 0.25rem;

      background: ${isChecked ? theme.colors.white : theme.colors.light};
      color: ${isChecked ? theme.colors.success : theme.gray};

      font-size: 1.25rem;
    }

    .loadingIndicator {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%);
    }

    .label {
      flex: 1;

      display: flex;
      align-items: center;
      flex-wrap: wrap;
      text-align: left;
      line-height: 1.125rem;

      .userInfos {
        flex: 1;

        display: flex;
        flex-direction: column;
        line-height: 1;

        .name {
          font-size: ${theme.font.sizes.medium};
          color: ${isChecked ? theme.colors.primary : theme.dark};
        }

        .email {
          flex: 1 1 100%;
        }
      }
    }

    ${deleteMode && containerModifiers.deleteMode(theme)}
    ${isDeleted && containerModifiers.isDeleted()}
    ${isSaving && containerModifiers.isSaving()}
  `}
`
