export const DTOCreateBannerCampaign = (
  data: DTOCreateBannerCampaignPayload
) => {
  const costType = data?.cost?.cost_type || 'cpmcpc'
  /**
   * Handle banner config
   */
  const bannerData: DTOCreateBannerCampaignConfig = {
    keywords: data.targeting?.keywords?.split(',') || null,
    categories: data.targeting?.categories?.split(',') || null,
    assets: data.banners.map(item => ({
      ad_size: item.ad_size,
      alt_text: item.alt_text,
      media_url: item.media_url
    }))
  }

  if (data.settings?.destination_url) {
    bannerData.destination_url = data.settings?.destination_url || null
  }

  if (data.settings?.destination_urls) {
    bannerData.destination_urls = data.settings?.destination_urls || null
  }

  /**
   * Handle products linkded to campaign
   */

  const products =
    data?.products?.map(item => ({
      id: item.id ? String(item.id) : undefined,
      sku: item.sku ? String(item.sku) : undefined
    })) || null

  /*
   * Handle budget
   */
  const { lengthCentsCurrency } = data

  const dailyCostRaw = data.settings?.daily_cost?.numAsString
  const dailyCost = lengthCentsCurrency
    ? Number(dailyCostRaw) / 100
    : Number(dailyCostRaw)

  const cpmRaw = data.budget?.cpm?.numAsString
  const cpm = lengthCentsCurrency ? Number(cpmRaw) / 100 : Number(cpmRaw)

  const dailyBudgetRaw = data.budget?.daily?.numAsString
  const dailyBudget = lengthCentsCurrency
    ? Number(dailyBudgetRaw) / 100
    : Number(dailyBudgetRaw)

  const budget = {
    daily_budget: dailyBudget,
    dailyCost,
    cpm
  }

  const costsByType = {
    cpmcpc: () => ({
      daily_budget: budget.daily_budget,
      cpm: budget.cpm
    }),
    daily_cost: () => ({
      daily_cost: budget.dailyCost
    })
  }

  /**
   * Handle campaign settings
   */

  const settings: DTOCreateBannerCampaignSettings = {
    products,
    ...costsByType[costType]()
  }

  if (data?.placements?.placementsCheck === 'selected') {
    const placements = data?.placements?.placements_ids
    const selectedPlacements = Object.values(placements).filter(
      values => values.checked
    )

    settings.placements = selectedPlacements.map(({ value: id }) => ({ id }))
  }

  if (data?.settings?.budget_type) {
    settings.budget_type = data.settings.budget_type
  }

  const body: DTOCreateBannerCampaignResponse = {
    publisher_id: data?.publisher_id,

    name: data.name,
    description: data?.description,

    type: 'on_site',
    ad_type: 'banner',

    start_at: data.start_date,
    end_at: data?.end_date || null,

    banner: { ...bannerData },

    settings
  }

  return body
}
