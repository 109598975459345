import { useMemo } from 'react'

import { formatAddress } from './helpers/formatAddress'

import * as S from './styles'

/**
 * Componente para retornar um elemento address formatado. Pode receber dados no
 * formato de `location` ou de `shipping_address`.
 *
 * @example
 * `<Address location={location} shippingAddress={shippingAddress} />`
 *
 * @returns Elemento `<address>` com o endereço formatado
 */

export const Address = ({ location, shippingAddress }: AddressProps) => {
  const address = useMemo(
    () => formatAddress({ location, shippingAddress }),
    [location, shippingAddress]
  )

  return (
    <S.Container className="addressSection">
      {address ? (
        <address>
          {address.street && <span title="Logradouro">{address.street}</span>}

          {address.number && address?.number !== '-' && (
            <span title="Número" className="number">
              , {address.number}
            </span>
          )}

          {address.complement && address.complement !== '-' && (
            <span title="Complemento"> {address.complement}</span>
          )}

          {address.neighborhood && address.neighborhood !== '-' && (
            <span title="Bairro"> {address.neighborhood}</span>
          )}

          {address.city && address.city !== '-' && (
            <span title="Cidade"> {address.city}</span>
          )}

          {address.state && address.state !== '-' && (
            <span title="Estado"> {address.state}</span>
          )}

          {address.postalCode && address.postalCode !== '-' && (
            <span title="CEP" className="postalCode">
              <br />
              {address.postalCode}
            </span>
          )}
        </address>
      ) : (
        'Erro ao carregar o endereço.'
      )}
    </S.Container>
  )
}
