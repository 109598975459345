import React, { useMemo } from 'react'
import { FiCalendar, FiShoppingCart, FiMapPin } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { Header, WrapperShadow } from '~/components/WrapperShadow'
import { channelName, formatDateToString } from '~/helpers'

import { statusList } from '../../../_status/order'
import { ModelConfirmationCancelOrder } from '../ModelConfirmationCancelOrder'
import RestartPicking from '../RestartPicking'
import { SelectStatus } from '../SelectStatus'

import * as S from './styles'

const PanelMainInfos = ({ scrollToActiveFields }) => {
  const { orderData } = useSelector(state => state.order)

  const formattedDate = useMemo(
    () => formatDateToString(orderData.createdAt),
    [orderData.createdAt]
  )

  const channelFormatted = useMemo(() => {
    const { channel_id } = orderData

    return channelName(channel_id)
  }, [orderData])

  const locationName = useMemo(() => {
    const name = orderData?.location?.[0]?.name

    return name || null
  }, [orderData])

  const showRestartPickingButton = useMemo(
    () =>
      orderData?.status?.[0]?.type === 'invoice_issued' &&
      orderData?.channel_id !== 'magento_ecommerce',
    [orderData]
  )

  const hasCancelled = useMemo(() => {
    return orderData.status?.[0]?.type === statusList.canceled.type
  }, [orderData.status])

  return (
    <WrapperShadow className="sectionWithFeatured">
      <Header>
        <div className="inlineFeatures">
          {formattedDate && (
            <span className="box">
              <div className="icon hideMobile">
                <FiCalendar />
              </div>
              <div className="text">
                <strong>Realizada:</strong> {formattedDate}
              </div>
            </span>
          )}

          {/* Canal de origem do pedido */}
          {channelFormatted && (
            <span className="box">
              <div className="icon hideMobile">
                <FiShoppingCart />
              </div>
              <div className="text">
                <strong>Origem:</strong> {channelFormatted}
              </div>
            </span>
          )}

          {/* Loja responsável pelo pedido */}
          {locationName && (
            <span className="box">
              <div className="icon hideMobile">
                <FiMapPin />
              </div>
              <div className="text">
                <strong>Loja:</strong> {locationName}
              </div>
            </span>
          )}
        </div>

        <aside className="featured">
          {showRestartPickingButton && <RestartPicking />}

          <S.WrapperStatusAndConfirmation hasCancelled={hasCancelled}>
            <S.WrapperButtons>
              <ModelConfirmationCancelOrder hasCancelled={hasCancelled} />
              <SelectStatus />
            </S.WrapperButtons>
          </S.WrapperStatusAndConfirmation>
        </aside>
      </Header>
    </WrapperShadow>
  )
}

export default PanelMainInfos
