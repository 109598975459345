import { t } from 'i18next'

import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'

import RealtimeDashboardRetailMedia from '../../components/RealtimeDashboard'

const pageTitle = `${t('rm:monitoring')} - Retail media`

const RetailMediaCheck = () => (
  <>
    <PageTitle title={pageTitle} />

    <HeaderPages title={pageTitle} />

    <RealtimeDashboardRetailMedia />
  </>
)

export default RetailMediaCheck
