import { ChartOptions } from 'chart.js'

import adCostPreset from '../metrics/adCost'
import balancePreset from '../metrics/balance'
import clicksPreset from '../metrics/clicks'
import conversionsPreset from '../metrics/conversions'
import impressionsPreset from '../metrics/impressions'
import impressionsDiffPreset from '../metrics/impressionsDiff'
import incomePreset from '../metrics/income'
import roasPreset from '../metrics/roas'
import totalCampaignsPreset from '../metrics/totalCampaigns'
import totalSpentPreset from '../metrics/totalSpent'
import totalSpentNoScalePreset from '../metrics/totalSpentNoScale'
import viewsPreset from '../metrics/views'
import {
  ChartPreset,
  ChartPresetProps,
  CreateChartOptions,
  CreateDatasets
} from '../types'

const availablePresets = {
  impressions: impressionsPreset,
  clicks: clicksPreset,
  conversions: conversionsPreset,
  views: viewsPreset,
  income: incomePreset,
  totalSpent: totalSpentPreset,
  totalSpentNoScale: totalSpentNoScalePreset,
  balance: balancePreset,
  roas: roasPreset,
  totalCampaigns: totalCampaignsPreset,
  adCost: adCostPreset,
  impressionsDiff: impressionsDiffPreset
}

const datasets: CreateDatasets = ({ data, metrics }) =>
  metrics.map(metric =>
    availablePresets[metric].dataset({ data, selectedMetrics: metrics })
  )

const chartOptions: CreateChartOptions = ({ data, metrics }) => ({
  scales: metrics.reduce(
    (acc, metric, index) => ({
      ...acc,
      ...availablePresets[metric].optionsScale({
        data,
        selectedMetrics: metrics,
        drawOnChartArea: index === 0,
        showScaleRuler: index < 2,
        position: index === 0 ? 'left' : 'right'
      })
    }),
    {}
  )
})

export const dynamicChartPreset: ChartPreset = ({
  data,
  metrics = ['clicks', 'impressions']
}) => ({
  datasets: datasets({ data, metrics }),
  chartOptions: chartOptions({ data, metrics })
})
