import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

import EmptyPage from '~/components/empty-page';
import DatepickerHeader from '~/components/datepicker-header';
import HeaderPages from '~/components/HeaderPages';
import Search from '~/components/search';

import RevenueChart from './_elements/revenue-chart';
import MarginChart from './_elements/margin-chart';
import StockTable from './_elements/stock-table';
import OriginChartTable from './_elements/origin-chart-table';
import BestSellersTable from './_elements/best-sellers-table';

const hasProducts = true;
const ProductsReports = () => {
  const { startDate, endDate } = useSelector(state => state.datepickerHeader);

  return (
    <Container fluid className="p-0">
      {hasProducts ? (
        <>
          <DatepickerHeader />
          <HeaderPages title="Relatório de Produtos" />
          <Search
            placeholder="Buscar Produto (Nome, SKU, EAN, etc)"
            onSubmit={() => {}}
            isLoading={false}
          />
          <RevenueChart startDate={startDate} endDate={endDate} />
          <MarginChart startDate={startDate} endDate={endDate} />
          <BestSellersTable startDate={startDate} endDate={endDate} />
          <Row>
            <Col lg="6" className="d-flex">
              <OriginChartTable startDate={startDate} endDate={endDate} />
            </Col>
            <Col lg="6" className="d-flex">
              <StockTable startDate={startDate} endDate={endDate} />
            </Col>
          </Row>
          {/* <Steps />
        <OrdersTable />
        <OrdersChart />
        <Row>
          <Col lg="6" className="d-flex">
            <PaymentTable />
          </Col>
          <Col lg="6" className="d-flex">
            <PlatformTable />
          </Col>
        </Row> */}
        </>
      ) : (
        <EmptyPage
          title="Produtos"
          subtitle="Você ainda não tem produtos cadastrados, comece importando sua base de produtos"
          titleButton="Importar"
          svgPath="photos/products-empty"
          // eslint-disable-next-line no-console
          onClick={() => console.log('clicou')}
        />
      )}
    </Container>
  );
};

export default ProductsReports;
