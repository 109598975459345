import * as types from '../../constants';

const index = {
  pending: `${types.CREATE_PAYMENT_METHODS_CONFIG}_PENDING`,
  fulfilled: `${types.CREATE_PAYMENT_METHODS_CONFIG}_FULFILLED`,
  rejected: `${types.CREATE_PAYMENT_METHODS_CONFIG}_REJECTED`,
};

export const cases = {
  [index.pending]: ({ draft }) => {
    draft.paymentMethodsConfigCreateLoading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    const createdConfigList = action.payload;
    const oldConfigList = draft.paymentMethodsConfigList;

    draft.paymentMethodsConfigList = [...oldConfigList, createdConfigList];

    draft.paymentMethodsConfigCreateLoading = false;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.paymentMethodsConfigCreateLoading = false;
  },
};
