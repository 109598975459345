import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import PropTypes from 'prop-types'

import FilterLocations from '~/components/FilterLocations'
import { history } from '~/helpers'
import { useDispatch } from 'react-redux'
import { showAllOnToEdit } from '~/pages/locations/actions'

const SelectLocations = ({ isPreview }) => {
  const { id: locationId } = useParams()

  const suffixUrl = useMemo(() => (isPreview ? '' : '/edit'), [isPreview])
  const dispatch = useDispatch()
  const handleChangeLocation = useCallback(
    data => {
      const suffix = data?.value ? suffixUrl : ''
      dispatch(showAllOnToEdit({ data: [] }))
      return history.push(`/logistic/${data?.value || 'all'}/regions${suffix}`)
    },
    [suffixUrl]
  )

  return (
    <section className="selectLocations">
      <header>
        <strong className="title">Exibindo regiões de:</strong>
      </header>

      <FilterLocations
        placeholder="Escolher um local"
        labelAll="Ver todos locais"
        initialLocation={locationId}
        onChange={handleChangeLocation}
        ignoreRedux
      />
    </section>
  )
}

export default SelectLocations

SelectLocations.propTypes = {
  isPreview: PropTypes.bool
}

SelectLocations.defaultProps = {
  isPreview: false
}
