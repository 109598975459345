import { formatSearchString, history } from '~/helpers'
import {
  indexAllLocationRegionsService,
  indexLocationService,
  showLocationService,
  createLocationService,
  updateLocationService,
  deleteLocationService,
  indexLocationDeliveryTypesService,
  indexLocationUsersService,
  createLocationUsersService,
  deleteLocationUsersService,
  indexLocationRegionsService,
  createLocationRegionsService,
  updateLocationRegionsService,
  deleteLocationRegionsService,
  indexLocationMinifiedService
} from '~/services/location-service'
import store from '~/store'

import * as types from './constants'

/**
 *
 * @exports indexLocation  Busca todos os locais da loja
 *
 * @exports showLocation Busca as informações de um local
 * @param {string} id ID do local
 *
 * @exports createLocation Criação de local
 * @param {object} data  Dados para criação do local
 *
 * @exports updateLocation Atualiza um local
 * @param {object} data Dados para atualizar um local
 *
 * @exports deleteLocation Deletar um local
 * @param {string} id Id do local que será deletado
 *
 * @exports indexLocationDeliveryTypes Busca os tipos de entrega cadastrados na conta
 *
 */

export const indexLocation = ({
  page = 1,
  quantity,
  query,
  externalId,
  address_state,
  active,
  type
}) => {
  const queryString = formatSearchString({
    page: page - 1,
    quantity,
    name: query,
    external_id: externalId,
    address_state,
    active,
    type
  })

  return {
    type: types.LOCATION_LIST,
    payload: indexLocationService(queryString),
    page
  }
}

export const handleLocationPerPage = value => {
  return {
    type: types.LOCATIONS_PER_PAGE_CHANGED,
    payload: value
  }
}

export const indexLocationMinified = queryString => ({
  type: types.LOCATION_LIST_MINIFIED,
  payload: indexLocationMinifiedService(queryString)
})

export const showLocation = id => ({
  type: types.LOCATION_GET_INFO,
  payload: showLocationService(id),
  onError: () => {
    history.push(`/locations/list`)
  },
  getErrorFeedbackMsg: error => error.response.data.error
})

export const createLocation = data => ({
  type: types.LOCATION_SUBMIT_FORM,
  payload: createLocationService(data),
  onSuccess: () => {
    history.push(`/locations/list`)
  },
  getErrorFeedbackMsg: error => error.response.data.error
})

export const updateLocation = data => ({
  type: types.LOCATION_UPDATE_INFO,
  payload: updateLocationService(data),
  successFeedbackMsg: 'Local atualizado com sucesso',
  getErrorFeedbackMsg: error => error.response.data.error
})

export const deleteLocation = id => ({
  type: types.LOCATION_DELETE,
  payload: deleteLocationService(id),
  successFeedbackMsg: 'Local removido com sucesso!',
  onSuccess: () => {
    history.push(`/locations/list`)
  }
})

/**
 * Delivery type
 */
export const indexLocationDeliveryTypes = () => ({
  type: types.LOCATION_DELIVERY_TYPES_LIST,
  payload: indexLocationDeliveryTypesService()
})

/**
 * Delivery type
 */
export const setMapCoordinates = data => ({
  type: types.LOCATION_MAP_COORDINATES,
  payload: data
})
export const resetMapCoordinates = () => ({
  type: types.LOCATION_MAP_COORDINATES_RESET
})

/**
 * Location users
 */
export const indexLocationUsers = ({ locationId }) => ({
  type: types.LOCATION_USERS_LIST,
  payload: indexLocationUsersService({ locationId })
})

/**
 *
 * {
 *  "location_id": "e029a930-de87-11eb-bf83-5dda1ba660ee",
 *  "user_id": "1234567"
 * }
 */
export const createLocationUsersOptimistic = ({ body }) => ({
  type: types.LOCATION_USERS_CREATE_OPTIMISTIC,
  payload: { body }
})

export const createLocationUsers = ({ body }) => {
  const { dispatch } = store

  dispatch(createLocationUsersOptimistic({ body }))

  return {
    type: types.LOCATION_USERS_CREATE,
    payload: createLocationUsersService({ body })
  }
}

export const deleteLocationUsersOptimistic = ({ userId }) => ({
  type: types.LOCATION_USERS_DELETE_OPTIMISTIC,
  payload: { userId }
})

export const deleteLocationUsers = ({ locationId, userId }) => {
  const { dispatch } = store

  dispatch(deleteLocationUsersOptimistic({ userId }))

  return {
    type: types.LOCATION_USERS_DELETE,
    payload: deleteLocationUsersService({ locationId, userId })
  }
}

/**
 * Multi região
 */
export const indexAllLocationRegions = ({ data }) => ({
  type: types.LOCATION_REGIONS_LIST_ALL,
  payload: indexAllLocationRegionsService({ data })
})

export const showAllOnToEdit = ({ data }) => ({
  type: types.LOCATION_SHOW_TO_EDIT,
  payload: indexAllLocationRegionsService({ data })
})

export const indexLocationRegions = ({ locationId }) => ({
  type: types.LOCATION_REGIONS_LIST,
  payload: indexLocationRegionsService({ locationId })
})

export const createLocationRegions = ({ body, onSuccess = null }) => ({
  type: types.LOCATION_REGIONS_CREATE,
  payload: createLocationRegionsService(body),
  successFeedbackMsg: 'Região criada com sucesso',
  errorFeedbackMsg: 'Houve um erro ao criar a região',
  onSuccess
})

export const updateLocationRegions = ({ body, onSuccess = null }) => ({
  type: types.LOCATION_REGIONS_UPDATE,
  payload: updateLocationRegionsService(body),
  successFeedbackMsg: 'Região editada com sucesso',
  errorFeedbackMsg: 'Houve um erro ao editar a região',
  onSuccess
})

export const deleteLocationRegions = ({ body, onSuccess = null }) => ({
  type: types.LOCATION_REGIONS_DELETE,
  values: { id: body.regionId },
  payload: deleteLocationRegionsService(body),
  successFeedbackMsg: 'Região deletada com sucesso',
  errorFeedbackMsg: 'Houve um erro ao deletar a região',
  onSuccess
})
