import { clearCustomer } from './clear'
import { createCustomer } from './create'
import { getCustomer } from './get'
import { listCustomers } from './list'
import { updateCustomer } from './update'

export default {
  ...getCustomer,
  ...listCustomers,
  ...createCustomer,
  ...updateCustomer,
  ...clearCustomer
}
