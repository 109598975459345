import { NavLink } from 'react-router-dom'

import { Badge } from '~/components/UI/Badge'
import { isReadOnly } from '~/helpers'
import i18n from '~/i18n'

import { PlacementsActionColumn } from './ActionColumn'

const schema: () => TableSchema<Placement> = () => ({
  name: {
    title: i18n.t('rm:placements.list.table.column.title.name'),
    render: rowData => (
      <NavLink to={isReadOnly() ? null : `${rowData.id}/edit/`}>
        <div className="d-inline-flex align-items-start flex-column gap-1 lh-1">
          <Badge className="name m-0">{rowData.name}</Badge>
          {rowData?.full_name && (
            <strong className="text-primary fullName">
              {rowData.full_name}
            </strong>
          )}
          {rowData?.description && (
            <small className="description">{rowData.description}</small>
          )}
        </div>
      </NavLink>
    ),
    sortKey: 'name'
  },
  context: {
    title: i18n.t('rm:placements.list.table.column.title.context'),
    render: rowData => (
      <span className="placement-context-name">
        {i18n.t('common:capitalize', {
          value: i18n.t(`rm:context.${rowData.context}`)
        })}
      </span>
    ),
    sortKey: 'context'
  },
  formats: {
    title: i18n.t('rm:placements.list.table.column.title.format'),
    render: rowData => (
      <div className="placements-formats">
        {[...new Set(rowData.formats.map(item => item.type))].map(item => (
          <span className="placements-formats-name" key={item}>
            <Badge>
              {i18n.t('common:capitalize', { value: i18n.t(`rm:${item}`) })}
            </Badge>
          </span>
        ))}
      </div>
    )
  },
  sizes: {
    title: i18n.t('rm:placements.list.table.column.title.size'),
    render: rowData => (
      <div className="placements-formats d-flex">
        {rowData.formats
          .filter(item => !!item?.size)
          .map(item => (
            <div className="placements-formats-name" key={item.id}>
              <Badge template="primary">{item.size}</Badge>
            </div>
          ))}
      </div>
    )
  },
  ...(isReadOnly()
    ? {}
    : {
        actions: {
          title: i18n.t('rm:placements.list.table.column.title.management'),
          render: rowData => (
            <PlacementsActionColumn
              rowData={rowData}
              key={`placement${rowData.id}`}
            />
          )
        }
      })
})

export default schema
