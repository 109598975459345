import { types } from '../constants'

const index = {
  pending: `${types.patch}_PENDING`,
  fulfilled: `${types.patch}_FULFILLED`,
  rejected: `${types.patch}_REJECTED`
}

export const patch = {
  [index.pending]: ({ draft, ...rest }) => {
    draft.grocery.campaign.patch.loading = true
    draft.grocery.campaign.patch.error = null
    // draft.grocery.campaign.patch = action.payload.id;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.grocery.campaign.patch.loading = false
    draft.grocery.campaign.patch.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.grocery.campaign.patch.loading = false
    draft.grocery.campaign.patch.error = action.payload
  },
  [types.updateItemLocal]: ({ draft, action }) => {
    const oldItem = draft.campaign.item.data
    const newValues = action.payload
    draft.campaign.item.data = { ...oldItem, ...newValues }
  }
}
