import * as types from '../constants';

const index = {
  pending: `${types.PROMOTIONS_DELETE}_PENDING`,
  fulfilled: `${types.PROMOTIONS_DELETE}_FULFILLED`,
  rejected: `${types.PROMOTIONS_DELETE}_REJECTED`,
};

export const deletePromotion = {
  [index.pending]: ({ draft }) => {
    draft.loadingDelete = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    const { deleted_id } = action.payload;
    const oldPromotions = draft.promotions;

    const filteredPromotions = oldPromotions?.filter(
      item => item.id !== deleted_id,
    );

    draft.loadingDelete = false;
    draft.promotions = filteredPromotions;
    draft.selectecProducts = [];
  },
  [index.rejected]: ({ draft }) => {
    draft.loadingDelete = false;
  },
};
