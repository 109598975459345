import { formatNumberWithK } from '~/helpers'
import i18n from '~/i18n'
import { primary as theme } from '~/styles/themes'

import { formatStylesChart } from '../../helpers'
import { CreateDataset } from '../types'
import { OptionsScale } from './types'

const axisID = 'y-clicks'

const settings = {
  label: i18n.t('rm:metrics.clicks'),
  formatData: (value: number) => formatNumberWithK({ number: value }),
  color: theme.colors.primary,
  dataKey: 'clicksNumber' as keyof ReportHistoryCampaigns,
  order: 10
}

const dataset: CreateDataset = ({ data, selectedMetrics }) => {
  const clicksViewsImpressions: AvailablePresets[] = [
    'clicks',
    'views',
    'impressions'
  ]

  const modifiersKeys = {
    clicksViewsImpressions: clicksViewsImpressions.every(value =>
      selectedMetrics?.includes(value)
    )
  }

  const modifiers = {
    style: modifiersKeys.clicksViewsImpressions ? 'bar' : 'line'
  }

  const type = modifiers.style as MixedChartType

  return {
    yAxisID: axisID,
    type,
    label: settings.label,
    data: data?.[settings.dataKey] || null,
    order: type === 'line' ? settings.order : settings.order + 1,

    ...formatStylesChart({
      color: settings.color,
      bgStyle: type === 'line' ? 'solid' : 'alphaGradient'
    })
  }
}

const optionsScale: OptionsScale = ({
  showScaleRuler = true,
  drawOnChartArea = true,
  position = 'left'
}) => ({
  [axisID]: {
    display: showScaleRuler,
    position,
    grid: {
      // If true, draw lines on the chart area inside the axis lines. This is useful
      // when there are multiple axes and you need to control which grid lines are drawn.
      drawOnChartArea
    },
    beginAtZero: true,
    suggestedMin: 0,
    ticks: {
      z: 5,
      color: settings.color,
      callback: settings.formatData
    }
  }
})

const clicksPreset = { axisID, dataset, optionsScale, settings }

export default clicksPreset
