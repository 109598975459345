import React from 'react';

import { cardIssuer } from '~/helpers';

import { AuthorizationTypes } from './types';

const CardIssuer = ({ payment }) => {
  if (!payment?.card_issuer) return null;

  return <> - {cardIssuer(payment.card_issuer)} </>;
};

CardIssuer.propTypes = AuthorizationTypes;

export default CardIssuer;
