import styled, { css } from 'styled-components'

interface WrapperStatusAndConfirmationProps {
  hasCancelled?: boolean
}

export const WrapperStatusAndConfirmation = styled.div<WrapperStatusAndConfirmationProps>`
  > div button + button {
    margin-left: ${({ theme }) => theme.spacings.xxxsmall};
  }
  > div .selectSearchableWrapper {
    min-width: 15rem;
  }
  > div .edit {
    width: ${props => (props.hasCancelled ? '100%' : 'auto')};
  }
`
export const WrapperButtons = styled.div`
  display: flex;
`
