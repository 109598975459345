import React, { useMemo } from 'react'
import { FiMessageSquare } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { ButtonNavLink } from '~/components/Buttons'
import { formatSearchString } from '~/helpers'
import useFetchSWR from '~/hooks/useFetchSWR'
import { getChatUrl } from '~/modules/chat/services'

const ButtonChat = () => {
  const { orderData } = useSelector(state => state.order)

  const customerId = useMemo(() => orderData.customer.id, [orderData])

  const queryString = useMemo(
    () =>
      formatSearchString({
        customerId
      }),
    [customerId]
  )

  const { data } = useFetchSWR({
    url: getChatUrl({ queryString })
  })

  const chatId = useMemo(() => data?.data?.[0]?.chatId, [data])

  const hasSession = useMemo(
    () =>
      customerId === data?.data?.[0]?.customerId && !!data?.data?.[0]?.session,
    [data, customerId]
  )

  return (
    <>
      {hasSession && (
        <ButtonNavLink
          to={`/chats/${chatId}`}
          text="Falar no chat"
          iconLeft={<FiMessageSquare />}
          size="small"
        />
      )}
    </>
  )
}

export default ButtonChat
