import { produce, Draft } from 'immer'

import { SET_FILTER, FILTERS_SEELOUT } from './constants'

const INITIAL_STATE = Object.keys(FILTERS_SEELOUT).reduce(
  (acc, item) => ({ ...acc, [item]: null as string | null }), // ou o tipo adequado para suas strings
  {} as { [key in FiltersKeysSellout]: string | null }
)

export default function filtersSellout(
  state = INITIAL_STATE,
  action: ActionGeneric<{ type: FiltersKeysSellout; data: string }>
) {
  return produce(state, (draft: Draft<typeof INITIAL_STATE>) => {
    switch (action.type) {
      // Store generic filter {value, label}
      case SET_FILTER: {
        const { type, data } = action.payload

        if (type) {
          draft[type] = data
        }

        break
      }

      default:
    }
  })
}
