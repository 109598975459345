import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'

import SelectSearchable from '~/components/SelectSearchable'
import { brazilianStates } from '~/helpers'
import useFetchSWR from '~/hooks/useFetchSWR'
import { indexLocationMinifiedService } from '~/services/location-service'
import { useAppSelector } from '~/store/hooks'
import { setFilteredLocations } from '~/store/modules/filters/actions'

/**
 * Componente para filtrar a listagem por locais.
 * Ele possibilita a seleção de locais e mantém no estado global: filters.locations.
 * @param { boolean } ignoreRedux pode ser usado sem armazenar no estado global e apenas retornar o valor selecionado.
 *
 * Pode receber um valor inicial no initialLocation e retorna um select procurável.
 *
 * @example <FilterLocations initialLocation="locatoinId" />
 */

const FilterLocations: React.FC<FilterLocationsProps> = ({
  initialLocation,
  onChange,
  placeholder,
  hideAll = false,
  labelAll = 'Todos os locais',
  ignoreRedux = false,
  filterActiveLocations = false,
  filterWithoutLocation = false,
  isClearable = true
}) => {
  const [locations, setLocations] = useState()
  const [loading, setLoading] = useState(true)

  const { locations: filteredLocations } = useAppSelector(
    state => state.filters
  )
  const dispatch = useDispatch()

  // const { data, error } = useFetchSWR({
  //   url: `/api/location/minified`
  // })

  const { data: { data, total } = {}, error } = useQuery({
    queryKey: ['listLocationsMinified'],
    queryFn: async () => {
      const response = await indexLocationMinifiedService()

      return response
    }
  })

  useEffect(() => {
    if (data) {
      const { data: locationsAPI } = data

      const locationsWithState = locationsAPI.reduce((acc, location) => {
        if (!acc[`${location.address_state}`]) {
          acc[`${location.address_state}`] = []
        }

        acc[`${location.address_state}`].push(location)

        return acc
      }, {})

      const formattedLocations = locationsFiltered =>
        locationsFiltered
          .filter(({ active }) => (filterActiveLocations ? active : true))
          .map(item => ({
            value: item.id,
            label: item.name
          }))

      const handleLocations = Object.keys(locationsWithState).map(item => ({
        label: brazilianStates[`${item}`],
        options: formattedLocations(locationsWithState[`${item}`])
      }))

      const customLocations = []

      if (!hideAll) {
        customLocations.push({
          label: labelAll || 'Todos os locais',
          value: null
        })
      }

      if (filterWithoutLocation) {
        customLocations.push({
          label: 'Sem local',
          value: 'without_location'
        })
      }

      setLocations([...customLocations, ...handleLocations])

      setLoading(false)
    }

    if (error) {
      const formattedLocations = [
        {
          value: null,
          label: 'Erro ao carregar locais...'
        }
      ]

      setLocations(formattedLocations)
      setLoading(false)
    }
  }, [
    data,
    error,
    filterActiveLocations,
    hideAll,
    labelAll,
    filterWithoutLocation
  ])

  const defaultValue = useMemo(() => {
    if (initialLocation && data) {
      const foundLocation = data.data.find(
        location => location.id === initialLocation
      )

      const filteredLocations = {
        value: foundLocation.id,
        label: foundLocation.name
      }

      return filteredLocations
    }

    return null
  }, [data, initialLocation])

  useEffect(() => {
    if (!ignoreRedux && defaultValue) {
      dispatch(
        setFilteredLocations({
          filteredLocations: defaultValue
        })
      )
    }
  }, [defaultValue, dispatch, ignoreRedux])

  const handleChangeLocations = selectedLocations => {
    if (onChange) {
      onChange(selectedLocations)
    }

    if (!ignoreRedux) {
      dispatch(setFilteredLocations({ filteredLocations: selectedLocations }))
    }
  }

  const handleDefaultValue = useMemo(
    () => (ignoreRedux ? defaultValue : filteredLocations),
    [defaultValue, filteredLocations, ignoreRedux]
  )

  return (
    <SelectSearchable
      placeholder={placeholder || 'Filtrar por locais'}
      options={locations}
      loading={loading}
      onChange={handleChangeLocations}
      defaultValue={[handleDefaultValue]}
      className="selectWrapper"
      isClearable={isClearable}
    />
  )
}

export default FilterLocations

FilterLocations.propTypes = {
  hideAll: PropTypes.bool,
  labelAll: PropTypes.string,
  ignoreRedux: PropTypes.bool,
  isClearable: PropTypes.bool,
  initialLocation: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  filterActiveLocations: PropTypes.bool,
  filterWithoutLocation: PropTypes.bool
}

FilterLocations.defaultProps = {
  hideAll: false,
  labelAll: 'Todos os locais',
  ignoreRedux: false,
  isClearable: true,
  initialLocation: null,
  onChange: null,
  placeholder: null,
  defaultValue: null,
  filterActiveLocations: false,
  filterWithoutLocation: false
}
