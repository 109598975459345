import styled from 'styled-components'

export const Keybind = styled.div`
  position: absolute;
  right: 0.5rem;

  transition: 0.2s ease-in-out all;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 1.875rem;
  max-height: 70%;
  padding: 0 10px;
  border-radius: ${props => props.theme.border_radius};
  margin-left: 1rem;

  background-color: var(--tertiary);
  color: var(--dark);

  body * > & {
    background-color: var(--white);
  }
`

export const Tooltip = styled.div`
  position: absolute;
  top: 0;
  left: 50%;

  min-width: fit-content;

  transform: translate(-50%, calc(-100%));

  transition: 0.2s ease-in-out;
  transition-property: opacity, transform, box-shadow, max-height;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-height: 0;
  padding: 0.5rem;
  border-radius: ${props => props.theme.border_radius};

  background-color: var(--info);
  font-size: ${props => props.theme.font.sizes.xsmall};
  color: var(--white);

  box-shadow: 0 0 0.5rem -1rem rgb(0 0 0 / 20%);
  opacity: 0;

  &::before {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-color: var(--info) transparent;
    border-width: 0.375rem 0.375rem 0 0.375rem;
    border-style: solid;
    border-bottom-width: 0;

    content: '';
  }
  body *:hover > & {
    max-height: 10rem;
    box-shadow: 0 1.25rem 0.5rem -1rem rgb(0 0 0 / 20%);
    opacity: 1;
    transform: translate(-50%, calc(-100% - 0.5rem));
  }
`
