import React from 'react'

import SelectBar from '../SelectBar'

import { Container } from './styles'

const SidebarActions = () => (
  <Container>
    <SelectBar />
  </Container>
)

export default SidebarActions
