import React, { useRef, useState } from 'react'
import { FiCopy, FiEdit2, FiMoreVertical, FiX } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import useOnClickOutside from '~/hooks/useClickOutside'

import ButtonCancel from './_elements/ButtonCancel'

import { ButtonWrapper, Container } from './styles'

/**
 * scheduled -> agendado
 * sent -> enviado
 * canceled -> cancelado
 * enqueued -> enfileirado
 */

const ListMenuCampaigns = ({ data }) => {
  const [keepsMenuOpen, setKeepsMenuOpen] = useState(false)
  const [showActions, setShowActions] = useState(false)

  const containerRef = useRef(null)

  const handleButtonClick = () => {
    setShowActions(prev => !prev)
  }

  const handleCloseMenu = e => {
    const closestContainer = e.target.closest('.action-button-container')

    if (!closestContainer || closestContainer !== containerRef.current)
      setShowActions(false)
  }

  useOnClickOutside(containerRef, handleCloseMenu, keepsMenuOpen)

  const { _id, status } = data

  return (
    <Container ref={containerRef} className="action-button-container">
      <div>
        <Button
          template={showActions ? 'transparentPrimary' : 'transparent'}
          onClick={handleButtonClick}
        >
          <FiMoreVertical />
        </Button>

        {showActions && (
          <ButtonWrapper>
            {status !== 'sent' && status !== 'canceled' && (
              <NavLink to={`/messaging-campaigns/edit/${_id}`}>
                <FiEdit2 /> <span>Editar</span>
              </NavLink>
            )}

            <NavLink to={`/messaging-campaigns/duplicate/${_id}`}>
              <FiCopy /> <span>Duplicar</span>
            </NavLink>

            {status !== 'canceled' && status !== 'sent' && (
              <ButtonCancel id={_id} keepsMenuOpen={setKeepsMenuOpen} />
            )}
          </ButtonWrapper>
        )}
      </div>
    </Container>
  )
}

ListMenuCampaigns.propTypes = {
  data: PropTypes.shape({
    account_id: PropTypes.string,
    active: PropTypes.bool,
    status: PropTypes.string,
    _id: PropTypes.string
  }).isRequired
}

export default ListMenuCampaigns
