import React from 'react';

import PropTypes from 'prop-types';

export const DefaultElement = ({ attributes, children }) => {
  return <p {...attributes}>{children}</p>;
};

DefaultElement.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired,
};
