import styled, { DefaultTheme, css } from 'styled-components'

import { device } from '~/styles/device'

const containerModifier = {
  opened: theme => css`
    width: var(--aside__width);
    max-width: 33%;
    margin-left: 0.5rem;

    @media ${device.tablet_max} {
      max-width: 95vw;
      margin: 0;
      box-shadow: -16vw 0 0 0 ${theme.modal.overlay};
    }
  `,
  closed: () => css`
    max-width: 0;
  `,
  mobile: (theme: DefaultTheme) => css`
    position: absolute;
    z-index: 9999;
    height: 100vh;
    top: 0;
    right: 0;
    border-radius: ${theme.border.radius.large} 0 0 ${theme.border.radius.large};
  `
}

export const Container = styled.aside`
  ${({ theme, isOpen }) => css`
    position: relative;

    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;

    transition: ease-in-out all 0.3s;

    border-radius: ${theme.border.radius.medium};
    background-color: ${theme.colors.white};

    @media ${device.tablet_max} {
      ${containerModifier.mobile(theme)}
    }

    ${isOpen && containerModifier.opened(theme)}
    ${!isOpen && containerModifier.closed}
  `}
`
