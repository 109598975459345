import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    .file-input-wrapper {
      display: flex;
      margin-bottom: ${theme.spacings.xsmall};

      > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  `}
`;
export const DateWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    margin-top: ${theme.spacings.xsmall};

    div + div {
      margin-left: ${theme.spacings.medium};
    }
  `}
`;

const previewImageModifiers = {
  loading: () => css`
    img {
      animation: fade 3s ease-in-out infinite;
    }
  `,
};

export const PreviewMedia = styled.div`
  margin-right: ${({ theme }) => theme.spacings.xxsmall};
  position: relative;

  ${({ isLoading }) => css`
    @keyframes fade {
      0% {
        opacity: 0.5;
      }
      50% {
        opacity: 0.75;
      }
      100% {
        opacity: 0.5;
      }
    }

    max-width: 200px;

    ${isLoading && previewImageModifiers.loading()}
  `}
`;

export const MessagePreviewWrapper = styled.div`
  margin-top: 1rem;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  padding: 0.25rem;
  background-color: ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  font-size: 0.5rem;
  line-height: 1;
  width: 1.25rem;
  height: 1.25rem;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray};
  }
`;
