import { t } from 'i18next'

import Panel from '~/components/Panel'
import Status from '~/components/Status'

import FormEditDailyCost from './FormEditDailyCost'

import * as S from './styles'

export const DailyCost = ({ data }: ViewCampaignProps) => {
  return (
    <Panel>
      <S.WrapperStatus className="mb-2">
        <S.TitleStrong>{t('rm:DailyCost')}</S.TitleStrong>

        <S.StatusContainer variant="info">
          <Status label={t('rm:dailyCost.status.text')} template="info" />
        </S.StatusContainer>
      </S.WrapperStatus>

      <FormEditDailyCost data={data} />
    </Panel>
  )
}
