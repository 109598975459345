/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';

import { formatDateExibition, isPast } from '~/helpers';
import { status } from '~/helpers/format-status-response';

import ListMenuCampaigns from './ListMenuCampaigns';

import { Name } from './styles';

export const schema = {
  name: {
    render: obj => (
      <div>
        <Name className="item__id">
          <Link
            to={`/messaging-campaigns/view/${obj._id}`}
            title="Ver campanha"
          >
            {obj.name}
          </Link>
        </Name>
        <div className="item__name">
          <Link
            to={`/messaging-campaigns/view/${obj._id}`}
            title="Ver campanha"
          >
            Ver campanha
          </Link>
        </div>
      </div>
    ),
    title: 'Nome',
    className: 'order__details col__infos',
    showTotal: true,
  },
  schedule_date: {
    render: obj => formatDateExibition(obj.schedule_date),
    title: 'Agendamento',
  },
  // enqueued_at: {
  //   render: obj => {
  //     if (isPast(obj.schedule_date)) {
  //       return 'Enviado';
  //     }

  //     if (obj.enqueued_at) {
  //       return 'Enfileirado';
  //     }

  //     return 'Agendado';
  //   },
  //   title: 'Status agendamento',
  // },
  active: {
    render: obj => status[obj.status],
    title: 'Status',
  },
  action: {
    render: obj => <ListMenuCampaigns data={obj} />,
    className: 'col__menu',
  },
};
