export default {
  list: {
    data: [],
    loading: false,
    page: 1,
    perPage: 50,
    total: 0,
    pages: 0,
    error: null
  }
}
