import React, { useCallback } from 'react'
import { FiTrash } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import { Spinner } from '~/components/Spinner'
import useConfirmationModal from '~/hooks/useConfirmationModal'

import useFlyers from '../../hooks/useFlyers'

const DeleteButton = ({ data, keepsMenuOpen, menuItem = false }) => {
  const [ConfirmationhModal, showConfirmationModal, toggleConfirmationModal] =
    useConfirmationModal()

  const { handleDeleteFlyer } = useFlyers()

  const { loading } = useSelector(
    ({ promotionFlyer }) => promotionFlyer.flyers.remove
  )

  const handleToogle = useCallback(() => {
    keepsMenuOpen && keepsMenuOpen(oldState => !oldState)

    toggleConfirmationModal()
  }, [keepsMenuOpen, toggleConfirmationModal])

  const handleSubmit = useCallback(() => {
    const body = {
      data,
      onSuccess: menuItem ? handleToogle : null
    }

    handleDeleteFlyer(body)
  }, [data, menuItem, handleToogle, handleDeleteFlyer])

  return (
    <>
      {menuItem ? (
        <button
          type="button"
          onClick={handleToogle}
          className="danger"
          disabled={loading}
        >
          {loading ? <Spinner /> : <FiTrash />} <span>Excluir</span>
        </button>
      ) : (
        <Button
          text="Excluir"
          type="button"
          template="transparentDanger"
          size="small"
          iconLeft={loading ? <Spinner /> : <FiTrash />}
          customWidth="auto"
          onClick={handleToogle}
        />
      )}

      {/* Modal deletar */}
      <ConfirmationhModal
        title="Deseja excluir o encarte?"
        isOpen={showConfirmationModal}
        onClose={handleToogle}
        onSubmit={handleSubmit}
        confirmationButton="Entendo e desejo excluir este encarte"
      >
        <p>
          Esta ação não poderá ser desfeita e as ações em decorrência desse ato
          não poderão ser desfeitas.
        </p>
        {loading && <Loading status={loading}>Excluindo encarte...</Loading>}
      </ConfirmationhModal>
    </>
  )
}

export default DeleteButton

DeleteButton.propTypes = {
  data: PropTypes.shape({}).isRequired,
  keepsMenuOpen: PropTypes.func,
  menuItem: PropTypes.bool
}

DeleteButton.defaultProps = {
  menuItem: false,
  keepsMenuOpen: null
}
