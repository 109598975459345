import { types } from './constants'

// Checking Account - List
export const setPageListCheckingAccount = (value: number) => ({
  type: types.listSetPage,
  payload: value
})

export const setPerPageListCheckingAccount = (value: number) => ({
  type: types.listPerPage,
  payload: value
})

export const setSortListCheckingAccount = (data: OnSortProps) => ({
  type: types.listSort,
  payload: data
})

export default {
  setPage: setPageListCheckingAccount,
  setPerPage: setPerPageListCheckingAccount,
  setSorting: setSortListCheckingAccount
}
