import { InfiniteData } from '@tanstack/react-query'

type FlattenData = (
  data: InfiniteData<ResponseGetAuditLog, unknown>
) => AuditLogItem[]

// Função para juntar os resultados recebidos pelo react-query que vem separado por pages
export const flattenData: FlattenData = data =>
  data.pages.reduce(
    (acc: AuditLogItem[], item: ResponseGetAuditLog) => [...acc, ...item.data],
    [] as AuditLogItem[]
  )
