import React, { memo } from 'react'

import { Tr, Td } from './styles'

const Row: React.FC<TableRow> = ({
  data,
  schema,
  sideBorder,
  smallerRowPadding,
  onMouseOver
}) => {
  return (
    <>
      {data.map((infoRow, index) => (
        <Tr key={index}>
          {Object.keys(schema).map((key, index) => {
            const currentSchema = schema[key]

            return (
              Object.keys(currentSchema).length && (
                <Td
                  onMouseOver={() => onMouseOver(key)}
                  sideBorder={sideBorder}
                  smallerRowPadding={smallerRowPadding}
                  key={`Td_${index}_${infoRow?.id}`}
                  width={currentSchema.width}
                  className={`${currentSchema.className || ''} ${
                    currentSchema.classNameTD || ''
                  }`}
                >
                  <div className="label-mobile">{currentSchema.title}</div>
                  {currentSchema.render && currentSchema.render(infoRow)}
                </Td>
              )
            )
          })}
        </Tr>
      ))}
    </>
  )
}

export default memo(Row)
