const HTMLtags = {
  '*': 'strong',
  '~': 'strike',
  _: 'em',
  '```': 'code',
};

const formatterMessage = (_, p1, p2) =>
  `<${HTMLtags[p1]}>${p2}</${HTMLtags[p1]}>`;

const formatterLink = match =>
  `<a href="${match}" target="_blank">${match}</a>`;

const regexWhatsAppStyles = /([*~_]|```)([a-zA-Z0-9].+?)\1(?!\w)/g;

const regexURL =
  /(?:(?:https|http):\/\/|\b(?:[a-z\d]+\.))[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?/gim;

export const formattedMessageToHtml = messageBody =>
  messageBody
    ?.replace(regexURL, formatterLink)
    .replace(regexWhatsAppStyles, formatterMessage)
    .replace(/\n/g, '<br />');
