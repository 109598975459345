import { transparentize, shade } from 'polished';
import styled, { css } from 'styled-components';

export const List = styled.ul`
  margin: 0;
  padding: 0;

  border: 0;
`;

export const Item = styled.li`
  ${({ theme, isDeleted }) => css`
    list-style: none;

    padding: 0;

    transition: all 0.3s ease-in-out;

    display: grid;
    grid-template-columns: auto repeat(4, 3cm) 3cm;
    column-gap: 0.5rem;

    ${isDeleted &&
    css`
      > div {
        &:not(.undo_edit) {
          opacity: 0.5;
        }
      }
    `}

    &:not(:first-child) {
      &:hover {
        background-color: var(--light);

        .name {
          color: var(--primary);
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.inative};
    }

    > div {
      display: flex;

      flex: 1;

      align-items: center;
      padding: 0.5rem;

      height: 100%;

      &.grayColumn {
        background: ${transparentize(0.94, shade(0.1, theme.colors.gray))};
      }
    }

    &:first-child {
      > div {
        font-weight: ${theme.font.bold};

        &.grayColumn {
          border-radius: 0.5rem 0.5rem 0 0;
        }
      }
    }

    &:last-child {
      > div {
        &.grayColumn {
          border-radius: 0 0 0.5rem 0.5rem;
        }
      }
    }

    &.columnTitle {
      margin-top: 1rem;

      .title {
        display: flex;
        align-items: center;
        flex-direction: row;
        line-height: 1.1;
        font-weight: ${theme.font.bold};
        grid-column: 1 / -1;

        svg {
          margin-right: 0.25rem;
          color: var(--warning);
        }
      }
    }

    .name {
      align-items: flex-start;
      line-height: 1.25;
      flex-flow: wrap;

      width: 100%;
      flex: 1 0 100%;

      .substitution {
        color: var(--danger);
        font-size: ${theme.font.sizes.xsmall};
        width: 100%;
        flex-basis: 100%;
      }

      .nameProduct {
        display: inline-flex;
        align-items: center;
        margin-right: 0.25rem;

        .icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: var(--tertiary);
          color: var(--primary);
          font-size: ${theme.font.sizes.xsmall};
          width: 1rem;
          height: 1rem;
          border-radius: 0.25rem;
          margin-right: 0.25rem;
          padding: 0.25rem;
        }
      }

      .note {
        display: flex;
        align-items: center;
        flex: 1 0 100%;
        width: 100%;

        .text {
          padding: 0.125rem 0;

          .helpText {
            font-size: 12px;
            color: var(--gray);
          }

          .noteText {
            border-radius: 0 0.25rem 0.25rem;
            padding: 0.125rem 0.25rem;
            background: ${transparentize(0.94, shade(0.1, theme.colors.gray))};
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }

    .productId {
      font-size: ${theme.font.sizes.small};
    }

    .quantity {
      justify-content: center;
      flex-direction: column;
      line-height: 1;
    }

    .original_price {
      .text_price {
        min-width: 4rem;
      }
    }
  `}
`;

export const TotalRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    border-radius: 0 0 0.5rem 0.5rem;

    font-weight: ${theme.font.bold};

    background-color: var(--light);
  `}
`;
