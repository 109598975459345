import React from 'react'
import { FiTrash } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import useConfirmationModal from '~/hooks/useConfirmationModal'

import { useMapDraw } from '../../hooks/useMapDraw'

const DeleteRegion = () => {
  const { editingRegion, handleDeleteRegion } = useMapDraw()

  const { deleteLocationRegionsLoading: deleteLoading } = useSelector(
    state => state.locations
  )

  const [ConfirmationModal, showConfirmationModal, toggleConfirmationModal] =
    useConfirmationModal()

  return (
    <div className="delete">
      <ConfirmationModal
        title="Deseja apagar a região?"
        isOpen={showConfirmationModal}
        onClose={toggleConfirmationModal}
        onSubmit={handleDeleteRegion}
        confirmationValue={editingRegion.name}
        confirmationButton="Entendo e desejo apagar esta região"
      >
        <p>
          Esta ação não poderá ser desfeita e os dados da região não poderão
          mais ser acessados.
        </p>
      </ConfirmationModal>

      <Button
        template="transparentDanger"
        text="Excluir região"
        iconLeft={<FiTrash />}
        textLoading="Excluindo região..."
        disabled={deleteLoading}
        onClick={toggleConfirmationModal}
        customWidth="100%"
        size="small"
      />
    </div>
  )
}

export default DeleteRegion
