import { Scope } from '@unform/core'

import Panel from '~/components/Panel'
import { FormGroup, Input, Label } from '~/components/unform'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { FieldsetAssets } from './FieldsetAssets'
import FieldsetLogo from './FieldsetLogo'

const SponsoredBrand: React.FC<SponsoredBrandFieldsetProps> = ({
  formRef,
  initialData
}) => {
  const { t } = useAPPTranslation()
  return (
    <Panel
      title={t('rm:campaign.create.SponsoredBrand.title')}
      description={t('rm:campaign.create.SponsoredBrand.description')}
    >
      <Scope path="sponsored_brand">
        <FormGroup className="mb-2">
          <Label
            htmlFor="headline"
            text={t('rm:campaign.create.SponsoredBrand.elements.title')}
          />
          <Input name="headline" />
        </FormGroup>

        <FormGroup className="mb-2">
          <Label
            htmlFor="description"
            text={t('rm:campaign.create.SponsoredBrand.elements.description')}
          />
          <Input name="description" />
        </FormGroup>

        <FormGroup className="mb-2">
          <Label
            htmlFor="brand_name"
            text={t('rm:campaign.create.SponsoredBrand.elements.brandName')}
          />
          <Input name="brand_name" />
        </FormGroup>

        <FieldsetLogo formRef={formRef} />

        <FieldsetAssets initialData={initialData} />
      </Scope>
    </Panel>
  )
}

export default SponsoredBrand
