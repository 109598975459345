import * as types from '../constants';

const index = {
  pending: `${types.PROMOTION_METRICS_VIEW}_PENDING`,
  fulfilled: `${types.PROMOTION_METRICS_VIEW}_FULFILLED`,
  rejected: `${types.PROMOTION_METRICS_VIEW}_REJECTED`,
};

export const viewPromotionMetrics = {
  [index.pending]: ({ draft }) => {
    draft.loadingMetrics = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.loadingMetrics = false;
    draft.promotionMetrics = action.payload;
  },
  [index.rejected]: ({ draft }) => {
    draft.loadingMetrics = false;
    draft.errorMetrics = draft?.payload || true;
  },
};
