import { useLocation } from 'react-router-dom'
import Collapse from 'reactstrap/lib/Collapse'

import { Badge } from '../UI/Badge'

type SidebarCategory = RootMenuItem & {
  children: React.ReactNode
  isOpen: boolean
  onClick?(): void
  to: string
}

const SidebarCategory: React.FC<SidebarCategory> = ({
  name,
  icon: Icon,
  isOpen,
  children,
  onClick,
  to,
  withSeparator,
  badgeColor,
  badgeText
}) => {
  const location = useLocation()

  const getSidebarItemClass = (path: string) =>
    location.pathname.indexOf(path) === 0 ||
    (location.pathname === '/' && path === '/dashboard')
      ? 'active'
      : ''

  return (
    <li
      className={`sidebar-item ${getSidebarItemClass(to)} ${
        withSeparator ? 'separator' : ''
      }`}
    >
      <span
        data-toggle="collapse"
        className={`sidebar-link ${!isOpen ? 'collapsed' : ''}`}
        onClick={onClick}
        aria-expanded={isOpen ? 'true' : 'false'}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        <Icon size={12} className="align-middle me-2" />

        <span className="align-middle">{name}</span>

        {badgeText && <Badge template={badgeColor}>{badgeText}</Badge>}
      </span>

      <Collapse isOpen={isOpen}>
        <ul id="item" className="sidebar-dropdown list-unstyled">
          {children.map(child => child)}
        </ul>
      </Collapse>
    </li>
  )
}

export default SidebarCategory
