/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import ExternalDashboard from '~/components/ExternalDashboard';
import Panel from '~/components/Panel';
import WhatsAppMessagePreview from '~/components/WhatsAppMessagePreview';
import { formatDateToString, formattedMessageToHtml, isPast } from '~/helpers';
import { Container } from '~/modules/messagingCampaigns/pages/templates/view/components/TemplateView/styles';

import DestinationsTable from '../DestinationsTable';
import DestinationsTag from '../DestinationsTag';

const CampaignView = ({ templateData, data, loading }) => {
  const { destinations } = data;

  const messagePreviewData = useMemo(() => {
    const templateHasButtons = !!templateData?.messages[0].buttons?.length;
    const templateHasCallbacks = !!data?.callbacks?.length;

    const steps = [
      {
        text: formattedMessageToHtml(templateData?.messages[0].text),
        image: data?.media?.url,
        buttons: templateData?.messages[0].buttons,
      },
    ];

    if (templateHasButtons) {
      steps.push({
        isUser: true,
        text: templateData?.messages[0].buttons[0].text,
      });
    }

    if (templateHasCallbacks) {
      data?.callbacks.forEach(item => {
        if (item?.message) {
          steps.push({
            text: formattedMessageToHtml(item.message || ''),
          });
        }

        if (item?.media) {
          steps.push({
            media: item.media,
          });
        }
      });
    }

    return steps;
  }, [templateData, data]);

  const statusDetails = data => {
    if (isPast(data.schedule_date)) {
      return 'Enviado';
    }

    if (data.enqueued_at) {
      return 'Enfileirado';
    }

    return 'Agendado';
  };

  return (
    <Container>
      <Panel title="Informações gerais">
        {data?.description && (
          <div className="hasBorder">
            <p className="text">{data.description}</p>
          </div>
        )}

        <div className="hasBorder">
          <p className="text">
            <strong>Status do envio:</strong> {statusDetails(data)}
            <br />
            <strong>Agendamento:</strong>{' '}
            {data?.schedule_date
              ? `dia ${formatDateToString(data.schedule_date)}`
              : 'Nenhuma data de agendamento cadastrada.'}
          </p>
        </div>
      </Panel>

      {templateData && (
        <Panel title="Pré-visualização das mensagens">
          <div>
            <p>
              <strong>Template escolhido:</strong>{' '}
              <Link
                to={`/messaging-campaigns/templates/view/${data.template_id}`}
              >
                {templateData.title}
              </Link>
            </p>
          </div>

          <WhatsAppMessagePreview messages={messagePreviewData} />
        </Panel>
      )}

      {/* {destinations && (
        <Panel
          isLoading={loading}
          title="Destinatários"
          headerSideContent={<DestinationsTag total={destinations?.length} />}
        >
          <DestinationsTable data={data} />
        </Panel>
      )} */}

      <ExternalDashboard
        dashboardId={42}
        // eslint-disable-next-line no-underscore-dangle
        customFilters={{ campaign_id: data._id }}
      />
    </Container>
  );
};

CampaignView.defaultProps = {
  loading: null,
  templateData: null,
};

CampaignView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    template_id: PropTypes.string,
    destinations: PropTypes.arrayOf(
      PropTypes.shape({
        customer_number: PropTypes.string,
      }),
    ),
    callbacks: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
      }),
    ),
    schedule_date: PropTypes.string,
    enqueued_at: PropTypes.string,
    media: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool,
  templateData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        buttons: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
          }),
        ),
      }),
    ),
    media: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

export default CampaignView;
