import { formatStringDateToTimezone, unformatMoney } from '~/helpers';

import { cleanSocialId } from '../helpers/cleanSocialId';
import {
  fomratBenefitsCoupon,
  formatLimitsCoupon,
  formatTriggerCoupon,
} from '../helpers/formatCouponData';

const formatCoupon = data => {
  const {
    trigger,
    limits,
    promotionRules,
    location_ids,
    promotion_target,
    offers_ids,
  } = data;

  const formatted = {
    name: data.name,
    descritpion: data.descritpion,
    promotion_type: 'coupon',
    code: data.code,
    location_ids,
    customers: cleanSocialId(data.customers), // { type: [String], required: false }
    start_promotion: data.start_promotion, // { type: Date, required: true }
    end_promotion: data.end_promotion, // { type: Date, required: true }
    ...formatTriggerCoupon({
      trigger,
      promotionRules,
      promotion_target,
      offers_ids,
    }),
    ...fomratBenefitsCoupon(data?.benefits),
    ...formatLimitsCoupon({ limits, promotionRules }),
  };

  return formatted;
};

export const modelPromotionCreate = ({ formData, offerIds: offers_ids }) => {
  const {
    start_date,
    start_hour = '00:00',
    end_date,
    end_hour = '23:59',
    benefits: benefitsRaw,
    trigger: triggerRaw,
    promotion_trigger,
    location_ids: locationsId,
    locationsCheck,
    customers: customersRaw,
    customersCheck,
    promotion_target,
    ...rest
  } = formData;

  const filteredLocations = () =>
    Object.keys(locationsId)
      .filter(item => locationsId[item])
      .map(item => item);

  const location_ids = locationsCheck === 'all' ? [] : filteredLocations();

  const customers = customersCheck === 'all' ? [] : customersRaw;

  const start_promotion = formatStringDateToTimezone(
    `${start_date} ${start_hour}`,
  );
  const end_promotion = formatStringDateToTimezone(`${end_date} ${end_hour}`);

  /**
   * Se for cupom, toma outro rumo.
   */
  if (promotion_trigger === 'coupon') {
    return formatCoupon({
      ...formData,
      location_ids,
      customers,
      start_promotion,
      end_promotion,
      offers_ids,
      promotion_target,
    });
  }

  const trigger = {
    quantity: Number(triggerRaw.quantity.value),
  };

  //

  const benefits = {
    price: benefitsRaw.price
      ? Number(unformatMoney(benefitsRaw.price.value))
      : null,
    discount: benefitsRaw.discount
      ? Number(benefitsRaw.discount.numAsString)
      : null,
    pay: benefitsRaw.pay ? Number(benefitsRaw.pay.value) : null,
  };

  delete rest.duration;

  const body = {
    start_promotion,
    end_promotion,
    benefits,
    trigger,
    offers_ids,
    location_ids,
    ...rest,
  };

  return { ...body };
};
