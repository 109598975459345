import ThumbZoomable from '~/components/ThumbZoomable'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { RightArrow } from './commons'

const Active = ({ prevValue, newValue, entity, note }: DefaultChangeProps) => {
  const { t, tCapitalize } = useAPPTranslation()

  const getText = {
    ad: (value: boolean) => (value ? t('rm:Active') : tCapitalize('rm:paused')),
    campaign: (value: boolean) =>
      value ? t('rm:Active') : tCapitalize('rm:paused', { context: 'female' }),
    campaign_keyword: (value: boolean) =>
      value ? t('rm:Active') : tCapitalize('rm:paused', { context: 'female' }),
    campaign_category: (value: boolean) =>
      value ? t('rm:Active') : tCapitalize('rm:paused', { context: 'female' })
  }

  const prevText = getText[entity](!!prevValue)
  const nextText = getText[entity](!!newValue)

  const parsedNote = Array.isArray(note) ? note?.[0] : note

  const campaignType = parsedNote?.media_url ? 'banner' : 'product'

  return (
    <>
      <span className="prevValue">{prevText}</span>

      <RightArrow />

      <span className="newValue">{nextText}</span>

      {note && entity === 'campaign_keyword' && (
        <p className="notes inline">
          <small className="keyword">{parsedNote?.keyword}</small>
        </p>
      )}

      {note && entity === 'campaign_category' && (
        <p className="notes inline">
          <small className="category">{parsedNote?.name}</small>
        </p>
      )}

      {note && entity === 'ad' && campaignType === 'banner' && (
        <p className="notes inline">
          <ThumbZoomable
            url={parsedNote.media_url}
            title={parsedNote.alt_text}
          />
          <small className="adSize">
            <strong>{t('rm:Format')}:</strong> {parsedNote.ad_size}
          </small>
        </p>
      )}

      {note && entity === 'ad' && campaignType === 'product' && (
        <p className="notes">
          {parsedNote?.product_name && (
            <>
              <small className="productName">
                <strong>{parsedNote.product_name}</strong>
              </small>
              <br />
              <small className="productSKU">
                <strong>SKU:</strong> {parsedNote.sku}
              </small>
            </>
          )}
        </p>
      )}
    </>
  )
}

export default Active
