import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

const containerModifiers = {
  isMasterLoading: () => css`
    animation: pulseFade 2s ease-in-out infinite;
    -webkit-animation: pulseFade 2s ease-in-out infinite;
  `
}

export const Container = styled.ul`
  ${({ theme, isMasterLoading }) => css`
    @keyframes pulseFade {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.75;
      }
      100% {
        opacity: 1;
      }
    }

    ${isMasterLoading && containerModifiers.isMasterLoading()}

    flex: 1;
    overflow-y: auto;

    margin: 0;

    padding: 0 0.5rem;
    max-height: 100%;
    height: 100%;

    list-style: none;

    .empty {
      padding: 0.5rem;
      text-align: center;
      color: ${theme.colors.gray};
    }

    > .loadMoreMsg {
      visibility: hidden;
      max-height: 0;
      overflow: hidden;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.colors.inative};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.tertiary};
      transition: 0.3s ease-in-out all;
      /* Handle on hover */
      &:hover {
        background: ${theme.colors.gray};
      }
    }
  `}
`

export const LoadingPopUp = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    > div {
      margin: 0;
      box-shadow: 0 32px 2.5rem -1rem rgb(87 62 113 / 20%);
      background-color: ${transparentize(0.95, theme.white)};
      backdrop-filter: saturate(180%) blur(5px);
    }
  `}
`
