import { FILTERS_SEELOUT } from '~/store/modules/filtersSellOut/constants'

import { metabaseKeys } from './keysMetabase'

export default {
  metabaseType: metabaseKeys[FILTERS_SEELOUT.subcategories],
  filteredBy: [
    FILTERS_SEELOUT.supermarkets,
    FILTERS_SEELOUT.brands,
    FILTERS_SEELOUT.categories
  ],
  placeholder: 'Filtrar por subcategoria',
  placeholderError: '🔴 Erro ao carregar subcategorias',
  labelMulti: 'subcategorias selecionadas',
  labelAll: 'Todas as subcategorias',
  searching: 'Buscando subcategorias...',
  labelSearchAnything: 'Pesquise alguma subcategoria',
  bigData: true
}
