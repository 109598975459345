import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.gray};
    padding: ${theme.spacings.medium};
    border-radius: ${theme.border.radius.large};
    line-height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .approver-role {
      margin: ${theme.spacings.tiny} 0;
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.stormGray};
      font-weight: ${theme.font.bold};
    }

    .approver-email {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.gray};
    }

    .approver-name {
      font-weight: ${theme.font.bolder};
      line-height: 1rem;
    }

    .approver-email,
    .approver-name {
      margin: 0;
    }

    svg {
      font-size: ${theme.font.sizes.xlarge};
    }

    .trash-icon {
      color: ${theme.colors.danger};
      margin-left: ${theme.spacings.medium};
    }
  `}
`;
