import styled, { css } from 'styled-components';

export const StrongTitle = styled.strong`
  ${({ theme }) => css`
    font-weight: ${theme.font.bolder};
    margin-top: ${theme.spacings.medium};
    display: block;
  `}
`;

export const PaymentWrapper = styled.div`
  .checkbox-goal {
    display: flex;
    white-space: nowrap;

    .inputContainer {
      margin: 0 5px;
      max-width: 60px;
      padding: 4px;
    }
  }
`;

export const ApproversWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    .approver-card-container {
      margin-bottom: ${theme.spacings.xxxsmall};
    }

    .approvers-content {
      width: 100%;
      padding-right: ${theme.spacings.xxlarge};

      &:last-child {
        padding-right: 0;
      }

      & + .approvers-content {
        padding-left: ${theme.spacings.xxlarge};
        border-left: 1px solid ${theme.colors.outlineGray};
      }
    }

    .approver-header {
      h3 {
        font-size: ${theme.font.sizes.xlarge};
        font-weight: ${theme.font.bold};
      }

      p {
        font-size: ${theme.font.sizes.small};
        margin-bottom: ${theme.spacings.xxlarge};
      }
    }

    button {
      width: 100%;
    }
  `}
`;
