import { css } from 'styled-components'

import { ButtonTemplatesProps } from '.'

const lightButtonTemplate = ({ theme }: ButtonTemplatesProps) => css`
  background: var(--light);
  color: var(--dark);

  border: 1px solid var(--gray);

  &:disabled {
    opacity: 1;
    background: ${theme.colors.gray};
    cursor: not-allowed;
  }

  &:not(:disabled):not(.active) {
    &:hover {
      background: var(--white);
      border-color: var(--hover);
      color: var(--primary);
      box-shadow: 0 8px 1rem -0.75rem rgb(91 81 200 / 50%);
    }
  }

  &.active {
    background: var(--light);
    color: var(--dark);
    border: 1px solid var(--dark);

    &:not(:disabled) {
      &:hover {
        background: var(--inative);
        cursor: default;
      }
    }
  }
`

export default lightButtonTemplate
