/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { mutate } from 'swr'

import EmptyPage from '~/components/empty-page'
import Loading from '~/components/Loading'
import Pagination from '~/components/pagination'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import {
  formatSearchString,
  getUserId,
  queryDatesDatepickerIsolated
} from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import useFetchSWR from '~/hooks/useFetchSWR'
import { resetFilters } from '~/store/modules/filters/actions'
import { handleShouldRevalidateList } from '~/store/modules/shoppingCart/actions'

import * as S from '../styles'
import { schema } from './table-schema'

const MyCartsTable = () => {
  const [page, setPage] = useState(1)
  const [data, setData] = useState()

  const [pageApproved, setPageApproved] = useState(1)
  const [dataApproved, setDataApproved] = useState()

  const dispatch = useDispatch()

  const { startDate, endDate } = useSelector(state => state.datepickerHeader)

  const { itemUpdated, shouldRevalidateList } = useSelector(
    ({ shoppingCart }) => shoppingCart
  )

  const { locations: filteredLocations, shoppingCartStatus } = useSelector(
    state => state.filters
  )

  const filteredLocationsIds = useMemo(
    () => filteredLocations?.value || null,
    [filteredLocations]
  )

  const shoppingCartStatusType = useMemo(
    () => shoppingCartStatus?.value || null,
    [shoppingCartStatus]
  )

  /**
   * Gerenciando a Listagem de pedidos
   */
  const quantityPerPage = 100
  const queryString = useMemo(
    () =>
      formatSearchString({
        reviewed_by: getUserId(),
        status: 'opened',
        ...queryDatesDatepickerIsolated(startDate, endDate)
      }),
    [endDate, startDate]
  )

  const queryStringApproved = useMemo(
    () =>
      formatSearchString({
        reviewed_by: getUserId(),
        status: 'approved',
        ...queryDatesDatepickerIsolated(startDate, endDate)
      }),
    [endDate, startDate]
  )

  const { data: dataSWR, error: errorSWR } = useFetchSWR({
    url: `${process.env.REACT_APP_HOST}/api/shopping-cart/minified?${queryString}&page=${page}`,
    refreshInterval: 6000,
    revalidateOnFocus: true
  })

  const { data: dataSWRApproved, error: errorSWRApproved } = useFetchSWR({
    url: `${process.env.REACT_APP_HOST}/api/shopping-cart/minified?${queryStringApproved}&page=${page}`,
    refreshInterval: 6000,
    revalidateOnFocus: true
  })

  const [loading, setLoading] = useState(!dataSWR)
  const [loadingApproved, setLoadingApproved] = useState(!dataSWRApproved)
  const [total, setTotal] = useState()
  const [totalApproved, setTotalApproved] = useState()
  const [errorOnPage, setErrorOnPage] = useState(null)
  const [AlertOnError] = useAlert(errorOnPage)

  useEffect(() => {
    setLoading(true)
    setPage(1)
  }, [queryString])

  useEffect(() => {
    if (dataSWR) {
      setData(dataSWR.data)
      setTotal(dataSWR.total)
      setLoading(false)
    }
  }, [dataSWR])

  useEffect(() => {
    if (dataSWRApproved) {
      setDataApproved(dataSWRApproved.data)
      setTotalApproved(dataSWRApproved.total)
      setLoadingApproved(false)
    }
  }, [dataSWRApproved])

  useEffect(() => {
    if (shouldRevalidateList) {
      mutate(
        `${process.env.REACT_APP_HOST}/api/shopping-cart/minified?${queryString}&page=${page}`
      )

      mutate(
        `${process.env.REACT_APP_HOST}/api/shopping-cart/minified?${queryStringApproved}&page=${page}`
      )

      dispatch(handleShouldRevalidateList(false))
    }
  }, [dispatch, page, queryString, queryStringApproved, shouldRevalidateList])

  // useEffect(() => {
  //   if (itemUpdated) {
  //     const updatedData = dataSWR?.data.map(item =>
  //       item.cart_id === itemUpdated.cart_id
  //         ? { ...item, status: itemUpdated.status }
  //         : item,
  //     );

  //     setData(updatedData);

  //     const updatedDataApproved = dataSWRApproved?.data.map(item =>
  //       item.cart_id === itemUpdated.cart_id
  //         ? { ...item, status: itemUpdated.status }
  //         : item,
  //     );

  //     setDataApproved(updatedDataApproved);
  //   }
  // }, [itemUpdated, dataSWR, dataSWRApproved]);

  useEffect(() => {
    if (errorSWR) {
      setErrorOnPage('Houve um erro com a conexão.')
    }
    if (!errorSWR) {
      setErrorOnPage(null)
    }
  }, [errorSWR])

  useEffect(() => {
    if (errorSWRApproved) {
      setErrorOnPage('Houve um erro com a conexão.')
    }
    if (!errorSWRApproved) {
      setErrorOnPage(null)
    }
  }, [errorSWRApproved])

  /**
   * Gerenciando a paginação
   */

  const handlePagination = page => {
    setLoading(true)
    setPage(page)
  }

  const handlePaginationApproved = page => {
    setLoadingApproved(true)
    setPageApproved(page)
  }

  /**
   * Handle reset filters
   */

  const handleResetFilter = useCallback(
    () => dispatch(resetFilters()),
    [dispatch]
  )

  return (
    <>
      <Loading status={loading || loadingApproved}>
        Carregando lista de carrinhos...
      </Loading>

      <AlertOnError />

      {!!data?.length && (
        <Panel
          noPaddingBody
          isLoading={loading}
          title="🔵 Carrinhos sendo revisados por você"
          headerSideContent={`${total || 0} itens`}
        >
          <Table schema={schema} data={data} total={total} />
        </Panel>
      )}

      {total > 0 && total > quantityPerPage && (
        <S.Footer>
          <Pagination
            count={total}
            page={page}
            limit={quantityPerPage}
            onClickPagination={handlePagination}
            hasFirstLast
            hasPagination
            alignCenter
          />
          <p>
            {total} {total > 1 ? 'itens' : 'item'}
          </p>
        </S.Footer>
      )}

      {/* Approved */}

      {!!dataApproved?.length && (
        <Panel
          noPaddingBody
          isLoading={loadingApproved}
          title="🟢 Carrinhos aprovados por você"
          headerSideContent={`${totalApproved || 0} itens`}
        >
          <Table schema={schema} data={dataApproved} total={totalApproved} />
        </Panel>
      )}

      {totalApproved > 0 && totalApproved > quantityPerPage && (
        <S.Footer>
          <Pagination
            count={totalApproved}
            page={pageApproved}
            limit={quantityPerPage}
            onClickPagination={handlePaginationApproved}
            hasFirstLast
            hasPagination
            alignCenter
          />
          <p>
            {totalApproved} {totalApproved > 1 ? 'itens' : 'item'}
          </p>
        </S.Footer>
      )}
    </>
  )
}

export default MyCartsTable
