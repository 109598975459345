import { Badge } from '~/components/UI/Badge'
import { getRetailMediaAccountType } from '~/helpers'
import i18n from '~/i18n'

import ToogleActiveCampaignCategory from '../../../ToogleActiveCampaignCategory'

const accountType = getRetailMediaAccountType()

const renderSwitch = {
  active: {
    title: '',
    render: (rowData: CampaignCategory) => (
      <ToogleActiveCampaignCategory data={rowData} key={rowData.category_id} />
    ),
    width: 50
  }
}

export const schemaCategories: TableSchema<CampaignCategory> = {
  ...(accountType === 'advertiser' ? { ...renderSwitch } : {}),
  title: {
    title: i18n.t('c:capitalize', {
      value: i18n.t('rm:category')
    }),
    render: rowData => (
      <>
        {accountType === 'publisher' && rowData.disabled_at && (
          <Badge template="warning">
            {i18n.t('c:capitalize', {
              value: i18n.t('rm:paused', { context: 'female' })
            })}
          </Badge>
        )}{' '}
        {rowData.name}
      </>
    ),
    className: 'order__details col__infos col__title',
    showTotal: true
  }
}
