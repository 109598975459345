import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import SelectSearchable from '~/components/SelectSearchable'
import { getTags } from '~/modules/chat/store/actions'
import { setFilteredChatTags } from '~/store/modules/filters/actions'
import { primary as theme } from '~/styles/themes'

import {
  customStyles,
  MultiValue,
  ValueContainer
} from '../FiltersMetabase/customSelect'

/**
 * Componente para filtrar a listagem por locais.
 * Ele possibilita a seleção de locais e mantém no estado global: filters.Tags.
 *
 * Pode receber um valor inicial no initalData e retorna um select procurável.
 *
 * @example <FilterChatTags initalData="locatoinId" />
 */

const FilterChatTags = ({ initalData, onChange }) => {
  const [tags, setTags] = useState()

  const dispatch = useDispatch()
  const { avaiableTags, loadingTags } = useSelector(({ chats }) => chats)
  const { chatTags: filteredChatTags } = useSelector(state => state.filters)

  useEffect(() => {
    if (!avaiableTags) {
      dispatch(getTags())
    }
  }, [avaiableTags, dispatch])

  useEffect(() => {
    if (avaiableTags) {
      const formattedTags = avaiableTags.map(item => ({
        value: item.tagId,
        label: item.label,
        color: item.color
      }))

      setTags([
        {
          label: 'Todas as tags',
          value: 'resetAllTags-systemDefault',
          reset: true
        },
        ...formattedTags
      ])
    }
  }, [avaiableTags])

  useEffect(() => {
    if (initalData === null && !filteredChatTags?.length) {
      dispatch(
        setFilteredChatTags({
          filteredChatTags: null
        })
      )
    }

    if (initalData && avaiableTags) {
      const foundTag = avaiableTags.find(tag => tag.tagId === initalData)

      if (foundTag) {
        const filteredChatTags = {
          value: foundTag.tagId,
          label: foundTag.label
        }

        dispatch(
          setFilteredChatTags({
            filteredChatTags
          })
        )
      }
    }
  }, [dispatch, initalData, avaiableTags, filteredChatTags])

  const handleChangeTags = selectedTags => {
    const shouldReset = selectedTags?.findIndex(item => item?.reset) > -1

    const updatedTags = shouldReset ? null : selectedTags

    if (onChange) {
      return onChange(updatedTags)
    }

    dispatch(
      setFilteredChatTags({
        filteredChatTags: updatedTags
      })
    )
  }

  const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10
    }
  })

  const removeOption = () => ({
    ':after': {
      content: '"x"',
      position: 'absolute',
      top: '50%',
      right: '1rem',
      transform: 'translateY(-50%)',
      fontSize: '.875rem',
      color: theme.colors.gray
    }
  })

  // const customStyles = {
  //   valueContainer: base => ({
  //     ...base,
  //     overflow: 'hidden',
  //     flexWrap: 'nowrap'
  //   }),
  //   singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  //   menuList: styles => ({
  //     ...styles,
  //     display: 'flex',
  //     flexDirection: 'column'
  //   }),
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     const { color } = data
  //     return {
  //       ...styles,
  //       position: 'relative',
  //       backgroundColor: (() => {
  //         if (isFocused) {
  //           return theme.colors.infoLighten50
  //         }

  //         return isSelected
  //           ? theme.colors.secondaryLighten30
  //           : theme.colors.white
  //       })(),
  //       color: isDisabled ? theme.colors.inative : theme.dark,
  //       cursor: isDisabled ? 'not-allowed' : 'default',

  //       ':active': {
  //         ...styles[':active'],
  //         backgroundColor: () => {
  //           if (isFocused) {
  //             return theme.colors.infoLighten50
  //           }
  //           if (!isDisabled) {
  //             return isSelected
  //               ? theme.colors.light
  //               : theme.colors.secondaryLighten30
  //           }
  //         }
  //       },
  //       ...dot(color),
  //       ...(isSelected ? removeOption() : {})
  //     }
  //   }
  // }

  return (
    <SelectSearchable
      placeholder="Filtrar por tags"
      options={tags || []}
      loading={loadingTags}
      onChange={handleChangeTags}
      defaultValue={filteredChatTags}
      className="selectWrapper"
      isMulti
      hideSelectedOptions={false}
      components={{ ValueContainer, MultiValue }}
      styles={customStyles}
    />
  )
}

export default FilterChatTags

FilterChatTags.propTypes = {
  initalData: PropTypes.string,
  onChange: PropTypes.func
}

FilterChatTags.defaultProps = {
  initalData: null,
  onChange: null
}
