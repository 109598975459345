import React, { useCallback, useMemo } from 'react'
import { toastr } from 'react-redux-toastr'

import ToggleInput from '~/components/Toggle'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { changeStatusCategoryService } from '~/modules/retailMedia/services/campaign-category'

const ToogleActiveCampaignCategory = ({ data }: { data: CampaignCategory }) => {
  const { t } = useAPPTranslation()

  const handleChange = useCallback(
    async ({ e, handleLoading }: HandleChange) => {
      const value = e.target.checked

      try {
        await changeStatusCategoryService({
          campaignId: data.campaign_id,
          categoryId: data.category_id,
          active: value
        })

        const status = value ? t('rm:activated') : t('rm:disabled')
        toastr.success(
          t('rm:toast.success'),
          t('rm:category.activatedOrDisabled.success.message', { status })
        )

        handleLoading(value)
      } catch (error) {
        console.error(error)
        toastr.error(
          t('rm:toast.error'),
          t('rm:category.updated.error.message')
        )
        handleLoading(!value)
      }
    },
    [data, t]
  )

  const isChecked = useMemo(() => data.active, [data.active])

  return (
    <ToggleInput
      name={`active_${data?.category_id}`}
      isChecked={isChecked}
      onChange={handleChange}
      disabled={false}
      isAsync
      size="small"
    />
  )
}

export default ToogleActiveCampaignCategory
