import Card from 'reactstrap/lib/Card'

import styled, { DefaultTheme, css } from 'styled-components'

const containerModifiers = {
  default: () => css``,
  borderWarning: (theme: DefaultTheme) => css`
    outline: 1px solid ${theme.colors.warning};
  `
}

export const Container = styled(Card)<ContainerProps>`
  ${({ theme, template }) => css`
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 0.5rem !important;

    .card-header {
      padding: 0.55rem 1rem;
    }

    ${template && containerModifiers?.[template](theme)}
  `}
`
