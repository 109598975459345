import React, { useMemo, useEffect, useState } from 'react'
import { RiInformationFill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'

import AlertDismissibleNew from '~/components/AlertDismissibleNew'
import CardInfo from '~/components/CardInfo'
import Loading from '~/components/Loading'
import Tooltip from '~/components/Tooltip'
import { useAlert } from '~/hooks/useAlert'
import campaignActions from '~/modules/messagingCampaigns/store/campaign/actions'
import ProgressBar from '~/pages/trade/list/_elements/ProgressBar'

import * as S from './styles'

const DashboardCampaign = () => {
  const [showBalanceAlert, setShowBalanceAlert] = useState(true)

  const dispatch = useDispatch()

  const {
    campaign: {
      dashboard: { data, loading, error }
    }
  } = useSelector(state => state.messagingCampaigns)

  const [AlertOnError] = useAlert(error)

  const balanceIsLow = useMemo(() => data?.raw?.balance < 1000, [data])

  const templateProgressBar = useMemo(() => {
    const goal = data?.raw.limit_per_day
    const value = data?.raw.total_dispatch_today

    const percentage = (value * 100) / goal

    if (percentage <= 30.0) {
      return 'success'
    }
    if (percentage >= 30.01 && percentage <= 90) {
      return 'warning'
    }
    if (percentage > 90.01) {
      return 'danger'
    }

    return 'default'
  }, [data])

  /**
   * Handles
   */
  const handleClosedToast = () => setShowBalanceAlert(state => !state)

  useEffect(() => {
    dispatch(campaignActions.dashboard())
  }, [dispatch])

  return (
    <>
      <Loading status={loading}>
        Carregando informações sobre disparos de mensagens
      </Loading>

      <AlertOnError />

      {balanceIsLow && showBalanceAlert && (
        <AlertDismissibleNew
          handleClosedToast={handleClosedToast}
          title="Seu saldo de disparos está acabando..."
          text="Entre em contato com nossa equipe comercial (comercial@newtail.com.br)
        para adicionar mais créditos de disparos."
        />
      )}

      {!!data && (
        <S.WrapperCards>
          {!data.isUnlimited && (
            <CardInfo
              title="Disparos disponíveis hoje"
              textTooltip={`O limite diário de disparos da sua conta é de ${data.daily_limit} mensagens.`}
              icon={<RiInformationFill />}
              featuredText={
                <>
                  {data.todayBalance ? (
                    <span className="todayBalance">
                      <s>{data.today_limit}</s>
                      {data.todayBalance}
                    </span>
                  ) : (
                    <>{data.today_limit}</>
                  )}
                </>
              }
            >
              <S.WrapperProgressBar>
                <ProgressBar
                  goal={data.daily_limit}
                  value={data.today_limit}
                  showTotal
                  template={templateProgressBar}
                  // isDesc
                />
              </S.WrapperProgressBar>
            </CardInfo>
          )}

          <CardInfo
            title="Saldo total"
            textTooltip="Essa é a quantidade de mensagens que você ainda pode disparar."
            icon={<RiInformationFill />}
            featuredText={data.balance}
          >
            <span className="sent-this-month">
              {data.messagesThisMonth} disparos efetuados esse mês
            </span>
          </CardInfo>

          {data.isUnlimited && (
            <CardInfo
              title="Disparos efetuados hoje"
              featuredText={<>{data.daily_usage}</>}
            />
          )}
        </S.WrapperCards>
      )}
    </>
  )
}

export default DashboardCampaign
