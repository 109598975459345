const localstorageConstants = {
  TOKEN: 'token',
  ORIGIN: 'origin',
  APP_INFO: 'newtail:appInfo',
  ACCOUNT_INFO: 'newtail:accountInfo',
  REDUX_PERSIST: 'persist:newtail'
}

export const getLocalstorage = key => localStorage.getItem(key)

export const setLocalstorage = (key, value) => localStorage.setItem(key, value)

export const removeLocalstorage = key => localStorage.removeItem(key)

export default localstorageConstants
