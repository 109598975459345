import React from 'react';

import PropTypes from 'prop-types';

import * as S from '../styles';

const ConfirmationModalInput = ({ confirmationValue, onChange, value }) => {
  return (
    <S.FormGroup>
      <S.Label>
        Por favor, digite <strong>{confirmationValue}</strong> na caixa abaixo
        para continuar.
      </S.Label>
      <S.Input onChange={onChange} value={value} autoFocus />
    </S.FormGroup>
  );
};

ConfirmationModalInput.defaultProps = {
  confirmationValue: '',
  value: '',
};

ConfirmationModalInput.propTypes = {
  confirmationValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};
export default ConfirmationModalInput;
