import ToggleAdverts from '../ToggleAdverts'

import * as S from './styles'

type HandleAdsActiveProps = { data: AdsResultFormatted; isolatedAd: boolean }

const HandleAdsActive: React.FC<HandleAdsActiveProps> = ({
  data,
  isolatedAd = false
}) => {
  return (
    <S.Container>
      <S.InnerContainer>
        <S.WrapperStatusWithSwitch>
          <div className="toggle">
            <ToggleAdverts data={data} isolatedAd={isolatedAd} />
          </div>
        </S.WrapperStatusWithSwitch>
      </S.InnerContainer>
    </S.Container>
  )
}

export default HandleAdsActive
