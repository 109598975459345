import React from 'react'

import { Container } from './styles'

const Status: React.FC<StatusProps> = ({
  onlyBullet,
  label,
  status = {},
  template
}) => {
  const { label: labelStatus = null, type = null } = status

  return (
    <Container type={type} onlyBullet={onlyBullet} template={template}>
      {!onlyBullet && (label || labelStatus)}
    </Container>
  )
}

export default Status
