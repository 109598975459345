import { modelPromotionsList } from '~/modules/promotional/models/list';

import * as types from '../constants';

const index = {
  pending: `${types.PROMOTIONS_LIST}_PENDING`,
  fulfilled: `${types.PROMOTIONS_LIST}_FULFILLED`,
  rejected: `${types.PROMOTIONS_LIST}_REJECTED`,
  itemsPerPage: `${types.PROMOTIONS_LIST_PER_PAGE}`,
};

export const listPromotions = {
  [index.pending]: ({ draft }) => {
    draft.loading = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.loading = false;
    draft.promotions = modelPromotionsList(
      action.payload.data || action.payload,
    );
    draft.total = action.payload.total || action.payload.length || 0;
    draft.pages = action.payload.pages || 1;
  },
  [index.rejected]: ({ draft }) => {
    draft.loading = false;
  },
  [index.itemsPerPage]: ({ draft, action }) => {
    draft.itemsPerPage = action.payload;
  },
};
