export const CHAT_REDUX_QUERY = 'queryChat'
export const CHAT_REDUX_QUERY_ATTENDANT = 'queryAttendant'
export const CHAT_TEMPLATES_REDUX_QUERY = 'queryChatTemplates'
export const CHAT_TAGS_REDUX_QUERY = 'queryChatTags'

export const SET_FULL_SCREEN = '@chats/SET_FULL_SCREEN'

export const GET_AUTH_STATUS = '@chats/GET_AUTH_STATUS'

export const GET_CHAT = '@chats/GET_CHAT'
export const GET_CHATS = '@chats/GET_CHATS'
export const GET_MORE_CHATS = '@chats/GET_MORE_CHATS'
export const SET_LOADING_CHATS = '@chats/SET_LOADING_CHATS'
export const SET_CHATS_ORDER_BY = '@chats/SET_CHATS_ORDER_BY'

export const HANDLE_NEW_MESSAGE = '@chats/HANDLE_NEW_MESSAGE'
export const ADD_NEW_MESSAGE_PREVIEW = '@chats/ADD_NEW_MESSAGE_PREVIEW'
export const ADD_NEW_MESSAGE = '@chats/ADD_NEW_MESSAGE'
export const TOGGLE_AUTO_REFRESH = '@chats/TOGGLE_AUTO_REFRESH'

export const GET_MESSAGES_INITIAL = '@chats/GET_MESSAGES_INITIAL'
export const GET_MESSAGES_MORE = '@chats/GET_MESSAGES_MORE'

export const SET_SELECTED_CHAT = '@chats/SET_SELECTED_CHAT'
export const CLEAR_SELECTED_CHAT = '@chats/CLEAR_SELECTED_CHAT'
export const SET_ID_URL = '@chats/SET_ID_URL'

export const SET_ORDER_OPEN = '@chats/SET_ORDER_OPEN'
export const SET_MESSAGE_STATUS_READ = '@chats/SET_MESSAGE_STATUS_READ'

export const TOGGLE_SELECT_CHATS_MODE = '@chats/TOGGLE_SELECT_CHATS_MODE'
export const TOGGLE_EDIT_LOCATIONS_MODE = '@chats/TOGGLE_EDIT_LOCATIONS_MODE'

export const SET_SELECTED_CHAT_TO_EDIT = '@chats/SET_SELECTED_CHAT_TO_EDIT'

export const SET_CHAT_MENU_OPEN = '@chats/SET_CHAT_MENU_OPEN'
export const CLEAN_SELECTEDS_CHATS = '@chats/CLEAN_SELECTEDS_CHATS'

export const UPDATE_CHAT_CHAT = '@chats/UPDATE_CHAT_CHAT'
export const UPDATE_SESSION = '@chats/UPDATE_SESSION'

export const GET_AVAIABLE_TAGS = '@chats/GET_AVAIABLE_TAGS'
export const CREATE_TAG = '@chats/CREATE_TAG'
export const CREATE_TAG_OPTIMISTIC = '@chats/CREATE_TAG_OPTIMISTIC'
export const UPDATE_TAG = '@chats/UPDATE_TAG'
export const DELETE_TAG = '@chats/DELETE_TAG'
export const DELETE_TAG_OPTIMISTIC = '@chats/DELETE_TAG_OPTIMISTIC'

export const SET_SELECTED_TAG = '@chats/SET_SELECTED_TAG'
export const UPDATE_CHATS_TAGS = '@chats/UPDATE_CHATS_TAGS'
export const UPDATE_CHATS_TAGS_OPTIMISTIC =
  '@chats/UPDATE_CHATS_TAGS_OPTIMISTIC'

export const TOGGLE_BOT_STATUS = '@chats/TOGGLE_BOT_STATUS'
export const TOGGLE_BOT_STATUS_OPTIMISTIC =
  '@chats/TOGGLE_BOT_STATUS_OPTIMISTIC'

export const TOGGLE_PANEL_HISTORY = '@chats/TOGGLE_PANEL_HISTORY'
export const TOGGLE_PANEL_CUSTOMER = '@chats/TOGGLE_PANEL_CUSTOMER'
export const TOGGLE_PANEL_TEMPLATES = '@chats/TOGGLE_PANEL_TEMPLATES'
export const TOGGLE_PANEL_TAGS = '@chats/TOGGLE_PANEL_TAGS'
export const TOGGLE_PANEL_SHOPPING_CART = '@chats/TOGGLE_PANEL_SHOPPING_CART'
export const CLOSE_SIDE_PANELS = '@chats/CLOSE_SIDE_PANELS'

export const SET_SELECTED_TEMPLATE = '@chats/SET_SELECTED_TEMPLATE'
export const RESET_SELECTED_TEMPLATE = '@chats/RESET_SELECTED_TEMPLATE'

export const NEW_FILTER_CHATS_BY_STATUS = '@chats/NEW_FILTER_CHATS_BY_STATUS'
export const SET_NOTIFICATIONS_PLAYED = '@chats/SET_NOTIFICATIONS_PLAYED'

export const START_SERVICE = '@chats/START_SERVICE'
export const FINISH_SERVICE = '@chats/FINISH_SERVICE'
export const SET_NEW_MESSAGE_STATUS_READ = '@chats/SET_NEW_MESSAGE_STATUS_READ'

export const LIST_ATTENDANT = '@chats/LIST_ATTENDANT'
export const AGENT_TRANSFER = '@chats/AGENT_TRANSFER'

export const HISTORY_CHAT = '@chats/HISTORY_CHAT'
