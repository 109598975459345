import * as types from '../constants';

const index = {
  pending: `${types.SEND_LINK}_PENDING`,
  fulfilled: `${types.SEND_LINK}_FULFILLED`,
  rejected: `${types.SEND_LINK}_REJECTED`,
};

export const cases = {
  [index.pending]: ({ draft }) => {
    draft.loadingSendLink = true;
    draft.errorSendLink = null;
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.itemUpdated = action.payload;
    draft.loadingSendLink = false;
    draft.errorSendLink = null;
  },
  [index.rejected]: ({ draft, action }) => {
    draft.loadingSendLink = false;
    draft.errorSendLink = action.payload;
  },
};
