import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

import { darken, lighten } from 'polished'
import { DefaultTheme, ThemeProvider } from 'styled-components'

import GlobalStyles from '~/styles/global'
import { primary as theme, neutral } from '~/styles/themes'

import { useAppInfo } from './useAppInfo'

export interface ThemeContextData {
  /**
   * Função responsável por lidar com os novos dados do tema
   * @param {object} data Objeto recebido pela API
   */
  updateTheme(data: Partial<DefaultTheme>): void
}
export const AppThemeConfigContextDefaultValues = {
  updateTheme: () => null
}

const ThemeUpdateContext = createContext<ThemeContextData>(
  {} as ThemeContextData
)

const AppThemeProvider = ({ children }: PropsWithChildren) => {
  const { colors } = useAppInfo()

  const {
    primary = '#3c3584',
    secondary = '#FFB450',
    header = '#3c3584'
  } = colors || {}

  const per08Formatter = (color: string) =>
    lighten(0.575, color) === '#fff'
      ? lighten(0.55, color)
      : lighten(0.575, color)

  const updatedTheme: DefaultTheme = useMemo(
    () => ({
      ...theme,
      primary,
      secondary,
      colors: {
        ...theme.colors,
        primary,
        hover: `${lighten(0.2, primary || '#354052')}`,
        primaryLighten30: `${lighten(0.3, primary || '#354052')}`,
        primaryLighten45: `${lighten(0.45, primary || '#354052')}`,
        primaryLighten50: `${lighten(0.45, primary || '#354052')}`,
        primaryLighten60: `${lighten(0.6, primary || '#354052')}`,
        primaryLighten61: `${lighten(0.61, primary || '#354052')}`,
        secondary,
        secondaryLighten10: `${lighten(0.1, secondary || '#ffb450')}`,
        secondaryLighten30: `${lighten(0.3, secondary || '#ffb450')}`,
        header
      }
    }),
    [header, primary, secondary]
  )

  const currentTheme = useMemo(
    () => (colors ? updatedTheme : theme),
    [colors, updatedTheme]
  )

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyles />
      {/* <ThemeUpdateContext.Provider > */}
      {children}
      {/* </ThemeUpdateContext.Provider> */}
    </ThemeProvider>
  )
}

function useTheme(): ThemeContextData {
  const context = useContext(ThemeUpdateContext)

  if (!context) {
    throw new Error('useAppThemeConfig must be used within an AppThemeProvider')
  }

  return context
}

export { ThemeUpdateContext, AppThemeProvider as ThemeProvider, useTheme }
