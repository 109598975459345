import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Alert from '~/components/Alert';
import HeaderPages from '~/components/HeaderPages';
import Loading from '~/components/Loading';
import PageTitle from '~/components/PageTitle';
import { listTemplates } from '~/store/modules/chatTemplates/actions';

import CreateTemplate from './elements/Form';
import Template from './elements/Template';
import * as S from './styles';

const Templates = () => {
  const dispatch = useDispatch();
  const { loading, templates } = useSelector(
    ({ chatTemplates }) => chatTemplates,
  );

  useEffect(() => {
    if (!templates) {
      dispatch(listTemplates());
    }
  }, [templates, dispatch]);

  const hasTemplates = useMemo(() => !!templates?.length, [templates]);

  return (
    <>
      {/* Meta <title> */}
      <PageTitle title="Atendimento > Mensagens prontas" />

      <HeaderPages title="Atendimento > Mensagens prontas" />

      <S.Container>
        <S.Sidebar>
          <header>
            <h3>Cadastrar mensagem pronta</h3>
          </header>

          <CreateTemplate />
        </S.Sidebar>

        <S.List>
          <Loading status={loading}>Carregando mensagens prontas...</Loading>

          {hasTemplates &&
            templates.map(template => (
              <Template key={template.templateId} data={template} />
            ))}

          {!loading && !hasTemplates && (
            <Alert active>
              Nenhuma mensagem pronta está cadastrada no momento.
            </Alert>
          )}
        </S.List>
      </S.Container>
    </>
  );
};

export default Templates;
