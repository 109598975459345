import styled, { css } from 'styled-components'

export const Container = styled.section`
  .customerHeader {
    display: flex;
    align-items: center;

    aside {
      margin-left: 0.5rem;
    }
  }
`

export const CartCard = styled.section`
  ${({ theme }) => css`
    position: relative;
    margin: ${theme.spacings.medium} 0;
    width: 100%;

    .text {
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: ${theme.spacings.small};

      line-height: 1.125;

      background-color: ${theme.colors.successShade20};
      border-radius: ${theme.border.radius.medium};

      color: ${theme.colors.light};
      text-align: center;

      .icon__close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        color: ${theme.colors.white};
      }

      a {
        color: ${theme.colors.white};
        font-size: ${theme.font.sizes.large};
        font-weight: ${theme.font.bold};
      }
    }
  `}
`
