import React from 'react'

import Tooltip from '../Tooltip'

import * as S from './styles'

/**
 * **Card de Informações**
 *
 * @param { string }  props.title Titulo do card
 * @param { string } props.textTooltip Texto que o tooltip deve conter
 * @param { array } props.icon Icone para visualizar o tolltipo aberto
 * @param { string } props.featuredText Texto que ficará em destaque no card
 * @param { string }  props.minHeight Titulo do card
 * @param { array } props.children Texto que ficará em destaque no card
 *
 * @example <CardInfo />
 *
 */

const CardInfo = ({
  title,
  textTooltip,
  icon,
  featuredText,
  minHeight,
  children,
  template
}: CardInfoProps) => {
  return (
    <S.Container minHeight={minHeight} template={template}>
      {title && (
        <S.WrapperTitileAndTooltip>
          <div>
            <span className="title">{title}</span>
          </div>
          <div>
            <Tooltip icon={icon} text={textTooltip} />
          </div>
        </S.WrapperTitileAndTooltip>
      )}

      {!!featuredText && <span className="featured-text">{featuredText}</span>}

      {children}
    </S.Container>
  )
}

CardInfo.defaultProps = {
  title: null,
  icon: null,
  textTooltip: null,
  featuredText: null,
  children: null,
  template: 'default'
}

export default CardInfo
