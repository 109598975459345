import { types } from '../constants'

const index = {
  pending: `${types.getPublisher}_PENDING`,
  fulfilled: `${types.getPublisher}_FULFILLED`,
  rejected: `${types.getPublisher}_REJECTED`
}

export const info = {
  [index.pending]: ({ draft }) => {
    draft.industry.publisher.getPublisher.loading = true
    draft.industry.publisher.getPublisher.error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.publisher.getPublisher.data = action.payload

    draft.industry.publisher.getPublisher.loading = false
    draft.industry.publisher.getPublisher.error = null
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.publisher.getPublisher.loading = false
    draft.industry.publisher.getPublisher.error = action.payload
  }
}
