import React, { useMemo, useRef, useEffect, useCallback } from 'react'
import { FiSearch } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { Form } from '@unform/web'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { Input } from '~/components/unform'
import { useAppSelector } from '~/store/hooks'
import { setQuery } from '~/store/modules/filters/actions'

import * as S from './styles'

/**
 *
 * Componente para manipular o campo query do state filter
 *
 *  const { query } = useSelector(state => state.filters);
 *
 * @param {boolean} live Dispara a função setState no onChange
 * @param {string} placeholder Texto para ser exibido no placeholder
 * @example
 *    <FilterSearch placeholder="Buscar Pedido (número, data, cliente, etc)" />
 * @returns React FC
 */

export const FilterSearch = ({
  live = false,
  placeholder = null,
  keyState = 'query.generic',
  showSubmitButton = false,
  searchOnEnter = true,
  isDebounce = false,
  ...rest
}) => {
  const formRef = useRef(null)

  let [mainKey, childKey = null] = keyState.split('.') as [
    mainKey: FiltersKeys,
    childKey: string | null
  ]

  if (!childKey) {
    mainKey = 'query'
    childKey = keyState
  }
  const dispatch = useDispatch()
  const { [mainKey]: query } = useAppSelector(state => state.filters)

  const initialData = useMemo(() => {
    const valueOnState = query?.[`${childKey}`] || null

    if (!query && formRef.current) {
      formRef.current.reset()
    }

    return { query: valueOnState }
  }, [childKey, query])

  useEffect(() => {
    formRef?.current.setFieldValue('query', query?.[`${childKey}`] || null)
  }, [childKey, initialData, query])

  const executeDebounceFunc = func => func()
  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  /**
   * Efeito colateral
   * Limpando o value do input caso estado do redux seja limpo de outra forma
   */
  useEffect(() => {
    const valueQuery = formRef.current?.getFieldValue('query')

    if (valueQuery && !query?.[`${childKey}`]) {
      formRef.current.reset()
    }
  }, [childKey, query])

  const handleChange = useCallback(
    ({ value }: OnChangeInputData) => {
      if (isDebounce) {
        handleDebounce(() => {
          dispatch(setQuery({ keyState, query: { [`${keyState}`]: value } }))
        })
      } else {
        dispatch(setQuery({ keyState, query: { [`${keyState}`]: value } }))
      }
    },
    [dispatch, handleDebounce, isDebounce, keyState]
  )

  const handleSubmit = useCallback(
    ({ query }) => {
      dispatch(setQuery({ keyState, query: { [`${keyState}`]: query } }))
    },
    [dispatch, keyState]
  )

  /**
   * Chamando a função via click para poder ser usado dentro de outros forms
   */
  const handleSubmitByclick = useCallback(() => {
    formRef.current?.submitForm()
  }, [formRef])

  /**
   * Prevenindo busca com enter
   */
  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode === 13) {
        e.preventDefault()

        if (searchOnEnter) formRef.current.submitForm()
      }
    },
    [formRef, searchOnEnter]
  )

  return (
    <S.Container className="searchWrapper">
      <Form onSubmit={handleSubmit} ref={formRef} initialData={initialData}>
        <Input
          icon={FiSearch}
          type="search"
          name="query"
          placeholder={placeholder}
          onChange={live ? handleChange : null}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          style={{ height: '31px' }}
          // Ajustando filtro de busca
          // Se não for no live, ele não funciona direito olhando o value
          // {...(live ? { value: currentValue } : {})}
          {...rest}
          data-testid="inputTest"
        />
        {showSubmitButton && (
          <Button
            type="button"
            data-testid="buttonSubmitTest"
            text="Buscar"
            onClick={handleSubmitByclick}
          />
        )}
      </Form>
    </S.Container>
  )
}

FilterSearch.propTypes = {
  live: PropTypes.bool,
  isDebounce: PropTypes.bool,
  searchOnEnter: PropTypes.bool,
  showSubmitButton: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  keyState: PropTypes.string
}

FilterSearch.defaultProps = {
  live: false,
  isDebounce: false,
  showSubmitButton: false,
  searchOnEnter: true,
  name: 'query',
  placeholder: 'Buscar...'
}
