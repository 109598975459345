import { t } from 'i18next'

import { isReadOnly } from '~/helpers'

import ActionColumn from '../ActionColumn'
import BadgesAdTypes from '../AdTypes'
import SellerId from '../SellerId'

export const advertisersLinkTableSchema: () => TableSchema<AdvertiserPublisherLink> =
  () => ({
    name: {
      title: t('rm:Advertisers'),
      render: rowData => (
        <>
          <strong>{rowData.advertiser_name}</strong>
          <div className="lh-1">
            <small>{rowData?.brands?.join(', ')}</small>
          </div>
        </>
      ),
      showTotal: true
    },
    adTypes: {
      title: t('rm:config.advertisers.list.table.column.title.adtype'),
      render: rowData => <BadgesAdTypes data={rowData} />
    },
    sellerId: {
      title: t('rm:config.advertisers.list.table.column.title.sellerId'),
      render: rowData => <SellerId data={rowData} />
    },
    ...(isReadOnly()
      ? {}
      : {
          action: {
            render: rowData => <ActionColumn data={rowData} />
          }
        })
  })
