import styled, { css } from 'styled-components'

import { device } from '~/styles/device'
/**
 * CSS vars:
 * src/pages/chat/messenger/styles.js
 */

const containerModifier = {
  hasChatSelected: () => css`
    min-width: unset;
    max-width: 0;
    margin-right: 0;
  `,
  opened: () => css`
    min-width: var(--chatlist__width);
    max-width: 33%;
    padding: 0;

    .opened {
      transform: translateX(0);
      width: 100%;
    }

    .closed {
      left: 0;
      transform: translateX(-100%);
      opacity: 0;
    }
  `,
  closed: () => css`
    min-width: 64px;
    max-width: 64px;

    .opened {
      transform: translateX(-100%);
      opacity: 0;
      width: 0;
    }

    .closed {
      left: 50%;
      transform: translateX(-50%);
    }
  `
}

type ContainerProps = {
  isOpen: boolean
  hasChatSelected: boolean
}

export const Container = styled.nav<ContainerProps>`
  ${({ theme, isOpen, hasChatSelected }) => css`
    display: flex;
    flex-direction: column;

    margin-right: 0.5rem;
    position: relative;

    &,
    > div {
      display: flex;
      flex-direction: column;

      height: 100%;
      transition: ease-in-out all 0.3s;
      opacity: 1;
      overflow: hidden;
      width: var(--chatlist__width);
    }

    > .opened {
      width: var(--chatlist__width);
      max-width: calc(100vw - 0.5rem);

      > .loading {
        height: 3px;
        background: ${theme.colors.primaryLighten60};
      }
    }

    > .closed {
      display: flex;
      flex-direction: column;
      align-items: center;

      position: absolute;
      top: 1rem;

      & > * + * {
        margin-top: 1rem;
      }
    }

    border-radius: ${theme.border.radius.medium};
    background-color: ${theme.colors.white};

    ${containerModifier[isOpen ? 'opened' : 'closed']}

    @media ${device.tablet_max} {
      ${hasChatSelected && containerModifier.hasChatSelected()}
    }
  `}
`
