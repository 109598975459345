import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import { verifyRefusal } from '~/pages/orders/actions'

import { AuthorizationTypes } from './types'

const RefusalButton = ({ payment, setRefusal, refusal }) => {
  const dispatch = useDispatch()

  const { loadingVerifyRefusal } = useSelector(state => state.order)

  const handleVerifyRefusal = useCallback(() => {
    dispatch(
      verifyRefusal({
        transactionId: payment?.transaction_id,
        onSuccess: response => setRefusal(response.data?.errors)
      })
    )
  }, [dispatch, payment?.transaction_id, setRefusal])

  const showButtonRefusal = useMemo(
    () => payment?.status === 'payment_refused' && !refusal,
    [payment, refusal]
  )

  return (
    !!showButtonRefusal && (
      <>
        {' '}
        <Button
          template="transparentDanger"
          text="Verficar motivo"
          customWidth="auto"
          size="xsmall"
          onClick={handleVerifyRefusal}
          loading={loadingVerifyRefusal}
        />
      </>
    )
  )
}

RefusalButton.propTypes = AuthorizationTypes

export default RefusalButton
