import React, { useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import AlertInfo from '~/components/AlertInfo'
import EmptyPage from '~/components/empty-page'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { formatSearchString, history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { listOperatorsService } from '~/services/operator-service'
import { resetFilterLocation } from '~/store/modules/filters/actions'

import { OperatorsSchema } from './table-schema'

const OperatorsTable = () => {
  /**
   * Handle reset filters
   */
  const dispatch = useDispatch()

  const handleResetFilter = useCallback(
    () => dispatch(resetFilterLocation()),
    [dispatch]
  )

  /**
   * React query
   */
  const queryParams = useQueryParams()
  const page = Number(queryParams.get('page')) || 1
  const quantity = Number(queryParams.get('quantity')) || 50
  const locationId = useParams().locationId

  const queryString = useMemo(
    () =>
      formatSearchString({
        page: page - 1,
        quantity: 100,
        location_id: locationId
      }),
    [locationId, page]
  )

  const {
    data: { data, total } = {},
    isPending,
    error
  } = useQuery({
    queryKey: ['listOperators', ...queryString],
    queryFn: async () => {
      const { data } = await listOperatorsService(queryString)

      return {
        total: data?.total,
        data: data?.data
      }
    }
  })

  /**
   * Gerenciando a paginação
   */
  const handlePagination = useCallback(
    (value: number) => {
      queryParams.set('page', String(value))
      history.push({ search: queryParams.toString() })
    },
    [queryParams]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      queryParams.set('quantity', String(value.value))
      queryParams.set('page', '1')
      history.push({ search: queryParams.toString() })
    },
    [queryParams]
  )

  return (
    <>
      <Loading status={isPending}>Carregando operadores</Loading>

      {error && (
        <AlertInfo
          text="Hove um erro ao carregar os operadores. Se o problema persistir, entre em contato com o suporte."
          template="danger"
        />
      )}

      {total === 0 && (
        <EmptyPage
          title="Nenhum operador encontrado"
          subtitle="Não encontramos operadores com os filtros aplicados."
          titleButton="Limpar filtros"
          svgPath="photos/orders-empty"
          onClick={handleResetFilter}
        />
      )}

      {data && (
        <Panel noPaddingBody isLoading={isPending}>
          <Table
            schema={OperatorsSchema}
            data={data}
            className="cardOnMobile"
            total={total}
          />
        </Panel>
      )}

      <ListPagination
        total={total}
        label={total > 1 ? 'operadoress' : 'operadores'}
        currentPage={page}
        itemsPerPage={quantity}
        menuPlacement="top"
        onClickPagination={handlePagination}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  )
}

export default OperatorsTable
