import { formatMoney, getRetailMediaAccountType } from '~/helpers'
import i18n from '~/i18n'
import { primary as theme } from '~/styles/themes'

import { abbreviateCurrency, formatStylesChart, maxValue } from '../../helpers'
import { CreateDataset } from '../types'
import { OptionsScale } from './types'

const accountType = getRetailMediaAccountType()
const labelByAccount =
  accountType === 'publisher'
    ? i18n.t('rm:metrics.adsRevenue')
    : i18n.t('rm:metrics.cost')

const axisID = 'y-totalSpent'

const settings = {
  label: labelByAccount,
  formatData: (value: number) => abbreviateCurrency(value),
  formatAdditionalData: (value: number) => formatMoney(value),
  color: theme.colors.danger,
  dataKey: 'totalSpentNumber' as keyof ReportHistoryCampaigns,
  order: 10
}

const dataset: CreateDataset = ({ data }) => ({
  yAxisID: axisID,
  type: 'line',
  label: settings.label,
  data: data?.[settings.dataKey] || null,
  order: settings.order,

  ...formatStylesChart({ color: settings.color })
})

const optionsScale: OptionsScale = ({
  selectedMetrics,
  showScaleRuler = true,
  drawOnChartArea = true,
  position = 'left',
  data
}) => {
  const shouldApplyMax = selectedMetrics?.includes('income')

  return {
    [axisID]: {
      display: showScaleRuler,
      grid: {
        // If true, draw lines on the chart area inside the axis lines. This is useful
        // when there are multiple axes and you need to control which grid lines are drawn.
        drawOnChartArea
      },
      position,
      suggestedMin: 0,
      suggestedMax: shouldApplyMax ? maxValue({ data })?.money : null,
      beginAtZero: true,
      ticks: {
        z: -1,
        color: settings.color,
        callback: settings.formatData
      }
    }
  }
}

const totalSpentPreset = { axisID, dataset, optionsScale, settings }

export default totalSpentPreset
