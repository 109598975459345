import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal_image {
    max-width: 27.75rem;
  }
`
