import * as Yup from 'yup';

export const schemaYup = Yup.object().shape({
  title: Yup.string().required('O título do template é obrigatório'),
  description: Yup.string(),
  messages: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.string()
          .min(5, 'O conteúdo precisa possuir pelo menos 5 caracteres')
          .max(1024, 'O conteúdo não pode exceder 1024 caracteres')
          .required('O texto é obrigatório'),
      }),
    )
    .min(1)
    .required('O texto é obrigatório'),
});
