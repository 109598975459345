import i18next from 'i18next'

export const adTypesBadges: AdTypesLabels = {
  banner: { color: 'success', label: i18next.t(`rm:Banner`) },
  product: { color: 'warning', label: i18next.t(`rm:productAds`) },
  sponsored_brand: {
    color: 'primary',
    label: i18next.t(`rm:sponsoredBrand`)
  },
  digital_signage: {
    color: 'info',
    label: i18next.t(`rm:digitalSignage`)
  }
}
