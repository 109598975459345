import React from 'react';

import * as S from './styles';

const Tag = ({ children, label = '' }) => {
  return (
    <S.Container>
      {!!label && <span className="label">{label}</span>}
      <span className="text">{children}</span>
    </S.Container>
  );
};

export default Tag;
