import Active from './Active'
import Costs from './Costs'
import CreateAd from './CreateAd'
import Dates from './Dates'
import Debug from './Debug'
import GenericContent from './Generic'
import PayloadCreate from './PayloadCreate'

type ContentComponents = {
  [Key in
    | ResponseGetAuditLogFieldNames
    | 'generic'
    | 'debug']?: React.FC<DefaultChangeProps>
}

const contentComponents: ContentComponents = {
  generic: GenericContent,
  active: Active,
  ads: CreateAd,
  end_at: Dates,
  payload_create: PayloadCreate,
  start_at: Dates,
  'settings.daily_budget': Costs,
  'settings.cpc': Costs,
  'settings.cpm': Costs,
  'settings.products': CreateAd,
  debug: Debug
}

export default contentComponents
