import { IconType } from 'react-icons'
import { FiSettings as icon } from 'react-icons/fi'

import Profile from '~/pages/configurations/profile'

import i18n from '~/i18n'

export const config: RootMenuItem<IconType> = {
  id: '0e4c6c23-92ab-4797-b749-01d59267ade2',
  path: '/config',
  name: i18n.t('config:configurations'),
  pageTitle: i18n.t('config:configurations'),
  icon,
  children: [
    {
      id: '72cc211d-142c-4425-afa6-60c3f164100f',
      path: '/config/profile',
      name: `${i18n.t('config:profile')}`,
      pageTitle: i18n.t('config:profile'),
      component: Profile
    }
  ]
}
