import React, { useState, useRef, useCallback, useMemo } from 'react'
import { FiCopy, FiMap, FiPhone, FiUser } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'

import {
  Button,
  ButtonCopyToClipboard,
  ButtonNavLink
} from '~/components/Buttons'
import Loading from '~/components/Loading'
import { Radio } from '~/components/unform'
import useModal from '~/hooks/useModal'
import {
  cancelExternalDelivery,
  getErrorExternalDelivery,
  requestExternalDelivery
} from '~/pages/orders/actions'

import placeholderImage from '../../../assets/img/photos/placeholder-image.jpg'
import { shipmentStatuses } from './constants'
import { externalShipmentTexts } from './texts'
import { vechicleTypes } from './vechicleTypes'

import * as S from './styles'

const vehicleTypeOption = [
  {
    id: 'vehicle_car',
    value: 'car',
    label: vechicleTypes.medium
  },
  {
    id: 'vehicle_motorcycle',
    value: 'motorcycle',
    label: vechicleTypes.small
  }
]

const OrderRequestDelivery = ({
  deliveryData,
  orderId,
  orderExternalId,
  ...rest
}) => {
  const [showForm, setShowForm] = useState(false)

  const formRef = useRef(null)

  const dispatch = useDispatch()

  const {
    loadingRequestExternalDelivery,
    loadingCancelExternalDelivery,
    externalErrorDeliveryData,
    loadingErrorExternalDelivery
  } = useSelector(state => state.order)

  const textErrorDelivery = useMemo(() => {
    const code = !!externalErrorDeliveryData && externalErrorDeliveryData?.code

    return externalShipmentTexts?.[code]
  }, [externalErrorDeliveryData])

  /**
   * Lidando com a requisição de uma corrida
   */

  const handleSubmit = useCallback(
    formData => {
      const { vehicle_type } = formData

      const body = {
        order_id: orderId,
        delivery_provider: 'uber_daas',
        vehicle_type
      }

      dispatch(
        requestExternalDelivery({ body, onSuccess: () => setShowForm(false) })
      )
    },
    [dispatch, orderId]
  )

  /**
   * Lidando com a requisição de uma corrida
   */

  const handleVerifyFailureReason = useCallback(() => {
    dispatch(getErrorExternalDelivery({ order_id: orderExternalId }))
  }, [dispatch, orderExternalId])

  /**
   * Lidando com cancelamento de uma entrega
   */
  const handleCancelDelivery = useCallback(() => {
    const { delivery_id } = deliveryData.shipmentData

    const body = {
      delivery_id
    }

    dispatch(cancelExternalDelivery({ body }))
  }, [dispatch, deliveryData])

  const { shipmentData } = deliveryData
  const {
    provider = null,
    status = null,
    uber_delivery_id = null,
    user_picture = null,
    user_name = null,
    vehicle_type = null,
    phone_number = null,
    courier_imminent = false,
    vehicle,
    type,
    tracking_url
  } = shipmentData || {}

  const servicesLogo = {
    uber_daas: 'https://cdn.newtail.com.br/brands/uber-logo.svg'
  }

  const texts = useMemo(() => {
    let texts

    if (type === 'return' && status !== 'returned') {
      texts = externalShipmentTexts.return || {}
    } else {
      texts = externalShipmentTexts?.[status] || {}
    }

    const {
      featured = null,
      description = null,
      showLoading = false,
      imminent = {}
    } = texts

    if (courier_imminent) {
      return {
        featured: imminent?.featured || featured,
        description: imminent?.description || description,
        showLoading
      }
    }

    return {
      featured,
      description,
      showLoading
    }
  }, [type, status, courier_imminent])

  /**
   * Verificando se podemos solicitar uma entrega
   */
  const allowRequestDelivery = useMemo(() => {
    const allowedstatus = ['open', 'canceled', 'returned', 'failed']

    return allowedstatus.includes(status)
  }, [status])

  /**
   * Verificas se vamos repetir uma solicitação
   */
  const isRepeatRequest = useMemo(() => {
    const allowedstatus = ['canceled', 'returned']

    return allowedstatus.includes(status)
  }, [status])

  /**
   * Verificas se vamos repetir uma solicitação
   */
  const showTrackingButton = useMemo(() => {
    const allowedstatus = ['pickup', 'pickup_complete', 'dropoff']

    return allowedstatus.includes(status)
  }, [status])

  const [userImage, setUserImage] = useState(user_picture)
  const [hasErrorImage, setHasErrorImage] = useState(false)

  const handleErrorImage = () => {
    setUserImage(placeholderImage)
    setHasErrorImage(true)

    return true
  }

  const [Modal, showModal, toggle, setIsShowing] = useModal()

  const handleCloseModal = () => {
    setIsShowing('')
  }

  return (
    <S.Container {...rest}>
      {status !== 'open' && (
        <>
          <Modal
            title={user_name}
            isOpen={!!showModal}
            onClose={handleCloseModal}
          >
            {userImage ? (
              <img
                src={userImage}
                alt="Foto do entregador"
                onError={handleErrorImage}
                className="modal_image"
              />
            ) : (
              <div className="fake">
                <FiUser />
              </div>
            )}
          </Modal>
          <S.Header>
            <div>
              <strong>{texts.featured}</strong>

              <span>{texts.description}</span>
            </div>

            {servicesLogo?.[provider] && (
              <aside className="provider">
                <img
                  src={servicesLogo[provider]}
                  alt="Logo serviço de entrega"
                />
              </aside>
            )}
          </S.Header>

          {uber_delivery_id && (
            <S.Card>
              <S.HeaderCard>
                <span>
                  Delivery ID:{' '}
                  <strong className="uber_delivery_id">
                    {status === 'enqueued'
                      ? 'Atualizando...'
                      : uber_delivery_id}
                  </strong>
                </span>
                <Loading onlyLine status={!!texts?.showLoading} />
              </S.HeaderCard>

              {status !== 'canceled' && (
                <div className="user">
                  <button
                    type="button"
                    onClick={toggle}
                    className="button_open-modal"
                    disabled={hasErrorImage}
                  >
                    <div className="avatar">
                      {!hasErrorImage && userImage && (
                        <img
                          src={userImage}
                          alt="Foto do entregador"
                          onError={handleErrorImage}
                        />
                      )}
                    </div>
                  </button>

                  <div className="userInfo">
                    <strong className="name">
                      {user_name || 'Nenhum usuário definido'}
                    </strong>
                    <div className="vehicle">
                      {/* <span className="type">
                        {vehicle_type
                          ? vechicleTypes?.[vehicle_type] || vehicle_type
                          : 'Nenhum veículo definido'}
                      </span>

                      {vehicle?.vehicle_make && (
                        <span className="make">{vehicle.vehicle_make}</span>
                      )} */}
                      {vehicle?.vehicle_model && (
                        <span className="model">{vehicle.vehicle_model}</span>
                      )}
                      {vehicle?.vehicle_color && (
                        <S.VehicleColor
                          className="color"
                          vehicleColor={vehicle.vehicle_color}
                          title={vehicle.vehicle_color}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}

              {phone_number && (
                <div className="phoneNumber">
                  <ButtonCopyToClipboard
                    template="light"
                    textAfter="Telefone copiado"
                    iconLeft={<FiPhone />}
                    iconRight={<FiCopy />}
                    textBefore={phone_number}
                    toCopy={phone_number}
                    size="xsmall"
                  />
                </div>
              )}
            </S.Card>
          )}

          {!!textErrorDelivery && (
            <S.WrapperError>
              <strong>{textErrorDelivery.featured}</strong>
              <span>{textErrorDelivery.description}</span>
            </S.WrapperError>
          )}

          {status === 'failed' && (
            <Button
              text={'Verificar motivo da falha na solicitação'}
              size="small"
              template="ghost"
              onClick={handleVerifyFailureReason}
              customWidth="100%"
              className="failure__button"
              loading={loadingErrorExternalDelivery}
            />
          )}

          {showTrackingButton && tracking_url && (
            <ButtonNavLink
              as="a"
              href={tracking_url}
              target="_blank"
              rel="noreferrer"
              text="Rastrear entrega"
              customWidth="100%"
              iconLeft={<FiMap />}
            />
          )}

          {status === 'pending' && (
            <footer>
              <Button
                text="Cancelar requisição"
                type="button"
                template="transparentDanger"
                onClick={handleCancelDelivery}
                loading={loadingCancelExternalDelivery}
              />
            </footer>
          )}
        </>
      )}

      {allowRequestDelivery && (
        <>
          {showForm && (
            <Form ref={formRef} onSubmit={handleSubmit}>
              <header>
                <strong>Solicitando entrega</strong>
                <br />
                <span>Qual o veículo adequado para entregar o pedido?</span>
              </header>
              <fieldset>
                <Radio
                  options={vehicleTypeOption}
                  name="vehicle_type"
                  required
                />
              </fieldset>

              <footer>
                <Button
                  text={
                    isRepeatRequest
                      ? 'Solicitar outra entrega'
                      : 'Solicitar entrega'
                  }
                  type="submit"
                  loading={loadingRequestExternalDelivery}
                />
                <Button
                  text="Cancelar"
                  type="button"
                  template="transparentDanger"
                  onClick={() => setShowForm(false)}
                />
              </footer>
            </Form>
          )}

          {!showForm && (
            <footer>
              <Button
                text={
                  isRepeatRequest
                    ? 'Solicitar outra entrega'
                    : 'Solicitar entrega'
                }
                onClick={() => setShowForm(true)}
              />
            </footer>
          )}
        </>
      )}
    </S.Container>
  )
}

export default OrderRequestDelivery

OrderRequestDelivery.defaultProps = {
  styles: null
}

OrderRequestDelivery.propTypes = {
  styles: PropTypes.shape({}),
  orderId: PropTypes.string.isRequired,
  deliveryData: PropTypes.shape({
    hasDeliveryProvider: PropTypes.bool,
    shipmentData: PropTypes.shape({
      status: PropTypes.oneOf(shipmentStatuses),
      provider: PropTypes.string,
      delivery_id: PropTypes.string,
      uber_delivery_id: PropTypes.string,
      user_name: PropTypes.string,
      user_picture: PropTypes.string,
      vehicle_type: PropTypes.string,
      phone_number: PropTypes.string,
      courier_imminent: PropTypes.bool
    }).isRequired
  }).isRequired
}
