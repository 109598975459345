export function reduceSlate(value) {
  const lastIndex = value.length - 1;

  /**
   * Handle text
   */
  const textReducer = (acc, currentValue) => {
    if (currentValue.bold) {
      if (currentValue.text !== '') {
        return `${acc}*${currentValue.text}*`;
      }
      return `${acc}${currentValue.text}`;
    }

    if (currentValue.italic) {
      if (currentValue.text !== '') {
        return `${acc}_${currentValue.text}_`;
      }
      return `${acc}${currentValue.text}`;
    }

    if (currentValue.code) {
      if (currentValue.text !== '') {
        return `${acc}\`\`\`${currentValue.text}\`\`\``;
      }
      return `${acc}${currentValue.text}`;
    }

    if (currentValue.overline) {
      if (currentValue.text) {
        return `${acc}~${currentValue.text}~`;
      }
      return `${acc}${currentValue.text}`;
    }

    return `${acc}${currentValue.text}`;
  };

  /**
   * Handle paragrah
   */
  const paragraphReducer = (acc, currentValue, index) => {
    if (index !== lastIndex) {
      return `${acc}${currentValue.children.reduce(textReducer, '')}\n`;
    }

    return `${acc}${currentValue.children.reduce(textReducer, '')}`;
  };

  return value.reduce(paragraphReducer, '');
}
