import { t } from 'i18next'
import ThumbZoomable from '~/components/ThumbZoomable'

const CreateAd: React.FC<DefaultChangeProps> = ({ newValue, note }) => {
  const campaignType =
    !Array.isArray(newValue) && !!newValue?.settings?.media_url
      ? 'banner'
      : 'product'

  const parsedNote = note ? note?.[0] : null

  return campaignType === 'banner' ? (
    <>
      <ThumbZoomable
        url={newValue.settings.media_url}
        title={newValue.settings.alt_text}
      />
      <small className="adSize">
        <strong>{t('rm:Format')}:</strong> {newValue.settings.ad_size}
      </small>
    </>
  ) : (
    <>
      <p className="notes">
        {parsedNote?.product_name && (
          <small className="productName">
            <strong>{parsedNote.product_name}</strong>
          </small>
        )}
        <br />
        <small className="productSKU">
          <strong>SKU:</strong>{' '}
          {Array.isArray(newValue)
            ? newValue.map(({ settings: { sku } }) => `${sku} `)
            : newValue?.settings?.sku || newValue?.sku}
        </small>
      </p>
    </>
  )
}

export default CreateAd
