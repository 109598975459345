import styled, { css } from 'styled-components'

export const Group = styled.div`
  ${({ theme }) => css`
    & + & {
      margin-top: ${theme.spacings.medium};
    }

    > input {
      width: 100%;
    }

    /* &:last-child:not(:first-child) { */
    &:not(:first-child) {
      padding-top: ${theme.spacings.xsmall};
      border-top: 1px solid ${theme.colors.inative};
    }

    > .radioListWrapper {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      line-height: 1;
    }
  `}
`

export const Children = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 0.5rem;
  width: 100%;
`

export const Child = styled.section`
  ${({ theme }) => css`
    > div {
      height: 100%;
      width: 100%;
      padding: 0 0 0 0.5rem;
      background: ${theme.colors.light};
      border-radius: 0.25rem;

      transition: 0.3s ease-in-out all;

      &:hover {
        background: ${theme.colors.primaryLighten60};
      }

      label {
        margin-bottom: 0;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  `}
`
