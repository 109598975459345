import React, { useState, useMemo } from 'react'
import { FiUser } from 'react-icons/fi'

import PropTypes from 'prop-types'

import Loading from '~/components/Loading'
import { formatDateToString } from '~/helpers'

import placeholderImage from '../../../assets/img/photos/placeholder-image.jpg'
import { shipmentStatuses } from './constants'
import { externalShipmentTexts } from './texts'

import * as S from './styles'

const NewtailDeliveryCard = ({
  deliveryData,

  ...rest
}) => {
  const { shipmentData } = deliveryData
  const {
    status = null,
    user_picture = null,
    user_name = null,
    user_email = null,
    type,
    acceptDeliveryAt,
    acceptDeliveryBy,
    items
  } = shipmentData || {}

  const texts = useMemo(() => {
    let texts = externalShipmentTexts?.[status] || {}

    const { featured = null, description = null, showLoading = false } = texts

    return {
      featured,
      description,
      showLoading
    }
  }, [status])

  const [userImage, setUserImage] = useState(user_picture)
  const [hasErrorImage, setHasErrorImage] = useState(false)

  const handleErrorImage = () => {
    setUserImage(placeholderImage)
    setHasErrorImage(true)

    return true
  }

  return (
    <S.Container {...rest}>
      {status === 'dropoff' && (
        <S.Header>
          <div>
            <strong>{texts.featured}</strong>

            <span>{texts.description}</span>
          </div>
        </S.Header>
      )}

      <S.Card>
        <S.HeaderCard>
          <span>
            <strong className="uber_delivery_id">Newtail Delivery</strong>
          </span>
          <Loading onlyLine status={!!texts?.showLoading} />
        </S.HeaderCard>

        <div className="user">
          <div className="avatar">
            {!hasErrorImage && userImage ? (
              <img
                src={userImage}
                alt="Foto do entregador"
                onError={handleErrorImage}
                className="modal_image"
              />
            ) : (
              <div className="fake">
                <FiUser />
              </div>
            )}
          </div>

          <div className="userInfo">
            <strong className="name">
              {user_name || 'Nenhum usuário definido'}
            </strong>
            <span className="email">
              {user_email || 'Nenhum e-mail definido'}
            </span>
          </div>
          <hr />
        </div>
      </S.Card>

      <footer>
        {acceptDeliveryAt && (
          <S.WrapperInfo>
            <strong>Coletado às </strong>
            <span>{formatDateToString(acceptDeliveryAt)}</span>
          </S.WrapperInfo>
        )}
        {acceptDeliveryBy && (
          <S.WrapperInfo>
            <strong>Recebido por </strong>
            <span>{acceptDeliveryBy}</span>
          </S.WrapperInfo>
        )}
        {items && (
          <S.WrapperInfo>
            <strong>Caixas </strong>
            <span>{items}</span>
          </S.WrapperInfo>
        )}
      </footer>
    </S.Container>
  )
}

export default NewtailDeliveryCard

NewtailDeliveryCard.defaultProps = {
  styles: null
}

NewtailDeliveryCard.propTypes = {
  styles: PropTypes.shape({}),
  orderId: PropTypes.string.isRequired,
  deliveryData: PropTypes.shape({
    hasDeliveryProvider: PropTypes.bool,
    shipmentData: PropTypes.shape({
      status: PropTypes.oneOf(shipmentStatuses),
      provider: PropTypes.string,
      delivery_id: PropTypes.string,
      user_name: PropTypes.string,
      user_picture: PropTypes.string,
      phone_number: PropTypes.string
    }).isRequired
  }).isRequired
}
