import * as types from '../constants';

const index = {
  pending: `${types.FLYERS_CREATE}_PENDING`,
  fulfilled: `${types.FLYERS_CREATE}_FULFILLED`,
  rejected: `${types.FLYERS_CREATE}_REJECTED`,
  clear: `${types.CLEAR_FLYER_STORE}`,
};

export const createFlyer = {
  [index.pending]: ({ draft }) => {
    draft.flyers.create.loading = true;
  },
  [index.fulfilled]: ({ draft }) => {
    draft.flyers.create.loading = false;
  },
  [index.rejected]: ({ draft }) => {
    draft.flyers.create.loading = false;
  },
  [index.clear]: ({ draft }) => {
    draft.flyers.view.data = null;
  },
};
