import { FiArchive as icon } from 'react-icons/fi'

import * as Pages from '~/modules/industry/stock/pages'

export const stock = {
  id: '230f7ba6-3a0e-4736-b892-5ee1e8914085',
  path: '/stock',
  name: 'Estoque',
  pageTitle: 'Relatório de estoque',
  icon,
  contentFullWidth: true,
  component: Pages.Dashboard,
  children: null,
  containsHome: true
}
