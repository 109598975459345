import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/advertiser`
const reportBaseURL = `${baseAPI}/report/v2/advertisers`
export const reportAdvertisersBaseURL = `${baseAPI}/report/v2/advertisers`

/**
 * LIST
 */
// export const list = () => Promise.resolve(listCampaigns)
export const list = (queries = {}) => {
  return axios.get<ResponseListGroceryAdvertisers>(`${reportBaseURL}`, {
    params: { ...queries }
  })
}

/**
 * * PODE REMOVER - Já tem pasta própria /services/advertiser
 */
export const get = ({ id, params = {} }: { id: string; params: object }) =>
  axios.get(`${baseURL}/${id}`, { params })

export default {
  list,
  get
}
