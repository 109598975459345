import React, { useCallback } from 'react'
import { FiTrash } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'
import templateActions from '~/modules/messagingCampaigns/store/template/actions'

const transparentDangerButton = () => {
  const dispatch = useDispatch()

  const { transparentDangerLoading: loading, template } = useSelector(
    state => state.messagingCampaigns
  )

  // eslint-disable-next-line no-underscore-dangle
  const templateId = template?.item?.data?._id

  const onDeleteClick = useCallback(() => {
    dispatch(templateActions.remove(templateId))
  }, [dispatch, templateId])

  return (
    <Button
      text="Excluir"
      type="button"
      template="transparentDanger"
      size="small"
      iconLeft={loading ? <Spinner /> : <FiTrash />}
      customWidth="auto"
      onClick={onDeleteClick}
    />
  )
}

export default transparentDangerButton
