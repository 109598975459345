import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import { history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'

const ActionColumn: React.FC<ActionColumnProps> = ({ data }) => {
  const queryParams = useQueryParams()

  const { advertiser_id: advertiserId } = data

  const handleEdit = () => {
    queryParams.set('rmid', advertiserId)
    history.push({ search: queryParams.toString() })
  }

  return (
    <section>
      <Button
        size="xsmall"
        customWidth="auto"
        template="primaryOutlined"
        text={t('rm:config.advertisers.list.actions.config')}
        iconLeft={<FiEdit />}
        onClick={handleEdit}
      />
    </section>
  )
}

export default ActionColumn
