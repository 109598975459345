import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { hideSidebar } from '~/components/sidebar/sidebar-actions';
import { history } from '~/helpers';

import WrapperSlots from './elements/WrapperSlots';
import { LogisticSlotsProvider } from './useLogisticSlots';

const Slots = () => {
  const { mode } = useParams();

  useLayoutEffect(() => {
    if (!mode) return history.push('/logistic/slots/weekdays/');
  }, [mode]);

  /**
   * Escondando menu ao carregar
   */
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(hideSidebar());
  }, [dispatch]);

  return (
    <LogisticSlotsProvider>{!!mode && <WrapperSlots />}</LogisticSlotsProvider>
  );
};

export default Slots;
