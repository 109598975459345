import { modelPromotionsList } from '~/modules/promotional/models/list';
import { modelPromotionsView } from '~/modules/promotional/models/view';

import * as types from '../constants';

const index = {
  pending: `${types.PROMOTIONS_UPDATE}_PENDING`,
  fulfilled: `${types.PROMOTIONS_UPDATE}_FULFILLED`,
  rejected: `${types.PROMOTIONS_UPDATE}_REJECTED`,
};

export const updatePromotion = {
  [index.pending]: ({ draft }) => {
    draft.loadingSaving = true;
  },
  [index.fulfilled]: ({ draft, action }) => {
    const updatePromotion = action.payload;
    const currentPromotion = draft.promotion;
    const currentPromotions = draft?.promotions;
    const updatePromotions = currentPromotions?.map(item =>
      item.id === updatePromotion.id ? updatePromotion : item,
    );

    draft.loadingSaving = false;

    draft.promotion = currentPromotion
      ? modelPromotionsView(updatePromotion)
      : null;
    draft.promotions = currentPromotions
      ? modelPromotionsList(updatePromotions)
      : null;
  },
  [index.rejected]: ({ draft }) => {
    draft.loadingSaving = false;
  },
};
