/* eslint-disable indent */
/* eslint-disable max-len */
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import Alert from '~/components/Alert';
import { getFileType, handleValidationsErrors } from '~/helpers';
import { default__buttons } from '~/modules/messagingCampaigns/constants/buttons';
import useMsgCampaign from '~/modules/messagingCampaigns/hooks/useMsgCampaigns';
import templateActions from '~/modules/messagingCampaigns/store/template/actions';

import FormTemplate from './FormTemplate';
import { schemaYup } from './yup';

import { Container } from './styles';

const CreateTemplate = () => {
  const formRef = useRef(null);

  const dispatch = useDispatch();

  const { saveTemplateLoading: loading } = useSelector(
    state => state.messagingCampaigns,
  );

  const [loadingUpload, setLoadingUpload] = useState(false);

  const [mediaFile, setMediaFile] = useState();

  const { onlyApprovedTemplates } = useMsgCampaign();

  const handleUpload = async event => {
    setLoadingUpload(true);

    const [file] = event.target.files;

    if (!file) {
      setMediaFile();
      return;
    }

    const dataMultipart = new FormData();

    dataMultipart.append('file', file);

    try {
      const fileType = getFileType(file);

      const { data } = await axios.post(
        `/api/messaging-campaign/upload`,
        dataMultipart,
      );

      setMediaFile({ ...data, name: file.name, type: fileType });
    } catch (err) {
      console.error('Erro', 'Houve um erro ao enviar a imagem de template.');
    } finally {
      setLoadingUpload(false);
    }
  };

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const body = {
          ...data,
          media: mediaFile,
        };

        if (data.buttons === 'with_buttons') {
          body.messages[0].buttons = default__buttons;
        }

        delete body.file;

        dispatch(templateActions.create(body));
      } catch (err) {
        console.error({ err });

        handleValidationsErrors(err, formRef);
      }
    },
    [dispatch, mediaFile],
  );

  return (
    <Container>
      {onlyApprovedTemplates && (
        <Alert active>
          Antes de começar! O seu template precisa ser aprovado pela equipe do
          WhatsApp. Ao criar, ele será salvo com status de em revisão e assim
          que estiver tudo ok, o status será alterado para aprovado.
        </Alert>
      )}

      <FormTemplate
        mediaFile={mediaFile}
        onSubmit={handleSubmit}
        formRef={formRef}
        onUpload={handleUpload}
        loading={loading}
        loadingUpload={loadingUpload}
      />
    </Container>
  );
};

export default CreateTemplate;
