import React from 'react';

import PropTypes from 'prop-types';

import * as S from './styles';

const ButtonMore = ({ slot, lengthHide, action }) => {
  return (
    <S.Container
      isNew={slot?.isNew}
      isRepeatable={slot.isRepeatable}
      template={slot.template}
      isGlobal={slot.isGlobal}
      onClick={action}
      role="button"
    >
      <div className="text">Ver mais (+{lengthHide})</div>
    </S.Container>
  );
};

export default ButtonMore;

ButtonMore.propTypes = {
  slot: PropTypes.shape({
    _id: PropTypes.string,
    startHour: PropTypes.string,
    endHour: PropTypes.string,
    isNew: PropTypes.bool,
    template: PropTypes.string.isRequired,
    isGlobal: PropTypes.bool.isRequired,
    isRepeatable: PropTypes.bool.isRequired,
  }).isRequired,
};
