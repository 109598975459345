import React from 'react'
import { Progress } from 'reactstrap'

const MainProgress = ({ value, title }) => (
  <div className="progress-component">
    <p>{title || 'Carregando...'}</p>
    <Progress striped animated color="info" value={value}>
      {value}%
    </Progress>
  </div>
)

export default MainProgress
