const prefix = `@CHAT_TEMPLATES`;

export const LIST_TEMPLATES = `${prefix}/LIST_TEMPLATES`;

export const SHOW_TEMPLATE = `${prefix}/SHOW_TEMPLATE`;

export const CREATE_TEMPLATES = `${prefix}/CREATE_TEMPLATES`;
export const CREATE_TEMPLATES_OPTIMISTIC = `${prefix}/CREATE_TEMPLATES_OPTIMISTIC`;

export const UPDATE_TEMPLATE = `${prefix}/UPDATE_TEMPLATE`;
export const UPDATE_TEMPLATE_OPTIMISTIC = `${prefix}/UPDATE_TEMPLATE_OPTIMISTIC`;

export const DELETE_TEMPLATE = `${prefix}/DELETE_TEMPLATE`;
export const DELETE_TEMPLATE_OPTIMISTIC = `${prefix}/DELETE_TEMPLATE_OPTIMISTIC`;
