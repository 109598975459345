import { FILTERS_SEELOUT } from '~/store/modules/filtersSellOut/constants'

import { metabaseKeys } from './keysMetabase'

export default {
  metabaseType: metabaseKeys[FILTERS_SEELOUT.brands],
  filteredBy: [FILTERS_SEELOUT.supermarkets],
  placeholder: 'Filtrar por marcas',
  placeholderError: '🔴 Erro ao carregar marcas',
  labelMulti: 'marcas selecionadas',
  labelAll: 'Todas as marcas',
  labelSearchAnything: 'Pesquise alguma marca',
  searching: 'Buscando marcas...',
  bigData: true
}
