import styled, { css } from 'styled-components';

import { Button } from '~/components/Buttons';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: ${theme.spacings.xsmall};
    border: 2px solid ${theme.colors.white};
    margin-bottom: ${theme.spacings.medium};

    border-radius: ${theme.border.radius.large};
    line-height: 1;

    background-color: ${theme.colors.white};

    transition: all 0.3s ease-in-out;

    &:hover {
      border-color: ${theme.colors.primaryLighten30};
    }
  `}
`;

export const Image = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 4rem;
    max-width: 4rem;
    max-height: 4rem;
    margin-right: ${theme.spacings.medium};
    border: 0.25rem solid ${theme.colors.light};

    border-radius: ${theme.border.radius.large};

    background-color: ${theme.colors.light};

    .without-image {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 4rem;
      height: 4rem;

      background-color: ${theme.colors.light};
      color: ${theme.colors.primaryLighten30};
    }

    img {
      mix-blend-mode: multiply;
      max-width: 3.75rem;
      max-height: 3.75rem;
      object-fit: cover;
    }
  `}
`;

export const Infos = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1;

    > div {
      display: flex;

      &.mainInfo {
        align-items: center;
      }
    }

    .secondary {
      > * {
        &:not(:first-child) {
          margin-left: ${theme.spacings.xxxsmall};
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;

      > * {
        &:not(:first-child) {
          margin-top: 2px;
        }
      }
    }
  `}
`;

export const TagWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > * {
      margin-left: ${theme.spacings.xxxsmall};
    }
  `}
`;

const tagModifiers = {
  warning: theme => css`
    background-color: ${theme.colors.warningLighten50};
    color: ${theme.colors.warning};
  `,
};

export const Tag = styled.div`
  ${({ theme, template }) => css`
    border-radius: ${theme.border.radius.medium};
    padding: 0.25rem;

    font-size: ${theme.font.sizes.xxsmall};
    color: ${theme.colors.dark};
    background-color: ${theme.colors.primaryLighten60};

    ${template === 'warning' && tagModifiers.warning(theme)}
  `}
`;

export const Name = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
    display: flex;
    align-items: center;

    ${Tag} {
      font-weight: 400;
      margin-left: ${theme.spacings.xxxsmall};
      text-transform: lowercase;
    }
  `}
`;

export const InfoItem = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;

    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.xsmall};

    transition: all 0.3s ease-in-out;

    &:first-child {
      margin-right: 4px;
    }

    &:hover {
      color: ${theme.colors.primary};
    }

    * {
      font-size: ${theme.font.sizes.xsmall};
    }

    > svg {
      margin-right: ${theme.spacings.tiny};
    }
  `}
`;
