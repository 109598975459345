import { formatDateToString, getDurationInDays } from '~/helpers'

import { getStatus } from '../../helpers/getStatus'

export const modelFlyersView = data => {
  const { start_at: startDate, end_at: endDate } = data

  /**
   * Handle locations
   */

  const hasLocations = data.location_ids?.length

  const locationsCheck = hasLocations ? 'selected' : 'all'

  const formatLocations = locationIds =>
    locationIds.reduce((acc, item) => ({ ...acc, [item]: true }), {})

  const location_ids = hasLocations ? formatLocations(data.location_ids) : []

  return {
    ...data,
    start_date: formatDateToString(startDate, 'dddd - DD/MM/YY [às] HH:mm'),
    end_date: formatDateToString(endDate, 'dddd - DD/MM/YY [às] HH:mm'),
    duration: getDurationInDays({ startDate, endDate }),
    status: getStatus(data),
    location_ids,
    location_ids_raw: data.location_ids,
    locationsCheck,
    media: {
      url: data.media_url,
      type: data.media_type,
      name: data.media_name
    },
    // Esse objeto raw serve para termos o objeto cru que recebemos
    // para mandar nas atualizações simples que não passam pelo model
    raw: data
  }
}
