import styled, { css } from 'styled-components'

export const WrapperThumbProduct = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 3rem;
    max-width: 3rem;
    max-height: 3rem;
    margin-right: ${theme.spacings.xxxsmall};
    border: 0.25rem solid ${theme.colors.light};

    border-radius: ${theme.border.radius.large};

    background-color: ${theme.colors.light};

    .without-image {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 3rem;
      height: 3rem;

      background-color: ${theme.colors.light};
      color: ${theme.colors.primaryLighten30};
    }

    img {
      mix-blend-mode: multiply;
      max-width: 3rem;
      max-height: 3rem;
      object-fit: cover;
    }
  `}
`
