import styled, { css } from 'styled-components'

import { device } from '~/styles/device'

const containerModifiers = {
  full: () => css`
    max-width: 100%;

    @media ${device.tablet_max} {
      padding: 0 !important;
      margin: 0 !important;
    }
  `,
  fullscreen: () => css`
    padding: 0;
    margin: 0;
    max-width: 100%;
  `
}

type ContainerProps = {
  full?: boolean
  fullscreen?: boolean
  hideFullMenu?: boolean
}

export const Container = styled.section<ContainerProps>`
  ${({ full = false, fullscreen = false, hideFullMenu = false }) => css`
    display: flex;
    flex-direction: column;

    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    padding: ${hideFullMenu ? 'initial' : '.5rem'};

    ${full && containerModifiers.full()}

    @media ${device.laptop_max} {
      padding: ${hideFullMenu ? 'initial' : '1rem'};
    }

    @media ${device.mobileL_max} {
      width: 100vw;
      max-width: 100vw;
      padding: ${hideFullMenu ? 'initial' : '1rem 0.25rem'};
    }

    ${fullscreen && containerModifiers.fullscreen()}
  `}
`
