import { RiNewspaperLine as TradeIcon } from 'react-icons/ri';

import { TradeCreate, TradeList } from '~/pages/trade';

export const trade = {
  path: '/trade',
  name: 'Campanhas',
  icon: TradeIcon,
  children: [
    {
      path: '/trade/list/',
      name: 'Listagem de campanhas',
      component: TradeList,
    },
    {
      path: '/trade/create',
      name: 'Criar campanha',
      component: TradeCreate,
    },
    {
      path: '/trade/view/:id',
      name: 'Editar promoção',
      // component: TradeView,
      hideMenu: true,
    },
    {
      path: '/trade/edit/:id',
      name: 'Editar promoção',
      // component: TradeEdit,
      hideMenu: true,
    },
  ],
};
