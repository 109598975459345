import styled, { css } from 'styled-components'

export const Container = styled.div<CointainerProps>`
  ${({ theme, alignCenter, onlyLine }) => css`
    position: relative;
    background-color: #fff;
    min-height: 2px;

    ${!onlyLine &&
    css`
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      box-shadow: 0 0.5rem 1rem rgb(20 40 60 / 3%);
      border-radius: 0.25rem;
      padding: 1.25rem;
    `}

    ${alignCenter &&
    css`
      justify-content: center;
    `}

    > div {
      margin-right: 0.25rem;
    }

    @keyframes loading {
      0% {
        background-size: 0 100%;
        background-position-x: right;
      }
      1% {
        background-size: 0 100%;
        background-position-x: left;
      }
      50% {
        background-size: 100% 100%;
        background-position-x: left;
      }
      51% {
        background-size: 100% 100%;
        background-position-x: right;
      }
    }

    &:after,
    &:before {
      --color: var(--primary);
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      height: 2px;
      width: 100%;

      border-radius: 0 0 0.25rem 0.25rem;

      background-image: linear-gradient(var(--color), var(--color));
      background-repeat: no-repeat;
      background-size: 0 100%;
      background-position-x: right;

      animation: loading 2s ease-in-out infinite;
    }

    &:before {
      --color: var(--secondary);
      animation: loading 3s ease-in-out infinite;
      background-color: ${theme.colors.primaryLighten60};
    }
  `}
`
