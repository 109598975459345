import i from 'i18next'

import { InputHidden, InputNumber, Label } from '~/components/unform'

const FormatQuantityAds = ({ name = 'quantity' }: { name?: string }) => (
  <div className="children">
    <Label
      text={`${i.t('rm:placements.form.quantityAds.label')} (${i.t('common:words.optional')})`}
      helperText={i.t('rm:placements.form.quantityAds.labelHelperText')}
    />
    <InputNumber name={name} />
    <InputHidden name="id" />
  </div>
)

export default FormatQuantityAds
