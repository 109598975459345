/* eslint-disable react/display-name */
import React from 'react'
import { FiTag } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { formatMoney, addLogos } from '~/helpers'
import { paymentMethodsLabels, paymentStatusLabels } from '~/helpers/labels'

import ColumnShipment from './ColumnShipment'
import ColumnStatus from './ColumnStatus'
import ColumnValues from './ColumnValues'

export const schema = {
  origin: {
    render: obj => (
      <div className={`channel__${obj.channel_id}`}>
        {addLogos(obj.channel_id)}
      </div>
    ),
    title: 'Canal',
    className: 'order__channel col__favicon'
  },
  order: {
    render: obj => (
      <div>
        <div className="item__id">
          <Link to={`/order/${obj.id}`} alt="Ver detalhes do pedido">
            #{obj.order_id}
          </Link>
        </div>
        <div className="item__name">
          <Link to={`/order/${obj.id}`} alt="Ver detalhes do pedido">
            Ver detalhes
          </Link>
        </div>
      </div>
    ),
    title: 'Pedido',
    className: 'order__details col__infos',
    showTotal: true
  },
  name: {
    render: obj => (
      <div>
        <div className="item__name">
          {obj.customer?.name || 'Sem nome cadastrado'}
        </div>
      </div>
    ),
    title: 'Nome',
    className: 'order__details col__name'
  },
  schedule_date: {
    render: obj => <ColumnShipment data={obj} />,
    title: 'Entrega',
    className: `col__shipment`
  },
  value: {
    render: obj => <ColumnValues data={obj} />,
    title: 'Valor',
    className: `col__shipment`
  },
  location: {
    render: obj =>
      obj.location && obj.location[0]?.name ? obj.location[0].name : '-',
    title: 'Loja'
  },
  status: {
    render: obj => <ColumnStatus data={obj} />,
    title: 'Status',
    className: `col__status`
  },
  payment: {
    render: obj => {
      const paymentMethod = obj.payments?.[0]?.method
      const paymentMethodLabel =
        paymentMethodsLabels[paymentMethod] || paymentMethod

      return (
        <>
          {paymentMethodLabel && (
            <span className={`paymentMethod ${paymentMethod}`}>
              {paymentMethodLabel}
            </span>
          )}

          <span>{paymentStatusLabels[obj.payments?.[0]?.status]?.short}</span>
        </>
      )
    },
    title: 'Pagamento',
    className: `col__payment`
  }
}
