import * as types from './dashboard-constants';
import {
  formatSearchString,
  queryDatesDatepicker,
} from '../../helpers/format-query-string';
import {
  getCardsData,
  getGmvPerDayData,
  getMarginPerDay,
  getTopProducts,
  getTopCustomers,
} from '../../services/dashboard-service';

export const getCardsInfo = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate),
  });
  dispatch({
    type: types.DASHBOARD_CARDS,
    payload: getCardsData(queryString),
  });
};

export const getGmvPerDayInfo = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate),
  });
  dispatch({
    type: types.DASHBOARD_GMV_PER_DAY,
    payload: getGmvPerDayData(queryString),
  });
};

export const getMarginPerDayInfo = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate),
  });
  dispatch({
    type: types.DASHBOARD_MARGIN_PER_DAY,
    payload: getMarginPerDay(queryString),
  });
};

export const getTopProductsInfo = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate),
  });
  dispatch({
    type: types.DASHBOARD_TOP_PRODUCTS,
    payload: getTopProducts(queryString),
  });
};

export const getTopCustomersInfo = (startDate, endDate) => dispatch => {
  const queryString = formatSearchString({
    ...queryDatesDatepicker(startDate, endDate),
  });
  dispatch({
    type: types.DASHBOARD_TOP_CUSTOMERS,
    payload: getTopCustomers(queryString),
  });
};
