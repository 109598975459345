import { useParams } from 'react-router-dom'

import LoadingPopUp from '~/components/LoadingPopUp'
import useFetchSWR from '~/hooks/useFetchSWR'
import { HeaderWalletBalance } from '~/modules/retailMedia/components/HeaderWalletBalance/HeaderWalletBalance'
import { WalletTableTransaction } from '~/modules/retailMedia/components/WalletTableTransaction'
import HeaderWallet from '~/modules/retailMedia/components/WalltetHeader'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'

const AdvertiserTransactions = () => {
  const { id } = useParams()

  const { data, error, isValidating, mutate } =
    useFetchSWR<GetCheckingAccountWithAccountInfoResponse>({
      url: `${reatailmediaApi}/checking-account/${id}?account_info=true`,
      refreshInterval: 60000,
      revalidateOnFocus: false
    })

  return (
    <>
      <HeaderWallet />

      <HeaderWalletBalance
        balance={data?.balance}
        credit={data?.credit_total}
        debit={data?.debit_total}
      />

      <WalletTableTransaction />

      <LoadingPopUp isActive={isValidating} />
    </>
  )
}

export default AdvertiserTransactions
