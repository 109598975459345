import styled, { css } from 'styled-components'

import bgChat from '~/assets/img/elements/bg_chat.jpeg'

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  height: 100%;
  overflow: hidden;
  background: rgb(236, 234, 231);

  /* Se não tem session pode ignorar o tamanho da submit bar */
  ${({ hasSession }) =>
    !hasSession &&
    css`
      max-height: calc(100% - var(--heightChatHeader));
    `}

  ${({ isFinished }) =>
    isFinished &&
    css`
      max-height: calc(100% - var(--heightChatHeader) - 2rem);
    `}

  position: relative;

  .alerts {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .messages {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    max-height: 100%;
    padding: 1.5rem;

    > * {
      z-index: 1;
    }

    &:after {
      background: url(${bgChat}) fixed;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 1rem);
      height: 100%;
      z-index: 0;
      mix-blend-mode: multiply;
      background-size: 21rem;
      opacity: 0.075;
    }

    .alertWrapper {
      overflow: visible;
      line-height: 1.25rem;
      margin-bottom: 0;
      justify-content: center;
    }

    > .loadMoreMsg {
      visibility: hidden;
      max-height: 0;
      overflow: hidden;
    }

    /* width */
    ::-webkit-scrollbar {
      width: 0.75rem;
      padding: 0.125rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--inative);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--primary);
      border-radius: 0.5rem;
      transition: 0.3s ease-in-out all;

      /* Handle on hover */
      &:hover {
        background: var(--hover);
      }
    }
  }
`

export const DateTag = styled.span`
  align-self: center;
  line-height: 1;
  position: sticky;
  text-align: center;
  z-index: 1;

  ${({ theme: { font, colors, border, spacings } }) => css`
    background-color: ${colors.primaryLighten60};
    border-radius: ${border.radius.large};
    color: ${colors.primary};
    font-size: ${font.sizes.xsmall};
    font-weight: ${font.bold};
    margin-bottom: ${spacings.xxxsmall};
    padding: ${`${spacings.xxxsmall} ${spacings.xsmall}`};
    top: ${`-${spacings.medium}`};
  `}
`

export const MessageGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
`
