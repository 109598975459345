import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.large};
    padding: ${theme.spacings.medium};
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.bolder};
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    margin: 0;
  `}
`;

export const ContainerMethods = styled.div`
  ${({ theme }) => css`
    max-height: 40rem;
    overflow-y: auto;
    position: relative;

    /* width */
    ::-webkit-scrollbar {
      height: 0.5rem;
      border-radius: 0.75rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${theme.colors.tertiary};
      border-radius: 0.5rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.primary};
      border-radius: 0.5rem;
      /* Handle on hover */
      &:hover {
        background: ${theme.colors.hover};
      }
    }
  `}
`;
