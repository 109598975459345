import { toastr } from 'react-redux-toastr'

import { jwtDecode } from 'jwt-decode'

import { JWTPayload, history, setLocalstorage } from '~/helpers'
import localstorageConstants from '~/helpers/localstorage-manipulation'
import { setLoggedIn } from '~/modules/auth/store/login/actions'
import { LOGIN_LOGGED_IN } from '~/modules/auth/store/login/constants'
import {
  chageAccountService,
  getAccountInfoService
} from '~/services/account-service'
import store from '~/store'

import * as types from './constants'

/**
 *
 * @exports getAccountInfo   Busca as informações da account
 *
 */

type SetAccountInfo = (a: GetAccountInfoResponse, b?: string) => unknown

const setAccountInfo: SetAccountInfo =
  (accountInfo, callback_url) => (dispatch: DispatchAction) => {
    setLocalstorage(
      localstorageConstants.ACCOUNT_INFO,
      JSON.stringify(accountInfo)
    )

    dispatch({ type: LOGIN_LOGGED_IN })

    if (callback_url === 'reset-change-account') {
      history.push('/')
      history.go(0)
      return
    }

    if (callback_url) {
      history.push(callback_url)
    } else {
      history.push('/')
    }
  }

export const getAccountInfo = (callback_url: string) => {
  const { dispatch } = store

  return {
    type: types.ACCOUNT_INFO_GET,
    payload: getAccountInfoService(),
    onSuccess: (resp: { data: GetAccountInfoResponse[] }) => {
      const data = resp.data?.[0] || null

      const accountInfo = {
        name: data?.name,
        logo: data?.logo,
        bgLogo: data?.header_color,
        host: data?.host,
        checkoutLink: data?.checkout_link
      } as GetAccountInfoResponse

      dispatch(setAccountInfo(accountInfo, callback_url))
    }
  }
}

export const updateAccountInfo = () => ({
  type: types.ACCOUNT_INFO_GET,
  payload: getAccountInfoService(),
  onSuccess: (resp: { data: GetAccountInfoResponse[] }) => {
    const data = resp.data?.[0] || null

    const accountInfo = {
      name: data?.name,
      logo: data?.logo,
      bgLogo: data?.header_color,
      host: data?.host,
      checkoutLink: data?.checkout_link
    }
    setLocalstorage(
      localstorageConstants.ACCOUNT_INFO,
      JSON.stringify(accountInfo)
    )
  }
})

/**
 * Mover para action de auth
 */
type ChangeAccountAction = {
  onSuccess?: () => void
  onError?: () => void
} & ChangeAccountProps

export const changeAccount = ({
  body,
  onSuccess,
  onError
}: ChangeAccountAction) => {
  const { dispatch } = store

  return {
    type: types.CHANGE_ACCOUNT,
    payload: chageAccountService({ body }),
    onSuccess: (resp: { data: ChangeAccountResponse }) => {
      const decodeToken = jwtDecode(resp.data.newToken) as JWTPayload

      if (!decodeToken.current_account_id) {
        toastr.error('Erro', 'Houve um erro ao mudar de conta')
        throw new Error()
      }

      // dispatch({ type: LOGIN_LOGGED_OUT })

      dispatch({ type: 'RESET_REDUX_STATE' })
      dispatch(setLoggedIn(resp.data.newToken, 'reset-change-account'))

      onSuccess?.()
    },
    onError: () => onError?.()
  }
}
