import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

type ContainerProps = {
  isActive: boolean
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, isActive }) => css`
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    z-index: 9999;

    max-height: ${isActive ? '100vh' : 0};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;

    font-weight: bold;
    text-align: center;
    color: white;


    background-color: ${transparentize(0.1, theme.primary)};
    backdrop-filter: saturate(80%) ;

    transition: .3s ease-in-out all;

    .spinners {
      display: flex;
      padding: 1rem;
    }

    .spinner {
      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      width: 2rem;
      height: 2rem;
      z-index: 2;

      animation: rotate 3s cubic-bezier(0.64, -0.25, 0.13, 1.38) infinite;

      fill: ${theme.colors.warning};

      }
    }

    .spinner_white {
      @keyframes rotate2 {
        100% {
          transform: rotate(-360deg);
        }
      }

      opacity: 0.5;
      width: 2rem;
      height: 2rem;
      z-index: 1;

      animation: rotate2 3s cubic-bezier(0.64, -0.25, 0.13, 1.38) infinite;

      fill: ${theme.colors.white};

    }
  `}
`
