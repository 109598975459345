import { FILTERS_SEELOUT } from '~/store/modules/filtersSellOut/constants'

import { metabaseKeys } from './keysMetabase'

export default {
  metabaseType: metabaseKeys[FILTERS_SEELOUT.stores],
  filteredBy: [FILTERS_SEELOUT.supermarkets],
  placeholder: 'Filtrar por lojas',
  placeholderError: '🔴 Erro ao carregar lojas',
  labelMulti: 'locais selecionados',
  labelAll: 'Todos as lojas',
  searching: 'Buscando lojas...'
}
