import { t } from 'i18next'

import { formatSearchString, history } from '~/helpers'
import {
  putFormUser,
  getUsers,
  resendInviteUserService,
  userChangeRole,
  removeUserService,
  getUserService,
  getUserAccountsService,
  userChangeAccountDefault,
  listUserByAccountService
} from '~/modules/users/services/user-service'

import * as types from './constants'

export const submitFormUser = data => dispatch => {
  dispatch({
    type: types.USERS_SUBMIT_USER,
    payload: putFormUser(data),
    successFeedbackMsg: t('users:userAddedSuccess'),
    onSuccess: () => {
      history.push(`/users/list`)
    }
  })
}

export const getUsersList =
  ({ params }) =>
  dispatch => {
    dispatch({
      type: types.USERS_LIST_USERS,
      payload: listUserByAccountService(params)
    })
  }

export const getUser =
  ({ queryString }) =>
  dispatch => {
    dispatch({
      type: types.USERS_LIST_USERS,
      payload: getUserService({ queryString })
    })
  }

export const resendInvite = data => dispatch => {
  dispatch({
    type: types.USERS_RESEND_INVITE,
    payload: resendInviteUserService(data),
    successFeedbackMsg: t('users:inviteResentSuccess')
  })
}

export const editUserRole = data => dispatch => {
  dispatch({
    type: types.USERS_EDIT,
    payload: userChangeRole(data),
    successFeedbackMsg: t('users:userEditedSuccess'),
    onSuccess: () => {
      dispatch(
        getUsersList({
          queryString: formatSearchString({
            quantity: 50
          })
        })
      )
    }
  })
}

export const removeUser = id => dispatch => {
  dispatch({
    type: types.USERS_REMOVE,
    payload: removeUserService(id),
    successFeedbackMsg: t('users:userRemovedSuccess'),
    onSuccess: () => {
      dispatch(
        getUsersList({
          queryString: formatSearchString({
            quantity: 50
          })
        })
      )
    }
  })
}

/**
 * Action responsável por listar as contas associadas ao usuário
 */

export const getUserAccounts = () => ({
  type: types.USER_ACCOUNTS_GET,
  payload: getUserAccountsService()
})

/**
 * Action responsável por listar as contas associadas ao usuário
 */

export const setDefaultUserAccounts = (data: UserChangeAccountDefault) => ({
  type: types.USER_DEFAULT_ACCOUNT_SET,
  payload: userChangeAccountDefault(data)
})
