import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import PropTypes from 'prop-types';

import { brazilianStates } from '~/helpers';
import useFetchSWR from '~/hooks/useFetchSWR';
import { setFilteredLocations } from '~/store/modules/filters/actions';

import { Dropdown, DropdownIndicator } from './styles';

/**
 * Componente para filtrar a listagem por locais.
 * Ele possibilita a seleção de locais e mantém no estado global: filters.locations.
 *
 * Pode receber um valor inicial no initialLocation e retorna um select procurável.
 *
 * @example <FilterLocations initialLocation="locatoinId" />
 */

const SelectLocations = ({
  initialLocation,
  onChange,
  editLocationLoading,
}) => {
  const [locations, setLocations] = useState();
  const [loading, setLoading] = useState(true);

  const { locations: filteredLocations } = useSelector(state => state.filters);
  const dispatch = useDispatch();

  const { data, error } = useFetchSWR({
    url: `${process.env.REACT_APP_HOST}/api/location`,
  });

  useEffect(() => {
    if (data) {
      const { data: locationsAPI } = data;

      let locationSac = {};

      const locationsWithState = locationsAPI.reduce((acc, location) => {
        if (location.type === 'customer_service_center') {
          locationSac = {
            value: location.id,
            label: location.name,
          };
          return acc;
        }

        if (!acc[`${location.address_state}`]) {
          acc[`${location.address_state}`] = [];
        }

        acc[`${location.address_state}`].push(location);

        return acc;
      }, {});

      const formattedLocations = locationsFiltered =>
        locationsFiltered.map(item => ({
          value: item.id,
          label: item.name,
        }));

      const handleLocations = Object.keys(locationsWithState).map(item => ({
        label: brazilianStates[`${item}`],
        options: formattedLocations(locationsWithState[`${item}`]),
      }));

      setLocations([locationSac, ...handleLocations]);

      setLoading(false);
    }

    if (error) {
      const formattedLocations = [
        {
          value: null,
          label: 'Erro ao carregar locais...',
        },
      ];

      setLocations(formattedLocations);
      setLoading(false);
    }
  }, [data, error]);

  useEffect(() => {
    if (initialLocation === null) {
      dispatch(
        setFilteredLocations({
          filteredLocations: null,
        }),
      );
    }

    if (initialLocation && data) {
      const foundLocation = data.data.find(
        location => location.id === initialLocation,
      );

      if (foundLocation) {
        const filteredLocations = {
          value: foundLocation.id,
          label: foundLocation.name,
        };

        dispatch(
          setFilteredLocations({
            filteredLocations,
          }),
        );
      }
    }
  }, [dispatch, initialLocation, data]);

  const handleChangeLocations = selectedLocations => {
    if (onChange) {
      return onChange(selectedLocations);
    }

    dispatch(setFilteredLocations({ filteredLocations: selectedLocations }));
  };

  const selectStyles = {
    container: provided => ({
      ...provided,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }),
    control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
    menu: () => ({
      boxShadow: 'none',
      margin: 8,
      marginTop: 0,
      height: 'calc(100% - 1rem - 38px)',
    }),
    menuList: provided => ({ ...provided, height: '100%', maxHeight: '100%' }),
  };

  return locations ? (
    <Dropdown className="dropdown__menu">
      <Select
        placeholder="Buscar local..."
        options={locations}
        loading={loading || editLocationLoading}
        onChange={handleChangeLocations}
        defaultValue={[filteredLocations]}
        autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        styles={selectStyles}
        tabSelectsValue={false}
      />
    </Dropdown>
  ) : (
    <></>
  );
};

export default SelectLocations;

SelectLocations.propTypes = {
  initialLocation: PropTypes.string,
  onChange: PropTypes.func,
  editLocationLoading: PropTypes.bool,
};

SelectLocations.defaultProps = {
  initialLocation: null,
  onChange: null,
  editLocationLoading: false,
};
