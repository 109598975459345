import styled, { css } from 'styled-components'

/**
 * Main Container
 */
export const Container = styled.section`
  form {
    .formGroup {
      &:not(:first-child) {
        margin-top: 1rem;
      }

      .radioListWrapper {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      }
    }

    textarea {
      min-height: 4rem;
    }

    .LoadingContainer {
      margin-bottom: 0;
    }
  }
`

/**
 * Sucess container
 */
export const SuccessContainer = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    header {
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 4rem;
        width: 4rem;

        margin-bottom: 0.5rem;
        font-size: 2rem;

        border-radius: 50%;

        text-align: center;
        background-color: ${theme.colors.success};
        color: ${theme.colors.white};
      }

      .description {
        line-height: 1.125;
        font-size: ${theme.font.sizes.small};
        color: ${theme.colors.neutral.low.light};
      }
    }

    footer {
      color: ${theme.colors.danger};
    }
  `}
`

/**
 * Card
 * Exportar para componente
 */

export const AmountCard = styled.section`
  ${({ theme }) => css`
    display: flex;

    > span {
      display: flex;
      align-items: center;

      &.icon {
        margin-right: 0.5rem;

        color: ${theme.colors.primary};
      }
    }
  `}
`
