import React, { useCallback, useMemo, useState } from 'react'
import { FiX } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from '~/components/Buttons'
import { cleanSelectedsChats } from '~/modules/chat/store/actions'

import TagsPanel from '../TagsPanel'

import { Container } from './styles'

const SelectBar = () => {
  const [openTagsPanel, setOpenTagsPanel] = useState(false)

  const dispatch = useDispatch()
  const { chatsSelectedToEdit, selectChatsMode } = useSelector(
    ({ chats }) => chats
  )

  const lengthSelected = useMemo(
    () =>
      chatsSelectedToEdit.length > 1
        ? `${chatsSelectedToEdit.length} selecionadas`
        : `${chatsSelectedToEdit.length} selecionada`,
    [chatsSelectedToEdit]
  )

  const handleCancelSelectChats = useCallback(() => {
    setOpenTagsPanel(false)

    dispatch(cleanSelectedsChats())
  }, [dispatch])

  const handleOpenSelectTags = () => setOpenTagsPanel(true)

  return selectChatsMode ? (
    <Container isActive={selectChatsMode} isOpen={openTagsPanel}>
      <header className="wrapper">
        <Button
          template="icon"
          iconLeft={<FiX />}
          onClick={handleCancelSelectChats}
          className="closeButton"
        />{' '}
        <span className="label">{lengthSelected}</span>
        {!openTagsPanel && (
          <Button
            template="primary"
            className="tagsButton"
            size="xsmall"
            onClick={handleOpenSelectTags}
            text="Escolher tags"
          />
        )}
      </header>

      <TagsPanel closePanel={setOpenTagsPanel} />
    </Container>
  ) : (
    <></>
  )
}

export default SelectBar
