import { produce } from 'immer';

import campaign from './campaign';
import template from './template';

const INITIAL_STATE = {
  campaign: campaign.initialState,
  template: template.initialState,
};

const reducerCases = { ...campaign.reducerCases, ...template.reducerCases };

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const reducer = Object.keys(reducerCases).filter(
      item => item === action.type,
    );

    if (reducer?.length) {
      reducerCases[reducer]({ state, draft, action });
    }
  });
