import React, { useMemo } from 'react'

import { getAccountId } from '~/helpers'

import Audio from './Audio'
import FilePDF from './FilePDF'
import Image from './Image'
import StatusTime from './StatusTime'
import Text from './Text'
import Video from './Video'

import { Container } from './styles'

const fileTypes = ['application', 'document']

const Message = ({
  data,
  lastUsername = null,
  lastIsSent,
  lastMessage
}: PropsMessage) => {
  const {
    userName = null,
    image,
    messageBody,
    createdAt,
    status,
    audio,
    video,
    fromStore,
    lastMessageAt,
    media = null
  } = data

  const isSent = useMemo(() => !!fromStore, [fromStore])

  const isGrouped = useMemo(
    () => lastUsername === userName && lastIsSent === fromStore,
    [fromStore, lastIsSent, lastUsername, userName]
  )

  const hideMessage = useMemo(
    () =>
      getAccountId() === 'be56d620-019c-11eb-883d-8980b7e89d71' &&
      lastMessage === 'Agora eu preciso do seu CPF. 🪪',
    [lastMessage]
  )

  const videoUrl = useMemo(
    () => (media?.type === 'video' ? media?.url : video),
    [media, video]
  )

  const isFile = useMemo(() => fileTypes.includes(media?.type), [media?.type])

  const safeMessage = message => {
    if (getAccountId() !== 'be56d620-019c-11eb-883d-8980b7e89d71') {
      return message
    }
    // Regex para CPF (formato: 000.000.000-00)
    const regexCPF = /\d{3}\.\d{3}\.\d{3}-\d{2}/g
    // Regex para CNPJ (formato: 00.000.000/0000-00)
    const regexCNPJ = /\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/g

    // Substitui o CPF ou CNPJ por '***.***.***-**' ou '**.***.***/****-**'
    const formatMessage = message
      .replace(regexCPF, '***.***.***-**')
      .replace(regexCNPJ, '**.***.***/****-**')

    return formatMessage
  }

  return (
    <Container
      isSent={isSent}
      isGrouped={isGrouped}
      hasImage={!!image}
      hasAudio={!!audio}
      hasVideo={!!video}
      hasFile={!!isFile}
    >
      <div className="wrap">
        <div className="message">
          {isFile && <FilePDF data={media} />}

          {image && !isFile && <Image data={image} />}

          {!!videoUrl && <Video data={videoUrl} />}

          {messageBody && !hideMessage && (
            <Text data={safeMessage(messageBody)} />
          )}
          {hideMessage && <Text data="Dado sensível ocultado" />}

          {audio && <Audio data={audio} />}

          <StatusTime
            date={createdAt || lastMessageAt}
            isSent={isSent}
            status={status}
          />
        </div>
      </div>

      {isSent && !isGrouped && (
        <div className="senderName">{userName || (isSent && 'Bot')}</div>
      )}
    </Container>
  )
}

export default Message
