/* eslint-disable indent */
import { produce } from 'immer';

import * as types from './constants';

const INITIAL_STATE = {
  // Indicador global de error
  error: null,
  // Indicador se o WhatsApp está conectado
  isConnected: false,
  // Indicador de loading para a requisição de status de conexão
  loadingAuthStatus: false,
  // Indicador se o provider usa a versão web
  isWhatsAppWeb: false,

  provider: null,
  loadingProvider: false,
};

export default function chatConnect(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      /**
       * CONNECT
       */

      // Set connection status
      case types.SET_CONNECTION_STATUS:
        draft.isConnected = true;
        break;

      // Get connection status
      case `${types.GET_AUTH_STATUS}_PENDING`:
        draft.loadingAuthStatus = true;
        break;

      case `${types.GET_AUTH_STATUS}_FULFILLED`: {
        if (action.payload.status === 'CONNECTED') {
          draft.isConnected = true;
        }

        if (action.payload.status === 'NOT_CONNECTED') {
          draft.isConnected = false;
        }

        draft.loadingAuthStatus = false;

        break;
      }

      case `${types.GET_AUTH_STATUS}_REJECTED`:
        draft.loadingAuthStatus = false;
        draft.error = action.payload;
        break;

      /**
       * PROVIDER
       */

      // Get connection status
      case `${types.GET_CHAT_PROVIDER}_PENDING`:
        draft.loadingProvider = true;
        break;

      case `${types.GET_CHAT_PROVIDER}_FULFILLED`: {
        const [config] = action.payload;
        const provider = config?.provider_name;
        const isWhatsAppWeb = config?.is_whatsapp_web;

        draft.provider = provider || null;
        draft.loadingProvider = false;
        draft.isWhatsAppWeb = isWhatsAppWeb;

        break;
      }

      case `${types.GET_CHAT_PROVIDER}_REJECTED`:
        draft.loadingProvider = false;
        draft.error = action.payload;
        break;

      default:
    }
  });
}
