/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { useLogisticSlots } from '../../useLogisticSlots';
import SlotConfig from '../SlotConfig';

import * as S from './styles';

const SlotList = ({ slots, dayIsSelected, dayId, dayWithoutWork }) => {
  const { formOpen, newSlot, templateBoard, isWithoutWorkDates } =
    useLogisticSlots();

  const compareStartHour = (a, b) => {
    const startHour_a = Number(`${a.start_hour}${a.start_minute}`);
    const startHour_b = Number(`${b.start_hour}${b.start_minute}`);

    switch (true) {
      case startHour_a < startHour_b:
        return -1;

      case startHour_a > startHour_b:
        return 1;

      default:
        return 0;
    }
  };

  const list = useMemo(() => {
    if (newSlot?.start_hour && dayIsSelected) {
      const timeListWithNewSlot = [...slots, newSlot];

      return timeListWithNewSlot.sort(compareStartHour);
    }

    return slots && !isWithoutWorkDates
      ? [...slots].sort(compareStartHour)
      : null;
  }, [slots, newSlot, dayIsSelected, isWithoutWorkDates]);

  return (
    <>
      <S.Container
        template={templateBoard}
        editMode={formOpen}
        parentSelected={dayIsSelected}
      >
        {list?.map(slots => (
          <SlotConfig
            key={slots._id}
            data={slots}
            dayId={dayId}
            template={templateBoard}
          />
        ))}

        {dayWithoutWork && (
          <S.Empty>Esse dia não fará entregas neste local ou região.</S.Empty>
        )}

        {!!slots && !list?.length && (
          <S.Empty>Nenhum slot foi criado para este dia.</S.Empty>
        )}
      </S.Container>
    </>
  );
};

export default SlotList;

SlotList.propTypes = {
  slots: PropTypes.arrayOf(PropTypes.shape({})),
  dayId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  dayIsSelected: PropTypes.bool.isRequired,
  dayWithoutWork: PropTypes.bool.isRequired,
};

SlotList.defaultProps = {
  slots: [],
};
