import { formatDateExibition } from '~/helpers'

export const FormatDataBarChartComposed = ({
  data,
  dateKey,
  labelKey,
  countKey
}) => {
  // Pega todas as datas do que vem da api e faz ordenação crescente
  const dates = data
    .reduce((acc, el) => {
      if (acc.indexOf(el[dateKey]) < 0) {
        acc.push(el[dateKey])
      }
      return acc
    }, [])
    .sort()

  // Reordena o que veio da api para juntas os dados da mesma label
  const dataReordered = data.reduce((acc, el) => {
    if (Object.keys(acc).indexOf(el[labelKey]) < 0) {
      acc[el[labelKey]] = { dates: {} }
    }

    acc[el[labelKey]].dates[el[dateKey]] = el[countKey]

    return acc
  }, {})

  // Passa por todas as chaves (labels) e verifica se falta o valor de alguma data específica. Se faltar, completa com zero
  const finalData = Object.keys(dataReordered).map(data => {
    const finalDates = dates.map(date => dataReordered[data].dates[date] || 0)
    return { name: data, data: finalDates }
  })

  return {
    finalData,
    dates: dates.map(date => formatDateExibition(date))
  }
}
