import { types } from '../constants'

const index = {
  pending: `${types.getMinCosts}_PENDING`,
  fulfilled: `${types.getMinCosts}_FULFILLED`,
  rejected: `${types.getMinCosts}_REJECTED`
}

export const getMinCosts = {
  [index.pending]: ({ draft }) => {
    draft.industry.budget.minCosts.loading = true
    draft.industry.budget.minCosts.error = null
  },
  [index.fulfilled]: ({ draft, action }) => {
    draft.industry.budget.minCosts.loading = false
    draft.industry.budget.minCosts.error = null
    draft.industry.budget.minCosts.data = action.payload
  },
  [index.rejected]: ({ draft, action }) => {
    draft.industry.budget.minCosts.loading = false
    draft.industry.budget.minCosts.error = action.payload
  }
}
