import React from 'react';

import { Container } from './styles';

/**
 *
 * Componente agnostico isolado
 */
const InputCheckbox = ({
  defaultChecked = false,
  inputRef,
  value,
  name,
  id,
  fieldName,
  label,
  error,
  className,
  disabled,
  ...rest
}) => {
  return (
    <Container isDisabled={disabled}>
      <input
        ref={inputRef}
        value={value}
        name={name}
        type="checkbox"
        id={id}
        disabled={disabled}
        {...(defaultChecked ? { defaultChecked } : {})}
        {...rest}
      />

      <label htmlFor={id} key={fieldName} className={className}>
        {label}
      </label>

      {error && <span>{error}</span>}
    </Container>
  );
};

export default InputCheckbox;
