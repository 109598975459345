import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FilterLocations from '~/components/FilterLocations';
import { Filters } from '~/components/Filters';
import HeaderPages from '~/components/HeaderPages';
import PageTitle from '~/components/PageTitle';
import { history } from '~/helpers';
import * as types from '~/store/modules/products/constants';

import List from './elements/List';
import * as S from './styles';

const ProductsList = () => {
  const { locationId } = useParams();

  const [indicatorAtLast, setIndicatorAtLast] = useState(true);

  const { locations: filteredLocations } = useSelector(state => state.filters);

  const filteredLocationLabel = useMemo(
    () => filteredLocations?.label || null,
    [filteredLocations],
  );

  const titlePage = useMemo(
    () => (filteredLocationLabel ? ` - ${filteredLocationLabel}` : ''),
    [filteredLocationLabel],
  );

  const handleChangeLocation = data =>
    history.replace(`/products/list/${data?.value || ''}`);

  return (
    <>
      {/* Meta <title> */}
      <PageTitle title={`Listagem de produtos${titlePage}`} />

      <HeaderPages
        title="Listagem de produtos"
        className="has-datepicker"
        sideLeftComponent={
          <FilterLocations
            onChange={handleChangeLocation}
            initialLocation={locationId}
          />
        }
        sideRightComponent={
          <S.Toogle className="active">
            <label htmlFor="indicatorAtLast">Posição dos indicadores</label>
            <input
              id="indicatorAtLast"
              name="indicatorAtLast"
              type="checkbox"
              className="toggle"
              checked={indicatorAtLast}
              onChange={() => setIndicatorAtLast(e => !e)}
            />
          </S.Toogle>
        }
      />

      {!!filteredLocations && (
        <Filters
          query
          liveQuery
          queryPlaceholder="Buscar Produto (Nome, SKU, EAN, etc)"
          queryKeyState={`${types.PRODUCT_REDUX_QUERY}`}
        />
      )}

      <List indicatorAtLast={indicatorAtLast} />
    </>
  );
};

export default ProductsList;
