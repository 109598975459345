import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;

    > form > div > label,
    small {
      margin: 0;
    }

    > form > div > div {
      margin: 0.5rem 0 0.5rem 0;
    }
  `}
`

export const WrapperAlert = styled.div`
  margin-bottom: 0.5rem;
`
interface Props {
  hasCancelButton: boolean
}
export const WrapperButtons = styled.div<Props>`
  ${({ hasCancelButton }) => css`
    float: ${hasCancelButton ? 'right' : 'none'};
    display: flex;
    gap: 0.8rem;
  `}
`
