/* eslint-disable no-restricted-globals */

import i18n from '~/i18n'

import localstorageConstants from './localstorage-manipulation'

const getValidLanguage = (): string => {
  const language = i18n.language || 'pt-BR'

  try {
    // Testa se o código de idioma é válido
    new Intl.NumberFormat(language)
    return language // Se for válido, retorna o mesmo código
  } catch (error) {
    // Se houver erro, retorna um código de idioma padrão
    console.warn(`Idioma inválido: ${language}. Usando 'pt-BR' como padrão.`)
    return 'pt-BR'
  }
}

/**
 * Formata o valor tipo number para um formato com pontos
 * @param {number} value Valor que será formatdo
 * @returns {string} Valor formatado com pontos
 * @example
 * const number = 23011987
 * formatNumberWithDots(number)
 * Return: "23.011.987"
 */
export const formatNumberWithDots = (value: string | number) => {
  const toNumber = Number(value)

  return isNaN(toNumber) ? '-' : toNumber.toLocaleString('pt-br')
}

export const brlToFloat = value => {
  if (typeof value === 'string') {
    return parseFloat(value.replace('.', '').replace(',', '.'))
  }

  return value
}

export const formatMoney = (
  value,
  returnNumber = false,
  fallbackMessage = '-'
) => {
  if (value !== 0 && isNaN(value)) return fallbackMessage

  const valueNumber = Number(value).toFixed(3)

  const language = getValidLanguage()

  let currency = 'BRL'

  const storageValue = localStorage.getItem(localstorageConstants.APP_INFO)

  if (storageValue) {
    const appInfo = JSON.parse(storageValue)

    currency = appInfo?.currencyCode
  }

  const fractionDigits = currency === 'COP' ? 0 : 2

  const formatter = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  })

  return formatter.format(valueNumber)
}

export const formatMoneyV2 = ({
  value,
  fallbackMessage = '-'
}: {
  value?: string | number | null
  fallbackMessage?: string
}) => {
  if (value !== 0 && !Number(value)) return fallbackMessage

  const valueNumber = Number(value).toFixed(3)

  const language = getValidLanguage()

  let currency = 'BRL'

  const storageValue = localStorage.getItem(localstorageConstants.APP_INFO)

  if (storageValue) {
    const appInfo = JSON.parse(storageValue)

    currency = appInfo?.currencyCode
  }

  const fractionDigits = currency === 'COP' ? 0 : 2

  const formatter = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  })

  return formatter.format(Number(valueNumber))
}

/**
 * Trata o digitação dos inputs de dinheiro e faz começa-los pelos centavos.
 *
 * @param {number} value valor retornado pelo inpit
 */
export const formatMoneyInputs = (value: number) => {
  const language = getValidLanguage()
  let currency = 'BRL'

  const storageValue = localStorage.getItem(localstorageConstants.APP_INFO)

  if (storageValue) {
    const appInfo = JSON.parse(storageValue)

    currency = appInfo?.currencyCode
  }

  const fractionDigits = currency === 'COP' ? 0 : 2

  const formatter = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  })

  if (!Number(value)) return formatter.format(0)

  const valueToFormat = currency === 'COP' ? value : value / 100

  const amount = formatter.format(valueToFormat)

  return `${amount}`
}

/**
 * Remove a formatação do dinheiro.
 * Exemplo: R$ 23.202,20 = 232020.20
 *
 * @param {string} value valor formato em R$
 */

export const unformatMoney = (value: string | number) => {
  if (!isNaN(Number(value))) return value

  const stringValue = `${value}`

  const formattedValue =
    stringValue
      .replace('R$', '')
      .replace('$', '')
      .replace('COP', '')
      .replace(/\./g, '')
      .replace(/,/g, '.')
      .trim() || null

  return value ? formattedValue : 0
}

export const formatPercent = (value, digits = 2) => {
  if (isNaN(`${value}`)) return '-'

  return value ? `${parseFloat(value).toFixed(digits)}%` : '0%'
}

export const percentage = (partialValue, totalValue) =>
  (100 * partialValue) / totalValue

/**
 * CPF
 */

export const formatCPF = (cpf: string) => {
  cpf = cpf.replace(/[^\d]/g, '')

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

/**
 * Number with k or m
 */

export function formatNumberWithK({
  number = 0,
  fixexThousand = 0,
  fixedMillion = 0
}) {
  if (number >= 1000000) {
    const formatted = (number / 1000000).toFixed(fixedMillion)
    return `${formatted}M`
  }

  if (number >= 1000) {
    const formatted = (number / 1000).toFixed(fixexThousand)
    return `${formatted}k`
  }

  return number.toString()
}
