import styled, { css } from 'styled-components'

export const Approved = styled.small`
  ${({ theme }) =>
    css`
      display: flex;
      align-items: center;
      line-height: 1rem;
      margin-top: 0.125rem;
      color: ${theme.colors.gray};
      transition: 0.3s ease all;

      &:hover {
        color: ${theme.colors.hover};
      }

      .name {
        line-height: 1rem;
        margin-right: 0.5rem;
      }

      .icon {
        display: inline-flex;
        margin-right: 0.25rem;
      }
    `}
`
