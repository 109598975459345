import styled, { css } from 'styled-components';

import Tooltip from '../unform/_elements/Tooltip';

export const Container = styled.div`
  ${({ theme, error }) => css`
    width: 100%;
    margin-top: 0;
    border-radius: ${theme.border_radius};

    border: 1px solid ${!error ? 'var(--tertiary)' : 'var(--danger)'};
    background: var(--white);

    .toolBar {
      border-radius: ${theme.border_radius} ${theme.border_radius} 0 0;
      border-bottom: 1px solid var(--tertiary);
      padding: 0.5rem;

      > button {
        height: 20px;
        width: 20px;
        margin: 0 0.25rem;
      }
    }

    > section {
      display: flex;
      padding: 0.5rem;

      code {
        color: ${theme.colors.dark};
      }

      .textArea {
        flex: 1;
        min-height: 240px;

        > div {
          height: 100%;

          p {
            margin-bottom: 0;
          }
        }
      }

      .emojiPicker {
        .emoji-picker-react ul.skin-tones-list {
          top: 1rem;
        }

        label {
          margin-bottom: 0;
        }
      }
    }
  `}
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
`;

export const HelperText = styled.section`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    font-style: italic;
    color: ${theme.colors.gray};
  `}
`;

export const Count = styled.aside`
  ${({ theme, isValid }) => css`
    margin-left: 1rem;

    code {
      white-space: nowrap;
      color: ${isValid ? theme.colors.dark : theme.colors.danger};
      font-weight: ${isValid ? 'normal' : 'bold'};
    }
  `}
`;

export const Error = styled(Tooltip)`
  line-height: 1rem;
  height: 1rem;
  margin-left: 1rem;
  align-self: flex-end;

  --mainColor: var(--danger);
  --mainColorText: var(--white);
`;
