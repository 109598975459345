import { produce } from 'immer'
import { AnyAction } from 'redux'

import { getAccountId, getUserId, getAccountType } from '~/helpers'

import * as types from './constants'

const INITIAL_STATE: SigninState = {
  loggedIn: false,
  loading: false,
  error: null,
  user: null,
  accountId: getAccountId(),
  userId: getUserId(),
  accountType: getAccountType()
}

export default (state = INITIAL_STATE, action: AnyAction) =>
  produce(state, draft => {
    switch (action.type) {
      case `${types.LOGIN_REQUEST}_FULFILLED`:
        draft.loggedIn = true
        draft.user = action.payload.user
        draft.accountId = action.payload.current_account_id
        draft.accountType = action.payload.current_account_type
        draft.error = null
        draft.loading = false
        break

      case `${types.LOGIN_REQUEST}_REJECTED`:
        draft.loggedIn = false
        draft.error = action.payload
        draft.loading = false
        break

      case `${types.LOGIN_REQUEST}_PENDING`:
        draft.error = null
        draft.loading = true
        break

      case types.LOGIN_SET_ACCOUNT_ID:
        draft.accountId = action.payload
        break

      default:
        return state
    }
  })
