/* eslint-disable react/no-array-index-key */
import React, { useMemo, useCallback, useState } from 'react'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import { Table as ReactstrapTable } from 'reactstrap'

import HeadRow from './HeadRow'
import Row from './Row'

import { Thead, Tbody, ContainerTable } from './styles'
// import 'react-perfect-scrollbar/dist/css/styles.css'

/**
 * # Tabela
 *
 * Componente para facilitar a renderização de resultados em tabela. Recebe uma lista em `data`
 * e um conjunto de definições em `schema` definindo o comportamento visual da tabela.
 *
 * @extras Demais propriedades podem ser vistas na tipagem do componente.
 *
 * @example
 * const results = [
 *  {name: "Breno", age: 36},
 *  {name: "Bart", age: 6},
 *  {name: "Gab", age: 3}
 * ]
 *
 * const schema = {
 *  name: {
 *    title: "Nome",
 *    render: rowData => (<strong>{rowData.name}</strong>)
 *  },
 *  age: {
 *    title: "Idade",
 *    render: rowData => `${rowData.age} anos`
 *  }
 * }
 *
 * <Table data={results} schema={mySchema} />
 */

const Table: React.FC<TableProps> = ({
  schema,
  data,
  className,
  total,
  hasHeader,
  striped,
  sideBorder,
  smallerRowPadding,
  activeSortKey,
  activeSortDirection,
  onSort,
  freeze = null
}) => {
  const [focusColumn, setFocusColumn] = useState(null)

  return (
    <ContainerTable data-freeze={freeze}>
      <ReactstrapTable
        className={`my-0 ${className} ${!!sideBorder && 'sideBorder'}`}
        striped={striped}
        onMouseLeave={() => setFocusColumn(null)}
        // responsive
      >
        {hasHeader && (
          <Thead>
            <HeadRow
              schema={schema}
              sideBorder={sideBorder}
              focusColumn={focusColumn}
              total={total}
              activeSortKey={activeSortKey}
              activeSortDirection={activeSortDirection}
              onSort={onSort}
            />
          </Thead>
        )}
        <Tbody>
          <Row
            data={data}
            schema={schema}
            sideBorder={sideBorder}
            smallerRowPadding={smallerRowPadding}
            onMouseOver={setFocusColumn}
          />
        </Tbody>
      </ReactstrapTable>
    </ContainerTable>
  )
}

Table.defaultProps = {
  data: [],
  className: '',
  total: null,
  hasHeader: true,
  striped: true,
  sideBorder: false,
  smallerRowPadding: false
}

export default Table
