import styled, { css } from 'styled-components';

export const Container = styled.button`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.tiny};
    text-align: left;
    padding: ${theme.spacings.xsmall};
    border: 1px solid ${theme.colors.inative};
    border-radius: 0.25rem;
    background-color: ${theme.colors.light};
    transition: 0.3s ease-in-out all;
    width: 100%;

    mark {
      padding: 0;
    }

    > header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        display: flex;
        align-items: center;

        svg {
          color: ${({ theme }) => theme.colors.primary};
          font-size: ${({ theme }) => theme.font.sizes.small};
          margin-right: ${theme.spacings.tiny};
        }
      }
    }

    > section {
      > p {
        margin: 0;

        + p {
          margin-top: 0.25rem;
        }
      }
    }

    &:hover {
      background-color: ${theme.colors.primaryLighten60};
      border-color: ${theme.colors.primary};
    }
  `}
`;

export const ContainerEdit = styled.div``;
