import { advertiserPublisher as service } from '~/modules/retailMedia/services/industry'

import { types } from './constants'

export const listAdvertiserPublisher = params => ({
  type: types.list,
  payload: service.list(params)
})

export default {
  listAdvertiserPublisher
}
