const prefix = `@PROMOTIONAL`;

export const PROMOTIONS_CREATE = `${prefix}/PROMOTIONS_CREATE`;
export const PROMOTIONS_DELETE = `${prefix}/PROMOTIONS_DELETE`;
export const PROMOTIONS_UPDATE = `${prefix}/PROMOTIONS_UPDATE`;
export const PROMOTIONS_LIST = `${prefix}/PROMOTIONS_LIST`;
export const PROMOTIONS_LIST_PER_PAGE = `${prefix}/PROMOTIONS_LIST_PER_PAGE`;
export const PRODUCT_REDUX_QUERY = `${prefix}/PRODUCT_REDUX_QUERY`;
export const PROMOTION_VIEW = `${prefix}/PROMOTION_VIEW`;
export const PROMOTION_METRICS_VIEW = `${prefix}/PROMOTION_METRICS_VIEW`;

export const ADD_PRODUCT = `${prefix}/ADD_PRODUCT`;
export const ADD_PRODUCTS_BULK = `${prefix}/ADD_PRODUCTS_BULK`;
export const DELETE_PRODUCT = `${prefix}/DELETE_PRODUCT`;
export const DELETE_ALL_PRODUCTS = `${prefix}/DELETE_ALL_PRODUCTS`;
export const CLEAR_PROMOTION_STORE = `${prefix}/CLEAR_PROMOTION_STORE`;

export const PROMOTIONAL_REDUX_QUERY = `${prefix}/TEMPLATE_REDUX_QUERY`;
