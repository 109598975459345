import React from 'react';
import { useSelector } from 'react-redux';

import Panel from '~/components/Panel';

import * as S from '../styles';

const GeneralInfoView = () => {
  const { promotion } = useSelector(state => state.promotional);

  return (
    <Panel title="Informações gerais">
      <S.RowWrapper className="hasBorder">
        <S.Wrapper>
          <strong>Título da campanha</strong>
          <p>{promotion?.name}</p>
        </S.Wrapper>
      </S.RowWrapper>

      {promotion?.description && (
        <S.RowWrapper className="hasBorder">
          <S.Wrapper>
            <strong>Descrição da campanha</strong>
            <p>{promotion?.description}</p>
          </S.Wrapper>
        </S.RowWrapper>
      )}

      <S.RowWrapper className="hasBorder">
        <S.GroupWrapper>
          <S.Wrapper>
            <strong>Data de início</strong>
            <p className="dateLabel">
              {promotion?.start_date || 'Nenhuma data cadastrada.'}
            </p>
          </S.Wrapper>

          <S.Wrapper>
            <strong>Data de término</strong>
            <p className="dateLabel">
              {promotion?.end_date || 'Nenhuma data cadastrada.'}
            </p>
          </S.Wrapper>

          <S.Wrapper>
            <strong>Duração</strong>
            <p>{promotion?.duration}</p>
          </S.Wrapper>
        </S.GroupWrapper>
      </S.RowWrapper>
    </Panel>
  );
};

export default GeneralInfoView;
