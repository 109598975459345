import React, { useMemo, useCallback, useEffect } from 'react'
import { FiChevronRight, FiX } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import Alert from '~/components/Alert'
import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import { FilterSearch } from '~/components/FilterSearch'
import {
  getTags,
  togglePanelTags,
  updateChatsTags,
  setSelectedTag
} from '~/modules/chat/store/actions'
import { CHAT_TAGS_REDUX_QUERY } from '~/modules/chat/store/constants'

import TagButton from '../TagButton'

import * as S from './styles'

const TagsPanel = ({ closePanel }) => {
  const dispatch = useDispatch()
  const {
    selectedChat,
    selectedTags,
    chatsSelectedToEdit,
    loadingTags,
    avaiableTags,
    tagsPanelOpen
  } = useSelector(({ chats }) => chats)
  const { query } = useSelector(({ filters }) => filters)
  const filteredQuery = useMemo(() => query?.queryChatTags || null, [query])

  const tags = useMemo(
    () => (selectedChat ? selectedChat?.session?.tags : null),
    [selectedChat?.session?.tags]
  )

  const sessionId = useMemo(
    () => (selectedChat ? selectedChat?.session?.sessionId : null),
    [selectedChat]
  )

  const handlePanelTags = () => dispatch(togglePanelTags())

  useEffect(() => {
    if (tagsPanelOpen) {
      dispatch(setSelectedTag({ initialData: tags }))
    }
  }, [tagsPanelOpen, dispatch, tags])

  useEffect(() => {
    if (!avaiableTags) {
      dispatch(getTags())
    }
  }, [avaiableTags, dispatch])

  const handleTagsData = useCallback(() => {
    const filteredChats = chatsSelectedToEdit
      .filter(item => !!item?.sessionId)
      .map(item => item.sessionId)

    const sessionIds = tagsPanelOpen ? [sessionId] : filteredChats

    const tags = [...selectedTags]

    return {
      sessionIds,
      tags
    }
  }, [chatsSelectedToEdit, sessionId, selectedTags, tagsPanelOpen])

  const handleTags = useCallback(() => {
    closePanel && closePanel(false)

    const requestData = handleTagsData()

    dispatch(updateChatsTags(requestData))
  }, [closePanel, dispatch, handleTagsData])

  const isSlidingPanel = useMemo(() => tagsPanelOpen, [tagsPanelOpen])

  const hasTags = useMemo(() => !!avaiableTags?.length, [avaiableTags])

  const filteredTags = useMemo(
    () =>
      hasTags &&
      avaiableTags?.filter(item => {
        const escaped = filteredQuery?.replace(
          /[-[\]{}()*+?.,\\^$|#\s]/g,
          '\\$&'
        )

        const condition = new RegExp(escaped, 'gi')

        return filteredQuery ? condition.test(item.label) : true
      }),
    [avaiableTags, filteredQuery, hasTags]
  )

  const hasTagsOnSearch = useMemo(
    () => !!filteredTags?.length,
    [filteredTags?.length]
  )

  const tagIsFiltered = useCallback(
    tagId => filteredTags.findIndex(item => item.tagId === tagId) > -1,
    [filteredTags]
  )

  return (
    chatsSelectedToEdit && (
      <S.Container>
        {isSlidingPanel && (
          <header>
            <strong className="title">Tags da conversas</strong>
            <p className="description">Defina as tags da conversa.</p>

            <button
              type="button"
              onClick={handlePanelTags}
              className="icon__close"
            >
              <FiX />
            </button>

            {!!hasTags && (
              <>
                <FilterSearch
                  live
                  placeholder="Buscar tags"
                  autoComplete="off"
                  keyState={CHAT_TAGS_REDUX_QUERY}
                />
              </>
            )}
          </header>
        )}

        <section>
          {!loadingTags && !hasTags && (
            <Alert>Nenhuma tag está cadastrada.</Alert>
          )}

          {!loadingTags && hasTags && !hasTagsOnSearch && (
            <Alert>Nenhuma tag foi encontrada.</Alert>
          )}

          {!hasTags && (
            <AlertInfo
              template="info"
              text={
                <>
                  Para começar a usar as tags, crie uma acessando{' '}
                  <NavLink to={'/chats/tags'}>
                    o menu tags dentro de chats
                  </NavLink>
                  .
                </>
              }
            />
          )}

          {!!hasTags && (
            <>
              <S.List>
                {!isSlidingPanel && (
                  <strong className="title">
                    Escolha as tags que deseja aplicar:
                  </strong>
                )}

                {filteredTags?.map(tag => (
                  <TagButton data={tag} key={tag.tagId} />
                ))}

                {filteredQuery && !!selectedTags?.length && (
                  <div className="separator">tags selecionadas:</div>
                )}

                {filteredQuery &&
                  avaiableTags?.map(tag => (
                    <TagButton
                      data={tag}
                      showOnlyChecked
                      isFiltered={tagIsFiltered(tag.tagId)}
                      key={tag.tagId}
                    />
                  ))}
              </S.List>
            </>
          )}
        </section>

        {!!hasTags && (
          <>
            <footer>
              <Button
                template="success"
                iconRight={<FiChevronRight />}
                text="Salvar tags da conversa"
                onClick={handleTags}
                size="small"
              />
            </footer>
          </>
        )}
      </S.Container>
    )
  )
}

export default TagsPanel

TagsPanel.propTypes = {
  closePanel: PropTypes.func
}

TagsPanel.defaultProps = {
  closePanel: null
}
