import StatusAdsBadge from '~/modules/retailMedia/components/StatusAdsBadge'

const Status: RowComponent = ({ status, isRunning, campaignStatus }) => {
  return (
    <div style={{ lineHeight: 1, maxWidth: '110px' }}>
      <StatusAdsBadge data={{ status, isRunning, campaignStatus }} />
    </div>
  )
}

export default Status
