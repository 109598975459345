import React, { useMemo } from 'react';

import { Spinner } from '~/components/Spinner';

import { useLogisticSlots } from '../../useLogisticSlots';

import * as S from './styles';

const LoadingSlots = () => {
  const { loading, loadingUpdate } = useLogisticSlots();

  const messageLoading = useMemo(() => {
    if (loading) return 'Carregando configuraçao de slots...';

    if (loadingUpdate) return 'Atualizando as configurações dos slots...';

    return null;
  }, [loading, loadingUpdate]);

  return (
    <>
      {messageLoading && (
        <S.LoadingMaster>
          <Spinner /> {messageLoading}
        </S.LoadingMaster>
      )}
    </>
  );
};

export default LoadingSlots;
