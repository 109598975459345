import React, { useEffect, useState } from 'react'
import { isInclusivelyBeforeDay } from 'react-dates'
import isInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay'
import * as Icon from 'react-feather'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { Button } from '~/components/Buttons'
import i18n from '~/i18n'
import i18next from '~/i18n'

import Calendar from '../calendar'

const DatePicker = ({
  isRangeDate,
  onChange,
  outsideRange,
  className,
  defaultStartDate,
  defaultSingleDate,
  defaultEndDate,
  maxEndDate,
  ...restCalendar
}) => {
  const language = i18n.language || 'pt-BR'
  moment.locale(language)

  const { t } = useTranslation()
  const patternDate = t('common:patterns.date')

  const [tempStartDate, setTempStartDate] = useState(
    defaultStartDate || defaultSingleDate || null
  )
  const [tempEndDate, setTempEndtDate] = useState(defaultEndDate || null)
  const [date, setDate] = useState(defaultSingleDate || null)
  const [showCalendar, setShowCalendar] = useState(false)

  /**
   * two useEffect to listeen changes on defaultStartDate, defaultSingleDate and defaultEndDate
   */
  useEffect(() => {
    if (defaultStartDate) setTempStartDate(defaultStartDate)
  }, [defaultStartDate])

  useEffect(() => {
    if (defaultSingleDate) setTempStartDate(defaultSingleDate)
  }, [defaultSingleDate])

  useEffect(() => {
    if (defaultEndDate) setTempEndtDate(defaultEndDate)
  }, [defaultEndDate])

  const outsideClick = () => {
    setShowCalendar(false)
  }

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar)
  }

  const hasDates = () => {
    if (isRangeDate) {
      return tempStartDate && tempEndDate
    }

    return !!date
  }

  const renderDates = (startDate, endDate, date) => {
    if (isRangeDate) {
      return `${moment(startDate).format(patternDate)} - ${moment(
        endDate
      ).format(patternDate)}`
    }

    return `${moment(date).format(patternDate)}`
  }

  const changeDate = dateValues => {
    const { startDate, endDate, date } = dateValues

    if (startDate) {
      setTempStartDate(startDate)
      setTempEndtDate(endDate)
      setDate(date)
    }

    if (isRangeDate && startDate && endDate) {
      toggleCalendar()
    }

    if (!isRangeDate) {
      toggleCalendar()
    }

    if (startDate || endDate || date) {
      onChange(dateValues)
    }
  }

  const isOutsideRange = day => {
    if (maxEndDate) {
      const formats = {
        D0: () => isInclusivelyAfterDay(day, moment().add(1, 'day')),
        D1: () => !isInclusivelyBeforeDay(day, moment().subtract(1, 'day'))
      }

      return formats?.[maxEndDate]()
    }

    if (outsideRange) {
      return outsideRange(day)
    }

    // return isInclusivelyAfterDay(day, moment().add(1, 'day'))
  }

  return (
    <div
      className={`datepicker__wrapper ${className || ''} ${
        showCalendar ? '--visible' : ''
      }`}
    >
      <div className="labelMobile mobile">Definir período:</div>
      <Button
        onClick={toggleCalendar}
        className="datepicker__button bg-white border-tertiary"
        template="light"
        data-testid="datepicker__button"
      >
        <Icon.Calendar size={16} className="datepicker__icon" />

        <span data-testid="datepicker__text">
          {(hasDates() && renderDates(tempStartDate, tempEndDate, date)) ||
            i18next.t('common:labels.selectDate')}
        </span>
      </Button>
      <div
        className={`datepicker__calendar ${showCalendar ? '--visible' : ''}`}
      >
        <Calendar
          startDate={tempStartDate || date}
          endDate={tempEndDate}
          onOutsideClick={outsideClick}
          onChange={dates => changeDate(dates)}
          isOutsideRange={isOutsideRange}
          isRangeDate={isRangeDate}
          maxEndDate={maxEndDate}
          range={48}
          {...restCalendar}
        />
      </div>
    </div>
  )
}

export default DatePicker
