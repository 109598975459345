import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'

import { buttonDefaultStyles, buttonTemplates } from '../templates'

export const Container = styled(NavLink)<ContainerProps>`
  ${({ theme, template = 'primary', ...rest }) => css`
    ${buttonDefaultStyles({ theme, props: { ...rest } })}
    ${buttonTemplates[template]({ theme, ...rest })}
  `}
`
