import styled, { css } from 'styled-components';

export const Tag = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.outlineGray};
    border-radius: 12px;
    padding: ${theme.spacings.tiny} ${theme.spacings.xxxsmall};

    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.stormGray};
  `}

  display: flex;
`;
