import { useQuery } from '@tanstack/react-query'
import i from 'i18next'

import { getAccountId, getIsNetwork } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import Tabs from '~/modules/retailMedia/components/Tabs'
import { BadgeNotifications } from '~/modules/retailMedia/components/Tabs/styles'
import {
  countCampaignsKey,
  countCampaignByStatus
} from '~/modules/retailMedia/services/grocery/campaign'

const PublisherDashboardTabs: React.FC = () => {
  const accountId = getAccountId()
  const isNetwork = getIsNetwork()

  const query = useQueryParams()
  const id = query.get('rmid')

  // Get counter review

  const { data } = useQuery({
    queryKey: [countCampaignsKey, accountId],
    queryFn: async () => {
      const res = await countCampaignByStatus({
        status: 'pending_review',
        advertiser_id: id
      })

      return res.data.count
    }
  })

  const linkParam = id ? `?rmid=${id}` : ''

  const advertiserTab = {
    to: '/retail-media/advertisers',
    label: i.t('rm:mainTabs.advertisers')
  }

  const subPublishersTab = {
    to: '/retail-media/network',
    label: i.t('rm:mainTabs.network')
  }

  const tabs = [
    !id ? advertiserTab : null,
    {
      to: `/retail-media/campaigns${linkParam}`,
      label: i.t('rm:mainTabs.campaigns')
    },
    {
      to: `/retail-media/ads${linkParam}`,
      label: i.t('rm:mainTabs.ads')
    },
    isNetwork ? subPublishersTab : null,
    {
      to: `/retail-media/campaigns/review${linkParam}`,
      label: i.t('rm:mainTabs.campaignReview'),
      iconRight:
        data > 0 ? <BadgeNotifications>{data}</BadgeNotifications> : null
    }
  ].filter(i => !!i)

  return <Tabs tabs={tabs} />
}

export default PublisherDashboardTabs
