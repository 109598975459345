import React from 'react';

import PropTypes from 'prop-types';

import * as S from '../styles';

const Discount = ({ value, selectedOffers }) => (
  <S.Wrapper>
    <strong>Desconto</strong>
    <p>
      {value}
      {!!selectedOffers && <small> (itens selecionados)</small>}
    </p>
  </S.Wrapper>
);

export default Discount;

Discount.propTypes = {
  value: PropTypes.string.isRequired,
  selectedOffers: PropTypes.bool.isRequired,
};
