import { formatDateToString, formatMoney } from '~/helpers';

const formatInitialData = promotion => {
  let data = {
    ...promotion,
    start_date: formatDateToString(promotion.start_promotion, 'YYYY-MM-DD'),
    end_date: formatDateToString(promotion.end_promotion, 'YYYY-MM-DD'),
  };

  if (data?.trigger?.total?.min_value) {
    data = {
      ...data,
      trigger: {
        ...data.trigger,
        total: {
          ...data.trigger.min_value,
          min_value: formatMoney(promotion.trigger.total.min_value),
        },
      },
    };
  }

  return data;
};

const defaultInitialData = {
  name: null,
  promotion_target: 'all',
  promotion_trigger: 'auto',
  promotion_type: 'buy_pay',
  start_hour: '00:00',
  end_hour: '23:59',
  benefits: {
    items_discount: {
      type: 'percent',
    },
    shipping_discount: {
      type: 'percent',
    },
  },
  promotionRules: {
    totalUsage: false,
    perCustomer: false,
    totalValue: false,
    orderItems: false,
  },
  customersCheck: 'all',
  customers: null,
  locationsCheck: 'all',
  location_ids: {
    all: true,
  },
};

export { formatInitialData, defaultInitialData };
