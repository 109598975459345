import * as Yup from 'yup';

export const schemaYup = Yup.object().shape({
  name: Yup.string().required('O título da campanha é obrigatório'),
  messages: Yup.array().of(
    Yup.object().shape({
      text: Yup.string()
        .min(5, 'O conteúdo precisa possuir pelo menos 5 caracteres')
        .max(1024, 'O conteúdo não pode exceder 1024 caracteres'),
    }),
  ),

  start_date: Yup.string().required(
    'A data e horário de envio são informações obrigatórias',
  ),
  start_hour: Yup.string().required(
    'A data e horário de envio são informações obrigatórias',
  ),
  end_date: Yup.string().required(
    'A data e horário de envio são informações obrigatórias',
  ),
  end_hour: Yup.string().required(
    'A data e horário de envio são informações obrigatórias',
  ),

  media: Yup.object()
    .shape({
      type: Yup.string(),
      url: Yup.string(),
      name: Yup.string(),
    })
    .required('A méidia é obrigatória.'),
});
