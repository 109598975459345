import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormModule from './form';
import FormFooter from './footer';

const WrapperFields = ({
  onSubmit,
  onPreviousStep,
  disabledStep,
  edit,
  children,
  loading,
  isLastStep,
}) => {
  const [formIsValid, setFormIsValid] = useState(false);

  const wrapperNew = () => (
    <FormModule
      onSubmit={onSubmit}
      checkIsValid={setFormIsValid}
      disabledStep={disabledStep}
    >
      {children}

      <FormFooter
        loading={loading}
        formIsValid={formIsValid}
        onPreviousStep={onPreviousStep}
        isLastStep={isLastStep}
      />
    </FormModule>
  );

  const wrapperEdit = () => <fieldset>{children}</fieldset>;

  return edit ? <>{wrapperEdit()}</> : <>{wrapperNew()}</>;
};

export default WrapperFields;

WrapperFields.propTypes = {
  onSubmit: PropTypes.func,
  disabledStep: PropTypes.bool,
  edit: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  loading: PropTypes.bool,
  isLastStep: PropTypes.bool,
};

WrapperFields.defaultProps = {
  edit: false,
  onSubmit: null,
  disabledStep: false,
  isLastStep: false,
};
