import { useState } from 'react'

import ConfirmationModal from '~/components/ConfirmationModal'

/**
 * Gerencia o componente de confirmação de exclusão
 *
 * @exports useConfirmationModal;
 * @exports useConfirmationModal.ConfirmationModal - Element - Componente do painel <ConfirmationModal />
 * @exports useConfirmationModal.isShowing - boolean - Informa se o painel está ativo ou não
 * @exports useConfirmationModal.setIsShowing - function - Seta se o painel está ativo ou não
 *
 * @example
 *
 *  const [ConfirmationModal, isShowing, setIsShowing] = useConfirmationModal();
 *
 *  <Button onClick={setIsShowing} />
 *
 *   <ConfirmationModal
 *      title="Deseja apagar o local?"
 *      isOpen={showConfirmationModal}
 *       onClose={toggleConfirmationModal}
 *         onSubmit={() => console.log('enviou')}
 *         cancelButton="Botão cancelar"
 *          confirmationButton="Botão confirmar"
 *         confirmationValue="Teste"
 *    >
 *      Tem certeza que deseja apagar este produto?
 *    </ConfirmationModal>
 *
 */

type UseConfirmationModal = (
  initialState?: boolean
) => [
  React.FC<ConfirmationModalProps>,
  boolean,
  () => void,
  React.Dispatch<React.SetStateAction<boolean>>
]

const useConfirmationModal: UseConfirmationModal = (initialValue = false) => {
  const [isShowing, setIsShowing] = useState(initialValue)

  const toggle = () => {
    setIsShowing(oldState => !oldState)
  }

  return [ConfirmationModal, isShowing, toggle, setIsShowing]
}

export default useConfirmationModal
