import { useMemo } from 'react';

import { getAccountInfo } from '~/helpers/account-info';

/**
 * Retorna informações referente ao checkout do pedido.
 * - {boolean} isOpenValue
 *    Valor para indicar se o valor do pedido ainda está aberto.
 *    Ele percorre os elementos que não podem ter valor zerado e checa tem valor maior do que zero.
 * - {boolean} showLinkCheckout
 *    Valor para indicar se o link para o checkout deve ser exibido ou não atendendo as regras de exibição.
 *
 * @param {object} order Informações do pedido
 * @example
 *  const { isOpenValue, hasPendingItems, showLinkCheckout, urlCheckout } = useCheckout({ order: data });
 *
 * @returns booleans {  isOpenValue, hasPendingItems, showLinkCheckout, urlCheckout t }

 */

const useCheckout = ({ order }) => {
  const { id, isOpenValue, hasPendingItems, showLinkCheckout } = order;

  const { checkoutLink = null } = getAccountInfo() || {};

  const urlCheckout = useMemo(
    () => (checkoutLink ? `https://${checkoutLink}/${id}` : null),
    [checkoutLink, id],
  );

  return { isOpenValue, hasPendingItems, showLinkCheckout, urlCheckout };
};

export default useCheckout;
