import React, { useState, useRef } from 'react'
import { FiCopy, FiMoreVertical } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import useOnClickOutside from '~/hooks/useClickOutside'

import CancelButton from './CancelButton'
import DeleteButton from './DeleteButton'
import FinishButton from './FinishButton'
import PauseButton from './PauseButton'
import ReactiveButton from './ReactiveButton'

import { Container, ButtonWrapper } from './styles'

const ListMenu = ({ promotion }) => {
  const [keepsMenuOpen, setKeepsMenuOpen] = useState(false)
  const { id, status: statusRaw } = promotion

  const status = statusRaw.type

  const containerRef = useRef(null)

  const [showActions, setShowActions] = useState(false)

  const handleCloseMenu = e => {
    const closestContainer = e.target.closest('.action-button-container')

    if (!closestContainer || closestContainer !== containerRef.current)
      setShowActions(false)
  }

  useOnClickOutside(containerRef, handleCloseMenu, keepsMenuOpen)

  const handleButtonClick = () => {
    setShowActions(prev => !prev)
  }

  return (
    <>
      <Container ref={containerRef} className="action-button-container">
        <Button template="transparentPrimary" onClick={handleButtonClick}>
          <FiMoreVertical />
        </Button>

        {showActions && (
          <ButtonWrapper>
            <NavLink to={`/promotional/create/?duplicate=${id}`}>
              <FiCopy /> <span>Duplicar</span>
            </NavLink>

            {status === 'pause' && (
              <ReactiveButton
                promotion={promotion}
                keepsMenuOpen={setKeepsMenuOpen}
                menuItem
              />
            )}

            {status === 'active' && (
              <PauseButton
                promotion={promotion}
                keepsMenuOpen={setKeepsMenuOpen}
                menuItem
              />
            )}

            {(status === 'active' || status === 'pause') && (
              <FinishButton
                promotion={promotion}
                keepsMenuOpen={setKeepsMenuOpen}
                menuItem
              />
            )}

            {status === 'scheduled' && (
              <CancelButton
                promotion={promotion}
                keepsMenuOpen={setKeepsMenuOpen}
                menuItem
              />
            )}

            {status !== 'active' && (
              <DeleteButton
                promotion={promotion}
                keepsMenuOpen={setKeepsMenuOpen}
                menuItem
              />
            )}
          </ButtonWrapper>
        )}
      </Container>
    </>
  )
}

ListMenu.propTypes = {
  promotion: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string
    })
  }).isRequired
}

export default ListMenu
