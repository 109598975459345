import styled from 'styled-components';

import { Container as ParentContainer } from '../ChatItem/styles';

export const Container = styled.div`
  position: relative;
  display: flex;

  transition: 0.3s cubic-bezier(0.78, -0.12, 0.71, 1.39) all;
  overflow: hidden;

  max-width: 0;

  opacity: 0;

  > button {
    outline: none;
    border: none;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;
  }

  ${ParentContainer}:hover & {
    max-width: 2rem;
    margin-left: 0.25rem;
    opacity: 1;
  }
`;
