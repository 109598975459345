import { IconType } from 'react-icons'
import { FiTag as icon } from 'react-icons/fi'

import {
  ProductsList,
  ProductsNew,
  ProductsEdit,
  ProductsReports
} from '~/pages/products'

export const products: RootMenuItem<IconType> = {
  id: '9665dbae-60e1-4bdd-b1ce-355cb85ac2b9',
  path: '/products',
  name: 'Catálogo',
  pageTitle: 'Catálogo',
  icon,
  children: [
    {
      id: '3c00ddbf-55f1-488a-8e17-2658158f964e',
      path: '/products/list/',
      name: 'Listagem de produtos',
      pageTitle: 'Listagem de produtos',
      component: ProductsList
    },
    {
      id: '3c00ddbf-55f1-488a-8e17-2658158f964e',
      path: '/products/list/:locationId',
      name: 'Listagem de produtos',
      pageTitle: 'Listagem de produtos',
      component: ProductsList,
      hideMenu: true
    }
  ]
}
