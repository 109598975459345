import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCardsInfo } from '../../dashboard-actions';
import CardIcon from '~/components/cards/card-icon';
import { formatMoney, formatPercent } from '~/helpers';
import {
  FiDollarSign,
  FiShoppingBag,
  FiShoppingCart,
  FiTrendingUp,
} from 'react-icons/fi';

const cardsData = [
  {
    key: 'ordersCount',
    title: 'Total de pedidos',
    icon: <FiShoppingCart className="feather-lg text-primary" />,
  },
  {
    key: 'gmv',
    title: 'GMV',
    icon: <FiShoppingBag className="feather-lg text-warning" />,
    format: formatMoney,
  },
  {
    key: 'averageTicket',
    title: 'Ticket médio',
    icon: <FiDollarSign className="feather-lg text-success" />,
    format: formatMoney,
  },
  {
    key: 'margin',
    title: 'Margem',
    icon: <FiTrendingUp className="feather-lg text-warning" />,
    format: formatPercent,
  },
];

const Statistics = () => {
  const { startDate, endDate } = useSelector(state => state.datepickerHeader);

  const { cardsLoading, cardsInfo } = useSelector(state => state.dashboard);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCardsInfo(startDate, endDate));
  }, [startDate, endDate]);

  return (
    <div className="hold-cards">
      {cardsData.map((el, index) => (
        <CardIcon
          key={index}
          loading={cardsLoading}
          icon={el.icon}
          title={el.title}
          value={
            el.format
              ? el.format(cardsInfo.data?.[0]?.[`Orders.${el.key}`])
              : cardsInfo?.data?.[0]?.[`Orders.${el.key}`]
          }
        />
      ))}
    </div>
  );
};

export default Statistics;
