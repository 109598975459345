import { IconType } from 'react-icons'
import { FiBarChart2 as AnalyiticsIcon } from 'react-icons/fi'

import * as CustomersAnalyticsPage from './pages/customers'
import * as SellOutAnalyticsPage from './pages/sellOut'

export const analyticsRoutes: RootMenuItem<IconType> = {
  id: 'a3ee207c-4e20-4dcb-9e6c-6138749512ab',
  path: '/analytics',
  name: 'Análise de dados',
  icon: AnalyiticsIcon,
  children: [
    {
      id: '6dc2a596-550a-43e4-a911-4ed5e51e3d01',
      path: '/analytics/sellout/',
      name: 'Vendas',
      component: SellOutAnalyticsPage.Dashboard
    },
    {
      id: '261d0b79-fe87-48ef-967d-c7b55eae7bcd',
      path: '/analytics/customers',
      name: 'Consumidores',
      component: CustomersAnalyticsPage.Dashboard
    }
  ]
}

export default analyticsRoutes
