import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react'
import { FiRefreshCcw, FiRepeat } from 'react-icons/fi'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'

import { Button } from '~/components/Buttons'
import { Select, InputNumber } from '~/components/unform'
import InputMoney from '~/components/unform/Input/InputMoney'
import { formatMoney, unformatMoney } from '~/helpers'
import { getAccountInfo } from '~/helpers/account-info'
import { gtagEvent } from '~/lib/gtag'
import {
  itemStatusList,
  itemStatusListOptionsSelect
} from '~/pages/orders/_status/items'

import { ruptureCodes } from './ruptureCodes'

import * as S from './styles'

const Product = ({ item, isWaitingPayment, isEditMode, onChange, onUndo }) => {
  const [quantity, setQuantity] = useState(null)
  const [status, setStatus] = useState(item.status)
  const [originalPrice, setOriginalPrice] = useState(null)
  const [showDetails, setShowDetails] = useState(true)

  const formRefQty = useRef()
  const formRefPrice = useRef()
  const formRefStatus = useRef()

  useEffect(() => {
    setQuantity(item.quantity)
    setOriginalPrice(item.original_price)
    setStatus(item.status)
  }, [item, item.quantity, item.original_price, item.status])

  useEffect(() => {
    if (formRefQty?.current) {
      const field = formRefQty.current.getFieldValue('quantity')

      if (Number(field) !== item.quantity) {
        formRefQty.current.setFieldValue('quantity', item.quantity)
      }
    }
  }, [item])

  const originalPriceFormatted = useMemo(
    () => formatMoney(originalPrice),
    [originalPrice]
  )

  const totalValue = useMemo(
    () => formatMoney(item.total_value || quantity * originalPrice),
    [item.total_value, quantity, originalPrice]
  )

  const statusInfos = useMemo(
    () =>
      Object.values(itemStatusList).find(
        statusItem => statusItem.type === status
      ) || {},
    [status]
  )

  const updateParent = useCallback(
    newValues => {
      const data = {
        id: item.id,
        quantity,
        original_price: originalPrice,
        special_price: originalPrice,
        status,
        ...newValues
      }
      onChange(data)
    },
    [quantity, originalPrice, status, item.id, onChange]
  )

  /**
   * GTAG
   */

  const accountInfo = getAccountInfo()

  const accountName = useMemo(() => accountInfo?.name, [accountInfo])

  const handleStatus = useCallback(
    e => {
      setStatus(e)

      updateParent({ status: e })

      gtagEvent({
        category: 'Pedido',
        action: 'Editar status item',
        label: `[${accountName}] - Mudou para ${e}`
      })
    },
    [accountName, updateParent]
  )

  const handleQuantity = e => {
    const value = Number(e.target.value)

    setQuantity(value)
    updateParent({ quantity: value })
  }

  const handleOriginalPrice = useCallback(
    e => {
      setOriginalPrice(Number(unformatMoney(e.formattedValue)))
      updateParent({
        original_price: Number(unformatMoney(e.formattedValue)),
        special_price: Number(unformatMoney(e.formattedValue))
      })
    },
    [updateParent]
  )

  const handleUndoChanges = useCallback(() => {
    onUndo(item.id)
  }, [item.id, onUndo])

  const hasDetails = useMemo(
    () => !!item?.rupture?.code || !!item?.rupture?.note,
    [item.rupture]
  )

  return (
    <>
      <S.Item
        hasActionColumn={isWaitingPayment}
        isEditMode={isEditMode}
        statusStep={statusInfos.step}
        hasDetails={hasDetails}
        showDetails={showDetails}
        aria-expanded={hasDetails && showDetails}
        onClick={() => !isEditMode && setShowDetails(state => !state)}
        {...(hasDetails
          ? { title: 'Exibir detalhes', className: 'hasDetails' }
          : {})}
      >
        {/* Nome e código do produto */}
        <div className="name">
          {item.status === 'substitution' && (
            <span className="substitution">{item.original_name}</span>
          )}

          <strong className="nameProduct">
            {item.status === 'substitution' && (
              <span className="icon">
                <FiRepeat />
              </span>
            )}
            {item.name}
          </strong>

          <span className="productId color__gray">
            {item.status === 'substitution'
              ? item.product_id_substitute
              : item.product_id}
          </span>

          {item.detail && (
            <div className="note">
              <div className="text">
                <span className="helpText">Observações do cliente: </span>
                <span className="noteText">{item.detail}</span>
              </div>
            </div>
          )}
        </div>

        {/* Preço unitário */}
        <div className="original_price">
          <span className="label">Preço unitário:</span>
          {!isEditMode && (
            <span className="text_price">{originalPriceFormatted}</span>
          )}

          {/* Editar valor */}
          {isEditMode && (
            <div className="edit_item">
              <Form
                ref={formRefPrice}
                initialData={{ newPrice: originalPriceFormatted }}
              >
                <InputMoney
                  placeholder="Insira um valor"
                  name="newPrice"
                  required
                  type="money"
                  onChange={handleOriginalPrice}
                />
              </Form>
            </div>
          )}
        </div>

        {/* Quantidade */}
        <div className="quantity grayColumn">
          <span className="label">Quantidade:</span>

          {!isEditMode && (
            <span className="text">
              {!!item?.original_quantity && `${item?.original_quantity} / `}
              {quantity}
            </span>
          )}

          {isEditMode && (
            <div className="edit_item">
              <Form ref={formRefQty} initialData={{ quantity }}>
                <InputNumber
                  name="quantity"
                  placeholder="Quantidade"
                  onChange={handleQuantity}
                />
              </Form>
            </div>
          )}
        </div>

        {/* Desconto */}
        <div className="discount">
          <span className="label">Desconto:</span>
          {formatMoney(item?.discount || 0)}
        </div>

        {/* Valor total */}
        <div className="total_value grayColumn">
          <span className="label">Subtotal:</span>
          {totalValue}
        </div>

        {isEditMode && (
          <div className="undo_edit">
            <Button
              template="light"
              iconLeft={<FiRefreshCcw />}
              text="Desfazer"
              size="small"
              customWidth="auto"
              onClick={handleUndoChanges}
              tabIndex="-1"
            />
          </div>
        )}

        {/* Editar status */}
        <div className="status">
          {!isEditMode && (
            <span className="text_status">
              {statusInfos.label || 'Sem status definido'}
            </span>
          )}
          {isEditMode && (
            <div className="edit_item">
              <Form ref={formRefStatus} initialData={{ status }}>
                <Select
                  id="status"
                  name="status"
                  placeholder="Escolha um status"
                  type="select"
                  options={itemStatusListOptionsSelect}
                  onChange={handleStatus}
                />
              </Form>
            </div>
          )}
        </div>

        {hasDetails && showDetails && !isEditMode && (
          <S.Details>
            <span>
              <strong>Motivo de ruptura: </strong>
              {ruptureCodes[item.rupture.code]?.label || item.rupture.code}
            </span>

            {!!item.rupture.note && (
              <span>
                {' '}
                - <strong>Descrição: </strong>
                {item.rupture.note}
              </span>
            )}
          </S.Details>
        )}
      </S.Item>
    </>
  )
}

export default Product

Product.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    detail: PropTypes.string,
    product_id: PropTypes.string,
    product_id_substitute: PropTypes.string,
    original_name: PropTypes.string,
    original_price: PropTypes.number,
    quantity: PropTypes.number,
    original_quantity: PropTypes.number,
    total_value: PropTypes.number,
    discount: PropTypes.number,
    id: PropTypes.string,
    order_id: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  isWaitingPayment: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onChange: PropTypes.func,
  onUndo: PropTypes.func
}

Product.defaultProps = {
  isWaitingPayment: true,
  isEditMode: false,
  onChange: null,
  onUndo: null
}
