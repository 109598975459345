import * as types from '../constants'

const index = {
  optimistic: `${types.DELETE_TEMPLATE_OPTIMISTIC}`,
  pending: `${types.DELETE_TEMPLATE}_PENDING`,
  fulfilled: `${types.DELETE_TEMPLATE}_FULFILLED`,
  rejected: `${types.DELETE_TEMPLATE}_REJECTED`
}

export const deleteTemplate = {
  /**
   * Optimistic
   */
  [index.optimistic]: ({ action, draft }) => {
    const deletedId = action.payload
    const oldTemplates = draft.templates

    const filteredldTemplates = oldTemplates.map(template =>
      template.templateId !== deletedId
        ? template
        : { ...template, deleted: true, saving: true }
    )

    draft.templates = [...filteredldTemplates]
  },
  /**
   * Pending
   */
  [index.pending]: () => {},
  /**
   *
   *Fulfilled
   */
  [index.fulfilled]: ({ draft }) => {
    const oldTemplates = draft.templates

    const filteredldTemplates = oldTemplates.filter(
      template => !template?.deleted
    )

    draft.templates = [...filteredldTemplates]
  },
  /**
   * Rejected
   */
  [index.rejected]: ({ draft }) => {
    const oldTemplates = draft.templates

    const filteredldTemplates = oldTemplates.map(template =>
      template.deleted
        ? { ...template, deleted: false, saving: false }
        : template
    )

    draft.templates = [...filteredldTemplates]
  }
}
