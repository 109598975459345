import { retailMediaGroceryServices } from '~/modules/retailMedia/services'

import { types } from './'

/**
 * Get budget limits */
export const getMinCostsCampaign = ({
  errorFeedbackMsg,
  body
}: ListMinCostsRequest) => ({
  type: types.minCosts,
  payload: retailMediaGroceryServices.configuration.MinCosts.list({ body }),
  errorFeedbackMsg
})
