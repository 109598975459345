import React, { useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import { FormGroup, InputNumber, Label } from '~/components/unform';

import * as S from './styles';

const BenefitsPay = ({ quantitySelected = null }) => {
  const [productQuantity, setProductQuantity] = useState(
    quantitySelected || '',
  );

  const handleProductQuantity = ({ target }) => {
    setProductQuantity(target.value);
  };

  const limitBenefitsPay = useCallback(
    inputObj => {
      const { value } = inputObj;

      if (Number(value) < Number(productQuantity)) return inputObj;
    },
    [productQuantity],
  );

  return (
    <S.PromotionRulesGroup>
      <FormGroup>
        <Label htmlFor="type" isRequired text="Leva" />

        <InputNumber
          name="trigger.quantity"
          placeholder="Digite a quantidade mínima"
          type="tel"
          onChange={handleProductQuantity}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="benefits.pay" isRequired text="Paga" />

        <InputNumber
          name="benefits.pay"
          placeholder="Digite a quantidade"
          required
          isAllowed={limitBenefitsPay}
        />
      </FormGroup>
    </S.PromotionRulesGroup>
  );
};

export default BenefitsPay;

BenefitsPay.propTypes = {
  quantitySelected: PropTypes.number,
};

BenefitsPay.defaultProps = {
  quantitySelected: null,
};
