import { getAccessModulesService } from '~/services/access-modules'

import { ACCESS_MODULES_GET } from './constants'

export const getAccessModules = () => {
  return (dispatch: DispatchAction, getState: () => RootState) => {
    const { accountType, accountId } = getState().signin

    dispatch({
      type: ACCESS_MODULES_GET,
      payload: getAccessModulesService(),
      values: { accountType, accountId }
    })
  }
}
