import React from 'react'
import ReactPlaceholder from 'react-placeholder'
import { Media, Card, CardBody } from 'reactstrap'

import { Badge } from '../UI/Badge'
import ArrowUpDown from './arrow-up-down'

const CardIcon = ({ loading, icon, title, value, percent, badge }) => (
  <Card className="flex-fill">
    <CardBody>
      <ReactPlaceholder
        showLoadingAnimation
        rows={3}
        type="media"
        ready={!loading}
      >
        <Media>
          <Media body>
            <header className="d-flex align-items-center">
              {icon && (
                <div className="cardIcon d-inline-flex me-1">{icon}</div>
              )}
              <span>{title}</span>
            </header>

            <section>
              <strong className="value lh-1">{value}</strong>
            </section>

            {!isNaN(percent) && (
              <ArrowUpDown className="mt-2" percent={percent} />
            )}

            {badge && (
              <Badge style={{ margin: 0 }}>
                <small>{badge}</small>
              </Badge>
            )}
          </Media>
        </Media>
      </ReactPlaceholder>
    </CardBody>
  </Card>
)

export default CardIcon
