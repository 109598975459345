export default {
  item: {
    data: null,
    loading: false,
    error: null
  },
  list: {
    data: [],
    loading: false,
    page: 1,
    perPage: 50,
    total: 0,
    pages: 0,
    error: null
  },
  listCountStatus: {
    count: 0
  },
  create: {
    loading: false,
    error: null
  },
  update: {
    loading: false,
    error: null
  },
  patch: {
    item: [],
    loading: false,
    error: null
  },
  remove: {
    loading: false,
    error: null
  },
  dashboard: {
    data: null,
    loading: false,
    error: null
  },
  report: {
    data: null,
    loading: false,
    error: null
  },
  reportHistory: {
    data: null,
    loading: false,
    error: null
  }
}
