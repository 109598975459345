import { useTranslation } from 'react-i18next'

export const useAPPTranslation = () => {
  const { t } = useTranslation()

  type CapitalizeTranslated = (
    key: string,
    values?: Record<string, any>
  ) => string

  const capitalizeTranslated: CapitalizeTranslated = (
    key,
    { count, ...values } = { count: 1 }
  ) =>
    t('c:capitalize', {
      value: t(key, { count, ...values })
    })

  interface ToastOptions {
    prefix: string
    toastFunction: (title: string, message: string) => void
  }
  /**
   * Exibe uma mensagem de toast utilizando traduções baseadas em um prefixo fornecido.
   * A função procura automaticamente as chaves de tradução concatenando `.title` e `.message` ao prefixo.
   *
   * @param {string} prefix - O prefixo usado para localizar as chaves de tradução.
   * @param {Object} toastFunction - A função de toast que será utilizada (por exemplo, `toastr.error`, `toastr.success`).
   * @returns {void} - Esta função não retorna um valor, apenas exibe o toast.
   */

  const tToasts = ({ prefix, toastFunction }: ToastOptions) => {
    toastFunction(t(`${prefix}.title`), t(`${prefix}.message`))
  }

  return { t, tCapitalize: capitalizeTranslated, tToasts }
}
