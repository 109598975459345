import { useAppInfo } from '~/hooks/useAppInfo'

import Wallets from '../wallets'
import WalletTransaction from '../WalletTransaction'

const Wallet = () => {
  const { isWhiteLabel } = useAppInfo()

  // return isWhiteLabel ? <WalletTransaction /> : <Wallets />
  return <Wallets />
}

export default Wallet
