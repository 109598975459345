import React, { useMemo, useCallback } from 'react'
import { FiCheckSquare, FiSquare } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { setSelectedTag } from '~/modules/chat/store/actions'

import { Container } from './styles'

const TagButton = ({
  showOnlyChecked,
  isFiltered = false,
  data: { tagId, color, label }
}) => {
  const dispatch = useDispatch()

  const { selectedTags } = useSelector(({ chats }) => chats)

  const checked = useMemo(
    () => selectedTags.indexOf(tagId) > -1,
    [selectedTags, tagId]
  )

  const handleSelectTag = useCallback(
    () => dispatch(setSelectedTag({ tagId })),
    [dispatch, tagId]
  )

  const shouldRender = useMemo(() => {
    if (isFiltered) {
      return false
    }

    if (showOnlyChecked) {
      return checked
    }

    return true
  }, [checked, showOnlyChecked, isFiltered])

  return shouldRender ? (
    <Container
      type="button"
      color={color}
      isChecked={checked}
      onClick={handleSelectTag}
      className="TagButton"
    >
      <div className="check">{checked ? <FiCheckSquare /> : <FiSquare />}</div>

      <div className="label">{label}</div>
    </Container>
  ) : (
    <></>
  )
}

export default TagButton

TagButton.propTypes = {
  data: PropTypes.shape({
    tagId: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  showOnlyChecked: PropTypes.bool,
  isFiltered: PropTypes.bool
}

TagButton.defaultProps = {
  isFiltered: false,
  showOnlyChecked: false
}
