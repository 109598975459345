import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectSearchable from '~/components/SelectSearchable';
import { statusListOptionsSelect } from '~/pages/orders/_status/order';
import { setFilteredStatus } from '~/store/modules/filters/actions';

/**
 * Componente para filtrar a listagem por canais.
 * Ele possibilita a seleção de diversos locais e mantém no estado global: filters.channel.
 *
 * Não recebe nenhum parâmetro e retorna um select procurável.
 *
 * @example <FilterStatus />
 */

const FilterStatus = () => {
  const dispatch = useDispatch();

  const { status: filteredStatus } = useSelector(state => state.filters);

  const handleChangeStatus = selectedStatus => {
    dispatch(setFilteredStatus({ filteredStatus: selectedStatus }));
  };

  return (
    <SelectSearchable
      placeholder="Filtrar por status"
      options={statusListOptionsSelect}
      onChange={handleChangeStatus}
      defaultValue={[filteredStatus]}
    />
  );
};

export default FilterStatus;
