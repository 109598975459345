import React, { useMemo } from 'react';

import { Form } from '@unform/web';

import RulesList from '~/components/RulesList';
import { FormGroup, Input, Label } from '~/components/unform';

import { useMapDraw } from '../../hooks/useMapDraw';
import DrawButtons from './DrawButtons';
import SubmitButtons from './SubmitButtons';

const CreatePanel = () => {
  const {
    drawing,
    isValidShape,
    editingRegion,
    setEditingRegion,
    handleCreateRegion,
  } = useMapDraw();

  const rules = {
    length: 'A área precisa ter no mínimo 3 pontos.',
  };
  const ruleErrors = useMemo(
    () => (isValidShape ? [] : ['length']),
    [isValidShape],
  );

  return (
    <>
      <section className="createSection">
        <header>
          <strong className="title">Criar uma região</strong>
        </header>
        <DrawButtons />
      </section>

      <section className="createSection">
        {drawing && (
          <Form onSubmit={handleCreateRegion}>
            <FormGroup>
              <Label htmlFor="name" isRequired>
                Nome da região
              </Label>

              <Input
                id="name"
                name="name"
                placeholder="Insira o nome da região"
                {...(editingRegion?.name ? { value: editingRegion?.name } : {})}
                autoFocus
                required
                onChange={data =>
                  setEditingRegion(e => ({ ...e, name: data.value }))
                }
              />
            </FormGroup>

            <FormGroup>
              <RulesList rules={rules} ruleErrors={ruleErrors} />
            </FormGroup>

            <SubmitButtons />
          </Form>
        )}
      </section>
    </>
  );
};

export default CreatePanel;
