import Axios from 'axios'
import PropTypes from 'prop-types'

import checkQuery from './checkQuery'

/**
 * Esta função dispara uma consulta no metabase.
 * Ela retorna um ID da tarefa que consultamos coma checkQuery.
 *
 * Dúvidas sobre a motivação dessa função?
 * Consulte o README.md na raiz de FilterMetabase
 */

/**
 *
 * @param {string} type Tipo do dado que queremos
 * @param {object} body Request body da requisição para combinar filtros
 * @param {function} onSuccess Função que retornará o objeto formatado para ser usado no select
 * @param {function} onError Função que tratará um possível erro na request
 * @param {function} handleLoading Função para lidar com status de loading
 * @returns {Promise}
 */

const initialRequestToGetValues = async ({
  type,
  body,
  handleLoading,
  onSuccess,
  onError
}) => {
  handleLoading(true)

  try {
    const { data } = await Axios.post(
      `/api/analytics/queries/${type}/trigger`,
      body
    )

    if (data.id) {
      checkQuery({
        idTask: data.id,
        onSuccess,
        onError,
        handleLoading,
        type
      })

      return
    }

    handleLoading(false)
  } catch (err) {
    console.error(err)

    setTimeout(() => {
      initialRequestToGetValues()
    }, 1000)
  }
}

export { initialRequestToGetValues }

initialRequestToGetValues.defaultProps = {
  body: null
}

initialRequestToGetValues.propTypes = {
  type: PropTypes.string.isRequired,
  body: PropTypes.shape({}),
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  handleLoading: PropTypes.func.isRequired
}
