import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SelectSearchable from '~/components/SelectSearchable'
import { setFilter } from '~/store/modules/filtersSellOut/actions'
import { FILTERS_SEELOUT } from '~/store/modules/filtersSellOut/constants'

import { customStyles, MultiValue, ValueContainer } from '../customSelect'
import { initialRequestToGetValues } from '../helpers/initialRequest'

const statictData = {
  type: 'supermarkets',
  placeholder: 'Filtrar por supermercados',
  placeholderError: '🔴 Erro ao carregar supermercados',
  labelMulti: 'supermercados selecionados',
  labelAll: 'Todos os supermercados',
  searching: 'Buscando supermercados...'
}

/**
 * Componente para filtrar a listagem por mercados.
 * Ele possibilita a seleção de diversos valores e mantém no estado global: filters.supermarkets.
 *
 * Não recebe nenhum parâmetro e retorna um select procurável.
 *
 * @example <FilterSupermarkets />
 */
const FilterSupermarkets = () => {
  const [rawOptions, setRawOptions] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()

  /**
   * Lidando com o filtro e ações no estado global
   */

  const { supermarkets: filteredValues } = useSelector(
    state => state.filtersSellOut
  )

  const handleChange = data => {
    const shouldReset = data?.findIndex(item => item?.reset) > -1

    const updatedStatus = shouldReset ? null : data

    dispatch(
      setFilter({ type: FILTERS_SEELOUT.supermarkets, data: updatedStatus })
    )
  }

  /**
   * Este é um filtro com valores dinâmicos.
   * Os options são dados obtidos com a API.
   */

  const initialRequestData = useMemo(
    () => ({
      type: statictData.type,
      onSuccess: setRawOptions,
      onError: setError,
      handleLoading: setLoading
    }),
    []
  )

  useEffect(() => {
    initialRequestToGetValues(initialRequestData)
  }, [initialRequestData])

  const options = useMemo(
    () => [
      // { label: statictData.labelAll, value: null, reset: true },
      ...rawOptions
    ],
    [rawOptions]
  )

  /**
   * Placeholder
   */

  const placeholder = useMemo(() => {
    if (loading) return statictData.searching

    if (error) return statictData.placeholderError

    return statictData.placeholder
  }, [error, loading])

  return (
    <SelectSearchable
      placeholder={placeholder}
      options={options}
      onChange={handleChange}
      defaultValue={filteredValues}
      loading={loading}
      disabled={loading}
      isMulti
      hideSelectedOptions={false}
      labelMulti={statictData.labelMulti}
      components={{ ValueContainer, MultiValue }}
      styles={customStyles}
      closeMenuOnSelect={false}
    />
  )
}

export default FilterSupermarkets
