import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'

import EmptyPage from '~/components/empty-page'
import { Filters } from '~/components/Filters'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import LoadingPopUp from '~/components/LoadingPopUp'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { formatSearchString, queryDatesDatepickerIsolated } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import useFetchSWR from '~/hooks/useFetchSWR'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'
import { useAppSelector } from '~/store/hooks'

import { retailMediaWalletFilters } from '../../store/common/filters'
import {
  setPageIndustryListTransaction,
  setPerPageIndustryListTransaction
} from '../../store/industry/wallet/actions'
import { schema } from './schema'

export const IndustryMovimentationsTable = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const { walletType } = useAppSelector(state => state.filters.retailMedia)

  const {
    industry: {
      wallet: {
        listTransaction: { page, perPage }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  const queryParams = useMemo(
    () =>
      formatSearchString({
        page,
        quantity: perPage,
        transaction_type: walletType?.value,
        ...queryDatesDatepickerIsolated(startDate, endDate)
      }),
    [endDate, page, perPage, startDate, walletType?.value]
  )

  const { data, error, isValidating } =
    useFetchSWR<CheckingAccountTransactionsResponse>({
      url: `${reatailmediaApi}/checking-account/transaction/${id}?${queryParams}`,
      refreshInterval: 60000,
      revalidateOnFocus: false
    })

  const [AlertOnError] = useAlert(error)

  const handlePagination = useCallback(
    (page: number) => {
      dispatch(setPageIndustryListTransaction(page))
    },
    [dispatch]
  )

  const handleItemsPerPageChange = useCallback(
    ({ value }: { value: number }) => {
      dispatch(setPerPageIndustryListTransaction(value))
    },
    [dispatch]
  )

  return (
    <>
      <Filters {...retailMediaWalletFilters} />

      <Loading status={!data && isValidating}>
        {t('common:actions.loadingData')}...
      </Loading>

      <LoadingPopUp isActive={isValidating} />

      <AlertOnError />

      {data?.total === 0 && (
        <EmptyPage
          title={t('common:table.empty')}
          subtitle={t('common:table.emptyFiltered')}
          titleButton={t('common:words.clearFilters')}
          svgPath="photos/orders-empty"
        />
      )}

      {data?.total > 0 && (
        <>
          <Panel isLoading={!data}>
            <Table
              schema={schema}
              data={data?.data}
              total={data?.total}
              sideBorder
              smallerRowPadding
            />
          </Panel>

          <footer>
            <ListPagination
              total={data?.total}
              label={t(`rm:wallet.movimentations.pagination.label.${data?.total > 1 ? 'plural' : 'singular'}`)}
              currentPage={page}
              itemsPerPage={perPage}
              menuPlacement="top"
              onClickPagination={handlePagination}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </footer>
        </>
      )}
    </>
  )
}
