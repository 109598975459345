import React from 'react';

import { OrderTableProvider } from '../_hooks/useOrderBoard';
import BaseOrderBoard from './Base';

const OrdersBoard = () => (
  <OrderTableProvider>
    <BaseOrderBoard />
  </OrderTableProvider>
);

export default OrdersBoard;
