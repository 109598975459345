import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  --aside__width: 360px;
  --chatlist__width: 480px;
`
