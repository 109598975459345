import i18n from '~/i18n'

export const paymentMethods = {
  credit_card: i18n.t('rm:creditCard'),
  pix: 'PIX'
  // boleto: 'Boleto'
}

export const paymentMethodsOptions = Object.entries(paymentMethods).map(
  ([value, label]) => ({ value, label })
)
