import { IconType } from 'react-icons'
import { FiGift as icon } from 'react-icons/fi'

import * as PromotionFlyersPage from '~/modules/flyers/pages/flyers'

import { promotionalFlyerTexts } from '../flyers/constants/texts'
import { promotionalTexts } from './constants/texts'
import * as PromotionPage from './pages/promotions'

export const promotionalRoutes: RootMenuItem<IconType> = {
  id: 'b358e737-9764-4361-8035-65da1583c604',
  path: '/promotional',
  name: 'Promocional',
  icon,
  children: [
    /**
     * Promoções
     */
    {
      id: '9ec83980-b622-4728-808e-2b9a86dd4234',
      path: '/promotional/list/',
      name: promotionalTexts.menu.list,
      component: PromotionPage.List
    },
    {
      id: '8c85c497-5f9e-430d-b00b-4f5a7dc07d0a',
      path: '/promotional/create',
      name: promotionalTexts.menu.create,
      component: PromotionPage.New
    },
    {
      id: null, //rota-sem-id
      path: '/promotional/view/:id',
      name: promotionalTexts.menu.view,
      component: PromotionPage.View,
      hideMenu: true
    },
    {
      id: null, //rota-sem-id
      path: '/promotional/edit/:id',
      name: promotionalTexts.menu.edit,
      component: PromotionPage.Edit,
      hideMenu: true
    },
    {
      id: '19d4e6ea-f92f-4882-a7c3-c5006d69d04f',
      path: '/promotional/coupon/',
      name: promotionalTexts.menu.couponMetrics,
      component: PromotionPage.Coupon
    },
    /**
     * Encartes
     */
    {
      id: null, //rota-sem-id
      path: '/promotional-flyers/list/',
      name: promotionalFlyerTexts.menu.list,
      component: PromotionFlyersPage.List
    },
    {
      id: null, //rota-sem-id
      path: '/promotional-flyers/create',
      name: promotionalFlyerTexts.menu.create,
      component: PromotionFlyersPage.New
    },
    {
      id: null, //rota-sem-id
      path: '/promotional-flyers/view/:id',
      name: promotionalFlyerTexts.menu.view,
      component: PromotionFlyersPage.View,
      hideMenu: true
    },
    {
      id: null, //rota-sem-id
      path: '/promotional-flyers/edit/:id',
      name: promotionalFlyerTexts.menu.edit,
      component: PromotionFlyersPage.Edit,
      hideMenu: true
    }
  ]
}

export default promotionalRoutes
