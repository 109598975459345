export default {
  item: {
    data: null,
    loading: false,
    error: null,
  },
  list: {
    data: [],
    loading: false,
    page: 1,
    perPage: 50,
    error: null,
  },
  create: {
    loading: false,
    error: null,
  },
  update: {
    loading: false,
    error: null,
  },
  remove: {
    loading: false,
    error: null,
  },
  dashboard: {
    data: null,
    loading: false,
    error: null,
  },
};
