export const SET_FILTER = '@filtersSellOut/SET_FILTER'

export const FILTERS_SEELOUT = {
  supermarkets: 'supermarkets',
  stores: 'stores',
  channels: 'channels',
  brands: 'brands',
  categories: 'categories',
  subcategories: 'subcategories',
  products: 'products',
  aggregate: 'aggregate'
} as const

export type FiltersSellOutKeysTypes = keyof typeof FILTERS_SEELOUT
