import styled from 'styled-components'

export const WrapperCard = styled.div`
  .hold-cards {
    gap: 1rem;

    .card {
      margin: 0;
    }
  }
`
