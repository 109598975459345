import axios from 'axios'

import { reatailmediaApi as baseAPI } from '../api'

const baseURL = `${baseAPI}/audit-log`

/**
 * List all
 */
export const getAuditLogService = (
  params: ResquestGetAuditLog = {} as ResquestGetAuditLog
) => axios.get<ResponseGetAuditLog>(baseURL, { params })
