import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import PropTypes from 'prop-types'

/**
 * @component
 * @example
 *    const options = [{value: 1, label: 'Label do item'}, {value: 2, label: 'Label do item 2'}];
 *
 *    <SelectSearchable
 *      placeholder="Filtrar por locais"
 *      options={locations}
 *      loading={loading}
 *      onChange={handleChangeLocations}
 *      defaultValue={filteredLocations}
 *    />
 *
 * @param {array} options Objeto com itens que serão as opções do select. {value: 1, label: 'Label do item'}
 * @param {array} defaultValue Objeto com itens já selecionados. Mesmo formato do options.
 * @param {function} onChange Função chamada a cada mudança no select. Retorna um array com os objetos selecionados
 * @param {string} placeholder Texto para o placeholder. Default: "Selecione".
 * @param {boolean} loading Status de carregamento. Default: false.
 * @param {string} error Erro ao carregar itens para lista. Default: null.
 *
 */

const SelectSearchable = ({
  options,
  onChange,
  placeholder,
  loading,
  error,
  defaultValue,
  isClearable,
  styles = {},
  isMulti = false,
  selectRef,
  size = 'medium',
  ...rest
}) => {
  const sizeModifiers = { small: { height: '33px', minHeight: '33px' } }

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? null : null,
      ...sizeModifiers?.[size]
    }),
    indicatorsContainer: base => ({
      ...base,
      ...sizeModifiers?.[size]
    }),
    valueContainer: base => ({ ...base, ...sizeModifiers?.[size] }),
    placeholder: provided => ({
      ...provided,
      lineHeight: 1,
      fontSize: '.75rem'
    }),
    ...styles
  }

  const multiStyles = {
    ...customStyles,
    ...styles,
    placeholder: provided => ({
      ...provided,
      lineHeight: 1,
      fontSize: '.75rem'
    }),
    valueContainer: provided => {
      return {
        ...provided,
        height: '33px',
        lineHeight: 1,
        fontSize: '.75rem'
      }
    },
    option: provided => {
      return {
        ...provided,
        textTransform: 'none'
      }
    },
    indicatorsContainer: provided => ({
      ...provided,
      ...sizeModifiers?.[size]
    })
  }

  return (
    <Select
      closeMenuOnSelect
      isMulti={isMulti}
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      isLoading={loading}
      isDisabled={!options || error || rest?.disabled}
      defaultValue={defaultValue}
      classNamePrefix="SelectSearchable"
      openMenuOnFocus
      openMenuOnClick
      menuPortalTarget={document.body}
      styles={isMulti ? multiStyles : customStyles}
      isClearable={isClearable}
      {...(selectRef ? { ref: selectRef } : {})}
      {...rest}
    />
  )
}

export default SelectSearchable

SelectSearchable.propTypes = {
  styles: PropTypes.shape({}),
  selectRef: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  error: PropTypes.string,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
}

SelectSearchable.defaultProps = {
  styles: {},
  onChange: null,
  selectRef: null,
  options: null,
  placeholder: 'Selecione',
  loading: false,
  error: null,
  defaultValue: null,
  isClearable: true,
  isMulti: false
}
