import moment from 'moment'

import { types } from '../constants'

const parentKey = 'report'
const key = 'getRealtime'
const reduxKey = types.getRealtime

const index = {
  pending: `${reduxKey}_PENDING`,
  fulfilled: `${reduxKey}_FULFILLED`,
  rejected: `${reduxKey}_REJECTED`
}

export const getRealtime = {
  [index.pending]: ({ draft }: ActionData) => {
    draft[parentKey][key].loading = true
    draft[parentKey][key].error = null
  },
  [index.fulfilled]: ({ draft, action }: GetReportCampaignsFulfilled) => {
    const oldData = draft[parentKey][key].data
    const currentTime = moment().format('HH:mm:ss')

    const rawData = { groupby: currentTime, ...action.payload }

    draft[parentKey][key].data = [...oldData, rawData]
    draft[parentKey][key].loading = false
    draft[parentKey][key].error = null
  },
  [index.rejected]: ({ draft, action }: ActionDataRejected) => {
    draft[parentKey][key].loading = false
    draft[parentKey][key].error = action.payload
  }
}
